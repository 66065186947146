// Core
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { JsonReply, ProjectTimeline } from "../models";
import { RoutePrefix, Route } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/CustomerProject')
export class CustomerProjectService {
    constructor(private httpClient: HttpClient) { }

    @Route('ForCustomer')
    public getForCustomer(customerId: number, url: string = ''): Promise<JsonReply<ProjectTimeline>> {
        return this.httpClient.post<JsonReply<ProjectTimeline>>(url, customerId)
            .toPromise();
    }

    @Route('Add')
    public addProject(project: ProjectTimeline, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, project)
            .toPromise();
    }

    @Route('Update')
    public updateProject(project: ProjectTimeline, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, project)
            .toPromise();
    }

    @Route('Delete')
    public deleteProject(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }
}