import { TableOptions, TableActions, TTD, GetTTDParams, UpdateType, SearchOrder } from '../models';
import { Config } from './../../app.constants';

export class TtdTableOptions extends TableOptions {
    constructor() {
        super('TTD', true);
        this.buttons = [
            TableActions.TTDReportAll,
            TableActions.DeleteMany,            
        ];
        this.paths.fetchPath = Config.ApiUrls.FETCH_TTD;
        this.paths.updatePath = () => this.config.RoutingUrls.TTD_DETAILS;
        this.paths.exportAllPath = 'api/TTD/ExportAll';
        this.params = new GetTTDParams();
        this.params.ordering.order = SearchOrder.Reversed;
        this.newParams = new GetTTDParams();
        this.displayedColumns = [
            'selectItems',
            'name',
            'instanceReceiver',
            'domainsCount',
            'lastUpdate'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'name': {
                label: 'TTD',
                sort: true,
                plainText: true
            },
            'instanceReceiver': {
                label: 'INSTANCE_RECEIVER',
                sort: true,
                plainText: true
            },
            'domainsCount': {
                label: 'RELATED_DOMAINS',
                sort: false,
                plainText: true
            },
            'lastUpdate': {
                format: () => 'DD/MM/YY, hh:mm',
                label: 'LAST_UPDATE',
                sort: true,
                type: 'date',
                plainText: true
            }
        };
        this.update = {
            action: (row: TTD) => {
                return {id: row.id};
            },
            enabled: true,
            type: UpdateType.RouterLink,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'TTD',
                formName: 'ttd_contacts_filter',
                type: 'text-input',
                class: 'col-12 col-md-4',
                param: 'name',
            },
            {
                name: 'INSTANCE_RECEIVER',
                formName: 'ttd_receiver_filter',
                type: 'text-input',
                class: 'col-12 col-md-5',
                param: 'instanceReceiver'
            }
        ];
        this.noContactId = true;
    }

}



