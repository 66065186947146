import { BaseParams } from "./BaseParams";
import { CustomerServiceModel } from "../customer/CustomerServiceModel";
import { RichEnum } from "../RichEnum";

export class FindContactsParams extends BaseParams {
    name: string;
    surname: string;
    nameOrSurnameOrCompany: string;
    caseType: number[];
    salesContractDateFrom: Date;
    salesContractDateTo: Date;
    salesContractDateEndFrom: Date;
    salesContractDateEndTo: Date;
    firstContactDateSpecified: boolean;
    workflowResult: number[];
    requiredServices: CustomerServiceModel[];
    assignedSalesId: string;
    firstContactPersonId: string;
    contactStatus: number[];
    preAnalysisStatus: number[];
    email: string;
    relation: number[];
    hasCurrentUserInTeam: boolean;
    workStartDateFrom: Date;
    workStartDateTo: Date;

    constructor(values: Object = null) {
        super();

        this.pageSizeOptions = [30, 50, 100];
        this.pageSize = 30;

        this.name = '';
        this.surname = '';
        this.nameOrSurnameOrCompany = '';
        this.caseType = []; //15;
        this.contactStatus = []; //RichEnum.Any;
        this.salesContractDateFrom = null;
        this.salesContractDateTo = null;
        this.salesContractDateEndFrom = null;
        this.salesContractDateEndTo = null;
        this.firstContactDateSpecified = false;
        this.assignedSalesId = null;
        this.firstContactPersonId = null;
        this.preAnalysisStatus = []; //RichEnum.Any;
        this.workflowResult = [];
        this.requiredServices = [];
        this.email = null;
        this.relation = []; //RichEnum.Any;
        this.hasCurrentUserInTeam = false;
        this.workStartDateFrom = null;
        this.workStartDateTo = null;

        if (values) {
            Object.assign(this, values);
        }
    }

    public getSaveToken(): string {
        this._save_token_ = 'FindContacts';
        return this._save_token_;
    }
}
