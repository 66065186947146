import { TableOptions, TableActions, FilterSelectEnumOptions, ImportFromUniumParams } from '../models';

export class InheritedAssetsTableOptions extends TableOptions {
    constructor() {
        super();
        this.buttons = [
            TableActions.ConvertMany
        ];
        this.params = new ImportFromUniumParams();        
        this.params.status = 3;
        this.params.pageSizeOptions = [10, 20, 50];
        this.params.pageSize = 10;
        this.newParams = new ImportFromUniumParams();
        this.newParams.status = 3;
        //se ci sono paramentri salvati nel localstorage li recupero
        var paramStorage = localStorage.getItem('/api/Content/InheritedForCustomer')
        this.params = paramStorage ? JSON.parse(paramStorage) : this.params;

        this.paths.fetchPath = '/api/Content/InheritedForCustomer';
        this.displayedColumns = [
            'selectItems',
            'url',
            'publicationDate',
            'preview',            
            'author',
            'rScore',
            'status',
            'evaluationIndex',
            'tags',
            'notes'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'URL',
                sort: true,
                type: 'link',
                plainText: false
            },
            'publicationDate': {
                label: 'PUBLISHED',
                sort: true,
                plainText: true,
                type: 'date'
            },
            'preview': {
                sort: false,
                label: 'PREVIEW',
            },  
            'author': {
                label: 'AUTHOR',
                sort: true,
                plainText: true
            },
            'rScore': {
                label: 'RSCORE',
                sort: true,
                rScore: true
            },
            'status': {
                label: 'STATUS',
                sort: true,
                type: 'enum',
                format: () => this.enums.TargetStatus,
                translate: true,
                plainText: false
            },
            'evaluationIndex': {
                label: 'SENTIMENT',
                sort: true,
            }, 
            'tags': {
                label: 'TAG',
                sort: true,
                plainText: true
            },  
            'notes': {
                label: 'NOTES',
                sort: true,
            }
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'URL',
                formName: 'inh_url_filter',
                type: 'text-input',
                class: 'col-12 col-lg-9',
                param: 'url'
            },           
            {
                name: 'KEYWORDS',
                formName: 'inh_preview_filter',
                type: 'text-input',
                class: 'col-12 col-lg-3',
                param: 'keyword'
            },
            {
                name: 'STATUS',
                formName: 'inh_status_filter',
                type: 'select',
                class: 'col-12 col-lg-3',
                param: 'status',
                enum: 'TargetStatus'
            },
            {
                name: 'SENTIMENT',
                formName: 'inh_sentiment_filter',
                type: 'select',
                class: 'col-12 col-lg-3',
                param: 'sentiment',
                enum: 'UNIUMSentiment',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            },
            {
                name: 'PUBLISHING_DATE_FROM',
                formName: 'inh_dateFrom_filter',
                type: 'datepicker',
                class: 'col-12 col-lg-3',
                param: 'publicationDateFrom'
            },
            {
                name: 'PUBLISHING_DATE_TO',
                formName: 'inh_dateTO_filter',
                type: 'datepicker',
                class: 'col-12 col-lg-3',
                param: 'publicationDateTo'
            },
            {
                name: 'MACROCATEGORY',
                formName: 'inh_macro_filter',
                type: 'select_macro_unium',
                class: 'col-12 col-lg-3',
                param: 'typologyId',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            },
            {
                name: 'CATEGORY',
                formName: 'inh_category_filter',
                type: 'select_category_unium',
                class: 'col-12 col-lg-3',
                param: 'targetId',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            },
            {
                name: 'CHANNELS',
                formName: 'inh_channels_filter',
                type: 'select_channel_unium',
                class: 'col-12 col-lg-3',
                param: 'channelId',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            }
        ];
    }

}



