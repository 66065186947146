import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'join',
    standalone: true
})
export class JoinArrayWithSeparatorPipe implements PipeTransform {
    transform(data: Object, separator: string = ' ', propertyName: string = null) {
        if (!data) {
            return '';
        }

        let arr: Array<any> = data as Array<any>;
        if (!arr.length || arr.length === 0) {
            return '';
        }

        if (propertyName) {
            arr = arr.map(item => item[propertyName]);
        }

        if (separator == '\n')
            separator = '<br />';

        let result = arr.join(separator);
        return result;
    }
}
