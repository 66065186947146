export class CustomerReferent {
    static count: number = 0;
    name: string = '';
    surname: string = '';
    email: string = '';
    officePhone: string = '';
    mobilePhone: string = '';
    note: string = '';
    id: number = 0;
    _id: number = CustomerReferent.count++;

    constructor(public contactId: number = 0) { }
}