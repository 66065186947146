// Core
import {Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges} from '@angular/core';
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import {BaseParams} from "../../instruments/models/api/BaseParams";
import {BaseParamsWithContactId} from "../../instruments/models/api/BaseParamsWithContactId";
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'rm-paginator',
    templateUrl: './rm-paginator.component.html',
    styleUrls: ['./rm-paginator.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule, MatButtonModule, MatTooltipModule, TranslateModule]
})
export class RmPaginatorComponent implements OnInit {
    // Buttons
    @ViewChild('firstPageButton', { static: true }) firstPageButton: MatButton;
    @ViewChild('prevButton', { static: true }) previousButton: MatButton;
    @ViewChild('nextButton', { static: true }) nextButton: MatButton;
    @ViewChild('lastPageButton', { static: true }) lastPageButton: MatButton;

    @Input() showButtons: boolean = true;
    @Input() updatePaginationEvent: EventEmitter<any>;

    // Pagination params
    private _params: BaseParams;

    @Input()
    set params (value:  BaseParams) {
        this._params = value;
        if (value.page >= 1) {
            this._params.page = value.page;
            this.handleButtonsDisabling();
        } else {
            this._params.page = 1;
        }

        if (value.pageSize >= 1) {
            this._params.pageSize = value.pageSize;
            this.handleButtonsDisabling();
        } else {
            this._params.pageSize = 1;
        }
    }
    get params(): BaseParams {
        return this._params;
    }

    @Output() paginationParamsChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        if (this.showButtons && this.updatePaginationEvent) {
            this.updatePaginationEvent.subscribe(() => this.handleButtonsDisabling());
        }

        if (this.showButtons)
            this.handleButtonsDisabling();
    }

    handleButtonsDisabling() {
        if (!this.showButtons)
            return;

        // first page
        if (this._params.page === 1) {
            let isSingle = this._params.totalPages === 1;
            this.nextButton.disabled = isSingle;
            this.lastPageButton.disabled = isSingle;
            this.firstPageButton.disabled = true;
            this.previousButton.disabled = true;
        // between first and last
        } else if (this._params.page < this._params.totalPages){
            this.firstPageButton.disabled = false;
            this.previousButton.disabled = false;
            this.nextButton.disabled = false;
            this.lastPageButton.disabled = false;
        // last page
        } else {
            this.firstPageButton.disabled = false;
            this.previousButton.disabled = false;
            this.nextButton.disabled = true;
            this.lastPageButton.disabled = true;
        }
    }

    // PAGE SIZE SELECTION

    emitPageSize() {
        this._params.page = 1;
        this.paginationParamsChanged.emit();
    }

    // NAVIGATION BUTTONS ACTIONS

    showPreviousPage() {
        this._params.page -= 1;
        this.paginationParamsChanged.emit();
    }

    showNextPage() {
        this._params.page += 1;
        this.paginationParamsChanged.emit();
    }

    showFirstPage() {
        this._params.page = 1;
        this.paginationParamsChanged.emit();
    }

    showLastPage() {
        this._params.page = this._params.totalPages;
        this.paginationParamsChanged.emit();
    }
}
