import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JsonReply, CustomerWorkingTeam  } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/WorkingTeam')

export class ProjectWorkingTeamService {
    constructor(private httpClient: HttpClient) {
    }

    @Route('ForCustomer')
    public getForCustomer(customerId: number, url: string = ''): Promise<JsonReply<CustomerWorkingTeam>> {
        return this.httpClient.post<JsonReply<CustomerWorkingTeam>>(url, customerId)
            .toPromise();
    }

    @Route('Update')
    public update(team: CustomerWorkingTeam, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, team)
            .toPromise();
    }

    @Route('Delete')
    public delete(customerId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerId)
            .toPromise();
    }
}
