// Core
import { NgModule, ErrorHandler } from '@angular/core';

import {
    CustomerService,
    ContactsLinkedServicesService,
    DomainUserService,
    ProjectService,
    ContentService,
    CustomerDocumentService,
    SharedService,
    CustomerEventsService,
    CustomerLinksService,
    ProjectLinkService,
    CustomerRecallsService,
    AlertService,
    ChannelService,
    NotificationService,
    HttpInterceptorService,
    ErrorsHandler,
    ProjectWorkingTeamService,
    ContentWritingTeamService,
    DomainService,
    TTDService,
    ValueMatrixService,
    HttpCancelService,
    InstanceService,
    InstanceRecallService,
    CustomerRecallService,
    CustomerReferentService,
    CustomerProjectService,
    CustomerCheckKeywordService,
    SERPService,
    QuickSerpService,
    SoftLegalService
} from './';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
    providers: [
        CustomerService,
        ContactsLinkedServicesService,
        DomainUserService,
        AlertService,
        ProjectService,
        ContentService,
        CustomerDocumentService,
        SharedService,
        CustomerEventsService,
        CustomerLinksService,
        ProjectLinkService,
        CustomerRecallsService,
        ChannelService,
        NotificationService,
        HttpInterceptorService,
        ValueMatrixService,
        ProjectWorkingTeamService,
        ContentWritingTeamService,
        DomainService,
        TTDService,
        HttpCancelService,
        InstanceService,
        InstanceRecallService,
        CustomerRecallService,
        CustomerReferentService,
        CustomerProjectService,
        CustomerCheckKeywordService,
        SERPService,
        SoftLegalService,
        QuickSerpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
            multi: false
        }
    ],
})
export class ServicesModule { }
