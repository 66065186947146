export class CustomerChannel {
    public contentId?: number;
    public domainId?: number;
    public contactId?: number;
    public isDone?: boolean = false;
    public publishedNumber?: number;
    public profileUrl?: string;
    public workingStatus?: number;
    public activityDate?: Date;
    public scheduled?: number;
    public type?: number;
    public approval?: number;
    public approvalDate?: Date;
    public managed?: boolean;

    //only UI
    public name?: string;
}
