import { Injectable } from '@angular/core';
import { SoftLegalMassiveParams } from '../../pages/project-details/soft-legal/soft-legal-massive/soft-legal-massive.component';
import {InstanceLinkStatus, SoftLegal, SoftLegalLinkStatus, WebFormStatus} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SoftLegalService {

    constructor() { }

    SoftLegalBadgeIsGreen(status: number | SoftLegalLinkStatus): boolean {
        let isGreen = null;
        switch (status) {
            case SoftLegalLinkStatus.Anonymized:
            case SoftLegalLinkStatus.Deleted:
            case SoftLegalLinkStatus.UnIndexed:
            case SoftLegalLinkStatus.Updated:
            case SoftLegalLinkStatus.Blocked:
                isGreen = true;
                break;
            case SoftLegalLinkStatus.Refused:
                isGreen = false;
                break;
            default:
                isGreen = null;
        }
        return isGreen;
    }

    // controlliamo che lo status generico rispetti quello delle azioni
    CheckForStatusChanged(status: number | SoftLegalLinkStatus, action: SoftLegal | SoftLegalMassiveParams) {
        let save = false;

        switch (status) {
            case SoftLegalLinkStatus.Blocked:
                save = action.googleResponse ||
                    action.yahooResponse ||
                    action.bingResponse ||
                    action.webFormResponse === WebFormStatus.Blocked;
                break;

            case SoftLegalLinkStatus.Deleted:
                save = action.webFormResponse === WebFormStatus.Cancelled || action.instanceResponse === InstanceLinkStatus.Deleted;
                break;

            case SoftLegalLinkStatus.Anonymized:
                save = action.instanceResponse === InstanceLinkStatus.Anonymized;
                break;

            case SoftLegalLinkStatus.UnIndexed:
                save = action.instanceResponse === InstanceLinkStatus.UnIndexed;
                break;

            case SoftLegalLinkStatus.Updated:
                save = action.instanceResponse === InstanceLinkStatus.Updated;
                break;

            case SoftLegalLinkStatus.Refused:
                save = action.googleResponse === false ||
                        action.yahooResponse === false ||
                        action.bingResponse === false ||
                        action.webFormResponse === WebFormStatus.Rejected ||
                        action.instanceResponse === InstanceLinkStatus.Refused;
                break;

            default:
                save = true;
                break;
        }

        return save;
    }

}
