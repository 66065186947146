import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { GetContentParams, JsonReply, ContentModel, JsonReplyWithParams, LinksToAssets, BRCustomerContentId, UsedbyOtherCustomer, linkCheckModel, GetContentLightParams, ImportInheritedAssetParams, BaseParamsWithContactId } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { SharedService } from './shared.service';
import {WidgetContentUpdateParams} from '../../components/widget-table/widget-components/widget-set-channel/widget-set-channel.component';
import {Observable} from 'rxjs';

@Injectable()
@RoutePrefix('/api/Content')
export class ContentService {
    constructor(private httpClient: HttpClient,
        private shared: SharedService) { }

    @Route('ForCustomer')
    public getContentForCustomer(params: GetContentParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Find')
    public findContents(params: GetContentLightParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Get')
    public getContents(params: GetContentParams, url: string = ''): Promise<JsonReplyWithParams<ContentModel[], GetContentParams>> {
        return this.httpClient.post<JsonReplyWithParams<ContentModel[], GetContentParams>>(url, params)
            .toPromise();
    }

    @Route('Add')
    public addContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('Upload')
    public upload(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('Update')
    public updateContent(content: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, content)
            .toPromise();
    }

    @Route('MassiveUpdate')
    public updateMassiveContent(param: any, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param)
            .toPromise();
    }

    @Route('NotUsed')
    public setAsNotUsed(contentId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contentId).toPromise();
    }

    @Route('ById')
    public getById(BRCustomerContentId: BRCustomerContentId, url: string = ''): Promise<JsonReply<ContentModel>>{
        return this.httpClient.post<JsonReply<ContentModel>>(url, BRCustomerContentId).toPromise();
    }

    @Route('Delete')
    public delete(contentId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contentId).toPromise();
    }

    @Route('Download')
    public download(id: BRCustomerContentId, url: string = ''): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');

        return this.httpClient.post(url, id, { headers: headers, responseType: 'blob' }).toPromise();
    }

    @Route('MaxUploadFileSize')
    public maxUploadFileSize(url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.get<JsonReply<any>>(url).toPromise();
    }

    //post refactor

    @Route('DeleteFile')
    public deleteFile(assetId: number, url: string = ''): Promise<JsonReply<ContentModel>> {
        return this.httpClient.post<JsonReply<ContentModel>>(url, assetId).toPromise();
    }
    
    @Route('MassiveImportInherited')
    public MassiveImport(linksToAssets: LinksToAssets, url: string = ''): Promise<JsonReply<ContentModel>> {
        return this.httpClient.post<JsonReply<ContentModel>>(url, linksToAssets).toPromise();
    }

    @Route('ImportInheritedAsset')
    public importInheritedAsset(InheritedContentIds: ImportInheritedAssetParams, url: string = ''): Promise<JsonReply<ContentModel>> {
        return this.httpClient.post<JsonReply<ContentModel>>(url, InheritedContentIds).toPromise();
    }

    @Route('UseCheck')
    public UseCheck(linkCheck: linkCheckModel, url: string = ''): Promise<JsonReply<UsedbyOtherCustomer>> {
        return this.httpClient.post<JsonReply<UsedbyOtherCustomer>>(url, linkCheck).toPromise();
    }

    @Route('GetTypologyDiagram')
    public getTypologyDiagram(params: BaseParamsWithContactId, url: string = ''): Promise<JsonReply<UsedbyOtherCustomer>> {
        return this.httpClient.post<JsonReply<UsedbyOtherCustomer>>(url, params).toPromise();
    }

    @Route('WidgetContentUpdate')
    public widgetContentUpdate(params: WidgetContentUpdateParams, url: string = ''): Observable<JsonReply<boolean>> {
        return this.httpClient.post<JsonReply<boolean>>(url, params);
    }
}
