import { TableOptions, TableActions, GetCustomerLinkParams, UpdateType, SoftLegalTab } from '../models';

export class SoftLegalTableOptionsAll extends TableOptions {
    constructor(tab: SoftLegalTab) {
        super('SoftLegal', true);
        this.buttons = [
            TableActions.DeleteManyBR,
            TableActions.MassiveSoftLegal
        ];


        this.paths.basePath = 'SoftLegal';
        this.tab = tab;
        this.paths.updatePath = () => '/api/Content/Update';
        this.displayedColumns = [
            'selectItems',
            'softLegal.isLinkWithDocuments',
            'url',
           'softLegal.successFee',
            'softLegal.instanceReceiverOrTTD',
            'softLegal.eventsNames',
            'contentCustomerExtData.commonNotes',
            'contentComponent'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'softLegal.isLinkWithDocuments': {
                label: 'EMPTY',
                sort: false,
            },
            'url': {
                label: 'URL',
                sort: true,
                type: 'link',
                plainText: false
            },
            'softLegal.successFee': {
                format: () => this.enums.SuccessFeeStatus,
                label: 'SUCCESS_FEE',
                sort: false,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'softLegal.instanceReceiverOrTTD': {
                label: 'TTD_INSTANCE_RECEIVER',
                sort: false,
                plainText: true
            },
            'softLegal.eventsNames': {
                label: 'EVENTS_NAMES',
                sort: false,
                plainText: true
            },
            'contentCustomerExtData.commonNotes': {
                label: 'NOTES',
                sort: false,
                plainText: false,
                type: 'longText'
                //longTextWithFormat: true
            },
            'contentComponent': {
                label: 'EMPTY',
                sort: true,
                type: 'contentComponent'
            }
        };
        this.update = {
            action: () => {
                return 'link';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
        this.showFilters = false;
        
        this.pagination.enabled = true;
        this.pagination.showButtons = true;

    }

}