import { Recall } from '../Recall';
import { InstanceStatusChange } from './InstanceStatusChange';

export class Instance {
    id: number = 0;
    contactId: number = 0;
    status: number = 0;
    insertDate: Date = null;
    lastUpdate: Date = null;
    lastCheck: Date = null;

    // EVENT
    eventName: string = null;

    // TTD
    instanceReceiver: string = null;
    ttdId: number = 0;
    ttdName: string = null;

    // REFERENT
    referentName: string = null;
    referentSurname: string = null;
    referentEmail: string = null;

    // ??
    description: string = null;
    note: string = null;
    letterDocUpdateTime: Date = null;
    instanceLetterGenerated: boolean = false;

    // LINKED STUFF
    statusChanges: InstanceStatusChange[] = null;
    recalls: Recall[] = null;

    monitoringContract: boolean = false;

}

export class InstanceGroup {
    status: number = 0;
    instances: Instance[] = [];
}
