import { Contact, SearchOrder, TableActions, TableOptions, UpdateType } from '../models';
import { FindContactsParams } from '../models/api/FindContactsParams';
import { FindContactsTableOptions } from './find-contacts-table.options';

export class RelatedContactsTableOptions extends FindContactsTableOptions {
    constructor(parentContactId: number, relation: number) {
        super();

        const params = (this.params as FindContactsParams);
        params.entityId = parentContactId;
        params.relation = [relation];

        this.buttons = [];
        this.displayedColumns = [
            'selectItems',
            'name',
            'surname',
            'caseType',
            'firstContactDate',
            'preAnalysisStatus',
            'contactStatus'
        ];

        this.paths.fetchPath = this.config.ApiUrls.GET_RELATED_CONTACTS;
    }
}