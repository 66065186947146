import { BaseParamsWithContactId } from "./BaseParamsWithContactId";
import { RichEnum } from "../RichEnum";
import { SoftLegalTab } from "../Enums";

export class GetFeasibilityParams extends BaseParamsWithContactId {
    public url: string;
    public result: number[] = [RichEnum.Any];
    public ttdOrInstanceReceiver: string;
    public eventName: string;
    public softLegalTab: SoftLegalTab;
    public isSoftLegal: boolean;

    constructor(public reverseOrder: boolean = false,
        public injuryLevel: number = RichEnum.Any,
        public successFee: number = null,
        public publishingDate: number = null,
        public linkPart: string = null,
        public notesPart: string = null) {

        super();

        this.softLegalTab = SoftLegalTab.All;
        this.url = null;
        this.pageSizeOptions = [10, 20, 50];
        this.pageSize = 10;
        this.isSoftLegal = false;
        this.result = [RichEnum.Any];
        this.ttdOrInstanceReceiver = null;
        this.eventName = null;
    }
}
