import { TableOptions, TableActions, GetCustomerLinkParams, UpdateType, SoftLegalTab } from '../models';

export class WebformTableOptions extends TableOptions {
    constructor(tab: SoftLegalTab) {
        super('SoftLegal', true);
        this.buttons = [
            //TableActions.ExportSoftLegalLinksAll,
            TableActions.DeleteManyBR,
            TableActions.MassiveSoftLegal
        ];

        this.paths.basePath = 'SoftLegal';
        this.paths.updatePath = () => '/api/SoftLegal/Update';
        this.displayedColumns = [
            'selectItems',
            'softLegal.isLinkWithDocuments',
            'url',
            'softLegal.successFee',
            'softLegal.status',
            'softLegal.instanceReceiverOrTTD',
            'softLegal.eventsNames',
            'contentCustomerExtData.commonNotes',
            //'customAction'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'softLegal.isLinkWithDocuments': {
                label: 'EMPTY',
                sort: false,
            },
            'url': {
                label: 'URL',
                sort: true,
                type: 'link',
                plainText: false
            },
            'softLegal.successFee': {
                format: () => this.enums.SuccessFeeStatus,
                label: 'SUCCESS_FEE',
                sort: false,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'softLegal.instanceReceiverOrTTD': {
                label: 'TTD_INSTANCE_RECEIVER',
                sort: false,
                plainText: true
            },
            'softLegal.status': {
                format: () => this.enums.InstanceLinkStatus,
                label: 'STATUS',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'contentCustomerExtData.commonNotes': {
                label: 'NOTES',
                sort: false,
                plainText: false,
                type: 'longText'
            },
            'softLegal.eventsNames': {
                label: 'EVENTS_NAMES',
                sort: false,
                plainText: false,
                type: 'longText'
            },
            'customAction': {
                label: 'WFT',
                sort: false,
                plainText: false,
                actionConfig: {
                    tooltip: 'ADD_WFT_ACQUISITION',
                    icon: 'play_circle_blue'
                }
            }
        };
        this.update = {
            action: () => {
                return 'link';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
        this.showFilters = false;
        this.pagination.enabled = true;
        this.pagination.showButtons = true;
    }

}