import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Recall, JsonReply } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/CustomerRecall')
export class CustomerRecallService {
    constructor(private httpClient: HttpClient) {

    }

    @Route('ForCustomer')
    public getRecalls(customerId: number, url: string = ''): Promise<JsonReply<Recall[]>> {
        return this.httpClient.post<JsonReply<Recall[]>>(url, customerId)
            .toPromise();
    }

    @Route('Add')
    public addRecall(customerRecall: Recall, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerRecall)
            .toPromise();
    }

    @Route('Update')
    public updateRecall(customerRecall: Recall, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerRecall)
            .toPromise();
    }

    @Route('Delete')
    public deleteRecall(recallId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, recallId)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteRecalls(recallIds: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, recallIds)
            .toPromise();
    }

    @Route('DeleteAll')
    public deleteFromContact(customerId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customerId)
            .toPromise();
    }
}
