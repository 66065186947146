import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';

import { Contact, 
    FindContactsParams, 
    JsonReply, 
    RequestPreAnalysisParams, 
    RequestPreAnalysisDateParams,
    CustomerDocument, 
    LinkLevelCountItem, 
    JsonReplyWithParams,
    ProjectStats, 
    CDashboard, 
    LinkContactsParams,
    CustomerGraphNodeData,
    LinkContactsParamsWithNumber} from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { Observable } from 'rxjs';


@Injectable()
@RoutePrefix('/api/Customer')
export class CustomerService {

    private httpClient: HttpClient;

    constructor(http: HttpClient) {
        this.httpClient = http;
    }

    @Route('ById')
    public getContactById(id: number, url: string = ''): Promise<JsonReply<Contact>> {
        return this.httpClient.post<JsonReply<Contact>>(url, id)
            .toPromise();
    }

    @Route('Create')
    public createContact(contact: Contact, url: string = ''): Promise<JsonReply<number>> {
        return this.httpClient.post<JsonReply<number>>(url, contact)
            .toPromise();
    }

    @Route('Update')
    public updateContact(contact: Contact, url: string = ''): Promise<JsonReply<Contact>> {
        return this.httpClient.post<JsonReply<Contact>>(url, contact)
            .toPromise();
    }

    @Route('Dashboard')
    public getDashboard(contactId: number, url: string = ''): Promise<JsonReply<CDashboard>> {
        return this.httpClient.post<JsonReply<CDashboard>>(url, contactId)
            .toPromise();
    }

    @Route('DeletePhoto')
    public deletePhoto(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }

    @Route('GetPhoto')
    public getPhoto(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }

    @Route('Find')
    public findContacts(contactParams: FindContactsParams, url: string = ''): Promise<JsonReplyWithParams<Contact[], FindContactsParams>> {
        return this.httpClient.post<JsonReplyWithParams<Contact[], FindContactsParams>>(url, contactParams)
            .toPromise();
    }

    @Route('FindRelated')
    public findRelatedContacts(contactParams: FindContactsParams, url: string = ''): Promise<JsonReplyWithParams<Contact[], FindContactsParams>> {
        return this.httpClient.post<JsonReplyWithParams<Contact[], FindContactsParams>>(url, contactParams)
            .toPromise();
    }

    @Route('RelationsForContact')
    public getContactRelationGraph(contactId: number, url: string = ''): Promise<JsonReply<CustomerGraphNodeData[]>> {
        return this.httpClient.post<JsonReply<CustomerGraphNodeData[]>>(url, contactId).toPromise();
    }

    @Route('LinkContacts')
    public linkContacts(params: LinkContactsParamsWithNumber, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, params).toPromise();
    }

    @Route('MakeExtension')
    public cloneContact(contactId: number, url: string = ''): Promise<JsonReply<number>> {
        return this.httpClient.post<JsonReply<number>>(url, contactId).toPromise();
    }

    @Route('Delete')
    public deleteContact(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteContacts(contactIds: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactIds)
            .toPromise();
    }

    @Route('PreAnalysisStatus')
    public setPreAnalysisStatus(requstParams: RequestPreAnalysisParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, requstParams)
            .toPromise();
    }

    @Route('UploadDocument')
    public uploadDocument(file: any, contactId: number, url: string = ''): Observable<HttpEvent<JsonReply<CustomerDocument>>> {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('ContactId', contactId.toString());

        return this.httpClient.post<JsonReply<any>>(url, file, { 'headers': headers, 'observe': 'events', 'reportProgress': true, responseType: 'json' });
    }

    @Route('AllStats')
    public getStats(contactId: number, url: string = ''): Promise<JsonReply<ProjectStats>> {
        return this.httpClient.post<JsonReply<ProjectStats>>(url, contactId)
            .toPromise();
    }

    @Route('CanAdd')
    public canAddContact(model: Contact, url: string = ''): Promise<JsonReply<string>> {
        return this.httpClient.post<JsonReply<string>>(url, model)        
            .toPromise();
    }

    @Route('UpdateWorkflowDate')
    public changePreAnalysisDate(requestParams: RequestPreAnalysisDateParams, url: string = ''): Promise<JsonReply<Contact>> {
        return this.httpClient.post<JsonReply<Contact>>(url, requestParams)
            .toPromise();
    }

    @Route('IsMyUniumXCustomer')
    public getIsMyUniumXCustomer(contactId: number, url: string = ''): Promise<JsonReply<boolean>> {
        return this.httpClient.post<JsonReply<boolean>>(url, contactId)
            .toPromise();
    }
}
