import { TableOptions, TableActions, GetContentParams, ContentModel, UpdateType } from '../models';

export class ContentTableOptions extends TableOptions {
    constructor() {
        super('Content', true);
        this.buttons = [TableActions.DeleteMany];
        this.params = new GetContentParams();
        this.newParams = new GetContentParams();
        this.paths.basePath = 'Content';
        this.paths.updatePath = () => this.config.RoutingUrls.CONTENT;
        this.displayedColumns = [
            'selectItems',
            'url',
            'domain.id',
            'fileName',
            'type',
            'contentCustomerExtData.possibleUses.0',
            'channelDomainName',
            'domain.insertDate',
            'onlineContentCount',
            'customAction'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'URL/FILE',
                sort: true,
                type: 'shortLink',
                plainText: false
            },
            'domain.id': {
                label: 'ID',
                sort: true,
                plainText: true
            },
            'fileName': {
                label: 'FILE_NAME/TITLE',
                sort: true,
                type: 'longText',
                plainText: false
            },
            'type': {
                format: () => this.enums.ContentType,
                label: 'TYPE',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'contentCustomerExtData.possibleUses.0': {
                format: () => this.enums.AssetStatus,
                label: 'STATUS',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'channelDomainName': {
                label: 'CHANNEL',
                sort: false,
                type: 'string',
                plainText: true
            },
            'domain.insertDate': {
                label: 'INSERT_DATE',
                sort: true,
                type: 'date',
                plainText: true
            },
            'onlineContentCount': {
                label: 'CONTENTS',
                sort: true,
                plainText: true
            },
            'customAction': {
                label: 'ADD_CONTENT',
                sort: false,
                actionConfig: {
                    tooltip: 'ADD_CONTENT',
                    icon: 'add_circle_blue'
                },
                plainText: false
            }
        };
        this.update = {
            action: (row: ContentModel) => {
                const status = row.url ? 'online' : 'offline';
                return { id: row.contentId, customerId: row.contactId, asset: status };
            },
            enabled: true,
            type: UpdateType.RouterLink,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'NAME',
                formName: 'as_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'fileName'
            },
            {
                name: 'TYPE',
                formName: 'as_type_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'assetType',
                enum: 'ContentType'
            },
            {
                name: 'STATUS',
                formName: 'as_status_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'status',
                enum: 'AssetStatus'
            },
        ];
    }

}



