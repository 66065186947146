import { ProjectLink } from './project/ProjectLink';
import { Instance } from './project/Instance';
import { TTDContact } from './TTDContact';
import { TTD } from './TTD';
import {CDashboard} from "./CDashboard";
import {Statistics} from "./Statistics";

const facebookLink = new ProjectLink();
facebookLink.url = 'https://uk-ua.facebook.com/';
const instagramLink = new ProjectLink();
instagramLink.url = 'https://www.instagram.com/';

export let customerLinksMock: ProjectLink[] = [facebookLink, instagramLink];

export let referentMock: TTDContact = new TTDContact();
referentMock.name = 'Kevin Systrom';
referentMock.email = 'kevin.systrom@inst.com';
referentMock.officePhone = '7896543210';
referentMock.mobilePhone = '7896543210';
referentMock.note = 'Referent notes';

export let ttdMock: TTD = new TTD();
ttdMock.instanceReceiver = 'https://www.instagram.com/';
ttdMock.name = 'Instagram';
ttdMock.firstAddress = 'https://www.instagram.com/';
ttdMock.secondAddress = 'https://www.instagram.com/';
ttdMock.ttdContacts = [referentMock];
ttdMock.note = 'TTD motes';

export let instanceMock: Instance = new Instance();
instanceMock.id = 1;
instanceMock.status = 1;
instanceMock.contactId = 34;
instanceMock.ttdName = ttdMock.name;
instanceMock.referentName = referentMock.name;
instanceMock.referentEmail = referentMock.email;


export let dashboardMock: CDashboard = new CDashboard();
dashboardMock.contactId = 34;
dashboardMock.customerName = 'Walter';
dashboardMock.customerSurname = 'White';
dashboardMock.contactName = 'Jessy';
dashboardMock.contactSurname = 'Pinkman';
dashboardMock.contactMobilePhone = '1233456678';
dashboardMock.contactEmailAddress = 'walter@white.com';
dashboardMock.companyName = 'KFC';
dashboardMock.contactStatus = 3;
dashboardMock.firstContactDate = new Date();
dashboardMock.lastRecallDate = new Date();
dashboardMock.caseType = 3;
dashboardMock.notes = 'lorem lorem lorem lorem lorem lorem';

dashboardMock.assignedAccount = null;
dashboardMock.assignedSales = null;

dashboardMock.requiredServices = [];

dashboardMock.statistics = new Statistics();

