// Core
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {Component, Input, OnInit} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import * as moment from 'moment';
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

const MONTH_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-rm-month-date-picker',
    templateUrl: './rm-month-date-picker.component.html',
    styleUrls: ['./rm-month-date-picker.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatDatepickerModule,
        ReactiveFormsModule,
    ],
})
export class RmMonthDatePickerComponent implements OnInit {
    private _max: Date;
    private _min: Date;

    public date: UntypedFormControl;

    @Input() selector: string;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() model: Date;
    @Input() propertyName: string;
    @Input()
    get max(): Date {
        return (!!this._max ? this._max : null);
    }
    set max(value: Date) {
        this._max = value;
    }

    @Input()
    get min(): Date {
        return (!!this._min ? this._min : null);
    }
    set min(value: Date) {
        this._min = value;
    }

    constructor() {
    }

    ngOnInit() {
        this.date = this.model[this.propertyName] ? new UntypedFormControl(moment(this.model[this.propertyName])) : new UntypedFormControl(null);
    }

    chosenYearHandler(normalizedYear: moment.Moment) {
        const ctrlValue = this.date.value ? this.date.value : new UntypedFormControl(moment(new Date())).value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(normlizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
        const ctrlValue = this.date.value ? this.date.value : new UntypedFormControl(moment(new Date())).value;
        ctrlValue.month(normlizedMonth.month());
        this.date.setValue(ctrlValue);
        ctrlValue.date(1);
        this.model[this.propertyName] = moment(ctrlValue).toDate();
        datepicker.close();
    }

}
