import { BaseParams } from "./BaseParams";

export class JsonReply<T> {
    public ok: boolean;
    public data: T;
    public errorMessage: string;
    public exception: any = null;
    public params: any = null;

    public static IsJsonReply(obj: Object): boolean {
        return obj && obj.hasOwnProperty('ok') && obj.hasOwnProperty('errorMessage');
    }
}

export class JsonReplyWithParams<T, TParams> extends JsonReply<T> {
    public params: TParams;
}
