export class SERPpreview {
    positiveLinksCount: number = 0;
    negativeLinksCount: number = 0;
    lowNegativeLinksCount: number = 0;
    unrelatedLinksCount: number = 0;
    neutralLinksCount: number = 0;
    controlled: number = 0;
    positioned: number = 0;
    aggregator: number = 0;
    videoCount: number = 0;
    imageCount: number = 0;
    newsCount: number = 0;
    sublinkCount: number = 0;
    totalLinksCategorized: number = 0;
    percentCategorized: number = 0;
    percentPositive: number = 0;
    percentUnrelated: number = 0;
    percentNegative: number = 0;
    percentLowNegative: number = 0;
    percentNeutral: number = 0;
    percentControlled: number = 0;
    percentPositioned: number = 0;
    percentAggregator: number = 0;
    percentVideo: number = 0;
    percentImage: number = 0;
    percentNews: number = 0;
    percentSubLink: number = 0;
}
