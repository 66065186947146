import { DomainsTableOptions } from './domains-table.options';
import { TableOptions, TableActions, TTD, GetTTDParams, UpdateType, SearchOrder } from '../models';

export class LinkedToTtdDomainsTableOptions extends DomainsTableOptions {
    constructor() {
        super();
        this.buttons = [TableActions.ExportRelatedDomains];
        this.paths.fetchPath = '/api/Domain/Linked';
        this.displayedColumns = [
            'url',
            'name',
            'domainCategoryName',
            'ttdName',
            'delete',
        ];
        this.formats = {
            'delete': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'URL',
                sort: false,
                plainText: true
            },
            'name': {
                label: 'NAME',
                sort: false,
                plainText: true
            },
            'domainCategoryName': {
                label: 'CATEGORY',
                sort: false,
                plainText: true
            },
            'ttdName': {
                label: 'TTD',
                sort: false,
                plainText: true
            },

        };
    }
}

