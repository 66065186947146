import { DomainUser } from "./DomainUser";

export class UsersCustomer {
    public customerId: number;
    public usersCustomer: UserModelAssociation[];

}

export class UserModelAssociation {
    public user: DomainUser;
    public isAssociated: boolean = true;
    public matrixIsActive: boolean = true;
    public timelineIsActive: boolean = true;
    public wipIsActive: boolean = true;
    public serpIsActive: boolean = true;
    public softLegalIsActive: boolean = true;
    public documentsIsActive: boolean = true;
    public serpIdsActive: number[] = [];
    public serpResults: number = 20;
}
