import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

import { CustomerReferent, JsonReply } from '../models';

@Injectable()
@RoutePrefix('/api/ContactReferent')
export class CustomerReferentService {

    constructor(private httpClient: HttpClient) { }

    @Route('ForContact')
    public getReferents(contactId: number, url: string = ''): Promise<JsonReply<CustomerReferent[]>> {
        return this.httpClient.post<JsonReply<CustomerReferent[]>>(url, contactId)
            .toPromise();
    }

    @Route('DeleteFromContact')
    public deleteFromContact(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }

    @Route('Update')
    public update(referents: CustomerReferent[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, referents)
            .toPromise();
    }

}