import { DomainType } from ".";

export class Domain {
    url: string; // DO NOT USE
    domain: string;
    name: string;
    supportedLanguages: string[];
    insertDate: Date;
    domainCategoryId: number;
    domainCategoryName: string;
    ttdId: number;
    ttdName: string;
    indexingEval: number;
    isDefaultChannel: boolean;
    managedByReputationManager: boolean;
    activityTarget: number;
    type: number;
    typeIsVideo: boolean;//UI ONLY
}
