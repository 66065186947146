import { TtdTableOptions } from './ttd-table.options';
import { TTDCustomer } from '../models/TTDCustomer';
import { UpdateType, Contact } from '../models';

export class LinkedToTtdCustomersTableOptions extends TtdTableOptions {
    customerId: any;

    constructor() {
        super();
        this.buttons = [];
        this.pagination.enabled = false;
        this.paths.fetchPath = 'api/Instance/SentForTTD';
        this.params = new TTDCustomer();
        this.showFilters = false;
        this.paths.updatePath = (contact: Contact) => ((!contact.customerId || contact.customerId == 0) ? this.config.RoutingUrls.CREATE_CONTACT : this.config.RoutingUrls.CUSTOMER_DETAILS);
        this.update = {
            action: (row: Contact) => {
                return { id: row.customerId };
            },
            enabled: true,
            type: UpdateType.RouterLink,
        };
        this.displayedColumns = [
            'name',
            'surname',
            'instanceSentCount',
            'contactCount'
        ];
        this.formats = {
            'name': {
                label: 'NAME',
                sort: false,
                plainText: true
            },
            'surname': {
                label: 'SURNAME',
                sort: false,
                plainText: true
            },
            'instanceSentCount': {
                label: 'SENT_INSTANCES',
                sort: false,
                plainText: true
            },
            'contactCount': {
                label: 'TODO_LINKS',
                sort: false,
                plainText: true
            },
        };
    }
}