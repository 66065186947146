import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, LinkedChannelParams, Channel } from "../models";

@Injectable()
@RoutePrefix('/api/Channel')
export class ChannelService {

    constructor(private httpClient: HttpClient) {

    }

    @Route('AvailableChannels')
    public getAvailableChannels(url: string = ''): Promise<JsonReply<Channel[]>> {
        return this.httpClient.get<JsonReply<Channel[]>>(url).toPromise();
    }

    @Route('ToggleForCustomer')
    public toggleForCustomer(channel: LinkedChannelParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, channel).toPromise();
    }

    @Route('ToggleDone')
    public toggleDone(channel: LinkedChannelParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, channel).toPromise();
    }
}
