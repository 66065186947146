import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomainUser, GetDomainUsersParams, JsonReply } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/DomainUser')
export class DomainUserService {

    private httpClient: HttpClient;

    constructor(http: HttpClient) {
        this.httpClient = http;
    }

    @Route('FromGroup')
    public getAvailableSales(url: string = ''): Promise<JsonReply<DomainUser[]>> {
        return this.httpClient.post<JsonReply<DomainUser[]>>(url, new GetDomainUsersParams('UniumX_Sales'))
            .toPromise();
    }

    @Route('FromGroup')
    public getAvailableAccounts(url: string = ''): Promise<JsonReply<DomainUser[]>> {
        return this.httpClient.post<JsonReply<DomainUser[]>>(url, new GetDomainUsersParams('UniumX_Accounts'))
            .toPromise();
    }

    @Route('FromGroup')
    public getAvailableUsers(url: string = ''): Promise<JsonReply<DomainUser[]>> {
        return this.httpClient.post<JsonReply<DomainUser[]>>(url, new GetDomainUsersParams('UniumX_Users'))
            .toPromise();
    }

    @Route('FromGroup')
    public getAvailableWriterUsers(url: string = ''): Promise<JsonReply<DomainUser[]>> {
        return this.httpClient.post<JsonReply<DomainUser[]>>(url, new GetDomainUsersParams('UniumX_Writers'))
            .toPromise();
    }

    @Route('FromGroup')
    public getAvailableLegalUsers(url: string = ''): Promise<JsonReply<DomainUser[]>> {
        return this.httpClient.post<JsonReply<DomainUser[]>>(url, new GetDomainUsersParams('UniumX_Legal'))
            .toPromise();
    }
}
