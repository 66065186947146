import { EventsTableOptions } from './events-table.options';

export class LinkedToLinkEventTableOptions extends EventsTableOptions {
    constructor() {
        super();
        this.buttons = [];
        this.paths.fetchPath = 'api/ContentEvent/Linked';
        this.displayedColumns = [
            'description',
            'startDate',
            'endDate',
        ];
    }
}
