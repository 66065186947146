import { TableOptions, TableActions, ContentModel, ContentParams, UpdateType, SearchOrder } from '../models';
import { OrderParams } from '../models/api/OrderParams';

export class ProjectContentTableOptions extends TableOptions {
    constructor() {
        super('Content', true);
        this.buttons = [
            TableActions.ExportContentsAll,
            TableActions.ExportDefaultMany,
            TableActions.DeleteManyBR,
            TableActions.MassiveContents
        ];
        this.params = new ContentParams();
        this.newParams = new ContentParams();
        const ordering = new OrderParams();
        ordering.order = SearchOrder.Reversed;
        ordering.orderPropertyName = 'contentCustomerExtData.insertDate';
        (this.params as ContentParams).ordering = ordering;
        this.newParams = new ContentParams();
        (this.newParams as ContentParams).ordering = ordering;
        this.paths.basePath = 'Content';
        this.paths.fetchPath = 'api/Content/Get';
        this.paths.updatePath = () => this.config.RoutingUrls.CONTENT;
        this.paths.exportAllPath = 'api/Content/ExportContents';
        this.paths.exportManyPath = 'api/Content/ExportContents';
        this.displayedColumns = [
            'selectItems',
            'url',
            'title',
            'preview',
            'publicationDate',
            'contentCustomerExtData.insertDate',
            'contentComponent'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'URL/FILE',
                sort: true,
                type: 'shortLink',
                plainText: false
            },
            'title': {
                label: 'TITLE',
                sort: true,
                type:'',
                plainText: true
            },
            'preview': {
                label: 'PREVIEW',
                sort: true,
                type: 'imaging',
                plainText: false
            },
            'author': {
                label: 'AUTHOR',
                sort: true,
                type: '',
                plainText: true
            },
            'publicationDate': {
                label: 'PUBLISHED',
                sort: true,
                type: 'date',
                plainText: true
            },
            'contentCustomerExtData.insertDate': {
                label: 'INSERT',
                sort: true,
                type: 'date',
                plainText: true
            },

            'commonNotes': {
                label: 'NOTES',
                sort: true,
                type: 'imaging',
                plainText: false
            },
            'typology': {
                format: () => this.enums.ContentTypology,
                label: 'CONTENT_TYPOLOGY',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'language': {
                format: () => this.enums.SERPLanguage,
                label: 'LANGUAGES',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'contentComponent': {
                label: 'EMPTY',
                sort: true,
                type: 'contentComponent'
            }
        };
        this.update = {
            action: (row: ContentModel) => {
                const status = row.isReport ? 'report' : row.url != '-' ? 'online' : 'offline';
                return { id: row.contentId, customerId: row.contactId, content: status };
            },
            enabled: true,
            type: UpdateType.RouterLink,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'KEYWORDS',
                formName: 'is_keyword_filter',
                type: 'chooseInput',
                class: 'col-12 col-md-6',
                param: 'keyword',
                choices: [
                    {
                        name: 'TITLE',
                        param: 'title',
                        value: true,
                    },
                    {
                        name: 'PREVIEW',
                        param: 'preview',
                        value: true
                    },
                    {
                        name: 'NOTES',
                        param: 'commonNotes',
                        value: true
                    },
                    {
                        name: 'TAG',
                        param: 'tags',
                        value: true
                    }
                ]
            },
            {
                name: 'URL',
                formName: 'as_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-6',
                param: 'url'
            },
            {
                name: 'FILENAME',
                formName: 'filename_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-6',
                param: 'fileName'
            },
            {
                name: 'EVENT_NAME',
                formName: 'event_name_rm_filter',
                type: 'text-input',
                class: 'col-12 col-md-6',
                param: 'eventName'
            },
            {
                name: 'PUBLICATION_DATE_FROM',
                formName: 'from_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'publicationDateFrom'
            },
            {
                name: 'PUBLICATION_DATE_TO',
                formName: 'to_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'publicationDateTo'
            },
            {
                name: 'INSERT_DATE_FROM',
                formName: 'fromInsert_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'insertDateFrom'
            },
            {
                name: 'INSERT_DATE_TO',
                formName: 'toInsert_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'insertDateTo'
            },
            {
                name: 'PERTINENCE',
                formName: 'pertinence_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'pertinence',
                enum: 'PertinenceFilter'
            },
            {
                name: 'EVALUATION',
                formName: 'eval_filter',
                type: 'multiSelect',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'evaluation',
                enum: 'ContentEvaluationFilter'
            },
            {
                name: 'IS_ONLINE',
                formName: 'is_online_filter',
                type: 'select',
                class: 'col-3',
                param: 'isOnLine',
                enum: 'NullIsOnline'
            },
            {
                name: 'SENDER',
                formName: 'sender_filter',
                type: 'select',
                class: 'col-3',
                param: 'sender',
                enum: 'SenderFilter'
            },
            {
                name: 'CONTENT_TYPOLOGY',
                formName: 'as_type_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'typology',
                enum: 'ContentTypology'
            },
            {
                name: 'POSSIBLE_USE',
                formName: 'as_possible_use_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'possibleUse',
                enum: 'PossibleUse'
            },
            {
                name: 'IS_USED',
                formName: 'is_used_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'isUsed',
                enum: 'NullIsUsed'
            },
            {
                name: 'MANAGED_BY',
                formName: 'as_managedBy_filter',
                type: 'select',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'managedBy',
                enum: 'ContentManagedBy'
            },
            {
                name: 'RM_PUBLISHED',
                formName: 'is_rm_pub_filter',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'rmPublished'
            },
            {
                name: 'DOMAIN_MANAGED_BY_REPUTATION-MANAGER',
                formName: 'is_managed_rm_filter',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'managedByReputationManager'
            },
            {
                name: 'WITHOUT_VALUES',
                formName: 'without_values_filter',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'withoutValues'
            },
            {
                name: 'POSITIONED',
                formName: 'positioned',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'positioned',
            },
            {
                name: 'PREMIUM',
                formName: 'premium',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'premium'
            },
            {
                name: 'INSERTBYUSERCUSTOMER',
                formName: 'insertByUserCustomer',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'insertByUserCustomer',
            },
            {
                name: 'CONTROLLED',
                formName: 'controlled',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'controlled',
            },
            {
                name: 'AGGREGATOR',
                formName: 'aggregator',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'aggregator',
            },
            {
                name: 'WIKIPEDIA_SOURCE',
                formName: 'wikipediaSource',
                type: 'tristatecheckbox',
                class: 'col-2 mb-3',
                param: 'wikipediaSource',
            },
            {
                name: 'null',
                formName: 'null',
                type: 'null',
                class: 'col-6',
                param: 'null'
            },
            {
                name: 'VALUE',
                formName: 'values_filter',
                type: 'valueChoosing',
                class: 'col-12',
                param: 'values',
            }            
        ];
    }

} 
