export class ContentBuild {
    contactId: number;
    contentId: number;
    status: number;
    taskManagerId: number; //ContentBuild generated from taskId
    lastUpdate: Date;
    userIdInsert: number;
    lastUserIdUpdate: number;
    tasks: any /*ProjectsTasksManagerModel*/;
    isUsed: boolean;
}