import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Project, JsonReply, ProjectStats, ProjectGoal } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import {MatrixValues} from '../models/valueMatrix/CustomerValueMatrix';
import {AssetCount} from "../models/asset/AssetCount";

@Injectable()
@RoutePrefix('/api/Project')
export class ProjectService {
    constructor(private httpClient: HttpClient) {}

    @Route('ById')
    public getCustomerById(id: number, url: string = ''): Promise<JsonReply<Project>> {
        return this.httpClient.post<JsonReply<Project>>(url, id)
            .toPromise();
    }

    @Route('Update')
    public updateCustomer(customer: Project, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, customer)
            .toPromise();
    }

    @Route('UpdateValueMatrix')
    public updateValueMatrix(matrix: MatrixValues, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, matrix).toPromise();
    }

    @Route('GetValueMatrix')
    public getValueMatrix(customerId: number, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, customerId).toPromise();
    }

    @Route('Timeline')
    public getTimeline(customerId: number, url: string = ''): Promise<JsonReply<AssetCount>> {
        return this.httpClient.post<JsonReply<AssetCount>>(url, customerId).toPromise();
    }

    @Route('GetAssetValueMatrix')
    public getAssetValueMatrix(customerId: number, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, customerId).toPromise();
    }

    @Route('UpdateAssetValueMatrix')
    public updateAssetValueMatrix(matrix: MatrixValues, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, matrix).toPromise();
    }

    @Route('AllStats')
    public getStats(customerId: number, url: string = ''): Promise<JsonReply<ProjectStats>> {
        return this.httpClient.post<JsonReply<ProjectStats>>(url, customerId).toPromise();
    }

    @Route('ProjectGoal')
    public saveGoal(projectGoal: ProjectGoal, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, projectGoal).toPromise();
    }
}
