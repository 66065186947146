<section class="row rm-paginator">
    <!-- SELECT ITEMS PER PAGE VALUE: Start-->
    <div class="d-flex">
        <div [ngClass]="{'d-none': !showButtons, 'd-md-block': showButtons}">
            <span>{{'PAGINATOR.ITEMS_PER_PAGE' | translate}}</span>
            <mat-form-field class="rm-paginator-select" *ngIf="params">
                <mat-select [(ngModel)]="params.pageSize" (selectionChange)="emitPageSize()" >
                    <mat-option *ngFor="let option of params.pageSizeOptions" [value]="option" class="no-pseudo-check">{{option}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- SELECT ITEMS PER PAGE VALUE: End -->
        <!-- CURRENT PAGE + TOTAL PAGES: Start -->
        <div class="rm-paginator-range mt-3 mr-2 mt-md-0 mr-md-3 ml-3" *ngIf="params">
            <span>{{params.firstItemNumber + '-' + params.lastItemNumber + ' ' + ('PAGINATOR.OF' | translate) + ' ' + params.totalItems}}</span>
        </div>
    </div>
    <!-- CURRENT PAGE + TOTAL PAGES: End -->
    <!-- CONTROLS: Start -->
    <div class="rm-paginator-controls" [ngClass]="{'d-none': !showButtons}">
        <button mat-icon-button
                [matTooltip]="'PAGINATOR.FIRST_PAGE' | translate"
                matTooltipPosition="above"
                #firstPageButton
                (click)="showFirstPage()">
            <img src="/app_assets/svg/material-icons/{{firstPageButton.disabled ? 'first_page_grey_light' : 'first_page_grey'}}.svg">
        </button>
        <button mat-icon-button
                [matTooltip]="'PAGINATOR.PREVIOUS_PAGE' | translate"
                matTooltipPosition="above"
                #prevButton
                (click)="showPreviousPage()">
            <img src="/app_assets/svg/material-icons/{{prevButton.disabled ? 'chevron_left_grey_light' : 'chevron_left_grey'}}.svg">
        </button>
        <button mat-icon-button
                [matTooltip]="'PAGINATOR.NEXT_PAGE' | translate"
                matTooltipPosition="above"
                #nextButton
                (click)="showNextPage()">
            <img src="/app_assets/svg/material-icons/{{nextButton.disabled ? 'chevron_right_grey_light': 'chevron_right_grey'}}.svg">
        </button>
        <button mat-icon-button
                [matTooltip]="'PAGINATOR.LAST_PAGE' | translate"
                matTooltipPosition="above"
                #lastPageButton
                (click)="showLastPage()">
            <img src="/app_assets/svg/material-icons/{{lastPageButton.disabled ? 'last_page_grey_light' : 'last_page_grey'}}.svg">
            </button>
    </div>
    <!-- CONTROLS: End-->
</section>