import { Contact, SearchOrder, TableActions, TableOptions, UpdateType } from '../models';
import { FindContactsParams } from '../models/api/FindContactsParams';

export class FindContactsTableOptions extends TableOptions {

    constructor() {
        super('Customer', false);
        this.buttons = [
            TableActions.ContactsGenericReportAll,
            TableActions.ContactsGenericReportMany,
            TableActions.GetApiToken,
            //TableActions.SalesReportAll,
            //TableActions.SalesReportMany,
            TableActions.LegalReportAll,
            TableActions.LegalReportMany,
            TableActions.DeleteMany,
            TableActions.EditMany
        ];
        this.displayedColumns = [
            'selectItems',
            'name',
            'surname',
            'caseType',
            'preAnalysisStatus',
            'contactStatus',
            'salesContractEndDate',
            'workStartDate',
            'recallDate',
            'myUniumx'
        ];

        this.params = new FindContactsParams();
        this.params.ordering.order = SearchOrder.Reversed;
        this.params.ordering.orderPropertyName = 'firstContactDate';
        this.newParams = new FindContactsParams();
        this.paths.basePath = 'Contact';
        this.paths.fetchPath = this.config.ApiUrls.GET_CONTACTS_DATA;
        this.paths.updatePath = (contact: Contact) => (
            (this.isCustomer(contact))
                ? this.config.RoutingUrls.CREATE_CONTACT
                : this.config.RoutingUrls.CUSTOMER_DETAILS);
        this.exportName = 'contacts';
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'name': {
                label: 'NAME',
                sort: true,
                plainText: true
            },
            'surname': {
                label: 'SURNAME',
                sort: true,
                plainText: true
            },
            'caseType': {
                format: () => this.enums.CaseType,
                label: 'CASE_TYPE',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'contactStatus': {
                format: () => this.enums.ContactStatus,
                label: 'RELATIONSHIP',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'preAnalysisStatus': {
                format: () => this.enums.PreAnalysisStatus,
                label: 'WORKFLOW',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'recallType': {
                enum: () => this.enums.RecallType,
                label: 'TYPE',
                sort: false,
                plainText: false
            },
            'salesContractEndDate': {
                format: () => 'DD/MM/YY, hh:mm',
                label: 'CONTRACT_END_DATE',
                sort: true,
                type: 'date',
                plainText: true,
            },
            'workStartDate': {
                format: () => 'DD/MM/YY, hh:mm',
                label: 'WORK_START',
                sort: true,
                type: 'date',
                plainText: true,
            },
            'recallDate': {
                format: () => 'DD/MM/YY, hh:mm',
                label: 'LAST_RECALL',
                sort: true,
                type: 'date',
                plainText: true,
                enum: () => this.enums.RecallElapsedStatus
            },
            'myUniumx': {
                label: 'EMPTY',
                sort: false,
                plainText: false
            }
        };
        this.update = {
            action: (row: Contact) => {
                return { id: row.id };
            },
            enabled: true,
            type: UpdateType.RouterLink,
        };
        this.pagination = {
            enabled: true,
            showButtons: true
        };
    }

    isCustomer(contact) {
        //toDO: condizionare in funzione dei ruoli in utente
        let ret: boolean;

        contact.preAnalysisStatus != 'ENUMS.PREANALYSIS_STATUS.ACTIVITY_START' && contact.preAnalysisStatus != 'ENUMS.PREANALYSIS_STATUS.ACTIVITY_END' ? ret = true : ret = false;

        return ret;
    }

}



