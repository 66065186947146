import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Enums, InjuryLevel, InstanceLinkStatus, JsonReply, SoftLegal, SoftLegalLinkStatus, SoftLegalTab, SuccessFeeStatus } from '../../../../instruments/models';
import { AlertService, NotificationService, SoftLegalService } from '../../../../instruments/services';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { TriStateCheckboxComponent } from '../../../../components/tri-state-checkbox/tri-state-checkbox.component';

export class SoftLegalMassiveParams {
    backInTODO: boolean = false;
    webFormRequest: boolean = null;
    webFormResponse: number = null;
    authorityRequest: boolean = null;
    authorityResponse: boolean = null;
    googleRequest: boolean = null;
    googleResponse: boolean = null;
    yahooRequest: boolean = null;
    yahooResponse: boolean = null;
    bingRequest: boolean = null;
    bingResponse: boolean = null;
    instanceResponse: InstanceLinkStatus = null;
    status: SoftLegalLinkStatus = null;
    workability: InjuryLevel = null;
    successFee: SuccessFeeStatus = null;
    ids: { customerId: number, contentId: number }[] = [];

    constructor(_ids: { customerId: number, contentId: number }[]) {
        this.ids = _ids;
    }
}

@Component({
    selector: 'soft-legal-massive',
    templateUrl: './soft-legal-massive.component.html',
    styles: ['span { font-family: "Inter", sans-serif;} .toBeSavedUnderline::after{ content: ""; position: absolute; box-shadow: 0px 15px 9px 2px var(--accent) !important; bottom: 8px; left: 0; right: 0;};'],
    standalone: true,
    imports: [TriStateCheckboxComponent, FormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, NgIf, NgClass, MatDialogModule, MatButtonModule, MatTooltipModule, TranslateModule]
})
export class SoftLegalMassiveComponent implements OnInit {
    @Input() data: any
    @Input() tab: SoftLegalTab;
    @Output() ok: EventEmitter<any> = new EventEmitter<any>();
    @Output() alert: EventEmitter<string> = new EventEmitter<string>();

    enums: Enums = new Enums();
    SoftLegalTab = SoftLegalTab;
    params: SoftLegalMassiveParams;
    toBeSaved: boolean = false;
    toBeSavedStatus: boolean = false;
    isResponseChanged: boolean = false;
    isStatusChanged: boolean = false;

    _ids: { customerId: number, contentId: number }[] = [];
    _withSharedLink: boolean = false;

    constructor(private httpClient: HttpClient,
        private alertService: AlertService,
        private snackbar: NotificationService,
        private softLegalService: SoftLegalService) { }

    ngOnInit() {
        this.data.link.map(l => {
            if (l.contentWidget.othersSoftLegalCustomers != null)
                this._withSharedLink = true;

            this._ids.push({
                customerId: l.softLegal.contactId,
                contentId: l.softLegal.contentId
            })
        });
        this.params = new SoftLegalMassiveParams(this._ids)
    }

    public onActionResponseChange(event: MatSelectChange, isStatus, checkbox: string) {
        if (event.value != null) {
            this.toBeSaved = true;
            this.toBeSavedStatus = true;
            if (isStatus) {
                this.isStatusChanged = true;
                this.toBeSavedStatus = false;
            }
            else
                this.isResponseChanged = true;

            if (checkbox)
                this.params[checkbox] = true;
        }
    }

    public onReset() {
        this.toBeSaved = false;
        this.toBeSavedStatus = false;
        this.isStatusChanged = false;
        this.isResponseChanged = false;
        this.params = new SoftLegalMassiveParams(this._ids);
    }

    public onSave() {
        if (this._withSharedLink) {
            const msg = `Attenzione, alcuni link selezionati sono condivisi con altri clienti, procedere ugualmente?`;
            this.alertService.prompt(msg).subscribe(res => {
                if (res === 'ok') {
                    this.checkMessageAlert();
                }
            })      
        } else {
            this.checkMessageAlert();
        }
    }

    private checkMessageAlert() {
        const status = this.params.status;

        //casistica 1 - modificate azioni e status link non modificato
        if (this.isResponseChanged && !this.isStatusChanged) {
            const msg = "Attenzione, e' stato modificato un risultato di un'azione e non lo status del link, procedere ugualmente?";
            this.alertService.prompt(msg).subscribe(res => {
                if (res === 'ok') {
                    this.save();
                }
            })
        }
        else {
            this.save();
        }
    }

    private save() {
        const status = this.params.status;
        let save = this.softLegalService.CheckForStatusChanged(status, this.params);
        if (!save) {
            const msg = "Attenzione! E' necessario indicare un risultato di un'azione uguale allo status prima di procedere al salvataggio";
            this.alertService.error(msg).subscribe(res => { })
            return;
        }
        this.httpClient.post<JsonReply<any>>('/api/SoftLegal/MassiveUpdate', this.params).subscribe({
            next: () => this.ok.emit(),
            error: () => this.snackbar.error()
        });
    }

    public onReturnToDo() {
        const msg = `Sicuro di voler procedere per i ${this.params.ids.length} link selezionati? In questo modo le informazioni selezionate verranno perse.`;
        this.alertService.prompt(msg).subscribe(res => {  
            if (res === 'ok') {
                this.params = new SoftLegalMassiveParams(this._ids);
                this.params.backInTODO = true;
                this.save();
            }
        })        
    }

}
