import { RichEnum } from "../RichEnum";
import { BaseParamsWithContactId } from "./BaseParamsWithContactId";

export class GetContentParams extends BaseParamsWithContactId {
    constructor(
        public url: string = null,
        public fileName: string = null,
        public possibleUse: number = null,
        public content_Type: string = null,
        public author: string = null,
        public requestDate: Date = null,
        public deadline: Date = null,
        public status: number = RichEnum.Any,
        public sender: number = null
    ) {
        super();
    }
}

export class GetContentLightParams extends BaseParamsWithContactId {
    constructor(
        public url: string = null,
        public fileName: string = null,
        public possibleUse: number = RichEnum.Any,
        public contentType: number = RichEnum.Any,
        public author: string = null,
        public requestDate: Date = null,
        public deadline: Date = null,
        public status: number = RichEnum.Any,
        public sender: number = null
    ) {
        super();
    }
}