import { TableOptions, TableActions, BaseParams, SearchOrder, UpdateType } from '../models';
import { OrderParams } from '../models/api/OrderParams';

export class ContactRecallTableOptions extends TableOptions {
    constructor() {
        super('ContactRecall', false);
        this.buttons = [TableActions.DeleteMany];
        this.paths.basePath = 'ContactRecall';
        this.displayedColumns = [
            'selectItems',
            'recallDate',
            'recallResult',
            'sales.fullName',
            'succeeded'
            //'recallType'
        ];
        this.params = new BaseParams();
        const ordering = new OrderParams();
        ordering.order = SearchOrder.Straight;
        ordering.orderPropertyName = 'recallDate';
        (this.params as BaseParams).ordering = ordering;
        this.newParams = new BaseParams();
        (this.newParams as BaseParams).ordering = ordering;

        //se ci sono paramentri salvati nel localstorage li recupero
        var paramStorage = localStorage.getItem('Recall')
        this.params = paramStorage ? JSON.parse(paramStorage) : this.params;

        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'recallDate': {
                label: 'DATE',
                sort: true,
                type: 'dateTime',
                plainText: true
            },
            'sales.fullName': {
                label: 'USER',
                sort: false,
                type: 'domainUser',
                plainText: true
            },
            'recallResult': {
                label: 'NOTES',
                sort: true,
                type: 'longText',
                plainText: false
            },
            'succeeded': {
                label: 'SUCCESS',
                sort: true,
                type: 'boolean',
                plainText: false
            },
            'recallType': {
                enum: () => this.enums.RecallType,
                label: 'TYPE',
                sort: false,
                plainText: false
            }
        };
        this.update = {
            action: () => {
                return 'contactRecall';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
    }

}



