// Core
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Instruments
import { SharedService } from '../../instruments/services/shared.service';

//models
import {
    JsonReply,
    MatrixMacroCategory,
    MatrixSubValues,
    MatrixValues
} from '../../instruments/models';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-select-values-only-macro',
    styleUrls: ['./select-values-only-macro.component.css'],
    templateUrl: './select-values-only-macro.component.html',
    standalone: true,
    imports: [
        FormsModule,
        NgFor,
        NgIf,
        NgStyle,
        MatCheckboxModule,
        MatButtonModule,
    ],
})
export class SelectValuesOnlyMacroComponent implements OnInit {

    @Input() initMacroCategories: MatrixMacroCategory[];
    @Input() localStorageParams: [number];
    @Input() isAllTemplate: boolean = false;
    macroCategories: MatrixMacroCategory[];
    showMicro = false;
    hasMicro = false;
    
    public colorsDataset: { [macroCatId: number]: {color: string, style: string}} = {
        1: {
            color: '#345f8c',
            style: 'color-blue',
        },
        2: {
            color: '#29a856',
            style: 'color-green',
        },
        3: {
            color: '#eb128a',
            style: 'color-pink',
        },
        4: {
            color: '#8b4694',
            style: 'color-violet',
        },
        5: {
            color: '#f18318',
            style: 'color-orange',
        },
        6: {
            color: '#2bb5b1',
            style: 'color-teal',
        },
        531: {
            color: '#2c4b71',
            style: 'color-blue',
        },
        537: {
            color: '#02ba56',
            style: 'color-green',
        },
        549: {
            color: '#ff026d',
            style: 'color-pink',
        },
        555: {
            color: '#7734a9',
            style: 'color-violet',
        },
        561: {
            color: '#f18318',
            style: 'color-orange',
        },
        566: {
            color: '#02bafd',
            style: 'color-teal',
        },
        138: {
            color: '#2c4b71',
            style: 'color-blue',
        },
        144: {
            color: '#02ba56',
            style: 'color-green',
        },
        156: {
            color: '#ff026d',
            style: 'color-pink',
        },
        162: {
            color: '#7734a9',
            style: 'color-violet',
        },
        168: {
            color: '#f18318',
            style: 'color-orange',
        },
        173: {
            color: '#02bafd',
            style: 'color-teal',
        }
    };

    constructor(private shared: SharedService,
        private httpClient: HttpClient) { }

    ngOnInit() {
        //se ci sono parametri dal localstorage gli recupero e valorizzo le relative microcategory
        if (this.localStorageParams && this.initMacroCategories) {
            for (let index = 0; index < this.localStorageParams.length; index++) {
                this.initMacroCategories.forEach((macro) => {
                    macro.subCategories.forEach((micro) => {
                        this.hasMicro = true;

                        if (micro.id == this.localStorageParams[index]) {
                            micro.value = 1;
                        }
                    })

                })
            }
        }
        
        if (this.initMacroCategories) {
            this.clearValues();
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    }

    public microChange(subCat, macroIndex, macrocategory) {
        if (!subCat.value) {
            subCat.value = 1;
            this.macroCategories[macroIndex].value = 1;
        } else {
            subCat.value = null;

            let atLeastOne = false;
            macrocategory.subCategories.map(sub => {
                if (sub.value) {
                    atLeastOne = true;
                }
            });
            macrocategory.value = atLeastOne ? 1 : null;
        }
    }

    public showMacro(event, i) {
        if (event.checked) {
            this.macroCategories[i].subCategories.map(micro => {
                micro.value = 1;
            })

            this.macroCategories[i].value = 1;
        } else {
            //non mandare se e deselezionata
            this.macroCategories[i].subCategories.map(micro => {
                micro.value = null;
            })

            this.macroCategories[i].value = null;
        }
    }
    
    private clearValues() {
        let macroAr = [];

        this.initMacroCategories.map((macro, i) => {
            macro.subCategories.map(micro => {
                this.hasMicro = true;
                if (micro.value) {
                    macro.value = 1;
                }
            })

            macroAr.push(macro);

            if (i == 5 || i == 566 || i == 173) {
                this.macroCategories = macroAr;
            }
        })
    }

    public showMicroCat(value) {
        this.showMicro = value;
    }

    filterSub(macrocategory: MatrixMacroCategory, status: boolean){
        return macrocategory.subCategories.filter(s => s.categoryActive === status)
    }

}
