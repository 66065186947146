import {AfterViewInit, Directive, Input} from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: 'mat-select [withDefault] [multiple]',
    standalone: true
})
export class MultiselectWithDefaultDirective implements AfterViewInit {
    @Input() withDefault: any;

    constructor(private select: MatSelect) {

    }

    ngAfterViewInit() {
        this.select.options.forEach((option: any) => option._element.nativeElement.onclick = () => this.handleOptionClick(option.value));
    }

    public handleOptionClick(value: any) {
        if (value !== this.withDefault) {
             const defaultOption = this.select.options.find(option => option.value === this.withDefault);
             if (defaultOption) {
                 defaultOption.deselect();
             }
        } else {
            this.select.options.forEach(option => {
                if (option.value !== this.withDefault) {
                    option.deselect();
                }
            });
        }
    }
}