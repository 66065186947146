import { OrderParams } from './OrderParams';

export class BaseParams {
    public _save_token_: string = null;

    public page: number;
    public totalPages: number;
    public pageSize: number;
    public pageSizeOptions: number[];
    public totalItems: number;
    public ordering: OrderParams;
    public firstItemNumber: number;
    public lastItemNumber: number;
    public contactId: number;
    public entityId: number;
    public variationResultId: number;


    constructor() {
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 50];
        this.totalPages = 1;
        this.totalItems = 0;
        this.ordering = new OrderParams();
        this.firstItemNumber = 1;
        this.lastItemNumber = 1;
        this.contactId = 0;
        this.entityId = 0;
    }

    //public getSaveToken(): string {
    //    this._save_token_ = 'BaseParams';
    //    return this._save_token_;
    //}

    //public removeSaveToken(): void {
    //    this._save_token_ = null;
    //}
}
