import { TableOptions, TableActions, GetDomainParams, UpdateType } from '../models';
import { Config } from './../../app.constants';

export class DomainsTableOptions extends TableOptions {
    constructor() {
        super('Domain', true);
        this.buttons = [
            TableActions.DeleteMany,
        ];
        this.paths.fetchPath = Config.ApiUrls.FETCH_DOMAINS;
        this.params = new GetDomainParams();
        this.newParams = new GetDomainParams();
        this.displayedColumns = [
            'selectItems',
            'url',
            'name',
            'domainCategoryName',  
            'ttdNameLinked',
            'isDefaultChannel',
            'managedByReputationManager',
            'supportedLanguages'
        ];
        this.formats = {
            'selectItems': {
                label: 'URL',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'URL',
                sort: true,
                type: 'domainLink',
                plainText: false
            },
            'name': {
                label: 'NAME',
                sort: true,
                plainText: true
            },
            'domainCategoryName': {
                label: 'CATEGORY',
                sort: false,
                plainText: true
            },   
            'ttdNameLinked': {
                label: 'TTD',
                sort: false,
                plainText: true
            },
            'isDefaultChannel': {
                label: 'IS_DEFAULT_CHANNEL',
                type: 'boolean',
                sort: true,
                plainText: false
            },
            'managedByReputationManager': {
                label: 'MANAGED_BY_REPUTATION-MANAGER',
                sort: true,
                type: 'boolean',
                plainText: false
            },
            'supportedLanguages': {
                label: 'SUPPORTED_LANGUAGES',
                sort: false,
                plainText: true
            },
        };
        this.update = {
            action: () => {
                return 'domain';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'URL',
                formName: 'domain_url_filter',
                type: 'text-input',
                class: 'col-5',
                param: 'url'
            },
            {
                name: 'NAME',
                formName: 'domain_name_filter',
                type: 'text-input',
                class: 'col-4',
                param: 'name',
            }
        ];
        this.noContactId = true;
    }

}



