import { Component, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { MaterialModule } from '../../instruments/material.module';

@Component({
  selector: 'rm-datetime-picker',
  standalone: true,
  imports: [OwlDateTimeModule, OwlNativeDateTimeModule, FormsModule, MatDatepickerModule, MaterialModule],
  templateUrl: './rm-datetime-picker.component.html',
  styleUrl: './rm-datetime-picker.component.css'
})
export class RmDatetimePickerComponent implements OnInit {
    @Input() model: Date = new Date();
    @Input() name: string = 'rm_time_picker';
    @Input() placeHolder: string = 'Data e ora';
    @Output() emitter: EventEmitter<Date> = new EventEmitter<Date>()
    isOpen: boolean = false;
    elementRef = inject(ElementRef)
    modelToString: string = '';
    

    ngOnInit() {
        this.model = new Date(this.model);
        this.modelToString = this.toString(this.model);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: PointerEvent) {
        const nativeElement: any = this.elementRef.nativeElement;
        const clickedInside: boolean = nativeElement.contains(event.target);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }

    onChange(date: Date) {
        this.modelToString = this.toString(date);
        this.emitter.emit(date);
    }

    private toString(date: Date | string): string {
        date = new Date(date);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    }
}
