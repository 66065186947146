import { CustomerServiceModel } from './customer/CustomerServiceModel';
import { DomainUser } from './DomainUser';
import { Statistics } from './Statistics';
import { ChannelIcon } from './channel/ChannelIcon';

export class CDashboard {
    isCustomer: boolean;
    contactId: number;
    customerName: string;
    customerSurname: string;
    contactName: string;
    contactSurname: string;
    contactMobilePhone: string;
    contactEmailAddress: string;
    companyName: string;
    contactStatus: number;
    firstContactDate: Date;
    lastRecallDate: Date;
    contractDate: Date;
    contractEndDate: Date;
    caseType: number;
    notes: string;
    workStartDate: Date;

    assignedAccount: DomainUser;
    assignedSales: DomainUser;

    requiredServices: CustomerServiceModel[];

    statistics: Statistics;

    channelIcons: ChannelIcon[];

    constructor() {
        const now = new Date();

        this.contactId = 0;
        this.customerName = '';
        this.customerSurname = '';
        this.contactName = '';
        this.contactSurname = '';
        this.contactMobilePhone = '';
        this.contactEmailAddress = '';
        this.companyName = '';
        this.contactStatus = 0;
        this.firstContactDate = now;
        this.lastRecallDate = now;
        this.caseType = 0;
        this.notes = '';
        this.workStartDate = null

        this.assignedAccount = DomainUser.Default;
        this.assignedSales = DomainUser.Default;

        this.requiredServices = null;

        this.statistics = new Statistics();
        this.channelIcons = [] as ChannelIcon[];
    }
}
