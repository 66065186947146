<div class="position-relative">
    <mat-form-field class="col-12 p-0" (click)="isOpen = true;">
        <mat-label>{{placeHolder}}</mat-label>
        <input [name]="name"
               [(ngModel)]="modelToString"
               matInput
               readonly
               required>
        <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
    </mat-form-field>

    @if(isOpen){
        <owl-date-time-inline style="position: fixed; top: calc(45% - 75px); left: 47%; z-index: 2;" [(ngModel)]="model" (ngModelChange)="onChange($event)" />
    }
</div>
