import { SearchOrder } from "../Enums";

export class OrderParams {
    public orderPropertyName: string = null;
    public order: number;

    constructor() {
        this.order = SearchOrder.NoOrder;
    }
}
