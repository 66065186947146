// Core
import { Injectable } from "@angular/core";
import {Observable} from "rxjs/index";

// Components
import { MatSnackBar, MatSnackBarDismiss } from "@angular/material/snack-bar";
import {NotificationComponent} from "./notification.component";
import {DisconnectData, DoneData, ErrorData, NotificationData, ProgressData, WarningData} from "./NotificationData";

@Injectable()
export class NotificationService {

    constructor ( private snackBar: MatSnackBar) { }

    open(data: NotificationData): Observable<MatSnackBarDismiss> {
        const snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
            data: data
        });
        return snackBarRef.afterDismissed();
    }

    done(title: string = 'Done!', translateParams: any = null): Observable<MatSnackBarDismiss> {
        return this.open(new DoneData(title, translateParams));
    }

    warning(title: string = 'Done!', translateParams: any = null): any {
        return this.open(new WarningData(title, translateParams));
    }

    progress(title: string): Observable<MatSnackBarDismiss> {
        return this.open(new ProgressData(title));
    }

    error(title: string = 'Error!'): Observable<MatSnackBarDismiss> {
        return this.open(new ErrorData(title));
    }

    disconnect(title: string = 'No internet connection'): Observable<MatSnackBarDismiss> {
        return this.open(new DisconnectData(title));
    }

}
