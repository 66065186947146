import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, JsonReplyWithParams, GetTTDParams, TTD } from "../models";

@Injectable()
@RoutePrefix('/api/TTD')
export class TTDService {
    constructor(private httpClient: HttpClient) { }

    @Route('Find')
    public find(param: GetTTDParams, url: string = ''): Promise<JsonReply<TTD[]>> {
        return this.httpClient.post<JsonReply<TTD[]>>(url, param).toPromise();
    }

    @Route('ById')
    public getById(id: number, url: string = ''): Promise<JsonReply<TTD>> {
        return this.httpClient.post<JsonReply<TTD>>(url, id).toPromise();
    }

    @Route('Add')
    public add(ttd: TTD, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ttd).toPromise();
    }

    @Route('Update')
    public update(ttd: TTD, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ttd).toPromise();
    }

    @Route('Delete')
    public delete(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('DeleteMany')
    public deleteMany(ids: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ids).toPromise();
    }
}