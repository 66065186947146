export class WorkingTeam {
    teamUserIds: string[];
    entityId: number;

    constructor() {
        this.teamUserIds = [];
        this.entityId = 0;
    }
}

export class CustomerWorkingTeam {

    customerId: number;

    writers: string[];
    legals: string[];
    projectManagers: string[];

    constructor() {
        this.customerId = 0;

        this.writers = [];
        this.legals = [];
        this.projectManagers = [];
    }
}
