import { TableOptions } from "../models/table/TableOptions";
import { Config } from './../../app.constants';
import { TableActions } from "../models/table/TableAction";
import { BaseParams } from "../models/api/BaseParams";

export class SERPLinksTableOptions extends TableOptions {
    constructor() {
        super('SERP/Link', true);
        this.buttons = [
            TableActions.ExportSERPLinksAll
        ];
        this.paths.fetchPath = Config.ApiUrls.FETCH_SERP_LINKS;
        this.paths.exportAllPath = Config.ApiUrls.EXPORT_ALL_LINKS;
        //this.paths.updatePath = () => this.config.RoutingUrls.TTD_DETAILS;
        //this.paths.exportAllPath = 'api/TTD/ExportAll';
        this.params = new BaseParams();
        //this.params.ordering.order = SearchOrder.Reversed;
        this.newParams = new BaseParams();
        this.displayedColumns = [
            'serpContentInfo.position',
            'serpContentInfo.variation',
            'title',
            'url',
            'customAction',
            'contentComponent'
        ];
        this.pagination = {
            enabled: false,
            showButtons: false
        };
        this.formats = {
            'serpContentInfo.position': {
                label: 'POSITION',
                sort: false,
                plainText: true
            },
            'serpContentInfo.variation': {
                label: 'VARIATION',
                sort: false,
                plainText: true
            },
            'title': {
                label: 'TITLE',
                sort: false,
                plainText: false,
                type: 'longText'
            },
            'url': {
                label: 'LINK',
                sort: false,
                plainText: false,
                type: 'link'
            },
            'customAction': {
                label: 'EMPTY',
                sort: false,
                actionConfig: {
                    tooltip: 'LINK_POSITION_TREND',
                    icon: 'timeline_blue'
                },
                plainText: false
            },
            'contentComponent': {
                label: 'EMPTY',
                sort: true,
                type: 'contentComponent'
            }
        };
        this.noContactId = true;
    }
}