import { TableOptions, TableActions, ContentModel, ContentParams, UpdateType, SearchOrder } from '../models';
import { OrderParams } from '../models/api/OrderParams';

export class ProjectContentReportsTableOptions extends TableOptions {
    constructor() {
        super('Content', true);
        this.buttons = [
            TableActions.DeleteManyContactDocument,
        ];
        this.params = new ContentParams();
        this.newParams = new ContentParams();
        const ordering = new OrderParams();
        ordering.order = SearchOrder.Reversed;
        ordering.orderPropertyName = 'insertDate';
        (this.params as ContentParams).ordering = ordering;
        this.newParams = new ContentParams();
        (this.newParams as ContentParams).ordering = ordering;
        this.paths.basePath = 'Content';
        this.paths.fetchPath = 'api/ContactDocument/MyUniumXDocuments';

        this.displayedColumns = [
            'selectItems',
            'fileNameDownload',
            'insertDate',
            'insertUser.fullName',
            'download',
            'shareReport'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'download': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'shareReport': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'fileNameDownload': {
                label: 'TITLE',
                sort: true,
                type: '',
                plainText: true
            },
            'insertUser.fullName': {
                label: 'AUTHOR_INSERT',
                sort: false,
                type: '',
                plainText: true
            },
            'insertDate': {
                label: 'INSERT_DATE',
                sort: true,
                type: 'date',
                plainText: true
            }
        };
        this.update = {
            enabled: false,

        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'FILENAME',
                formName: 'filename_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'fileName'
            },
            {
                name: 'AUTHOR_INSERT',
                formName: 'author_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'author'
            },

            {
                name: 'INSERT_DATE_FROM',
                formName: 'fromInsert_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'insertDateFrom'
            },
            {
                name: 'INSERT_DATE_TO',
                formName: 'toInsert_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'insertDateTo'
            },
        ];
    }

} 