export class ContentCustomerExtData {
    public contactId: number;
    public contentId: number;
    public uniumId: string;
    public commonNotes: string;
    public sERPKind: number;
    public insertDate: Date;
    public wftDate: Date;
    public buildStatus: number;
    public sender: number;
    public possibleUses: number[] = [];
    public evaluation: number;
    public evaluationUI: number;
    public pertinence: number;
    public pertinenceUI: number;
    public rank: number;
    public premium: boolean;
    public positioned: boolean;
    public rScore: number;
    public controlled: boolean;
    public aggregator: boolean;
    public wikipediaSource: boolean;
}
