import { Component, Input } from '@angular/core';
import { Enums } from '../../../instruments/models';
import { softLegalStatusInfo } from '../../../instruments/models/content/contentWidget';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'widget-share-status',
    template: `
    <div *ngIf="data">
        <div class="row align-items-center" [ngClass]="{'mt-4': isFirst, 'mb-4': isLast}" *ngFor="let d of data.othersSoftLegalCustomers; first as isFirst; last as isLast;">
            <div class="col-12">
                <p>
                    <span *ngIf="data.lastStatusUpdateExtCustomer && d.id == data.lastStatusUpdateExtCustomer.id">Ultima modifica di: </span>
                    <a [href]="'/project;id=' + d.id" target="_blank"><strong>{{d.name}} {{d.surname}} </strong></a>
                    <span>con status: "<strong>{{enums.SoftLegalLinkStatus[d.status].name | translate}}</strong>"</span>
                </p>
            </div>
        </div>
    </div>
`,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, TranslateModule]
})
export class WidgetShareStatusComponent {
    @Input() data: softLegalStatusInfo
    enums: Enums = new Enums();

    constructor() { }
}
