<!-- FILTERS: Start -->
<div class="pr-4 pl-4 rm-divider" *ngIf="options.showFilters">
    <form class="row justify-content-lg-between filter-form pt-2 mt-3 mb-3 mb-lg-0">
        <div *ngFor="let filter of options.filters; let i = index" [class]="filter.class">
            <!-- TEXT INPUT TEMPLATE: Start -->
            <mat-form-field class="w-100" *ngIf="filter.type === 'text-input'">
                <mat-label>{{'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <input [name]="filter.formName"
                       [(ngModel)]="options.params[filter.param]"
                       (keydown.enter)="fetchData()"
                       matInput>
                <button *ngIf="options.params[filter.param]" matSuffix mat-icon-button aria-label="Clear" type="button" (click)="options.params[filter.param]=''">
                    <img class="clear-input-btn" src="/app_assets/svg/material-icons/close_grey.svg">
                </button>
            </mat-form-field>
            <!-- TEXT INPUT TEMPLATE: End -->
            <!-- DATEPICKER TEMPLATE: Start -->
            <mat-form-field class="w-100" *ngIf="filter.type === 'datepicker'">
                <mat-label>{{'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <input [name]="filter.formName"
                       [(ngModel)]="options.params[filter.param]"
                       [matDatepicker]="i"
                       matInput>
                <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
            </mat-form-field>
            <!-- DATEPICKER TEMPLATE: End -->
            <!-- SELECT TEMPLATE: Start -->
            <mat-form-field class="w-100" *ngIf="filter.type === 'select'">
                <mat-label>{{ 'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <mat-select [name]="filter.formName"
                            [(ngModel)]="options.params[filter.param]"
                            >
                    <mat-option *ngFor="let option of filter.additionalOptions"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                    <mat-option *ngFor="let option of options.enums[filter.enum]"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SELECT TEMPLATE: End -->
            <!-- MultipleSELECT TEMPLATE: Start -->
            <mat-form-field class="w-100" *ngIf="filter.type === 'multiSelect'">
                <mat-label>{{ 'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <mat-select [name]="filter.formName" multiple
                            [(ngModel)]="options.params[filter.param]"
                            >
                    <mat-option *ngFor="let option of filter.additionalOptions"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                    <mat-option *ngFor="let option of options.enums[filter.enum]"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SELECT TEMPLATE: End -->
            <!-- SELECT IMPORT UNIUM -->
            <mat-form-field class="w-100" *ngIf="filter.type === 'select_channel_unium'">
                <mat-label>{{ 'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <mat-select [name]="filter.formName"
                            [(ngModel)]="options.params[filter.param]"
                            >
                    <mat-option *ngFor="let option of filter.additionalOptions"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                    <mat-option *ngFor="let option of channelsUnium"
                                [value]="option.chan_id">{{option.chan_title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="filter.type === 'select_category_unium'">
                <mat-label>{{ 'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <mat-select [name]="filter.formName"
                            [(ngModel)]="options.params[filter.param]"
                            >
                    <mat-option *ngFor="let option of filter.additionalOptions"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                    <mat-option *ngFor="let option of categoryUnium"
                                [value]="option.keta_id">{{option.keta_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="filter.type === 'select_macro_unium'">
                <mat-label>{{ 'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                <mat-select [name]="filter.formName"
                            [(ngModel)]="options.params[filter.param]"
                            >
                    <mat-option *ngFor="let option of filter.additionalOptions"
                                [value]="option.value">{{option.name | translate}}</mat-option>
                    <mat-option *ngFor="let option of macroUnium"
                                [value]="option.kety_id">{{option.kety_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SELECT IMPORT UNIUM: END -->
            <!-- CHECKBOX TEMPLATE: Start-->
            <mat-checkbox *ngIf="filter.type === 'checkbox'"
                          [name]="filter.formName"
                          [(ngModel)]="options.params[filter.param]">
                {{'TABLE_HEADINGS.' + filter.name | translate}}
            </mat-checkbox>

            <app-tri-state-checkbox *ngIf="filter.type === 'tristatecheckbox'"
                                    [name]="filter.formName"
                                    [(ngModel)]="options.params[filter.param]">
                {{'TABLE_HEADINGS.' + filter.name | translate}}
            </app-tri-state-checkbox>

            <div class="row align-items-center" *ngIf="filter.type === 'chooseInput'">
                <mat-form-field class="col-6">
                    <mat-label>{{'TABLE_HEADINGS.' + filter.name | translate }}</mat-label>
                    <input [name]="filter.formName"
                           [(ngModel)]="options.params[filter.param]"
                           (keydown.enter)="log(options.params[filter.param], filter.choices);fetchData();"
                           (change)="log(options.params[filter.param], filter.choices)"
                           matInput>
                    <button *ngIf="options.params[filter.param]" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="options.params[filter.param]=null; log(null, filter.choices)">
                        <img class="clear-input-btn" src="/app_assets/svg/material-icons/close_grey.svg">
                    </button>
                </mat-form-field>

                <div class="col-6 p-0 d-flex">
                    <mat-checkbox [name]="choice.name"
                                  *ngFor="let choice of filter.choices"
                                  class="smallCheckbox"
                                  (change)="log(options.params[filter.param], filter.choices)"
                                  [(ngModel)]="choice.value">
                        {{'TABLE_HEADINGS.' + choice.name | translate}}
                    </mat-checkbox>
                </div>
            </div>

            <div class="row" style="padding-left: 15px" *ngIf="filter.type === 'valueChoosing' && filter.macroCategories">
                <span _ngcontent-c5="" class="mat-typography subheading-underline-blue-light" style="display: block; width: 100%; margin-bottom: 10px"><strong _ngcontent-c5="">{{'TITLES.VALUE_MATRIX' | translate}}</strong></span>
                <app-select-values-only-macro style="width: 99%" #selectValuesMacro [initMacroCategories]="filter.macroCategories" [localStorageParams]="storageCategoryIds"></app-select-values-only-macro>
            </div>
            <!-- CHECKBOX TEMPLATE: End-->
            <!-- BUTTON TEMPLATE: Start-->
            <!--<button *ngIf="filter.type === 'button'"
            mat-raised-button
            class="uniumx-button text-uppercase"
            color="primary"
            (click)="filter.action()">
        {{filter.name | translate}}
    </button>-->
            <!-- BUTTON TEMPLATE: End -->
        </div>
        <!-- ACTIONS: Start -->
        <div class="col pt-1 pb-1 d-flex justify-content-end" *ngIf="options.filters && options.filters.length > 0">
            <div style="display:inline-flex;margin:0px auto;">
                <!-- Find events -->
                <button mat-button
                        type="submit"
                        [matTooltip]="'BUTTONS.FIND' | translate"
                        class="mr-3 level2-button roundButton"
                        (click)="fetchData()">
                    <img src="/app_assets/svg/material-icons/search_blue.svg">

                </button>
                <!-- Reset filters -->
                <button mat-button
                        class="level2-button roundButton"
                        [matTooltip]="'BUTTONS.RESET_FILTERS' | translate"
                        (click)="resetFilters()">                  
                    <img src="/app_assets/svg/material-icons/cached_blue.svg">
                </button>
            </div>
        </div>
    </form>
    <!-- ACTIONS: End -->
</div>
<!-- FILTERS: End -->
<div *ngIf="responseData; else nothingFound">
    <div class="d-flex divider position-relative">
        <!-- TABLE_ACTIONS: Start -->
        <div class="ml w-50 d-flex align-items-center" *ngIf="responseData && responseData.length > 0; else div">
            <div *ngFor="let action of options.buttons" class="d-flex align-items-center">
                <div *ngIf="action.label && action.enabled && action.allowed" class="action-label">{{action.label | translate}}</div>
                <button mat-icon-button
                        *ngIf="action.enabled && action.allowed"
                        class="d-flex align-items-center justify-content-center mr-2"
                        type="button"
                        [ngClass]="action.btnStyle != undefined ? action.btnStyle : ''"
                        [matTooltip]="action.tooltip | translate"
                        (click)="actionClicked(action.type)">
                    <img [class]="action.style" [src]="action.iconPath">
                    <div *ngIf="action.btnText && action.enabled && action.allowed" class="btn-text">{{action.btnText | translate}}</div>
                </button>
            </div>
        </div>
        <ng-template #div><div class="w-50"></div></ng-template>
        <!-- TABLE_ACTIONS: End -->
        <!-- SPINNER -->
        <div *ngIf="loading && options.pagination.enabled" class="loading-spinner">
            <mat-spinner class="m-auto" diameter="25"></mat-spinner>
        </div>
        <!-- PAGINATOR: Start -->
        <div class="pr-2 w-50" *ngIf="options.pagination.enabled">
            <rm-paginator class="w-100" 
                          [showButtons]="options.pagination.showButtons"
                          [params]="options.params"
                          [updatePaginationEvent]="updatePagination"
                          (paginationParamsChanged)="fetchData()"></rm-paginator>
        </div>
        <!-- PAGINATOR: End -->
    </div>
    <!-- TABLE: Start -->
    <mat-table [dataSource]="dataSource"
               *ngIf="formattedData && formattedData.length !== 0; else nothingFound"
               class="mat-elevation-z8 w-100 --box-shadow-none">
        <ng-container *ngFor="let col of options.displayedColumns" [cdkColumnDef]="col">
            <!-- HEADER: Start -->
            <mat-header-cell *matHeaderCellDef [ngClass]="{'sortable-header': options.formats[col].sort}">
                <div *ngIf="col !== 'selectItems'" class="d-flex align-items-center justify-content-start"
                     (click)="options.formats[col].sort && changeOrder(col)">
                    <img class="sort-arrow mr-2"
                         *ngIf="options.formats[col].sort"
                         [ngClass]="getFlipIconClass(col)"
                         src="/app_assets/svg/material-icons/arrow_upward_grey.svg">
                    <span>{{'TABLE_HEADINGS.' + options.formats[col].label | translate}}</span>
                </div>
                <!-- CHECKBOX: Start -->
                <div *ngIf="col === 'selectItems'">
                    <mat-checkbox matTooltip="Check all"
                                  matTooltipPosition="above"
                                  #checkAllCheckbox
                                  [checked]="allChecked"
                                  (change)="checkAll($event)">
                    </mat-checkbox>
                </div>
                <!-- CHECKBOX: End -->
            </mat-header-cell>
            <!-- HEADER: End -->
            <mat-cell *matCellDef="let row" [ngClass]="{'query-in-progress': loading}">
                <!-- CHECKBOXES: Start -->
                <!-- SELECT ITEMS -->
                <mat-checkbox (click)="_stopPropagation($event)" [(ngModel)]="row._checked"
                              *ngIf="col === 'selectItems'"
                              (change)="checkItems($event, row)">
                </mat-checkbox>
                <!-- CUSTOM CHECKBOX ACTION -->
                <mat-checkbox (click)="_stopPropagation($event)" [(ngModel)]="row.isLinked"
                              *ngIf="col === 'customCheckbox'"
                              (change)="checkAction($event, row)">
                </mat-checkbox>
                <!-- CHECKBOXES: End -->
                <!-- CUSTOM DELETE ACTION -->
                <div (click)="_stopPropagation($event)"
                     *ngIf="col === 'delete'">
                    <button (click)="deleteTTD($event, row)" style="margin: 2px 5px" class="roundButton" mat-button>
                        <img src="/app_assets/svg/material-icons/delete_grey.svg">
                    </button>

                </div>
                <!-- CUSTOMDELETE ACTION: End -->
                <div (click)="_stopPropagation($event)" class="w-100 text-right"
                     *ngIf="col === 'download'">
                    <a href="/api/ContactDocument/Download/{{row.id}}">
                        <img src="/app_assets/svg/material-icons/save_alt_blue.svg">
                    </a>
                </div>
                <!-- Share -->
                <div (click)="_stopPropagation($event); shareReport(row.id, row.fileName)" class="w-100 text-right"
                     *ngIf="col === 'shareReport'">
                    <img src="/app_assets/svg/material-icons/copy.svg" [matTooltip]="'Copia URL'">
                </div>
                <!-- FILENAMEDOWNLOAD -->
                <div (click)="_stopPropagation($event)"
                     *ngIf="col === 'fileNameDownload'">
                    <a href="/api/ContactDocument/Download/{{row.id}}">
                        {{row.fileName}}
                    </a>
                </div>

                <!-- END FILEDOWNLOAD -->
                <!-- SHORT LINK: Start -->
                <div (click)="_stopPropagation($event)" *ngIf="options.formats[col].type ==='shortLink'">
                    <a *ngIf="rowUrl(row); else showFileName" class="pointerAlias fakeLink" [href]="row.url | safeUrl" target="_blank" rel="noopener">
                        <p [innerHTML]="massTimingsHtml(row.url)"></p>
                    </a>

                    <ng-template #showFileName>
                        <p class="fakeLink" (click)="downloadOffline(row)">{{row.fileName}}</p>
                    </ng-template>
                </div>
                <!-- SHORT LINK: End -->
                <!-- INHERITED: Start -->
                <div *ngIf="col === 'inheritedAssetId'">
                    <span>{{row.inheritedAssetId && row.inheritedAssetId !== 0 ? 'Yes' : 'No'}}</span>
                </div>
                <!-- INHERITED.PREVIEW: Start -->
                <div class="d-flex"
                     *ngIf="row.preview != '-' && col === 'preview' && rowCol(row, col) != null"
                     [matTooltip]="getInheritedAssetsPreview(row, col)" [matTooltipPosition]="'right'"
                     matTooltipClass="tooltip-multiline max-h-350 max-w-350">
                    <img src="/app_assets/svg/material-icons/papyrus.svg">
                </div>
                <!-- INHERITED.PREVIEW: End -->
                <!-- INHERITED.NOTES: Start -->
                <div class="d-flex"
                     *ngIf="row.notes && col === 'notes' && rowCol(row, col)"
                     [matTooltip]="getInheritedAssetsNotes(row, col)"
                     matTooltipClass="tooltip-multiline">
                    <img *ngIf="row.notes !== '-'" src="/app_assets/svg/material-icons/papyrus.svg">
                </div>
                <!-- INHERITED.NOTES: End -->
                <!-- ELAPSED RECALL INDICATOR: Start -->
                <span class="dot-indicator d-inline-block"
                      *ngIf="(row.recallElapsedStatus || row.recallElapsedStatus === 0) && col === 'recallDate' && rowCol(row, col)"
                      [matTooltip]="(row.recallElapsedStatus | valueOfEnum:options.formats[col].enum():true).name | translate"
                      [ngClass]="(row.recallElapsedStatus | valueOfEnum:options.formats[col].enum():true).displayName">
                </span>
                <!-- ELAPSED RECALL INDICATOR: End -->
                <!-- POSITION INDICATOR: Start -->
                <div class="d-flex justify-content-end align-items-center"
                     *ngIf="row._item.position && row._item.previousPosition && row._item.positionUp !== null && col === 'previousPosition' && rowCol(row, col)">
                    <img *ngIf="row._item.positionUp" src="/app_assets/svg/material-icons/keyboard_arrow_up.svg" />
                    <img *ngIf="!row._item.positionUp" src="/app_assets/svg/material-icons/keyboard_arrow_down.svg" />
                </div>
                <!-- POSITION INDICATOR: End -->
                <!-- STATUS CHECK INDICATOR: Start -->
                <div class="d-flex"
                     *ngIf="row.softLegal && col === 'softLegal.lastAutoCheck' && rowCol(row, col)"
                     [matTooltip]="getStatusTooltip(row, col)"
                     matTooltipClass="tooltip-multiline">
                    <img *ngIf="row.softLegal.statusNotMatch === true" src="/app_assets/svg/material-icons/priority_high_circle.svg">
                    <img *ngIf="row.softLegal.statusNotMatch === false" src="/app_assets/svg/material-icons/check_circle_green.svg">
                </div>
                <!-- STATUS CHECK INDICATOR: End -->
                <!-- LAST AUTO CHECK: Start -->
                <div class="d-flex"
                     *ngIf="row.softLegal && col === 'softLegal.lastAutoCheck' && rowCol(row, col)">
                    <div id="{{row.contentId}}" *ngIf="!manualCheckSpinner && rotatingEl === row.contentId; else checkSpinner">
                        <mat-spinner *ngIf="!manualCheckSpinner && rotatingEl === row.contentId" [diameter]="25"></mat-spinner>
                    </div>
                    <ng-template #checkSpinner>
                        <button id="{{row.contentId}}" (click)="$event.preventDefault(); $event.stopPropagation(); clickOnReload(row.contentId, row)" style="border: 0px solid; background-color: transparent;" matTooltip="New check">
                            <img class="mr-2" style="width:20px;" src="/app_assets/svg/material-icons/reload_blue.svg">
                        </button>
                    </ng-template>
                </div>
                <!-- STATUS CHECK INDICATOR GOOGLE: Start -->
                <div class="d-flex" *ngIf="row.softLegal && col === 'isGoogleBlocked'" style="margin-left:12px">
                    <img *ngIf="row.softLegal.blockedDomainsRequired.includes(1)" src="app_assets/svg/material-icons/check_circle_green.svg" />
                </div>
                <!-- STATUS CHECK INDICATOR GOOGLE: End -->
                <!-- STATUS CHECK INDICATOR YAHOO: Start -->
                <div class="d-flex" *ngIf="row.softLegal && col === 'isYahooBlocked'" style="margin-left:12px">
                    <img *ngIf="row.softLegal.blockedDomainsRequired.includes(2)" src="app_assets/svg/material-icons/check_circle_green.svg" />
                </div>
                <!-- STATUS CHECK INDICATOR YAHOO: End -->
                <!-- STATUS CHECK INDICATOR BING: Start -->
                <div class="d-flex" *ngIf="row.softLegal && col === 'isBingBlocked'" style="margin-left:12px">
                    <img *ngIf="row.softLegal.blockedDomainsRequired.includes(4)" src="app_assets/svg/material-icons/check_circle_green.svg" />
                </div>
                <!-- STATUS CHECK INDICATOR BING: End -->
                <!-- DEADLINE INDICATOR: Start -->
                <span class="dot-indicator d-inline-block --bg-warn"
                      *ngIf="(row.deadlineExpired && row.deadlineExpired === true) && col === 'deadline' && rowCol(row, col)">
                </span>
                <!-- DEADLINE INDICATOR: End -->
                <!-- VALUE: Start -->
                <div *ngIf="options.formats[col].list">
                    {{item}}
                </div>
                <div (click)="_stopPropagation($event)" *ngIf="options.formats[col].type ==='domainLink'"
                     matTooltipPosition="above"
                     [matTooltip]="row.url">
                    <a class="pointerAlias" [href]="row.url | safeUrl" target="_blank" rel="noopener">{{row.domain}}</a>
                </div>
                <div (click)="_stopPropagation($event)" *ngIf="options.formats[col].type ==='instanceLink'"
                     matTooltipPosition="above"
                     [matTooltip]="row.url">
                    <a [href]="'/api/SoftLegal/VisitLink?contentId=' + row.contentId + '&customerId=' + row.contactId + '&url=' + row.url | safeUrl"
                       target="_blank"
                       rel="noopener"
                       class="pointerAlias"
                       (click)="handleUrlNavigation(row.id, $event)">
                        {{row.url}}
                    </a>
                </div>
                <div *ngIf="col === 'softLegal.isLinkWithDocuments'" class="fillerAttach long-text" [ngClass]="{'linkHasFile': row.softLegal.isLinkWithDocuments == true}">
                </div>
                <div (click)="_stopPropagation($event)" *ngIf="options.formats[col].type ==='link'">
                    <a class="pointerAlias" [href]="row.url | safeUrl" target="_blank" rel="noopener">{{row.url}}</a>
                </div>
                <div *ngIf="options.formats[col].type ==='longText'" class="long-text" [matTooltip]="rowCol(row, col) != '-' ? rowCol(row, col) : null">
                    {{rowCol(row, col) != '-' ? rowCol(row, col) : null}}
                </div>
                <div *ngIf="options.formats[col].type === 'boolean'">
                    <img *ngIf="rowCol(row, col)" src="app_assets/svg/material-icons/check_circle_green.svg" />
                </div>
                <!-- ENUMS: Start -->
                <div *ngIf="options.formats[col].type === 'enum'">
                    {{rowColEnum(row, col)}}
                </div>
                <div *ngIf="options.formats[col].type === 'enumMulti'">
                    {{row[col] | translate | values | join:', '}}
                </div>
                <!-- ENUMS: End -->
                <!-- contentComponent: START -->

                <div id="mydiv" *ngIf="options.formats[col].type === 'contentComponent'" class="row contentWidget d-block" style="margin: 15px 0;margin-bottom: 7px;width: 100%;">
                    <widget-component [row]="row"></widget-component>
                </div>
                <!-- contentComponent: END -->

                <div *ngIf="options.formats[col].plainText"
                     [matTooltip]="options.formats[col].longTextWithFormat ? rowCol(row, col) : options.formats[col].label == 'LAST_RECALL' ? getRecallTooltip(row, col) : null"
                     [ngClass]="{'long-text': options.formats[col].longTextWithFormat}">
                    {{rowCol(row, col)}}
                </div>
                <div *ngIf="options.formats[col].rScore">
                    {{rowScore(row)}}
                </div>
                <div *ngIf="options.formats[col].userGroup">
                    {{roleNumberToString(row)}}
                </div>
                <!-- VALUE: End -->
                <!-- ACTIONS: Start -->
                <div *ngIf="col === 'customAction'" [ngClass]="{'col-jus-left': options.formats[col].label == 'WFT'}" class="actions-container">
                    <button mat-icon-button
                            [ngClass]="getCustomActionClass(row, options.formats[col])"
                            class="d-flex justify-content-between align-items-center --txt-blue"
                            [disabled]="loading"
                            (click)="emitCustomAction(row, $event)"
                            [matTooltip]="getCustomActionTooltip(row, options.formats[col])">
                        <div style="margin-top: 7px" *ngIf="getCustomActionIcon(row, options.formats[col]) != 'loadingWftCheck'">
                            <img src="/app_assets/svg/material-icons/{{getCustomActionIcon(row, options.formats[col])}}.svg">
                        </div>

                        <div style="margin-top: 7px" *ngIf="getCustomActionIcon(row, options.formats[col]) == 'loadingWftCheck'">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                    </button>
                </div>
                <!-- ACTIONS: End -->
                <div *ngIf="col === 'customActionRecall'">
                    <div *ngIf="row.fileName && row.fileName != '-'"
                         class="d-flex align-items-center justify-content-end w-13 ml-3 mb-0">
                        <img [matTooltip]="row.fileName" style="margin-bottom: 5px;" src="/app_assets/svg/material-icons/paperclip.svg">
                    </div>
                </div>
                <!-- RECALL TYPE: Start -->
                <div *ngIf="row.recallType && col === 'recallDate' && rowCol(row, col)"
                     [matTooltip]="recallTool((row.recallType | valueOfEnum: options.formats['recallType'].enum(): true).displayName)"
                     class="ml-2 mr-2 recall-type"
                     [ngClass]="row.lastRecallSucceeded || row.succeeded ?
                     (row.recallType | valueOfEnum:options.formats['recallType'].enum():true).displayName + ' recall-ok' :
                     (row.recallType | valueOfEnum:options.formats['recallType'].enum():true).displayName + ' recall-ko' ">
                </div>
                <!-- RECALL TYPE: End -->
                <!-- MYUNIUMX -->
                <div *ngIf="col === 'myUniumx'" (click)="myUniumxModal($event, row)" class="w-100" [ngClass]="row.isMyUniumXCustomer ? 'cursor-pointer' : 'cursor-default'">
                    <a *ngIf="row.isMyUniumXCustomer"><img class="m-2" src="/app_assets/svg/material-icons/myuniumx.svg" /></a>
                </div>
                <!-- MYUNIUMX END -->
                <!-- Domain TDD -->
                <div *ngIf="col === 'ttdNameLinked'" (click)="ttdNameLinked(row, $event)" class="w-100">
                    <div style="text-decoration: underline;">{{row.ttdName == '-' ? '' : row.ttdName}}</div>
                </div>
                <!-- Domain TDD end -->
                <!-- EVALUATION -->
                <div *ngIf="col === 'evaluationIndex'">
                    <span *ngIf="row.evaluation == 0">- </span>
                    <span *ngIf="row.evaluation != 0">+ </span>
                    {{row.evaluationIndex}}
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="options.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: options.displayedColumns;"
                 [ngClass]="getRowColor(row._item)"
                 [ngStyle]="{'opacity': getOpacity(row, options)}"
                 (click)="(options.update.enabled && !loading) ? updateItem(row) : null">
        </mat-row>
    </mat-table>
    <!-- TABLE: End -->
    <!-- PAGINATOR: Start -->
    <div class="row m-0">
        <div class="col-8 col-md-6 pt-1 pb-1 d-flex align-items-center">
            <ng-content></ng-content>
        </div>
        <div class="col-4 col-md-6 pt-1 pb-1" *ngIf="options.pagination.enabled">
            <rm-paginator [showButtons]="options.pagination.showButtons" 
                          [params]="options.params" 
                          [updatePaginationEvent]="updatePagination"
                          (paginationParamsChanged)="fetchData()"></rm-paginator>
        </div>
    </div>
    <!-- PAGINATOR: End -->
</div>

<ng-template class="d-flex flex-column p-3 mb-5" #placeholder>
    <div *ngFor="let item of shared.pageSizeLoop(10);" class="placeholder-table-item">
        <div class="placeholder-table-text placeholder-gradient w-25 mr-2"></div>
        <div class="placeholder-table-text placeholder-gradient w-25 mr-2"></div>
        <div class="placeholder-table-text placeholder-gradient w-50"></div>
    </div>
</ng-template>


<!-- NOTHING FOUND -->
<ng-template #nothingFound>
    <div  class="rm-divider">
        <app-nothing-found [color]="'white'" [innerText]="tableMessage" [icon]="tableIcon"></app-nothing-found>
    </div>
</ng-template>
