import { TtdTableOptions } from './ttd-table.options';
import { Config } from '../../app.constants';
import { DomainsTableOptions } from '../table-options/domains-table.options';

export class LinkDomainsToTtdTableOptions extends DomainsTableOptions {
    constructor() {
        super();
        this.buttons = [];
        this.paths.fetchPath = '/api/Domain/ForTTD';
        this.paths.linkToEntityPath = '/api/Domain/LinkToTTD';
        this.displayedColumns = [
            'customCheckbox',
            'url',
            'name',
        ];
        this.formats = {
            'customCheckbox': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            ...this.formats,
        };
    }
}
