import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RoutePrefix, Route } from '../helpers/routeDecorators';
import { JsonReply, DomainUser } from '../models';
import { CookieService } from 'ngx-cookie-service';

export class AuthReply {
    public access_token: string;
    public token_type: string; // 'Bearer'
    public expires_in: number;

    static valid(reply: AuthReply): boolean {
        return reply && reply.access_token && reply.expires_in && reply.token_type === 'bearer';
    }
}

@Injectable({ providedIn: 'root' })
@RoutePrefix('api/Account')
export class AuthService {

    constructor(private httpClient: HttpClient, private cookies: CookieService) { }

    public LoginForm(username: string, password: string, url: string = ''): Promise<HttpResponse<any>> {
        let body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.httpClient.post<HttpResponse<any>>(url, body.toString(), options).toPromise().then(resp => {
            return resp;
        });
    }

    @Route('Current')
    public getAccount(url: string = ''): Promise<JsonReply<DomainUser>> {
        return this.httpClient.get<JsonReply<DomainUser>>(url).toPromise();
    }

    @Route('Token')
    public getAccessToken(url: string = ''): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.httpClient.get<JsonReply<string>>(url).toPromise()
                .then(resp => {
                    if (resp.ok) {
                        resolve(resp.data);
                    } else {
                        reject(resp.errorMessage);
                    }
                })
                .catch(err => reject(err));
        });
    }

    public login(username: string, password: string, url: string = ''): Promise<boolean> {
        let body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.httpClient.post<AuthReply>(url, body, options).toPromise().then(resp => {
            if (AuthReply.valid(resp)) {
                this.setToken(resp.access_token);
                return true;
            }

            return false;
        });


    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public logOut(): void {
        this.cookies.delete('LoggedUserID');
        this.cookies.delete('LoggedUserFullname');
        this.cookies.delete('cookie');
    }

    private setToken(token: string): void {
        localStorage.setItem('token', token);
    }
}
