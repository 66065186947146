import { BaseParamsWithContactId } from "./BaseParamsWithContactId";
import { RichEnum } from "../RichEnum";
import { InjuryLevel, SoftLegalTab, SuccessFeeStatus } from "../Enums";

export class GetCustomerLinkParams extends BaseParamsWithContactId {
    public url: string;
    public result: number[];
    public ttdOrInstanceReceiver: string;
    public eventName: string;
    public softLegalTab: SoftLegalTab;
    public isSoftLegal: boolean;
    public successFee: SuccessFeeStatus;
    public withDocuments: boolean;
    public isShared: boolean;

    constructor(public reverseOrder: boolean = false,
        public injuryLevel: number = RichEnum.Any,
        public publishingDate: number = null,
        public linkPart: string = null,
        public notesPart: string = null) {

        super();

        this.softLegalTab = SoftLegalTab.All;
        this.url = null;
        this.isSoftLegal = true;
        this.result = [RichEnum.Any];
        this.ttdOrInstanceReceiver = null;
        this.eventName = null;
    }
}
