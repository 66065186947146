import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '../../instruments/models';
import { NotificationService, ProjectService } from '../../instruments/services';
import * as go from '../../theme/assets/go.js'
import { MatButtonModule } from '@angular/material/button';

const $ = go.GraphObject.make;
const roundedRectangleParams = { parameter1: 2,  spot1: go.Spot.TopLeft, spot2: go.Spot.BottomRight };
const dimensionDiagram: number[] = [0, 105, 125, 155];
const colorsDiagram: string[] = ["#8E8DBE", "#002761", "#676965", "#002761"];

@Component({
    selector: 'app-channels-chart',
    templateUrl: './channels-chart.component.html',
    styleUrls: ['./channels-chart.component.css'],
    standalone: true,
    imports: [MatButtonModule]
})

export class ChannelsChartComponent implements OnInit {
    public diagram: go.Diagram = null;
    public selectedNode = null;
    public MODEL = {
        "nodeKeyProperty": "id",
        "nodeDataArray": [],
        "linkDataArray": [],
    };

    @Input() customer: Project;
    @Output() closeModal: EventEmitter<number> = new EventEmitter();

    constructor(private projectService: ProjectService, private snackbar: NotificationService,) { }

    ngOnInit()
    {
        var _model = JSON.parse(this.customer.channelsChart);
        this.customer.newChannelsChart = !_model.nodeKeyProperty ? true : false;
        if (this.customer.newChannelsChart)
            this.MODEL.nodeDataArray = _model;
        else
            this.MODEL = _model;

        this.setDiagram();   
    }

    setDiagram()
    {
        this.diagram = $(go.Diagram, 'myDiagramDiv', { 'undoManager.isEnabled': true } );

        this.diagram.nodeTemplate =
        $(go.Node, "Auto",
            {
                locationSpot: go.Spot.Top,
                isShadowed: true, shadowBlur: 1,
                shadowOffset: new go.Point(0, 1),
                shadowColor: "rgba(0, 0, 0, .14)",
            },
            new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
            $(go.Shape, "Circle", roundedRectangleParams,
                {
                    name: "SHAPE",
                    strokeWidth: 0,
                    stroke: null,
                    portId: "",
                    fromLinkable: true,
                    fromLinkableSelfNode: true,
                    fromLinkableDuplicates: true,
                    toLinkable: true,
                    toLinkableSelfNode: true,
                    toLinkableDuplicates: true,
                    cursor: "pointer"
                },
                new go.Binding('height', 'dimension', function (key: number) {
                    return dimensionDiagram[key];
                }),
                new go.Binding('width', 'dimension', function (key: number) {
                    return dimensionDiagram[key];
                }),
                new go.Binding('fill', 'kickoff', function (key: string) {
                    return colorsDiagram[key];
                }),
                new go.Binding('fill', 'approval', function (approval: boolean) {
                    if (approval)
                        return "#002761";
                })
            ),
            $(go.Picture,
                {
                    width: 50,
                    height: 50,
                    opacity: 0.1
                },
                new go.Binding('source', 'text', function (key: string) {
                    var pics = ["facebook", "instagram", "linkedin", "pinterest", "top managers", "twitter", "vimeo", "wikipedia", "youtube"];
                    if (pics.includes(key.toLowerCase()))
                        return "app_assets/svg/channels/" + key + "_small.svg";
                    else
                        return "app_assets/svg/channels/generic_small.svg";
                })
            ),
            $(go.Panel, "Table",
                {
                    margin: new go.Margin(5, 5, 5, 5),
                    defaultAlignment: go.Spot.Center,
                },
                $(go.TextBlock,
                    {
                        row: 0,
                        font: "bold small-caps 11pt helvetica, bold arial, sans-serif",
                        margin: 5,
                        stroke: "white",
                        editable: true,
                        isMultiline: true,
                        textAlign: "center"
                    },
                    new go.Binding("text").makeTwoWay(),
                    new go.Binding('width', 'dimension', function (key: number) {
                        return dimensionDiagram[key] - 10;
                    })
                ),
                $(go.Panel, "Table",
                    {
                        row: 1,
                        margin: new go.Margin(5, 5, 5, 5),
                        defaultAlignment: go.Spot.Center,
                    },
                    $(go.RowColumnDefinition, { column: 0, width: 30 }),
                    $(go.RowColumnDefinition, { column: 1, width: 30 }),
                    $(go.RowColumnDefinition, { column: 2, width: 30 }),
                    $(go.Picture,
                        {
                            row: 1,
                            column: 2,
                            width: 20,
                            height: 20,
                        },
                        new go.Binding('source', 'serp', function (serp: string) {
                            if (serp)
                                return "app_assets/svg/channels/serp_small.svg";
                        })
                    ),
                    $(go.Picture,
                        {
                            row: 1,
                            column: 1,
                            width: 20,
                            height: 20,
                        },
                        new go.Binding('source', 'type', function (type: string) {
                            if (type == "1")
                                return "app_assets/svg/material-icons/person_outline.svg";
                            else if (type == "2")
                                return "app_assets/svg/channels/corporate_small.svg";
                        })
                    ),

                    $(go.Picture,
                        {
                            row: 1,
                            column: 0,
                            width: 20,
                            height: 20,
                        },
                        new go.Binding('source', 'managed', function (managed: string) {
                            if (managed)
                                return "app_assets/svg/channels/management.svg";
                        })
                    ),
                ),
            ),
        );

        this.diagram.nodeTemplate.selectionAdornmentTemplate =
        $(go.Adornment, "Spot",
            $(go.Panel, "Auto",
                $(go.Shape, "RoundedRectangle", roundedRectangleParams,
                    {
                        fill: null,
                        stroke: "#7986cb",
                        strokeWidth: 3
                    }),
            $(go.Placeholder)
            ),
            $("Button",
            {
                alignment: go.Spot.TopRight,
                click: this.addNodeAndLink
            },
            $(go.Shape, "PlusLine", { width: 6, height: 6 })),
            $("Button",
                {
                    alignment: go.Spot.TopLeft,
                    click: this.setDimension
                },
                $(go.TextBlock, {text: " -"},
                new go.Binding('text', 'dimension', function (key: number) {
                    return key == 2 ? 3 : key == 3 ? 1 : 2;
                }))
            )
        );

        this.diagram.linkTemplate =
        $(go.Link,
            {
                curve: go.Link.Bezier,
                adjusting: go.Link.Stretch,
                reshapable: true,
                relinkableFrom: true,
                relinkableTo: true,
                toShortLength: 3
            },
            new go.Binding("points").makeTwoWay(),
            new go.Binding("curviness"),
            $(go.Shape, { strokeWidth: 1.5 },
                new go.Binding('stroke', 'dotted', dotted => dotted ? "black" : 'black'),
                new go.Binding('strokeDashArray', 'dotted', dotted => dotted ? [5, 5] : ''),
                new go.Binding('strokeWidth', 'dotted', dotted => dotted ? 2.5 : 1.5)),
            $(go.Shape, { toArrow: "standard", stroke: null, scale: 1.5, fill: "black" }),
            $(go.Shape, { fromArrow: "", stroke: null, scale: 1.5, fill: "black" }, new go.Binding('fromArrow', 'arrowheads', arrowheads => arrowheads ? "Backward" : "")),
            $(go.Panel, "Auto",
                $(go.Shape,
                {
                    fill: null,
                    stroke: null
                }),
                $(go.TextBlock, "collegamento",
                    {
                        textAlign: "center",
                        font: "bold 14px helvetica, arial, sans-serif",
                        margin: 4,
                        background: "white",
                        stroke: "black", 
                        editable: true
                    },
                new go.Binding("text").makeTwoWay())
            ),
        );


        this.diagram.linkTemplate.selectionAdornmentTemplate =
        $(go.Adornment, "Spot",
            $(go.Panel, "Table",
                {
                    row: 0,
                    height: 50,
                    defaultAlignment: go.Spot.Top,
                },
                $(go.RowColumnDefinition, { column: 0, width: 30 }),
                $(go.RowColumnDefinition, { column: 1, width: 30 }),
                $("Button",
                    {
                        row: 1,
                        column: 0,
                        click: this.setArrowheads
                    },
                    $(go.Shape, "LineRight", { width: 6, height: 6 })
                ),
                $("Button",
                    {
                        row: 1,
                        column: 1,
                        click: this.addDotted
                    },
                    $(go.Shape, "LineV", { width: 6, height: 6 })
                )
            ),
        );

        this.load();
    }

    /*
     * setta le dimensioni del pallino 1 small 2 medium 3 large
     */ 
    setDimension(e, obj) {
        var diagram = e.diagram;
        var model = diagram.model;

        diagram.startTransaction("Set Dimension");

        var oldNode = model.Pc.filter(m => m.__gohashid == obj.part.data.__gohashid)[0]
        model.removeNodeData(oldNode);

        var newNode = oldNode;
        newNode.dimension = newNode.dimension == 2 ? 3 : newNode.dimension == 3 ? 1 : 2;
        model.addNodeData(newNode)

        diagram.commitTransaction("Set Dimension");
    }

    /*
     * setta il collegamento con due freccie
     */ 
    setArrowheads(e, obj) {
        var diagram = e.diagram;
        var model = diagram.model;

        var dd = e.diagram.model.dd;
        var id = obj.part.data.__gohashid

        diagram.startTransaction("Add Arrowheads");

        var arrow = dd.filter(m => m.__gohashid == id)[0]

        var linkdata = {
            from: arrow.from,
            to: arrow.to,
            points: arrow.points,
            text: arrow.text,
            dotted: arrow.dotted,
            arrowheads: !arrow.arrowheads
        };
        // and add the link data to the model
        model.removeLinkData(arrow);
        model.addLinkData(linkdata);

        diagram.commitTransaction("Add Arrowheads");
    }

    /*
     * setta il collegamento con tratteggio
     */ 
    addDotted(e, obj) {
        var diagram = e.diagram;
        var model = diagram.model;

        var dd = e.diagram.model.dd;
        var id = obj.part.data.__gohashid

        diagram.startTransaction("Add dotted");

        var arrow = dd.filter(m => m.__gohashid == id)[0]

        var linkdata = {
            from: arrow.from,
            to: arrow.to,
            points: arrow.points,
            text: arrow.text,
            arrowheads: arrow.arrowheads,
            dotted: !arrow.dotted,
        };
        // and add the link data to the model
        model.removeLinkData(arrow);
        model.addLinkData(linkdata);

        diagram.commitTransaction("Add dotted");
    }

    /*
     * aggiunge un nodo
     */ 
    addNodeAndLink(e, obj) {
        var adornment = obj.part;
        var diagram = e.diagram;
        diagram.startTransaction("Add State");

        var fromNode = adornment.adornedPart;
        var fromData = fromNode.data;
        var toData = { text: "Nuovo canale", loc: null, kickoff: 0, dimension: 2 };
        var p = fromNode.location.copy();
        p.x += 200;
        toData.loc = go.Point.stringify(p);
        var model = diagram.model;
        model.addNodeData(toData);

        var linkdata = {
            from: model.getKeyForNodeData(fromData),
            to: model.getKeyForNodeData(toData),
            text: "collegamento"
        };
        model.addLinkData(linkdata);

        var newnode = diagram.findNodeForData(toData);
        diagram.select(newnode);

        diagram.commitTransaction("Add State");

        diagram.scrollToRect(newnode.actualBounds);
    }

    /*
     * carica il chart con il modello
     */
    load() {
        this.diagram.model = go.Model.fromJson(this.MODEL);
    }

    public export() {
        var url = this.diagram.makeImage({
            background: '#fff'
        });

        const link = document.createElement('a');
        link.setAttribute('href', url.src);
        link.setAttribute('download', "Channels_chart_" + this.customer.name + "_" + this.customer.surname);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    public save() {
        var modelToSave = {
        "nodeKeyProperty": "id",
        "nodeDataArray": [],
        "linkDataArray": [],
        };
        var _pc = this.diagram.model.Pc;
        var _dd = this.diagram.model.dd;

        modelToSave.nodeDataArray = _pc;
        modelToSave.linkDataArray = _dd;

        this.customer.channelsChart = JSON.stringify(modelToSave);

        this.projectService.updateCustomer(this.customer).then(() => {
            this.customer.newChannelsChart = false;
            this.snackbar.done();
            this.closeModal.emit();
        });

    }

    public reset() {
        this.customer.channelsChart = null;

        this.projectService.updateCustomer(this.customer).then(() => {
            this.snackbar.done();
            this.closeModal.emit();
        });
    }

}
