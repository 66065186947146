export class ContentCheckModel {
    ContactId: number;
    ContentId: number;
    lastCheck: Date;
    CustomerLinkStatus: [number];
    httpStatus: number;
    anonymized: boolean;
    deIndexed: boolean;
    ActualLinkDeindexStatus: [number];
    MetaTagNoIndexError: string;
    KeywordsCheck: [string];
}