// Core
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

// Instruments


@Component({
    selector: 'notification-component',
    templateUrl: './notification.component.html',
    styleUrls: ['notification.component.css'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatButtonModule,
        TranslateModule,
    ],
})
export class NotificationComponent implements OnInit {
    // UI

    constructor(private snackBarRef: MatSnackBarRef<NotificationComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    ngOnInit() {
    }

    _dismiss() {
        this.snackBarRef.dismiss();
    }



}
