import {DomainUser} from "./DomainUser";

export class Recall {
    _checked: boolean;
    id: number = 0;
    entityId: number = 0;
    recallDate: Date = new Date();
    recallResult: string = '';
    succeeded: boolean = false;
    recallType: number = 0;
    fileName: string = null;
    completed: boolean;
    //ui
    userGroupName: string = null;

    constructor (public contactId: number = 0, public sales: DomainUser = null) {

    }
}