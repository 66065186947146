import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CustomerServiceModel, JsonReply, ServiceType } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/ContactsService')
export class ContactsLinkedServicesService {

    private httpClient: HttpClient;

    constructor(http: HttpClient) {
        this.httpClient = http;
    }

    @Route('Available')
    public getAvailableServices(url: string = ''): Promise<JsonReply<ServiceType[]>> {
        return this.httpClient.get<JsonReply<ServiceType[]>>(url)
            .toPromise();
    }

    @Route('ForContact')
    public getServicesForContact(contactId: number, url: string = ''): Promise<JsonReply<CustomerServiceModel[]>> {
        return this.httpClient.get<JsonReply<CustomerServiceModel[]>>(url, { params: { 'contactId': contactId.toString() } })
            .toPromise();
    }
}
