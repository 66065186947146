import { EventsTableOptions } from './events-table.options';

export class LinkEventsToAssetTableOptions extends EventsTableOptions {
    constructor() {
        super();
        this.buttons = [];
        this.paths.fetchPath = 'api/ContentEvent/ForCustomer';
        this.paths.linkToEntityPath = '/api/Content/LinkToEvent';
        this.displayedColumns = [
            'customCheckbox',
            'description',
            'startDate',
            'endDate',
        ];
        this.formats = {
            ...this.formats,
            'customCheckbox': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
        };
    }
}
