export class TTDContact {
    static count: number = 0;
    name: string = '';
    email: string = '';
    officePhone: string = '';
    mobilePhone: string = '';
    note: string = '';
    id: number = 0;
    _id: number = TTDContact.count++;
}
