// Core
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/index";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

// Instruments
import {
    AlertData,
    AssetAlertData,
    ContentAlertData,
    DeleteAlertData,
    ErrorAlertData,
    EventAlertData,
    LinkAlertData,
    PromptAlertData,
    RecallAlertData,
    SuccessAlertData,
    TagAlertData,
    ValueAlertData,
    LinkEventAlertData,
    DomainAlertData,
    LinkDomainAlertData,
    InstanceAlertData,
    InstanceLinkAlertData,
    ReminderAlertData,
    SelectEventAlertData,
    CustomerRecallAlertData,
    LinkContactAlertData,
    ApiLinkData,
    WftAcquisitionAlertData,
    LinkTrendData,
    EditManyData,
    NewDefaultChannel,

    UpdateChannelCheck,
    MassiveAlertData,
    ShowSerpTable,
    MassiveContentsData,
    QuickSerpDictionary,
    myUniumxTable,
    StepDateData,
    AddNewSerp,
    MoreInfoSerp,
    CustomerStartActivityData,
    ChannelChart,
    MassiveSoftLegal,
    SoftLegalShareStatus,
    EditUrlSerp, ChannelsSetStatus
} from './AlertData';
import {
    Recall,
    CustomerEvent,
    DomainUser,
    PopupTableType,
    Domain,
    LinkManyEntitiesParams,
    Contact,
    FindContactsParams,
    JsonReply,
    LinkContactsParams,
    JsonReplyWithParams,
    AcquisitionRequest,
    ContentModel,
    SERPLinkPositionData,
    SERPLinkPositionTrend,

    UsedbyOtherCustomer,
    LinkContactsParamsWithNumber,
    UsersCustomer,
    RequestPreAnalysisDateParams,
    SERPQuery,
    CustomerStartActivity,
    SoftLegalTab,
} from '../../instruments/models';

// Components
import { AlertComponent } from './alert.component';
import { Instance } from "../../instruments/models/project/Instance";
import { ProjectLink } from "../../instruments/models/project/ProjectLink";
import { softLegalStatusInfo } from "../../instruments/models/content/contentWidget";
import {WidgetContentUpdateParams} from '../widget-table/widget-components/widget-set-channel/widget-set-channel.component';

@Injectable()
export class AlertService {

    constructor ( private dialog: MatDialog) {

    }

    open(alertData: AlertData, wide: boolean = false): Observable<string> {
        const dialogRef = this.dialog.open(AlertComponent, {
            data: alertData,
            width: wide ? '1300px' : '550px',
        });
        return dialogRef.afterClosed();
    }

    apiLink(token: string, link: string, title: string = 'API link'): Observable<string> {
        return this.open(new ApiLinkData(token, link, title));
    }

    prompt(promptTitle: string = 'MESSAGES.ARE_YOU_SURE'): Observable<string> {
        return this.open(new PromptAlertData(promptTitle));
    }

    massiveImport(promptTitle: string = 'MESSAGES.MASSIVE_BROWSER'): Observable<string> {
        return this.open(new MassiveAlertData(promptTitle), true);
    }

    delete(promptTitle: string = 'MESSAGES.ARE_YOU_SURE', translateParams: any): Observable<string> {
        return this.open(new DeleteAlertData(promptTitle, translateParams));
    }

    error(errorTitle: string = 'Error'): Observable<string> {
        return this.open(new ErrorAlertData(errorTitle));
    }

    success(suceessTitle: string = 'Success'): Observable<string> {
        return this.open(new SuccessAlertData(suceessTitle));
    }

    event(event: CustomerEvent, eventTitle: string = 'BUTTONS.ADD_EVENT'): Observable<string> {
        return this.open(new EventAlertData(event, eventTitle));
    }

    appointment(appointment: CustomerEvent, eventTitle: string = 'BUTTONS.ADD_APPOINTMENT'): Observable<string> {
        return this.open(new EventAlertData(appointment, eventTitle));
    }

    domain(domain: Domain, title: string = 'BUTTONS.ADD_DOMAIN'): Observable<string> {
        return this.open(new DomainAlertData(domain, title));
    }

    linkEvent(customerId: number,
        entityId: number,
              tableType: PopupTableType,
              title: string = 'BUTTONS.SELECT_EVENTS'): Observable<string> {
        return this.open(new LinkEventAlertData(customerId, entityId, tableType, title), true);
    }

    linkContent(customerId: number,
                entityId: number,
                tableType: PopupTableType,
                title: string = 'BUTTONS.SELECT_CONTENT'): Observable<string> {
        return this.open(new ContentAlertData(customerId, entityId, tableType, title), true);
    }

    linkAsset(customerId: number,
              entityId: number,
              tableType: PopupTableType,
              title: string = 'BUTTONS.SELECT_ASSET'): Observable<string> {
        return this.open(new AssetAlertData(customerId, entityId, tableType, title), true);
    }

    linkDomain(entityId: number,
               tableType: PopupTableType,
               title: string = 'BUTTONS.SELECT_DOMAINS'): Observable<string> {
        return this.open(new LinkDomainAlertData(entityId, tableType, title), true);
    }

    linkContacts(
        params: LinkContactsParamsWithNumber,
        tableType: PopupTableType,
        findContactsFunc: (params: FindContactsParams) => Promise<JsonReplyWithParams<Contact[], FindContactsParams>>,
        title: string = 'BUTTONS.LINK_TO_CONTACT'
    ): Observable<string> {
        return this.open(new LinkContactAlertData(params, tableType, findContactsFunc, title), true);
    }

    link(link: ContentModel, linkTitle: string = 'BUTTONS.ADD_LINK'): Observable<string> {
        return this.open(new LinkAlertData(linkTitle, link, PopupTableType.LinkedEventsToLink, this), true);
    }

    // link(link: CustomerLink, linkTitle: string = 'BUTTONS.ADD_LINK'): Observable<string> {
    //     return this.open(new LinkAlertData(linkTitle, link, PopupTableType.LinkEventToLink), true);
    // }

    contactRecall(recall: Recall, recallTitle: string = 'BUTTONS.ADD_RECALL'): Observable<string> {
        return this.open(new RecallAlertData(recall, recallTitle, DomainUser.equals));
    }

    customerRecall(recall: Recall, recallTitle: string = 'BUTTONS.ADD_CUSTOMER_RECALL'): Observable<string> {
        return this.open(new CustomerRecallAlertData(recall, recallTitle, DomainUser.equals));
    }

    customerReminer(recall: Recall, recallTitle: string = 'BUTTONS.ADD_REMINDER'): Observable<string> {
        return this.open(new ReminderAlertData(recall, recallTitle, DomainUser.equals));
    }

    value(entity: ContentModel, title: string = 'BUTTONS.SELECT_VALUES'): Observable<string> {
        return this.open(new ValueAlertData(entity, title), true);
    }

    selectInstance(params: LinkManyEntitiesParams,
                   instances: Instance[],
                   title: string = 'BUTTONS.SELECT_INSTANCE'): Observable<string> { // TODO: Translate
        return this.open(new InstanceAlertData(params, instances, title), true);
    }

    instanceLink(link: ProjectLink, title: string = 'BUTTONS.UPDATE_LINK'): Observable<string> {
        return this.open(new InstanceLinkAlertData(link, title));
    }

    selectEvent(params: LinkManyEntitiesParams, events: CustomerEvent[], title: string = 'BUTTONS.SELECT_EVENT'): Observable<string> {
        return this.open(new SelectEventAlertData(params, events, title));
    }

    wftAcquisition(request: AcquisitionRequest, title: string = 'BUTTONS.ADD_WFT_ACQUISITION'): Observable<string> {
        return this.open(new WftAcquisitionAlertData(request, title));
    }

    linkPositionTrend(data: SERPLinkPositionTrend, title: string = 'SERP.LINK_POSITION_TREND', url:string): Observable<string> {
        return this.open(new LinkTrendData(data, title, url), true);
    }

    massiveSoftLegal(link: any[], title: string = 'BUTTONS.SELECT_EVENTS', tab: SoftLegalTab): Observable<string> {
        return this.open(new MassiveSoftLegal(link, title, tab));
    }

    massiveContents(contents: any[], title: string = 'BUTTONS.MASSIVE_CONTENTS'): Observable<string> {
        return this.open(new MassiveContentsData(contents, title, PopupTableType.MassiveContents), true);
    }

    editMany(link: any[], title: string = 'BUTTONS.SELECT_EVENTS'): Observable<string> {
        return this.open(new EditManyData(link, title));
    }

    addDefaultChannel(link: any[], title: string = 'BUTTONS.SELECT_EVENTS'): Observable<string> {
        return this.open(new NewDefaultChannel(link, title));
    }

    urlCheck(link: any, title: string = 'BUTTONS.SELECT_EVENTS'): Observable<string> {
        return this.open(new UpdateChannelCheck(link, title));
    }

    serpTable(serpSource: any, url: string, title: string = 'TABLE_HEADINGS.SERP_TABLE'): Observable<string> {
        return this.open(new ShowSerpTable(serpSource, title, url), true);
    }

    quickSerp(dictionary: any, title: string = 'TABLE_HEADINGS.DICTIONARY'): Observable<string> {
        return this.open(new QuickSerpDictionary(dictionary, title), true);
    }

    myUniumxLink(users: UsersCustomer, title: string = 'MYUniumx', customerName: string): Observable<string> {
        return this.open(new myUniumxTable(users, title, customerName));
    }

    stepDate(params: RequestPreAnalysisDateParams, title: string, stepName): Observable<string> {
        return this.open(new StepDateData(params, title, stepName));
    }

    addNewSerp(query: SERPQuery, title: string,): Observable<string> {
        return this.open(new AddNewSerp(query,title));
    }

    moreInfoSerp(query: SERPQuery, title: string,): Observable<string> {
        return this.open(new MoreInfoSerp(query, title));
    }

    editUrlSerp(query: SERPQuery, title: string,): Observable<string> {
        return this.open(new EditUrlSerp(query,title));
    }

    canStartActivity(data: CustomerStartActivity, title: string = 'Seleziona le date di inizio e fine contratto',): Observable<string> {
        return this.open(new CustomerStartActivityData(data, title));
    }

    channelsChart(data: any, title: string = 'Grafico piano canali',): Observable<string> {
        return this.open(new ChannelChart(data, title, ""), true);
    }

    softLegalShareStatus(data: softLegalStatusInfo, title: string = '', afterTitle: string) {
        return this.open(new SoftLegalShareStatus(data, title, afterTitle))
    }

    channelsSetStatus(data: WidgetContentUpdateParams, title: string = '', afterTitle: string = '') {
        return this.open(new ChannelsSetStatus(data, title, afterTitle))
    }
}


