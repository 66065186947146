// Core
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { HttpClient } from '@angular/common/http';

// Instruments
import { BaseParamsWithContactId, ContentDocumentType, CustomerDocument, Enums, IUploadedDocument, JsonReply } from "../../../instruments/models";
import { IDocumentService } from '../../../instruments/services';
import { ProjectLinkDocument } from '../../../instruments/services/projectLinkDocument';
import { AlertService } from "../../../components/alert/alert.service";
import {Config} from "../../../app.constants";
import { TranslateModule } from '@ngx-translate/core';
import { NothingFoundComponent } from '../../../components/nothing-found/nothing-found.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { RmPaginatorComponent } from '../../../components/rm-paginator/rm-paginator.component';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-customer-documents-table',
    templateUrl: './customer-documents-table.component.html',
    styleUrls: ['./customer-documents-table.component.css'],
    standalone: true,
    imports: [NgIf, RmPaginatorComponent, MatTableModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule, NothingFoundComponent, DatePipe, TranslateModule]
})
export class CustomerDocumentsTableComponent implements OnInit {
    // DOCUMENTS
    contactDocuments: IUploadedDocument[] | CustomerDocument[] = [];

    // TABLE
    displayedColumns: string[];
    dataSource: MatTableDataSource<IUploadedDocument>;

    // INSTRUMENTS
    params: BaseParamsWithContactId = new BaseParamsWithContactId();
    config: any = Config;
    enums: Enums = new Enums();

    @Input() entityId: number;
    @Input() contentId: number;
    @Input() docService: IDocumentService;
    @Input() linkDocService: ProjectLinkDocument;
    @Input() downloadUrl: string;
    @Output() totalDocuments: EventEmitter<number> = new EventEmitter<number>();
    @Output() totalDocumentsForCustomer: EventEmitter<number> = new EventEmitter<number>();

    constructor(private alert: AlertService,
        private httpClient: HttpClient) {
        this.displayedColumns = [
            'fileName',
            'date',
            'actions'
        ];
    }

    ngOnInit() {
        this.params.entityId = this.entityId;

        this.params.ComplexEntityId = {
            "customerId": this.entityId,
            "contentId": this.contentId
        }

        if (this.downloadUrl == "api/ContentDocument/Download/") {
            this.getLinkDocuments();
            this.displayedColumns = [ 'fileName', 'type', 'date', 'actions'];
        } else {
            this.getDocuments();
        }
    }

    getDocuments() {
        this.docService.getDocuments(this.params).then(resp => {
            if (resp.ok) {
                this.contactDocuments = resp.data;
                this.dataSource = new MatTableDataSource(this.contactDocuments);
                this.params = resp.params;
                this.totalDocuments.emit(resp.params.totalItems);
            } else {
                this.alert.error(resp.errorMessage);
            }
        }).catch(resp => { this.alert.error(resp.errorMessage); console.error(resp); });
    }

    getLinkDocuments() {
        this.httpClient.post<JsonReply<CustomerDocument[]>>("/api/ContentDocument/ForContactLink", this.params).subscribe(resp => {
            if (resp.ok) {
                this.contactDocuments = resp.data;
                this.dataSource = new MatTableDataSource(this.contactDocuments as CustomerDocument[]);
                this.params = resp.params;
                this.totalDocuments.emit(resp.params.totalItems);
                let docForCustomer = resp.data.filter(d => d.type == ContentDocumentType.CustomerLinkImage).length;
                this.totalDocumentsForCustomer.emit(docForCustomer)
            } else {
                this.alert.error(resp.errorMessage);
            }
        });
    }

    deleteDocument(documentId: number) {
        if (this.downloadUrl == "api/ContentDocument/Download/") {
            this.httpClient.post<JsonReply<any>>("/api/ContentDocument/DeleteById/", documentId).subscribe(resp => {
                if (resp.ok) {
                    const contactDocuments = this.contactDocuments as IUploadedDocument[];
                    const index = contactDocuments.findIndex((c) => c.id === documentId);
                    if (index !== -1) {
                        this.contactDocuments.splice(index, 1);
                        this.getLinkDocuments();
                    }
                } else {
                    this.alert.error(resp.errorMessage);
                    console.error(resp);
                }
            });
        } else {
            this.alert.prompt().subscribe(response => {
                if (response == 'ok') {
                    this.docService.deleteDocument(documentId).then(resp => {
                        if (resp.ok) {
                            const contactDocuments = this.contactDocuments as IUploadedDocument[];
                            const index = contactDocuments.findIndex((c) => c.id === documentId);
                            if (index !== -1) {
                                this.contactDocuments.splice(index, 1);
                                this.getDocuments();
                            }
                        } else {
                            this.alert.error(resp.errorMessage);
                            console.error(resp);
                        }
                    })
                    .catch(resp => { this.alert.error(resp.errorMessage); console.error(resp); });
                }
            });

        }
    }
}
