import { BRCustomerContentId } from "../api/BRCustomerContentId";

export class AcquisitionRequest {
    public id: number;

    constructor(
        public linkId: BRCustomerContentId = null,
        public linkUrl: string = null,
        public sendToEmail: boolean = false,
        public useUserEmail: boolean = true,
        public email: string = null,
        public caseId: number = null,
        public caseName: string = null,
        public caseNotes: string = null,
    ) {

    }
}