import { LinkManyEntitiesParams } from "../api/LinkEntitiesParams";
import { ContactRelationship } from "src/app/instruments/models/Enums";

export class LinkContactsParams extends LinkManyEntitiesParams {
    public relation: number = ContactRelationship.Relationship;
}

export class LinkContactsParamsWithNumber {
    entityId: number = 0;
    linkedEntitiesIds: any = [];
    public relation: number = ContactRelationship.Relationship;
}