import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JsonReply, BaseParamsWithContactId, JsonReplyWithParams, IUploadedDocument } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

export interface IDocumentService {
    getDocuments(params: BaseParamsWithContactId): Promise<JsonReplyWithParams<IUploadedDocument[], BaseParamsWithContactId>>;
    deleteDocument(id: number): Promise<JsonReply<any>>
}

@Injectable()
@RoutePrefix('/api/ContactDocument')
export class CustomerDocumentService implements IDocumentService {
    constructor(private httpClient: HttpClient) {
    }

    @Route('ForContact')
    public getDocuments(params: BaseParamsWithContactId, url: string = ''): Promise<JsonReplyWithParams<IUploadedDocument[], BaseParamsWithContactId>> {
        return this.httpClient.post<JsonReplyWithParams<IUploadedDocument[], BaseParamsWithContactId>>(url, params).toPromise();
    }

    @Route('DeleteById')
    public deleteDocument(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }
}
