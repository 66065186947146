import { BaseParams, TableOptions, TableActions, UpdateType } from '../models';

export class SoftLegalRecallTableOptions extends TableOptions {
    constructor() {
        super('SoftLegalRecall', false);
        this.buttons = [TableActions.DeleteMany];
        this.paths.basePath = 'SoftLegalRecall';
        this.paths.fetchPath = 'api/SoftLegalRecall/List';
        this.displayedColumns = [
            'selectItems',
            'recallDate',
            'recallResult',
            'succeeded',
        ];
        this.params = new BaseParams();
        this.newParams = new BaseParams();
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'recallDate': {
                label: 'DATE',
                sort: true,
                type: 'dateTime',
                plainText: true
            },
            'recallResult': {
                label: 'NOTES',
                sort: true,
                type: 'longText',
                plainText: false
            },
            'succeeded': {
                label: 'SUCCESS',
                sort: true,
                type: 'boolean',
                plainText: false
            }
        };
        this.update = {
            action: () => {
                return 'recall';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
    }

}



