<mat-form-field class="w-100">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput
           [formControl]="date"
           [matDatepicker]="selector"
           [placeholder]="placeholder"
           [required]="required"
           [max]="max"
           [min]="min">
    <mat-datepicker-toggle matSuffix [for]="selector"></mat-datepicker-toggle>
    <mat-datepicker #selector
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, selector)">
    </mat-datepicker>
</mat-form-field>
