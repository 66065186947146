export class NotificationData {
    constructor(public message: string,
                public hasSpinner: boolean,
                public icon: string) {
    }
}

export class ProgressData extends NotificationData {
    constructor(public title: string) {
        super(title, true, null);
    }
}

export class DoneData extends NotificationData {
    constructor(public title: string, public translateParams: any) {
        super(title, false, 'done_accent');
    }
}

export class WarningData extends NotificationData {
    constructor(public title: string, public translateParams: any) {
        super(title, false, 'warning_accent');
    }
}

export class ErrorData extends NotificationData {
    constructor(public title: string) {
        super(title, false, 'error_accent');
    }
}

export class DisconnectData extends NotificationData {
    constructor(public title: string) {
        super(title, false, 'wifi_off_accent');
    }
}



