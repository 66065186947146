import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import * as HighchartsGantt from "highcharts/highcharts-gantt";
import * as HighchartsStock from 'highcharts/highstock';
import HC_exporting from "highcharts/modules/exporting";
import HCMore from "highcharts/highcharts-more";
import * as moment from 'moment';
import { Config } from './app.constants';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SharedService } from './instruments/services';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
HC_exporting(Highcharts);
HCMore(Highcharts);
HC_exporting(HighchartsGantt);
HC_exporting(HighchartsStock);

@Component({
    selector: 'app-root',
    template: `
    <section>
        <mat-drawer-container>
            <!-- SIDE MENU: Start -->
            <mat-drawer #drawer mode="over" autoFocus="false" class="sidebar-nav" [disableClose]="false">
                <mat-list>
                    <mat-list-item>
                        <a routerLink="/findContacts"
                           class="sidebar-link"
                           routerLinkActive="active-link">
                            <i class="material-icons mr-3">search</i>
                            Find contacts
                        </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <a routerLink="/contact"
                           class="sidebar-link"
                           routerLinkActive="active-link">
                            <i class="material-icons mr-3">person_add</i>
                            Create contact
                        </a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
            </mat-drawer>
            <!-- Main content -->
            <router-outlet></router-outlet>
        </mat-drawer-container>
    </section>
`,
    styles: `
.sidebar-nav {
    color: var(--grey-dark);
    min-width: 300px;
}
::ng-deep .mat-drawer-content {
    overflow: hidden !important;
}
.sidebar-link {
    color: var(--grey-dark);
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.active-link {
    color: var(--primary);
}
.progress-bar-section{
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
    color: var(--primary);
}
`,
    standalone: true,
    imports: [MatSidenavModule, MatListModule, RouterLink, RouterLinkActive, MatDividerModule, RouterOutlet]
})
export class AppComponent implements OnInit {
    public currentUrl: string = '/';
    public config = Config;
    @ViewChild('drawer', { static: true }) sidenav: MatDrawer;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('sideMenu') sideMenu: SideMenuComponent;

    constructor(private router: Router,
                private location: Location,
                private shared: SharedService,
                public translate: TranslateService) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            this.sidenav.close();
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });

        if (window.location.pathname.toLowerCase() != '/' + this.config.RoutingUrls.LOGIN && window.location.pathname.toLowerCase() != '/' ) {
            this.shared.getLoggedUser();
        }     
        this.configureTranslateService();
        const self: AppComponent = this;

        window.addEventListener('beforeunload', function (e) {
            self.beforeUnloadHander(e);
        });

        this.configuringCharts();
    }

    beforeUnloadHander(event) {
        const path = this.location.path(); 

        if (!path.startsWith('contact;'))
            return;

        if (this.shared.loggedUser && this.shared.loggedUserID) {
            navigator.sendBeacon('api/EntityState/UnlockForUser');
        }
    }

    private configureTranslateService(): void {
        this.translate.addLangs(['en', 'it']);
        this.translate.setDefaultLang('it');

        this.translate.onLangChange.subscribe((ev: LangChangeEvent) => {
            moment.locale(ev.lang);
        });

        const browserLang = this.translate.getBrowserLang();
        const currLang = 'it';

        this.translate.use(currLang);
        moment.locale(currLang);
        localStorage.setItem('LOCALE', currLang);
        this.shared.currentLang = currLang;
    }

    private configuringCharts() {
        const HIGHCHARTS_CONFIG = {
            lang: {
                months: [
                    'Gennaio', 'Febbraio', 'Marzo', 'Aprile',
                    'Maggio', 'Giugno', 'Luglio', 'Agosto',
                    'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
                ],
                weekdays: [
                    'Domenica', 'Lunedi', 'Martedi', 'Mercoledi',
                    'Giovedi', 'Venerdi', 'Sabato'
                ],
                shortMonths: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
            },
            rangeSelector: {
                inputEnabled: false
            },
            tooltip: {
                borderWidth: 1
            },
            exporting: {
                sourceWidth: 1000,
                sourceHeight: 550,
                url: 'https://charts.unium.it',
                fallbackToExportServer: true,
                menuItemDefinitions: {
                    downloadPNG: {
                        onclick: function () {
                            this.exportChart({
                                type: 'image/png'
                            }, {
                                chart: {
                                    backgroundColor: 'transparent',
                                    width: this.chartWidth
                                }
                            });
                        }
                    },
                    downloadJPEG: {
                        onclick: function () {
                            this.exportChart({
                                type: 'image/jpeg'
                            }, {
                                chart: {
                                    backgroundColor: '#FFFFFF',
                                    width: this.chartWidth
                                }
                            });
                        }
                    },
                },
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadJPEG"]
                    }
                },
            },
        };
        HighchartsGantt.setOptions(HIGHCHARTS_CONFIG);
        HighchartsStock.setOptions(HIGHCHARTS_CONFIG);
        Highcharts.setOptions(HIGHCHARTS_CONFIG);
    }
}
