import {BaseParamsWithContactId} from "./BaseParamsWithContactId";
import { BRCustomerContentId } from "./BRCustomerContentId";

export class GetContactEventParams extends BaseParamsWithContactId {
    constructor(
        public description: string = null,
        public startDate: Date = null,
        public endDate: Date = null,
        public isAppointment: boolean = false,
        public isHideInChart: boolean = false,
        public complexEntityId: BRCustomerContentId = null
    ) {
        super();
    }
}
