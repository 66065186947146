import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JsonReply, AddQuickSerp, GetQuickSerp, JsonReplyWithParams } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/QuickSERP')

export class QuickSerpService {
    constructor(private httpClient: HttpClient) {
    }

    @Route('Query/Add')
    public addQuickSerp(addSerp: AddQuickSerp, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, addSerp)
            .toPromise();
    }

    @Route('Query/List')
    public getQuickSerp(getSerp: GetQuickSerp, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, getSerp)
            .toPromise();
    }

    @Route('Query/Export')
    public exportQuickSerp(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('Query/Delete')
    public deleteQuickSerp(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('Dictionary/List')
    public getDictionary(url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, null)
            .toPromise();
    }

    @Route('Dictionary/Add')
    public addDictionary(keyword: string, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, keyword)
            .toPromise();
    }

    @Route('Dictionary/Delete')
    public deleteDictionary(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }
}