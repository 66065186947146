import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatRadioButton, MatRadioChange, MatRadioGroup} from '@angular/material/radio';
import {BRCustomerContentId, ContentModel} from '../../../../instruments/models';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'widget-set-channel',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioButton,
    ReactiveFormsModule,
    TranslateModule,
    MatButton,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './widget-set-channel.component.html',
  styleUrl: './widget-set-channel.component.css'
})
export class WidgetSetChannelComponent {
  @Input({required: true}) data: WidgetContentUpdateParams;
  @Output() closeModal: EventEmitter<WidgetContentUpdateParams> = new EventEmitter();

  onSubmit(){
    this.closeModal.emit(this.data);
  }
}


export class WidgetContentUpdateParams{
  id: BRCustomerContentId;
  controlled?: boolean;
  aggregator?:  boolean;

  constructor(contactId: number, contentId: number, controlled: boolean, aggregator: boolean ) {
    this.id = new BRCustomerContentId(contactId, contentId);
    this.controlled = controlled;
    this.aggregator = aggregator;
  }
}
