import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Recall, JsonReply } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/ContactRecall')
export class CustomerRecallsService {
    constructor(private httpClient: HttpClient) {

    }

    @Route('ForContact')
    public getRecalls(contactId: number, url: string = ''): Promise<JsonReply<Recall[]>> {
        return this.httpClient.post<JsonReply<Recall[]>>(url, contactId)
            .toPromise();
    }

    @Route('Add')
    public addRecall(recall: Recall, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, recall)
            .toPromise();
    }

    @Route('Update')
    public updateRecall(recall: Recall, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, recall)
            .toPromise();
    }

    @Route('Delete')
    public deleteRecall(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteRecalls(idList: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, idList)
            .toPromise();
    }

    @Route('DeleteAll')
    public deleteFromContact(contactId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contactId)
            .toPromise();
    }
}
