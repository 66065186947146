import {Component, Input, OnInit} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-nothing-found',
    templateUrl: './nothing-found.component.html',
    styleUrls: ['./nothing-found.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, MatProgressSpinnerModule, TranslateModule]
})
export class NothingFoundComponent implements OnInit {
    @Input() icon: string;
    @Input() innerText: string;
    @Input() color: string;
    constructor() {
    }

    ngOnInit() {
    }

}
