<section class="d-flex justify-content-start h-100 w-100">
    <!-- Hidden input -->
    <input #ngMatFileUploaderInput
           type="file"
           [accept]="acceptMimeTypes"
           (change)="fileSelectionChanged($event)"
           [hidden]="true"
           [disabled]="disabled"/>
    <!-- Select button-->
    <button *ngIf="!file"
            [ngClass]="{'upload-btn-default': defaultStyle, 'transparent': !defaultStyle}"
            (click)="openFileDialog()"
            [disabled]="disabled">
        <span class="upload-file-btn-inner"><ng-content></ng-content></span>
    </button>
    <!-- BUTTONS: Start -->
    <!-- Upload -->
    <div *ngIf="file" class="d-flex" style="width: 100%">
        <!-- Spinner -->
        <!--{{loading}}<div *ngIf="loading" class="d-flex align-items-center justify-content-center mr-4">
            <mat-spinner diameter="25"></mat-spinner>
        </div>-->
        <!-- Cancel -->
        <button mat-raised-button color="primary" style="width: 100%; margin: 0 !important" type="button" class="uniumx-button-small mr-4" (click)="removeFile()" [disabled]="disabled">
            <img src="/app_assets/svg/material-icons/delete.svg">
            <span class="uniumx-button-text">{{'BUTTONS.CANCEL' | translate}}</span>
        </button>
    </div>
    <!-- BUTTONS: End -->
</section>
