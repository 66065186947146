import { BaseParams } from "../api/BaseParams";

export class GetQuickSerp extends BaseParams {

    Id: number;
    QueryUrl: string;
    Description: string;
    Status: number;
    ExecutionDate: Date;
    ErrorResponse: string;
    InsertDate: Date;
    InsertUserId: number;

    constructor() {
        super();
        this.ordering.order = -1;
        this.ordering.orderPropertyName = 'insertDate';
        this.Id = 0;
        this.QueryUrl = null;
        this.Description = null;
        this.Status = 0;
        this.ExecutionDate = null;
        this.ErrorResponse = null;
        this.InsertDate = null;
        this.InsertUserId = 0;
    }
}