import { RichEnum, RichEnumOption } from "../RichEnum";
export class MatrixAddCustomCat {
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixMacroCategory[]) { }
}

export class MatrixDeleteCustomCat {
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixMacroCategory[]) { }
}

export class MatrixMacroCategory {
    macroCategoryId: number;
    contactId: number;
    contentId?: number;
    type: number = 0;
    position: number;
    id: number;
    macroCategoryName: string;
    macroCategoryNameNoSpace?: string;
    weight: number;
    value: number;
    status: boolean;
    subCategoryName?: string;
    subCategories?: MatrixMacroCategory[];
    subCategoryId?: number;
    checked?: boolean;
    categoryId?: number;
    isCustomCategory: boolean;
    entityId?: number;
    enabled?: boolean;
    isCustom?: boolean;
    categoryActive?: boolean;
}

export class SubCategory {
    ContactId: number;
    CategoryId: number;
    Value: number;
}

export class MatrixMacroUpdate {
    weight: number;
    macroCategoryId: number;
}

export class SubCategoryStatus {
    id: number;
    status: boolean;
}

export class MatrixValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public macroCategories: MatrixMacroCategory[]) { }
}

export class MatrixMacroValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public MatrixCategoriesValues: MatrixMacroUpdate[]) { }
}

export class MatrixSubValues {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public contactId: number, public MatrixCategoriesValues: SubCategory[]) { }
}

export class MatrixSubValuesMassive {
    constructor(public contentIds: number[], public customerId: number, public categoryIds: number[]) { }
}

export class MatrixSubStatus {
    map(arg0: (resp: any) => void) {
        throw new Error("Method not implemented.");
    }
    constructor(public entityId: number, public MatrixCategoriesValues: SubCategoryStatus[]) { }
}

export class MacroCategoryWeight extends RichEnum {
    constructor() {
        super(
            'MACRO_CATEGORY_WEIGHT',
            new RichEnumOption(0, "NULL"),
            new RichEnumOption(1, "VERY_LOW"),
            new RichEnumOption(2, "LOW"),
            new RichEnumOption(3, "MEDIUM"),
            new RichEnumOption(4, "HIGH"),
            new RichEnumOption(5, "VERY_HIGH"),
        );
    }
}

export enum MacroCategoryDescription {
    descVALORIPROFESSIONALI = "Valori legati al curriculum e all'esperienza professonale",
    descVALORIMANAGERIALI = "Valori legati al ruolo manageriale e alle doti di leader",
    descVALORIISTITUZIONALI = "Valori derivanti dal rapporto con le istituzioni o al contesto internazionale",
    descVALORIESG = "Valori legati al sociale, filantropia, tematiche sensibili",
    descVALORICULTURALI = "Valori legati alla conoscenza, cultura personale, doti di comunicatore",
    descASPETTIPRIVATI = "Valori collegati alla vita privata o non strettamente professionali",
    descBUSINESS = "Valori legati all'attivit%u00E0 commerciale dell'azienda",
    descCORPORATEIDENTITY = "Valori legati all'identit%u00E0 dell'azienda e alla sua riconoscibilit%u00E0 sul mercato",
    descSTAKEHOLDER = "Valori legati al rapporto con i player chiave all'interno del business dell'azienda",
    descSOCIAL = "Valori legati alla componente ESG, quindi Ambientali, Sociali e di Governance",
    descCOMMUNICATION = "Valori legati alla modalit%u00E0 in cui l'azienda comunica la propria immagine e la propria value proposition",
    descPERFORMANCE = "Valori legati ai risultati ottenuti dalla Societ%u00E0 e alla sua stabilit%u00E0",
};
