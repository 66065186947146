export class ProjectTimeline {

    id: number = 0;

    assessmentStart: Date = null;
    assessmentEnd: Date = null;
    assessmentProgress: number = 0;

    projectStart: Date = null;
    projectEnd: Date = null;
    projectProgress: number = 0;

    deployStart: Date = null;
    deployEnd: Date = null;
    deployProgress: number = 0;

    developmentStart: Date = null;
    developmentEnd: Date = null;
    developmentProgress: number = 0;
    contractProgress: number = 0;

    // FROM CONTACT
    contractStart: Date = null;
    contractEnd: Date = null;
    
    // COMPUTED ON BACKEND
    workingStart: Date = null;
    workingEnd: Date = null;

    constructor(public customerId: number) {

    }
}