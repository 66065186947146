import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { DomainUser, SERPQuery, UserModelAssociation, UsersCustomer } from '../../instruments/models';
import { SERPService } from '../../instruments/services';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-myuniumx-users-settings',
    templateUrl: './myuniumx-users-settings.component.html',
    styleUrls: ['./myuniumx-users-settings.component.css'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatInputModule, FormsModule, NgFor, MatCheckboxModule, MatDialogModule, MatButtonModule, TranslateModule, MatSelectModule]
})
export class MyUniumxUsersSettingsComponent implements OnInit {

    @Input() usersCustomers: UsersCustomer;
    @Output() ok: EventEmitter<boolean> = new EventEmitter<boolean>();

    usersFilter: string = '';
    usersLength: number = 10;
    step: number = 0;
    modelCustomers: UserModelAssociation[];
    detailUser: UserModelAssociation;
    allSerp: SERPQuery[];

    constructor(private serpService: SERPService) { }

    ngOnInit() {
        this.modelCustomers = this.usersCustomers.usersCustomer;
        this.getSERPForCustomer();
    }

    usersCustomerLength(users) {
        if (!this.usersFilter)
            return users.length;

        if (this.usersFilter.length < 3)
            return users.length;

        return users.filter(obj => obj.user.fullName.toLowerCase().includes(this.usersFilter.toLowerCase())).length;
    }

    filterUsersCustomers(users): any[] {
        if (!this.usersFilter)
            return users;

        if (this.usersFilter.length < 3)
            return users;

        return users.filter(obj => obj.user.fullName.toLowerCase().includes(this.usersFilter.toLowerCase()));
    }

    newAssociated(event: MatCheckboxChange, model: UserModelAssociation) {
        if (event.checked) {
            model.serpIdsActive = this.allSerp.map(s => s.id);
            model.matrixIsActive = true;
            model.timelineIsActive = true;
            model.wipIsActive = true;
            model.serpIsActive = true;
            model.softLegalIsActive = true;
            model.documentsIsActive = true;
        }
            
    }

    setAllSerp(event: MatCheckboxChange, model: UserModelAssociation) {
        if (event.checked) {
            model.serpIdsActive = this.allSerp.map(s => s.id);
        }
    }

    settingsForUserId(model: UserModelAssociation) {
        this.detailUser = model;
    }


    private getSERPForCustomer() {
        this.serpService.getQueriesForCustomer(this.usersCustomers.customerId).then(resp => {
            if (resp.ok) {
                this.allSerp = resp.data;

            }
        });
    }

    serpCheck(event: MatCheckboxChange, id: number) {
        if (event.checked) {
            this.detailUser.serpIdsActive.push(id)
        }
        else {
            this.detailUser.serpIdsActive = this.detailUser.serpIdsActive.filter(s => s != id);
        }
    }

    isChecked(user: UserModelAssociation, id: number): boolean {
        return user.serpIdsActive.includes(id);
    }



    _ok() {
        console.log(this.usersCustomers)
        this.ok.emit(true);
    }

}
