/*
 * GoJS v2.3.3 JavaScript Library for HTML Diagrams, https://gojs.net
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation, https://www.nwoods.com.
 * Copyright (C) 1998-2023 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/2.3.3/license.html.
 * DO NOT MODIFY THIS FILE.  DO NOT DISTRIBUTE A MODIFIED COPY OF THE CONTENTS OF THIS FILE.
 */
(function () {
    var t; function aa(a) { var b = 0; return function () { return b < a.length ? { done: !1, value: a[b++] } : { done: !0 } } } function ba(a) { var b = "undefined" != typeof Symbol && Symbol.iterator && a[Symbol.iterator]; return b ? b.call(a) : { next: aa(a) } } function da(a) { for (var b, c = []; !(b = a.next()).done;)c.push(b.value); return c } var ea = "function" == typeof Object.create ? Object.create : function (a) { function b() { } b.prototype = a; return new b }, fa;
    if ("function" == typeof Object.setPrototypeOf) fa = Object.setPrototypeOf; else { var ha; a: { var ia = { a: !0 }, ja = {}; try { ja.__proto__ = ia; ha = ja.a; break a } catch (a) { } ha = !1 } fa = ha ? function (a, b) { a.__proto__ = b; if (a.__proto__ !== b) throw new TypeError(a + " is not extensible"); return a } : null } var ka = fa;
    function la(a, b) { a.prototype = ea(b.prototype); a.prototype.constructor = a; if (ka) ka(a, b); else for (var c in b) if ("prototype" != c) if (Object.defineProperties) { var d = Object.getOwnPropertyDescriptor(b, c); d && Object.defineProperty(a, c, d) } else a[c] = b[c]; a.GA = b.prototype }
    var ma = "undefined" != typeof global && null != global ? global : self, oa = "function" == typeof Object.assign ? Object.assign : function (a, b) { for (var c = 1; c < arguments.length; c++) { var d = arguments[c]; if (d) for (var e in d) Object.prototype.hasOwnProperty.call(d, e) && (a[e] = d[e]) } return a }, pa = "function" == typeof Object.defineProperties ? Object.defineProperty : function (a, b, c) { a != Array.prototype && a != Object.prototype && (a[b] = c.value) };
    function qa(a, b) { if (b) { var c = ma; a = a.split("."); for (var d = 0; d < a.length - 1; d++) { var e = a[d]; e in c || (c[e] = {}); c = c[e] } a = a[a.length - 1]; d = c[a]; b = b(d); b != d && null != b && pa(c, a, { writable: !0, value: b }) } } qa("Object.assign", function (a) { return a || oa }); qa("Array.prototype.fill", function (a) { return a ? a : function (a, c, d) { var b = this.length || 0; 0 > c && (c = Math.max(0, b + c)); if (null == d || d > b) d = b; d = Number(d); 0 > d && (d = Math.max(0, b + d)); for (c = Number(c || 0); c < d; c++)this[c] = a; return this } }); var x = "object" === typeof globalThis && globalThis || "object" === typeof global && global.global === global && global || "object" === typeof self && self.self === self && self || {}; void 0 === x.requestAnimationFrame && (void 0 === x.setImmediate ? x.requestAnimationFrame = function (a) { x.setTimeout(a, 0) } : x.requestAnimationFrame = x.setImmediate); function ra() { } function sa(a, b) { var c = -1; return function () { var d = this, e = arguments; -1 !== c && x.clearTimeout(c); c = ta(function () { c = -1; a.apply(d, e) }, b) } } function ta(a, b) { return x.setTimeout(a, b) }
    function ua(a) { return x.document.createElement(a) } function C(a) { throw Error(a); } function va(a, b) { a = "The object is frozen, so its properties cannot be set: " + a.toString(); void 0 !== b && (a += "  to value: " + b); C(a) } function xa(a, b, c, d) { c = null === c ? "*" : "string" === typeof c ? c : "function" === typeof c && "string" === typeof c.className ? c.className : ""; void 0 !== d && (c += "." + d); C(c + " is not in the range " + b + ": " + a) } function ya(a) { x.console && x.console.log(a) } function za(a) { return "object" === typeof a && null !== a }
    function Aa(a) { return Array.isArray(a) || x.NodeList && a instanceof x.NodeList || x.HTMLCollection && a instanceof x.HTMLCollection } function Ba(a) { return Array.prototype.slice.call(a) } function Ca(a, b, c) { Array.isArray(a) ? b >= a.length ? a.push(c) : a.splice(b, 0, c) : C("Cannot insert an object into an HTMLCollection or NodeList: " + c + " at " + b) } function Da(a, b) { Array.isArray(a) ? b >= a.length ? a.pop() : a.splice(b, 1) : C("Cannot remove an object from an HTMLCollection or NodeList at " + b) }
    function Ea() { var a = Fa.pop(); return void 0 === a ? [] : a } function Ga(a) { a.length = 0; Fa.push(a) } function Ha(a) { if ("function" === typeof a) { if (a.className) return a.className; if (a.name) return a.name; var b = a.toString(); b = b.substring(9, b.indexOf("(")).trim(); if ("" !== b) return a._className = b } else if (za(a) && a.constructor) return Ha(a.constructor); return typeof a }
    function Ia(a) { var b = a; za(a) && (a.text ? b = a.text : a.name ? b = a.name : void 0 !== a.key ? b = a.key : void 0 !== a.id ? b = a.id : a.constructor === Object && (a.Text ? b = a.Text : a.Name ? b = a.Name : void 0 !== a.Key ? b = a.Key : void 0 !== a.Id ? b = a.Id : void 0 !== a.ID && (b = a.ID))); return void 0 === b ? "undefined" : null === b ? "null" : b.toString() } function Ja(a, b) { if (a.hasOwnProperty(b)) return !0; for (a = Object.getPrototypeOf(a); a && a !== Function;) { if (a.hasOwnProperty(b)) return !0; var c = a.sA; if (c && c[b]) return !0; a = Object.getPrototypeOf(a) } return !1 }
    function Ka(a, b, c) { Object.defineProperty(La.prototype, a, { get: b, set: c }) }
    function Ma(a) { for (var b = [], c = 0; 256 > c; c++)b["0123456789abcdef".charAt(c >> 4) + "0123456789abcdef".charAt(c & 15)] = String.fromCharCode(c); a.length % 2 && (a = "0" + a); c = []; for (var d = 0, e = 0; e < a.length; e += 2)c[d++] = b[a.substr(e, 2)]; a = c.join(""); a = "" === a ? "0" : a; b = []; for (c = 0; 256 > c; c++)b[c] = c; for (c = d = 0; 256 > c; c++)d = (d + b[c] + 119) % 256, e = b[c], b[c] = b[d], b[d] = e; d = c = 0; for (var f = "", g = 0; g < a.length; g++)c = (c + 1) % 256, d = (d + b[c]) % 256, e = b[c], b[c] = b[d], b[d] = e, f += String.fromCharCode(a.charCodeAt(g) ^ b[(b[c] + b[d]) % 256]); return f }
    var Na = void 0 !== x.navigator && 0 < x.navigator.userAgent.indexOf("Trident/7"), Pa = void 0 !== x.navigator && void 0 !== x.navigator.platform && null !== x.navigator.platform.match(/(iPhone|iPod|iPad|Mac)/i), Fa = []; Object.freeze([]); ra.className = "Util"; ra.Dx = "32ab5ff3b26f42dc0ed90f21472913b5"; ra.adym = "gojs.net"; ra.vfo = "28e645fdb0"; ra.className = "Util"; function D(a, b, c) { Qa(this); this.j = a; this.wa = b; this.m = c } D.prototype.toString = function () { return "EnumValue." + this.wa };
    function Ra(a, b) { return void 0 === b || null === b || "" === b ? null : a[b] } ma.Object.defineProperties(D.prototype, { classType: { get: function () { return this.j } }, name: { get: function () { return this.wa } }, value: { get: function () { return this.m } } }); D.className = "EnumValue"; function Sa() { this.Yv = [] } Sa.prototype.toString = function () { return this.Yv.join("") }; Sa.prototype.add = function (a) { "" !== a && this.Yv.push(a) }; Sa.className = "StringBuilder";
    function Ta() { } Ta.className = "PropertyCollection";
    var Wa = {
        uA: !1, zA: !1, wA: !1, yA: !1, DA: !1, BA: !1, hx: !1, vA: null, trace: function (a) { x.console && x.console.log(a) }, xA: function (a, b, c, d) {
            a.strokeStyle = "red"; a.fillStyle = "red"; a.font = "8px sans-serif"; a.beginPath(); a.moveTo(-10, 0); a.lineTo(10, 0); a.moveTo(0, -10); a.lineTo(0, 10); a.stroke(); a.setTransform(1, 0, 0, 1, 0, 0); a.scale(c, c); a.transform(b.m11, b.m12, b.m21, b.m22, b.dx, b.dy); a.lineWidth = 2; a.beginPath(); a.moveTo(d.left, d.top + 20); a.lineTo(d.left, d.top); a.lineTo(d.left + 20, d.top); a.moveTo(d.right, d.bottom - 20); a.lineTo(d.right,
                d.bottom); a.lineTo(d.right - 20, d.bottom); a.stroke(); a.fillText("DB: " + Math.round(d.x) + ", " + Math.round(d.y) + ", " + Math.round(d.width) + ", " + Math.round(d.height), d.left, d.top - 5)
        }, tA: function (a) {
            var b = {}, c; for (c in a) {
                b.x = c; if ("licenseKey" !== b.x) {
                    var d = a[b.x]; if (void 0 !== d.prototype) {
                        b.Vl = Object.getOwnPropertyNames(d.prototype); for (var e = { Rj: 0 }; e.Rj < b.Vl.length; e = { Rj: e.Rj }, e.Rj++) {
                            var f = Object.getOwnPropertyDescriptor(d.prototype, b.Vl[e.Rj]); void 0 !== f.get && void 0 === f.set && Object.defineProperty(d.prototype,
                                b.Vl[e.Rj], { set: function (a, b) { return function () { throw Error("Property " + a.Vl[b.Rj] + " of " + a.x + " is read-only."); } }(b, e) })
                        }
                    }
                } b = { Vl: b.Vl, x: b.x }
            }
        }
    }; function Xa() { } Xa.prototype.reset = function () { }; Xa.prototype.next = function () { return !1 }; Xa.prototype.ud = function () { return !1 }; Xa.prototype.first = function () { return null }; Xa.prototype.any = function () { return !1 }; Xa.prototype.all = function () { return !0 }; Xa.prototype.each = function () { return this }; Xa.prototype.map = function () { return this }; Xa.prototype.filter = function () { return this };
    Xa.prototype.$d = function () { }; Xa.prototype.toString = function () { return "EmptyIterator" }; ma.Object.defineProperties(Xa.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return 0 } } }); Xa.prototype.first = Xa.prototype.first; Xa.prototype.hasNext = Xa.prototype.ud; Xa.prototype.next = Xa.prototype.next; Xa.prototype.reset = Xa.prototype.reset; var Ya = null; Xa.className = "EmptyIterator"; Ya = new Xa; function Za(a) { this.key = -1; this.value = a }
    Za.prototype.reset = function () { this.key = -1 }; Za.prototype.next = function () { return -1 === this.key ? (this.key = 0, !0) : !1 }; Za.prototype.ud = function () { return this.next() }; Za.prototype.first = function () { this.key = 0; return this.value }; Za.prototype.any = function (a) { this.key = -1; return a(this.value) }; Za.prototype.all = function (a) { this.key = -1; return a(this.value) }; Za.prototype.each = function (a) { this.key = -1; a(this.value); return this }; Za.prototype.map = function (a) { return new Za(a(this.value)) };
    Za.prototype.filter = function (a) { return a(this.value) ? new Za(this.value) : Ya }; Za.prototype.$d = function () { this.value = null }; Za.prototype.toString = function () { return "SingletonIterator(" + this.value + ")" }; ma.Object.defineProperties(Za.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return 1 } } }); Za.prototype.first = Za.prototype.first; Za.prototype.hasNext = Za.prototype.ud; Za.prototype.next = Za.prototype.next;
    Za.prototype.reset = Za.prototype.reset; Za.className = "SingletonIterator"; function $a(a) { this.Bb = a; this.lf = null; a.Sa = null; this.qa = a.Ja; this.Ya = -1 } $a.prototype.reset = function () { var a = this.Bb; a.Sa = null; this.qa = a.Ja; this.Ya = -1 }; $a.prototype.next = function () { var a = this.Bb; if (a.Ja !== this.qa && 0 > this.key) return !1; a = a.s; var b = a.length, c = ++this.Ya, d = this.lf; if (null !== d) for (; c < b;) { var e = a[c]; if (d(e)) return this.key = this.Ya = c, this.value = e, !0; c++ } else { if (c < b) return this.key = c, this.value = a[c], !0; this.$d() } return !1 };
    $a.prototype.ud = function () { return this.next() }; $a.prototype.first = function () { var a = this.Bb; this.qa = a.Ja; this.Ya = 0; a = a.s; var b = a.length, c = this.lf; if (null !== c) { for (var d = 0; d < b;) { var e = a[d]; if (c(e)) return this.key = this.Ya = d, this.value = e; d++ } return null } return 0 < b ? (a = a[0], this.key = 0, this.value = a) : null }; $a.prototype.any = function (a) { var b = this.Bb; b.Sa = null; this.Ya = -1; b = b.s; for (var c = b.length, d = this.lf, e = 0; e < c; e++) { var f = b[e]; if ((null === d || d(f)) && a(f)) return !0 } return !1 };
    $a.prototype.all = function (a) { var b = this.Bb; b.Sa = null; this.Ya = -1; b = b.s; for (var c = b.length, d = this.lf, e = 0; e < c; e++) { var f = b[e]; if ((null === d || d(f)) && !a(f)) return !1 } return !0 }; $a.prototype.each = function (a) { var b = this.Bb; b.Sa = null; this.Ya = -1; b = b.s; for (var c = b.length, d = this.lf, e = 0; e < c; e++) { var f = b[e]; (null === d || d(f)) && a(f) } return this };
    $a.prototype.map = function (a) { var b = this.Bb; b.Sa = null; this.Ya = -1; var c = []; b = b.s; for (var d = b.length, e = this.lf, f = 0; f < d; f++) { var g = b[f]; (null === e || e(g)) && c.push(a(g)) } a = new F; a.s = c; a.xb(); return a.iterator }; $a.prototype.filter = function (a) { var b = this.Bb; b.Sa = null; this.Ya = -1; var c = []; b = b.s; for (var d = b.length, e = this.lf, f = 0; f < d; f++) { var g = b[f]; (null === e || e(g)) && a(g) && c.push(g) } a = new F; a.s = c; a.xb(); return a.iterator };
    $a.prototype.$d = function () { this.key = -1; this.value = null; this.qa = -1; this.lf = null; this.Bb.Sa = this }; $a.prototype.toString = function () { return "ListIterator@" + this.Ya + "/" + this.Bb.count };
    ma.Object.defineProperties($a.prototype, { iterator: { get: function () { return this } }, predicate: { get: function () { return this.lf }, set: function (a) { this.lf = a } }, count: { get: function () { var a = this.lf; if (null !== a) { for (var b = 0, c = this.Bb.s, d = c.length, e = 0; e < d; e++)a(c[e]) && b++; return b } return this.Bb.s.length } } }); $a.prototype.first = $a.prototype.first; $a.prototype.hasNext = $a.prototype.ud; $a.prototype.next = $a.prototype.next;
    $a.prototype.reset = $a.prototype.reset; $a.className = "ListIterator"; function ab(a) { this.Bb = a; a.Dg = null; this.qa = a.Ja; this.Ya = a.s.length } ab.prototype.reset = function () { var a = this.Bb; a.Dg = null; this.qa = a.Ja; this.Ya = a.s.length }; ab.prototype.next = function () { var a = this.Bb; if (a.Ja !== this.qa && 0 > this.key) return !1; var b = --this.Ya; if (0 <= b) return this.key = b, this.value = a.s[b], !0; this.$d(); return !1 }; ab.prototype.ud = function () { return this.next() };
    ab.prototype.first = function () { var a = this.Bb; this.qa = a.Ja; var b = a.s; this.Ya = a = b.length - 1; return 0 <= a ? (b = b[a], this.key = a, this.value = b) : null }; ab.prototype.any = function (a) { var b = this.Bb; b.Dg = null; b = b.s; var c = b.length; this.Ya = c; for (--c; 0 <= c; c--)if (a(b[c])) return !0; return !1 }; ab.prototype.all = function (a) { var b = this.Bb; b.Dg = null; b = b.s; var c = b.length; this.Ya = c; for (--c; 0 <= c; c--)if (!a(b[c])) return !1; return !0 };
    ab.prototype.each = function (a) { var b = this.Bb; b.Dg = null; b = b.s; var c = b.length; this.Ya = c; for (--c; 0 <= c; c--)a(b[c]); return this }; ab.prototype.map = function (a) { var b = this.Bb; b.Dg = null; var c = []; b = b.s; var d = b.length; this.Ya = d; for (--d; 0 <= d; d--)c.push(a(b[d])); a = new F; a.s = c; a.xb(); return a.iterator }; ab.prototype.filter = function (a) { var b = this.Bb; b.Dg = null; var c = []; b = b.s; var d = b.length; this.Ya = d; for (--d; 0 <= d; d--) { var e = b[d]; a(e) && c.push(e) } a = new F; a.s = c; a.xb(); return a.iterator };
    ab.prototype.$d = function () { this.key = -1; this.value = null; this.qa = -1; this.Bb.Dg = this }; ab.prototype.toString = function () { return "ListIteratorBackwards(" + this.Ya + "/" + this.Bb.count + ")" }; ma.Object.defineProperties(ab.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return this.Bb.s.length } } }); ab.prototype.first = ab.prototype.first; ab.prototype.hasNext = ab.prototype.ud; ab.prototype.next = ab.prototype.next; ab.prototype.reset = ab.prototype.reset;
    ab.className = "ListIteratorBackwards"; function F(a) { Qa(this); this._isFrozen = !1; this.s = []; this.Ja = 0; this.Dg = this.Sa = null; "string" === typeof a && C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type."); void 0 !== a && this.addAll(a) } t = F.prototype; t.xb = function () { var a = this.Ja; a++; 999999999 < a && (a = 0); this.Ja = a }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { this._isFrozen = !1; return this }; t.toString = function () { return "List()#" + bb(this) };
    t.add = function (a) { if (null === a) return this; this._isFrozen && va(this, a); this.s.push(a); this.xb(); return this }; t.push = function (a) { this.add(a) }; t.addAll = function (a) { if (null === a) return this; this._isFrozen && va(this); var b = this.s; if (Aa(a)) for (var c = a.length, d = 0; d < c; d++)b.push(a[d]); else for (a = a.iterator; a.next();)b.push(a.value); this.xb(); return this }; t.clear = function () { this._isFrozen && va(this); this.s.length = 0; this.xb() }; t.contains = function (a) { return null === a ? !1 : -1 !== this.s.indexOf(a) }; t.has = function (a) { return this.contains(a) };
    t.indexOf = function (a) { return null === a ? -1 : this.s.indexOf(a) }; t.L = function (a) { var b = this.s; (0 > a || a >= b.length) && xa(a, "0 <= i < length", F, "elt:i"); return b[a] }; t.get = function (a) { return this.L(a) }; t.Xc = function (a, b) { var c = this.s; (0 > a || a >= c.length) && xa(a, "0 <= i < length", F, "setElt:i"); this._isFrozen && va(this, a); c[a] = b }; t.set = function (a, b) { this.Xc(a, b) }; t.first = function () { var a = this.s; return 0 === a.length ? null : a[0] }; t.fc = function () { var a = this.s, b = a.length; return 0 < b ? a[b - 1] : null };
    t.pop = function () { this._isFrozen && va(this); var a = this.s; return 0 < a.length ? a.pop() : null }; F.prototype.any = function (a) { for (var b = this.s, c = b.length, d = 0; d < c; d++)if (a(b[d])) return !0; return !1 }; F.prototype.all = function (a) { for (var b = this.s, c = b.length, d = 0; d < c; d++)if (!a(b[d])) return !1; return !0 }; F.prototype.each = function (a) { for (var b = this.s, c = b.length, d = 0; d < c; d++)a(b[d]); return this }; F.prototype.map = function (a) { for (var b = new F, c = [], d = this.s, e = d.length, f = 0; f < e; f++)c.push(a(d[f])); b.s = c; b.xb(); return b };
    F.prototype.filter = function (a) { for (var b = new F, c = [], d = this.s, e = d.length, f = 0; f < e; f++) { var g = d[f]; a(g) && c.push(g) } b.s = c; b.xb(); return b }; t = F.prototype; t.Eb = function (a, b) { 0 > a && xa(a, ">= 0", F, "insertAt:i"); this._isFrozen && va(this, a); var c = this.s; a >= c.length ? c.push(b) : c.splice(a, 0, b); this.xb() }; t.remove = function (a) { if (null === a) return !1; this._isFrozen && va(this, a); var b = this.s; a = b.indexOf(a); if (-1 === a) return !1; a === b.length - 1 ? b.pop() : b.splice(a, 1); this.xb(); return !0 }; t.delete = function (a) { return this.remove(a) };
    t.nb = function (a) { var b = this.s; (0 > a || a >= b.length) && xa(a, "0 <= i < length", F, "removeAt:i"); this._isFrozen && va(this, a); a === b.length - 1 ? b.pop() : b.splice(a, 1); this.xb() }; t.removeRange = function (a, b) { var c = this.s, d = c.length; if (0 > a) a = 0; else if (a >= d) return this; if (0 > b) return this; b >= d && (b = d - 1); if (a > b) return this; this._isFrozen && va(this); for (var e = a, f = b + 1; f < d;)c[e++] = c[f++]; c.length = d - (b - a + 1); this.xb(); return this };
    F.prototype.copy = function () { var a = new F, b = this.s; 0 < b.length && (a.s = Array.prototype.slice.call(b)); return a }; t = F.prototype; t.Fa = function () { for (var a = this.s, b = this.count, c = Array(b), d = 0; d < b; d++)c[d] = a[d]; return c }; t.Bv = function () { for (var a = new H, b = this.s, c = this.count, d = 0; d < c; d++)a.add(b[d]); return a }; t.sort = function (a) { this._isFrozen && va(this); this.s.sort(a); this.xb(); return this };
    t.wi = function (a, b, c) { var d = this.s, e = d.length; void 0 === b && (b = 0); void 0 === c && (c = e); this._isFrozen && va(this); var f = c - b; if (1 >= f) return this; (0 > b || b >= e - 1) && xa(b, "0 <= from < length", F, "sortRange:from"); if (2 === f) return c = d[b], e = d[b + 1], 0 < a(c, e) && (d[b] = e, d[b + 1] = c, this.xb()), this; if (0 === b) if (c >= e) d.sort(a); else for (b = d.slice(0, c), b.sort(a), a = 0; a < c; a++)d[a] = b[a]; else if (c >= e) for (c = d.slice(b), c.sort(a), a = b; a < e; a++)d[a] = c[a - b]; else for (e = d.slice(b, c), e.sort(a), a = b; a < c; a++)d[a] = e[a - b]; this.xb(); return this };
    t.reverse = function () { this._isFrozen && va(this); this.s.reverse(); this.xb(); return this };
    ma.Object.defineProperties(F.prototype, {
        _dataArray: { get: function () { return this.s } }, count: { get: function () { return this.s.length } }, size: { get: function () { return this.s.length } }, length: { get: function () { return this.s.length } }, iterator: { get: function () { if (0 >= this.s.length) return Ya; var a = this.Sa; return null !== a ? (a.reset(), a) : new $a(this) } }, iteratorBackwards: {
            get: function () { if (0 >= this.s.length) return Ya; var a = this.Dg; return null !== a ? (a.reset(), a) : new ab(this) }
        }
    }); F.prototype.reverse = F.prototype.reverse; F.prototype.sortRange = F.prototype.wi; F.prototype.sort = F.prototype.sort; F.prototype.toSet = F.prototype.Bv; F.prototype.toArray = F.prototype.Fa; F.prototype.removeRange = F.prototype.removeRange; F.prototype.removeAt = F.prototype.nb; F.prototype["delete"] = F.prototype.delete; F.prototype.remove = F.prototype.remove; F.prototype.insertAt = F.prototype.Eb;
    F.prototype.pop = F.prototype.pop; F.prototype.last = F.prototype.fc; F.prototype.first = F.prototype.first; F.prototype.set = F.prototype.set; F.prototype.setElt = F.prototype.Xc; F.prototype.get = F.prototype.get; F.prototype.elt = F.prototype.L; F.prototype.indexOf = F.prototype.indexOf; F.prototype.has = F.prototype.has; F.prototype.contains = F.prototype.contains; F.prototype.clear = F.prototype.clear; F.prototype.addAll = F.prototype.addAll; F.prototype.push = F.prototype.push; F.prototype.add = F.prototype.add; F.prototype.thaw = F.prototype.ka;
    F.prototype.freeze = F.prototype.freeze; F.className = "List"; function cb(a) { this.ag = a; a.Sa = null; this.qa = a.Ja; this.sa = null } cb.prototype.reset = function () { var a = this.ag; a.Sa = null; this.qa = a.Ja; this.sa = null }; cb.prototype.next = function () { var a = this.ag; if (a.Ja !== this.qa && null === this.key) return !1; var b = this.sa; b = null === b ? a.ea : b.ua; if (null !== b) return this.sa = b, this.value = b.value, this.key = b.key, !0; this.$d(); return !1 }; cb.prototype.ud = function () { return this.next() };
    cb.prototype.first = function () { var a = this.ag; this.qa = a.Ja; a = a.ea; if (null !== a) { this.sa = a; var b = a.value; this.key = a.key; return this.value = b } return null }; cb.prototype.any = function (a) { var b = this.ag; this.sa = b.Sa = null; for (b = b.ea; null !== b;) { if (a(b.value)) return !0; b = b.ua } return !1 }; cb.prototype.all = function (a) { var b = this.ag; this.sa = b.Sa = null; for (b = b.ea; null !== b;) { if (!a(b.value)) return !1; b = b.ua } return !0 }; cb.prototype.each = function (a) { var b = this.ag; this.sa = b.Sa = null; for (b = b.ea; null !== b;)a(b.value), b = b.ua; return this };
    cb.prototype.map = function (a) { var b = this.ag; b.Sa = null; var c = new F; for (b = b.ea; null !== b;)c.add(a(b.value)), b = b.ua; return c.iterator }; cb.prototype.filter = function (a) { var b = this.ag; b.Sa = null; var c = new F; for (b = b.ea; null !== b;) { var d = b.value; a(d) && c.add(d); b = b.ua } return c.iterator }; cb.prototype.$d = function () { this.value = this.key = null; this.qa = -1; this.ag.Sa = this }; cb.prototype.toString = function () { return null !== this.sa ? "SetIterator@" + this.sa.value : "SetIterator" };
    ma.Object.defineProperties(cb.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return this.ag.Nb } } }); cb.prototype.first = cb.prototype.first; cb.prototype.hasNext = cb.prototype.ud; cb.prototype.next = cb.prototype.next; cb.prototype.reset = cb.prototype.reset; cb.className = "SetIterator";
    function H(a) { Qa(this); this._isFrozen = !1; this.Ob = {}; this.Nb = 0; this.Sa = null; this.Ja = 0; this.ff = this.ea = null; "string" === typeof a && C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type."); void 0 !== a && this.addAll(a) } t = H.prototype; t.xb = function () { var a = this.Ja; a++; 999999999 < a && (a = 0); this.Ja = a }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { this._isFrozen = !1; return this }; t.toString = function () { return "Set()#" + bb(this) };
    t.add = function (a) { if (null === a) return this; this._isFrozen && va(this, a); var b = a; za(a) && (b = fb(a)); void 0 === this.Ob[b] && (this.Nb++, a = new ib(a, a), this.Ob[b] = a, b = this.ff, null === b ? this.ea = a : (a.gl = b, b.ua = a), this.ff = a, this.xb()); return this }; t.addAll = function (a) { if (null === a) return this; this._isFrozen && va(this); if (Aa(a)) for (var b = a.length, c = 0; c < b; c++)this.add(a[c]); else for (a = a.iterator; a.next();)this.add(a.value); return this };
    t.contains = function (a) { if (null === a) return !1; var b = a; return za(a) && (b = bb(a), void 0 === b) ? !1 : void 0 !== this.Ob[b] }; t.has = function (a) { return this.contains(a) }; t.Ky = function (a) { if (null === a) return !0; for (a = a.iterator; a.next();)if (!this.contains(a.value)) return !1; return !0 }; t.Ly = function (a) { if (null === a) return !0; for (a = a.iterator; a.next();)if (this.contains(a.value)) return !0; return !1 }; t.first = function () { var a = this.ea; return null === a ? null : a.value };
    H.prototype.any = function (a) { for (var b = this.ea; null !== b;) { if (a(b.value)) return !0; b = b.ua } return !1 }; H.prototype.all = function (a) { for (var b = this.ea; null !== b;) { if (!a(b.value)) return !1; b = b.ua } return !0 }; H.prototype.each = function (a) { for (var b = this.ea; null !== b;)a(b.value), b = b.ua; return this }; H.prototype.map = function (a) { for (var b = new H, c = this.ea; null !== c;)b.add(a(c.value)), c = c.ua; return b }; H.prototype.filter = function (a) { for (var b = new H, c = this.ea; null !== c;) { var d = c.value; a(d) && b.add(d); c = c.ua } return b }; t = H.prototype;
    t.remove = function (a) { if (null === a) return !1; this._isFrozen && va(this, a); var b = a; if (za(a) && (b = bb(a), void 0 === b)) return !1; a = this.Ob[b]; if (void 0 === a) return !1; var c = a.ua, d = a.gl; null !== c && (c.gl = d); null !== d && (d.ua = c); this.ea === a && (this.ea = c); this.ff === a && (this.ff = d); delete this.Ob[b]; this.Nb--; this.xb(); return !0 }; t.delete = function (a) { return this.remove(a) };
    t.ap = function (a) { if (null === a) return this; this._isFrozen && va(this); if (Aa(a)) for (var b = a.length, c = 0; c < b; c++)this.remove(a[c]); else for (a = a.iterator; a.next();)this.remove(a.value); return this }; t.fA = function (a) { if (null === a || 0 === this.count) return this; this._isFrozen && va(this); var b = new H; b.addAll(a); a = []; for (var c = this.iterator; c.next();) { var d = c.value; b.contains(d) || a.push(d) } this.ap(a); return this };
    t.clear = function () { this._isFrozen && va(this); this.Ob = {}; this.Nb = 0; null !== this.Sa && this.Sa.reset(); this.ff = this.ea = null; this.xb() }; H.prototype.copy = function () { var a = new H, b = this.Ob, c; for (c in b) a.add(b[c].value); return a }; H.prototype.Fa = function () { var a = Array(this.Nb), b = this.Ob, c = 0, d; for (d in b) a[c] = b[d].value, c++; return a }; H.prototype.Av = function () { var a = new F, b = this.Ob, c; for (c in b) a.add(b[c].value); return a }; function Qa(a) { a.__gohashid = jb++ }
    function fb(a) { var b = a.__gohashid; void 0 === b && (b = jb++, a.__gohashid = b); return b } function bb(a) { return a.__gohashid } ma.Object.defineProperties(H.prototype, { count: { get: function () { return this.Nb } }, size: { get: function () { return this.Nb } }, iterator: { get: function () { if (0 >= this.Nb) return Ya; var a = this.Sa; return null !== a ? (a.reset(), a) : new cb(this) } } }); H.prototype.toList = H.prototype.Av; H.prototype.toArray = H.prototype.Fa;
    H.prototype.clear = H.prototype.clear; H.prototype.retainAll = H.prototype.fA; H.prototype.removeAll = H.prototype.ap; H.prototype["delete"] = H.prototype.delete; H.prototype.remove = H.prototype.remove; H.prototype.first = H.prototype.first; H.prototype.containsAny = H.prototype.Ly; H.prototype.containsAll = H.prototype.Ky; H.prototype.has = H.prototype.has; H.prototype.contains = H.prototype.contains; H.prototype.addAll = H.prototype.addAll; H.prototype.add = H.prototype.add; H.prototype.thaw = H.prototype.ka; H.prototype.freeze = H.prototype.freeze;
    var jb = 1; H.className = "Set"; H.uniqueHash = Qa; H.hashIdUnique = fb; H.hashId = bb; function kb(a) { this.ma = a; this.qa = a.Ja; this.sa = null } kb.prototype.reset = function () { this.qa = this.ma.Ja; this.sa = null }; kb.prototype.next = function () { var a = this.ma; if (a.Ja !== this.qa && null === this.key) return !1; var b = this.sa; b = null === b ? a.ea : b.ua; if (null !== b) return this.sa = b, this.value = this.key = a = b.key, !0; this.$d(); return !1 }; kb.prototype.ud = function () { return this.next() };
    kb.prototype.first = function () { var a = this.ma; this.qa = a.Ja; a = a.ea; return null !== a ? (this.sa = a, this.value = this.key = a = a.key) : null }; kb.prototype.any = function (a) { var b = this.ma; this.sa = null; for (b = b.ea; null !== b;) { if (a(b.key)) return !0; b = b.ua } return !1 }; kb.prototype.all = function (a) { var b = this.ma; this.sa = null; for (b = b.ea; null !== b;) { if (!a(b.key)) return !1; b = b.ua } return !0 }; kb.prototype.each = function (a) { var b = this.ma; this.sa = null; for (b = b.ea; null !== b;)a(b.key), b = b.ua; return this };
    kb.prototype.map = function (a) { var b = this.ma; this.sa = null; var c = new F; for (b = b.ea; null !== b;)c.add(a(b.key)), b = b.ua; return c.iterator }; kb.prototype.filter = function (a) { var b = this.ma; this.sa = null; var c = new F; for (b = b.ea; null !== b;) { var d = b.key; a(d) && c.add(d); b = b.ua } return c.iterator }; kb.prototype.$d = function () { this.value = this.key = null; this.qa = -1 }; kb.prototype.toString = function () { return null !== this.sa ? "MapKeySetIterator@" + this.sa.value : "MapKeySetIterator" };
    ma.Object.defineProperties(kb.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return this.ma.Nb } } }); kb.prototype.first = kb.prototype.first; kb.prototype.hasNext = kb.prototype.ud; kb.prototype.next = kb.prototype.next; kb.prototype.reset = kb.prototype.reset; kb.className = "MapKeySetIterator"; function lb(a) { H.call(this); Qa(this); this._isFrozen = !0; this.ma = a } la(lb, H); t = lb.prototype; t.freeze = function () { return this }; t.ka = function () { return this };
    t.toString = function () { return "MapKeySet(" + this.ma.toString() + ")" }; t.add = function () { C("This Set is read-only: " + this.toString()) }; t.contains = function (a) { return this.ma.contains(a) }; t.has = function (a) { return this.contains(a) }; t.remove = function () { C("This Set is read-only: " + this.toString()) }; t.delete = function (a) { return this.remove(a) }; t.clear = function () { C("This Set is read-only: " + this.toString()) }; t.first = function () { var a = this.ma.ea; return null !== a ? a.key : null };
    lb.prototype.any = function (a) { for (var b = this.ma.ea; null !== b;) { if (a(b.key)) return !0; b = b.ua } return !1 }; lb.prototype.all = function (a) { for (var b = this.ma.ea; null !== b;) { if (!a(b.key)) return !1; b = b.ua } return !0 }; lb.prototype.each = function (a) { for (var b = this.ma.ea; null !== b;)a(b.key), b = b.ua; return this }; lb.prototype.map = function (a) { for (var b = new H, c = this.ma.ea; null !== c;)b.add(a(c.key)), c = c.ua; return b }; lb.prototype.filter = function (a) { for (var b = new H, c = this.ma.ea; null !== c;) { var d = c.key; a(d) && b.add(d); c = c.ua } return b };
    lb.prototype.copy = function () { return new lb(this.ma) }; lb.prototype.Bv = function () { var a = new H, b = this.ma.Ob, c; for (c in b) a.add(b[c].key); return a }; lb.prototype.Fa = function () { var a = this.ma.Ob, b = Array(this.ma.Nb), c = 0, d; for (d in a) b[c] = a[d].key, c++; return b }; lb.prototype.Av = function () { var a = new F, b = this.ma.Ob, c; for (c in b) a.add(b[c].key); return a };
    ma.Object.defineProperties(lb.prototype, { count: { get: function () { return this.ma.Nb } }, size: { get: function () { return this.ma.Nb } }, iterator: { get: function () { return 0 >= this.ma.Nb ? Ya : new kb(this.ma) } } }); lb.prototype.toList = lb.prototype.Av; lb.prototype.toArray = lb.prototype.Fa; lb.prototype.toSet = lb.prototype.Bv; lb.prototype.first = lb.prototype.first; lb.prototype.clear = lb.prototype.clear; lb.prototype["delete"] = lb.prototype.delete;
    lb.prototype.remove = lb.prototype.remove; lb.prototype.has = lb.prototype.has; lb.prototype.contains = lb.prototype.contains; lb.prototype.add = lb.prototype.add; lb.prototype.thaw = lb.prototype.ka; lb.prototype.freeze = lb.prototype.freeze; lb.className = "MapKeySet"; function mb(a) { this.ma = a; a.ef = null; this.qa = a.Ja; this.sa = null } mb.prototype.reset = function () { var a = this.ma; a.ef = null; this.qa = a.Ja; this.sa = null };
    mb.prototype.next = function () { var a = this.ma; if (a.Ja !== this.qa && null === this.key) return !1; var b = this.sa; b = null === b ? a.ea : b.ua; if (null !== b) return this.sa = b, this.value = b.value, this.key = b.key, !0; this.$d(); return !1 }; mb.prototype.ud = function () { return this.next() }; mb.prototype.first = function () { var a = this.ma; this.qa = a.Ja; a = a.ea; if (null !== a) { this.sa = a; var b = a.value; this.key = a.key; return this.value = b } return null };
    mb.prototype.any = function (a) { var b = this.ma; this.sa = b.ef = null; for (b = b.ea; null !== b;) { if (a(b.value)) return !0; b = b.ua } return !1 }; mb.prototype.all = function (a) { var b = this.ma; this.sa = b.ef = null; for (b = b.ea; null !== b;) { if (!a(b.value)) return !1; b = b.ua } return !0 }; mb.prototype.each = function (a) { var b = this.ma; this.sa = b.ef = null; for (b = b.ea; null !== b;)a(b.value), b = b.ua; return this }; mb.prototype.map = function (a) { var b = this.ma; this.sa = b.ef = null; var c = new F; for (b = b.ea; null !== b;)c.add(a(b.value)), b = b.ua; return c.iterator };
    mb.prototype.filter = function (a) { var b = this.ma; this.sa = b.ef = null; var c = new F; for (b = b.ea; null !== b;) { var d = b.value; a(d) && c.add(d); b = b.ua } return c.iterator }; mb.prototype.$d = function () { this.value = this.key = null; this.qa = -1; this.ma.ef = this }; mb.prototype.toString = function () { return null !== this.sa ? "MapValueSetIterator@" + this.sa.value : "MapValueSetIterator" }; ma.Object.defineProperties(mb.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return this.ma.Nb } } });
    mb.prototype.first = mb.prototype.first; mb.prototype.hasNext = mb.prototype.ud; mb.prototype.next = mb.prototype.next; mb.prototype.reset = mb.prototype.reset; mb.className = "MapValueSetIterator"; function ib(a, b) { this.key = a; this.value = b; this.gl = this.ua = null } ib.prototype.toString = function () { return "{" + this.key + ":" + this.value + "}" }; ib.className = "KeyValuePair"; function nb(a) { this.ma = a; a.Sa = null; this.qa = a.Ja; this.sa = null } nb.prototype.reset = function () { var a = this.ma; a.Sa = null; this.qa = a.Ja; this.sa = null };
    nb.prototype.next = function () { var a = this.ma; if (a.Ja !== this.qa && null === this.key) return !1; var b = this.sa; b = null === b ? a.ea : b.ua; if (null !== b) return this.sa = b, this.key = b.key, this.value = b.value, !0; this.$d(); return !1 }; nb.prototype.ud = function () { return this.next() }; nb.prototype.first = function () { var a = this.ma; this.qa = a.Ja; a = a.ea; return null !== a ? (this.sa = a, this.key = a.key, this.value = a.value, a) : null }; nb.prototype.any = function (a) { var b = this.ma; this.sa = b.Sa = null; for (b = b.ea; null !== b;) { if (a(b)) return !0; b = b.ua } return !1 };
    nb.prototype.all = function (a) { var b = this.ma; this.sa = b.Sa = null; for (b = b.ea; null !== b;) { if (!a(b)) return !1; b = b.ua } return !0 }; nb.prototype.each = function (a) { var b = this.ma; this.sa = b.Sa = null; for (b = b.ea; null !== b;)a(b), b = b.ua; return this }; nb.prototype.map = function (a) { var b = this.ma; this.sa = b.Sa = null; var c = new F; for (b = b.ea; null !== b;)c.add(a(b)), b = b.ua; return c.iterator }; nb.prototype.filter = function (a) { var b = this.ma; this.sa = b.Sa = null; var c = new F; for (b = b.ea; null !== b;)a(b) && c.add(b), b = b.ua; return c.iterator };
    nb.prototype.$d = function () { this.value = this.key = null; this.qa = -1; this.ma.Sa = this }; nb.prototype.toString = function () { return null !== this.sa ? "MapIterator@" + this.sa : "MapIterator" }; ma.Object.defineProperties(nb.prototype, { iterator: { get: function () { return this } }, count: { get: function () { return this.ma.Nb } } }); nb.prototype.first = nb.prototype.first; nb.prototype.hasNext = nb.prototype.ud; nb.prototype.next = nb.prototype.next; nb.prototype.reset = nb.prototype.reset;
    nb.className = "MapIterator"; function ob(a) { Qa(this); this._isFrozen = !1; this.Ob = {}; this.Nb = 0; this.ef = this.Sa = null; this.Ja = 0; this.ff = this.ea = null; "string" === typeof a && C("Error: Since 2.0 List/Map/Set constructors no longer take an argument that enforces type."); void 0 !== a && this.addAll(a) } t = ob.prototype; t.xb = function () { var a = this.Ja; a++; 999999999 < a && (a = 0); this.Ja = a }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { this._isFrozen = !1; return this }; t.toString = function () { return "Map()#" + bb(this) };
    t.add = function (a, b) { this._isFrozen && va(this, a); var c = a; za(a) && (c = fb(a)); var d = this.Ob[c]; void 0 === d ? (this.Nb++, a = new ib(a, b), this.Ob[c] = a, c = this.ff, null === c ? this.ea = a : (a.gl = c, c.ua = a), this.ff = a, this.xb()) : d.value = b; return this }; t.set = function (a, b) { return this.add(a, b) };
    t.addAll = function (a) { if (null === a) return this; if (Aa(a)) for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; this.add(d.key, d.value) } else if (a instanceof ob) for (a = a.iterator; a.next();)this.add(a.key, a.value); else for (a = a.iterator; a.next();)b = a.value, this.add(b.key, b.value); return this }; t.first = function () { return this.ea }; ob.prototype.any = function (a) { for (var b = this.ea; null !== b;) { if (a(b)) return !0; b = b.ua } return !1 }; ob.prototype.all = function (a) { for (var b = this.ea; null !== b;) { if (!a(b)) return !1; b = b.ua } return !0 };
    ob.prototype.each = function (a) { for (var b = this.ea; null !== b;)a(b), b = b.ua; return this }; ob.prototype.map = function (a) { for (var b = new ob, c = this.ea; null !== c;)b.add(c.key, a(c)), c = c.ua; return b }; ob.prototype.filter = function (a) { for (var b = new ob, c = this.ea; null !== c;)a(c) && b.add(c.key, c.value), c = c.ua; return b }; t = ob.prototype; t.contains = function (a) { var b = a; return za(a) && (b = bb(a), void 0 === b) ? !1 : void 0 !== this.Ob[b] }; t.has = function (a) { return this.contains(a) };
    t.I = function (a) { var b = a; if (za(a) && (b = bb(a), void 0 === b)) return null; a = this.Ob[b]; return void 0 === a ? null : a.value }; t.get = function (a) { return this.I(a) }; t.remove = function (a) { if (null === a) return !1; this._isFrozen && va(this, a); var b = a; if (za(a) && (b = bb(a), void 0 === b)) return !1; a = this.Ob[b]; if (void 0 === a) return !1; var c = a.ua, d = a.gl; null !== c && (c.gl = d); null !== d && (d.ua = c); this.ea === a && (this.ea = c); this.ff === a && (this.ff = d); delete this.Ob[b]; this.Nb--; this.xb(); return !0 }; t.delete = function (a) { return this.remove(a) };
    t.clear = function () { this._isFrozen && va(this); this.Ob = {}; this.Nb = 0; null !== this.Sa && this.Sa.reset(); null !== this.ef && this.ef.reset(); this.ff = this.ea = null; this.xb() }; ob.prototype.copy = function () { var a = new ob, b = this.Ob, c; for (c in b) { var d = b[c]; a.add(d.key, d.value) } return a }; ob.prototype.Fa = function () { var a = this.Ob, b = Array(this.Nb), c = 0, d; for (d in a) { var e = a[d]; b[c] = new ib(e.key, e.value); c++ } return b }; ob.prototype.If = function () { return new lb(this) };
    ma.Object.defineProperties(ob.prototype, {
        count: { get: function () { return this.Nb } }, size: { get: function () { return this.Nb } }, iterator: { get: function () { if (0 >= this.count) return Ya; var a = this.Sa; return null !== a ? (a.reset(), a) : new nb(this) } }, iteratorKeys: { get: function () { return 0 >= this.count ? Ya : new kb(this) } }, iteratorValues: {
            get: function () {
                if (0 >= this.count) return Ya;
                var a = this.ef; return null !== a ? (a.reset(), a) : new mb(this)
            }
        }
    }); ob.prototype.toKeySet = ob.prototype.If; ob.prototype.toArray = ob.prototype.Fa; ob.prototype.clear = ob.prototype.clear; ob.prototype["delete"] = ob.prototype.delete; ob.prototype.remove = ob.prototype.remove; ob.prototype.get = ob.prototype.get; ob.prototype.getValue = ob.prototype.I; ob.prototype.has = ob.prototype.has; ob.prototype.contains = ob.prototype.contains; ob.prototype.first = ob.prototype.first; ob.prototype.addAll = ob.prototype.addAll;
    ob.prototype.set = ob.prototype.set; ob.prototype.add = ob.prototype.add; ob.prototype.thaw = ob.prototype.ka; ob.prototype.freeze = ob.prototype.freeze; ob.className = "Map"; function I(a, b) { void 0 === a ? this.y = this.x = 0 : "number" === typeof a && "number" === typeof b ? (this.x = a, this.y = b) : C("Invalid arguments to Point constructor: " + a + ", " + b); this._isFrozen = !1 } var pb, qb, rb, sb, tb; I.prototype.assign = function (a) { this.x = a.x; this.y = a.y; return this }; I.prototype.h = function (a, b) { this.x = a; this.y = b; return this };
    I.prototype.og = function (a, b) { this.x = a; this.y = b; return this }; I.prototype.set = function (a) { this.x = a.x; this.y = a.y; return this }; I.prototype.copy = function () { var a = new I; a.x = this.x; a.y = this.y; return a }; t = I.prototype; t.ha = function () { this._isFrozen = !0; Object.freeze(this); return this }; t.J = function () { return this._isFrozen || Object.isFrozen(this) ? this : this.copy().freeze() }; t.freeze = function () { this._isFrozen = !0; return this };
    t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; return this }; function ub(a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); return new I(c, e) } return new I } function vb(a) { return a.x.toString() + " " + a.y.toString() } t.toString = function () { return "Point(" + this.x + "," + this.y + ")" }; t.C = function (a) { return a instanceof I ? this.x === a.x && this.y === a.y : !1 };
    t.ei = function (a, b) { return this.x === a && this.y === b }; t.ab = function (a) { return K.B(this.x, a.x) && K.B(this.y, a.y) }; t.add = function (a) { this.x += a.x; this.y += a.y; return this }; t.ne = function (a) { this.x -= a.x; this.y -= a.y; return this }; t.offset = function (a, b) { this.x += a; this.y += b; return this };
    I.prototype.rotate = function (a) { if (0 === a) return this; var b = this.x, c = this.y; if (0 === b && 0 === c) return this; 360 <= a ? a -= 360 : 0 > a && (a += 360); if (90 === a) { a = 0; var d = 1 } else 180 === a ? (a = -1, d = 0) : 270 === a ? (a = 0, d = -1) : (d = a * Math.PI / 180, a = Math.cos(d), d = Math.sin(d)); this.x = a * b - d * c; this.y = d * b + a * c; return this }; t = I.prototype; t.scale = function (a, b) { this.x *= a; this.y *= b; return this }; t.yf = function (a) { var b = a.x - this.x; a = a.y - this.y; return b * b + a * a }; t.td = function (a, b) { a -= this.x; b -= this.y; return a * a + b * b };
    t.normalize = function () { var a = this.x, b = this.y, c = Math.sqrt(a * a + b * b); 0 < c && (this.x = a / c, this.y = b / c); return this }; t.$a = function (a) { return wb(a.x - this.x, a.y - this.y) }; t.direction = function (a, b) { return wb(a - this.x, b - this.y) }; function wb(a, b) { if (0 === a) return 0 < b ? 90 : 0 > b ? 270 : 0; if (0 === b) return 0 < a ? 0 : 180; if (isNaN(a) || isNaN(b)) return 0; var c = 180 * Math.atan(Math.abs(b / a)) / Math.PI; 0 > a ? c = 0 > b ? c + 180 : 180 - c : 0 > b && (c = 360 - c); return c } t.Jy = function (a, b) { return K.Bl(a.x, a.y, b.x, b.y, this.x, this.y) };
    t.Wz = function (a, b, c, d) { K.lh(a, b, c, d, this.x, this.y, this); return this }; t.Xz = function (a, b) { K.lh(a.x, a.y, b.x, b.y, this.x, this.y, this); return this }; t.kA = function (a, b, c, d) { K.Jo(this.x, this.y, a, b, c, d, this); return this }; t.lA = function (a, b) { K.Jo(this.x, this.y, a.x, a.y, b.width, b.height, this); return this }; t.ui = function (a, b) { this.x = a.x + b.x * a.width + b.offsetX; this.y = a.y + b.y * a.height + b.offsetY; return this }; t.vi = function (a, b, c, d, e) { this.x = a + e.x * c + e.offsetX; this.y = b + e.y * d + e.offsetY; return this };
    t.transform = function (a) { a.Ga(this); return this }; function xb(a, b) { b.Ld(a); return a } function yb(a, b, c, d, e, f) { var g = e - c, h = f - d, k = g * g + h * h; c -= a; d -= b; var l = -c * g - d * h; if (0 >= l || l >= k) return g = e - a, h = f - b, Math.min(c * c + d * d, g * g + h * h); a = g * d - h * c; return a * a / k } function zb(a, b, c, d) { a = c - a; b = d - b; return a * a + b * b } function Ab(a, b, c, d) { a = c - a; b = d - b; if (0 === a) return 0 < b ? 90 : 0 > b ? 270 : 0; if (0 === b) return 0 < a ? 0 : 180; if (isNaN(a) || isNaN(b)) return 0; d = 180 * Math.atan(Math.abs(b / a)) / Math.PI; 0 > a ? d = 0 > b ? d + 180 : 180 - d : 0 > b && (d = 360 - d); return d }
    t.w = function () { return isFinite(this.x) && isFinite(this.y) }; I.alloc = function () { var a = Bb.pop(); return void 0 === a ? new I : a }; I.allocAt = function (a, b) { var c = Bb.pop(); if (void 0 === c) return new I(a, b); c.x = a; c.y = b; return c }; I.free = function (a) { Bb.push(a) }; I.prototype.isReal = I.prototype.w; I.prototype.setSpot = I.prototype.vi; I.prototype.setRectSpot = I.prototype.ui; I.prototype.snapToGridPoint = I.prototype.lA; I.prototype.snapToGrid = I.prototype.kA; I.prototype.projectOntoLineSegmentPoint = I.prototype.Xz;
    I.prototype.projectOntoLineSegment = I.prototype.Wz; I.intersectingLineSegments = function (a, b, c, d, e, f, g, h) { return K.Ml(a, b, c, d, e, f, g, h) }; I.prototype.compareWithLineSegmentPoint = I.prototype.Jy; I.compareWithLineSegment = function (a, b, c, d, e, f) { return K.Bl(a, b, c, d, e, f) }; I.prototype.direction = I.prototype.direction; I.prototype.directionPoint = I.prototype.$a; I.prototype.normalize = I.prototype.normalize; I.prototype.distanceSquared = I.prototype.td; I.prototype.distanceSquaredPoint = I.prototype.yf; I.prototype.scale = I.prototype.scale;
    I.prototype.rotate = I.prototype.rotate; I.prototype.offset = I.prototype.offset; I.prototype.subtract = I.prototype.ne; I.prototype.add = I.prototype.add; I.prototype.equalsApprox = I.prototype.ab; I.prototype.equalTo = I.prototype.ei; I.prototype.equals = I.prototype.C; I.prototype.set = I.prototype.set; I.prototype.setTo = I.prototype.og; var Bb = []; I.className = "Point"; I.parse = ub; I.stringify = vb; I.distanceLineSegmentSquared = yb; I.distanceSquared = zb; I.direction = Ab; I.Origin = pb = (new I(0, 0)).ha();
    I.InfiniteTopLeft = qb = (new I(-Infinity, -Infinity)).ha(); I.InfiniteBottomRight = rb = (new I(Infinity, Infinity)).ha(); I.SixPoint = sb = (new I(6, 6)).ha(); I.NoPoint = tb = (new I(NaN, NaN)).ha(); I.parse = ub; I.stringify = vb; I.distanceLineSegmentSquared = yb; I.distanceSquared = zb; I.direction = Ab;
    function L(a, b) { void 0 === a ? this.height = this.width = 0 : "number" === typeof a && (0 <= a || isNaN(a)) && "number" === typeof b && (0 <= b || isNaN(b)) ? (this.width = a, this.height = b) : C("Invalid arguments to Size constructor: " + a + ", " + b); this._isFrozen = !1 } var Cb, Gb, Hb, Ib, Jb, Kb, Lb; L.prototype.assign = function (a) { this.width = a.width; this.height = a.height; return this }; L.prototype.h = function (a, b) { this.width = a; this.height = b; return this }; L.prototype.og = function (a, b) { this.width = a; this.height = b; return this };
    L.prototype.set = function (a) { this.width = a.width; this.height = a.height; return this }; L.prototype.copy = function () { var a = new L; a.width = this.width; a.height = this.height; return a }; t = L.prototype; t.ha = function () { this._isFrozen = !0; Object.freeze(this); return this }; t.J = function () { return this._isFrozen || Object.isFrozen(this) ? this : this.copy().freeze() }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; return this };
    function Mb(a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); return new L(c, e) } return new L } function Nb(a) { return a.width.toString() + " " + a.height.toString() } t.toString = function () { return "Size(" + this.width + "," + this.height + ")" }; t.C = function (a) { return a instanceof L ? this.width === a.width && this.height === a.height : !1 }; t.ei = function (a, b) { return this.width === a && this.height === b };
    t.ab = function (a) { return K.B(this.width, a.width) && K.B(this.height, a.height) }; t.yc = function (a, b) { a = this.width + a; this.width = 0 <= a ? a : 0; b = this.height + b; this.height = 0 <= b ? b : 0; return this }; t.w = function () { return isFinite(this.width) && isFinite(this.height) }; L.alloc = function () { var a = Ob.pop(); return void 0 === a ? new L : a }; L.free = function (a) { Ob.push(a) }; L.prototype.isReal = L.prototype.w; L.prototype.inflate = L.prototype.yc; L.prototype.equalsApprox = L.prototype.ab; L.prototype.equalTo = L.prototype.ei;
    L.prototype.equals = L.prototype.C; L.prototype.set = L.prototype.set; L.prototype.setTo = L.prototype.og; var Ob = []; L.className = "Size"; L.parse = Mb; L.stringify = Nb; L.ZeroSize = Cb = (new L(0, 0)).ha(); L.OneSize = Gb = (new L(1, 1)).ha(); L.SixSize = Hb = (new L(6, 6)).ha(); L.EightSize = Ib = (new L(8, 8)).ha(); L.TenSize = Jb = (new L(10, 10)).ha(); L.InfiniteSize = Kb = (new L(Infinity, Infinity)).ha(); L.NoSize = Lb = (new L(NaN, NaN)).ha(); L.parse = Mb; L.stringify = Nb;
    function N(a, b, c, d) {
        void 0 === a ? this.height = this.width = this.y = this.x = 0 : "number" === typeof a && "number" === typeof b && "number" === typeof c && (0 <= c || isNaN(c)) && "number" === typeof d && (0 <= d || isNaN(d)) ? (this.x = a, this.y = b, this.width = c, this.height = d) : a instanceof I ? (c = a.x, a = a.y, b instanceof I ? (d = b.x, b = b.y, this.x = Math.min(c, d), this.y = Math.min(a, b), this.width = Math.abs(c - d), this.height = Math.abs(a - b)) : b instanceof L ? (this.x = c, this.y = a, this.width = b.width, this.height = b.height) : C("Incorrect second argument supplied to Rect constructor " +
            b)) : C("Invalid arguments to Rect constructor: " + a + ", " + b + ", " + c + ", " + d); this._isFrozen = !1
    } t = N.prototype; t.assign = function (a) { this.x = a.x; this.y = a.y; this.width = a.width; this.height = a.height; return this }; t.h = function (a, b, c, d) { this.x = a; this.y = b; this.width = c; this.height = d; return this }; function Pb(a, b, c) { a.width = b; a.height = c } t.og = function (a, b, c, d) { this.x = a; this.y = b; this.width = c; this.height = d; return this }; t.set = function (a) { this.x = a.x; this.y = a.y; this.width = a.width; this.height = a.height; return this };
    t.Yc = function (a) { this.x = a.x; this.y = a.y; return this }; t.jA = function (a) { this.width = a.width; this.height = a.height; return this }; N.prototype.copy = function () { var a = new N; a.x = this.x; a.y = this.y; a.width = this.width; a.height = this.height; return a }; t = N.prototype; t.ha = function () { this._isFrozen = !0; Object.freeze(this); return this }; t.J = function () { return this._isFrozen || Object.isFrozen(this) ? this : this.copy().freeze() }; t.freeze = function () { this._isFrozen = !0; return this };
    t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; return this }; function Qb(a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); for (var f = 0; "" === a[b];)b++; (d = a[b++]) && (f = parseFloat(d)); for (var g = 0; "" === a[b];)b++; (d = a[b++]) && (g = parseFloat(d)); return new N(c, e, f, g) } return new N }
    function Rb(a) { return a.x.toString() + " " + a.y.toString() + " " + a.width.toString() + " " + a.height.toString() } t.toString = function () { return "Rect(" + this.x + "," + this.y + "," + this.width + "," + this.height + ")" }; t.C = function (a) { return a instanceof N ? this.x === a.x && this.y === a.y && this.width === a.width && this.height === a.height : !1 }; t.ei = function (a, b, c, d) { return this.x === a && this.y === b && this.width === c && this.height === d }; t.ab = function (a) { return K.B(this.x, a.x) && K.B(this.y, a.y) && K.B(this.width, a.width) && K.B(this.height, a.height) };
    function Ub(a, b) { return K.$(a.x, b.x) && K.$(a.y, b.y) && K.$(a.width, b.width) && K.$(a.height, b.height) } t.ca = function (a) { return this.x <= a.x && this.x + this.width >= a.x && this.y <= a.y && this.y + this.height >= a.y }; t.Me = function (a) { return this.x <= a.x && a.x + a.width <= this.x + this.width && this.y <= a.y && a.y + a.height <= this.y + this.height }; t.contains = function (a, b, c, d) { void 0 === c && (c = 0); void 0 === d && (d = 0); return this.x <= a && a + c <= this.x + this.width && this.y <= b && b + d <= this.y + this.height }; t.offset = function (a, b) { this.x += a; this.y += b; return this };
    t.yc = function (a, b) { return Vb(this, b, a, b, a) }; t.yo = function (a) { return Vb(this, a.top, a.right, a.bottom, a.left) }; t.yv = function (a) { return Vb(this, -a.top, -a.right, -a.bottom, -a.left) }; t.zz = function (a, b, c, d) { return Vb(this, a, b, c, d) }; function Vb(a, b, c, d, e) { var f = a.width; c + e <= -f ? (a.x += f / 2, a.width = 0) : (a.x -= e, a.width += c + e); c = a.height; b + d <= -c ? (a.y += c / 2, a.height = 0) : (a.y -= b, a.height += b + d); return a } t.xx = function (a) { return Wb(this, a.x, a.y, a.width, a.height) }; t.Qu = function (a, b, c, d) { return Wb(this, a, b, c, d) };
    function Wb(a, b, c, d, e) { var f = Math.max(a.x, b), g = Math.max(a.y, c); b = Math.min(a.x + a.width, b + d); c = Math.min(a.y + a.height, c + e); a.x = f; a.y = g; a.width = Math.max(0, b - f); a.height = Math.max(0, c - g); return a } t.Tc = function (a) { return this.Ru(a.x, a.y, a.width, a.height) }; t.Ru = function (a, b, c, d) { var e = this.width, f = this.x; if (Infinity !== e && Infinity !== c && (e += f, c += a, isNaN(c) || isNaN(e) || f > c || a > e)) return !1; a = this.height; c = this.y; return Infinity !== a && Infinity !== d && (a += c, d += b, isNaN(d) || isNaN(a) || c > d || b > a) ? !1 : !0 };
    function Xb(a, b) { var c = a.width, d = a.x, e = b.x - 10; if (d > b.width + 10 + 10 + e || e > c + d) return !1; c = a.height; a = a.y; d = b.y - 10; return a > b.height + 10 + 10 + d || d > c + a ? !1 : !0 } t.Ue = function (a) { return Yb(this, a.x, a.y, 0, 0) }; t.Zc = function (a) { return Yb(this, a.x, a.y, a.width, a.height) }; t.Dv = function (a, b, c, d) { void 0 === c && (c = 0); void 0 === d && (d = 0); return Yb(this, a, b, c, d) };
    function Yb(a, b, c, d, e) { var f = Math.min(a.x, b), g = Math.min(a.y, c); b = Math.max(a.x + a.width, b + d); c = Math.max(a.y + a.height, c + e); a.x = f; a.y = g; a.width = b - f; a.height = c - g; return a } t.vi = function (a, b, c) { this.x = a - c.offsetX - c.x * this.width; this.y = b - c.offsetY - c.y * this.height; return this }; function Zb(a, b, c, d, e, f, g, h) { void 0 === g && (g = 0); void 0 === h && (h = 0); return a <= e && e + g <= a + c && b <= f && f + h <= b + d } function $b(a, b, c, d, e, f, g, h) { return a > g + e || e > c + a ? !1 : b > h + f || f > d + b ? !1 : !0 }
    t.w = function () { return isFinite(this.x) && isFinite(this.y) && isFinite(this.width) && isFinite(this.height) }; t.Ez = function () { return 0 === this.width && 0 === this.height }; N.alloc = function () { var a = ac.pop(); return void 0 === a ? new N : a }; N.allocAt = function (a, b, c, d) { var e = ac.pop(); return void 0 === e ? new N(a, b, c, d) : e.h(a, b, c, d) }; N.free = function (a) { ac.push(a) };
    ma.Object.defineProperties(N.prototype, {
        left: { get: function () { return this.x }, set: function (a) { this.x = a } }, top: { get: function () { return this.y }, set: function (a) { this.y = a } }, right: { get: function () { return this.x + this.width }, set: function (a) { this.x += a - (this.x + this.width) } }, bottom: { get: function () { return this.y + this.height }, set: function (a) { this.y += a - (this.y + this.height) } }, position: {
            get: function () { return new I(this.x, this.y) }, set: function (a) { this.x = a.x; this.y = a.y }
        }, size: { get: function () { return new L(this.width, this.height) }, set: function (a) { this.width = a.width; this.height = a.height } }, center: { get: function () { return new I(this.x + this.width / 2, this.y + this.height / 2) }, set: function (a) { this.x = a.x - this.width / 2; this.y = a.y - this.height / 2 } }, centerX: {
            get: function () { return this.x + this.width / 2 },
            set: function (a) { this.x = a - this.width / 2 }
        }, centerY: { get: function () { return this.y + this.height / 2 }, set: function (a) { this.y = a - this.height / 2 } }
    }); N.prototype.isEmpty = N.prototype.Ez; N.prototype.isReal = N.prototype.w; N.intersectsLineSegment = function (a, b, c, d, e, f, g, h) { return K.yx(a, b, c, d, e, f, g, h) }; N.prototype.setSpot = N.prototype.vi; N.prototype.union = N.prototype.Dv; N.prototype.unionRect = N.prototype.Zc; N.prototype.unionPoint = N.prototype.Ue; N.prototype.intersects = N.prototype.Ru;
    N.prototype.intersectsRect = N.prototype.Tc; N.prototype.intersect = N.prototype.Qu; N.prototype.intersectRect = N.prototype.xx; N.prototype.grow = N.prototype.zz; N.prototype.subtractMargin = N.prototype.yv; N.prototype.addMargin = N.prototype.yo; N.prototype.inflate = N.prototype.yc; N.prototype.offset = N.prototype.offset; N.prototype.contains = N.prototype.contains; N.prototype.containsRect = N.prototype.Me; N.prototype.containsPoint = N.prototype.ca; N.prototype.equalsApprox = N.prototype.ab; N.prototype.equalTo = N.prototype.ei;
    N.prototype.equals = N.prototype.C; N.prototype.setSize = N.prototype.jA; N.prototype.setPoint = N.prototype.Yc; N.prototype.set = N.prototype.set; N.prototype.setTo = N.prototype.og; var bc = null, cc = null, ac = []; N.className = "Rect"; N.parse = Qb; N.stringify = Rb; N.contains = Zb; N.intersects = $b; N.ZeroRect = bc = (new N(0, 0, 0, 0)).ha(); N.NoRect = cc = (new N(NaN, NaN, NaN, NaN)).ha(); N.parse = Qb; N.stringify = Rb; N.contains = Zb; N.intersects = $b;
    function hc(a, b, c, d) { void 0 === a ? this.left = this.bottom = this.right = this.top = 0 : void 0 === b ? this.left = this.bottom = this.right = this.top = a : void 0 === c ? (this.top = a, this.right = b, this.bottom = a, this.left = b) : void 0 !== d ? (this.top = a, this.right = b, this.bottom = c, this.left = d) : C("Invalid arguments to Margin constructor: " + a + ", " + b + ", " + c + ", " + d); this._isFrozen = !1 } hc.prototype.assign = function (a) { this.top = a.top; this.right = a.right; this.bottom = a.bottom; this.left = a.left; return this };
    hc.prototype.og = function (a, b, c, d) { this.top = a; this.right = b; this.bottom = c; this.left = d; return this }; hc.prototype.set = function (a) { this.top = a.top; this.right = a.right; this.bottom = a.bottom; this.left = a.left; return this }; hc.prototype.copy = function () { var a = new hc; a.top = this.top; a.right = this.right; a.bottom = this.bottom; a.left = this.left; return a }; t = hc.prototype; t.ha = function () { this._isFrozen = !0; Object.freeze(this); return this }; t.J = function () { return this._isFrozen || Object.isFrozen(this) ? this : this.copy().freeze() };
    t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; return this };
    function ic(a) { if ("string" === typeof a) { a = a.split(" "); for (var b = 0, c = NaN; "" === a[b];)b++; var d = a[b++]; d && (c = parseFloat(d)); if (isNaN(c)) return new hc; for (var e = NaN; "" === a[b];)b++; (d = a[b++]) && (e = parseFloat(d)); if (isNaN(e)) return new hc(c); for (var f = NaN; "" === a[b];)b++; (d = a[b++]) && (f = parseFloat(d)); if (isNaN(f)) return new hc(c, e); for (var g = NaN; "" === a[b];)b++; (d = a[b++]) && (g = parseFloat(d)); return isNaN(g) ? new hc(c, e) : new hc(c, e, f, g) } return new hc }
    function jc(a) { return a.top.toString() + " " + a.right.toString() + " " + a.bottom.toString() + " " + a.left.toString() } t.toString = function () { return "Margin(" + this.top + "," + this.right + "," + this.bottom + "," + this.left + ")" }; t.C = function (a) { return a instanceof hc ? this.top === a.top && this.right === a.right && this.bottom === a.bottom && this.left === a.left : !1 }; t.ei = function (a, b, c, d) { return this.top === a && this.right === b && this.bottom === c && this.left === d };
    t.ab = function (a) { return K.B(this.top, a.top) && K.B(this.right, a.right) && K.B(this.bottom, a.bottom) && K.B(this.left, a.left) }; t.w = function () { return isFinite(this.top) && isFinite(this.right) && isFinite(this.bottom) && isFinite(this.left) }; hc.alloc = function () { var a = kc.pop(); return void 0 === a ? new hc : a }; hc.free = function (a) { kc.push(a) }; hc.prototype.isReal = hc.prototype.w; hc.prototype.equalsApprox = hc.prototype.ab; hc.prototype.equalTo = hc.prototype.ei; hc.prototype.equals = hc.prototype.C; hc.prototype.set = hc.prototype.set;
    hc.prototype.setTo = hc.prototype.og; var pc = null, qc = null, kc = []; hc.className = "Margin"; hc.parse = ic; hc.stringify = jc; hc.ZeroMargin = pc = (new hc(0, 0, 0, 0)).ha(); hc.TwoMargin = qc = (new hc(2, 2, 2, 2)).ha(); hc.parse = ic; hc.stringify = jc; function O(a, b, c, d) { void 0 === a ? this.offsetY = this.offsetX = this.y = this.x = 0 : (void 0 === b && (b = 0), void 0 === c && (c = 0), void 0 === d && (d = 0), this.x = a, this.y = b, this.offsetX = c, this.offsetY = d); this._isFrozen = !1 }
    var rc, sc, tc, uc, vc, xc, yc, zc, Ac, Bc, Cc, Dc, Ec, Fc, Hc, Ic, Jc, Kc, Lc, Mc, Nc, Oc, Pc, Qc, Rc, Sc, Wc, Xc, Yc, Zc, $c, ad, bd, cd, dd, gd; O.prototype.assign = function (a) { this.x = a.x; this.y = a.y; this.offsetX = a.offsetX; this.offsetY = a.offsetY; return this }; O.prototype.og = function (a, b, c, d) { this.x = a; this.y = b; this.offsetX = c; this.offsetY = d; return this }; O.prototype.set = function (a) { this.x = a.x; this.y = a.y; this.offsetX = a.offsetX; this.offsetY = a.offsetY; return this };
    O.prototype.copy = function () { var a = new O; a.x = this.x; a.y = this.y; a.offsetX = this.offsetX; a.offsetY = this.offsetY; return a }; t = O.prototype; t.ha = function () { this._isFrozen = !0; Object.freeze(this); return this }; t.J = function () { return this._isFrozen || Object.isFrozen(this) ? this : this.copy().freeze() }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; return this }; function hd(a, b) { a.x = NaN; a.y = NaN; a.offsetX = b; return a }
    function id(a) {
        if ("string" === typeof a) {
            a = a.trim(); if ("None" === a) return rc; if ("TopLeft" === a) return sc; if ("Top" === a || "TopCenter" === a || "MiddleTop" === a) return tc; if ("TopRight" === a) return uc; if ("Left" === a || "LeftCenter" === a || "MiddleLeft" === a) return vc; if ("Center" === a) return xc; if ("Right" === a || "RightCenter" === a || "MiddleRight" === a) return yc; if ("BottomLeft" === a) return zc; if ("Bottom" === a || "BottomCenter" === a || "MiddleBottom" === a) return Ac; if ("BottomRight" === a) return Bc; if ("TopSide" === a) return Cc; if ("LeftSide" ===
                a) return Dc; if ("RightSide" === a) return Ec; if ("BottomSide" === a) return Fc; if ("TopBottomSides" === a) return Hc; if ("LeftRightSides" === a) return Ic; if ("TopLeftSides" === a) return Jc; if ("TopRightSides" === a) return Kc; if ("BottomLeftSides" === a) return Lc; if ("BottomRightSides" === a) return Mc; if ("NotTopSide" === a) return Nc; if ("NotLeftSide" === a) return Oc; if ("NotRightSide" === a) return Pc; if ("NotBottomSide" === a) return Qc; if ("AllSides" === a) return Rc; if ("Default" === a) return Sc; a = a.split(" "); for (var b = 0, c = 0; "" === a[b];)b++;
            var d = a[b++]; void 0 !== d && 0 < d.length && (c = parseFloat(d)); for (var e = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (e = parseFloat(d)); for (var f = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (f = parseFloat(d)); for (var g = 0; "" === a[b];)b++; d = a[b++]; void 0 !== d && 0 < d.length && (g = parseFloat(d)); return new O(c, e, f, g)
        } return new O
    } function jd(a) { return a.tb() ? a.x.toString() + " " + a.y.toString() + " " + a.offsetX.toString() + " " + a.offsetY.toString() : a.toString() }
    t.toString = function () {
        return this.tb() ? 0 === this.offsetX && 0 === this.offsetY ? "Spot(" + this.x + "," + this.y + ")" : "Spot(" + this.x + "," + this.y + "," + this.offsetX + "," + this.offsetY + ")" : this.C(rc) ? "None" : this.C(sc) ? "TopLeft" : this.C(tc) ? "Top" : this.C(uc) ? "TopRight" : this.C(vc) ? "Left" : this.C(xc) ? "Center" : this.C(yc) ? "Right" : this.C(zc) ? "BottomLeft" : this.C(Ac) ? "Bottom" : this.C(Bc) ? "BottomRight" : this.C(Cc) ? "TopSide" : this.C(Dc) ? "LeftSide" : this.C(Ec) ? "RightSide" : this.C(Fc) ? "BottomSide" : this.C(Hc) ? "TopBottomSides" : this.C(Ic) ?
            "LeftRightSides" : this.C(Jc) ? "TopLeftSides" : this.C(Kc) ? "TopRightSides" : this.C(Lc) ? "BottomLeftSides" : this.C(Mc) ? "BottomRightSides" : this.C(Nc) ? "NotTopSide" : this.C(Oc) ? "NotLeftSide" : this.C(Pc) ? "NotRightSide" : this.C(Qc) ? "NotBottomSide" : this.C(Rc) ? "AllSides" : this.C(Sc) ? "Default" : "None"
    }; t.C = function (a) { return a instanceof O ? (this.x === a.x || isNaN(this.x) && isNaN(a.x)) && (this.y === a.y || isNaN(this.y) && isNaN(a.y)) && this.offsetX === a.offsetX && this.offsetY === a.offsetY : !1 };
    t.cv = function () { return new O(.5 - (this.x - .5), .5 - (this.y - .5), -this.offsetX, -this.offsetY) }; t.Ef = function (a) { if (!this.Vc()) return !1; if (!a.Vc()) if (a.C(Wc)) a = Dc; else if (a.C(Xc)) a = Ec; else if (a.C(Yc)) a = Cc; else if (a.C(Zc)) a = Fc; else return !1; a = a.offsetY; return (this.offsetY & a) === a }; t.tb = function () { return !isNaN(this.x) && !isNaN(this.y) }; t.Jc = function () { return isNaN(this.x) || isNaN(this.y) }; t.Vc = function () { return isNaN(this.x) && isNaN(this.y) && 1 === this.offsetX && 0 !== this.offsetY };
    t.rs = function () { return isNaN(this.x) && isNaN(this.y) && 0 === this.offsetX && 0 === this.offsetY }; t.Tb = function () { return isNaN(this.x) && isNaN(this.y) && -1 === this.offsetX && 0 === this.offsetY }; O.alloc = function () { var a = kd.pop(); return void 0 === a ? new O : a }; O.free = function (a) { kd.push(a) }; O.prototype.isDefault = O.prototype.Tb; O.prototype.isNone = O.prototype.rs; O.prototype.isSide = O.prototype.Vc; O.prototype.isNoSpot = O.prototype.Jc; O.prototype.isSpot = O.prototype.tb; O.prototype.includesSide = O.prototype.Ef;
    O.prototype.opposite = O.prototype.cv; O.prototype.equals = O.prototype.C; O.prototype.set = O.prototype.set; O.prototype.setTo = O.prototype.og; var kd = []; O.className = "Spot"; O.parse = id; O.stringify = jd; O.None = rc = hd(new O(0, 0, 0, 0), 0).ha(); O.Default = Sc = hd(new O(0, 0, -1, 0), -1).ha(); O.TopLeft = sc = (new O(0, 0, 0, 0)).ha(); O.TopCenter = tc = (new O(.5, 0, 0, 0)).ha(); O.TopRight = uc = (new O(1, 0, 0, 0)).ha(); O.LeftCenter = vc = (new O(0, .5, 0, 0)).ha(); O.Center = xc = (new O(.5, .5, 0, 0)).ha(); O.RightCenter = yc = (new O(1, .5, 0, 0)).ha();
    O.BottomLeft = zc = (new O(0, 1, 0, 0)).ha(); O.BottomCenter = Ac = (new O(.5, 1, 0, 0)).ha(); O.BottomRight = Bc = (new O(1, 1, 0, 0)).ha(); O.MiddleTop = $c = tc; O.MiddleLeft = ad = vc; O.MiddleRight = bd = yc; O.MiddleBottom = cd = Ac; O.Top = Yc = tc; O.Left = Wc = vc; O.Right = Xc = yc; O.Bottom = Zc = Ac; O.TopSide = Cc = hd(new O(0, 0, 1, 1), 1).ha(); O.LeftSide = Dc = hd(new O(0, 0, 1, 2), 1).ha(); O.RightSide = Ec = hd(new O(0, 0, 1, 4), 1).ha(); O.BottomSide = Fc = hd(new O(0, 0, 1, 8), 1).ha(); O.TopBottomSides = Hc = hd(new O(0, 0, 1, 9), 1).ha(); O.LeftRightSides = Ic = hd(new O(0, 0, 1, 6), 1).ha();
    O.TopLeftSides = Jc = hd(new O(0, 0, 1, 3), 1).ha(); O.TopRightSides = Kc = hd(new O(0, 0, 1, 5), 1).ha(); O.BottomLeftSides = Lc = hd(new O(0, 0, 1, 10), 1).ha(); O.BottomRightSides = Mc = hd(new O(0, 0, 1, 12), 1).ha(); O.NotTopSide = Nc = hd(new O(0, 0, 1, 14), 1).ha(); O.NotLeftSide = Oc = hd(new O(0, 0, 1, 13), 1).ha(); O.NotRightSide = Pc = hd(new O(0, 0, 1, 11), 1).ha(); O.NotBottomSide = Qc = hd(new O(0, 0, 1, 7), 1).ha(); O.AllSides = Rc = hd(new O(0, 0, 1, 15), 1).ha(); dd = (new O(.156, .156)).ha(); gd = (new O(.844, .844)).ha(); O.parse = id; O.stringify = jd;
    function ld() { this.m11 = 1; this.m21 = this.m12 = 0; this.m22 = 1; this.dy = this.dx = 0 } ld.prototype.set = function (a) { this.m11 = a.m11; this.m12 = a.m12; this.m21 = a.m21; this.m22 = a.m22; this.dx = a.dx; this.dy = a.dy; return this }; ld.prototype.setTransform = function (a, b, c, d, e, f) { this.m11 = a; this.m12 = b; this.m21 = c; this.m22 = d; this.dx = e; this.dy = f; return this }; ld.prototype.copy = function () { var a = new ld; a.m11 = this.m11; a.m12 = this.m12; a.m21 = this.m21; a.m22 = this.m22; a.dx = this.dx; a.dy = this.dy; return a };
    ld.prototype.toString = function () { return "Transform(" + this.m11 + "," + this.m12 + "," + this.m21 + "," + this.m22 + "," + this.dx + "," + this.dy + ")" }; ld.prototype.C = function (a) { return this.m11 === a.m11 && this.m12 === a.m12 && this.m21 === a.m21 && this.m22 === a.m22 && this.dx === a.dx && this.dy === a.dy }; ld.prototype.ps = function () { return 0 === this.dx && 0 === this.dy && 1 === this.m11 && 0 === this.m12 && 0 === this.m21 && 1 === this.m22 }; ld.prototype.reset = function () { this.m11 = 1; this.m21 = this.m12 = 0; this.m22 = 1; this.dy = this.dx = 0; return this };
    ld.prototype.multiply = function (a) { var b = this.m11 * a.m11 + this.m21 * a.m12, c = this.m12 * a.m11 + this.m22 * a.m12, d = this.m11 * a.m21 + this.m21 * a.m22, e = this.m12 * a.m21 + this.m22 * a.m22; this.dx = this.m11 * a.dx + this.m21 * a.dy + this.dx; this.dy = this.m12 * a.dx + this.m22 * a.dy + this.dy; this.m11 = b; this.m12 = c; this.m21 = d; this.m22 = e; return this };
    ld.prototype.Yu = function (a) { var b = 1 / (a.m11 * a.m22 - a.m12 * a.m21), c = a.m22 * b, d = -a.m12 * b, e = -a.m21 * b, f = a.m11 * b, g = b * (a.m21 * a.dy - a.m22 * a.dx); a = b * (a.m12 * a.dx - a.m11 * a.dy); b = this.m11 * c + this.m21 * d; c = this.m12 * c + this.m22 * d; d = this.m11 * e + this.m21 * f; e = this.m12 * e + this.m22 * f; this.dx = this.m11 * g + this.m21 * a + this.dx; this.dy = this.m12 * g + this.m22 * a + this.dy; this.m11 = b; this.m12 = c; this.m21 = d; this.m22 = e; return this };
    ld.prototype.Ol = function () { var a = 1 / (this.m11 * this.m22 - this.m12 * this.m21), b = -this.m12 * a, c = -this.m21 * a, d = this.m11 * a, e = a * (this.m21 * this.dy - this.m22 * this.dx), f = a * (this.m12 * this.dx - this.m11 * this.dy); this.m11 = this.m22 * a; this.m12 = b; this.m21 = c; this.m22 = d; this.dx = e; this.dy = f; return this };
    ld.prototype.rotate = function (a, b, c) { 360 <= a ? a -= 360 : 0 > a && (a += 360); if (0 === a) return this; this.translate(b, c); if (90 === a) { a = 0; var d = 1 } else 180 === a ? (a = -1, d = 0) : 270 === a ? (a = 0, d = -1) : (d = a * Math.PI / 180, a = Math.cos(d), d = Math.sin(d)); var e = this.m12 * a + this.m22 * d, f = this.m11 * -d + this.m21 * a, g = this.m12 * -d + this.m22 * a; this.m11 = this.m11 * a + this.m21 * d; this.m12 = e; this.m21 = f; this.m22 = g; this.translate(-b, -c); return this }; t = ld.prototype; t.translate = function (a, b) { this.dx += this.m11 * a + this.m21 * b; this.dy += this.m12 * a + this.m22 * b; return this };
    t.scale = function (a, b) { void 0 === b && (b = a); this.m11 *= a; this.m12 *= a; this.m21 *= b; this.m22 *= b; return this }; t.Ga = function (a) { var b = a.x, c = a.y; return a.h(b * this.m11 + c * this.m21 + this.dx, b * this.m12 + c * this.m22 + this.dy) }; t.Ld = function (a) { var b = 1 / (this.m11 * this.m22 - this.m12 * this.m21), c = a.x, d = a.y; return a.h(c * this.m22 * b + d * -this.m21 * b + b * (this.m21 * this.dy - this.m22 * this.dx), c * -this.m12 * b + d * this.m11 * b + b * (this.m12 * this.dx - this.m11 * this.dy)) };
    t.Uw = function (a) { var b = 1 / (this.m11 * this.m22 - this.m12 * this.m21), c = 1.25 * a.x, d = 1.2 * a.y; return a.h(c * this.m22 * b + d * -this.m21 * b + b * (this.m21 * this.dy - this.m22 * this.dx), c * -this.m12 * b + d * this.m11 * b + b * (this.m12 * this.dx - this.m11 * this.dy)) };
    t.jp = function (a) { var b = a.x, c = a.y, d = b + a.width, e = c + a.height, f = this.m11, g = this.m12, h = this.m21, k = this.m22, l = this.dx, m = this.dy, n = b * f + c * h + l, p = b * g + c * k + m, r = d * f + c * h + l, q = d * g + c * k + m; c = b * f + e * h + l; b = b * g + e * k + m; f = d * f + e * h + l; d = d * g + e * k + m; e = Math.min(n, r); n = Math.max(n, r); r = Math.min(p, q); p = Math.max(p, q); e = Math.min(e, c); n = Math.max(n, c); r = Math.min(r, b); p = Math.max(p, b); e = Math.min(e, f); n = Math.max(n, f); r = Math.min(r, d); p = Math.max(p, d); a.h(e, r, n - e, p - r); return a }; ld.alloc = function () { var a = md.pop(); return void 0 === a ? new ld : a };
    ld.free = function (a) { md.push(a) }; ld.prototype.transformRect = ld.prototype.jp; ld.prototype.transformPoint = ld.prototype.Ga; ld.prototype.scale = ld.prototype.scale; ld.prototype.translate = ld.prototype.translate; ld.prototype.rotate = ld.prototype.rotate; ld.prototype.invert = ld.prototype.Ol; ld.prototype.multiplyInverted = ld.prototype.Yu; ld.prototype.multiply = ld.prototype.multiply; ld.prototype.reset = ld.prototype.reset; ld.prototype.isIdentity = ld.prototype.ps; ld.prototype.equals = ld.prototype.C;
    ld.prototype.setTransform = ld.prototype.setTransform; ld.prototype.set = ld.prototype.set; var md = []; ld.className = "Transform"; ld.xF = "54a702f3e53909c447824c6706603faf4c";
    var K = {
        qA: "7da71ca0ad381e90", sg: (Math.sqrt(2) - 1) / 3 * 4, Nv: null, sqrt: function (a) { if (0 >= a) return 0; var b = K.Nv; if (null === b) { b = []; for (var c = 0; 2E3 >= c; c++)b[c] = Math.sqrt(c); K.Nv = b } return 1 > a ? (c = 1 / a, 2E3 >= c ? 1 / b[c | 0] : Math.sqrt(a)) : 2E3 >= a ? b[a | 0] : Math.sqrt(a) }, B: function (a, b) { a -= b; return .5 > a && -.5 < a }, $: function (a, b) { a -= b; return 5E-8 > a && -5E-8 < a }, Ub: function (a, b, c, d, e, f, g) {
            0 >= e && (e = 1E-6); if (a < c) { var h = a; var k = c } else h = c, k = a; if (b < d) { var l = b; var m = d } else l = d, m = b; if (a === c) return l <= g && g <= m && a - e <= f && f <= a + e; if (b === d) return h <=
                f && f <= k && b - e <= g && g <= b + e; k += e; h -= e; if (h <= f && f <= k && (m += e, l -= e, l <= g && g <= m)) if (k - h > m - l) if (a - c > e || c - a > e) { if (f = (d - b) / (c - a) * (f - a) + b, f - e <= g && g <= f + e) return !0 } else return !0; else if (b - d > e || d - b > e) { if (g = (c - a) / (d - b) * (g - b) + a, g - e <= f && f <= g + e) return !0 } else return !0; return !1
        }, Ur: function (a, b, c, d, e, f, g, h, k, l, m, n) {
            if (K.Ub(a, b, g, h, n, c, d) && K.Ub(a, b, g, h, n, e, f)) return K.Ub(a, b, g, h, n, l, m); var p = (a + c) / 2, r = (b + d) / 2, q = (c + e) / 2, u = (d + f) / 2; e = (e + g) / 2; f = (f + h) / 2; d = (p + q) / 2; c = (r + u) / 2; q = (q + e) / 2; u = (u + f) / 2; var w = (d + q) / 2, v = (c + u) / 2; return K.Ur(a,
                b, p, r, d, c, w, v, k, l, m, n) || K.Ur(w, v, q, u, e, f, g, h, k, l, m, n)
        }, Vw: function (a, b, c, d, e, f, g, h, k) { var l = (c + e) / 2, m = (d + f) / 2; k.h((((a + c) / 2 + l) / 2 + (l + (e + g) / 2) / 2) / 2, (((b + d) / 2 + m) / 2 + (m + (f + h) / 2) / 2) / 2); return k }, Fy: function (a, b, c, d, e, f, g, h) { var k = (c + e) / 2, l = (d + f) / 2; return Ab(((a + c) / 2 + k) / 2, ((b + d) / 2 + l) / 2, (k + (e + g) / 2) / 2, (l + (f + h) / 2) / 2) }, zl: function (a, b, c, d, e, f, g, h, k, l) {
            if (K.Ub(a, b, g, h, k, c, d) && K.Ub(a, b, g, h, k, e, f)) Yb(l, a, b, 0, 0), Yb(l, g, h, 0, 0); else {
                var m = (a + c) / 2, n = (b + d) / 2, p = (c + e) / 2, r = (d + f) / 2; e = (e + g) / 2; f = (f + h) / 2; d = (m + p) / 2; c = (n + r) /
                    2; p = (p + e) / 2; r = (r + f) / 2; var q = (d + p) / 2, u = (c + r) / 2; K.zl(a, b, m, n, d, c, q, u, k, l); K.zl(q, u, p, r, e, f, g, h, k, l)
            } return l
        }, Le: function (a, b, c, d, e, f, g, h, k, l) { if (K.Ub(a, b, g, h, k, c, d) && K.Ub(a, b, g, h, k, e, f)) 0 === l.length && (l.push(a), l.push(b)), l.push(g), l.push(h); else { var m = (a + c) / 2, n = (b + d) / 2, p = (c + e) / 2, r = (d + f) / 2; e = (e + g) / 2; f = (f + h) / 2; d = (m + p) / 2; c = (n + r) / 2; p = (p + e) / 2; r = (r + f) / 2; var q = (d + p) / 2, u = (c + r) / 2; K.Le(a, b, m, n, d, c, q, u, k, l); K.Le(q, u, p, r, e, f, g, h, k, l) } return l }, fv: function (a, b, c, d, e, f, g, h, k, l) {
            if (K.Ub(a, b, e, f, l, c, d)) return K.Ub(a,
                b, e, f, l, h, k); var m = (a + c) / 2, n = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var p = (m + c) / 2, r = (n + d) / 2; return K.fv(a, b, m, n, p, r, g, h, k, l) || K.fv(p, r, c, d, e, f, g, h, k, l)
        }, EA: function (a, b, c, d, e, f, g) { g.h(((a + c) / 2 + (c + e) / 2) / 2, ((b + d) / 2 + (d + f) / 2) / 2); return g }, ev: function (a, b, c, d, e, f, g, h) { if (K.Ub(a, b, e, f, g, c, d)) Yb(h, a, b, 0, 0), Yb(h, e, f, 0, 0); else { var k = (a + c) / 2, l = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var m = (k + c) / 2, n = (l + d) / 2; K.ev(a, b, k, l, m, n, g, h); K.ev(m, n, c, d, e, f, g, h) } return h }, Yo: function (a, b, c, d, e, f, g, h) {
            if (K.Ub(a, b, e, f, g, c, d)) 0 === h.length && (h.push(a),
                h.push(b)), h.push(e), h.push(f); else { var k = (a + c) / 2, l = (b + d) / 2; c = (c + e) / 2; d = (d + f) / 2; var m = (k + c) / 2, n = (l + d) / 2; K.Yo(a, b, k, l, m, n, g, h); K.Yo(m, n, c, d, e, f, g, h) } return h
        }, Ao: function (a, b, c, d, e, f, g, h, k, l, m, n, p, r) {
            if (K.Ub(a, b, g, h, p, c, d) && K.Ub(a, b, g, h, p, e, f)) { if (!K.Ml(a, b, g, h, k, l, m, n)) return !1; p = (a - g) * (l - n) - (b - h) * (k - m); if (0 === p) return !1; r.h(((a * h - b * g) * (k - m) - (a - g) * (k * n - l * m)) / p, ((a * h - b * g) * (l - n) - (b - h) * (k * n - l * m)) / p); return !0 } var q = (a + c) / 2, u = (b + d) / 2, w = (c + e) / 2, v = (d + f) / 2; e = (e + g) / 2; f = (f + h) / 2; d = (q + w) / 2; c = (u + v) / 2; w = (w + e) /
                2; v = (v + f) / 2; var z = (d + w) / 2, y = (c + v) / 2, A = Infinity, B = !1, E = 0, M = 0; K.Ao(a, b, q, u, d, c, z, y, k, l, m, n, p, r) && (a = (r.x - k) * (r.x - k) + (r.y - l) * (r.y - l), a < A && (A = a, B = !0, E = r.x, M = r.y)); K.Ao(z, y, w, v, e, f, g, h, k, l, m, n, p, r) && (r.x - k) * (r.x - k) + (r.y - l) * (r.y - l) < A && (B = !0, E = r.x, M = r.y); B && (r.x = E, r.y = M); return B
        }, Bo: function (a, b, c, d, e, f, g, h, k, l, m, n, p) {
            var r = 0; if (K.Ub(a, b, g, h, p, c, d) && K.Ub(a, b, g, h, p, e, f)) {
                p = (a - g) * (l - n) - (b - h) * (k - m); if (0 === p) return r; var q = ((a * h - b * g) * (k - m) - (a - g) * (k * n - l * m)) / p, u = ((a * h - b * g) * (l - n) - (b - h) * (k * n - l * m)) / p; if (q >= m) return r;
                if ((k > m ? k - m : m - k) < (l > n ? l - n : n - l)) { if (b < h ? (k = b, a = h) : (k = h, a = b), u < k || u > a) return r } else if (a < g ? (k = a, a = g) : k = g, q < k || q > a) return r; 0 < p ? r++ : 0 > p && r--
            } else { q = (a + c) / 2; u = (b + d) / 2; var w = (c + e) / 2, v = (d + f) / 2; e = (e + g) / 2; f = (f + h) / 2; d = (q + w) / 2; c = (u + v) / 2; w = (w + e) / 2; v = (v + f) / 2; var z = (d + w) / 2, y = (c + v) / 2; r += K.Bo(a, b, q, u, d, c, z, y, k, l, m, n, p); r += K.Bo(z, y, w, v, e, f, g, h, k, l, m, n, p) } return r
        }, lh: function (a, b, c, d, e, f, g) {
            if (K.$(a, c)) { b < d ? (c = b, b = d) : c = d; if (f < c) return g.h(a, c), !1; if (f > b) return g.h(a, b), !1; g.h(a, f); return !0 } if (K.$(b, d)) {
                a < c ? (d = a,
                    a = c) : d = c; if (e < d) return g.h(d, b), !1; if (e > a) return g.h(a, b), !1; g.h(e, b); return !0
            } e = ((a - e) * (a - c) + (b - f) * (b - d)) / ((c - a) * (c - a) + (d - b) * (d - b)); if (-5E-6 > e) return g.h(a, b), !1; if (1.000005 < e) return g.h(c, d), !1; g.h(a + e * (c - a), b + e * (d - b)); return !0
        }, Re: function (a, b, c, d, e, f, g, h, k) {
            if (K.B(a, c) && K.B(b, d)) return k.h(a, b), !1; if (K.$(e, g)) return K.$(a, c) ? (K.lh(a, b, c, d, e, f, k), !1) : K.lh(a, b, c, d, e, (d - b) / (c - a) * (e - a) + b, k); h = (h - f) / (g - e); if (K.$(a, c)) {
                c = h * (a - e) + f; b < d ? (e = b, b = d) : e = d; if (c < e) return k.h(a, e), !1; if (c > b) return k.h(a, b), !1;
                k.h(a, c); return !0
            } g = (d - b) / (c - a); if (K.$(h, g)) return K.lh(a, b, c, d, e, f, k), !1; e = (g * a - h * e + f - b) / (g - h); if (K.$(g, 0)) { a < c ? (d = a, a = c) : d = c; if (e < d) return k.h(d, b), !1; if (e > a) return k.h(a, b), !1; k.h(e, b); return !0 } return K.lh(a, b, c, d, e, g * (e - a) + b, k)
        }, CA: function (a, b, c, d, e) { return K.Re(c.x, c.y, d.x, d.y, a.x, a.y, b.x, b.y, e) }, AA: function (a, b, c, d, e, f, g, h, k, l) {
            function m(c, d) { var e = (c - a) * (c - a) + (d - b) * (d - b); e < n && (n = e, k.h(c, d)) } var n = Infinity; m(k.x, k.y); var p = 0, r = 0, q = 0, u = 0; e < g ? (p = e, r = g) : (p = g, r = e); f < h ? (q = e, u = g) : (q = g, u = e); p = (r -
                p) / 2 + l; l = (u - q) / 2 + l; e = (e + g) / 2; f = (f + h) / 2; if (0 === p || 0 === l) return k; if (.5 > (c > a ? c - a : a - c)) { p = 1 - (c - e) * (c - e) / (p * p); if (0 > p) return k; p = Math.sqrt(p); d = -l * p + f; m(c, l * p + f); m(c, d) } else { c = (d - b) / (c - a); d = 1 / (p * p) + c * c / (l * l); h = 2 * c * (b - c * a) / (l * l) - 2 * c * f / (l * l) - 2 * e / (p * p); p = h * h - 4 * d * (2 * c * a * f / (l * l) - 2 * b * f / (l * l) + f * f / (l * l) + e * e / (p * p) - 1 + (b - c * a) * (b - c * a) / (l * l)); if (0 > p) return k; p = Math.sqrt(p); l = (-h + p) / (2 * d); m(l, c * l - c * a + b); p = (-h - p) / (2 * d); m(p, c * p - c * a + b) } return k
        }, Cf: function (a, b, c, d, e, f, g, h, k) {
            var l = 1E21, m = a, n = b; if (K.Re(a, b, a, d, e, f, g,
                h, k)) { var p = (k.x - e) * (k.x - e) + (k.y - f) * (k.y - f); p < l && (l = p, m = k.x, n = k.y) } K.Re(c, b, c, d, e, f, g, h, k) && (p = (k.x - e) * (k.x - e) + (k.y - f) * (k.y - f), p < l && (l = p, m = k.x, n = k.y)); K.Re(a, b, c, b, e, f, g, h, k) && (b = (k.x - e) * (k.x - e) + (k.y - f) * (k.y - f), b < l && (l = b, m = k.x, n = k.y)); K.Re(a, d, c, d, e, f, g, h, k) && (a = (k.x - e) * (k.x - e) + (k.y - f) * (k.y - f), a < l && (l = a, m = k.x, n = k.y)); k.h(m, n); return 1E21 > l
        }, ns: function (a, b, c) { return K.yx(a.x, a.y, a.width, a.height, b.x, b.y, c.x, c.y) }, yx: function (a, b, c, d, e, f, g, h) {
            var k = a + c, l = b + d; return e === g ? (f < h ? (g = f, f = h) : g = h, a <= e && e <=
                k && g <= l && f >= b) : f === h ? (e < g ? (h = e, e = g) : h = g, b <= f && f <= l && h <= k && e >= a) : Zb(a, b, c, d, e, f) || Zb(a, b, c, d, g, h) || K.Ml(a, b, k, b, e, f, g, h) || K.Ml(k, b, k, l, e, f, g, h) || K.Ml(k, l, a, l, e, f, g, h) || K.Ml(a, l, a, b, e, f, g, h) ? !0 : !1
        }, Ml: function (a, b, c, d, e, f, g, h) { return a === c && b === d && e === g && f === h ? a === e && b === f : 0 >= K.Bl(a, b, c, d, e, f) * K.Bl(a, b, c, d, g, h) && 0 >= K.Bl(e, f, g, h, a, b) * K.Bl(e, f, g, h, c, d) }, Bl: function (a, b, c, d, e, f) { c -= a; d -= b; a = e - a; b = f - b; f = a * d - b * c; 0 === f && (f = a * c + b * d, 0 < f && (f = (a - c) * c + (b - d) * d, 0 > f && (f = 0))); return f }, Wo: function (a) {
            0 > a && (a += 360);
            360 <= a && (a -= 360); return a
        }, bx: function (a, b, c, d, e, f) { var g = Math.PI; f || (d *= g / 180, e *= g / 180); var h = d > e ? -1 : 1; f = []; var k = g / 2, l = d; d = Math.min(2 * g, Math.abs(e - d)); if (1E-5 > d) return k = l + h * Math.min(d, k), h = a + c * Math.cos(l), l = b + c * Math.sin(l), a += c * Math.cos(k), b += c * Math.sin(k), c = (h + a) / 2, k = (l + b) / 2, f.push([h, l, c, k, c, k, a, b]), f; for (; 1E-5 < d;)e = l + h * Math.min(d, k), f.push(K.Ny(c, l, e, a, b)), d -= Math.abs(e - l), l = e; return f }, Ny: function (a, b, c, d, e) {
            var f = (c - b) / 2, g = a * Math.cos(f), h = a * Math.sin(f), k = -h, l = g * g + k * k, m = l + g * g + k * h; l = 4 / 3 * (Math.sqrt(2 *
                l * m) - m) / (g * h - k * g); h = g - l * k; g = k + l * g; k = -g; l = f + b; f = Math.cos(l); l = Math.sin(l); return [d + a * Math.cos(b), e + a * Math.sin(b), d + h * f - g * l, e + h * l + g * f, d + h * f - k * l, e + h * l + k * f, d + a * Math.cos(c), e + a * Math.sin(c)]
        }, Jo: function (a, b, c, d, e, f, g) { c = Math.floor((a - c) / e) * e + c; d = Math.floor((b - d) / f) * f + d; var h = c; c + e - a < e / 2 && (h = c + e); a = d; d + f - b < f / 2 && (a = d + f); g.h(h, a); return g }, ox: function (a, b) { var c = Math.max(a, b); a = Math.min(a, b); var d; do b = c % a, c = d = a, a = b; while (0 < b); return d }, Sy: function (a, b, c, d) {
            var e = 0 > c, f = 0 > d; if (a < b) { var g = 1; var h = 0 } else g =
                0, h = 1; var k = 0 === g ? a : b; var l = 0 === g ? c : d; if (0 === g ? e : f) l = -l; g = h; c = 0 === g ? c : d; if (0 === g ? e : f) c = -c; return K.Ty(k, 0 === g ? a : b, l, c, 0, 0)
        }, Ty: function (a, b, c, d, e, f) {
            if (0 < d) if (0 < c) { e = a * a; f = b * b; a *= c; var g = b * d, h = -f + g, k = -f + Math.sqrt(a * a + g * g); b = h; for (var l = 0; 9999999999 > l; ++l) { b = .5 * (h + k); if (b === h || b === k) break; var m = a / (b + e), n = g / (b + f); m = m * m + n * n - 1; if (0 < m) h = b; else if (0 > m) k = b; else break } c = e * c / (b + e) - c; d = f * d / (b + f) - d; c = Math.sqrt(c * c + d * d) } else c = Math.abs(d - b); else d = a * a - b * b, f = a * c, f < d ? (d = f / d, f = b * Math.sqrt(Math.abs(1 - d * d)), c = a * d -
                c, c = Math.sqrt(c * c + f * f)) : c = Math.abs(c - a); return c
        }, Ve: new Ta, rm: new Ta, Jf: new Ta, Kf: 0, sm: 100
    }; K.za = K.qA; function P(a) { Qa(this); this.o = 2; void 0 === a && (a = P.m); this.na = a; this.Bc = this.Ac = this.gd = this.fd = 0; a === P.m ? this.ae = new F : (null === P.V && (P.V = (new F).freeze()), this.ae = P.V); this.Up = this.ae.Ja; this.Cp = (new N).freeze(); this.vm = this.nk = null; this.wm = NaN; this.bg = sc; this.cg = Bc; this.Mk = this.Nk = NaN; this.Rf = nd }
    P.prototype.copy = function () { var a = new P; a.o = this.o & -2; a.na = this.na; a.fd = this.fd; a.gd = this.gd; a.Ac = this.Ac; a.Bc = this.Bc; if (this.na === P.m) { for (var b = this.ae.s, c = a.ae, d = 0; d < b.length; d++)c.add(b[d].copy()); a.ae = c } else a.ae = this.ae; a.Up = this.Up; a.Cp.assign(this.Cp); a.nk = this.nk; a.vm = this.vm; a.wm = this.wm; a.bg = this.bg.J(); a.cg = this.cg.J(); a.Nk = this.Nk; a.Mk = this.Mk; a.Rf = this.Rf; return a }; P.prototype.ha = function () { this.freeze(); Object.freeze(this); return this };
    P.prototype.freeze = function () { this._isFrozen = !0; if (this.type !== P.m) return this; var a = this.figures; a.freeze(); a = a.s; for (var b = a.length, c = 0; c < b; c++)a[c].freeze(); return this }; P.prototype.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; if (this.type !== P.m) return this; var a = this.figures; a.ka(); a = a.s; for (var b = a.length, c = 0; c < b; c++)a[c].ka(); return this };
    P.prototype.ab = function (a) { if (!(a instanceof P)) return !1; if (this.type !== a.type) return this.type === P.j && a.type === P.m ? od(this, a) : a.type === P.j && this.type === P.m ? od(a, this) : !1; if (this.type === P.m) { var b = this.figures.s; a = a.figures.s; var c = b.length; if (c !== a.length) return !1; for (var d = 0; d < c; d++)if (!b[d].ab(a[d])) return !1; return !0 } return K.B(this.startX, a.startX) && K.B(this.startY, a.startY) && K.B(this.endX, a.endX) && K.B(this.endY, a.endY) };
    function od(a, b) { return a.type !== P.j || b.type !== P.m ? !1 : 1 === b.figures.count && (b = b.figures.L(0), 1 === b.segments.count && K.B(a.startX, b.startX) && K.B(a.startY, b.startY) && (b = b.segments.L(0), b.type === pd && K.B(a.endX, b.endX) && K.B(a.endY, b.endY))) ? !0 : !1 } P.ga = function (a) { return a.toString() }; P.prototype.rb = function (a) { a.classType === P && (this.type = a) };
    P.prototype.toString = function (a) {
        void 0 === a && (a = -1); switch (this.type) {
            case P.j: return 0 > a ? "M" + this.startX.toString() + " " + this.startY.toString() + "L" + this.endX.toString() + " " + this.endY.toString() : "M" + this.startX.toFixed(a) + " " + this.startY.toFixed(a) + "L" + this.endX.toFixed(a) + " " + this.endY.toFixed(a); case P.u: var b = new N(this.startX, this.startY, 0, 0); b.Dv(this.endX, this.endY, 0, 0); return 0 > a ? "M" + b.x.toString() + " " + b.y.toString() + "H" + b.right.toString() + "V" + b.bottom.toString() + "H" + b.left.toString() + "z" : "M" +
                b.x.toFixed(a) + " " + b.y.toFixed(a) + "H" + b.right.toFixed(a) + "V" + b.bottom.toFixed(a) + "H" + b.left.toFixed(a) + "z"; case P.F: b = new N(this.startX, this.startY, 0, 0); b.Dv(this.endX, this.endY, 0, 0); if (0 > a) return a = b.left.toString() + " " + (b.y + b.height / 2).toString(), "M" + a + "A" + (b.width / 2).toString() + " " + (b.height / 2).toString() + " 0 0 1 " + (b.right.toString() + " " + (b.y + b.height / 2).toString()) + "A" + (b.width / 2).toString() + " " + (b.height / 2).toString() + " 0 0 1 " + a; var c = b.left.toFixed(a) + " " + (b.y + b.height / 2).toFixed(a); return "M" +
                    c + "A" + (b.width / 2).toFixed(a) + " " + (b.height / 2).toFixed(a) + " 0 0 1 " + (b.right.toFixed(a) + " " + (b.y + b.height / 2).toFixed(a)) + "A" + (b.width / 2).toFixed(a) + " " + (b.height / 2).toFixed(a) + " 0 0 1 " + c; case P.m: b = ""; c = this.figures.s; for (var d = c.length, e = 0; e < d; e++) { var f = c[e]; 0 < e && (b += " x "); f.isFilled && (b += "F "); b += f.toString(a) } return b; default: return this.type.toString()
        }
    };
    P.ob = function (a) { a = a.split(/[Xx]/); for (var b = a.length, c = "", d = 0; d < b; d++) { var e = a[d]; c = null !== e.match(/[Ff]/) ? 0 === d ? c + e : c + ("X" + (" " === e[0] ? "" : " ") + e) : c + ((0 === d ? "" : "X ") + "F" + (" " === e[0] ? "" : " ") + e) } return c };
    P.parse = function (a, b) {
        function c() { return u >= A - 1 ? !0 : null !== l[u + 1].match(B) } function d() { u++; return l[u] } function e(a) { var b = parseFloat(d()), c = parseFloat(d()); w === w.toLowerCase() && (b = y.x + b, c = y.y + c); a.h(b, c) } function f() { e(y); return y } function g() { e(z); return z } function h() { var a = v.toLowerCase(); return "c" !== a && "s" !== a && "q" !== a && "t" !== a ? y : new I(2 * y.x - z.x, 2 * y.y - z.y) } void 0 === b && (b = !1); var k = /([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm; a = a.replace(/,/gm, " "); a = a.replace(k, "$1 $2");
        a = a.replace(k, "$1 $2"); a = a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([^s])/gm, "$1 $2"); a = a.replace(/([^s])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm, "$1 $2"); a = a.replace(/([0-9])([+\-])/gm, "$1 $2"); a = a.replace(/[\s\r\t\n]+/gm, " "); a = a.replace(/^\s+|\s+$/g, ""); var l = a.split(" "); for (a = 0; a < l.length; a++) {
            var m = l[a]; if (null !== m.match(/(\.[0-9]*)(\.)/gm)) {
                k = Ea(); for (var n = "", p = !1, r = 0; r < m.length; r++) { var q = m[r]; "." !== q || p ? "." === q ? (k.push(n), n = ".") : n += q : (p = !0, n += q) } k.push(n); l.splice(a, 1); for (m = 0; m < k.length; m++)l.splice(a +
                    m, 0, k[m]); a += k.length - 1; Ga(k)
            }
        } var u = -1, w = "", v = ""; k = new I(0, 0); var z = new I(0, 0), y = new I(0, 0), A = l.length; a = qd(null); p = n = m = !1; r = !0; q = null; for (var B = /[UuBbMmZzLlHhVvCcSsQqTtAaFfXx]/; !(u >= A - 1);)if (v = w, w = d(), "" !== w) switch (w.toUpperCase()) {
            case "X": r = !0; n = m = !1; break; case "M": q = f(); null === a.ic || !0 === r ? (wd(a, q.x, q.y, m, !n, p), r = !1) : a.moveTo(q.x, q.y); for (k.assign(y); !c();)q = f(), a.lineTo(q.x, q.y); break; case "L": for (; !c();)q = f(), a.lineTo(q.x, q.y); break; case "H": for (; !c();)y = new I((w === w.toLowerCase() ? y.x : 0) + parseFloat(d()),
                y.y), a.lineTo(y.x, y.y); break; case "V": for (; !c();)y = new I(y.x, (w === w.toLowerCase() ? y.y : 0) + parseFloat(d())), a.lineTo(y.x, y.y); break; case "C": for (; !c();) { q = new I; e(q); var E = g(), M = f(); xd(a, q.x, q.y, E.x, E.y, M.x, M.y) } break; case "S": for (; !c();)q = h(), E = g(), M = f(), xd(a, q.x, q.y, E.x, E.y, M.x, M.y); break; case "Q": for (; !c();)q = g(), E = f(), yd(a, q.x, q.y, E.x, E.y); break; case "T": for (; !c();)z = q = h(), E = f(), yd(a, q.x, q.y, E.x, E.y); break; case "B": for (; !c();) {
                    q = parseFloat(d()); E = parseFloat(d()); M = parseFloat(d()); var R = parseFloat(d()),
                        S = parseFloat(d()), J = S, G = !1; c() || (J = parseFloat(d()), c() || (G = 0 !== parseFloat(d()))); w === w.toLowerCase() && (M += y.x, R += y.y); a.arcTo(q, E, M, R, S, J, G)
                } break; case "A": for (; !c();)q = Math.abs(parseFloat(d())), E = Math.abs(parseFloat(d())), M = parseFloat(d()), S = R = !1, G = J = 0, J = d(), 1 === J.length ? (R = !!parseFloat(J), S = !!parseFloat(d()), J = parseFloat(d())) : 2 === J.length ? (R = !!parseFloat(J[0]), S = !!parseFloat(J[1]), J = parseFloat(d())) : (R = !!parseFloat(J[0]), S = !!parseFloat(J[1]), J = parseFloat(J.slice(2))), G = parseFloat(d()), w === w.toLowerCase() &&
                    (J = y.x + J, G = y.y + G), y.h(J, G), zd(a, q, E, M, R, S, J, G); break; case "Z": Ad(a); y.assign(k); break; case "F": q = ""; for (E = 1; l[u + E];)if ("0" === l[u + E]) p = !0, E++; else if (null !== l[u + E].match(/[Uu]/)) E++; else if (null === l[u + E].match(B)) E++; else { q = l[u + E]; break } q.match(/[Mm]/) ? m = !0 : 0 < a.ic.segments.length && (a.ic.isFilled = !0); break; case "U": q = ""; for (E = 1; l[u + E];)if (null !== l[u + E].match(/[Ff]/)) E++; else if (null === l[u + E].match(B)) E++; else { q = l[u + E]; break } q.match(/[Mm]/) ? n = !0 : a.ic.isShadowed = !1
        }k = a.gs; Bd = a; if (b) for (b = k.figures.iterator; b.next();)b.value.isFilled =
            !0; return k
    }; P.Ha = function (a, b) { for (var c = a.length, d = I.alloc(), e = 0; e < c; e++) { var f = a[e]; d.x = f[0]; d.y = f[1]; b.Ga(d); f[0] = d.x; f[1] = d.y; d.x = f[2]; d.y = f[3]; b.Ga(d); f[2] = d.x; f[3] = d.y; d.x = f[4]; d.y = f[5]; b.Ga(d); f[4] = d.x; f[5] = d.y; d.x = f[6]; d.y = f[7]; b.Ga(d); f[6] = d.x; f[7] = d.y } I.free(d) }; P.prototype.Vu = function () { if (this._isInvalidBounds) return !0; if (this.type === P.m) { if (this.Up !== this.figures.Ja) return !0; for (var a = this.figures.s, b = a.length, c = 0; c < b; c++)if (a[c].Vu()) return !0 } return !1 };
    P.prototype.computeBounds = function () { this._isInvalidBounds = !1; this.vm = this.nk = null; this.wm = NaN; if (this.type === P.m) { this.Up = this.figures.Ja; for (var a = this.figures.s, b = a.length, c = 0; c < b; c++) { var d = a[c]; d._isInvalidBounds = !1; var e = d.segments; d.jr = e.Ja; d = e.s; e = d.length; for (var f = 0; f < e; f++) { var g = d[f]; g._isInvalidBounds = !1; g.Xd = null } } } a = this.Cp; a.ka(); isNaN(this.Nk) || isNaN(this.Mk) ? a.h(0, 0, 0, 0) : a.h(0, 0, this.Nk, this.Mk); Cd(this, a, !1); Yb(a, 0, 0, 0, 0); a.freeze() };
    P.prototype.ax = function () { var a = new N; Cd(this, a, !0); return a };
    function Cd(a, b, c) {
        switch (a.type) {
            case P.j: case P.u: case P.F: c ? b.h(a.fd, a.gd, 0, 0) : Yb(b, a.fd, a.gd, 0, 0); Yb(b, a.Ac, a.Bc, 0, 0); break; case P.m: var d = a.figures; a = d.s; d = d.length; for (var e = 0; e < d; e++) {
                var f = a[e]; c && 0 === e ? b.h(f.startX, f.startY, 0, 0) : Yb(b, f.startX, f.startY, 0, 0); for (var g = f.segments.s, h = g.length, k = f.startX, l = f.startY, m = 0; m < h; m++) {
                    var n = g[m]; switch (n.type) {
                        case pd: case Dd: k = n.endX; l = n.endY; Yb(b, k, l, 0, 0); break; case Ed: K.zl(k, l, n.point1X, n.point1Y, n.point2X, n.point2Y, n.endX, n.endY, .5, b); k = n.endX;
                            l = n.endY; break; case Kd: K.ev(k, l, n.point1X, n.point1Y, n.endX, n.endY, .5, b); k = n.endX; l = n.endY; break; case Ld: case Md: var p = n.type === Ld ? Nd(n, f) : Od(n, f, k, l), r = p.length; if (0 === r) { k = n.type === Ld ? n.centerX : n.endX; l = n.type === Ld ? n.centerY : n.endY; Yb(b, k, l, 0, 0); break } n = null; for (var q = 0; q < r; q++)n = p[q], K.zl(n[0], n[1], n[2], n[3], n[4], n[5], n[6], n[7], .5, b); null !== n && (k = n[6], l = n[7]); break; default: C("Unknown Segment type: " + n.type)
                    }
                }
            } break; default: C("Unknown Geometry type: " + a.type)
        }
    }
    P.prototype.normalize = function () { this._isFrozen && va(this); var a = this.ax(); this.offset(-a.x, -a.y); return new I(-a.x, -a.y) }; P.prototype.offset = function (a, b) { this._isFrozen && va(this); this.transform(1, 0, 0, 1, a, b); return this }; P.prototype.scale = function (a, b) { this._isFrozen && va(this); this.transform(a, 0, 0, b, 0, 0); return this };
    P.prototype.rotate = function (a, b, c) { this._isFrozen && va(this); void 0 === b && (b = 0); void 0 === c && (c = 0); var d = ld.alloc(); d.reset(); d.rotate(a, b, c); this.transform(d.m11, d.m12, d.m21, d.m22, d.dx, d.dy); ld.free(d); return this }; t = P.prototype;
    t.transform = function (a, b, c, d, e, f) {
        switch (this.type) {
            case P.j: case P.u: case P.F: var g = this.fd; var h = this.gd; this.fd = g * a + h * c + e; this.gd = g * b + h * d + f; g = this.Ac; h = this.Bc; this.Ac = g * a + h * c + e; this.Bc = g * b + h * d + f; break; case P.m: for (var k = this.figures.s, l = k.length, m = 0; m < l; m++) {
                var n = k[m]; g = n.startX; h = n.startY; n.startX = g * a + h * c + e; n.startY = g * b + h * d + f; n = n.segments.s; for (var p = n.length, r = 0; r < p; r++) {
                    var q = n[r]; switch (q.type) {
                        case pd: case Dd: g = q.endX; h = q.endY; q.endX = g * a + h * c + e; q.endY = g * b + h * d + f; break; case Ed: g = q.point1X;
                            h = q.point1Y; q.point1X = g * a + h * c + e; q.point1Y = g * b + h * d + f; g = q.point2X; h = q.point2Y; q.point2X = g * a + h * c + e; q.point2Y = g * b + h * d + f; g = q.endX; h = q.endY; q.endX = g * a + h * c + e; q.endY = g * b + h * d + f; break; case Kd: g = q.point1X; h = q.point1Y; q.point1X = g * a + h * c + e; q.point1Y = g * b + h * d + f; g = q.endX; h = q.endY; q.endX = g * a + h * c + e; q.endY = g * b + h * d + f; break; case Ld: g = q.centerX; h = q.centerY; q.centerX = g * a + h * c + e; q.centerY = g * b + h * d + f; 0 !== b && (g = 180 * Math.atan2(b, a) / Math.PI, 0 > g && (g += 360), q.startAngle += g); 0 > a && (q.startAngle = 180 - q.startAngle, q.sweepAngle = -q.sweepAngle);
                            0 > d && (q.startAngle = -q.startAngle, q.sweepAngle = -q.sweepAngle); q.radiusX *= Math.sqrt(a * a + c * c); void 0 !== q.radiusY && (q.radiusY *= Math.sqrt(b * b + d * d)); break; case Md: g = q.endX; h = q.endY; q.endX = g * a + h * c + e; q.endY = g * b + h * d + f; if (0 === q.radiusX || 0 === q.radiusY) break; 0 !== b && (g = 180 * Math.atan2(b, a) / Math.PI, 0 > g && (g += 360), q.xAxisRotation += g); 0 > a && (q.xAxisRotation = 180 - q.xAxisRotation, q.isClockwiseArc = !q.isClockwiseArc); 0 > d && (q.xAxisRotation = -q.xAxisRotation, q.isClockwiseArc = !q.isClockwiseArc); q.radiusX *= Math.sqrt(a * a + c * c);
                            q.radiusY *= Math.sqrt(b * b + d * d); break; default: C("Unknown Segment type: " + q.type)
                    }
                }
            }
        }this._isInvalidBounds = !0; return this
    };
    t.ca = function (a, b) { void 0 === b && (b = 0); var c = this.fd, d = this.gd, e = this.Ac, f = this.Bc; switch (this.type) { case P.j: return K.Ub(c, d, e, f, b, a.x, a.y); case P.u: var g = N.allocAt(Math.min(c, e) - b, Math.min(d, f) - b, Math.abs(e - c) + 2 * b, Math.abs(f - d) + 2 * b); a = g.ca(a); N.free(g); return a; case P.F: g = Math.min(c, e) - b; var h = Math.min(d, f) - b; c = (Math.abs(e - c) + 2 * b) / 2; b = (Math.abs(f - d) + 2 * b) / 2; if (0 >= c || 0 >= b) return !1; g = a.x - (g + c); h = a.y - (h + b); return 1 >= g * g / (c * c) + h * h / (b * b); case P.m: return Pd(this, a, b, !0, !1); default: return !1 } };
    function Pd(a, b, c, d, e) {
        var f = b.x; b = b.y; for (var g = a.bounds.x - 20, h = 0, k, l, m, n, p = a.figures.s, r = p.length, q = 0; q < r; q++) {
            var u = p[q], w = !u.isEvenOdd; if (u.isFilled) {
                if (d && u.ca(f, b, c)) return !0; var v = u.segments; k = u.startX; l = u.startY; for (var z = k, y = l, A = v.s, B = 0; B <= v.length; B++) {
                    var E = void 0; if (B !== v.length) { E = A[B]; var M = E.type; a = E.endX; n = E.endY } else M = pd, a = z, n = y; switch (M) {
                        case Dd: z = Qd(f, b, g, b, k, l, z, y); if (isNaN(z)) return !0; h += z; z = a; y = n; break; case pd: k = Qd(f, b, g, b, k, l, a, n); if (isNaN(k)) return !0; h += k; break; case Ed: m =
                            K.Bo(k, l, E.point1X, E.point1Y, E.point2X, E.point2Y, a, n, g, b, f, b, .5); h += m; break; case Kd: m = K.Bo(k, l, (k + 2 * E.point1X) / 3, (l + 2 * E.point1Y) / 3, (2 * E.point1X + a) / 3, (2 * E.point1Y + n) / 3, a, n, g, b, f, b, .5); h += m; break; case Ld: case Md: M = E.type === Ld ? Nd(E, u) : Od(E, u, k, l); var R = M.length; if (0 === R) { k = Qd(f, b, g, b, k, l, E.type === Ld ? E.centerX : E.endX, E.type === Ld ? E.centerY : E.endY); if (isNaN(k)) return !0; h += k; break } E = null; for (var S = 0; S < R; S++) {
                                E = M[S]; if (0 === S) { m = Qd(f, b, g, b, k, l, E[0], E[1]); if (isNaN(m)) return !0; h += m } m = K.Bo(E[0], E[1], E[2], E[3],
                                    E[4], E[5], E[6], E[7], g, b, f, b, .5); h += m
                            } null !== E && (a = E[6], n = E[7]); break; default: C("Unknown Segment type: " + E.type)
                    }k = a; l = n
                } if (w) { if (0 !== h) return !0 } else if (0 !== h % 2) return !0; h = 0
            } else if (u.ca(f, b, e ? c : c + 2)) return !0
        } return !1
    }
    function Qd(a, b, c, d, e, f, g, h) { if (K.Ub(e, f, g, h, .05, a, b)) return NaN; var k = (a - c) * (f - h); if (0 === k) return 0; var l = ((a * d - b * c) * (e - g) - (a - c) * (e * h - f * g)) / k; b = (a * d - b * c) * (f - h) / k; if (l >= a) return 0; if ((e > g ? e - g : g - e) < (f > h ? f - h : h - f)) if (f < h) { if (b < f || b > h) return 0 } else { if (b < h || b > f) return 0 } else if (e < g) { if (l < e || l > g) return 0 } else if (l < g || l > e) return 0; return 0 < k ? 1 : -1 } function Rd(a, b, c, d) { a = a.figures.s; for (var e = a.length, f = 0; f < e; f++)if (a[f].ca(b, c, d)) return !0; return !1 }
    t.Lu = function (a, b) { 0 > a ? a = 0 : 1 < a && (a = 1); void 0 === b && (b = new I); if (this.type === P.j) return b.h(this.startX + a * (this.endX - this.startX), this.startY + a * (this.endY - this.startY)), b; for (var c = this.flattenedSegments, d = this.flattenedLengths, e = c.length, f = this.flattenedTotalLength * a, g = 0, h = 0; h < e; h++) { var k = d[h], l = k.length; for (a = 0; a < l; a++) { var m = k[a]; if (g + m >= f) return d = f - g, d = 0 === m ? 0 : d / m, c = c[h], h = c[2 * a], e = c[2 * a + 1], b.h(h + (c[2 * a + 2] - h) * d, e + (c[2 * a + 3] - e) * d), b; g += m } } return b };
    t.px = function (a) { 0 > a ? a = 0 : 1 < a && (a = 1); if (this.type === P.j) return 180 * Math.atan2(this.endY - this.startY, this.endX - this.startX) / Math.PI; for (var b = this.flattenedSegments, c = this.flattenedLengths, d = b.length, e = this.flattenedTotalLength * a, f = 0, g = 0; g < d; g++) { var h = c[g], k = h.length; for (a = 0; a < k; a++) { var l = h[a]; if (f + l >= e) return e = b[g], b = e[2 * a], c = e[2 * a + 1], d = e[2 * a + 2], a = e[2 * a + 3], 1 > Math.abs(d - b) && 1 > Math.abs(a - c) ? 0 : 1 > Math.abs(d - b) ? 0 <= a - c ? 90 : 270 : 1 > Math.abs(a - c) ? 0 <= d - b ? 0 : 180 : 180 * Math.atan2(a - c, d - b) / Math.PI; f += l } } return NaN };
    t.Mu = function (a, b) {
        0 > a ? a = 0 : 1 < a && (a = 1); void 0 === b && (b = []); b.length = 3; if (this.type === P.j) return b[0] = this.startX + a * (this.endX - this.startX), b[1] = this.startY + a * (this.endY - this.startY), b[2] = 180 * Math.atan2(this.endY - this.startY, this.endX - this.startX) / Math.PI, b; for (var c = this.flattenedSegments, d = this.flattenedLengths, e = c.length, f = this.flattenedTotalLength * a, g = 0, h = 0; h < e; h++) {
            var k = d[h], l = k.length; for (a = 0; a < l; a++) {
                var m = k[a]; if (g + m >= f) return d = f - g, d = 0 === m ? 0 : d / m, m = c[h], c = m[2 * a], h = m[2 * a + 1], e = m[2 * a + 2], a = m[2 *
                    a + 3], b[0] = c + (e - c) * d, b[1] = h + (a - h) * d, b[2] = 1 > Math.abs(e - c) && 1 > Math.abs(a - h) ? 0 : 1 > Math.abs(e - c) ? 0 <= a - h ? 90 : 270 : 1 > Math.abs(a - h) ? 0 <= e - c ? 0 : 180 : 180 * Math.atan2(a - h, e - c) / Math.PI, b; g += m
            }
        } return b
    };
    t.qx = function (a) {
        if (this.type === P.j) { var b = this.startX, c = this.startY, d = this.endX, e = this.endY; if (b !== d || c !== e) { var f = a.x; a = a.y; if (b === d) { if (c < e) { var g = c; d = e } else g = e, d = c; return a <= g ? g === c ? 0 : 1 : a >= d ? d === c ? 0 : 1 : Math.abs(a - c) / (d - g) } if (c === e) return b < d ? g = b : (g = d, d = b), f <= g ? g === b ? 0 : 1 : f >= d ? d === b ? 0 : 1 : Math.abs(f - b) / (d - g); g = (d - b) * (d - b) + (e - c) * (e - c); var h = I.alloc(); K.lh(b, c, d, e, f, a, h); a = h.x; f = h.y; I.free(h); return Math.sqrt(((a - b) * (a - b) + (f - c) * (f - c)) / g) } } else if (this.type === P.u) {
            g = this.startX; h = this.startY; var k = this.endX;
            e = this.endY; if (g !== k || h !== e) { b = k - g; c = e - h; f = 2 * b + 2 * c; d = a.x; a = a.y; d = Math.min(Math.max(d, g), k); a = Math.min(Math.max(a, h), e); g = Math.abs(d - g); k = Math.abs(d - k); h = Math.abs(a - h); e = Math.abs(a - e); var l = Math.min(g, k, h, e); if (l === h) return d / f; if (l === k) return (b + a) / f; if (l === e) return (2 * b + c - d) / f; if (l === g) return (2 * b + 2 * c - a) / f }
        } else {
            b = this.flattenedSegments; c = this.flattenedLengths; f = this.flattenedTotalLength; d = I.alloc(); e = Infinity; h = g = 0; k = b.length; for (var m = l = 0, n = 0; n < k; n++)for (var p = b[n], r = c[n], q = p.length, u = 0; u < q; u += 2) {
                var w =
                    p[u], v = p[u + 1]; if (0 !== u) { K.lh(l, m, w, v, a.x, a.y, d); var z = (d.x - a.x) * (d.x - a.x) + (d.y - a.y) * (d.y - a.y); z < e && (e = z, g = h, g += Math.sqrt((d.x - l) * (d.x - l) + (d.y - m) * (d.y - m))); h += r[(u - 2) / 2] } l = w; m = v
            } I.free(d); a = g / f; return 0 > a ? 0 : 1 < a ? 1 : a
        } return 0
    };
    function Sd(a) {
        if (null === a.nk) {
            var b = a.nk = [], c = a.vm = [], d = [], e = []; if (a.type === P.j) d.push(a.startX), d.push(a.startY), d.push(a.endX), d.push(a.endY), b.push(d), e.push(Math.sqrt((a.startX - a.endX) * (a.startX - a.endX) + (a.startY - a.endY) * (a.startY - a.endY))), c.push(e); else if (a.type === P.u) d.push(a.startX), d.push(a.startY), d.push(a.endX), d.push(a.startY), d.push(a.endX), d.push(a.endY), d.push(a.startX), d.push(a.endY), d.push(a.startX), d.push(a.startY), b.push(d), e.push(Math.abs(a.startX - a.endX)), e.push(Math.abs(a.startY -
                a.endY)), e.push(Math.abs(a.startX - a.endX)), e.push(Math.abs(a.startY - a.endY)), c.push(e); else if (a.type === P.F) {
                    var f = new Wd; f.startX = a.endX; f.startY = (a.startY + a.endY) / 2; var g = new Xd(Ld); g.startAngle = 0; g.sweepAngle = 360; g.centerX = (a.startX + a.endX) / 2; g.centerY = (a.startY + a.endY) / 2; g.radiusX = Math.abs(a.startX - a.endX) / 2; g.radiusY = Math.abs(a.startY - a.endY) / 2; f.add(g); a = Nd(g, f); e = a.length; if (0 === e) d.push(g.centerX), d.push(g.centerY); else {
                        g = f.startX; f = f.startY; for (var h = 0; h < e; h++) {
                            var k = a[h]; K.Le(g, f, k[2], k[3],
                                k[4], k[5], k[6], k[7], .5, d); g = k[6]; f = k[7]
                        }
                    } b.push(d); c.push(Yd(d))
                } else for (a = a.figures.iterator; a.next();) {
                    e = a.value; d = []; d.push(e.startX); d.push(e.startY); g = e.startX; f = e.startY; h = g; k = f; for (var l = e.segments.s, m = l.length, n = 0; n < m; n++) {
                        var p = l[n]; switch (p.type) {
                            case Dd: 4 <= d.length && (b.push(d), c.push(Yd(d))); d = []; d.push(p.endX); d.push(p.endY); g = p.endX; f = p.endY; h = g; k = f; break; case pd: d.push(p.endX); d.push(p.endY); g = p.endX; f = p.endY; break; case Ed: K.Le(g, f, p.point1X, p.point1Y, p.point2X, p.point2Y, p.endX, p.endY,
                                .5, d); g = p.endX; f = p.endY; break; case Kd: K.Yo(g, f, p.point1X, p.point1Y, p.endX, p.endY, .5, d); g = p.endX; f = p.endY; break; case Ld: var r = Nd(p, e), q = r.length; if (0 === q) { d.push(p.centerX); d.push(p.centerY); g = p.centerX; f = p.centerY; break } for (var u = 0; u < q; u++) { var w = r[u]; K.Le(g, f, w[2], w[3], w[4], w[5], w[6], w[7], .5, d); g = w[6]; f = w[7] } break; case Md: r = Od(p, e, g, f); q = r.length; u = p.endX; w = p.endY; if (0 === q) { d.push(u); d.push(w); g = u; f = w; break } for (u = 0; u < q; u++)w = r[u], K.Le(g, f, w[2], w[3], w[4], w[5], w[6], w[7], .5, d), g = w[6], f = w[7]; break; default: C("Segment not of valid type: " +
                                    p.type)
                        }p.isClosed && (d.push(h), d.push(k))
                    } 4 <= d.length && (b.push(d), c.push(Yd(d)))
                }
        }
    } function Yd(a) { for (var b = [], c = 0, d = 0, e = a.length, f = 0; f < e; f += 2) { var g = a[f], h = a[f + 1]; 0 !== f && b.push(Math.sqrt(zb(c, d, g, h))); c = g; d = h } return b } t.add = function (a) { this.ae.add(a); return this }; t.mm = function (a, b, c, d, e, f, g, h) { this._isFrozen && va(this); this.bg = (new O(a, b, e, f)).freeze(); this.cg = (new O(c, d, g, h)).freeze(); return this };
    ma.Object.defineProperties(P.prototype, {
        _isFrozen: { get: function () { return 0 !== (this.o & 1) }, set: function (a) { this.o = a ? this.o | 1 : this.o & -2 } }, _isInvalidBounds: { get: function () { return 0 !== (this.o & 2) }, set: function (a) { this.o = a ? this.o | 2 : this.o & -3 } }, flattenedSegments: { get: function () { Sd(this); return this.nk } }, flattenedLengths: { get: function () { Sd(this); return this.vm } }, flattenedTotalLength: {
            get: function () { var a = this.wm; if (isNaN(a)) { if (this.type === P.j) { a = Math.abs(this.endX - this.startX); var b = Math.abs(this.endY - this.startY); a = Math.sqrt(a * a + b * b) } else if (this.type === P.u) a = 2 * Math.abs(this.endX - this.startX) + 2 * Math.abs(this.endY - this.startY); else { b = this.flattenedLengths; for (var c = b.length, d = a = 0; d < c; d++)for (var e = b[d], f = e.length, g = 0; g < f; g++)a += e[g] } this.wm = a } return a }
        }, type: {
            get: function () { return this.na }, set: function (a) {
                this.na !== a && (this._isFrozen &&
                    va(this, a), this.na = a, a === P.m ? this.ae = new F : (null === P.V && (P.V = (new F).freeze()), this.ae = P.V), this._isInvalidBounds = !0)
            }
        }, startX: { get: function () { return this.fd }, set: function (a) { this.fd !== a && (this._isFrozen && va(this, a), this.fd = a, this._isInvalidBounds = !0) } }, startY: { get: function () { return this.gd }, set: function (a) { this.gd !== a && (this._isFrozen && va(this, a), this.gd = a, this._isInvalidBounds = !0) } }, endX: {
            get: function () { return this.Ac },
            set: function (a) { this.Ac !== a && (this._isFrozen && va(this, a), this.Ac = a, this._isInvalidBounds = !0) }
        }, endY: { get: function () { return this.Bc }, set: function (a) { this.Bc !== a && (this._isFrozen && va(this, a), this.Bc = a, this._isInvalidBounds = !0) } }, figures: { get: function () { return this.ae }, set: function (a) { this.ae !== a && (this._isFrozen && va(this, a), this.ae = a, this._isInvalidBounds = !0) } }, spot1: {
            get: function () { return this.bg }, set: function (a) {
                this._isFrozen &&
                va(this, a); this.bg = a.J()
            }
        }, spot2: { get: function () { return this.cg }, set: function (a) { this._isFrozen && va(this, a); this.cg = a.J() } }, defaultStretch: { get: function () { return this.Rf }, set: function (a) { this._isFrozen && va(this, a); this.Rf = a } }, bounds: { get: function () { this.Vu() && this.computeBounds(); return this.Cp } }
    }); P.prototype.setSpots = P.prototype.mm; P.prototype.add = P.prototype.add; P.prototype.getFractionForPoint = P.prototype.qx;
    P.prototype.getPointAndAngleAlongPath = P.prototype.Mu; P.prototype.getAngleAlongPath = P.prototype.px; P.prototype.getPointAlongPath = P.prototype.Lu; P.prototype.containsPoint = P.prototype.ca; P.prototype.transform = P.prototype.transform; P.prototype.rotate = P.prototype.rotate; P.prototype.scale = P.prototype.scale; P.prototype.offset = P.prototype.offset; P.prototype.normalize = P.prototype.normalize; P.prototype.computeBoundsWithoutOrigin = P.prototype.ax; P.prototype.equalsApprox = P.prototype.ab; P.className = "Geometry";
    P.j = new D(P, "Line", 0); P.u = new D(P, "Rectangle", 1); P.F = new D(P, "Ellipse", 2); P.m = new D(P, "Path", 3); P.V = null; P.hb = "UuBbMmZzLlHhVvCcSsQqTtAaFfXx"; P.className = "Geometry"; P.stringify = P.ga; P.fillPath = P.ob; P.parse = P.parse; P.Line = P.j; P.Rectangle = P.u; P.Ellipse = P.F; P.Path = P.m; function Wd(a, b, c, d, e) { Qa(this); void 0 === c && (c = !0); void 0 === d && (d = !0); void 0 === e && (e = !1); this.o = 2 | (c ? 4 : 0) | (d ? 8 : 0) | (e ? 16 : 0); void 0 !== a ? this.fd = a : this.fd = 0; void 0 !== b ? this.gd = b : this.gd = 0; this.ll = new F; this.jr = this.ll.Ja; this.Zv = null }
    Wd.prototype.copy = function () { var a = new Wd; a.o = this.o & -2; a.fd = this.fd; a.gd = this.gd; for (var b = this.ll.s, c = b.length, d = a.ll, e = 0; e < c; e++) { var f = b[e].copy(); d.add(f) } a.jr = this.jr; a.Zv = this.Zv; return a }; t = Wd.prototype; t.ab = function (a) { if (!(a instanceof Wd && K.B(this.startX, a.startX) && K.B(this.startY, a.startY))) return !1; var b = this.segments.s; a = a.segments.s; var c = b.length; if (c !== a.length) return !1; for (var d = 0; d < c; d++)if (!b[d].ab(a[d])) return !1; return !0 };
    t.toString = function (a) { void 0 === a && (a = -1); var b = 0 > a ? "M" + this.startX.toString() + " " + this.startY.toString() : "M" + this.startX.toFixed(a) + " " + this.startY.toFixed(a); for (var c = this.segments.s, d = c.length, e = 0; e < d; e++)b += " " + c[e].toString(a); return b }; t.freeze = function () { this._isFrozen = !0; var a = this.segments; a.freeze(); var b = a.s; a = a.length; for (var c = 0; c < a; c++)b[c].freeze(); return this }; t.ka = function () { this._isFrozen = !1; var a = this.segments; a.ka(); a = a.s; for (var b = a.length, c = 0; c < b; c++)a[c].ka(); return this };
    t.Vu = function () { if (this._isInvalidBounds) return !0; var a = this.segments; if (this.jr !== a.Ja) return !0; a = a.s; for (var b = a.length, c = 0; c < b; c++)if (a[c]._isInvalidBounds) return !0; return !1 }; t.add = function (a) { this.ll.add(a); return this };
    t.ca = function (a, b, c) {
        for (var d = this.startX, e = this.startY, f = d, g = e, h = this.segments.s, k = h.length, l = 0; l < k; l++) {
            var m = h[l]; switch (m.type) {
                case Dd: f = m.endX; g = m.endY; d = m.endX; e = m.endY; break; case pd: if (K.Ub(d, e, m.endX, m.endY, c, a, b)) return !0; d = m.endX; e = m.endY; break; case Ed: if (K.Ur(d, e, m.point1X, m.point1Y, m.point2X, m.point2Y, m.endX, m.endY, .5, a, b, c)) return !0; d = m.endX; e = m.endY; break; case Kd: if (K.fv(d, e, m.point1X, m.point1Y, m.endX, m.endY, .5, a, b, c)) return !0; d = m.endX; e = m.endY; break; case Ld: case Md: var n = m.type ===
                    Ld ? Nd(m, this) : Od(m, this, d, e), p = n.length, r = m.type === Ld ? m.centerX : m.endX, q = m.type === Ld ? m.centerY : m.endY; if (0 === p) { if (K.Ub(d, e, r, q, c, a, b)) return !0; d = r; e = q; break } r = null; for (q = 0; q < p; q++)if (r = n[q], 0 === q && K.Ub(d, e, r[0], r[1], c, a, b) || K.Ur(r[0], r[1], r[2], r[3], r[4], r[5], r[6], r[7], .5, a, b, c)) return !0; null !== r && (d = r[6], e = r[7]); break; default: C("Unknown Segment type: " + m.type)
            }if (m.isClosed && (d !== f || e !== g) && K.Ub(d, e, f, g, c, a, b)) return !0
        } return !1
    };
    ma.Object.defineProperties(Wd.prototype, {
        _isFrozen: { get: function () { return 0 !== (this.o & 1) }, set: function (a) { this.o = a ? this.o | 1 : this.o & -2 } }, _isInvalidBounds: { get: function () { return 0 !== (this.o & 2) }, set: function (a) { this.o = a ? this.o | 2 : this.o & -3 } }, isFilled: { get: function () { return 0 !== (this.o & 4) }, set: function (a) { this._isFrozen && va(this, a); this.o = a ? this.o | 4 : this.o & -5 } }, isShadowed: {
            get: function () {
                return 0 !==
                    (this.o & 8)
            }, set: function (a) { this._isFrozen && va(this, a); this.o = a ? this.o | 8 : this.o & -9 }
        }, isEvenOdd: { get: function () { return 0 !== (this.o & 16) }, set: function (a) { this._isFrozen && va(this, a); this.o = a ? this.o | 16 : this.o & -17 } }, startX: { get: function () { return this.fd }, set: function (a) { this._isFrozen && va(this, a); this.fd = a; this._isInvalidBounds = !0 } }, startY: {
            get: function () { return this.gd }, set: function (a) {
                this._isFrozen && va(this, a);
                this.gd = a; this._isInvalidBounds = !0
            }
        }, segments: { get: function () { return this.ll }, set: function (a) { this._isFrozen && va(this, a); this.ll = a; this._isInvalidBounds = !0 } }
    }); Wd.prototype.add = Wd.prototype.add; Wd.prototype.equalsApprox = Wd.prototype.ab; Wd.className = "PathFigure";
    function Xd(a, b, c, d, e, f, g, h) {
        Qa(this); void 0 === a && (a = pd); this.na = a; this.o = 2; void 0 !== b ? this.Ac = b : this.Ac = 0; void 0 !== c ? this.Bc = c : this.Bc = 0; void 0 === d && (d = 0); void 0 === e && (e = 0); void 0 === f && (f = 0); void 0 === g && (g = 0); a === Md ? (a = f % 360, 0 > a && (a += 360), this.Je = a, this.Rh = 0, this.Sh = Math.max(d, 0), this.Kg = Math.max(e, 0), "boolean" === typeof g ? g && (this.isLargeArc = !0) : "number" === typeof g && g && (this.isLargeArc = !0), h && (this.isClockwiseArc = !0)) : (this.Je = d, this.Rh = e, a === Ld && (f = Math.max(f, 0)), this.Sh = f, "number" === typeof g ? (a ===
            Ld && (g = Math.max(g, 0)), this.Kg = g) : this.Kg = 0); this.Xd = null
    } Xd.prototype.copy = function () { var a = new Xd; a.na = this.na; a.o = this.o & -2; a.Ac = this.Ac; a.Bc = this.Bc; a.Je = this.Je; a.Rh = this.Rh; a.Sh = this.Sh; a.Kg = this.Kg; return a }; t = Xd.prototype;
    t.ab = function (a) {
        if (!(a instanceof Xd) || this.type !== a.type || this.isClosed !== a.isClosed) return !1; switch (this.type) {
            case Dd: case pd: return K.B(this.endX, a.endX) && K.B(this.endY, a.endY); case Ed: return K.B(this.endX, a.endX) && K.B(this.endY, a.endY) && K.B(this.point1X, a.point1X) && K.B(this.point1Y, a.point1Y) && K.B(this.point2X, a.point2X) && K.B(this.point2Y, a.point2Y); case Kd: return K.B(this.endX, a.endX) && K.B(this.endY, a.endY) && K.B(this.point1X, a.point1X) && K.B(this.point1Y, a.point1Y); case Ld: return K.B(this.startAngle,
                a.startAngle) && K.B(this.sweepAngle, a.sweepAngle) && K.B(this.centerX, a.centerX) && K.B(this.centerY, a.centerY) && K.B(this.radiusX, a.radiusX) && K.B(this.radiusY, a.radiusY); case Md: return this.isClockwiseArc === a.isClockwiseArc && this.isLargeArc === a.isLargeArc && K.B(this.xAxisRotation, a.xAxisRotation) && K.B(this.endX, a.endX) && K.B(this.endY, a.endY) && K.B(this.radiusX, a.radiusX) && K.B(this.radiusY, a.radiusY); default: return !1
        }
    }; t.rb = function (a) { a.classType === Xd && (this.type = a) };
    t.toString = function (a) {
        void 0 === a && (a = -1); switch (this.type) {
            case Dd: a = 0 > a ? "M" + this.endX.toString() + " " + this.endY.toString() : "M" + this.endX.toFixed(a) + " " + this.endY.toFixed(a); break; case pd: a = 0 > a ? "L" + this.endX.toString() + " " + this.endY.toString() : "L" + this.endX.toFixed(a) + " " + this.endY.toFixed(a); break; case Ed: a = 0 > a ? "C" + this.point1X.toString() + " " + this.point1Y.toString() + " " + this.point2X.toString() + " " + this.point2Y.toString() + " " + this.endX.toString() + " " + this.endY.toString() : "C" + this.point1X.toFixed(a) +
                " " + this.point1Y.toFixed(a) + " " + this.point2X.toFixed(a) + " " + this.point2Y.toFixed(a) + " " + this.endX.toFixed(a) + " " + this.endY.toFixed(a); break; case Kd: a = 0 > a ? "Q" + this.point1X.toString() + " " + this.point1Y.toString() + " " + this.endX.toString() + " " + this.endY.toString() : "Q" + this.point1X.toFixed(a) + " " + this.point1Y.toFixed(a) + " " + this.endX.toFixed(a) + " " + this.endY.toFixed(a); break; case Ld: a = 0 > a ? "B" + this.startAngle.toString() + " " + this.sweepAngle.toString() + " " + this.centerX.toString() + " " + this.centerY.toString() +
                    " " + this.radiusX.toString() + " " + this.radiusY.toString() : "B" + this.startAngle.toFixed(a) + " " + this.sweepAngle.toFixed(a) + " " + this.centerX.toFixed(a) + " " + this.centerY.toFixed(a) + " " + this.radiusX.toFixed(a) + " " + this.radiusY.toFixed(a); break; case Md: a = 0 > a ? "A" + this.radiusX.toString() + " " + this.radiusY.toString() + " " + this.xAxisRotation.toString() + " " + (this.isLargeArc ? 1 : 0) + " " + (this.isClockwiseArc ? 1 : 0) + " " + this.endX.toString() + " " + this.endY.toString() : "A" + this.radiusX.toFixed(a) + " " + this.radiusY.toFixed(a) +
                        " " + this.xAxisRotation.toFixed(a) + " " + (this.isLargeArc ? 1 : 0) + " " + (this.isClockwiseArc ? 1 : 0) + " " + this.endX.toFixed(a) + " " + this.endY.toFixed(a); break; default: a = this.type.toString()
        }return a + (this.isClosed ? "z" : "")
    }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { this._isFrozen = !1; return this }; t.close = function () { this.isClosed = !0; return this };
    function Nd(a, b) { if (null !== a.Xd && !1 === b._isInvalidBounds) return a.Xd; var c = a.radiusX, d = a.radiusY; void 0 === d && (d = c); if (0 === c || 0 === d) return a.Xd = [], a.Xd; b = a.Je; var e = a.Rh, f = K.bx(0, 0, c < d ? c : d, a.startAngle, a.startAngle + a.sweepAngle, !1); if (c !== d) { var g = ld.alloc(); g.reset(); c < d ? g.scale(1, d / c) : g.scale(c / d, 1); P.Ha(f, g); ld.free(g) } c = f.length; for (d = 0; d < c; d++)g = f[d], g[0] += b, g[1] += e, g[2] += b, g[3] += e, g[4] += b, g[5] += e, g[6] += b, g[7] += e; a.Xd = f; return a.Xd }
    function Od(a, b, c, d) {
        function e(a, b, c, d) { return (a * d < b * c ? -1 : 1) * Math.acos((a * c + b * d) / (Math.sqrt(a * a + b * b) * Math.sqrt(c * c + d * d))) } if (null !== a.Xd && !1 === b._isInvalidBounds) return a.Xd; if (0 === a.radiusX || 0 === a.radiusY) return a.Xd = [], a.Xd; b = a.Sh; var f = a.Kg; 0 === b && (b = 1E-4); 0 === f && (f = 1E-4); var g = Math.PI / 180 * a.Je, h = a.isLargeArc, k = a.isClockwiseArc, l = a.Ac, m = a.Bc, n = Math.cos(g), p = Math.sin(g), r = n * (c - l) / 2 + p * (d - m) / 2; g = -p * (c - l) / 2 + n * (d - m) / 2; var q = r * r / (b * b) + g * g / (f * f); 1 < q && (b *= Math.sqrt(q), f *= Math.sqrt(q)); q = (h === k ? -1 : 1) *
            Math.sqrt((b * b * f * f - b * b * g * g - f * f * r * r) / (b * b * g * g + f * f * r * r)); isNaN(q) && (q = 0); h = q * b * g / f; q = q * -f * r / b; isNaN(h) && (h = 0); isNaN(q) && (q = 0); c = (c + l) / 2 + n * h - p * q; d = (d + m) / 2 + p * h + n * q; m = e(1, 0, (r - h) / b, (g - q) / f); n = (r - h) / b; l = (g - q) / f; r = (-r - h) / b; h = (-g - q) / f; g = e(n, l, r, h); r = (n * r + l * h) / (Math.sqrt(n * n + l * l) * Math.sqrt(r * r + h * h)); -1 >= r ? g = Math.PI : 1 <= r && (g = 0); !k && 0 < g && (g -= 2 * Math.PI); k && 0 > g && (g += 2 * Math.PI); k = b > f ? 1 : b / f; r = b > f ? f / b : 1; b = K.bx(0, 0, b > f ? b : f, m, m + g, !0); f = ld.alloc(); f.reset(); f.translate(c, d); f.rotate(a.Je, 0, 0); f.scale(k, r); P.Ha(b, f);
        ld.free(f); a.Xd = b; return a.Xd
    }
    ma.Object.defineProperties(Xd.prototype, {
        _isFrozen: { get: function () { return 0 !== (this.o & 1) }, set: function (a) { this.o = a ? this.o | 1 : this.o & -2 } }, _isInvalidBounds: { get: function () { return 0 !== (this.o & 2) }, set: function (a) { this.o = a ? this.o | 2 : this.o & -3 } }, isClosed: { get: function () { return 0 !== (this.o & 8) }, set: function (a) { this.isClosed !== a && (this.o = a ? this.o | 8 : this.o & -9, this._isInvalidBounds = !0) } }, type: {
            get: function () { return this.na }, set: function (a) { this._isFrozen && va(this, a); this.na = a; this._isInvalidBounds = !0 }
        }, endX: { get: function () { return this.Ac }, set: function (a) { this._isFrozen && va(this, a); this.Ac = a; this._isInvalidBounds = !0 } }, endY: { get: function () { return this.Bc }, set: function (a) { this._isFrozen && va(this, a); this.Bc = a; this._isInvalidBounds = !0 } }, point1X: {
            get: function () { return this.Je }, set: function (a) {
                this._isFrozen &&
                va(this, a); this.Je = a; this._isInvalidBounds = !0
            }
        }, point1Y: { get: function () { return this.Rh }, set: function (a) { this._isFrozen && va(this, a); this.Rh = a; this._isInvalidBounds = !0 } }, point2X: { get: function () { return this.Sh }, set: function (a) { this._isFrozen && va(this, a); this.Sh = a; this._isInvalidBounds = !0 } }, point2Y: { get: function () { return this.Kg }, set: function (a) { this._isFrozen && va(this, a); this.Kg = a; this._isInvalidBounds = !0 } }, centerX: {
            get: function () { return this.Je }, set: function (a) { this._isFrozen && va(this, a); this.Je = a; this._isInvalidBounds = !0 }
        }, centerY: { get: function () { return this.Rh }, set: function (a) { this._isFrozen && va(this, a); this.Rh = a; this._isInvalidBounds = !0 } }, radiusX: { get: function () { return this.Sh }, set: function (a) { 0 > a && xa(a, ">= zero", Xd, "radiusX"); this._isFrozen && va(this, a); this.Sh = a; this._isInvalidBounds = !0 } }, radiusY: {
            get: function () { return this.Kg },
            set: function (a) { 0 > a && xa(a, ">= zero", Xd, "radiusY"); this._isFrozen && va(this, a); this.Kg = a; this._isInvalidBounds = !0 }
        }, startAngle: { get: function () { return this.Ac }, set: function (a) { this._isFrozen && va(this, a); a %= 360; 0 > a && (a += 360); this.Ac = a; this._isInvalidBounds = !0 } }, sweepAngle: { get: function () { return this.Bc }, set: function (a) { this._isFrozen && va(this, a); 360 < a && (a = 360); -360 > a && (a = -360); this.Bc = a; this._isInvalidBounds = !0 } }, isClockwiseArc: {
            get: function () { return 0 !== (this.o & 4) }, set: function (a) { this._isFrozen && va(this, a); this.o = a ? this.o | 4 : this.o & -5; this._isInvalidBounds = !0 }
        }, isLargeArc: { get: function () { return 0 !== (this.o & 16) }, set: function (a) { this._isFrozen && va(this, a); this.o = a ? this.o | 16 : this.o & -17; this._isInvalidBounds = !0 } }, xAxisRotation: {
            get: function () { return this.Je }, set: function (a) {
                this._isFrozen && va(this, a); a %= 360; 0 > a && (a += 360); this.Je = a; this._isInvalidBounds =
                    !0
            }
        }
    }); Xd.prototype.equalsApprox = Xd.prototype.ab; var Dd = new D(Xd, "Move", 0), pd = new D(Xd, "Line", 1), Ed = new D(Xd, "Bezier", 2), Kd = new D(Xd, "QuadraticBezier", 3), Ld = new D(Xd, "Arc", 4), Md = new D(Xd, "SvgArc", 4); Xd.className = "PathSegment"; Xd.Move = Dd; Xd.Line = pd; Xd.Bezier = Ed; Xd.QuadraticBezier = Kd; Xd.Arc = Ld; Xd.SvgArc = Md;
    function Zd() { this.H = null; this.su = (new I(0, 0)).freeze(); this.et = (new I(0, 0)).freeze(); this.vp = this.Kq = 0; this.wp = 1; this.qq = ""; this.Or = this.Np = !1; this.Lp = this.yp = 0; this.tg = this.gq = this.mq = !1; this.Sp = null; this.Jr = 0; this.rd = this.Fr = null } Zd.prototype.copy = function () { var a = new Zd; return this.clone(a) };
    Zd.prototype.clone = function (a) { a.H = this.H; a.su.assign(this.viewPoint); a.et.assign(this.documentPoint); a.Kq = this.Kq; a.vp = this.vp; a.wp = this.wp; a.qq = this.qq; a.Np = this.Np; a.Or = this.Or; a.yp = this.yp; a.Lp = this.Lp; a.mq = this.mq; a.gq = this.gq; a.tg = this.tg; a.Sp = this.Sp; a.Jr = this.Jr; a.Fr = this.Fr; a.rd = this.rd; return a };
    Zd.prototype.toString = function () { var a = "^"; 0 !== this.modifiers && (a += "M:" + this.modifiers); 0 !== this.button && (a += "B:" + this.button); "" !== this.key && (a += "K:" + this.key); 0 !== this.clickCount && (a += "C:" + this.clickCount); 0 !== this.delta && (a += "D:" + this.delta); this.handled && (a += "h"); this.bubbles && (a += "b"); null !== this.documentPoint && (a += "@" + this.documentPoint.toString()); return a }; Zd.prototype.Mo = function (a, b) { var c = this.diagram; if (null === c) return b; $d(c, a, b); return b };
    Zd.prototype.sz = function (a, b) { var c = this.diagram; if (null === c) return b; $d(c, a, b); b.assign(c.Is(b)); return b };
    ma.Object.defineProperties(Zd.prototype, {
        diagram: { get: function () { return this.H }, set: function (a) { this.H = a } }, viewPoint: { get: function () { return this.su }, set: function (a) { this.su.assign(a) } }, documentPoint: { get: function () { return this.et }, set: function (a) { this.et.assign(a) } }, modifiers: { get: function () { return this.Kq }, set: function (a) { this.Kq = a } }, button: {
            get: function () { return this.vp },
            set: function (a) { this.vp = a; if (null === this.event) switch (a) { case 0: this.buttons = 1; break; case 1: this.buttons = 4; break; case 2: this.buttons = 2 } }
        }, buttons: { get: function () { return this.wp }, set: function (a) { this.wp = a } }, key: { get: function () { return this.qq }, set: function (a) { this.qq = a } }, down: { get: function () { return this.Np }, set: function (a) { this.Np = a } }, up: {
            get: function () { return this.Or }, set: function (a) {
                this.Or =
                a
            }
        }, clickCount: { get: function () { return this.yp }, set: function (a) { this.yp = a } }, delta: { get: function () { return this.Lp }, set: function (a) { this.Lp = a } }, isMultiTouch: { get: function () { return this.mq }, set: function (a) { this.mq = a } }, handled: { get: function () { return this.gq }, set: function (a) { this.gq = a } }, bubbles: { get: function () { return this.tg }, set: function (a) { this.tg = a } }, event: {
            get: function () { return this.Sp }, set: function (a) { this.Sp = a }
        }, isTouchEvent: { get: function () { var a = this.event; return null === a ? !1 : "touch" === a.pointerType || "pen" === a.pointerType } }, timestamp: { get: function () { return this.Jr }, set: function (a) { this.Jr = a } }, targetDiagram: { get: function () { return this.Fr }, set: function (a) { this.Fr = a } }, targetObject: {
            get: function () { return this.rd }, set: function (a) {
                this.rd =
                a
            }
        }, control: { get: function () { return 0 !== (this.modifiers & 1) }, set: function (a) { this.modifiers = a ? this.modifiers | 1 : this.modifiers & -2 } }, shift: { get: function () { return 0 !== (this.modifiers & 4) }, set: function (a) { this.modifiers = a ? this.modifiers | 4 : this.modifiers & -5 } }, alt: { get: function () { return 0 !== (this.modifiers & 2) }, set: function (a) { this.modifiers = a ? this.modifiers | 2 : this.modifiers & -3 } }, meta: {
            get: function () {
                return 0 !==
                    (this.modifiers & 8)
            }, set: function (a) { this.modifiers = a ? this.modifiers | 8 : this.modifiers & -9 }
        }, left: { get: function () { var a = this.event; return null === a || "mousedown" !== a.type && "mouseup" !== a.type && "pointerdown" !== a.type && "pointerup" !== a.type ? 0 !== (this.buttons & 1) : 0 === this.button }, set: function (a) { this.buttons = a ? this.buttons | 1 : this.buttons & -2 } }, right: {
            get: function () {
                var a = this.event; return null === a || "mousedown" !== a.type && "mouseup" !== a.type && "pointerdown" !==
                    a.type && "pointerup" !== a.type ? 0 !== (this.buttons & 2) : 2 === this.button
            }, set: function (a) { this.buttons = a ? this.buttons | 2 : this.buttons & -3 }
        }, middle: { get: function () { var a = this.event; return null === a || "mousedown" !== a.type && "mouseup" !== a.type && "pointerdown" !== a.type && "pointerup" !== a.type ? 0 !== (this.buttons & 4) : 1 === this.button }, set: function (a) { this.buttons = a ? this.buttons | 4 : this.buttons & -5 } }
    }); Zd.prototype.getMultiTouchDocumentPoint = Zd.prototype.sz;
    Zd.prototype.getMultiTouchViewPoint = Zd.prototype.Mo; Zd.className = "InputEvent"; function ae() { this.H = null; this.wa = ""; this.Uq = this.Dr = null } ae.prototype.copy = function () { var a = new ae; a.H = this.H; a.wa = this.wa; a.Dr = this.Dr; a.Uq = this.Uq; return a }; ae.prototype.toString = function () { var a = "*" + this.name; null !== this.subject && (a += ":" + this.subject.toString()); null !== this.parameter && (a += "(" + this.parameter.toString() + ")"); return a };
    ma.Object.defineProperties(ae.prototype, { diagram: { get: function () { return this.H }, set: function (a) { this.H = a } }, name: { get: function () { return this.wa }, set: function (a) { this.wa = a } }, subject: { get: function () { return this.Dr }, set: function (a) { this.Dr = a } }, parameter: { get: function () { return this.Uq }, set: function (a) { this.Uq = a } } }); ae.className = "DiagramEvent";
    function be() { this.Cm = ce; this.mf = this.Jq = ""; this.Hn = this.In = this.Nn = this.On = this.Mn = this.H = this.$b = null } be.prototype.clear = function () { this.Hn = this.In = this.Nn = this.On = this.Mn = this.H = this.$b = null };
    be.prototype.copy = function () { var a = new be; a.Cm = this.Cm; a.Jq = this.Jq; a.mf = this.mf; a.$b = this.$b; a.H = this.H; a.Mn = this.Mn; var b = this.On; a.On = za(b) && "function" === typeof b.J ? b.J() : b; b = this.Nn; a.Nn = za(b) && "function" === typeof b.J ? b.J() : b; b = this.In; a.In = za(b) && "function" === typeof b.J ? b.J() : b; b = this.Hn; a.Hn = za(b) && "function" === typeof b.J ? b.J() : b; return a }; be.prototype.rb = function (a) { a.classType === be && (this.change = a) };
    be.prototype.toString = function () {
        var a = ""; a = this.change === de ? a + "* " : this.change === ce ? a + (null !== this.model ? "!m" : "!d") : a + ((null !== this.model ? "!m" : "!d") + this.change); this.propertyName && "string" === typeof this.propertyName && (a += " " + this.propertyName); this.modelChange && this.modelChange !== this.propertyName && (a += " " + this.modelChange); a += ": "; this.change === de ? null !== this.oldValue && (a += " " + this.oldValue) : (null !== this.object && (a += Ia(this.object)), null !== this.oldValue && (a += "  old: " + Ia(this.oldValue)), null !==
            this.oldParam && (a += " " + this.oldParam), null !== this.newValue && (a += "  new: " + Ia(this.newValue)), null !== this.newParam && (a += " " + this.newParam)); return a
    }; be.prototype.I = function (a) { return a ? this.oldValue : this.newValue }; be.prototype.vz = function (a) { return a ? this.oldParam : this.newParam }; be.prototype.canUndo = function () { return null !== this.model || null !== this.diagram ? !0 : !1 };
    be.prototype.undo = function () { this.canUndo() && (null !== this.model ? this.model.changeState(this, !0) : null !== this.diagram && this.diagram.changeState(this, !0)) }; be.prototype.canRedo = function () { return null !== this.model || null !== this.diagram ? !0 : !1 }; be.prototype.redo = function () { this.canRedo() && (null !== this.model ? this.model.changeState(this, !1) : null !== this.diagram && this.diagram.changeState(this, !1)) };
    ma.Object.defineProperties(be.prototype, {
        model: { get: function () { return this.$b }, set: function (a) { this.$b = a } }, diagram: { get: function () { return this.H }, set: function (a) { this.H = a } }, change: { get: function () { return this.Cm }, set: function (a) { this.Cm = a } }, modelChange: { get: function () { return this.Jq }, set: function (a) { this.Jq = a } }, propertyName: {
            get: function () { return this.mf },
            set: function (a) { this.mf = a }
        }, isTransactionFinished: { get: function () { return this.Cm === de && ("CommittedTransaction" === this.mf || "FinishedUndo" === this.mf || "FinishedRedo" === this.mf) } }, object: { get: function () { return this.Mn }, set: function (a) { this.Mn = a } }, oldValue: { get: function () { return this.On }, set: function (a) { this.On = a } }, oldParam: { get: function () { return this.Nn }, set: function (a) { this.Nn = a } },
        newValue: { get: function () { return this.In }, set: function (a) { this.In = a } }, newParam: { get: function () { return this.Hn }, set: function (a) { this.Hn = a } }
    }); be.prototype.redo = be.prototype.redo; be.prototype.canRedo = be.prototype.canRedo; be.prototype.undo = be.prototype.undo; be.prototype.canUndo = be.prototype.canUndo; be.prototype.getParam = be.prototype.vz; be.prototype.getValue = be.prototype.I; be.prototype.clear = be.prototype.clear;
    var de = new D(be, "Transaction", -1), ce = new D(be, "Property", 0), ee = new D(be, "Insert", 1), fe = new D(be, "Remove", 2); be.className = "ChangedEvent"; be.Transaction = de; be.Property = ce; be.Insert = ee; be.Remove = fe; function ge() { this.m = (new F).freeze(); this.wa = ""; this.j = !1 } ge.prototype.toString = function (a) { var b = "Transaction: " + this.name + " " + this.changes.count.toString() + (this.isComplete ? "" : ", incomplete"); if (void 0 !== a && 0 < a) { a = this.changes.count; for (var c = 0; c < a; c++) { var d = this.changes.L(c); null !== d && (b += "\n  " + d.toString()) } } return b };
    ge.prototype.clear = function () { var a = this.changes; a.ka(); for (var b = a.count - 1; 0 <= b; b--) { var c = a.L(b); null !== c && c.clear() } a.clear(); a.freeze() }; ge.prototype.canUndo = function () { return this.isComplete }; ge.prototype.undo = function () { if (this.canUndo()) for (var a = this.changes.count - 1; 0 <= a; a--) { var b = this.changes.L(a); null !== b && b.undo() } }; ge.prototype.canRedo = function () { return this.isComplete };
    ge.prototype.redo = function () { if (this.canRedo()) for (var a = this.changes.count, b = 0; b < a; b++) { var c = this.changes.L(b); null !== c && c.redo() } };
    ge.prototype.Yj = function () { if (this.isComplete) { var a = this.changes; a.ka(); for (var b = new ob, c = 0; c < a.count; c++) { var d = a.L(c); if (null !== d && d.change === ce && d.object) { var e = b.get(d.object); e || (e = new ob, b.set(d.object, e)); var f = e.get(d.propertyName); null === f ? e.set(d.propertyName, -1) : (0 < f && a.Xc(f, null), e.set(d.propertyName, c)) } } for (c = b = 0; c < a.count; c++)d = a.L(c), null !== d && (c > b && a.Xc(b, d), b++); for (; a.length > b;)a.pop(); a.freeze() } };
    ma.Object.defineProperties(ge.prototype, { changes: { get: function () { return this.m } }, name: { get: function () { return this.wa }, set: function (a) { this.wa = a } }, isComplete: { get: function () { return this.j }, set: function (a) { this.j = a } } }); ge.prototype.optimize = ge.prototype.Yj; ge.prototype.redo = ge.prototype.redo; ge.prototype.canRedo = ge.prototype.canRedo; ge.prototype.undo = ge.prototype.undo; ge.prototype.canUndo = ge.prototype.canUndo;
    ge.prototype.clear = ge.prototype.clear; ge.className = "Transaction"; function he() { this.Kt = new H; this.Ad = !1; this.u = (new F).freeze(); this.ye = -1; this.m = 999; this.Be = !1; this.Hp = null; this.Zh = 0; this.j = !1; this.Ee = (new F).freeze(); this.cl = new F; this.mt = !0; this.wt = this.kq = this.zt = this.yt = !1 }
    he.prototype.toString = function (a) { var b = "UndoManager " + this.historyIndex + "<" + this.history.count + "<=" + this.maxHistoryLength; b += "["; for (var c = this.nestedTransactionNames.count, d = 0; d < c; d++)0 < d && (b += " "), b += this.nestedTransactionNames.L(d); b += "]"; if (void 0 !== a && 0 < a) for (c = this.history.count, d = 0; d < c; d++)b += "\n " + this.history.L(d).toString(a - 1); return b };
    he.prototype.clear = function () { var a = this.history; a.ka(); for (var b = a.count - 1; 0 <= b; b--) { var c = a.L(b); null !== c && c.clear() } a.clear(); this.ye = -1; a.freeze(); this.Be = !1; this.Hp = null; this.Zh = 0; this.Ee.ka(); this.Ee.clear(); this.Ee.freeze(); this.cl.clear(); this.wt = this.kq = this.zt = this.yt = !1 }; he.prototype.copyProperties = function (a) { this.isEnabled = a.isEnabled; this.maxHistoryLength = a.maxHistoryLength; this.checksTransactionLevel = a.checksTransactionLevel }; t = he.prototype; t.Qw = function (a) { this.Kt.add(a) }; t.Nx = function (a) { this.Kt.remove(a) };
    t.Ea = function (a) {
        void 0 === a && (a = ""); null === a && (a = ""); if (this.isUndoingRedoing) return !1; !0 === this.mt && (this.mt = !1, this.Zh++, this.isInternalTransaction || this.Lb("StartingFirstTransaction", a, this.currentTransaction), 0 < this.Zh && this.Zh--); this.isEnabled && (this.Ee.ka(), this.Ee.add(a), this.Ee.freeze(), null === this.currentTransaction ? this.cl.add(0) : this.cl.add(this.currentTransaction.changes.count)); this.Zh++; var b = 1 === this.transactionLevel; b && (this.isInternalTransaction || this.Lb("StartedTransaction", a, this.currentTransaction));
        return b
    }; t.eb = function (a) { void 0 === a && (a = ""); return ie(this, !0, a) }; t.Hf = function () { return ie(this, !1, "") };
    function ie(a, b, c) {
        if (a.isUndoingRedoing) return !1; a.checksTransactionLevel && 1 > a.transactionLevel && ya("Ending transaction without having started a transaction: " + c); var d = 1 === a.transactionLevel, e = a.currentTransaction; d && b && (a.isInternalTransaction || a.Lb("CommittingTransaction", c, e)); var f = 0; if (0 < a.transactionLevel && (a.Zh--, a.isEnabled)) { var g = a.Ee.count; 0 < g && ("" === c && (c = a.Ee.L(0)), a.Ee.ka(), a.Ee.nb(g - 1), a.Ee.freeze()); g = a.cl.count; 0 < g && (f = a.cl.L(g - 1), a.cl.nb(g - 1)) } if (d) {
            if (b) {
                a.kq = !1; null === e && "" !==
                    c && (e = a.currentTransaction); if (a.isEnabled && null !== e) { e.isComplete || (e.isComplete = !0, e.name = c); b = a.history; b.ka(); for (d = b.count - 1; d > a.historyIndex; d--)f = b.L(d), null !== f && f.clear(), b.nb(d), a.kq = !0; d = a.maxHistoryLength; 0 <= d && (0 === d ? b.clear() : b.count >= d && (f = b.L(0), null !== f && f.clear(), b.nb(0), a.ye--)); 0 === d || 0 !== b.count && b.get(b.count - 1) === e || (b.add(e), a.ye++); b.freeze() } a.isInternalTransaction || a.Lb("CommittedTransaction", c, e)
            } else {
                a.Be = !0; try { a.isEnabled && null !== e && (e.isComplete = !0, e.undo()) } finally {
                    a.isInternalTransaction ||
                    a.Lb("RolledBackTransaction", c, e), a.Be = !1
                } null !== e && e.clear()
            } a.Hp = null; a.isPendingClear && a.clear(); a.isPendingClear = !1; a.isPendingUnmodified = !1; return !0
        } if (a.isEnabled && !b && null !== e) { a = f; c = e.changes; for (e = c.count - 1; e >= a; e--)b = c.L(e), null !== b && b.undo(), c.ka(), c.nb(e); c.freeze() } return !1
    } he.prototype.canUndo = function () { if (!this.isEnabled || 0 < this.transactionLevel) return !1; var a = this.transactionToUndo; return null !== a && a.canUndo() ? !0 : !1 };
    he.prototype.undo = function () { if (this.canUndo()) { var a = this.transactionToUndo; try { this.Be = !0, this.Lb("StartingUndo", "Undo", a), this.ye--, a.undo() } catch (b) { ya("undo error: " + b.toString()) } finally { this.Lb("FinishedUndo", "Undo", a), this.Be = !1 } } }; he.prototype.canRedo = function () { if (!this.isEnabled || 0 < this.transactionLevel) return !1; var a = this.transactionToRedo; return null !== a && a.canRedo() ? !0 : !1 };
    he.prototype.redo = function () { if (this.canRedo()) { var a = this.transactionToRedo; try { this.Be = !0, this.Lb("StartingRedo", "Redo", a), this.ye++, a.redo() } catch (b) { ya("redo error: " + b.toString()) } finally { this.Lb("FinishedRedo", "Redo", a), this.Be = !1 } } }; he.prototype.Lb = function (a, b, c) { void 0 === c && (c = null); var d = new be; d.change = de; d.propertyName = a; d.object = c; d.oldValue = b; for (a = this.models; a.next();)b = a.value, d.model = b, b.Vr(d) };
    he.prototype.Pu = function (a) { if (this.isEnabled && !this.isUndoingRedoing && !this.skipsEvent(a)) { var b = this.currentTransaction; null === b && (this.Hp = b = new ge); var c = a.copy(); b = b.changes; b.ka(); b.add(c); b.freeze(); this.checksTransactionLevel && 0 >= this.transactionLevel && !this.mt && (a = a.diagram, null !== a && !1 === a.mi || ya("Change not within a transaction: " + c.toString())) } };
    he.prototype.skipsEvent = function (a) { if (null === a || 0 > a.change.value) return !0; a = a.object; if (null === a) return !1; if (void 0 !== a.layer) { if (a = a.layer, null !== a && a.isTemporary) return !0 } else if (a.isTemporary) return !0; return !1 };
    ma.Object.defineProperties(he.prototype, {
        models: { get: function () { return this.Kt.iterator } }, isEnabled: { get: function () { return this.Ad }, set: function (a) { this.Ad = a } }, transactionToUndo: { get: function () { return 0 <= this.historyIndex && this.historyIndex <= this.history.count - 1 ? this.history.L(this.historyIndex) : null } }, transactionToRedo: {
            get: function () {
                return this.historyIndex < this.history.count -
                    1 ? this.history.L(this.historyIndex + 1) : null
            }
        }, isUndoingRedoing: { get: function () { return this.Be } }, history: { get: function () { return this.u } }, maxHistoryLength: { get: function () { return this.m }, set: function (a) { this.m = a } }, historyIndex: { get: function () { return this.ye } }, currentTransaction: { get: function () { return this.Hp } }, transactionLevel: {
            get: function () { return this.Zh }
        }, isInTransaction: { get: function () { return 0 < this.Zh } }, checksTransactionLevel: { get: function () { return this.j }, set: function (a) { this.j = a } }, nestedTransactionNames: { get: function () { return this.Ee } }, isPendingClear: { get: function () { return this.yt }, set: function (a) { this.yt = a } }, isPendingUnmodified: {
            get: function () { return this.zt }, set: function (a) {
                this.zt =
                a
            }
        }, isInternalTransaction: { get: function () { return this.wt }, set: function (a) { this.wt = a } }, isJustDiscarded: { get: function () { return this.kq } }
    }); he.prototype.handleChanged = he.prototype.Pu; he.prototype.redo = he.prototype.redo; he.prototype.undo = he.prototype.undo; he.prototype.canUndo = he.prototype.canUndo; he.prototype.rollbackTransaction = he.prototype.Hf; he.prototype.commitTransaction = he.prototype.eb; he.prototype.startTransaction = he.prototype.Ea;
    he.prototype.removeModel = he.prototype.Nx; he.prototype.addModel = he.prototype.Qw; he.prototype.clear = he.prototype.clear; he.className = "UndoManager"; function je() { Qa(this); this.H = ke; this.wa = ""; this.Ad = !0; this.ob = !1; this.tm = null; this.uy = new Zd; this.Rr = -1 } je.prototype.toString = function () { return "" !== this.name ? this.name + " Tool" : Ha(this.constructor) }; je.prototype.updateAdornments = function () { }; je.prototype.canStart = function () { return this.isEnabled }; je.prototype.doStart = function () { };
    je.prototype.doActivate = function () { this.isActive = !0 }; je.prototype.doDeactivate = function () { this.isActive = !1 }; je.prototype.doStop = function () { }; je.prototype.doCancel = function () { this.transactionResult = null; this.stopTool() }; je.prototype.stopTool = function () { var a = this.diagram; a.currentTool === this && (a.currentTool = null, a.currentCursor = "") }; je.prototype.doMouseDown = function () { !this.isActive && this.canStart() && this.doActivate() }; je.prototype.doMouseMove = function () { }; je.prototype.doMouseUp = function () { this.stopTool() };
    je.prototype.doMouseWheel = function () { }; je.prototype.canStartMultiTouch = function () { return !0 }; je.prototype.standardPinchZoomStart = function () { var a = this.diagram, b = a.lastInput, c = b.Mo(0, I.allocAt(NaN, NaN)), d = b.Mo(1, I.allocAt(NaN, NaN)); if (c.w() && d.w() && (this.doCancel(), a.Ll("hasGestureZoom"))) { a.uw = a.scale; var e = d.x - c.x, f = d.y - c.y; a.Cw = Math.sqrt(e * e + f * f); b.bubbles = !1 } I.free(c); I.free(d) };
    je.prototype.standardPinchZoomMove = function () { var a = this.diagram, b = a.lastInput, c = b.Mo(0, I.allocAt(NaN, NaN)), d = b.Mo(1, I.allocAt(NaN, NaN)); if (c.w() && d.w() && (this.doCancel(), a.Ll("hasGestureZoom"))) { var e = d.x - c.x, f = d.y - c.y; f = Math.sqrt(e * e + f * f) / a.Cw; e = new I((Math.min(d.x, c.x) + Math.max(d.x, c.x)) / 2, (Math.min(d.y, c.y) + Math.max(d.y, c.y)) / 2); f *= a.uw; var g = a.commandHandler; if (f !== a.scale && g.canResetZoom(f)) { var h = a.zoomPoint; a.zoomPoint = e; g.resetZoom(f); a.zoomPoint = h } b.bubbles = !1 } I.free(c); I.free(d) };
    je.prototype.doKeyDown = function () { "Esc" === this.diagram.lastInput.key && this.doCancel() }; je.prototype.doKeyUp = function () { }; je.prototype.Ea = function (a) { void 0 === a && (a = this.name); this.transactionResult = null; return this.diagram.Ea(a) }; je.prototype.stopTransaction = function () { var a = this.diagram; return null === this.transactionResult ? a.Hf() : a.eb(this.transactionResult) };
    je.prototype.standardMouseSelect = function () {
        var a = this.diagram; if (a.allowSelect) {
            var b = a.lastInput, c = a.Jl(b.documentPoint, !1); if (null !== c) if (Pa ? b.meta : b.control) { a.R("ChangingSelection", a.selection); for (b = c; null !== b && !b.canSelect();)b = b.containingGroup; null !== b && (b.isSelected = !b.isSelected); a.R("ChangedSelection", a.selection) } else if (b.shift) {
                if (!c.isSelected) {
                    a.R("ChangingSelection", a.selection); for (b = c; null !== b && !b.canSelect();)b = b.containingGroup; null !== b && (b.isSelected = !0); a.R("ChangedSelection",
                        a.selection)
                }
            } else { if (!c.isSelected) { for (b = c; null !== b && !b.canSelect();)b = b.containingGroup; null !== b && a.select(b) } } else !b.left || (Pa ? b.meta : b.control) || b.shift || a.clearSelection()
        }
    }; je.prototype.standardMouseClick = function (a, b) { void 0 === a && (a = null); void 0 === b && (b = function (a) { return !a.layer.isTemporary }); var c = this.diagram, d = c.lastInput; a = c.ec(d.documentPoint, a, b); d.targetObject = a; le(a, d, c); return d.handled };
    function le(a, b, c) {
        b.handled = !1; if (null === a || a.Ff()) {
            var d = 0; b.left ? d = 1 === b.clickCount ? 1 : 2 === b.clickCount ? 2 : 1 : b.right && 1 === b.clickCount && (d = 3); var e = "ObjectSingleClicked"; if (null !== a) { switch (d) { case 1: e = "ObjectSingleClicked"; break; case 2: e = "ObjectDoubleClicked"; break; case 3: e = "ObjectContextClicked" }0 !== d && c.R(e, a) } else { switch (d) { case 1: e = "BackgroundSingleClicked"; break; case 2: e = "BackgroundDoubleClicked"; break; case 3: e = "BackgroundContextClicked" }0 !== d && c.R(e) } if (null !== a) for (; null !== a;) {
                c = null; switch (d) {
                    case 1: c =
                        a.click; break; case 2: c = a.doubleClick ? a.doubleClick : a.click; break; case 3: c = a.contextClick
                }if (null !== c && (c(b, a), b.handled)) break; a = a.panel
            } else { a = null; switch (d) { case 1: a = c.click; break; case 2: a = c.doubleClick ? c.doubleClick : c.click; break; case 3: a = c.contextClick }null !== a && a(b) }
        }
    }
    je.prototype.standardMouseOver = function () {
        var a = this.diagram, b = a.lastInput; if (!0 !== a.animationManager.zb) {
            var c = a.skipsUndoManager; a.skipsUndoManager = !0; var d = a.viewportBounds.ca(b.documentPoint) ? a.ec(b.documentPoint, null, null) : null; !b.event || "pointercancel" !== b.event.type && "pointerout" !== b.event.type || (d = null); b.targetObject = d; var e = !1; if (d !== a.xk) {
                var f = a.xk, g = f; a.xk = d; this.doCurrentObjectChanged(f, d); for (b.handled = !1; null !== f;) {
                    var h = f.mouseLeave; if (null !== h) {
                        if (d === f) break; if (null !== d && d.ng(f)) break;
                        h(b, f, d); e = !0; if (b.handled) break
                    } f = f.panel
                } f = g; for (b.handled = !1; null !== d;) { g = d.mouseEnter; if (null !== g) { if (f === d) break; if (null !== f && f.ng(d)) break; g(b, d, f); e = !0; if (b.handled) break } d = d.panel } d = a.xk
            } if (null !== d) { f = d; for (g = ""; null !== f;) { g = f.cursor; if ("" !== g) break; f = f.panel } a.currentCursor = g; b.handled = !1; for (f = d; null !== f;) { d = f.mouseOver; if (null !== d && (d(b, f), e = !0, b.handled)) break; f = f.panel } } else this.doUpdateCursor(null), d = a.mouseOver, null !== d && (d(b), e = !0); e && a.Wb(); a.skipsUndoManager = c
        }
    };
    je.prototype.doUpdateCursor = function () { var a = this.diagram; a && (a.currentCursor = "") }; je.prototype.doCurrentObjectChanged = function () { };
    je.prototype.standardMouseWheel = function () {
        var a = this.diagram, b = a.lastInput, c = b.delta; if (0 !== c && a.documentBounds.w()) {
            var d = a.commandHandler, e = a.toolManager.mouseWheelBehavior; if (null !== d && (e === me && !b.shift || e === ne && b.control)) { if (0 < c ? d.canIncreaseZoom() : d.canDecreaseZoom()) e = a.zoomPoint, a.zoomPoint = b.viewPoint, 0 < c ? d.increaseZoom() : d.decreaseZoom(), a.zoomPoint = e; b.bubbles = !1 } else if (e === me && b.shift || e === ne && !b.control) {
                d = a.position.copy(); var f = b.event; e = f.deltaMode; var g = f.deltaX; f = f.deltaY; var h =
                    "pixel"; if (0 === g && 0 === f || b.shift) !b.shift && a.allowVerticalScroll ? (c = 3 * c * a.scrollVerticalLineChange, a.scroll(h, 0 < c ? "down" : "up", Math.abs(c))) : b.shift && a.allowHorizontalScroll && (c = 3 * c * a.scrollHorizontalLineChange, a.scroll(h, 0 < c ? "left" : "right", Math.abs(c))); else {
                        switch (e) { case 0: h = "pixel"; break; case 1: h = "line"; break; case 2: h = "page" }0 !== g && a.allowHorizontalScroll && (g *= a.scrollHorizontalLineChange / 16, a.scroll(h, 0 < g ? "right" : "left", Math.abs(g))); 0 !== f && a.allowVerticalScroll && (f *= a.scrollVerticalLineChange /
                            16, a.scroll(h, 0 < f ? "down" : "up", Math.abs(f)))
                    } this.doWheelChange(a, d, b)
            }
        }
    }; je.prototype.doWheelChange = function (a, b, c) { a.position.C(b) && 0 === c.event.wheelDeltaX || (c.bubbles = !1) }; je.prototype.standardWaitAfter = function (a, b) { void 0 === b && (b = this.diagram.lastInput); this.cancelWaitAfter(); var c = this, d = b.clone(this.uy); this.Rr = ta(function () { c.doWaitAfter(d) }, a) }; je.prototype.cancelWaitAfter = function () { -1 !== this.Rr && x.clearTimeout(this.Rr); this.Rr = -1 }; je.prototype.doWaitAfter = function () { };
    je.prototype.findToolHandleAt = function (a, b) { a = this.diagram.ec(a, function (a) { for (; null !== a && !(a.panel instanceof te);)a = a.panel; return a }); return null === a ? null : a.part.category === b ? a : null }; je.prototype.isBeyondDragSize = function (a, b) { var c = this.diagram; void 0 === a && (a = c.firstInput.viewPoint); void 0 === b && (b = c.lastInput.viewPoint); var d = c.toolManager.dragSize, e = d.width; d = d.height; c.firstInput.isTouchEvent && (e += 6, d += 6); return Math.abs(b.x - a.x) > e || Math.abs(b.y - a.y) > d };
    ma.Object.defineProperties(je.prototype, {
        diagram: { get: function () { return this.H }, set: function (a) { a instanceof Q && (this.H = a) } }, name: { get: function () { return this.wa }, set: function (a) { this.wa = a } }, isEnabled: { get: function () { return this.Ad }, set: function (a) { this.Ad = a } }, isActive: { get: function () { return this.ob }, set: function (a) { this.ob = a } }, transactionResult: {
            get: function () { return this.tm },
            set: function (a) { this.tm = a }
        }
    }); je.prototype.startTransaction = je.prototype.Ea; je.className = "Tool"; function La() { je.call(this); this.name = "ToolManager"; this.hb = new F; this.pb = new F; this.Fb = new F; this.F = this.V = 850; this.m = (new L(2, 2)).ha(); this.Ha = 5E3; this.ga = ne; this.u = ue; this.Gp = this.j = null; this.xj = -1 } la(La, je); La.prototype.initializeStandardTools = function () { };
    La.prototype.updateAdornments = function (a) { var b = this.currentToolTip; if (b instanceof te && this.Gp === a) { var c = b.adornedObject; (null !== a ? c.part === a : null === c) ? this.showToolTip(b, c) : this.hideToolTip() } };
    La.prototype.doMouseDown = function () {
        var a = this.diagram, b = a.lastInput; b.isTouchEvent && this.gestureBehavior === ve && (b.bubbles = !1); if (b.isMultiTouch) { this.cancelWaitAfter(); if (this.gestureBehavior === we) { b.bubbles = !0; return } if (this.gestureBehavior === ve) return; if (a.currentTool.canStartMultiTouch()) { a.currentTool.standardPinchZoomStart(); return } } for (var c = this.mouseDownTools.length, d = 0; d < c; d++) {
            var e = this.mouseDownTools.L(d); e.diagram = this.diagram; if (e.canStart()) {
                a.doFocus(); a.currentTool = e; a.currentTool ===
                    e && (e.isActive || e.doActivate(), e.doMouseDown()); return
            }
        } 1 === a.lastInput.button && (this.mouseWheelBehavior === ne ? this.mouseWheelBehavior = me : this.mouseWheelBehavior === me && (this.mouseWheelBehavior = ne)); this.doActivate(); this.standardWaitAfter(this.holdDelay, b)
    };
    La.prototype.doMouseMove = function () {
        var a = this.diagram, b = a.lastInput; if (b.isMultiTouch) { if (this.gestureBehavior === we) { b.bubbles = !0; return } if (this.gestureBehavior === ve) return; if (a.currentTool.canStartMultiTouch()) { a.currentTool.standardPinchZoomMove(); return } } if (this.isActive) for (var c = this.mouseMoveTools.length, d = 0; d < c; d++) { var e = this.mouseMoveTools.L(d); e.diagram = this.diagram; if (e.canStart()) { a.doFocus(); a.currentTool = e; a.currentTool === e && (e.isActive || e.doActivate(), e.doMouseMove()); return } } xe(this,
            a); a = b.event; null === a || "pointermove" !== a.type && a.cancelable || (b.bubbles = !0)
    }; function xe(a, b) { a.standardMouseOver(); a.isBeyondDragSize() && a.standardWaitAfter(a.isActive ? a.holdDelay : a.hoverDelay, b.lastInput) } La.prototype.doCurrentObjectChanged = function (a, b) { a = this.currentToolTip; null === a || null !== b && a instanceof te && (b === a || b.ng(a)) || this.hideToolTip() };
    La.prototype.doWaitAfter = function (a) { var b = this.diagram; null !== b.xa && (this.doMouseHover(), this.isActive || this.doToolTip(), a.isTouchEvent && !b.lastInput.handled && (a = a.copy(), a.button = 2, a.buttons = 2, b.lastInput = a, b.lu = !0, b.doMouseUp())) };
    La.prototype.doMouseHover = function () { var a = this.diagram, b = a.lastInput; null === b.targetObject && (b.targetObject = a.ec(b.documentPoint, null, null)); var c = b.targetObject; if (null !== c) for (b.handled = !1; null !== c;) { a = this.isActive ? c.mouseHold : c.mouseHover; if (null !== a && (a(b, c), b.handled)) break; c = c.panel } else c = this.isActive ? a.mouseHold : a.mouseHover, null !== c && c(b) };
    La.prototype.doToolTip = function () { var a = this.diagram, b = a.lastInput; null === b.targetObject && (b.targetObject = a.ec(b.documentPoint, null, null)); b = b.targetObject; if (null !== b) { if (a = this.currentToolTip, !(a instanceof te) || b !== a && !b.ng(a)) { for (; null !== b;) { a = b.toolTip; if (null !== a) { this.showToolTip(a, b); return } b = b.panel } this.hideToolTip() } } else b = a.toolTip, null !== b ? this.showToolTip(b, null) : this.hideToolTip() };
    La.prototype.showToolTip = function (a, b) {
        var c = this.diagram; a !== this.currentToolTip && this.hideToolTip(); if (a instanceof te) { a.layerName = "Tool"; a.selectable = !1; a.scale = 1 / c.scale; a.category = "ToolTip"; null !== a.placeholder && (a.placeholder.scale = c.scale); var d = a.diagram; null !== d && d !== c && d.remove(a); c.add(a); null !== b ? a.adornedObject = b : a.data = c.model; a.Va(); this.positionToolTip(a, b) } else a instanceof ye && a !== this.currentToolTip && a.show(b, c, this); this.currentToolTip = a; -1 !== this.xj && (x.clearTimeout(this.xj),
            this.xj = -1); a = this.toolTipDuration; if (0 < a && Infinity !== a) { var e = this; this.xj = ta(function () { e.hideToolTip() }, a) }
    }; La.prototype.positionToolTip = function (a) { if (null === a.placeholder) { var b = this.diagram, c = b.lastInput.documentPoint.copy(), d = a.measuredBounds, e = b.viewportBounds; b.lastInput.isTouchEvent && (c.x -= d.width); c.x + d.width > e.right && (c.x -= d.width + 5 / b.scale); c.x < e.x && (c.x = e.x); c.y = c.y + 20 / b.scale + d.height > e.bottom ? c.y - (d.height + 5 / b.scale) : c.y + 20 / b.scale; c.y < e.y && (c.y = e.y); a.position = c } };
    La.prototype.hideToolTip = function () { -1 !== this.xj && (x.clearTimeout(this.xj), this.xj = -1); var a = this.diagram, b = this.currentToolTip; null !== b && (b instanceof te ? (a.remove(b), null !== this.Gp && this.Gp.Gf(b.category), b.data = null, b.adornedObject = null) : b instanceof ye && null !== b.hide && b.hide(a, this), this.currentToolTip = null) };
    La.prototype.doMouseUp = function () { this.cancelWaitAfter(); var a = this.diagram; if (this.isActive) for (var b = this.mouseUpTools.length, c = 0; c < b; c++) { var d = this.mouseUpTools.L(c); d.diagram = this.diagram; if (d.canStart()) { a.doFocus(); a.currentTool = d; a.currentTool === d && (d.isActive || d.doActivate(), d.doMouseUp()); return } } a.doFocus(); this.doDeactivate() }; La.prototype.doMouseWheel = function () { this.standardMouseWheel() }; La.prototype.doKeyDown = function () { var a = this.diagram; null !== a.commandHandler && a.commandHandler.doKeyDown() };
    La.prototype.doKeyUp = function () { var a = this.diagram; null !== a.commandHandler && a.commandHandler.doKeyUp() }; La.prototype.findTool = function (a) { for (var b = this.mouseDownTools.length, c = 0; c < b; c++) { var d = this.mouseDownTools.L(c); if (d.name === a) return d } b = this.mouseMoveTools.length; for (c = 0; c < b; c++)if (d = this.mouseMoveTools.L(c), d.name === a) return d; b = this.mouseUpTools.length; for (c = 0; c < b; c++)if (d = this.mouseUpTools.L(c), d.name === a) return d; return null };
    La.prototype.replaceTool = function (a, b) {
        null !== b && (b.diagram = this.diagram); for (var c = this.mouseDownTools.length, d = 0; d < c; d++) { var e = this.mouseDownTools.L(d); if (e.name === a) return null !== b ? this.mouseDownTools.Xc(d, b) : this.mouseDownTools.nb(d), e } c = this.mouseMoveTools.length; for (d = 0; d < c; d++)if (e = this.mouseMoveTools.L(d), e.name === a) return null !== b ? this.mouseMoveTools.Xc(d, b) : this.mouseMoveTools.nb(d), e; c = this.mouseUpTools.length; for (d = 0; d < c; d++)if (e = this.mouseUpTools.L(d), e.name === a) return null !== b ? this.mouseUpTools.Xc(d,
            b) : this.mouseUpTools.nb(d), e; return null
    }; La.prototype.gb = function (a, b, c) { null !== b && (b.name = a, b.diagram = this.diagram); this.findTool(a) ? this.replaceTool(a, b) : null !== b && c.add(b) };
    ma.Object.defineProperties(La.prototype, {
        mouseWheelBehavior: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, gestureBehavior: { get: function () { return this.u }, set: function (a) { this.u = a } }, currentToolTip: { get: function () { return this.j }, set: function (a) { this.j = a; this.Gp = null !== a && a instanceof te ? a.adornedPart : null } }, mouseDownTools: { get: function () { return this.hb } }, mouseMoveTools: {
            get: function () { return this.pb }
        }, mouseUpTools: { get: function () { return this.Fb } }, hoverDelay: { get: function () { return this.V }, set: function (a) { this.V = a } }, holdDelay: { get: function () { return this.F }, set: function (a) { this.F = a } }, dragSize: { get: function () { return this.m }, set: function (a) { this.m = a.J() } }, toolTipDuration: {
            get: function () { return this.Ha }, set: function (a) {
                this.Ha =
                a
            }
        }
    }); La.prototype.replaceStandardTool = La.prototype.gb; var ne = new D(La, "WheelScroll", 0), me = new D(La, "WheelZoom", 1), ze = new D(La, "WheelNone", 2), ue = new D(La, "GestureZoom", 3), ve = new D(La, "GestureCancel", 4), we = new D(La, "GestureNone", 5); La.className = "ToolManager"; La.WheelScroll = ne; La.WheelZoom = me; La.WheelNone = ze; La.GestureZoom = ue; La.GestureCancel = ve; La.GestureNone = we;
    function Ae() { je.call(this); this.name = "Dragging"; this.u = this.Lw = this.pb = !0; this.m = this.Ha = this.V = this.Ke = null; this.Vm = this.Fb = !1; this.pl = new I(NaN, NaN); this.xr = new I; this.hb = !0; this.Dk = 100; this.yg = []; this.pe = (new H).freeze(); this.ga = new Be; this.xn = null; this.F = "copy"; this.Lc = ""; this.Mc = "no-drop" } var Ce; la(Ae, je);
    Ae.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; if (a.isReadOnly && !a.allowDragOut || !a.allowMove && !a.allowCopy && !a.allowDragOut || !a.allowSelect) return !1; var b = a.lastInput; return !b.left || a.currentTool !== this && (!this.isBeyondDragSize() || b.isTouchEvent && b.timestamp - a.firstInput.timestamp < this.Dk) ? !1 : null !== this.findDraggablePart() };
    Ae.prototype.findDraggablePart = function () { var a = this.diagram; a = a.Jl(a.firstInput.documentPoint, !1); if (null === a) return null; for (; null !== a && !a.canSelect();)a = a.containingGroup; return null !== a && (a.canMove() || a.canCopy()) ? a : null };
    Ae.prototype.standardMouseSelect = function () { var a = this.diagram; if (a.allowSelect) { var b = a.Jl(a.firstInput.documentPoint, !1); if (null !== b) { for (; null !== b && !b.canSelect();)b = b.containingGroup; this.currentPart = b; null === this.currentPart || this.currentPart.isSelected || (a.R("ChangingSelection", a.selection), b = a.lastInput, (Pa ? b.meta : b.control) || b.shift || a.clearSelection(!0), this.currentPart.isSelected = !0, a.R("ChangedSelection", a.selection)) } } };
    Ae.prototype.doActivate = function () {
        var a = this.diagram; this.xn = null; null === this.currentPart && this.standardMouseSelect(); var b = this.currentPart; null !== b && (b.canMove() || b.canCopy()) && (a.zu(!0), De = null, this.isActive = !0, this.pl.set(a.position), Ee(this, a.selection), this.yg.length = 0, a.animationManager.zc(), this.draggedParts = this.computeEffectiveCollection(a.selection, this.dragOptions), this.Lw = this.diagram.layout.isRealtime, this.diagram.layout.isRealtime = !1, a.Gs = !0, !0 === a.Df("temporaryPixelRatio") && 30 < a.Tw &&
            Fe(a), Ge(a, this.draggedParts), this.Ea("Drag"), this.startPoint = a.firstInput.documentPoint, a.isMouseCaptured = !0, a.allowDragOut && (this.isDragOutStarted = !0, this.Vm = !1, De = this, He = this.diagram, this.doSimulatedDragOut()))
    };
    function Ee(a, b) { if (a.dragsLink) { var c = a.diagram; c.allowRelink && (c.model.Tj() && 1 === b.count && b.first() instanceof T ? (a.draggedLink = b.first(), a.draggedLink.canRelinkFrom() && a.draggedLink.canRelinkTo() && a.draggedLink.Cj(), a.Ke = c.toolManager.findTool("Relinking"), null === a.Ke && (a.Ke = new Ie, a.Ke.diagram = c), a.Ke.originalLink = a.draggedLink) : (a.draggedLink = null, a.Ke = null)) } } Ae.prototype.computeEffectiveCollection = function (a, b) { return this.diagram.commandHandler.computeEffectiveCollection(a, b) };
    Ae.prototype.Gd = function (a) { return void 0 === a ? new Je(pb) : this.isGridSnapEnabled ? new Je(new I(Math.round(1E3 * a.x) / 1E3, Math.round(1E3 * a.y) / 1E3)) : new Je(a.copy()) };
    Ae.prototype.doDeactivate = function () {
        this.isActive = !1; var a = this.diagram; this.diagram.layout.isRealtime = this.Lw; a.zu(!1); a.qg(); Te(this); Ue(a, this.draggedParts); this.draggedParts = this.currentPart = this.xn = null; this.Vm = this.isDragOutStarted = !1; if (0 < Ce.count) { for (var b = Ce, c = b.length, d = 0; d < c; d++) { var e = b.L(d); Ve(e); We(e); Te(e); e.diagram.qg() } b.clear() } Ve(this); this.pl.h(NaN, NaN); null !== He && (He.currentCursor = ""); De = He = null; We(this); a.isMouseCaptured = !1; a.currentCursor = ""; a.Gs = !1; this.stopTransaction();
        Xe(a, !0)
    }; Ae.prototype.stopTransaction = function () { var a = this.diagram, b = je.prototype.stopTransaction.call(this); b && null !== a.undoManager.transactionToUndo && a.undoManager.transactionToUndo.Yj(); return b }; function Te(a) { var b = a.diagram, c = b.skipsUndoManager; b.skipsUndoManager = !0; Ye(a, b.lastInput, null); b.skipsUndoManager = c; a.yg.length = 0 } function Ze() { var a = De; We(a); $e(a); var b = a.diagram; a.pl.w() && (b.position = a.pl); b.qg() }
    Ae.prototype.doCancel = function () { We(this); $e(this); var a = this.diagram; this.pl.w() && (a.position = this.pl); this.stopTool() }; Ae.prototype.doKeyDown = function () { var a = this.diagram, b = a.lastInput; this.isActive && ("Esc" === b.key ? this.doCancel() : b.key !== a.previousInput.key && this.doMouseMove()) }; Ae.prototype.doKeyUp = function () { this.isActive && this.doMouseMove() };
    function af(a, b) {
        if (null === a.copiedParts) {
            var c = a.diagram; if ((!b || !c.isReadOnly && !c.isModelReadOnly) && null !== a.draggedParts) {
                var d = c.undoManager; d.isEnabled && d.isInTransaction ? null !== d.currentTransaction && 0 < d.currentTransaction.changes.count && (c.undoManager.Hf(), c.Ea("Drag")) : $e(a); c.skipsUndoManager = !b; c.partManager.addsToTemporaryLayer = !b; a.startPoint = c.firstInput.documentPoint; b = a.copiesEffectiveCollection ? a.draggedParts.If() : c.selection; b = c.Ij(b, c, !0); c = new ob; for (d = a.draggedParts.iterator; d.next();) {
                    var e =
                        d.key; if (e.Uc() && e.canCopy()) { var f = b.I(e); null !== f && (f.location = e.location, f.Va(), c.add(f, a.Gd(f.location))) }
                } for (b = b.iterator; b.next();)d = b.value, d instanceof T && d.canCopy() && c.add(d, a.Gd()); a.copiedParts = c; Ee(a, c.If()); null !== a.draggedLink && (c = a.draggedLink, b = c.routeBounds, bf(c, a.startPoint.x - (b.x + b.width / 2), a.startPoint.y - (b.y + b.height / 2))); a.doUpdateCursor(null)
            }
        }
    }
    function We(a) { var b = a.diagram; if (null !== a.copiedParts) { b.Bs(a.copiedParts.If(), !1); a.copiedParts = null; if (null !== a.draggedParts) for (var c = a.draggedParts.iterator; c.next();)c.key instanceof T && (c.value.point = new I(0, 0)); a.doUpdateCursor(null) } b.skipsUndoManager = !1; b.partManager.addsToTemporaryLayer = !1; a.startPoint = b.firstInput.documentPoint }
    function Ve(a) { if (null !== a.draggedLink) { if (a.dragsLink && null !== a.Ke) { var b = a.Ke; b.diagram.remove(b.temporaryFromNode); b.diagram.remove(b.temporaryToNode); a.Ke.originalLink = null } a.draggedLink = null; a.Ke = null } } function cf(a, b, c) { var d = a.diagram, e = a.startPoint, f = I.alloc(); f.assign(d.lastInput.documentPoint); a.moveParts(b, f.ne(e), c); I.free(f); !0 === d.Df("temporaryPixelRatio") && null === d.Vh && 30 < d.Tw && (Fe(d), d.Xl()) }
    Ae.prototype.moveParts = function (a, b, c) { void 0 === c && (c = !1); var d = this.diagram; null !== d && df(d, a, b, this.dragOptions, c) }; function $e(a) { if (null !== a.draggedParts) { for (var b = a.diagram, c = a.draggedParts.iterator; c.next();) { var d = c.key; d.Uc() && (d.location = c.value.point) } for (c = a.draggedParts.iterator; c.next();)if (d = c.key, d instanceof T && d.suspendsRouting) { var e = c.value.point; a.draggedParts.add(d, a.Gd()); bf(d, -e.x, -e.y) } b.Wc() } }
    function nf(a, b) {
        var c = a.diagram; a.dragsLink && (null !== a.draggedLink && (a.draggedLink.fromNode = null, a.draggedLink.toNode = null), of(a, !1)); var d = a.findDragOverObject(b), e = c.lastInput; e.targetObject = d; a.doUpdateCursor(d); var f = c.skipsUndoManager, g = !1; try {
            c.skipsUndoManager = !0; g = Ye(a, e, d); if (!a.isActive && null === De) return; var h = null !== d ? d.part : null; if (null === h || c.handlesDragDropForTopLevelParts && h.isTopLevel && !(h instanceof pf)) { var k = c.mouseDragOver; null !== k && (k(e), g = !0) } if (!a.isActive && null === De) return;
            a.doDragOver(b, d); if (!a.isActive && null === De) return
        } finally { c.skipsUndoManager = f, g && c.Wc() } a.xn = d; c.isReadOnly || !c.allowMove && !c.allowCopy || !c.allowHorizontalScroll && !c.allowVerticalScroll || c.Zr(e.viewPoint)
    } Ae.prototype.findDragOverObject = function (a) { var b = this; return qf(this.diagram, a, null, function (a) { null === a ? a = !0 : (a = a.part, a = null === a || a instanceof te || a.layer.isTemporary || b.draggedParts && b.draggedParts.contains(a) || b.copiedParts && b.copiedParts.contains(a) ? !0 : !1); return !a }) };
    Ae.prototype.doUpdateCursor = function (a) { var b = this.diagram; if (null === a || this.xn !== a) !this.diagram.currentTool.isActive || this.mayCopy() ? b.currentCursor = this.copyCursor : this.mayMove() ? b.currentCursor = this.moveCursor : this.mayDragOut() && (b.currentCursor = this.nodropCursor) };
    function Ye(a, b, c) { var d = !1, e = a.yg.length, f = 0 < e ? a.yg[0] : null; if (c === f) return !1; b.handled = !1; for (var g = 0; g < e; g++) { var h = a.yg[g], k = h.mouseDragLeave; if (null !== k && (k(b, h, c), d = !0, b.handled)) break } a.yg.length = 0; if (!a.isActive && null === De || null === c) return d; b.handled = !1; for (e = c; null !== e;)a.yg.push(e), e = rf(e); e = a.yg.length; for (c = 0; c < e && (g = a.yg[c], h = g.mouseDragEnter, null === h || (h(b, g, f), d = !0, !b.handled)); c++); return d }
    function rf(a) { var b = a.panel; return null !== b ? b : a instanceof U && !(a instanceof pf) && (a = a.containingGroup, null !== a && a.handlesDragDropForMembers) ? a : null } function sf(a, b, c) { var d = a.Ke; if (null === d) return null; var e = a.diagram.kg(b, d.portGravity, function (a) { return d.findValidLinkablePort(a, c) }); a = I.alloc(); var f = Infinity, g = null; for (e = e.iterator; e.next();) { var h = e.value; if (null !== h.part) { var k = h.ja(xc, a); k = b.yf(k); k < f && (g = h, f = k) } } I.free(a); return g }
    function of(a, b) {
        var c = a.draggedLink; if (null !== c && !(2 > c.pointsCount)) {
            var d = a.diagram; if (!d.isReadOnly) {
                var e = a.Ke; if (null !== e) {
                    var f = null, g = null; null === c.fromNode && (f = sf(a, c.i(0), !1), null !== f && (g = f.part)); var h = null, k = null; null === c.toNode && (h = sf(a, c.i(c.pointsCount - 1), !0), null !== h && (k = h.part)); e.isValidLink(g, f, k, h) ? b ? (c.defaultFromPoint = c.i(0), c.defaultToPoint = c.i(c.pointsCount - 1), c.suspendsRouting = !1, c.fromNode = g, null !== f && (c.fromPortId = f.portId), c.toNode = k, null !== h && (c.toPortId = h.portId), c.fromPort !==
                        d.Ix && d.R("LinkRelinked", c, d.Ix), c.toPort !== d.Jx && d.R("LinkRelinked", c, d.Jx)) : tf(e, g, f, k, h) : tf(e, null, null, null, null)
                }
            }
        }
    } Ae.prototype.doDragOver = function () { };
    function uf(a, b) {
        var c = a.diagram; a.dragsLink && of(a, !0); Te(a); var d = a.findDragOverObject(b), e = c.lastInput; e.targetObject = d; if (null !== d) { e.handled = !1; for (var f = d; null !== f;) { var g = f.mouseDrop; if (null !== g && (g(e, f), e.handled)) break; vf(a, e, f); f = rf(f) } } else f = c.mouseDrop, null !== f && f(e); if (a.isActive || null !== De) {
            for (e = (a.copiedParts || a.draggedParts).iterator; e.next();)f = e.key, f instanceof V && f.linksConnected.each(function (a) { a.suspendsRouting = !1 }); a.doDropOnto(b, d); if (a.isActive || null !== De) {
                a = N.alloc(); for (b =
                    c.selection.iterator; b.next();)d = b.value, d instanceof V && wf(c, d, a); N.free(a)
            }
        }
    } function vf(a, b, c) { a = a.diagram; c = c.part; !a.handlesDragDropForTopLevelParts || !c.isTopLevel || c instanceof pf || (c = a.mouseDrop, null !== c && c(b)) } function wf(a, b, c) { var d = !1; b.getAvoidableRect(c); a.viewportBounds.Me(c) && (d = !0); a = a.Ju(c, function (a) { return a.part }, function (a) { return a instanceof T }, !0, function (a) { return a instanceof T }, d); if (0 !== a.count) for (a = a.iterator; a.next();)c = a.value, !c.Md(b) && c.isAvoiding && c.bb() }
    Ae.prototype.doDropOnto = function () { }; Ae.prototype.doMouseMove = function () { if (this.isActive) { var a = this.diagram, b = a.lastInput; this.simulatedMouseMove(b.event, b.documentPoint, b.targetDiagram) || null === this.currentPart || null === this.draggedParts || (this.mayCopy() ? (af(this, !1), Ge(a, this.copiedParts), cf(this, this.copiedParts, !1), Ue(a, this.copiedParts)) : this.mayMove() ? (We(this), cf(this, this.draggedParts, !0)) : this.mayDragOut() ? (af(this, !1), cf(this, this.copiedParts, !1)) : We(this), nf(this, a.lastInput.documentPoint)) } };
    Ae.prototype.doMouseUp = function () {
        if (this.isActive) {
            var a = this.diagram, b = a.lastInput; if (!this.simulatedMouseUp(b.event, b.documentPoint, b.targetDiagram)) {
                b = !1; var c = this.mayCopy(); c && null !== this.copiedParts ? (We(this), af(this, !0), Ge(a, this.copiedParts), cf(this, this.copiedParts, !1), Ue(a, this.copiedParts), null !== this.copiedParts && (a.R("ChangingSelection", a.selection), a.clearSelection(!0), this.copiedParts.iteratorKeys.each(function (a) { a.isSelected = !0 }))) : (b = !0, We(this), this.mayMove() && (cf(this, this.draggedParts,
                    !0), nf(this, a.lastInput.documentPoint))); this.Vm = !0; uf(this, a.lastInput.documentPoint); if (this.isActive) { var d = c ? this.copiedParts.If() : this.draggedParts.If(); this.copiedParts = null; b && xf(this); a.Xa(); Ue(a, this.draggedParts); this.transactionResult = c ? "Copy" : "Move"; a.R(c ? "SelectionCopied" : "SelectionMoved", d) } this.stopTool(); c && a.R("ChangedSelection", a.selection)
            }
        }
    };
    Ae.prototype.simulatedMouseMove = function (a, b, c) {
        if (null === De) return !1; var d = De.diagram; c instanceof Q || (c = null); var e = He; c !== e && (null !== e && e !== d && (e.qg(), De.isDragOutStarted = !1, e = e.toolManager.findTool("Dragging"), null !== e && e.doSimulatedDragLeave()), He = c, null !== c && c !== d && (Ze(), e = c.toolManager.findTool("Dragging"), null !== e && (Ce.contains(e) || Ce.add(e), e.doSimulatedDragEnter())), this.doUpdateCursor(null)); if (null === c || c === d || !c.allowDrop || c.isReadOnly || !c.allowInsert) return !1; d = c.toolManager.findTool("Dragging");
        null !== d && (null !== a && (void 0 !== a.targetTouches && (0 < a.targetTouches.length ? a = a.targetTouches[0] : 0 < a.changedTouches.length && (a = a.changedTouches[0])), b = c.getMouse(a)), c.lastInput.documentPoint = b, c.lastInput.viewPoint = c.ip(b), c.lastInput.down = !1, c.lastInput.up = !1, d.doSimulatedDragOver()); return !0
    };
    Ae.prototype.simulatedMouseUp = function (a, b, c) {
        if (null === De) return !1; var d = He, e = De.diagram; if (null === c) return De.doCancel(), !0; if (c !== d) { var f = d.toolManager.findTool("Dragging"); if (null !== d && d !== e && null !== f) return d.qg(), De.isDragOutStarted = !1, f.doSimulatedDragLeave(), !1; He = c; d = c.toolManager.findTool("Dragging"); null !== d && (Ze(), Ce.contains(d) || Ce.add(d), d.doSimulatedDragEnter()) } return c !== this.diagram ? (null !== a ? (void 0 !== a.targetTouches && (0 < a.targetTouches.length ? a = a.targetTouches[0] : 0 < a.changedTouches.length &&
            (a = a.changedTouches[0])), b = c.getMouse(a)) : null === b && (b = new I), c.lastInput.documentPoint = b, c.lastInput.viewPoint = c.ip(b), c.lastInput.down = !1, c.lastInput.up = !0, a = c.toolManager.findTool("Dragging"), null !== a && a.doSimulatedDrop(), a = De, null !== a && (c = a.mayCopy(), a.transactionResult = c ? "Copy" : "Move", a.stopTool()), !0) : !1
    };
    function xf(a) { if (null !== a.draggedParts) for (var b = a.draggedParts.iterator; b.next();) { var c = b.key; c instanceof V && (c = c.containingGroup, null === c || null === c.placeholder || a.draggedParts.contains(c) || c.placeholder.v()) } }
    Ae.prototype.mayCopy = function () { if (!this.isCopyEnabled) return !1; var a = this.diagram; if (a.isReadOnly || a.isModelReadOnly || !a.allowInsert || !a.allowCopy || (Pa ? !a.lastInput.alt : !a.lastInput.control)) return !1; for (a = a.selection.iterator; a.next();)if (a.value.canCopy()) return !0; return null !== this.draggedLink && this.dragsLink && this.draggedLink.canCopy() ? !0 : !1 };
    Ae.prototype.mayDragOut = function () { if (!this.isCopyEnabled) return !1; var a = this.diagram; if (!a.allowDragOut || !a.allowCopy || a.allowMove) return !1; for (a = a.selection.iterator; a.next();)if (a.value.canCopy()) return !0; return null !== this.draggedLink && this.dragsLink && this.draggedLink.canCopy() ? !0 : !1 };
    Ae.prototype.mayMove = function () { var a = this.diagram; if (a.isReadOnly || !a.allowMove) return !1; for (a = a.selection.iterator; a.next();)if (a.value.canMove()) return !0; return null !== this.draggedLink && this.dragsLink && this.draggedLink.canMove() ? !0 : !1 }; Ae.prototype.computeBorder = function (a, b, c) { return this.Vm || null === this.draggedParts || this.draggedParts.contains(a) ? null : c.assign(b) }; Ae.prototype.rz = function () { return De };
    Ae.prototype.mayDragIn = function () { var a = this.diagram; if (!a.allowDrop || a.isReadOnly || a.isModelReadOnly || !a.allowInsert) return !1; var b = De; return null === b || b.diagram.model.dataFormat !== a.model.dataFormat ? !1 : !0 }; Ae.prototype.doSimulatedDragEnter = function () { if (this.mayDragIn()) { var a = this.diagram; a.animationManager.zc(); yf(a); a.animationManager.zc(); a = De; null !== a && (a.diagram.Gs = !1); this.doUpdateCursor(null) } }; Ae.prototype.doSimulatedDragLeave = function () { var a = De; null !== a && a.doSimulatedDragOut(); this.doCancel() };
    Ae.prototype.doSimulatedDragOver = function () { var a = this.diagram; a.animationManager.Am = !0; var b = De; if (null !== b && null !== b.draggedParts) { if (!this.mayDragIn()) return; zf(this, b.draggedParts.If(), !1, a.firstInput); cf(this, this.copiedParts, !1); nf(this, a.lastInput.documentPoint) } a.animationManager.Am = !1 };
    Ae.prototype.doSimulatedDrop = function () {
        var a = this.diagram, b = De; if (null !== b) {
            var c = b.diagram; b.Vm = !0; We(this); if (!this.mayDragIn()) return; a.animationManager.Am = !0; a.R("ChangingSelection", a.selection); this.Ea("Drop"); zf(this, b.draggedParts.If(), !0, a.lastInput); cf(this, this.copiedParts, !1); null !== this.copiedParts && (a.clearSelection(!0), this.copiedParts.iteratorKeys.each(function (a) { a.isSelected = !0 })); uf(this, a.lastInput.documentPoint); a.Xa(); b = a.selection; null !== this.copiedParts ? this.transactionResult =
                "ExternalCopy" : b = new H; this.copiedParts = null; a.doFocus(); a.R("ExternalObjectsDropped", b, c); this.stopTransaction(); a.R("ChangedSelection", a.selection)
        } a.animationManager.Am = !1
    };
    function zf(a, b, c, d) {
        if (null === a.copiedParts) {
            var e = a.diagram; if (!e.isReadOnly && !e.isModelReadOnly) {
                e.skipsUndoManager = !c; e.partManager.addsToTemporaryLayer = !c; a.startPoint = d.documentPoint; c = e.Ij(b, e, !0); var f = N.alloc(); e = d = Infinity; for (var g = -Infinity, h = -Infinity, k = b.iterator; k.next();) { var l = k.value; if (l.isVisible()) { var m = l.location; l = m.x; m = m.y; isNaN(l) || isNaN(m) || (l < d && (d = l), m < e && (e = m), l > g && (g = l), m > h && (h = m)) } } Infinity === d ? f.h(0, 0, 0, 0) : f.h(d, e, g - d, h - e); d = f.x + f.width / 2; e = f.y + f.height / 2; N.free(f);
                f = a.xr; g = new ob; h = I.alloc(); for (k = b.iterator; k.next();)l = k.value, l instanceof T && l.canCopy() && (m = c.I(l), null !== m && (m.points = l.points, bf(m, f.x - d, f.y - e), m.suspendsRouting = !0, g.add(m, a.Gd()))); for (b = b.iterator; b.next();)l = b.value, l.Uc() && l.canCopy() && (k = c.I(l), null !== k && (l = l.location, h.h(f.x - (d - l.x), f.y - (e - l.y)), k.location = h, k.Va(), g.add(k, a.Gd(h)))); I.free(h); a.copiedParts = g; Ee(a, g.If()); null !== a.draggedLink && (c = a.draggedLink, b = c.routeBounds, bf(c, a.startPoint.x - (b.x + b.width / 2), a.startPoint.y - (b.y + b.height /
                    2))); a.doUpdateCursor(null)
            }
        }
    } Ae.prototype.doSimulatedDragOut = function () { var a = this.diagram; a.Gs = !1; this.mayCopy() || this.mayMove() ? a.currentCursor = "" : a.currentCursor = this.nodropCursor; this.xn = null }; Ae.prototype.computeMove = function (a, b, c, d) { c = this.diagram; return null !== c ? c.computeMove(a, b, this.dragOptions, d) : new I };
    ma.Object.defineProperties(Ae.prototype, {
        isCopyEnabled: { get: function () { return this.pb }, set: function (a) { this.pb = a } }, copiesEffectiveCollection: { get: function () { return this.u }, set: function (a) { this.u = a } }, dragOptions: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, isGridSnapEnabled: {
            get: function () { return this.dragOptions.isGridSnapEnabled }, set: function (a) {
                this.dragOptions.isGridSnapEnabled =
                a
            }
        }, isComplexRoutingRealtime: { get: function () { return this.hb }, set: function (a) { this.hb = a } }, isGridSnapRealtime: { get: function () { return this.dragOptions.isGridSnapRealtime }, set: function (a) { this.dragOptions.isGridSnapRealtime = a } }, gridSnapCellSize: { get: function () { return this.dragOptions.gridSnapCellSize }, set: function (a) { this.dragOptions.gridSnapCellSize.C(a) || (a = a.J(), this.dragOptions.gridSnapCellSize = a) } }, gridSnapCellSpot: {
            get: function () { return this.dragOptions.gridSnapCellSpot }, set: function (a) { this.dragOptions.gridSnapCellSpot.C(a) || (a = a.J(), this.dragOptions.gridSnapCellSpot = a) }
        }, gridSnapOrigin: { get: function () { return this.dragOptions.gridSnapOrigin }, set: function (a) { this.dragOptions.gridSnapOrigin.C(a) || (a = a.J(), this.dragOptions.gridSnapOrigin = a) } }, dragsLink: {
            get: function () { return this.dragOptions.dragsLink }, set: function (a) {
                this.dragOptions.dragsLink =
                a
            }
        }, dragsTree: { get: function () { return this.dragOptions.dragsTree }, set: function (a) { this.dragOptions.dragsTree = a } }, copyCursor: { get: function () { return this.F }, set: function (a) { this.F = a } }, moveCursor: { get: function () { return this.Lc }, set: function (a) { this.Lc = a } }, nodropCursor: { get: function () { return this.Mc }, set: function (a) { this.Mc = a } }, currentPart: {
            get: function () { return this.V },
            set: function (a) { this.V = a }
        }, copiedParts: { get: function () { return this.m }, set: function (a) { this.m = a } }, draggedParts: { get: function () { return this.Ha }, set: function (a) { this.Ha = a } }, draggingParts: { get: function () { return null !== this.copiedParts ? this.copiedParts.If() : null !== this.draggedParts ? this.draggedParts.If() : this.pe } }, draggedLink: {
            get: function () { return this.diagram.draggedLink }, set: function (a) {
                this.diagram.draggedLink =
                a
            }
        }, isDragOutStarted: { get: function () { return this.Fb }, set: function (a) { this.Fb = a } }, startPoint: { get: function () { return this.xr }, set: function (a) { this.xr.C(a) || this.xr.assign(a) } }, delay: { get: function () { return this.Dk }, set: function (a) { this.Dk = a } }
    }); Ae.prototype.getDraggingSource = Ae.prototype.rz; var De = null, He = null; Ae.className = "DraggingTool"; Ce = new F;
    Ka("draggingTool", function () { return this.findTool("Dragging") }, function (a) { this.gb("Dragging", a, this.mouseMoveTools) }); La.prototype.doCancel = function () { null !== De && De.doCancel(); je.prototype.doCancel.call(this) };
    function Af() {
        je.call(this); this.pe = 100; this.Ha = !1; this.u = "pointer"; var a = new T, b = new Bf; b.isPanelMain = !0; b.stroke = "blue"; a.add(b); b = new Bf; b.toArrow = "Standard"; b.fill = "blue"; b.stroke = "blue"; a.add(b); a.layerName = "Tool"; this.zi = a; a = new V; b = new Bf; b.portId = ""; b.figure = "Rectangle"; b.fill = null; b.stroke = "magenta"; b.strokeWidth = 2; b.desiredSize = Gb; a.add(b); a.selectable = !1; a.layerName = "Tool"; this.yi = a; this.j = b; a = new V; b = new Bf; b.portId = ""; b.figure = "Rectangle"; b.fill = null; b.stroke = "magenta"; b.strokeWidth =
            2; b.desiredSize = Gb; a.add(b); a.selectable = !1; a.layerName = "Tool"; this.Ai = a; this.m = b; this.Mc = this.Lc = this.pb = this.hb = this.Fb = null; this.ga = !0; this.ay = new ob; this.Lf = this.ej = this.Mf = null
    } la(Af, je); Af.prototype.doStop = function () { this.diagram.qg(); this.originalToPort = this.originalToNode = this.originalFromPort = this.originalFromNode = this.originalLink = null; this.validPortsCache.clear(); this.targetPort = null };
    Af.prototype.copyPortProperties = function (a, b, c, d, e) { if (null !== a && null !== b && null !== c && null !== d) { var f = b.Af(), g = L.alloc(); g.width = b.naturalBounds.width * f; g.height = b.naturalBounds.height * f; d.desiredSize = g; L.free(g); e ? (d.toSpot = b.toSpot, d.toEndSegmentLength = b.toEndSegmentLength) : (d.fromSpot = b.fromSpot, d.fromEndSegmentLength = b.fromEndSegmentLength); c.locationSpot = xc; f = I.alloc(); c.location = b.ja(xc, f); I.free(f); d.angle = b.ii(); null !== this.portTargeted && this.portTargeted(a, b, c, d, e) } };
    Af.prototype.setNoTargetPortProperties = function (a, b, c) { null !== b && (b.desiredSize = Gb, b.fromSpot = rc, b.toSpot = rc); null !== a && (a.location = this.diagram.lastInput.documentPoint); null !== this.portTargeted && this.portTargeted(null, null, a, b, c) }; Af.prototype.doMouseDown = function () { this.isActive && this.doMouseMove() };
    Af.prototype.doMouseMove = function () {
        if (this.isActive) {
            var a = this.diagram; this.targetPort = this.findTargetPort(this.isForwards); if (null !== this.targetPort && this.targetPort.part instanceof V) { var b = this.targetPort.part; this.isForwards ? this.copyPortProperties(b, this.targetPort, this.temporaryToNode, this.temporaryToPort, !0) : this.copyPortProperties(b, this.targetPort, this.temporaryFromNode, this.temporaryFromPort, !1) } else this.isForwards ? this.setNoTargetPortProperties(this.temporaryToNode, this.temporaryToPort,
                !0) : this.setNoTargetPortProperties(this.temporaryFromNode, this.temporaryFromPort, !1); (a.allowHorizontalScroll || a.allowVerticalScroll) && a.Zr(a.lastInput.viewPoint)
        }
    }; Af.prototype.findValidLinkablePort = function (a, b) { if (null === a) return null; var c = a.part; if (!(c instanceof V)) return null; for (; null !== a;) { var d = b ? a.toLinkable : a.fromLinkable; if (!0 === d && (null !== a.portId || a instanceof V) && (b ? this.isValidTo(c, a) : this.isValidFrom(c, a))) return a; if (!1 === d) break; a = a.panel } return null };
    Af.prototype.findTargetPort = function (a) {
        var b = this.diagram, c = b.lastInput.documentPoint, d = this.portGravity; 0 >= d && (d = .1); var e = this, f = b.kg(c, d, function (b) { return e.findValidLinkablePort(b, a) }, null, !0); d = Infinity; b = null; for (f = f.iterator; f.next();) {
            var g = f.value, h = g.part; if (h instanceof V) {
                var k = g.ja(xc, I.alloc()), l = c.x - k.x, m = c.y - k.y; I.free(k); k = l * l + m * m; k < d && (l = this.validPortsCache.I(g), null !== l ? l && (b = g, d = k) : a && this.isValidLink(this.originalFromNode, this.originalFromPort, h, g) || !a && this.isValidLink(h,
                    g, this.originalToNode, this.originalToPort) ? (this.validPortsCache.add(g, !0), b = g, d = k) : this.validPortsCache.add(g, !1))
            }
        } return null !== b && (c = b.part, c instanceof V && (null === c.layer || c.layer.allowLink)) ? b : null
    };
    Af.prototype.isValidFrom = function (a, b) { if (null === a || null === b) return this.isUnconnectedLinkValid; if (this.diagram.currentTool === this && (null !== a.layer && !a.layer.allowLink || !0 !== b.fromLinkable)) return !1; var c = b.fromMaxLinks; if (Infinity > c) { if (null !== this.originalLink && a === this.originalFromNode && b === this.originalFromPort) return !0; b = b.portId; null === b && (b = ""); if (a.Io(b).count >= c) return !1 } return !0 };
    Af.prototype.isValidTo = function (a, b) { if (null === a || null === b) return this.isUnconnectedLinkValid; if (this.diagram.currentTool === this && (null !== a.layer && !a.layer.allowLink || !0 !== b.toLinkable)) return !1; var c = b.toMaxLinks; if (Infinity > c) { if (null !== this.originalLink && a === this.originalToNode && b === this.originalToPort) return !0; b = b.portId; null === b && (b = ""); if (a.Kd(b).count >= c) return !1 } return !0 };
    Af.prototype.isInSameNode = function (a, b) { if (null === a || null === b) return !1; if (a === b) return !0; a = a.part; b = b.part; return null !== a && a === b }; Af.prototype.isLinked = function (a, b) { if (null === a || null === b) return !1; var c = a.part; if (!(c instanceof V)) return !1; a = a.portId; null === a && (a = ""); var d = b.part; if (!(d instanceof V)) return !1; b = b.portId; null === b && (b = ""); for (b = d.Kd(b); b.next();)if (d = b.value, d.fromNode === c && d.fromPortId === a) return !0; return !1 };
    Af.prototype.isValidLink = function (a, b, c, d) {
        if (!this.isValidFrom(a, b) || !this.isValidTo(c, d) || !(null === b || null === d || (b.fromLinkableSelfNode && d.toLinkableSelfNode || !this.isInSameNode(b, d)) && (b.fromLinkableDuplicates && d.toLinkableDuplicates || !this.isLinked(b, d))) || null !== this.originalLink && (null !== a && this.isLabelDependentOnLink(a, this.originalLink) || null !== c && this.isLabelDependentOnLink(c, this.originalLink)) || null !== a && null !== c && (null === a.data && null !== c.data || null !== a.data && null === c.data) || !this.isValidCycle(a,
            c, this.originalLink)) return !1; if (null !== a) { var e = a.linkValidation; if (null !== e && !e(a, b, c, d, this.originalLink)) return !1 } if (null !== c && (e = c.linkValidation, null !== e && !e(a, b, c, d, this.originalLink))) return !1; e = this.linkValidation; return null !== e ? e(a, b, c, d, this.originalLink) : !0
    }; Af.prototype.isLabelDependentOnLink = function (a, b) { if (null === a) return !1; var c = a.labeledLink; if (null === c) return !1; if (c === b) return !0; var d = new H; d.add(a); return Cf(this, c, b, d) };
    function Cf(a, b, c, d) { if (b === c) return !0; var e = b.fromNode; if (null !== e && e.isLinkLabel && (d.add(e), Cf(a, e.labeledLink, c, d))) return !0; b = b.toNode; return null !== b && b.isLinkLabel && (d.add(b), Cf(a, b.labeledLink, c, d)) ? !0 : !1 }
    Af.prototype.isValidCycle = function (a, b, c) {
        void 0 === c && (c = null); if (null === a || null === b) return this.isUnconnectedLinkValid; var d = this.diagram.validCycle; if (d !== Df) {
            if (d === Ef) { d = c || this.temporaryLink; if (null !== d && !d.isTreeLink) return !0; for (d = b.linksConnected; d.next();) { var e = d.value; if (e !== c && e.isTreeLink && e.toNode === b) return !1 } return !Ff(this, a, b, c, !0) } if (d === Gf) {
                d = c || this.temporaryLink; if (null !== d && !d.isTreeLink) return !0; for (d = a.linksConnected; d.next();)if (e = d.value, e !== c && e.isTreeLink && e.fromNode ===
                    a) return !1; return !Ff(this, a, b, c, !0)
            } if (d === Hf) return a === b ? a = !0 : (d = new H, d.add(b), a = If(this, d, a, b, c)), !a; if (d === Qf) return !Ff(this, a, b, c, !1); if (d === Rf) return a === b ? a = !0 : (d = new H, d.add(b), a = Sf(this, d, a, b, c)), !a
        } return !0
    }; function Ff(a, b, c, d, e) { if (b === c) return !0; if (null === b || null === c) return !1; for (var f = b.linksConnected; f.next();) { var g = f.value; if (g !== d && (!e || g.isTreeLink) && g.toNode === b && (g = g.fromNode, g !== b && Ff(a, g, c, d, e))) return !0 } return !1 }
    function If(a, b, c, d, e) { if (c === d) return !0; if (null === c || null === d || b.contains(c)) return !1; b.add(c); for (var f = c.linksConnected; f.next();) { var g = f.value; if (g !== e && g.toNode === c && (g = g.fromNode, g !== c && If(a, b, g, d, e))) return !0 } return !1 } function Sf(a, b, c, d, e) { if (c === d) return !0; if (null === c || null === d || b.contains(c)) return !1; b.add(c); for (var f = c.linksConnected; f.next();) { var g = f.value; if (g !== e) { var h = g.fromNode; g = g.toNode; h = h === c ? g : h; if (h !== c && Sf(a, b, h, d, e)) return !0 } } return !1 }
    ma.Object.defineProperties(Af.prototype, {
        portGravity: { get: function () { return this.pe }, set: function (a) { 0 <= a && (this.pe = a) } }, isUnconnectedLinkValid: { get: function () { return this.Ha }, set: function (a) { this.Ha = a } }, linkingCursor: { get: function () { return this.u }, set: function (a) { this.u = a } }, temporaryLink: { get: function () { return this.zi }, set: function (a) { this.zi = a } }, temporaryFromNode: {
            get: function () { return this.yi }, set: function (a) { if (this.yi = a) this.j = a.port }
        }, temporaryFromPort: { get: function () { return this.j }, set: function (a) { if (null !== this.j) { var b = this.j.panel; if (null !== b) { var c = b.P.indexOf(this.j); b.nb(c); b.Eb(c, a) } } this.j = a } }, temporaryToNode: { get: function () { return this.Ai }, set: function (a) { if (this.Ai = a) this.m = a.port } }, temporaryToPort: {
            get: function () { return this.m }, set: function (a) {
                if (null !==
                    this.m) { var b = this.m.panel; if (null !== b) { var c = b.P.indexOf(this.m); b.nb(c); b.Eb(c, a) } } this.m = a
            }
        }, originalLink: { get: function () { return this.Fb }, set: function (a) { this.Fb = a } }, originalFromNode: { get: function () { return this.hb }, set: function (a) { this.hb = a } }, originalFromPort: { get: function () { return this.pb }, set: function (a) { this.pb = a } }, originalToNode: {
            get: function () { return this.Lc }, set: function (a) {
                this.Lc =
                a
            }
        }, originalToPort: { get: function () { return this.Mc }, set: function (a) { this.Mc = a } }, isForwards: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, validPortsCache: { get: function () { return this.ay } }, targetPort: { get: function () { return this.Mf }, set: function (a) { this.Mf = a } }, linkValidation: { get: function () { return this.ej }, set: function (a) { this.ej = a } }, portTargeted: {
            get: function () { return this.Lf }, set: function (a) { this.Lf = a }
        }
    }); Af.className = "LinkingBaseTool"; function Tf() { Af.call(this); this.name = "Linking"; this.V = {}; this.F = null; this.K = Uf; this.Sd = null } la(Tf, Af); Tf.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return a.isReadOnly || a.isModelReadOnly || !a.allowLink || !a.model.qs() || !a.lastInput.left || a.currentTool !== this && !this.isBeyondDragSize() ? !1 : null !== this.findLinkablePort() };
    Tf.prototype.findLinkablePort = function () {
        var a = this.diagram, b = this.startObject; null === b && (b = a.ec(a.firstInput.documentPoint, null, null)); if (null === b) return null; a = b.part; if (!(a instanceof V)) return null; var c = this.direction; if (c === Uf || c === Vf) { var d = this.findValidLinkablePort(b, !1); if (null !== d || this.startObject === a && (d = a.port, this.findValidLinkablePort(d, !1))) return this.isForwards = !0, d } if (c === Uf || c === Wf) if (b = this.findValidLinkablePort(b, !0), null !== b || this.startObject === a && (b = a.port, this.findValidLinkablePort(b,
            !0))) return this.isForwards = !1, b; return null
    };
    Tf.prototype.doActivate = function () {
        var a = this.diagram, b = this.findLinkablePort(); null === b ? this.stopTool() : (this.Ea(this.name), a.isMouseCaptured = !0, a.currentCursor = this.linkingCursor, this.isForwards ? (null !== this.temporaryToNode && (this.temporaryToNode.location = a.lastInput.documentPoint), this.originalFromPort = b, b = this.originalFromPort.part, b instanceof V && (this.originalFromNode = b), this.copyPortProperties(this.originalFromNode, this.originalFromPort, this.temporaryFromNode, this.temporaryFromPort, !1)) : (null !==
            this.temporaryFromNode && (this.temporaryFromNode.location = a.lastInput.documentPoint), this.originalToPort = b, b = this.originalToPort.part, b instanceof V && (this.originalToNode = b), this.copyPortProperties(this.originalToNode, this.originalToPort, this.temporaryToNode, this.temporaryToPort, !0)), a.add(this.temporaryFromNode), this.temporaryFromNode && this.temporaryFromNode.Va(), a.add(this.temporaryToNode), this.temporaryToNode && this.temporaryToNode.Va(), null !== this.temporaryLink && (null !== this.temporaryFromNode &&
                (this.temporaryLink.fromNode = this.temporaryFromNode), null !== this.temporaryToNode && (this.temporaryLink.toNode = this.temporaryToNode), this.temporaryLink.isTreeLink = this.isNewTreeLink(), this.temporaryLink.bb(), a.add(this.temporaryLink)), this.isActive = !0)
    }; Tf.prototype.doDeactivate = function () { this.isActive = !1; var a = this.diagram; a.remove(this.temporaryLink); a.remove(this.temporaryFromNode); a.remove(this.temporaryToNode); a.isMouseCaptured = !1; a.currentCursor = ""; this.stopTransaction() };
    Tf.prototype.doStop = function () { Af.prototype.doStop.call(this); this.startObject = null };
    Tf.prototype.doMouseUp = function () {
        if (this.isActive) {
            var a = this.diagram, b = this.transactionResult = null, c = null, d = null, e = null, f = null; try {
                var g = this.targetPort = this.findTargetPort(this.isForwards); if (null !== g) { var h = g.part; h instanceof V && (this.isForwards ? (null !== this.originalFromNode && (b = this.originalFromNode, c = this.originalFromPort), d = h, e = g) : (b = h, c = g, null !== this.originalToNode && (d = this.originalToNode, e = this.originalToPort))) } else this.isForwards ? null !== this.originalFromNode && this.isUnconnectedLinkValid &&
                    (b = this.originalFromNode, c = this.originalFromPort) : null !== this.originalToNode && this.isUnconnectedLinkValid && (d = this.originalToNode, e = this.originalToPort); null !== b || null !== d ? (f = this.insertLink(b, c, d, e), null !== f ? (null === g && (this.isForwards ? f.defaultToPoint = a.lastInput.documentPoint : f.defaultFromPoint = a.lastInput.documentPoint), a.allowSelect && (a.R("ChangingSelection", a.selection), a.clearSelection(!0), f.isSelected = !0), this.transactionResult = this.name, a.R("LinkDrawn", f)) : this.doNoLink(b, c, d, e)) : this.isForwards ?
                        this.doNoLink(this.originalFromNode, this.originalFromPort, null, null) : this.doNoLink(null, null, this.originalToNode, this.originalToPort)
            } finally { this.stopTool(), f && a.allowSelect && a.R("ChangedSelection", a.selection) }
        }
    }; Tf.prototype.isNewTreeLink = function () { var a = this.archetypeLinkData; if (null === a) return !0; if (a instanceof T) return a.isTreeLink; var b = this.diagram; if (null === b) return !0; a = b.partManager.getLinkCategoryForData(a); b = b.partManager.findLinkTemplateForCategory(a); return null !== b ? b.isTreeLink : !0 };
    Tf.prototype.insertLink = function (a, b, c, d) { return this.diagram.partManager.insertLink(a, b, c, d) }; Tf.prototype.doNoLink = function () { };
    ma.Object.defineProperties(Tf.prototype, { archetypeLinkData: { get: function () { return this.V }, set: function (a) { this.V = a } }, archetypeLabelNodeData: { get: function () { return this.F }, set: function (a) { this.F = a } }, direction: { get: function () { return this.K }, set: function (a) { this.K = a } }, startObject: { get: function () { return this.Sd }, set: function (a) { this.Sd = a } } });
    var Uf = new D(Tf, "Either", 0), Vf = new D(Tf, "ForwardsOnly", 0), Wf = new D(Tf, "BackwardsOnly", 0); Tf.className = "LinkingTool"; Tf.Either = Uf; Tf.ForwardsOnly = Vf; Tf.BackwardsOnly = Wf;
    function Ie() { Af.call(this); this.name = "Relinking"; var a = new Bf; a.figure = "Diamond"; a.desiredSize = Ib; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.cursor = this.linkingCursor; a.segmentIndex = 0; this.V = a; a = new Bf; a.figure = "Diamond"; a.desiredSize = Ib; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.cursor = this.linkingCursor; a.segmentIndex = -1; this.Sd = a; this.F = null; this.xw = new N } la(Ie, Af);
    Ie.prototype.updateAdornments = function (a) {
        if (null !== a && a instanceof T) {
            var b = "RelinkFrom", c = null; if (a.isSelected && !this.diagram.isReadOnly) { var d = a.selectionObject; null !== d && a.canRelinkFrom() && a.actualBounds.w() && a.isVisible() && d.actualBounds.w() && d.Nd() && (c = a.Nj(b), null === c && (c = this.makeAdornment(d, !1), a.Wg(b, c))) } null === c && a.Gf(b); b = "RelinkTo"; c = null; a.isSelected && !this.diagram.isReadOnly && (d = a.selectionObject, null !== d && a.canRelinkTo() && a.actualBounds.w() && a.isVisible() && d.actualBounds.w() && d.Nd() &&
                (c = a.Nj(b), null === c ? (c = this.makeAdornment(d, !0), a.Wg(b, c)) : c.v())); null === c && a.Gf(b)
        }
    }; Ie.prototype.makeAdornment = function (a, b) { var c = new te; c.type = W.Link; b = b ? this.toHandleArchetype : this.fromHandleArchetype; null !== b && c.add(b.copy()); c.adornedObject = a; return c };
    Ie.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; if (a.isReadOnly || a.isModelReadOnly || !a.allowRelink || !a.model.qs() || !a.lastInput.left) return !1; var b = this.findToolHandleAt(a.firstInput.documentPoint, "RelinkFrom"); null === b && (b = this.findToolHandleAt(a.firstInput.documentPoint, "RelinkTo")); return null !== b };
    Ie.prototype.doActivate = function () {
        var a = this.diagram; if (null === this.originalLink) { var b = this.handle; null === b && (b = this.findToolHandleAt(a.firstInput.documentPoint, "RelinkFrom"), null === b && (b = this.findToolHandleAt(a.firstInput.documentPoint, "RelinkTo"))); if (null === b) return; var c = b.part; if (!(c instanceof te && c.adornedPart instanceof T)) return; this.handle = b; this.isForwards = null === c || "RelinkTo" === c.category; this.originalLink = c.adornedPart } this.Ea(this.name); a.isMouseCaptured = !0; a.currentCursor = this.linkingCursor;
        this.originalFromPort = this.originalLink.fromPort; this.originalFromNode = this.originalLink.fromNode; this.originalToPort = this.originalLink.toPort; this.originalToNode = this.originalLink.toNode; this.xw.set(this.originalLink.actualBounds); null !== this.originalLink && 0 < this.originalLink.pointsCount && (null === this.originalLink.fromNode && (null !== this.temporaryFromPort && (this.temporaryFromPort.desiredSize = Cb), null !== this.temporaryFromNode && (this.temporaryFromNode.location = this.originalLink.i(0))), null === this.originalLink.toNode &&
            (null !== this.temporaryToPort && (this.temporaryToPort.desiredSize = Cb), null !== this.temporaryToNode && (this.temporaryToNode.location = this.originalLink.i(this.originalLink.pointsCount - 1)))); this.copyPortProperties(this.originalFromNode, this.originalFromPort, this.temporaryFromNode, this.temporaryFromPort, !1); this.copyPortProperties(this.originalToNode, this.originalToPort, this.temporaryToNode, this.temporaryToPort, !0); a.add(this.temporaryFromNode); a.add(this.temporaryToNode); null !== this.temporaryLink && (null !==
                this.temporaryFromNode && (this.temporaryLink.fromNode = this.temporaryFromNode), null !== this.temporaryToNode && (this.temporaryLink.toNode = this.temporaryToNode), this.copyLinkProperties(this.originalLink, this.temporaryLink), this.temporaryLink.bb(), a.add(this.temporaryLink)); this.isActive = !0
    };
    Ie.prototype.copyLinkProperties = function (a, b) { if (null !== a && null !== b) { b.adjusting = a.adjusting; b.corner = a.corner; var c = a.curve; if (c === Xf || c === Yf) c = Zf; b.curve = c; b.curviness = a.curviness; b.isTreeLink = a.isTreeLink; b.points = a.points; b.routing = a.routing; b.smoothness = a.smoothness; b.fromSpot = a.fromSpot; b.fromEndSegmentLength = a.fromEndSegmentLength; b.fromShortLength = a.fromShortLength; b.toSpot = a.toSpot; b.toEndSegmentLength = a.toEndSegmentLength; b.toShortLength = a.toShortLength } };
    Ie.prototype.doDeactivate = function () { this.isActive = !1; var a = this.diagram; a.remove(this.temporaryLink); a.remove(this.temporaryFromNode); a.remove(this.temporaryToNode); a.isMouseCaptured = !1; a.currentCursor = ""; this.stopTransaction() }; Ie.prototype.doStop = function () { Af.prototype.doStop.call(this); this.handle = null };
    Ie.prototype.doMouseUp = function () {
        if (this.isActive) {
            var a = this.diagram; this.transactionResult = null; var b = this.originalFromNode, c = this.originalFromPort, d = this.originalToNode, e = this.originalToPort, f = this.originalLink; try {
                this.targetPort = this.findTargetPort(this.isForwards); if (null !== this.targetPort) { var g = this.targetPort.part; g instanceof V && (this.isForwards ? (d = g, e = this.targetPort) : (b = g, c = this.targetPort)) } else this.isUnconnectedLinkValid ? this.isForwards ? e = d = null : c = b = null : f = null; null !== f ? (this.reconnectLink(f,
                    this.isForwards ? d : b, this.isForwards ? e : c, this.isForwards), null === this.targetPort && (this.isForwards ? f.defaultToPoint = a.lastInput.documentPoint : f.defaultFromPoint = a.lastInput.documentPoint, f.bb()), this.transactionResult = this.name, a.R("LinkRelinked", f, this.isForwards ? this.originalToPort : this.originalFromPort)) : this.doNoRelink(this.originalLink, this.isForwards); this.originalLink.Ro(this.xw)
            } finally { this.stopTool() }
        }
    };
    Ie.prototype.reconnectLink = function (a, b, c, d) { c = null !== c && null !== c.portId ? c.portId : ""; d ? (a.toNode = b, a.toPortId = c) : (a.fromNode = b, a.fromPortId = c); return !0 }; Ie.prototype.doNoRelink = function () { };
    function tf(a, b, c, d, e) { null !== b ? (a.copyPortProperties(b, c, a.temporaryFromNode, a.temporaryFromPort, !1), a.diagram.add(a.temporaryFromNode)) : a.diagram.remove(a.temporaryFromNode); null !== d ? (a.copyPortProperties(d, e, a.temporaryToNode, a.temporaryToPort, !0), a.diagram.add(a.temporaryToNode)) : a.diagram.remove(a.temporaryToNode) }
    ma.Object.defineProperties(Ie.prototype, { fromHandleArchetype: { get: function () { return this.V }, set: function (a) { this.V = a } }, toHandleArchetype: { get: function () { return this.Sd }, set: function (a) { this.Sd = a } }, handle: { get: function () { return this.F }, set: function (a) { if (null !== a && !(a.part instanceof te)) throw Error("new handle is not in an Adornment: " + a); this.F = a } } }); Ie.className = "RelinkingTool";
    Ka("linkingTool", function () { return this.findTool("Linking") }, function (a) { this.gb("Linking", a, this.mouseMoveTools) }); Ka("relinkingTool", function () { return this.findTool("Relinking") }, function (a) { this.gb("Relinking", a, this.mouseDownTools) });
    function $f() { je.call(this); this.name = "LinkReshaping"; var a = new Bf; a.figure = "Rectangle"; a.desiredSize = Hb; a.fill = "lightblue"; a.stroke = "dodgerblue"; this.m = a; a = new Bf; a.figure = "Diamond"; a.desiredSize = Ib; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.cursor = "move"; this.u = a; this.F = 3; this.Ms = this.j = null; this.yw = new I; this.Rq = new F } la($f, je); $f.prototype.Nu = function (a) { return a && 0 !== a.reshapingBehavior.value ? a.reshapingBehavior : ag }; $f.prototype.lm = function (a, b) { a.reshapingBehavior = b };
    $f.prototype.updateAdornments = function (a) { if (null !== a && a instanceof T) { var b = null; if (a.isSelected && !this.diagram.isReadOnly) { var c = a.path; null !== c && a.canReshape() && a.actualBounds.w() && a.isVisible() && c.actualBounds.w() && c.Nd() && (b = a.Nj(this.name), null === b || b.tw !== a.pointsCount || b.Nw !== a.resegmentable) && (b = this.makeAdornment(c), null !== b && (b.tw = a.pointsCount, b.Nw = a.resegmentable, a.Wg(this.name, b))) } null === b && a.Gf(this.name) } };
    $f.prototype.makeAdornment = function (a) {
        var b = a.part, c = b.pointsCount, d = b.isOrthogonal, e = null; if (null !== b.points && 1 < c) {
            e = new te; e.type = W.Link; c = b.firstPickIndex; var f = b.lastPickIndex, g = d ? 1 : 0; if (b.resegmentable && b.computeCurve() !== bg) for (var h = c + g; h < f - g; h++) { var k = this.makeResegmentHandle(a, h); null !== k && (k.segmentIndex = h, k.segmentFraction = .5, k.fromMaxLinks = 999, e.add(k)) } for (g = c + 1; g < f; g++)if (h = this.makeHandle(a, g), null !== h) {
                h.segmentIndex = g; if (g !== c) if (g === c + 1 && d) {
                    k = b.i(c); var l = b.i(c + 1); K.B(k.x, l.x) &&
                        K.B(k.y, l.y) && (l = b.i(c - 1)); K.B(k.x, l.x) ? (this.lm(h, cg), h.cursor = "n-resize") : K.B(k.y, l.y) && (this.lm(h, dg), h.cursor = "w-resize")
                } else g === f - 1 && d ? (k = b.i(f - 1), l = b.i(f), K.B(k.x, l.x) && K.B(k.y, l.y) && (k = b.i(f + 1)), K.B(k.x, l.x) ? (this.lm(h, cg), h.cursor = "n-resize") : K.B(k.y, l.y) && (this.lm(h, dg), h.cursor = "w-resize")) : g !== f && (this.lm(h, eg), h.cursor = "move"); e.add(h)
            } e.adornedObject = a
        } return e
    }; $f.prototype.makeHandle = function () { var a = this.handleArchetype; return null === a ? null : a.copy() };
    $f.prototype.makeResegmentHandle = function () { var a = this.midHandleArchetype; return null === a ? null : a.copy() }; $f.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return !a.isReadOnly && a.allowReshape && a.lastInput.left ? null !== this.findToolHandleAt(a.firstInput.documentPoint, this.name) : !1 };
    $f.prototype.doActivate = function () {
        var a = this.diagram; null === this.handle && (this.handle = this.findToolHandleAt(a.firstInput.documentPoint, this.name)); if (null !== this.handle) {
            var b = this.handle.part.adornedPart; if (b instanceof T) {
                this.Ms = b; a.isMouseCaptured = !0; this.Ea(this.name); if (b.resegmentable && 999 === this.handle.fromMaxLinks) {
                    var c = b.points.copy(), d = this.getResegmentingPoint(); c.Eb(this.handle.segmentIndex + 1, d); b.isOrthogonal && c.Eb(this.handle.segmentIndex + 1, d); b.points = c; b.Sb(); b.updateAdornments();
                    this.handle = this.findToolHandleAt(a.firstInput.documentPoint, this.name); if (null === this.handle) { this.doDeactivate(); return }
                } this.yw = b.i(this.handle.segmentIndex); this.Rq = b.points.copy(); this.isActive = !0
            }
        }
    }; $f.prototype.doDeactivate = function () { this.stopTransaction(); this.Ms = this.handle = null; this.isActive = this.diagram.isMouseCaptured = !1 };
    $f.prototype.stopTransaction = function () { var a = this.diagram, b = je.prototype.stopTransaction.call(this); b && null !== a.undoManager.transactionToUndo && a.undoManager.transactionToUndo.Yj(); return b }; $f.prototype.doCancel = function () { var a = this.adornedLink; null !== a && (a.points = this.Rq); this.stopTool() }; $f.prototype.getResegmentingPoint = function () { return this.handle.ja(xc) }; $f.prototype.doMouseMove = function () { var a = this.diagram; this.isActive && (a = this.computeReshape(a.lastInput.documentPoint), this.reshape(a)) };
    $f.prototype.doMouseUp = function () {
        var a = this.diagram; if (this.isActive) {
            var b = this.computeReshape(a.lastInput.documentPoint); this.reshape(b); b = this.adornedLink; if (null !== b && b.resegmentable) {
                var c = this.handle.segmentIndex, d = b.i(c - 1), e = b.i(c), f = b.i(c + 1); if (b.isOrthogonal) {
                    if (c > b.firstPickIndex + 1 && c < b.lastPickIndex - 1) {
                        var g = b.i(c - 2); if (Math.abs(d.x - e.x) < this.resegmentingDistance && Math.abs(d.y - e.y) < this.resegmentingDistance && (fg(this, g, d, e, f, !0) || fg(this, g, d, e, f, !1))) {
                            var h = b.points.copy(); fg(this, g, d,
                                e, f, !0) ? (h.Xc(c - 2, new I(g.x, (f.y + g.y) / 2)), h.Xc(c + 1, new I(f.x, (f.y + g.y) / 2))) : (h.Xc(c - 2, new I((f.x + g.x) / 2, g.y)), h.Xc(c + 1, new I((f.x + g.x) / 2, f.y))); h.nb(c); h.nb(c - 1); b.points = h; b.Sb()
                        } else g = b.i(c + 2), Math.abs(e.x - f.x) < this.resegmentingDistance && Math.abs(e.y - f.y) < this.resegmentingDistance && (fg(this, d, e, f, g, !0) || fg(this, d, e, f, g, !1)) && (h = b.points.copy(), fg(this, d, e, f, g, !0) ? (h.Xc(c - 1, new I(d.x, (d.y + g.y) / 2)), h.Xc(c + 2, new I(g.x, (d.y + g.y) / 2))) : (h.Xc(c - 1, new I((d.x + g.x) / 2, d.y)), h.Xc(c + 2, new I((d.x + g.x) / 2, g.y))),
                            h.nb(c + 1), h.nb(c), b.points = h, b.Sb())
                    }
                } else g = I.alloc(), K.lh(d.x, d.y, f.x, f.y, e.x, e.y, g) && g.yf(e) < this.resegmentingDistance * this.resegmentingDistance && (d = b.points.copy(), d.nb(c), b.points = d, b.Sb()), I.free(g)
            } a.Xa(); this.transactionResult = this.name; a.R("LinkReshaped", this.adornedLink, this.Rq)
        } this.stopTool()
    };
    function fg(a, b, c, d, e, f) { return f ? Math.abs(b.y - c.y) < a.resegmentingDistance && Math.abs(c.y - d.y) < a.resegmentingDistance && Math.abs(d.y - e.y) < a.resegmentingDistance : Math.abs(b.x - c.x) < a.resegmentingDistance && Math.abs(c.x - d.x) < a.resegmentingDistance && Math.abs(d.x - e.x) < a.resegmentingDistance }
    $f.prototype.reshape = function (a) {
        var b = this.adornedLink; b.ph(); var c = this.handle.segmentIndex, d = this.Nu(this.handle); if (b.isOrthogonal) if (c === b.firstPickIndex + 1) c = b.firstPickIndex + 1, d === cg ? (b.M(c, b.i(c - 1).x, a.y), b.M(c + 1, b.i(c + 2).x, a.y)) : d === dg && (b.M(c, a.x, b.i(c - 1).y), b.M(c + 1, a.x, b.i(c + 2).y)); else if (c === b.lastPickIndex - 1) c = b.lastPickIndex - 1, d === cg ? (b.M(c - 1, b.i(c - 2).x, a.y), b.M(c, b.i(c + 1).x, a.y)) : d === dg && (b.M(c - 1, a.x, b.i(c - 2).y), b.M(c, a.x, b.i(c + 1).y)); else {
            d = c; var e = b.i(d), f = b.i(d - 1), g = b.i(d + 1); K.B(f.x,
                e.x) && K.B(e.y, g.y) ? (K.B(f.x, b.i(d - 2).x) && !K.B(f.y, b.i(d - 2).y) ? (b.l(d, a.x, f.y), c++, d++) : b.M(d - 1, a.x, f.y), K.B(g.y, b.i(d + 2).y) && !K.B(g.x, b.i(d + 2).x) ? b.l(d + 1, g.x, a.y) : b.M(d + 1, g.x, a.y)) : K.B(f.y, e.y) && K.B(e.x, g.x) ? (K.B(f.y, b.i(d - 2).y) && !K.B(f.x, b.i(d - 2).x) ? (b.l(d, f.x, a.y), c++, d++) : b.M(d - 1, f.x, a.y), K.B(g.x, b.i(d + 2).x) && !K.B(g.y, b.i(d + 2).y) ? b.l(d + 1, a.x, g.y) : b.M(d + 1, a.x, g.y)) : K.B(f.x, e.x) && K.B(e.x, g.x) ? (K.B(f.x, b.i(d - 2).x) && !K.B(f.y, b.i(d - 2).y) ? (b.l(d, a.x, f.y), c++, d++) : b.M(d - 1, a.x, f.y), K.B(g.x, b.i(d + 2).x) &&
                    !K.B(g.y, b.i(d + 2).y) ? b.l(d + 1, a.x, g.y) : b.M(d + 1, a.x, g.y)) : K.B(f.y, e.y) && K.B(e.y, g.y) && (K.B(f.y, b.i(d - 2).y) && !K.B(f.x, b.i(d - 2).x) ? (b.l(d, f.x, a.y), c++, d++) : b.M(d - 1, f.x, a.y), K.B(g.y, b.i(d + 2).y) && !K.B(g.x, b.i(d + 2).x) ? b.l(d + 1, g.x, a.y) : b.M(d + 1, g.x, a.y)); b.M(c, a.x, a.y)
        } else b.M(c, a.x, a.y), d = b.fromNode, e = b.fromPort, null !== d && (f = d.findVisibleNode(), null !== f && f !== d && (d = f, e = d.port)), 1 === c && b.computeSpot(!0, e).Jc() && (f = e.ja(xc, I.alloc()), d = b.getLinkPointFromPoint(d, e, f, a, !0, I.alloc()), b.M(0, d.x, d.y), I.free(f),
            I.free(d)), d = b.toNode, e = b.toPort, null !== d && (f = d.findVisibleNode(), null !== f && f !== d && (d = f, e = d.port)), c === b.pointsCount - 2 && b.computeSpot(!1, e).Jc() && (c = e.ja(xc, I.alloc()), a = b.getLinkPointFromPoint(d, e, c, a, !1, I.alloc()), b.M(b.pointsCount - 1, a.x, a.y), I.free(c), I.free(a)); b.wf()
    }; $f.prototype.computeReshape = function (a) { var b = this.adornedLink, c = this.handle.segmentIndex; switch (this.Nu(this.handle)) { case eg: return a; case cg: return new I(b.i(c).x, a.y); case dg: return new I(a.x, b.i(c).y); default: case ag: return b.i(c) } };
    ma.Object.defineProperties($f.prototype, {
        handleArchetype: { get: function () { return this.m }, set: function (a) { this.m = a } }, midHandleArchetype: { get: function () { return this.u }, set: function (a) { this.u = a } }, handle: { get: function () { return this.j }, set: function (a) { if (null !== a && !(a.part instanceof te)) throw Error("new handle is not in an Adornment: " + a); this.j = a } }, adornedLink: { get: function () { return this.Ms } },
        resegmentingDistance: { get: function () { return this.F }, set: function (a) { this.F = a } }, originalPoint: { get: function () { return this.yw } }, originalPoints: { get: function () { return this.Rq } }
    }); $f.prototype.setReshapingBehavior = $f.prototype.lm; $f.prototype.getReshapingBehavior = $f.prototype.Nu; var ag = new D($f, "None", 0), dg = new D($f, "Horizontal", 1), cg = new D($f, "Vertical", 2), eg = new D($f, "All", 3); $f.className = "LinkReshapingTool";
    $f.None = ag; $f.Horizontal = dg; $f.Vertical = cg; $f.All = eg; Ka("linkReshapingTool", function () { return this.findTool("LinkReshaping") }, function (a) { this.gb("LinkReshaping", a, this.mouseDownTools) });
    function gg() { je.call(this); this.name = "Resizing"; this.m = (new L(1, 1)).freeze(); this.j = (new L(9999, 9999)).freeze(); this.ug = (new L(NaN, NaN)).freeze(); this.V = !1; this.ga = !0; this.qe = null; var a = new Bf; a.alignmentFocus = xc; a.figure = "Rectangle"; a.desiredSize = Hb; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.strokeWidth = 1; a.cursor = "pointer"; this.F = a; this.u = null; this.Qq = new I; this.ww = new L; this.Pn = new I; this.kt = new L(0, 0); this.jt = new L(Infinity, Infinity); this.it = new L(1, 1) } la(gg, je);
    gg.prototype.updateAdornments = function (a) { if (!(null === a || a instanceof T)) { if (a.isSelected && !this.diagram.isReadOnly) { var b = a.resizeObject, c = a.Nj(this.name); if (null !== b && a.canResize() && a.actualBounds.w() && a.isVisible() && b.actualBounds.w() && b.Nd()) { if (null === c || c.adornedObject !== b) c = this.makeAdornment(b); if (null !== c) { b = b.ii(); hg(a) && this.updateResizeHandles(c, b); a.Wg(this.name, c); return } } } a.Gf(this.name) } };
    gg.prototype.makeAdornment = function (a) { var b = a.part.resizeAdornmentTemplate; if (null === b) { b = new te; b.type = W.Spot; b.locationSpot = xc; var c = new ig; c.isPanelMain = !0; b.add(c); b.add(this.makeHandle(a, sc)); b.add(this.makeHandle(a, uc)); b.add(this.makeHandle(a, Bc)); b.add(this.makeHandle(a, zc)); b.add(this.makeHandle(a, $c)); b.add(this.makeHandle(a, bd)); b.add(this.makeHandle(a, cd)); b.add(this.makeHandle(a, ad)) } else if (jg(b), b = b.copy(), null === b) return null; b.adornedObject = a; return b };
    gg.prototype.makeHandle = function (a, b) { a = this.handleArchetype; if (null === a) return null; a = a.copy(); a.alignment = b; return a };
    gg.prototype.updateResizeHandles = function (a, b) {
        if (null !== a) if (!a.alignment.Tb() && ("pointer" === a.cursor || 0 < a.cursor.indexOf("resize"))) a: {
            var c = a.alignment; c.Jc() && (c = xc); if (0 >= c.x) b = 0 >= c.y ? b + 225 : 1 <= c.y ? b + 135 : b + 180; else if (1 <= c.x) 0 >= c.y ? b += 315 : 1 <= c.y && (b += 45); else if (0 >= c.y) b += 270; else if (1 <= c.y) b += 90; else break a; 0 > b ? b += 360 : 360 <= b && (b -= 360); a.cursor = 22.5 > b ? "e-resize" : 67.5 > b ? "se-resize" : 112.5 > b ? "s-resize" : 157.5 > b ? "sw-resize" : 202.5 > b ? "w-resize" : 247.5 > b ? "nw-resize" : 292.5 > b ? "n-resize" : 337.5 > b ? "ne-resize" :
                "e-resize"
        } else if (a instanceof W) for (a = a.elements; a.next();)this.updateResizeHandles(a.value, b)
    }; gg.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return !a.isReadOnly && a.allowResize && a.lastInput.left ? null !== this.findToolHandleAt(a.firstInput.documentPoint, this.name) : !1 };
    gg.prototype.doActivate = function () {
        var a = this.diagram; null === this.handle && (this.handle = this.findToolHandleAt(a.firstInput.documentPoint, this.name)); null !== this.handle && (this.adornedObject = this.handle.part.adornedObject, null !== this.adornedObject && (a.isMouseCaptured = !0, a.animationManager.zc(), this.Ea(this.name), this.Qq.set(this.adornedObject.ja(this.handle.alignment.cv())), this.Pn.set(this.adornedObject.part.location), this.ww.set(this.adornedObject.desiredSize), this.it = this.computeCellSize(), this.kt =
            this.computeMinSize(), this.jt = this.computeMaxSize(), this.isActive = !0))
    }; gg.prototype.doDeactivate = function () { var a = this.diagram; this.stopTransaction(); this.qe = this.handle = null; this.isActive = a.isMouseCaptured = !1 }; gg.prototype.stopTransaction = function () { var a = this.diagram, b = je.prototype.stopTransaction.call(this); b && null !== a.undoManager.transactionToUndo && a.undoManager.transactionToUndo.Yj(); return b };
    gg.prototype.doCancel = function () { null !== this.adornedObject && (this.adornedObject.desiredSize = this.originalDesiredSize, this.adornedObject.part.location = this.originalLocation); this.stopTool() }; gg.prototype.doMouseMove = function () { var a = this.diagram; if (this.isActive) { var b = this.kt, c = this.jt, d = this.it, e = this.adornedObject.js(a.lastInput.documentPoint, I.alloc()), f = this.computeReshape(); b = this.computeResize(e, this.handle.alignment, b, c, d, f); this.resize(b); a.Wc(); I.free(e) } };
    gg.prototype.doMouseUp = function () { var a = this.diagram; if (this.isActive) { var b = this.kt, c = this.jt, d = this.it, e = this.adornedObject.js(a.lastInput.documentPoint, I.alloc()), f = this.computeReshape(); b = this.computeResize(e, this.handle.alignment, b, c, d, f); this.resize(b); I.free(e); a.Xa(); this.transactionResult = this.name; a.R("PartResized", this.adornedObject, this.originalDesiredSize) } this.stopTool() };
    gg.prototype.resize = function (a) { var b = this.diagram, c = this.adornedObject; if (null !== c) { c.desiredSize = a.size; a = c.part; a.Va(); c = c.ja(this.handle.alignment.cv()); if (a instanceof pf) { var d = new F; d.add(a); if (!this.dragsMembers && null === a.placeholder) { var e = new Be; e.dragsMembers = !1 } b.moveParts(d, this.oppositePoint.copy().ne(c), !0, e) } else a.location = a.location.copy().ne(c).add(this.oppositePoint); b.Wc() } };
    gg.prototype.computeResize = function (a, b, c, d, e, f) {
        b.Jc() && (b = xc); var g = this.adornedObject.naturalBounds, h = g.x, k = g.y, l = g.x + g.width, m = g.y + g.height, n = 1; if (!f) { n = g.width; var p = g.height; 0 >= n && (n = 1); 0 >= p && (p = 1); n = p / n } p = I.alloc(); K.Jo(a.x, a.y, h, k, e.width, e.height, p); a = g.copy(); 0 >= b.x ? 0 >= b.y ? (a.x = Math.max(p.x, l - d.width), a.x = Math.min(a.x, l - c.width), a.width = Math.max(l - a.x, c.width), a.y = Math.max(p.y, m - d.height), a.y = Math.min(a.y, m - c.height), a.height = Math.max(m - a.y, c.height), f || (1 <= a.height / a.width ? (a.height = Math.max(Math.min(n *
            a.width, d.height), c.height), a.width = a.height / n) : (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width), a.x = l - a.width, a.y = m - a.height)) : 1 <= b.y ? (a.x = Math.max(p.x, l - d.width), a.x = Math.min(a.x, l - c.width), a.width = Math.max(l - a.x, c.width), a.height = Math.max(Math.min(p.y - k, d.height), c.height), f || (1 <= a.height / a.width ? (a.height = Math.max(Math.min(n * a.width, d.height), c.height), a.width = a.height / n) : (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width), a.x = l - a.width)) : (a.x =
                Math.max(p.x, l - d.width), a.x = Math.min(a.x, l - c.width), a.width = l - a.x, f || (a.height = Math.max(Math.min(n * a.width, d.height), c.height), a.width = a.height / n, a.y = k + .5 * (m - k - a.height))) : 1 <= b.x ? 0 >= b.y ? (a.width = Math.max(Math.min(p.x - h, d.width), c.width), a.y = Math.max(p.y, m - d.height), a.y = Math.min(a.y, m - c.height), a.height = Math.max(m - a.y, c.height), f || (1 <= a.height / a.width ? (a.height = Math.max(Math.min(n * a.width, d.height), c.height), a.width = a.height / n) : (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width),
                    a.y = m - a.height)) : 1 <= b.y ? (a.width = Math.max(Math.min(p.x - h, d.width), c.width), a.height = Math.max(Math.min(p.y - k, d.height), c.height), f || (1 <= a.height / a.width ? (a.height = Math.max(Math.min(n * a.width, d.height), c.height), a.width = a.height / n) : (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width))) : (a.width = Math.max(Math.min(p.x - h, d.width), c.width), f || (a.height = Math.max(Math.min(n * a.width, d.height), c.height), a.width = a.height / n, a.y = k + .5 * (m - k - a.height))) : 0 >= b.y ? (a.y = Math.max(p.y, m - d.height),
                        a.y = Math.min(a.y, m - c.height), a.height = m - a.y, f || (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width, a.x = h + .5 * (l - h - a.width))) : 1 <= b.y && (a.height = Math.max(Math.min(p.y - k, d.height), c.height), f || (a.width = Math.max(Math.min(a.height / n, d.width), c.width), a.height = n * a.width, a.x = h + .5 * (l - h - a.width))); I.free(p); return a
    }; gg.prototype.computeReshape = function () { var a = kg; this.adornedObject instanceof Bf && (a = lg(this.adornedObject)); return !(a === mg || this.diagram.lastInput.shift) };
    gg.prototype.computeMinSize = function () { var a = this.adornedObject.minSize.copy(), b = this.minSize; !isNaN(b.width) && b.width > a.width && (a.width = b.width); !isNaN(b.height) && b.height > a.height && (a.height = b.height); return a }; gg.prototype.computeMaxSize = function () { var a = this.adornedObject.maxSize.copy(), b = this.maxSize; !isNaN(b.width) && b.width < a.width && (a.width = b.width); !isNaN(b.height) && b.height < a.height && (a.height = b.height); return a };
    gg.prototype.computeCellSize = function () {
        var a = new L(NaN, NaN), b = this.adornedObject.part; null !== b && (b = b.resizeCellSize, !isNaN(b.width) && 0 < b.width && (a.width = b.width), !isNaN(b.height) && 0 < b.height && (a.height = b.height)); b = this.cellSize; isNaN(a.width) && !isNaN(b.width) && 0 < b.width && (a.width = b.width); isNaN(a.height) && !isNaN(b.height) && 0 < b.height && (a.height = b.height); b = this.diagram; (isNaN(a.width) || isNaN(a.height)) && b && (b = b.grid, null !== b && b.visible && this.isGridSnapEnabled && (b = b.gridCellSize, isNaN(a.width) &&
            !isNaN(b.width) && 0 < b.width && (a.width = b.width), isNaN(a.height) && !isNaN(b.height) && 0 < b.height && (a.height = b.height))); if (isNaN(a.width) || 0 === a.width || Infinity === a.width) a.width = 1; if (isNaN(a.height) || 0 === a.height || Infinity === a.height) a.height = 1; return a
    };
    ma.Object.defineProperties(gg.prototype, {
        handleArchetype: { get: function () { return this.F }, set: function (a) { this.F = a } }, handle: { get: function () { return this.u }, set: function (a) { if (null !== a && !(a.part instanceof te)) throw Error("new handle is not in an Adornment: " + a); this.u = a } }, adornedObject: {
            get: function () { return this.qe }, set: function (a) {
                if (null !== a && a.part instanceof te) throw Error("new handle must not be in an Adornment: " +
                    a); this.qe = a
            }
        }, minSize: { get: function () { return this.m }, set: function (a) { if (!this.m.C(a)) { var b = a.width; isNaN(b) && (b = 0); a = a.height; isNaN(a) && (a = 0); this.m.h(b, a) } } }, maxSize: { get: function () { return this.j }, set: function (a) { if (!this.j.C(a)) { var b = a.width; isNaN(b) && (b = Infinity); a = a.height; isNaN(a) && (a = Infinity); this.j.h(b, a) } } }, cellSize: { get: function () { return this.ug }, set: function (a) { this.ug.C(a) || this.ug.assign(a) } },
        isGridSnapEnabled: { get: function () { return this.V }, set: function (a) { this.V = a } }, dragsMembers: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, oppositePoint: { get: function () { return this.Qq }, set: function (a) { this.Qq.C(a) || this.Qq.assign(a) } }, originalDesiredSize: { get: function () { return this.ww } }, originalLocation: { get: function () { return this.Pn } }
    });
    gg.className = "ResizingTool"; Ka("resizingTool", function () { return this.findTool("Resizing") }, function (a) { this.gb("Resizing", a, this.mouseDownTools) }); function ng() { je.call(this); this.name = "Rotating"; this.ga = 45; this.V = 2; this.Pn = new I; this.qe = null; var a = new Bf; a.figure = "Ellipse"; a.desiredSize = Ib; a.fill = "lightblue"; a.stroke = "dodgerblue"; a.strokeWidth = 1; a.cursor = "pointer"; this.m = a; this.j = null; this.vw = 0; this.dr = new I(NaN, NaN); this.u = 0; this.F = 50 } la(ng, je);
    ng.prototype.updateAdornments = function (a) { if (null !== a) { if (a.kh()) { var b = a.rotateObject; if (b === a || b === a.path || b.isPanelMain) return } if (a.isSelected && !this.diagram.isReadOnly && (b = a.rotateObject, null !== b && a.canRotate() && a.actualBounds.w() && a.isVisible() && b.actualBounds.w() && b.Nd())) { var c = a.Nj(this.name); if (null === c || c.adornedObject !== b) c = this.makeAdornment(b); if (null !== c) { c.angle = b.ii(); null === c.placeholder && (c.location = this.computeAdornmentLocation(b)); a.Wg(this.name, c); return } } a.Gf(this.name) } };
    ng.prototype.makeAdornment = function (a) { var b = a.part.rotateAdornmentTemplate; if (null === b) { b = new te; b.type = W.Position; b.locationSpot = xc; var c = this.handleArchetype; null !== c && b.add(c.copy()) } else if (jg(b), b = b.copy(), null === b) return null; b.adornedObject = a; return b }; ng.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return !a.isReadOnly && a.allowRotate && a.lastInput.left ? null !== this.findToolHandleAt(a.firstInput.documentPoint, this.name) : !1 };
    ng.prototype.doActivate = function () { var a = this.diagram; if (null === this.adornedObject) { null === this.handle && (this.handle = this.findToolHandleAt(a.firstInput.documentPoint, this.name)); if (null === this.handle) return; this.adornedObject = this.handle.part.adornedObject } null !== this.adornedObject && (a.isMouseCaptured = !0, a.delaysLayout = !0, this.Ea(this.name), this.vw = this.adornedObject.angle, this.dr = this.computeRotationPoint(this.adornedObject), this.Pn = this.adornedObject.part.location.copy(), this.isActive = !0) };
    ng.prototype.computeRotationPoint = function (a) { var b = a.part, c = b.locationObject; return b.rotationSpot.tb() ? a.ja(b.rotationSpot) : a === b || a === c ? c.ja(b.locationSpot) : a.ja(xc) };
    ng.prototype.computeAdornmentLocation = function (a) {
        var b = this.rotationPoint; b.w() || (b = this.computeRotationPoint(a)); b = a.js(b); var c = this.handleAngle; 0 > c ? c += 360 : 360 <= c && (c -= 360); c = Math.round(45 * Math.round(c / 45)); var d = this.handleDistance; 0 === c ? b.x = a.naturalBounds.width + d : 45 === c ? (b.x = a.naturalBounds.width + d, b.y = a.naturalBounds.height + d) : 90 === c ? b.y = a.naturalBounds.height + d : 135 === c ? (b.x = -d, b.y = a.naturalBounds.height + d) : 180 === c ? b.x = -d : 225 === c ? (b.x = -d, b.y = -d) : 270 === c ? b.y = -d : 315 === c && (b.x = a.naturalBounds.width +
            d, b.y = -d); return a.ja(b)
    }; ng.prototype.doDeactivate = function () { var a = this.diagram; this.stopTransaction(); this.qe = this.handle = null; this.dr = new I(NaN, NaN); this.isActive = a.isMouseCaptured = !1 }; ng.prototype.stopTransaction = function () { var a = this.diagram, b = je.prototype.stopTransaction.call(this); b && null !== a.undoManager.transactionToUndo && a.undoManager.transactionToUndo.Yj(); return b }; ng.prototype.doCancel = function () { this.diagram.delaysLayout = !1; this.rotate(this.originalAngle); this.stopTool() };
    ng.prototype.doMouseMove = function () { var a = this.diagram; this.isActive && (a = this.computeRotate(a.lastInput.documentPoint), this.rotate(a)) }; ng.prototype.doMouseUp = function () { var a = this.diagram; if (this.isActive) { a.delaysLayout = !1; var b = this.computeRotate(a.lastInput.documentPoint); this.rotate(b); a.Xa(); this.transactionResult = this.name; a.R("PartRotated", this.adornedObject, this.originalAngle) } this.stopTool() };
    ng.prototype.rotate = function (a) { var b = this.adornedObject; if (null !== b) { b.angle = a; b = b.part; b.Va(); var c = b.locationObject, d = b.rotateObject; if (c === d || c.ng(d)) c = this.Pn.copy(), b.location = c.ne(this.rotationPoint).rotate(a - this.originalAngle).add(this.rotationPoint); this.diagram.Wc() } };
    ng.prototype.computeRotate = function (a) { a = this.rotationPoint.$a(a) - this.handleAngle; var b = this.adornedObject.panel; null !== b && (a -= b.ii()); 360 <= a ? a -= 360 : 0 > a && (a += 360); b = Math.min(Math.abs(this.snapAngleMultiple), 180); var c = Math.min(Math.abs(this.snapAngleEpsilon), b / 2); !this.diagram.lastInput.shift && 0 < b && 0 < c && (a % b < c ? a = Math.floor(a / b) * b : a % b > b - c && (a = (Math.floor(a / b) + 1) * b)); 360 <= a ? a -= 360 : 0 > a && (a += 360); return a };
    ma.Object.defineProperties(ng.prototype, {
        handleArchetype: { get: function () { return this.m }, set: function (a) { this.m = a } }, handle: { get: function () { return this.j }, set: function (a) { if (null !== a && !(a.part instanceof te)) throw Error("new handle is not in an Adornment: " + a); this.j = a } }, adornedObject: {
            get: function () { return this.qe }, set: function (a) {
                if (null !== a && a.part instanceof te) throw Error("new handle must not be in an Adornment: " +
                    a); this.qe = a
            }
        }, snapAngleMultiple: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, snapAngleEpsilon: { get: function () { return this.V }, set: function (a) { this.V = a } }, originalAngle: { get: function () { return this.vw } }, rotationPoint: { get: function () { return this.dr }, set: function (a) { this.dr = a.copy() } }, handleAngle: {
            get: function () { return this.u }, set: function (a) {
                this.u =
                a
            }
        }, handleDistance: { get: function () { return this.F }, set: function (a) { this.F = a } }
    }); ng.className = "RotatingTool"; Ka("rotatingTool", function () { return this.findTool("Rotating") }, function (a) { this.gb("Rotating", a, this.mouseDownTools) }); function og() { je.call(this); this.name = "ClickSelecting" } la(og, je); og.prototype.canStart = function () { return !this.isEnabled || this.isBeyondDragSize() ? !1 : !0 };
    og.prototype.doMouseUp = function () { this.isActive && (this.standardMouseSelect(), !this.standardMouseClick() && this.diagram.lastInput.isTouchEvent && this.diagram.toolManager.doToolTip()); this.stopTool() }; og.className = "ClickSelectingTool"; function pg() { je.call(this); this.name = "Action"; this.jk = null } la(pg, je);
    pg.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram, b = a.lastInput, c = a.ec(b.documentPoint, function (a) { for (; null !== a.panel && !a.isActionable;)a = a.panel; return a }); if (null !== c) { if (!c.isActionable) return !1; this.jk = c; a.xk = a.ec(b.documentPoint, null, null); return !0 } return !1 }; pg.prototype.doMouseDown = function () { if (this.isActive) { var a = this.diagram.lastInput, b = this.jk; null !== b && (a.targetObject = b, null !== b.actionDown && b.actionDown(a, b)) } else this.canStart() && this.doActivate() };
    pg.prototype.doMouseMove = function () { if (this.isActive) { var a = this.diagram.lastInput, b = this.jk; null !== b && (a.targetObject = b, null !== b.actionMove && b.actionMove(a, b)) } }; pg.prototype.doMouseUp = function () { if (this.isActive) { var a = this.diagram.lastInput, b = this.jk; if (null === b) return; a.targetObject = b; null !== b.actionUp && b.actionUp(a, b); this.standardMouseClick(function (a) { for (; null !== a.panel && (!a.isActionable || a !== b);)a = a.panel; return a }, function (a) { return a === b }) } this.stopTool() };
    pg.prototype.doCancel = function () { var a = this.diagram.lastInput, b = this.jk; null !== b && (a.targetObject = b, null !== b.actionCancel && b.actionCancel(a, b), this.stopTool()) }; pg.prototype.doStop = function () { this.jk = null }; pg.className = "ActionTool"; function qg() { je.call(this); this.name = "ClickCreating"; this.Oi = null; this.m = !0; this.j = !1; this.fw = new I(0, 0) } la(qg, je);
    qg.prototype.canStart = function () { if (!this.isEnabled || null === this.archetypeNodeData) return !1; var a = this.diagram; if (a.isReadOnly || a.isModelReadOnly || !a.allowInsert || !a.lastInput.left || this.isBeyondDragSize()) return !1; if (this.isDoubleClick) { if (1 === a.lastInput.clickCount && (this.fw = a.lastInput.viewPoint.copy()), 2 !== a.lastInput.clickCount || this.isBeyondDragSize(this.fw)) return !1 } else if (1 !== a.lastInput.clickCount) return !1; return a.currentTool !== this && null !== a.Jl(a.lastInput.documentPoint, !0) ? !1 : !0 };
    qg.prototype.doMouseUp = function () { var a = this.diagram; this.isActive && this.insertPart(a.lastInput.documentPoint); this.stopTool() };
    qg.prototype.insertPart = function (a) {
        var b = this.diagram, c = this.archetypeNodeData; if (null === c) return null; var d = null; try {
            b.R("ChangingSelection", b.selection); this.Ea(this.name); if (c instanceof U) c.Uc() && (jg(c), d = c.copy(), null !== d && b.add(d)); else if (null !== c) { var e = b.model.copyNodeData(c); za(e) && (b.model.uf(e), d = b.Ic(e)) } if (null !== d) { var f = I.allocAt(a.x, a.y); this.isGridSnapEnabled && zg(this.diagram, d, a, f); d.location = f; b.allowSelect && (b.clearSelection(!0), d.isSelected = !0); I.free(f) } b.Xa(); this.transactionResult =
                this.name; b.R("PartCreated", d)
        } finally { this.stopTransaction(), b.R("ChangedSelection", b.selection) } return d
    }; ma.Object.defineProperties(qg.prototype, { archetypeNodeData: { get: function () { return this.Oi }, set: function (a) { this.Oi = a } }, isDoubleClick: { get: function () { return this.m }, set: function (a) { this.m = a } }, isGridSnapEnabled: { get: function () { return this.j }, set: function (a) { this.j = a } } }); qg.className = "ClickCreatingTool";
    function Ag() { je.call(this); this.name = "DragSelecting"; this.Dk = 175; this.m = !1; var a = new U; a.layerName = "Tool"; a.selectable = !1; var b = new Bf; b.name = "SHAPE"; b.figure = "Rectangle"; b.fill = null; b.stroke = "magenta"; a.add(b); this.j = a } la(Ag, je);
    Ag.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; if (!a.allowSelect) return !1; var b = a.lastInput; return !b.left || a.currentTool !== this && (!this.isBeyondDragSize() || b.timestamp - a.firstInput.timestamp < this.delay || null !== a.Jl(b.documentPoint, !0)) ? !1 : !0 }; Ag.prototype.doActivate = function () { var a = this.diagram; this.isActive = !0; a.isMouseCaptured = !0; a.skipsUndoManager = !0; a.add(this.box); this.doMouseMove() };
    Ag.prototype.doDeactivate = function () { var a = this.diagram; a.qg(); a.remove(this.box); a.skipsUndoManager = !1; this.isActive = a.isMouseCaptured = !1 }; Ag.prototype.doMouseMove = function () { var a = this.diagram; if (this.isActive && null !== this.box) { var b = this.computeBoxBounds(), c = this.box.fb("SHAPE"); null === c && (c = this.box.sb()); var d = L.alloc().h(b.width, b.height); c.desiredSize = d; this.box.jm(b.x, b.y, !1); L.free(d); (a.allowHorizontalScroll || a.allowVerticalScroll) && a.Zr(a.lastInput.viewPoint) } };
    Ag.prototype.doMouseUp = function () { if (this.isActive) { var a = this.diagram; a.remove(this.box); try { a.currentCursor = "wait", a.R("ChangingSelection", a.selection), this.selectInRect(this.computeBoxBounds()), a.R("ChangedSelection", a.selection) } finally { a.currentCursor = "" } } this.stopTool() }; Ag.prototype.computeBoxBounds = function () { var a = this.diagram; return new N(a.firstInput.documentPoint, a.lastInput.documentPoint) };
    Ag.prototype.selectInRect = function (a) {
        var b = this.diagram, c = b.lastInput; a = b.nx(a, this.isPartialInclusion); if (Pa ? c.meta : c.control) if (c.shift) for (a = a.iterator; a.next();)b = a.value, b.isSelected && (b.isSelected = !1); else for (a = a.iterator; a.next();)b = a.value, b.isSelected = !b.isSelected; else if (c.shift) for (a = a.iterator; a.next();)b = a.value, b.isSelected || (b.isSelected = !0); else {
            c = new F; for (b = b.selection.iterator; b.next();) { var d = b.value; a.contains(d) || c.add(d) } for (b = c.iterator; b.next();)b.value.isSelected = !1; for (a =
                a.iterator; a.next();)b = a.value, b.isSelected || (b.isSelected = !0)
        }
    }; ma.Object.defineProperties(Ag.prototype, { delay: { get: function () { return this.Dk }, set: function (a) { this.Dk = a } }, isPartialInclusion: { get: function () { return this.m }, set: function (a) { this.m = a } }, box: { get: function () { return this.j }, set: function (a) { this.j = a } } }); Ag.className = "DragSelectingTool";
    function Bg() { je.call(this); this.name = "Panning"; this.St = new I; this.ty = new I; this.tg = !1; var a = this; this.Dw = function () { var b = a.diagram; null !== b && b.removeEventListener(x.document, "scroll", a.Dw, !1); a.stopTool() } } la(Bg, je); Bg.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return !a.allowHorizontalScroll && !a.allowVerticalScroll || !a.lastInput.left || a.currentTool !== this && !this.isBeyondDragSize() ? !1 : !0 };
    Bg.prototype.doActivate = function () { var a = this.diagram; this.tg ? (a.lastInput.bubbles = !0, a.addEventListener(x.document, "scroll", this.Dw, !1)) : (a.currentCursor = "move", a.isMouseCaptured = !0, this.St.assign(a.position)); this.isActive = !0 }; Bg.prototype.doDeactivate = function () { var a = this.diagram; a.currentCursor = ""; this.isActive = a.isMouseCaptured = !1 }; Bg.prototype.doCancel = function () { var a = this.diagram; a.position = this.St; a.isMouseCaptured = !1; this.stopTool() }; Bg.prototype.doMouseMove = function () { this.move() };
    Bg.prototype.doMouseUp = function () { this.move(); this.stopTool() }; Bg.prototype.move = function () { var a = this.diagram; if (this.isActive && a) if (this.tg) a.lastInput.bubbles = !0; else { var b = a.position, c = a.firstInput.documentPoint, d = a.lastInput.documentPoint, e = b.x + c.x - d.x; c = b.y + c.y - d.y; a.allowHorizontalScroll || (e = b.x); a.allowVerticalScroll || (c = b.y); a.position = this.ty.h(e, c) } };
    ma.Object.defineProperties(Bg.prototype, { bubbles: { get: function () { return this.tg }, set: function (a) { this.tg = a } }, originalPosition: { get: function () { return this.St } } }); Bg.className = "PanningTool"; Ka("clickCreatingTool", function () { return this.findTool("ClickCreating") }, function (a) { this.gb("ClickCreating", a, this.mouseUpTools) }); Ka("clickSelectingTool", function () { return this.findTool("ClickSelecting") }, function (a) { this.gb("ClickSelecting", a, this.mouseUpTools) });
    Ka("panningTool", function () { return this.findTool("Panning") }, function (a) { this.gb("Panning", a, this.mouseMoveTools) }); Ka("dragSelectingTool", function () { return this.findTool("DragSelecting") }, function (a) { this.gb("DragSelecting", a, this.mouseMoveTools) }); Ka("actionTool", function () { return this.findTool("Action") }, function (a) { this.gb("Action", a, this.mouseDownTools) }); function ye() { this.F = this.u = this.j = this.m = null }
    ma.Object.defineProperties(ye.prototype, { mainElement: { get: function () { return this.u }, set: function (a) { this.u = a } }, show: { get: function () { return this.m }, set: function (a) { this.m !== a && (this.m = a) } }, hide: { get: function () { return this.j }, set: function (a) { this.j !== a && (this.j = a) } }, valueFunction: { get: function () { return this.F }, set: function (a) { this.F = a } } }); ye.className = "HTMLInfo";
    function Cg(a, b, c) { this.text = a; this.$w = b; this.visible = c } Cg.className = "ContextMenuButtonInfo"; function Dg() { je.call(this); this.name = "ContextMenu"; this.m = this.$s = this.j = null; this.rw = new I; this.bt = null; this.xt = !1; var a = this; this.ou = function () { a.stopTool() } } la(Dg, je);
    function Eg(a) {
        var b = new ye; b.show = function (a, b, c) { c.showDefaultContextMenu() }; b.hide = function (a, b) { b.hideDefaultContextMenu() }; Fg = b; a.ou = function () { a.stopTool() }; b = ua("div"); var c = ua("div"); b.style.cssText = "top: 0px;z-index:10002;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px"; c.style.cssText = "z-index:10001;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;opacity: 0.8;";
        var d = ua("style"); x.document.getElementsByTagName("head")[0].appendChild(d); d.sheet.insertRule(".goCXul { list-style: none; }", 0); d.sheet.insertRule(".goCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }", 0); d.sheet.insertRule(".goCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }", 0); d = a.diagram; null !== d && (d.addEventListener(b, "contextmenu", Gg, !1), d.addEventListener(b, "selectstart",
            Gg, !1), d.addEventListener(c, "contextmenu", Gg, !1)); b.className = "goCXforeground"; c.className = "goCXbackground"; x.document.body && (x.document.body.appendChild(b), x.document.body.appendChild(c)); Hg = b; Ig = c; Jg = !0
    } function Gg(a) { a.preventDefault(); return !1 }
    Dg.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; return this.isBeyondDragSize() || !a.lastInput.right || 1 < a.lastInput.clickCount ? !1 : a.lastInput.isTouchEvent && null !== this.defaultTouchContextMenu || null !== this.findObjectWithContextMenu() ? !0 : !1 }; Dg.prototype.doStart = function () { this.rw.set(this.diagram.firstInput.documentPoint) }; Dg.prototype.doStop = function () { this.hideContextMenu(); this.currentObject = null };
    Dg.prototype.findObjectWithContextMenu = function (a) { void 0 === a && (a = null); var b = this.diagram, c = b.lastInput, d = null; a instanceof Q || (a instanceof Y ? d = a : d = b.ec(c.documentPoint, null, function (a) { return !a.layer.isTemporary })); if (null !== d) { for (a = d; null !== a;) { if (null !== a.contextMenu) return a; a = a.panel } if (b.lastInput.isTouchEvent && this.defaultTouchContextMenu) return d.part } else if (null !== b.contextMenu) return b; return null }; Dg.prototype.doActivate = function () { };
    Dg.prototype.doMouseDown = function () { je.prototype.doMouseDown.call(this); if (this.isActive && this.currentContextMenu instanceof te) { var a = this.diagram.toolManager.findTool("Action"); null !== a && a.canStart() && (a.doActivate(), a.doMouseDown(), a.doDeactivate()) } this.diagram.toolManager.mouseDownTools.contains(this) && Kg(this) };
    Dg.prototype.doMouseUp = function () { if (this.isActive && this.currentContextMenu instanceof te) { var a = this.diagram.toolManager.findTool("Action"); null !== a && a.canStart() && (a.doActivate(), a.doCancel(), a.doDeactivate()) } Kg(this) };
    function Kg(a) { var b = a.diagram; if (a.isActive) { var c = a.currentContextMenu; if (null !== c) { if (!(c instanceof ye)) { var d = b.ec(b.lastInput.documentPoint, null, null); null !== d && d.ng(c) && a.standardMouseClick(null, null) } a.stopTool(); a.canStart() && (b.currentTool = a, a.doMouseUp()) } } else a.canStart() && (Lg(a, !0), a.isActive || a.stopTool()) }
    function Lg(a, b, c) { void 0 === c && (c = null); if (!a.xt && (a.xt = !0, b && a.standardMouseSelect(), b = a.standardMouseClick(), a.xt = !1, !b)) { a.isActive = !0; b = Fg; null === c && (c = a.findObjectWithContextMenu()); if (null !== c) { var d = c.contextMenu; null !== d ? (a.currentObject = c instanceof Y ? c : null, a.showContextMenu(d, a.currentObject)) : null !== b && a.showContextMenu(b, a.currentObject) } else null !== b && a.showContextMenu(b, null); a.currentContextMenu instanceof te && !a.currentContextMenu.visible && a.stopTool() } }
    Dg.prototype.doMouseMove = function () { var a = this.diagram.toolManager.findTool("Action"); null !== a && a.doMouseMove(); this.isActive && this.diagram.toolManager.doMouseMove() };
    Dg.prototype.showContextMenu = function (a, b) { var c = this.diagram; a !== this.currentContextMenu && this.hideContextMenu(); if (a instanceof te) { a.layerName = "Tool"; a.selectable = !1; a.scale = 1 / c.scale; a.category = this.name; null !== a.placeholder && (a.placeholder.scale = c.scale); var d = a.diagram; null !== d && d !== c && d.remove(a); c.add(a); null !== b ? a.adornedObject = b : a.data = c.model; a.Va(); this.positionContextMenu(a, b) } else a instanceof ye && a.show(b, c, this); this.currentContextMenu = a };
    Dg.prototype.positionContextMenu = function (a) { if (null === a.placeholder) { var b = this.diagram, c = b.lastInput.documentPoint.copy(), d = a.measuredBounds, e = b.viewportBounds; b.lastInput.isTouchEvent && (c.x -= d.width); c.x + d.width > e.right && (c.x -= d.width + 5 / b.scale); c.x < e.x && (c.x = e.x); c.y + d.height > e.bottom && (c.y -= d.height + 5 / b.scale); c.y < e.y && (c.y = e.y); a.position = c } };
    Dg.prototype.hideContextMenu = function () { var a = this.diagram, b = this.currentContextMenu; null !== b && (b instanceof te ? (a.remove(b), null !== this.$s && this.$s.Gf(b.category), b.data = null, b.adornedObject = null) : b instanceof ye && (null !== b.hide ? b.hide(a, this) : null !== b.mainElement && (b.mainElement.style.display = "none")), this.currentContextMenu = null, this.standardMouseOver()) };
    function Mg(a) {
        var b = new F; b.add(new Cg("Copy", function (a) { a.commandHandler.copySelection() }, function (a) { return a.commandHandler.canCopySelection() })); b.add(new Cg("Cut", function (a) { a.commandHandler.cutSelection() }, function (a) { return a.commandHandler.canCutSelection() })); b.add(new Cg("Delete", function (a) { a.commandHandler.deleteSelection() }, function (a) { return a.commandHandler.canDeleteSelection() })); b.add(new Cg("Paste", function (b) { b.commandHandler.pasteSelection(a.mouseDownPoint) }, function (b) { return b.commandHandler.canPasteSelection(a.mouseDownPoint) }));
        b.add(new Cg("Select All", function (a) { a.commandHandler.selectAll() }, function (a) { return a.commandHandler.canSelectAll() })); b.add(new Cg("Undo", function (a) { a.commandHandler.undo() }, function (a) { return a.commandHandler.canUndo() })); b.add(new Cg("Redo", function (a) { a.commandHandler.redo() }, function (a) { return a.commandHandler.canRedo() })); b.add(new Cg("Scroll To Part", function (a) { a.commandHandler.scrollToPart() }, function (a) { return a.commandHandler.canScrollToPart() })); b.add(new Cg("Zoom To Fit", function (a) { a.commandHandler.zoomToFit() },
            function (a) { return a.commandHandler.canZoomToFit() })); b.add(new Cg("Reset Zoom", function (a) { a.commandHandler.resetZoom() }, function (a) { return a.commandHandler.canResetZoom() })); b.add(new Cg("Group Selection", function (a) { a.commandHandler.groupSelection() }, function (a) { return a.commandHandler.canGroupSelection() })); b.add(new Cg("Ungroup Selection", function (a) { a.commandHandler.ungroupSelection() }, function (a) { return a.commandHandler.canUngroupSelection() })); b.add(new Cg("Edit Text", function (a) { a.commandHandler.editTextBlock() },
                function (a) { return a.commandHandler.canEditTextBlock() })); return b
    }
    Dg.prototype.showDefaultContextMenu = function () {
        var a = this.diagram; null === this.bt && (this.bt = Mg(this)); Hg.innerHTML = ""; Ig.addEventListener("pointerdown", this.ou, !1); var b = this, c = ua("ul"); c.className = "goCXul"; Hg.appendChild(c); c.innerHTML = ""; for (var d = this.bt.iterator; d.next();) {
            var e = d.value, f = e.visible; if ("function" === typeof e.$w && ("function" !== typeof f || f(a))) {
                f = ua("li"); f.className = "goCXli"; var g = ua("a"); g.className = "goCXa"; g.href = "#"; g.gy = e.$w; g.addEventListener("pointerdown", function (c) {
                    this.gy(a);
                    b.stopTool(); c.preventDefault(); return !1
                }, !1); g.textContent = e.text; f.appendChild(g); c.appendChild(f)
            }
        } Hg.style.display = "block"; Ig.style.display = "block"
    }; Dg.prototype.hideDefaultContextMenu = function () { if (null !== this.currentContextMenu && this.currentContextMenu === Fg) { Hg.style.display = "none"; Ig.style.display = "none"; var a = this.diagram; null !== a && a.removeEventListener(Ig, "pointerdown", this.ou, !1); this.currentContextMenu = null } };
    ma.Object.defineProperties(Dg.prototype, { currentContextMenu: { get: function () { return this.j }, set: function (a) { this.j = a; this.$s = a instanceof te ? a.adornedPart : null } }, defaultTouchContextMenu: { get: function () { !1 === Jg && null === Fg && Ng && Eg(this); return Fg }, set: function (a) { null === a && (Jg = !0); Fg = a } }, currentObject: { get: function () { return this.m }, set: function (a) { this.m = a } }, mouseDownPoint: { get: function () { return this.rw } } });
    var Fg = null, Jg = !1, Ig = null, Hg = null; Dg.className = "ContextMenuTool"; Ka("contextMenuTool", function () { return this.findTool("ContextMenu") }, function (a) { this.gb("ContextMenu", a, this.mouseUpTools) }); function Og() { je.call(this); this.name = "TextEditing"; this.Wh = new Pg; this.ga = null; this.V = Qg; this.tj = null; this.qa = Rg; this.u = 1; this.F = !0; this.m = null; this.j = new ye; this.ct = null; Sg(this, this.j) } la(Og, je);
    function Sg(a, b) {
        if (Ng) {
            var c = ua("textarea"); a.ct = c; c.addEventListener("input", function () { if (null !== a.textBlock) { var b = a.Gx(this.value); this.style.width = 20 + b.measuredBounds.width * this.mA + "px"; this.rows = b.lineCount } }, !1); c.addEventListener("keydown", function (b) { if (null !== a.textBlock) { var c = b.key; "Enter" === c ? (!1 === a.textBlock.isMultiline && b.preventDefault(), a.acceptText(Tg)) : "Tab" === c ? (a.acceptText(Ug), b.preventDefault()) : "Escape" === c && (a.doCancel(), null !== a.diagram && a.diagram.doFocus()) } }, !1); c.addEventListener("focus",
                function () { if (null !== a.currentTextEditor && a.state !== Rg) { var b = a.ct; a.qa === Vg && (a.qa = Wg); "function" === typeof b.select && a.selectsTextOnActivate && (b.select(), b.setSelectionRange(0, 9999)) } }, !1); c.addEventListener("blur", function () { if (null !== a.currentTextEditor && a.state !== Rg) { var b = a.ct; "function" === typeof b.focus && b.focus(); "function" === typeof b.select && a.selectsTextOnActivate && (b.select(), b.setSelectionRange(0, 9999)) } }, !1); b.valueFunction = function () { return c.value }; b.mainElement = c; b.show = function (a,
                    b, f) {
                        if (a instanceof Pg && f instanceof Og) if (f.state === Xg) c.style.border = "3px solid red", c.focus(); else {
                            var d = a.ja(xc), e = b.position, k = b.scale, l = a.Af() * k; l < f.minimumEditorScale && (l = f.minimumEditorScale); var m = a.naturalBounds.width * l + 6, n = a.naturalBounds.height * l + 2, p = (d.x - e.x) * k; d = (d.y - e.y) * k; e = a.verticalAlignment; k = (a.lineHeight + a.spacingAbove + a.spacingBelow) * a.lineCount * l; n = e.y * n - e.y * k + e.offsetY - (.5 * n - .5 * k) - k / 2; c.value = a.text; b.div.style.font = a.font; c.style.position = "absolute"; c.style.zIndex = "100";
                            c.style.font = "inherit"; c.style.fontSize = 100 * l + "%"; c.style.lineHeight = "normal"; c.style.width = m + "px"; c.style.left = (p - m / 2 | 0) - 1 + "px"; c.style.top = (d + n | 0) - 1 + "px"; c.style.textAlign = a.textAlign; c.style.margin = "0"; c.style.padding = "1px"; c.style.border = "0"; c.style.outline = "none"; c.style.whiteSpace = "pre-wrap"; c.style.overflow = "hidden"; c.rows = a.lineCount; c.mA = l; c.className = "goTXarea"; b.div.appendChild(c); c.focus(); f.selectsTextOnActivate && (c.select(), c.setSelectionRange(0, 9999))
                        }
                }; b.hide = function (a) { a.div.removeChild(c) }
        }
    }
    Og.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; if (null === a || a.isReadOnly || Yg && Yg !== this && (Yg.acceptText(Zg), Yg && Yg !== this) || !a.lastInput.left || this.isBeyondDragSize()) return !1; var b = a.ec(a.lastInput.documentPoint); if (!(null !== b && b instanceof Pg && b.editable && b.part.canEdit())) return !1; b = b.part; return null === b || this.starting === Qg && !b.isSelected || this.starting === $g && 2 > a.lastInput.clickCount ? !1 : !0 }; Og.prototype.doStart = function () { Yg = this; null !== this.textBlock && this.doActivate() };
    Og.prototype.doActivate = function () { if (!this.isActive) { var a = this.diagram; if (null !== a) { var b = this.textBlock; null === b && (b = a.ec(a.lastInput.documentPoint)); if (null !== b && b instanceof Pg && (this.textBlock = b, null !== b.part)) { a.animationManager.zc(); this.isActive = !0; this.qa = Vg; var c = this.defaultTextEditor; null !== b.textEditor && (c = b.textEditor); this.Wh = this.textBlock.copy(); var d = new N(this.textBlock.ja(sc), this.textBlock.ja(Bc)); a.ov(d); c.show(b, a, this); this.currentTextEditor = c } } } }; Og.prototype.doCancel = function () { this.stopTool() };
    Og.prototype.doMouseUp = function () { this.canStart() && this.doActivate() }; Og.prototype.doMouseDown = function () { this.isActive && this.acceptText(Zg) }; Og.prototype.acceptText = function (a) { switch (a) { case Zg: if (this.qa === ah) this.currentTextEditor instanceof HTMLElement && this.currentTextEditor.focus(); else if (this.qa === Vg || this.qa === Xg || this.qa === Wg) this.qa = bh, ch(this); break; case dh: case Tg: case Ug: if (Tg !== a || !0 !== this.textBlock.isMultiline) if (this.qa === Vg || this.qa === Xg || this.qa === Wg) this.qa = bh, ch(this) } };
    function ch(a) { var b = a.textBlock, c = a.diagram, d = a.currentTextEditor; if (null !== b && null !== d) { var e = b.text, f = ""; null !== d.valueFunction && (f = d.valueFunction()); a.isValidText(b, e, f) ? (a.Ea(a.name), a.qa = ah, a.transactionResult = a.name, b.text = f, a.doSuccess(e, f), null !== c && c.R("TextEdited", b, e), a.stopTransaction(), a.stopTool(), null !== c && c.doFocus()) : (a.qa = Xg, a.doError(e, f)) } }
    Og.prototype.doError = function (a, b) { var c = this.textBlock; null !== c.errorFunction && c.errorFunction(this, a, b); (a = this.currentTextEditor) && a.show(c, this.diagram, this) }; Og.prototype.doSuccess = function (a, b) { var c = this.textBlock; null !== c.textEdited && c.textEdited(c, a, b) }; Og.prototype.doDeactivate = function () { var a = this.diagram; null !== a && (this.qa = Rg, null !== this.currentTextEditor && this.currentTextEditor.hide(a, this), this.textBlock = null, this.isActive = !1) }; Og.prototype.doStop = function () { Yg = null };
    Og.prototype.isValidText = function (a, b, c) { var d = this.textValidation; if (null !== d && !d(a, b, c)) return !1; d = a.textValidation; return null === d || d(a, b, c) ? !0 : !1 }; Og.prototype.Gx = function (a) { var b = this.Wh; b.text = a; b.measure(this.textBlock.Eh, Infinity); return b };
    ma.Object.defineProperties(Og.prototype, {
        textBlock: { get: function () { return this.ga }, set: function (a) { this.ga = a } }, currentTextEditor: { get: function () { return this.m }, set: function (a) { this.m = a } }, defaultTextEditor: { get: function () { return this.j }, set: function (a) { this.j = a } }, starting: { get: function () { return this.V }, set: function (a) { this.V = a } }, textValidation: {
            get: function () { return this.tj },
            set: function (a) { this.tj = a }
        }, minimumEditorScale: { get: function () { return this.u }, set: function (a) { this.u = a } }, selectsTextOnActivate: { get: function () { return this.F }, set: function (a) { this.F = a } }, state: { get: function () { return this.qa }, set: function (a) { this.qa !== a && (this.qa = a) } }
    }); Og.prototype.measureTemporaryTextBlock = Og.prototype.Gx;
    var dh = new D(Og, "LostFocus", 0), Zg = new D(Og, "MouseDown", 1), Ug = new D(Og, "Tab", 2), Tg = new D(Og, "Enter", 3), eh = new D(Og, "SingleClick", 0), Qg = new D(Og, "SingleClickSelected", 1), $g = new D(Og, "DoubleClick", 2), Rg = new D(Og, "StateNone", 0), Vg = new D(Og, "StateActive", 1), Wg = new D(Og, "StateEditing", 2), bh = new D(Og, "StateValidating", 3), Xg = new D(Og, "StateInvalid", 4), ah = new D(Og, "StateValidated", 5), Yg = null; Og.className = "TextEditingTool"; Og.LostFocus = dh; Og.MouseDown = Zg; Og.Tab = Ug; Og.Enter = Tg; Og.SingleClick = eh;
    Og.SingleClickSelected = Qg; Og.DoubleClick = $g; Og.StateNone = Rg; Og.StateActive = Vg; Og.StateEditing = Wg; Og.StateValidating = bh; Og.StateInvalid = Xg; Og.StateValidated = ah; Ka("textEditingTool", function () { return this.findTool("TextEditing") }, function (a) { this.gb("TextEditing", a, this.mouseUpTools) });
    function fh(a) { gh || (hh(), gh = !0); this.H = ke; this.Jk = this.zb = this.oq = this.Zb = !1; this.Jw = !0; this.Kk = ih; this.Am = !1; this.Ch = this.Ad = !0; this.zg = 600; this.Wv = this.Hw = !1; this.Ud = new H; this.Zd = new jh; this.Zd.Td = this; this.Bi = new H; this.ku = new H; this.Kr = new H; this.kl = new H; a && Object.assign(this, a) } fh.prototype.Te = function (a) { this.H = a }; fh.prototype.canStart = function () { return !0 }; function kh(a, b) { a.Ad && a.canStart(b) && (a.Ud.add(b), a.defaultAnimation.isAnimating && a.zc(), lh(a.H), a.zb = !0) }
    function mh(a) {
        if (a.Ad && a.zb) {
            var b = a.Zd, c = a.H, d = a.Ud.contains("Model"); d && (a.Jk = !0, a.Kk === ih ? (b.isViewportUnconstrained = !0, b.sc.clear(), b.add(c, "position", c.position.copy().offset(0, -200), c.position), b.add(c, "opacity", 0, 1)) : a.Kk === nh && b.sc.clear(), a.Jw = a.Kk === oh && c.Zt.C(c.pa) ? !0 : !1, c.R("InitialAnimationStarting", a)); d && !a.Ch || 0 === b.sc.count ? (a.Ud.clear(), a.zb = !1, b.sc.clear(), ph(b, c), a.Jk = !1, c.N()) : (a.Ud.clear(), c.gg = !1, d = b.sc.get(c), c.autoScale !== qh && null !== d && (delete d.start.scale, delete d.end.scale),
                x.requestAnimationFrame(function () { !1 === a.zb || b.Zb || (c.Df("temporaryPixelRatio") && Fe(c), rh(c), a.zb = !1, b.start(), b.Zb && (sh(a), c.Xa(), th(b, 0), yf(c, !0), uh(a), c.R("AnimationStarting", a))) }))
        }
    } function vh(a) { return a.Ud.contains("Trigger") && 1 === a.Ud.count } function Gh(a, b, c, d) { a.isTicking || vh(a) || b instanceof T && (null !== b.fromNode || null !== b.toNode) || a.Zd.add(b, "position", c, d, !1) } t = fh.prototype; t.bv = function (a) { return this.Zd.bv(a) }; t.Tu = function (a) { return this.Zd.Tu(a) };
    function Hh(a, b) { function c() { 0 < e.Kr.count && (d.addAll(e.Kr), e.Kr.clear(), e.Zb = !0); if (!1 !== e.Zb && 0 !== d.count) { e.ku.addAll(d); for (var a = e.ku.iterator; a.next();) { var b = a.value; if (!1 !== b.Zb) { a: if (0 < b.ul.count) var h = !0; else { for (h = b.sc.iterator; h.next();) { var k = h.key; if (k instanceof Y && null !== k.diagram || k instanceof Q) { h = !0; break a } } h = !1 } h ? Ih(b, !1) : b.Sk = !0 } } e.ku.clear(); !1 !== e.Zb && (sh(e), yf(e.H), uh(e)); x.requestAnimationFrame(c) } } var d = a.Bi, e = a; a.Zb ? a.Kr.add(b) : (a.Zb = !0, d.add(b), x.requestAnimationFrame(function () { c() })) }
    function Jh(a) { for (a = a.Bi.iterator; a.next();)a.value.Sk = !1 } function sh(a) { if (!a.oq) { var b = a.H; a.Hw = b.skipsUndoManager; a.Wv = b.skipsModelSourceBindings; b.skipsUndoManager = !0; b.skipsModelSourceBindings = !0; a.oq = !0 } } function uh(a) { var b = a.H; b.skipsUndoManager = a.Hw; b.skipsModelSourceBindings = a.Wv; a.oq = !1 }
    t.zc = function (a) { var b = this.Zd; !0 === this.zb && (this.Jk = this.zb = !1, this.Ud.clear(), 0 < b.sc.count && this.H.Wb()); if (this.Zb) { if (b.Hl(!0), b.sc.clear(), ph(b, null), !0 === a) for (a = this.Bi.Fa(), b = 0; b < a.length; b++)a[b].Hl(!0) } else b.sc.clear(), ph(b, this.H) }; t.Hl = function (a) { a === this.defaultAnimation && this.defaultAnimation.sc.clear(); this.Bi.remove(a); 0 === this.Bi.count && (this.Zb = !1, this.H.Wb()); a === this.defaultAnimation && (this.defaultAnimation.sc.clear(), this.H.R("AnimationFinished", this)) };
    t.Aj = function (a, b) { this.zb && (this.Ud.contains("Expand Tree") || this.Ud.contains("Expand SubGraph")) && (this.Zd.Aj(a, b), Kh(this, a)) }; t.yj = function (a, b) { this.zb && (this.Ud.contains("Collapse Tree") || this.Ud.contains("Collapse SubGraph")) && (this.Zd.yj(a, b), Lh(this.Zd, b, "position", b.position, b.position), Kh(this, a)) }; function Mh(a, b, c) { !a.zb || b.C(c) || vh(a) || (a.H.mi || (b = c.copy()), Lh(a.Zd, a.H, "position", b, c)) }
    function Kh(a, b) { for (b = b.findLinksConnected(); b.next();) { var c = b.value; c.nf = c.points.copy(); a.kl.add(c) } } function Nh(a, b, c, d, e) { null === a && (a = [0, 0, 0, 0]); null === b && (b = [0, 0, 0, 0]); var f = a[0], g = a[1], h = a[2]; a = a[3]; var k = b[0], l = b[1], m = b[2]; b = b[3]; if (0 === h || 100 === h) f = k, g = l; else if (0 === m || 100 === m) k = f, l = g; 180 < Math.abs(k - f) && (k > f ? f += 360 : k += 360); f = e(c, f, k - f, d) % 360; g = e(c, g, l - g, d); h = e(c, h, m - h, d); c = e(c, a, b - a, d); return "hsla(" + f + ", " + g + "%, " + h + "%, " + c + ")" }
    function hh() {
        function a(a, b, e, f, g, h) { a.position = new I(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h)) } var b = new ob; b.add("position:diagram", a); b.add("position", a); b.add("position:part", function (a, b, e, f, g, h) { g < h ? a.jm(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h), !1) : a.position = new I(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h)) }); b.add("location", function (a, b, e, f, g, h) { g < h ? a.jm(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h), !0) : a.location = new I(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h)) }); b.add("position:placeholder", function (a, b, e, f,
            g, h) { g < h ? a.jm(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h), !1) : a.position = new I(f(g, b.x, e.x - b.x, h), f(g, b.y, e.y - b.y, h)) }); b.add("position:nodeCollapse", function (a, b, e, f, g, h) { var c = a.actualBounds, d = e.actualBounds; e = d.x + d.width / 2 - c.width / 2; c = d.y + d.height / 2 - c.height / 2; g < h ? a.jm(f(g, b.x, e - b.x, h), f(g, b.y, c - b.y, h), !1) : a.position = new I(f(g, b.x, e - b.x, h), f(g, b.y, c - b.y, h)) }); b.add("desiredSize", function (a, b, e, f, g, h) { a.desiredSize = new N(f(g, b.width, e.width - b.width, h), f(g, b.height, e.height - b.height, h)) }); b.add("width",
                function (a, b, e, f, g, h) { a.width = f(g, b, e - b, h) }); b.add("height", function (a, b, e, f, g, h) { a.height = f(g, b, e - b, h) }); b.add("fill", function (a, b, e, f, g, h) { a.fill = Nh(b, e, g, h, f) }); b.add("stroke", function (a, b, e, f, g, h) { a.stroke = Nh(b, e, g, h, f) }); b.add("strokeWidth", function (a, b, e, f, g, h) { a.strokeWidth = f(g, b, e - b, h) }); b.add("strokeDashOffset", function (a, b, e, f, g, h) { a.strokeDashOffset = f(g, b, e - b, h) }); b.add("background", function (a, b, e, f, g, h) { a.background = Nh(b, e, g, h, f) }); b.add("areaBackground", function (a, b, e, f, g, h) {
                    a.areaBackground =
                    Nh(b, e, g, h, f)
                }); b.add("opacity", function (a, b, e, f, g, h) { a.opacity = f(g, b, e - b, h) }); b.add("scale", function (a, b, e, f, g, h) { a.scale = f(g, b, e - b, h) }); b.add("angle", function (a, b, e, f, g, h) { a.angle = f(g, b, e - b, h) }); Oh = b
    }
    ma.Object.defineProperties(fh.prototype, {
        animationReasons: { get: function () { return this.Ud } }, isEnabled: { get: function () { return this.Ad }, set: function (a) { (this.Ad = a) && this.Bi.each(function (a) { a.isAnimating || Infinity !== a.runCount || a.start() }) } }, duration: { get: function () { return this.zg }, set: function (a) { 1 > a && xa(a, ">= 1", fh, "duration"); this.zg = a } }, isAnimating: { get: function () { return this.Zb } },
        isTicking: { get: function () { return this.oq } }, isInitial: { get: function () { return this.Ch }, set: function (a) { this.Ch = a } }, defaultAnimation: { get: function () { return this.Zd } }, activeAnimations: { get: function () { return this.Bi } }, initialAnimationStyle: { get: function () { return this.Kk }, set: function (a) { this.Kk = a } }
    }); fh.prototype.stopAnimation = fh.prototype.zc;
    var Oh = null, gh = !1, ih = new D(fh, "Default", 1), oh = new D(fh, "AnimateLocations", 2), nh = new D(fh, "None", 3); fh.className = "AnimationManager"; fh.defineAnimationEffect = function (a, b) { gh || (hh(), gh = !0); Oh.add(a, b) }; fh.Default = ih; fh.AnimateLocations = oh; fh.None = nh;
    function jh(a) { this.qu = this.Gw = this.Td = this.H = null; this.Sk = this.Zb = this.j = !1; this.Zm = this.Hd = 0; this.Fp = this.ht = Ph; this.Rk = this.bo = !1; this.Yt = 1; this.Wt = 0; this.yd = this.zg = NaN; this.$v = 0; this.$m = null; this.m = pb; this.sc = new ob; this.Ot = new ob; this.ul = new H; this.Pt = new H; this.Xv = Qh; a && Object.assign(this, a) } jh.prototype.suspend = function () { this.Sk = !0 }; jh.prototype.advanceTo = function (a, b) { b && (this.Sk = !1); this.bo && a >= this.yd && (this.Rk = !0, a -= this.yd); this.$v = a; Ih(this, !0); sh(this.Td); yf(this.H); uh(this.Td); this.H.redraw() };
    function ph(a, b) { a.Ot.clear(); a.Rk = !1; a.Wt = 0; a.yd = NaN; 0 < a.ul.count && a.ul.clear(); 0 < a.Pt.count && a.Pt.clear(); if (null !== b) for (a = b.links; a.next();)a.value.nf = null } t = jh.prototype;
    t.start = function () {
        if (0 === this.sc.count || this.Zb) return this; for (var a = this.H, b = this.sc.iterator; b.next();) { var c = b.key; null === a && (c instanceof Q ? a = c : c instanceof Y && (a = c.diagram)) } if (null !== a) this.H = a, this.Td = a.animationManager; else return this; b = this.Td; if (!1 === b.isEnabled) return this; this.yd = isNaN(this.zg) ? b.duration : this.zg; this.Fp = this.ht; b.Jk && b.Kk === ih && this === b.defaultAnimation && (this.Fp = Rh, this.yd = isNaN(this.zg) ? 600 === b.duration ? 900 : b.duration : this.zg); this.Xv = a.scrollMode; this.isViewportUnconstrained &&
            (a.qj = Sh); sh(b); this.ul.each(function (b) { b.data = null; a.add(b) }); uh(b); this.Zb = !0; this.Hd = +new Date; this.Zm = this.Hd + this.yd; Hh(b, this); return this
    }; t.By = function (a, b) { if (!a.Uc()) return this; this.ul.add(a); this.H = b; return this }; t.add = function (a, b, c, d, e) { if ("position" === b && c.C(d)) return this; null === this.H && (a instanceof Q ? this.H = a : a instanceof Y && null !== a.diagram && (this.H = a.diagram)); if (a instanceof U) { if (!a.isAnimated) return this; "position" === b && (b = "position:part") } Lh(this, a, b, c, d, e); return this };
    function Lh(a, b, c, d, e, f) {
        var g = a.sc; b instanceof Q && "position" === c && (c = "position:diagram"); if ("fill" === c || "stroke" === c || "background" === c || "areaBackground" === c) Th(d), Uh(), d = [Vh.j, Vh.u, Vh.m, Vh.F], Th(e), Uh(), e = [Vh.j, Vh.u, Vh.m, Vh.F]; if (g.contains(b)) { var h = g.I(b); var k = h.start; var l = h.end; void 0 === k[c] && (k[c] = Wh(d)); l[c] = Wh(e) } else k = {}, l = {}, k[c] = Wh(d), l[c] = Wh(e), h = k.position, b instanceof Y && h instanceof I && !h.w() && b.diagram.animationManager.Ud.contains("Expand SubGraph") && h.assign(l.position), h = new Xh(k,
            l, f), g.add(b, h); g = k[c]; g instanceof I && !g.w() && g.assign(a.m); f && 0 === c.indexOf("position:") && b instanceof U ? h.Eu.location = Wh(b.location) : f && (h.Eu[c] = Wh(d))
    } function Wh(a) { return a instanceof I ? a.copy() : a instanceof L ? a.copy() : a } t.bv = function (a) { if (!this.Zb) return !1; a = this.sc.I(a); return null !== a && a.iv }; t.Tu = function (a) { if (!this.Zb) return !1; a = this.sc.I(a); return null !== a && (a.start.position || a.start.location) };
    function Ih(a, b) { if (!a.Sk || b) { var c = a.Td; if (!1 !== a.Zb) { var d = +new Date, e = d > a.Zm ? a.yd : d - a.Hd; b && (e = a.$v, e < a.yd ? (a.Hd = +new Date - e, a.Zm = a.Hd + a.yd) : e = a.yd); sh(c); th(a, e); yf(a.H, !0); uh(c); d > a.Zm && (a.bo && !a.Rk ? (a.Hd = +new Date, a.Zm = a.Hd + a.yd, a.Rk = !0) : a.Hl(!1)) } } }
    function th(a, b) { for (var c = a.yd, d = a.sc.iterator, e = a.Rk; d.next();) { var f = d.key; if (!(f instanceof Y && null === f.diagram)) { var g = d.value, h = e ? g.end : g.start; g = e ? g.start : g.end; var k = Oh, l; for (l in g) "position" === l && (g["position:placeholder"] || g["position:nodeCollapse"]) || null === k.get(l) || k.get(l)(f, h[l], g[l], a.Fp, b, c, a) } } } t.stop = function () { if (!this.Zb) return this; this.Hl(!0); return this };
    t.Hl = function (a) {
        null !== this.qu && this.qu.eo.remove(this.Gw); if (this.Zb) {
            var b = this.H, c = this.Td; this.Sk = this.Zb = c.Jk = !1; sh(c); for (var d = this.sc, e = this.ul.iterator; e.next();)b.remove(e.value); for (e = this.Pt.iterator; e.next();)e.value.v(); e = this.bo; d = d.iterator; for (var f = Oh; d.next();) {
                var g = d.key, h = d.value, k = e ? h.end : h.start, l = e ? h.start : h.end, m = h.Eu, n; for (n in l) if (null !== f.get(n)) {
                    var p = n; !h.Bu || "position:nodeCollapse" !== p && "position:placeholder" !== p || (p = "position"); f.get(p)(g, k[n], void 0 !== m[n] ? m[n] :
                        h.Bu ? k[n] : l[n], this.Fp, this.yd, this.yd, this)
                } h.Bu && void 0 !== m.location && g instanceof U && (g.location = m.location); h.iv && g instanceof U && g.Vb(!1)
            } b.ts.clear(); Xe(b, !1); b.Xa(); b.N(); yf(b, !0); if (c.defaultAnimation === this) { for (n = c.kl.iterator; n.next();)e = n.value, null !== e.nf && (e.points = e.nf, e.nf = null); c.kl.clear() } yf(b, !0); this.isViewportUnconstrained && (b.scrollMode = this.Xv); uh(c); this.Wt++; !a && this.Yt > this.Wt ? (this.Rk = !1, this.start()) : (this.$m && this.$m(this), ph(this, null), c.Hl(this), b.Wb())
        }
    };
    t.Aj = function (a, b) { var c = b.actualBounds, d = null; b instanceof pf && (d = b.placeholder); null !== d ? (c = d.ja(sc), d = d.padding, c.x += d.left, c.y += d.top, this.add(a, "position", c, a.position, !1)) : this.add(a, "position", new I(c.x + c.width / 2, c.y + c.height / 2), a.position, !1); this.add(a, "scale", .01, a.scale, !1); if (a instanceof pf) for (a = a.memberParts; a.next();)d = a.value, d instanceof V && this.Aj(d, b) };
    t.yj = function (a, b) { if (a.isVisible()) { var c = null; b instanceof pf && (c = b.placeholder); if (null !== c) { var d = c.ja(sc); c = c.padding; d.x += c.left; d.y += c.top; this.add(a, "position:placeholder", a.position, d, !0) } else this.add(a, "position:nodeCollapse", a.position, b, !0); this.add(a, "scale", a.scale, .01, !0); d = this.sc; d.contains(a) && (d.I(a).iv = !0); if (a instanceof pf) for (a = a.memberParts; a.next();)d = a.value, d instanceof V && this.yj(d, b) } }; t.wz = function (a) { var b = this.Ot.get(a); null === b && (b = {}, this.Ot.add(a, b)); return b };
    ma.Object.defineProperties(jh.prototype, {
        duration: { get: function () { return this.zg }, set: function (a) { 1 > a && xa(a, ">= 1", jh, "duration"); this.zg = a } }, reversible: { get: function () { return this.bo }, set: function (a) { this.bo = a } }, runCount: { get: function () { return this.Yt }, set: function (a) { 0 < a ? this.Yt = a : C("Animation.runCount value must be a positive integer.") } }, finished: {
            get: function () { return this.$m },
            set: function (a) { this.$m !== a && (this.$m = a) }
        }, easing: { get: function () { return this.ht }, set: function (a) { this.ht = a } }, isViewportUnconstrained: { get: function () { return this.j }, set: function (a) { this.j = a } }, isAnimating: { get: function () { return this.Zb } }
    }); jh.prototype.getTemporaryState = jh.prototype.wz; jh.prototype.stop = jh.prototype.stop; jh.prototype.add = jh.prototype.add; jh.prototype.addTemporaryPart = jh.prototype.By;
    function Ph(a, b, c, d) { a /= d / 2; return 1 > a ? c / 2 * a * a + b : -c / 2 * (--a * (a - 2) - 1) + b } function Rh(a, b, c, d) { return a === d ? b + c : c * (-Math.pow(2, -10 * a / d) + 1) + b } jh.className = "Animation"; jh.EaseLinear = function (a, b, c, d) { return c * a / d + b }; jh.EaseInOutQuad = Ph; jh.EaseInQuad = function (a, b, c, d) { return c * (a /= d) * a + b }; jh.EaseOutQuad = function (a, b, c, d) { return -c * (a /= d) * (a - 2) + b }; jh.EaseInExpo = function (a, b, c, d) { return 0 === a ? b : c * Math.pow(2, 10 * (a / d - 1)) + b }; jh.EaseOutExpo = Rh;
    function Xh(a, b, c) { this.start = a; this.end = b; this.Eu = {}; this.Bu = c; this.iv = !1 } Xh.className = "AnimationState"; function Yh(a, b, c) { this.rd = null; this.mf = a; this.oo = c || Zh; this.pk = null; void 0 !== b && (this.pk = b, void 0 === c && (this.oo = $h)) } Yh.prototype.copy = function () { var a = new Yh(this.mf); a.oo = this.oo; var b = this.pk; if (null !== b) { var c = {}; void 0 !== b.duration && (c.duration = b.duration); void 0 !== b.finished && (c.finished = b.finished); void 0 !== b.easing && (c.easing = b.easing); a.pk = c } return a };
    function ai(a, b) { a = a.pk; null !== a && (a.duration && (b.duration = a.duration), a.finished && (b.finished = a.finished), a.easing && (b.easing = a.easing)) } ma.Object.defineProperties(Yh.prototype, { propertyName: { get: function () { return this.mf }, set: function (a) { this.mf = a } }, animationSettings: { get: function () { return this.pk }, set: function (a) { this.pk = a } }, startCondition: { get: function () { return this.oo }, set: function (a) { this.oo = a } } });
    var Zh = new D(Yh, "Default", 1), $h = new D(Yh, "Immediate", 2), bi = new D(Yh, "Bundled", 3); Yh.className = "AnimationTrigger"; Yh.Default = Zh; Yh.Immediate = $h; Yh.Bundled = bi; function ci(a) { Qa(this); this.H = null; this.Oa = new F; this.wa = ""; this.qb = 1; this.m = !1; this.j = this.Qh = this.u = this.Li = this.Ki = this.Ji = this.Ii = this.Gi = this.Hi = this.Fi = this.Ni = this.Ei = this.Mi = this.Di = this.Ci = !0; this.Sq = []; a && Object.assign(this, a) } t = ci.prototype;
    t.clear = function () { for (var a = this.Oa, b = 0; b < a.length; b++)di(a.s[b], null); a.clear(); this.Sq.length = 0 }; t.Te = function (a) { this.H = a };
    t.toString = function (a) {
        void 0 === a && (a = 0); var b = 'Layer "' + this.name + '"'; if (0 >= a) return b; for (var c = 0, d = 0, e = 0, f = 0, g = 0, h = this.Oa.iterator; h.next();) { var k = h.value; k instanceof pf ? e++ : k instanceof V ? d++ : k instanceof T ? f++ : k instanceof te ? g++ : c++ } h = ""; 0 < c && (h += c + " Parts "); 0 < d && (h += d + " Nodes "); 0 < e && (h += e + " Groups "); 0 < f && (h += f + " Links "); 0 < g && (h += g + " Adornments "); if (1 < a) for (a = this.Oa.iterator; a.next();)c = a.value, h += "\n    " + c.toString(), d = c.data, null !== d && bb(d) && (h += " #" + bb(d)), c instanceof V ? h += " " +
            Ia(d) : c instanceof T && (h += " " + Ia(c.fromNode) + " " + Ia(c.toNode)); return b + " " + this.Oa.count + ": " + h
    }; t.ec = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); if (!1 === this.Qh) return null; var d = !1; null !== this.diagram && this.diagram.viewportBounds.ca(a) && (d = !0); for (var e = I.alloc(), f = this.Oa.s, g = f.length; g--;) { var h = f[g]; if ((!0 !== d || !1 !== hg(h)) && h.isVisible() && (e.assign(a), xb(e, h.ke), h = h.ec(e, b, c), null !== h && (null !== b && (h = b(h)), null !== h && (null === c || c(h))))) return I.free(e), h } I.free(e); return null };
    t.gi = function (a, b, c, d) { void 0 === b && (b = null); void 0 === c && (c = null); d instanceof F || d instanceof H || (d = new H); if (!1 === this.Qh) return d; var e = !1; null !== this.diagram && this.diagram.viewportBounds.ca(a) && (e = !0); for (var f = I.alloc(), g = this.Oa.s, h = g.length; h--;) { var k = g[h]; if ((!0 !== e || !1 !== hg(k)) && k.isVisible()) { f.assign(a); xb(f, k.ke); var l = k; k.gi(f, b, c, d) && (null !== b && (l = b(l)), null === l || null !== c && !c(l) || d.add(l)) } } I.free(f); return d };
    t.zf = function (a, b, c, d, e) { void 0 === b && (b = null); void 0 === c && (c = null); void 0 === d && (d = !1); e instanceof F || e instanceof H || (e = new H); if (!1 === this.Qh) return e; var f = !1; null !== this.diagram && this.diagram.viewportBounds.Me(a) && (f = !0); for (var g = this.Oa.s, h = g.length; h--;) { var k = g[h]; if ((!0 !== f || !1 !== hg(k)) && k.isVisible()) { var l = k; k.zf(a, b, c, d, e) && (null !== b && (l = b(l)), null === l || null !== c && !c(l) || e.add(l)) } } return e };
    t.Ju = function (a, b, c, d, e, f, g) { if (!1 === this.Qh) return e; for (var h = this.Oa.s, k = h.length; k--;) { var l = h[k]; if ((!0 !== g || !1 !== hg(l)) && f(l) && l.isVisible()) { var m = l; l.zf(a, b, c, d, e) && (null !== b && (m = b(m)), null === m || null !== c && !c(m) || e.add(m)) } } return e };
    t.kg = function (a, b, c, d, e, f) {
        void 0 === c && (c = null); void 0 === d && (d = null); void 0 === e && (e = !0); if (!1 !== e && !0 !== e) { if (e instanceof F || e instanceof H) f = e; e = !0 } f instanceof F || f instanceof H || (f = new H); if (!1 === this.Qh) return f; var g = !1; null !== this.diagram && this.diagram.viewportBounds.ca(a) && (g = !0); for (var h = I.alloc(), k = I.alloc(), l = this.Oa.s, m = l.length; m--;) {
            var n = l[m]; if ((!0 !== g || !1 !== hg(n)) && n.isVisible()) {
                h.assign(a); xb(h, n.ke); k.h(a.x + b, a.y); xb(k, n.ke); var p = n; n.kg(h, k, c, d, e, f) && (null !== c && (p = c(p)), null ===
                    p || null !== d && !d(p) || f.add(p))
            }
        } I.free(h); I.free(k); return f
    };
    t.wd = function (a, b) {
        if (this.visible) {
            var c = this.Oa.s, d = c.length; if (0 !== d) {
                a = Ea(); for (var e = Ea(), f = 0; f < d; f++) { var g = c[f]; g.ow = f; if (g instanceof T) { if (!1 === g.xd) continue } else if (g instanceof te && null !== g.adornedPart) continue; var h = g.actualBounds; g.isVisible() && Xb(h, b) ? (g.wd(!0), a.push(g)) : (g.wd(!1), null !== g.adornments && 0 < g.adornments.count && e.push(g)) } for (b = 0; b < a.length; b++)for (c = a[b], ei(c), c = c.adornments; c.next();)d = c.value, d.measure(Infinity, Infinity), d.arrange(), d.wd(!0); for (b = 0; b < e.length; b++)ei(e[b]);
                Ga(a); Ga(e)
            }
        }
    }; function fi(a, b) { var c = 1; 1 !== a.qb && (c = b.globalAlpha, b.globalAlpha = c * a.qb); return c } t.cc = function (a, b, c) { if (this.visible && 0 !== this.qb && (void 0 === c && (c = !0), c || !this.isTemporary)) { c = this.Oa.s; var d = c.length; if (0 !== d) { if (a.isTemporary) for (var e = 0; e < d; e++)c[e].Ej(); e = fi(this, a); var f = this.Sq; f.length = 0; for (var g = b.scale, h = N.alloc(), k = 0; k < d; k++)this.Mj(a, c[k], b, f, g, h, !0); N.free(h); if (a.isTemporary) for (b = 0; b < d; b++)c[b].Ej(); a.globalAlpha = e } } };
    function gi(a, b, c, d, e) { if (a.visible && 0 !== a.qb && (e || !a.isTemporary)) { e = a.Oa.s; var f = e.length; if (0 !== f) { var g = fi(a, b), h = a.Sq; h.length = 0; for (var k = c.scale, l = N.alloc(), m = 0; m < f; m++) { var n = e[m]; d.contains(n) && a.Mj(b, n, c, h, k, l, !1) } N.free(l); b.globalAlpha = g } } }
    t.Mj = function (a, b, c, d, e, f, g) {
        if (g && !hg(b)) a.As(b); else {
            if (null !== d && b instanceof T && (b.isOrthogonal && d.push(b), !1 === b.xd)) { a.As(b); return } g = !1; for (d = b.containingGroup; null !== d;)g ? null !== d.ib && f.xx(d.ib) : null !== d.ib && (g = !0, f.assign(d.ib)), d = d.containingGroup; var h = b.actualBounds; d = !1; if (g && b.isVisible()) { if (!f.Tc(h)) { a.As(b); return } d = !f.Me(h) } d && (a.save(), a.beginPath(), a.rect(f.x, f.y, f.width, f.height), a.clip()); h.width * e > c.Hq || h.height * e > c.Hq ? b.cc(a, c) : (e = b.actualBounds, f = b.naturalBounds, 0 === e.width ||
                0 === e.height || isNaN(e.x) || isNaN(e.y) || !b.isVisible() || (c = b.transform, null !== b.areaBackground && (hi(b, a, b.areaBackground, !0, !0, f, e), a.fillRect(e.x, e.y, e.width, e.height)), null === b.areaBackground && null === b.background && (hi(b, a, "rgba(0,0,0,0.3)", !0, !1, f, e), a.fillRect(e.x, e.y, e.width, e.height)), null !== b.background && (a.transform(c.m11, c.m12, c.m21, c.m22, c.dx, c.dy), hi(b, a, b.background, !0, !1, f, e), a.fillRect(0, 0, f.width / 2, f.height / 2), c.ps() || (b = 1 / (c.m11 * c.m22 - c.m12 * c.m21), a.transform(c.m22 * b, -c.m12 * b, -c.m21 *
                    b, c.m11 * b, b * (c.m21 * c.dy - c.m22 * c.dx), b * (c.m12 * c.dx - c.m11 * c.dy)))))); d && (a.restore(), a.Qc(!0))
        }
    }; t.g = function (a, b, c, d, e) { var f = this.diagram; null !== f && f.cb(ce, a, this, b, c, d, e) }; t.li = function (a, b, c) { var d = this.Oa; di(b, this); if (a >= d.count) a = d.count; else if (d.L(a) === b) return -1; d.Eb(a, b); b.Po(c); d = this.diagram; null !== d && (c ? d.N() : d.li(b)); ii(this, a, b); return a };
    t.mc = function (a, b, c) { if (!c && b.layer !== this && null !== b.layer) return b.layer.mc(a, b, c); var d = this.Oa; if (0 > a || a >= d.length) { if (a = d.indexOf(b), 0 > a) return -1 } else if (d.L(a) !== b && (a = d.indexOf(b), 0 > a)) return -1; b.Qo(c); d.nb(a); d = this.diagram; null !== d && (c ? d.N() : d.mc(b)); di(b, null); return a };
    function ii(a, b, c) { b = ji(a, b, c); if (c instanceof pf && null !== c && isNaN(c.zOrder)) { if (0 !== c.memberParts.count) { for (var d = -1, e = a.Oa.s, f = e.length, g = 0; g < f; g++) { var h = e[g]; if (h === c && (b = g, 0 <= d)) break; if (0 > d && h.containingGroup === c && (d = g, 0 <= b)) break } !(0 > d) && d < b && (e = a.Oa, e.nb(b), e.Eb(d, c)) } b = c.containingGroup; null !== b && ii(a, -1, b) } null !== c.svg && c.svg.remove() }
    function ji(a, b, c) {
        var d = c.zOrder; if (isNaN(d)) return b; a = a.Oa; var e = a.count; if (1 >= e) return b; 0 > b && (b = a.indexOf(c)); if (0 > b) return -1; for (var f = b - 1, g = NaN; 0 <= f;) { g = a.L(f).zOrder; if (!isNaN(g)) break; f-- } for (var h = b + 1, k = NaN; h < e;) { k = a.L(h).zOrder; if (!isNaN(k)) break; h++ } if (!isNaN(g) && g > d) for (; ;) { if (-1 === f || g <= d) { f++; if (f === b) break; a.nb(b); a.Eb(f, c); return f } for (g = NaN; 0 <= --f && (g = a.L(f).zOrder, isNaN(g));); } else if (!isNaN(k) && k < d) for (; ;) {
            if (h === e || k >= d) { h--; if (h === b) break; a.nb(b); a.Eb(h, c); return h } for (k = NaN; ++h <
                e && (k = a.L(h).zOrder, isNaN(k)););
        } return b
    }
    ma.Object.defineProperties(ci.prototype, {
        parts: { get: function () { return this.Oa.iterator } }, partsBackwards: { get: function () { return this.Oa.iteratorBackwards } }, diagram: { get: function () { return this.H } }, name: {
            get: function () { return this.wa }, set: function (a) {
                var b = this.wa; if (b !== a) {
                    var c = this.diagram; if (null !== c) for ("" === b && C("Cannot rename default Layer to: " + a), c = c.layers; c.next();)c.value.name ===
                        a && C("Layer.name is already present in this diagram: " + a); this.wa = a; this.g("name", b, a); for (a = this.Oa.iterator; a.next();)a.value.layerName = this.wa
                }
            }
        }, opacity: { get: function () { return this.qb }, set: function (a) { var b = this.qb; b !== a && ((0 > a || 1 < a) && xa(a, "0 <= value <= 1", ci, "opacity"), this.qb = a, this.g("opacity", b, a), a = this.diagram, null !== a && a.N()) } }, isTemporary: {
            get: function () { return this.m }, set: function (a) {
                var b = this.m; if (b !== a) {
                    if (this.m = a) this.isInDocumentBounds =
                        !1; this.g("isTemporary", b, a)
                }
            }
        }, visible: { get: function () { return this.u }, set: function (a) { var b = this.u; if (b !== a) { this.u = a; this.g("visible", b, a); for (b = this.Oa.iterator; b.next();)b.value.Vb(a); a = this.diagram; null !== a && a.N() } } }, pickable: { get: function () { return this.Qh }, set: function (a) { var b = this.Qh; b !== a && (this.Qh = a, this.g("pickable", b, a)) } }, isInDocumentBounds: {
            get: function () { return this.j }, set: function (a) {
                this.j !==
                a && (this.j = a, null !== this.diagram && this.diagram.Xa())
            }
        }, allowCopy: { get: function () { return this.Ci }, set: function (a) { var b = this.Ci; b !== a && (this.Ci = a, this.g("allowCopy", b, a)) } }, allowDelete: { get: function () { return this.Di }, set: function (a) { var b = this.Di; b !== a && (this.Di = a, this.g("allowDelete", b, a)) } }, allowTextEdit: {
            get: function () { return this.Mi }, set: function (a) {
                var b = this.Mi; b !== a && (this.Mi = a, this.g("allowTextEdit", b,
                    a))
            }
        }, allowGroup: { get: function () { return this.Ei }, set: function (a) { var b = this.Ei; b !== a && (this.Ei = a, this.g("allowGroup", b, a)) } }, allowUngroup: { get: function () { return this.Ni }, set: function (a) { var b = this.Ni; b !== a && (this.Ni = a, this.g("allowUngroup", b, a)) } }, allowLink: { get: function () { return this.Fi }, set: function (a) { var b = this.Fi; b !== a && (this.Fi = a, this.g("allowLink", b, a)) } }, allowRelink: {
            get: function () { return this.Hi },
            set: function (a) { var b = this.Hi; b !== a && (this.Hi = a, this.g("allowRelink", b, a)) }
        }, allowMove: { get: function () { return this.Gi }, set: function (a) { var b = this.Gi; b !== a && (this.Gi = a, this.g("allowMove", b, a)) } }, allowReshape: { get: function () { return this.Ii }, set: function (a) { var b = this.Ii; b !== a && (this.Ii = a, this.g("allowReshape", b, a)) } }, allowResize: {
            get: function () { return this.Ji }, set: function (a) {
                var b = this.Ji; b !== a && (this.Ji = a, this.g("allowResize",
                    b, a))
            }
        }, allowRotate: { get: function () { return this.Ki }, set: function (a) { var b = this.Ki; b !== a && (this.Ki = a, this.g("allowRotate", b, a)) } }, allowSelect: { get: function () { return this.Li }, set: function (a) { var b = this.Li; b !== a && (this.Li = a, this.g("allowSelect", b, a)) } }
    }); ci.prototype.findObjectsNear = ci.prototype.kg; ci.prototype.findObjectsIn = ci.prototype.zf; ci.prototype.findObjectsAt = ci.prototype.gi; ci.prototype.findObjectAt = ci.prototype.ec; ci.className = "Layer";
    function Q(a, b) {
        ki || (li(), ki = !0); Qa(this); ke = this; this.vb = !0; this.Ka = null; this.Ba = this.Ca = 0; this.xa = null; if (Ng) { var c = this, d = function () { c.removeEventListener(x.document, "DOMContentLoaded", d, !1); c.setRTL() }; null !== x.document.body ? this.setRTL() : c.addEventListener(x.document, "DOMContentLoaded", d, !1) } this.jl = null; mi.add("Model", ni); var e = this; this.Fb = function (a) {
            var b = e.partManager; if (a.model === b.diagram.model && b.diagram.aa) {
                b.diagram.aa = !1; try {
                    var c = a.change; "" === a.modelChange && c === ce && b.updateDataBindings(a.object,
                        a.propertyName)
                } finally { b.diagram.aa = !0 }
            }
        }; this.Lc = function (a) { e.partManager.doModelChanged(a) }; this.cu = this.fu = null; oi(this); this.model = pi(); this.Qg = !0; qi(this); this.layout = new ri; this.Qg = !1; this.Zj = this.ak = this.bk = this.$j = this.ri = this.Sz = this.Xj = this.Hv = null; this.Xl = function () { }; this.preventDefault = null; this.nn = !1; this.Bh = new si; void 0 !== a && ("string" === typeof a || x.Element && a instanceof Element ? ti(this, a) : b = a); this.vb = !1; b && this.km(b); return this
    } var ui, ke;
    function oi(a) {
        a.Td = new fh; a.Td.Te(a); a.Db = 17; a.zw = !1; a.pn = !1; a.Xt = "default"; a.Wa = new F; a.nj = new ob; a.il = "default"; a.Px(); a.Ox(); a.qb = 1; a.pa = (new I(NaN, NaN)).freeze(); a.Zt = new I(NaN, NaN); a.Aa = 1; a.Jp = 1; a.st = (new I(NaN, NaN)).freeze(); a.tt = NaN; a.Jt = 1E-4; a.Ft = 100; a.Ab = new ld; a.uu = (new I(NaN, NaN)).freeze(); a.nt = (new N(NaN, NaN, NaN, NaN)).freeze(); a.pj = (new hc(0, 0, 0, 0)).freeze(); a.qj = Qh; a.iu = !1; a.Ut = null; a.au = null; a.th = qh; a.uk = Sc; a.Bg = qh; a.iq = Sc; a.rt = sc; a.ut = sc; a.Cc = !0; a.ln = !1; a.ze = new H; a.zh = new ob; a.Um =
            !0; a.op = 250; a.qk = -1; a.qp = (new hc(16, 16, 16, 16)).freeze(); a.gg = !1; a.Ek = !1; a.bn = !0; a.Vp = new Zd; a.Vp.diagram = a; a.Lg = new Zd; a.Lg.diagram = a; a.$i = new Zd; a.$i.diagram = a; a.vg = null; a.kf = null; a.lu = !1; vi(a); a.Yh = new H; a.At = !0; a.pu = wi; a.iw = !1; a.ru = Df; a.at = "auto"; a.Ip = "auto"; a.uh = null; a.Ah = null; a.wh = null; a.Mh = null; a.Kh = null; a.Jh = null; a.Lt = null; a.Hh = null; a.qt = !1; a.Ih = null; a.Lh = null; a.Xh = null; a.xh = null; a.Mt = !1; a.Tt = {}; a.mj = [null, null]; a.Qg = !1; a.Bw = !1; a.ju = !1; a.dt = !1; a.kw = !0; a.cd = !1; a.Xi = !1; a.Mw = !0; a.ye = -2; a.Jg = new ob;
        a.Tn = new F; a.Cg = !1; a.Ad = !0; a.Ns = !0; a.Ci = !0; a.Di = !0; a.Os = !1; a.Ps = !0; a.Mi = !0; a.Ei = !0; a.Ni = !0; a.Rs = !0; a.Fi = !0; a.Hi = !0; a.Gi = !0; a.Ii = !0; a.Ji = !0; a.Ki = !0; a.Li = !0; a.Ss = !0; a.Us = !0; a.jw = !1; a.Qk = !1; a.hn = !0; a.jn = !0; a.Qs = !0; a.Ts = !0; a.du = 16; a.hu = 16; a.hr = !1; a.bu = !1; a.gu = 0; a.eu = 0; a.yb = (new hc(5)).freeze(); a.Aw = (new H).freeze(); a.Gt = 999999999; a.gw = (new H).freeze(); a.Wi = !0; a.Ae = !0; a.pd = !0; a.xe = !1; a.cf = !1; a.sh = !0; a.df = !1; a.ny = new H; a.hw = new H; a.Th = null; a.Uv = new L(8, 8); a.Vv = 999; a.uw = 1; a.Cw = 0; a.jd = {
            scale: 1, position: new I,
            bounds: new N, Ww: new L, Hx: new L, Ax: !1
        }; a.Kw = (new N(NaN, NaN, NaN, NaN)).freeze(); a.so = (new L(NaN, NaN)).freeze(); a.Mp = (new N(NaN, NaN, NaN, NaN)).freeze(); a.Bt = !1; a.Gg = new ob; var b = new V, c = new Pg; c.bind(new xi("text", "", Ia)); b.add(c); a.jy = b; a.Gg.add("", b); b = new V; c = new Pg; c.stroke = "brown"; c.bind(new xi("text", "", Ia)); b.add(c); a.Gg.add("Comment", b); b = new V; b.selectable = !1; b.avoidable = !1; c = new Bf; c.figure = "Ellipse"; c.fill = "black"; c.stroke = null; c.desiredSize = (new L(3, 3)).ha(); b.add(c); a.Gg.add("LinkLabel", b);
        a.Vi = new ob; b = new pf; b.selectionObjectName = "GROUPPANEL"; b.type = W.Vertical; c = new Pg; c.font = "bold 12pt sans-serif"; c.bind(new xi("text", "", Ia)); b.add(c); c = new W(W.Auto); c.name = "GROUPPANEL"; var d = new Bf; d.figure = "Rectangle"; d.fill = "rgba(128,128,128,0.2)"; d.stroke = "black"; c.add(d); d = new ig; d.padding = (new hc(5, 5, 5, 5)).ha(); c.add(d); b.add(c); a.hy = b; a.Vi.add("", b); a.Gh = new ob; b = new T; c = new Bf; c.isPanelMain = !0; b.add(c); c = new Bf; c.toArrow = "Standard"; c.fill = "black"; c.stroke = null; c.strokeWidth = 0; b.add(c); a.iy =
            b; a.Gh.add("", b); b = new T; c = new Bf; c.isPanelMain = !0; c.stroke = "brown"; b.add(c); a.Gh.add("Comment", b); b = new te; b.type = W.Auto; c = new Bf; c.fill = null; c.stroke = "dodgerblue"; c.strokeWidth = 3; b.add(c); c = new ig; c.margin = (new hc(1.5, 1.5, 1.5, 1.5)).ha(); b.add(c); a.Nt = b; a.pt = b; b = new te; b.type = W.Link; c = new Bf; c.isPanelMain = !0; c.fill = null; c.stroke = "dodgerblue"; c.strokeWidth = 3; b.add(c); a.Dt = b; a.be = null; a.fq = !1; a.xk = null; a.partManager = new ni; a.toolManager = new La; a.toolManager.initializeStandardTools(); a.defaultTool =
                a.toolManager; a.currentTool = a.defaultTool; a.ft = null; a.Tm = new Be; a.Qt = null; a.Rt = null; a.Er = !1; a.commandHandler = Fi(); a.Xp = null; a.Yp = tb; a.Iw = !1; a.Pb = 1; a.Vh = null; a.Hq = 1; a.Lq = 0; a.sw = [0, 0, 0, 0, 0]; a.Mq = 0; a.rq = 1; a.mw = 0; a.nw = new I; a.vy = 500; a.pp = new I; a.pq = !1
    } Q.prototype.clear = function () { this.animationManager.zc(); this.model.clear(); Hi = null; Ii = ""; Ji(this, !1); this.Tn.clear(); Ki(); this.Jg.clear(); this.Th = null; this.Xa(); this.Va(); this.N() };
    function Ji(a, b) {
        a.animationManager.zc(!0); a.Aw = (new H).freeze(); a.gw = (new H).freeze(); var c = a.skipsUndoManager, d = null !== a.$b && void 0 !== a.$b; d && (a.skipsUndoManager = !0); var e = null; null !== a.be && (e = a.be.part, null !== e && a.remove(e)); var f = [], g = a.Wa.length; if (b) { for (b = 0; b < g; b++)for (var h = a.Wa.s[b].parts; h.next();) { var k = h.value; k !== e && null === k.data && f.push(k) } for (b = 0; b < f.length; b++)a.remove(f[b]) } for (b = 0; b < g; b++)a.Wa.s[b].clear(); a.partManager.clear(); a.ze.clear(); a.zh.clear(); a.Yh.clear(); a.xk = null; a.Tn.clear();
        Ki(); a.Jg.clear(); Fa = []; null !== e && (a.add(e), a.partManager.parts.remove(e)); d && (a.skipsUndoManager = c); return f
    } function Fi() { return null } Q.prototype.reset = function () { this.clear(); this.vb = !0; oi(this); Li(this); this.Qg = !0; qi(this); this.layout = new ri; this.Qg = !1; this.model = pi(); this.model.undoManager = new he; this.vb = this.nn = !1; this.N() };
    Q.prototype.setRTL = function (a) { a = void 0 === a ? this.div : a; null === a && (a = x.document.body); var b = ua("div"); b.dir = "rtl"; b.style.cssText = "font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;"; b.textContent = "A"; a.appendChild(b); var c = "reverse"; 0 < b.scrollLeft ? c = "default" : (b.scrollLeft = 1, 0 === b.scrollLeft && (c = "negative")); a.removeChild(b); this.Xt = c };
    Q.prototype.setScrollWidth = function (a) {
        a = void 0 === a ? this.div : a; null === a && (a = x.document.body); var b = 0; if (Ng) {
            var c = Mi; b = ui; null === c && (c = Mi = ua("p"), c.style.width = "100%", c.style.height = "200px", c.style.boxSizing = "content-box", b = ui = ua("div"), b.style.position = "absolute", b.style.visibility = "hidden", b.style.width = "200px", b.style.height = "150px", b.style.boxSizing = "content-box", b.appendChild(c)); b.style.overflow = "hidden"; a.appendChild(b); var d = c.offsetWidth; b.style.overflow = "scroll"; c = c.offsetWidth; d === c && (c =
                b.clientWidth); a.removeChild(b); b = d - c; 0 !== b || Pa || (b = 11)
        } this.Db = b; this.zw = !0
    }; Q.prototype.rb = function (a) { a.classType === Q && (this.autoScale = a) }; Q.prototype.toString = function (a) { void 0 === a && (a = 0); var b = ""; this.div && this.div.id && (b = this.div.id); b = 'Diagram "' + b + '"'; if (0 >= a) return b; for (var c = this.Wa.iterator; c.next();)b += "\n  " + c.value.toString(a - 1); return b };
    function ti(a, b) {
        if (Ng) {
            void 0 !== b && null !== b || C("Diagram setup requires an argument DIV."); null !== a.Ka && C("Diagram has already completed setup."); "string" === typeof b ? a.Ka = x.document.getElementById(b) : b instanceof HTMLDivElement ? a.Ka = b : C("No DIV or DIV id supplied: " + b); null === a.Ka && C("Invalid DIV id; could not get element with id: " + b); void 0 !== a.Ka.H && C("Invalid div id; div already has a Diagram associated with it."); if (!a.jl && x.ResizeObserver) {
                var c = sa(function () { a.Wb() }, 250); a.jl = new x.ResizeObserver(function () { c() });
                a.jl.observe(a.Ka)
            } "static" === x.getComputedStyle(a.Ka, null).position && (a.Ka.style.position = "relative"); b = 5; var d = "rgba(2" + b + "5, 255, 255, 0)"; b--; a.Ka.style["-webkit-tap-highlight-color"] = d; a.Ka.innerHTML = ""; a.Ka.H = a; a.Ka.goDiagram = a; a.Ka.go = x.go; var e = new Ni(a); void 0 !== e.style && (e.style.position = "absolute", e.style.top = "0px", e.style.left = "0px", "rtl" === x.getComputedStyle(a.Ka, null).getPropertyValue("direction") && (a.pn = !0), e.style.zIndex = "2", e.style.userSelect = "none", e.style.MozUserSelect = "none", e.style.touchAction =
                "none"); a.xa = e; a.jb = e.context; d = a.jb; a.Pb = a.computePixelRatio(); a.viewSize.w() || (a.Ca = a.Ka.clientWidth || 1, a.Ba = a.Ka.clientHeight || 1); Oi(a, a.Ca, a.Ba); a.Ka.insertBefore(e.La, a.Ka.firstChild); e = new Ni(null); e.width = 1; e.height = 1; a.cw = e; a.ky = e.context; if (Ng) {
                    e = ua("div"); var f = ua("div"); e.style.position = "absolute"; e.style.overflow = "auto"; e.style.width = a.Ca + "px"; e.style.height = a.Ba + "px"; e.style.zIndex = "1"; f.style.position = "absolute"; f.style.width = "1px"; f.style.height = "1px"; a.Ka.appendChild(e); e.appendChild(f);
                    e.onscroll = Pi; e.addEventListener("pointerdown", Qi); e.H = a; e.py = !0; e.qy = !0; a.fu = e; a.cu = f
                } a.Xl = sa(function () { a.Vh = null; a.N() }, 300); a.Hv = sa(function () { rh(a) }, 250); a.preventDefault = function (a) { a.preventDefault(); return !1 }; a.Xj = function (b) {
                    if (a.isEnabled) {
                        var c = Ri(a, b, !0); c.bubbles = !0; var d = 0, e = 0; c.delta = 0; void 0 !== b.deltaX ? (0 !== b.deltaX && (d = 0 < b.deltaX ? 1 : -1), 0 !== b.deltaY && (e = 0 < b.deltaY ? 1 : -1), c.delta = Math.abs(b.deltaX) > Math.abs(b.deltaY) ? -d : -e) : void 0 !== b.wheelDeltaX ? (0 !== b.wheelDeltaX && (d = 0 < b.wheelDeltaX ?
                            -1 : 1), 0 !== b.wheelDeltaY && (e = 0 < b.wheelDeltaY ? -1 : 1), c.delta = Math.abs(b.wheelDeltaX) > Math.abs(b.wheelDeltaY) ? -d : -e) : void 0 !== b.wheelDelta && 0 !== b.wheelDelta && (c.delta = 0 < b.wheelDelta ? 1 : -1); a.doMouseWheel(); Si(c, b)
                    }
                }; a.Sz = function (b) { a.isEnabled && (a.Qk = !1, Ri(a, b, !0), b = a.currentTool, b.cancelWaitAfter(), b.standardMouseOver()) }; a.ri = function (b) {
                    if (a.isEnabled) {
                        a.Qk = !0; var c = a.Tt; void 0 === c[b.pointerId] && (c[b.pointerId] = b); c = a.mj; var d = !1; if (null === c[0] || c[0].pointerId !== b.pointerId && c[0].pointerType === b.pointerType) if (null !==
                            c[1] && c[1].pointerId === b.pointerId) c[1] = b, d = !0; else if (null === c[0]) c[0] = b; else if (null === c[1]) c[1] = b, d = !0; else { b.preventDefault(); return } else c[0] = b; if (c = "touch" === b.pointerType || "pen" === b.pointerType) a.lu = !1, a.pq = !0; var e = d; d = Ti(a, b, !0, !1, !0, e); Ui(a, b, d); d.targetDiagram = Vi(b, b.target); d.targetObject = null; e || d.clone(a.Vp); e = a.nw; c = c ? 25 : 10; b.timeStamp - a.mw < a.vy && !(Math.abs(e.x - b.screenX) > c || Math.abs(e.y - b.screenY) > c) ? a.rq++ : a.rq = 1; d.clickCount = a.rq; a.mw = b.timeStamp; a.nw.og(b.screenX, b.screenY); a.doMouseDown();
                        1 === b.button ? b.preventDefault() : Si(d, b)
                    }
                }; a.$j = function (b) {
                    if (a.isEnabled) {
                        a.Qk = !0; var c = a.mj; if (null === c[0] || c[0].pointerId !== b.pointerId && c[0].pointerType === b.pointerType) { if (null !== c[1] && c[1].pointerId === b.pointerId) { c[1] = b; return } if (null === c[0]) c[0] = b; else return } else c[0] = b; if (c[0].pointerId === b.pointerId) {
                            c = Ti(a, b, !1, !1, !1, null !== c[1]); var d = x.document.elementFromPoint(b.clientX, b.clientY) || null; null !== d && void 0 !== d.shadowRoot && null !== d.shadowRoot && (d = d.shadowRoot.elementFromPoint(b.clientX,
                                b.clientY)); var e = a, f = b; d && d.H && (f = b, e = d.H); c.targetDiagram = e; Ui(a, f, c); c.targetObject = null; a.doMouseMove(); Si(c, b)
                        }
                    }
                }; a.bk = function (b) {
                    if (a.isEnabled) {
                        a.Qk = !0; var c = "touch" === b.pointerType || "pen" === b.pointerType, d = a.Tt; if (c && a.lu) delete d[b.pointerId], b.preventDefault(); else if (d = a.mj, null === d[0] || d[0].pointerId !== b.pointerId && d[0].pointerType === b.pointerType) null !== d[1] && d[1].pointerId === b.pointerId && (d[1] = null); else {
                            d[0] = null; d = Ti(a, b, !1, !0, !0, !1); var e = x.document.elementFromPoint(b.clientX, b.clientY) ||
                                null; null !== e && void 0 !== e.shadowRoot && null !== e.shadowRoot && (e = e.shadowRoot.elementFromPoint(b.clientX, b.clientY)); null !== e && e.H instanceof Q && e.H !== a && Ui(e.H, b, d); null === e && (e = b.target); Ui(a, b, d); d.clickCount = a.rq; d.targetDiagram = Vi(b, e); d.targetObject = null; a.doMouseUp(); Si(d, b); c && (a.pq = !1)
                        }
                    }
                }; a.ak = function (b) {
                    if (a.isEnabled) {
                        a.Qk = !1; var c = a.Tt; c[b.pointerId] && delete c[b.pointerId]; c = a.mj; null !== c[0] && c[0].pointerId === b.pointerId && (c[0] = null); null !== c[1] && c[1].pointerId === b.pointerId && (c[1] = null);
                        "touch" !== b.pointerType && "pen" !== b.pointerType && (b = a.currentTool, b.cancelWaitAfter(), b.standardMouseOver())
                    }
                }; a.Zj = function (b) { var c = a.mj; null !== c[0] && c[0].pointerId === b.pointerId ? (c[0] = null, a.pq = !1) : null !== c[1] && c[1].pointerId === b.pointerId && (c[1] = null) }; a.gt = d.Z["d" + Wi[1] + Wi[2] + "wI" + Xi.slice(1, 5)]["b" + Yi.slice(1, b)](d.Z, Zi, b, b); d.Qc(!0); $i(a); "svg" === a.il && (b = a.nj.get("svg"), a.Ka.appendChild(b.La), a.jb = b.context)
        }
    }
    Q.prototype.addEventListener = function (a, b, c, d) { a.addEventListener(b, c, { capture: d, passive: !1 }) }; Q.prototype.removeEventListener = function (a, b, c, d) { a.removeEventListener(b, c, { capture: d }) };
    function $i(a) {
        var b = a.xa.La; b instanceof HTMLCanvasElement || (b = a.div); a.addEventListener(b, "pointerdown", a.ri, !1); a.addEventListener(b, "pointermove", a.$j, !1); a.addEventListener(b, "pointerup", a.bk, !1); a.addEventListener(b, "pointerout", a.ak, !1); a.addEventListener(b, "pointercancel", a.Zj, !1); a.addEventListener(b, "pointerenter", a.Uy, !1); a.addEventListener(b, "pointerleave", a.Vy, !1); a.addEventListener(b, "wheel", a.Xj, !1); a.addEventListener(b, "keydown", a.Hz, !1); a.addEventListener(b, "keyup", a.Iz, !1); a.addEventListener(b,
            "blur", a.Gy, !1); a.addEventListener(b, "focus", a.Hy, !1); a.addEventListener(b, "selectstart", function (a) { a.preventDefault(); return !1 }, !1); a.addEventListener(b, "contextmenu", function (a) { a.preventDefault(); return !1 }, !1); a.addEventListener(x, "resize", a.Hv, !1)
    } function Fe(a) { 30 < a.Lq && (a.Vh = 1) } function Xe(a, b) { null !== a.Vh && (a.Vh = null, b && a.Xl(), Wa && Wa.hx || (a.Lq = 0, a.sw = [0, 0, 0, 0, 0], a.Mq = 0)) } Q.prototype.computePixelRatio = function () { return null !== this.Vh ? this.Vh : x.devicePixelRatio || 1 }; Q.prototype.doMouseMove = function () { this.currentTool.doMouseMove() };
    Q.prototype.doMouseDown = function () { this.currentTool.doMouseDown() }; Q.prototype.doMouseUp = function () { this.currentTool.doMouseUp() }; Q.prototype.doMouseWheel = function () { this.currentTool.doMouseWheel() }; Q.prototype.doKeyDown = function () { this.currentTool.doKeyDown() }; Q.prototype.doKeyUp = function () { this.currentTool.doKeyUp() }; Q.prototype.doFocus = function () { this.focus() };
    Q.prototype.focus = function () { if (this.xa) if (this.scrollsPageOnFocus) this.xa.focus(); else { var a = x.scrollX || x.pageXOffset, b = x.scrollY || x.pageYOffset; this.xa.focus(); x.scrollTo(a, b) } }; Q.prototype.Hy = function () { this.H.R("GainedFocus") }; Q.prototype.Gy = function () { this.H.R("LostFocus") };
    function rh(a) { if (null !== a.xa) { var b = a.Ka; if (null !== b && 0 !== b.clientWidth && 0 !== b.clientHeight) { a.zw || a.setScrollWidth(); var c = a.cf ? a.Db : 0, d = a.xe ? a.Db : 0, e = a.Pb; a.Pb = a.computePixelRatio(); a.Pb !== e && (a.ln = !0, a.Wb()); if (b.clientWidth !== a.Ca + c || b.clientHeight !== a.Ba + d) a.Ae = !0, a.Cc = !0, b = a.layout, null !== b && b.isViewportSized && a.autoScale === qh && (a.Ek = !0, b.D()), a.cd || a.Wb() } } }
    function qi(a) { var b = new ci; b.name = "Background"; a.wl(b); b = new ci; b.name = ""; a.wl(b); b = new ci; b.name = "Foreground"; a.wl(b); b = new ci; b.name = "Adornment"; b.isTemporary = !0; b.isInDocumentBounds = !1; a.wl(b); b = new ci; b.name = "Tool"; b.isTemporary = !0; b.isInDocumentBounds = !0; a.wl(b); b = new ci; b.name = "Grid"; b.allowSelect = !1; b.pickable = !1; b.isTemporary = !0; b.isInDocumentBounds = !1; a.Pw(b, a.Oj("Background")) }
    function aj(a) {
        var b = new W(W.Grid); b.name = "GRID"; var c = new Bf; c.figure = "LineH"; c.stroke = "lightgray"; c.strokeWidth = .5; c.interval = 1; b.add(c); c = new Bf; c.figure = "LineH"; c.stroke = "gray"; c.strokeWidth = .5; c.interval = 5; b.add(c); c = new Bf; c.figure = "LineH"; c.stroke = "gray"; c.strokeWidth = 1; c.interval = 10; b.add(c); c = new Bf; c.figure = "LineV"; c.stroke = "lightgray"; c.strokeWidth = .5; c.interval = 1; b.add(c); c = new Bf; c.figure = "LineV"; c.stroke = "gray"; c.strokeWidth = .5; c.interval = 5; b.add(c); c = new Bf; c.figure = "LineV"; c.stroke =
            "gray"; c.strokeWidth = 1; c.interval = 10; b.add(c); c = new U; c.add(b); c.layerName = "Grid"; c.zOrder = 0; c.isInDocumentBounds = !1; c.isAnimated = !1; c.pickable = !1; c.locationObjectName = "GRID"; a.add(c); a.partManager.parts.remove(c); b.visible = !1; return b
    } function Pi() { this.H.bu ? this.H.bu = !1 : this.H.isEnabled ? this.H.ex(this) : bj(this.H) } function Qi(a) { this.H.isEnabled ? (this.H.gu = a.target.scrollTop, this.H.eu = a.target.scrollLeft) : bj(this.H) }
    Q.prototype.ex = function (a) {
        if (null !== this.xa) {
            var b = this.fu, c = this.cu; this.hr = !0; var d = this.documentBounds, e = this.viewportBounds, f = this.pj, g = d.x - f.left, h = d.y - f.top, k = d.width + f.left + f.right, l = d.height + f.top + f.bottom, m = d.right + f.right; f = d.bottom + f.bottom; var n = e.x; d = e.y; var p = e.width, r = e.height, q = e.right, u = e.bottom; e = this.scale; var w = a.scrollLeft; if (this.pn) switch (this.Xt) { case "negative": w = w + a.scrollWidth - a.clientWidth; break; case "reverse": w = a.scrollWidth - w - a.clientWidth }var v = w; p < k || r < l ? (w = I.allocAt(this.position.x,
                this.position.y), this.allowHorizontalScroll && this.eu !== v && (w.x = v / e + g, this.eu = v), this.allowVerticalScroll && this.gu !== a.scrollTop && (w.y = a.scrollTop / e + h, this.gu = a.scrollTop), this.position = w, I.free(w), this.Ae = this.hr = !1) : (w = I.alloc(), a.py && this.allowHorizontalScroll && (g < n && (this.position = w.h(v + g, this.position.y)), m > q && (this.position = w.h(-(b.scrollWidth - this.Ca) + v - this.Ca / e + m, this.position.y))), a.qy && this.allowVerticalScroll && (h < d && (this.position = w.h(this.position.x, a.scrollTop + h)), f > u && (this.position =
                    w.h(this.position.x, -(b.scrollHeight - this.Ba) + a.scrollTop - this.Ba / e + f))), I.free(w), this.pd = !0, this.Ae = this.hr = !1, d = this.documentBounds, e = this.viewportBounds, m = d.right, q = e.right, f = d.bottom, u = e.bottom, g = d.x, n = e.x, h = d.y, d = e.y, p >= k && g >= n && m <= q && (c.style.width = "1px"), r >= l && h >= d && f <= u && (c.style.height = "1px"))
        }
    }; Q.prototype.computeBounds = function (a) { void 0 === a && (a = new N); lh(this); return cj(this, a) };
    function cj(a, b) { if (a.fixedBounds.w()) return b.assign(a.fixedBounds), b.yo(a.yb), b; for (var c = !0, d = a.Wa.s, e = d.length, f = 0; f < e; f++) { var g = d[f]; if (g.visible && g.isInDocumentBounds) { g = g.Oa.s; for (var h = g.length, k = 0; k < h; k++) { var l = g[k]; l.isInDocumentBounds && l.isVisible() && (l = l.actualBounds, l.w() && (c ? (c = !1, b.assign(l)) : b.Zc(l))) } } } c && b.h(0, 0, 0, 0); b.yo(a.yb); return b }
    Q.prototype.computePartsBounds = function (a, b) { void 0 === b && (b = !1); var c = null; if (Aa(a)) for (var d = 0; d < a.length; d++) { var e = a[d]; !b && e instanceof T || (e.Va(), null === c ? c = e.actualBounds.copy() : c.Zc(e.actualBounds)) } else for (a = a.iterator; a.next();)d = a.value, !b && d instanceof T || (d.Va(), null === c ? c = d.actualBounds.copy() : c.Zc(d.actualBounds)); return null === c ? new N(NaN, NaN, 0, 0) : c };
    function dj(a, b) {
        if ((b || a.df) && !a.vb && null !== a.xa && a.documentBounds.w()) {
            a.vb = !0; if (b) { var c = a.initialPosition; if (c.w()) { a.vb = !1; a.position = c; return } c = I.alloc(); c.ui(a.documentBounds, a.initialDocumentSpot); var d = a.viewportBounds; d = N.allocAt(0, 0, d.width, d.height); var e = I.alloc(); e.ui(d, a.initialViewportSpot); e.h(c.x - e.x, c.y - e.y); a.position = e; N.free(d); I.free(e); I.free(c) } c = a.th; b && a.Bg !== qh && (c = a.Bg); var f = c !== qh ? ej(a, c) : a.scale; c = a.pa.x; d = a.pa.y; e = a.Ca / f; var g = a.Ba / f, h = a.uk, k = a.iq; b && !h.tb() && (k.tb() ||
                k.Tb()) && (h = k.Tb() ? xc : k); fj(a, a.documentBounds, e, g, h, b); b = a.scale; a.scale = f; a.vb = !1; f = a.viewportBounds; if (!(K.B(f.x, c) && K.B(f.y, d) && K.B(f.width, e) && K.B(f.height, g))) a.onViewportBoundsChanged(new N(c, d, e, g), f, b, !1)
        }
    }
    function ej(a, b) { var c = a.Jp; if (null === a.xa) return c; a.Va(); var d = a.documentBounds; if (!d.w()) return c; var e = d.width; d = d.height; var f = a.Ca + (a.cf ? a.Db : 0), g = a.Ba + (a.xe ? a.Db : 0), h = f / e, k = g / d; return b === gj ? (b = Math.min(k, h), b > c && (b = c), b < a.minScale && (b = a.minScale), b > a.maxScale && (b = a.maxScale), b) : b === hj ? (b = k > h ? (g - a.Db) / d : (f - a.Db) / e, b > c && (b = c), b < a.minScale && (b = a.minScale), b > a.maxScale && (b = a.maxScale), b) : a.scale }
    Q.prototype.zoomToFit = function () { var a = this.qj; this.qj = Qh; this.scale = ej(this, gj); a !== Qh && (dj(this, !1), fj(this, this.documentBounds, this.Ca / this.Aa, this.Ba / this.Aa, this.uk, !1)); this.qj = a }; t = Q.prototype;
    t.rA = function (a, b) { void 0 === b && (b = gj); var c = a.width, d = a.height; if (!(0 === c || 0 === d || isNaN(c) && isNaN(d))) { var e = 1; if (b === gj || b === hj) if (isNaN(c)) e = this.viewportBounds.height * this.scale / d; else if (isNaN(d)) e = this.viewportBounds.width * this.scale / c; else { e = this.Ca; var f = this.Ba; e = b === hj ? f / d > e / c ? (f - (this.xe ? this.Db : 0)) / d : (e - (this.cf ? this.Db : 0)) / c : Math.min(f / d, e / c) } this.scale = e; this.position = new I(a.x, a.y) } };
    t.Cy = function (a, b) { this.Va(); var c = this.documentBounds, d = this.viewportBounds; this.position = new I(c.x + (a.x * c.width + a.offsetX) - (b.x * d.width - b.offsetX), c.y + (a.y * c.height + a.offsetY) - (b.y * d.height - b.offsetY)) }; t.qz = function (a) { if (a instanceof Y) { this.Xp = a; var b = I.alloc(); this.Yp = this.ip(a.ja(sc, b)); I.free(b) } else this.Xp = null, this.Yp = tb };
    function fj(a, b, c, d, e, f) {
        var g = I.allocAt(a.pa.x, a.pa.y), h = g.x, k = g.y; if (null !== a.Xp) { var l = I.alloc(); l = a.Xp.ja(sc, l); h = l.x - a.Yp.x / a.scale; k = l.y - a.Yp.y / a.scale; e = rc; I.free(l) } if (f || a.scrollMode === Qh) e.tb() && (c > b.width && (h = b.x + (e.x * b.width + e.offsetX) - (e.x * c - e.offsetX)), d > b.height && (k = b.y + (e.y * b.height + e.offsetY) - (e.y * d - e.offsetY))), e = a.pj, f = c - b.width, c < b.width + e.left + e.right ? (h = Math.min(h + c / 2, b.right + Math.max(f, e.right) - c / 2), h = Math.max(h, b.left - Math.max(f, e.left) + c / 2), h -= c / 2) : h > b.left ? h = b.left : h < b.right -
            c && (h = b.right - c), c = d - b.height, d < b.height + e.top + e.bottom ? (k = Math.min(k + d / 2, b.bottom + Math.max(c, e.bottom) - d / 2), k = Math.max(k, b.top - Math.max(c, e.top) + d / 2), k -= d / 2) : k > b.top ? k = b.top : k < b.bottom - d && (k = b.bottom - d); g.x = isFinite(h) ? h : -a.yb.left; g.y = isFinite(k) ? k : -a.yb.top; null !== a.positionComputation && (b = a.positionComputation(a, g), g.x = b.x, g.y = b.y); Mh(a.Td, a.pa, g); a.pa.h(g.x, g.y); Li(a); I.free(g)
    }
    t.Jl = function (a, b) { void 0 === b && (b = !0); if (b) { if (a = qf(this, a, function (a) { return a.part }, function (a) { return a.canSelect() }), a instanceof U) return a } else if (a = qf(this, a, function (a) { return a.part }), a instanceof U) return a; return null }; t.ec = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); lh(this); for (var d = this.Wa.iteratorBackwards; d.next();) { var e = d.value; if (e.visible && (e = e.ec(a, b, c), null !== e)) return e } return null };
    function qf(a, b, c, d) { void 0 === c && (c = null); void 0 === d && (d = null); lh(a); for (a = a.Wa.iteratorBackwards; a.next();) { var e = a.value; if (e.visible && !e.isTemporary && (e = e.ec(b, c, d), null !== e)) return e } return null } t.dz = function (a, b, c) { void 0 === b && (b = !0); return ij(this, a, function (a) { return a.part }, b ? function (a) { return a instanceof U && a.canSelect() } : null, c) };
    function ij(a, b, c, d, e) { void 0 === c && (c = null); void 0 === d && (d = null); e instanceof F || e instanceof H || (e = new H); lh(a); for (a = a.Wa.iteratorBackwards; a.next();) { var f = a.value; f.visible && !f.isTemporary && f.gi(b, c, d, e) } return e } t.gi = function (a, b, c, d) { void 0 === b && (b = null); void 0 === c && (c = null); d instanceof F || d instanceof H || (d = new H); lh(this); for (var e = this.Wa.iteratorBackwards; e.next();) { var f = e.value; f.visible && f.gi(a, b, c, d) } return d };
    t.nx = function (a, b, c, d) { void 0 === b && (b = !1); void 0 === c && (c = !0); return jj(this, a, function (a) { return a instanceof U && (!c || a.canSelect()) }, b, d) }; t.zf = function (a, b, c, d, e) { void 0 === b && (b = null); void 0 === c && (c = null); void 0 === d && (d = !1); e instanceof F || e instanceof H || (e = new H); lh(this); for (var f = this.Wa.iteratorBackwards; f.next();) { var g = f.value; g.visible && g.zf(a, b, c, d, e) } return e };
    t.Ju = function (a, b, c, d, e, f) { var g = new H; lh(this); for (var h = this.Wa.iteratorBackwards; h.next();) { var k = h.value; k.visible && k.Ju(a, b, c, d, g, e, f) } return g }; function jj(a, b, c, d, e) { var f = null; void 0 === f && (f = null); void 0 === c && (c = null); void 0 === d && (d = !1); e instanceof F || e instanceof H || (e = new H); lh(a); for (a = a.Wa.iteratorBackwards; a.next();) { var g = a.value; g.visible && !g.isTemporary && g.zf(b, f, c, d, e) } return e }
    t.ez = function (a, b, c, d, e) { void 0 === c && (c = !0); void 0 === d && (d = !0); return kj(this, a, b, function (a) { return a instanceof U && (!d || a.canSelect()) }, c, e) }; t.kg = function (a, b, c, d, e, f) { void 0 === c && (c = null); void 0 === d && (d = null); void 0 === e && (e = !0); if (!1 !== e && !0 !== e) { if (e instanceof F || e instanceof H) f = e; e = !0 } f instanceof F || f instanceof H || (f = new H); lh(this); for (var g = this.Wa.iteratorBackwards; g.next();) { var h = g.value; h.visible && h.kg(a, b, c, d, e, f) } return f };
    function kj(a, b, c, d, e, f) { var g = null; void 0 === g && (g = null); void 0 === d && (d = null); void 0 === e && (e = !0); if (!1 !== e && !0 !== e) { if (e instanceof F || e instanceof H) f = e; e = !0 } f instanceof F || f instanceof H || (f = new H); lh(a); for (a = a.Wa.iteratorBackwards; a.next();) { var h = a.value; h.visible && !h.isTemporary && h.kg(b, c, g, d, e, f) } return f } Q.prototype.acceptEvent = function (a) { return Ri(this, a, a instanceof MouseEvent) };
    function Ri(a, b, c) { var d = a.Lg; a.Lg = a.$i; a.$i = d; d.diagram = a; d.event = b; c ? Ui(a, b, d) : (d.viewPoint = a.Lg.viewPoint, d.documentPoint = a.Lg.documentPoint); a = 0; b.ctrlKey && (a += 1); b.altKey && (a += 2); b.shiftKey && (a += 4); b.metaKey && (a += 8); d.modifiers = a; d.button = b.button; void 0 !== b.buttons && (d.buttons = b.buttons); Pa && 0 === b.button && b.ctrlKey && (d.button = 2); d.down = !1; d.up = !1; d.clickCount = 1; d.delta = 0; d.handled = !1; d.bubbles = !1; d.timestamp = b.timeStamp; d.isMultiTouch = !1; d.targetDiagram = Vi(b, b.target); d.targetObject = null; return d }
    function Vi(a, b) { if (null === b) return null; if (b = b.H) return b; (b = a.path) || "function" !== typeof a.composedPath || (b = a.composedPath()); return b && b[0] && b[0].H ? b[0].H : null }
    function Ti(a, b, c, d, e, f) { var g = a.Lg; a.Lg = a.$i; a.$i = g; g.diagram = a; g.clickCount = 1; a = g.delta = 0; b.ctrlKey && (a += 1); b.altKey && (a += 2); b.shiftKey && (a += 4); b.metaKey && (a += 8); g.modifiers = a; g.event = b; g.timestamp = b.timeStamp; g.button = b.button; g.buttons = b.buttons; Pa && 0 === b.button && b.ctrlKey && (g.button = 2); g.down = c; g.up = d; g.handled = !1; g.bubbles = e; g.isMultiTouch = f; return g }
    function Si(a, b) { if (a.bubbles) return !0; void 0 !== b.stopPropagation && b.stopPropagation(); !1 !== b.cancelable && b.preventDefault(); b.cancelBubble = !0; return !1 }
    Q.prototype.Hz = function (a) {
        var b = this.H; if (!this.H.isEnabled) return !1; var c = Ri(b, a, !1); c.key = String.fromCharCode(a.which); c.down = !0; switch (a.which) {
            case 8: c.key = "Backspace"; break; case 33: c.key = "PageUp"; break; case 34: c.key = "PageDown"; break; case 35: c.key = "End"; break; case 36: c.key = "Home"; break; case 37: c.key = "Left"; break; case 38: c.key = "Up"; break; case 39: c.key = "Right"; break; case 40: c.key = "Down"; break; case 45: c.key = "Insert"; break; case 46: c.key = "Del"; break; case 48: c.key = "0"; break; case 187: case 61: case 107: c.key =
                "Add"; break; case 189: case 173: case 109: c.key = "Subtract"; break; case 27: c.key = "Esc"
        }b.doKeyDown(); return Si(c, a)
    };
    Q.prototype.Iz = function (a) { var b = this.H; if (!b.isEnabled) return !1; var c = Ri(b, a, !1); c.key = String.fromCharCode(a.which); c.up = !0; switch (a.which) { case 8: c.key = "Backspace"; break; case 33: c.key = "PageUp"; break; case 34: c.key = "PageDown"; break; case 35: c.key = "End"; break; case 36: c.key = "Home"; break; case 37: c.key = "Left"; break; case 38: c.key = "Up"; break; case 39: c.key = "Right"; break; case 40: c.key = "Down"; break; case 45: c.key = "Insert"; break; case 46: c.key = "Del" }b.doKeyUp(); return Si(c, a) };
    Q.prototype.Uy = function (a) { var b = this.H; if (!b.isEnabled) return !1; var c = Ri(b, a, !0); null !== b.mouseEnter && b.mouseEnter(c); b.currentTool.standardMouseOver(); return Si(c, a) }; Q.prototype.Vy = function (a) { var b = this.H; if (!b.isEnabled) return !1; var c = Ri(b, a, !0); null !== b.mouseLeave && b.mouseLeave(c); return Si(c, a) };
    Q.prototype.getMouse = function (a) { var b = this.xa; if (null === b) return new I(0, 0); var c = b.getBoundingClientRect(); b = a.clientX - this.Ca / c.width * c.left; a = a.clientY - this.Ba / c.height * c.top; return null !== this.Ab ? xb(new I(b, a), this.Ab) : new I(b, a) };
    function Ui(a, b, c) { var d = a.xa, e = a.Ca, f = a.Ba, g = 0, h = 0; null !== d && (d = d.getBoundingClientRect(), g = b.clientX - e / d.width * d.left, h = b.clientY - f / d.height * d.top); c.viewPoint.h(g, h); null !== a.Ab ? (b = I.allocAt(g, h), a.Ab.Ld(b), c.documentPoint.assign(b), I.free(b)) : c.documentPoint.h(g, h) } function $d(a, b, c) { if (null !== a.mj[0]) { b = a.mj[b]; var d = a.xa; null !== d && (d = d.getBoundingClientRect(), c.h(b.clientX - a.Ca / d.width * d.left, b.clientY - a.Ba / d.height * d.top)) } } Q.prototype.Xa = function () { this.Wi || (this.Wi = !0, this.Wb(!1)) };
    function lj(a) { a.cd || lh(a); a.Va() } Q.prototype.redraw = function () { this.vb || this.cd || (this.N(), this.Wc()) }; t = Q.prototype; t.Gz = function () { return this.gg }; t.Py = function (a) { void 0 === a && (a = null); var b = this.animationManager, c = b.isEnabled; b.zc(); b.isEnabled = !1; yf(this); this.df = !1; this.Zt = new I(NaN, NaN); b.isEnabled = c; this.cd = !0; var d = this; null !== a && ta(function () { d.cd = !1; kh(b, "Model"); a(d) }, 1) };
    t.Wb = function (a) { void 0 === a && (a = !1); if (!0 !== this.gg && !(this.vb || !1 === a && this.cd)) { this.gg = !0; var b = this; x.requestAnimationFrame(function () { b.gg && b.Wc() }) } }; t.Wc = function () { if (!this.bn || this.gg) this.bn && (this.bn = !1), yf(this) }; function mj(a, b, c) { !a.Ae || a.vb || a.animationManager.defaultAnimation.isAnimating || bj(a) || (b && lh(a), c && dj(a, !1)) }
    function yf(a, b) {
        if (!a.cd && (a.gg = !1, null !== a.Ka || a.so.w())) {
            a.cd = !0; var c = a.animationManager, d = a.Tn; if (!c.isAnimating && 0 !== d.length) { for (var e = d.s, f = e.length, g = 0; g < f; g++) { var h = e[g]; nj(h, !1); h.v() } d.clear() } d = a.hw; 0 < d.count && (d.each(function (a) { a.Gv() }), d.clear()); e = d = !1; c.defaultAnimation.isAnimating && (e = !0, d = a.skipsUndoManager, a.skipsUndoManager = !0); c.zb || rh(a); mj(a, !1, !0); null !== a.be && (a.be.visible && !a.fq && (oj(a), a.fq = !0), !a.be.visible && a.fq && (a.fq = !1)); lh(a); f = !1; if (!a.df || a.sh) a.df ? pj(a, !a.Ek) :
                (a.Ea("Initial Layout"), !1 === c.isEnabled && c.zc(), pj(a, !1)), f = !0; a.Ek = !1; lh(a); a.ju || lj(a); mj(a, !0, !1); g = !1; f ? (c = N.alloc(), c.assign(a.viewportBounds), a.df || (g = a.df = !0, a.skipsUndoManager || (a.undoManager.isPendingClear = !0), a.undoManager.isPendingUnmodified = !0, qj(a)), a.R("LayoutCompleted"), mj(a, !0, !1), N.free(c)) : c.Jk && c.Jw && (a.Bg !== qh ? a.scale = ej(a, a.Bg) : a.th !== qh ? a.scale = ej(a, a.th) : (c = a.initialScale, isFinite(c) && 0 < c && (a.scale = c)), dj(a, !0)); f && g && a.eb("Initial Layout"); lh(a); b || a.cc(a.jb); e && (a.skipsUndoManager =
                    d); a.cd = !1
        }
    } function qj(a) { if (a.Bg !== qh) a.scale = ej(a, a.Bg); else if (a.th !== qh) a.scale = ej(a, a.th); else { var b = a.initialScale; isFinite(b) && 0 < b && (a.scale = b) } dj(a, !0); b = a.Wa.s; a.wd(b, b.length, a, a.viewportBounds); a.R("InitialLayoutCompleted"); a.Zt.assign(a.pa); oj(a) }
    function lh(a) { if (0 !== a.ze.count && (a.cd || !a.animationManager.isTicking)) { for (var b = 0; 23 > b; b++) { var c = a.ze.iterator; if (null === c || 0 === a.ze.count) break; a.ze = new H; a.Gv(c, a.ze) } a.nodes.each(function (a) { a instanceof pf && 0 !== (a.U & 65536) !== !1 && (a.U = a.U ^ 65536) }) } }
    t.Gv = function (a, b) { for (a.reset(); a.next();) { var c = a.value; !c.Uc() || c instanceof pf || (c.oi() ? (c.measure(Infinity, Infinity), c.arrange()) : b.add(c)) } for (a.reset(); a.next();)c = a.value, c instanceof pf && c.isVisible() && rj(this, c); for (a.reset(); a.next();)c = a.value, c instanceof T && c.isVisible() && (c.oi() ? (c.measure(Infinity, Infinity), c.arrange()) : b.add(c)); for (a.reset(); a.next();)c = a.value, c instanceof te && c.isVisible() && (c.oi() ? (c.measure(Infinity, Infinity), c.arrange()) : b.add(c)) };
    function rj(a, b) { for (var c = Ea(), d = Ea(), e = b.memberParts; e.next();) { var f = e.value; f.isVisible() && (f instanceof pf ? (sj(f) || tj(f) || uj(f)) && rj(a, f) : f instanceof T ? f.fromNode === b || f.toNode === b ? d.push(f) : c.push(f) : (f.measure(Infinity, Infinity), f.arrange())) } a = c.length; for (e = 0; e < a; e++)f = c[e], f.measure(Infinity, Infinity), f.arrange(); Ga(c); b.measure(Infinity, Infinity); b.arrange(); a = d.length; for (b = 0; b < a; b++)c = d[b], c.measure(Infinity, Infinity), c.arrange(); Ga(d) }
    t.wd = function (a, b, c, d) { if (this.pd || this.animationManager.isAnimating) for (var e = 0; e < b; e++)a[e].wd(c, d) };
    t.cc = function (a) {
        if (null !== this.Ka) if (null === this.xa && C("No canvas specified"), "svg" === this.il) {
            if (null !== this.Ka) {
                var b = this.animationManager; if (!b.zb && (!b.isAnimating || b.isTicking)) {
                    vj(this); var c = this.Ca, d = this.Ba; a.zv.resize(c, d, c, d); c = this.Wa.s; d = c.length; this.wd(c, d, this, this.viewportBounds); if (this.Cc || b.isAnimating) {
                        b = this.pa; var e = this.Aa, f = Math.round(b.x * e) / e, g = Math.round(b.y * e) / e, h = this.Ab; h.reset(); 1 !== e && h.scale(e); 0 === b.x && 0 === b.y || h.translate(-f, -g); a.pi = null; wj(a, h.m11, h.m12, h.m21,
                            h.m22, h.dx, h.dy); 1 !== this.qb && (a.globalAlpha = this.qb); xj(this, a); for (b = 0; b < d; b++)c[b].cc(a, this); this.Cc = this.pd = !1
                    }
                }
            }
        } else a: if (f = this.animationManager, !f.zb && (!f.isAnimating || f.isTicking) && (c = new Date, vj(this), "0" !== this.Ka.style.opacity)) {
            d = a !== this.jb; b = this.Wa.s; e = b.length; this.wd(b, e, this, this.viewportBounds); if (d) a.Qc(!0), this.pd = !0; else if (!this.Cc && !f.isAnimating) break a; g = this.pa; h = this.Aa; var k = Math.round(g.x * h) / h, l = Math.round(g.y * h) / h; f = this.Ab; f.reset(); 1 !== h && f.scale(h); 0 === g.x && 0 ===
                g.y || f.translate(-k, -l); g = this.Pb; a.setTransform(g, 0, 0, g, 0, 0); a.clearRect(0, 0, this.Ca, this.Ba); 1 !== this.qb && (a.globalAlpha = this.qb); xj(this, a); for (h = 0; h < e; h++)a.setTransform(g, 0, 0, g, 0, 0), a.transform(f.m11, f.m12, f.m21, f.m22, f.dx, f.dy), a.vc(), b[h].cc(a, this); b = !1; this.Bh ? yj(this.Bh, this) && (b = this.gt()) : b = !0; b && (ld.prototype.Ld = ld.prototype.Uw); d ? (this.jb.Qc(!0), this.pd = !0) : this.Cc = this.pd = !1; b = +new Date - +c; d = Wa && Wa.hx; c = this.sw; if (d || null === this.Vh) {
                    c[this.Mq] = b; this.Mq = (this.Mq + 1) % c.length; for (e = b =
                        0; e < c.length; e++)b += c[e]; this.Lq = b / c.length
                } 1 !== this.qb && (a.globalAlpha = 1); if (d) for (a.setTransform(1, 0, 0, 1, 0, 0), d = 0; d < c.length; d++)a.fillText(c[d].toString(), 20, 150 + +(20 * d))
        }
    };
    function zj(a, b, c, d, e, f, g, h, k, l) {
        if (null !== a.Ka) {
            null === a.xa && C("No canvas specified"); void 0 === g && (g = null); void 0 === h && (h = null); void 0 === k && (k = !1); void 0 === l && (l = !1); vj(a); a.jb.Qc(!0); a.pd = !0; a.Xi = !0; var m = a.Aa; a.Aa = e; var n = a.Wa.s, p = n.length; try {
                var r = new N(f.x, f.y, d.width / e, d.height / e), q = r.copy(); q.yo(c); oj(a, q); lh(a); a.wd(n, p, a, r); b.setTransform(1, 0, 0, 1, 0, 0); b.clearRect(0, 0, d.width, d.height); null !== h && "" !== h && (b.fillStyle = h, b.ix(0, 0, d.width, d.height)); var u = a.Pb; b.scale(u, u); var w = ld.alloc(); w.reset();
                w.translate(c.left, c.top); w.scale(e); 0 === f.x && 0 === f.y || w.translate(-f.x, -f.y); b.setTransform(w.m11, w.m12, w.m21, w.m22, w.dx, w.dy); b.vc(); b instanceof Aj && wj(b, w.m11, w.m12, w.m21, w.m22, w.dx, w.dy); ld.free(w); xj(a, b); b.globalAlpha = a.qb; var v = a.Oj("Grid"); if (g) { var z = new H, y = g.iterator; for (y.reset(); y.next();) { var A = y.value; A instanceof U && (l || A.layer !== v) && (A.Ej(), z.add(A)) } for (l = 0; l < p; l++)gi(n[l], b, a, z, k); z.each(function (a) { return a.Ej() }) } else for (z = 0; z < p; z++) {
                    var B = n[z]; B === v ? l && B.cc(b, a, !0) : B.cc(b,
                        a, k)
                } a.Xi = !1; a.Bh && yj(a.Bh, a) && a.gt()
            } finally { a.Aa = m, a.jb.Qc(!0), a.pd = !0, a.wd(n, p, a, a.viewportBounds), oj(a), a.jb instanceof Aj && (a.jb.Lj.FA(), a.redraw()) }
        }
    } t.Df = function (a) { return this.Mg[a] }; t.Tx = function (a, b) { "minDrawingLength" === a && (this.Hq = b); this.Mg[a] = b; this.redraw() }; t.Px = function () { this.Mg = new Ta; this.Mg.drawShadows = !0; this.Mg.textGreeking = !0; this.Mg.viewportOptimizations = Pa || Na ? !1 : !0; this.Mg.temporaryPixelRatio = !0; this.Mg.pictureRatioOptimization = !0; this.Hq = this.Mg.minDrawingLength = 1 };
    function xj(a, b) { a = a.Mg; null !== a && (void 0 !== a.imageSmoothingEnabled && b.Sx(!!a.imageSmoothingEnabled), a = a.defaultFont, void 0 !== a && null !== a && (b.font = a)) } t.Ll = function (a) { return this.kn[a] }; t.iA = function (a, b) { this.kn[a] = b }; t.Ox = function () { this.kn = new Ta; this.kn.extraTouchArea = 10; this.kn.extraTouchThreshold = 10; this.kn.hasGestureZoom = !0 }; t.set = function (a) { Object.assign(this, a); return this }; t.yu = function (a) { return this.km(a) }; t.km = function (a) { Bj(this, a); return this };
    function Bj(a, b) {
        var c = a instanceof W, d = a instanceof Q, e; for (e in b) {
            "" === e && C("Setting properties requires non-empty property names"); var f = a, g = e; if (c || d) { var h = e.indexOf("."); if (0 < h) { var k = e.substring(0, h); if (c) f = a.fb(k); else if (f = a[k], void 0 === f || null === f) f = a.toolManager[k]; za(f) ? g = e.substr(h + 1) : C("Unable to find object named: " + k + " in " + a.toString() + " when trying to set property: " + e) } } if ("_" !== g[0] && !Ja(f, g)) if (d && "ModelChanged" === g) { a.Rw(b[g]); continue } else if (d && "Changed" === g) { a.Xg(b[g]); continue } else if (d &&
                Ja(a.toolManager, g)) f = a.toolManager; else if (d && Cj(a, g)) { a.zj(g, b[g]); continue } else if (a instanceof Z && "Changed" === g) { a.Xg(b[g]); continue } else C('Trying to set undefined property "' + g + '" on object: ' + f.toString()); f[g] = b[e]; "_" === g[0] && f instanceof Y && f.Ow(g)
        }
    } t.Xw = function () { if (0 !== this.zh.count && !(1 < this.undoManager.transactionLevel)) { for (; 0 < this.zh.count;) { var a = this.zh; this.zh = new ob; for (a = a.iterator; a.next();) { var b = a.key; b.Ro(a.value); b.nc() } } this.N() } };
    t.N = function (a) { if (void 0 === a) this.Cc = !0, this.Wb(); else { var b = this.viewportBounds; a.w() && b.Tc(a) && (this.Cc = !0, this.Wb()) } this.R("InvalidateDraw") };
    Q.prototype.invalidateViewport = function (a, b) {
        if (!0 !== this.Cc) if (this.Cc = !0, "svg" === this.il) this.Wc(); else {
            var c = !0 === this.Df("temporaryPixelRatio"); if (!0 === this.Df("viewportOptimizations") && this.scrollMode !== Sh && this.pj.ei(0, 0, 0, 0) && b.width === a.width && b.height === a.height) {
                var d = this.scale, e = Math.max(a.y, b.y), f = Math.min(a.y + a.height, b.y + b.height); if (0 < Math.max(0, Math.min(a.x + a.width, b.x + b.width) - Math.max(a.x, b.x)) * d && 0 < Math.max(0, f - e) * d) {
                    if (!(this.cd || (this.gg = !1, null === this.Ka || (this.cd = !0, this.documentBounds.w() ||
                        (d = N.alloc(), Dj(this, this.computeBounds(d)), N.free(d)), d = this.xa, null === d || d instanceof Ej)))) {
                            var g = this.Pb; e = this.Ca * g; var h = this.Ba * g, k = this.scale * g; f = Math.round(Math.round(b.x * k) - Math.round(a.x * k)); b = Math.round(Math.round(b.y * k) - Math.round(a.y * k)); k = this.cw; a = this.ky; k.width !== e && (k.width = e); k.height !== h && (k.height = h); a.clearRect(0, 0, e, h); k = 190 * g; var l = 70 * g, m = Math.max(f, 0), n = Math.max(b, 0), p = Math.floor(e - m), r = Math.floor(h - n); a.drawImage(d.La, m, n, p, r, 0, 0, p, r); yj(this.Bh, this) && a.clearRect(0, 0, k,
                                l); d = Ea(); a = Ea(); r = Math.abs(f); p = Math.abs(b); var q = 0 === m ? 0 : e - r; m = I.allocAt(q, 0); r = I.allocAt(r + q, h); a.push(new N(Math.min(m.x, r.x), Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y))); var u = this.Ab; u.reset(); u.scale(g, g); 1 !== this.Aa && u.scale(this.Aa); g = this.pa; (0 !== g.x || 0 !== g.y) && isFinite(g.x) && isFinite(g.y) && u.translate(-g.x, -g.y); xb(m, u); xb(r, u); d.push(new N(Math.min(m.x, r.x), Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y))); q = 0 === n ? 0 : h - p; m.h(0, q); r.h(e, p + q); a.push(new N(Math.min(m.x, r.x),
                                    Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y))); xb(m, u); xb(r, u); d.push(new N(Math.min(m.x, r.x), Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y))); yj(this.Bh, this) && (e = 0 < f ? 0 : -f, h = 0 < b ? 0 : -b, m.h(e, h), r.h(k + e, l + h), a.push(new N(Math.min(m.x, r.x), Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y))), xb(m, u), xb(r, u), d.push(new N(Math.min(m.x, r.x), Math.min(m.y, r.y), Math.abs(m.x - r.x), Math.abs(m.y - r.y)))); I.free(m); I.free(r); mj(this, !1, !0); null === this.Ka && C("No div specified"); null === this.xa &&
                                        C("No canvas specified"); if (!this.animationManager.zb && (e = this.jb, this.Cc)) {
                                            vj(this); h = this.Pb; e.setTransform(1, 0, 0, 1, 0, 0); e.clearRect(0, 0, this.Ca * h, this.Ba * h); e.drawImage(this.cw.La, 0 < f ? 0 : Math.round(-f), 0 < b ? 0 : Math.round(-b)); f = this.pa; g = this.Aa; k = Math.round(f.x * g) / g; l = Math.round(f.y * g) / g; b = this.Ab; b.reset(); 1 !== g && b.scale(g); 0 === f.x && 0 === f.y || b.translate(-k, -l); e.save(); e.beginPath(); f = a.length; for (g = 0; g < f; g++)k = a[g], 0 !== k.width && 0 !== k.height && e.rect(Math.floor(k.x), Math.floor(k.y), Math.ceil(k.width),
                                                Math.ceil(k.height)); e.clip(); e.setTransform(h, 0, 0, h, 0, 0); e.transform(b.m11, b.m12, b.m21, b.m22, b.dx, b.dy); e.vc(); b = this.Wa.s; f = b.length; this.wd(b, f, this, this.viewportBounds); xj(this, e); e.globalAlpha = this.qb; for (h = 0; h < f; h++)if (g = b[h], k = d, g.visible && 0 !== g.qb) {
                                                    l = fi(g, e); n = g.Sq; n.length = 0; m = this.scale; p = N.alloc(); r = g.Oa.s; u = r.length; q = k.length; for (var w = 0; w < u; w++) {
                                                        var v = r[w], z = Fj(v, v.actualBounds); a: {
                                                            for (var y = 2 / m, A = 4 / m, B = 0; B < q; B++) {
                                                                var E = k[B]; if (0 !== E.width && 0 !== E.height && z.Ru(E.x - y, E.y - y, E.width + A, E.height +
                                                                    A)) { z = !0; break a }
                                                            } z = !1
                                                        } z && g.Mj(e, v, this, n, m, p, !0)
                                                    } N.free(p); e.globalAlpha = l
                                                } e.restore(); e.Qc(!0); this.Bh && yj(this.Bh, this) && this.gt(); this.Cc = this.pd = !1; this.Xl()
                                        } Ga(d); Ga(a); this.cd = !1
                    }
                } else this.Wc(); c && (Fe(this), this.Wc(), Xe(this, !0))
            } else c ? (Fe(this), this.Wc(), Xe(this, !0)) : this.Wc()
        }
    }; function vj(a) { !1 !== a.ln && (a.ln = !1, Oi(a, a.Ca, a.Ba)) } function Oi(a, b, c) { Li(a); var d = a.Pb; a.xa.resize(b * d, c * d, b, c) && (a.Cc = !0, a.jb.Qc(!0)) }
    function bj(a) {
        var b = a.xa; if (null === b || !a.df || a.so.w()) return !0; var c = a.Ca, d = a.Ba, e = a.viewportBounds; if (!a.viewportBounds.w()) return !0; var f = e.x, g = e.y, h = e.width, k = e.height, l = a.fu, m = a.cu, n = a.Ka, p = !1, r = a.cf ? a.Db : 0, q = a.xe ? a.Db : 0, u = n.clientWidth || c + r, w = n.clientHeight || d + q; if (u !== c + r || w !== d + q) a.cf = !1, a.xe = !1, q = r = 0, a.Ca = u, a.Ba = w, p = a.ln = !0, Li(a); a.Ae = !1; if (!(p || a.cf || a.xe || a.hn || a.jn)) return !0; var v = a.documentBounds, z = 0, y = 0, A = 0, B = 0; n = e.width; var E = e.height, M = a.pj; a.contentAlignment.tb() ? (v.width > n && (z = M.left,
            y = M.right), v.height > E && (A = M.top, B = M.bottom)) : (z = M.left, y = M.right, A = M.top, B = M.bottom); var R = v.width + z + y, S = v.height + A + B; M = a.scale; var J = R > u / M, G = S > w / M; if (!(p || a.cf || a.xe || J || G)) return !0; z = v.x - z; u = e.x; y = v.right + y; w = e.right + r; A = v.y - A; var ca = e.y; v = v.bottom + B; e = e.bottom + q; var X = "1px"; B = "1px"; a.scrollMode === Qh && (J || G) && (J && a.hasHorizontalScrollbar && a.allowHorizontalScroll && (J = 1, z + 1 < u && (J = Math.max((u - z) * M + a.Ca, J)), y > w + 1 && (J = Math.max((y - w) * M + a.Ca, J)), n + r + 1 < R && (J = Math.max((R - n) * M + a.Ca, J)), X = J.toString() + "px"),
                G && a.hasVerticalScrollbar && a.allowVerticalScroll && (J = 1, A + 1 < ca && (J = Math.max((ca - A) * M + a.Ba, J)), v > e + 1 && (J = Math.max((v - e) * M + a.Ba, J)), E + q + 1 < S && (J = Math.max((S - E) * M + a.Ba, J)), B = J.toString() + "px")); J = "1px" !== X; G = "1px" !== B; J && G || !J && !G || (G && (w -= a.Db), J && (e -= a.Db), R < n + r || !a.hasHorizontalScrollbar || !a.allowHorizontalScroll || (r = 1, z + 1 < u && (r = Math.max((u - z) * M + a.Ca, r)), y > w + 1 && (r = Math.max((y - w) * M + a.Ca, r)), n + 1 < R && (r = Math.max((R - n) * M + a.Ca, r)), X = r.toString() + "px"), J = "1px" !== X, r = a.Ba, J !== a.xe && (r = J ? a.Ba - a.Db : a.Ba + a.Db),
                    S < E + q || !a.hasVerticalScrollbar || !a.allowVerticalScroll || (q = 1, A + 1 < ca && (q = Math.max((ca - A) * M + r, q)), v > e + 1 && (q = Math.max((v - e) * M + r, q)), E + 1 < S && (q = Math.max((S - E) * M + r, q)), B = q.toString() + "px"), G = "1px" !== B); if (a.hr && J === a.xe && G === a.cf) return c === a.Ca && d === a.Ba || a.Wc(), !1; J !== a.xe && ("1px" === X ? a.Ba = a.Ba + a.Db : a.Ba = Math.max(a.Ba - a.Db, 1), p = !0); a.xe = J; m.style.width = X; G !== a.cf && ("1px" === B ? a.Ca = a.Ca + a.Db : a.Ca = Math.max(a.Ca - a.Db, 1), p = !0, a.pn && (q = I.alloc(), G ? (b.style.left = a.Db + "px", a.position = q.h(a.pa.x + a.Db / a.scale,
                        a.pa.y)) : (b.style.left = "0px", a.position = q.h(a.pa.x - a.Db / a.scale, a.pa.y)), I.free(q))); p && Li(a); a.cf = G; m.style.height = B; a.bu = !0; p && (a.ln = !0); b = l.scrollLeft; a.hasHorizontalScrollbar && a.allowHorizontalScroll && (n + 1 < R ? b = (a.position.x - z) * M : z + 1 < u ? b = l.scrollWidth - l.clientWidth : y > w + 1 && (b = a.position.x * M)); if (a.pn) switch (a.Xt) { case "negative": b = -(l.scrollWidth - b - l.clientWidth); break; case "reverse": b = l.scrollWidth - b - l.clientWidth }l.scrollLeft = b; a.hasVerticalScrollbar && a.allowVerticalScroll && (E + 1 < S ? l.scrollTop =
                            (a.position.y - A) * M : A + 1 < ca ? l.scrollTop = l.scrollHeight - l.clientHeight : v > e + 1 && (l.scrollTop = a.position.y * M)); u = a.Ca; w = a.Ba; l.style.width = u + (a.cf ? a.Db : 0) + "px"; l.style.height = w + (a.xe ? a.Db : 0) + "px"; return c !== u || d !== w || a.animationManager.zb ? (c = N.allocAt(f, g, h, k), a.onViewportBoundsChanged(c, a.viewportBounds, M, p), N.free(c), !1) : !0
    } t = Q.prototype;
    t.add = function (a) { var b = a.diagram; if (b !== this && (null !== b && C("Cannot add part " + a.toString() + " to " + this.toString() + ". It is already a part of " + b.toString()), b = this.Oj(a.layerName), null === b && (b = this.Oj("")), null === b && C('Cannot add a Part when unable find a Layer named "' + a.layerName + '" and there is no default Layer'), a.layer !== b)) { var c = b.li(99999999, a, a.diagram === this); 0 <= c && this.cb(ee, "parts", b, null, a, null, c); b.isTemporary || this.Xa(); a.D(1); c = a.layerChanged; null !== c && c(a, null, b) } };
    t.li = function (a) { this.partManager.li(a); var b = this; Gj(a, function (a) { Hj(b, a) }); (a instanceof te || a instanceof pf && null !== a.placeholder) && a.v(); null !== a.data && Gj(a, function (a) { Ij(b.partManager, a) }); !0 !== tj(a) && !0 !== uj(a) || this.ze.add(a); Jj(a, !0, this); Kj(a) ? (a.actualBounds.w() && this.N(Fj(a, a.actualBounds)), this.Xa()) : a.isVisible() && a.actualBounds.w() && this.N(Fj(a, a.actualBounds)); this.Wb() };
    t.mc = function (a) { a.Cj(); this.partManager.mc(a); var b = this; null !== a.data && Gj(a, function (a) { Lj(b.partManager, a, b) }); this.ze.remove(a); Kj(a) ? (a.actualBounds.w() && this.N(Fj(a, a.actualBounds)), this.Xa()) : a.isVisible() && a.actualBounds.w() && this.N(Fj(a, a.actualBounds)); this.Wb() }; t.remove = function (a) { Mj(this, a, !0) };
    function Mj(a, b, c) { var d = b.layer; null !== d && d.diagram === a && (b.isSelected = !1, b.isHighlighted = !1, b.D(2), c && b.Jj(), c = d.mc(-1, b, !1), 0 <= c && a.cb(fe, "parts", d, b, null, c, null), a = b.layerChanged, null !== a && a(b, d, null)) } t.Bs = function (a, b) { void 0 === b && (b = !1); if (Aa(a)) for (var c = a.length, d = 0; d < c; d++) { var e = a[d]; b && !e.canDelete() || this.remove(e) } else for (c = new H, c.addAll(a), a = c.iterator; a.next();)c = a.value, b && !c.canDelete() || this.remove(c) }; t.Ij = function (a, b, c) { void 0 === c && (c = !1); return this.partManager.Ij(a, b, c) };
    Q.prototype.moveParts = function (a, b, c, d) { void 0 === c && (c = !1); void 0 === d && (d = Nj(this)); if (null !== this.toolManager) { var e = new ob; if (null !== a) if (Aa(a)) for (var f = 0; f < a.length; f++)Oj(this, e, a[f], c, d); else for (a = a.iterator; a.next();)Oj(this, e, a.value, c, d); else { for (a = this.parts; a.next();)Oj(this, e, a.value, c, d); for (a = this.nodes; a.next();)Oj(this, e, a.value, c, d); for (a = this.links; a.next();)Oj(this, e, a.value, c, d) } df(this, e, b, d, c) } };
    function Oj(a, b, c, d, e, f) {
        if (!b.contains(c) && (void 0 === f && (f = !1), !d || f || c.canMove() || c.canCopy())) if (void 0 === e && (e = Nj(a)), c instanceof V) {
            b.add(c, a.Gd(e, c, c.location)); if (c instanceof pf && (null !== c.placeholder || e.dragsMembers)) for (f = c.memberParts; f.next();)Oj(a, b, f.value, d, e, e.groupsAlwaysMove); for (f = c.linksConnected; f.next();) { var g = f.value; if (!b.contains(g)) { var h = g.fromNode, k = g.toNode; null !== h && b.contains(h) && null !== k && b.contains(k) && Oj(a, b, g, d, e) } } if (e.dragsTree) for (c = c.Ku(); c.next();)Oj(a, b,
                c.value, d, e)
        } else if (c instanceof T) for (b.add(c, a.Gd(e, c)), c = c.labelNodes; c.next();)Oj(a, b, c.value, d, e); else c instanceof te || b.add(c, a.Gd(e, c, c.location))
    }
    function df(a, b, c, d, e) {
        if (null !== b && 0 !== b.count) {
            var f = I.alloc(), g = I.alloc(); g.assign(c); isNaN(g.x) && (g.x = 0); isNaN(g.y) && (g.y = 0); (c = a.Er) || Ge(a, b); for (var h = Ea(), k = Ea(), l = b.iterator, m = I.alloc(); l.next();) { var n = l.key, p = l.value; if (n.Uc()) { var r = Pj(a, n, b); if (null !== r) h.push(new Qj(n, p, r)); else if (!e || n.canMove()) r = p.point, f.assign(r), a.computeMove(n, f.add(g), d, m), n.location = m, void 0 === p.shifted && (p.shifted = new I), p.shifted.assign(m.ne(r)) } else l.key instanceof T && k.push(l.sa) } I.free(m); e = h.length; for (l =
                0; l < e; l++)n = h[l], f.assign(n.info.point), void 0 === n.Ou.shifted && (n.Ou.shifted = new I), n.node.location = f.add(n.Ou.shifted); e = I.alloc(); l = I.alloc(); n = k.length; for (p = 0; p < n; p++) {
                    var q = k[p]; r = q.key; if (r instanceof T) if (r.suspendsRouting) {
                        r.nf = null; m = r.fromNode; var u = r.toNode; if (null !== a.draggedLink && d.dragsLink) if (u = q.value.point, null === r.dragComputation) b.add(r, a.Gd(d, r, g)), bf(r, g.x - u.x, g.y - u.y); else {
                            q = I.allocAt(0, 0); (m = r.i(0)) && m.w() && q.assign(m); var w = m = I.alloc().assign(q).add(g); d.isGridSnapEnabled &&
                                (d.isGridSnapRealtime || a.lastInput.up) && (w = I.alloc(), zg(a, r, m, w, d)); m.assign(r.dragComputation(r, m, w)).ne(q); b.add(r, a.Gd(d, r, m)); bf(r, m.x - u.x, m.y - u.y); I.free(q); I.free(m); w !== m && I.free(w)
                        } else null !== m && (e.assign(m.location), w = b.I(m), null !== w && e.ne(w.point)), null !== u && (l.assign(u.location), w = b.I(u), null !== w && l.ne(w.point)), null !== m && null !== u ? e.ab(l) ? (m = q.value.point, u = f, u.assign(e), u.ne(m), b.add(r, a.Gd(d, r, e)), bf(r, u.x, u.y)) : (r.suspendsRouting = !1, r.bb()) : (q = q.value.point, m = null !== m ? e : null !== u ? l :
                            g, b.add(r, a.Gd(d, r, m)), bf(r, m.x - q.x, m.y - q.y))
                    } else if (null === r.fromNode || null === r.toNode) m = q.value.point, b.add(r, a.Gd(d, r, g)), bf(r, g.x - m.x, g.y - m.y)
                } I.free(f); I.free(g); I.free(e); I.free(l); Ga(h); Ga(k); c || (lh(a), Ue(a, b))
        }
    }
    Q.prototype.computeMove = function (a, b, c, d) {
        void 0 === d && (d = new I); d.assign(b); if (null === a) return d; var e = b, f = c.isGridSnapEnabled; f && (c.isGridSnapRealtime || this.lastInput.up) && (e = I.alloc(), zg(this, a, b, e, c)); c = null !== a.dragComputation ? a.dragComputation(a, b, e) : e; var g = a.minLocation, h = g.x; isNaN(h) && (h = f ? Math.round(1E3 * a.location.x) / 1E3 : a.location.x); g = g.y; isNaN(g) && (g = f ? Math.round(1E3 * a.location.y) / 1E3 : a.location.y); var k = a.maxLocation, l = k.x; isNaN(l) && (l = f ? Math.round(1E3 * a.location.x) / 1E3 : a.location.x);
        k = k.y; isNaN(k) && (k = f ? Math.round(1E3 * a.location.y) / 1E3 : a.location.y); d.h(Math.max(h, Math.min(c.x, l)), Math.max(g, Math.min(c.y, k))); e !== b && I.free(e); return d
    }; function Nj(a) { var b = a.toolManager.findTool("Dragging"); return null !== b ? b.dragOptions : a.Tm }
    function zg(a, b, c, d, e) { void 0 === e && (e = Nj(a)); d.assign(c); if (null !== b) { var f = a.grid; b = e.gridSnapCellSize; a = b.width; b = b.height; var g = e.gridSnapOrigin, h = g.x; g = g.y; e = e.gridSnapCellSpot; if (null !== f) { var k = f.gridCellSize; isNaN(a) && (a = k.width); isNaN(b) && (b = k.height); f = f.gridOrigin; isNaN(h) && (h = f.x); isNaN(g) && (g = f.y) } f = I.allocAt(0, 0); f.vi(0, 0, a, b, e); K.Jo(c.x, c.y, h + f.x, g + f.y, a, b, d); I.free(f) } } function Ge(a, b) { if (null !== b) for (a.Er = !0, a = b.iterator; a.next();)b = a.key, b instanceof T && (b.suspendsRouting = !0) }
    function Ue(a, b) { if (null !== b) { for (b = b.iterator; b.next();) { var c = b.key; c instanceof T && (c.suspendsRouting = !1, Rj(c) && c.bb()) } a.Er = !1 } } function Pj(a, b, c) { b = b.containingGroup; if (null !== b) { a = Pj(a, b, c); if (null !== a) return a; a = c.I(b); if (null !== a) return a } return null } t = Q.prototype; t.Gd = function (a, b, c) { if (void 0 === c) return new Je(pb); var d = a.isGridSnapEnabled; a.groupsSnapMembers || null === b.containingGroup || (d = !1); return d ? new Je(new I(Math.round(1E3 * c.x) / 1E3, Math.round(1E3 * c.y) / 1E3)) : new Je(c.copy()) };
    function Sj(a, b, c) { null !== b.diagram && b.diagram !== a && C("Cannot share a Layer with another Diagram: " + b + " of " + b.diagram); null === c ? null !== b.diagram && C("Cannot add an existing Layer to this Diagram again: " + b) : (c.diagram !== a && C("Existing Layer must be in this Diagram: " + c + " not in " + c.diagram), b === c && C("Cannot move a Layer before or after itself: " + b)); if (b.diagram !== a) { b = b.name; a = a.Wa; c = a.count; for (var d = 0; d < c; d++)a.L(d).name === b && C("Cannot add Layer with the name '" + b + "'; a Layer with the same name is already present in this Diagram.") } }
    t.wl = function (a) { Sj(this, a, null); a.Te(this); var b = this.Wa, c = b.count - 1; if (!a.isTemporary) for (; 0 <= c && b.L(c).isTemporary;)c--; b.Eb(c + 1, a); null !== this.$b && this.cb(ee, "layers", this, null, a, null, c + 1); this.N(); this.Xa(); return this };
    t.Pw = function (a, b) { Sj(this, a, b); a.Te(this); var c = this.Wa, d = c.indexOf(a); 0 <= d && (c.remove(a), null !== this.$b && this.cb(fe, "layers", this, a, null, d, null)); var e = c.count, f; for (f = 0; f < e; f++)if (c.L(f) === b) { c.Eb(f, a); break } null !== this.$b && this.cb(ee, "layers", this, null, a, null, f); this.N(); 0 > d && this.Xa(); return this };
    t.xy = function (a, b) { Sj(this, a, b); a.Te(this); var c = this.Wa, d = c.indexOf(a); 0 <= d && (c.remove(a), null !== this.$b && this.cb(fe, "layers", this, a, null, d, null)); var e = c.count, f; for (f = 0; f < e; f++)if (c.L(f) === b) { c.Eb(f + 1, a); break } null !== this.$b && this.cb(ee, "layers", this, null, a, null, f + 1); this.N(); 0 > d && this.Xa(); return this };
    t.aA = function (a) { a.diagram !== this && C("Cannot remove a Layer from another Diagram: " + a + " of " + a.diagram); if ("" !== a.name) { var b = this.Wa, c = b.indexOf(a); if (b.remove(a)) { for (b = a.Oa.copy().iterator; b.next();) { var d = b.value, e = d.layerName; e !== a.name ? d.layerName = e : d.layerName = "" } null !== this.$b && this.cb(fe, "layers", this, a, null, c, null); this.N(); this.Xa() } } }; t.Oj = function (a) { for (var b = this.layers; b.next();) { var c = b.value; if (c.name === a) return c } return null };
    t.Rw = function (a) { null === this.kf && (this.kf = new F); this.kf.add(a); this.model.Xg(a); return this }; t.cA = function (a) { null !== this.kf && (this.kf.remove(a), 0 === this.kf.count && (this.kf = null)); this.model.dk(a) }; t.Xg = function (a) { null === this.vg && (this.vg = new F); this.vg.add(a); return this }; t.dk = function (a) { null !== this.vg && (this.vg.remove(a), 0 === this.vg.count && (this.vg = null)) };
    t.Vr = function (a) { this.skipsUndoManager || this.model.skipsUndoManager || this.model.undoManager.Pu(a); a.change !== de && (this.isModified = !0); if (null !== this.vg) for (var b = this.vg, c = b.length, d = 0; d < c; d++)b.L(d)(a) }; t.cb = function (a, b, c, d, e, f, g) { void 0 === f && (f = null); void 0 === g && (g = null); var h = new be; h.diagram = this; h.change = a; h.propertyName = b; h.object = c; h.oldValue = d; h.oldParam = f; h.newValue = e; h.newParam = g; this.Vr(h) }; t.g = function (a, b, c, d, e) { this.cb(ce, a, this, b, c, d, e) };
    Q.prototype.changeState = function (a, b) {
        if (null !== a && a.diagram === this) {
            var c = this.skipsModelSourceBindings; try {
                this.skipsModelSourceBindings = !0; var d = a.change; if (d === ce) { var e = a.object; Tj(e, a.propertyName, a.I(b)); if (e instanceof Y) { var f = e.part; null !== f && f.Sb() } this.isModified = !0 } else if (d === ee) {
                    var g = a.object, h = a.newParam, k = a.newValue; if (g instanceof W) if ("number" === typeof h && k instanceof Y) { b ? g.mc(h) : g.Eb(h, k); var l = g.part; null !== l && l.Sb() } else "number" === typeof h && k instanceof Uj && (k.isRow ? Vj(g, h,
                        b ? void 0 : k) : Wj(g, h, b ? void 0 : k)); else if (g instanceof ci) { var m = !0 === a.oldParam; "number" === typeof h && k instanceof U && (b ? (k.isSelected = !1, k.isHighlighted = !1, k.Sb(), g.mc(m ? h : -1, k, m)) : g.li(h, k, m)) } else g instanceof Q ? "number" === typeof h && k instanceof ci && (b ? this.Wa.nb(h) : (k.Te(this), this.Wa.Eb(h, k))) : C("unknown ChangedEvent.Insert object: " + a.toString()); this.isModified = !0
                } else if (d === fe) {
                    var n = a.object, p = a.oldParam, r = a.oldValue; if (n instanceof W) "number" === typeof p && r instanceof Y ? b ? n.Eb(p, r) : n.mc(p) :
                        "number" === typeof p && r instanceof Uj && (r.isRow ? Vj(n, p, b ? r : void 0) : Wj(n, p, b ? r : void 0)); else if (n instanceof ci) { var q = !0 === a.newParam; "number" === typeof p && r instanceof U && (b ? 0 > n.Oa.indexOf(r) && n.li(p, r, q) : (r.isSelected = !1, r.isHighlighted = !1, r.Sb(), n.mc(q ? p : -1, r, q))) } else n instanceof Q ? "number" === typeof p && r instanceof ci && (b ? (r.Te(this), this.Wa.Eb(p, r)) : this.Wa.nb(p)) : C("unknown ChangedEvent.Remove object: " + a.toString()); this.isModified = !0
                } else d !== de && C("unknown ChangedEvent: " + a.toString())
            } finally {
                this.skipsModelSourceBindings =
                c
                }
        }
    }; Q.prototype.Ea = function (a) { return this.undoManager.Ea(a) }; Q.prototype.eb = function (a) { return this.undoManager.eb(a) }; Q.prototype.Hf = function () { return this.undoManager.Hf() }; Q.prototype.commit = function (a, b) { void 0 === b && (b = ""); var c = this.skipsUndoManager; null === b && (this.skipsUndoManager = !0, b = ""); this.undoManager.Ea(b); var d = !1; try { a(this), d = !0 } finally { d ? this.undoManager.eb(b) : this.undoManager.Hf(), this.skipsUndoManager = c } }; Q.prototype.updateAllTargetBindings = function (a) { this.partManager.updateAllTargetBindings(a) };
    Q.prototype.lp = function () { this.partManager.lp() };
    function Xj(a, b, c) {
        var d = a.animationManager; if (a.vb || a.cd) a.Aa = c, Li(a), a.N(), a.Ae = !0, d.zb && (vh(d) || d.Zd.add(d.H, "scale", b, a.Aa)); else if (null === a.xa) a.Aa = c, Li(a); else {
            a.vb = !0; var e = a.viewportBounds.copy(), f = a.Ca, g = a.Ba, h = a.zoomPoint.x, k = a.zoomPoint.y, l = a.contentAlignment; isNaN(h) && (l.Vc() ? l.Ef(Dc) ? h = 0 : l.Ef(Ec) && (h = f - 1) : h = l.tb() ? l.x * (f - 1) : f / 2); isNaN(k) && (l.Vc() ? l.Ef(Cc) ? k = 0 : l.Ef(Fc) && (k = g - 1) : k = l.tb() ? l.y * (g - 1) : g / 2); null === a.scaleComputation || a.animationManager.defaultAnimation.isAnimating || (c = a.scaleComputation(a,
                c)); c < a.minScale && (c = a.minScale); c > a.maxScale && (c = a.maxScale); f = I.allocAt(a.pa.x + h / b - h / c, a.pa.y + k / b - k / c); a.position = f; I.free(f); a.Aa = c; Li(a); a.vb = !1; a.onViewportBoundsChanged(e, a.viewportBounds, b, !1); dj(a, !1); d.zb && (vh(d) || d.Zd.add(d.H, "scale", b, a.Aa))
        }
    }
    Q.prototype.onViewportBoundsChanged = function (a, b, c, d) {
        if (!a.C(b)) {
            void 0 === d && (d = !1); d || (this.Ae = !0); this.pd = !0; var e = this.layout; null === e || !e.isViewportSized || this.autoScale !== qh || a.width === b.width && a.height === b.height || e.D(); e = this.currentTool; !0 === this.Qk && e instanceof La && (this.lastInput.documentPoint = this.Is(this.lastInput.viewPoint), xe(e, this)); this.vb || this.invalidateViewport(a, b); oj(this); this.jd.scale = c; this.jd.position.x = a.x; this.jd.position.y = a.y; this.jd.bounds.assign(a); this.jd.Ww.width =
                Math.round(a.width * c); this.jd.Ww.height = Math.round(a.height * c); this.jd.Hx.width = this.Ca; this.jd.Hx.height = this.Ba; this.jd.Ax = d; this.R("ViewportBoundsChanged", this.jd, a); this.isVirtualized && this.links.each(function (a) { a.isAvoiding && a.actualBounds.Tc(b) && a.bb() })
        }
    };
    function oj(a, b) {
        var c = a.be; if (null !== c && c.visible) {
            for (var d = L.alloc(), e = 1, f = 1, g = c.P.s, h = g.length, k = 0; k < h; k++) { var l = g[k], m = Math.abs(l.interval); 2 > m || (Yj(l.figure) ? f = f * m / K.ox(f, m) : e = e * m / K.ox(e, m)) } g = c.gridCellSize; d.h(f * g.width, e * g.height); if (void 0 !== b) e = b.width, f = b.height, a = b.x, g = b.y; else { b = N.alloc(); a = a.viewportBounds; b.h(a.x, a.y, a.width, a.height); if (!b.w()) { N.free(b); return } e = b.width; f = b.height; a = b.x; g = b.y; N.free(b) } c.width = e + 2 * d.width; c.height = f + 2 * d.height; b = I.alloc(); K.Jo(a, g, 0, 0, d.width, d.height,
                b); b.offset(-d.width, -d.height); L.free(d); c.part.location = b; I.free(b)
        }
    } Q.prototype.clearSelection = function (a) { void 0 === a && (a = !1); var b = this.selection; if (0 !== b.count) { a || this.R("ChangingSelection", b); for (var c = b.Fa(), d = c.length, e = 0; e < d; e++)c[e].isSelected = !1; b.ka(); b.clear(); b.freeze(); a || this.R("ChangedSelection", b) } };
    Q.prototype.select = function (a) { null !== a && a.diagram === this && (!a.isSelected || 1 < this.selection.count) && (this.R("ChangingSelection", this.selection), this.clearSelection(!0), a.isSelected = !0, this.R("ChangedSelection", this.selection)) };
    Q.prototype.hA = function (a) { this.R("ChangingSelection", this.selection); this.clearSelection(!0); if (Aa(a)) for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; d instanceof U || C("Diagram.selectCollection given something that is not a Part: " + d); d.isSelected = !0 } else for (a = a.iterator; a.next();)b = a.value, b instanceof U || C("Diagram.selectCollection given something that is not a Part: " + b), b.isSelected = !0; this.R("ChangedSelection", this.selection) };
    Q.prototype.clearHighlighteds = function () { var a = this.highlighteds; if (0 < a.count) { for (var b = a.Fa(), c = b.length, d = 0; d < c; d++)b[d].isHighlighted = !1; a.ka(); a.clear(); a.freeze() } }; t = Q.prototype; t.Az = function (a) { null !== a && a.diagram === this && (!a.isHighlighted || 1 < this.highlighteds.count) && (this.clearHighlighteds(), a.isHighlighted = !0) };
    t.Bz = function (a) { a = (new H).addAll(a); for (var b = this.highlighteds.copy().ap(a).iterator; b.next();)b.value.isHighlighted = !1; for (a = a.iterator; a.next();)b = a.value, b instanceof U || C("Diagram.highlightCollection given something that is not a Part: " + b), b.isHighlighted = !0 };
    t.scroll = function (a, b, c) {
        void 0 === c && (c = 1); var d = "up" === b || "down" === b, e = 0, f = I.alloc(); if ("pixel" === a) e = c; else if ("line" === a) e = c * (d ? this.scrollVerticalLineChange : this.scrollHorizontalLineChange); else if ("page" === a) a = d ? this.viewportBounds.height : this.viewportBounds.width, a *= this.scale, 0 !== a && (e = c * Math.max(a - (d ? this.scrollVerticalLineChange : this.scrollHorizontalLineChange), 0)); else {
            if ("document" === a) {
                e = this.documentBounds; c = this.viewportBounds; "up" === b ? this.position = f.h(c.x, e.y) : "left" === b ? this.position =
                    f.h(e.x, c.y) : "down" === b ? this.position = f.h(c.x, e.bottom - c.height) : "right" === b && (this.position = f.h(e.right - c.width, c.y)); I.free(f); return
            } C("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: " + a)
        } e /= this.scale; c = this.position; "up" === b ? this.position = f.h(c.x, c.y - e) : "down" === b ? this.position = f.h(c.x, c.y + e) : "left" === b ? this.position = f.h(c.x - e, c.y) : "right" === b ? this.position = f.h(c.x + e, c.y) : C("scrolling direction must be 'up', 'down', 'left', or 'right', not: " + b); I.free(f)
    };
    t.ov = function (a) { var b = this.viewportBounds; b.Me(a) || (a = a.center, a.x -= b.width / 2, a.y -= b.height / 2, this.position = a) }; t.Wr = function (a) { var b = this.viewportBounds; a = a.center; a.x -= b.width / 2; a.y -= b.height / 2; this.position = a }; t.ip = function (a) { var b = this.Ab; b.reset(); 1 !== this.Aa && b.scale(this.Aa); var c = this.pa; (0 !== c.x || 0 !== c.y) && isFinite(c.x) && isFinite(c.y) && b.translate(-c.x, -c.y); return a.copy().transform(this.Ab) };
    t.pA = function (a) { var b = this.Ab, c = a.x, d = a.y, e = c + a.width, f = d + a.height, g = b.m11, h = b.m12, k = b.m21, l = b.m22, m = b.dx, n = b.dy, p = c * g + d * k + m; b = c * h + d * l + n; var r = e * g + d * k + m; a = e * h + d * l + n; d = c * g + f * k + m; c = c * h + f * l + n; g = e * g + f * k + m; e = e * h + f * l + n; f = Math.min(p, r); p = Math.max(p, r); r = Math.min(b, a); b = Math.max(b, a); f = Math.min(f, d); p = Math.max(p, d); r = Math.min(r, c); b = Math.max(b, c); f = Math.min(f, g); p = Math.max(p, g); r = Math.min(r, e); b = Math.max(b, e); return new N(f, r, p - f, b - r) };
    t.Is = function (a) { var b = this.Ab; b.reset(); 1 !== this.Aa && b.scale(this.Aa); var c = this.pa; (0 !== c.x || 0 !== c.y) && isFinite(c.x) && isFinite(c.y) && b.translate(-c.x, -c.y); return xb(a.copy(), this.Ab) }; function Zj(a) { var b = a.isModified; a.Mw !== b && (a.Mw = b, a.R("Modified")) } function ak(a) { a = mi.get(a); return null !== a ? new a : new ni }
    Q.prototype.doModelChanged = function (a) {
        var b = this; if (a.model === this.model) {
            var c = a.change, d = a.propertyName; if (c === de && "S" === d[0]) if ("StartingFirstTransaction" === d) { var e = this; a = this.toolManager; a.mouseDownTools.each(function (a) { return a.diagram = e }); a.mouseMoveTools.each(function (a) { return a.diagram = e }); a.mouseUpTools.each(function (a) { return a.diagram = e }); this.cd || this.df || (this.Ek = !0, this.bn && (this.gg = !0)) } else "StartingUndo" === d || "StartingRedo" === d ? (a = this.animationManager, a.defaultAnimation.isAnimating &&
                !this.skipsUndoManager && a.zc(), this.R("ChangingSelection", this.selection)) : "StartedTransaction" === d && (a = this.animationManager, a.defaultAnimation.isAnimating && !this.skipsUndoManager && a.zc()); else if (this.aa) {
                    this.aa = !1; try {
                        if ("" === a.modelChange && c === de) {
                            if ("FinishedUndo" === d || "FinishedRedo" === d) this.R("ChangedSelection", this.selection), lh(this); var f = this.animationManager; "RolledBackTransaction" === d && f.zc(); this.Xw(); this.Ek = !0; this.Wc(); 0 !== this.undoManager.transactionLevel && 1 !== this.undoManager.transactionLevel ||
                                mh(f); "CommittedTransaction" === d && this.undoManager.isJustDiscarded && (this.ye = Math.min(this.ye, this.undoManager.historyIndex - 1)); "CommittedTransaction" !== d && "RolledBackTransaction" !== d || !this.undoManager.isPendingUnmodified || setTimeout(function () { b.isModified = !1 }, 1); var g = a.isTransactionFinished; g && (Zj(this), this.ts.clear(), Jh(this.animationManager)); if (!this.Mt && g && this.lastInput.targetDiagram) { this.Mt = !0; var h = this; ta(function () { h.currentTool.standardMouseOver(); h.Mt = !1 }, 10) }
                        }
                    } finally { this.aa = !0 }
                }
        }
    };
    function Hj(a, b) { b = b.P.s; for (var c = b.length, d = 0; d < c; d++)bk(a, b[d]) } function bk(a, b) { if (b instanceof ck) { var c = b.element; if (null !== c && c instanceof HTMLImageElement) { c.xg instanceof H && c.xg.add(a); var d = b.Ag; null !== d && !0 === d.lq && null !== b.diagram && b.diagram.Tn.add(b); if (c.dn) if (c = c.getAttribute("src") || "", d = a.Jg.I(c), null === d) d = [], d.push(b), a.Jg.add(c, d); else { for (a = 0; a < d.length; a++)if (d[a] === b) return; d.push(b) } } } }
    function dk(a, b) { if (b instanceof ck) { var c = b.element; if (null !== c && c instanceof HTMLImageElement && c.dn) { var d = c.getAttribute("src") || "", e = a.Jg.I(d); if (null !== e) for (var f = 0; f < e.length; f++)if (e[f] === b) { e.splice(f, 1); 0 === e.length && (a.Jg.remove(d), c.yq && c.removeEventListener("load", c.yq), c.Rp && c.removeEventListener("error", c.Rp), delete c.yq, delete c.Rp, c.xg instanceof H && c.xg.remove(a), Ki(d)); break } } } } Q.prototype.Rd = function () { this.partManager.Rd() }; Q.prototype.yj = function (a, b) { this.Td.yj(a, b) };
    Q.prototype.Aj = function (a, b) { this.Td.Aj(a, b) }; Q.prototype.findPartForKey = function (a) { return this.partManager.findPartForKey(a) }; Q.prototype.Rb = function (a) { return this.partManager.Rb(a) }; Q.prototype.findLinkForKey = function (a) { return this.partManager.findLinkForKey(a) }; t = Q.prototype; t.Ic = function (a) { return this.partManager.Ic(a) }; t.fi = function (a) { return this.partManager.fi(a) }; t.Hc = function (a) { return this.partManager.Hc(a) };
    t.es = function (a) { for (var b = [], c = 0; c < arguments.length; ++c)b[c] = arguments[c]; return this.partManager.es.apply(this.partManager, b instanceof Array ? b : da(ba(b))) }; t.ds = function (a) { for (var b = [], c = 0; c < arguments.length; ++c)b[c] = arguments[c]; return this.partManager.ds.apply(this.partManager, b instanceof Array ? b : da(ba(b))) }; function Li(a) { var b = a.pa, c = a.Aa; a.Kw.h(b.x, b.y, Math.max(a.Ca, 0) / c, Math.max(a.Ba, 0) / c); a.pd = !0 }
    function Dj(a, b) { a.Wi = !1; var c = a.Mp; c.C(b) || (c = c.copy(), a.Mp.assign(b), dj(a, !1), a.R("DocumentBoundsChanged", void 0, c), a.Ae = !0, a.N()) } t.Va = function () { if (this.Wi) { var a = N.alloc(); Dj(this, this.computeBounds(a)); N.free(a) } }; t.jz = function () { for (var a = new H, b = this.nodes; b.next();) { var c = b.value; c.isTopLevel && a.add(c) } for (b = this.links; b.next();)c = b.value, c.isTopLevel && a.add(c); return a.iterator }; t.iz = function () { return this.Yh.iterator };
    t.Lz = function (a) { lh(this); a && ek(this, !0); this.cd ? pj(this, !1) : (this.Ek = !0, yf(this)) }; function ek(a, b) { for (var c = a.Yh.iterator; c.next();)fk(a, c.value, b); a.layout && (b ? a.layout.isValidLayout = !1 : a.layout.D()) } function fk(a, b, c) { if (null !== b) { for (var d = b.bl.iterator; d.next();)fk(a, d.value, c); null !== b.layout && (c ? b.layout.isValidLayout = !1 : b.layout.D()) } }
    function pj(a, b) {
        if (a.sh && !a.dt) {
            var c = a.aa; a.aa = !0; var d = a.undoManager.transactionLevel, e = a.layout, f = a.animationManager; try { 0 === d && (a.undoManager.isInternalTransaction = !0, a.Ea("Layout")); 1 >= d && !f.defaultAnimation.isAnimating && !f.zb && (b || kh(f, "Layout")); a.sh = !1; for (var g = a.Yh.iterator; g.next();)gk(a, g.value, b, d); e.isValidLayout || (!b || e.isRealtime || null === e.isRealtime || 0 === d ? (e.doLayout(a), lh(a), e.isValidLayout = !0) : a.sh = !0) } finally {
                b = a.undoManager.isInternalTransaction, 0 === d && (a.eb("Layout"), a.undoManager.isInternalTransaction =
                    !1), !b || 0 !== d && 1 !== d || mh(f), a.aa = c
            }
        }
    } function gk(a, b, c, d) { if (null !== b) { for (var e = b.bl.iterator; e.next();)gk(a, e.value, c, d); e = b.layout; null === e || e.isValidLayout || (!c || e.isRealtime || 0 === d ? (b.mh = !b.location.w(), e.doLayout(b), b.D(32), rj(a, b), e.isValidLayout = !0) : a.sh = !0) } } t.pz = function () { for (var a = new F, b = this.nodes; b.next();) { var c = b.value; c.isTopLevel && null === c.fh() && a.add(c) } return a.iterator };
    function vi(a) {
        function b(a) { var b = a.toLowerCase(), e = new F; c.add(a, e); c.add(b, e); d.add(a, a); d.add(b, a) } var c = new ob, d = new ob; b("InitialAnimationStarting"); b("AnimationStarting"); b("AnimationFinished"); b("BackgroundSingleClicked"); b("BackgroundDoubleClicked"); b("BackgroundContextClicked"); b("ClipboardChanged"); b("ClipboardPasted"); b("DocumentBoundsChanged"); b("ExternalObjectsDropped"); b("GainedFocus"); b("InitialLayoutCompleted"); b("LayoutCompleted"); b("LinkDrawn"); b("LinkRelinked"); b("LinkReshaped");
        b("LostFocus"); b("Modified"); b("ObjectSingleClicked"); b("ObjectDoubleClicked"); b("ObjectContextClicked"); b("PartCreated"); b("PartResized"); b("PartRotated"); b("SelectionMoved"); b("SelectionCopied"); b("SelectionDeleting"); b("SelectionDeleted"); b("SelectionGrouped"); b("SelectionUngrouped"); b("ChangingSelection"); b("ChangedSelection"); b("SubGraphCollapsed"); b("SubGraphExpanded"); b("TextEdited"); b("TreeCollapsed"); b("TreeExpanded"); b("ViewportBoundsChanged"); b("InvalidateDraw"); a.bw = c; a.aw = d
    }
    function Cj(a, b) { var c = a.aw.I(b); return null !== c ? c : a.aw.I(b.toLowerCase()) } function hk(a, b) { var c = a.bw.I(b); if (null !== c) return c; c = a.bw.I(b.toLowerCase()); if (null !== c) return c; C("Unknown DiagramEvent name: " + b) } t.zj = function (a, b) { a = hk(this, a); null !== a && a.add(b); return this }; t.Yl = function (a, b) { a = hk(this, a); null !== a && a.remove(b) };
    t.R = function (a, b, c) { var d = hk(this, a), e = new ae; e.diagram = this; a = Cj(this, a); null !== a && (e.name = a); void 0 !== b && (e.subject = b); void 0 !== c && (e.parameter = c); b = d.length; if (1 === b) d.L(0)(e); else if (0 !== b) for (d = d.Fa(), c = 0; c < b; c++)(0, d[c])(e) }; function ik(a) { if (a.animationManager.isTicking) return !1; var b = a.currentTool; return b === a.toolManager.findTool("Dragging") ? !a.Er || b.isComplexRoutingRealtime : !0 } t.Vj = function (a, b) { return jk(this, !1, null, b || null).Vj(a.x, a.y, a.width, a.height) };
    Q.prototype.computeOccupiedArea = function (a) { return this.isVirtualized ? this.viewportBounds.copy() : this.Wi ? cj(this, a) : a.assign(this.documentBounds) };
    function jk(a, b, c, d) {
        var e = a.Th; null === e && (a.Th = e = new kk(a.avoidanceCellSize)); if (e.os || e.group !== c || e.Ux !== d) {
            e.Bx = Math.max(e.Hs + 1, Math.floor(a.avoidanceLimit / a.avoidanceCellSize.width)); e.Cx = Math.max(e.Hs + 1, Math.floor(a.avoidanceLimit / a.avoidanceCellSize.height)); null !== c && (c = tk(a, c)); if (null === c) { b = N.alloc(); a.computeOccupiedArea(b); b.yc(100, 100); e.initialize(b); for (var f = a.nodes; f.next();) { var g = f.value, h = g.layer; null !== h && h.visible && !h.isTemporary && xk(a, g, d, b) } N.free(b) } else {
                0 < c.memberParts.count &&
                (b = a.computePartsBounds(c.memberParts, !1), b.yc(20, 20), e.initialize(b)); b = N.alloc(); for (f = c.memberParts; f.next();)g = f.value, g instanceof V && xk(a, g, d, b); N.free(b)
            } e.group = c; e.Ux = d; e.os = !1
        } else b && yk(e); return e
    } function tk(a, b) { return b.avoidable && !b.isLinkLabel ? b : null !== b.containingGroup ? tk(a, b.containingGroup) : null }
    function xk(a, b, c, d) { if (b !== c && b.isVisible()) if (b.avoidable && !b.isLinkLabel) { b = b.getAvoidableRect(d); a = a.Th; var e = b.x, f = b.y; if (zk(a, e, f)) for (c = (f - a.Fd) / a.pc | 0, d = Math.min((e + b.width - a.Ed) / a.oc | 0, a.ai), b = Math.min((f + b.height - a.Fd) / a.pc | 0, a.bi), e = (e - a.Ed) / a.oc | 0; e <= d; e++)if (f = a.Yb[e], f.fill) f.fill(Ak, c, b + 1); else for (var g = c; g <= b; g++)f[g] = Ak } else if (b instanceof pf) for (b = b.memberParts; b.next();)e = b.value, e instanceof V && xk(a, e, c, d) }
    function Bk(a, b) { null === a.Th || a.Th.os || void 0 !== b && (!b.avoidable || b.isLinkLabel) || (a.Th.os = !0) } Q.prototype.Zr = function (a) { this.pp.assign(a); this.computeAutoScrollPosition(this.pp).ab(this.position) ? this.qg() : Ck(this) };
    function Ck(a) { -1 === a.qk && (a.qk = ta(function () { if (-1 !== a.qk && (a.qg(), null !== a.lastInput.event)) { var b = a.computeAutoScrollPosition(a.pp); b.ab(a.position) || (a.position = b, a.lastInput.documentPoint = a.Is(a.pp), a.doMouseMove(), a.Wi = !0, b = N.alloc(), a.computeBounds(b), b.Zc(a.documentBounds), Dj(a, b), N.free(b), a.Cc = !0, a.Wc(), Ck(a)) } }, a.op)) } Q.prototype.qg = function () { -1 !== this.qk && (x.clearTimeout(this.qk), this.qk = -1) };
    Q.prototype.computeAutoScrollPosition = function (a) {
        var b = this.position, c = this.qp; if (0 >= c.top && 0 >= c.left && 0 >= c.right && 0 >= c.bottom) return b; var d = this.viewportBounds, e = this.scale; d = N.allocAt(0, 0, d.width * e, d.height * e); var f = I.allocAt(0, 0); if (a.x >= d.x && a.x < d.x + c.left && this.allowHorizontalScroll) { var g = Math.max(this.scrollHorizontalLineChange, 1); g |= 0; f.x -= g; a.x < d.x + c.left / 2 && (f.x -= g); a.x < d.x + c.left / 4 && (f.x -= 4 * g) } else a.x <= d.x + d.width && a.x > d.x + d.width - c.right && this.allowHorizontalScroll && (g = Math.max(this.scrollHorizontalLineChange,
            1), g |= 0, f.x += g, a.x > d.x + d.width - c.right / 2 && (f.x += g), a.x > d.x + d.width - c.right / 4 && (f.x += 4 * g)); a.y >= d.y && a.y < d.y + c.top && this.allowVerticalScroll ? (g = Math.max(this.scrollVerticalLineChange, 1), g |= 0, f.y -= g, a.y < d.y + c.top / 2 && (f.y -= g), a.y < d.y + c.top / 4 && (f.y -= 4 * g)) : a.y <= d.y + d.height && a.y > d.y + d.height - c.bottom && this.allowVerticalScroll && (g = Math.max(this.scrollVerticalLineChange, 1), g |= 0, f.y += g, a.y > d.y + d.height - c.bottom / 2 && (f.y += g), a.y > d.y + d.height - c.bottom / 4 && (f.y += 4 * g)); f.ab(pb) || (b = new I(b.x + f.x / e, b.y + f.y / e));
        N.free(d); I.free(f); return b
    }; t = Q.prototype; t.vs = function () { return null }; t.Xu = function () { return null }; t.Tr = function (a, b) { this.nj.add(a, b) }; t.zu = function (a) { (this.Iw = a) || this.nodes.each(function (a) { a instanceof pf && (a.Tp = null) }) };
    function Dk(a, b, c) { function d() { var a = +new Date; f = !0; for (g.reset(); g.next();)if (!g.value[0].Wk) { f = !1; break } f || a - l > k ? b(c, e, h) : x.requestAnimationFrame(d) } for (var e = c.callback, f = !0, g = a.Jg.iterator; g.next();)if (!g.value[0].Wk) { f = !1; break } if ("function" !== typeof e || f) return b(c, e, a); var h = a, k = c.callbackTimeout || 300, l = +new Date; x.requestAnimationFrame(function () { d() }); return null } t.Nz = function (a) { if (!Ng) return null; void 0 === a && (a = {}); a.returnType = "Image"; return this.Ex(a) };
    t.Ex = function (a) { void 0 === a && (a = {}); return Dk(this, this.Oz, a) };
    t.Oz = function (a, b, c) {
        var d = Ek(c, a, "canvas", null); if (null === d) return null; c = d.Z.canvas; var e = null; if (null !== c) switch (e = a.returnType, void 0 === e ? e = "string" : e = e.toLowerCase(), e) {
            case Xi + "data": e = d.getImageData(0, 0, c.width, c.height); break; case Xi: d = (a.document || document).createElement("img"); d.src = c.toDataURL(a.type, a.details); e = d; break; case "blob": "function" !== typeof b && C('Error: Diagram.makeImageData called with "returnType: toBlob", but no required "callback" function property defined.'); if ("function" ===
                typeof c.toBlob) return c.toBlob(b, a.type, a.details), "toBlob"; b(null); return null; default: e = c.toDataURL(a.type, a.details)
        }return "function" === typeof b ? (b(e), null) : e
    };
    function Ek(a, b, c, d) {
        a.animationManager.zc(); a.Wc(); if (null === a.xa) return null; "object" !== typeof b && C("properties argument must be an Object."); var e = b.size || null, f = b.scale || null; void 0 !== b.scale && isNaN(b.scale) && (f = "NaN"); var g = b.maxSize; void 0 === b.maxSize && (g = "SVG" === c ? new L(Infinity, Infinity) : new L(2E3, 2E3)); var h = b.position || null, k = b.parts || null, l = void 0 === b.padding ? 1 : b.padding, m = b.background || null, n = b.omitTemporary; void 0 === n && (n = !0); var p = b.document || document, r = b.elementFinished || null, q = b.showTemporary;
        void 0 === q && (q = !n); b = b.showGrid; void 0 === b && (b = q); null !== e && isNaN(e.width) && isNaN(e.height) && (e = null); "number" === typeof l ? l = new hc(l) : l instanceof hc || C("MakeImage padding must be a Margin or a number."); l.left = Math.max(l.left, 0); l.right = Math.max(l.right, 0); l.top = Math.max(l.top, 0); l.bottom = Math.max(l.bottom, 0); a.jb.Qc(!0); n = new Ni(null, p, void 0, !0); var u = n.context; if (!(e || f || k || h)) {
            n.width = a.Ca + Math.ceil(l.left + l.right); n.height = a.Ba + Math.ceil(l.top + l.bottom); if ("SVG" === c) {
                if (null === d) return null;
                d.resize(n.width, n.height, n.width, n.height); d.ownerDocument = p; d.$r = r; zj(a, d.context, l, new L(n.width, n.height), a.Aa, a.pa, k, m, q, b); return d.context
            } a.Um = !1; zj(a, u, l, new L(n.width, n.height), a.Aa, a.pa, k, m, q, b); a.Um = !0; return n.context
        } var w = a.Jp, v = a.documentBounds.copy(); v.yv(a.yb); if (q) for (var z = a.Wa.s, y = z.length, A = 0; A < y; A++) { var B = z[A]; if (B.visible && B.isTemporary) { B = B.Oa.s; for (var E = B.length, M = 0; M < E; M++) { var R = B[M]; R.isInDocumentBounds && R.isVisible() && (R = R.actualBounds, R.w() && v.Zc(R)) } } } z = new I(v.x,
            v.y); if (null !== k) { y = new N(0, 0, 0, 0); A = !0; B = k.iterator; for (B.reset(); B.next();)E = B.value, E instanceof U && (M = E.layer, null !== M && !M.visible || null !== M && !q && M.isTemporary || !E.isVisible() || (E = E.actualBounds, E.w() && (A ? (A = !1, y.assign(E)) : y.Zc(E)))); v.width = y.width; v.height = y.height; z.x = y.x; z.y = y.y } null !== h && h.w() && (z = h, f || (f = w)); y = h = 0; null !== l && (h = l.left + l.right, y = l.top + l.bottom); A = B = 0; null !== e && (B = e.width, A = e.height, isFinite(B) && (B = Math.max(0, B - h)), isFinite(A) && (A = Math.max(0, A - y))); null !== e && null !== f ? ("NaN" ===
                f && (f = w), e.w() ? (e = B, v = A) : isNaN(A) ? (e = B, v = v.height * f) : (e = v.width * f, v = A)) : null !== e ? e.w() ? (f = Math.min(B / v.width, A / v.height), e = B, v = A) : isNaN(A) ? (f = B / v.width, e = B, v = v.height * f) : (f = A / v.height, e = v.width * f, v = A) : null !== f ? "NaN" === f && g.w() ? (f = Math.min((g.width - h) / v.width, (g.height - y) / v.height), f > w ? (f = w, e = v.width, v = v.height) : (e = g.width, v = g.height)) : (e = v.width * f, v = v.height * f) : (f = w, e = v.width, v = v.height); null !== l ? (e += h, v += y) : l = new hc(0); null !== g && (w = g.width, g = g.height, isNaN(w) && (w = 2E3), isNaN(g) && (g = 2E3), isFinite(w) &&
                    (e = Math.min(e, w)), isFinite(g) && (v = Math.min(v, g))); n.width = Math.ceil(e); n.height = Math.ceil(v); if ("SVG" === c) { if (null === d) return null; d.resize(n.width, n.height, n.width, n.height); d.ownerDocument = p; d.$r = r; zj(a, d.context, l, new L(Math.ceil(e), Math.ceil(v)), f, z, k, m, q, b); return d.context } a.Um = !1; zj(a, u, l, new L(Math.ceil(e), Math.ceil(v)), f, z, k, m, q, b); a.Um = !0; return n.context
    }
    ma.Object.defineProperties(Q.prototype, {
        renderer: {
            get: function () { return this.il }, set: function (a) {
                this instanceof Fk || (a = a.toLowerCase(), a !== this.il && (this.il = a, "default" === a ? (a = this.nj.get("svg"), null !== a && a.La.remove(), this.xa && (this.jb = this.xa.context)) : "svg" === a ? (this.nj.contains("svg") ? a = this.nj.get("svg") : (a = new Ej(this, x.document), this.Tr("svg", a), null !== this.div && this.div.appendChild(a.La)), this.jb = a.context, this.xa && (this.xa.context.setTransform(this.Pb, 0, 0, this.Pb,
                    0, 0), this.xa.context.clearRect(0, 0, this.Ca, this.Ba))) : "debug" !== a || this.nj.contains("SVG") || (a = new Ej(this, x.document), this.Tr("SVG", a), a.La.style.backgroundColor = "whitesmoke", null !== this.div && this.div.after(a.La)), this.Xl()))
            }
        }, div: {
            get: function () { return this.Ka }, set: function (a) {
                if (this.Ka !== a) {
                    var b = this.Ka; null !== b ? (b.H = void 0, b.goDiagram = void 0, b.go = void 0, b.innerHTML = "", null !== this.xa && (b = this.xa.La, this.removeEventListener(b, "pointermove", this.$j, !1), this.removeEventListener(b,
                        "pointerdown", this.ri, !1), this.removeEventListener(b, "pointerup", this.bk, !1), this.removeEventListener(b, "pointerout", this.ak, !1), this.removeEventListener(b, "pointercancel", this.Zj, !1), this.xa.gx()), this.jl && (this.jl.disconnect(), this.jl = null), b = this.toolManager, null !== b && (b.mouseDownTools.each(function (a) { a.cancelWaitAfter() }), b.mouseMoveTools.each(function (a) { a.cancelWaitAfter() }), b.mouseUpTools.each(function (a) { a.cancelWaitAfter() })), b.cancelWaitAfter(), this.currentTool.doCancel(), this.xa = null,
                        this.removeEventListener(x, "resize", this.Hv, !1), this.removeEventListener(x, "wheel", this.Xj, !0), ke === this && (ke = null)) : null === this.ri && (this.df = !1); this.Ka = null; if (null !== a) { if (b = a.H) b.div = null; ti(this, a); this.redraw() }
                }
            }
        }, Tw: { get: function () { return this.Lq } }, mi: { get: function () { return this.df } }, draggedLink: {
            get: function () { return this.ft }, set: function (a) {
                this.ft !== a && (this.ft = a, null !== a && (this.Qt = a.fromPort, this.Rt =
                    a.toPort))
            }
        }, Ix: { get: function () { return this.Qt }, set: function (a) { this.Qt = a } }, Jx: { get: function () { return this.Rt }, set: function (a) { this.Rt = a } }, animationManager: { get: function () { return this.Td } }, undoManager: { get: function () { return this.$b.undoManager } }, skipsUndoManager: { get: function () { return this.Qg }, set: function (a) { this.Qg = a; this.$b.skipsUndoManager = a } }, delaysLayout: {
            get: function () { return this.dt }, set: function (a) { this.dt = a }
        }, opacity: { get: function () { return this.qb }, set: function (a) { var b = this.qb; b !== a && ((0 > a || 1 < a) && xa(a, "0 <= value <= 1", Q, "opacity"), this.qb = a, this.g("opacity", b, a), this.N()) } }, validCycle: { get: function () { return this.ru }, set: function (a) { var b = this.ru; b !== a && (this.ru = a, this.g("validCycle", b, a)) } }, layers: { get: function () { return this.Wa.iterator } },
        isModelReadOnly: { get: function () { var a = this.$b; return null === a ? !1 : a.isReadOnly }, set: function (a) { var b = this.$b; null !== b && (b.isReadOnly = a) } }, isReadOnly: { get: function () { return this.Cg }, set: function (a) { var b = this.Cg; b !== a && (this.Cg = a, this.g("isReadOnly", b, a)) } }, isEnabled: { get: function () { return this.Ad }, set: function (a) { var b = this.Ad; b !== a && (this.Ad = a, this.g("isEnabled", b, a)) } }, allowClipboard: {
            get: function () { return this.Ns }, set: function (a) { var b = this.Ns; b !== a && (this.Ns = a, this.g("allowClipboard", b, a)) }
        }, allowCopy: { get: function () { return this.Ci }, set: function (a) { var b = this.Ci; b !== a && (this.Ci = a, this.g("allowCopy", b, a)) } }, allowDelete: { get: function () { return this.Di }, set: function (a) { var b = this.Di; b !== a && (this.Di = a, this.g("allowDelete", b, a)) } }, allowDragOut: {
            get: function () { return this.Os }, set: function (a) {
                var b =
                    this.Os; b !== a && (this.Os = a, this.g("allowDragOut", b, a))
            }
        }, allowDrop: { get: function () { return this.Ps }, set: function (a) { var b = this.Ps; b !== a && (this.Ps = a, this.g("allowDrop", b, a)) } }, allowTextEdit: { get: function () { return this.Mi }, set: function (a) { var b = this.Mi; b !== a && (this.Mi = a, this.g("allowTextEdit", b, a)) } }, allowGroup: {
            get: function () { return this.Ei }, set: function (a) {
                var b = this.Ei; b !== a && (this.Ei = a, this.g("allowGroup", b,
                    a))
            }
        }, allowUngroup: { get: function () { return this.Ni }, set: function (a) { var b = this.Ni; b !== a && (this.Ni = a, this.g("allowUngroup", b, a)) } }, allowInsert: { get: function () { return this.Rs }, set: function (a) { var b = this.Rs; b !== a && (this.Rs = a, this.g("allowInsert", b, a)) } }, allowLink: { get: function () { return this.Fi }, set: function (a) { var b = this.Fi; b !== a && (this.Fi = a, this.g("allowLink", b, a)) } }, allowRelink: {
            get: function () { return this.Hi },
            set: function (a) { var b = this.Hi; b !== a && (this.Hi = a, this.g("allowRelink", b, a)) }
        }, allowMove: { get: function () { return this.Gi }, set: function (a) { var b = this.Gi; b !== a && (this.Gi = a, this.g("allowMove", b, a)) } }, allowReshape: { get: function () { return this.Ii }, set: function (a) { var b = this.Ii; b !== a && (this.Ii = a, this.g("allowReshape", b, a)) } }, allowResize: {
            get: function () { return this.Ji }, set: function (a) {
                var b = this.Ji; b !== a && (this.Ji = a, this.g("allowResize",
                    b, a))
            }
        }, allowRotate: { get: function () { return this.Ki }, set: function (a) { var b = this.Ki; b !== a && (this.Ki = a, this.g("allowRotate", b, a)) } }, allowSelect: { get: function () { return this.Li }, set: function (a) { var b = this.Li; b !== a && (this.Li = a, this.g("allowSelect", b, a)) } }, allowUndo: { get: function () { return this.Ss }, set: function (a) { var b = this.Ss; b !== a && (this.Ss = a, this.g("allowUndo", b, a)) } }, allowZoom: {
            get: function () { return this.Us },
            set: function (a) { var b = this.Us; b !== a && (this.Us = a, this.g("allowZoom", b, a)) }
        }, hasVerticalScrollbar: { get: function () { return this.jn }, set: function (a) { var b = this.jn; b !== a && (this.jn = a, this.Ae = !0, this.N(), this.g("hasVerticalScrollbar", b, a), dj(this, !1)) } }, hasHorizontalScrollbar: { get: function () { return this.hn }, set: function (a) { var b = this.hn; b !== a && (this.hn = a, this.Ae = !0, this.N(), this.g("hasHorizontalScrollbar", b, a), dj(this, !1)) } }, allowHorizontalScroll: {
            get: function () { return this.Qs }, set: function (a) { var b = this.Qs; b !== a && (this.Qs = a, this.g("allowHorizontalScroll", b, a), dj(this, !1)) }
        }, allowVerticalScroll: { get: function () { return this.Ts }, set: function (a) { var b = this.Ts; b !== a && (this.Ts = a, this.g("allowVerticalScroll", b, a), dj(this, !1)) } }, scrollHorizontalLineChange: {
            get: function () { return this.du }, set: function (a) {
                var b = this.du; b !== a && (0 > a && xa(a, ">= 0", Q, "scrollHorizontalLineChange"), this.du =
                    a, this.g("scrollHorizontalLineChange", b, a))
            }
        }, scrollVerticalLineChange: { get: function () { return this.hu }, set: function (a) { var b = this.hu; b !== a && (0 > a && xa(a, ">= 0", Q, "scrollVerticalLineChange"), this.hu = a, this.g("scrollVerticalLineChange", b, a)) } }, lastInput: { get: function () { return this.$i }, set: function (a) { this.$i = a } }, previousInput: { get: function () { return this.Lg }, set: function (a) { this.Lg = a } }, firstInput: {
            get: function () { return this.Vp }, set: function (a) { this.Vp = a }
        }, currentCursor: {
            get: function () { return this.at }, set: function (a) {
                "" === a && (a = this.Ip); if (this.at !== a) {
                    var b = this.xa, c = this.Ka; if (null !== b && null !== c) {
                        this.at = a; var d = b.style.cursor; b.style.cursor = a; c.style.cursor = a; b.style.cursor === d && (b.style.cursor = "-webkit-" + a, c.style.cursor = "-webkit-" + a, b.style.cursor === d && (b.style.cursor = "-moz-" + a, c.style.cursor = "-moz-" + a, b.style.cursor === d && (b.style.cursor = a, c.style.cursor =
                            a)))
                    }
                }
            }
        }, defaultCursor: { get: function () { return this.Ip }, set: function (a) { "" === a && (a = "auto"); var b = this.Ip; b !== a && (this.Ip = a, this.g("defaultCursor", b, a)) } }, click: { get: function () { return this.uh }, set: function (a) { var b = this.uh; b !== a && (this.uh = a, this.g("click", b, a)) } }, doubleClick: { get: function () { return this.Ah }, set: function (a) { var b = this.Ah; b !== a && (this.Ah = a, this.g("doubleClick", b, a)) } }, contextClick: {
            get: function () { return this.wh }, set: function (a) { var b = this.wh; b !== a && (this.wh = a, this.g("contextClick", b, a)) }
        }, mouseOver: { get: function () { return this.Mh }, set: function (a) { var b = this.Mh; b !== a && (this.Mh = a, this.g("mouseOver", b, a)) } }, mouseHover: { get: function () { return this.Kh }, set: function (a) { var b = this.Kh; b !== a && (this.Kh = a, this.g("mouseHover", b, a)) } }, mouseHold: {
            get: function () { return this.Jh }, set: function (a) {
                var b =
                    this.Jh; b !== a && (this.Jh = a, this.g("mouseHold", b, a))
            }
        }, mouseDragOver: { get: function () { return this.Lt }, set: function (a) { var b = this.Lt; b !== a && (this.Lt = a, this.g("mouseDragOver", b, a)) } }, mouseDrop: { get: function () { return this.Hh }, set: function (a) { var b = this.Hh; b !== a && (this.Hh = a, this.g("mouseDrop", b, a)) } }, handlesDragDropForTopLevelParts: {
            get: function () { return this.qt }, set: function (a) {
                var b = this.qt; b !== a && (this.qt = a, this.g("handlesDragDropForTopLevelParts",
                    b, a))
            }
        }, mouseEnter: { get: function () { return this.Ih }, set: function (a) { var b = this.Ih; b !== a && (this.Ih = a, this.g("mouseEnter", b, a)) } }, mouseLeave: { get: function () { return this.Lh }, set: function (a) { var b = this.Lh; b !== a && (this.Lh = a, this.g("mouseLeave", b, a)) } }, toolTip: { get: function () { return this.Xh }, set: function (a) { var b = this.Xh; b !== a && (this.Xh = a, this.g("toolTip", b, a)) } }, contextMenu: {
            get: function () { return this.xh },
            set: function (a) { var b = this.xh; b !== a && (this.xh = a, this.g("contextMenu", b, a)) }
        }, commandHandler: { get: function () { return this.ob }, set: function (a) { this.ob !== a && (this.ob = a, a.Te(this)) } }, toolManager: { get: function () { return this.pe }, set: function (a) { this.pe !== a && (this.pe = a, a.diagram = this) } }, defaultTool: {
            get: function () { return this.pb }, set: function (a) {
                var b = this.pb; b !== a && (this.pb = a, a.diagram = this, this.currentTool === b && (this.currentTool =
                    a))
            }
        }, currentTool: { get: function () { return this.hb }, set: function (a) { var b = this.hb; b && (b.isActive && b.doDeactivate(), b.cancelWaitAfter(), b.doStop()); null === a && (a = this.defaultTool); null !== a && (this.hb = a, a.diagram = this, a.doStart()) } }, selection: { get: function () { return this.Aw } }, maxSelectionCount: {
            get: function () { return this.Gt }, set: function (a) {
                var b = this.Gt; if (b !== a) if (0 <= a && !isNaN(a)) {
                    if (this.Gt = a, this.g("maxSelectionCount",
                        b, a), !this.undoManager.isUndoingRedoing && (a = this.selection.count - a, 0 < a)) { this.R("ChangingSelection", this.selection); b = this.selection.Fa(); for (var c = 0; c < a; c++)b[c].isSelected = !1; this.R("ChangedSelection", this.selection) }
                } else xa(a, ">= 0", Q, "maxSelectionCount")
            }
        }, nodeSelectionAdornmentTemplate: { get: function () { return this.Nt }, set: function (a) { var b = this.Nt; b !== a && (this.Nt = a, this.g("nodeSelectionAdornmentTemplate", b, a)) } }, groupSelectionAdornmentTemplate: {
            get: function () { return this.pt }, set: function (a) { var b = this.pt; b !== a && (this.pt = a, this.g("groupSelectionAdornmentTemplate", b, a)) }
        }, linkSelectionAdornmentTemplate: { get: function () { return this.Dt }, set: function (a) { var b = this.Dt; b !== a && (this.Dt = a, this.g("linkSelectionAdornmentTemplate", b, a)) } }, highlighteds: { get: function () { return this.gw } }, isModified: {
            get: function () {
                var a = this.undoManager; return a.isEnabled ? null !== a.currentTransaction ?
                    !0 : this.nn && this.ye !== a.historyIndex : this.nn
            }, set: function (a) { if (this.nn !== a) { this.nn = a; var b = this.undoManager; !a && b.isEnabled && (this.ye = b.historyIndex); a || Zj(this) } }
        }, model: {
            get: function () { return this.$b }, set: function (a) {
                var b = this.$b; if (b !== a) {
                    this.currentTool.doCancel(); b && b.undoManager !== a.undoManager && b.undoManager.isInTransaction && C("Do not replace a Diagram.model while a transaction is in progress."); this.animationManager.zc(!0); var c = Ji(this, !0); this.df = !1; this.bn =
                        !0; this.ye = -2; this.gg = !1; var d = this.vb; this.vb = !0; kh(this.animationManager, "Model"); b && (null !== this.kf && this.kf.each(function (a) { return b.dk(a) }), b.dk(this.Lc)); this.$b = a; this.partManager = ak(this.$b.type); for (var e = 0; e < c.length; e++)this.add(c[e]); a.Xg(this.Fb); this.partManager.addAllModeledParts(); a.dk(this.Fb); a.Xg(this.Lc); null !== this.kf && this.kf.each(function (b) { return a.Xg(b) }); this.vb = d; this.Xa(); this.vb || this.N(); b && a.undoManager.copyProperties(b.undoManager)
                }
            }
        }, aa: {
            get: function () { return this.kw }, set: function (a) { this.kw = a }
        }, ts: { get: function () { return this.ny } }, skipsModelSourceBindings: { get: function () { return this.Bw }, set: function (a) { this.Bw = a } }, Gs: { get: function () { return this.ju }, set: function (a) { this.ju = a } }, nodeTemplate: {
            get: function () { return this.Gg.I("") }, set: function (a) {
                var b = this.Gg.I(""); b !== a && (this.Gg.add("", a), this.g("nodeTemplate", b, a),
                    this.undoManager.isUndoingRedoing || this.Rd())
            }
        }, nodeTemplateMap: { get: function () { return this.Gg }, set: function (a) { var b = this.Gg; b !== a && (this.Gg = a, this.g("nodeTemplateMap", b, a), this.undoManager.isUndoingRedoing || this.Rd()) } }, groupTemplate: { get: function () { return this.Vi.I("") }, set: function (a) { var b = this.Vi.I(""); b !== a && (this.Vi.add("", a), this.g("groupTemplate", b, a), this.undoManager.isUndoingRedoing || this.Rd()) } }, groupTemplateMap: {
            get: function () { return this.Vi }, set: function (a) { var b = this.Vi; b !== a && (this.Vi = a, this.g("groupTemplateMap", b, a), this.undoManager.isUndoingRedoing || this.Rd()) }
        }, linkTemplate: { get: function () { return this.Gh.I("") }, set: function (a) { var b = this.Gh.I(""); b !== a && (this.Gh.add("", a), this.g("linkTemplate", b, a), this.undoManager.isUndoingRedoing || this.Rd()) } }, linkTemplateMap: {
            get: function () { return this.Gh }, set: function (a) {
                var b = this.Gh; b !==
                    a && (this.Gh = a, this.g("linkTemplateMap", b, a), this.undoManager.isUndoingRedoing || this.Rd())
            }
        }, isMouseCaptured: {
            get: function () { return this.jw }, set: function (a) {
                var b = this.xa; null !== b && (b = b.La, b instanceof SVGElement || (a ? (this.lastInput.bubbles = !1, this.removeEventListener(b, "pointermove", this.$j, !1), this.removeEventListener(b, "pointerdown", this.ri, !1), this.removeEventListener(b, "pointerup", this.bk, !1), this.removeEventListener(b, "pointerout", this.ak, !1), this.removeEventListener(b,
                    "pointercancel", this.Zj, !1), this.addEventListener(x, "pointermove", this.$j, !0), this.addEventListener(x, "pointerdown", this.ri, !0), this.addEventListener(x, "pointerup", this.bk, !0), this.addEventListener(x, "pointerout", this.ak, !0), this.addEventListener(x, "pointercancel", this.Zj, !0), this.removeEventListener(b, "wheel", this.Xj, !1), this.addEventListener(x, "wheel", this.Xj, !0), this.addEventListener(x, "selectstart", this.preventDefault, !1)) : (this.removeEventListener(x, "pointermove", this.$j, !0), this.removeEventListener(x,
                        "pointerdown", this.ri, !0), this.removeEventListener(x, "pointerup", this.bk, !0), this.removeEventListener(x, "pointerout", this.ak, !0), this.removeEventListener(x, "pointercancel", this.Zj, !0), this.addEventListener(b, "pointermove", this.$j, !1), this.addEventListener(b, "pointerdown", this.ri, !1), this.addEventListener(b, "pointerup", this.bk, !1), this.addEventListener(b, "pointerout", this.ak, !1), this.addEventListener(b, "pointercancel", this.Zj, !1), this.removeEventListener(x, "wheel", this.Xj, !0), this.removeEventListener(x,
                            "selectstart", this.preventDefault, !1), this.addEventListener(b, "wheel", this.Xj, !1)), this.jw = a))
            }
        }, position: {
            get: function () { return this.pa }, set: function (a) {
                var b = I.alloc().assign(this.pa); if (!b.C(a)) {
                    var c = this.viewportBounds.copy(); this.pa.assign(a); Mh(this.animationManager, b, this.pa); this.vb || null === this.xa && !this.so.w() ? Li(this) : (this.vb = !0, a = this.scale, fj(this, this.Mp, this.Ca / a, this.Ba / a, this.uk, !1), this.vb = !1); if (!this.vb) this.onViewportBoundsChanged(c, this.viewportBounds,
                        this.Aa, !1)
                } I.free(b)
            }
        }, initialPosition: { get: function () { return this.st }, set: function (a) { this.st.C(a) || (this.st = a.J()) } }, initialScale: { get: function () { return this.tt }, set: function (a) { this.tt !== a && (this.tt = a) } }, grid: {
            get: function () { null === this.be && (this.be = aj(this)); return this.be }, set: function (a) {
                var b = this.be; if (b !== a) {
                    null === b && (b = this.be = aj(this)); a.type !== W.Grid && C("Diagram.grid must be a Panel of type Panel.Grid");
                    var c = b.panel; null !== c && c.remove(b); this.be = a; a.name = "GRID"; null !== c && c.add(a); oj(this); this.N(); this.g("grid", b, a)
                }
            }
        }, viewportBounds: { get: function () { return this.Kw } }, viewSize: {
            get: function () { return this.so }, set: function (a) {
                var b = this.viewSize; b.C(a) || (this.so = a = a.J(), this.Ba = this.Ca = 0, a.w() ? (this.Ca = a.width, this.Ba = a.height) : null !== this.Ka && (this.Ca = this.Ka.clientWidth || 1, this.Ba = this.Ka.clientHeight || 1), Li(this), this.Xa(), this.g("viewSize",
                    b, a), this.redraw())
            }
        }, fixedBounds: { get: function () { return this.nt }, set: function (a) { var b = this.nt; b.C(a) || (-Infinity !== a.width && Infinity !== a.height && -Infinity !== a.height || C("fixedBounds width/height must not be Infinity"), this.nt = a = a.J(), this.Xa(), this.g("fixedBounds", b, a)) } }, scrollMargin: { get: function () { return this.pj }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.pj; b.C(a) || (this.pj = a = a.J(), this.g("scrollMargin", b, a), this.Xl()) } },
        scrollMode: { get: function () { return this.qj }, set: function (a) { var b = this.qj; b !== a && (this.qj = a, a === Qh && dj(this, !1), this.g("scrollMode", b, a), this.Ae = !0, this.N()) } }, scrollsPageOnFocus: { get: function () { return this.iu }, set: function (a) { var b = this.iu; b !== a && (this.iu = a, this.g("scrollsPageOnFocus", b, a)) } }, positionComputation: {
            get: function () { return this.Ut }, set: function (a) {
                var b = this.Ut; b !== a && (this.Ut = a, dj(this, !1), this.g("positionComputation",
                    b, a))
            }
        }, scaleComputation: { get: function () { return this.au }, set: function (a) { var b = this.au; b !== a && (this.au = a, Xj(this, this.scale, this.scale), this.g("scaleComputation", b, a)) } }, documentBounds: { get: function () { return this.Mp } }, isVirtualized: { get: function () { return this.Bt }, set: function (a) { var b = this.Bt; b !== a && (this.Bt = a, this.g("isVirtualized", b, a)) } }, scale: {
            get: function () { return this.Aa },
            set: function (a) { var b = this.Aa; b !== a && Xj(this, b, a) }
        }, defaultScale: { get: function () { return this.Jp }, set: function (a) { this.Jp = a } }, autoScale: { get: function () { return this.th }, set: function (a) { var b = this.th; b !== a && (this.th = a, this.g("autoScale", b, a), a !== qh && dj(this, !1)) } }, initialAutoScale: { get: function () { return this.Bg }, set: function (a) { var b = this.Bg; b !== a && (this.Bg = a, this.g("initialAutoScale", b, a)) } }, initialViewportSpot: {
            get: function () { return this.ut }, set: function (a) { var b = this.ut; b !== a && (a.tb() || C("initialViewportSpot must be a specific Spot: " + a), this.ut = a, this.g("initialViewportSpot", b, a)) }
        }, initialDocumentSpot: { get: function () { return this.rt }, set: function (a) { var b = this.rt; b !== a && (a.tb() || C("initialViewportSpot must be a specific Spot: " + a), this.rt = a, this.g("initialDocumentSpot", b, a)) } }, minScale: {
            get: function () { return this.Jt }, set: function (a) {
                var b =
                    this.Jt; b !== a && (0 < a ? (this.Jt = a, this.g("minScale", b, a), a > this.scale && (this.scale = a)) : xa(a, "> 0", Q, "minScale"))
            }
        }, maxScale: { get: function () { return this.Ft }, set: function (a) { var b = this.Ft; b !== a && (0 < a ? (this.Ft = a, this.g("maxScale", b, a), a < this.scale && (this.scale = a)) : xa(a, "> 0", Q, "maxScale")) } }, zoomPoint: { get: function () { return this.uu }, set: function (a) { this.uu.C(a) || (this.uu = a = a.J()) } }, contentAlignment: {
            get: function () { return this.uk },
            set: function (a) { var b = this.uk; b.C(a) || (this.uk = a = a.J(), this.g("contentAlignment", b, a), dj(this, !1)) }
        }, initialContentAlignment: { get: function () { return this.iq }, set: function (a) { var b = this.iq; b.C(a) || (this.iq = a = a.J(), this.g("initialContentAlignment", b, a)) } }, padding: { get: function () { return this.yb }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.yb; b.C(a) || (this.yb = a = a.J(), this.Xa(), this.g("padding", b, a)) } }, partManager: {
            get: function () { return this.Mc }, set: function (a) { var b = this.Mc; b !== a && (null !== a.diagram && C("Cannot share PartManagers between Diagrams: " + a.toString()), b && b.Te(null), this.Mc = a, a.Te(this)) }
        }, nodes: { get: function () { return this.partManager.nodes.iterator } }, links: { get: function () { return this.partManager.links.iterator } }, parts: { get: function () { return this.partManager.parts.iterator } }, layout: {
            get: function () { return this.Oc }, set: function (a) { var b = this.Oc; b !== a && (this.Oc = a, a.diagram = this, a.group = null, this.sh = !0, this.g("layout", b, a), this.Wb()) }
        }, isTreePathToChildren: { get: function () { return this.At }, set: function (a) { var b = this.At; if (b !== a && (this.At = a, this.g("isTreePathToChildren", b, a), !this.undoManager.isUndoingRedoing)) for (a = this.nodes; a.next();)Gk(a.value) } }, treeCollapsePolicy: {
            get: function () { return this.pu }, set: function (a) {
                var b =
                    this.pu; b !== a && (a !== wi && a !== Hk && a !== Ik && C("Unknown Diagram.treeCollapsePolicy: " + a), this.pu = a, this.g("treeCollapsePolicy", b, a))
            }
        }, Pe: { get: function () { return this.iw }, set: function (a) { this.iw = a } }, avoidanceCellSize: { get: function () { return this.Uv }, set: function (a) { this.Th = null; this.Uv = new L(Math.max(1, a.width), Math.max(1, a.height)) } }, avoidanceLimit: {
            get: function () { return this.Vv }, set: function (a) {
                this.Vv = Math.max(1,
                    a)
            }
        }, autoScrollInterval: { get: function () { return this.op }, set: function (a) { var b = this.op; b !== a && (this.op = a, this.g("autoScrollInterval", b, a)) } }, autoScrollRegion: { get: function () { return this.qp }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.qp; b.C(a) || (this.qp = a = a.J(), this.Xa(), this.g("autoScrollRegion", b, a)) } }
    });
    ma.Object.defineProperties(Q, { licenseKey: { get: function () { return Jk.fc() }, set: function (a) { Jk.add(a.replace(/\s/g, "")) } }, version: { get: function () { return Kk } } }); Q.prototype.makeImageData = Q.prototype.Ex; Q.prototype.makeImage = Q.prototype.Nz; Q.prototype.cacheGroupExternalLinks = Q.prototype.zu; Q.prototype.addRenderer = Q.prototype.Tr; Q.prototype.makeSVG = Q.prototype.Xu; Q.prototype.makeSvg = Q.prototype.vs; Q.prototype.stopAutoScroll = Q.prototype.qg;
    Q.prototype.doAutoScroll = Q.prototype.Zr; Q.prototype.isUnoccupied = Q.prototype.Vj; Q.prototype.raiseDiagramEvent = Q.prototype.R; Q.prototype.removeDiagramListener = Q.prototype.Yl; Q.prototype.addDiagramListener = Q.prototype.zj; Q.prototype.findTreeRoots = Q.prototype.pz; Q.prototype.layoutDiagram = Q.prototype.Lz; Q.prototype.findTopLevelGroups = Q.prototype.iz; Q.prototype.findTopLevelNodesAndLinks = Q.prototype.jz; Q.prototype.ensureBounds = Q.prototype.Va; Q.prototype.findLinksByExample = Q.prototype.ds;
    Q.prototype.findNodesByExample = Q.prototype.es; Q.prototype.findLinkForData = Q.prototype.Hc; Q.prototype.findNodeForData = Q.prototype.fi; Q.prototype.findPartForData = Q.prototype.Ic; Q.prototype.findLinkForKey = Q.prototype.findLinkForKey; Q.prototype.findNodeForKey = Q.prototype.Rb; Q.prototype.findPartForKey = Q.prototype.findPartForKey; Q.prototype.rebuildParts = Q.prototype.Rd; Q.prototype.transformViewToDoc = Q.prototype.Is; Q.prototype.transformRectDocToView = Q.prototype.pA; Q.prototype.transformDocToView = Q.prototype.ip;
    Q.prototype.centerRect = Q.prototype.Wr; Q.prototype.scrollToRect = Q.prototype.ov; Q.prototype.scroll = Q.prototype.scroll; Q.prototype.highlightCollection = Q.prototype.Bz; Q.prototype.highlight = Q.prototype.Az; Q.prototype.selectCollection = Q.prototype.hA; Q.prototype.select = Q.prototype.select; Q.prototype.updateAllRelationshipsFromData = Q.prototype.lp; Q.prototype.updateAllTargetBindings = Q.prototype.updateAllTargetBindings; Q.prototype.commit = Q.prototype.commit; Q.prototype.rollbackTransaction = Q.prototype.Hf;
    Q.prototype.commitTransaction = Q.prototype.eb; Q.prototype.startTransaction = Q.prototype.Ea; Q.prototype.raiseChanged = Q.prototype.g; Q.prototype.raiseChangedEvent = Q.prototype.cb; Q.prototype.removeChangedListener = Q.prototype.dk; Q.prototype.addChangedListener = Q.prototype.Xg; Q.prototype.removeModelChangedListener = Q.prototype.cA; Q.prototype.addModelChangedListener = Q.prototype.Rw; Q.prototype.findLayer = Q.prototype.Oj; Q.prototype.removeLayer = Q.prototype.aA; Q.prototype.addLayerAfter = Q.prototype.xy;
    Q.prototype.addLayerBefore = Q.prototype.Pw; Q.prototype.addLayer = Q.prototype.wl; Q.prototype.moveParts = Q.prototype.moveParts; Q.prototype.copyParts = Q.prototype.Ij; Q.prototype.removeParts = Q.prototype.Bs; Q.prototype.remove = Q.prototype.remove; Q.prototype.add = Q.prototype.add; Q.prototype.clearDelayedGeometries = Q.prototype.Xw; Q.prototype.setProperties = Q.prototype.km; Q.prototype.attach = Q.prototype.yu; Q.prototype.set = Q.prototype.set; Q.prototype.resetInputOptions = Q.prototype.Ox; Q.prototype.setInputOption = Q.prototype.iA;
    Q.prototype.getInputOption = Q.prototype.Ll; Q.prototype.resetRenderingHints = Q.prototype.Px; Q.prototype.setRenderingHint = Q.prototype.Tx; Q.prototype.getRenderingHint = Q.prototype.Df; Q.prototype.maybeUpdate = Q.prototype.Wc; Q.prototype.requestUpdate = Q.prototype.Wb; Q.prototype.delayInitialization = Q.prototype.Py; Q.prototype.isUpdateRequested = Q.prototype.Gz; Q.prototype.invalidateDocumentBounds = Q.prototype.Xa; Q.prototype.findObjectsNear = Q.prototype.kg; Q.prototype.findPartsNear = Q.prototype.ez;
    Q.prototype.findObjectsIn = Q.prototype.zf; Q.prototype.findPartsIn = Q.prototype.nx; Q.prototype.findObjectsAt = Q.prototype.gi; Q.prototype.findPartsAt = Q.prototype.dz; Q.prototype.findObjectAt = Q.prototype.ec; Q.prototype.findPartAt = Q.prototype.Jl; Q.prototype.focusObject = Q.prototype.qz; Q.prototype.alignDocument = Q.prototype.Cy; Q.prototype.zoomToRect = Q.prototype.rA; Q.prototype.zoomToFit = Q.prototype.zoomToFit; Q.prototype.diagramScroll = Q.prototype.ex; Q.prototype.focus = Q.prototype.focus;
    var mi = new ob, Mi = null, Ng = void 0 !== x.document, Hi = null, Ii = "", qh = new D(Q, "None", 0), gj = new D(Q, "Uniform", 1), hj = new D(Q, "UniformToFill", 2), Df = new D(Q, "CycleAll", 10), Hf = new D(Q, "CycleNotDirected", 11), Qf = new D(Q, "CycleNotDirectedFast", 12), Rf = new D(Q, "CycleNotUndirected", 13), Ef = new D(Q, "CycleDestinationTree", 14), Gf = new D(Q, "CycleSourceTree", 15), Qh = new D(Q, "DocumentScroll", 1), Sh = new D(Q, "InfiniteScroll", 2), wi = new D(Q, "TreeParentCollapsed", 21), Hk = new D(Q, "AllParentsCollapsed", 22), Ik = new D(Q, "AnyParentsCollapsed",
        23), Jk = new F, Kk = "2.3.3", Xi = "image", Zi = null, ki = !1;
    function li() {
        if (Ng) {
            var a = x.document.createElement("canvas"), b = a.getContext("2d"), c = Ma("7ca11abfd022028846"); b[c] = Ma("398c3597c01238"); for (var d = ["5da73c80a36455d7038e4972187c3cae51fd22", ra.Dx + "4ae6247590da4bb21c324ba3a84e385776", ld.xF + "fb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387", K.za], e = 1; 5 > e; e++)b[Ma("7ca11abfd7330390")](Ma(d[e - 1]), 10, 15 * e); b[c] = Ma("39f046ebb36e4b"); for (c = 1; 5 > c; c++)b[Ma("7ca11abfd7330390")](Ma(d[c - 1]), 10, 15 * c); if (4 !== d.length || "5" !== d[0][0] || "7" !== d[3][0]) ld.prototype.Ld = ld.prototype.Uw;
            Zi = a
        }
    } Q.className = "Diagram"; Q.fromDiv = function (a) { var b = a; "string" === typeof a && (b = x.document.getElementById(a)); return b instanceof HTMLDivElement && b.H instanceof Q ? b.H : null }; Q.inherit = function (a, b) { function c() { } if (Object.getPrototypeOf(a).prototype) throw Error("Used go.Diagram.inherit defining already defined class \n" + a); c.prototype = b.prototype; a.prototype = new c; a.prototype.constructor = a }; Q.useDOM = function (a) { Ng = a ? void 0 !== x.document : !1 }; Q.isUsingDOM = function () { return Ng }; Q.None = qh; Q.Uniform = gj;
    Q.UniformToFill = hj; Q.CycleAll = Df; Q.CycleNotDirected = Hf; Q.CycleNotDirectedFast = Qf; Q.CycleNotUndirected = Rf; Q.CycleDestinationTree = Ef; Q.CycleSourceTree = Gf; Q.DocumentScroll = Qh; Q.InfiniteScroll = Sh; Q.TreeParentCollapsed = wi; Q.AllParentsCollapsed = Hk; Q.AnyParentsCollapsed = Ik; function si() { this.sy = null; this.j = "zz@orderNum"; "63ad05bbe23a1786468a4c741b6d2" === this._tk ? this.nd = this.j = !0 : this.nd = null }
    function yj(a, b) {
        b.jb.setTransform(b.Pb, 0, 0, b.Pb, 0, 0); b.jb.vc(); if (null === a.nd) a: {
            b = "f"; var c = x[Ma("76a715b2f73f148a")][Ma("72ba13b5")]; a.nd = !0; if (Ng) {
                var d = Q[Ma("76a115b6ed251eaf4692")]; if (d) for (var e = Jk.iterator; e.next();) {
                    d = e.value; d = Ma(d).split(Ma("39e9")); if (6 > d.length) break; var f = Ma(d[1]).split("."); if ("7da71ca0" !== d[4]) break; var g = Ma(ra[Ma("6cae19")]).split("."); if (f[0] > g[0] || f[0] === g[0] && f[1] >= g[1]) {
                        var h = x[Ma("74a900bae4370f8b51")][Ma("6fbb13a1c2311e8a57")].toLowerCase(); g = h[Ma("73a612b6fb191d")](Ma("3aad1ab6e022098b4dc4"));
                        f = Ma(d[2]); if (-1 < g && (h = h[Ma("73a612b6fb191d")](f.toLowerCase() + "/"), a.nd = !(-1 < h && h < g), !a.nd)) break; g = c[Ma("76ad18b4f73e")]; for (h = c[Ma("73a612b6fb191d")](Ma("35e7")) + 2; h < g; h++)b += c[h]; g = b[Ma("73a612b6fb191d")](f); 0 > g && f !== Ma("7da71ca0ad381e90") && (g = b[Ma("73a612b6fb191d")](Ma("76a715b2ef3e149757"))); 0 > g && (g = b[Ma("73a612b6fb191d")](Ma("76a715b2ef3e149757"))); 0 > g && (g = c[Ma("73a612b6fb191d")](Ma("7baa19a6f76c1988428554"))); a.nd = !(0 <= g && g < b[Ma("73a612b6fb191d")](Ma("35")) || -1 === b[Ma("73a612b6fb191d")](Ma("35")));
                        if (!a.nd) break; if (c[Ma("73a612b6fb191d")](Ma("7fb002b6ed25128b4da25b2e")) && c[Ma("73a612b6fb191d")](Ma("75ba1beee63a1e875799507d")) && (a.nd = !(-1 < c[Ma("73a612b6fb191d")](f)), !a.nd)) break; if ("#" === f[0]) {
                            g = x.document.createElement("div"); for (var k = d[0].replace(/[A-Za-z]/g, ""); 4 > k.length;)k += "9"; h = k; k = k.substr(k.length - 4); d = ""; d += ["gsh", "gsf"][parseInt(k.substr(0, 1), 10) % 2]; d += ["Header", "Background", "Display", "Feedback"][parseInt(k.substr(0, 1), 10) % 4]; g[Ma("79a417a0f0181a8946")] = d; if (x.document[Ma("78a712aa")]) {
                                x.document[Ma("78a712aa")][Ma("7bb806b6ed32388c4a875b")](g);
                                k = x.getComputedStyle(g).getPropertyValue(Ma("78a704b7e62456904c9b12701b6532a8")); var l = x.getComputedStyle(g).getPropertyValue(Ma("75b817b0ea2202")); x.document[Ma("78a712aa")][Ma("68ad1bbcf533388c4a875b")](g); if (k) if (g = x[Ma("77a902b0eb1b1e804a8a")], l === "0." + parseInt(h, 10) % 100 || -1 !== k.indexOf(parseInt(f[1] + f[2], 16)) && -1 !== k.indexOf(parseInt(f[3] + f[4], 16))) { a.nd = !1; break } else if (g && g(Ma("32ae19a1e0331fc94084537c067a67fa59f1386b89d006")).matches) for (d = "." + d, f = 0; f < document.styleSheets.length; f++) {
                                    g = document.styleSheets[f].rules ||
                                    document.styleSheets[f].cssRules; for (var m in g) if (d === g[m].selectorText) { a.nd = !1; break a }
                                }
                            } else a.nd = null, a.nd = !1
                        }
                    }
                } else { m = c[Ma("76ad18b4f73e")]; for (e = c[Ma("73a612b6fb191d")](Ma("35e7")) + 2; e < m; e++)b += c[e]; c = b[Ma("73a612b6fb191d")](Ma("7da71ca0ad381e90")); a.nd = !(0 <= c && c < b[Ma("73a612b6fb191d")](Ma("35"))) }
            }
        } return 0 < a.nd && a !== a.sy ? !0 : !1
    } si.className = "DiagramHelper"; function Je(a) { this.j = void 0 === a ? new I : a; this.m = new I }
    ma.Object.defineProperties(Je.prototype, { point: { get: function () { return this.j }, set: function (a) { this.j = a } }, shifted: { get: function () { return this.m }, set: function (a) { this.m = a } } }); var Wi = "DraggingInfo"; Je.className = "DraggingInfo"; function Qj(a, b, c) { this.node = a; this.info = b; this.Ou = c } Qj.className = "DraggingNodeInfoPair"; function Be() { this.reset() }
    Be.prototype.reset = function () { this.isGridSnapEnabled = !1; this.isGridSnapRealtime = !0; this.gridSnapCellSize = (new L(NaN, NaN)).freeze(); this.gridSnapCellSpot = sc; this.gridSnapOrigin = (new I(NaN, NaN)).freeze(); this.groupsSnapMembers = this.dragsTree = this.dragsLink = !1; this.dragsMembers = this.groupsAlwaysMove = !0 }; Be.className = "DraggingOptions"; function Lk(a, b) { if ("string" === typeof a || x.Element && a instanceof Element) var c = Q.call(this, a) || this; else c = Q.call(this) || this, b = a; Mk(c); b && c.km(b); return c } la(Lk, Q);
    function Mk(a) { a.allowDragOut = !0; a.allowMove = !1; a.isReadOnly = !0; a.contentAlignment = tc; a.layout = new Nk } Lk.prototype.reset = function () { Q.prototype.reset.call(this); Mk(this) }; Lk.className = "Palette";
    function Fk(a, b) {
        if ("string" === typeof a || x.Element && a instanceof Element) var c = Q.call(this, a) || this; else c = Q.call(this) || this, b = a; var d = c; c.animationManager.isEnabled = !1; c.vb = !0; c.V = null; c.hn = !1; c.jn = !1; c.F = !0; c.u = !0; c.ga = 0; c.m = !1; c.ml = null; c.Tx("drawShadows", !1); a = new U; var e = new Bf; e.stroke = "magenta"; e.strokeWidth = 2; e.fill = "transparent"; e.name = "BOXSHAPE"; a.selectable = !0; a.selectionAdorned = !1; a.selectionObjectName = "BOXSHAPE"; a.locationObjectName = "BOXSHAPE"; a.resizeObjectName = "BOXSHAPE"; a.cursor =
            "move"; a.add(e); c.j = a; c.allowCopy = !1; c.allowDelete = !1; c.allowSelect = !0; c.autoScrollRegion = new hc(0, 0, 0, 0); c.pa.h(0, 0); c.toolManager.gb("Dragging", new Ok, c.toolManager.mouseMoveTools); c.click = function () { var a = d.observed; if (null !== a) { var b = a.viewportBounds, c = d.lastInput.documentPoint; a.position = new I(c.x - b.width / 2, c.y - b.height / 2) } }; c.Mf = function () { d.Xa(); Pk(d) }; c.Sd = function () { null !== d.observed && (d.Xa(), d.N()) }; c.Ha = function () {
                1 > d.updateDelay ? d.N() : d.m || (d.m = !0, setTimeout(function () { return d.redraw() },
                    d.updateDelay))
            }; c.Lf = function () { null !== d.observed && Pk(d) }; c.autoScale = gj; c.vb = !1; b && Object.assign(c, b); return c
    } la(Fk, Q); Fk.prototype.computePixelRatio = function () { return 1 };
    Fk.prototype.redraw = function () { if (this.m && 1 <= this.updateDelay) { this.m = !1; var a = this.xa, b = this.jb; if (null !== a && null !== b) { vj(this); if (null === this.ml) { var c = new Ni(null); c.width = a.width; c.height = a.height; this.ml = c } try { this.xa = this.ml, this.jb = this.xa.context, this.jb.Qc(!0), this.jb.setTransform(1, 0, 0, 1, 0, 0), this.jb.clearRect(0, 0, this.xa.width, this.xa.height), Qk(this) } finally { this.xa = a, this.jb = b } } } Q.prototype.redraw.call(this) };
    Fk.prototype.cc = function () {
        null === this.Ka && C("No div specified"); null === this.xa && C("No canvas specified"); if (!(this.xa instanceof Ej) && (ei(this.box), this.Cc)) {
            var a = this.observed; if (null !== a && !a.animationManager.defaultAnimation.isAnimating && a.mi) {
                vj(this); var b = this.xa; a = this.jb; a.Qc(!0); a.setTransform(1, 0, 0, 1, 0, 0); a.clearRect(0, 0, b.width, b.height); 1 > this.updateDelay ? Qk(this) : null !== this.ml && (a.drawImage(this.ml.La, 0, 0), b = this.Ab, b.reset(), 1 !== this.scale && b.scale(this.scale), 0 === this.position.x &&
                    0 === this.position.y || b.translate(-this.position.x, -this.position.y), a.scale(this.Pb, this.Pb), a.transform(b.m11, b.m12, b.m21, b.m22, b.dx, b.dy), a.vc()); b = this.Wa.s; for (var c = b.length, d = 0; d < c; d++)b[d].cc(a, this); this.Cc = this.pd = !1
            }
        }
    };
    function Qk(a) {
        var b = a.observed; if (null !== b) {
            var c = a.drawsTemporaryLayers, d = a.drawsGrid && c, e = b.grid; d && null !== e && e.visible && !isNaN(e.width) && !isNaN(e.height) && (e = N.alloc().assign(a.viewportBounds).Zc(b.viewportBounds), oj(b, e), N.free(e), lh(b)); var f = a.Ab; f.reset(); 1 !== a.scale && f.scale(a.scale); 0 === a.position.x && 0 === a.position.y || f.translate(-a.position.x, -a.position.y); e = a.jb; e.scale(a.Pb, a.Pb); e.transform(f.m11, f.m12, f.m21, f.m22, f.dx, f.dy); e.vc(); b = b.Wa.s; f = b.length; for (var g = 0; g < f; g++) {
                var h = b[g],
                k = a; if (h.visible && 0 !== h.opacity) { var l = h.diagram.grid.part; if (!c && h.isTemporary) d && l.layer === h && (h = fi(h, e), l.cc(e, k), e.globalAlpha = h); else { for (var m = fi(h, e), n = k.scale, p = N.alloc(), r = h.Oa.s, q = r.length, u = 0; u < q; u++) { var w = r[u]; (d || w !== l) && h.Mj(e, w, k, null, n, p, !1) } N.free(p); e.globalAlpha = m } }
            }
        }
    }
    function Pk(a) { var b = a.box; if (null !== b) { var c = a.observed; if (null !== c) { a.Cc = !0; c = c.viewportBounds; var d = b.selectionObject, e = L.alloc(); e.h(c.width, c.height); d.desiredSize = e; L.free(e); a = 2 / a.scale; d instanceof Bf && (d.strokeWidth = a); b.location = new I(c.x - a / 2, c.y - a / 2); b.isSelected = !0 } } } Fk.prototype.computeBounds = function () { var a = this.observed; if (null === a) return bc; var b = a.documentBounds.copy(); b.Zc(a.viewportBounds); return b }; Fk.prototype.invalidateViewport = function () { !0 !== this.Cc && (this.Cc = !0, this.Wb()) };
    Fk.prototype.onViewportBoundsChanged = function (a, b, c, d) { this.vb || (this.pd = !0, this.N(), this.Ae = !0, this.Xa(), Pk(this), this.jd.scale = c, this.jd.position.x = a.x, this.jd.position.y = a.y, this.jd.bounds.assign(a), this.jd.Ax = d, this.R("ViewportBoundsChanged", this.jd, a)) };
    ma.Object.defineProperties(Fk.prototype, {
        observed: {
            get: function () { return this.V }, set: function (a) {
                var b = this.V; a instanceof Fk && C("Overview.observed Diagram may not be an Overview itself: " + a); if (b !== a) {
                    null !== b && (this.remove(this.box), b.Yl("ViewportBoundsChanged", this.Mf), b.Yl("DocumentBoundsChanged", this.Sd), b.Yl("InvalidateDraw", this.Ha), b.Yl("AnimationFinished", this.Lf)); this.V = a; null !== a && (a.zj("ViewportBoundsChanged", this.Mf), a.zj("DocumentBoundsChanged", this.Sd),
                        a.zj("InvalidateDraw", this.Ha), a.zj("AnimationFinished", this.Lf), this.add(this.box)); this.Xa(); if (null === a) { this.ml = null; var c = this.xa, d = this.jb; c && d && (d.setTransform(1, 0, 0, 1, 0, 0), d.clearRect(0, 0, c.width, c.height)) } else this.Ha(null), Pk(this), this.N(); this.g("observed", b, a)
                }
            }
        }, box: { get: function () { return this.j }, set: function (a) { var b = this.j; b !== a && (this.j = a, this.remove(b), this.add(this.j), Pk(this), this.g("box", b, a)) } }, drawsTemporaryLayers: {
            get: function () { return this.F }, set: function (a) { this.F !== a && (this.F = a, this.redraw()) }
        }, drawsGrid: { get: function () { return this.u }, set: function (a) { this.u !== a && (this.u = a, this.redraw()) } }, updateDelay: { get: function () { return this.ga }, set: function (a) { 0 > a && (a = 0); this.ga !== a && (this.ga = a) } }
    }); Fk.className = "Overview"; function Ok() { Ae.call(this); this.j = null } la(Ok, Ae);
    Ok.prototype.canStart = function () { if (!this.isEnabled) return !1; var a = this.diagram; if (null === a || !a.allowMove || !a.allowSelect) return !1; var b = a.observed; if (null === b) return !1; var c = a.lastInput; if (!c.left || a.currentTool !== this && (!this.isBeyondDragSize() || c.isTouchEvent && c.timestamp - a.firstInput.timestamp < this.delay)) return !1; null === this.findDraggablePart() && (c = b.viewportBounds, this.j = new I(c.width / 2, c.height / 2), a = a.firstInput.documentPoint, b.position = new I(a.x - this.j.x, a.y - this.j.y)); return !0 };
    Ok.prototype.doActivate = function () { this.j = null; Ae.prototype.doActivate.call(this) }; Ok.prototype.doUpdateCursor = function () { var a = this.diagram, b = a.box; null !== b && b.cursor && (a.currentCursor = b.cursor) }; Ok.prototype.moveParts = function () { var a = this.diagram, b = a.observed; if (null !== b) { var c = a.box; if (null !== c) { if (null === this.j) { var d = a.firstInput.documentPoint; c = c.location; this.j = new I(d.x - c.x, d.y - c.y) } a = a.lastInput.documentPoint; b.position = new I(a.x - this.j.x, a.y - this.j.y) } } }; Ok.className = "OverviewDraggingTool";
    function Rk() { Qa(this); this.H = ke; this.Ha = this.u = this.m = !0; this.F = this.V = this.ob = this.ga = !1; this.gj = this.j = null; this.Fb = 1.05; this.Ct = NaN; this.lw = null; this.vu = NaN; this.tu = bc; this.hb = !0; this.Zf = null; this.pb = 200 } Rk.prototype.toString = function () { return "CommandHandler" }; Rk.prototype.Te = function (a) { this.H = a };
    Rk.prototype.doKeyDown = function () {
        var a = this.diagram, b = a.lastInput, c = Pa ? b.meta : b.control, d = b.shift, e = b.alt, f = b.key; !c || "C" !== f && "Insert" !== f ? c && "X" === f || d && "Del" === f ? this.canCutSelection() && this.cutSelection() : c && "V" === f || d && "Insert" === f ? this.canPasteSelection() && this.pasteSelection() : c && "Y" === f || e && d && "Backspace" === f ? this.canRedo() && this.redo() : c && "Z" === f || e && "Backspace" === f ? this.canUndo() && this.undo() : "Del" === f || "Backspace" === f ? this.canDeleteSelection() && this.deleteSelection() : c && "A" === f ? this.canSelectAll() &&
            this.selectAll() : "Esc" === f ? this.canStopCommand() && this.stopCommand() : "Up" === f ? a.allowVerticalScroll && (c ? a.scroll("pixel", "up") : a.scroll("line", "up")) : "Down" === f ? a.allowVerticalScroll && (c ? a.scroll("pixel", "down") : a.scroll("line", "down")) : "Left" === f ? a.allowHorizontalScroll && (c ? a.scroll("pixel", "left") : a.scroll("line", "left")) : "Right" === f ? a.allowHorizontalScroll && (c ? a.scroll("pixel", "right") : a.scroll("line", "right")) : "PageUp" === f ? d && a.allowHorizontalScroll ? a.scroll("page", "left") : a.allowVerticalScroll &&
                a.scroll("page", "up") : "PageDown" === f ? d && a.allowHorizontalScroll ? a.scroll("page", "right") : a.allowVerticalScroll && a.scroll("page", "down") : "Home" === f ? c && a.allowVerticalScroll ? a.scroll("document", "up") : !c && a.allowHorizontalScroll && a.scroll("document", "left") : "End" === f ? c && a.allowVerticalScroll ? a.scroll("document", "down") : !c && a.allowHorizontalScroll && a.scroll("document", "right") : " " === f ? this.canScrollToPart() && this.scrollToPart() : "Subtract" === f ? this.canDecreaseZoom() && this.decreaseZoom() : "Add" === f ? this.canIncreaseZoom() &&
                    this.increaseZoom() : c && "0" === f ? this.canResetZoom() && this.resetZoom() : d && "Z" === f ? this.canZoomToFit() && this.zoomToFit() : c && !d && "G" === f ? this.canGroupSelection() && this.groupSelection() : c && d && "G" === f ? this.canUngroupSelection() && this.ungroupSelection() : "F2" === f || b.event && "F2" === b.event.key ? this.canEditTextBlock() && this.editTextBlock() : "ContextMenu" === f || b.event && "ContextMenu" === b.event.key ? this.canShowContextMenu() && this.showContextMenu() : b.bubbles = !0 : this.canCopySelection() && this.copySelection()
    };
    Rk.prototype.doKeyUp = function () { this.diagram.lastInput.bubbles = !0 }; Rk.prototype.stopCommand = function () { var a = this.diagram, b = a.currentTool; b instanceof La && a.allowSelect && a.clearSelection(); null !== b && b.doCancel() }; Rk.prototype.canStopCommand = function () { return !0 };
    Rk.prototype.selectAll = function () { var a = this.diagram; a.N(); try { a.currentCursor = "wait"; a.R("ChangingSelection", a.selection); for (var b = a.parts; b.next();)b.value.isSelected = !0; for (var c = a.nodes; c.next();)c.value.isSelected = !0; for (var d = a.links; d.next();)d.value.isSelected = !0 } finally { a.R("ChangedSelection", a.selection), a.currentCursor = "" } }; Rk.prototype.canSelectAll = function () { return this.diagram.allowSelect };
    Rk.prototype.deleteSelection = function () { var a = this.diagram; try { a.currentCursor = "wait"; a.R("ChangingSelection", a.selection); a.Ea("Delete"); a.R("SelectionDeleting", a.selection); for (var b = new H, c = a.selection.iterator; c.next();)Sk(b, c.value, !0, this.deletesTree ? Infinity : 0, this.deletesConnectedLinks ? null : !1, function (a) { return a.canDelete() }); a.Bs(b, !0); a.R("SelectionDeleted", b) } finally { a.eb("Delete"), a.R("ChangedSelection", a.selection), a.currentCursor = "" } };
    Rk.prototype.canDeleteSelection = function () { var a = this.diagram; return a.isReadOnly || a.isModelReadOnly || !a.allowDelete || 0 === a.selection.count ? !1 : !0 }; Rk.prototype.copySelection = function () { var a = this.diagram, b = new H; for (a = a.selection.iterator; a.next();)Sk(b, a.value, !0, this.copiesTree ? Infinity : 0, this.copiesConnectedLinks, function (a) { return a.canCopy() }); this.copyToClipboard(b) }; Rk.prototype.canCopySelection = function () { var a = this.diagram; return a.allowCopy && a.allowClipboard && 0 !== a.selection.count ? !0 : !1 };
    Rk.prototype.cutSelection = function () { this.copySelection(); this.deleteSelection() }; Rk.prototype.canCutSelection = function () { var a = this.diagram; return !a.isReadOnly && !a.isModelReadOnly && a.allowCopy && a.allowDelete && a.allowClipboard && 0 !== a.selection.count ? !0 : !1 };
    Rk.prototype.copyToClipboard = function (a) { var b = this.diagram, c = null; if (null === a) Hi = null, Ii = ""; else { c = b.model; var d = !1, e = !1, f = null; try { c.Pl() && (d = c.Hj, c.Hj = this.copiesParentKey), c.Tj() && (e = c.Gj, c.Gj = this.copiesGroupKey), f = b.Ij(a, null, !0) } finally { c.Pl() && (c.Hj = d), c.Tj() && (c.Gj = e), c = new F, c.addAll(f), Hi = c, Ii = b.model.dataFormat } } b.R("ClipboardChanged", c) };
    Rk.prototype.pasteFromClipboard = function () { var a = new H, b = Hi; if (null === b) return a; var c = this.diagram; if (Ii !== c.model.dataFormat) return a; var d = c.model, e = !1, f = !1, g = null; try { d.Pl() && (e = d.Hj, d.Hj = this.copiesParentKey), d.Tj() && (f = d.Gj, d.Gj = this.copiesGroupKey), g = c.Ij(b, c, !1) } finally { for (d.Pl() && (d.Hj = e), d.Tj() && (d.Gj = f), b = g.iterator; b.next();)c = b.value, d = b.key, c.location.w() || (d.location.w() ? c.location = d.location : !c.position.w() && d.position.w() && (c.position = d.position)), a.add(c) } return a };
    Rk.prototype.pasteSelection = function (a) {
        void 0 === a && (a = null); var b = this.diagram; try { b.currentCursor = "wait"; b.R("ChangingSelection", b.selection); b.Ea("Paste"); var c = this.pasteFromClipboard(); 0 < c.count && b.clearSelection(!0); for (var d = c.iterator; d.next();)d.value.isSelected = !0; if (null !== a) { var e = b.computePartsBounds(b.selection); if (e.w()) { var f = this.computeEffectiveCollection(b.selection, b.Tm); df(b, f, new I(a.x - e.centerX, a.y - e.centerY), b.Tm, !1) } } b.R("ClipboardPasted", c) } finally {
            b.eb("Paste"), b.R("ChangedSelection",
                b.selection), b.currentCursor = ""
        }
    }; Rk.prototype.canPasteSelection = function () { var a = this.diagram; return a.isReadOnly || a.isModelReadOnly || !a.allowInsert || !a.allowClipboard || null === Hi || 0 === Hi.count || Ii !== a.model.dataFormat ? !1 : !0 }; Rk.prototype.undo = function () { this.diagram.undoManager.undo() }; Rk.prototype.canUndo = function () { var a = this.diagram; return a.isReadOnly || a.isModelReadOnly ? !1 : a.allowUndo && a.undoManager.canUndo() }; Rk.prototype.redo = function () { this.diagram.undoManager.redo() };
    Rk.prototype.canRedo = function () { var a = this.diagram; return a.isReadOnly || a.isModelReadOnly ? !1 : a.allowUndo && a.undoManager.canRedo() }; Rk.prototype.decreaseZoom = function (a) { void 0 === a && (a = 1 / this.zoomFactor); var b = this.diagram; b.autoScale === qh && (a = b.scale * a, a < b.minScale || a > b.maxScale || (b.scale = a)) }; Rk.prototype.canDecreaseZoom = function (a) { void 0 === a && (a = 1 / this.zoomFactor); var b = this.diagram; if (b.autoScale !== qh) return !1; a = b.scale * a; return a < b.minScale || a > b.maxScale ? !1 : b.allowZoom };
    Rk.prototype.increaseZoom = function (a) { void 0 === a && (a = this.zoomFactor); var b = this.diagram; b.autoScale === qh && (a = b.scale * a, a < b.minScale || a > b.maxScale || (b.scale = a)) }; Rk.prototype.canIncreaseZoom = function (a) { void 0 === a && (a = this.zoomFactor); var b = this.diagram; if (b.autoScale !== qh) return !1; a = b.scale * a; return a < b.minScale || a > b.maxScale ? !1 : b.allowZoom }; Rk.prototype.resetZoom = function (a) { void 0 === a && (a = this.defaultScale); var b = this.diagram; a < b.minScale || a > b.maxScale || (b.scale = a) };
    Rk.prototype.canResetZoom = function (a) { void 0 === a && (a = this.defaultScale); var b = this.diagram; return a < b.minScale || a > b.maxScale ? !1 : b.allowZoom };
    Rk.prototype.zoomToFit = function () { var a = this.diagram, b = a.animationManager; b.zc(); a.redraw(); var c = a.position, d = a.scale; kh(b, "Zoom To Fit"); this.isZoomToFitRestoreEnabled && d === this.vu && !isNaN(this.Ct) && a.documentBounds.C(this.tu) ? (a.scale = this.Ct, a.position = this.lw, this.vu = NaN, this.tu = bc) : (this.Ct = d, this.lw = c.copy(), a.zoomToFit(), this.vu = a.scale, this.tu = a.documentBounds.copy()); mh(b) }; Rk.prototype.canZoomToFit = function () { return this.diagram.allowZoom };
    Rk.prototype.scrollToPart = function (a) {
        void 0 === a && (a = null); var b = this.diagram; b.Va(); if (null === a) { try { null !== this.Zf && (this.Zf.next() ? a = this.Zf.value : this.Zf = null) } catch (k) { this.Zf = null } null === a && (0 < b.highlighteds.count ? this.Zf = b.highlighteds.iterator : 0 < b.selection.count && (this.Zf = b.selection.iterator), null !== this.Zf && this.Zf.next() && (a = this.Zf.value)) } if (null !== a) {
            var c = b.animationManager; kh(c, "Scroll To Part"); var d = this.scrollToPartPause; if (0 < d) {
                var e = Tk(this, a, [a]); if (1 === e.length) b.Ea(), b.Wr(a.actualBounds),
                    b.eb("Scroll To Part"); else {
                        var f = function () { b.Ea(); for (var a = e.pop(); 0 < e.length && a instanceof V && a.isTreeExpanded && (!(a instanceof pf) || a.isSubGraphExpanded);)a = e.pop(); 0 < e.length ? (a instanceof U && b.ov(a.actualBounds), a instanceof V && !a.isTreeExpanded && (a.isTreeExpanded = !0), a instanceof pf && !a.isSubGraphExpanded && (a.isSubGraphExpanded = !0)) : (a instanceof U && b.Wr(a.actualBounds), b.Yl("LayoutCompleted", g)); b.eb("Scroll To Part") }, g = function () { ta(f, (c.isEnabled ? c.duration : 0) + d) }; b.zj("LayoutCompleted",
                            g); f()
                }
            } else { var h = b.position.copy(); b.Wr(a.actualBounds); h.ab(b.position) && c.zc() }
        }
    };
    function Tk(a, b, c) { if (b.isVisible()) return c; if (b instanceof te) Tk(a, b.adornedPart, c); else if (b instanceof T) { var d = b.fromNode; null !== d && Tk(a, d, c); b = b.toNode; null !== b && Tk(a, b, c) } else b instanceof V && (d = b.labeledLink, null !== d && Tk(a, d, c), d = b.lg(), null !== d && (d.isTreeExpanded || d.wasTreeExpanded || c.push(d), Tk(a, d, c))), b = b.containingGroup, null !== b && (b.isSubGraphExpanded || b.wasSubGraphExpanded || c.push(b), Tk(a, b, c)); return c }
    Rk.prototype.canScrollToPart = function (a) { void 0 === a && (a = null); if (null !== a && !(a instanceof U)) return !1; a = this.diagram; return 0 === a.selection.count && 0 === a.highlighteds.count ? !1 : a.allowHorizontalScroll && a.allowVerticalScroll };
    Rk.prototype.collapseTree = function (a) { void 0 === a && (a = null); var b = this.diagram; try { b.Ea("Collapse Tree"); kh(b.animationManager, "Collapse Tree"); var c = new F; if (null !== a && a.isTreeExpanded) a.collapseTree(), c.add(a); else if (null === a) for (var d = b.selection.iterator; d.next();) { var e = d.value; e instanceof V && e.isTreeExpanded && (e.collapseTree(), c.add(e)) } b.R("TreeCollapsed", c) } finally { b.eb("Collapse Tree") } };
    Rk.prototype.canCollapseTree = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly) return !1; if (null !== a) { if (!(a instanceof V && a.isTreeExpanded)) return !1; if (0 < a.Ko().count) return !0 } else for (a = b.selection.iterator; a.next();)if (b = a.value, b instanceof V && b.isTreeExpanded && 0 < b.Ko().count) return !0; return !1 };
    Rk.prototype.expandTree = function (a) { void 0 === a && (a = null); var b = this.diagram; try { b.Ea("Expand Tree"); kh(b.animationManager, "Expand Tree"); var c = new F; if (null !== a && !a.isTreeExpanded) a.expandTree(), c.add(a); else if (null === a) for (var d = b.selection.iterator; d.next();) { var e = d.value; e instanceof V && !e.isTreeExpanded && (e.expandTree(), c.add(e)) } b.R("TreeExpanded", c) } finally { b.eb("Expand Tree") } };
    Rk.prototype.canExpandTree = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly) return !1; if (null !== a) { if (!(a instanceof V) || a.isTreeExpanded) return !1; if (0 < a.Ko().count) return !0 } else for (a = b.selection.iterator; a.next();)if (b = a.value, b instanceof V && !b.isTreeExpanded && 0 < b.Ko().count) return !0; return !1 };
    Rk.prototype.groupSelection = function () {
        var a = this.diagram, b = a.model; if (b.Uj()) {
            var c = this.archetypeGroupData; if (null !== c) {
                var d = null; try {
                    a.currentCursor = "wait"; a.R("ChangingSelection", a.selection); a.Ea("Group"); for (var e = new F, f = a.selection.iterator; f.next();) { var g = f.value; g.Uc() && g.canGroup() && e.add(g) } for (var h = new F, k = e.iterator; k.next();) { var l = k.value; f = !1; for (var m = e.iterator; m.next();)if (l.Md(m.value)) { f = !0; break } f || h.add(l) } if (0 < h.count) {
                        var n = h.first().containingGroup; if (null !== n) for (; null !==
                            n;) { e = !1; for (var p = h.iterator; p.next();)if (!p.value.Md(n)) { e = !0; break } if (e) n = n.containingGroup; else break } if (c instanceof pf) jg(c), d = c.copy(), null !== d && a.add(d); else if (b.Uu(c)) { var r = b.copyNodeData(c); za(r) && (b.uf(r), d = a.fi(r)) } if (null !== d) { null !== n && this.isValidMember(n, d) && (d.containingGroup = n); for (var q = h.iterator; q.next();) { var u = q.value; this.isValidMember(d, u) && (u.containingGroup = d) } a.clearSelection(!0); d.isSelected = !0 }
                    } a.R("SelectionGrouped", d)
                } finally {
                    a.eb("Group"), a.R("ChangedSelection",
                        a.selection), a.currentCursor = ""
                    }
            }
        }
    }; Rk.prototype.canGroupSelection = function () { var a = this.diagram; if (a.isReadOnly || a.isModelReadOnly || !a.allowInsert || !a.allowGroup || !a.model.Uj() || null === this.archetypeGroupData) return !1; for (a = a.selection.iterator; a.next();) { var b = a.value; if (b.Uc() && b.canGroup()) return !0 } return !1 };
    function Uk(a) { var b = Ea(); for (a = a.iterator; a.next();) { var c = a.value; c instanceof T || b.push(c) } a = new H; c = b.length; for (var d = 0; d < c; d++) { for (var e = b[d], f = !0, g = 0; g < c; g++)if (e.Md(b[g])) { f = !1; break } f && a.add(e) } Ga(b); return a }
    Rk.prototype.isValidMember = function (a, b) { if (null === b || a === b || b instanceof T) return !1; if (null !== a) { if (a === b || a.Md(b)) return !1; var c = a.memberValidation; if (null !== c && !c(a, b) || null === a.data && null !== b.data || null !== a.data && null === b.data) return !1 } c = this.memberValidation; return null !== c ? c(a, b) : !0 };
    Rk.prototype.ungroupSelection = function (a) {
        void 0 === a && (a = null); var b = this.diagram, c = b.model; if (c.Uj()) try {
            b.currentCursor = "wait"; b.R("ChangingSelection", b.selection); b.Ea("Ungroup"); var d = new F; if (null !== a) d.add(a); else for (var e = b.selection.iterator; e.next();) { var f = e.value; f instanceof pf && f.canUngroup() && d.add(f) } var g = new F; if (0 < d.count) {
                b.clearSelection(!0); for (var h = d.iterator; h.next();) {
                    var k = h.value; k.expandSubGraph(); var l = k.containingGroup, m = null !== l && null !== l.data ? c.va(l.data) : void 0; g.addAll(k.memberParts);
                    for (var n = g.iterator; n.next();) { var p = n.value; p.isSelected = !0; if (!(p instanceof T)) { var r = p.data; null !== r ? c.gp(r, m) : p.containingGroup = l } } b.remove(k)
                }
            } b.R("SelectionUngrouped", d, g)
        } finally { b.eb("Ungroup"), b.R("ChangedSelection", b.selection), b.currentCursor = "" }
    };
    Rk.prototype.canUngroupSelection = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly || b.isModelReadOnly || !b.allowDelete || !b.allowUngroup || !b.model.Uj()) return !1; if (null !== a) { if (!(a instanceof pf)) return !1; if (a.canUngroup()) return !0 } else for (a = b.selection.iterator; a.next();)if (b = a.value, b instanceof pf && b.canUngroup()) return !0; return !1 };
    Rk.prototype.addTopLevelParts = function (a, b) { var c = !0; for (a = Uk(a).iterator; a.next();) { var d = a.value; null !== d.containingGroup && (!b || this.isValidMember(null, d) ? d.containingGroup = null : c = !1) } return c };
    Rk.prototype.collapseSubGraph = function (a) { void 0 === a && (a = null); var b = this.diagram; try { b.Ea("Collapse SubGraph"); kh(b.animationManager, "Collapse SubGraph"); var c = new F; if (null !== a && a.isSubGraphExpanded) a.collapseSubGraph(), c.add(a); else if (null === a) for (var d = b.selection.iterator; d.next();) { var e = d.value; e instanceof pf && e.isSubGraphExpanded && (e.collapseSubGraph(), c.add(e)) } b.R("SubGraphCollapsed", c) } finally { b.eb("Collapse SubGraph") } };
    Rk.prototype.canCollapseSubGraph = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly) return !1; if (null !== a) return a instanceof pf && a.isSubGraphExpanded ? !0 : !1; for (a = b.selection.iterator; a.next();)if (b = a.value, b instanceof pf && b.isSubGraphExpanded) return !0; return !1 };
    Rk.prototype.expandSubGraph = function (a) { void 0 === a && (a = null); var b = this.diagram; try { b.Ea("Expand SubGraph"); kh(b.animationManager, "Expand SubGraph"); var c = new F; if (null !== a && !a.isSubGraphExpanded) a.expandSubGraph(), c.add(a); else if (null === a) for (var d = b.selection.iterator; d.next();) { var e = d.value; e instanceof pf && !e.isSubGraphExpanded && (e.expandSubGraph(), c.add(e)) } b.R("SubGraphExpanded", c) } finally { b.eb("Expand SubGraph") } };
    Rk.prototype.canExpandSubGraph = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly) return !1; if (null !== a) return a instanceof pf && !a.isSubGraphExpanded ? !0 : !1; for (a = b.selection.iterator; a.next();)if (b = a.value, b instanceof pf && !b.isSubGraphExpanded) return !0; return !1 };
    Rk.prototype.editTextBlock = function (a) { void 0 === a && (a = null); var b = this.diagram, c = b.toolManager.findTool("TextEditing"); if (null !== c) { if (null === a) { a = null; for (var d = b.selection.iterator; d.next();) { var e = d.value; if (e.canEdit()) { a = e; break } } if (null === a) return; a = a.Il(function (a) { return a instanceof Pg && a.editable }) } null !== a && (b.currentTool = null, c.textBlock = a, b.currentTool = c) } };
    Rk.prototype.canEditTextBlock = function (a) { void 0 === a && (a = null); var b = this.diagram; if (b.isReadOnly || b.isModelReadOnly || !b.allowTextEdit || null === b.toolManager.findTool("TextEditing")) return !1; if (null !== a) { if (!(a instanceof Pg)) return !1; a = a.part; if (null !== a && a.canEdit()) return !0 } else for (b = b.selection.iterator; b.next();)if (a = b.value, a.canEdit() && (a = a.Il(function (a) { return a instanceof Pg && a.editable }), null !== a)) return !0; return !1 };
    Rk.prototype.showContextMenu = function (a) { var b = this.diagram, c = b.toolManager.findTool("ContextMenu"); if (null !== c && (void 0 === a && (a = 0 < b.selection.count ? b.selection.first() : b), a = c.findObjectWithContextMenu(a), null !== a)) { var d = b.lastInput, e = null; a instanceof Y ? e = a.ja(xc) : b.viewportBounds.ca(d.documentPoint) || (e = b.viewportBounds, e = new I(e.x + e.width / 2, e.y + e.height / 2)); null !== e && (d.viewPoint = b.ip(e), d.documentPoint = e, d.left = !1, d.right = !0, d.up = !0); b.currentTool = c; Lg(c, !1, a) } };
    Rk.prototype.canShowContextMenu = function (a) { var b = this.diagram, c = b.toolManager.findTool("ContextMenu"); if (null === c) return !1; void 0 === a && (a = 0 < b.selection.count ? b.selection.first() : b); return null === c.findObjectWithContextMenu(a) ? !1 : !0 };
    Rk.prototype.computeEffectiveCollection = function (a, b) { var c = this.diagram, d = c.toolManager.findTool("Dragging"), e = c.currentTool === d; void 0 === b && (b = e ? d.dragOptions : c.Tm); d = new ob; if (null === a) return d; for (var f = a.iterator; f.next();)Oj(c, d, f.value, e, b); if (null !== c.draggedLink && b.dragsLink) return d; for (f = a.iterator; f.next();)a = f.value, a instanceof T && (b = a.fromNode, null === b || d.contains(b) ? (b = a.toNode, null === b || d.contains(b) || d.remove(a)) : d.remove(a)); return d };
    ma.Object.defineProperties(Rk.prototype, {
        diagram: { get: function () { return this.H } }, copiesClipboardData: { get: function () { return this.m }, set: function (a) { this.m = a } }, copiesConnectedLinks: { get: function () { return this.u }, set: function (a) { this.u = a } }, deletesConnectedLinks: { get: function () { return this.Ha }, set: function (a) { this.Ha = a } }, copiesTree: {
            get: function () { return this.ga },
            set: function (a) { this.ga = a }
        }, deletesTree: { get: function () { return this.ob }, set: function (a) { this.ob = a } }, copiesParentKey: { get: function () { return this.V }, set: function (a) { this.V = a } }, copiesGroupKey: { get: function () { return this.F }, set: function (a) { this.F = a } }, archetypeGroupData: { get: function () { return this.j }, set: function (a) { this.j = a } }, memberValidation: {
            get: function () { return this.gj },
            set: function (a) { this.gj = a }
        }, defaultScale: { get: function () { return this.diagram.defaultScale }, set: function (a) { this.diagram.defaultScale = a } }, zoomFactor: { get: function () { return this.Fb }, set: function (a) { 1 < a || C("zoomFactor must be larger than 1.0, not: " + a); this.Fb = a } }, isZoomToFitRestoreEnabled: { get: function () { return this.hb }, set: function (a) { this.hb = a } }, scrollToPartPause: {
            get: function () { return this.pb },
            set: function (a) { this.pb = a }
        }
    }); Rk.className = "CommandHandler"; Fi = function () { return new Rk };
    function Y() { Qa(this); this.o = 30723; this.qb = 1; this.Ra = this.Ie = null; this.pa = (new I(NaN, NaN)).freeze(); this.ad = Lb; this.Ab = new ld; this.rh = new ld; this.Jb = null; this.Aa = this.Fk = 1; this.Ib = 0; this.Fg = pc; this.rc = (new N(NaN, NaN, NaN, NaN)).freeze(); this.Gb = (new N(NaN, NaN, NaN, NaN)).freeze(); this.ac = (new N(0, 0, NaN, NaN)).freeze(); this.ba = this.Un = this.Vn = null; this.mk = this.Hb = Sc; this.wg = this.co = 0; this.V = this.ib = this.yh = this.A = this.Ua = null; this.Uh = !1; this.eo = this.Ig = null } var nd, kg, mg, Vk, Wk, Xk, Yk, Zk, $k, al;
    Y.prototype.cloneProtected = function (a) {
        a.o = this.o | 6144; a.qb = this.qb; a.Ra = this.Ra; a.pa.assign(this.pa); a.ad = this.ad.J(); null !== this.Jb ? a.Jb = this.Jb.copy() : a.Jb = null; a.Aa = this.Aa; a.Ib = this.Ib; a.Fg = this.Fg.J(); a.rc.assign(this.rc); a.Gb.assign(this.Gb); a.ac.assign(this.ac); a.Un = this.Un; null !== this.ba && (a.ba = this.ba.copy()); a.Hb = this.Hb.J(); a.mk = this.mk.J(); a.co = this.co; a.wg = this.wg; null !== this.Ua && (a.Ua = this.Ua.copy()); null !== this.A && (a.A = this.A.jc ? this.A : this.A.copy()); null !== this.yh && (a.yh = Ba(this.yh));
        null !== this.ib && (a.ib = this.ib.copy())
    }; Y.prototype.Ow = function (a) { var b = this.yh; if (Aa(b)) for (var c = 0; c < b.length; c++) { if (b[c] === a) return } else this.yh = b = []; b.push(a) }; Y.prototype.xf = function (a) { a.Vn = null; a.Ig = null; a.v() }; Y.prototype.clone = function () { var a = new this.constructor; this.cloneProtected(a); if (null !== this.yh) for (var b = 0; b < this.yh.length; b++) { var c = this.yh[b]; a[c] = this[c] } return a }; Y.prototype.copy = function () { return this.clone() }; t = Y.prototype;
    t.rb = function (a) { a.classType === T ? 0 === a.name.indexOf("Orient") ? this.segmentOrientation = a : C("Unknown Link enum value for GraphObject.segmentOrientation property: " + a) : a.classType === Y && (this.stretch = a) }; t.toString = function () { return Ha(this.constructor) + "#" + bb(this) }; t.oh = function () { null !== this.A && (this.A.jc = !0) }; function bl(a) { null === a.A ? a.A = new cl : a.A.jc && (a.A = a.A.copy()) } t.Sc = function () { if (null === this.ba) { var a = new dl; a.Ui = rc; a.wj = rc; a.Si = 10; a.uj = 10; a.Ti = 0; a.vj = 0; this.ba = a } };
    t.cb = function (a, b, c, d, e, f, g) {
        var h = this.part; if (null !== h && (h.ck(a, b, c, d, e, f, g), c === this && a === ce && el(this) && fl(this, h, b), f = this.diagram, null === this.animationTriggers || null === f || !f.mi || f.undoManager.isUndoingRedoing || f.animationManager.Am || (a = this.animationTriggers.get(b), null !== a && f.animationManager.isEnabled && !f.animationManager.isTicking && (null === this.eo && (this.eo = new ob), g = 0 === f.undoManager.transactionLevel, a.startCondition === $h ? g = !0 : a.startCondition === bi && (g = !1), g ? (f = new jh, ai(a, f), g = this.eo.get(a),
            null !== g && g.stop(), this.eo.add(a, f), f.qu = this, f.Gw = a, f.add(this, b, d, e), f.start()) : (kh(f.animationManager, "Trigger"), f.animationManager.defaultAnimation.add(this, b, d, e)))), this instanceof W && c === h && 0 !== (h.o & 16777216) && null !== h.data)) for (c = this.P.s, d = c.length, e = 0; e < d; e++)h = c[e], h instanceof W && Gj(h, function (a) { null !== a.data && 0 !== (a.o & 16777216) && a.Ma(b) })
    };
    function fl(a, b, c) { var d = a.dh(); if (null !== d) for (var e = a.localBindings.iterator; e.next();) { var f = e.value, g = null; if (null !== f.sourceName) { g = gl(f, d, a); if (null === g) continue; f.mp(a, g, c, null) } else if (f.isToModel) { var h = b.diagram; null === h || h.skipsModelSourceBindings || f.mp(a, h.model.modelData, c, d) } else { h = d.data; if (null === h) continue; var k = b.diagram; null === k || k.skipsModelSourceBindings || f.mp(a, h, c, d) } g === a && (h = d.cs(f.xi), null !== h && f.Fv(h, g, c)) } } t.cs = function (a) { return this.bindingId === a ? this : null };
    t.g = function (a, b, c) { this.cb(ce, a, this, b, c) }; t.Ce = function (a, b, c, d) { var e = this.rc; e.h(a, b, c, d); if (1 !== this.Aa || 0 !== this.Ib) { null === this.Jb && (this.Jb = new ld); var f = this.Jb; f.reset(); this.jo(f, a, b, c, d); f.jp(e) } }; function hl(a, b, c, d) { if (!1 === a.pickable) return !1; d.multiply(a.transform); return c ? a.Tc(b, d) : a.$g(b, d) }
    t.mx = function (a, b, c) { if (!1 === this.pickable) return !1; var d = this.naturalBounds; b = a.yf(b); return c ? yb(a.x, a.y, 0, 0, 0, d.height) <= b || yb(a.x, a.y, 0, d.height, d.width, d.height) <= b || yb(a.x, a.y, d.width, d.height, d.width, 0) <= b || yb(a.x, a.y, d.width, 0, 0, 0) <= b : a.td(0, 0) <= b && a.td(0, d.height) <= b && a.td(d.width, 0) <= b && a.td(d.width, d.height) <= b }; t.oe = function () { return !0 };
    t.ca = function (a) {
        var b = I.alloc(); b.assign(a); this.transform.Ga(b); var c = this.actualBounds; if (!c.w()) return I.free(b), !1; var d = this.diagram; if (null !== d && d.pq) { var e = d.Ll("extraTouchThreshold"), f = d.Ll("extraTouchArea"), g = f / 2, h = this.naturalBounds; d = this.Af() * d.scale; var k = 1 / d; if (h.width * d < e && h.height * d < e) return a = Zb(c.x - g * k, c.y - g * k, c.width + f * k, c.height + f * k, b.x, b.y), I.free(b), a } e = !1; if (this instanceof te || this instanceof Bf ? Zb(c.x - 5, c.y - 5, c.width + 10, c.height + 10, b.x, b.y) : c.ca(b)) this.ib && !this.ib.ca(b) ?
            e = !1 : null !== this.areaBackground && c.ca(b) ? e = !0 : null !== this.Ra && this.ac.ca(a) ? e = !0 : e = this.ah(a); I.free(b); return e
    }; t.ah = function (a) { var b = this.naturalBounds; return Zb(0, 0, b.width, b.height, a.x, a.y) };
    t.Me = function (a) { if (0 === this.angle) return this.actualBounds.Me(a); var b = this.naturalBounds; b = N.allocAt(0, 0, b.width, b.height); var c = this.transform, d = !1, e = I.allocAt(a.x, a.y); b.ca(c.Ld(e)) && (e.h(a.x, a.bottom), b.ca(c.Ld(e)) && (e.h(a.right, a.bottom), b.ca(c.Ld(e)) && (e.h(a.right, a.y), b.ca(c.Ld(e)) && (d = !0)))); I.free(e); N.free(b); return d };
    t.$g = function (a, b) { if (void 0 === b) return a.Me(this.actualBounds); var c = this.naturalBounds, d = !1, e = I.allocAt(0, 0); a.ca(b.Ga(e)) && (e.h(0, c.height), a.ca(b.Ga(e)) && (e.h(c.width, c.height), a.ca(b.Ga(e)) && (e.h(c.width, 0), a.ca(b.Ga(e)) && (d = !0)))); I.free(e); return d };
    t.Tc = function (a, b) {
        if (void 0 === b && (b = this.transform, 0 === this.angle)) return a.Tc(this.actualBounds); var c = this.naturalBounds, d = I.allocAt(0, 0), e = I.allocAt(0, c.height), f = I.allocAt(c.width, c.height), g = I.allocAt(c.width, 0), h = !1; if (a.ca(b.Ga(d)) || a.ca(b.Ga(e)) || a.ca(b.Ga(f)) || a.ca(b.Ga(g))) h = !0; else {
            c = N.allocAt(0, 0, c.width, c.height); var k = I.allocAt(a.x, a.y); c.ca(b.Ld(k)) ? h = !0 : (k.h(a.x, a.bottom), c.ca(b.Ld(k)) ? h = !0 : (k.h(a.right, a.bottom), c.ca(b.Ld(k)) ? h = !0 : (k.h(a.right, a.y), c.ca(b.Ld(k)) && (h = !0)))); I.free(k);
            N.free(c); !h && (K.ns(a, d, e) || K.ns(a, e, f) || K.ns(a, f, g) || K.ns(a, g, d)) && (h = !0)
        } I.free(d); I.free(e); I.free(f); I.free(g); return h
    }; t.ja = function (a, b) { void 0 === b && (b = new I); if (a instanceof O) { var c = this.naturalBounds; b.h(a.x * c.width + a.offsetX, a.y * c.height + a.offsetY) } else b.set(a); this.ke.Ga(b); return b };
    t.ji = function (a) { void 0 === a && (a = new N); var b = this.naturalBounds, c = this.ke, d = I.allocAt(0, 0).transform(c); a.h(d.x, d.y, 0, 0); d.h(b.width, 0).transform(c); Yb(a, d.x, d.y, 0, 0); d.h(b.width, b.height).transform(c); Yb(a, d.x, d.y, 0, 0); d.h(0, b.height).transform(c); Yb(a, d.x, d.y, 0, 0); I.free(d); return a }; t.ii = function () { var a = this.ke; 1 === a.m11 && 0 === a.m12 ? a = 0 : (a = 180 * Math.atan2(a.m12, a.m11) / Math.PI, 0 > a && (a += 360)); return a };
    t.Af = function () { if (0 !== (this.o & 4096) === !1) return this.Fk; var a = this.Aa; return null !== this.panel ? a * this.panel.Af() : a }; t.js = function (a, b) { void 0 === b && (b = new I); b.assign(a); this.ke.Ld(b); return b }; t.ks = function (a, b, c) { return this.Qj(a.x, a.y, b.x, b.y, c) };
    t.Qj = function (a, b, c, d, e) { var f = this.transform, g = 1 / (f.m11 * f.m22 - f.m12 * f.m21), h = f.m22 * g, k = -f.m12 * g, l = -f.m21 * g, m = f.m11 * g, n = g * (f.m21 * f.dy - f.m22 * f.dx), p = g * (f.m12 * f.dx - f.m11 * f.dy); if (null !== this.areaBackground) return f = this.actualBounds, K.Cf(f.left, f.top, f.right, f.bottom, a, b, c, d, e); g = a * h + b * l + n; a = a * k + b * m + p; b = c * h + d * l + n; c = c * k + d * m + p; e.h(0, 0); d = this.naturalBounds; c = K.Cf(0, 0, d.width, d.height, g, a, b, c, e); e.transform(f); return c };
    Y.prototype.measure = function (a, b, c, d) {
        if (!1 !== sj(this)) {
            var e = this.Fg, f = e.right + e.left; e = e.top + e.bottom; a = Math.max(a - f, 0); b = Math.max(b - e, 0); c = Math.max((c || 0) - f, 0); d = Math.max((d || 0) - e, 0); f = this.angle; e = this.desiredSize; var g = this.cq(); 90 === f || 270 === f ? (a = isFinite(e.height) ? e.height + g : a, b = isFinite(e.width) ? e.width + g : b) : (a = isFinite(e.width) ? e.width + g : a, b = isFinite(e.height) ? e.height + g : b); e = c || 0; g = d || 0; var h = this instanceof W; switch (il(this, !0)) {
                case kg: g = e = 0; h && (b = a = Infinity); break; case nd: isFinite(a) &&
                    a > c && (e = a); isFinite(b) && b > d && (g = b); break; case Vk: isFinite(a) && a > c && (e = a); g = 0; h && (b = Infinity); break; case Wk: isFinite(b) && b > d && (g = b), e = 0, h && (a = Infinity)
            }h = this.maxSize; var k = this.minSize; e > h.width && k.width < h.width && (e = h.width); g > h.height && k.height < h.height && (g = h.height); c = Math.max(e / this.scale, k.width); d = Math.max(g / this.scale, k.height); h.width < c && (c = Math.min(k.width, c)); h.height < d && (d = Math.min(k.height, d)); a = Math.min(h.width, a); b = Math.min(h.height, b); a = Math.max(c, a); b = Math.max(d, b); if (90 === f || 270 ===
                f) f = a, a = b, b = f, f = c, c = d, d = f; this.rc.ka(); this.Sl(a, b, c, d); this.rc.freeze(); this.rc.w() || C("Non-real measuredBounds has been set. Object " + this + ", measuredBounds: " + this.rc.toString()); nj(this, !1)
        }
    }; Y.prototype.Sl = function () { }; Y.prototype.Qe = function () { return !1 };
    Y.prototype.arrange = function (a, b, c, d, e) {
        this.Pk(); var f = N.alloc(); f.assign(this.Gb); this.Gb.ka(); !1 === tj(this) ? this.Gb.h(a, b, c, d) : this.Zg(a, b, c, d); this.Gb.freeze(); void 0 === e ? this.ib = null : this.ib = e; c = !1; if (void 0 !== e) c = !0; else if (e = this.panel, null !== e && jl(e) && (e = e.panel), null !== e && (e = e.ac, d = this.measuredBounds, null !== this.areaBackground && (d = this.Gb), c = b + d.height, d = a + d.width, c = !(0 <= a + .05 && d <= e.width + .05 && 0 <= b + .05 && c <= e.height + .05), this instanceof Pg && (a = this.naturalBounds, this.Eq > a.height || this.Cb >
            a.width))) c = !0; this.o = c ? this.o | 256 : this.o & -257; this.Gb.w() || C("Non-real actualBounds has been set. Object " + this + ", actualBounds: " + this.Gb.toString()); this.Tl(f, this.Gb); kl(this, !1); N.free(f)
    }; t = Y.prototype; t.Zg = function () { };
    function ll(a, b, c, d, e) { a.Gb.h(b, c, d, e); if (!a.desiredSize.w()) { var f = a.rc; c = a.Fg; b = c.right + c.left; var g = c.top + c.bottom; c = f.width + b; f = f.height + g; d += b; e += g; b = il(a, !0); c === d && f === e && (b = kg); switch (b) { case kg: if (c > d || f > e) nj(a, !0), a.measure(c > d ? d : c, f > e ? e : f, 0, 0); break; case nd: nj(a, !0); a.measure(d, e, 0, 0); break; case Vk: nj(a, !0); a.measure(d, f, 0, 0); break; case Wk: nj(a, !0), a.measure(c, e, 0, 0) } } }
    t.Tl = function (a, b) { var c = this.part; null !== c && null !== c.diagram && (c.selectionObject !== this && c.resizeObject !== this && c.rotateObject !== this || ml(c, !0), this.N(), Ub(a, b) || (c.jh(), this.Ln(c))) }; t.Ln = function (a) { null !== this.portId && (ml(a, !0), a instanceof V && nl(a, this)) };
    t.cc = function (a, b) {
        if (!ol(this, a, b) && this.visible) {
            var c = this.opacity; if (0 !== c) {
                var d = 1; 1 !== c && (d = a.globalAlpha, a.globalAlpha = d * c); var e = this instanceof W && jl(this), f = this.Gb; if (e) {
                    var g = this.Gb, h = this.ac, k = this.areaBackground, l = this.Ra; null === k && null === l || a.vc(); null !== k && (hi(this, a, k, !0, !0, h, g), k instanceof pl && k.type === ql ? (a.beginPath(), a.rect(g.x, g.y, g.width, g.height), a.jg(k)) : a.fillRect(g.x, g.y, g.width, g.height)); null !== l && (hi(this, a, l, !0, !1, h, g), l instanceof pl && l.type === ql ? (a.beginPath(), a.rect(g.x,
                        g.y, g.width, g.height), a.jg(l)) : a.fillRect(g.x, g.y, g.width, g.height)); this.bh(a, b)
                } if (!e && (e || 0 !== f.width && 0 !== f.height && !isNaN(f.x) && !isNaN(f.y))) {
                    e = this.transform; g = this.panel; 0 !== (this.o & 4096) === !0 && rl(this); k = this.part; h = !1; var m = 0; k && b.Df("drawShadows") && (h = k.isShadowed) && (l = k.shadowOffset, m = Math.max(l.y, l.x) * b.scale * b.Pb); l = this.Qe(); var n; if (!(n = b.Xi || !l)) {
                        var p = this.naturalBounds; n = this.rh; var r = n.m11, q = n.m21, u = n.dx, w = n.m12, v = n.m22, z = n.dy, y, A = y = 0; n = y * r + A * q + u; var B = y * w + A * v + z; y = p.width + m; A = 0;
                        var E = y * r + A * q + u; y = y * w + A * v + z; A = Math.min(n, E); var M = Math.min(B, y); var R = Math.max(n + 0, E) - A; var S = Math.max(B + 0, y) - M; n = A; B = M; y = p.width + m; A = p.height + m; E = y * r + A * q + u; y = y * w + A * v + z; A = Math.min(n, E); M = Math.min(B, y); R = Math.max(n + R, E) - A; S = Math.max(B + S, y) - M; n = A; B = M; y = 0; A = p.height + m; E = y * r + A * q + u; y = y * w + A * v + z; A = Math.min(n, E); M = Math.min(B, y); R = Math.max(n + R, E) - A; S = Math.max(B + S, y) - M; n = A; B = M; m = b.viewportBounds; p = m.x; r = m.y; n = !(n > m.width + p || p > R + n || B > m.height + r || r > S + B)
                    } if (n) {
                        n = 0 !== (this.o & 256); a.clipInsteadOfFill && (n = !1); this instanceof
                            Pg && (a.font = this.font); if (n) { B = g.oe() ? g.naturalBounds : g.actualBounds; null !== this.ib ? (p = this.ib, R = p.x, S = p.y, m = p.width, p = p.height) : (R = Math.max(f.x, B.x), S = Math.max(f.y, B.y), m = Math.min(f.right, B.right) - R, p = Math.min(f.bottom, B.bottom) - S); if (R > f.width + f.x || f.x > B.width + B.x) { 1 !== c && (a.globalAlpha = d); return } a.save(); a.beginPath(); a.rect(R, S, m, p); a.clip() } if (l) {
                                if (!k.isVisible()) { 1 !== c && (a.globalAlpha = d); return } h && (B = k.shadowOffset, a.xv(B.x * b.scale * this.scale * b.Pb, B.y * b.scale * this.scale * b.Pb, k.shadowBlur),
                                    sl(a), a.shadowColor = k.shadowColor)
                            } h && !0 === this.shadowVisible ? sl(a) : h && !1 === this.shadowVisible && tl(a); k = this.naturalBounds; null !== this.areaBackground && (a.vc(), ul(this, a, k, f)); a.transform(e.m11, e.m12, e.m21, e.m22, e.dx, e.dy); h && (null !== g && 0 !== (g.o & 512) || null !== g && (g.type === W.Auto || g.type === W.Spot) && g.sb() !== this) && null === this.shadowVisible && tl(a); null !== this.Ra && (a.vc(), vl(this, a, k, f)); h && (null !== this.Ra || null !== this.areaBackground || null !== g && 0 !== (g.o & 512) || null !== g && (g.type === W.Auto || g.type === W.Spot) &&
                                g.sb() !== this) ? (wl(this, !0), null === this.shadowVisible && tl(a)) : wl(this, !1); this.bh(a, b); h && 0 !== (this.o & 512) === !0 && sl(a); l && h && tl(a); n && (a.restore(), a.Qc(this instanceof W)); e.ps() || (b = 1 / (e.m11 * e.m22 - e.m12 * e.m21), a.transform(e.m22 * b, -e.m12 * b, -e.m21 * b, e.m11 * b, b * (e.m21 * e.dy - e.m22 * e.dx), b * (e.m12 * e.dx - e.m11 * e.dy)))
                    }
                } 1 !== c && (a.globalAlpha = d)
            }
        }
    };
    function xl(a, b, c, d) {
        var e = a.part, f = !1; e && b.Df("drawShadows") && (f = e.isShadowed); c.clipInsteadOfFill && (f = !1); a.Qe() && f && sl(c); f && !0 === a.shadowVisible ? sl(c) : f && !1 === a.shadowVisible && tl(c); e = a.panel; f && (null !== e && 0 !== (e.o & 512) || null !== e && (e.type === W.Auto || e.type === W.Spot) && e.sb() !== a) && null === a.shadowVisible && tl(c); c.Dl && (b = b.computePixelRatio(), b = "drop-shadow(" + c.shadowOffsetX / b + "px " + c.shadowOffsetY / b + "px " + c.shadowBlur / b / 2 + "px " + c.shadowColor + ")", null !== a.background && d.Ra.setAttributeNS(null, "filter",
            b), null !== a.areaBackground && d.Vd.setAttributeNS(null, "filter", b)); f && (null !== a.Ra || null !== a.areaBackground || null !== e && 0 !== (e.o & 512) || null !== e && (e.type === W.Auto || e.type === W.Spot) && e.sb() !== a) ? (wl(a, !0), null === a.shadowVisible && tl(c)) : wl(a, !1); return f
    } function yl(a, b, c, d, e) { d.Dl && (c = c.computePixelRatio(), a.wv(b, "drop-shadow(" + d.shadowOffsetX / c + "px " + d.shadowOffsetY / c + "px " + d.shadowBlur / c / 2 + "px " + d.shadowColor + ")")); e && 0 !== (a.o & 512) === !0 && sl(d); a.Qe() && tl(d) } t.wv = function () { };
    function ul(a, b, c, d) { hi(a, b, a.areaBackground, !0, !0, c, d); a.areaBackground instanceof pl && a.areaBackground.type === ql ? (b.beginPath(), b.rect(d.x, d.y, d.width, d.height), b.jg(a.areaBackground)) : b.fillRect(d.x, d.y, d.width, d.height) } function vl(a, b, c, d) { var e = a.cq(), f = c.x, g = c.y, h = c.width + e, k = c.height + e; hi(a, b, a.Ra, !0, !1, c, d); a.Ra instanceof pl && a.Ra.type === ql ? (b.beginPath(), b.rect(f - e / 2, g - e / 2, h, k), b.jg(a.Ra)) : b.fillRect(f - e / 2, g - e / 2, h, k) }
    function zl(a, b, c) { for (var d = b.P.s, e = d.length, f = -1, g = 0; g < e; g++)if (d[g] === a) { f = g; break } a = f; if (-1 !== a) if (0 === a) b.svg.prepend(c); else { for (; 0 !== a && !b.L(a - 1).Nd();)a--; 0 === a ? b.svg.prepend(c) : (a = b.L(a - 1).svg, null !== a ? a.after(c) : b.svg.appendChild(c)) } }
    function ol(a, b, c) {
        if (!(b instanceof Aj)) return !1; if (!a.visible || 0 === a.opacity || a.Qe() && !a.isVisible()) return null !== a.svg && null !== a.svg.parentNode && a.svg.remove(), !0; if (a.Qe() && a.isShadowed) { var d = a.shadowOffset; b.xv(d.x * c.scale * a.scale * c.Pb, d.y * c.scale * a.scale * c.Pb, a.shadowBlur); sl(b); b.shadowColor = a.shadowColor } d = a.panel; var e = a.ik(b); if (a.fk(b, c, e)) {
            if (null !== a.svg.parentElement) return a.Qe() && (b.pi = a.svg), !0; a.Qe() ? (null !== b.pi ? b.pi.after(a.svg) : b.Lj.prepend(a.svg), b.pi = a.svg) : d && zl(a, d, a.svg);
            return !0
        } a instanceof Pg && (b.font = a.font); b.sd = null; var f = a.transform, g = a instanceof W; 0 !== (a.o & 4096) === !0 && rl(a); var h = a.Gb; b.lc.ia = [1, 0, 0, 1, 0, 0]; b.m = !1; e && (b.m = !0, a instanceof W && jl(a) ? Al(b, 1, 0, 0, 1, 0, 0) : Al(b, f.m11, f.m12, f.m21, f.m22, f.dx, f.dy), g && (a.svg = b.Od)); e = a.naturalBounds; if (null !== a.areaBackground) { var k = f.copy().Ol(); b.lc.ia = [k.m11, k.m12, k.m21, k.m22, k.dx, k.dy]; ul(a, b, e, h); b.lc.ia = [1, 0, 0, 1, 0, 0]; b.sd.Vd = b.Od; b.sd.prepend(b.Od) } g || b.m || (b.lc.ia = [f.m11, f.m12, f.m21, f.m22, f.dx, f.dy]); null !== a.background &&
            (a instanceof W && jl(a) ? (b.lc.ia = [f.m11, f.m12, f.m21, f.m22, f.dx, f.dy], vl(a, b, e, h), b.lc.ia = [1, 0, 0, 1, 0, 0]) : vl(a, b, e, h), b.sd.Ra = b.Od, b.sd.prepend(b.Od)); f = xl(a, c, b, b.sd); a.bh(b, c); g && b.ih.pop(); b.m && b.ih.pop(); e = b.zv.$r; k = g ? a.svg : b.sd; if (!g && null !== a.svg && k !== a.svg) return a.svg.replaceWith(k), a.svg = k, null !== k && a.Uo(b, d, h, k), !0; a.svg = k; null !== e && null === k && (Bl(b), b.ih.pop()); if (null === k) return !0; a.Uo(b, d, h, k); yl(a, k, c, b, f); 1 !== b.globalAlpha && k.setAttributeNS(null, "opacity", b.globalAlpha.toString()); a.Qe() ?
                (b.pi ? b.pi.after(k) : b.Lj.prepend(k), b.pi = k) : null !== d && zl(a, d, k); null !== e && e(a, k); return !0
    } t.Uo = function (a, b, c, d) { var e = 0 !== (this.o & 256); a.clipInsteadOfFill && (e = !1); e ? (b = b.oe() ? b.naturalBounds : b.actualBounds, a = Math.min(c.right, b.right) - Math.max(c.x, b.x), c = Math.min(c.bottom, b.bottom) - Math.max(c.y, b.y), null !== this.ib && (c = this.ib, a = c.width, c = c.height), d.setAttributeNS(null, "clip-path", "path('M 0,0 L " + a + " 0 L " + a + " " + c + " L 0 " + c + " z')")) : d.removeAttributeNS(null, "clip-path") }; t.bh = function () { };
    t.fk = function (a, b, c) {
        var d = this.svg; if (null === d) return !1; if (this.Uh) return this.Uh = !1; if (!this.visible) return d.remove(), !1; if ("g" !== d.nodeName && c || "g" === d.nodeName && !c) return !1; c = this.transform; this instanceof W && jl(this) || d.setAttributeNS(null, "transform", "matrix(" + c.m11 + ", " + c.m12 + ", " + c.m21 + ", " + c.m22 + ", " + c.dx + ", " + c.dy + ")"); var e = a.globalAlpha.toString(); "1" === e ? d.removeAttribute("opacity") : d.setAttributeNS(null, "opacity", e); a.lc.ia = [1, 0, 0, 1, 0, 0]; if (null !== this.areaBackground || void 0 !== d.Vd) {
            e =
            d.Vd; if (null === this.areaBackground) d.Vd.remove(), d.Vd = void 0; else { var f = c.copy().Ol(); a.lc.ia = [f.m11, f.m12, f.m21, f.m22, f.dx, f.dy]; ul(this, a, this.naturalBounds, this.actualBounds); a.lc.ia = [1, 0, 0, 1, 0, 0]; d.Vd ? d.Vd.replaceWith(a.Od) : d.prepend(a.Od); d.Vd = a.Od } e && Cl(a, e)
        } if (null !== this.background || void 0 !== d.Ra) e = d.Ra, null === this.background ? (Cl(a, d.Ra), d.Ra.remove(), d.Ra = void 0) : (this instanceof W && jl(this) && (a.lc.ia = [c.m11, c.m12, c.m21, c.m22, c.dx, c.dy]), vl(this, a, this.naturalBounds, this.actualBounds), d.Ra ?
            (Cl(a, d.Ra), d.Ra.replaceWith(a.Od)) : d.prepend(a.Od), d.Ra = a.Od), e && Cl(a, e); this.Uo(a, this.panel, this.actualBounds, d); c = xl(this, b, a, d); yl(this, d, b, a, c); return !0
    }; t.ik = function (a) { if (null !== this.background || null !== this.areaBackground) return !0; var b = 0 !== (this.o & 256); a.clipInsteadOfFill && (b = !1); return b ? !0 : !1 };
    function hi(a, b, c, d, e, f, g) {
        if (null !== c) {
            var h = 1, k = 1; if ("string" === typeof c) d ? b.fillStyle = c : b.strokeStyle = c; else if (c.type === Dl) d ? b.fillStyle = c.color : b.strokeStyle = c.color; else {
                var l = a.cq(); h = f.width; k = f.height; e ? (h = g.width, k = g.height) : d || (h += l, k += l); if ((f = b instanceof El) && c.te && (c.type === Fl || c.tk === h && c.Ys === k)) var m = c.te; else {
                    var n = 0, p = 0, r = 0, q = 0, u = 0, w = 0; w = u = 0; e ? (u = g.x, w = g.y) : d || (u -= l / 2, w -= l / 2); n = c.start.x * h + c.start.offsetX; p = c.start.y * k + c.start.offsetY; r = c.end.x * h + c.end.offsetX; q = c.end.y * k + c.end.offsetY;
                    n += u; r += u; p += w; q += w; if (c.type === Gl) m = b.createLinearGradient(n, p, r, q); else if (c.type === ql) w = isNaN(c.endRadius) ? Math.max(h, k) / 2 : c.endRadius, isNaN(c.startRadius) ? (u = 0, w = Math.max(h, k) / 2) : u = c.startRadius, m = b.createRadialGradient(n, p, u, r, q, w); else if (c.type === Fl) try { m = b.createPattern(c.pattern, "repeat") } catch (z) { m = null } if (c.type !== Fl && (e = c.colorStops, null !== e)) for (e = e.iterator; e.next();)m.addColorStop(e.key, e.value); if (f && (c.te = m, null !== m && (c.tk = h, c.Ys = k), null === m && c.type === Fl && -1 !== c.tk)) {
                        c.tk = -1; var v =
                            a.diagram; null !== v && -1 === c.tk && ta(function () { v.redraw() }, 600)
                    }
                } d ? b.fillStyle = m : b.strokeStyle = m
            }
        }
    } t.ng = function (a) { if (a instanceof W) a: { if (this !== a && null !== a) for (var b = this.panel; null !== b;) { if (b === a) { a = !0; break a } b = b.panel } a = !1 } else a = !1; return a }; t.Nd = function () { if (!this.visible) return !1; var a = this.panel; return null !== a ? a.Nd() : !0 }; t.Ff = function () { for (var a = this instanceof W ? this : this.panel; null !== a && a.isEnabled;)a = a.panel; return null === a };
    function rl(a) { if (0 !== (a.o & 2048) === !0) { var b = a.Ab; b.reset(); if (!a.Gb.w() || !a.rc.w()) { Hl(a, !1); return } b.translate(a.Gb.x - a.rc.x, a.Gb.y - a.rc.y); if (1 !== a.scale || 0 !== a.angle) { var c = a.naturalBounds; a.jo(b, c.x, c.y, c.width, c.height) } Hl(a, !1); Il(a, !0) } 0 !== (a.o & 4096) === !0 && (b = a.panel, null === b ? (a.rh.set(a.Ab), a.Fk = a.scale, Il(a, !1)) : null !== b.ke && (c = a.rh, c.reset(), b.oe() ? c.multiply(b.rh) : null !== b.panel && c.multiply(b.panel.rh), c.multiply(a.Ab), a.Fk = a.scale * b.Fk, Il(a, !1))) }
    t.jo = function (a, b, c, d, e) { 1 !== this.Aa && a.scale(this.Aa); if (0 !== this.Ib) { var f = I.alloc(); f.vi(b, c, d, e, xc); a.rotate(this.Ib, f.x, f.y); I.free(f) } }; t.v = function (a) { if (!sj(this)) { void 0 === a && (a = !1); nj(this, !0); kl(this, !0); var b = this.panel; null === b || a || b.v() } }; t.Sj = function () { sj(this) || (nj(this, !0), kl(this, !0)) }; function Jl(a) { if (!tj(a)) { var b = a.panel; null !== b ? b.v() : a.Qe() && (b = a.diagram, null !== b && (b.ze.add(a), a instanceof V && a.vd(), b.Wb())); kl(a, !0) } }
    t.Pk = function () { 0 !== (this.o & 2048) === !1 && (Hl(this, !0), Il(this, !0)) }; t.Su = function () { Il(this, !0) }; t.N = function () { var a = this.part; null !== a && a.N() };
    function il(a, b) { var c = a.stretch, d = a.panel; if (null !== d && d.type === W.Table) return Kl(a, d.getRowDefinition(a.row), d.getColumnDefinition(a.column), b); if (null !== d && d.type === W.Auto && d.sb() === a) return Ll(a, nd, b); if (c === Xk) { if (null !== d) { if (d.type === W.Spot && d.sb() === a) return Ll(a, nd, b); c = d.defaultStretch; return c === Xk ? Ll(a, kg, b) : Ll(a, c, b) } return Ll(a, kg, b) } return Ll(a, c, b) }
    function Kl(a, b, c, d) { var e = a.stretch; if (e !== Xk) return Ll(a, e, d); var f = e = null; switch (b.stretch) { case Wk: f = !0; break; case nd: f = !0 }switch (c.stretch) { case Vk: e = !0; break; case nd: e = !0 }b = a.panel.defaultStretch; null === e && (e = b === Vk || b === nd); null === f && (f = b === Wk || b === nd); return !0 === e && !0 === f ? Ll(a, nd, d) : !0 === e ? Ll(a, Vk, d) : !0 === f ? Ll(a, Wk, d) : Ll(a, kg, d) }
    function Ll(a, b, c) { if (c) return b; if (b === kg) return kg; c = a.desiredSize; if (c.w()) return kg; a = a.angle; if (!isNaN(c.width)) if (90 !== a && 270 !== a) { if (b === Vk) return kg; if (b === nd) return Wk } else { if (b === Wk) return kg; if (b === nd) return Vk } if (!isNaN(c.height)) if (90 !== a && 270 !== a) { if (b === Wk) return kg; if (b === nd) return Vk } else { if (b === Vk) return kg; if (b === nd) return Wk } return b } function wl(a, b) { a.o = b ? a.o | 512 : a.o & -513 } function el(a) { return 0 !== (a.o & 1024) } function Ml(a, b) { a.o = b ? a.o | 1024 : a.o & -1025 }
    function Hl(a, b) { a.o = b ? a.o | 2048 : a.o & -2049 } function Il(a, b) { a.o = b ? a.o | 4096 : a.o & -4097 } function sj(a) { return 0 !== (a.o & 8192) } function nj(a, b) { a.o = b ? a.o | 8192 : a.o & -8193 } function tj(a) { return 0 !== (a.o & 16384) } function kl(a, b) { a.o = b ? a.o | 16384 : a.o & -16385 } t.Ej = function () { this.svg = null }; t.ti = function (a) { this.Ie = a; null !== this.svg && this.svg.remove() }; t.tv = function () { }; t.sv = function (a) { this.pa = a; Jl(this); return !0 }; t.jm = function (a, b) { if (this.pa.x !== a || this.pa.y !== b) this.pa.h(a, b), this.Pk() }; t.cq = function () { return 0 };
    function Nl(a) { var b = a.part; if (b instanceof V && (null !== a.portId || a === b.port)) { var c = b.diagram; null === c || c.undoManager.isUndoingRedoing || nl(b, a) } } function Ol(a) { var b = a.diagram; null === b || b.undoManager.isUndoingRedoing || (a instanceof W ? a instanceof V ? a.vd() : a.nm(a, function (a) { Nl(a) }) : Nl(a)) }
    t.bind = function (a, b, c, d) { var e = null; "string" === typeof a ? e = new xi(a, b, c, d) : e = a; e.rd = this; a = this.dh(); null !== a && Pl(a) && C("Cannot add a Binding to a template that has already been copied: " + e); null === this.localBindings && (this.localBindings = new F); this.localBindings.add(e); return this }; t.set = function (a) { Object.assign(this, a); return this }; t.yu = function (a) { Ql(this, a); return this }; t.apply = function (a) { a(this); return this }; t.km = function (a) { Bj(this, a); return this }; t.hz = function () { return this.dh() };
    t.dh = function () { for (var a = this instanceof W ? this : this.panel; null !== a;) { if (null !== a.allBindings) return a; a = a.panel } return null }; t.Cv = function (a) { a.rd = this; null === this.animationTriggers && (this.animationTriggers = new ob); this.animationTriggers.add(a.propertyName, a); return this };
    function Rl(a, b) {
        for (var c = 1; c < arguments.length; ++c); c = arguments; var d = null, e = null; if ("function" === typeof a) e = a; else if ("string" === typeof a) { var f = Sl.I(a); "function" === typeof f ? (c = Ba(arguments), d = f(c), za(d) || C('GraphObject.make invoked object builder "' + a + '", but it did not return an Object')) : e = x.go[a] } null === d && (void 0 !== e && null !== e && e.constructor || C("GraphObject.make requires a class function or GoJS class name or name of an object builder, not: " + a), d = new e); e = 1; if (d instanceof Q && 1 < c.length) {
            f =
            d; var g = c[1]; if ("string" === typeof g || g instanceof HTMLDivElement) ti(f, g), e++
        } for (; e < c.length; e++)f = c[e], void 0 === f ? C("Undefined value at argument " + e + " for object being constructed by GraphObject.make: " + d) : Ql(d, f); return d
    }
    function Ql(a, b) {
        if ("string" === typeof b) if (a instanceof Pg) a.text = b; else if (a instanceof Bf) a.figure = b; else if (a instanceof ck) a.source = b; else if (a instanceof W) b = Tl.I(b), null !== b && (a.type = b); else if (a instanceof pl) { var c = Ra(pl, b); null !== c ? a.type = c : C("Unknown Brush type as an argument to GraphObject.make: " + b) } else a instanceof P ? (b = Ra(P, b), null !== b && (a.type = b)) : a instanceof Xd && (b = Ra(Xd, b), null !== b && (a.type = b)); else if (b instanceof Y) a instanceof W || C("A GraphObject can only be added to a Panel, not to: " +
            a), a.add(b); else if (b instanceof Uj) { var d; b.isRow && "function" === typeof a.getRowDefinition ? d = a.getRowDefinition(b.index) : b.isRow || "function" !== typeof a.getColumnDefinition || (d = a.getColumnDefinition(b.index)); d instanceof Uj ? Ul(d, b) : C("A RowColumnDefinition can only be added to an object that implements getRowDefinition/getColumnDefinition, not to: " + a) } else if (b instanceof D) "function" === typeof a.rb && a.rb(b); else if (b instanceof Vl) a.type = b; else if (b instanceof xi) a instanceof Y ? a.bind(b) : a instanceof
                Uj ? a.bind(b) : C("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: " + a); else if (b instanceof Yh) a instanceof Y ? a.Cv(b) : C("An AnimationTrigger can only be applied to a GraphObject, not to: " + a); else if (b instanceof Wd) a instanceof P && a.type === P.m ? a.figures.add(b) : C("A PathFigure can only be added to a Path Geometry, not to: " + a); else if (b instanceof Xd) a instanceof Wd ? a.segments.add(b) : C("A PathSegment can only be added to a PathFigure, not to: " + a); else if (b instanceof
                    ri) a instanceof Q ? a.layout = b : a instanceof pf ? a.layout = b : C("A Layout can only be assigned to a Diagram or a Group, not to: " + a); else if (Array.isArray(b)) for (c = 0; c < b.length; c++)Ql(a, b[c]); else if ("object" === typeof b && null !== b) if (a instanceof pl) { c = new Ta; for (var e in b) d = parseFloat(e), isNaN(d) ? c[e] = b[e] : a.addColorStop(d, b[e]); Bj(a, c) } else if (a instanceof Uj) {
                        void 0 !== b.row ? (e = b.row, (void 0 === e || null === e || Infinity === e || isNaN(e) || 0 > e) && C("Must specify non-negative integer row for RowColumnDefinition " +
                            b + ", not: " + e), a.isRow = !0, a.index = e) : void 0 !== b.column && (e = b.column, (void 0 === e || null === e || Infinity === e || isNaN(e) || 0 > e) && C("Must specify non-negative integer column for RowColumnDefinition " + b + ", not: " + e), a.isRow = !1, a.index = e); e = new Ta; for (c in b) "row" !== c && "column" !== c && (e[c] = b[c]); Bj(a, e)
                    } else Bj(a, b); else C('Unknown initializer "' + b + '" for object being constructed by GraphObject.make: ' + a)
    } function Wl(a, b) { Sl.add(a, b) }
    function Xl(a, b, c) { void 0 === c && (c = null); var d = a[1]; if ("function" === typeof c ? c(d) : "string" === typeof d) return a.splice(1, 1), d; if (void 0 === b) throw Error("no " + ("function" === typeof c ? "satisfactory" : "string") + " argument for GraphObject builder " + a[0]); return b }
    ma.Object.defineProperties(Y.prototype, {
        bindingId: { get: function () { return null !== this.A ? this.A.sp : -1 }, set: function (a) { this.bindingId !== a && (null === this.A && (this.A = new cl), this.A.sp = a) } }, localBindings: { get: function () { return null !== this.A ? this.A.pe : null }, set: function (a) { this.localBindings !== a && (null === this.A && (this.A = new cl), this.A.pe = a) } }, srcBindings: {
            get: function () { return null !== this.A ? this.A.tm : null }, set: function (a) {
                this.srcBindings !==
                a && (null === this.A && (this.A = new cl), this.A.tm = a)
            }
        }, shadowVisible: { get: function () { return null !== this.A ? this.A.zi : null }, set: function (a) { var b = this.shadowVisible; b !== a && (bl(this), this.A.zi = a, this.N(), this.g("shadowVisible", b, a)) } }, enabledChanged: { get: function () { return null !== this.A ? this.A.Fb : null }, set: function (a) { var b = this.enabledChanged; b !== a && (bl(this), this.A.Fb = a, this.g("enabledChanged", b, a)) } }, segmentOrientation: {
            get: function () { return null !== this.Ua ? this.Ua.ir : Zf }, set: function (a) { var b = this.segmentOrientation; b !== a && (null === this.Ua && (this.Ua = new Yl), this.Ua.ir = a, this.v(), this.g("segmentOrientation", b, a), a === Zf && (this.angle = 0)) }
        }, segmentIndex: { get: function () { return null !== this.Ua ? this.Ua.u : -Infinity }, set: function (a) { a = Math.round(a); var b = this.segmentIndex; b !== a && (null === this.Ua && (this.Ua = new Yl), this.Ua.u = a, this.v(), this.g("segmentIndex", b, a)) } }, segmentFraction: {
            get: function () { return null !== this.Ua ? this.Ua.m : 0 }, set: function (a) { isNaN(a) ? a = 0 : 0 > a ? a = 0 : 1 < a && (a = 1); var b = this.segmentFraction; b !== a && (null === this.Ua && (this.Ua = new Yl), this.Ua.m = a, this.v(), this.g("segmentFraction", b, a)) }
        }, segmentOffset: { get: function () { return null !== this.Ua ? this.Ua.F : pb }, set: function (a) { var b = this.segmentOffset; b.C(a) || (a = a.J(), null === this.Ua && (this.Ua = new Yl), this.Ua.F = a, this.v(), this.g("segmentOffset", b, a)) } }, stretch: {
            get: function () { return null !== this.A ? this.A.dg : Xk }, set: function (a) { var b = this.stretch; b !== a && (bl(this), this.A.dg = a, this.v(), this.g("stretch", b, a)) }
        }, name: { get: function () { return null !== this.A ? this.A.wa : "" }, set: function (a) { var b = this.name; b !== a && (bl(this), this.A.wa = a, null !== this.part && (this.part.Nh = null), this.g("name", b, a)) } }, opacity: {
            get: function () { return this.qb }, set: function (a) {
                var b = this.qb; b !== a && ((0 > a || 1 < a) && xa(a, "0 <= value <= 1", Y, "opacity"),
                    this.qb = a, this.g("opacity", b, a), a = this.diagram, b = this.part, null !== a && null !== b && a.N(Fj(b, b.actualBounds)))
            }
        }, visible: { get: function () { return 0 !== (this.o & 1) }, set: function (a) { var b = 0 !== (this.o & 1); b !== a && (this.o ^= 1, this.g("visible", b, a), b = this.panel, null !== b ? b.v() : this.Qe() && this.Vb(a), this.N(), Ol(this)) } }, pickable: { get: function () { return 0 !== (this.o & 2) }, set: function (a) { var b = 0 !== (this.o & 2); b !== a && (this.o ^= 2, this.g("pickable", b, a)) } }, fromLinkableDuplicates: {
            get: function () { return 0 !== (this.o & 4) }, set: function (a) { var b = 0 !== (this.o & 4); b !== a && (this.o ^= 4, this.g("fromLinkableDuplicates", b, a)) }
        }, fromLinkableSelfNode: { get: function () { return 0 !== (this.o & 8) }, set: function (a) { var b = 0 !== (this.o & 8); b !== a && (this.o ^= 8, this.g("fromLinkableSelfNode", b, a)) } }, toLinkableDuplicates: {
            get: function () { return 0 !== (this.o & 16) }, set: function (a) {
                var b = 0 !== (this.o & 16); b !== a && (this.o ^= 16, this.g("toLinkableDuplicates",
                    b, a))
            }
        }, toLinkableSelfNode: { get: function () { return 0 !== (this.o & 32) }, set: function (a) { var b = 0 !== (this.o & 32); b !== a && (this.o ^= 32, this.g("toLinkableSelfNode", b, a)) } }, isPanelMain: { get: function () { return 0 !== (this.o & 64) }, set: function (a) { var b = 0 !== (this.o & 64); b !== a && (this.o ^= 64, this.v(), this.g("isPanelMain", b, a)) } }, isActionable: {
            get: function () { return 0 !== (this.o & 128) }, set: function (a) {
                var b = 0 !== (this.o & 128); b !== a && (this.o ^=
                    128, this.g("isActionable", b, a))
            }
        }, areaBackground: { get: function () { return null !== this.A ? this.A.Vd : null }, set: function (a) { var b = this.areaBackground; b !== a && (null !== a && Zl(a, "GraphObject.areaBackground"), a instanceof pl && a.freeze(), bl(this), this.A.Vd = a, this.N(), this.g("areaBackground", b, a)) } }, background: {
            get: function () { return this.Ra }, set: function (a) {
                var b = this.Ra; b !== a && (null !== a && Zl(a, "GraphObject.background"), a instanceof pl && a.freeze(), this.Ra =
                    a, this.N(), this.g("background", b, a))
            }
        }, part: { get: function () { if (this.Qe()) return this; if (null !== this.Ig) return this.Ig; var a; for (a = this.panel; a;) { if (a instanceof U) return this.Ig = a; a = a.panel } return null } }, svg: { get: function () { return this.V }, set: function (a) { this.V = a } }, panel: { get: function () { return this.Ie } }, layer: { get: function () { var a = this.part; return null !== a ? a.layer : null } }, diagram: {
            get: function () { var a = this.part; return null !== a ? a.diagram : null }
        }, position: { get: function () { return this.pa }, set: function (a) { var b = a.x, c = a.y, d = this.pa, e = d.x; d = d.y; (e === b || isNaN(e) && isNaN(b)) && (d === c || isNaN(d) && isNaN(c)) ? this.tv() : (a = a.copy(), this.sv(a, e, d) && this.g("position", new I(e, d), a)) } }, actualBounds: { get: function () { return this.Gb } }, scale: {
            get: function () { return this.Aa }, set: function (a) {
                var b = this.Aa;
                b !== a && (0 >= a && C("GraphObject.scale for " + this + " must be greater than zero, not: " + a), this.Aa = a, this.v(), this.g("scale", b, a))
            }
        }, angle: { get: function () { return this.Ib }, set: function (a) { var b = this.Ib; b !== a && (a %= 360, 0 > a && (a += 360), b !== a && (this.Ib = a, Ol(this), this.v(), this.g("angle", b, a))) } }, desiredSize: {
            get: function () { return this.ad }, set: function (a) {
                var b = a.width, c = a.height, d = this.ad, e = d.width, f = d.height; (e === b || isNaN(e) && isNaN(b)) && (f === c || isNaN(f) &&
                    isNaN(c)) || (this.ad = a = a.J(), this.v(), this instanceof Bf && this.nc(), this.g("desiredSize", d, a), el(this) && (a = this.part, null !== a && (fl(this, a, "width"), fl(this, a, "height"))))
            }
        }, width: { get: function () { return this.ad.width }, set: function (a) { var b = this.ad.width; b === a || isNaN(b) && isNaN(a) || (b = this.ad, this.ad = a = (new L(a, this.ad.height)).freeze(), this.v(), this instanceof Bf && this.nc(), this.g("desiredSize", b, a), el(this) && (a = this.part, null !== a && fl(this, a, "width"))) } }, height: {
            get: function () { return this.ad.height }, set: function (a) { var b = this.ad.height; b === a || isNaN(b) && isNaN(a) || (b = this.ad, this.ad = a = (new L(this.ad.width, a)).freeze(), this.v(), this instanceof Bf && this.nc(), this.g("desiredSize", b, a), el(this) && (a = this.part, null !== a && fl(this, a, "height"))) }
        }, minSize: {
            get: function () { return null !== this.A ? this.A.u : Cb }, set: function (a) {
                var b = this.minSize; b.C(a) || (a = a.copy(), isNaN(a.width) && (a.width = 0), isNaN(a.height) && (a.height = 0), a.freeze(),
                    bl(this), this.A.u = a, this.v(), this.g("minSize", b, a))
            }
        }, maxSize: { get: function () { return null !== this.A ? this.A.m : Kb }, set: function (a) { var b = this.maxSize; b.C(a) || (a = a.copy(), isNaN(a.width) && (a.width = Infinity), isNaN(a.height) && (a.height = Infinity), a.freeze(), bl(this), this.A.m = a, this.v(), this.g("maxSize", b, a)) } }, measuredBounds: { get: function () { return this.rc } }, naturalBounds: { get: function () { return this.ac } }, margin: {
            get: function () { return this.Fg }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.Fg; b.C(a) || (this.Fg = a = a.J(), this.v(), this.g("margin", b, a)) }
        }, transform: { get: function () { 0 !== (this.o & 2048) === !0 && rl(this); return this.Ab } }, ke: { get: function () { 0 !== (this.o & 4096) === !0 && rl(this); return this.rh } }, animationTriggers: {
            get: function () { return null !== this.A ? this.A.hb : null }, set: function (a) {
                this.animationTriggers !==
                a && (bl(this), this.A.hb = a)
            }
        }, reshapingBehavior: { get: function () { return null !== this.A ? this.A.Mf : kg }, set: function (a) { this.reshapingBehavior !== a && (bl(this), this.A.Mf = a) } }, alignment: { get: function () { return this.Hb }, set: function (a) { var b = this.Hb; b.C(a) || (a.Jc() && !a.Tb() && C("GraphObject.alignment for " + this + " must be a real Spot or Spot.Default, not: " + a), this.Hb = a = a.J(), Jl(this), this.g("alignment", b, a)) } }, column: {
            get: function () { return this.wg },
            set: function (a) { a = Math.round(a); var b = this.wg; b !== a && (0 > a && xa(a, ">= 0", Y, "column"), this.wg = a, this.v(), this.g("column", b, a), null !== this.svg && this.svg.remove()) }
        }, columnSpan: { get: function () { return null !== this.A ? this.A.pb : 1 }, set: function (a) { a = Math.round(a); var b = this.columnSpan; b !== a && (1 > a && xa(a, ">= 1", Y, "columnSpan"), bl(this), this.A.pb = a, this.v(), this.g("columnSpan", b, a)) } }, row: {
            get: function () { return this.co }, set: function (a) {
                a = Math.round(a);
                var b = this.co; b !== a && (0 > a && xa(a, ">= 0", Y, "row"), this.co = a, this.v(), this.g("row", b, a), null !== this.svg && this.svg.remove())
            }
        }, rowSpan: { get: function () { return null !== this.A ? this.A.yi : 1 }, set: function (a) { a = Math.round(a); var b = this.rowSpan; b !== a && (1 > a && xa(a, ">= 1", Y, "rowSpan"), bl(this), this.A.yi = a, this.v(), this.g("rowSpan", b, a)) } }, spanAllocation: {
            get: function () { return null !== this.A ? this.A.Ai : null }, set: function (a) {
                var b = this.spanAllocation; b !== a &&
                    (bl(this), this.A.Ai = a, this.v(), this.g("spanAllocation", b, a))
            }
        }, alignmentFocus: { get: function () { return this.mk }, set: function (a) { var b = this.mk; b.C(a) || (this.mk = a = a.J(), this.v(), this.g("alignmentFocus", b, a)) } }, portId: {
            get: function () { return this.Un }, set: function (a) {
                var b = this.Un; if (b !== a) {
                    var c = this.part; null === c || c instanceof V || C("Cannot set portID on a Link: " + a); null !== b && null !== c && $l(c, this); this.Un = a; null !== a && null !== c && (c.Oe = !0, am(c, this));
                    this.g("portId", b, a)
                }
            }
        }, toSpot: { get: function () { return null !== this.ba ? this.ba.wj : rc }, set: function (a) { var b = this.toSpot; b.C(a) || (a = a.J(), this.Sc(), this.ba.wj = a, this.g("toSpot", b, a), Nl(this)) } }, toEndSegmentLength: { get: function () { return null !== this.ba ? this.ba.uj : 10 }, set: function (a) { var b = this.toEndSegmentLength; b !== a && (0 > a && xa(a, ">= 0", Y, "toEndSegmentLength"), this.Sc(), this.ba.uj = a, this.g("toEndSegmentLength", b, a), Nl(this)) } }, toShortLength: {
            get: function () { return null !== this.ba ? this.ba.vj : 0 }, set: function (a) { var b = this.toShortLength; b !== a && (this.Sc(), this.ba.vj = a, this.g("toShortLength", b, a), Nl(this)) }
        }, toLinkable: { get: function () { return null !== this.ba ? this.ba.Lr : null }, set: function (a) { var b = this.toLinkable; b !== a && (this.Sc(), this.ba.Lr = a, this.g("toLinkable", b, a)) } }, toMaxLinks: {
            get: function () { return null !== this.ba ? this.ba.Mr : Infinity }, set: function (a) {
                var b = this.toMaxLinks;
                b !== a && (0 > a && xa(a, ">= 0", Y, "toMaxLinks"), this.Sc(), this.ba.Mr = a, this.g("toMaxLinks", b, a))
            }
        }, fromSpot: { get: function () { return null !== this.ba ? this.ba.Ui : rc }, set: function (a) { var b = this.fromSpot; b.C(a) || (a = a.J(), this.Sc(), this.ba.Ui = a, this.g("fromSpot", b, a), Nl(this)) } }, fromEndSegmentLength: {
            get: function () { return null !== this.ba ? this.ba.Si : 10 }, set: function (a) {
                var b = this.fromEndSegmentLength; b !== a && (0 > a && xa(a, ">= 0", Y, "fromEndSegmentLength"), this.Sc(),
                    this.ba.Si = a, this.g("fromEndSegmentLength", b, a), Nl(this))
            }
        }, fromShortLength: { get: function () { return null !== this.ba ? this.ba.Ti : 0 }, set: function (a) { var b = this.fromShortLength; b !== a && (this.Sc(), this.ba.Ti = a, this.g("fromShortLength", b, a), Nl(this)) } }, fromLinkable: { get: function () { return null !== this.ba ? this.ba.$p : null }, set: function (a) { var b = this.fromLinkable; b !== a && (this.Sc(), this.ba.$p = a, this.g("fromLinkable", b, a)) } }, fromMaxLinks: {
            get: function () { return null !== this.ba ? this.ba.aq : Infinity }, set: function (a) { var b = this.fromMaxLinks; b !== a && (0 > a && xa(a, ">= 0", Y, "fromMaxLinks"), this.Sc(), this.ba.aq = a, this.g("fromMaxLinks", b, a)) }
        }, cursor: { get: function () { return null !== this.A ? this.A.j : "" }, set: function (a) { var b = this.cursor; b !== a && (bl(this), this.A.j = a, this.g("cursor", b, a)) } }, click: {
            get: function () { return null !== this.A ? this.A.uh : null }, set: function (a) {
                var b = this.click;
                b !== a && (bl(this), this.A.uh = a, this.g("click", b, a))
            }
        }, doubleClick: { get: function () { return null !== this.A ? this.A.Ah : null }, set: function (a) { var b = this.doubleClick; b !== a && (bl(this), this.A.Ah = a, this.g("doubleClick", b, a)) } }, contextClick: { get: function () { return null !== this.A ? this.A.wh : null }, set: function (a) { var b = this.contextClick; b !== a && (bl(this), this.A.wh = a, this.g("contextClick", b, a)) } }, mouseEnter: {
            get: function () {
                return null !==
                    this.A ? this.A.Ih : null
            }, set: function (a) { var b = this.mouseEnter; b !== a && (bl(this), this.A.Ih = a, this.g("mouseEnter", b, a)) }
        }, mouseLeave: { get: function () { return null !== this.A ? this.A.Lh : null }, set: function (a) { var b = this.mouseLeave; b !== a && (bl(this), this.A.Lh = a, this.g("mouseLeave", b, a)) } }, mouseOver: { get: function () { return null !== this.A ? this.A.Mh : null }, set: function (a) { var b = this.mouseOver; b !== a && (bl(this), this.A.Mh = a, this.g("mouseOver", b, a)) } }, mouseHover: {
            get: function () { return null !== this.A ? this.A.Kh : null }, set: function (a) { var b = this.mouseHover; b !== a && (bl(this), this.A.Kh = a, this.g("mouseHover", b, a)) }
        }, mouseHold: { get: function () { return null !== this.A ? this.A.Jh : null }, set: function (a) { var b = this.mouseHold; b !== a && (bl(this), this.A.Jh = a, this.g("mouseHold", b, a)) } }, mouseDragEnter: {
            get: function () { return null !== this.A ? this.A.Lf : null }, set: function (a) {
                var b = this.mouseDragEnter; b !== a && (bl(this),
                    this.A.Lf = a, this.g("mouseDragEnter", b, a))
            }
        }, mouseDragLeave: { get: function () { return null !== this.A ? this.A.Sd : null }, set: function (a) { var b = this.mouseDragLeave; b !== a && (bl(this), this.A.Sd = a, this.g("mouseDragLeave", b, a)) } }, mouseDrop: { get: function () { return null !== this.A ? this.A.Hh : null }, set: function (a) { var b = this.mouseDrop; b !== a && (bl(this), this.A.Hh = a, this.g("mouseDrop", b, a)) } }, actionDown: {
            get: function () {
                return null !==
                    this.A ? this.A.V : null
            }, set: function (a) { var b = this.actionDown; b !== a && (bl(this), this.A.V = a, this.g("actionDown", b, a)) }
        }, actionMove: { get: function () { return null !== this.A ? this.A.ga : null }, set: function (a) { var b = this.actionMove; b !== a && (bl(this), this.A.ga = a, this.g("actionMove", b, a)) } }, actionUp: { get: function () { return null !== this.A ? this.A.Ha : null }, set: function (a) { var b = this.actionUp; b !== a && (bl(this), this.A.Ha = a, this.g("actionUp", b, a)) } }, actionCancel: {
            get: function () { return null !== this.A ? this.A.F : null }, set: function (a) { var b = this.actionCancel; b !== a && (bl(this), this.A.F = a, this.g("actionCancel", b, a)) }
        }, toolTip: { get: function () { return null !== this.A ? this.A.Xh : null }, set: function (a) { var b = this.toolTip; b !== a && (bl(this), this.A.Xh = a, this.g("toolTip", b, a)) } }, contextMenu: {
            get: function () { return null !== this.A ? this.A.xh : null }, set: function (a) {
                var b = this.contextMenu; b !== a && (bl(this), this.A.xh =
                    a, this.g("contextMenu", b, a))
            }
        }
    }); Y.prototype.trigger = Y.prototype.Cv; Y.prototype.findBindingPanel = Y.prototype.dh; Y.prototype.findTemplateBinder = Y.prototype.hz; Y.prototype.setProperties = Y.prototype.km; Y.prototype.apply = Y.prototype.apply; Y.prototype.attach = Y.prototype.yu; Y.prototype.set = Y.prototype.set; Y.prototype.isEnabledObject = Y.prototype.Ff; Y.prototype.isVisibleObject = Y.prototype.Nd; Y.prototype.isContainedBy = Y.prototype.ng; Y.prototype.getNearestIntersectionPoint = Y.prototype.ks;
    Y.prototype.getLocalPoint = Y.prototype.js; Y.prototype.getDocumentScale = Y.prototype.Af; Y.prototype.getDocumentAngle = Y.prototype.ii; Y.prototype.getDocumentBounds = Y.prototype.ji; Y.prototype.getDocumentPoint = Y.prototype.ja; Y.prototype.intersectsRect = Y.prototype.Tc; Y.prototype.containedInRect = Y.prototype.$g; Y.prototype.containsRect = Y.prototype.Me; Y.prototype.containsPoint = Y.prototype.ca; Y.prototype.raiseChanged = Y.prototype.g; Y.prototype.raiseChangedEvent = Y.prototype.cb; Y.prototype.addCopyProperty = Y.prototype.Ow;
    var Sl = null; Y.className = "GraphObject"; Sl = new ob;
    Wl("Button", function () {
        function a(a, b) { return null !== a.diagram.ec(a.documentPoint, function (a) { for (; null !== a.panel && !a.isActionable;)a = a.panel; return a }, function (a) { return a === b }) } var b = Rl(W, W.Auto, {
            isActionable: !0, enabledChanged: function (a, b) { if (a instanceof W) { var c = a.fb("ButtonBorder"); null !== c && (c.fill = b ? a._buttonFillNormal : a._buttonFillDisabled) } }, cursor: "pointer", _buttonFillNormal: "#F5F5F5", _buttonStrokeNormal: "#BDBDBD", _buttonFillOver: "#E0E0E0", _buttonStrokeOver: "#9E9E9E", _buttonFillPressed: "#BDBDBD",
            _buttonStrokePressed: "#9E9E9E", _buttonFillDisabled: "#E5E5E5"
        }, Rl(Bf, { name: "ButtonBorder", figure: "RoundedRectangle", spot1: new O(0, 0, 2.76142374915397, 2.761423749153969), spot2: new O(1, 1, -2.76142374915397, -2.761423749153969), parameter1: 2, parameter2: 2, fill: "#F5F5F5", stroke: "#BDBDBD" })); b.mouseEnter = function (a, b) { if (b.Ff() && b instanceof W && (a = b.fb("ButtonBorder"), a instanceof Bf)) { var c = b._buttonFillOver; b._buttonFillNormal = a.fill; a.fill = c; c = b._buttonStrokeOver; b._buttonStrokeNormal = a.stroke; a.stroke = c } };
        b.mouseLeave = function (a, b) { b.Ff() && b instanceof W && (a = b.fb("ButtonBorder"), a instanceof Bf && (a.fill = b._buttonFillNormal, a.stroke = b._buttonStrokeNormal)) }; b.actionDown = function (a, b) {
            if (b.Ff() && b instanceof W && null !== b._buttonFillPressed && 0 === a.button) {
                var c = b.fb("ButtonBorder"); if (c instanceof Bf) {
                    a = a.diagram; var d = a.skipsUndoManager; a.skipsUndoManager = !0; var g = b._buttonFillPressed; b._buttonFillOver = c.fill; c.fill = g; g = b._buttonStrokePressed; b._buttonStrokeOver = c.stroke; c.stroke = g; a.skipsUndoManager =
                        d
                }
            }
        }; b.actionUp = function (b, d) { if (d.Ff() && d instanceof W && null !== d._buttonFillPressed && 0 === b.button) { var c = d.fb("ButtonBorder"); if (c instanceof Bf) { var f = b.diagram, g = f.skipsUndoManager; f.skipsUndoManager = !0; a(b, d) ? (c.fill = d._buttonFillOver, c.stroke = d._buttonStrokeOver) : (c.fill = d._buttonFillNormal, c.stroke = d._buttonStrokeNormal); f.skipsUndoManager = g } } }; b.actionCancel = function (b, d) {
            if (d.Ff() && d instanceof W && null !== d._buttonFillPressed) {
                var c = d.fb("ButtonBorder"); if (c instanceof Bf) {
                    var f = b.diagram,
                    g = f.skipsUndoManager; f.skipsUndoManager = !0; a(b, d) ? (c.fill = d._buttonFillOver, c.stroke = d._buttonStrokeOver) : (c.fill = d._buttonFillNormal, c.stroke = d._buttonStrokeNormal); f.skipsUndoManager = g
                }
            }
        }; b.actionMove = function (b, d) {
            if (d.Ff() && d instanceof W && null !== d._buttonFillPressed) {
                var c = b.diagram; if (0 === c.firstInput.button && (c.currentTool.standardMouseOver(), a(b, d) && (b = d.fb("ButtonBorder"), b instanceof Bf))) {
                    var f = c.skipsUndoManager; c.skipsUndoManager = !0; var g = d._buttonFillPressed; b.fill !== g && (b.fill = g); g =
                        d._buttonStrokePressed; b.stroke !== g && (b.stroke = g); c.skipsUndoManager = f
                }
            }
        }; return b
    });
    Wl("TreeExpanderButton", function () {
        var a = Rl("Button", { _treeExpandedFigure: "MinusLine", _treeCollapsedFigure: "PlusLine" }, Rl(Bf, { name: "ButtonIcon", figure: "MinusLine", stroke: "#424242", strokeWidth: 2, desiredSize: Ib }, (new xi("figure", "isTreeExpanded", function (a, c) { c = c.panel; return a ? c._treeExpandedFigure : c._treeCollapsedFigure })).Xo()), { visible: !1 }, (new xi("visible", "isTreeLeaf", function (a) { return !a })).Xo()); a.click = function (a, c) {
            c = c.part; c instanceof te && (c = c.adornedPart); if (c instanceof V) {
                var b = c.diagram;
                if (null !== b) { b = b.commandHandler; if (c.isTreeExpanded) { if (!b.canCollapseTree(c)) return } else if (!b.canExpandTree(c)) return; a.handled = !0; c.isTreeExpanded ? b.collapseTree(c) : b.expandTree(c) }
            }
        }; return a
    });
    Wl("SubGraphExpanderButton", function () {
        var a = Rl("Button", { _subGraphExpandedFigure: "MinusLine", _subGraphCollapsedFigure: "PlusLine" }, Rl(Bf, { name: "ButtonIcon", figure: "MinusLine", stroke: "#424242", strokeWidth: 2, desiredSize: Ib }, (new xi("figure", "isSubGraphExpanded", function (a, c) { c = c.panel; return a ? c._subGraphExpandedFigure : c._subGraphCollapsedFigure })).Xo())); a.click = function (a, c) {
            c = c.part; c instanceof te && (c = c.adornedPart); if (c instanceof pf) {
                var b = c.diagram; if (null !== b) {
                    b = b.commandHandler; if (c.isSubGraphExpanded) { if (!b.canCollapseSubGraph(c)) return } else if (!b.canExpandSubGraph(c)) return;
                    a.handled = !0; c.isSubGraphExpanded ? b.collapseSubGraph(c) : b.expandSubGraph(c)
                }
            }
        }; return a
    }); Wl("ToolTip", function () { return Rl(te, W.Auto, { isShadowed: !0, shadowColor: "rgba(0, 0, 0, .4)", shadowOffset: new I(0, 3), shadowBlur: 5 }, Rl(Bf, { name: "Border", figure: "RoundedRectangle", parameter1: 1, parameter2: 1, fill: "#F5F5F5", stroke: "#F0F0F0", spot1: new O(0, 0, 4, 6), spot2: new O(1, 1, -4, -4) })) });
    Wl("ContextMenu", function () { return Rl(te, W.Vertical, { background: "#F5F5F5", isShadowed: !0, shadowColor: "rgba(0, 0, 0, .4)", shadowOffset: new I(0, 3), shadowBlur: 5 }, new xi("background", "", function (a) { return null !== a.adornedPart && null !== a.placeholder ? null : "#F5F5F5" })) }); Wl("ContextMenuButton", function () { var a = Rl("Button"); a.stretch = Vk; var b = a.fb("ButtonBorder"); b instanceof Bf && (b.figure = "Rectangle", b.strokeWidth = 0, b.spot1 = new O(0, 0, 2, 3), b.spot2 = new O(1, 1, -2, -2)); return a });
    Wl("PanelExpanderButton", function (a) {
        var b = Xl(a, "COLLAPSIBLE"), c = Rl("Button", { _buttonExpandedFigure: "M0 0 M0 6 L4 2 8 6 M8 8", _buttonCollapsedFigure: "M0 0 M0 2 L4 6 8 2 M8 8", _buttonFillNormal: "rgba(0, 0, 0, 0)", _buttonStrokeNormal: null, _buttonFillOver: "rgba(0, 0, 0, .2)", _buttonStrokeOver: null, _buttonFillPressed: "rgba(0, 0, 0, .4)", _buttonStrokePressed: null }, Rl(Bf, { name: "ButtonIcon", strokeWidth: 2 }, (new xi("geometryString", "visible", function (a) { return a ? c._buttonExpandedFigure : c._buttonCollapsedFigure })).Xo(b)));
        a = c.fb("ButtonBorder"); a instanceof Bf && (a.stroke = null, a.fill = "rgba(0, 0, 0, 0)"); c.click = function (a, c) { var d = c.diagram; if (null !== d && !d.isReadOnly) { var e = c.dh(); null === e && (e = c.part); null !== e && (c = e.fb(b), null !== c && (a.handled = !0, d.Ea("Collapse/Expand Panel"), c.visible = !c.visible, d.eb("Collapse/Expand Panel"))) } }; return c
    });
    Wl("CheckBoxButton", function (a) {
        var b = Xl(a); a = Rl("Button", { desiredSize: new L(14, 14) }, Rl(Bf, { name: "ButtonIcon", geometryString: "M0 0 M0 8.85 L4.9 13.75 16.2 2.45 M16.2 16.2", strokeWidth: 2, stretch: nd, geometryStretch: mg, visible: !1 }, "" !== b ? (new xi("visible", b)).Fx() : [])); a.click = function (a, d) {
            if (d instanceof W) {
                var c = a.diagram; if (!(null === c || c.isReadOnly || "" !== b && c.model.isReadOnly)) {
                    a.handled = !0; var f = d.fb("ButtonIcon"); c.Ea("checkbox"); f.visible = !f.visible; "function" === typeof d._doClick && d._doClick(a,
                        d); c.eb("checkbox")
                }
            }
        }; return a
    });
    Wl("CheckBox", function (a) {
        a = Xl(a); a = Rl("CheckBoxButton", a, { name: "Button", isActionable: !1, margin: new hc(0, 1, 0, 0) }); var b = Rl(W, "Horizontal", a, {
            isActionable: !0, cursor: a.cursor, margin: new hc(1), _buttonFillNormal: a._buttonFillNormal, _buttonStrokeNormal: a._buttonStrokeNormal, _buttonFillOver: a._buttonFillOver, _buttonStrokeOver: a._buttonStrokeOver, _buttonFillPressed: a._buttonFillPressed, _buttonStrokePressed: a._buttonStrokePressed, _buttonFillDisabled: a._buttonFillDisabled, mouseEnter: a.mouseEnter, mouseLeave: a.mouseLeave,
            actionDown: a.actionDown, actionUp: a.actionUp, actionCancel: a.actionCancel, actionMove: a.actionMove, click: a.click, _buttonClick: a.click
        }); a.mouseEnter = null; a.mouseLeave = null; a.actionDown = null; a.actionUp = null; a.actionCancel = null; a.actionMove = null; a.click = null; return b
    }); Y.None = kg = new D(Y, "None", 0); Y.Default = Xk = new D(Y, "Default", 0); Y.Vertical = Wk = new D(Y, "Vertical", 4); Y.Horizontal = Vk = new D(Y, "Horizontal", 5); Y.Fill = nd = new D(Y, "Fill", 3); Y.Uniform = mg = new D(Y, "Uniform", 1);
    Y.UniformToFill = Yk = new D(Y, "UniformToFill", 2); Y.FlipVertical = Zk = new D(Y, "FlipVertical", 1); Y.FlipHorizontal = $k = new D(Y, "FlipHorizontal", 2); Y.FlipBoth = al = new D(Y, "FlipBoth", 3); Y.make = Rl; Y.getBuilders = function () { var a = new ob, b; for (b in Sl) if (b !== b.toLowerCase()) { var c = Sl.I(b); "function" === typeof c && a.add(b, c) } a.freeze(); return a }; Y.defineBuilder = Wl;
    Y.build = function (a, b, c) { for (var d = [], e = 2; e < arguments.length; ++e)d[e - 2] = arguments[e]; e = Sl.I(a); if ("function" === typeof e && (d = e([a].concat(d)), d instanceof Y)) return b && d.set(b), d; C('GraphObject.build invoked object builder "' + a + '", but it did not return an Object') }; Y.takeBuilderArgument = Xl;
    function cl() { this.jc = !1; this.wa = ""; this.sp = -1; this.ob = this.tm = this.pe = null; this.j = ""; this.hb = this.Ai = this.Fb = this.xh = this.Xh = this.F = this.Ha = this.ga = this.V = this.Hh = this.Sd = this.Lf = this.Jh = this.Kh = this.Mh = this.Lh = this.Ih = this.wh = this.Ah = this.uh = null; this.u = Cb; this.m = Kb; this.dg = Xk; this.pb = this.yi = 1; this.Mf = kg; this.Mc = this.zi = this.Vd = null; this.Lc = "category" }
    cl.prototype.copy = function () { var a = new cl; a.wa = this.wa; a.sp = this.sp; a.pe = this.pe; a.ob = this.ob; a.j = this.j; a.uh = this.uh; a.Ah = this.Ah; a.wh = this.wh; a.Ih = this.Ih; a.Lh = this.Lh; a.Mh = this.Mh; a.Kh = this.Kh; a.Jh = this.Jh; a.Lf = this.Lf; a.Sd = this.Sd; a.Hh = this.Hh; a.V = this.V; a.ga = this.ga; a.Ha = this.Ha; a.F = this.F; a.Xh = this.Xh; a.xh = this.xh; a.Fb = this.Fb; a.Ai = this.Ai; a.hb = this.hb; a.u = this.u.J(); a.m = this.m.J(); a.dg = this.dg; a.yi = this.yi; a.pb = this.pb; a.Mf = this.Mf; a.Vd = this.Vd; a.zi = this.zi; a.Mc = this.Mc; a.Lc = this.Lc; return a };
    function Yl() { this.u = -Infinity; this.m = 0; this.F = pb; this.ir = Zf; this.j = this.V = "None" } Yl.prototype.copy = function () { var a = new Yl; a.u = this.u; a.m = this.m; a.F = this.F.J(); a.ir = this.ir; a.V = this.V; a.j = this.j; return a }; Yl.className = "LinkElementSettings"; function dl() { this.wj = this.Ui = Sc; this.vj = this.Ti = this.uj = this.Si = NaN; this.Lr = this.$p = null; this.Mr = this.aq = Infinity }
    dl.prototype.copy = function () { var a = new dl; a.Ui = this.Ui.J(); a.wj = this.wj.J(); a.Si = this.Si; a.uj = this.uj; a.Ti = this.Ti; a.vj = this.vj; a.$p = this.$p; a.Lr = this.Lr; a.aq = this.aq; a.Mr = this.Mr; return a }; dl.className = "LinkSettings"; function bm() { this.ia = [1, 0, 0, 1, 0, 0] } bm.prototype.copy = function () { var a = new bm; a.ia[0] = this.ia[0]; a.ia[1] = this.ia[1]; a.ia[2] = this.ia[2]; a.ia[3] = this.ia[3]; a.ia[4] = this.ia[4]; a.ia[5] = this.ia[5]; return a };
    bm.prototype.translate = function (a, b) { this.ia[4] += this.ia[0] * a + this.ia[2] * b; this.ia[5] += this.ia[1] * a + this.ia[3] * b }; bm.prototype.scale = function (a, b) { this.ia[0] *= a; this.ia[1] *= a; this.ia[2] *= b; this.ia[3] *= b };
    bm.prototype.rotate = function (a, b, c) { 360 <= a ? a -= 360 : 0 > a && (a += 360); if (0 !== a) { this.translate(b, c); if (90 === a) { a = 0; var d = 1 } else 180 === a ? (a = -1, d = 0) : 270 === a ? (a = 0, d = -1) : (d = a * Math.PI / 180, a = Math.cos(d), d = Math.sin(d)); var e = this.ia[1] * a + this.ia[3] * d, f = this.ia[0] * -d + this.ia[2] * a, g = this.ia[1] * -d + this.ia[3] * a; this.ia[0] = this.ia[0] * a + this.ia[2] * d; this.ia[1] = e; this.ia[2] = f; this.ia[3] = g; this.translate(-b, -c) } }; bm.className = "STransform";
    function cm(a) { this.type = a; this.r2 = this.y2 = this.x2 = this.r1 = this.y1 = this.x1 = 0; this.Zw = []; this.pattern = null } cm.prototype.addColorStop = function (a, b) { this.Zw.push({ offset: a, color: b }) }; cm.className = "SGradient";
    function Ej(a, b, c) { this.ownerDocument = a = void 0 === b ? x.document : b; this.Tz = "http://www.w3.org/2000/svg"; void 0 !== a && (this.La = this.Pd("svg", { width: "1px", height: "1px", viewBox: "0 0 1 1" }), this.La.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns", "http://www.w3.org/2000/svg"), this.La.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink")); this.$r = null; this.context = new Aj(this, c) }
    Ej.prototype.resize = function (a, b, c, d) { return this.width !== a || this.height !== b ? (this.width = a, this.height = b, this.style.width = c + "px", this.style.height = d + "px", this.La.setAttributeNS(null, "width", c + "px"), this.La.setAttributeNS(null, "height", d + "px"), this.La.setAttributeNS(null, "viewBox", "0 0 " + c + " " + d), this.context.pw.firstElementChild.setAttributeNS(null, "width", c + "px"), this.context.pw.firstElementChild.setAttributeNS(null, "height", d + "px"), !0) : !1 };
    Ej.prototype.Pd = function (a, b, c) { a = this.ownerDocument.createElementNS(this.Tz, a); if (za(b)) for (var d in b) a.setAttributeNS("href" === d ? "http://www.w3.org/1999/xlink" : "", d, b[d]); void 0 !== c && (a.textContent = c); return a }; Ej.prototype.getBoundingClientRect = function () { return this.La.getBoundingClientRect() }; Ej.prototype.focus = function () { this.La.focus() }; Ej.prototype.gx = function () { this.ownerDocument = null };
    ma.Object.defineProperties(Ej.prototype, { width: { get: function () { return this.La.width.baseVal.value }, set: function (a) { this.La.setAttribute("width", a + "px") } }, height: { get: function () { return this.La.height.baseVal.value }, set: function (a) { this.La.setAttribute("height", a + "px") } }, style: { get: function () { return this.La.style } } }); Ej.className = "SVGSurface";
    function Aj(a, b) {
        this.zv = a; this.svg = a.La; this.stack = []; this.ih = []; this.fillStyle = "#000000"; this.font = "10px sans-serif"; this.globalAlpha = 1; this.lineCap = "butt"; this.lineDashOffset = 0; this.lineJoin = "miter"; this.lineWidth = 1; this.miterLimit = 10; this.shadowBlur = 0; this.shadowColor = "rgba(0, 0, 0, 0)"; this.shadowOffsetY = this.shadowOffsetX = 0; this.Dl = !1; this.strokeStyle = "#000000"; this.textAlign = "start"; this.clipInsteadOfFill = !1; this.Pg = this.tr = this.sr = 0; this.sd = this.Od = null; this.path = []; this.Ul = {}; this.vt = !1;
        this.Sg = null; this.sj = 0; this.lc = new bm; this.j = Bl(this); this.Lj = Bl(this, "gojs-diagram"); this.Xr = this.pi = null; var c = jb++, d = this.Pd("clipPath", { id: "mainClip" + c }); d.appendChild(this.Pd("rect", { x: 0, y: 0, width: a.width, height: a.height })); this.pw = d; this.svg.appendChild(this.j); this.j.appendChild(this.Lj); this.svg.appendChild(d); this.j.setAttributeNS(null, "clip-path", "url(#mainClip" + c + ")"); this.Vz = {}; this.isTemporary = !!b
    }
    function wj(a, b, c, d, e, f, g) { void 0 === a.Lj ? Al(a, b, c, d, e, f, g) : a.Lj.setAttribute("transform", "matrix(" + b + ", " + c + ", " + d + ", " + e + ", " + f + ", " + g + ")") } t = Aj.prototype;
    t.arc = function (a, b, c, d, e, f, g, h) { var k = 2 * Math.PI, l = k - 1E-6, m = c * Math.cos(d), n = c * Math.sin(d), p = a + m, r = b + n, q = f ? 0 : 1; d = f ? d - e : e - d; (1E-6 < Math.abs(g - p) || 1E-6 < Math.abs(h - r)) && this.path.push(["L", p, +r]); 0 > d && (d = d % k + k); d > l ? (this.path.push(["A", c, c, 0, 1, q, a - m, b - n]), this.path.push(["A", c, c, 0, 1, q, p, r])) : 1E-6 < d && this.path.push(["A", c, c, 0, +(d >= Math.PI), q, a + c * Math.cos(e), b + c * Math.sin(e)]) }; t.beginPath = function () { this.path = []; this.Ul = { stroke: "none", fill: "none" } }; t.Ne = function (a) { this.addPath(this.path, this.lc, a || 0) };
    t.bezierCurveTo = function (a, b, c, d, e, f) { this.path.push(["C", a, b, c, d, e, f]) }; t.clearRect = function () { }; t.clip = function () { this.Ul.clipPath = !0 }; t.closePath = function () { this.path.push(["z"]) }; t.createLinearGradient = function (a, b, c, d) { var e = new cm("linear"); e.x1 = a; e.y1 = b; e.x2 = c; e.y2 = d; return e };
    t.createPattern = function (a) { var b = ""; a instanceof HTMLCanvasElement && (b = a.toDataURL()); a instanceof HTMLImageElement && (b = a.getAttribute("src")); var c = this.Vz; if (c[b]) return "url(#" + c[b] + ")"; var d = "PATTERN" + jb++, e = { x: 0, y: 0, width: a.width, height: a.height, href: b }; a = this.Pd("pattern", { width: a.width, height: a.height, id: d, patternUnits: "userSpaceOnUse" }); a.appendChild(this.Pd(Xi, e)); this.svg.appendChild(a); c[b] = d; return "url(#" + d + ")" };
    t.createRadialGradient = function (a, b, c, d, e, f) { var g = new cm("radial"); g.x1 = a; g.y1 = b; g.r1 = c; g.x2 = d; g.y2 = e; g.r2 = f; return g };
    t.drawImage = function (a, b, c, d, e, f, g, h, k) {
        f = ""; a instanceof HTMLCanvasElement && (f = a.toDataURL()); a instanceof HTMLImageElement && (f = a.getAttribute("src")); g = a instanceof HTMLImageElement ? a.naturalWidth : a.width; var l = a instanceof HTMLImageElement ? a.naturalHeight : a.height; void 0 === d && (h = d = g, k = e = l); d = d || 0; e = e || 0; h = h || 0; k = k || 0; a = { x: 0, y: 0, width: h || g || d, height: k || l || e, href: f }; K.$(d, h) && K.$(e, k) ? -1 === f.toLowerCase().indexOf(".svg") && -1 === f.toLowerCase().indexOf("data:image/svg") && (a.preserveAspectRatio = "xMidYMid slice") :
            a.preserveAspectRatio = "none"; if (0 !== b || 0 !== c || d !== g || e !== l) h = "CLIP" + jb++, k = this.Pd("clipPath", { id: h }), k.appendChild(this.Pd("rect", { x: b, y: c, width: d, height: e })), this.svg.appendChild(k), a["clip-path"] = "url(#" + h + ")"; dm(this, Xi, a, this.lc); this.addElement(Xi, a)
    }; t.fill = function (a) { a && (this.Ul["fill-rule"] = "evenodd"); em(this, this.Ul) }; t.jg = function (a, b) { this.clipInsteadOfFill ? this.clip() : this.fill(b) };
    t.fillRect = function (a, b, c, d) { a = [a, b, c, d]; a = { x: a[0], y: a[1], width: a[2], height: a[3] }; dm(this, "fill", a, this.lc); this.addElement("rect", a) }; t.ix = function (a, b, c, d) { a = { x: a, y: b, width: c, height: d }; dm(this, "fill", a, this.lc); em(this, a); this.svg.prepend(this.Pd("rect", a)) }; t.fillText = function (a, b, c) { a = [a, b, c]; b = this.textAlign; "left" === b ? b = "start" : "right" === b ? b = "end" : "center" === b && (b = "middle"); b = { x: a[1], y: a[2], style: "font: " + this.font, "text-anchor": b }; dm(this, "fill", b, this.lc); this.addElement("text", b, a[0]) };
    t.lineTo = function (a, b) { this.path.push(["L", a, b]) }; t.moveTo = function (a, b) { this.path.push(["M", a, b]) }; t.quadraticCurveTo = function (a, b, c, d) { this.path.push(["Q", a, b, c, d]) }; t.rect = function (a, b, c, d) { this.path.push(["M", a, b], ["L", a + c, b], ["L", a + c, b + d], ["L", a, b + d], ["z"]) };
    t.restore = function () { this.lc = this.stack.pop(); this.path = this.stack.pop(); var a = this.stack.pop(); this.fillStyle = a.fillStyle; this.font = a.font; this.globalAlpha = a.globalAlpha; this.lineCap = a.lineCap; this.lineDashOffset = a.lineDashOffset; this.lineJoin = a.lineJoin; this.lineWidth = a.lineWidth; this.miterLimit = a.miterLimit; this.shadowBlur = a.shadowBlur; this.shadowColor = a.shadowColor; this.shadowOffsetX = a.shadowOffsetX; this.shadowOffsetY = a.shadowOffsetY; this.strokeStyle = a.strokeStyle; this.textAlign = a.textAlign };
    t.save = function () { this.stack.push({ fillStyle: this.fillStyle, font: this.font, globalAlpha: this.globalAlpha, lineCap: this.lineCap, lineDashOffset: this.lineDashOffset, lineJoin: this.lineJoin, lineWidth: this.lineWidth, miterLimit: this.miterLimit, shadowBlur: this.shadowBlur, shadowColor: this.shadowColor, shadowOffsetX: this.shadowOffsetX, shadowOffsetY: this.shadowOffsetY, strokeStyle: this.strokeStyle, textAlign: this.textAlign }); for (var a = [], b = 0; b < this.path.length; b++)a.push(this.path[b]); this.stack.push(a); this.stack.push(this.lc.copy()) };
    t.setTransform = function (a, b, c, d, e, f) { 1 === a && 0 === b && 0 === c && 1 === d && 0 === e && 0 === f || Al(this, a, b, c, d, e, f) }; t.scale = function (a, b) { this.lc.scale(a, b) }; t.translate = function (a, b) { this.lc.translate(a, b) }; t.transform = function () { }; t.vc = function () { }; t.stroke = function () { fm(this, this.Ul) }; t.ek = function () { this.clipInsteadOfFill || this.stroke() }; t.Pd = function (a, b, c) { return this.zv.Pd(a, b, c) }; t.addElement = function (a, b, c) { a = this.Pd(a, b, c); null !== this.sd ? this.sd.appendChild(a) : this.sd = a; return this.Od = a };
    function dm(a, b, c, d) { "fill" === b ? em(a, c) : "stroke" === b && fm(a, c); null !== d && (a = d.ia, c.transform = "matrix(" + a[0] + ", " + a[1] + ", " + a[2] + ", " + a[3] + ", " + a[4] + ", " + a[5] + ")") } function em(a, b) { a.fillStyle instanceof cm ? b.fill = gm(a, a.fillStyle) : /^rgba\(/.test(a.fillStyle) ? (a = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle), b.fill = "rgb(" + a[1] + "," + a[2] + "," + a[3] + ")", b["fill-opacity"] = a[4]) : b.fill = a.fillStyle }
    function hm(a, b, c) { c = c ? "fill" : "stroke"; a.fillStyle instanceof cm ? b.setAttributeNS(null, "fill", gm(a, a.fillStyle)) : /^rgba\(/.test(a.fillStyle) ? (a = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle), b.setAttributeNS(null, c, "rgb(" + a[1] + "," + a[2] + "," + a[3] + ")"), b.setAttributeNS(null, c + "-opacity", a[4])) : b.setAttributeNS(null, c, a.fillStyle) }
    function fm(a, b) {
        if (a.strokeStyle instanceof cm) b.stroke = gm(a, a.strokeStyle); else if (/^rgba\(/.test(a.strokeStyle)) { var c = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle); b.stroke = "rgb(" + c[1] + "," + c[2] + "," + c[3] + ")"; b["stroke-opacity"] = c[4] } else b.stroke = a.strokeStyle; b["stroke-width"] = a.lineWidth; b["stroke-linecap"] = a.lineCap; b["stroke-linejoin"] = a.lineJoin; b["stroke-miterlimit"] = a.miterLimit; a.vt && (b["stroke-dasharray"] = a.Sg.toString(), b["stroke-dashoffset"] =
            a.sj)
    }
    function gm(a, b) {
        var c = "GRAD" + jb++; if ("linear" === b.type) var d = a.Pd("linearGradient", { x1: b.x1, x2: b.x2, y1: b.y1, y2: b.y2, id: c, gradientUnits: "userSpaceOnUse" }); else if ("radial" === b.type) d = a.Pd("radialGradient", { x1: b.x1, x2: b.x2, y1: b.y1, y2: b.y2, r1: b.r1, r2: b.r2, id: c }); else throw Error("invalid gradient"); var e = b.Zw; b = e.length; for (var f = [], g = 0; g < b; g++) {
            var h = e[g], k = h.color; h = { offset: h.offset, "stop-color": k }; /^rgba\(/.test(k) && (k = /^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(k), h["stop-color"] =
                "rgb(" + k[1] + "," + k[2] + "," + k[3] + ")", h["stop-opacity"] = k[4]); f.push(h)
        } f.sort(function (a, b) { return a.offset > b.offset ? 1 : -1 }); for (e = 0; e < b; e++)d.appendChild(a.Pd("stop", f[e])); a.svg.appendChild(d); return "url(#" + c + ")"
    }
    t.addPath = function (a, b, c) {
        var d = []; for (var e = 0; e < a.length; e++) { var f = Ba(a[e]), g = [f.shift()]; if ("A" === g[0]) g.push(f.shift() + "," + f.shift(), f.shift(), f.shift() + "," + f.shift(), f.shift() + "," + f.shift()); else for (; f.length;)g.push(f.shift() + "," + f.shift()); d.push(g.join(" ")) } d = d.join(" "); a = this.Ul; if (a.clipPath) 0 < this.ih.length && this.ih[this.ih.length - 1].setAttributeNS(null, "clip-path", 'path("' + d + '")'); else if (a.d = d, dm(this, "", a, b), null !== this.Xr) if (b = this.Xr, "g" !== b.nodeName) for (var h in a) "transform" !==
            h && b.setAttributeNS(null, h, a[h]); else { h = b.getElementsByTagName("path"); for (var k in a) "transform" !== k && h[c].setAttributeNS(null, k, a[k]) } else this.addElement("path", a)
    }; function Al(a, b, c, d, e, f, g) { var h = new bm; h.ia = [b, c, d, e, f, g]; b = {}; dm(a, "g", b, h); h = a.addElement("g", b); a.ih.push(h) } function Bl(a, b) { var c = {}; b && (c["class"] = b); b = a.addElement("g", c); a.ih.push(b); return b } t.xv = function (a, b, c) { this.sr = a; this.tr = b; this.Pg = c }; function tl(a) { a.Dl = !1; a.shadowOffsetX = 0; a.shadowOffsetY = 0; a.shadowBlur = 0 }
    function sl(a) { a.Dl = !0; a.shadowOffsetX = a.sr; a.shadowOffsetY = a.tr; a.shadowBlur = a.Pg } t.bs = function (a, b) { this.vt = !0; this.Sg = a; this.sj = b }; t.Yr = function () { this.vt = !1 }; t.Qc = function () { }; t.Sx = function () { }; Aj.prototype.rotate = function (a) { this.lc.rotate(57.2958 * a, 0, 0) }; Aj.prototype.getImageData = function () { return null }; Aj.prototype.measureText = function () { return null };
    function Cl(a, b) { b = b.getAttribute("fill"); null !== b && (b = b.split("#"), 1 !== b.length && (b = a.svg.getElementById(b[1].slice(0, -1)), null !== b && 1 === a.svg.innerHTML.match(new RegExp(b.id, "g")).length && b.remove())) } Aj.prototype.As = function (a) { null !== a.svg && a.svg.parentNode === this.Lj && a.svg.remove() }; Aj.className = "SVGContext";
    Q.prototype.vs = function (a) { this.nj.contains("SVG") || this.Tr("SVG", new Ej(this, x.document)); var b = new Ej(this, x.document, !0); void 0 === a && (a = new Ta); var c = this; return Dk(this, function (a, e) { a = Ek(c, a, "SVG", b); a = null !== a ? a.svg : null; return "function" === typeof e ? (e(a), null) : a }, a) }; Q.prototype.makeSvg = Q.prototype.vs; Q.prototype.Xu = function (a) { return this.vs(a) }; Q.prototype.makeSVG = Q.prototype.Xu;
    function Ni(a, b, c, d) { this.ownerDocument = b = void 0 === b ? x.document : b; this.$r = null; b = b.createElement("canvas"); b.tabIndex = 0; this.La = b; this.La.innerHTML = "This text is displayed if your browser does not support the Canvas HTML element."; this.context = new El(b, c, d); b.H = a } Ni.prototype.resize = function (a, b, c, d) { return this.width !== a || this.height !== b ? (this.width = a, this.height = b, this.style.width = c + "px", this.style.height = d + "px", !0) : !1 }; Ni.prototype.toDataURL = function (a, b) { return this.La.toDataURL(a, b) };
    Ni.prototype.getBoundingClientRect = function () { return this.La.getBoundingClientRect() }; Ni.prototype.focus = function () { this.La.focus() }; Ni.prototype.gx = function () { this.ownerDocument = this.La.H = null }; ma.Object.defineProperties(Ni.prototype, { width: { get: function () { return this.La.width }, set: function (a) { this.La.width = a } }, height: { get: function () { return this.La.height }, set: function (a) { this.La.height = a } }, style: { get: function () { return this.La.style } } });
    Ni.className = "CanvasSurface"; function El(a, b, c) { this.Z = a.getContext ? a.getContext("2d", b) : null; null === this.Z && C("Browser does not support HTML Canvas Element"); this.Vs = this.Xs = this.Ws = ""; this.Em = !1; this.Pg = this.tr = this.sr = 0; this.Dl = !1; this.Al = new ld; this.isTemporary = !!c } t = El.prototype; t.Sx = function (a) { this.Z.imageSmoothingEnabled = a }; t.arc = function (a, b, c, d, e, f) { this.Z.arc(a, b, c, d, e, f) }; t.beginPath = function () { this.Z.beginPath() }; t.Ne = function () { };
    t.bezierCurveTo = function (a, b, c, d, e, f) { this.Z.bezierCurveTo(a, b, c, d, e, f) }; t.clearRect = function (a, b, c, d) { this.vc(); this.Z.clearRect(a, b, c, d) }; t.clip = function () { this.Z.clip() }; t.closePath = function () { this.Z.closePath() }; t.createLinearGradient = function (a, b, c, d) { return this.Z.createLinearGradient(a, b, c, d) }; t.createPattern = function (a, b) { return this.Z.createPattern(a, b) }; t.createRadialGradient = function (a, b, c, d, e, f) { return this.Z.createRadialGradient(a, b, c, d, e, f) };
    t.drawImage = function (a, b, c, d, e, f, g, h, k) { void 0 === d ? this.Z.drawImage(a, b, c) : this.Z.drawImage(a, b, c, d, e, f, g, h, k) }; t.fill = function (a) { this.Z.fill(a ? "evenodd" : "nonzero") }; t.fillRect = function (a, b, c, d) { this.Z.fillRect(a, b, c, d) }; t.ix = function (a, b, c, d) { this.Z.fillRect(a, b, c, d) }; t.fillText = function (a, b, c) { this.Z.fillText(a, b, c) }; t.getImageData = function (a, b, c, d) { return this.Z.getImageData(a, b, c, d) }; t.lineTo = function (a, b) { this.Z.lineTo(a, b) }; t.measureText = function (a) { return this.Z.measureText(a) };
    t.moveTo = function (a, b) { this.Z.moveTo(a, b) }; t.quadraticCurveTo = function (a, b, c, d) { this.Z.quadraticCurveTo(a, b, c, d) }; t.rect = function (a, b, c, d) { this.Z.rect(a, b, c, d) }; t.restore = function () { this.Z.restore() }; t.save = function () { this.vc(); this.Z.save() }; El.prototype.rotate = function (a) { this.Al.rotate(57.2958 * a, 0, 0) }; t = El.prototype; t.setTransform = function (a, b, c, d, e, f) { this.Al.setTransform(a, b, c, d, e, f) }; t.scale = function (a, b) { this.Al.scale(a, b) }; t.translate = function (a, b) { this.Al.translate(a, b) }; t.stroke = function () { this.Z.stroke() };
    t.transform = function (a, b, c, d, e, f) { if (1 !== a || 0 !== b || 0 !== c || 1 !== d || 0 !== e || 0 !== f) { var g = this.Al, h = g.m11 * a + g.m21 * b; a = g.m12 * a + g.m22 * b; b = g.m11 * c + g.m21 * d; c = g.m12 * c + g.m22 * d; g.dx = g.m11 * e + g.m21 * f + g.dx; g.dy = g.m12 * e + g.m22 * f + g.dy; g.m11 = h; g.m12 = a; g.m21 = b; g.m22 = c } }; t.vc = function () { var a = this.Al; this.Z.setTransform(a.m11, a.m12, a.m21, a.m22, a.dx, a.dy) };
    t.jg = function (a, b) { if (a instanceof pl && a.type === ql) { var c = a.tk; a = a.Ys; a > c ? (this.scale(c / a, 1), this.translate((a - c) / 2, 0)) : c > a && (this.scale(1, a / c), this.translate(0, (c - a) / 2)); this.Em ? this.clip() : this.fill(b); a > c ? (this.translate(-(a - c) / 2, 0), this.scale(1 / (c / a), 1)) : c > a && (this.translate(0, -(c - a) / 2), this.scale(1, 1 / (a / c))) } else this.Em ? this.clip() : this.fill(b) }; t.ek = function () { this.Em || this.stroke() }; t.xv = function (a, b, c) { this.sr = a; this.tr = b; this.Pg = c };
    t.bs = function (a, b) { var c = this.Z; void 0 !== c.setLineDash && (c.setLineDash(a), c.lineDashOffset = b) }; t.Yr = function () { var a = this.Z; void 0 !== a.setLineDash && (a.setLineDash(im), a.lineDashOffset = 0) }; t.Qc = function (a) { a && (this.Ws = ""); this.Vs = this.Xs = "" }; t.As = function () { };
    ma.Object.defineProperties(El.prototype, {
        fillStyle: { get: function () { return this.Z.fillStyle }, set: function (a) { this.Vs !== a && (this.Vs = this.Z.fillStyle = a) } }, font: { get: function () { return this.Z.font }, set: function (a) { this.Ws !== a && (this.Ws = this.Z.font = a) } }, globalAlpha: { get: function () { return this.Z.globalAlpha }, set: function (a) { this.Z.globalAlpha = a } }, lineCap: {
            get: function () { return this.Z.lineCap },
            set: function (a) { this.Z.lineCap = a }
        }, lineDashOffset: { get: function () { return this.Z.lineDashOffset }, set: function (a) { this.Z.lineDashOffset = a } }, lineJoin: { get: function () { return this.Z.lineJoin }, set: function (a) { this.Z.lineJoin = a } }, lineWidth: { get: function () { return this.Z.lineWidth }, set: function (a) { this.Z.lineWidth = a } }, miterLimit: {
            get: function () { return this.Z.miterLimit }, set: function (a) {
                this.Z.miterLimit =
                a
            }
        }, shadowBlur: { get: function () { return this.Z.shadowBlur }, set: function (a) { this.Z.shadowBlur = a } }, shadowColor: { get: function () { return this.Z.shadowColor }, set: function (a) { this.Z.shadowColor = a } }, shadowOffsetX: { get: function () { return this.Z.shadowOffsetX }, set: function (a) { this.Z.shadowOffsetX = a } }, shadowOffsetY: {
            get: function () { return this.Z.shadowOffsetY }, set: function (a) {
                this.Z.shadowOffsetY =
                a
            }
        }, strokeStyle: { get: function () { return this.Z.strokeStyle }, set: function (a) { this.Xs !== a && (this.Xs = this.Z.strokeStyle = a) } }, textAlign: { get: function () { return this.Z.textAlign }, set: function (a) { this.Z.textAlign = a } }, imageSmoothingEnabled: { get: function () { return this.Z.imageSmoothingEnabled }, set: function (a) { this.Z.imageSmoothingEnabled = a } }, clipInsteadOfFill: {
            get: function () { return this.Em },
            set: function (a) { this.Em = a }
        }
    }); var im = Object.freeze([]); El.className = "CanvasSurfaceContext"; function jm() { this.F = this.m = this.u = this.j = 0 } jm.className = "ColorNumbers";
    function pl(a, b) { km || (lm(), km = !0); Qa(this); this._isFrozen = !1; void 0 === a ? (this.na = Dl, this.sk = "black") : "string" === typeof a ? (this.na = Dl, this.sk = a) : (this.na = a, this.sk = "black"); a = this.na; a === Gl ? (this.Hd = tc, this.Gk = Ac) : this.Gk = a === ql ? this.Hd = xc : this.Hd = rc; this.yr = 0; this.Qp = NaN; this.te = this.Wq = this.se = null; this.Ys = this.tk = 0; b && Object.assign(this, b) }
    pl.prototype.copy = function () { var a = new pl; a.na = this.na; a.sk = this.sk; a.Hd = this.Hd.J(); a.Gk = this.Gk.J(); a.yr = this.yr; a.Qp = this.Qp; null !== this.se && (a.se = this.se.copy()); a.Wq = this.Wq; return a }; t = pl.prototype; t.freeze = function () { this._isFrozen = !0; null !== this.se && this.se.freeze(); return this }; t.ka = function () { Object.isFrozen(this) && C("cannot thaw constant: " + this); this._isFrozen = !1; null !== this.se && this.se.ka(); return this }; t.rb = function (a) { a.classType === pl && (this.type = a) };
    t.toString = function () { var a = "Brush("; if (this.type === Dl) a += this.color; else if (a = this.type === Gl ? a + "Linear " : this.type === ql ? a + "Radial " : this.type === Fl ? a + "Pattern " : a + "(unknown) ", a += this.start + " " + this.end, null !== this.colorStops) for (var b = this.colorStops.iterator; b.next();)a += " " + b.key + ":" + b.value; return a + ")" };
    t.addColorStop = function (a, b) { this._isFrozen && va(this); ("number" !== typeof a || !isFinite(a) || 1 < a || 0 > a) && xa(a, "0 <= loc <= 1", pl, "addColorStop:loc"); null === this.se && (this.se = new ob); this.se.add(a, b); this.na === Dl && (this.type = Gl); this.te = null; return this };
    t.Mz = function (a, b) { this._isFrozen && va(this); a = void 0 === a || "number" !== typeof a ? .2 : a; b = void 0 === b ? mm : b; if (this.type === Dl) Th(this.color), this.color = nm(a, b); else if ((this.type === Gl || this.type === ql) && null !== this.colorStops) for (var c = this.colorStops.iterator; c.next();)Th(c.value), this.addColorStop(c.key, nm(a, b)); return this }; function om(a, b, c) { b = void 0 === b || "number" !== typeof b ? .2 : b; c = void 0 === c ? mm : c; Th(a); return nm(b, c) }
    t.Oy = function (a, b) { this._isFrozen && va(this); a = void 0 === a || "number" !== typeof a ? .2 : a; b = void 0 === b ? mm : b; if (this.type === Dl) Th(this.color), this.color = nm(-a, b); else if ((this.type === Gl || this.type === ql) && null !== this.colorStops) for (var c = this.colorStops.iterator; c.next();)Th(c.value), this.addColorStop(c.key, nm(-a, b)); return this }; function pm(a, b, c) { b = void 0 === b || "number" !== typeof b ? .2 : b; c = void 0 === c ? mm : c; Th(a); return nm(-b, c) }
    function qm(a, b, c) { Th(a); a = rm.j; var d = rm.u, e = rm.m, f = rm.F; Th(b); void 0 === c && (c = .5); return "rgba(" + Math.round((rm.j - a) * c + a) + ", " + Math.round((rm.u - d) * c + d) + ", " + Math.round((rm.m - e) * c + e) + ", " + Math.round((rm.F - f) * c + f) + ")" }
    t.zx = function () { if (this.type === Dl) return sm(this.color); if ((this.type === Gl || this.type === ql) && null !== this.colorStops) { var a = this.colorStops; if (this.type === ql) return sm(a.first().value); if (null !== a.get(.5)) return sm(a.get(.5)); if (2 === a.count) return a = a.Fa(), sm(qm(a[0].value, a[1].value)); for (var b = a.iterator, c = -1, d = -1, e = 1, f = 1; b.next();) { var g = b.key, h = Math.abs(.5 - b.key); e > f && h < e ? (c = g, e = h) : f >= e && h < f && (d = g, f = h) } c > d && (f = c, c = d, d = f, f = e); e = d - c; return sm(qm(a.get(c), a.get(d), 1 - f / e)) } return !1 };
    function sm(a) { if (!a) return !1; if (a instanceof pl) return a.zx(); Th(a); return 128 > (299 * rm.j + 587 * rm.u + 114 * rm.m) / 1E3 }
    function nm(a, b) {
        switch (b) {
            case mm: b = 100 * tm(rm.j); var c = 100 * tm(rm.u), d = 100 * tm(rm.m); um.j = .4124564 * b + .3575761 * c + .1804375 * d; um.u = .2126729 * b + .7151522 * c + .072175 * d; um.m = .0193339 * b + .119192 * c + .9503041 * d; um.F = rm.F; b = vm(um.j / wm[0]); c = vm(um.u / wm[1]); d = vm(um.m / wm[2]); xm.j = 116 * c - 16; xm.u = 500 * (b - c); xm.m = 200 * (c - d); xm.F = um.F; xm.j = Math.min(100, Math.max(0, xm.j + 100 * a)); a = (xm.j + 16) / 116; b = a - xm.m / 200; um.j = wm[0] * ym(xm.u / 500 + a); um.u = wm[1] * (xm.j > zm * Am ? Math.pow(a, 3) : xm.j / zm); um.m = wm[2] * ym(b); um.F = xm.F; a = -.969266 * um.j + 1.8760108 *
                um.u + .041556 * um.m; b = .0556434 * um.j + -.2040259 * um.u + 1.0572252 * um.m; rm.j = 255 * Bm((3.2404542 * um.j + -1.5371385 * um.u + -.4985314 * um.m) / 100); rm.u = 255 * Bm(a / 100); rm.m = 255 * Bm(b / 100); rm.F = um.F; rm.j = Math.round(rm.j); 255 < rm.j ? rm.j = 255 : 0 > rm.j && (rm.j = 0); rm.u = Math.round(rm.u); 255 < rm.u ? rm.u = 255 : 0 > rm.u && (rm.u = 0); rm.m = Math.round(rm.m); 255 < rm.m ? rm.m = 255 : 0 > rm.m && (rm.m = 0); return "rgba(" + rm.j + ", " + rm.u + ", " + rm.m + ", " + rm.F + ")"; case Cm: return Uh(), Vh.m = Math.min(100, Math.max(0, Vh.m + 100 * a)), "hsla(" + Vh.j + ", " + Vh.u + "%, " + Vh.m + "%, " +
                    Vh.F + ")"; default: C("Unknown color space: " + b)
        }
    } function Th(a) { km || (lm(), km = !0); var b = Dm; if (null !== b) { b.clearRect(0, 0, 1, 1); b.fillStyle = "#000000"; var c = b.fillStyle; b.fillStyle = a; b.fillStyle !== c ? (b.fillRect(0, 0, 1, 1), a = b.getImageData(0, 0, 1, 1).data, rm.j = a[0], rm.u = a[1], rm.m = a[2], rm.F = a[3] / 255) : (b.fillStyle = "#FFFFFF", b.fillStyle = a, rm.j = 0, rm.u = 0, rm.m = 0, rm.F = 1) } }
    function Uh() { var a = rm.j / 255, b = rm.u / 255, c = rm.m / 255, d = Math.max(a, b, c), e = Math.min(a, b, c), f = d - e; e = (d + e) / 2; if (0 === f) var g = a = 0; else { switch (d) { case a: g = (b - c) / f % 6; break; case b: g = (c - a) / f + 2; break; case c: g = (a - b) / f + 4 }g *= 60; 0 > g && (g += 360); a = f / (1 - Math.abs(2 * e - 1)) } Vh.j = Math.round(g); Vh.u = Math.round(100 * a); Vh.m = Math.round(100 * e); Vh.F = rm.F } function tm(a) { a /= 255; return .04045 >= a ? a / 12.92 : Math.pow((a + .055) / 1.055, 2.4) } function Bm(a) { return .0031308 >= a ? 12.92 * a : 1.055 * Math.pow(a, 1 / 2.4) - .055 }
    function vm(a) { return a > Am ? Math.pow(a, 1 / 3) : (zm * a + 16) / 116 } function ym(a) { var b = a * a * a; return b > Am ? b : (116 * a - 16) / zm } function Zl(a, b) { "string" !== typeof a && (a instanceof pl || C("Value for " + b + " must be a color string or a Brush, not " + a)) } function lm() { Dm = Ng ? (new Ni(null, void 0, { willReadFrequently: !0 })).context : null }
    ma.Object.defineProperties(pl.prototype, {
        type: { get: function () { return this.na }, set: function (a) { this._isFrozen && va(this, a); this.na = a; this.start.Jc() && (a === Gl ? this.start = tc : a === ql && (this.start = xc)); this.end.Jc() && (a === Gl ? this.end = Ac : a === ql && (this.end = xc)); this.te = null } }, color: { get: function () { return this.sk }, set: function (a) { this._isFrozen && va(this, a); this.sk = a; this.te = null } }, start: {
            get: function () { return this.Hd },
            set: function (a) { this._isFrozen && va(this, a); this.Hd = a.J(); this.te = null }
        }, end: { get: function () { return this.Gk }, set: function (a) { this._isFrozen && va(this, a); this.Gk = a.J(); this.te = null } }, startRadius: { get: function () { return this.yr }, set: function (a) { this._isFrozen && va(this, a); 0 > a && xa(a, ">= zero", pl, "startRadius"); this.yr = a; this.te = null } }, endRadius: {
            get: function () { return this.Qp }, set: function (a) {
                this._isFrozen && va(this,
                    a); 0 > a && xa(a, ">= zero", pl, "endRadius"); this.Qp = a; this.te = null
            }
        }, colorStops: { get: function () { return this.se }, set: function (a) { this._isFrozen && va(this, a); this.se = a; this.te = null } }, pattern: { get: function () { return this.Wq }, set: function (a) { this._isFrozen && va(this, a); this.Wq = a; this.te = null } }
    }); pl.prototype.isDark = pl.prototype.zx; pl.prototype.darkenBy = pl.prototype.Oy; pl.prototype.lightenBy = pl.prototype.Mz; pl.prototype.addColorStop = pl.prototype.addColorStop;
    var Am = 216 / 24389, zm = 24389 / 27, wm = [95.047, 100, 108.883], Dm = null, rm = new jm, Vh = new jm, um = new jm, xm = new jm, km = !1; pl.className = "Brush"; var Dl; pl.Solid = Dl = new D(pl, "Solid", 0); var Gl; pl.Linear = Gl = new D(pl, "Linear", 1); var ql; pl.Radial = ql = new D(pl, "Radial", 2); var Fl; pl.Pattern = Fl = new D(pl, "Pattern", 4); var mm; pl.Lab = mm = new D(pl, "Lab", 5); var Cm; pl.HSL = Cm = new D(pl, "HSL", 6);
    pl.randomColor = function (a, b) { void 0 === a && (a = 128); void 0 === b && (b = Math.max(a, 255)); var c = Math.abs(b - a); b = Math.floor(a + Math.random() * c).toString(16); var d = Math.floor(a + Math.random() * c).toString(16); a = Math.floor(a + Math.random() * c).toString(16); 2 > b.length && (b = "0" + b); 2 > d.length && (d = "0" + d); 2 > a.length && (a = "0" + a); return "#" + b + d + a };
    pl.isValidColor = function (a) { if ("black" === a) return !0; if ("" === a) return !1; km || (lm(), km = !0); var b = Dm; if (null === b) return !0; b.fillStyle = "#000000"; var c = b.fillStyle; b.fillStyle = a; if (b.fillStyle !== c) return !0; b.fillStyle = "#FFFFFF"; c = b.fillStyle; b.fillStyle = a; return b.fillStyle !== c }; pl.lighten = function (a) { return om(a) }; pl.lightenBy = om; pl.darken = function (a) { return pm(a) }; pl.darkenBy = pm; pl.mix = qm; pl.isDark = sm; function Vl() { this.wa = "Base" } Vl.prototype.measure = function () { };
    Vl.prototype.Pz = function (a, b, c, d, e) { a.measure(b, c, d, e) }; Vl.prototype.arrange = function () { }; Vl.prototype.Ey = function (a, b, c, d, e, f) { a.arrange(b, c, d, e, f) }; Vl.prototype.Zz = function (a) { a.Sj() }; Vl.prototype.cc = function () { }; ma.Object.defineProperties(Vl.prototype, { name: { get: function () { return this.wa }, set: function (a) { this.wa = a } }, classType: { get: function () { return W } } }); Vl.prototype.remeasureObject = Vl.prototype.Zz; Vl.prototype.arrangeElement = Vl.prototype.Ey;
    Vl.prototype.measureElement = Vl.prototype.Pz; Vl.className = "PanelLayout"; function Em() { this.wa = "Base" } la(Em, Vl);
    Em.prototype.measure = function (a, b, c, d, e, f, g) { var h = d.length; a = Fm(a); for (var k = 0; k < h; k++) { var l = d[k]; if (l.visible || l === a) { var m = l.margin, n = m.right + m.left; m = m.top + m.bottom; l.measure(b, c, f, g); var p = l.measuredBounds; n = Math.max(p.width + n, 0); m = Math.max(p.height + m, 0); p = l.position.x; var r = l.position.y; isFinite(p) || (p = 0); isFinite(r) || (r = 0); l instanceof Bf && l.isGeometryPositioned && (l = l.strokeWidth / 2, p -= l, r -= l); Yb(e, p, r, n, m) } } };
    Em.prototype.arrange = function (a, b, c) { var d = b.length, e = a.padding; a = c.x - e.left; c = c.y - e.top; for (e = 0; e < d; e++) { var f = b[e], g = f.measuredBounds, h = f.margin, k = f.position.x, l = f.position.y; k = isNaN(k) ? -a : k - a; l = isNaN(l) ? -c : l - c; if (f instanceof Bf && f.isGeometryPositioned) { var m = f.strokeWidth / 2; k -= m; l -= m } f.visible && f.arrange(k + h.left, l + h.top, g.width, g.height) } }; function Gm() { this.wa = "Base" } la(Gm, Vl);
    Gm.prototype.measure = function (a, b, c, d, e, f, g) {
        var h = d.length; b = Ea(); f = Fm(a); for (var k = 0; k < h; k++) { var l = d[k]; if (l.visible || l === f) { var m = il(l, !1); if (m !== kg && m !== Vk) b.push(l); else { l.measure(Infinity, c, 0, g); m = l.margin; l = l.measuredBounds; var n = Math.max(l.height + m.top + m.bottom, 0); e.width += Math.max(l.width + m.right + m.left, 0); e.height = Math.max(e.height, n) } } } d = b.length; a.desiredSize.height ? c = Math.min(a.desiredSize.height, a.maxSize.height) : 0 !== e.height && (c = Math.min(e.height, a.maxSize.height)); for (a = 0; a < d; a++)if (k =
            b[a], k.visible || k === f) m = k.margin, h = m.right + m.left, m = m.top + m.bottom, k.measure(Infinity, c, 0, g), k = k.measuredBounds, m = Math.max(k.height + m, 0), e.width += Math.max(k.width + h, 0), e.height = Math.max(e.height, m); Ga(b)
    };
    Gm.prototype.arrange = function (a, b, c) {
        for (var d = b.length, e = a.padding, f = e.top, g = a.isOpposite, h = g ? c.width : e.left, k = 0; k < d; k++) {
            var l = f, m = b[k]; if (m.visible) {
                var n = m.measuredBounds, p = m.margin, r = p.top + p.bottom, q = f + e.bottom, u = n.height, w = il(m, !1); if (isNaN(m.desiredSize.height) && w === nd || w === Wk) u = Math.max(c.height - r - q, 0); r = u + r + q; q = m.alignment; q.Tb() && (q = a.defaultAlignment); q.tb() || (q = xc); g && (h -= n.width + p.left + p.right); m.arrange(h + q.offsetX + p.left, l + q.offsetY + p.top + (c.height * q.y - r * q.y), n.width, u); g || (h += n.width +
                    p.left + p.right)
            }
        }
    }; function Hm() { this.wa = "Base" } la(Hm, Vl);
    Hm.prototype.measure = function (a, b, c, d, e, f) {
        var g = d.length; c = Ea(); for (var h = Fm(a), k = 0; k < g; k++) { var l = d[k]; if (l.visible || l === h) { var m = il(l, !1); if (m !== kg && m !== Wk) c.push(l); else { var n = l.margin; m = n.right + n.left; n = n.top + n.bottom; l.measure(b, Infinity, f, 0); l = l.measuredBounds; Pb(e, Math.max(e.width, Math.max(l.width + m, 0)), e.height + Math.max(l.height + n, 0)) } } } d = c.length; if (0 !== d) {
            a.desiredSize.width ? b = Math.min(a.desiredSize.width, a.maxSize.width) : 0 !== e.width && (b = Math.min(e.width, a.maxSize.width)); for (a = 0; a < d; a++)if (k =
                c[a], k.visible || k === h) l = k.margin, g = l.right + l.left, l = l.top + l.bottom, k.measure(b, Infinity, f, 0), k = k.measuredBounds, l = Math.max(k.height + l, 0), e.width = Math.max(e.width, Math.max(k.width + g, 0)), e.height += l; Ga(c)
        }
    };
    Hm.prototype.arrange = function (a, b, c) {
        for (var d = b.length, e = a.padding, f = e.left, g = a.isOpposite, h = g ? c.height : e.top, k = 0; k < d; k++) {
            var l = f, m = b[k]; if (m.visible) {
                var n = m.measuredBounds, p = m.margin, r = p.left + p.right, q = f + e.right, u = n.width, w = il(m, !1); if (isNaN(m.desiredSize.width) && w === nd || w === Vk) u = Math.max(c.width - r - q, 0); r = u + r + q; q = m.alignment; q.Tb() && (q = a.defaultAlignment); q.tb() || (q = xc); g && (h -= n.height + p.bottom + p.top); m.arrange(l + q.offsetX + p.left + (c.width * q.x - r * q.x), h + q.offsetY + p.top, u, n.height); g || (h += n.height +
                    p.bottom + p.top)
            }
        }
    }; function Im() { this.wa = "Base" } la(Im, Vl);
    Im.prototype.measure = function (a, b, c, d, e, f, g) {
        var h = d.length, k = a.sb(), l = k.margin, m = l.right + l.left, n = l.top + l.bottom; k.measure(b, c, f, g); var p = k.measuredBounds; f = p.width; g = p.height; var r = Math.max(f + m, 0); var q = Math.max(g + n, 0); var u = a.isClipping, w = N.allocAt(-l.left, -l.top, r, q), v = N.allocAt(0, 0, f, g); Jm(k, v, !0); f = v.width; g = v.height; for (var z = !0, y = Fm(a), A = 0; A < h; A++) {
            var B = d[A], E = b, M = c; if (B !== k && (B.visible || B === y)) {
                l = B.margin; r = l.right + l.left; q = l.top + l.bottom; p = il(B, !1); switch (p) {
                    case nd: E = f; M = g; break; case Vk: E =
                        f; break; case Wk: M = g
                }B.measure(E, M, 0, 0); p = B.measuredBounds; r = Math.max(p.width + r, 0); q = Math.max(p.height + q, 0); E = B.alignment; E.Tb() && (E = a.defaultAlignment); E.tb() || (E = xc); var R = B.alignmentFocus; R.Tb() && (R = xc); M = null; B instanceof W && "" !== B.We && (M = B.fb(B.We), M === B && (M = null)); var S = v.x, J = v.y; if (null !== M) {
                    B.arrange(0, 0, p.width, p.height); l = M.actualBounds; l = I.allocAt(l.x + (R.x * l.width - R.offsetX), l.y + (R.y * l.height - R.offsetY)); for (M = M.panel; M !== B;)M.transform.Ga(l), M = M.panel; S += E.x * f + E.offsetX - l.x; J += E.y * g + E.offsetY -
                        l.y; I.free(l)
                } else S += E.x * f + E.offsetX - (R.x * p.width + R.offsetX) - l.left, J += E.y * g + E.offsetY - (R.y * p.height + R.offsetY) - l.top; z ? (z = !1, e.h(S, J, r, q)) : Yb(e, S, J, r, q)
            }
        } z ? e.assign(w) : u ? e.Qu(v.x, v.y, v.width, v.height) : Yb(e, w.x, w.y, w.width, w.height); N.free(w); N.free(v); p = k.stretch; p === Xk && (p = il(k, !1)); switch (p) { case kg: return; case nd: if (!isFinite(b) && !isFinite(c)) return; break; case Vk: if (!isFinite(b)) return; break; case Wk: if (!isFinite(c)) return }p = k.measuredBounds; f = p.width; g = p.height; r = Math.max(f + m, 0); q = Math.max(g +
            n, 0); l = k.margin; v = N.allocAt(-l.left, -l.top, r, q); Jm(k, v, !0); f = v.width; g = v.height; for (b = 0; b < h; b++)c = d[b], c === k || !c.visible && c !== y || (l = c.margin, r = l.right + l.left, q = l.top + l.bottom, p = c.measuredBounds, r = Math.max(p.width + r, 0), q = Math.max(p.height + q, 0), m = c.alignment, m.Tb() && (m = a.defaultAlignment), m.tb() || (m = xc), c = c.alignmentFocus, c.Tb() && (c = xc), z ? (z = !1, e.h(m.x * f + m.offsetX - (c.x * p.width + c.offsetX) - l.left, m.y * g + m.offsetY - (c.y * p.height + c.offsetY) - l.top, r, q)) : Yb(e, m.x * f + m.offsetX - (c.x * p.width + c.offsetX) - l.left,
                m.y * g + m.offsetY - (c.y * p.height + c.offsetY) - l.top, r, q)); z ? e.assign(w) : u ? e.Qu(v.x, v.y, v.width, v.height) : Yb(e, w.x, w.y, w.width, w.height); N.free(v)
    };
    Im.prototype.arrange = function (a, b, c) {
        var d = b.length, e = a.sb(), f = e.measuredBounds, g = f.width, h = f.height, k = a.padding; f = k.left; k = k.top; var l = f - c.x, m = k - c.y; e.arrange(l, m, g, h); g = N.allocAt(0, 0, g, h); Jm(e, g, !1); for (h = 0; h < d; h++) {
            var n = b[h]; if (n !== e) {
                l = n.measuredBounds; var p = l.width, r = l.height; m = n.alignment; m.Tb() && (m = a.defaultAlignment); m.tb() || (m = xc); var q = n.alignmentFocus; q.Tb() && (q = xc); l = null; n instanceof W && "" !== n.We && (l = n.fb(n.We), l === n && (l = null)); if (null !== l) {
                    var u = l.actualBounds; q = I.allocAt(u.x + (q.x *
                        u.width - q.offsetX), u.y + (q.y * u.height - q.offsetY)); for (l = l.panel; l !== n;)l.transform.Ga(q), l = l.panel; l = m.x * g.width + m.offsetX - q.x; m = m.y * g.height + m.offsetY - q.y; I.free(q)
                } else l = m.x * g.width + m.offsetX - (q.x * p + q.offsetX), m = m.y * g.height + m.offsetY - (q.y * r + q.offsetY); l += g.x - c.x; m += g.y - c.y; n.visible && n.arrange(f + l, k + m, p, r)
            }
        } N.free(g)
    };
    function Jm(a, b, c) { var d = null; a instanceof W && "" !== a.We && (d = a.fb(a.We), d === a && (d = null)); if (null !== d) for (c && a.arrange(0, 0, b.width, b.height), b.assign(d.actualBounds), d = d.panel; d !== a;)d.transform.jp(b), d = d.panel } function Km() { this.wa = "Base" } la(Km, Vl);
    Km.prototype.measure = function (a, b, c, d, e, f, g) {
        var h = d.length, k = a.sb(), l = k.margin, m = b, n = c, p = l.right + l.left, r = l.top + l.bottom; k.measure(b, c, f, g); l = k.measuredBounds; var q = 0, u = null; k instanceof Bf && (u = k, q = u.strokeWidth * u.scale); var w = Math.max(l.width + p, 0); l = Math.max(l.height + r, 0); var v = Lm(k), z = v.x * w + v.offsetX; v = v.y * l + v.offsetY; var y = Mm(k), A = y.x * w + y.offsetX; y = y.y * l + y.offsetY; isFinite(b) && (m = Math.max(Math.abs(z - A) - q, 0)); isFinite(c) && (n = Math.max(Math.abs(v - y) - q, 0)); q = L.alloc(); q.h(0, 0); a = Fm(a); for (y = 0; y < h; y++)v =
            d[y], v === k || !v.visible && v !== a || (l = v.margin, w = l.right + l.left, z = l.top + l.bottom, v.measure(m, n, 0, 0), l = v.measuredBounds, w = Math.max(l.width + w, 0), l = Math.max(l.height + z, 0), q.h(Math.max(w, q.width), Math.max(l, q.height))); if (1 === h) e.width = w, e.height = l, L.free(q); else {
                v = Lm(k); y = Mm(k); h = d = 0; y.x !== v.x && y.y !== v.y && (d = q.width / Math.abs(y.x - v.x), h = q.height / Math.abs(y.y - v.y)); L.free(q); q = 0; null !== u && (q = u.strokeWidth * u.scale, lg(u) === mg && (d = h = Math.max(d, h))); d += Math.abs(v.offsetX) + Math.abs(y.offsetX) + q; h += Math.abs(v.offsetY) +
                    Math.abs(y.offsetY) + q; u = k.stretch; u === Xk && (u = il(k, !1)); switch (u) { case kg: g = f = 0; break; case nd: isFinite(b) && (d = b); isFinite(c) && (h = c); break; case Vk: isFinite(b) && (d = b); g = 0; break; case Wk: f = 0, isFinite(c) && (h = c) }k.Sj(); k.measure(d, h, f, g); e.width = k.measuredBounds.width + p; e.height = k.measuredBounds.height + r
            }
    };
    Km.prototype.arrange = function (a, b) {
        var c = b.length, d = a.sb(), e = d.measuredBounds, f = N.alloc(); f.h(0, 0, 1, 1); var g = d.margin, h = g.left; g = g.top; var k = a.padding, l = k.left; k = k.top; d.arrange(l + h, k + g, e.width, e.height); var m = Lm(d), n = Mm(d), p = m.y * e.height + m.offsetY, r = n.x * e.width + n.offsetX; n = n.y * e.height + n.offsetY; f.x = m.x * e.width + m.offsetX; f.y = p; Yb(f, r, n, 0, 0); f.x += h + l; f.y += g + k; for (e = 0; e < c; e++)h = b[e], h !== d && (l = h.measuredBounds, g = h.margin, k = Math.max(l.width + g.right + g.left, 0), m = Math.max(l.height + g.top + g.bottom, 0), p = h.alignment,
            p.Tb() && (p = a.defaultAlignment), p.tb() || (p = xc), k = f.width * p.x + p.offsetX - k * p.x + g.left + f.x, g = f.height * p.y + p.offsetY - m * p.y + g.top + f.y, h.visible && (Zb(f.x, f.y, f.width, f.height, k, g, l.width, l.height) ? h.arrange(k, g, l.width, l.height) : h.arrange(k, g, l.width, l.height, new N(f.x, f.y, f.width, f.height)))); N.free(f)
    }; function Nm() { this.wa = "Base"; this.name = "Table" } la(Nm, Vl);
    Nm.prototype.measure = function (a, b, c, d, e) {
        for (var f = d.length, g = Ea(), h = Ea(), k = 0; k < f; k++) { var l = d[k], m = l instanceof W ? l : null; if (null !== m && jl(m) && l.visible) { h.push(m); for (var n = m.P.s, p = n.length, r = 0; r < p; r++) { var q = n[r]; m.type === W.TableRow ? q.row = l.row : m.type === W.TableColumn && (q.column = l.column); g.push(q) } } else g.push(l) } f = g.length; 0 === f && (a.getRowDefinition(0), a.getColumnDefinition(0)); for (var u = [], w = 0; w < f; w++) {
            var v = g[w]; v.visible && (nj(v, !0), kl(v, !0), u[v.row] || (u[v.row] = []), u[v.row][v.column] || (u[v.row][v.column] =
                []), u[v.row][v.column].push(v))
        } Ga(g); var z = Ea(), y = Ea(), A = Ea(), B = { count: 0 }, E = { count: 0 }, M = b, R = c, S = a.rowDefs, J = a.colDefs, G = null; f = S.length; for (var ca = 0; ca < f; ca++)G = S[ca], void 0 !== G && (G.actual = 0); f = J.length; for (var X = 0; X < f; X++)G = J[X], void 0 !== G && (G.actual = 0); for (var na = u.length, wa = 0, db = 0; db < na; db++)u[db] && (wa = Math.max(wa, u[db].length)); var gb = Math.min(a.topIndex, na - 1), Db = Math.min(a.leftIndex, wa - 1), eb = 0; na = u.length; for (var Ke = Fm(a), Ua = 0; Ua < na; Ua++)if (u[Ua]) {
            wa = u[Ua].length; var rd = a.getRowDefinition(Ua);
            rd.actual = 0; for (var Eb = rd.kc = 0; Eb < wa; Eb++)if (u[Ua][Eb]) {
                var Le = a.getColumnDefinition(Eb); void 0 === z[Eb] && (Le.actual = 0, Le.kc = 0, z[Eb] = !0); for (var wc = u[Ua][Eb], Tc = wc.length, lc = 0; lc < Tc; lc++) {
                    var dc = wc[lc]; if (dc.visible || dc === Ke) {
                        var ef = 1 < dc.rowSpan || 1 < dc.columnSpan; ef && (Ua < gb || Eb < Db || y.push(dc)); var sd = dc.margin, wh = sd.right + sd.left, mc = sd.top + sd.bottom; var Fb = Kl(dc, rd, Le, !1); var yi = dc.desiredSize, rg = !isNaN(yi.height), Fd = !isNaN(yi.width) && rg; ef || Fb === kg || Fd || Ua < gb || Eb < Db || (void 0 !== B[Eb] || Fb !== nd && Fb !==
                            Vk || (B[Eb] = -1, B.count++), void 0 !== E[Ua] || Fb !== nd && Fb !== Wk || (E[Ua] = -1, E.count++), A.push(dc)); dc.measure(Infinity, Infinity, 0, 0); if (!(Ua < gb || Eb < Db)) {
                                var ec = dc.measuredBounds, Jf = Math.max(ec.width + wh, 0), nc = Math.max(ec.height + mc, 0); if (1 === dc.rowSpan && (Fb === kg || Fb === Vk)) { G = a.getRowDefinition(Ua); var ff = G.Rc(); eb = Math.max(nc - G.actual, 0); eb + ff > R && (eb = Math.max(R - ff, 0)); var lk = 0 === G.oa; G.kc = G.kc + eb; G.actual = G.oa + eb; R = Math.max(R - (eb + (lk ? ff : 0)), 0) } if (1 === dc.columnSpan && (Fb === kg || Fb === Wk)) {
                                    G = a.getColumnDefinition(Eb);
                                    var zi = G.Rc(); eb = Math.max(Jf - G.actual, 0); eb + zi > M && (eb = Math.max(M - zi, 0)); var Mn = 0 === G.oa; G.kc = G.kc + eb; G.actual = G.oa + eb; M = Math.max(M - (eb + (Mn ? zi : 0)), 0)
                                } ef && dc.Sj()
                            }
                    }
                }
            }
        } Ga(z); var Td = 0, oe = 0; f = a.columnCount; for (var sg = 0; sg < f; sg++) { var gf = J[sg]; void 0 !== gf && (Td += isNaN(gf.Ec) ? gf.kc : gf.Ec, 0 !== gf.kc && (Td += gf.Rc())) } f = a.rowCount; for (var xh = 0; xh < f; xh++) { var hf = S[xh]; void 0 !== hf && (oe += isNaN(hf.Ec) ? hf.kc : hf.Ec, 0 !== hf.kc && (oe += hf.Rc())) } M = Math.max(b - Td, 0); var Ud = R = Math.max(c - oe, 0), Me = M; f = A.length; for (var fc = 0; fc <
            f; fc++) { var Gd = A[fc], mk = a.getRowDefinition(Gd.row), pe = a.getColumnDefinition(Gd.column), yh = Gd.measuredBounds, td = Gd.margin, Kf = td.right + td.left, Hd = td.top + td.bottom; B[Gd.column] = 0 === pe.kc && void 0 !== B[Gd.column] ? Math.max(yh.width + Kf, B[Gd.column]) : null; E[Gd.row] = 0 === mk.kc && void 0 !== E[Gd.row] ? Math.max(yh.height + Hd, E[Gd.row]) : null } var tg = 0, zh = 0, ed; for (ed in E) "count" !== ed && (tg += E[ed]); for (ed in B) "count" !== ed && (zh += B[ed]); for (var hb = L.alloc(), Ai = 0; Ai < f; Ai++) {
                var Sb = A[Ai]; if (Sb.visible || Sb === Ke) {
                    var Va = a.getRowDefinition(Sb.row),
                    Tb = a.getColumnDefinition(Sb.column), qe = 0; isFinite(Tb.width) ? qe = Tb.width : (isFinite(M) && null !== B[Sb.column] ? 0 === zh ? qe = Tb.actual + M : qe = B[Sb.column] / zh * Me : null !== B[Sb.column] ? qe = M : qe = Tb.actual || M, qe = Math.max(0, qe - Tb.Rc())); var Ne = 0; isFinite(Va.height) ? Ne = Va.height : (isFinite(R) && null !== E[Sb.row] ? 0 === tg ? Ne = Va.actual + R : Ne = E[Sb.row] / tg * Ud : null !== E[Sb.row] ? Ne = R : Ne = Va.actual || R, Ne = Math.max(0, Ne - Va.Rc())); hb.h(Math.max(Tb.minimum, Math.min(qe, Tb.maximum)), Math.max(Va.minimum, Math.min(Ne, Va.maximum))); Fb = Kl(Sb,
                        Va, Tb, !1); switch (Fb) { case Vk: hb.height = Math.max(hb.height, Va.actual + R); break; case Wk: hb.width = Math.max(hb.width, Tb.actual + M) }var Lf = Sb.margin, Nn = Lf.right + Lf.left, nk = Lf.top + Lf.bottom; Sb.Sj(); Sb.measure(hb.width, hb.height, Tb.minimum, Va.minimum); var Ah = Sb.measuredBounds, Mf = Math.max(Ah.width + Nn, 0), ug = Math.max(Ah.height + nk, 0); isFinite(M) && (Mf = Math.min(Mf, hb.width)); isFinite(R) && (ug = Math.min(ug, hb.height)); var gc = 0; gc = Va.actual; Va.actual = Math.max(Va.actual, ug); Va.kc = Math.max(Va.kc, ug); eb = Va.actual - gc; R =
                            Math.max(R - eb, 0); null === E[Sb.row] && (Ud = Math.max(Ud - eb, 0)); gc = Tb.actual; Tb.actual = Math.max(Tb.actual, Mf); Tb.kc = Math.max(Tb.kc, Mf); eb = Tb.actual - gc; M = Math.max(M - eb, 0); null === B[Sb.column] && (Me = Math.max(Me - eb, 0))
                }
            } Ga(A); var Id = L.alloc(), ud = Ea(), Gc = Ea(); f = y.length; if (0 !== f) for (var jf = 0; jf < na; jf++)if (u[jf]) { wa = u[jf].length; var Bh = a.getRowDefinition(jf); ud[jf] = Bh.actual; for (var kf = 0; kf < wa; kf++)if (u[jf][kf]) { var ok = a.getColumnDefinition(kf); Gc[kf] = ok.actual } } for (var Ch = 0; Ch < f; Ch++) {
                var Oa = y[Ch]; if (Oa.visible ||
                    Oa === Ke) {
                        var fd = a.getRowDefinition(Oa.row), Uc = a.getColumnDefinition(Oa.column); hb.h(Math.max(Uc.minimum, Math.min(b, Uc.maximum)), Math.max(fd.minimum, Math.min(c, fd.maximum))); Fb = Kl(Oa, fd, Uc, !1); switch (Fb) { case nd: 0 !== Gc[Uc.index] && (hb.width = Math.min(hb.width, Gc[Uc.index])); 0 !== ud[fd.index] && (hb.height = Math.min(hb.height, ud[fd.index])); break; case Vk: 0 !== Gc[Uc.index] && (hb.width = Math.min(hb.width, Gc[Uc.index])); break; case Wk: 0 !== ud[fd.index] && (hb.height = Math.min(hb.height, ud[fd.index])) }isFinite(Uc.width) &&
                            (hb.width = Uc.width); isFinite(fd.height) && (hb.height = fd.height); Id.h(0, 0); for (var pk = Uc.minimum, oc = fd.minimum, Oe = 1; Oe < Oa.rowSpan && !(Oa.row + Oe >= a.rowCount); Oe++)G = a.getRowDefinition(Oa.row + Oe), eb = 0, eb = Fb === nd || Fb === Wk ? Math.max(G.minimum, 0 === ud[Oa.row + Oe] ? G.maximum : Math.min(ud[Oa.row + Oe], G.maximum)) : Math.max(G.minimum, isNaN(G.Ec) ? G.maximum : Math.min(G.Ec, G.maximum)), Id.height += eb, oc += G.minimum; for (var Vc = 1; Vc < Oa.columnSpan && !(Oa.column + Vc >= a.columnCount); Vc++)G = a.getColumnDefinition(Oa.column + Vc), eb =
                                0, eb = Fb === nd || Fb === Vk ? Math.max(G.minimum, 0 === Gc[Oa.column + Vc] ? G.maximum : Math.min(Gc[Oa.column + Vc], G.maximum)) : Math.max(G.minimum, isNaN(G.Ec) ? G.maximum : Math.min(G.Ec, G.maximum)), Id.width += eb, pk += G.minimum; hb.width += Id.width; hb.height += Id.height; var Dh = Oa.margin, lf = Dh.right + Dh.left, Bi = Dh.top + Dh.bottom; Oa.measure(hb.width, hb.height, pk, oc); for (var Eh = Oa.measuredBounds, Jd = Math.max(Eh.width + lf, 0), Nf = Math.max(Eh.height + Bi, 0), mf = 0, Fh = 0; Fh < Oa.rowSpan && !(Oa.row + Fh >= a.rowCount); Fh++)G = a.getRowDefinition(Oa.row +
                                    Fh), mf += G.total || 0; if (mf < Nf) { var Pe = Nf - mf, Vd = Nf - mf; if (null !== Oa.spanAllocation) for (var Qe = Oa.spanAllocation, re = 0; re < Oa.rowSpan && !(0 >= Pe) && !(Oa.row + re >= a.rowCount); re++) { G = a.getRowDefinition(Oa.row + re); var Re = G.oa || 0, Of = Qe(Oa, G, Vd); G.actual = Math.min(G.maximum, Re + Of); G.oa !== Re && (Pe -= G.oa - Re) } for (; 0 < Pe;) { var vd = G.oa || 0; isNaN(G.height) && G.maximum > vd && (G.actual = Math.min(G.maximum, vd + Pe), G.oa !== vd && (Pe -= G.oa - vd)); if (0 === G.index) break; G = a.getRowDefinition(G.index - 1) } } for (var vg = 0, Se = 0; Se < Oa.columnSpan &&
                                        !(Oa.column + Se >= a.columnCount); Se++)G = a.getColumnDefinition(Oa.column + Se), vg += G.total || 0; if (vg < Jd) {
                                            var wg = Jd - vg, On = Jd - vg; if (null !== Oa.spanAllocation) for (var se = Oa.spanAllocation, Pf = 0; Pf < Oa.columnSpan && !(0 >= wg) && !(Oa.column + Pf >= a.columnCount); Pf++) { G = a.getColumnDefinition(Oa.column + Pf); var Ci = G.oa || 0, Pn = se(Oa, G, On); G.actual = Math.min(G.maximum, Ci + Pn); G.oa !== Ci && (wg -= G.oa - Ci) } for (; 0 < wg;) {
                                                var qk = G.oa || 0; isNaN(G.width) && G.maximum > qk && (G.actual = Math.min(G.maximum, qk + wg), G.oa !== qk && (wg -= G.oa - qk)); if (0 ===
                                                    G.index) break; G = a.getColumnDefinition(G.index - 1)
                                            }
                                        }
                }
            } Ga(y); L.free(Id); L.free(hb); void 0 !== ud && Ga(ud); void 0 !== Gc && Ga(Gc); var xg = 0, yg = 0, rk = a.desiredSize, Br = a.maxSize; Fb = il(a, !0); var Di = oe = Td = 0, Ei = 0; f = a.columnCount; for (var sk = 0; sk < f; sk++)void 0 !== J[sk] && (G = a.getColumnDefinition(sk), isFinite(G.width) ? (Di += G.width, Di += G.Rc()) : Om(G) === Pm ? (Di += G.oa, Di += G.Rc()) : 0 !== G.oa && (Td += G.oa, Td += G.Rc())); isFinite(rk.width) ? xg = Math.min(rk.width, Br.width) : xg = Fb !== kg && isFinite(b) ? b : Td; xg = Math.max(xg, a.minSize.width); xg =
                Math.max(xg - Di, 0); for (var ou = 0 === Td ? 1 : Math.max(xg / Td, 1), uk = 0; uk < f; uk++)void 0 !== J[uk] && (G = a.getColumnDefinition(uk), isFinite(G.width) || Om(G) === Pm || (G.actual = G.oa * ou), G.position = e.width, 0 !== G.oa && (e.width += G.oa, e.width += G.Rc())); f = a.rowCount; for (var vk = 0; vk < f; vk++)void 0 !== S[vk] && (G = a.getRowDefinition(vk), isFinite(G.height) ? (Ei += G.height, Ei += G.Rc()) : Om(G) === Pm ? (Ei += G.oa, Ei += G.Rc()) : 0 !== G.oa && (oe += G.oa, oe += G.Rc())); isFinite(rk.height) ? yg = Math.min(rk.height, Br.height) : yg = Fb !== kg && isFinite(c) ? c : oe; yg =
                    Math.max(yg, a.minSize.height); yg = Math.max(yg - Ei, 0); for (var pu = 0 === oe ? 1 : Math.max(yg / oe, 1), wk = 0; wk < f; wk++)void 0 !== S[wk] && (G = a.getRowDefinition(wk), isFinite(G.height) || Om(G) === Pm || (G.actual = G.oa * pu), G.position = e.height, 0 !== G.oa && (e.height += G.oa, e.height += G.Rc())); f = h.length; for (var Qn = 0; Qn < f; Qn++) { var Gi = h[Qn], Rn = 0, Sn = 0; Gi.type === W.TableRow ? (Rn = e.width, G = a.getRowDefinition(Gi.row), Sn = G.actual) : (G = a.getColumnDefinition(Gi.column), Rn = G.actual, Sn = e.height); Gi.measuredBounds.h(0, 0, Rn, Sn); nj(Gi, !1) } Ga(h);
        a.inRowsCols = u
    };
    Nm.prototype.arrange = function (a, b, c) {
        var d = b.length, e = a.padding, f = e.left; e = e.top; var g = a.inRowsCols; if (null !== g) {
            for (var h, k, l = g.length, m = 0, n = 0; n < l; n++)g[n] && (m = Math.max(m, g[n].length)); k = a.rowDefs; h = a.colDefs; var p = n = 0, r = Math.min(a.topIndex, l - 1); if (0 < k.length) { for (; r !== l && (void 0 === k[r] || 0 === k[r].oa);)r++; r = Math.max(Math.min(r, l - 1), 0); n = -k[r].position } var q = Math.min(a.leftIndex, m - 1); if (0 < h.length) { for (; q !== m && (void 0 === h[q] || 0 === h[q].oa);)q++; q = Math.max(Math.min(q, m - 1), 0); p = -h[q].position } for (var u = 0,
                w = 0; u !== l && void 0 === k[u];)u++; for (; w !== l && void 0 === h[w];)w++; m = a.part; a.getRowDefinition(u); a.getColumnDefinition(w); for (var v = 0; v < b.length; v++) {
                    var z = b[v]; if (z instanceof W && z.visible && jl(z)) {
                        if (z.type === W.TableRow) { h = a.getRowDefinition(z.row); var y = a.getColumnDefinition(w) } else h = a.getRowDefinition(u), y = a.getColumnDefinition(z.column); k = h.position + n + e; 0 !== h.oa && (k += h.Eo(Math.max(u, r))); h = y.position + p + f; 0 !== y.oa && (h += y.Eo(Math.max(w, q))); y = z.measuredBounds; z.Pk(); z.actualBounds.ka(); var A = z.actualBounds,
                            B = N.allocAt(A.x, A.y, A.width, A.height); A.x = z.type === W.TableRow ? f : h; A.y = z.type === W.TableColumn ? e : k; A.width = y.width; A.height = y.height; z.actualBounds.freeze(); kl(z, !1); Ub(B, A) || null === m || (m.jh(), z.Ln(m)); N.free(B)
                    }
                } v = L.alloc(); for (z = 0; z < l; z++)if (g[z]) for (m = g[z].length, y = a.getRowDefinition(z), k = y.position + n + e, 0 !== y.oa && (k += y.Eo(Math.max(u, r))), A = 0; A < m; A++)if (g[z][A]) {
                    B = a.getColumnDefinition(A); h = B.position + p + f; 0 !== B.oa && (h += B.Eo(Math.max(w, q))); for (var E = g[z][A], M = E.length, R = 0; R < M; R++) {
                        var S = E[R], J = S.measuredBounds;
                        v.h(0, 0); for (var G = 1; G < S.rowSpan && !(z + G >= a.rowCount); G++) { var ca = a.getRowDefinition(z + G); 0 !== ca.oa && (v.height += ca.total) } for (G = 1; G < S.columnSpan && !(A + G >= a.columnCount); G++)ca = a.getColumnDefinition(A + G), 0 !== ca.oa && (v.width += ca.total); var X = B.oa + v.width, na = y.oa + v.height; G = h; ca = k; var wa = X, db = na, gb = h, Db = k, eb = X, Ke = na; h + X > c.width && (eb = Math.max(c.width - h, 0)); k + na > c.height && (Ke = Math.max(c.height - k, 0)); var Ua = S.alignment; if (Ua.Tb()) {
                            Ua = a.defaultAlignment; Ua.tb() || (Ua = xc); var rd = Ua.x; var Eb = Ua.y; var Le = Ua.offsetX;
                            Ua = Ua.offsetY; var wc = B.alignment, Tc = y.alignment; wc.tb() && (rd = wc.x, Le = wc.offsetX); Tc.tb() && (Eb = Tc.y, Ua = Tc.offsetY)
                        } else rd = Ua.x, Eb = Ua.y, Le = Ua.offsetX, Ua = Ua.offsetY; if (isNaN(rd) || isNaN(Eb)) Eb = rd = .5, Ua = Le = 0; wc = J.width; Tc = J.height; var lc = S.margin, dc = lc.left + lc.right, ef = lc.top + lc.bottom, sd = Kl(S, y, B, !1); !isNaN(S.desiredSize.width) || sd !== nd && sd !== Vk || (wc = Math.max(X - dc, 0)); !isNaN(S.desiredSize.height) || sd !== nd && sd !== Wk || (Tc = Math.max(na - ef, 0)); X = S.maxSize; na = S.minSize; wc = Math.min(X.width, wc); Tc = Math.min(X.height,
                            Tc); wc = Math.max(na.width, wc); Tc = Math.max(na.height, Tc); X = Tc + ef; G += wa * rd - (wc + dc) * rd + Le + lc.left; ca += db * Eb - X * Eb + Ua + lc.top; S.visible && (Zb(gb, Db, eb, Ke, G, ca, J.width, J.height) ? S.arrange(G, ca, wc, Tc) : S.arrange(G, ca, wc, Tc, new N(gb, Db, eb, Ke)))
                    }
                } L.free(v); for (a = 0; a < d; a++)c = b[a], f = c instanceof W ? c : null, null !== f && jl(f) && (f = c.actualBounds, c.naturalBounds.ka(), c.naturalBounds.h(0, 0, f.width, f.height), c.naturalBounds.freeze())
        }
    }; function Qm() { this.wa = "Base" } la(Qm, Vl); Qm.prototype.measure = function () { };
    Qm.prototype.arrange = function () { }; function Rm() { this.wa = "Base" } la(Rm, Vl); Rm.prototype.measure = function () { }; Rm.prototype.arrange = function () { }; function Sm() { this.wa = "Base" } la(Sm, Vl);
    Sm.prototype.measure = function (a, b, c, d, e, f, g) {
        1 < d.length && C("Viewbox Panel cannot contain more than one GraphObject."); d = d[0]; d.Aa = 1; d.Sj(); d.measure(Infinity, Infinity, f, g); var h = d.measuredBounds, k = d.margin, l = k.right + k.left; k = k.top + k.bottom; if (isFinite(b) || isFinite(c)) {
            var m = d.scale, n = h.width; h = h.height; var p = Math.max(b - l, 0), r = Math.max(c - k, 0), q = 1; a.viewboxStretch === mg ? 0 !== n && 0 !== h && (q = Math.min(p / n, r / h)) : 0 !== n && 0 !== h && (q = Math.max(p / n, r / h)); 0 === q && (q = 1E-4); d.Aa *= q; m !== d.scale && (nj(d, !0), d.measure(Infinity,
                Infinity, f, g))
        } h = d.measuredBounds; e.width = isFinite(b) ? b : Math.max(h.width + l, 0); e.height = isFinite(c) ? c : Math.max(h.height + k, 0)
    }; Sm.prototype.arrange = function (a, b, c) { b = b[0]; var d = b.measuredBounds, e = b.margin, f = Math.max(d.width + (e.right + e.left), 0); e = Math.max(d.height + (e.top + e.bottom), 0); var g = b.alignment; g.Tb() && (g = a.defaultAlignment); g.tb() || (g = xc); b.arrange(c.width * g.x - f * g.x + g.offsetX, c.height * g.y - e * g.y + g.offsetY, d.width, d.height) }; function Tm() { this.wa = "Base" } la(Tm, Vl); Tm.prototype.measure = function () { };
    Tm.prototype.arrange = function () { };
    Tm.prototype.cc = function (a, b, c) {
        c = a.Af() * c.scale; 0 >= c && (c = 1); var d = a.gridCellSize, e = d.width; d = d.height; var f = a.naturalBounds, g = a.actualBounds, h = f.width, k = f.height, l = Math.ceil(h / e), m = Math.ceil(k / d), n = a.gridOrigin, p = n.x; n = n.y; b.save(); b.beginPath(); b.rect(0, 0, h, k); b.clip(); b.Ne(); for (var r = [], q = a.P.s, u = q.length, w = 0; w < u; w++) { var v = q[w], z = []; r.push(z); if (v.visible) { var y = v.interval; if (!(0 > y)) { v = Yj(v.figure); for (var A = 0; A < u; A++)if (A !== w) { var B = q[A]; B.visible && Yj(B.figure) === v && (B = B.interval, B > y && z.push(B)) } } } } q =
            a.P.s; u = q.length; for (w = 0; w < u; w++) {
                var E = q[w]; if (E.visible && (z = E.interval || 1, v = Math.abs(z), !(2 > e * v * c))) {
                    y = E.segmentIndex; if (!isFinite(y) || isNaN(y)) y = 0; 0 > y && (y = v - Math.min(-y, v)); y = Math.round(y % v); v = E.opacity; A = 1; if (1 !== v) { if (0 === v) continue; A = b.globalAlpha; b.globalAlpha = A * v } B = r[w]; var M = !1, R = E.strokeDashArray; null !== R && (M = !0, b.bs(R, E.strokeDashOffset)); if ("LineV" === E.figure && null !== E.stroke && 0 < E.strokeWidth) {
                        b.lineWidth = E.strokeWidth; hi(a, b, E.stroke, !1, !1, f, g); b.beginPath(); for (R = E = Math.floor((p - e) /
                            e); R <= E + l; R++) { var S = R * e + p; 0 <= S && S <= h && Um(R, z, y, B) && (b.moveTo(S, 0), b.lineTo(S, k)) } b.stroke(); b.Ne()
                    } else if ("LineH" === E.figure && null !== E.stroke && 0 < E.strokeWidth) { b.lineWidth = E.strokeWidth; hi(a, b, E.stroke, !1, !1, f, g); b.beginPath(); for (R = E = Math.floor((n - d) / d); R <= E + m; R++)S = R * d + n, 0 <= S && S <= k && Um(R, z, y, B) && (b.moveTo(0, S), b.lineTo(h, S)); b.stroke(); b.Ne() } else if ("BarV" === E.figure && null !== E.fill) for (hi(a, b, E.fill, !0, !1, f, g), E = E.width, isNaN(E) && (E = e), S = R = Math.floor((p - e) / e); S <= R + l; S++) {
                        var J = S * e + p; 0 <= J + E &&
                            J <= h && Um(S, z, y, B) && b.fillRect(J, 0, E, k)
                    } else if ("BarH" === E.figure && null !== E.fill) for (hi(a, b, E.fill, !0, !1, f, g), E = E.height, isNaN(E) && (E = d), S = R = Math.floor((n - d) / d); S <= R + m; S++)J = S * d + n, 0 <= J + E && J <= k && Um(S, z, y, B) && b.fillRect(0, J, h, E); M && b.Yr(); 1 !== v && (b.globalAlpha = A)
                }
            } b.restore(); b.Qc(!1)
    }; function Um(a, b, c, d) { if (0 > b) return a % b === c; if (a % b !== c) return !1; b = d.length; for (var e = 0; e < b; e++)if (a % d[e] === c) return !1; return !0 } function Vm() { this.wa = "Base" } la(Vm, Vl);
    Vm.prototype.measure = function (a, b, c, d, e) {
        var f = d.length; if (a instanceof te || a instanceof T) {
            var g = null, h = null; a instanceof T && (g = a); a instanceof te && (h = a, g = h.adornedPart, null === a.j && (a.j = [])); if (g instanceof T) {
                var k = g; if (0 === f) Pb(a.naturalBounds, 0, 0), a.measuredBounds.h(0, 0, 0, 0); else {
                    var l = a instanceof te ? null : g.path; b = g.routeBounds; c = a.fg; c.h(0, 0, b.width, b.height); var m = k.points, n = g.pointsCount; null !== h ? h.Ql(!1) : null !== g && g.Ql(!1); h = b.width; var p = b.height, r = a.j; r.length = 0; if (null !== l) {
                        Wm(a, h, p, l);
                        var q = l.measuredBounds; c.Zc(q); r.push(q)
                    } q = ld.alloc(); for (var u = I.alloc(), w = I.alloc(), v = 0; v < f; v++) {
                        var z = d[v]; if (z !== l) if (z.isPanelMain && z instanceof Bf) { Wm(a, h, p, z); var y = z.measuredBounds; c.Zc(y); r.push(y) } else if (2 > n) z.measure(Infinity, Infinity, 0, 0), y = z.measuredBounds, c.Zc(y), r.push(y); else {
                            var A = z.segmentIndex, B = z.segmentFraction, E = z.alignmentFocus; E.Jc() && (E = xc); var M = z.segmentOrientation, R = z.segmentOffset; if (isNaN(A)) {
                                var S = k.ta; y = Ea(); S.Mu(B, y); var J = I.allocAt(y[0], y[1]); J.add(k.i(0)); S.type ===
                                    P.j ? J.offset(-S.startX, -S.startY) : (S = S.figures.first(), null !== S && J.offset(-S.startX, -S.startY)); S = y[2]; if (M !== Zf) { var G = k.computeAngle(z, M, S); z.Ib = G } G = J.x - b.x; var ca = J.y - b.y; I.free(J); Ga(y)
                            } else if (A < -n || A >= n) y = I.alloc(), k.rg(), k.computeMidPoint(y), S = k.midAngle, M !== Zf && (G = k.computeAngle(z, M, S), z.Ib = G), G = y.x - b.x, ca = y.y - b.y, I.free(y); else {
                                G = 0; 0 <= A ? (ca = m.L(A), y = A < n - 1 ? m.L(A + 1) : ca) : (G = n + A, ca = m.L(G), y = 0 < G ? m.L(G - 1) : ca); if (ca.ab(y)) {
                                    0 <= A ? (S = 0 < A ? m.L(A - 1) : ca, G = A < n - 2 ? m.L(A + 2) : y) : (S = G < n - 1 ? m.L(G + 1) : ca, G = 1 < G ? m.L(G -
                                        2) : y); J = S.yf(ca); var X = y.yf(G); S = J > X + 10 ? 0 <= A ? S.$a(ca) : ca.$a(S) : X > J + 10 ? 0 <= A ? y.$a(G) : G.$a(y) : 0 <= A ? S.$a(G) : G.$a(S)
                                } else S = 0 <= A ? ca.$a(y) : y.$a(ca); M !== Zf && (G = k.computeAngle(z, M, S), z.Ib = G); G = ca.x + (y.x - ca.x) * B - b.x; ca = ca.y + (y.y - ca.y) * B - b.y
                            } z.measure(Infinity, Infinity, 0, 0); y = z.measuredBounds; J = z.naturalBounds; var na = 0; z instanceof Bf && (na = z.strokeWidth); X = J.width + na; var wa = J.height + na; q.reset(); q.translate(-y.x, -y.y); q.scale(z.scale, z.scale); q.rotate(M === Zf ? z.angle : S, X / 2, wa / 2); M !== Xm && M !== Ym || q.rotate(90,
                                X / 2, wa / 2); M !== Zm && M !== $m || q.rotate(-90, X / 2, wa / 2); M === an && (45 < S && 135 > S || 225 < S && 315 > S) && q.rotate(-S, X / 2, wa / 2); J = new N(0, 0, X, wa); u.ui(J, E); q.Ga(u); E = -u.x + na / 2 * z.scale; z = -u.y + na / 2 * z.scale; w.assign(R); na = isNaN(R.x); var db = isNaN(R.y); if (na || db) { X = X / 2 + 3; wa = wa / 2 + 3; var gb = 45 <= S && 135 >= S, Db = 225 <= S && 315 >= S; M === Zf && (gb || Db) ? (w.x = db ? X : R.y, w.y = na ? wa : R.x, gb ? 0 <= A || isNaN(A) && .5 > B || !na || (w.y = -wa) : Db && ((0 <= A || isNaN(A) && .5 > B) && na && (w.y = -wa), db && (w.x = -X))) : (na && (w.x = 0 <= A || isNaN(A) && .5 > B ? X : -X), db && (w.y = -wa), w.rotate(S)) } else w.rotate(S);
                            G += w.x; ca += w.y; J.set(y); J.h(G + E, ca + z, y.width, y.height); r.push(J); c.Zc(J)
                        }
                    } if (null !== g) for (d = g.labelNodes; d.next();)d.value.measure(Infinity, Infinity); a.fg = c; a.position.h(b.x + c.x, b.y + c.y); Pb(e, c.width || 0, c.height || 0); ld.free(q); I.free(u); I.free(w)
                }
            }
        }
    };
    Vm.prototype.arrange = function (a, b) {
        var c = b.length; if (a instanceof te || a instanceof T) {
            var d = a, e = null; a instanceof te && (e = a, d = e.adornedPart); var f = a instanceof te ? null : d.path, g = a.j; if (0 !== g.length) { var h = 0; if (null !== f && h < g.length) { var k = g[h]; h++; f.arrange(k.x - a.fg.x, k.y - a.fg.y, k.width, k.height) } for (k = 0; k < c; k++) { var l = b[k]; if (l !== f && h < g.length) { var m = g[h]; h++; l.arrange(m.x - a.fg.x, m.y - a.fg.y, m.width, m.height) } } } b = d.points; c = b.count; if (2 <= c && a instanceof T) for (f = a.labelNodes; f.next();) {
                var n = a; g = f.value;
                h = g.segmentIndex; k = g.segmentFraction; var p = g.alignmentFocus; l = g.segmentOrientation; m = g.segmentOffset; if (isNaN(h)) { var r = n.ta; var q = Ea(); r.Mu(k, q); var u = I.allocAt(q[0], q[1]); u.add(n.i(0)); r.type === P.j ? u.offset(-r.startX, -r.startY) : (r = r.figures.first(), u.offset(-r.startX, -r.startY)); r = q[2]; l !== Zf && (n = n.computeAngle(g, l, r), g.angle = n); n = u.x; var w = u.y; I.free(u); Ga(q) } else if (h < -c || h >= c) q = I.alloc(), r = n, w = q, r.rg(), r.computeMidPoint(w), r = n.midAngle, l !== Zf && (n = n.computeAngle(g, l, r), g.angle = n), n = q.x, w = q.y,
                    I.free(q); else { u = 0; 0 <= h ? (q = b.s[h], w = h < c - 1 ? b.s[h + 1] : q) : (u = c + h, q = b.s[u], w = 0 < u ? b.s[u - 1] : q); if (q.ab(w)) { 0 <= h ? (r = 0 < h ? b.s[h - 1] : q, u = h < c - 2 ? b.s[h + 2] : w) : (r = u < c - 1 ? b.s[u + 1] : q, u = 1 < u ? b.s[u - 2] : w); var v = r.yf(q), z = w.yf(u); r = v > z + 10 ? 0 <= h ? r.$a(q) : q.$a(r) : z > v + 10 ? 0 <= h ? w.$a(u) : u.$a(w) : 0 <= h ? r.$a(u) : u.$a(r) } else r = 0 <= h ? q.$a(w) : w.$a(q); l !== Zf && (n = n.computeAngle(g, l, r), g.angle = n); n = q.x + (w.x - q.x) * k; w = q.y + (w.y - q.y) * k } if (p.rs()) g.location = new I(n, w); else {
                        p.Jc() && (p = xc); q = ld.alloc(); q.reset(); q.scale(g.scale, g.scale); q.rotate(g.angle,
                            0, 0); var y = g.naturalBounds; u = N.allocAt(0, 0, y.width, y.height); v = I.alloc(); v.ui(u, p); q.Ga(v); p = -v.x; z = -v.y; var A = y.width, B = y.height; y = I.alloc(); y.assign(m); var E = isNaN(m.x), M = isNaN(m.y); if (E || M) { A = A / 2 + 3; B = B / 2 + 3; var R = 45 <= r && 135 >= r, S = 225 <= r && 315 >= r; l === Zf && (R || S) ? (y.x = M ? A : m.y, y.y = E ? B : m.x, R ? 0 <= h || isNaN(h) && .5 > k || !E || (y.y = -B) : S && ((0 <= h || isNaN(h) && .5 > k) && E && (y.y = -B), M && (y.x = -A))) : (E && (y.x = 0 <= h || isNaN(h) && .5 > k ? A : -A), M && (y.y = -B), y.rotate(r)) } else y.rotate(r); n += y.x; w += y.y; q.jp(u); p += u.x; z += u.y; h = I.allocAt(n +
                                p, w + z); g.move(h); I.free(h); I.free(y); I.free(v); N.free(u); ld.free(q)
                    }
            } null !== e ? e.Ql(!1) : d.Ql(!1); d = bn(a); a.location.h(a.position.x + d.x, a.position.y + d.y); I.free(d)
        }
    };
    function Wm(a, b, c, d) { if (!1 !== sj(d)) { var e = d.strokeWidth; 0 === e && a instanceof te && a.type === W.Link && a.adornedObject instanceof Bf && (e = a.adornedObject.strokeWidth); e *= d.Aa; a instanceof T && null !== a.ta ? (a = a.geometry.bounds, d.Ce(a.x - e / 2, a.y - e / 2, a.width + e, a.height + e)) : a instanceof te && null !== a.adornedPart.ta ? (a = a.adornedPart.ta.bounds, d.Ce(a.x - e / 2, a.y - e / 2, a.width + e, a.height + e)) : d.Ce(-(e / 2), -(e / 2), b + e, c + e); e = d.desiredSize; e.w() || null === d.ta ? d.ac.h(0, 0, e.width, e.height) : d.ac.assign(d.ta.bounds); nj(d, !1) } }
    function cn() { this.wa = "Base" } la(cn, Vl);
    cn.prototype.measure = function (a, b, c, d, e, f, g) {
        var h = a.sb(); a.graduatedBounds = []; var k = h.margin, l = k.right + k.left, m = k.top + k.bottom; h.measure(b, c, f, g); var n = h.measuredBounds, p = new N(-k.left, -k.top, Math.max(n.width + l, 0), Math.max(n.height + m, 0)); a.graduatedBounds.push(p); e.assign(p); for (var r = h.geometry, q = h.strokeWidth, u = r.flattenedSegments, w = r.flattenedLengths, v = r.flattenedTotalLength, z = u.length, y = 0, A = 0, B = Ea(), E = 0; E < z; E++) {
            var M = u[E], R = []; A = y = 0; for (var S = M.length, J = 0; J < S; J += 2) {
                var G = M[J], ca = M[J + 1]; if (0 !==
                    J) { var X = 180 * Math.atan2(ca - A, G - y) / Math.PI; 0 > X && (X += 360); R.push(X) } y = G; A = ca
            } B.push(R)
        } if (null === a.graduatedIntervals) { for (var na = [], wa = a.P.s, db = wa.length, gb = 0; gb < db; gb++) { var Db = wa[gb], eb = []; na.push(eb); if (Db.visible) { var Ke = Db.interval; if (!(0 > Ke)) for (var Ua = 0; Ua < db; Ua++)if (Ua !== gb) { var rd = wa[Ua]; if (rd.visible && Db.constructor === rd.constructor) { var Eb = rd.interval; Eb > Ke && eb.push(Eb) } } } } a.graduatedIntervals = na } var Le = a.graduatedIntervals; var wc = a.P.s, Tc = wc.length, lc = 0, dc = 0, ef = v; a.graduatedMarks = []; for (var sd,
            wh = 0; wh < Tc; wh++) {
                var mc = wc[wh]; sd = []; if (mc.visible && mc !== h) {
                    var Fb = Math.abs(mc.interval), yi = a.graduatedTickUnit; if (!(2 > yi * Fb * v / a.graduatedRange && null === mc.graduatedSkip)) {
                        var rg = w[0][0], Fd = 0, ec = 0; dc = v * mc.graduatedStart - 1E-4; ef = v * mc.graduatedEnd + 1E-4; var Jf = yi * Fb, nc = a.graduatedTickBase; if (nc < a.graduatedMin) { var ff = (a.graduatedMin - nc) / Jf; ff = 0 === ff % 1 ? ff : Math.floor(ff + 1); nc += ff * Jf } else nc > a.graduatedMin + Jf && (nc -= Math.floor((nc - a.graduatedMin) / Jf) * Jf); for (var lk = Le[wh], zi = nc, Mn = 1E-6 * a.graduatedMax, Td =
                            1; nc <= a.graduatedMax + Mn; Td++) {
                                a: { for (var oe = lk.length, sg = 0; sg < oe; sg++) { var gf = (nc - a.graduatedTickBase) % (lk[sg] * a.graduatedTickUnit), xh = 1E-6 * a.graduatedTickUnit; if (gf < xh && gf > -xh) { var hf = !1; break a } } hf = !0 } if (hf && (nc > a.graduatedMax && (nc = a.graduatedMax), null === mc.graduatedSkip || mc instanceof Pg && !mc.graduatedSkip(nc, mc) || mc instanceof Bf && !mc.graduatedSkip(nc, mc)) && (lc = (nc - a.graduatedMin) * v / a.graduatedRange, lc > v && (lc = v), dc <= lc && lc <= ef)) {
                                    for (var Ud = B[Fd][ec], Me = w[Fd][ec]; Fd < w.length;) {
                                        for (; lc > rg && ec < w[Fd].length -
                                            1;)ec++, Ud = B[Fd][ec], Me = w[Fd][ec], rg += Me; if (lc <= rg) break; Fd++; ec = 0; Ud = B[Fd][ec]; Me = w[Fd][ec]; rg += Me
                                    } var fc = u[Fd], Gd = fc[2 * ec], mk = fc[2 * ec + 1], pe = (lc - (rg - Me)) / Me, yh = new I(Gd + (fc[2 * ec + 2] - Gd) * pe + q / 2 - r.bounds.x, mk + (fc[2 * ec + 3] - mk) * pe + q / 2 - r.bounds.y); yh.scale(h.scale, h.scale); a: {
                                        var td = Ud, Kf = B[Fd]; if (!(.5005 > pe && .4995 < pe)) {
                                            var Hd = td; .5 > pe ? 0 < ec ? Hd = Kf[ec - 1] : K.$(fc[0], fc[fc.length - 2]) && K.$(fc[1], fc[fc.length - 1]) && (Hd = Kf[Kf.length - 1]) : .5 < pe && (ec + 1 < Kf.length ? Hd = Kf[ec + 1] : K.$(fc[0], fc[fc.length - 2]) && K.$(fc[1], fc[fc.length -
                                                1]) && (Hd = Kf[0])); if (td !== Hd) { var tg = Math.abs(td - Hd); 180 < tg && (td < Hd ? td += 360 : Hd += 360, tg = Math.abs(td - Hd)); if (5E-4 > pe || .9995 < pe) { Ud = (td + Hd) / 2 % 360; break a } if (10 > tg) { var zh = 1 - Math.abs(.5 - pe); Ud = (td * zh + Hd * (1 - zh)) % 360; break a } }
                                        } Ud = td
                                    } if (mc instanceof Pg) { var ed = ""; null !== mc.graduatedFunction ? (ed = mc.graduatedFunction(nc, mc), ed = null !== ed && void 0 !== ed ? ed.toString() : "") : ed = (+nc.toFixed(2)).toString(); "" !== ed && sd.push({ Wl: yh, angle: Ud, text: ed }) } else sd.push({ Wl: yh, angle: Ud })
                                } nc = zi + Td * Jf
                        }
                    }
                } a.graduatedMarks.push(sd)
        } Ga(B);
        var hb = a.graduatedMarks; if (null !== hb) for (var Ai = d.length, Sb = 0; Sb < Ai; Sb++) {
            var Va = d[Sb], Tb = hb[Sb]; if (Va.visible && Va !== h && 0 !== Tb.length) {
                if (Va instanceof Bf) {
                    var qe = a, Ne = e, Lf = Va.alignmentFocus; Lf.Jc() && (Lf = tc); var Nn = Va.angle; Va.Ib = 0; Va.measure(Infinity, Infinity, 0, 0); Va.Ib = Nn; var nk = Va.measuredBounds, Ah = nk.width, Mf = nk.height, ug = N.allocAt(0, 0, Ah, Mf), gc = I.alloc(); gc.ui(ug, Lf); N.free(ug); for (var Id = -gc.x, ud = -gc.y, Gc = new N, jf = Tb.length, Bh = 0; Bh < jf; Bh++)for (var kf = Tb[Bh], ok = kf.Wl.x, Ch = kf.Wl.y, Oa = kf.angle,
                        fd = 0; 4 > fd; fd++) { switch (fd) { case 0: gc.h(Id, ud); break; case 1: gc.h(Id + Ah, ud); break; case 2: gc.h(Id, ud + Mf); break; case 3: gc.h(Id + Ah, ud + Mf) }gc.rotate(Oa + Va.angle); gc.offset(ok, Ch); 0 === Bh && 0 === fd ? Gc.h(gc.x, gc.y, 0, 0) : Gc.Ue(gc); gc.offset(-ok, -Ch); gc.rotate(-Oa - Va.angle) } I.free(gc); null !== qe.graduatedBounds && qe.graduatedBounds.push(Gc); Yb(Ne, Gc.x, Gc.y, Gc.width, Gc.height)
                } else if (Va instanceof Pg) {
                    var Uc = a, pk = e; null === Uc.temporaryTextBlock && (Uc.temporaryTextBlock = new Pg); var oc = Uc.temporaryTextBlock; dn(oc, Va);
                    var Oe = Va.alignmentFocus; Oe.Jc() && (Oe = tc); for (var Vc = Va.segmentOrientation, Dh = Va.segmentOffset, lf = new N, Bi = 0, Eh = 0, Jd = 0, Nf = 0, mf = 0, Fh = Tb.length, Pe = 0; Pe < Fh; Pe++) {
                        var Vd = Tb[Pe]; Bi = Vd.Wl.x; Eh = Vd.Wl.y; Jd = Vd.angle; mf = Nf = Va.angle; Vc !== Zf && (mf = Vc === en || Vc === fn ? Jd + Va.angle : Jd, Nf = T.computeAngle(Vc, mf)); oc.Ib = Nf; oc.text = Vd.text || ""; oc.measure(Infinity, Infinity, 0, 0); var Qe = oc.measuredBounds, re = oc.naturalBounds, Re = re.width, Of = re.height, vd = ld.alloc(); vd.reset(); vd.translate(-Qe.x, -Qe.y); vd.scale(oc.scale, oc.scale);
                        vd.rotate(mf, Re / 2, Of / 2); Vc !== Xm && Vc !== Ym || vd.rotate(90, Re / 2, Of / 2); Vc !== Zm && Vc !== $m || vd.rotate(-90, Re / 2, Of / 2); Vc === an && (45 < Jd && 135 > Jd || 225 < Jd && 315 > Jd) && vd.rotate(-Jd, Re / 2, Of / 2); var vg = N.allocAt(0, 0, Re, Of), Se = I.alloc(); Se.ui(vg, Oe); vd.Ga(Se); var wg = -Se.x, On = -Se.y, se = I.alloc(); se.assign(Dh); isNaN(se.x) && (se.x = Re / 2 + 3); isNaN(se.y) && (se.y = -(Of / 2 + 3)); se.rotate(Jd); Bi += se.x + wg; Eh += se.y + On; var Pf = new N(Bi, Eh, Qe.width, Qe.height), Ci = new N(Qe.x, Qe.y, Qe.width, Qe.height), Pn = new N(re.x, re.y, re.width, re.height);
                        Vd.Jz = Nf; Vd.lineCount = oc.lineCount; Vd.lines = [oc.Cb, oc.Eh, oc.$e, oc.He, oc.Nc, oc.Xb, oc.ve]; Vd.actualBounds = Pf; Vd.measuredBounds = Ci; Vd.naturalBounds = Pn; 0 === Pe ? lf.assign(Pf) : lf.Zc(Pf); I.free(se); I.free(Se); N.free(vg); ld.free(vd)
                    } null !== Uc.graduatedBounds && Uc.graduatedBounds.push(lf); Yb(pk, lf.x, lf.y, lf.width, lf.height)
                } nj(Va, !1)
            }
        }
    };
    cn.prototype.arrange = function (a, b, c) { if (null !== a.graduatedBounds) { var d = a.sb(), e = a.graduatedMarks; if (null !== e) { var f = a.graduatedBounds, g = 0, h = f[g]; g++; null !== d && d.arrange(h.x - c.x, h.y - c.y, h.width, h.height); for (var k = b.length, l = 0; l < k; l++) { var m = b[l]; h = e[l]; m.visible && m !== d && 0 !== h.length && (h = f[g], g++, m.arrange(h.x - c.x, h.y - c.y, h.width, h.height)) } a.graduatedBounds = null } } };
    cn.prototype.cc = function (a, b, c) {
        var d = c.Xi; c.Xi = !0; var e = a.naturalBounds, f = e.width, g = e.height; e = b instanceof Aj; b.save(); b.beginPath(); b.rect(-1, -1, f + 1, g + 1); b.clip(); b.Ne(); f = a.sb(); f.cc(b, c); g = a.Af() * c.scale; 0 >= g && (g = 1); for (var h = f.actualBounds, k = a.P.s, l = a.graduatedMarks, m = k.length, n = 0; n < m; n++) {
            var p = k[n], r = l[n], q = r.length; if (p.visible && p !== f && 0 !== r.length) if (p instanceof Bf) {
                if (!(2 > a.graduatedTickUnit * p.interval * f.geometry.flattenedTotalLength / a.graduatedRange * g && null === p.graduatedSkip)) {
                    var u =
                        p.measuredBounds, w = p.strokeWidth * p.scale, v = p.alignmentFocus; v.Jc() && (v = tc); for (var z = 0; z < q; z++) { var y = r[z].Wl, A = r[z].angle, B = v, E = p.Ab; E.reset(); E.translate(y.x + h.x, y.y + h.y); E.rotate(A + p.angle, 0, 0); E.translate(-u.width * B.x + B.offsetX + w / 2, -u.height * B.y + B.offsetY + w / 2); E.scale(p.scale, p.scale); Hl(p, !1); p.rh.set(p.Ab); p.Fk = p.scale; Il(p, !1); p.cc(b, c); e && (a.svg.appendChild(p.svg), p.svg = null); p.Ab.reset() }
                }
            } else if (p instanceof Pg) for (null === a.temporaryTextBlock && (a.temporaryTextBlock = new Pg), u = a.temporaryTextBlock,
                dn(u, p), w = 0; w < q; w++)v = r[w], v.actualBounds && v.measuredBounds && v.naturalBounds && (u.bc = v.text || "", u.Ib = v.Jz || 0, u.Dc = v.lineCount || 0, z = v.lines, void 0 !== z && (u.Cb = z[0], u.Eh = z[1], u.$e = z[2], u.He = z[3], u.Nc = z[4], u.Xb = z[5], u.ve = z[6]), v.naturalBounds && (u.ac = v.naturalBounds), v.actualBounds && (z = v.actualBounds, u.arrange(z.x, z.y, z.width, z.height)), z = v.actualBounds, u.arrange(z.x, z.y, z.width, z.height), y = v.measuredBounds, v = v.naturalBounds, A = u.Ab, A.reset(), A.translate(z.x + h.x, z.y + h.y), A.translate(-y.x, -y.y), u.jo(A, v.x,
                    v.y, v.width, v.height), Hl(u, !1), u.rh.set(u.Ab), u.Fk = u.scale, Il(u, !1), u.Ig = p.part, u.Ie = p.panel, u.cc(b, c), u.Ie = null, u.Ig = null, e && (a.svg.appendChild(u.svg), u.svg = null))
        } c.Xi = d; b.restore(); b.Qc(!0)
    };
    function W(a, b) {
        Y.call(this); this.na = null; if (void 0 === a) this.na = W.Position; else if ("string" === typeof a) { var c = Tl.I(a); null !== c && (this.na = c) } else a instanceof Vl ? this.na = a : a && (this.na = W.Position, b = a); null === this.na && C("Panel type not specified or PanelLayout not loaded: " + a); this.o = this.o | 4194304 | (this.na === W.Grid ? 1048576 : 0); this.P = new F; this.yb = pc; this.Qm = Sc; this.Rf = Xk; this.Ug = null; this.We = ""; this.fg = new N(NaN, NaN, NaN, NaN); this.T = this.F = null; this.to = mg; this.en = Jb; this.fn = pb; this.ub = this.ra = null; this.vn =
            NaN; this.ce = this.Dh = null; b && Object.assign(this, b)
    } la(W, Y); W.prototype.cloneProtected = function (a) { Y.prototype.cloneProtected.call(this, a); a.na = this.na; a.yb = this.yb.J(); a.Qm = this.Qm.J(); a.Rf = this.Rf; a.We = this.We; a.fg.assign(this.fg); null !== this.T && (a.T = this.T.copy(a)); a.to = this.to; a.en = this.en.J(); a.fn = this.fn.J(); null !== this.ra && (a.ra = this.ra.copy()); a.ub = this.ub; a.vn = this.vn; a.Dh = this.Dh };
    W.prototype.xf = function (a) { Y.prototype.xf.call(this, a); a.P = this.P; for (var b = a.P.s, c = b.length, d = 0; d < c; d++)b[d].Ie = a; a.Ug = null }; W.prototype.copy = function () { var a = Y.prototype.copy.call(this); if (null !== a) { for (var b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d].copy(); e.ti(a); e.Ig = null; var f = a.P, g = f.count; f.Eb(g, e); f = a.part; if (null !== f) { f.Nh = null; null !== e.portId && f instanceof V && (f.Oe = !0); var h = a.diagram; null !== h && h.undoManager.isUndoingRedoing || f.cb(ee, "elements", a, null, e, null, g) } } return a } return null };
    t = W.prototype; t.toString = function () { return "Panel(" + this.type.name + ")#" + bb(this) }; t.Ln = function (a) { Y.prototype.Ln.call(this, a); for (var b = this.P.s, c = b.length, d = 0; d < c; d++)b[d].Ln(a) };
    t.bh = function (a, b) { if (this.na === W.Grid) a.vc(), this.na.cc(this, a, b); else if (this.na === W.Graduated) a.vc(), this.na.cc(this, a, b); else { if (this.na === W.Table) if (a.vc(), a instanceof Aj) { var c = a.sd; a.sd = Bl(a, "gojs-ts"); gn(this, a); a.ih.pop(); a.sd = c } else gn(this, a); (c = this.isClipping && this.na === W.Spot) && a.save(); for (var d = this.sb(), e = this.P.s, f = e.length, g = 0; g < f; g++) { var h = e[g]; c && h === d && (a.clipInsteadOfFill = !0); h.cc(a, b); c && h === d && (a.clipInsteadOfFill = !1) } c && (a.restore(), a.Qc(!0)) } };
    function gn(a, b) { b.lineCap = "butt"; var c = 0 < a.rowCount ? a.rowDefs : null, d = 0 < a.columnCount ? a.colDefs : null; null !== c && hn(a, b, !0, c, !0); null !== d && hn(a, b, !1, d, !0); null !== c && jn(a, b, !0, c); null !== d && jn(a, b, !1, d); null !== c && hn(a, b, !0, c, !1); null !== d && hn(a, b, !1, d, !1) }
    function jn(a, b, c, d) {
        for (var e = d.length, f = a.actualBounds, g = a.naturalBounds, h = !0, k = 0; k < e; k++) {
            var l = d[k]; if (void 0 !== l && 0 !== l.actual) if (h) h = !1; else {
                if (c) { if (l.position > g.height) continue } else if (l.position > g.width) continue; var m = l.separatorStrokeWidth; isNaN(m) && (m = c ? a.defaultRowSeparatorStrokeWidth : a.defaultColumnSeparatorStrokeWidth); var n = l.separatorStroke; null === n && (n = c ? a.defaultRowSeparatorStroke : a.defaultColumnSeparatorStroke); if (0 !== m && null !== n) {
                    hi(a, b, n, !1, !1, g, f); n = !1; var p = l.separatorDashArray;
                    null === p && (p = c ? a.defaultRowSeparatorDashArray : a.defaultColumnSeparatorDashArray); null !== p && (n = !0, b.bs(p, 0)); b.beginPath(); p = l.position + m; c ? p > g.height && (m -= p - g.height) : p > g.width && (m -= p - g.width); l = l.position + m / 2; b.lineWidth = m; m = a.yb; c ? (l += m.top, p = g.width - m.right, b.moveTo(m.left, l), b.lineTo(p, l)) : (l += m.left, p = g.height - m.bottom, b.moveTo(l, m.top), b.lineTo(l, p)); b.stroke(); b.Ne(); n && b.Yr()
                }
            }
        }
    }
    function hn(a, b, c, d, e) {
        for (var f = d.length, g = a.actualBounds, h = a.naturalBounds, k = kn(a, !0), l = kn(a, !1), m = 0; m < f; m++) {
            var n = d[m]; if (void 0 !== n && null !== n.background && n.coversSeparators !== e && 0 !== n.actual) {
                var p = c ? g.height : g.width; if (!(n.position > p)) {
                    var r = n.Rc(), q = n.separatorStrokeWidth; isNaN(q) && (q = c ? a.defaultRowSeparatorStrokeWidth : a.defaultColumnSeparatorStrokeWidth); var u = n.separatorStroke; null === u && (u = c ? a.defaultRowSeparatorStroke : a.defaultColumnSeparatorStroke); if (null === u || n.index === (c ? k : l)) q = 0; r -=
                        q; q = n.position + q; r += n.actual; q + r > p && (r = p - q); 0 >= r || (p = a.yb, hi(a, b, n.background, !0, !1, h, g), c ? b.fillRect(p.left, q + p.top, g.width - (p.left + p.right), r) : b.fillRect(q + p.left, p.top, r, g.height - (p.top + p.bottom)))
                }
            }
        }
    } function kn(a, b) { for (var c = b ? a.rowCount : a.columnCount, d = 0; d < c; d++) { var e = b ? a.rowDefs[d] : a.colDefs[d]; if (void 0 !== e) return e.index } return 0 } function Yj(a) { return "LineV" === a || "BarV" === a }
    t.fk = function (a, b, c) { if (!Y.prototype.fk.call(this, a, b, c)) return !1; if (this.type === W.Table) c = this.svg.getElementsByClassName("gojs-ts"), c = 0 !== c.length ? c[0] : null, null !== c && (c.innerHTML = ""), a.sd = c, gn(this, a), a.sd = null; else if (this.na === W.Grid || this.na === W.Graduated) return this.svg.remove(), !1; c = this.P.s; for (var d = c.length, e = 0; e < d; e++)c[e].cc(a, b); return !0 }; t.ik = function () { return !0 }; t.Uo = function (a, b, c, d) { this.isClipping || Y.prototype.Uo.call(this, a, b, c, d) };
    t.Qj = function (a, b, c, d, e) {
        var f = this.oe(), g = this.transform, h = 1 / (g.m11 * g.m22 - g.m12 * g.m21), k = g.m22 * h, l = -g.m12 * h, m = -g.m21 * h, n = g.m11 * h, p = h * (g.m21 * g.dy - g.m22 * g.dx), r = h * (g.m12 * g.dx - g.m11 * g.dy); if (null !== this.areaBackground) return g = this.actualBounds, K.Cf(g.left, g.top, g.right, g.bottom, a, b, c, d, e); if (null !== this.background) return f = a * k + b * m + p, h = a * l + b * n + r, a = c * k + d * m + p, k = c * l + d * n + r, e.h(0, 0), c = this.naturalBounds, f = K.Cf(0, 0, c.width, c.height, f, h, a, k, e), e.transform(g), f; f || (k = 1, m = l = 0, n = 1, r = p = 0); h = a * k + b * m + p; a = a * l + b *
            n + r; k = c * k + d * m + p; c = c * l + d * n + r; e.h(k, c); d = (k - h) * (k - h) + (c - a) * (c - a); l = !1; n = this.P.s; r = n.length; m = I.alloc(); p = null; b = Infinity; var q = null, u = this.isClipping && this.na === W.Spot; u && (q = I.alloc(), p = this.sb(), (l = p.Qj(h, a, k, c, q)) && (b = (h - q.x) * (h - q.x) + (a - q.y) * (a - q.y))); for (var w = 0; w < r; w++) { var v = n[w]; v.visible && v !== p && v.Qj(h, a, k, c, m) && (l = !0, v = (h - m.x) * (h - m.x) + (a - m.y) * (a - m.y), v < d && (d = v, e.set(m))) } u && (b > d && e.set(q), I.free(q)); I.free(m); f && e.transform(g); return l
    };
    t.Ej = function () { for (var a = this.P.s, b = a.length, c = 0; c < b; c++)a[c].Ej(); Y.prototype.Ej.call(this) }; t.v = function (a) { if (!sj(this)) { Y.prototype.v.call(this, a); a = null; if (this.na === W.Auto || this.na === W.Link) a = this.sb(); for (var b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d]; (e === a || e.isPanelMain) && e.v(!0); if (!e.desiredSize.w()) { var f = il(e, !1); (e instanceof ig || e instanceof W || e instanceof Pg || f !== kg) && e.v(!0) } } } }; t.Sj = function () { if (!sj(this)) { nj(this, !0); kl(this, !0); for (var a = this.P.s, b = a.length, c = 0; c < b; c++)a[c].Sj() } };
    t.Pk = function () { if (0 !== (this.o & 2048) === !1) { Hl(this, !0); Il(this, !0); for (var a = this.P.s, b = a.length, c = 0; c < b; c++)a[c].Su() } }; t.Su = function () { Il(this, !0); for (var a = this.P.s, b = a.length, c = 0; c < b; c++)a[c].Su() };
    t.Sl = function (a, b, c, d) {
        var e = this.fg; e.h(0, 0, 0, 0); var f = this.desiredSize, g = this.minSize; void 0 === c && (c = g.width, d = g.height); c = Math.max(c, g.width); d = Math.max(d, g.height); var h = this.maxSize; isNaN(f.width) || (a = Math.min(f.width, h.width)); isNaN(f.height) || (b = Math.min(f.height, h.height)); a = Math.max(c, a); b = Math.max(d, b); var k = this.yb; a = Math.max(a - k.left - k.right, 0); b = Math.max(b - k.top - k.bottom, 0); var l = this.P.s; 0 !== l.length && this.na.measure(this, a, b, l, e, c, d); a = e.width + k.left + k.right; k = e.height + k.top + k.bottom;
        isFinite(f.width) && (a = f.width); isFinite(f.height) && (k = f.height); a = Math.min(h.width, a); k = Math.min(h.height, k); a = Math.max(g.width, a); k = Math.max(g.height, k); a = Math.max(c, a); k = Math.max(d, k); Pb(e, a, k); Pb(this.naturalBounds, a, k); this.Ce(0, 0, a, k)
    }; t.sb = function () { if (null === this.Ug) { var a = this.P.s, b = a.length; if (0 === b) return null; for (var c = 0; c < b; c++) { var d = a[c]; if (!0 === d.isPanelMain) return this.Ug = d } this.Ug = a[0] } return this.Ug }; function Fm(a) { return null !== a.part ? a.part.locationObject : null }
    t.Zg = function (a, b, c, d) { var e = this.P.s; this.actualBounds.h(a, b, c, d); if (0 !== e.length) { if (!this.desiredSize.w()) { a = il(this, !0); var f = this.measuredBounds; b = f.width; f = f.height; var g = this.Fg, h = g.left + g.right; g = g.top + g.bottom; b === c && f === d && (a = kg); switch (a) { case kg: if (b > c || f > d) this.v(), this.measure(b > c ? c : b, f > d ? d : f, 0, 0); break; case nd: this.v(!0); this.measure(c + h, d + g, 0, 0); break; case Vk: this.v(!0); this.measure(c + h, f + g, 0, 0); break; case Wk: this.v(!0), this.measure(b + h, d + g, 0, 0) } } this.na.arrange(this, e, this.fg) } };
    t.ah = function (a) { var b = this.naturalBounds, c = Fm(this); if (Zb(0, 0, b.width, b.height, a.x, a.y)) { b = this.P.s; for (var d = b.length, e = I.allocAt(0, 0); d--;) { var f = b[d]; if (f.visible || f === c) if (xb(e.set(a), f.transform), f.ca(e)) return I.free(e), !0 } I.free(e); return null === this.Ra && null === this.areaBackground ? !1 : !0 } return !1 }; t.cs = function (a) { if (this.bindingId === a) return this; for (var b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d].cs(a); if (null !== e) return e } return null };
    t.nm = function (a, b) { b(this, a); if (a instanceof W) { a = a.P.s; for (var c = a.length, d = 0; d < c; d++)this.nm(a[d], b) } }; function Gj(a, b) { ln(a, a, b) } function ln(a, b, c) { c(b); b = b.P.s; for (var d = b.length, e = 0; e < d; e++) { var f = b[e]; f instanceof W && ln(a, f, c) } } function mn(a, b) { nn(a, a, b) } function nn(a, b, c) { c(b); if (b instanceof W) { b = b.P.s; for (var d = b.length, e = 0; e < d; e++)nn(a, b[e], c) } } t.Il = function (a) { return on(this, this, a) };
    function on(a, b, c) { if (c(b)) return b; if (b instanceof W) { b = b.P.s; for (var d = b.length, e = 0; e < d; e++) { var f = on(a, b[e], c); if (null !== f) return f } } return null } t.fb = function (a) { if (this.name === a) return this; var b = this.P.s, c = b.length; null === this.Dh && null === this.itemTemplateMap || (c = pn(this)); for (var d = 0; d < c; d++) { var e = b[d]; if (e instanceof W) { var f = e.fb(a); if (null !== f) return f } if (e.name === a) return e } return null };
    function qn(a) { a = a.P.s; for (var b = a.length, c = 0, d = 0; d < b; d++) { var e = a[d]; e instanceof W ? c = Math.max(c, qn(e)) : e instanceof Bf && (c = Math.max(c, e.eg / 2 * e.strokeMiterLimit * e.Af())) } return c } t.oe = function () { return !(this.type === W.TableRow || this.type === W.TableColumn) }; function jl(a) { return a.type === W.TableRow || a.type === W.TableColumn }
    t.ec = function (a, b, c) {
        if (!1 === this.pickable) return null; void 0 === b && (b = null); void 0 === c && (c = null); if (tj(this)) return null; var d = this.naturalBounds, e = 1 / this.Af(), f = this.oe(), g = f ? a : xb(I.allocAt(a.x, a.y), this.transform), h = this.diagram, k = 10, l = 5; null !== h && (k = h.Ll("extraTouchArea"), l = k / 2); if (Zb(-(l * e), -(l * e), d.width + k * e, d.height + k * e, g.x, g.y)) {
            if (!this.isAtomic) {
                e = this.P.s; var m = e.length; h = I.alloc(); l = (k = this.isClipping && this.na === W.Spot) ? this.sb() : null; if (k && (l.oe() ? xb(h.set(a), l.transform) : h.set(a), !l.ca(h))) return I.free(h),
                    f || I.free(g), null; for (var n = Fm(this); m--;) { var p = e[m]; if (p.visible || p === n) if (p.oe() ? xb(h.set(a), p.transform) : h.set(a), !k || p !== l) { var r = null; p instanceof W ? r = p.ec(h, b, c) : !0 === p.pickable && p.ca(h) && (r = p); if (null !== r && (null !== b && (r = b(r)), null !== r && (null === c || c(r)))) return I.free(h), f || I.free(g), r } } I.free(h)
            } if (null === this.background && null === this.areaBackground) return f || I.free(g), null; a = Zb(0, 0, d.width, d.height, g.x, g.y) ? this : null; f || I.free(g); return a
        } f || I.free(g); return null
    };
    t.gi = function (a, b, c, d) {
        if (!1 === this.pickable) return !1; void 0 === b && (b = null); void 0 === c && (c = null); var e = this.naturalBounds, f = this.oe(), g = f ? a : xb(I.allocAt(a.x, a.y), this.transform); e = Zb(0, 0, e.width, e.height, g.x, g.y); if (jl(this) || e) {
            if (!this.isAtomic) {
                for (var h = this.P.s, k = h.length, l = I.alloc(), m = Fm(this); k--;) {
                    var n = h[k]; if (n.visible || n === m) {
                        n.oe() ? xb(l.set(a), n.transform) : l.set(a); var p = n; n = n instanceof W ? n : null; (null !== n ? n.gi(l, b, c, d) : p.ca(l)) && !1 !== p.pickable && (null !== b && (p = b(p)), null === p || null !== c &&
                            !c(p) || d.add(p))
                    }
                } I.free(l)
            } f || I.free(g); return e && (null !== this.background || null !== this.areaBackground)
        } f || I.free(g); return !1
    };
    t.zf = function (a, b, c, d, e, f) {
        if (!1 === this.pickable) return !1; void 0 === b && (b = null); void 0 === c && (c = null); var g = f; void 0 === f && (g = ld.alloc(), g.reset()); g.multiply(this.transform); if (this.$g(a, g)) return rn(this, b, c, e), void 0 === f && ld.free(g), !0; if (this.Tc(a, g)) {
            if (!this.isAtomic) for (var h = Fm(this), k = this.P.s, l = k.length; l--;) {
                var m = k[l]; if (m.visible || m === h) {
                    var n = m.actualBounds, p = this.naturalBounds; if (!(n.x > p.width || n.y > p.height || 0 > n.x + n.width || 0 > n.y + n.height)) {
                        n = m; m = m instanceof W ? m : null; p = ld.alloc(); p.set(g);
                        if (null !== m ? m.zf(a, b, c, d, e, p) : hl(n, a, d, p)) null !== b && (n = b(n)), null === n || null !== c && !c(n) || e.add(n); ld.free(p)
                    }
                }
            } void 0 === f && ld.free(g); return d
        } void 0 === f && ld.free(g); return !1
    }; function rn(a, b, c, d) { for (var e = a.P.s, f = e.length; f--;) { var g = e[f]; if (g.visible) { var h = g.actualBounds, k = a.naturalBounds; h.x > k.width || h.y > k.height || 0 > h.x + h.width || 0 > h.y + h.height || (g instanceof W && rn(g, b, c, d), null !== b && (g = b(g)), null === g || null !== c && !c(g) || d.add(g)) } } }
    t.kg = function (a, b, c, d, e, f) {
        if (!1 === this.pickable) return !1; void 0 === c && (c = null); void 0 === d && (d = null); var g = this.naturalBounds, h = this.oe(), k = h ? a : xb(I.allocAt(a.x, a.y), this.transform), l = h ? b : xb(I.allocAt(b.x, b.y), this.transform), m = k.yf(l), n = 0 < k.x && k.x < g.width && 0 < k.y && k.y < g.height || yb(k.x, k.y, 0, 0, 0, g.height) <= m || yb(k.x, k.y, 0, g.height, g.width, g.height) <= m || yb(k.x, k.y, g.width, g.height, g.width, 0) <= m || yb(k.x, k.y, g.width, 0, 0, 0) <= m; g = k.td(0, 0) <= m && k.td(0, g.height) <= m && k.td(g.width, 0) <= m && k.td(g.width, g.height) <=
            m; h || (I.free(k), I.free(l)); if (n) { if (!this.isAtomic) { k = I.alloc(); l = I.alloc(); m = Fm(this); for (var p = this.P.s, r = p.length; r--;) { var q = p[r]; if (q.visible || q === m) { var u = q.actualBounds, w = this.naturalBounds; if (!h || !(u.x > w.width || u.y > w.height || 0 > u.x + u.width || 0 > u.y + u.height)) if (q.oe() ? (u = q.transform, xb(k.set(a), u), xb(l.set(b), u)) : (k.set(a), l.set(b)), u = q, q = q instanceof W ? q : null, null !== q ? q.kg(k, l, c, d, e, f) : u.mx(k, l, e)) null !== c && (u = c(u)), null === u || null !== d && !d(u) || f.add(u) } } I.free(k); I.free(l) } return e ? n : g } return !1
    };
    function Lm(a) { var b = null; a instanceof Bf && (b = a.spot1, b === Sc && (b = null), a = a.geometry, null !== a && null === b && (b = a.spot1)); null === b && (b = sc); return b } function Mm(a) { var b = null; a instanceof Bf && (b = a.spot2, b === Sc && (b = null), a = a.geometry, null !== a && null === b && (b = a.spot2)); null === b && (b = Bc); return b } t.add = function (a) { for (var b = [], c = 0; c < arguments.length; ++c)b[c] = arguments[c]; for (c = 0; c < b.length; c++)this.Eb(this.P.count, b[c]); return this }; t.L = function (a) { return this.P.L(a) };
    t.Eb = function (a, b) {
        b instanceof U && C("Cannot add a Part to a Panel: " + b + "; use a Panel instead"); if (this === b || this.ng(b)) this === b && C("Cannot make a Panel contain itself: " + this.toString()), C("Cannot make a Panel indirectly contain itself: " + this.toString() + " already contains " + b.toString()); var c = b.panel; null !== c && c !== this && C("Cannot add a GraphObject that already belongs to another Panel to this Panel: " + b.toString() + ", already contained by " + c.toString() + ", cannot be shared by this Panel: " + this.toString());
        this.na !== W.Grid || b instanceof Bf || C("Can only add Shapes to a Grid Panel, not: " + b); this.na !== W.Graduated || b instanceof Bf || b instanceof Pg || C("Can only add Shapes or TextBlocks to a Graduated Panel, not: " + b); b.ti(this); b.Ig = null; if (null !== this.itemArray) { var d = b.data; null !== d && "object" === typeof d && (null === this.ce && (this.ce = new ob), this.ce.add(d, b)) } var e = this.P; d = -1; if (c === this) {
            for (var f = -1, g = this.P.s, h = g.length, k = 0; k < h; k++)if (g[k] === b) { f = k; break } if (-1 !== f) {
                if (f === a || f + 1 >= e.count && a >= e.count) return;
                e.nb(f); d = f
            } else C("element " + b.toString() + " has panel " + c.toString() + " but is not contained by it.")
        } if (0 > a || a > e.count) a = e.count; e.Eb(a, b); if (0 === a || b.isPanelMain) this.Ug = null; sj(this) || this.v(); b.v(!1); null !== b.portId ? this.Oe = !0 : b instanceof W && !0 === b.Oe && (this.Oe = !0); this.graduatedIntervals = null; this.Ff() || sn(this, b, !1); c = this.part; null !== c && (c.Nh = null, c.Xf = NaN, this.Oe && c instanceof V && (c.Oe = !0), c.Oe && c instanceof V && (c.tc = null), e = this.diagram, null !== e && e.undoManager.isUndoingRedoing || (-1 !== d &&
            c.cb(fe, "elements", this, b, null, d, null), c.cb(ee, "elements", this, null, b, null, a)))
    }; function tn(a, b) { a.o = b ? a.o | 16777216 : a.o & -16777217 } t.remove = function (a) { for (var b = this.P.s, c = b.length, d = -1, e = 0; e < c; e++)if (b[e] === a) { d = e; break } -1 !== d && this.mc(d, !0) }; t.nb = function (a) { 0 <= a && this.mc(a, !0) };
    t.mc = function (a, b) { var c = this.P, d = c.L(a); d.Ig = null; d.ti(null); if (null !== this.ce) { var e = d.data; "object" === typeof e && this.ce.remove(e) } c.nb(a); nj(this, !1); this.v(); this.Ug === d && (this.Ug = null); this.graduatedIntervals = null; var f = this.part; null !== f && (f.Nh = null, f.Xf = NaN, f.Sb(), f instanceof V && (d instanceof W ? d.nm(d, function (a, c) { $l(f, c, b) }) : $l(f, d, b)), c = this.diagram, null !== c && c.undoManager.isUndoingRedoing || f.cb(fe, "elements", this, d, null, a, null)) };
    W.prototype.getRowDefinition = function (a) { 0 > a && xa(a, ">= 0", W, "getRowDefinition:idx"); a = Math.round(a); var b = this.rowDefs; if (void 0 === b[a]) { var c = new Uj; c.ti(this); c.isRow = !0; c.index = a; b[a] = c; this.cb(ee, "rowdefs", this, null, c, null, a) } return b[a] }; W.prototype.eA = function (a) { 0 > a && xa(a, ">= 0", W, "removeRowDefinition:idx"); if (0 !== this.rowCount) { a = Math.round(a); var b = this.rowDefs, c = b[a]; if (c) { b[a] = void 0; for (var d = b.length - 1; 0 <= d && void 0 === b[d--];)b.length--; this.cb(fe, "rowdefs", this, c, null, a, null); this.v() } } };
    function Vj(a, b, c) { var d = a.rowDefs; if (!(0 > b)) { d[b] = c instanceof Uj ? c : void 0; if (!c) for (b = d.length - 1; 0 <= b && void 0 === d[b--];)d.length--; a.v() } } W.prototype.getColumnDefinition = function (a) { 0 > a && xa(a, ">= 0", W, "getColumnDefinition:idx"); a = Math.round(a); var b = this.colDefs; if (void 0 === b[a]) { var c = new Uj; c.ti(this); c.isRow = !1; c.index = a; b[a] = c; this.cb(ee, "coldefs", this, null, c, null, a) } return b[a] };
    W.prototype.$z = function (a) { 0 > a && xa(a, ">= 0", W, "removeColumnDefinition:idx"); if (0 !== this.columnCount) { a = Math.round(a); var b = this.colDefs, c = b[a]; if (c) { b[a] = void 0; for (var d = b.length - 1; 0 <= d && void 0 === b[d--];)b.length--; this.cb(fe, "coldefs", this, c, null, a, null); this.v() } } }; function Wj(a, b, c) { var d = a.colDefs; if (!(0 > b)) { d[b] = c instanceof Uj ? c : void 0; if (!c) for (b = d.length - 1; 0 <= b && void 0 === d[b--];)d.length--; a.v() } }
    W.prototype.addRowColumnDefinition = function (a) { var b = null; a.isRow ? b = this.getRowDefinition(a.index) : a.isRow || (b = this.getColumnDefinition(a.index)); b && Ul(b, a); return this }; t = W.prototype; t.fz = function (a) { if (0 > a || this.type !== W.Table || 0 === this.rowCount) return -1; for (var b = 0, c = this.rowDefs, d = c.length, e = this.topIndex; e < d; e++) { var f = c[e]; if (void 0 !== f && (b += f.total, a < b)) break } return e };
    t.Yy = function (a) { if (0 > a || this.type !== W.Table || 0 === this.columnCount) return -1; for (var b = 0, c = this.colDefs, d = c.length, e = this.leftIndex; e < d; e++) { var f = c[e]; if (void 0 !== f && (b += f.total, a < b)) break } return e }; t.xz = function (a, b) { void 0 === b && (b = new I(NaN, NaN)); if (this.type !== W.Graduated) return b.h(NaN, NaN), b; a = Math.min(Math.max(a, this.graduatedMin), this.graduatedMax); var c = this.sb(); c.geometry.Lu((a - this.graduatedMin) / this.graduatedRange, b); return c.transform.Ga(b) };
    t.yz = function (a) { if (this.type !== W.Graduated) return NaN; var b = this.sb(); b.transform.Ld(a); return b.geometry.qx(a) * this.graduatedRange + this.graduatedMin }; function Pl(a) { a = a.allBindings; return null !== a && a._isFrozen }
    function jg(a) {
        var b = a.allBindings; if (null === b) null !== a.data && C("Template cannot have .data be non-null: " + a), a.allBindings = b = new F; else if (b._isFrozen) return; var c = new F; tn(a, !1); a.nm(a, function (a, d) {
            d.oh(); var e = d.localBindings; if (null !== e) for (Ml(d, !1), e = e.iterator; e.next();) { var f = e.value; f.mode === un && Ml(d, !0); var g = f.sourceName; null !== g && ("/" === g && tn(a, !0), g = gl(f, a, d), null !== g && (c.add(g), null === g.srcBindings && (g.srcBindings = new F), g.srcBindings.add(f))); b.add(f) } if (d instanceof W && d.type === W.Table) {
                if (0 <
                    d.rowCount) for (a = d.rowDefs, e = a.length, f = 0; f < e; f++)if (g = a[f], void 0 !== g && null !== g.Yd) for (var h = g.Yd.iterator; h.next();) { var k = h.value; k.rd = g; k.po = 2; k.sl = g.index; b.add(k) } if (0 < d.columnCount) for (d = d.colDefs, a = d.length, e = 0; e < a; e++)if (f = d[e], void 0 !== f && null !== f.Yd) for (g = f.Yd.iterator; g.next();)h = g.value, h.rd = f, h.po = 1, h.sl = f.index, b.add(h)
            }
        }); for (var d = c.iterator; d.next();) {
            var e = d.value; if (null !== e.srcBindings) {
                Ml(e, !0); for (var f = e.srcBindings.iterator; f.next();) {
                    var g = f.value; null === e.localBindings &&
                        (e.localBindings = new F); e.localBindings.add(g)
                }
            } e.srcBindings = null
        } for (d = b.iterator; d.next();)if (e = d.value, f = e.rd, null !== f) {
            e.rd = null; var h = e.targetProperty, k = h.indexOf("."); 0 < k && f instanceof W && (g = h.substring(0, k), h = h.substr(k + 1), k = f.fb(g), null !== k ? (f = k, e.targetProperty = h) : ya('Warning: unable to find GraphObject named "' + g + '" for Binding: ' + e.toString())); f instanceof Uj ? (g = bb(f.panel), e.xi = void 0 === g ? -1 : g, f.panel.bindingId = e.xi) : f instanceof Y ? (g = bb(f), e.xi = void 0 === g ? -1 : g, f.bindingId = e.xi) : C("Unknown type of binding target: " +
                f)
        } b.freeze(); a instanceof U && a.Uc() && a.Va()
    } t.My = function (a) { void 0 === a && (a = !1); var b = this.copy(); mn(b, function (a) { a instanceof W && (a.allBindings = null, a.ub = null); var b = a.localBindings; null !== b && (a.localBindings = null, b.each(function (b) { a.bind(b.copy()) })); b = a.animationTriggers; null !== b && (a.animationTriggers = null, b.each(function (b) { a.Cv(b.value.copy()) })) }); a && jg(b); return b };
    t.Ma = function (a) {
        var b = this.allBindings; if (null !== b) for (void 0 === a && (a = ""), b = b.iterator; b.next();) {
            var c = b.value, d = c.sourceProperty; if ("" === a || "" === d || d === a) if (d = c.targetProperty, null !== c.converter || "" !== d) {
                d = this.data; var e = c.sourceName; if (null !== e) if ("" === e) d = this; else if ("/" === e) d = this; else if ("." === e) d = this; else if (".." === e) d = this; else { if (d = this.fb(e), null === d) continue } else if (c.isToModel) if (d = this.diagram, null !== d) d = d.model.modelData; else continue; var f = this, g = c.xi; if (-1 !== g) {
                    if (f = this.cs(g),
                        null === f) continue
                } else null !== c.rd && (f = c.rd); "/" === e ? d = f.part : "." === e ? d = f : ".." === e && (d = f.panel); e = c.po; if (0 !== e) { if (!(f instanceof W)) continue; 1 === e ? f = f.getColumnDefinition(c.sl) : 2 === e && (f = f.getRowDefinition(c.sl)) } void 0 !== f && c.Fv(f, d)
            }
        }
    }; t.lx = function (a) { return void 0 === a || null === a || null === this.ce ? null : this.ce.I(a) };
    function vn(a, b) { a = a.P.s; for (var c = a.length, d = b.length, e = 0, f = null; e < c && !(f = a[e], f instanceof W && null !== f.data);)e++, f = a[e]; if (c - e !== d) return !0; if (null === f) return 0 < d; for (var g = 0; e < c && g < d;) { f = a[e]; if (!(f instanceof W) || f.data !== b[g]) return !0; e++; g++ } return !1 }
    function pn(a) { if (a.type === W.Spot || a.type === W.Auto) return Math.min(a.P.length, 1); if (a.type === W.Link) { a = a.P; for (var b = a.length, c = 0; c < b; c++) { var d = a.L(c); if (!(d instanceof Bf && d.isPanelMain)) break } return c } return a.type === W.Table && 0 < a.P.length && (a = a.P.L(0), a.isPanelMain && a instanceof W && jl(a)) ? 1 : 0 } t.hv = function () { for (var a = pn(this); this.P.length > a;)this.mc(this.P.length - 1, !1); wn(this, this.itemArray) };
    function wn(a, b) {
        var c = pn(a); if (null === b || 0 === b.length) for (; a.P.length > c;)a.mc(a.P.length - 1, !1); else if (a.P.length <= c) { c = b.length; for (var d = 0; d < c; d++)xn(a, b[d], d, !0) } else {
            d = c; for (var e = 0; e < b.length; e++, d++) { var f = b[e], g = d < a.P.length ? a.P.L(d) : null; if (g instanceof W) { if (g.data !== f) { var h = yn(a, g.data), k = yn(a, f), l = k !== h; l || (h = zn(a, h), l = zn(a, k) !== h); l ? (a.mc(d, !1), xn(a, f, e, !1)) : g.data = f } } else xn(a, f, e, !1) } for (; d < a.P.length;)a.mc(d, !1); a.Ug = null; An(a, c, 0); sj(a) || a.v(); a.graduatedIntervals = null; a.Ff() || sn(a,
                a, !1); b = a.part; null !== b && (b.Nh = null, b.Xf = NaN, a.Oe && b instanceof V && (b.Oe = !0), b.Oe && b instanceof V && (b.tc = null))
        }
    } function xn(a, b, c, d) { if (!(void 0 === b || null === b || 0 > c)) { var e = yn(a, b); e = zn(a, e); if (null !== e) { jg(e); e = e.copy(); if (0 !== (e.o & 16777216)) { var f = a.dh(); null !== f && tn(f, !0) } "object" === typeof b && (null === a.ce && (a.ce = new ob), a.ce.add(b, e)); f = c + pn(a); a.Eb(f, e); d && (e.ub = b, An(a, f, c), e.ub = null); e.data = b } } }
    function An(a, b, c) { for (a = a.P; b < a.length;) { var d = a.L(b); if (d instanceof W) { var e = b, f = c; d.type === W.TableRow ? d.row = e : d.type === W.TableColumn && (d.column = e); d.itemIndex = f } b++; c++ } } function yn(a, b) { if (null === b) return ""; a = a.itemCategoryProperty; if ("function" === typeof a) a = a(b); else if ("string" === typeof a && "object" === typeof b) { if ("" === a) return ""; a = Bn(b, a) } else return ""; if (void 0 === a) return ""; if ("string" === typeof a) return a; C("Panel.getCategoryForItemData found a non-string category for " + b + ": " + a) }
    function zn(a, b) { var c = a.itemTemplateMap, d = null; null !== c && (d = c.I(b)); null === d && (null !== c && (d = c.I("")), null === d && (Cn || (Cn = !0, ya('No item template Panel found for category "' + b + '" on ' + a), ya("  Using default item template."), a = new W, b = new Pg, b.bind(new xi("text", "", Ia)), a.add(b), Dn = a), d = Dn)); return d } function sn(a, b, c) { var d = b.enabledChanged; null !== d && d(b, c); if (b instanceof W) { b = b.P.s; d = b.length; for (var e = 0; e < d; e++) { var f = b[e]; c && f instanceof W && !f.isEnabled || sn(a, f, c) } } }
    function En(a, b) { b.name = a; Tl.add(a, b) }
    ma.Object.defineProperties(W.prototype, {
        type: { get: function () { return this.na }, set: function (a) { var b = this.na; b !== a && (this.na = a, this.isAtomic = this.na === W.Grid, this.v(), this.g("type", b, a)) } }, elements: { get: function () { return this.P.iterator } }, naturalBounds: { get: function () { return this.ac } }, padding: {
            get: function () { return this.yb }, set: function (a) {
                "number" === typeof a ? (0 > a && xa(a, ">= 0", W, "padding"),
                    a = new hc(a)) : (0 > a.left && xa(a.left, ">= 0", W, "padding:value.left"), 0 > a.right && xa(a.right, ">= 0", W, "padding:value.right"), 0 > a.top && xa(a.top, ">= 0", W, "padding:value.top"), 0 > a.bottom && xa(a.bottom, ">= 0", W, "padding:value.bottom")); var b = this.yb; b.C(a) || (this.yb = a = a.J(), this.v(), this.g("padding", b, a))
            }
        }, defaultAlignment: { get: function () { return this.Qm }, set: function (a) { var b = this.Qm; b.C(a) || (this.Qm = a = a.J(), this.v(), this.g("defaultAlignment", b, a)) } }, defaultStretch: {
            get: function () { return this.Rf }, set: function (a) { var b = this.Rf; b !== a && (this.Rf = a, this.v(), this.g("defaultStretch", b, a)) }
        }, defaultSeparatorPadding: { get: function () { return null !== this.T ? this.T.Kp : pc }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.defaultSeparatorPadding; b.C(a) || (a = a.J(), null === this.T && (this.T = new Fn), this.T.Kp = a, this.v(), this.g("defaultSeparatorPadding", b, a)) } }, defaultRowSeparatorStroke: {
            get: function () {
                return null !==
                    this.T ? this.T.ga : null
            }, set: function (a) { var b = this.defaultRowSeparatorStroke; b !== a && (null === a || "string" === typeof a || a instanceof pl) && (a instanceof pl && a.freeze(), null === this.T && (this.T = new Fn), this.T.ga = a, this.N(), this.g("defaultRowSeparatorStroke", b, a)) }
        }, defaultRowSeparatorStrokeWidth: {
            get: function () { return null !== this.T ? this.T.Ha : 1 }, set: function (a) {
                var b = this.defaultRowSeparatorStrokeWidth; b !== a && isFinite(a) && 0 <= a && (null === this.T && (this.T = new Fn), this.T.Ha = a, this.v(),
                    this.g("defaultRowSeparatorStrokeWidth", b, a))
            }
        }, defaultRowSeparatorDashArray: {
            get: function () { return null !== this.T ? this.T.V : null }, set: function (a) {
                var b = this.defaultRowSeparatorDashArray; if (b !== a) {
                    if (null !== a) { for (var c = a.length, d = 0, e = 0; e < c; e++) { var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || C("defaultRowSeparatorDashArray value " + f + " at index " + e + " must be a positive number or zero."); d += f } if (0 === d) { if (null === b) return; a = null } } null === this.T && (this.T = new Fn); this.T.V =
                        a; this.N(); this.g("defaultRowSeparatorDashArray", b, a)
                }
            }
        }, defaultColumnSeparatorStroke: { get: function () { return null !== this.T ? this.T.u : null }, set: function (a) { var b = this.defaultColumnSeparatorStroke; b !== a && (null === a || "string" === typeof a || a instanceof pl) && (a instanceof pl && a.freeze(), null === this.T && (this.T = new Fn), this.T.u = a, this.N(), this.g("defaultColumnSeparatorStroke", b, a)) } }, defaultColumnSeparatorStrokeWidth: {
            get: function () {
                return null !==
                    this.T ? this.T.F : 1
            }, set: function (a) { var b = this.defaultColumnSeparatorStrokeWidth; b !== a && isFinite(a) && 0 <= a && (null === this.T && (this.T = new Fn), this.T.F = a, this.v(), this.g("defaultColumnSeparatorStrokeWidth", b, a)) }
        }, defaultColumnSeparatorDashArray: {
            get: function () { return null !== this.T ? this.T.m : null }, set: function (a) {
                var b = this.defaultColumnSeparatorDashArray; if (b !== a) {
                    if (null !== a) {
                        for (var c = a.length, d = 0, e = 0; e < c; e++) {
                            var f = a[e]; "number" === typeof f && 0 <= f && isFinite(f) || C("defaultColumnSeparatorDashArray value " +
                                f + " at index " + e + " must be a positive number or zero."); d += f
                        } if (0 === d) { if (null === b) return; a = null }
                    } null === this.T && (this.T = new Fn); this.T.m = a; this.N(); this.g("defaultColumnSeparatorDashArray", b, a)
                }
            }
        }, inRowsCols: { get: function () { return null !== this.T ? this.T.ob : null }, set: function (a) { null === this.T && (this.T = new Fn); this.T.ob = a } }, viewboxStretch: {
            get: function () { return this.to }, set: function (a) {
                var b = this.to; b !== a && (this.to = a, this.v(), this.g("viewboxStretch",
                    b, a))
            }
        }, gridCellSize: { get: function () { return this.en }, set: function (a) { var b = this.en; if (!b.C(a)) { a.w() && 0 !== a.width && 0 !== a.height || C("Invalid Panel.gridCellSize: " + a); this.en = a.J(); var c = this.diagram; null !== c && this === c.grid && oj(c); this.N(); this.g("gridCellSize", b, a) } } }, gridOrigin: {
            get: function () { return this.fn }, set: function (a) {
                var b = this.fn; if (!b.C(a)) {
                    a.w() || C("Invalid Panel.gridOrigin: " + a); this.fn = a.J(); var c = this.diagram; null !== c && this ===
                        c.grid && oj(c); this.N(); this.g("gridOrigin", b, a)
                }
            }
        }, graduatedMin: { get: function () { return null !== this.ra ? this.ra.eq : 0 }, set: function (a) { var b = this.graduatedMin; b !== a && (null === this.ra && (this.ra = new Gn), this.ra.eq = a, this.v(), this.g("graduatedMin", b, a), el(this) && (a = this.part, null !== a && fl(this, a, "graduatedRange"))) } }, graduatedMax: {
            get: function () { return null !== this.ra ? this.ra.F : 100 }, set: function (a) {
                var b = this.graduatedMax; b !== a && (null === this.ra &&
                    (this.ra = new Gn), this.ra.F = a, this.v(), this.g("graduatedMax", b, a), el(this) && (a = this.part, null !== a && fl(this, a, "graduatedRange")))
            }
        }, graduatedRange: { get: function () { return this.graduatedMax - this.graduatedMin } }, graduatedTickUnit: { get: function () { return null !== this.ra ? this.ra.ga : 10 }, set: function (a) { var b = this.graduatedTickUnit; b !== a && 0 < a && (null === this.ra && (this.ra = new Gn), this.ra.ga = a, this.v(), this.g("graduatedTickUnit", b, a)) } }, graduatedTickBase: {
            get: function () { return null !== this.ra ? this.ra.V : 0 }, set: function (a) { var b = this.graduatedTickBase; b !== a && (null === this.ra && (this.ra = new Gn), this.ra.V = a, this.v(), this.g("graduatedTickBase", b, a)) }
        }, graduatedMarks: { get: function () { return null !== this.ra ? this.ra.u : null }, set: function (a) { null !== this.ra ? this.ra.u = a : null !== a && (this.ra = new Gn, this.ra.u = a) } }, graduatedBounds: {
            get: function () { return null !== this.ra ? this.ra.j : null }, set: function (a) {
                null !==
                this.ra ? this.ra.j = a : null !== a && (this.ra = new Gn, this.ra.j = a)
            }
        }, graduatedIntervals: { get: function () { return null !== this.ra ? this.ra.m : null }, set: function (a) { null !== this.ra ? this.ra.m = a : null !== a && (this.ra = new Gn, this.ra.m = a) } }, temporaryTextBlock: { get: function () { return null !== this.ra ? this.ra.Wh : null }, set: function (a) { null !== this.ra ? this.ra.Wh = a : null !== a && (this.ra = new Gn, this.ra.Wh = a) } }, panelLayoutState: {
            get: function () { return this.F },
            set: function (a) { this.F = a }
        }, Oe: { get: function () { return 0 !== (this.o & 8388608) }, set: function (a) { 0 !== (this.o & 8388608) !== a && (this.o ^= 8388608) } }, rowDefs: { get: function () { null === this.T && (this.T = new Fn); return this.T.Mc } }, rowCount: { get: function () { return null === this.T ? 0 : this.rowDefs.length } }, colDefs: { get: function () { null === this.T && (this.T = new Fn); return this.T.Lc } }, columnCount: {
            get: function () { return null === this.T ? 0 : this.colDefs.length }
        }, rowSizing: { get: function () { return null !== this.T ? this.T.pb : Hn }, set: function (a) { var b = this.rowSizing; b !== a && (null === this.T && (this.T = new Fn), this.T.pb = a, this.v(), this.g("rowSizing", b, a)) } }, columnSizing: {
            get: function () { return null !== this.T ? this.T.j : Hn }, set: function (a) {
                var b = this.columnSizing; b !== a && (null === this.T && (this.T = new Fn), this.T.j = a, this.v(), this.g("columnSizing",
                    b, a))
            }
        }, topIndex: { get: function () { return null !== this.T ? this.T.Fb : 0 }, set: function (a) { var b = this.topIndex; b !== a && ((!isFinite(a) || 0 > a) && C("Panel.topIndex must be greater than zero and a real number, not: " + a), null === this.T && (this.T = new Fn), this.T.Fb = a, this.v(), this.g("topIndex", b, a)) } }, leftIndex: {
            get: function () { return null !== this.T ? this.T.hb : 0 }, set: function (a) {
                var b = this.leftIndex; b !== a && ((!isFinite(a) || 0 > a) && C("Panel.leftIndex must be greater than zero and a real number, not: " +
                    a), null === this.T && (this.T = new Fn), this.T.hb = a, this.v(), this.g("leftIndex", b, a))
            }
        }, data: {
            get: function () { return this.ub }, set: function (a) {
                var b = this.ub; if (b !== a) {
                    var c = this instanceof U && !(this instanceof te); jg(this); this.ub = a; var d = this.diagram; null !== d && (c ? (c = d.partManager, this instanceof T ? (null !== b && c.Ye.remove(b), null !== a && c.Ye.add(a, this)) : this instanceof U && (null !== b && c.Ze.remove(b), null !== a && c.Ze.add(a, this))) : (c = this.panel, null !== c && null !== c.ce && (null !== b && c.ce.remove(b),
                        null !== a && c.ce.add(a, this)))); this.g("data", b, a); null !== d && d.undoManager.isUndoingRedoing || null !== a && this.Ma()
                }
            }
        }, itemIndex: { get: function () { return this.vn }, set: function (a) { var b = this.vn; b !== a && (this.vn = a, this.g("itemIndex", b, a)) } }, allBindings: { get: function () { return null !== this.A ? this.A.ob : null }, set: function (a) { this.localBindings !== a && (null === this.A && (this.A = new cl), this.A.ob = a) } }, itemArray: {
            get: function () { return this.Dh },
            set: function (a) { var b = this.Dh; if (b !== a || null !== a && vn(this, a)) { var c = this.diagram; b !== a && (null !== c && null !== b && Lj(c.partManager, this, c), this.Dh = a, null !== c && null !== a && Ij(c.partManager, this)); this.g("itemArray", b, a); null !== c && c.undoManager.isUndoingRedoing || wn(this, a) } }
        }, itemTemplate: {
            get: function () { return null === this.itemTemplateMap ? null : this.itemTemplateMap.I("") }, set: function (a) {
                if (null === this.itemTemplateMap) { if (null === a) return; this.itemTemplateMap = new ob } var b = this.itemTemplateMap.I("");
                b !== a && ((a instanceof U || a.isPanelMain) && C("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: " + a), this.itemTemplateMap.add("", a), this.g("itemTemplate", b, a), a = this.diagram, null !== a && a.undoManager.isUndoingRedoing || this.hv())
            }
        }, itemTemplateMap: {
            get: function () { return null !== this.A ? this.A.Mc : null }, set: function (a) {
                var b = this.itemTemplateMap; if (b !== a) {
                    for (var c = a.iterator; c.next();); bl(this); this.A.Mc = a; this.g("itemTemplateMap", b, a); a = this.diagram; null !== a && a.undoManager.isUndoingRedoing ||
                        this.hv()
                }
            }
        }, itemCategoryProperty: { get: function () { return null !== this.A ? this.A.Lc : "category" }, set: function (a) { var b = this.itemCategoryProperty; b !== a && (bl(this), this.A.Lc = a, this.g("itemCategoryProperty", b, a)) } }, isAtomic: { get: function () { return 0 !== (this.o & 1048576) }, set: function (a) { var b = 0 !== (this.o & 1048576); b !== a && (this.o ^= 1048576, this.g("isAtomic", b, a)) } }, isClipping: {
            get: function () { return 0 !== (this.o & 2097152) },
            set: function (a) { var b = 0 !== (this.o & 2097152); b !== a && (this.o ^= 2097152, this instanceof pf && (this.ib = null), this.v(), this.g("isClipping", b, a)) }
        }, isOpposite: { get: function () { return 0 !== (this.o & 33554432) }, set: function (a) { var b = 0 !== (this.o & 33554432); b !== a && (this.o ^= 33554432, this.v(), this.g("isOpposite", b, a)) } }, isEnabled: {
            get: function () { return 0 !== (this.o & 4194304) }, set: function (a) {
                var b = 0 !== (this.o & 4194304); if (b !== a) {
                    var c = null === this.panel || this.panel.Ff();
                    this.o ^= 4194304; this.g("isEnabled", b, a); b = this.diagram; null !== b && b.undoManager.isUndoingRedoing || c && sn(this, this, a)
                }
            }
        }, alignmentFocusName: { get: function () { return this.We }, set: function (a) { var b = this.We; b !== a && (this.We = a, this.v(), this.g("alignmentFocusName", b, a)) } }
    });
    ma.Object.defineProperties(W, {
        Position: { get: function () { return Tl.I("Position") } }, Horizontal: { get: function () { return Tl.I("Horizontal") } }, Vertical: { get: function () { return Tl.I("Vertical") } }, Spot: { get: function () { return Tl.I("Spot") } }, Auto: { get: function () { return Tl.I("Auto") } }, Table: { get: function () { return Tl.I("Table") } }, Viewbox: {
            get: function () { return Tl.I("Viewbox") }
        }, TableRow: { get: function () { return Tl.I("TableRow") } }, TableColumn: { get: function () { return Tl.I("TableColumn") } }, Link: { get: function () { return Tl.I("Link") } }, Grid: { get: function () { return Tl.I("Grid") } }, Graduated: { get: function () { return Tl.I("Graduated") } }
    }); W.prototype.rebuildItemElements = W.prototype.hv;
    W.prototype.findItemPanelForData = W.prototype.lx; W.prototype.updateTargetBindings = W.prototype.Ma; W.prototype.copyTemplate = W.prototype.My; W.prototype.graduatedValueForPoint = W.prototype.yz; W.prototype.graduatedPointForValue = W.prototype.xz; W.prototype.findColumnForLocalX = W.prototype.Yy; W.prototype.findRowForLocalY = W.prototype.fz; W.prototype.removeColumnDefinition = W.prototype.$z; W.prototype.removeRowDefinition = W.prototype.eA; W.prototype.removeAt = W.prototype.nb; W.prototype.remove = W.prototype.remove;
    W.prototype.insertAt = W.prototype.Eb; W.prototype.elt = W.prototype.L; W.prototype.add = W.prototype.add; W.prototype.findObject = W.prototype.fb; W.prototype.findInVisualTree = W.prototype.Il; W.prototype.walkVisualTreeFrom = W.prototype.nm; W.prototype.findMainElement = W.prototype.sb; var Cn = !1, Dn = null, Tl = new ob; W.className = "Panel"; W.definePanelLayout = En; En("Position", new Em); En("Vertical", new Hm); En("Auto", new Km); En("Link", new Vm);
    function Gn() { this.eq = 0; this.F = 100; this.ga = 10; this.V = 0; this.Wh = this.m = this.j = this.u = null } Gn.prototype.copy = function () { var a = new Gn; a.eq = this.eq; a.F = this.F; a.ga = this.ga; a.V = this.V; a.u = this.u; a.j = this.j; a.m = this.m; a.Wh = this.Wh; return a }; Gn.className = "GradPanelSettings"; function Fn() { this.Mc = []; this.Lc = []; this.j = this.pb = Hn; this.hb = this.Fb = 0; this.Kp = pc; this.Ha = 1; this.V = this.ga = null; this.F = 1; this.ob = this.m = this.u = null }
    Fn.prototype.copy = function (a) { for (var b = new Fn, c = this.Mc, d = c.length, e = b.Mc, f = 0; f < d; f++)if (void 0 !== c[f]) { var g = c[f].copy(); g.ti(a); e[f] = g } c = this.Lc; d = c.length; e = b.Lc; for (f = 0; f < d; f++)void 0 !== c[f] && (g = c[f].copy(), g.ti(a), e[f] = g); b.pb = this.pb; b.j = this.j; b.Fb = this.Fb; b.hb = this.hb; b.Kp = this.Kp.J(); b.Ha = this.Ha; b.ga = this.ga; b.V = this.V; b.F = this.F; b.u = this.u; b.m = this.m; b.ob = this.ob; return b }; Fn.className = "TablePanelSettings";
    function Uj(a) { Qa(this); this.Ie = null; this.nq = !0; this.Ya = 0; this.Ec = NaN; this.hj = 0; this.fj = Infinity; this.Hb = Sc; this.pa = this.kc = this.oa = 0; this.Yd = null; this.ko = In; this.dg = Xk; this.ho = this.$f = null; this.io = NaN; this.Ra = this.rj = null; this.Mm = !1; a && (Object.assign(this, a), void 0 !== a.column ? (this.isRow = !1, this.index = a.column) : void 0 !== a.row && (this.isRow = !0, this.index = a.row)) }
    Uj.prototype.copy = function () { var a = new Uj; a.nq = this.nq; a.Ya = this.Ya; a.Ec = this.Ec; a.hj = this.hj; a.fj = this.fj; a.Hb = this.Hb; a.oa = this.oa; a.kc = this.kc; a.pa = this.pa; a.dg = this.dg; a.ko = this.ko; null === this.$f ? a.$f = null : a.$f = this.$f.J(); a.ho = this.ho; a.io = this.io; a.rj = null; null !== this.rj && (a.separatorDashArray = Ba(this.separatorDashArray)); a.Ra = this.Ra; a.Mm = this.Mm; a.Yd = this.Yd; return a };
    function Ul(a, b) { b.isRow ? a.height = b.height : a.width = b.width; a.minimum = b.minimum; a.maximum = b.maximum; a.alignment = b.alignment; a.stretch = b.stretch; a.sizing = b.sizing; a.$f = null === b.separatorPadding ? null : b.separatorPadding.J(); a.separatorStroke = b.separatorStroke; a.separatorStrokeWidth = b.separatorStrokeWidth; a.rj = null; b.separatorDashArray && (a.rj = Ba(b.separatorDashArray)); a.background = b.background; a.coversSeparators = b.coversSeparators; a.Yd = b.Yd } t = Uj.prototype;
    t.rb = function (a) { a.classType === Uj && (this.sizing = a) }; t.toString = function () { return "RowColumnDefinition " + (this.isRow ? "(Row " : "(Column ") + this.index + ") #" + bb(this) }; t.ti = function (a) { this.Ie = a };
    t.Eo = function (a) { var b = 0, c = this.Ie; this.index !== a && (a = this.separatorStroke, null === a && null !== c && (a = this.isRow ? c.defaultRowSeparatorStroke : c.defaultColumnSeparatorStroke), null !== a && (b = this.separatorStrokeWidth, isNaN(b) && (null !== c ? b = this.isRow ? c.defaultRowSeparatorStrokeWidth : c.defaultColumnSeparatorStrokeWidth : b = 0))); a = this.$f; if (null === a) if (null !== c) a = c.defaultSeparatorPadding; else return b; return b + (this.isRow ? a.top : a.left) };
    t.Rc = function () {
        var a = 0, b = this.Ie, c = 0, d = this.isRow; if (null !== b && b.type === W.Table) for (var e = d ? b.rowDefs.length : b.colDefs.length, f = 0; f < e; f++) { var g = d ? b.rowDefs[f] : b.colDefs[f]; if (void 0 !== g && 0 !== g.oa) { c = g.index; break } } this.index !== c && (c = this.separatorStroke, null === c && null !== b && (c = d ? b.defaultRowSeparatorStroke : b.defaultColumnSeparatorStroke), null !== c && (a = this.separatorStrokeWidth, isNaN(a) && (null !== b ? a = d ? b.defaultRowSeparatorStrokeWidth : b.defaultColumnSeparatorStrokeWidth : a = 0))); d = this.$f; if (null === d) if (null !==
            b) d = b.defaultSeparatorPadding; else return a; return a + (this.isRow ? d.top + d.bottom : d.left + d.right)
    }; t.Lb = function (a, b, c) { var d = this.Ie; if (null !== d && (d.cb(ce, a, this, b, c, void 0, void 0), null !== this.Yd && (b = d.diagram, null !== b && !b.skipsModelSourceBindings && (d = d.dh(), null !== d && (b = d.data, null !== b))))) for (c = this.Yd.iterator; c.next();)c.value.mp(this, b, a, d) }; function Om(a) { if (a.sizing === In) { var b = a.Ie; return a.isRow ? b.rowSizing : b.columnSizing } return a.sizing }
    t.bind = function (a, b, c, d) { var e = null; "string" === typeof a ? e = new xi(a, b, c, d) : e = a; e.rd = this; a = this.panel; null !== a && (b = a.dh(), null !== b && Pl(b) && C("Cannot add a Binding to a RowColumnDefinition that is already frozen: " + e + " on " + a)); null === this.Yd && (this.Yd = new F); this.Yd.add(e); return this };
    ma.Object.defineProperties(Uj.prototype, {
        panel: { get: function () { return this.Ie } }, isRow: { get: function () { return this.nq }, set: function (a) { this.nq = a } }, index: { get: function () { return this.Ya }, set: function (a) { this.Ya = a } }, height: {
            get: function () { return this.Ec }, set: function (a) {
                var b = this.Ec; b !== a && (0 > a && xa(a, ">= 0", Uj, "height"), this.Ec = a, this.actual = this.oa, null !== this.panel && this.panel.v(),
                    this.Lb("height", b, a))
            }
        }, width: { get: function () { return this.Ec }, set: function (a) { var b = this.Ec; b !== a && (0 > a && xa(a, ">= 0", Uj, "width"), this.Ec = a, this.actual = this.oa, null !== this.panel && this.panel.v(), this.Lb("width", b, a)) } }, minimum: { get: function () { return this.hj }, set: function (a) { var b = this.hj; b !== a && ((0 > a || !isFinite(a)) && xa(a, ">= 0", Uj, "minimum"), this.hj = a, this.actual = this.oa, null !== this.panel && this.panel.v(), this.Lb("minimum", b, a)) } }, maximum: {
            get: function () { return this.fj }, set: function (a) { var b = this.fj; b !== a && (0 > a && xa(a, ">= 0", Uj, "maximum"), this.fj = a, this.actual = this.oa, null !== this.panel && this.panel.v(), this.Lb("maximum", b, a)) }
        }, alignment: { get: function () { return this.Hb }, set: function (a) { var b = this.Hb; b.C(a) || (this.Hb = a.J(), null !== this.panel && this.panel.v(), this.Lb("alignment", b, a)) } }, stretch: {
            get: function () { return this.dg }, set: function (a) {
                var b = this.dg; b !== a && (this.dg =
                    a, null !== this.panel && this.panel.v(), this.Lb("stretch", b, a))
            }
        }, separatorPadding: { get: function () { return this.$f }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.$f; null !== a && null !== b && b.C(a) || (null !== a && (a = a.J()), this.$f = a, null !== this.panel && this.panel.v(), this.Lb("separatorPadding", b, a)) } }, separatorStroke: {
            get: function () { return this.ho }, set: function (a) {
                var b = this.ho; b !== a && (null !== a && Zl(a, "RowColumnDefinition.separatorStroke"),
                    a instanceof pl && a.freeze(), this.ho = a, null !== this.panel && this.panel.v(), this.Lb("separatorStroke", b, a))
            }
        }, separatorStrokeWidth: { get: function () { return this.io }, set: function (a) { var b = this.io; b !== a && (this.io = a, null !== this.panel && this.panel.v(), this.Lb("separatorStrokeWidth", b, a)) } }, separatorDashArray: {
            get: function () { return this.rj }, set: function (a) {
                var b = this.rj; if (b !== a) {
                    if (null !== a) {
                        for (var c = a.length, d = 0, e = 0; e < c; e++) {
                            var f = a[e]; "number" ===
                                typeof f && 0 <= f && isFinite(f) || C("separatorDashArray value " + f + " at index " + e + " must be a positive number or zero."); d += f
                        } if (0 === d) { if (null === b) return; a = null }
                    } this.rj = a; null !== this.panel && this.panel.N(); this.Lb("separatorDashArray", b, a)
                }
            }
        }, background: { get: function () { return this.Ra }, set: function (a) { var b = this.Ra; b !== a && (null !== a && Zl(a, "RowColumnDefinition.background"), a instanceof pl && a.freeze(), this.Ra = a, null !== this.panel && this.panel.N(), this.Lb("background", b, a)) } }, coversSeparators: {
            get: function () { return this.Mm }, set: function (a) { var b = this.Mm; b !== a && (this.Mm = a, null !== this.panel && this.panel.N(), this.Lb("coversSeparators", b, a)) }
        }, sizing: { get: function () { return this.ko }, set: function (a) { var b = this.ko; b !== a && (this.ko = a, null !== this.panel && this.panel.v(), this.Lb("sizing", b, a)) } }, actual: {
            get: function () { return this.oa }, set: function (a) {
                this.oa = isNaN(this.Ec) ? Math.max(Math.min(this.fj, a), this.hj) : Math.max(Math.min(this.fj,
                    this.Ec), this.hj)
            }
        }, measured: { get: function () { return this.kc }, set: function (a) { this.kc = a } }, total: { get: function () { return this.oa + this.Rc() } }, position: { get: function () { return this.pa }, set: function (a) { this.pa = a } }
    }); Uj.prototype.computeEffectiveSpacing = Uj.prototype.Rc; Uj.prototype.computeEffectiveSpacingTop = Uj.prototype.Eo; var In = new D(Uj, "Default", 0), Pm = new D(Uj, "None", 1), Hn = new D(Uj, "ProportionalExtra", 2);
    Uj.className = "RowColumnDefinition"; Uj.Default = In; Uj.None = Pm; Uj.ProportionalExtra = Hn; function Bf(a, b) { Y.call(this); this.Jd = this.ta = null; this.Ym = "None"; this.uc = this.Ri = "black"; this.eg = 1; this.Na = this.Sg = null; this.Rn = this.Qn = NaN; this.fa = null; "string" === typeof a ? this.figure = a : a && Object.assign(this, a); b && Object.assign(this, b) } la(Bf, Y);
    Bf.prototype.cloneProtected = function (a) { Y.prototype.cloneProtected.call(this, a); a.ta = this.ta; a.Jd = this.Jd; a.Ym = this.Ym; a.Ri = this.Ri; a.uc = this.uc; a.eg = this.eg; null !== this.Sg && (a.Sg = Ba(this.Sg)); null !== this.Na && (a.Na = this.Na.jc ? this.Na : this.Na.copy()); a.Qn = this.Qn; a.Rn = this.Rn; null !== this.fa && (a.fa = this.fa.copy()) }; function Jn(a) { null === a.Na ? a.Na = new Kn : a.Na.jc && (a.Na = a.Na.copy()) } t = Bf.prototype; t.oh = function () { Y.prototype.oh.call(this); null !== this.Na && (this.Na.jc = !0) };
    t.rb = function (a) { a === kg || a === mg || a === Yk || a === Xk ? this.geometryStretch = a : Y.prototype.rb.call(this, a) }; t.toString = function () { return "Shape(" + ("None" !== this.figure ? this.figure : "None" !== this.toArrow ? this.toArrow : this.fromArrow) + ")#" + bb(this) };
    function Ln(a, b, c, d) {
        var e = c.length; if (!(4 > e)) {
            var f = d.measuredBounds, g = Math.max(1, f.width); f = f.height; for (var h = c[0], k = c[1], l, m, n, p, r, q, u = 0, w = Ea(), v = 2; v < e; v += 2)l = c[v], m = c[v + 1], n = l - h, h = m - k, 0 === n && (n = .001), p = h / n, r = Math.atan2(h, n), q = Math.sqrt(n * n + h * h), w.push([n, r, p, q]), u += q, h = l, k = m; h = c[0]; k = c[1]; n = d.measuredBounds.width; d instanceof Bf && (n -= d.strokeWidth); 1 > n && (n = 1); e = c = n; l = g / 2; m = 0 === l ? !1 : !0; v = 0; q = w[v]; n = q[0]; r = q[1]; p = q[2]; q = q[3]; for (var z = 0; .1 <= u;) {
                0 === z && (m ? (e = c, e -= l, u -= l, m = !1) : e = c, 0 === e && (e = 1));
                if (e > u) { Ga(w); return } e > q ? (z = e - q, e = q) : z = 0; var y = Math.sqrt(e * e / (1 + p * p)); 0 > n && (y = -y); h += y; k += p * y; a.translate(h, k); a.rotate(r); a.translate(-(g / 2), -(f / 2)); 0 === z && d.bh(a, b); a.translate(g / 2, f / 2); a.rotate(-r); a.translate(-h, -k); u -= e; q -= e; if (0 !== z) { v++; if (v === w.length) { Ga(w); return } q = w[v]; n = q[0]; r = q[1]; p = q[2]; q = q[3]; e = z }
            } Ga(w)
        }
    }
    t.bh = function (a, b) {
        var c = this.uc, d = this.Ri; if (null !== c || null !== d) {
            var e = this.ta; if (null !== e) {
                a.vc(); var f = this.actualBounds, g = this.naturalBounds; null !== d && hi(this, a, d, !0, !1, g, f); var h = this.part, k = this.eg; null === c || 0 !== k || null === h || !this.isPanelMain && h.sb() !== this || (k = h.type === W.Link && h instanceof te && "Selection" === h.category && h.adornedObject instanceof Bf && h.adornedPart.sb() === h.adornedObject ? h.adornedObject.strokeWidth : 0); 0 === k && (c = null); null !== c && 0 !== k && (hi(this, a, c, !1, !1, g, f), a.lineWidth = k, a.lineJoin =
                    this.strokeJoin, a.lineCap = this.strokeCap, a.miterLimit = this.strokeMiterLimit); f = !1; h && b.Df("drawShadows") && (f = h.isShadowed); !1 === a.Dl && (f = !1); h = !0; null === c || null !== d && "transparent" !== d || (h = !1); g = !1; k = this.strokeDashArray; null !== k && (g = !0, a.bs(k, this.strokeDashOffset)); if (e.type === P.j) a.beginPath(), a.moveTo(e.startX, e.startY), a.lineTo(e.endX, e.endY), null !== c && a.ek(), a.Ne(); else if (e.type === P.u) {
                        var l = e.startX; k = e.startY; var m = e.endX, n = e.endY; e = Math.min(l, m); var p = Math.min(k, n); l = Math.abs(m - l); k = Math.abs(n -
                            k); a.beginPath(); a.rect(e, p, l, k); null !== d && a.jg(d); null !== c && (h && f && tl(a), a.ek(), h && f && sl(a)); a.Ne()
                    } else if (e.type === P.F) l = e.startX, k = e.startY, m = e.endX, n = e.endY, e = Math.abs(m - l) / 2, p = Math.abs(n - k) / 2, l = Math.min(l, m) + e, k = Math.min(k, n) + p, a.beginPath(), a.moveTo(l, k - p), a.bezierCurveTo(l + K.sg * e, k - p, l + e, k - K.sg * p, l + e, k), a.bezierCurveTo(l + e, k + K.sg * p, l + K.sg * e, k + p, l, k + p), a.bezierCurveTo(l - K.sg * e, k + p, l - e, k + K.sg * p, l - e, k), a.bezierCurveTo(l - e, k - K.sg * p, l - K.sg * e, k - p, l, k - p), a.closePath(), null !== d && a.jg(d), null !== c &&
                        (h && f && tl(a), a.ek(), h && f && sl(a)), a.Ne(); else if (e.type === P.m) for (e = e.figures, k = e.length, p = 0; p < k; p++) {
                            l = e.s[p]; a.beginPath(); a.moveTo(l.startX, l.startY); m = l.segments.s; n = m.length; for (var r = null, q = 0; q < n; q++) {
                                var u = m[q]; switch (u.type) {
                                    case Dd: a.moveTo(u.endX, u.endY); break; case pd: a.lineTo(u.endX, u.endY); break; case Ed: a.bezierCurveTo(u.point1X, u.point1Y, u.point2X, u.point2Y, u.endX, u.endY); break; case Kd: a.quadraticCurveTo(u.point1X, u.point1Y, u.endX, u.endY); break; case Ld: if (u.radiusX === u.radiusY) {
                                        var w =
                                            Math.PI / 180; a.arc(u.point1X, u.point1Y, u.radiusX, u.startAngle * w, (u.startAngle + u.sweepAngle) * w, 0 > u.sweepAngle, null !== r ? r.endX : l.startX, null !== r ? r.endY : l.startY)
                                    } else if (r = Nd(u, l), w = r.length, 0 === w) a.lineTo(u.centerX, u.centerY); else for (var v = 0; v < w; v++) { var z = r[v]; 0 === v && a.lineTo(z[0], z[1]); a.bezierCurveTo(z[2], z[3], z[4], z[5], z[6], z[7]) } break; case Md: v = w = 0; if (null !== r && r.type === Ld) { r = Nd(r, l); z = r.length; if (0 === z) { a.lineTo(u.endX, u.endY); break } r = r[z - 1] || null; null !== r && (w = r[6], v = r[7]) } else w = null !== r ? r.endX :
                                        l.startX, v = null !== r ? r.endY : l.startY; r = Od(u, l, w, v); w = r.length; if (0 === w) { a.lineTo(u.endX, u.endY); break } for (v = 0; v < w; v++)z = r[v], a.bezierCurveTo(z[2], z[3], z[4], z[5], z[6], z[7]); break; default: C("Segment not of valid type: " + u.type)
                                }u.isClosed && a.closePath(); r = u
                            } m = l.isFilled; f ? l.isShadowed ? (!0 === m && "transparent" !== d && null !== d && a.jg(d, l.isEvenOdd), null !== c && (m && h && tl(a), a.ek(), m && h && sl(a))) : (tl(a), m && "transparent" !== d && null !== d && a.jg(d, l.isEvenOdd), null !== c && a.ek(), sl(a)) : (m && null !== d && a.jg(d, l.isEvenOdd),
                                null !== c && a.ek()); a.Ne(p)
                        } g && a.Yr(); if (null !== this.pathPattern) {
                            c = this.pathPattern; c.measure(Infinity, Infinity); d = c.measuredBounds; c.arrange(0, 0, d.width, d.height); f = this.geometry; a.save(); a.beginPath(); d = Ea(); if (f.type === P.j) d.push(f.startX), d.push(f.startY), d.push(f.endX), d.push(f.endY), Ln(a, b, d, c); else if (f.type === P.m) for (f = f.figures.iterator; f.next();) {
                                h = f.value; d.length = 0; d.push(h.startX); d.push(h.startY); g = h.startX; e = h.startY; k = g; p = e; l = h.segments.s; m = l.length; for (n = 0; n < m; n++) {
                                    q = l[n]; switch (q.type) {
                                        case Dd: Ln(a,
                                            b, d, c); d.length = 0; d.push(q.endX); d.push(q.endY); g = q.endX; e = q.endY; k = g; p = e; break; case pd: d.push(q.endX); d.push(q.endY); g = q.endX; e = q.endY; break; case Ed: K.Le(g, e, q.point1X, q.point1Y, q.point2X, q.point2Y, q.endX, q.endY, .5, d); g = q.endX; e = q.endY; break; case Kd: K.Yo(g, e, q.point1X, q.point1Y, q.endX, q.endY, .5, d); g = q.endX; e = q.endY; break; case Ld: u = Nd(q, h); r = u.length; if (0 === r) { d.push(q.centerX); d.push(q.centerY); g = q.centerX; e = q.centerY; break } for (w = 0; w < r; w++)v = u[w], K.Le(g, e, v[2], v[3], v[4], v[5], v[6], v[7], .5, d), g = v[6],
                                                e = v[7]; break; case Md: u = Od(q, h, g, e); r = u.length; if (0 === r) { d.push(q.endX); d.push(q.endY); g = q.endX; e = q.endY; break } for (w = 0; w < r; w++)v = u[w], K.Le(g, e, v[2], v[3], v[4], v[5], v[6], v[7], .5, d), g = v[6], e = v[7]; break; default: C("Segment not of valid type: " + q.type)
                                    }q.isClosed && (d.push(k), d.push(p), Ln(a, b, d, c))
                                } Ln(a, b, d, c)
                            } else if (f.type === P.u) d.push(f.startX), d.push(f.startY), d.push(f.endX), d.push(f.startY), d.push(f.endX), d.push(f.endY), d.push(f.startX), d.push(f.endY), d.push(f.startX), d.push(f.startY), Ln(a, b, d, c); else if (f.type ===
                                P.F) { e = new Wd; e.startX = f.endX; e.startY = (f.startY + f.endY) / 2; g = new Xd(Ld); g.startAngle = 0; g.sweepAngle = 360; g.centerX = (f.startX + f.endX) / 2; g.centerY = (f.startY + f.endY) / 2; g.radiusX = Math.abs(f.startX - f.endX) / 2; g.radiusY = Math.abs(f.startY - f.endY) / 2; e.add(g); f = Nd(g, e); h = f.length; if (0 === h) d.push(g.centerX), d.push(g.centerY); else for (g = e.startX, e = e.startY, k = 0; k < h; k++)p = f[k], K.Le(g, e, p[2], p[3], p[4], p[5], p[6], p[7], .5, d), g = p[6], e = p[7]; Ln(a, b, d, c) } Ga(d); a.restore(); a.Qc(!1)
                        }
            }
        }
    };
    t.fk = function (a, b, c) { if (!Y.prototype.fk.call(this, a, b, c) || null === this.uc && null === this.Ri || null === this.ta || null !== this.pathPattern) return !1; a.Xr = this.svg; this.bh(a, b); a.Xr = null; return !0 }; t.ik = function (a) { return null !== this.geometry && 1 < this.geometry.figures.length || null !== this.pathPattern ? !0 : Y.prototype.ik.call(this, a) }; t.wv = function (a, b) { if ("g" !== a.nodeName) a.setAttributeNS(null, "filter", b); else { a = a.getElementsByTagName("path"); for (var c = 0; c < a.length; c++)a[c].setAttributeNS(null, "filter", b) } };
    t.ja = function (a, b) { void 0 === b && (b = new I); if (a instanceof O) { a.Jc() && C("getDocumentPoint Spot must be a real, specific Spot, not: " + a.toString()); var c = this.naturalBounds, d = this.strokeWidth; b.h(a.x * (c.width + d) - d / 2 + c.x + a.offsetX, a.y * (c.height + d) - d / 2 + c.y + a.offsetY) } else b.set(a); this.ke.Ga(b); return b };
    t.ji = function (a) { void 0 === a && (a = new N); var b = this.naturalBounds, c = this.ke; b = N.allocAt(b.x, b.y, b.width, b.height); var d = this.strokeWidth; b.yc(d / 2, d / 2); d = I.allocAt(b.x, b.y).transform(c); a.h(d.x, d.y, 0, 0); d.h(b.right, b.y).transform(c); Yb(a, d.x, d.y, 0, 0); d.h(b.right, b.bottom).transform(c); Yb(a, d.x, d.y, 0, 0); d.h(b.x, b.bottom).transform(c); Yb(a, d.x, d.y, 0, 0); N.free(b); I.free(d); return a };
    t.ah = function (a, b) {
        var c = this.geometry; if (null === c || null === this.fill && null === this.stroke) return !1; var d = c.bounds, e = this.strokeWidth / 2; c.type !== P.j || b || (e += 2); var f = N.alloc(); f.assign(d); f.yc(e + 2, e + 2); if (!f.ca(a)) return N.free(f), !1; d = e + 1E-4; if (c.type === P.j) { if (null === this.stroke) return !1; d = (c.endX - c.startX) * (a.x - c.startX) + (c.endY - c.startY) * (a.y - c.startY); if (0 > (c.startX - c.endX) * (a.x - c.endX) + (c.startY - c.endY) * (a.y - c.endY) || 0 > d) return !1; N.free(f); return K.Ub(c.startX, c.startY, c.endX, c.endY, e, a.x, a.y) } if (c.type ===
            P.u) { b = c.startX; var g = c.startY, h = c.endX; c = c.endY; f.x = Math.min(b, h); f.y = Math.min(g, c); f.width = Math.abs(h - b); f.height = Math.abs(c - g); if (null === this.fill) { f.yc(-d, -d); if (f.ca(a)) return N.free(f), !1; f.yc(d, d) } null !== this.stroke && f.yc(e, e); a = f.ca(a); N.free(f); return a } if (c.type === P.F) {
                g = c.startX; e = c.startY; h = c.endX; var k = c.endY; c = Math.min(g, h); b = Math.min(e, k); g = Math.abs(h - g) / 2; e = Math.abs(k - e) / 2; c = a.x - (c + g); b = a.y - (b + e); if (null === this.fill) {
                    g -= d; e -= d; if (0 >= g || 0 >= e || 1 >= c * c / (g * g) + b * b / (e * e)) return N.free(f),
                        !1; g += d; e += d
                } null !== this.stroke && (g += d, e += d); N.free(f); return 0 >= g || 0 >= e ? !1 : 1 >= c * c / (g * g) + b * b / (e * e)
            } if (c.type === P.m) return N.free(f), null === this.fill ? Rd(c, a.x, a.y, e) : Pd(c, a, e, 1 < this.strokeWidth, b); C("Unknown Geometry type: " + c.type)
    };
    t.Sl = function (a, b, c, d) {
        var e = this.desiredSize, f = this.eg; a = Math.max(a, 0); b = Math.max(b, 0); if (null !== this.Jd) var g = this.geometry.bounds; else { var h = this.figure, k = Tn[h]; if (void 0 === k) { var l = K.Ve[h]; "string" === typeof l && (l = K.Ve[l]); "function" === typeof l ? (k = l(null, 100, 100), Tn[h] = k) : C("Unsupported Figure: " + h) } g = k.bounds } h = g.width; k = g.height; l = g.width; var m = g.height; switch (il(this, !0)) {
            case kg: d = c = 0; break; case nd: l = Math.max(a - f, 0); m = Math.max(b - f, 0); break; case Vk: l = Math.max(a - f, 0); d = 0; break; case Wk: c = 0, m =
                Math.max(b - f, 0)
        }isFinite(e.width) && (l = e.width); isFinite(e.height) && (m = e.height); g = this.maxSize; var n = this.minSize; c = Math.max(c - f, n.width); d = Math.max(d - f, n.height); l = Math.min(g.width, l); m = Math.min(g.height, m); l = isFinite(l) ? Math.max(c, l) : Math.max(h, c); m = isFinite(m) ? Math.max(d, m) : Math.max(k, d); c = lg(this); switch (c) { case kg: break; case nd: h = l; k = m; break; case mg: c = Math.min(l / h, m / k); isFinite(c) || (c = 1); h *= c; k *= c; break; default: C(c + " is not a valid geometryStretch.") }null !== this.Jd ? (0 === h && (h = .001), 0 === k && (k =
            .001), c = null !== this.Jd ? this.Jd : this.ta, g = c.bounds, d = h / g.width, g = k / g.height, isFinite(d) || (d = 1), isFinite(g) || (g = 1), this.ta = 1 === d && 1 === g ? c : c.copy().scale(d, g)) : null !== this.ta && K.$(this.ta.Nk, a - f) && K.$(this.ta.Mk, b - f) || (this.ta = Bf.makeGeometry(this, h, k)); g = this.ta.bounds; Infinity === a || Infinity === b ? this.Ce(g.x - f / 2, g.y - f / 2, 0 === a && 0 === h ? 0 : g.width + f, 0 === b && 0 === k ? 0 : g.height + f) : this.Ce(-(f / 2), -(f / 2), l + f, m + f); e.w() ? this.ac.h(0, 0, e.width, e.height) : this.ac.assign(g)
    };
    function lg(a) { var b = a.geometryStretch; return null !== a.Jd ? b === Xk ? nd : b : b === Xk ? Tn[a.figure].defaultStretch : b } t.Zg = function (a, b, c, d) { ll(this, a, b, c, d) }; t.ks = function (a, b, c) { return this.Qj(a.x, a.y, b.x, b.y, c) };
    t.Qj = function (a, b, c, d, e) {
        var f = this.transform, g = 1 / (f.m11 * f.m22 - f.m12 * f.m21), h = f.m22 * g, k = -f.m12 * g, l = -f.m21 * g, m = f.m11 * g, n = g * (f.m21 * f.dy - f.m22 * f.dx), p = g * (f.m12 * f.dx - f.m11 * f.dy); f = a * h + b * l + n; g = a * k + b * m + p; h = c * h + d * l + n; k = c * k + d * m + p; n = this.eg / 2; l = this.ta; null === l && (this.measure(Infinity, Infinity), l = this.ta); var r = l.bounds; m = !1; if (l.type === P.j) if (1.5 >= this.strokeWidth) m = K.Re(l.startX, l.startY, l.endX, l.endY, f, g, h, k, e); else {
            l.startX === l.endX ? (d = n, m = 0) : (b = (l.endY - l.startY) / (l.endX - l.startX), m = n / Math.sqrt(1 + b * b),
                d = m * b); b = Ea(); a = new I; K.Re(l.startX + d, l.startY + m, l.endX + d, l.endY + m, f, g, h, k, a) && b.push(a); a = new I; K.Re(l.startX - d, l.startY - m, l.endX - d, l.endY - m, f, g, h, k, a) && b.push(a); a = new I; K.Re(l.startX + d, l.startY + m, l.startX - d, l.startY - m, f, g, h, k, a) && b.push(a); a = new I; K.Re(l.endX + d, l.endY + m, l.endX - d, l.endY - m, f, g, h, k, a) && b.push(a); h = b.length; if (0 === h) return Ga(b), !1; m = !0; k = Infinity; for (d = 0; d < h; d++)a = b[d], c = (a.x - f) * (a.x - f) + (a.y - g) * (a.y - g), c < k && (k = c, e.x = a.x, e.y = a.y); Ga(b)
        } else if (l.type === P.u) m = K.Cf(r.x - n, r.y - n, r.x + r.width +
            n, r.y + r.height + n, f, g, h, k, e); else if (l.type === P.F) {
                b = N.allocAt(r.x, r.y, r.width, r.height).yc(n, n); a: if (0 === b.width) m = K.Re(b.x, b.y, b.x, b.y + b.height, f, g, h, k, e); else if (0 === b.height) m = K.Re(b.x, b.y, b.x + b.width, b.y, f, g, h, k, e); else {
                    a = b.width / 2; l = b.height / 2; d = b.x + a; m = b.y + l; c = 9999; f !== h && (c = (g - k) / (f - h)); if (9999 > Math.abs(c)) {
                        k = g - m - c * (f - d); if (0 > a * a * c * c + l * l - k * k) { e.x = NaN; e.y = NaN; m = !1; break a } n = Math.sqrt(a * a * c * c + l * l - k * k); h = (-(a * a * c * k) + a * l * n) / (l * l + a * a * c * c) + d; a = (-(a * a * c * k) - a * l * n) / (l * l + a * a * c * c) + d; l = c * (h - d) + k + m; k = c *
                            (a - d) + k + m; Math.abs((f - h) * (f - h)) + Math.abs((g - l) * (g - l)) < Math.abs((f - a) * (f - a)) + Math.abs((g - k) * (g - k)) ? (e.x = h, e.y = l) : (e.x = a, e.y = k)
                    } else { h = l * l; k = f - d; h -= h / (a * a) * k * k; if (0 > h) { e.x = NaN; e.y = NaN; m = !1; break a } k = Math.sqrt(h); h = m + k; k = m - k; Math.abs(h - g) < Math.abs(k - g) ? (e.x = f, e.y = h) : (e.x = f, e.y = k) } m = !0
                } N.free(b)
            } else if (l.type === P.m) {
                p = I.alloc(); var q = h - f; var u = k - g, w = q * q + u * u, v = f, z = g; if (0 < w && r.contains(v, z) && (p.x = v, p.y = z, this.ca(p))) for (0 !== q && .5 > Math.abs(q) ? (u *= .5 / q, q = .5) : 0 !== u && .5 > Math.abs(u) && (q *= .5 / u, u = .5), v -= q, z -=
                    u; r.contains(v, z);)v -= q, z -= u; e.x = h; e.y = k; for (u = 0; u < l.figures.count; u++) {
                        var y = l.figures.s[u], A = y.isFilled ? v : f, B = y.isFilled ? z : g, E = y.segments; r = y.startX; q = y.startY; for (var M = r, R = q, S = 0; S < E.count; S++) {
                            var J = E.s[S], G = J.type; var ca = J.endX; var X = J.endY; var na = !1; switch (G) {
                                case Dd: M = ca; R = X; break; case pd: na = Un(r, q, ca, X, A, B, h, k, p); break; case Ed: na = K.Ao(r, q, J.point1X, J.point1Y, J.point2X, J.point2Y, ca, X, A, B, h, k, .6, p); break; case Kd: na = K.Ao(r, q, r + 2 / 3 * (J.point1X - r), q + 2 / 3 * (J.point1Y - q), ca + 2 / 3 * (J.point1X - ca), X + 2 / 3 *
                                    (J.point1Y - X), ca, X, A, B, h, k, .6, p); break; case Ld: case Md: G = J.type === Ld ? Nd(J, y) : Od(J, y, r, q); var wa = G.length; if (0 === wa) { na = Un(r, q, J.type === Ld ? J.centerX : J.endX, J.type === Ld ? J.centerY : J.endY, A, B, h, k, p); break } X = null; for (ca = 0; ca < wa; ca++) { X = G[ca]; if (0 === ca && Un(r, q, X[0], X[1], A, B, h, k, p)) { var db = Vn(A, B, p, w, e); db < w && (w = db, m = !0) } K.Ao(X[0], X[1], X[2], X[3], X[4], X[5], X[6], X[7], A, B, h, k, .6, p) && (db = Vn(A, B, p, w, e), db < w && (w = db, m = !0)) } ca = X[6]; X = X[7]; break; default: C("Unknown Segment type: " + G)
                            }r = ca; q = X; na && (na = Vn(A, B, p, w, e),
                                na < w && (w = na, m = !0)); J.isClosed && (ca = M, X = R, Un(r, q, ca, X, A, B, h, k, p) && (J = Vn(A, B, p, w, e), J < w && (w = J, m = !0)))
                        }
                    } f = c - a; g = d - b; h = Math.sqrt(f * f + g * g); 0 !== h && (f /= h, g /= h); e.x -= f * n; e.y -= g * n; I.free(p)
            } else C("Unknown Geometry type: " + l.type); if (!m) return !1; this.transform.Ga(e); return !0
    }; function Vn(a, b, c, d, e) { a = c.x - a; b = c.y - b; b = a * a + b * b; return b < d ? (e.x = c.x, e.y = c.y, b) : d }
    function Un(a, b, c, d, e, f, g, h, k) { if (K.$(a, c) && K.$(b, d) || K.$(e, g) && K.$(f, h)) return !1; var l = !1, m = (e - g) * (b - d) - (f - h) * (a - c); if (0 === m) return !1; k.x = ((e * h - f * g) * (a - c) - (e - g) * (a * d - b * c)) / m; k.y = ((e * h - f * g) * (b - d) - (f - h) * (a * d - b * c)) / m; (a > c ? a - c : c - a) < (b > d ? b - d : d - b) ? (a = b < d ? b : d, b = b < d ? d : b, (k.y > a || K.$(k.y, a)) && (k.y < b || K.$(k.y, b)) && (l = !0)) : (b = a < c ? a : c, a = a < c ? c : a, (k.x > b || K.$(k.x, b)) && (k.x < a || K.$(k.x, a)) && (l = !0)); return l }
    t.$g = function (a, b) { if (void 0 === b) return a.Me(this.actualBounds); var c = this.ta; null === c && (this.measure(Infinity, Infinity), c = this.ta); c = c.bounds; var d = this.strokeWidth / 2, e = !1, f = I.alloc(); f.h(c.x - d, c.y - d); a.ca(b.Ga(f)) && (f.h(c.x - d, c.bottom + d), a.ca(b.Ga(f)) && (f.h(c.right + d, c.bottom + d), a.ca(b.Ga(f)) && (f.h(c.right + d, c.y - d), a.ca(b.Ga(f)) && (e = !0)))); I.free(f); return e };
    t.Tc = function (a, b) {
        if (this.$g(a, b) || void 0 === b && (b = this.transform, a.Me(this.actualBounds))) return !0; var c = ld.alloc(); c.set(b); c.Ol(); var d = a.left, e = a.right, f = a.top; a = a.bottom; var g = I.alloc(); g.h(d, f); c.Ga(g); if (this.ah(g, !0)) return I.free(g), !0; g.h(e, f); c.Ga(g); if (this.ah(g, !0)) return I.free(g), !0; g.h(d, a); c.Ga(g); if (this.ah(g, !0)) return I.free(g), !0; g.h(e, a); c.Ga(g); if (this.ah(g, !0)) return I.free(g), !0; var h = I.alloc(), k = I.alloc(); c.set(b); c.Yu(this.transform); c.Ol(); h.x = e; h.y = f; h.transform(c); g.x =
            d; g.y = f; g.transform(c); b = !1; Wn(this, g, h, k) ? b = !0 : (g.x = e, g.y = a, g.transform(c), Wn(this, g, h, k) ? b = !0 : (h.x = d, h.y = a, h.transform(c), Wn(this, g, h, k) ? b = !0 : (g.x = d, g.y = f, g.transform(c), Wn(this, g, h, k) && (b = !0)))); I.free(g); ld.free(c); I.free(h); I.free(k); return b
    }; function Wn(a, b, c, d) { if (!a.ks(b, c, d)) return !1; a = b.x; b = b.y; var e = c.x, f = c.y; c = d.x; d = d.y; if (a === e) return b < f ? (a = b, b = f) : a = f, d >= a && d <= b; a < e ? (d = a, a = e) : d = e; return c >= d && c <= a }
    t.mx = function (a, b, c) {
        function d(a, b) { for (var c = a.length, d = 0; d < c; d += 2)if (b.td(a[d], a[d + 1]) > e) return !0; return !1 } if (c && null !== this.fill && this.ah(a, !0)) return !0; var e = a.yf(b), f = e; 1.5 < this.strokeWidth && (e = this.strokeWidth / 2 + Math.sqrt(e), e *= e); b = this.ta; if (null === b && (this.measure(Infinity, Infinity), b = this.ta, null === b)) return !1; if (!c) { var g = b.bounds, h = g.x, k = g.y, l = g.x + g.width; g = g.y + g.height; if (zb(a.x, a.y, h, k) <= e && zb(a.x, a.y, l, k) <= e && zb(a.x, a.y, h, g) <= e && zb(a.x, a.y, l, g) <= e) return !0 } h = b.startX; k = b.startY; l =
            b.endX; g = b.endY; if (b.type === P.j) { if (c = (h - l) * (a.x - l) + (k - g) * (a.y - g), yb(a.x, a.y, h, k, l, g) <= (0 <= (l - h) * (a.x - h) + (g - k) * (a.y - k) && 0 <= c ? e : f)) return !0 } else {
                if (b.type === P.u) return b = !1, c && (b = yb(a.x, a.y, h, k, h, g) <= e || yb(a.x, a.y, h, k, l, k) <= e || yb(a.x, a.y, l, k, l, g) <= e || yb(a.x, a.y, h, g, l, g) <= e), b; if (b.type === P.F) {
                    b = a.x - (h + l) / 2; f = a.y - (k + g) / 2; var m = Math.abs(l - h) / 2, n = Math.abs(g - k) / 2; if (0 === m || 0 === n) return yb(a.x, a.y, h, k, l, g) <= e ? !0 : !1; if (c) { if (a = K.Sy(m, n, b, f), a * a <= e) return !0 } else return zb(b, f, -m, 0) >= e || zb(b, f, 0, -n) >= e || zb(b,
                        f, 0, n) >= e || zb(b, f, m, 0) >= e ? !1 : !0
                } else if (b.type === P.m) {
                    l = b.bounds; f = l.x; h = l.y; k = l.x + l.width; l = l.y + l.height; if (a.x > k && a.x < f && a.y > l && a.y < h && yb(a.x, a.y, f, h, f, l) > e && yb(a.x, a.y, f, h, k, h) > e && yb(a.x, a.y, k, l, f, l) > e && yb(a.x, a.y, k, l, k, h) > e) return !1; f = Math.sqrt(e); if (c) { if (null === this.fill ? Rd(b, a.x, a.y, f) : Pd(b, a, f, !0, !1)) return !0 } else {
                        c = b.figures; for (b = 0; b < c.count; b++) {
                            f = c.s[b]; g = f.startX; m = f.startY; if (a.td(g, m) > e) return !1; h = f.segments.s; k = h.length; for (l = 0; l < k; l++)switch (n = h[l], n.type) {
                                case Dd: case pd: g = n.endX;
                                    m = n.endY; if (a.td(g, m) > e) return !1; break; case Ed: var p = Ea(); K.Le(g, m, n.point1X, n.point1Y, n.point2X, n.point2Y, n.endX, n.endY, .8, p); g = d(p, a); Ga(p); if (g) return !1; g = n.endX; m = n.endY; if (a.td(g, m) > e) return !1; break; case Kd: p = Ea(); K.Yo(g, m, n.point1X, n.point1Y, n.endX, n.endY, .8, p); g = d(p, a); Ga(p); if (g) return !1; g = n.endX; m = n.endY; if (a.td(g, m) > e) return !1; break; case Ld: case Md: p = n.type === Ld ? Nd(n, f) : Od(n, f, g, m); var r = p.length; if (0 === r) {
                                        g = n.type === Ld ? n.centerX : n.endX; m = n.type === Ld ? n.centerY : n.endY; if (a.td(g, m) > e) return !1;
                                        break
                                    } n = null; for (var q = Ea(), u = 0; u < r; u++)if (n = p[u], q.length = 0, K.Le(n[0], n[1], n[2], n[3], n[4], n[5], n[6], n[7], .8, q), d(q, a)) return Ga(q), !1; Ga(q); null !== n && (g = n[6], m = n[7]); break; default: C("Unknown Segment type: " + n.type)
                            }
                        } return !0
                    }
                }
            } return !1
    }; t.nc = function () { this.ta = null }; t.cq = function () { return this.eg };
    function Xn(a) { var b = a.diagram; null !== b && b.undoManager.isUndoingRedoing || (a.segmentOrientation = en, "None" !== a.toArrow ? (a.segmentIndex = -1, a.alignmentFocus = bd) : "None" !== a.fromArrow && (a.segmentIndex = 0, a.alignmentFocus = new O(1 - bd.x, bd.y))) }
    Bf.makeGeometry = function (a, b, c) { if ("None" !== a.toArrow) var d = Yn[a.toArrow]; else "None" !== a.fromArrow ? d = Yn[a.fromArrow] : (d = K.Ve[a.figure], "string" === typeof d && (d = K.Ve[d]), void 0 === d && C("Unknown Shape.figure: " + a.figure), d = d(a, b, c), d.Nk = b, d.Mk = c); if (null === d) { var e = K.Ve.Rectangle; "function" === typeof e && (d = e(a, b, c)) } return d };
    function Zn(a) { var b = Yn[a]; if (void 0 === b) { var c = a.toLowerCase(); if ("none" === c) return "None"; b = Yn[c]; if (void 0 === b) { var d = null, e; for (e in K.rm) if (e.toLowerCase() === c) { d = e; break } if (null !== d) return a = P.parse(K.rm[d], !1), Yn[d] = a, c !== d && (Yn[c] = d), d } } return "string" === typeof b ? b : b instanceof P ? a : null }
    ma.Object.defineProperties(Bf.prototype, {
        geometry: { get: function () { return null !== this.ta ? this.ta : this.Jd }, set: function (a) { var b = this.ta, c = this.Jd; if (b !== a) { null !== a ? this.Jd = this.ta = a.freeze() : this.Jd = this.ta = null; var d = this.part; null !== d && (d.Xf = NaN); this.v(); this.g("geometry", b || c, a); el(this) && (a = this.part, null !== a && fl(this, a, "geometryString")) } } }, geometryString: {
            get: function () { return null === this.geometry ? "" : this.geometry.toString() }, set: function (a) {
                a =
                P.parse(a); var b = a.normalize(); this.geometry = a; this.position = a = I.allocAt(-b.x, -b.y); I.free(a)
            }
        }, isGeometryPositioned: { get: function () { return 0 !== (this.o & 1048576) }, set: function (a) { var b = 0 !== (this.o & 1048576); b !== a && (this.o ^= 1048576, this.v(), this.g("isGeometryPositioned", b, a)) } }, fill: {
            get: function () { return this.Ri }, set: function (a) {
                var b = this.Ri; b !== a && (null !== a && Zl(a, "Shape.fill"), a instanceof pl && a.freeze(), this.Ri = a, this.N(), this.g("fill",
                    b, a))
            }
        }, stroke: { get: function () { return this.uc }, set: function (a) { var b = this.uc; b !== a && (null !== a && Zl(a, "Shape.stroke"), a instanceof pl && a.freeze(), this.uc = a, this.N(), this.g("stroke", b, a)) } }, strokeWidth: { get: function () { return this.eg }, set: function (a) { var b = this.eg; if (b !== a) if (0 <= a) { this.eg = a; this.v(); var c = this.part; null !== c && (c.Xf = NaN); this.g("strokeWidth", b, a) } else xa(a, "value >= 0", Bf, "strokeWidth:value") } }, strokeCap: {
            get: function () { return null !== this.Na ? this.Na.zr : "butt" }, set: function (a) { var b = this.strokeCap; b !== a && ("string" !== typeof a || "butt" !== a && "round" !== a && "square" !== a ? xa(a, '"butt", "round", or "square"', Bf, "strokeCap") : (Jn(this), this.Na.zr = a, this.N(), this.g("strokeCap", b, a))) }
        }, strokeJoin: {
            get: function () { return null !== this.Na ? this.Na.Ar : "miter" }, set: function (a) {
                var b = this.strokeJoin; b !== a && ("string" !== typeof a || "miter" !== a && "bevel" !== a && "round" !== a ? xa(a, '"miter", "bevel", or "round"',
                    Bf, "strokeJoin") : (Jn(this), this.Na.Ar = a, this.N(), this.g("strokeJoin", b, a)))
            }
        }, strokeMiterLimit: { get: function () { return null !== this.Na ? this.Na.Br : 10 }, set: function (a) { var b = this.strokeMiterLimit; if (b !== a && 1 <= a) { Jn(this); this.Na.Br = a; this.N(); var c = this.part; null !== c && (c.Xf = NaN); this.g("strokeMiterLimit", b, a) } } }, strokeDashArray: {
            get: function () { return this.Sg }, set: function (a) {
                var b = this.Sg; if (b !== a) {
                    if (null !== a) {
                        for (var c = a.length, d = 0, e = 0; e < c; e++) {
                            var f =
                                a[e]; 0 <= f && isFinite(f) || C("strokeDashArray:value " + f + " at index " + e + " must be a positive number or zero."); d += f
                        } if (0 === d) { if (null === b) return; a = null }
                    } this.Sg = a; this.N(); this.g("strokeDashArray", b, a)
                }
            }
        }, strokeDashOffset: { get: function () { return null !== this.Na ? this.Na.sj : 0 }, set: function (a) { var b = this.strokeDashOffset; b !== a && 0 <= a && (Jn(this), this.Na.sj = a, this.N(), this.g("strokeDashOffset", b, a)) } }, figure: {
            get: function () { return this.Ym }, set: function (a) {
                var b =
                    this.Ym; if (b !== a) { var c = K.Ve[a]; "function" === typeof c ? c = a : (c = K.Ve[a.toLowerCase()]) || C("Unknown Shape.figure: " + a); b !== c && (a = this.part, null !== a && (a.Xf = NaN), this.Ym = c, this.Jd = null, this.nc(), this.v(), this.g("figure", b, c)) }
            }
        }, toArrow: {
            get: function () { return null !== this.Ua ? this.Ua.V : "None" }, set: function (a) {
                var b = this.toArrow; !0 === a ? a = "Standard" : !1 === a && (a = ""); if (b !== a) {
                    var c = Zn(a); null === c ? C("Unknown Shape.toArrow: " + a) : b !== c && (null === this.Ua && (this.Ua = new Yl), this.Ua.V = c,
                        this.Jd = null, this.nc(), this.v(), Xn(this), this.g("toArrow", b, c))
                }
            }
        }, fromArrow: { get: function () { return null !== this.Ua ? this.Ua.j : "None" }, set: function (a) { var b = this.fromArrow; !0 === a ? a = "Standard" : !1 === a && (a = ""); if (b !== a) { var c = Zn(a); null === c ? C("Unknown Shape.fromArrow: " + a) : b !== c && (null === this.Ua && (this.Ua = new Yl), this.Ua.j = c, this.Jd = null, this.nc(), this.v(), Xn(this), this.g("fromArrow", b, c)) } } }, spot1: {
            get: function () {
                return null !== this.Na ? this.Na.bg :
                    Sc
            }, set: function (a) { var b = this.spot1; b.C(a) || (a = a.J(), Jn(this), this.Na.bg = a, this.v(), this.g("spot1", b, a)) }
        }, spot2: { get: function () { return null !== this.Na ? this.Na.cg : Sc }, set: function (a) { var b = this.spot2; b.C(a) || (a = a.J(), Jn(this), this.Na.cg = a, this.v(), this.g("spot2", b, a)) } }, parameter1: { get: function () { return this.Qn }, set: function (a) { var b = this.Qn; b !== a && (this.Qn = a, this.nc(), this.v(), this.g("parameter1", b, a)) } }, parameter2: {
            get: function () { return this.Rn }, set: function (a) { var b = this.Rn; b !== a && (this.Rn = a, this.nc(), this.v(), this.g("parameter2", b, a)) }
        }, naturalBounds: { get: function () { return this.ac } }, pathPattern: { get: function () { return null !== this.Na ? this.Na.Vq : null }, set: function (a) { var b = this.pathPattern; b !== a && (Jn(this), this.Na.Vq = a, this.N(), this.g("pathPattern", b, a)) } }, geometryStretch: {
            get: function () {
                return null !== this.Na ? this.Na.bq :
                    Xk
            }, set: function (a) { var b = this.geometryStretch; b !== a && (Jn(this), this.Na.bq = a, this.v(), this.g("geometryStretch", b, a)) }
        }, interval: { get: function () { return null !== this.fa ? this.fa.Ok : 1 }, set: function (a) { var b = this.interval; a = Math.round(a); if (b !== a && 0 !== a && isFinite(a)) { null === this.fa && (this.fa = new $n); this.fa.Ok = a; var c = this.diagram; null !== c && this.panel === c.grid && oj(c); this.v(); c = this.panel; null !== c && (c.graduatedIntervals = null); this.g("interval", b, a) } } }, graduatedStart: {
            get: function () { return null !== this.fa ? this.fa.u : 0 }, set: function (a) { var b = this.graduatedStart; b !== a && (0 > a ? a = 0 : 1 < a && (a = 1), null === this.fa && (this.fa = new $n), this.fa.u = a, this.v(), this.g("graduatedStart", b, a)) }
        }, graduatedEnd: { get: function () { return null !== this.fa ? this.fa.j : 1 }, set: function (a) { var b = this.graduatedEnd; b !== a && (0 > a ? a = 0 : 1 < a && (a = 1), null === this.fa && (this.fa = new $n), this.fa.j = a, this.v(), this.g("graduatedEnd", b, a)) } }, graduatedSkip: {
            get: function () { return null !== this.fa ? this.fa.m : null }, set: function (a) { var b = this.graduatedSkip; b !== a && (null === this.fa && (this.fa = new $n), this.fa.m = a, this.v(), this.g("graduatedSkip", b, a)) }
        }
    }); Bf.prototype.intersectsRect = Bf.prototype.Tc; Bf.prototype.containedInRect = Bf.prototype.$g; Bf.prototype.getNearestIntersectionPoint = Bf.prototype.ks; Bf.prototype.getDocumentBounds = Bf.prototype.ji; Bf.prototype.getDocumentPoint = Bf.prototype.ja; var Yn = new Ta, Tn = new Ta; Bf.className = "Shape";
    Bf.getFigureGenerators = function () { var a = new ob, b; for (b in K.Ve) b !== b.toLowerCase() && a.add(b, K.Ve[b]); a.freeze(); return a }; Bf.defineFigureGenerator = function (a, b) { var c = a.toLowerCase(), d = K.Ve; d[a] = b; d[c] = a }; Bf.getArrowheadGeometries = function () { var a = new ob; for (d in K.rm) if (void 0 === Yn[d]) { var b = P.parse(K.rm[d], !1); Yn[d] = b; b = d.toLowerCase(); b !== d && (Yn[b] = d) } for (var c in Yn) if (c !== c.toLowerCase()) { var d = Yn[c]; d instanceof P && a.add(c, d) } a.freeze(); return a };
    Bf.defineArrowheadGeometry = function (a, b) { var c = null; "string" === typeof b ? c = P.parse(b, !1) : c = b; b = a.toLowerCase(); "none" !== b && a !== b || C("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: " + a); var d = Yn; d[a] = c; d[b] = a }; function Kn() { this.jc = !1; this.zr = "butt"; this.Ar = "miter"; this.Br = 10; this.sj = 0; this.Vq = null; this.bq = Xk; this.cg = this.bg = Sc }
    Kn.prototype.copy = function () { var a = new Kn; a.zr = this.zr; a.Ar = this.Ar; a.Br = this.Br; a.sj = this.sj; a.Vq = this.Vq; a.bq = this.bq; a.bg = this.bg.J(); a.cg = this.cg.J(); return a };
    function Pg(a, b) { Y.call(this); ao || (bo = Ng ? (new Ni(null)).context : null, ao = !0); this.o |= 2097152; this.bc = ""; this.uc = "black"; this.we = "13px sans-serif"; this.Eq = this.Cb = this.Dc = 0; this.da = this.fa = this.Dm = this.Ht = this.It = null; this.Eh = 0; this.$e = null; this.He = 0; this.ve = this.Xb = this.Nc = null; "string" === typeof a ? this.text = a : a && Object.assign(this, a); b && Object.assign(this, b); return this } la(Pg, Y);
    Pg.prototype.cloneProtected = function (a) { Y.prototype.cloneProtected.call(this, a); a.bc = this.bc; a.uc = this.uc; a.we = this.we; a.Dc = this.Dc; a.Cb = this.Cb; a.Eq = this.Eq; a.It = this.It; a.Ht = this.Ht; a.Dm = this.Dm; null !== this.fa && (a.fa = this.fa.copy()); null !== this.da && (a.da = this.da.jc ? this.da : this.da.copy()); a.Eh = this.Eh; a.$e = this.$e; a.He = this.He; a.Nc = this.Nc; a.Xb = this.Xb; a.ve = this.ve }; function co(a) { null === a.da ? a.da = new eo : a.da.jc && (a.da = a.da.copy()) } t = Pg.prototype;
    t.oh = function () { Y.prototype.oh.call(this); null !== this.da && (this.da.jc = !0) };
    function dn(a, b) {
        a.o = b.o | 6144; a.qb = b.opacity; a.Ra = b.background; a.areaBackground = b.areaBackground; a.ad = b.desiredSize.J(); a.minSize = b.minSize.J(); a.maxSize = b.maxSize.J(); null !== b.Jb ? a.Jb = b.Jb.copy() : a.Jb = null; a.Aa = b.scale; a.Ib = b.angle; a.stretch = b.stretch; a.Fg = b.margin.J(); a.Hb = b.alignment.J(); a.mk = b.alignmentFocus.J(); a.segmentFraction = b.segmentFraction; a.segmentOffset = b.segmentOffset.J(); a.segmentOrientation = b.segmentOrientation; null !== b.ib && (a.ib = b.ib.copy()); a.shadowVisible = b.shadowVisible; a.bc =
            b.bc; a.uc = b.uc; a.we = b.we; null !== a.fa && (a.fa = b.fa.copy()); null !== b.da && (a.da = b.da.jc ? b.da : b.da.copy()); a.Eh = b.Eh
    } t.rb = function (a) { a.classType === Pg ? this.wrap = a : Y.prototype.rb.call(this, a) }; t.toString = function () { return 22 < this.bc.length ? 'TextBlock("' + this.bc.substring(0, 20) + '"...)' : 'TextBlock("' + this.bc + '")' }; t.v = function () { Y.prototype.v.call(this); this.Ht = this.It = null };
    t.bh = function (a, b) {
        if (null !== this.uc && 0 !== this.bc.length && null !== this.we) {
            var c = this.naturalBounds, d = this.actualBounds, e = c.width, f = c.height, g = fo(this), h = a.textAlign = this.textAlign, k = b.pn; "start" === h ? h = k ? "right" : "left" : "end" === h && (h = k ? "left" : "right"); k = this.isUnderline; var l = this.isStrikethrough; hi(this, a, this.uc, !0, !1, c, d); (k || l) && hi(this, a, this.uc, !1, !1, c, d); d = 0; c = !1; var m = this.ke, n = m.dx - (g * m.m21 + m.dx); m = m.dy - (g * m.m22 + m.dy); var p = b.scale; 8 > (n * n + m * m) * p * p && (c = !0); b.jb !== a && (c = !1); b.jb instanceof Aj &&
                (c = !1); !1 === b.Df("textGreeking") && (c = !1); b = this.spacingAbove; n = this.spacingBelow; switch (this.flip) { case $k: a.translate(e, 0); a.scale(-1, 1); break; case Zk: a.translate(0, f); a.scale(1, -1); break; case al: a.translate(e, f), a.scale(-1, -1) }a.vc(); m = this.Dc; p = (b + g + n) * m; f > p && (d = this.verticalAlignment, d = d.y * f - d.y * p + d.offsetY); if (1 === m && null !== this.ve) n = this.Cb, n > e && (n = e), this.Mj(this.ve, a, 0, d + b, e, g, n, c, h, k, l); else if (null !== this.Nc && null !== this.Xb) for (p = 0; p < m; p++) {
                    var r = this.Nc[p]; r > e && (r = e); d += b; this.Mj(this.Xb[p],
                        a, 0, d, e, g, r, c, h, k, l); d += g + n
                } switch (this.flip) { case $k: a.scale(-1, 1); a.translate(-e, 0); break; case Zk: a.scale(1, -1); a.translate(0, -f); break; case al: a.scale(-1, -1), a.translate(-e, -f) }
        }
    };
    t.Mj = function (a, b, c, d, e, f, g, h, k, l, m) {
        var n = 0; if (h) "left" === k ? n = 0 : "right" === k ? n = e - g : "center" === k && (n = (e - g) / 2), b.fillRect(c + n, d + .25 * f, g, 1); else {
            "left" === k ? n = 0 : "right" === k ? n = e : "center" === k && (n = e / 2); e = null !== go ? go(this, f) : .75 * f; b.fillText(a, c + n, d + e); a = f / 20 | 0; 0 === a && (a = 1); "right" === k ? n -= g : "center" === k && (n -= g / 2); if (l) { h = null !== ho ? ho(this, f) : .8 * f; k = c + n; l = d + h; e = c + n + g; h = d + h; var p = a; b.beginPath(); b.lineWidth = p; b.moveTo(k, l); b.lineTo(e, h); b.stroke(); b.Ne(); b instanceof Aj && b.Od.classList.add("gojs-td") } m && (m =
                d + f - f / 2.2 | 0, 0 !== a % 2 && (m += .5), d = c + n, f = m, c = c + n + g, g = m, n = a, b.beginPath(), b.lineWidth = n, b.moveTo(d, f), b.lineTo(c, g), b.stroke(), b.Ne(), b instanceof Aj && b.Od.classList.add("gojs-td"))
        }
    };
    t.fk = function (a, b, c) { if (!Y.prototype.fk.call(this, a, b, c)) return !1; hi(this, a, this.uc, !0, !1, this.naturalBounds, this.actualBounds); if (c) { if (0 === this.svg.getElementsByTagName("text").length) return !0; b = this.svg.getElementsByClassName("gojs-td"); for (c = 0; c < b.length; c++)hm(a, b[c], !1) } else b = this.svg, b.innerHTML = this.text, hm(a, b, !0); return !0 }; t.ik = function (a) { return 1 < this.lineCount || this.isUnderline || this.isStrikethrough ? !0 : Y.prototype.ik.call(this, a) };
    t.wv = function (a, b) { if ("g" !== a.nodeName) a.setAttributeNS(null, "filter", b); else { a = a.getElementsByTagName("text"); for (var c = 0; c < a.length; c++)a[c].setAttributeNS(null, "filter", b) } };
    t.Sl = function (a, b, c, d) {
        var e = this.Dc; this.Eh = a; var f = this.we; null !== bo && io !== f && (io = bo.font = f); this.Cb = this.He = 0; this.ve = this.Xb = this.Nc = this.$e = null; var g; if (isNaN(this.desiredSize.width)) {
            f = this.bc.replace(/\r\n/g, "\n").replace(/\r/g, "\n"); if (0 === f.length) f = 0; else if (this.isMultiline) { for (var h = g = 0, k = !1; !k;) { var l = f.indexOf("\n", h); -1 === l && (l = f.length, k = !0); h = f.substr(h, l - h); this.formatting === jo && (h = h.trim()); g = Math.max(g, ko(h)); h = l + 1 } f = g } else g = f.indexOf("\n", 0), 0 <= g && (f = f.substr(0, g)), f = ko(f);
            f = Math.min(f, a / this.scale); f = Math.max(8, f)
        } else f = this.desiredSize.width; null !== this.panel && (f = Math.min(f, this.panel.maxSize.width)); g = lo(this, f); isNaN(this.desiredSize.height) ? g = Math.min(g, b / this.scale) : g = this.desiredSize.height; k = g; if (0 !== this.Cb && null !== this.Nc && null !== this.Xb && this.overflow === mo && (b = this.we, b = this.overflow === mo ? no(b) : 0, l = this.spacingAbove + this.spacingBelow, l = Math.max(0, fo(this) + l), k = Math.min(this.maxLines - 1, Math.max(Math.floor(k / l + .01) - 1, 0)), !(k + 1 >= this.Xb.length))) {
            l = this.Xb[k];
            for (b = Math.max(1, a - b); ko(l) > b && 1 < l.length;)l = l.substr(0, l.length - 1); l += oo; b = ko(l); this.Xb[k] = l; this.Xb = this.Xb.slice(0, k + 1); this.Nc[k] = b; this.Nc = this.Nc.slice(0, k + 1); this.He = this.Xb.length; this.Cb = Math.max(this.Cb, b); this.Dc = this.He; 1 === this.Dc && (this.ve = this.Xb[0])
        } if (this.wrap === po || isNaN(this.desiredSize.width)) f = isNaN(a) ? this.Cb : Math.min(a, this.Cb), isNaN(this.desiredSize.width) && (f = Math.max(8, f)); f = Math.max(c, f); g = Math.max(d, g); Pb(this.ac, f, g); this.Ce(0, 0, f, g); null !== this.svg && e !== this.Dc && (this.Uh =
            !0)
    }; t.Zg = function (a, b, c, d) { ll(this, a, b, c, d) }; function qo(a, b, c) { if (null === a.ve) a.ve = b, a.Cb = c; else { if (null === a.Xb || null === a.Nc) a.Xb = [], a.Nc = [], a.Xb.push(a.ve), a.Nc.push(a.Cb); a.Xb.push(b); a.Nc.push(c) } }
    function ro(a, b, c, d) {
        var e = a.formatting === jo; e && (b = b.trim()); var f = 0, g = a.we, h = a.spacingAbove + a.spacingBelow; h = Math.max(0, fo(a) + h); var k = a.overflow === mo ? no(g) : 0; if (a.Dc >= a.maxLines) null !== d && d.h(0, h); else if (g = b, a.wrap === so) {
            a.He = 1; var l = ko(b); if (0 === k || l <= c) a.Cb = Math.max(a.Cb, l), qo(a, b, a.Cb), null !== d && d.h(l, h); else {
                f = to(a, g); g = g.substr(f.length); b = to(a, g); for (l = ko(f + b); 0 < b.length && l <= c;)f += b, g = g.substr(b.length), b = to(a, g), l = f + b, e && (l = l.trim()), l = ko(l); e && (b = b.trim()); f += b; for (c = Math.max(1, c - k); ko(f) >
                    c && 1 < f.length;)f = f.substr(0, f.length - 1); f += oo; var m = ko(f); a.Cb = m; qo(a, f, m); null !== d && d.h(m, h)
            }
        } else {
            k = 0; 0 === g.length && (k = 1, qo(a, g, 0)); for (; 0 < g.length;) {
                b = to(a, g); for (g = g.substr(b.length); ko(b) > c;) { var n = 1; l = ko(b.substr(0, n)); for (m = 0; l <= c;)n++, m = l, l = ko(b.substr(0, n)); if (1 === n) { var p = l; f = Math.max(f, l) } else p = m, f = Math.max(f, m); n--; 1 > n && (n = 1); qo(a, b.substr(0, n), p); k++; b = b.substr(n); if (a.Dc + k > a.maxLines) break } m = to(a, g); for (l = ko(b + m); 0 < m.length && l <= c;)b += m, g = g.substr(m.length), m = to(a, g), l = b + m, e && (l = l.trim()),
                    l = ko(l); e && (b = b.trim()); if ("" !== b && ("\u00ad" === b[b.length - 1] && (b = b.substring(0, b.length - 1) + "\u2010"), 0 === m.length ? (n = l, f = Math.max(f, l)) : (n = m = ko(b), f = Math.max(f, m)), qo(a, b, n), k++, a.Dc + k > a.maxLines)) break
            } a.He = Math.min(a.maxLines, k); a.Cb = Math.max(a.Cb, f); null !== d && d.h(a.Cb, h * a.He)
        }
    }
    function to(a, b) { if (a.wrap === uo) return b.substr(0, 1); for (var c = b.length, d = 0, e = vo; d < c && !e.test(b.charAt(d));)d++; for (a.formatting === wo && (c = Math.min(c, d + 1)); d < c && e.test(b.charAt(d));)d++; return d >= b.length ? b : b.substr(0, d) } function ko(a) { return null === bo ? 8 * a.length : bo.measureText(a).width } function fo(a) { if (null !== a.$e) return a.$e; var b = a.we; if (null === bo) { var c = 16; return a.$e = c } void 0 !== xo[b] && 5E3 > yo ? c = xo[b] : (c = 1.3 * bo.measureText("M").width, xo[b] = c, yo++); return a.$e = c }
    function no(a) { if (null === bo) return 6; if (void 0 !== zo[a] && 5E3 > Ao) var b = zo[a]; else b = bo.measureText(oo).width, zo[a] = b, Ao++; return b }
    function lo(a, b) { var c = a.bc.replace(/\r\n/g, "\n").replace(/\r/g, "\n"), d = a.spacingAbove + a.spacingBelow; d = Math.max(0, fo(a) + d); if (0 === c.length) return a.Cb = 0, a.Dc = 1, d; if (!a.isMultiline) { var e = c.indexOf("\n", 0); 0 <= e && (c = c.substr(0, e)) } e = 0; for (var f = a.Dc = 0, g, h = !1; !h;) { g = c.indexOf("\n", f); -1 === g && (g = c.length, h = !0); if (f <= g) { f = c.substr(f, g - f); if (a.wrap !== so) { a.He = 0; var k = L.alloc(); ro(a, f, b, k); e += k.height; L.free(k); a.Dc += a.He } else ro(a, f, b, null), e += d, a.Dc++; a.Dc === a.maxLines && (h = !0) } f = g + 1 } return a.Eq = e }
    ma.Object.defineProperties(Pg.prototype, {
        font: { get: function () { return this.we }, set: function (a) { var b = this.we; b !== a && (this.we = a, this.$e = null, this.v(), this.g("font", b, a)) } }, text: { get: function () { return this.bc }, set: function (a) { var b = this.bc; null !== a && void 0 !== a ? a = a.toString() : a = ""; b !== a && (this.bc = a, this.v(), this.g("text", b, a), this.Uh = !0) } }, textAlign: {
            get: function () { return null !== this.da ? this.da.Gr : "start" }, set: function (a) {
                var b =
                    this.textAlign; b === a || "start" !== a && "end" !== a && "left" !== a && "right" !== a && "center" !== a || (co(this), this.da.Gr = a, this.N(), this.g("textAlign", b, a))
            }
        }, flip: { get: function () { return null !== this.da ? this.da.Uf : kg }, set: function (a) { var b = this.flip; b !== a && (co(this), this.da.Uf = a, this.N(), this.g("flip", b, a)) } }, verticalAlignment: {
            get: function () { return null !== this.da ? this.da.Qr : Yc }, set: function (a) {
                var b = this.verticalAlignment; b.C(a) || (a = a.J(), co(this), this.da.Qr =
                    a, Jl(this), this.g("verticalAlignment", b, a))
            }
        }, naturalBounds: { get: function () { if (!this.ac.w()) { var a = L.alloc(); ro(this, this.bc, 999999, a); var b = a.width; L.free(a); a = lo(this, b); var c = this.desiredSize; isNaN(c.width) || (b = c.width); isNaN(c.height) || (a = c.height); Pb(this.ac, b, a) } return this.ac } }, isMultiline: {
            get: function () { return 0 !== (this.o & 2097152) }, set: function (a) {
                var b = 0 !== (this.o & 2097152); b !== a && (this.o ^= 2097152, this.v(), this.g("isMultiline", b,
                    a))
            }
        }, isUnderline: { get: function () { return 0 !== (this.o & 4194304) }, set: function (a) { var b = 0 !== (this.o & 4194304); b !== a && (this.o ^= 4194304, this.Uh = !0, this.N(), this.g("isUnderline", b, a)) } }, isStrikethrough: { get: function () { return 0 !== (this.o & 8388608) }, set: function (a) { var b = 0 !== (this.o & 8388608); b !== a && (this.o ^= 8388608, this.Uh = !0, this.N(), this.g("isStrikethrough", b, a)) } }, wrap: {
            get: function () {
                return null !== this.da ? this.da.Sr :
                    Bo
            }, set: function (a) { var b = this.wrap; b !== a && (co(this), this.da.Sr = a, this.v(), this.g("wrap", b, a)) }
        }, overflow: { get: function () { return null !== this.da ? this.da.Tq : Co }, set: function (a) { var b = this.overflow; b !== a && (co(this), this.da.Tq = a, this.v(), this.g("overflow", b, a)) } }, stroke: { get: function () { return this.uc }, set: function (a) { var b = this.uc; b !== a && (null !== a && Zl(a, "TextBlock.stroke"), a instanceof pl && a.freeze(), this.uc = a, this.N(), this.g("stroke", b, a)) } },
        lineCount: { get: function () { return this.Dc } }, lineHeight: { get: function () { return fo(this) } }, editable: { get: function () { return 0 !== (this.o & 1048576) }, set: function (a) { var b = 0 !== (this.o & 1048576); b !== a && (this.o ^= 1048576, this.g("editable", b, a)) } }, textEditor: {
            get: function () { return null !== this.da ? this.da.Ir : null }, set: function (a) {
                var b = this.textEditor; b !== a && (co(this), this.da.Ir = a, this.g("textEditor",
                    b, a))
            }
        }, errorFunction: { get: function () { return null !== this.da ? this.da.zd : null }, set: function (a) { var b = this.errorFunction; b !== a && (co(this), this.da.zd = a, this.g("errorFunction", b, a)) } }, interval: {
            get: function () { return null !== this.fa ? this.fa.Ok : 1 }, set: function (a) {
                var b = this.interval; a = Math.round(a); if (b !== a && 0 !== a && isFinite(a)) {
                    null === this.fa && (this.fa = new $n); this.fa.Ok = a; this.v(); var c = this.panel; null !== c && (c.graduatedIntervals = null); this.g("interval",
                        b, a)
                }
            }
        }, graduatedStart: { get: function () { return null !== this.fa ? this.fa.u : 0 }, set: function (a) { var b = this.graduatedStart; b !== a && (0 > a ? a = 0 : 1 < a && (a = 1), null === this.fa && (this.fa = new $n), this.fa.u = a, this.v(), this.g("graduatedStart", b, a)) } }, graduatedEnd: { get: function () { return null !== this.fa ? this.fa.j : 1 }, set: function (a) { var b = this.graduatedEnd; b !== a && (0 > a ? a = 0 : 1 < a && (a = 1), null === this.fa && (this.fa = new $n), this.fa.j = a, this.v(), this.g("graduatedEnd", b, a)) } },
        graduatedFunction: { get: function () { return null !== this.fa ? this.fa.F : null }, set: function (a) { var b = this.graduatedFunction; b !== a && (null === this.fa && (this.fa = new $n), this.fa.F = a, this.v(), this.g("graduatedFunction", b, a)) } }, graduatedSkip: { get: function () { return null !== this.fa ? this.fa.m : null }, set: function (a) { var b = this.graduatedSkip; b !== a && (null === this.fa && (this.fa = new $n), this.fa.m = a, this.v(), this.g("graduatedSkip", b, a)) } }, textValidation: {
            get: function () { return null !== this.da ? this.da.tj : null }, set: function (a) { var b = this.textValidation; b !== a && (co(this), this.da.tj = a, this.g("textValidation", b, a)) }
        }, textEdited: { get: function () { return null !== this.da ? this.da.Hr : null }, set: function (a) { var b = this.textEdited; b !== a && (co(this), this.da.Hr = a, this.g("textEdited", b, a)) } }, spacingAbove: {
            get: function () { return null !== this.da ? this.da.vr : 0 }, set: function (a) {
                var b = this.spacingAbove; b !==
                    a && (co(this), this.da.vr = a, this.v(), this.g("spacingAbove", b, a))
            }
        }, spacingBelow: { get: function () { return null !== this.da ? this.da.wr : 0 }, set: function (a) { var b = this.spacingBelow; b !== a && (co(this), this.da.wr = a, this.v(), this.g("spacingBelow", b, a)) } }, formatting: { get: function () { return null !== this.da ? this.da.Zp : jo }, set: function (a) { var b = this.formatting; b !== a && (co(this), this.da.Zp = a, this.v(), this.g("formatting", b, a)) } }, maxLines: {
            get: function () { return null !== this.da ? this.da.Cq : Infinity }, set: function (a) { var b = this.maxLines; b !== a && (a = Math.floor(a), 0 >= a && xa(a, "> 0", Pg, "maxLines"), co(this), this.da.Cq = a, this.v(), this.g("maxLines", b, a)) }
        }, metrics: { get: function () { return { arrSize: null !== this.Nc ? this.Nc : [this.Cb], arrText: null !== this.Xb ? this.Xb : [this.ve], maxLineWidth: this.Cb, fontHeight: this.$e } } }, choices: {
            get: function () { return this.Dm }, set: function (a) {
                var b = this.Dm; b !== a && (this.Dm =
                    a, this.g("choices", b, a))
            }
        }
    }); var go = null, ho = null, so = new D(Pg, "None", 0), po = new D(Pg, "WrapFit", 1), Bo = new D(Pg, "WrapDesiredSize", 2), uo = new D(Pg, "WrapBreakAll", 3), Co = new D(Pg, "OverflowClip", 0), mo = new D(Pg, "OverflowEllipsis", 1), jo = new D(Pg, "FormatTrim", 0), wo = new D(Pg, "FormatNone", 0), vo = /[ \u200b\u00ad]/, xo = new Ta, yo = 0, zo = new Ta, Ao = 0, oo = "...", io = "", bo = null, ao = !1; Pg.className = "TextBlock";
    function eo() { this.jc = !1; this.zd = null; this.Uf = kg; this.Zp = jo; this.Cq = Infinity; this.Tq = Co; this.wr = this.vr = 0; this.Gr = "start"; this.tj = this.Ir = this.Hr = null; this.Qr = Yc; this.Sr = Bo } eo.prototype.copy = function () { var a = new eo; a.zd = this.zd; a.Uf = this.Uf; a.Zp = this.Zp; a.Cq = this.Cq; a.Tq = this.Tq; a.vr = this.vr; a.wr = this.wr; a.Gr = this.Gr; a.Hr = this.Hr; a.Ir = this.Ir; a.tj = this.tj; a.Qr = this.Qr; a.Sr = this.Sr; return a }; Pg.getEllipsis = function () { return oo }; Pg.setEllipsis = function (a) { oo = a; zo = new Ta; Ao = 0 }; Pg.getBaseline = function () { return go };
    Pg.setBaseline = function (a) { go = a }; Pg.getUnderline = function () { return ho }; Pg.setUnderline = function (a) { ho = a }; Pg.isValidFont = function (a) { ao || (bo = Ng ? (new Ni(null)).context : null, ao = !0); if (null === bo) return !0; var b = bo.font; if (a === b || "10px sans-serif" === a) return !0; bo.font = "10px sans-serif"; bo.font = a; var c = bo.font; if ("10px sans-serif" !== c) return bo.font = b, !0; bo.font = "19px serif"; var d = bo.font; bo.font = a; c = bo.font; bo.font = b; return c !== d }; Pg.FormatNone = wo; Pg.FormatTrim = jo; Pg.None = so; Pg.OverflowClip = Co;
    Pg.OverflowEllipsis = mo; Pg.WrapBreakAll = uo; Pg.WrapDesiredSize = Bo; Pg.WrapFit = po; function $n() { this.Ok = 1; this.u = 0; this.j = 1; this.m = this.F = null } $n.prototype.copy = function () { var a = new $n; a.Ok = this.Ok; a.u = this.u; a.j = this.j; a.F = this.F; a.m = this.m; return a }; $n.className = "GradElementSettings";
    function ck(a, b) { Y.call(this); this.Ag = null; this.mo = ""; this.Rg = cc; this.Ik = nd; this.Tg = this.zd = null; this.Hk = xc; this.Uf = kg; this.rl = null; this.mn = !1; this.an = !0; this.Wk = !1; this.nl = null; "string" === typeof a ? this.source = a : a && Object.assign(this, a); b && Object.assign(this, b) } var Do; la(ck, Y); ck.prototype.cloneProtected = function (a) { Y.prototype.cloneProtected.call(this, a); a.mo = this.mo; a.element = this.Ag; a.Rg = this.Rg.J(); a.Ik = this.Ik; a.Uf = this.Uf; a.zd = this.zd; a.Tg = this.Tg; a.Hk = this.Hk.J(); a.an = this.an; a.nl = this.nl };
    ck.prototype.rb = function (a) { a === kg || a === mg || a === Yk ? this.imageStretch = a : Y.prototype.rb.call(this, a) }; ck.prototype.toString = function () { return "Picture(" + this.source + ")#" + bb(this) }; function Ki(a) { void 0 === a && (a = ""); "" !== a ? Do[a] && (delete Do[a], Eo--) : (Do = new Ta, Eo = 0) }
    function Fo(a, b) {
        b.my || (b.yq = function (c) { b.lq = !0; b.gn = !1; var d = b.xg.copy(); if (null !== d && void 0 !== d) { for (d = d.iterator; d.next();) { var e = d.value; e.N(); var f = b.getAttribute("src") || ""; var g = e.Jg.I(f); if (null !== g) for (var h = g.length, k = 0; k < h; k++)f = g[k], e.Tn.add(f), f.Uh = !0, e.Wb(), b.dn && (void 0 === b.$t && (b.$t = c), null !== f.Tg && f.Tg(f, c)) } b.xg.clear() } a.desiredSize.w() || (nj(a, !1), a.v()) }, b.Rp = function (a) {
            b.gn = a; var c = b.xg.copy(); if (null !== c && void 0 !== c) {
                for (c = c.iterator; c.next();) {
                    var e = c.value, f = b.getAttribute("src") ||
                        ""; var g = e.Jg.I(f); if (null !== g) { e = g.length; f = Ea(); for (var h = 0; h < e; h++)f.push(g[h]); if (b.dn) for (h = 0; h < e; h++)g = f[h], null !== g.zd && g.zd(g, a); Ga(f) }
                } b.xg.clear()
            }
        }, b.addEventListener("load", b.yq), b.addEventListener("error", b.Rp), b.my = !0)
    } ck.prototype.Yz = function () { if ("" !== this.source) { Ki(this.source); var a = this.source; this.source = ""; this.source = a } }; ck.prototype.redraw = function () { this.N() };
    ck.prototype.bh = function (a, b) {
        var c = this.Ag; if (null !== c) {
            var d = c.getAttribute("src"); if (!(c instanceof HTMLImageElement && (null === d || "" === d) || c.gn instanceof Event && a instanceof El)) {
                var e = a instanceof Aj; d = this.naturalBounds; var f = 0, g = 0, h = this.mn, k = h ? +c.width : c.naturalWidth; h = h ? +c.height : c.naturalHeight; void 0 === k && c.videoWidth && (k = c.videoWidth); void 0 === h && c.videoHeight && (h = c.videoHeight); k = k || d.width; h = h || d.height; if (0 !== k && 0 !== h) {
                    var l = k, m = h; this.sourceRect.w() && (f = this.Rg.x, g = this.Rg.y, k = this.Rg.width,
                        h = this.Rg.height); var n = k, p = h, r = this.Ik, q = this.Hk; switch (r) {
                            case kg: if (this.sourceRect.w()) break; n >= d.width && (f = f + q.offsetX + (n * q.x - d.width * q.x)); p >= d.height && (g = g + q.offsetY + (p * q.y - d.height * q.y)); k = Math.min(d.width, n); h = Math.min(d.height, p); break; case nd: n = d.width; p = d.height; break; case mg: case Yk: r === mg ? (r = Math.min(d.height / p, d.width / n), n *= r, p *= r) : r === Yk && (r = Math.max(d.height / p, d.width / n), n *= r, p *= r, n >= d.width && (f = (f + q.offsetX + (n * q.x - d.width * q.x) / n) * k), p >= d.height && (g = (g + q.offsetY + (p * q.y - d.height * q.y) /
                                p) * h), k *= 1 / (n / d.width), h *= 1 / (p / d.height), n = d.width, p = d.height)
                        }r = this.Af() * b.scale; var u = n * r * p * r, w = k * h / u, v = c.__goCache; r = null; var z = Go; if (!e) {
                            if (c.lq && void 0 !== v && 4 < u && w > z * z) for (null === v.di && (Ho(v, 4, l, m, c), Ho(v, 16, l, m, c)), e = v.di, l = e.length, m = 0; m < l; m++)if (e[m].ratio * e[m].ratio < w) r = e[m]; else break; if (!b.Um) {
                                if (null === this.rl) if (null === this.Ag) this.rl = !1; else {
                                    e = (new Ni(null)).context; try { e.drawImage(this.Ag, 0, 0) } catch (y) { this.an = !1 } try { e.getImageData(0, 0, 1, 1).data[3] && (this.rl = !1), this.rl = !1 } catch (y) {
                                        this.rl =
                                        !0
                                    }
                                } if (this.rl) return
                            }
                        } e = n < d.width ? q.offsetX + (d.width * q.x - n * q.x) : 0; q = p < d.height ? q.offsetY + (d.height * q.y - p * q.y) : 0; switch (this.flip) { case $k: a.translate(Math.min(d.width, n), 0); a.scale(-1, 1); break; case Zk: a.translate(0, Math.min(d.height, p)); a.scale(1, -1); break; case al: a.translate(Math.min(d.width, n), Math.min(d.height, p)), a.scale(-1, -1) }a.vc(); if (b.Df("pictureRatioOptimization") && !b.Xi && void 0 !== v && null !== r && 1 !== r.ratio) {
                            a.save(); b = r.ratio; try {
                                a.drawImage(r.source, f / b, g / b, Math.min(r.source.width, k / b),
                                    Math.min(r.source.height, h / b), e, q, Math.min(d.width, n), Math.min(d.height, p))
                            } catch (y) { this.an = !1 } a.restore()
                        } else try { a.drawImage(c, f, g, k, h, e, q, Math.min(d.width, n), Math.min(d.height, p)) } catch (y) { this.an = !1 } switch (this.flip) { case $k: a.scale(-1, 1); a.translate(-Math.min(d.width, n), 0); break; case Zk: a.scale(1, -1); a.translate(0, -Math.min(d.height, p)); break; case al: a.scale(-1, -1), a.translate(-Math.min(d.width, n), -Math.min(d.height, p)) }
                }
            }
        }
    };
    ck.prototype.Sl = function (a, b, c, d) {
        var e = this.desiredSize, f = il(this, !0), g = this.Ag, h = this.mn, k = 0, l = 0; if (h || !this.Wk && g && g.complete) this.Wk = !0; null === g ? (isFinite(e.width) || (a = 0), isFinite(e.height) || (b = 0)) : (k = h ? +g.width : g.naturalWidth, l = h ? +g.height : g.naturalHeight); isFinite(e.width) || f === nd || f === Vk ? (isFinite(a) || (this.sourceRect.w() ? a = this.sourceRect.width : null !== g && (a = k)), c = 0) : null !== g && !1 !== this.Wk && (a = this.sourceRect.w() ? this.sourceRect.width : k); isFinite(e.height) || f === nd || f === Wk ? (isFinite(b) || (this.sourceRect.w() ?
            b = this.sourceRect.height : null !== g && (b = l)), d = 0) : null !== g && !1 !== this.Wk && (b = this.sourceRect.w() ? this.sourceRect.height : l); isFinite(e.width) && (a = e.width); isFinite(e.height) && (b = e.height); e = this.maxSize; f = this.minSize; c = Math.max(c || 0, f.width); d = Math.max(d || 0, f.height); a = Math.min(e.width, a); b = Math.min(e.height, b); a = Math.max(c, a); b = Math.max(d, b); null === g || g.complete || (isFinite(a) || (a = 0), isFinite(b) || (b = 0)); Pb(this.ac, a, b); this.Ce(0, 0, a, b)
    }; ck.prototype.Zg = function (a, b, c, d) { ll(this, a, b, c, d) };
    ma.Object.defineProperties(ck.prototype, {
        element: {
            get: function () { return this.Ag }, set: function (a) {
                var b = this.Ag; if (b !== a) {
                    this.mn = a instanceof HTMLCanvasElement; var c = a instanceof HTMLImageElement; null === a || c || a instanceof HTMLVideoElement || this.mn || C("Picture.element must be an instance of Image, Canvas, or Video, not: " + a); var d = this.diagram; null !== d && dk(d, this); this.Ag = a; null !== a && c && void 0 === a.xg && (a.xg = new H); null !== d && c && bk(d, this); null !== a && (this.mn || !0 === a.complete ?
                        (a.dn && (a.gn instanceof Event && null !== this.zd ? this.zd(this, a.gn) : !0 === a.lq && !a.gn && null !== this.Tg && a.$t && this.Tg(this, a.$t)), a.lq = !0, this.desiredSize.w() || (nj(this, !1), this.v())) : c && Fo(this, a)); this.g("element", b, a); this.N()
                }
            }
        }, source: {
            get: function () { return this.mo }, set: function (a) {
                var b = this.mo; if (b !== a) {
                    this.mo = a; var c = Do; if (Ng) {
                        var d = null; if (void 0 !== c[a]) d = c[a]; else if ("" !== a) {
                            30 < Eo && (Ki(), c = Do); d = ua("img"); d.dn = !0; Fo(this, d); var e = this.nl; null !== e && (d.crossOrigin =
                                e(this)); d.src = a; c[a] = d; Eo++
                        } this.element = d; null !== d && void 0 === d.__goCache && (d.__goCache = new Io); this.v(); this.N()
                    } this.g("source", b, a)
                }
            }
        }, sourceCrossOrigin: { get: function () { return this.nl }, set: function (a) { if (this.nl !== a && (this.nl = a, null !== this.element)) { var b = this.element.getAttribute("src"); null === a && "string" === typeof b ? this.element.crossOrigin = null : null !== a && (this.element.crossOrigin = a(this)); this.element.src = b } } }, sourceRect: {
            get: function () { return this.Rg },
            set: function (a) { var b = this.Rg; b.C(a) || (this.Rg = a = a.J(), this.N(), this.g("sourceRect", b, a)) }
        }, imageStretch: { get: function () { return this.Ik }, set: function (a) { var b = this.Ik; b !== a && (this.Ik = a, this.N(), this.g("imageStretch", b, a)) } }, flip: { get: function () { return this.Uf }, set: function (a) { var b = this.Uf; b !== a && (this.Uf = a, this.N(), this.g("flip", b, a)) } }, imageAlignment: {
            get: function () { return this.Hk }, set: function (a) {
                var b = this.Hk;
                b.C(a) || (this.Hk = a = a.J(), this.N(), this.g("imageAlignment", b, a))
            }
        }, errorFunction: { get: function () { return this.zd }, set: function (a) { var b = this.zd; b !== a && (this.zd = a, this.g("errorFunction", b, a)) } }, successFunction: { get: function () { return this.Tg }, set: function (a) { var b = this.Tg; b !== a && (this.Tg = a, this.g("successFunction", b, a)) } }, naturalBounds: { get: function () { return this.ac } }
    }); ck.prototype.redraw = ck.prototype.redraw;
    ck.prototype.reloadSource = ck.prototype.Yz; var Eo = 0, Go = 4; ck.className = "Picture"; Do = new Ta; ck.clearCache = Ki; function Io() { this.di = null } function Ho(a, b, c, d, e) { null === a.di && (a.di = []); var f = new Ni(null), g = f.context, h = 1 / b; f.width = c / b; f.height = d / b; 0 !== f.width && 0 !== f.height && (b = new Jo(f.La, b), c = 1, 0 < a.di.length && (c = a.di[a.di.length - 1], e = c.source, c = c.ratio), g.setTransform(h * c, 0, 0, h * c, 0, 0), g.vc(), g.drawImage(e, 0, 0), a.di.push(b)) } Io.className = "PictureCacheArray"; function Jo(a, b) { this.source = a; this.ratio = b }
    Jo.className = "PictureCacheInstance"; function Ko() { this.gs = new P; this.ic = null } Ko.prototype.reset = function (a) { null !== a ? (a.ka(), this.gs = a, a.figures.clear()) : this.gs = new P; this.ic = null }; function wd(a, b, c, d, e, f) { a.ic = new Wd; a.ic.startX = b; a.ic.startY = c; a.ic.isFilled = d; a.ic.isEvenOdd = f || !1; a.gs.figures.add(a.ic); void 0 !== e && (a.ic.isShadowed = e) } function Ad(a) { var b = a.ic.segments.length; 0 < b && a.ic.segments.L(b - 1).close() }
    Ko.prototype.moveTo = function (a, b, c) { void 0 === c && (c = !1); var d = new Xd(Dd); d.endX = a; d.endY = b; c && d.close(); this.ic.segments.add(d) }; Ko.prototype.lineTo = function (a, b, c) { void 0 === c && (c = !1); var d = new Xd(pd); d.endX = a; d.endY = b; c && d.close(); this.ic.segments.add(d) }; function xd(a, b, c, d, e, f, g) { var h; void 0 === h && (h = !1); var k = new Xd(Ed); k.point1X = b; k.point1Y = c; k.point2X = d; k.point2Y = e; k.endX = f; k.endY = g; h && k.close(); a.ic.segments.add(k) }
    function yd(a, b, c, d, e) { var f; void 0 === f && (f = !1); var g = new Xd(Kd); g.point1X = b; g.point1Y = c; g.endX = d; g.endY = e; f && g.close(); a.ic.segments.add(g) } Ko.prototype.arcTo = function (a, b, c, d, e, f, g) { void 0 === f && (f = 0); void 0 === g && (g = !1); var h = new Xd(Ld); h.startAngle = a; h.sweepAngle = b; h.centerX = c; h.centerY = d; h.radiusX = e; h.radiusY = 0 !== f ? f : e; g && h.close(); this.ic.segments.add(h) }; function zd(a, b, c, d, e, f, g, h) { var k; void 0 === k && (k = !1); b = new Xd(Md, g, h, b, c, d, e, f); k && b.close(); a.ic.segments.add(b) }
    function qd(a) { var b = Bd; if (null !== b) return Bd = null, b.reset(a), b; b = new Ko; b.reset(a); return b } var Bd = null; Ko.className = "StreamGeometryContext"; function Lo(a, b) { var c = a.toLowerCase(), d = K.Ve; d[a] = b; d[c] = a } Lo("Rectangle", function (a, b, c) { a = "r" + b + "," + c; var d = K.Jf[a]; if (void 0 !== d) return d; d = new P(P.u); d.endX = b; d.endY = c; K.Kf < K.sm && (K.Jf[a] = d, K.Kf++); return d });
    Lo("Square", function (a, b, c) { a = "s" + b + "," + c; var d = K.Jf[a]; if (void 0 !== d) return d; d = new P(P.u); d.endX = b; d.endY = c; d.defaultStretch = mg; K.Kf < K.sm && (K.Jf[a] = d, K.Kf++); return d });
    Lo("RoundedRectangle", function (a, b, c) {
        a = a ? a.parameter1 : NaN; if (isNaN(a) || 0 > a) a = 5; a = Math.min(a, b / 3); a = Math.min(a, c / 3); var d = "rr" + b + "," + c + "," + a, e = K.Jf[d]; if (void 0 !== e) return e; var f = a * K.sg; e = (new P).add((new Wd(a, 0, !0)).add(new Xd(pd, b - a, 0)).add(new Xd(Ed, b, a, b - f, 0, b, f)).add(new Xd(pd, b, c - a)).add(new Xd(Ed, b - a, c, b, c - f, b - f, c)).add(new Xd(pd, a, c)).add(new Xd(Ed, 0, c - a, f, c, 0, c - f)).add(new Xd(pd, 0, a)).add((new Xd(Ed, a, 0, 0, f, f, 0)).close())); 1 < f && (e.spot1 = new O(0, 0, f, f), e.spot2 = new O(1, 1, -f, -f)); K.Kf < K.sm &&
            (K.Jf[d] = e, K.Kf++); return e
    }); Lo("Border", "RoundedRectangle"); Lo("Ellipse", function (a, b, c) { a = "e" + b + "," + c; var d = K.Jf[a]; if (void 0 !== d) return d; d = new P(P.F); d.endX = b; d.endY = c; d.spot1 = dd; d.spot2 = gd; K.Kf < K.sm && (K.Jf[a] = d, K.Kf++); return d }); Lo("Circle", function (a, b, c) { a = "c" + b + "," + c; var d = K.Jf[a]; if (void 0 !== d) return d; d = new P(P.F); d.endX = b; d.endY = c; d.spot1 = dd; d.spot2 = gd; d.defaultStretch = mg; K.Kf < K.sm && (K.Jf[a] = d, K.Kf++); return d });
    Lo("TriangleRight", function (a, b, c) { return (new P).add((new Wd(0, 0)).add(new Xd(pd, b, .5 * c)).add((new Xd(pd, 0, c)).close())).mm(0, .25, .5, .75) }); Lo("TriangleDown", function (a, b, c) { return (new P).add((new Wd(0, 0)).add(new Xd(pd, b, 0)).add((new Xd(pd, .5 * b, c)).close())).mm(.25, 0, .75, .5) }); Lo("TriangleLeft", function (a, b, c) { return (new P).add((new Wd(b, c)).add(new Xd(pd, 0, .5 * c)).add((new Xd(pd, b, 0)).close())).mm(.5, .25, 1, .75) });
    Lo("TriangleUp", function (a, b, c) { return (new P).add((new Wd(b, c)).add(new Xd(pd, 0, c)).add((new Xd(pd, .5 * b, 0)).close())).mm(.25, .5, .75, 1) }); Lo("Triangle", "TriangleUp"); Lo("Diamond", function (a, b, c) { return (new P).add((new Wd(.5 * b, 0)).add(new Xd(pd, 0, .5 * c)).add(new Xd(pd, .5 * b, c)).add((new Xd(pd, b, .5 * c)).close())).mm(.25, .25, .75, .75) }); Lo("LineH", function (a, b, c) { a = new P(P.j); a.startX = 0; a.startY = c / 2; a.endX = b; a.endY = c / 2; return a });
    Lo("LineV", function (a, b, c) { a = new P(P.j); a.startX = b / 2; a.startY = 0; a.endX = b / 2; a.endY = c; return a }); Lo("None", "Rectangle"); Lo("BarH", "Rectangle"); Lo("BarV", "Rectangle"); Lo("MinusLine", "LineH"); Lo("PlusLine", function (a, b, c) { return (new P).add((new Wd(0, c / 2, !1)).add(new Xd(pd, b, c / 2)).add(new Xd(Dd, b / 2, 0)).add(new Xd(pd, b / 2, c))) }); Lo("XLine", function (a, b, c) { return (new P).add((new Wd(0, c, !1)).add(new Xd(pd, b, 0)).add(new Xd(Dd, 0, 0)).add(new Xd(pd, b, c))) });
    Lo("LineRight", function (a, b, c) { return (new P).add((new Wd(.25 * b, 0, !1)).add(new Xd(pd, .75 * b, .5 * c)).add(new Xd(pd, .25 * b, c))) }); Lo("LineDown", function (a, b, c) { return (new P).add((new Wd(0, .25 * c, !1)).add(new Xd(pd, .5 * b, .75 * c)).add(new Xd(pd, b, .25 * c))) }); Lo("LineLeft", function (a, b, c) { return (new P).add((new Wd(.75 * b, 0, !1)).add(new Xd(pd, .25 * b, .5 * c)).add(new Xd(pd, .75 * b, c))) }); Lo("LineUp", function (a, b, c) { return (new P).add((new Wd(0, .75 * c, !1)).add(new Xd(pd, .5 * b, .25 * c)).add(new Xd(pd, b, .75 * c))) });
    K.rm = {
        "": "", Standard: "F1 m 0,0 l 8,4 -8,4 2,-4 z", Backward: "F1 m 8,0 l -2,4 2,4 -8,-4 z", Triangle: "F1 m 0,0 l 8,4.62 -8,4.62 z", BackwardTriangle: "F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z", Boomerang: "F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z", BackwardBoomerang: "F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z", SidewaysV: "m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z", BackwardV: "m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z", OpenTriangle: "m 0,0 l 8,4 -8,4", BackwardOpenTriangle: "m 8,0 l -8,4 8,4", OpenTriangleLine: "m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
        BackwardOpenTriangleLine: "m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8", OpenTriangleTop: "m 0,0 l 8,4 m 0,4", BackwardOpenTriangleTop: "m 8,0 l -8,4 m 0,4", OpenTriangleBottom: "m 0,8 l 8,-4", BackwardOpenTriangleBottom: "m 0,4 l 8,4", HalfTriangleTop: "F1 m 0,0 l 0,4 8,0 z m 0,8", BackwardHalfTriangleTop: "F1 m 8,0 l 0,4 -8,0 z m 0,8", HalfTriangleBottom: "F1 m 0,4 l 0,4 8,-4 z", BackwardHalfTriangleBottom: "F1 m 8,4 l 0,4 -8,-4 z", ForwardSemiCircle: "m 4,0 b 270 180 0 4 4", BackwardSemiCircle: "m 4,8 b 90 180 0 -4 4", Feather: "m 0,0 l 3,4 -3,4",
        BackwardFeather: "m 3,0 l -3,4 3,4", DoubleFeathers: "m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4", BackwardDoubleFeathers: "m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4", TripleFeathers: "m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4", BackwardTripleFeathers: "m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4", ForwardSlash: "m 0,8 l 5,-8", BackSlash: "m 0,0 l 5,8", DoubleForwardSlash: "m 0,8 l 4,-8 m -2,8 l 4,-8", DoubleBackSlash: "m 0,0 l 4,8 m -2,-8 l 4,8", TripleForwardSlash: "m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
        TripleBackSlash: "m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8", Fork: "m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4", BackwardFork: "m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4", LineFork: "m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4", BackwardLineFork: "m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8", CircleFork: "F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4", BackwardCircleFork: "F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3", CircleLineFork: "F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
        BackwardCircleLineFork: "F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3", Circle: "F1 m 8,4 b 0 360 -4 0 4 z", Block: "F1 m 0,0 l 0,8 8,0 0,-8 z", StretchedDiamond: "F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z", Diamond: "F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z", Chevron: "F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z", StretchedChevron: "F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z", NormalArrow: "F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z", X: "m 0,0 l 8,8 m 0,-8 l -8,8", TailedNormalArrow: "F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
        DoubleTriangle: "F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z", BigEndArrow: "F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z", ConcaveTailArrow: "F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z", RoundedTriangle: "F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z", SimpleArrow: "F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z", AccelerationArrow: "F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z", BoxArrow: "F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
        TriangleLine: "F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8", CircleEndedArrow: "F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z", DynamicWidthArrow: "F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z", EquilibriumArrow: "m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3", FastForward: "F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z", Kite: "F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z", HalfArrowTop: "F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8", HalfArrowBottom: "F1 m 0,8 l 4,-4 4,0 -8,4 z",
        OpposingDirectionDoubleArrow: "F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z", PartialDoubleTriangle: "F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z", LineCircle: "F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z", DoubleLineCircle: "F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z", TripleLineCircle: "F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z", CircleLine: "F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8", DiamondCircle: "F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z", PlusCircle: "F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
        OpenRightTriangleTop: "m 8,0 l 0,4 -8,0 m 0,4", OpenRightTriangleBottom: "m 8,8 l 0,-4 -8,0", Line: "m 0,0 l 0,8", DoubleLine: "m 0,0 l 0,8 m 2,0 l 0,-8", TripleLine: "m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8", PentagonArrow: "F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"
    };
    function Mo() { this.jc = !1; this.kr = this.Ep = this.sq = null; this.mr = ""; this.Zq = this.hq = this.lr = null; this.ar = ""; this.$q = Lb; this.br = null; this.cr = ""; this.er = Sc; this.zq = ""; this.Aq = sc; this.Iq = qb; this.Dq = rb; this.Op = null; this.vq = 127; this.rr = sb; this.qr = "gray"; this.Pg = 4 }
    Mo.prototype.copy = function () { var a = new Mo; a.sq = this.sq; a.Ep = this.Ep; a.kr = this.kr; a.mr = this.mr; a.lr = this.lr; a.hq = this.hq; a.Zq = this.Zq; a.ar = this.ar; a.$q = this.$q.J(); a.br = this.br; a.cr = this.cr; a.er = this.er.J(); a.zq = this.zq; a.Aq = this.Aq.J(); a.Iq = this.Iq.J(); a.Dq = this.Dq.J(); a.Op = this.Op; a.vq = this.vq; a.rr = this.rr.J(); a.qr = this.qr; a.Pg = this.Pg; return a };
    function U(a, b) { void 0 === a || a instanceof Vl || "string" === typeof a ? W.call(this, a) : (W.call(this), a && (b = a)); this.G = 2408959; this.Qf = ""; this.O = null; this.Eg = ""; this.Nf = this.Og = null; this.bc = ""; this.vh = this.aj = null; this.ge = (new I(NaN, NaN)).freeze(); this.jf = null; this.wo = NaN; this.ow = -1; this.ly = new N; this.Nh = null; this.Xf = NaN; b && Object.assign(this, b) } la(U, W);
    U.prototype.cloneProtected = function (a) { W.prototype.cloneProtected.call(this, a); a.G = this.G & -4097 | 49152; a.Qf = this.Qf; null !== this.O && (a.O = this.O.jc ? this.O : this.O.copy()); a.Eg = this.Eg; a.Nf = null; a.bc = this.bc; a.ge.assign(this.ge); a.wo = this.wo }; t = U.prototype; t.xf = function (a) { W.prototype.xf.call(this, a); a.jh(); a.Og = null; a.jf = null; a.Nh = null }; t.toString = function () { var a = Ha(this.constructor) + "#" + bb(this); null !== this.data && (a += "(" + Ia(this.data) + ")"); return a };
    function No(a) { null === a.O ? a.O = new Mo : a.O.jc && (a.O = a.O.copy()) } t.oh = function () { W.prototype.oh.call(this); null !== this.O && (this.O.jc = !0) }; t.ck = function (a, b, c, d, e, f, g) { var h = this.diagram; null !== h && (a === ee && "elements" === b ? e instanceof W ? Gj(e, function (a) { Ij(h.partManager, a); Hj(h, a) }) : bk(h, e) : a === fe && "elements" === b && (e instanceof W ? Gj(e, function (a) { Lj(h.partManager, a, h) }) : dk(h, e)), h.cb(a, b, c, d, e, f, g)) };
    t.Ma = function (a) { W.prototype.Ma.call(this, a); if (null !== this.data) { for (var b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d]; e instanceof W && Gj(e, function (a) { null !== a.data && a.Ma() }) } for (b = this.adornments; b.next();)b.value.Ma(a) } }; U.prototype.updateRelationshipsFromData = function () { var a; null !== this.data && (null === (a = this.diagram) || void 0 === a ? void 0 : a.partManager.updateRelationshipsFromData(this)) }; U.prototype.Nj = function (a) { var b = this.Nf; return null === b ? null : b.I(a) };
    U.prototype.Wg = function (a, b) { if (null !== b) { var c = null, d = this.Nf; null !== d && (c = d.I(a)); if (c !== b) { if (null !== c) { var e = c.diagram; null !== e && e.remove(c) } null === d && (this.Nf = d = new ob); b.Qf !== a && (b.category = a); d.add(a, b); a = this.diagram; null !== a && (a.add(b), a = b.adornedObject, null !== a && (a = a.dh(), null !== a && (b.data = a.data))) } } }; U.prototype.Gf = function (a) { var b = this.Nf; if (null !== b) { var c = b.I(a); if (null !== c) { var d = c.diagram; null !== d && d.remove(c) } b.remove(a); 0 === b.count && (this.Nf = null) } };
    U.prototype.Cj = function () { var a = this.Nf; if (null !== a) { var b = Ea(); for (a = a.iterator; a.next();)b.push(a.key); a = b.length; for (var c = 0; c < a; c++)this.Gf(b[c]); Ga(b) } };
    U.prototype.updateAdornments = function () {
        var a = this.diagram; if (null !== a) {
            for (var b = this.adornments; b.next();) { var c = b.value; c.v(); c.placeholder && c.placeholder.v() } a: {
                if (this.isSelected && this.selectionAdorned && (b = this.selectionObject, null !== b && this.actualBounds.w() && this.isVisible() && b.Nd() && b.actualBounds.w())) {
                    c = this.Nj("Selection"); if (null === c) {
                        c = this.selectionAdornmentTemplate; null === c && (c = this.kh() ? a.linkSelectionAdornmentTemplate : this instanceof pf ? a.groupSelectionAdornmentTemplate : a.nodeSelectionAdornmentTemplate);
                        if (!(c instanceof te)) break a; jg(c); c = c.copy(); null !== c && (this.kh() && this.selectionObject === this.path && (c.type = W.Link), c.adornedObject = b)
                    } if (null !== c) { c.type === W.Link && c.v(); this.Wg("Selection", c); break a }
                } this.Gf("Selection")
            } Oo(this, a); for (b = this.adornments; b.next();)b.value.Ma()
        }
    }; U.prototype.Sb = function () { var a = this.diagram; null !== a && (a.pd = !0, 0 !== (this.G & 16384) !== !0 && (this.G |= 16384)) }; function ei(a) { 0 !== (a.G & 16384) !== !1 && (a.updateAdornments(), a.G &= -16385) }
    function Oo(a, b) { b.toolManager.mouseDownTools.each(function (b) { b.isEnabled && b.updateAdornments(a) }); b.toolManager.updateAdornments(a) } function di(a, b) { a.aj = b; null !== a.svg && a.svg.remove() } function Po(a) { if (!1 === uj(a)) { Qo(a, !0); a.Pk(); var b = a.diagram; null !== b && (b.ze.add(a), b.Wb()) } } function Ro(a) { a.G |= 2097152; if (!1 !== uj(a)) { var b = a.position, c = a.location; c.w() && b.w() || So(a, b, c); c = a.Gb; var d = N.alloc().assign(c); c.ka(); c.x = b.x; c.y = b.y; c.freeze(); a.Tl(d, c); N.free(d); Qo(a, !1) } }
    U.prototype.Ce = function (a, b, c, d) { var e = this.rc; e.h(a, b, c, d); null === this.Jb && (this.Jb = new ld); var f = this.Jb; f.reset(); this.jo(f, a, b, c, d); this.Jb = f; f.ps() || f.jp(e) };
    U.prototype.jo = function (a, b, c, d, e) { 1 !== this.Aa && a.scale(this.Aa); if (0 !== this.Ib) { var f = xc; this.locationSpot.tb() && (f = this.locationSpot); var g = I.alloc(); if (this.locationObject !== this) for (b = this.locationObject, c = b.naturalBounds, g.vi(c.x, c.y, c.width, c.height, f), null !== b.Jb && b.Jb.Ga(g), g.offset(-b.measuredBounds.x, -b.measuredBounds.y), f = b.panel; null !== f && f !== this;)null !== f.Jb && f.Jb.Ga(g), g.offset(-f.measuredBounds.x, -f.measuredBounds.y), f = f.panel; else g.vi(b, c, d, e, f); a.rotate(this.Ib, g.x, g.y); I.free(g) } };
    U.prototype.move = function (a, b) { !0 === b ? this.location = a : this.position = a }; U.prototype.moveTo = function (a, b, c) { a = I.allocAt(a, b); this.move(a, c); I.free(a) }; U.prototype.isVisible = function () { if (!this.visible) return !1; var a = this.layer; if (null !== a) { if (!a.visible) return !1; a = a.diagram; if (null !== a && a.animationManager.bv(this)) return !0 } a = this.containingGroup; return null === a || a.isSubGraphExpanded && a.isVisible() ? !0 : !1 };
    U.prototype.Vb = function (a) { var b = this.diagram; a ? (this.D(4), this.Sb(), null !== b && b.ze.add(this)) : (this.D(8), this.Cj()); this.jh(); null !== b && (b.Xa(), b.N()) }; U.prototype.fb = function (a) { if (this.name === a) return this; var b = this.Nh; null === b && (this.Nh = b = new ob); if (null !== b.I(a)) return b.I(a); var c = W.prototype.fb.call(this, a); if (null !== c) return b.set(a, c), c; b.set(a, null); return null };
    function To(a, b, c, d) { void 0 === d && (d = new I); c = c.Jc() ? xc : c; var e = b.naturalBounds; d.h(e.width * c.x + c.offsetX, e.height * c.y + c.offsetY); if (null === b || b === a) return d; b.transform.Ga(d); for (b = b.panel; null !== b && b !== a;)b.transform.Ga(d), b = b.panel; null !== a.Jb && a.Jb.Ga(d); d.offset(-a.rc.x, -a.rc.y); return d } U.prototype.ji = function (a) { void 0 === a && (a = new N); return a.assign(this.actualBounds) }; U.prototype.Va = function () { this.measure(Infinity, Infinity); this.arrange() };
    function bn(a) { var b = I.alloc(), c = a.locationObject, d = c.naturalBounds, e = c instanceof Bf ? c.strokeWidth : 0; b.vi(0, 0, d.width + e, d.height + e, a.locationSpot); if (c !== a) for (b.offset(-e / 2, -e / 2), c.transform.Ga(b), c = c.panel; null !== c && c !== a;)c.transform.Ga(b), c = c.panel; null !== a.Jb && a.Jb.Ga(b); b.offset(-a.rc.x, -a.rc.y); return b }
    function Fj(a, b) { var c = a.ly; isNaN(a.Xf) && (a.Xf = qn(a)); var d = a.Xf; var e = 2 * d; if (!a.isShadowed) return c.h(b.x - 1 - d, b.y - 1 - d, b.width + 2 + e, b.height + 2 + e), c; d = b.x; e = b.y; var f = b.width; b = b.height; var g = a.shadowBlur; a = a.shadowOffset; f += g; b += g; d -= g / 2; e -= g / 2; 0 < a.x ? f += a.x : (d += a.x, f -= a.x); 0 < a.y ? b += a.y : (e += a.y, b -= a.y); c.h(d - 1, e - 1, f + 2, b + 2); return c }
    U.prototype.arrange = function () { if (!1 === tj(this)) Ro(this); else { kl(this, !1); var a = this.Gb, b = N.alloc(); b.assign(a); var c = hg(this); this.Zg(0, 0, this.rc.width, this.rc.height); var d = this.position; So(this, d, this.location); a.ka(); a.x = d.x; a.y = d.y; a.freeze(); this.Tl(b, a); b.C(a) ? this.wd(c) : !this.Uc() || K.B(b.width, a.width) && K.B(b.height, a.height) || 0 <= this.ow && this.D(16); N.free(b); Qo(this, !1) } }; t = U.prototype;
    t.Tl = function (a, b) { var c = this.diagram; if (null !== c) { var d = !1; if (!1 === c.Wi && a.w()) { var e = N.alloc(); e.assign(c.documentBounds); e.yv(c.padding); a.x > e.x && a.y > e.y && a.right < e.right && a.bottom < e.bottom && b.x > e.x && b.y > e.y && b.right < e.right && b.bottom < e.bottom && (d = !0); N.free(e) } 0 !== (this.G & 65536) !== !0 && a.C(b) || Jj(this, d, c); c.N(); !a.w() && !b.w() || Ub(a, b) || (this instanceof V && !c.undoManager.isUndoingRedoing && this.vd(), this.jh()) } };
    t.sv = function (a, b, c) { if (this.kh() || !a.w()) return !1; var d = this.diagram; null !== d && Uo(this, d, a, b, c); this.pa = a; this.G &= -2097153; !this.ge.w() || null !== d && !d.animationManager.isTicking && d.undoManager.isUndoingRedoing || (d = this.ge, this.ge = new I(d.x + (a.x - b), d.y + (a.y - c)), this.g("location", d, this.ge.copy())); !1 === uj(this) && !1 === tj(this) && (Po(this), Ro(this)); return !0 }; function Uo(a, b, c, d, e) { null === b || a instanceof te || (b = b.animationManager, b.zb && Gh(b, a, new I(d, e), c)) }
    t.jm = function (a, b, c) { var d = this.ge, e = this.pa; if (c) { if (d.x === a && d.y === b) return; uj(this) || tj(this) ? e.h(NaN, NaN) : e.h(e.x + a - d.x, e.y + b - d.y); d.h(a, b) } else { if (e.x === a && e.y === b) return; d.h(d.x + a - e.x, d.y + b - e.y); e.h(a, b) } Po(this) }; t.tv = function () { this.G &= -2097153; Po(this) }; function So(a, b, c) { var d = bn(a), e = a.diagram, f = c.w(), g = b.w(); f && g ? 0 !== (a.G & 2097152) ? Vo(a, b, c, e, d) : Wo(a, b, c, e, d) : f ? Vo(a, b, c, e, d) : g && Wo(a, b, c, e, d); a.G |= 2097152; I.free(d); a.Pk() }
    function Vo(a, b, c, d, e) { var f = b.x, g = b.y; b.h(c.x - e.x, c.y - e.y); null !== d && (c = d.animationManager, (d = c.defaultAnimation.isAnimating) || !c.zb || a instanceof te || Gh(c, a, new I(f, g), b), d || b.x === f && b.y === g || a.g("position", new I(f, g), b.copy())) } function Wo(a, b, c, d, e) { var f = c.copy(); c.h(b.x + e.x, b.y + e.y); c.C(f) || null === d || a.g("location", f, c.copy()) }
    function Jj(a, b, c) { ml(a, !1); a instanceof V && Bk(c, a); a.layer.isTemporary || b || c.Xa(); b = a.Gb; var d = c.viewportBounds; d.w() ? hg(a) ? (Xb(b, d) || a.wd(!1), a.updateAdornments()) : a.isVisible() && b.Tc(d) ? (a.wd(!0), a.updateAdornments()) : a.Sb() : c.pd = !0 } t.oi = function () { return !0 }; t.Uc = function () { return !0 }; t.kh = function () { return !1 }; t.Qe = function () { return !0 };
    function Xo(a, b, c, d) {
        b.constructor === a.constructor || Yo || (Yo = !0, ya('Should not change the class of the Part when changing category from "' + c + '" to "' + d + '"'), ya("  Old class: " + Ha(a.constructor) + ", new class: " + Ha(b.constructor) + ", part: " + a.toString())); a.Cj(); var e = a.data; c = a.layerName; var f = a.isSelected, g = a.isHighlighted, h = !0, k = !0, l = !1; a instanceof V && (h = a.isTreeLeaf, k = a.isTreeExpanded, l = a.wasTreeExpanded); b.xf(a); b.cloneProtected(a); a.Qf = d; a.v(); a.N(); b = a.diagram; d = !0; null !== b && (d = b.skipsUndoManager,
            b.skipsUndoManager = !0); a.ub = e; a.G = f ? a.G | 4096 : a.G & -4097; a.G = g ? a.G | 524288 : a.G & -524289; a instanceof V && (a.U = h ? a.U | 4 : a.U & -5, a.U = k ? a.U | 1 : a.U & -2, a.U = l ? a.U | 2 : a.U & -3); null !== e && a.Ma(); e = a.layerName; e !== c && (a.Eg = c, a.layerName = e); null !== b && (b.skipsUndoManager = d); a.Uc() && a.D(64)
    } U.prototype.canCopy = function () { if (!this.copyable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowCopy) return !1; a = a.diagram; return null === a ? !0 : a.allowCopy ? !0 : !1 };
    U.prototype.canDelete = function () { if (!this.deletable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowDelete) return !1; a = a.diagram; return null === a ? !0 : a.allowDelete ? !0 : !1 }; U.prototype.canEdit = function () { if (!this.textEditable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowTextEdit) return !1; a = a.diagram; return null === a ? !0 : a.allowTextEdit ? !0 : !1 };
    U.prototype.canGroup = function () { if (!this.groupable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowGroup) return !1; a = a.diagram; return null === a ? !0 : a.allowGroup ? !0 : !1 }; U.prototype.canMove = function () { if (!this.movable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowMove) return !1; a = a.diagram; return null === a ? !0 : a.allowMove ? !0 : !1 };
    U.prototype.canReshape = function () { if (!this.reshapable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowReshape) return !1; a = a.diagram; return null === a ? !0 : a.allowReshape ? !0 : !1 }; U.prototype.canResize = function () { if (!this.resizable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowResize) return !1; a = a.diagram; return null === a ? !0 : a.allowResize ? !0 : !1 };
    U.prototype.canRotate = function () { if (!this.rotatable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowRotate) return !1; a = a.diagram; return null === a ? !0 : a.allowRotate ? !0 : !1 }; U.prototype.canSelect = function () { if (!this.selectable) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowSelect) return !1; a = a.diagram; return null === a ? !0 : a.allowSelect ? !0 : !1 }; function uj(a) { return 0 !== (a.G & 32768) } function Qo(a, b) { a.G = b ? a.G | 32768 : a.G & -32769 } function ml(a, b) { a.G = b ? a.G | 65536 : a.G & -65537 }
    function hg(a) { return 0 !== (a.G & 131072) } t = U.prototype; t.wd = function (a) { this.G = a ? this.G | 131072 : this.G & -131073 }; function Zo(a, b) { a.G = b ? a.G | 1048576 : a.G & -1048577 } t.jh = function () { var a = this.containingGroup; null !== a && (a.v(), null !== a.placeholder && a.placeholder.v(), a.vd()) }; t.N = function () { var a = this.diagram; null !== a && !tj(this) && !uj(this) && this.isVisible() && this.Gb.w() && a.N(Fj(this, this.Gb)) };
    t.v = function () { W.prototype.v.call(this); var a = this.diagram; null !== a && (a.ze.add(this), this instanceof V && null !== this.labeledLink && Jl(this.labeledLink), a.Wb(!0)) }; t.Po = function (a) { a || (a = this.vh, null !== a && $o(a, this)) }; t.Qo = function (a) { a || (a = this.vh, null !== a && ap(a, this)) }; t.Jj = function () { var a = this.data; if (null !== a) { var b = this.diagram; null !== b && (b = b.model, null !== b && b.$l(a)) } }; t.gz = function () { return bp(this, this) };
    function bp(a, b) { var c = b.containingGroup; return null !== c ? 1 + bp(a, c) : b instanceof V && (b = b.labeledLink, null !== b) ? bp(a, b) : 0 } t.kz = function () { return cp(this, this) }; function cp(a, b) { var c = b.containingGroup; return null !== c || b instanceof V && (c = b.labeledLink, null !== c) ? cp(a, c) : b } t.Md = function (a) { return a instanceof pf ? dp(this, this, a) : !1 }; function dp(a, b, c) { if (b === c || null === c) return !1; var d = b.containingGroup; return null === d || d !== c && !dp(a, d, c) ? b instanceof V && (b = b.labeledLink, null !== b) ? dp(a, b, c) : !1 : !0 }
    t.jx = function (a) { if (null === a) return null; if (this === a) return this.containingGroup; for (var b = this; null !== b;) { b instanceof pf && Zo(b, !0); if (b instanceof V) { var c = b.labeledLink; null !== c && (b = c) } b = b.containingGroup } c = null; for (b = a; null !== b;) { if (0 !== (b.G & 1048576)) { c = b; break } b instanceof V && (a = b.labeledLink, null !== a && (b = a)); b = b.containingGroup } for (b = this; null !== b;)b instanceof pf && Zo(b, !1), b instanceof V && (a = b.labeledLink, null !== a && (b = a)), b = b.containingGroup; return c };
    U.prototype.canLayout = function () { if (!this.isLayoutPositioned || !this.isVisible()) return !1; var a = this.layer; return null !== a && a.isTemporary || this instanceof V && this.isLinkLabel ? !1 : !0 };
    U.prototype.D = function (a) { void 0 === a && (a = 16777215); if (this.isLayoutPositioned && 0 !== (a & this.layoutConditions)) { var b = this.layer; null !== b && b.isTemporary || this instanceof V && this.isLinkLabel ? b = !1 : (b = this.diagram, b = null !== b && b.undoManager.isUndoingRedoing ? !1 : !0) } else b = !1; if (b) if (b = this.vh, null !== b) { var c = b.layout; null !== c ? c.D() : b.D(a) } else a = this.diagram, null !== a && (a = a.layout, null !== a && a.D()) }; function Kj(a) { if (!a.isVisible()) return !1; a = a.layer; return null !== a && a.isTemporary ? !1 : !0 }
    function Sk(a, b, c, d, e, f) {
        void 0 === f && (f = null); if (!(a.contains(b) || null !== f && !f(b) || b instanceof te)) if (a.add(b), b instanceof V) { if (c && b instanceof pf) for (var g = b.memberParts; g.next();)Sk(a, g.value, c, d, e, f); if (!1 !== e) for (g = b.linksConnected; g.next();) { var h = g.value; if (!a.contains(h)) { var k = h.fromNode, l = h.toNode; k = null === k || a.contains(k); l = null === l || a.contains(l); (e ? k && l : k || l) && Sk(a, h, c, d, e, f) } } if (1 < d) for (b = b.Ku(); b.next();)Sk(a, b.value, c, d - 1, e, f) } else if (b instanceof T) for (b = b.labelNodes; b.next();)Sk(a,
            b.value, c, d, e, f)
    }
    ma.Object.defineProperties(U.prototype, {
        key: { get: function () { var a = this.diagram; if (null !== a) return a.model.va(this.data) } }, adornments: { get: function () { return null === this.Nf ? Ya : this.Nf.iteratorValues } }, layer: { get: function () { return this.aj } }, diagram: { get: function () { var a = this.aj; return null === a || void 0 === a ? null : a.diagram } }, layerName: {
            get: function () { return this.Eg }, set: function (a) {
                var b =
                    this.Eg; if (b !== a) { var c = this.diagram; if (null === c || null !== c.Oj(a) && !c.partManager.addsToTemporaryLayer) if (this.Eg = a, null !== c && c.Xa(), this.g("layerName", b, a), b = this.layer, null !== b && b.name !== a && (c = b.diagram, null !== c && (a = c.Oj(a), null !== a && a !== b))) { var d = b.mc(-1, this, !0); 0 <= d && c.cb(fe, "parts", b, this, null, d, !0); d = a.li(99999999, this, !0); b.visible !== a.visible && this.Vb(a.visible); 0 <= d && c.cb(ee, "parts", a, null, this, !0, d); d = this.layerChanged; if (null !== d) { var e = c.aa; c.aa = !0; d(this, b, a); c.aa = e } } }
            }
        }, layerChanged: {
            get: function () { return null !== this.O ? this.O.sq : null }, set: function (a) { var b = this.layerChanged; b !== a && (No(this), this.O.sq = a, this.g("layerChanged", b, a)) }
        }, zOrder: { get: function () { return this.wo }, set: function (a) { var b = this.wo; if (b !== a) { this.wo = a; var c = this.layer; null !== c && ii(c, -1, this); this.g("zOrder", b, a); a = this.diagram; null !== a && a.N() } } }, locationObject: {
            get: function () {
                if (null === this.jf) {
                    var a = this.locationObjectName; "" !== a ? (a = this.fb(a),
                        null !== a ? this.jf = a : this.jf = this) : this instanceof te ? this.type !== W.Link && null !== this.placeholder ? this.jf = this.placeholder : this.jf = this : this.jf = this
                } return this.jf.visible ? this.jf : this
            }
        }, minLocation: { get: function () { return null !== this.O ? this.O.Iq : qb }, set: function (a) { var b = this.minLocation; b.C(a) || (a = a.J(), No(this), this.O.Iq = a, this.g("minLocation", b, a)) } }, maxLocation: {
            get: function () { return null !== this.O ? this.O.Dq : rb }, set: function (a) {
                var b = this.maxLocation;
                b.C(a) || (a = a.J(), No(this), this.O.Dq = a, this.g("maxLocation", b, a))
            }
        }, locationObjectName: { get: function () { return null !== this.O ? this.O.zq : "" }, set: function (a) { var b = this.locationObjectName; b !== a && (No(this), this.O.zq = a, this.jf = null, this.v(), this.g("locationObjectName", b, a)) } }, locationSpot: {
            get: function () { return null !== this.O ? this.O.Aq : sc }, set: function (a) {
                var b = this.locationSpot; b.C(a) || (a = a.J(), No(this), this.O.Aq = a, this.v(), this.g("locationSpot",
                    b, a))
            }
        }, location: {
            get: function () { return this.ge }, set: function (a) {
                var b = a.x, c = a.y, d = this.ge; if (!(d.x === b || isNaN(d.x) && isNaN(b)) || !(d.y === c || isNaN(d.y) && isNaN(c))) {
                    a = a.copy(); b = a; if (this.kh()) b = !1; else { this.ge = b; this.G |= 2097152; if (!1 === tj(this) && (Po(this), c = this.pa, c.w())) { var e = this.diagram; if (null === e || e.animationManager.isTicking || !e.undoManager.isUndoingRedoing) { var f = c.copy(); c.h(c.x + (b.x - d.x), c.y + (b.y - d.y)); Uo(this, e, c, f.x, f.y); this.g("position", f, c.copy()) } } b = !0 } b &&
                        this.g("location", d, a.copy())
                }
            }
        }, category: {
            get: function () { return this.Qf }, set: function (a) {
                var b = this.Qf; if (b !== a) {
                    null !== this.svg && (this.svg.remove(), this.Uh = !0); var c = this.diagram, d = this.data, e = null; if (null !== c && null !== d && !(this instanceof te)) { var f = c.model.undoManager; f.isEnabled && !f.isUndoingRedoing && (e = this.clone(), e.P.addAll(this.P)) } this.Qf = a; this.g("category", b, a); null === c || null === d || this instanceof te ? this instanceof te && (e = this.adornedPart, null !== e && (a = e.Nf,
                        null !== a && a.remove(b), e.Wg(this.category, this))) : (f = c.model, f.undoManager.isUndoingRedoing || (this.kh() ? (c.partManager.setLinkCategoryForData(d, a), c = c.partManager.findLinkTemplateForCategory(a), null !== c && (jg(c), c = c.copy(), null !== c && Xo(this, c, b, a))) : (null !== f && f.gm(d, a), c = ep(c.partManager, d, a), null !== c && (jg(c), c = c.copy(), null === c || c instanceof T || (d = this.location.copy(), Xo(this, c, b, a), this.location.w() || (this.location = d)))), null !== e && (b = this.clone(), b.P.addAll(this.P), this.g("self", e, b))))
                }
            }
        }, self: {
            get: function () { return this }, set: function (a) { Xo(this, a, this.category, a.category) }
        }, copyable: { get: function () { return 0 !== (this.G & 1) }, set: function (a) { var b = 0 !== (this.G & 1); b !== a && (this.G ^= 1, this.g("copyable", b, a)) } }, deletable: { get: function () { return 0 !== (this.G & 2) }, set: function (a) { var b = 0 !== (this.G & 2); b !== a && (this.G ^= 2, this.g("deletable", b, a)) } }, textEditable: {
            get: function () { return 0 !== (this.G & 4) }, set: function (a) {
                var b =
                    0 !== (this.G & 4); b !== a && (this.G ^= 4, this.g("textEditable", b, a), this.Sb())
            }
        }, groupable: { get: function () { return 0 !== (this.G & 8) }, set: function (a) { var b = 0 !== (this.G & 8); b !== a && (this.G ^= 8, this.g("groupable", b, a)) } }, movable: { get: function () { return 0 !== (this.G & 16) }, set: function (a) { var b = 0 !== (this.G & 16); b !== a && (this.G ^= 16, this.g("movable", b, a)) } }, selectionAdorned: {
            get: function () { return 0 !== (this.G & 32) }, set: function (a) {
                var b =
                    0 !== (this.G & 32); b !== a && (this.G ^= 32, this.g("selectionAdorned", b, a), this.Sb())
            }
        }, isInDocumentBounds: { get: function () { return 0 !== (this.G & 64) }, set: function (a) { var b = 0 !== (this.G & 64); if (b !== a) { this.G ^= 64; var c = this.diagram; null !== c && c.Xa(); this.g("isInDocumentBounds", b, a) } } }, isLayoutPositioned: { get: function () { return 0 !== (this.G & 128) }, set: function (a) { var b = 0 !== (this.G & 128); b !== a && (this.G ^= 128, this.g("isLayoutPositioned", b, a), this.D(a ? 4 : 8)) } }, selectable: {
            get: function () { return 0 !== (this.G & 256) }, set: function (a) { var b = 0 !== (this.G & 256); b !== a && (this.G ^= 256, this.g("selectable", b, a), this.Sb()) }
        }, reshapable: { get: function () { return 0 !== (this.G & 512) }, set: function (a) { var b = 0 !== (this.G & 512); b !== a && (this.G ^= 512, this.g("reshapable", b, a), this.Sb()) } }, resizable: { get: function () { return 0 !== (this.G & 1024) }, set: function (a) { var b = 0 !== (this.G & 1024); b !== a && (this.G ^= 1024, this.g("resizable", b, a), this.Sb()) } },
        rotatable: { get: function () { return 0 !== (this.G & 2048) }, set: function (a) { var b = 0 !== (this.G & 2048); b !== a && (this.G ^= 2048, this.g("rotatable", b, a), this.Sb()) } }, isSelected: {
            get: function () { return 0 !== (this.G & 4096) }, set: function (a) {
                var b = 0 !== (this.G & 4096); if (b !== a) {
                    var c = this.diagram; if (!a || this.canSelect() && !(null !== c && c.selection.count >= c.maxSelectionCount)) {
                        this.G ^= 4096; var d = !1; if (null !== c) {
                            d = c.skipsUndoManager; c.skipsUndoManager = !0; var e = c.selection;
                            e.ka(); a ? e.add(this) : e.remove(this); e.freeze()
                        } this.g("isSelected", b, a); this.Sb(); a = this.selectionChanged; null !== a && a(this); null !== c && (c.Wb(), c.skipsUndoManager = d)
                    }
                }
            }
        }, isHighlighted: { get: function () { return 0 !== (this.G & 524288) }, set: function (a) { var b = 0 !== (this.G & 524288); if (b !== a) { this.G ^= 524288; var c = this.diagram; null !== c && (c = c.highlighteds, c.ka(), a ? c.add(this) : c.remove(this), c.freeze()); this.g("isHighlighted", b, a); this.N(); a = this.highlightedChanged; null !== a && a(this) } } },
        isShadowed: { get: function () { return 0 !== (this.G & 8192) }, set: function (a) { var b = 0 !== (this.G & 8192); b !== a && (this.G ^= 8192, this.g("isShadowed", b, a), this.N()) } }, isAnimated: { get: function () { return 0 !== (this.G & 262144) }, set: function (a) { var b = 0 !== (this.G & 262144); b !== a && (this.G ^= 262144, this.g("isAnimated", b, a)) } }, highlightedChanged: {
            get: function () { return null !== this.O ? this.O.hq : null }, set: function (a) {
                var b = this.highlightedChanged;
                b !== a && (No(this), this.O.hq = a, this.g("highlightedChanged", b, a))
            }
        }, selectionObjectName: { get: function () { return null !== this.O ? this.O.mr : "" }, set: function (a) { var b = this.selectionObjectName; b !== a && (No(this), this.O.mr = a, this.Og = null, this.g("selectionObjectName", b, a)) } }, selectionAdornmentTemplate: {
            get: function () { return null !== this.O ? this.O.kr : null }, set: function (a) {
                var b = this.selectionAdornmentTemplate; b !== a && (No(this), this.O.kr = a, this.g("selectionAdornmentTemplate",
                    b, a))
            }
        }, selectionObject: { get: function () { if (null === this.Og) { var a = this.selectionObjectName; null !== a && "" !== a ? (a = this.fb(a), null !== a ? this.Og = a : this.Og = this) : this instanceof T ? (a = this.path, null !== a ? this.Og = a : this.Og = this) : this.Og = this } return this.Og } }, selectionChanged: { get: function () { return null !== this.O ? this.O.lr : null }, set: function (a) { var b = this.selectionChanged; b !== a && (No(this), this.O.lr = a, this.g("selectionChanged", b, a)) } }, resizeAdornmentTemplate: {
            get: function () { return null !== this.O ? this.O.Zq : null }, set: function (a) { var b = this.resizeAdornmentTemplate; b !== a && (No(this), this.O.Zq = a, this.g("resizeAdornmentTemplate", b, a)) }
        }, resizeObjectName: { get: function () { return null !== this.O ? this.O.ar : "" }, set: function (a) { var b = this.resizeObjectName; b !== a && (No(this), this.O.ar = a, this.g("resizeObjectName", b, a)) } }, resizeObject: {
            get: function () {
                var a = this.resizeObjectName; return "" !== a && (a = this.fb(a),
                    null !== a) ? a : this
            }
        }, resizeCellSize: { get: function () { return null !== this.O ? this.O.$q : Lb }, set: function (a) { var b = this.resizeCellSize; b.C(a) || (a = a.J(), No(this), this.O.$q = a, this.g("resizeCellSize", b, a)) } }, rotateAdornmentTemplate: { get: function () { return null !== this.O ? this.O.br : null }, set: function (a) { var b = this.rotateAdornmentTemplate; b !== a && (No(this), this.O.br = a, this.g("rotateAdornmentTemplate", b, a)) } }, rotateObjectName: {
            get: function () { return null !== this.O ? this.O.cr : "" }, set: function (a) { var b = this.rotateObjectName; b !== a && (No(this), this.O.cr = a, this.g("rotateObjectName", b, a)) }
        }, rotateObject: { get: function () { var a = this.rotateObjectName; return "" !== a && (a = this.fb(a), null !== a) ? a : this } }, rotationSpot: { get: function () { return null !== this.O ? this.O.er : Sc }, set: function (a) { var b = this.rotationSpot; b.C(a) || (a = a.J(), No(this), this.O.er = a, this.g("rotationSpot", b, a)) } }, text: {
            get: function () { return this.bc }, set: function (a) { var b = this.bc; b !== a && (this.bc = a, this.g("text", b, a)) }
        }, containingGroup: {
            get: function () { return this.vh }, set: function (a) {
                if (this.Uc()) {
                    var b = this.vh; if (b !== a) {
                        null === a || this !== a && !a.Md(this) || (this === a && C("Cannot make a Group a member of itself: " + this.toString()), C("Cannot make a Group indirectly contain itself: " + this.toString() + " already contains " + a.toString())); this.D(2); var c = this.diagram; null !== b ? ap(b, this) :
                            this instanceof pf && null !== c && c.Yh.remove(this); this.vh = a; null !== a ? $o(a, this) : this instanceof pf && null !== c && c.Yh.add(this); this.D(1); if (null !== c && c.aa) { var d = this.data, e = c.model; if (null !== d && e.Uj()) { var f = e.va(null !== a ? a.data : null); e.gp(d, f) } } d = this.containingGroupChanged; null !== d && (e = !0, null !== c && (e = c.aa, c.aa = !0), d(this, b, a), null !== c && (c.aa = e)); if (this instanceof pf) for (c = new H, Sk(c, this, !0, 0, !0), c = c.iterator; c.next();)if (d = c.value, d instanceof V) for (d = d.linksConnected; d.next();)fp(d.value); if (this instanceof
                                V) { for (c = this.linksConnected; c.next();)fp(c.value); c = this.labeledLink; null !== c && fp(c) } this.g("containingGroup", b, a); null !== a && (b = a.layer, null !== b && ii(b, -1, a))
                    }
                } else C("cannot set the Part.containingGroup of a Link or Adornment")
            }
        }, containingGroupChanged: { get: function () { return null !== this.O ? this.O.Ep : null }, set: function (a) { var b = this.containingGroupChanged; b !== a && (No(this), this.O.Ep = a, this.g("containingGroupChanged", b, a)) } }, isTopLevel: {
            get: function () {
                return null !==
                    this.containingGroup || this instanceof V && null !== this.labeledLink ? !1 : !0
            }
        }, layoutConditions: { get: function () { return null !== this.O ? this.O.vq : 127 }, set: function (a) { var b = this.layoutConditions; b !== a && (No(this), this.O.vq = a, this.g("layoutConditions", b, a)) } }, dragComputation: { get: function () { return null !== this.O ? this.O.Op : null }, set: function (a) { var b = this.dragComputation; b !== a && (No(this), this.O.Op = a, this.g("dragComputation", b, a)) } }, shadowOffset: {
            get: function () { return null !== this.O ? this.O.rr : sb }, set: function (a) { var b = this.shadowOffset; b.C(a) || (a = a.J(), No(this), this.O.rr = a, this.N(), this.g("shadowOffset", b, a)) }
        }, shadowColor: { get: function () { return null !== this.O ? this.O.qr : "gray" }, set: function (a) { var b = this.shadowColor; b !== a && (No(this), this.O.qr = a, this.N(), this.g("shadowColor", b, a)) } }, shadowBlur: {
            get: function () { return null !== this.O ? this.O.Pg : 4 }, set: function (a) {
                var b = this.shadowBlur;
                b !== a && (No(this), this.O.Pg = a, this.N(), this.g("shadowBlur", b, a))
            }
        }
    }); U.prototype.invalidateLayout = U.prototype.D; U.prototype.findCommonContainingGroup = U.prototype.jx; U.prototype.isMemberOf = U.prototype.Md; U.prototype.findTopLevelPart = U.prototype.kz; U.prototype.findSubGraphLevel = U.prototype.gz; U.prototype.ensureBounds = U.prototype.Va; U.prototype.getDocumentBounds = U.prototype.ji; U.prototype.findObject = U.prototype.fb; U.prototype.moveTo = U.prototype.moveTo; U.prototype.invalidateAdornments = U.prototype.Sb;
    U.prototype.clearAdornments = U.prototype.Cj; U.prototype.removeAdornment = U.prototype.Gf; U.prototype.addAdornment = U.prototype.Wg; U.prototype.findAdornment = U.prototype.Nj; U.prototype.updateTargetBindings = U.prototype.Ma; var Yo = !1; U.className = "Part"; U.LayoutNone = 0; U.LayoutAdded = 1; U.LayoutRemoved = 2; U.LayoutShown = 4; U.LayoutHidden = 8; U.LayoutNodeSized = 16; U.LayoutGroupLayout = 32; U.LayoutNodeReplaced = 64; U.LayoutStandard = 127; U.LayoutAll = 16777215;
    function te(a, b) { void 0 === a || a instanceof Vl || "string" === typeof a ? U.call(this, a) : (U.call(this), a && (b = a)); this.G &= -257; this.Eg = "Adornment"; this.qe = null; this.tw = 0; this.Nw = !1; this.j = this.Za = null; b && Object.assign(this, b) } la(te, U); te.prototype.toString = function () { var a = this.adornedPart; return "Adornment(" + this.category + ")" + (null !== a ? a.toString() : "") }; te.prototype.updateRelationshipsFromData = function () { };
    te.prototype.Ql = function (a) { var b = this.adornedObject, c = null === b || void 0 === b ? void 0 : b.part; if (c instanceof T && b instanceof Bf) { b = c.path; c.Ql(a); a = b.geometry; c = this.P.s; b = c.length; for (var d = 0; d < b; d++) { var e = c[d]; e.isPanelMain && e instanceof Bf && (e.ta = a) } } }; te.prototype.oi = function () { var a = this.qe; if (null === a) return !0; a = a.part; return null === a || !tj(a) }; te.prototype.Uc = function () { return !1 };
    te.prototype.ck = function (a, b, c, d, e, f, g) { if (a === ee && "elements" === b) if (e instanceof ig) null === this.Za && (this.Za = e); else { if (e instanceof W) { var h = e.Il(function (a) { return a instanceof ig }); h instanceof ig && null === this.Za && (this.Za = h) } } else a === fe && "elements" === b && null !== this.Za && (d === this.Za ? this.Za = null : d instanceof W && this.Za.ng(d) && (this.Za = null)); U.prototype.ck.call(this, a, b, c, d, e, f, g) }; te.prototype.updateAdornments = function () { }; te.prototype.Jj = function () { };
    ma.Object.defineProperties(te.prototype, {
        placeholder: { get: function () { return this.Za } }, adornedObject: { get: function () { return this.qe }, set: function (a) { var b = this.adornedPart, c = null; null !== a && (c = a.part); null === b || null !== a && b === c || b.Gf(this.category); this.qe = a; null !== c && c.Wg(this.category, this) } }, adornedPart: { get: function () { var a = this.qe; return null !== a ? a.part : null } }, containingGroup: {
            get: function () { return null }
        }
    }); te.className = "Adornment"; function gp() { this.jc = !1; this.Nr = this.ej = this.xq = this.wq = null; this.rp = qc; this.Yq = hp; this.Cr = this.gj = this.Gq = this.Fq = null } gp.prototype.copy = function () { var a = new gp; a.wq = this.wq; a.xq = this.xq; a.ej = this.ej; a.Nr = this.Nr; a.rp = this.rp.J(); a.Yq = this.Yq; a.Fq = this.Fq; a.Gq = this.Gq; a.gj = this.gj; a.Cr = this.Cr; return a };
    function V(a, b) { void 0 === a || a instanceof Vl || "string" === typeof a ? U.call(this, a) : (U.call(this), a && (b = a)); this.U = 13; this.ya = null; this.kb = new F; this.tc = this.Xe = this.Tk = null; this.$h = !1; b && Object.assign(this, b) } la(V, U); V.prototype.cloneProtected = function (a) { U.prototype.cloneProtected.call(this, a); a.U = this.U; null !== this.ya && (a.ya = this.ya.jc ? this.ya : this.ya.copy()) }; t = V.prototype; t.xf = function (a) { U.prototype.xf.call(this, a); a.vd(); a.Xe = this.Xe; a.tc = null };
    function ip(a) { null === a.ya ? a.ya = new gp : a.ya.jc && (a.ya = a.ya.copy()) } t.oh = function () { U.prototype.oh.call(this); null !== this.ya && (this.ya.jc = !0) }; function jp(a, b) { null !== b && (null === a.Xe && (a.Xe = new H), a.Xe.add(b)) } function kp(a, b, c, d) { if (null === b || null === a.Xe) return null; for (var e = a.Xe.iterator; e.next();) { var f = e.value; if (f.ws === a && f.Zu === b && f.Kx === c && f.Lx === d || f.ws === b && f.Zu === a && f.Kx === d && f.Lx === c) return f } return null }
    t.Dz = function (a, b, c) { if (void 0 === b || null === b) b = ""; if (void 0 === c || null === c) c = ""; a = kp(this, a, b, c); null !== a && a.Nl() }; t.ck = function (a, b, c, d, e, f, g) { a === ee && "elements" === b ? this.tc = null : a === fe && "elements" === b && (this.tc = null); U.prototype.ck.call(this, a, b, c, d, e, f, g) }; t.vd = function (a) { for (var b = this.linksConnected; b.next();) { var c = b.value; void 0 !== a && a.contains(c) || (lp(c.fromPort), lp(c.toPort), c.bb()) } };
    function nl(a, b) { for (a = a.linksConnected; a.next();) { var c = a.value; if (c.fromPort === b || c.toPort === b) lp(c.fromPort), lp(c.toPort), c.bb() } } function lp(a) { if (null !== a) { var b = a.Vn; null !== b && b.Nl(); a = a.part; b = a.containingGroup; null === b || a.isVisible() || lp(b.port) } } t.oi = function () { return !0 }; V.prototype.getAvoidableRect = function (a) { a.set(this.actualBounds); a.yo(this.avoidableMargin); return a }; V.prototype.findVisibleNode = function () { for (var a = this; null !== a && !a.isVisible();)a = a.containingGroup; return a };
    V.prototype.isVisible = function () { if (!U.prototype.isVisible.call(this)) return !1; var a = !0, b = wi, c = this.diagram; null !== c && (a = c.isTreePathToChildren, b = c.treeCollapsePolicy); if (b === wi) { if (a = this.lg(), null !== a && !a.isTreeExpanded) return !1 } else if (b === Hk) { if (a = a ? this.Hu() : this.Iu(), 0 < a.count && a.all(function (a) { return !a.isTreeExpanded })) return !1 } else if (b === Ik && (a = a ? this.Hu() : this.Iu(), 0 < a.count && a.any(function (a) { return !a.isTreeExpanded }))) return !1; a = this.labeledLink; return null !== a ? a.isVisible() : !0 };
    V.prototype.Vb = function (a) { U.prototype.Vb.call(this, a); for (var b = this.linksConnected; b.next();) { var c = b.value; if (a && null !== this.containingGroup) { var d = c.No(this); null === d || d.Md(this.containingGroup) || c.bb() } c.Vb(a) } }; V.prototype.kx = function () { var a = new H, b = new H; mp(this, this, a, b); return b.iterator };
    function mp(a, b, c, d) { if (null !== b && !c.has(b)) { c.add(b); var e = !0, f = a.diagram; null !== f && (e = f.isTreePathToChildren); b.linksConnected.each(function (f) { f.isTreeLink ? (e ? f.fromNode === b : f.toNode === b) && mp(a, e ? f.toNode : f.fromNode, c, d) : d.add(f) }) } } V.prototype.findLinksConnected = function (a) { void 0 === a && (a = null); if (null === a) return this.kb.iterator; var b = new $a(this.kb), c = this; b.predicate = function (b) { return b.fromNode === c && b.fromPortId === a || b.toNode === c && b.toPortId === a }; return b }; t = V.prototype;
    t.Io = function (a) { void 0 === a && (a = null); var b = new $a(this.kb), c = this; b.predicate = function (b) { return b.fromNode !== c ? !1 : null === a ? !0 : b.fromPortId === a }; return b }; t.Kd = function (a) { void 0 === a && (a = null); var b = new $a(this.kb), c = this; b.predicate = function (b) { return b.toNode !== c ? !1 : null === a ? !0 : b.toPortId === a }; return b };
    t.Gu = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.kb.iterator; d.next();) { var e = d.value; if (e.fromNode === this) { if (null === a || e.fromPortId === a) e = e.toNode, null !== b ? b.add(e) : null !== c && c !== e ? (b = new H, b.add(c), b.add(e)) : c = e } else e.toNode !== this || null !== a && e.toPortId !== a || (e = e.fromNode, null !== b ? b.add(e) : null !== c && c !== e ? (b = new H, b.add(c), b.add(e)) : c = e) } return null !== b ? b.iterator : null !== c ? new Za(c) : Ya };
    t.Iu = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.kb.iterator; d.next();) { var e = d.value; e.fromNode !== this || null !== a && e.fromPortId !== a || (e = e.toNode, null !== b ? b.add(e) : null !== c && c !== e ? (b = new H, b.add(c), b.add(e)) : c = e) } return null !== b ? b.iterator : null !== c ? new Za(c) : Ya };
    t.Hu = function (a) { void 0 === a && (a = null); for (var b = null, c = null, d = this.kb.iterator; d.next();) { var e = d.value; e.toNode !== this || null !== a && e.toPortId !== a || (e = e.fromNode, null !== b ? b.add(e) : null !== c && c !== e ? (b = new H, b.add(c), b.add(e)) : c = e) } return null !== b ? b.iterator : null !== c ? new Za(c) : Ya };
    t.bz = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); var d = new $a(this.kb), e = this; d.predicate = function (d) { return (d.fromNode !== e || d.toNode !== a || null !== b && d.fromPortId !== b || null !== c && d.toPortId !== c) && (d.fromNode !== a || d.toNode !== e || null !== c && d.fromPortId !== c || null !== b && d.toPortId !== b) ? !1 : !0 }; return d };
    t.cz = function (a, b, c) { void 0 === b && (b = null); void 0 === c && (c = null); var d = new $a(this.kb), e = this; d.predicate = function (d) { return d.fromNode !== e || d.toNode !== a || null !== b && d.fromPortId !== b || null !== c && d.toPortId !== c ? !1 : !0 }; return d };
    function np(a, b, c) { lp(c); var d = a.kb.contains(b); d || a.kb.add(b); if (!d || b.fromNode === b.toNode) { var e = a.linkConnected; if (null !== e) { var f = !0, g = a.diagram; null !== g && (f = g.aa, g.aa = !0); e(a, b, c); null !== g && (g.aa = f) } } !d && b.isTreeLink && (c = b.fromNode, b = b.toNode, null !== c && null !== b && c !== b && (d = !0, a = a.diagram, null !== a && (d = a.isTreePathToChildren), e = d ? b : c, f = d ? c : b, e.$h || (e.$h = f), !f.isTreeLeaf || null !== a && a.undoManager.isUndoingRedoing || (d ? c === f && (f.isTreeLeaf = !1) : b === f && (f.isTreeLeaf = !1)))) }
    function op(a, b, c) { lp(c); var d = a.kb.remove(b), e = null; if (d || b.toNode === b.fromNode) { var f = a.linkDisconnected; e = a.diagram; if (null !== f) { var g = !0; null !== e && (g = e.aa, e.aa = !0); f(a, b, c); null !== e && (e.aa = g) } } d && b.isTreeLink && (c = !0, null !== e && (c = e.isTreePathToChildren), a = c ? b.toNode : b.fromNode, b = c ? b.fromNode : b.toNode, null !== a && (a.$h = !1), null === b || b.isTreeLeaf || (0 === b.kb.count ? (b.$h = null, null !== e && e.undoManager.isUndoingRedoing || (b.isTreeLeaf = !0)) : Gk(b))) }
    function Gk(a) { a.$h = !1; if (0 !== a.kb.count) { var b = !0, c = a.diagram; if (null === c || !c.undoManager.isUndoingRedoing) { null !== c && (b = c.isTreePathToChildren); for (c = a.kb.iterator; c.next();) { var d = c.value; if (d.isTreeLink) if (b) { if (d.fromNode === a) { a.isTreeLeaf = !1; return } } else if (d.toNode === a) { a.isTreeLeaf = !1; return } } a.isTreeLeaf = !0 } } } V.prototype.updateRelationshipsFromData = function () { var a = this.diagram; null !== a && a.partManager.updateRelationshipsFromData(this) }; t = V.prototype;
    t.Po = function (a) { U.prototype.Po.call(this, a); a || (Gk(this), a = this.Tk, null !== a && pp(a, this)) }; t.Qo = function (a) { U.prototype.Qo.call(this, a); a || (a = this.Tk, null !== a && null !== a.qd && (a.qd.remove(this), a.v())) };
    t.Jj = function () { if (0 < this.kb.count) { var a = this.diagram; if (null !== a) for (var b = null !== a.commandHandler ? a.commandHandler.deletesConnectedLinks : !0, c = this.kb.copy().iterator; c.next();) { var d = c.value; b ? a.remove(d) : (d.fromNode === this && (d.fromNode = null), d.toNode === this && (d.toNode = null)) } } this.labeledLink = null; U.prototype.Jj.call(this) }; t.fs = function (a) { if (null === this.tc) { if ("" === a && !1 === this.Oe) return this; qp(this) } var b = this.tc.I(a); return null !== b || "" !== a && (b = this.tc.I(""), null !== b) ? b : this };
    function qp(a) { null === a.tc ? a.tc = new ob : a.tc.clear(); a.nm(a, function (a, c) { am(a, c) }); 0 === a.tc.count && a.tc.add("", a) } function am(a, b) { var c = b.portId; null !== c && null !== a.tc && a.tc.add(c, b) } function $l(a, b, c) { var d = b.portId; if (null !== d && (null !== a.tc && a.tc.remove(d), b = a.diagram, null !== b && c)) { c = null; for (a = a.findLinksConnected(d); a.next();)d = a.value, null === c && (c = Ea()), c.push(d); if (null !== c) { for (a = 0; a < c.length; a++)b.remove(c[a]); Ga(c) } } }
    t.Fz = function (a) { if (null === a || a === this) return !1; var b = !0, c = this.diagram; null !== c && (b = c.isTreePathToChildren); c = this; if (b) for (; c !== a;) { b = null; for (var d = c.kb.iterator; d.next();) { var e = d.value; if (e.isTreeLink && (b = e.fromNode, b !== c && b !== this)) break } if (b === this || null === b || b === c) return !1; c = b } else for (; c !== a;) { b = null; for (d = c.kb.iterator; d.next() && (e = d.value, !e.isTreeLink || (b = e.toNode, b === c || b === this));); if (b === this || null === b || b === c) return !1; c = b } return !0 };
    t.oz = function () { var a = !0, b = this.diagram; null !== b && (a = b.isTreePathToChildren); b = this; if (a) for (; ;) { a = null; for (var c = b.kb.iterator; c.next();) { var d = c.value; if (d.isTreeLink && (a = d.fromNode, a !== b && a !== this)) break } if (a === this) return this; if (null === a || a === b) return b; b = a } else for (; ;) { a = null; for (c = b.kb.iterator; c.next() && (d = c.value, !d.isTreeLink || (a = d.toNode, a === b || a === this));); if (a === this) return this; if (null === a || a === b) return b; b = a } };
    t.Zy = function (a) { if (null === a) return null; if (this === a) return this; for (var b = this; null !== b;)Zo(b, !0), b = b.lg(); var c = null; for (b = a; null !== b;) { if (0 !== (b.G & 1048576)) { c = b; break } b = b.lg() } for (b = this; null !== b;)Zo(b, !1), b = b.lg(); return c }; t.fh = function () { var a = !0, b = this.diagram; null !== b && (a = b.isTreePathToChildren); b = this.kb.iterator; if (a) for (; b.next();) { if (a = b.value, a.isTreeLink && a.fromNode !== this) return a } else for (; b.next();)if (a = b.value, a.isTreeLink && a.toNode !== this) return a; return null };
    t.lg = function () { var a = this.$h; if (null === a) return null; if (a instanceof V) return a; a = this.diagram; a = null !== a ? a.isTreePathToChildren : !0; for (var b = this.kb.iterator; b.next();) { var c = b.value; if (c.isTreeLink && (c = a ? c.fromNode : c.toNode, c !== this)) return this.$h = c } return this.$h = null }; t.mz = function () { function a(b, d) { d.add(b); var c = b.fh(); null !== c && (d.add(c), b = b.lg(), null !== b && a(b, d)) } var b = new H; a(this, b); return b }; t.lz = function () { return rp(this, this) }; function rp(a, b) { b = b.lg(); return null === b ? 0 : 1 + rp(a, b) }
    t.Ko = function () { var a = !0, b = this.diagram; null !== b && (a = b.isTreePathToChildren); b = new $a(this.kb); var c = this; b.predicate = a ? function (a) { return a.isTreeLink && a.fromNode === c ? !0 : !1 } : function (a) { return a.isTreeLink && a.toNode === c ? !0 : !1 }; return b };
    t.Ku = function () { var a = !0, b = this.diagram; null !== b && (a = b.isTreePathToChildren); var c = b = null, d = this.kb.iterator; if (a) for (; d.next();)a = d.value, a.isTreeLink && a.fromNode === this && (a = a.toNode, null !== b ? b.add(a) : null !== c && c !== a ? (b = new F, b.add(c), b.add(a)) : c = a); else for (; d.next();)a = d.value, a.isTreeLink && a.toNode === this && (a = a.fromNode, null !== b ? b.add(a) : null !== c && c !== a ? (b = new F, b.add(c), b.add(a)) : c = a); return null !== b ? b.iterator : null !== c ? new Za(c) : Ya };
    t.nz = function (a) { void 0 === a && (a = Infinity); var b = new H; Sk(b, this, !1, a, !0); return b }; V.prototype.collapseTree = function (a) { void 0 === a && (a = 1); 1 > a && (a = 1); var b = this.diagram; if (null !== b && !b.Pe) { b.Pe = !0; var c = new H; c.add(this); sp(this, c, b.isTreePathToChildren, a, b, this, b.treeCollapsePolicy === wi); b.Pe = !1 } }; function sp(a, b, c, d, e, f, g) { if (1 < d) for (var h = c ? a.Io() : a.Kd(); h.next();) { var k = h.value; k.isTreeLink && (k = k.No(a), null === k || k === a || b.contains(k) || (b.add(k), sp(k, b, c, d - 1, e, f, g))) } else tp(a, b, c, e, f, g) }
    function tp(a, b, c, d, e, f) { for (var g = e === a ? !0 : a.isTreeExpanded, h = c ? a.Io() : a.Kd(); h.next();) { var k = h.value; if (k.isTreeLink && (k = k.No(a), null !== k && k !== a)) { var l = b.contains(k); l || b.add(k); g && (f && d.yj(k, e), k.jh(), k.Vb(!1)); k.isTreeExpanded && (k.wasTreeExpanded = k.isTreeExpanded, l || tp(k, b, c, d, e, f)) } } a.isTreeExpanded = !1 }
    V.prototype.expandTree = function (a) { void 0 === a && (a = 2); 2 > a && (a = 2); var b = this.diagram; if (null !== b && !b.Pe) { b.Pe = !0; var c = new H; c.add(this); up(this, c, b.isTreePathToChildren, a, b, this, b.treeCollapsePolicy === wi); b.Pe = !1 } };
    function up(a, b, c, d, e, f, g) { for (var h = f === a ? !1 : a.isTreeExpanded, k = c ? a.Io() : a.Kd(); k.next();) { var l = k.value; l.isTreeLink && (h || l.xd || l.bb(), l = l.No(a), null !== l && l !== a && !b.contains(l) && (b.add(l), h || (l.Vb(!0), l.jh(), g && e.Aj(l, f)), 2 < d || l.wasTreeExpanded)) && (l.wasTreeExpanded = !1, up(l, b, c, d - 1, e, f, g)) } a.isTreeExpanded = !0 }
    ma.Object.defineProperties(V.prototype, {
        portSpreading: { get: function () { return null !== this.ya ? this.ya.Yq : hp }, set: function (a) { var b = this.portSpreading; b !== a && (ip(this), this.ya.Yq = a, this.g("portSpreading", b, a), a = this.diagram, null !== a && a.undoManager.isUndoingRedoing || this.vd()) } }, avoidable: {
            get: function () { return 0 !== (this.U & 8) }, set: function (a) {
                var b = 0 !== (this.U & 8); if (b !== a) {
                    this.U ^= 8; var c = this.diagram; null !== c && Bk(c, this); this.g("avoidable",
                        b, a)
                }
            }
        }, avoidableMargin: { get: function () { return null !== this.ya ? this.ya.rp : qc }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.avoidableMargin; if (!b.C(a)) { a = a.J(); ip(this); this.ya.rp = a; var c = this.diagram; null !== c && Bk(c, this); this.g("avoidableMargin", b, a) } } }, linksConnected: { get: function () { return this.kb.iterator } }, linkConnected: {
            get: function () { return null !== this.ya ? this.ya.wq : null }, set: function (a) {
                var b =
                    this.linkConnected; b !== a && (ip(this), this.ya.wq = a, this.g("linkConnected", b, a))
            }
        }, linkDisconnected: { get: function () { return null !== this.ya ? this.ya.xq : null }, set: function (a) { var b = this.linkDisconnected; b !== a && (ip(this), this.ya.xq = a, this.g("linkDisconnected", b, a)) } }, linkValidation: { get: function () { return null !== this.ya ? this.ya.ej : null }, set: function (a) { var b = this.linkValidation; b !== a && (ip(this), this.ya.ej = a, this.g("linkValidation", b, a)) } }, isLinkLabel: {
            get: function () { return null !== this.Tk }
        }, labeledLink: {
            get: function () { return this.Tk }, set: function (a) {
                var b = this.Tk; if (b !== a) {
                    var c = this.diagram, d = this.data; if (null !== b) { null !== b.qd && (b.qd.remove(this), b.v()); if (null !== c && null !== d && !c.undoManager.isUndoingRedoing) { var e = b.data, f = c.model; if (null !== e && f.ni()) { var g = f.va(d); void 0 !== g && f.Mx(e, g) } } this.containingGroup = null } this.Tk = a; null !== a && (pp(a, this), null === c || null === d || c.undoManager.isUndoingRedoing || (e = a.data,
                        c = c.model, null !== e && c.ni() && (d = c.va(d), void 0 !== d && c.wu(e, d))), this.containingGroup = a.containingGroup); Jl(this); this.g("labeledLink", b, a)
                }
            }
        }, port: { get: function () { return this.fs("") } }, ports: { get: function () { null === this.tc && qp(this); return this.tc.iteratorValues } }, isTreeExpanded: {
            get: function () { return 0 !== (this.U & 1) }, set: function (a) {
                var b = 0 !== (this.U & 1); if (b !== a) {
                    this.U ^= 1; var c = this.diagram; this.g("isTreeExpanded",
                        b, a); b = this.treeExpandedChanged; if (null !== b) { var d = !0; null !== c && (d = c.aa, c.aa = !0); b(this); null !== c && (c.aa = d) } null !== c && c.undoManager.isUndoingRedoing ? this.Vb(a) : a ? this.expandTree() : this.collapseTree()
                }
            }
        }, wasTreeExpanded: { get: function () { return 0 !== (this.U & 2) }, set: function (a) { var b = 0 !== (this.U & 2); b !== a && (this.U ^= 2, this.g("wasTreeExpanded", b, a)) } }, treeExpandedChanged: {
            get: function () { return null !== this.ya ? this.ya.Nr : null }, set: function (a) {
                var b =
                    this.treeExpandedChanged; b !== a && (ip(this), this.ya.Nr = a, this.g("treeExpandedChanged", b, a))
            }
        }, isTreeLeaf: { get: function () { return 0 !== (this.U & 4) }, set: function (a) { var b = 0 !== (this.U & 4); b !== a && (this.U ^= 4, this.g("isTreeLeaf", b, a)) } }
    }); V.prototype.expandTree = V.prototype.expandTree; V.prototype.collapseTree = V.prototype.collapseTree; V.prototype.findTreeParts = V.prototype.nz; V.prototype.findTreeChildrenNodes = V.prototype.Ku; V.prototype.findTreeChildrenLinks = V.prototype.Ko;
    V.prototype.findTreeLevel = V.prototype.lz; V.prototype.findTreeParentChain = V.prototype.mz; V.prototype.findTreeParentNode = V.prototype.lg; V.prototype.findTreeParentLink = V.prototype.fh; V.prototype.findCommonTreeParent = V.prototype.Zy; V.prototype.findTreeRoot = V.prototype.oz; V.prototype.isInTreeOf = V.prototype.Fz; V.prototype.findPort = V.prototype.fs; V.prototype.findLinksTo = V.prototype.cz; V.prototype.findLinksBetween = V.prototype.bz; V.prototype.findNodesInto = V.prototype.Hu; V.prototype.findNodesOutOf = V.prototype.Iu;
    V.prototype.findNodesConnected = V.prototype.Gu; V.prototype.findLinksInto = V.prototype.Kd; V.prototype.findLinksOutOf = V.prototype.Io; V.prototype.findExternalTreeLinksConnected = V.prototype.kx; V.prototype.invalidateConnectedLinks = V.prototype.vd; V.prototype.invalidateLinkBundle = V.prototype.Dz; var vp = new D(V, "SpreadingNone", 10), hp = new D(V, "SpreadingEvenly", 11), wp = new D(V, "SpreadingPacked", 12); V.className = "Node"; V.SpreadingNone = vp; V.SpreadingEvenly = hp; V.SpreadingPacked = wp;
    function pf(a, b) { void 0 === a || a instanceof Vl || "string" === typeof a ? V.call(this, a) : (V.call(this), a && (b = a)); this.U |= 4608; this.Cn = new H; this.Za = null; this.Oc = new ri; this.Oc.group = this; this.bl = new H; this.ib = this.Tp = null; b && Object.assign(this, b) } la(pf, V);
    pf.prototype.cloneProtected = function (a) { V.prototype.cloneProtected.call(this, a); this.U = this.U & -32769; var b = a.Il(function (a) { return a instanceof ig }); b instanceof ig ? a.Za = b : a.Za = null; null !== this.Oc ? (a.Oc = this.Oc.copy(), a.Oc.group = a) : (null !== a.Oc && (a.Oc.group = null), a.Oc = null); null !== this.ib && (a.ib = this.ib.copy()) }; t = pf.prototype;
    t.xf = function (a) { V.prototype.xf.call(this, a); var b = a.Pj(); for (a = a.memberParts; a.next();) { var c = a.value; c.v(); c.D(8); c.Cj(); if (c instanceof V) c.vd(b); else if (c instanceof T) for (c = c.labelNodes; c.next();)c.value.vd(b) } };
    t.ck = function (a, b, c, d, e, f, g) {
        if (a === ee && "elements" === b) if (e instanceof ig) null === this.Za ? this.Za = e : this.Za !== e && C("Cannot insert a second Placeholder into the visual tree of a Group."); else { if (e instanceof W) { var h = e.Il(function (a) { return a instanceof ig }); h instanceof ig && (null === this.Za ? this.Za = h : this.Za !== h && C("Cannot insert a second Placeholder into the visual tree of a Group.")) } } else a === fe && "elements" === b && null !== this.Za && (d === this.Za ? this.Za = null : d instanceof W && this.Za.ng(d) && (this.Za = null));
        V.prototype.ck.call(this, a, b, c, d, e, f, g)
    }; t.Tl = function (a, b) { V.prototype.Tl.call(this, a, b); this.isClipping && this.type !== W.Spot && this.isSubGraphExpanded && (a = this.resizeObject, a instanceof W && (a = a.sb()), null === this.ib && (this.ib = new N), a.ji(this.ib), a instanceof Bf && this.ib.yc(-a.strokeWidth, -a.strokeWidth)) }; t.Zg = function (a, b, c, d) { this.jf = this.Za; V.prototype.Zg.call(this, a, b, c, d) };
    t.Va = function () { var a = this; a.isSubGraphExpanded && this.memberParts.each(function (b) { !a.computesBoundsIncludingLinks && b instanceof T || b.Va() }); V.prototype.Va.call(this) }; t.oi = function () { if (!V.prototype.oi.call(this)) return !1; for (var a = this.memberParts; a.next();) { var b = a.value; if (b instanceof V) { if (b.isVisible() && tj(b)) return !1 } else if (b instanceof T && b.isVisible() && tj(b) && b.fromNode !== this && b.toNode !== this) return !1 } return !0 };
    function $o(a, b) { if (a.Cn.add(b)) { b instanceof pf && a.bl.add(b); var c = a.memberAdded; if (null !== c) { var d = !0, e = a.diagram; null !== e && (d = e.aa, e.aa = !0); c(a, b); null !== e && (e.aa = d) } a.isVisible() && a.isSubGraphExpanded || b.Vb(!1) } b instanceof T && !a.computesBoundsIncludingLinks || (b = a.Za, null === b && (b = a), b.v()) }
    function ap(a, b) { if (a.Cn.remove(b)) { b instanceof pf && a.bl.remove(b); var c = a.memberRemoved; if (null !== c) { var d = !0, e = a.diagram; null !== e && (d = e.aa, e.aa = !0); c(a, b); null !== e && (e.aa = d) } a.isVisible() && a.isSubGraphExpanded || b.Vb(!0) } b instanceof T && !a.computesBoundsIncludingLinks || (b = a.Za, null === b && (b = a), b.v()) } t.Jj = function () { if (0 < this.Cn.count) { var a = this.diagram; if (null !== a) for (var b = this.Cn.copy().iterator; b.next();)a.remove(b.value) } V.prototype.Jj.call(this) };
    pf.prototype.canAddMembers = function (a) { var b = this.diagram; if (null === b) return !1; b = b.commandHandler; for (a = Uk(a).iterator; a.next();)if (!b.isValidMember(this, a.value)) return !1; return !0 }; pf.prototype.addMembers = function (a, b) { var c = this.diagram; if (null === c) return !1; c = c.commandHandler; var d = !0; for (a = Uk(a).iterator; a.next();) { var e = a.value; !b || c.isValidMember(this, e) ? e.containingGroup = this : d = !1 } return d };
    pf.prototype.canUngroup = function () { if (!this.ungroupable) return !1; var a = this.layer; if (null === a || !a.allowUngroup) return !1; a = a.diagram; return null === a || a.allowUngroup ? !0 : !1 }; t = pf.prototype;
    t.vd = function (a) { var b = 0 !== (this.U & 65536); V.prototype.vd.call(this, a); if (!b) for (0 !== (this.U & 65536) !== !0 && (this.U = this.U ^ 65536), b = this.Fu(); b.next();) { var c = b.value; if (void 0 === a || !a.contains(c)) { var d = c.fromNode; null !== d && d !== this && d.Md(this) && !d.isVisible() ? (lp(c.fromPort), lp(c.toPort), c.bb()) : (d = c.toNode, null !== d && d !== this && d.Md(this) && !d.isVisible() && (lp(c.fromPort), lp(c.toPort), c.bb())) } } };
    t.Fu = function () { var a = null !== this.diagram && this.diagram.Iw; if (a && null !== this.Tp) return this.Tp.iterator; var b = this.Pj(); b.add(this); for (var c = new H, d = b.iterator; d.next();) { var e = d.value; if (e instanceof V) for (e = e.linksConnected; e.next();) { var f = e.value; b.contains(f) || c.add(f) } } a && (this.Tp = c); return c.iterator };
    t.az = function () { var a = this.Pj(); a.add(this); for (var b = new H, c = a.iterator; c.next();) { var d = c.value; if (d instanceof V) for (d = d.linksConnected; d.next();) { var e = d.value, f = e.fromNode; null === f || a.contains(f) && f !== this || b.add(f); e = e.toNode; null === e || a.contains(e) && e !== this || b.add(e) } } return b.iterator }; t.$y = function () { function a(b, d) { null !== b && (d.add(b), a(b.containingGroup, d)) } var b = new H; a(this, b); return b }; t.Pj = function () { var a = new H; Sk(a, this, !0, 0, !0); a.remove(this); return a };
    t.Vb = function (a) { V.prototype.Vb.call(this, a); for (var b = this.memberParts; b.next();)b.value.Vb(a) }; pf.prototype.collapseSubGraph = function () { var a = this.diagram; if (null !== a && !a.Pe) { a.Pe = !0; var b = this.Pj(); xp(this, b, a, this); a.Pe = !1 } };
    function xp(a, b, c, d) { for (var e = a.memberParts; e.next();) { var f = e.value; f.Vb(!1); f instanceof pf && f.isSubGraphExpanded && (f.wasSubGraphExpanded = f.isSubGraphExpanded, xp(f, b, c, d)); if (f instanceof V) f.vd(b), c.yj(f, d); else if (f instanceof T) for (f = f.labelNodes; f.next();)f.value.vd(b) } a.isSubGraphExpanded = !1 } pf.prototype.expandSubGraph = function () { var a = this.diagram; if (null !== a && !a.Pe) { a.Pe = !0; var b = this.Pj(); yp(this, b, a, this); a.Pe = !1 } };
    function yp(a, b, c, d) { for (var e = a.memberParts; e.next();) { var f = e.value; f.Vb(!0); f instanceof pf && f.wasSubGraphExpanded && (f.wasSubGraphExpanded = !1, yp(f, b, c, d)); if (f instanceof V) f.vd(b), c.Aj(f, d); else if (f instanceof T) for (f = f.labelNodes; f.next();)f.value.vd(b) } a.isSubGraphExpanded = !0 }
    pf.prototype.move = function (a, b) {
        void 0 === b && (b = !1); var c = b ? this.location : this.position, d = c.x; c = c.y; var e = a.x, f = a.y; if (!(d === e || isNaN(d) && isNaN(e)) || !(c === f || isNaN(c) && isNaN(f))) {
            d = e - (isNaN(d) ? 0 : d); c = f - (isNaN(c) ? 0 : c); f = I.alloc(); V.prototype.move.call(this, a, b); a = new H; for (b = this.Pj().iterator; b.next();)if (e = b.value, e instanceof T && (e.suspendsRouting && a.add(e), e.xd || e.fromNode !== this && e.toNode !== this)) e.suspendsRouting = !0; for (b.reset(); b.next();)if (e = b.value, !(e.kh() || e instanceof V && e.isLinkLabel)) {
                var g =
                    e.position, h = e.location; g.w() ? (f.x = g.x + d, f.y = g.y + c, e.position = f) : h.w() && (f.x = h.x + d, f.y = h.y + c, e.location = f)
            } for (b.reset(); b.next();)if (e = b.value, e instanceof T && (e.suspendsRouting = a.contains(e), e.xd || e.fromNode !== this && e.toNode !== this)) g = e.position, f.x = g.x + d, f.y = g.y + c, f.w() ? e.move(f) : e.bb(), Rj(e) && e.bb(); I.free(f)
        }
    };
    ma.Object.defineProperties(pf.prototype, {
        placeholder: { get: function () { return this.Za } }, computesBoundsAfterDrag: { get: function () { return 0 !== (this.U & 2048) }, set: function (a) { var b = 0 !== (this.U & 2048); b !== a && (this.U ^= 2048, this.g("computesBoundsAfterDrag", b, a)) } }, computesBoundsIncludingLinks: {
            get: function () { return 0 !== (this.U & 4096) }, set: function (a) {
                var b = 0 !== (this.U & 4096); b !== a && (this.U ^= 4096, this.g("computesBoundsIncludingLinks",
                    b, a))
            }
        }, computesBoundsIncludingLocation: { get: function () { return 0 !== (this.U & 8192) }, set: function (a) { var b = 0 !== (this.U & 8192); b !== a && (this.U ^= 8192, this.g("computesBoundsIncludingLocation", b, a)) } }, handlesDragDropForMembers: { get: function () { return 0 !== (this.U & 16384) }, set: function (a) { var b = 0 !== (this.U & 16384); b !== a && (this.U ^= 16384, this.g("handlesDragDropForMembers", b, a)) } }, memberParts: { get: function () { return this.Cn.iterator } },
        layout: { get: function () { return this.Oc }, set: function (a) { var b = this.Oc; if (b !== a) { null !== a && null !== a.diagram && a.diagram.layout === a && C("A layout cannot be both the Diagram.layout and a Group.layout: " + a); null !== b && (b.diagram = null, b.group = null); this.Oc = a; var c = this.diagram; null !== a && (a.diagram = c, a.group = this); null !== c && (c.sh = !0); this.g("layout", b, a); null !== c && c.Wb() } } }, memberAdded: {
            get: function () { return null !== this.ya ? this.ya.Fq : null }, set: function (a) {
                var b =
                    this.memberAdded; b !== a && (ip(this), this.ya.Fq = a, this.g("memberAdded", b, a))
            }
        }, memberRemoved: { get: function () { return null !== this.ya ? this.ya.Gq : null }, set: function (a) { var b = this.memberRemoved; b !== a && (ip(this), this.ya.Gq = a, this.g("memberRemoved", b, a)) } }, memberValidation: { get: function () { return null !== this.ya ? this.ya.gj : null }, set: function (a) { var b = this.memberValidation; b !== a && (ip(this), this.ya.gj = a, this.g("memberValidation", b, a)) } }, ungroupable: {
            get: function () { return 0 !== (this.U & 256) }, set: function (a) { var b = 0 !== (this.U & 256); b !== a && (this.U ^= 256, this.g("ungroupable", b, a)) }
        }, isSubGraphExpanded: {
            get: function () { return 0 !== (this.U & 512) }, set: function (a) {
                var b = 0 !== (this.U & 512); if (b !== a) {
                    this.U ^= 512; var c = this.diagram; this.g("isSubGraphExpanded", b, a); b = this.subGraphExpandedChanged; if (null !== b) { var d = !0; null !== c && (d = c.aa, c.aa = !0); b(this); null !== c && (c.aa = d) } null !== c && c.undoManager.isUndoingRedoing ? (null !==
                        this.Za && this.Za.v(), this.memberParts.each(function (a) { a.updateAdornments() })) : a ? this.expandSubGraph() : this.collapseSubGraph()
                }
            }
        }, wasSubGraphExpanded: { get: function () { return 0 !== (this.U & 1024) }, set: function (a) { var b = 0 !== (this.U & 1024); b !== a && (this.U ^= 1024, this.g("wasSubGraphExpanded", b, a)) } }, subGraphExpandedChanged: {
            get: function () { return null !== this.ya ? this.ya.Cr : null }, set: function (a) {
                var b = this.subGraphExpandedChanged; b !== a && (ip(this), this.ya.Cr =
                    a, this.g("subGraphExpandedChanged", b, a))
            }
        }, mh: { get: function () { return 0 !== (this.U & 32768) }, set: function (a) { 0 !== (this.U & 32768) !== a && (this.U ^= 32768) } }
    }); pf.prototype.expandSubGraph = pf.prototype.expandSubGraph; pf.prototype.collapseSubGraph = pf.prototype.collapseSubGraph; pf.prototype.findSubGraphParts = pf.prototype.Pj; pf.prototype.findContainingGroupChain = pf.prototype.$y; pf.prototype.findExternalNodesConnected = pf.prototype.az; pf.prototype.findExternalLinksConnected = pf.prototype.Fu;
    pf.prototype.ensureBounds = pf.prototype.Va; pf.className = "Group"; function ig(a) { Y.call(this); this.yb = pc; this.fo = new N(NaN, NaN, NaN, NaN); a && Object.assign(this, a) } la(ig, Y); ig.prototype.cloneProtected = function (a) { Y.prototype.cloneProtected.call(this, a); a.yb = this.yb.J(); a.fo = this.fo.copy() }; ig.prototype.ah = function (a) { if (null === this.background && null === this.areaBackground) return !1; var b = this.naturalBounds; return Zb(0, 0, b.width, b.height, a.x, a.y) };
    ig.prototype.Sl = function () {
        var a = this.part; null !== a && (a instanceof pf || a instanceof te) || C("Placeholder is not inside a Group or Adornment."); if (a instanceof pf) {
            var b = this.computeBorder(this.fo), c = this.minSize, d = this.ac; Pb(d, (isFinite(c.width) ? Math.max(c.width, b.width) : b.width) || 0, (isFinite(c.height) ? Math.max(c.height, b.height) : b.height) || 0); this.Ce(0, 0, d.width, d.height); d = a.memberParts; for (c = !1; d.next();)if (d.value.isVisible()) { c = !0; break } d = a.diagram; !c || null === d || d.animationManager.Tu(a) || isNaN(b.x) ||
                isNaN(b.y) || (c = I.alloc(), c.ui(b, a.locationSpot), c.C(a.location) || (a.location = c), I.free(c))
        } else {
            b = this.ac; c = this.yb; d = c.left + c.right; var e = c.top + c.bottom, f = a.adornedObject; a.angle = f.ii(); var g = 0; f instanceof Bf && (g = f.strokeWidth); var h = f.Af(), k = f.naturalBounds, l = (k.width + g) * h; g = (k.height + g) * h; a.type !== W.Link && (h = f.ja("Selection" === a.category ? sc : a.locationSpot, I.alloc()), a.location = h, I.free(h)); isNaN(l) || isNaN(g) ? (a = f.ja(sc, I.alloc()), l = N.allocAt(a.x, a.y, 0, 0), l.Ue(f.ja(Bc, a)), l.Ue(f.ja(uc, a)), l.Ue(f.ja(zc,
                a)), Pb(b, l.width + d || 0, l.height + e || 0), this.Ce(-c.left, -c.top, b.width, b.height), I.free(a), N.free(l)) : (Pb(b, l + d || 0, g + e || 0), this.Ce(-c.left, -c.top, b.width, b.height))
        }
    }; ig.prototype.Zg = function (a, b, c, d) { this.actualBounds.h(a, b, c, d) };
    ig.prototype.computeBorder = function (a) {
        var b = this.part, c = b.diagram; if (null !== c && b instanceof pf && !b.layer.isTemporary && b.computesBoundsAfterDrag && this.fo.w()) { var d = c.toolManager.findTool("Dragging"); if (d === c.currentTool && (c = d.computeBorder(b, this.fo, a), null !== c)) return c } c = N.alloc(); d = this.computeMemberBounds(c); var e = this.yb; b instanceof pf && !b.isSubGraphExpanded ? a.h(d.x - e.left, d.y - e.top, 0, 0) : a.h(d.x - e.left, d.y - e.top, Math.max(d.width + e.left + e.right, 0), Math.max(d.height + e.top + e.bottom, 0)); N.free(c);
        b instanceof pf && b.computesBoundsIncludingLocation && b.location.w() && a.Ue(b.location); return a
    };
    ig.prototype.computeMemberBounds = function (a) {
        if (!(this.part instanceof pf)) return a.h(0, 0, 0, 0), a; for (var b = this.part, c = Infinity, d = Infinity, e = -Infinity, f = -Infinity, g = b.memberParts; g.next();) { var h = g.value; if (h.isVisible()) { if (h instanceof T) { if (!b.computesBoundsIncludingLinks) continue; if (sj(h)) continue; if (h.fromNode === b || h.toNode === b) continue } h = h.actualBounds; h.left < c && (c = h.left); h.top < d && (d = h.top); h.right > e && (e = h.right); h.bottom > f && (f = h.bottom) } } isFinite(c) && isFinite(d) ? a.h(c, d, e - c, f - d) : (b = b.location,
            a.h(b.x, b.y, 0, 0)); return a
    }; ma.Object.defineProperties(ig.prototype, { padding: { get: function () { return this.yb }, set: function (a) { "number" === typeof a && (a = new hc(a)); var b = this.yb; b.C(a) || (this.yb = a = a.J(), this.g("padding", b, a), this.v()) } } }); ig.className = "Placeholder";
    function T(a) { U.call(this, W.Link); this.Pa = 8; this.af = null; this.bf = ""; this.rf = this.cn = null; this.sf = ""; this.qo = null; this.kk = Zf; this.Lm = 0; this.Nm = Zf; this.Om = NaN; this.oj = zp; this.lo = .5; this.qd = null; this.Kb = (new F).freeze(); this.nf = this.Hg = null; this.hl = new N; this.ta = new P; this.u = this.m = this.Pf = this.Tf = null; this.nu = new I; this.Wp = this.Fw = this.Ew = null; this.ot = NaN; this.ba = null; this.j = []; a && Object.assign(this, a) } la(T, U);
    T.prototype.cloneProtected = function (a) { U.prototype.cloneProtected.call(this, a); a.Pa = this.Pa & -113; a.bf = this.bf; a.cn = this.cn; a.sf = this.sf; a.qo = this.qo; a.kk = this.kk; a.Lm = this.Lm; a.Nm = this.Nm; a.Om = this.Om; a.oj = this.oj; a.lo = this.lo; null !== this.ba && (a.ba = this.ba.copy()) }; t = T.prototype; t.xf = function (a) { U.prototype.xf.call(this, a); this.bf = a.bf; this.sf = a.sf; a.Hg = null; a.bb(); a.Pf = this.Pf; var b = a.fromPort; null !== b && lp(b); a = a.toPort; null !== a && lp(a) };
    t.rb = function (a) { a.classType === T ? 2 === (a.value & 2) ? this.routing = a : a === bg || a === Yf || a === Xf ? this.curve = a : a === Ap || a === Bp || a === Cp ? this.adjusting = a : a !== zp && a !== Zf && C("Unknown Link enum value for a Link property: " + a) : U.prototype.rb.call(this, a) }; t.Sc = function () { null === this.ba && (this.ba = new dl) }; t.oi = function () { var a = this.fromNode; if (null !== a) { var b = a.findVisibleNode(); null !== b && (a = b); if (tj(a) || uj(a)) return !1 } a = this.toNode; return null !== a && (b = a.findVisibleNode(), null !== b && (a = b), tj(a) || uj(a)) ? !1 : !0 }; t.sv = function () { return !1 };
    t.tv = function () { }; t.Uc = function () { return !1 }; T.prototype.computeAngle = function (a, b, c) { return T.computeAngle(b, c) }; T.computeAngle = function (a, b) { switch (a) { default: case Zf: a = 0; break; case en: a = b; break; case Xm: a = b + 90; break; case Zm: a = b - 90; break; case Dp: a = b + 180; break; case fn: a = K.Wo(b); 90 < a && 270 > a && (a -= 180); break; case Ym: a = K.Wo(b + 90); 90 < a && 270 > a && (a -= 180); break; case $m: a = K.Wo(b - 90); 90 < a && 270 > a && (a -= 180); break; case an: a = K.Wo(b); if (45 < a && 135 > a || 225 < a && 315 > a) return 0; 90 < a && 270 > a && (a -= 180) }return K.Wo(a) };
    function fp(a) { var b = a.fromNode, c = a.toNode, d = null; null !== b ? d = null !== c ? b.jx(c) : b.containingGroup : null !== c ? d = c.containingGroup : d = null; b = d; c = a.vh; if (c !== b) { null !== c && ap(c, a); a.vh = b; null !== b && $o(b, a); var e = a.containingGroupChanged; if (null !== e) { var f = !0, g = a.diagram; null !== g && (f = g.aa, g.aa = !0); e(a, c, b); null !== g && (g.aa = f) } !a.xd || a.Ew !== c && a.Fw !== c || a.bb() } if (a.isLabeledLink) for (a = a.labelNodes; a.next();)a.value.containingGroup = d } t = T.prototype;
    t.jh = function () { var a = this.containingGroup; null !== a && this.fromNode !== a && this.toNode !== a && a.computesBoundsIncludingLinks && U.prototype.jh.call(this) }; t.No = function (a) { var b = this.fromNode; return a === b ? this.toNode : b }; t.uz = function (a) { var b = this.fromPort; return a === b ? this.toPort : b }; function pp(a, b) { null === a.qd && (a.qd = new H); a.qd.add(b); a.v() }
    t.Po = function (a) { U.prototype.Po.call(this, a); Ep(this) && this.Ro(this.actualBounds); if (!a) { a = this.af; var b = null; null !== a && (b = this.fromPort, np(a, this, b)); var c = this.rf; if (null !== c) { var d = this.toPort; c === a && d === b || np(c, this, d) } Fp(this) } }; t.Qo = function (a) { U.prototype.Qo.call(this, a); Ep(this) && this.Ro(this.actualBounds); if (!a) { a = this.af; var b = null; null !== a && (b = this.fromPort, op(a, this, b)); var c = this.rf; if (null !== c) { var d = this.toPort; c === a && d === b || op(c, this, d) } Gp(this) } };
    t.Jj = function () { this.xd = !0; if (null !== this.qd) { var a = this.diagram; if (null !== a) for (var b = this.qd.copy().iterator; b.next();)a.remove(b.value) } null !== this.data && (a = this.diagram, null !== a && a.partManager.removeDataForLink(this)) }; T.prototype.updateRelationshipsFromData = function () { if (null !== this.data) { var a = this.diagram; null !== a && a.partManager.updateRelationshipsFromData(this) } };
    T.prototype.move = function (a, b) { var c = b ? this.location : this.position, d = c.x; isNaN(d) && (d = 0); var e = c.y; isNaN(e) && (e = 0); d = a.x - d; e = a.y - e; !0 === b ? U.prototype.move.call(this, a, !1) : (a = I.allocAt(c.x + d, c.y + e), U.prototype.move.call(this, a, !1), I.free(a)); bf(this, d, e); for (a = this.labelNodes; a.next();)b = a.value, c = b.position, b.moveTo(c.x + d, c.y + e) };
    T.prototype.canRelinkFrom = function () { if (!this.relinkableFrom) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowRelink) return !1; a = a.diagram; return null === a || a.allowRelink ? !0 : !1 }; T.prototype.canRelinkTo = function () { if (!this.relinkableTo) return !1; var a = this.layer; if (null === a) return !0; if (!a.allowRelink) return !1; a = a.diagram; return null === a || a.allowRelink ? !0 : !1 };
    T.prototype.computeMidPoint = function (a) {
        var b = this.pointsCount; if (0 === b) return a.assign(tb), a; if (1 === b) return a.assign(this.i(0)), a; if (2 === b) { var c = this.i(0), d = this.i(1); a.h((c.x + d.x) / 2, (c.y + d.y) / 2); return a } if (this.isOrthogonal && (15 <= this.computeCorner() || this.computeCurve() === bg)) return this.ta.Lu(.5, a), a.add(this.i(0)), c = this.ta.figures.first(), a.offset(-c.startX, -c.startY), a; if (this.computeCurve() === bg) {
            if (3 === b) {
                c = this.i(0); d = this.i(1); var e = this.i(2); K.Vw(c.x, c.y, d.x, d.y, d.x, d.y, e.x, e.y, a);
                return a
            } d = (b - 1) / 3 | 0; c = 3 * (d / 2 | 0); if (1 === d % 2) { d = this.i(c); e = this.i(c + 1); var f = this.i(c + 2); c = this.i(c + 3); K.Vw(d.x, d.y, e.x, e.y, f.x, f.y, c.x, c.y, a) } else a.assign(this.i(c)); return a
        } var g = this.flattenedLengths; c = this.flattenedTotalLength; for (e = f = d = 0; d < c / 2 && f < b;) { e = g[f]; if (d + e > c / 2) break; d += e; f++ } b = this.i(f); f = this.i(f + 1); 1 > Math.abs(b.x - f.x) ? b.y > f.y ? a.h(b.x, b.y - (c / 2 - d)) : a.h(b.x, b.y + (c / 2 - d)) : 1 > Math.abs(b.y - f.y) ? b.x > f.x ? a.h(b.x - (c / 2 - d), b.y) : a.h(b.x + (c / 2 - d), b.y) : (c = (c / 2 - d) / e, a.h(b.x + c * (f.x - b.x), b.y + c * (f.y -
            b.y))); return a
    };
    T.prototype.computeMidAngle = function () {
        var a = this.pointsCount; if (2 > a) return NaN; if (2 === a) return this.i(0).$a(this.i(1)); if (this.isOrthogonal && (15 <= this.computeCorner() || this.computeCurve() === bg)) return this.ta.px(.5); if (this.computeCurve() === bg && 4 <= a) { var b = (a - 1) / 3 | 0, c = 3 * (b / 2 | 0); if (1 === b % 2) { c = Math.floor(c); a = this.i(c); b = this.i(c + 1); var d = this.i(c + 2); c = this.i(c + 3); return K.Fy(a.x, a.y, b.x, b.y, d.x, d.y, c.x, c.y) } if (0 < c && c + 1 < a) return this.i(c - 1).$a(this.i(c + 1)) } b = this.flattenedLengths; d = this.flattenedTotalLength;
        var e = 0; c = 0; for (var f; e < d / 2 && c < a;) { f = b[c]; if (e + f > d / 2) break; e += f; c++ } b = this.i(c); d = this.i(c + 1); if (1 > Math.abs(b.x - d.x) && 1 > Math.abs(b.y - d.y)) { if (0 < c && c + 2 < a) return this.i(c - 1).$a(this.i(c + 2)) } else { if (1 > Math.abs(b.x - d.x)) return b.y > d.y ? 270 : 90; if (1 > Math.abs(b.y - d.y)) return b.x > d.x ? 180 : 0 } return b.$a(d)
    }; t = T.prototype; t.i = function (a) { return this.Kb.s[a] }; t.Yc = function (a, b) { this.Kb.Xc(a, b) }; t.M = function (a, b, c) { this.Kb.Xc(a, new I(b, c)) }; t.Cz = function (a, b) { this.Kb.Eb(a, b) };
    t.l = function (a, b, c) { this.Kb.Eb(a, new I(b, c)) }; t.je = function (a) { this.Kb.add(a) }; t.vf = function (a, b) { this.Kb.add(new I(a, b)) }; t.kv = function (a) { this.Kb.nb(a) }; t.Dj = function () { this.Kb.clear() };
    function bf(a, b, c) {
        if (0 !== b || 0 !== c) if (0 === a.pointsCount) a.defaultFromPoint && a.defaultFromPoint.offset(b, c), a.defaultToPoint && a.defaultToPoint.offset(b, c); else {
            for (var d = a.xd, e = new F, f = a.Kb.iterator; f.next();) { var g = f.value; e.add((new I(g.x + b, g.y + c)).freeze()) } e.freeze(); f = a.Kb; a.Kb = e; g = a.diagram; isNaN(b) || isNaN(c) || null !== g && g.animationManager.zb ? a.v() : (a.ge.h(a.ge.x + b, a.ge.y + c), a.pa.h(a.pa.x + b, a.pa.y + c), Jl(a)); d ? Hp(a) : (a.defaultFromPoint && (a.defaultFromPoint = a.i(0)), a.defaultToPoint && (a.defaultToPoint =
                a.i(a.pointsCount - 1))); null !== g && g.animationManager.zb && (b = g.animationManager, a.nf = e, b.kl.add(a)); a.g("points", f, e)
        }
    } t.ph = function () { null === this.Hg && (this.Hg = this.Kb, this.Kb = this.Kb.copy()) };
    t.wf = function () {
        if (null !== this.Hg) {
            for (var a = this.Hg, b = this.Kb, c = Infinity, d = Infinity, e = a.s, f = e.length, g = 0; g < f; g++) { var h = e[g]; c = Math.min(h.x, c); d = Math.min(h.y, d) } h = g = Infinity; for (var k = b.s, l = k.length, m = 0; m < l; m++) { var n = k[m]; g = Math.min(n.x, g); h = Math.min(n.y, h); n.freeze() } b.freeze(); if (l === f) for (f = 0; f < l; f++) { if (m = e[f], n = k[f], m.x - c !== n.x - g || m.y - d !== n.y - h) { this.nc(); break } } else this.nc(); this.Hg = null; c = this.diagram; null !== c && c.animationManager.zb && (c = c.animationManager, this.nf = b, c.kl.add(this)); Hp(this);
            this.g("points", a, b)
        }
    }; t.mv = function () { null !== this.Hg && (this.Kb = this.Hg, this.Hg = null) }; function Hp(a) { 0 === a.Kb.count ? a.xd = !1 : (a.xd = !0, a.Wp = null, a.ot = NaN, a.defaultFromPoint = a.i(0), a.defaultToPoint = a.i(a.pointsCount - 1), Ip(a, !1)) }
    t.bb = function () { if (!this.suspendsRouting) { var a = this.diagram; if (a) { if (a.ts.contains(this) || a.undoManager.isUndoingRedoing) return; a = a.animationManager; if (a.isTicking && !a.isAnimating) return; null === this.nf || a.isTicking || (this.nf = null) } a = this.path; null !== a && (this.xd = !1, this.v(), a.v()) } }; t.rg = function () { if (!this.xd && !this.computingPoints) { var a = !0; try { this.computingPoints = !0, this.ph(), a = this.computePoints() } catch (b) { this.computingPoints = !1, this.mv() } finally { this.computingPoints = !1, a ? this.wf() : this.mv() } } };
    T.prototype.computePoints = function () {
        if (null === this.diagram) return !1; var a = this.fromNode, b = null; null === a ? (Jp || (Jp = new V, Jp.desiredSize = Cb, Jp.Va()), this.defaultFromPoint && (Jp.location = this.defaultFromPoint, Jp.Va(), b = a = Jp)) : b = this.fromPort; if (null !== b && !a.isVisible()) { var c = a.findVisibleNode(); null !== c && c !== a ? (a = c, b = c.port) : a = c } this.Ew = a; if (null === a || !a.location.w()) return !1; for (; !(null === b || b.actualBounds.w() && b.Nd());)b = b.panel; if (null === b) return !1; var d = this.toNode, e = null; null === d ? (Kp || (Kp = new V,
            Kp.desiredSize = Cb, Kp.Va()), this.defaultToPoint && (Kp.location = this.defaultToPoint, Kp.Va(), e = d = Kp)) : e = this.toPort; null === e || d.isVisible() || (c = d.findVisibleNode(), null !== c && c !== d ? (d = c, e = c.port) : d = c); this.Fw = d; if (null === d || !d.location.w()) return !1; for (; !(null === e || e.actualBounds.w() && e.Nd());)e = e.panel; if (null === e) return !1; var f = this.pointsCount, g = this.computeSpot(!0, b); c = this.computeSpot(!1, e); var h = Lp(g), k = Lp(c), l = b === e && null !== b, m = this.isOrthogonal, n = this.curve === bg; this.Tf = l && !m ? n = !0 : !1; var p = this.computeAdjusting() ===
                Zf || l; if (!m && !l && h && k) {
                    if (h = !1, !p && 3 <= f && (p = this.getLinkPoint(a, b, g, !0, !1, d, e), k = this.getLinkPoint(d, e, c, !1, !1, a, b), h = this.adjustPoints(0, p, f - 1, k)) && (p = this.getLinkPoint(a, b, g, !0, !1, d, e), k = this.getLinkPoint(d, e, c, !1, !1, a, b), this.adjustPoints(0, p, f - 1, k)), !h) if (this.Dj(), n) {
                        f = this.getLinkPoint(a, b, g, !0, !1, d, e); p = this.getLinkPoint(d, e, c, !1, !1, a, b); h = p.x - f.x; k = p.y - f.y; l = this.computeCurviness(); n = m = 0; var r = f.x + h / 3, q = f.y + k / 3, u = r, w = q; K.B(k, 0) ? w = 0 < h ? w - l : w + l : (m = -h / k, n = Math.sqrt(l * l / (m * m + 1)), 0 > l && (n = -n), u =
                            (0 > k ? -1 : 1) * n + r, w = m * (u - r) + q); r = f.x + 2 * h / 3; q = f.y + 2 * k / 3; var v = r, z = q; K.B(k, 0) ? z = 0 < h ? z - l : z + l : (v = (0 > k ? -1 : 1) * n + r, z = m * (v - r) + q); this.Dj(); this.je(f); this.vf(u, w); this.vf(v, z); this.je(p); this.Yc(0, this.getLinkPoint(a, b, g, !0, !1, d, e)); this.Yc(3, this.getLinkPoint(d, e, c, !1, !1, a, b))
                    } else if (f = d, p = e, d = this.getLinkPoint(a, b, g, !0, !1, f, p), e = this.getLinkPoint(f, p, c, !1, !1, a, b), this.hasCurviness()) p = e.x - d.x, c = e.y - d.y, g = this.computeCurviness(), a = d.x + p / 2, b = d.y + c / 2, f = a, h = b, K.B(c, 0) ? h = 0 < p ? h - g : h + g : (p = -p / c, f = Math.sqrt(g * g / (p *
                        p + 1)), 0 > g && (f = -f), f = (0 > c ? -1 : 1) * f + a, h = p * (f - a) + b), this.je(d), this.vf(f, h), this.je(e); else { this.je(d); this.je(e); h = N.alloc(); b.ji(h); k = h.ca(e); p.ji(h); l = h.ca(d); if (k || l) g = b.ja(Mp(g), new I), this.Yc(0, this.getLinkPointFromPoint(a, b, g, e, !0, g)), c = p.ja(Mp(c), new I), this.Yc(1, this.getLinkPointFromPoint(f, p, c, d, !1, c)); N.free(h) }
                } else {
                    n = this.isAvoiding; p && (m && n || l) && this.Dj(); var y = l ? this.computeCurviness() : 0; n = this.getLinkPoint(a, b, g, !0, m, d, e); r = u = q = 0; if (m || !h || l) w = this.computeEndSegmentLength(a, b, g, !0), r =
                        this.getLinkDirection(a, b, n, g, !0, m, d, e), l && (h || g.C(c) || !m && 1 === g.x + c.x && 1 === g.y + c.y) && (r -= m ? 90 : 30, 0 > y && (r -= 180)), 0 > r ? r += 360 : 360 <= r && (r -= 360), l && (w += Math.abs(y) * (m ? 1 : 2)), 0 === r ? q = w : 90 === r ? u = w : 180 === r ? q = -w : 270 === r ? u = -w : (q = w * Math.cos(r * Math.PI / 180), u = w * Math.sin(r * Math.PI / 180)), g.Jc() && l && (w = b.ja(xc, I.alloc()), v = I.allocAt(w.x + 1E3 * q, w.y + 1E3 * u), this.getLinkPointFromPoint(a, b, w, v, !0, n), I.free(w), I.free(v)); w = this.getLinkPoint(d, e, c, !1, m, a, b); var A = z = v = 0; if (m || !k || l) {
                            var B = this.computeEndSegmentLength(d, e,
                                c, !1); A = this.getLinkDirection(d, e, w, c, !1, m, a, b); l && (k || g.C(c) || !m && 1 === g.x + c.x && 1 === g.y + c.y) && (A += m ? 0 : 30, 0 > y && (A += 180)); 0 > A ? A += 360 : 360 <= A && (A -= 360); l && (B += Math.abs(y) * (m ? 1 : 2)); 0 === A ? v = B : 90 === A ? z = B : 180 === A ? v = -B : 270 === A ? z = -B : (v = B * Math.cos(A * Math.PI / 180), z = B * Math.sin(A * Math.PI / 180)); c.Jc() && l && (c = e.ja(xc, I.alloc()), g = I.allocAt(c.x + 1E3 * v, c.y + 1E3 * z), this.getLinkPointFromPoint(d, e, c, g, !1, w), I.free(c), I.free(g))
                        } c = n; if (m || !h || l) c = new I(n.x + q, n.y + u); g = w; if (m || !k || l) g = new I(w.x + v, w.y + z); !p && !m && h && 3 < f && this.adjustPoints(0,
                            n, f - 2, g) ? this.Yc(f - 1, w) : !p && !m && k && 3 < f && this.adjustPoints(1, c, f - 1, w) ? this.Yc(0, n) : !p && (m ? 6 <= f : 4 < f) && this.adjustPoints(1, c, f - 2, g) ? (this.Yc(0, n), this.Yc(f - 1, w)) : (this.Dj(), this.je(n), (m || !h || l) && this.je(c), m && this.addOrthoPoints(c, r, g, A, a, d), (m || !k || l) && this.je(g), this.je(w))
        } return !0
    }; function Np(a, b) { Math.abs(b.x - a.x) > Math.abs(b.y - a.y) ? (b.x >= a.x ? b.x = a.x + 9E9 : b.x = a.x - 9E9, b.y = a.y) : (b.y >= a.y ? b.y = a.y + 9E9 : b.y = a.y - 9E9, b.x = a.x); return b }
    T.prototype.getLinkPointFromPoint = function (a, b, c, d, e, f) {
        void 0 === f && (f = new I); if (null === a || null === b) return f.assign(c), f; a.isVisible() || (e = a.findVisibleNode(), null !== e && e !== a && (b = e.port)); a = null; e = b.panel; null === e || e.oe() || (e = e.panel); if (null === e) { e = d.x; d = d.y; var g = c.x; c = c.y } else { a = e.ke; e = 1 / (a.m11 * a.m22 - a.m12 * a.m21); g = a.m22 * e; var h = -a.m12 * e, k = -a.m21 * e, l = a.m11 * e, m = e * (a.m21 * a.dy - a.m22 * a.dx), n = e * (a.m12 * a.dx - a.m11 * a.dy); e = d.x * g + d.y * k + m; d = d.x * h + d.y * l + n; g = c.x * g + c.y * k + m; c = c.x * h + c.y * l + n } b.Qj(e, d, g, c, f); null !==
            a && f.transform(a); return f
    }; function Op(a, b) { var c = b.Vn; null === c && (c = new Pp, c.port = b, c.node = b.part, b.Vn = c); return Qp(c, a) }
    T.prototype.getLinkPoint = function (a, b, c, d, e, f, g, h) {
        void 0 === h && (h = new I); if (c.tb() && !Lp(c)) return b.ja(c, h), h; if (c.Vc()) { var k = Op(this, b); if (null !== k) { h.assign(k.To); if (e && this.routing === Rp) { var l = Op(this, g); if (null !== l && k.Cl < l.Cl) { k = I.alloc(); l = I.alloc(); var m = new N(b.ja(sc, k), b.ja(Bc, l)), n = this.computeSpot(!d, g); a = this.getLinkPoint(f, g, n, !d, e, a, b, l); (c.Ef(Dc) || c.Ef(Ec)) && a.y >= m.y && a.y <= m.y + m.height ? h.y = a.y : (c.Ef(Cc) || c.Ef(Fc)) && a.x >= m.x && a.x <= m.x + m.width && (h.x = a.x); I.free(k); I.free(l) } } return h } } c =
            b.ja(Mp(c), I.alloc()); this.pointsCount > (e ? 6 : 2) ? (g = d ? this.i(1) : this.i(this.pointsCount - 2), e && (g = Np(c, g.copy()))) : (k = this.computeSpot(!d, g), f = I.alloc(), g = g.ja(Mp(k), f), e && (g = Np(c, g)), I.free(f)); this.getLinkPointFromPoint(a, b, c, g, d, h); I.free(c); return h
    };
    T.prototype.getLinkDirection = function (a, b, c, d, e, f, g, h) {
        a: if (d.tb()) var k = d.x > d.y ? d.x > 1 - d.y ? 0 : d.x < 1 - d.y ? 270 : 315 : d.x < d.y ? d.x > 1 - d.y ? 90 : d.x < 1 - d.y ? 180 : 135 : .5 > d.x ? 225 : .5 < d.x ? 45 : 0; else {
            if (d.Vc() && (k = Op(this, b), null !== k)) switch (k.Kc) { case 1: k = 270; break a; case 2: k = 180; break a; default: case 4: k = 0; break a; case 8: k = 90; break a }k = b.ja(xc, I.alloc()); this.pointsCount > (f ? 6 : 2) ? (h = e ? this.i(1) : this.i(this.pointsCount - 2), h = f ? Np(k, h.copy()) : c) : (c = I.alloc(), h = h.ja(xc, c), I.free(c)); c = Math.abs(h.x - k.x) > Math.abs(h.y - k.y) ? h.x >=
                k.x ? 0 : 180 : h.y >= k.y ? 90 : 270; I.free(k); k = c
        } d.Jc() && g.Md(a) && (k += 180, 360 <= k && (k -= 360)); if (Lp(d)) return k; a = b.ii(); if (0 === a) return k; 45 <= a && 135 > a ? k += 90 : 135 <= a && 225 > a ? k += 180 : 225 <= a && 315 > a && (k += 270); 360 <= k && (k -= 360); return k
    }; T.prototype.computeEndSegmentLength = function (a, b, c, d) { if (null !== b && c.Vc() && (a = Op(this, b), null !== a)) return a.Du; a = d ? this.fromEndSegmentLength : this.toEndSegmentLength; null !== b && isNaN(a) && (a = d ? b.fromEndSegmentLength : b.toEndSegmentLength); isNaN(a) && (a = 10); return a };
    T.prototype.computeSpot = function (a, b) { void 0 === b && (b = null); a ? (a = b ? b : this.fromPort, null === a ? a = xc : (b = this.fromSpot, b.Tb() && (b = a.fromSpot), a = b === Sc ? rc : b)) : (a = b ? b : this.toPort, null === a ? a = xc : (b = this.toSpot, b.Tb() && (b = a.toSpot), a = b === Sc ? rc : b)); return a }; function Lp(a) { return a === rc || .5 === a.x && .5 === a.y } function Mp(a) { return .5 === a.x && .5 === a.y ? a : xc }
    T.prototype.computeOtherPoint = function (a, b) { if (this.computeAdjusting() !== Zf && 4 < this.pointsCount) return this.computeMidPoint(new I); a = b.Vn; a = null !== a ? Qp(a, this) : null; return null !== a ? a.To : b.ja(xc) }; T.prototype.computeShortLength = function (a) { if (a) { a = this.fromShortLength; if (isNaN(a)) { var b = this.fromPort; null !== b && (a = b.fromShortLength) } return isNaN(a) ? 0 : a } a = this.toShortLength; isNaN(a) && (b = this.toPort, null !== b && (a = b.toShortLength)); return isNaN(a) ? 0 : a };
    T.prototype.zf = function (a, b, c, d, e, f) {
        if (!1 === this.pickable) return !1; void 0 === b && (b = null); void 0 === c && (c = null); var g = f; void 0 === f && (g = ld.alloc(), g.reset()); g.multiply(this.transform); if (this.$g(a, g)) return rn(this, b, c, e), void 0 === f && ld.free(g), !0; if (this.Tc(a, g)) {
            var h = !1; if (!this.isAtomic) for (var k = this.P.s, l = k.length; l--;) {
                var m = k[l]; if (m.visible || m === this.locationObject) {
                    var n = m.actualBounds, p = this.naturalBounds; if (!(n.x > p.width || n.y > p.height || 0 > n.x + n.width || 0 > n.y + n.height)) {
                        n = ld.alloc(); n.set(g);
                        if (m instanceof W) h = m.zf(a, b, c, d, e, n); else if (this.path === m) {
                            if (m instanceof Bf) if (h = a, p = d, !1 === m.pickable) h = !1; else if (n.multiply(m.transform), p) b: {
                                var r = h, q = n; if (m.$g(r, q)) h = !0; else {
                                    if (void 0 === q && (q = m.transform, r.Me(m.actualBounds))) { h = !0; break b } h = r.left; p = r.right; var u = r.top; r = r.bottom; var w = I.alloc(), v = I.alloc(), z = I.alloc(), y = ld.alloc(); y.set(q); y.Yu(m.transform); y.Ol(); v.x = p; v.y = u; v.transform(y); w.x = h; w.y = u; w.transform(y); q = !1; Wn(m, w, v, z) ? q = !0 : (w.x = p, w.y = r, w.transform(y), Wn(m, w, v, z) ? q = !0 : (v.x =
                                        h, v.y = r, v.transform(y), Wn(m, w, v, z) ? q = !0 : (w.x = h, w.y = u, w.transform(y), Wn(m, w, v, z) && (q = !0)))); ld.free(y); I.free(w); I.free(v); I.free(z); h = q
                                }
                            } else h = m.$g(h, n)
                        } else h = hl(m, a, d, n); h && (p = m, null !== b && (p = b(m)), p && (null === c || c(p)) && e.add(p)); ld.free(n)
                    }
                }
            } void 0 === f && ld.free(g); return h || null !== this.background || null !== this.areaBackground
        } void 0 === f && ld.free(g); return !1
    };
    T.prototype.computeCurve = function () { if (null === this.Tf) { var a = this.fromPort, b = this.isOrthogonal; this.Tf = null !== a && a === this.toPort && !b } return this.Tf ? bg : this.curve }; T.prototype.computeCorner = function () { if (this.curve === bg) return 0; var a = this.corner; if (isNaN(a) || 0 > a) a = 10; return a };
    T.prototype.findMidLabel = function () { for (var a = this.path, b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d]; if (e !== a && !e.isPanelMain && (-Infinity === e.segmentIndex || isNaN(e.segmentIndex))) return e } for (a = this.labelNodes; a.next();)if (b = a.value, -Infinity === b.segmentIndex || isNaN(b.segmentIndex)) return b; return null };
    T.prototype.computeSpacing = function () {
        if (!this.isVisible()) return 0; var a = Sp; a = Math.max(a, this.computeThickness()); var b = this.fromPort, c = this.toPort; if (null !== b && null !== c) {
            var d = this.findMidLabel(); if (null !== d) {
                var e = d.naturalBounds, f = d.margin, g = isNaN(e.width) ? 30 : e.width * d.scale + f.left + f.right; e = isNaN(e.height) ? 14 : e.height * d.scale + f.top + f.bottom; d = d.segmentOrientation; d === en || d === fn || d === Dp ? a = Math.max(a, e) : d === Zm || d === $m || d === Xm || d === Ym ? a = Math.max(a, g) : (b = b.ja(xc).$a(c.ja(xc)) / 180 * Math.PI, a = Math.max(a,
                    Math.abs(Math.sin(b) * g) + Math.abs(Math.cos(b) * e) + 1)); this.curve === bg && (a *= 1.333)
            }
        } return a
    }; T.prototype.arrangeBundledLinks = function (a, b) { if (b) for (b = 0; b < a.length; b++) { var c = a[b]; c.computeAdjusting() === Zf && c.bb() } };
    T.prototype.computeCurviness = function () { var a = this.curviness; if (isNaN(a)) { a = Tp; var b = this.Pf; if (null !== b) { for (var c = Ea(), d = 0, e = b.links, f = 0; f < e.length; f++) { var g = e[f].computeSpacing(); c.push(g); d += g } d = -d / 2; for (f = 0; f < e.length; f++) { if (e[f] === this) { a = d + c[f] / 2; break } d += c[f] } b.ws === this.fromNode && (a = -a); Ga(c) } } return a }; T.prototype.computeThickness = function () { if (!this.isVisible()) return 0; var a = this.path; return null !== a ? Math.max(a.strokeWidth, 1) : 1 };
    T.prototype.hasCurviness = function () { return !isNaN(this.curviness) || null !== this.Pf };
    T.prototype.adjustPoints = function (a, b, c, d) {
        var e = this.computeAdjusting(); if (this.isOrthogonal) { if (e === Bp) return !1; e === Cp && (e = Ap) } switch (e) {
            case Bp: var f = this.i(a), g = this.i(c); if (!f.ab(b) || !g.ab(d)) {
                e = f.x; f = f.y; var h = g.x - e, k = g.y - f, l = Math.sqrt(h * h + k * k); if (!K.$(l, 0)) {
                    if (K.$(h, 0)) var m = 0 > k ? -Math.PI / 2 : Math.PI / 2; else m = Math.atan(k / Math.abs(h)), 0 > h && (m = Math.PI - m); g = b.x; var n = b.y; h = d.x - g; var p = d.y - n; k = Math.sqrt(h * h + p * p); K.$(h, 0) ? p = 0 > p ? -Math.PI / 2 : Math.PI / 2 : (p = Math.atan(p / Math.abs(h)), 0 > h && (p = Math.PI - p)); l =
                        k / l; m = p - m; this.Yc(a, b); for (a += 1; a < c; a++)b = this.i(a), h = b.x - e, k = b.y - f, b = Math.sqrt(h * h + k * k), K.$(b, 0) || (K.$(h, 0) ? k = 0 > k ? -Math.PI / 2 : Math.PI / 2 : (k = Math.atan(k / Math.abs(h)), 0 > h && (k = Math.PI - k)), h = k + m, b *= l, this.M(a, g + b * Math.cos(h), n + b * Math.sin(h))); this.Yc(c, d)
                }
            } return !0; case Cp: f = this.i(a); n = this.i(c); if (!f.ab(b) || !n.ab(d)) {
                e = f.x; f = f.y; g = n.x; n = n.y; l = (g - e) * (g - e) + (n - f) * (n - f); h = b.x; m = b.y; k = d.x; p = d.y; var r = 1; if (0 !== k - h) { var q = (p - m) / (k - h); r = Math.sqrt(1 + 1 / (q * q)) } else q = 9E9; this.Yc(a, b); for (a += 1; a < c; a++) {
                    b = this.i(a);
                    var u = b.x, w = b.y, v = .5; 0 !== l && (v = ((e - u) * (e - g) + (f - w) * (f - n)) / l); var z = e + v * (g - e), y = f + v * (n - f); b = Math.sqrt((u - z) * (u - z) + (w - y) * (w - y)); w < q * (u - z) + y && (b = -b); 0 < q && (b = -b); u = h + v * (k - h); v = m + v * (p - m); 0 !== q ? (b = u + b / r, this.M(a, b, v - (b - u) / q)) : this.M(a, u, v + b)
                } this.Yc(c, d)
            } return !0; case Ap: a: {
                if (this.isOrthogonal && (e = this.i(a), f = this.i(a + 1), g = this.i(a + 2), h = f.x, m = f.y, n = h, l = m, K.B(e.y, f.y) ? K.B(f.x, g.x) ? m = b.y : K.B(f.y, g.y) && (h = b.x) : K.B(e.x, f.x) && (K.B(f.y, g.y) ? h = b.x : K.B(f.x, g.x) && (m = b.y)), this.M(a + 1, h, m), e = this.i(c), f = this.i(c -
                    1), g = this.i(c - 2), h = f.x, m = f.y, k = h, p = m, K.B(e.y, f.y) ? K.B(f.x, g.x) ? m = d.y : K.B(f.y, g.y) && (h = d.x) : K.B(e.x, f.x) && (K.B(f.y, g.y) ? h = d.x : K.B(f.x, g.x) && (m = d.y)), this.M(c - 1, h, m), Rj(this))) { this.M(a + 1, n, l); this.M(c - 1, k, p); c = !1; break a } this.Yc(a, b); this.Yc(c, d); c = !0
            } return c; default: return !1
        }
    };
    T.prototype.addOrthoPoints = function (a, b, c, d, e, f) {
        0 > b ? b += 360 : 360 <= b && (b -= 360); 0 > d ? d += 360 : 360 <= d && (d -= 360); var g = d; b = 315 <= b || 45 > b ? 0 : 45 <= b && 135 > b ? 90 : 135 <= b && 225 > b ? 180 : 270; d = 315 <= d || 45 > d ? 0 : 45 <= d && 135 > d ? 90 : 135 <= d && 225 > d ? 180 : 270; var h = e.actualBounds.copy(), k = f.actualBounds.copy(); if (h.w() && k.w()) {
            h.yc(Up, Up); k.yc(Up, Up); h.Ue(a); k.Ue(c); var l = I.alloc(), m = I.alloc(); if (0 === b) if (c.x > a.x || 270 === d && c.y < a.y && k.right > a.x || 90 === d && c.y > a.y && k.right > a.x) l.h(c.x, a.y), m.h(c.x, (a.y + c.y) / 2), 180 === d ? (l.x = this.computeMidOrthoPosition(a.x,
                a.y, c.x, c.y, !1), m.x = l.x, m.y = c.y) : 270 === d && c.y < a.y || 90 === d && c.y > a.y ? (l.x = a.x < k.left ? this.computeMidOrthoPosition(a.x, a.y, k.left, c.y, !1) : a.x < k.right && (270 === d && a.y < k.top || 90 === d && a.y > k.bottom) ? this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !1) : k.right, m.x = l.x, m.y = c.y) : 0 === d && a.x < k.left && a.y > k.top && a.y < k.bottom && (l.x = a.x, l.y = a.y < c.y ? Math.min(c.y, k.top) : Math.max(c.y, k.bottom), m.y = l.y); else {
                    l.h(a.x, c.y); m.h((a.x + c.x) / 2, c.y); if (180 === d || 90 === d && c.y < h.top || 270 === d && c.y > h.bottom) 180 === d && (k.ca(a) || h.ca(c)) ?
                        l.y = this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !0) : c.y < a.y && (180 === d || 90 === d) ? l.y = this.computeMidOrthoPosition(a.x, h.top, c.x, Math.max(c.y, k.bottom), !0) : c.y > a.y && (180 === d || 270 === d) && (l.y = this.computeMidOrthoPosition(a.x, h.bottom, c.x, Math.min(c.y, k.top), !0)), m.x = c.x, m.y = l.y; if (l.y > h.top && l.y < h.bottom) if (c.x >= h.left && c.x <= a.x || a.x <= k.right && a.x >= c.x) { if (90 === d || 270 === d) l.h(Math.max((a.x + c.x) / 2, a.x), a.y), m.h(l.x, c.y) } else l.y = 270 === d || (0 === d || 180 === d) && c.y < a.y ? Math.min(c.y, 0 === d ? h.top : Math.min(h.top,
                            k.top)) : Math.max(c.y, 0 === d ? h.bottom : Math.max(h.bottom, k.bottom)), m.x = c.x, m.y = l.y
            } else if (180 === b) if (c.x < a.x || 270 === d && c.y < a.y && k.left < a.x || 90 === d && c.y > a.y && k.left < a.x) l.h(c.x, a.y), m.h(c.x, (a.y + c.y) / 2), 0 === d ? (l.x = this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !1), m.x = l.x, m.y = c.y) : 270 === d && c.y < a.y || 90 === d && c.y > a.y ? (l.x = a.x > k.right ? this.computeMidOrthoPosition(a.x, a.y, k.right, c.y, !1) : a.x > k.left && (270 === d && a.y < k.top || 90 === d && a.y > k.bottom) ? this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !1) : k.left, m.x = l.x,
                m.y = c.y) : 180 === d && a.x > k.right && a.y > k.top && a.y < k.bottom && (l.x = a.x, l.y = a.y < c.y ? Math.min(c.y, k.top) : Math.max(c.y, k.bottom), m.y = l.y); else {
                    l.h(a.x, c.y); m.h((a.x + c.x) / 2, c.y); if (0 === d || 90 === d && c.y < h.top || 270 === d && c.y > h.bottom) 0 === d && (k.ca(a) || h.ca(c)) ? l.y = this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !0) : c.y < a.y && (0 === d || 90 === d) ? l.y = this.computeMidOrthoPosition(a.x, h.top, c.x, Math.max(c.y, k.bottom), !0) : c.y > a.y && (0 === d || 270 === d) && (l.y = this.computeMidOrthoPosition(a.x, h.bottom, c.x, Math.min(c.y, k.top), !0)), m.x =
                        c.x, m.y = l.y; if (l.y > h.top && l.y < h.bottom) if (c.x <= h.right && c.x >= a.x || a.x >= k.left && a.x <= c.x) { if (90 === d || 270 === d) l.h(Math.min((a.x + c.x) / 2, a.x), a.y), m.h(l.x, c.y) } else l.y = 270 === d || (0 === d || 180 === d) && c.y < a.y ? Math.min(c.y, 180 === d ? h.top : Math.min(h.top, k.top)) : Math.max(c.y, 180 === d ? h.bottom : Math.max(h.bottom, k.bottom)), m.x = c.x, m.y = l.y
            } else if (90 === b) if (c.y > a.y || 180 === d && c.x < a.x && k.bottom > a.y || 0 === d && c.x > a.x && k.bottom > a.y) l.h(a.x, c.y), m.h((a.x + c.x) / 2, c.y), 270 === d ? (l.y = this.computeMidOrthoPosition(a.x, a.y, c.x,
                c.y, !0), m.x = c.x, m.y = l.y) : 180 === d && c.x < a.x || 0 === d && c.x > a.x ? (l.y = a.y < k.top ? this.computeMidOrthoPosition(a.x, a.y, c.x, k.top, !0) : a.y < k.bottom && (180 === d && a.x < k.left || 0 === d && a.x > k.right) ? this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !0) : k.bottom, m.x = c.x, m.y = l.y) : 90 === d && a.y < k.top && a.x > k.left && a.x < k.right && (l.x = a.x < c.x ? Math.min(c.x, k.left) : Math.max(c.x, k.right), l.y = a.y, m.x = l.x); else {
                    l.h(c.x, a.y); m.h(c.x, (a.y + c.y) / 2); if (270 === d || 0 === d && c.x < h.left || 180 === d && c.x > h.right) 270 === d && (k.ca(a) || h.ca(c)) ? l.x = this.computeMidOrthoPosition(a.x,
                        a.y, c.x, c.y, !1) : c.x < a.x && (270 === d || 0 === d) ? l.x = this.computeMidOrthoPosition(h.left, a.y, Math.max(c.x, k.right), c.y, !1) : c.x > a.x && (270 === d || 180 === d) && (l.x = this.computeMidOrthoPosition(h.right, a.y, Math.min(c.x, k.left), c.y, !1)), m.x = l.x, m.y = c.y; if (l.x > h.left && l.x < h.right) if (c.y >= h.top && c.y <= a.y || a.y <= k.bottom && a.y >= c.y) { if (0 === d || 180 === d) l.h(a.x, Math.max((a.y + c.y) / 2, a.y)), m.h(c.x, l.y) } else l.x = 180 === d || (90 === d || 270 === d) && c.x < a.x ? Math.min(c.x, 90 === d ? h.left : Math.min(h.left, k.left)) : Math.max(c.x, 90 === d ? h.right :
                            Math.max(h.right, k.right)), m.x = l.x, m.y = c.y
            } else if (c.y < a.y || 180 === d && c.x < a.x && k.top < a.y || 0 === d && c.x > a.x && k.top < a.y) l.h(a.x, c.y), m.h((a.x + c.x) / 2, c.y), 90 === d ? (l.y = this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !0), m.x = c.x, m.y = l.y) : 180 === d && c.x < a.x || 0 === d && c.x >= a.x ? (l.y = a.y > k.bottom ? this.computeMidOrthoPosition(a.x, a.y, c.x, k.bottom, !0) : a.y > k.top && (180 === d && a.x < k.left || 0 === d && a.x > k.right) ? this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !0) : k.top, m.x = c.x, m.y = l.y) : 270 === d && a.y > k.bottom && a.x > k.left && a.x < k.right &&
                (l.x = a.x < c.x ? Math.min(c.x, k.left) : Math.max(c.x, k.right), l.y = a.y, m.x = l.x); else {
                    l.h(c.x, a.y); m.h(c.x, (a.y + c.y) / 2); if (90 === d || 0 === d && c.x < h.left || 180 === d && c.x > h.right) 90 === d && (k.ca(a) || h.ca(c)) ? l.x = this.computeMidOrthoPosition(a.x, a.y, c.x, c.y, !1) : c.x < a.x && (90 === d || 0 === d) ? l.x = this.computeMidOrthoPosition(h.left, a.y, Math.max(c.x, k.right), c.y, !1) : c.x > a.x && (90 === d || 180 === d) && (l.x = this.computeMidOrthoPosition(h.right, a.y, Math.min(c.x, k.left), c.y, !1)), m.x = l.x, m.y = c.y; if (l.x > h.left && l.x < h.right) if (c.y <= h.bottom &&
                        c.y >= a.y || a.y >= k.top && a.y <= c.y) { if (0 === d || 180 === d) l.h(a.x, Math.min((a.y + c.y) / 2, a.y)), m.h(c.x, l.y) } else l.x = 180 === d || (90 === d || 270 === d) && c.x < a.x ? Math.min(c.x, 270 === d ? h.left : Math.min(h.left, k.left)) : Math.max(c.x, 270 === d ? h.right : Math.max(h.right, k.right)), m.x = l.x, m.y = c.y
            } var n = c; if (this.isAvoiding) {
                var p = this.diagram; if (null === p || e === f || this.layer.isTemporary || !ik(p) || h.ca(n) && !f.Md(e) || k.ca(a) && !e.Md(f)) b = !1; else {
                    var r = jk(p, !0, this.containingGroup, null); if (17 !== this.segmentIndex && r.Vj(Math.min(a.x, l.x),
                        Math.min(a.y, l.y), Math.abs(a.x - l.x), Math.abs(a.y - l.y)) && r.Vj(Math.min(l.x, m.x), Math.min(l.y, m.y), Math.abs(l.x - m.x), Math.abs(l.y - m.y)) && r.Vj(Math.min(m.x, n.x), Math.min(m.y, n.y), Math.abs(m.x - n.x), Math.abs(m.y - n.y))) b = !1; else {
                            e = a; f = n; var q = c = null; if (p.isVirtualized) {
                                p = r.bounds.copy(); p.yc(-r.Do, -r.Co); var u = I.alloc(); zk(r, a.x, a.y) || (K.Cf(p.x, p.y, p.x + p.width, p.y + p.height, a.x, a.y, l.x, l.y, u) ? (c = a = u.copy(), b = u.$a(l)) : K.Cf(p.x, p.y, p.x + p.width, p.y + p.height, l.x, l.y, m.x, m.y, u) ? (c = a = u.copy(), b = u.$a(m)) : K.Cf(p.x,
                                    p.y, p.x + p.width, p.y + p.height, m.x, m.y, n.x, n.y, u) && (c = a = u.copy(), b = u.$a(n))); zk(r, n.x, n.y) || (K.Cf(p.x, p.y, p.x + p.width, p.y + p.height, n.x, n.y, m.x, m.y, u) ? (q = n = u.copy(), d = m.$a(u)) : K.Cf(p.x, p.y, p.x + p.width, p.y + p.height, m.x, m.y, l.x, l.y, u) ? (q = n = u.copy(), d = l.$a(u)) : K.Cf(p.x, p.y, p.x + p.width, p.y + p.height, l.x, l.y, a.x, a.y, u) && (q = n = u.copy(), d = a.$a(u))); I.free(u)
                            } h = h.copy().Zc(k); h.yc(r.Do * r.Hs, r.Co * r.Hs); Vp(r, a, b, n, d, h, !0); k = Wp(r, n.x, n.y); !r.abort && k >= Xp && (yk(r), h.yc(r.Do * r.Bx, r.Co * r.Cx), Vp(r, a, b, n, d, h, !1), k = Wp(r,
                                n.x, n.y)); if (!r.abort && k < Xp && Wp(r, n.x, n.y) !== Ak) {
                                    Yp(this, r, n.x, n.y, d, !0, g); h = this.i(2); if (4 > this.pointsCount) 0 === b || 180 === b ? (h.x = a.x, h.y = n.y) : (h.x = n.x, h.y = a.y), this.M(2, h.x, h.y), this.l(3, h.x, h.y); else if (g = this.i(3), 0 === b || 180 === b) K.B(h.x, g.x) ? (h = 0 === b ? Math.max(h.x, a.x) : Math.min(h.x, a.x), this.M(2, h, a.y), this.M(3, h, g.y)) : K.B(h.y, g.y) ? (Math.abs(a.y - h.y) <= r.Co / 2 && (this.M(2, h.x, a.y), this.M(3, g.x, a.y)), this.l(2, h.x, a.y)) : this.M(2, a.x, h.y); else if (90 === b || 270 === b) K.B(h.y, g.y) ? (h = 90 === b ? Math.max(h.y, a.y) :
                                        Math.min(h.y, a.y), this.M(2, a.x, h), this.M(3, g.x, h)) : K.B(h.x, g.x) ? (Math.abs(a.x - h.x) <= r.Do / 2 && (this.M(2, a.x, h.y), this.M(3, a.x, g.y)), this.l(2, a.x, h.y)) : this.M(2, h.x, a.y); null !== c && (a = this.i(1), g = this.i(2), a.x !== g.x && a.y !== g.y ? 0 === b || 180 === b ? this.l(2, a.x, g.y) : this.l(2, g.x, a.y) : 0 === b || 180 === b ? this.l(2, e.x, c.y) : this.l(2, c.x, e.y)); null !== q && (0 === d || 180 === d ? this.vf(f.x, q.y) : this.vf(q.x, f.y)); b = !0
                                } else b = !1
                    }
                }
            } else b = !1; b || (this.je(l.copy()), this.je(m.copy())); I.free(l); I.free(m)
        }
    };
    T.prototype.computeMidOrthoPosition = function (a, b, c, d, e) { var f = 0; !this.hasCurviness() || this.computeSpot(!0, this.fromPort).Vc() || this.computeSpot(!1, this.toPort).Vc() || (f = this.computeCurviness()); return e ? (b + d) / 2 + f : (a + c) / 2 + f };
    function Rj(a) { if (null === a.diagram || !a.isAvoiding || !ik(a.diagram)) return !1; var b = a.points.s, c = b.length; if (4 > c) return !1; a = jk(a.diagram, !0, a.containingGroup, null); for (var d = 1; d < c - 2; d++) { var e = b[d], f = b[d + 1]; if (!a.Vj(Math.min(e.x, f.x), Math.min(e.y, f.y), Math.abs(e.x - f.x), Math.abs(e.y - f.y))) return !0 } return !1 }
    function Yp(a, b, c, d, e, f, g) {
        var h = b.Do, k = b.Co, l = Wp(b, c, d), m = c, n = d; 0 === e ? m += h : 90 === e ? n += k : 180 === e ? m -= h : n -= k; e !== g && l > Zp && Wp(b, m, n) !== l - 1 && (Wp(b, c - h, d) === l - 1 ? (e = 180, m = c - h, n = d) : Wp(b, c + h, d) === l - 1 ? (e = 0, m = c + h, n = d) : Wp(b, c, d - k) === l - 1 ? (e = 270, m = c, n = d - k) : Wp(b, c, d + k) === l - 1 && (e = 90, m = c, n = d + k)); for (; l > Zp && Wp(b, m, n) === l - 1;)c = m, d = n, 0 === e ? m += h : 90 === e ? n += k : 180 === e ? m -= h : n -= k, --l; if (f) { if (l > Zp) if (180 === e || 0 === e) c = Math.floor(c / h) * h + h / 2; else if (90 === e || 270 === e) d = Math.floor(d / k) * k + k / 2 } else c = Math.floor(c / h) * h + h / 2, d = Math.floor(d /
            k) * k + k / 2; l > Zp && (f = e, g = c, m = d, 0 === e ? (f = 90, m += k) : 90 === e ? (f = 180, g -= h) : 180 === e ? (f = 270, m -= k) : 270 === e && (f = 0, g += h), Wp(b, g, m) === l - 1 ? Yp(a, b, g, m, f, !1, f) : (g = c, m = d, 0 === e ? (f = 270, m -= k) : 90 === e ? (f = 0, g += h) : 180 === e ? (f = 90, m += k) : 270 === e && (f = 180, g -= h), Wp(b, g, m) === l - 1 && Yp(a, b, g, m, f, !1, f))); a.vf(c, d)
    } T.prototype.Xy = function (a) { var b = a.x; a = a.y; for (var c = this.i(0), d = this.i(1), e = yb(b, a, c.x, c.y, d.x, d.y), f = 0, g = 1; g < this.pointsCount - 1; g++) { c = this.i(g + 1); var h = yb(b, a, d.x, d.y, c.x, c.y); d = c; h < e && (f = g, e = h) } return f };
    T.prototype.nc = function () { this.gk = !1; this.v() }; T.prototype.Ql = function (a) { if (!a) { if (!1 === this.xd) return; a = this.sb(); if (this.gk && (null === a || null !== a.geometry)) return } 2 > this.pointsCount ? this.gk = !0 : this.ta = this.makeGeometry(); a = this.path; if (null !== a) { a.ta = this.ta; for (var b = this.P.s, c = b.length, d = 0; d < c; d++) { var e = b[d]; e !== a && e.isPanelMain && e instanceof Bf && (e.ta = this.ta) } } };
    T.prototype.makeGeometry = function () {
        var a = this.ta, b = this.pointsCount, c = !1, d = this.diagram; null !== d && Ep(this) && d.zh.contains(this) && (0 !== this.hl.width || 0 !== this.hl.height) && (d.animationManager.isAnimating || d.currentTool !== d.toolManager) && (c = !0); var e = this.i(0).copy(), f = e.copy(); d = this.Kb.s; var g = this.computeCurve(); if (g === bg && 3 <= b && !K.$(this.smoothness, 0)) if (3 === b) { var h = this.i(1); d = Math.min(e.x, h.x); var k = Math.min(e.y, h.y); h = this.i(2); d = Math.min(d, h.x); k = Math.min(k, h.y) } else {
            if (this.isOrthogonal) for (k =
                0; k < b; k++)h = d[k], f.x = Math.min(h.x, f.x), f.y = Math.min(h.y, f.y); else for (d = 3; d < b; d += 3)d + 3 >= b && (d = b - 1), k = this.i(d), f.x = Math.min(k.x, f.x), f.y = Math.min(k.y, f.y); d = f.x; k = f.y
        } else { for (k = 0; k < b; k++)h = d[k], f.x = Math.min(h.x, f.x), f.y = Math.min(h.y, f.y); d = f.x; k = f.y } d -= this.nu.x; k -= this.nu.y; e.x -= d; e.y -= k; if (2 !== b || Ep(this)) {
            a.type = P.m; h = qd(a); 0 !== this.computeShortLength(!0) && (e = $p(this, e, !0, f)); wd(h, e.x, e.y, !1); if (g === bg && 3 <= b && !K.$(this.smoothness, 0)) if (3 === b) c = this.i(1), b = c.x - d, c = c.y - k, e = this.i(2).copy(), e.x -=
                d, e.y -= k, 0 !== this.computeShortLength(!1) && (e = $p(this, e, !1, f)), xd(h, b, c, b, c, e.x, e.y); else if (this.isOrthogonal) {
                    b = I.allocAt(d, k); c = I.alloc(); c.assign(this.i(1)); f = I.allocAt(d, k); e = I.allocAt(d, k); g = this.i(0); for (var l, m = this.smoothness / 3, n = 1; n < this.pointsCount - 1; n++) {
                        l = this.i(n); var p = g, r = l, q = this.i(aq(this, l, n, !1)); if (!K.$(p.x, r.x) || !K.$(r.x, q.x)) if (!K.$(p.y, r.y) || !K.$(r.y, q.y)) {
                            var u = m; isNaN(u) && (u = this.smoothness / 3); var w = p.x; p = p.y; var v = r.x; r = r.y; var z = q.x; q = q.y; var y = u * bq(w, p, v, r); u *= bq(v, r, z,
                                q); K.$(p, r) && K.$(v, z) && (v > w ? q > r ? (f.x = v - y, f.y = r - y, e.x = v + u, e.y = r + u) : (f.x = v - y, f.y = r + y, e.x = v + u, e.y = r - u) : q > r ? (f.x = v + y, f.y = r - y, e.x = v - u, e.y = r + u) : (f.x = v + y, f.y = r + y, e.x = v - u, e.y = r - u)); K.$(w, v) && K.$(r, q) && (r > p ? (z > v ? (f.x = v - y, f.y = r - y, e.x = v + u) : (f.x = v + y, f.y = r - y, e.x = v - u), e.y = r + u) : (z > v ? (f.x = v - y, f.y = r + y, e.x = v + u) : (f.x = v + y, f.y = r + y, e.x = v - u), e.y = r - u)); if (K.$(w, v) && K.$(v, z) || K.$(p, r) && K.$(r, q)) w = .5 * (w + z), p = .5 * (p + q), f.x = w, f.y = p, e.x = w, e.y = p; 1 === n ? (c.x = .5 * (g.x + l.x), c.y = .5 * (g.y + l.y)) : 2 === n && K.$(g.x, this.i(0).x) && K.$(g.y, this.i(0).y) &&
                                    (c.x = .5 * (g.x + l.x), c.y = .5 * (g.y + l.y)); xd(h, c.x - d, c.y - k, f.x - d, f.y - k, l.x - d, l.y - k); b.set(f); c.set(e); g = l
                        }
                    } l = g.x; g = g.y; m = this.i(this.pointsCount - 1); 0 !== this.computeShortLength(!1) && (m = $p(this, m.copy(), !1, pb)); l = .5 * (l + m.x); g = .5 * (g + m.y); xd(h, e.x - d, e.y - k, l - d, g - k, m.x - d, m.y - k); I.free(b); I.free(c); I.free(f); I.free(e)
                } else for (c = 3; c < b; c += 3)f = this.i(c - 2), c + 3 >= b && (c = b - 1), e = this.i(c - 1), g = this.i(c), c === b - 1 && 0 !== this.computeShortLength(!1) && (g = $p(this, g.copy(), !1, pb)), xd(h, f.x - d, f.y - k, e.x - d, e.y - k, g.x - d, g.y - k); else {
                    f =
                    I.alloc(); f.assign(this.i(0)); e = I.alloc(); e.assign(f); g = this.computeCorner(); n = 1; for (l = 0; n < b;) {
                        n = aq(this, f, n, 1 < n); m = this.i(n); if (n >= b - 1) { if (!f.C(m)) 0 !== this.computeShortLength(!1) && (m = $p(this, m.copy(), !1, pb)), cq(this, h, -d, -k, f, m, c); else if (0 === l) for (n = 1; n < b;)e.assign(f), m = this.i(n++), cq(this, h, -d, -k, f, m, c), f.assign(m); break } l = aq(this, m, n + 1, n < b - 3); n = -d; w = -k; p = m; v = this.i(l); u = c; if (K.B(f.y, p.y) && K.B(p.x, v.x)) if (q = Math.min(g, Math.abs(p.x - e.x) / 2), q = y = Math.min(q, Math.abs(v.y - p.y) / 2), K.B(q, 0)) cq(this, h,
                            n, w, f, p, u), f.assign(p); else { var A = p.x, B = p.y; r = A; z = B; p.x > f.x ? A = p.x - q : A = p.x + q; v.y > p.y ? z = p.y + y : z = p.y - y; v = I.allocAt(A, B); cq(this, h, n, w, f, v, u); I.free(v); yd(h, p.x + n, p.y + w, r + n, z + w); f.h(r, z) } else K.B(f.x, p.x) && K.B(p.y, v.y) ? (q = Math.min(g, Math.abs(p.y - e.y) / 2), q = y = Math.min(q, Math.abs(v.x - p.x) / 2), K.B(y, 0) ? (cq(this, h, n, w, f, p, u), f.assign(p)) : (A = p.x, B = p.y, r = A, z = B, p.y > f.y ? B = p.y - q : B = p.y + q, v.x > p.x ? r = p.x + y : r = p.x - y, v = I.allocAt(A, B), cq(this, h, n, w, f, v, u), I.free(v), yd(h, p.x + n, p.y + w, r + n, z + w), f.h(r, z))) : (cq(this, h, n, w, f,
                                p, u), f.assign(p)); e.assign(m); n = l
                    } I.free(f)
            } Bd = h
        } else h = this.i(1).copy(), h.x -= d, h.y -= k, 0 !== this.computeShortLength(!0) && (e = $p(this, e, !0, f)), 0 !== this.computeShortLength(!1) && (h = $p(this, h, !1, f)), a.type = P.j, a.startX = e.x, a.startY = e.y, a.endX = h.x, a.endY = h.y; this.gk = !0; return a
    }; function bq(a, b, c, d) { a = c - a; if (isNaN(a) || Infinity === a || -Infinity === a) return NaN; 0 > a && (a = -a); b = d - b; if (isNaN(b) || Infinity === b || -Infinity === b) return NaN; 0 > b && (b = -b); return K.$(a, 0) ? b : K.$(b, 0) ? a : Math.sqrt(a * a + b * b) }
    function $p(a, b, c, d) { var e = a.pointsCount; if (2 > e) return b; if (c) { var f = a.i(1); c = f.x - d.x; f = f.y - d.y; d = bq(b.x, b.y, c, f); if (0 === d) return b; e = 2 === e ? .5 * d : d; a = a.computeShortLength(!0); a > e && (a = e); e = a * (f - b.y) / d; b.x += a * (c - b.x) / d; b.y += e } else { f = a.i(e - 2); c = f.x - d.x; f = f.y - d.y; d = bq(b.x, b.y, c, f); if (0 === d) return b; e = 2 === e ? .5 * d : d; a = a.computeShortLength(!1); a > e && (a = e); e = a * (b.y - f) / d; b.x -= a * (b.x - c) / d; b.y -= e } return b }
    function aq(a, b, c, d) { for (var e = a.pointsCount, f = b; K.$(b.x, f.x) && K.$(b.y, f.y);) { if (c >= e) return e - 1; f = a.i(c++) } if (!K.$(b.x, f.x) && !K.$(b.y, f.y)) return c - 1; for (var g = f; K.$(b.x, f.x) && K.$(f.x, g.x) && (!d || (b.y >= f.y ? f.y >= g.y : f.y <= g.y)) || K.$(b.y, f.y) && K.$(f.y, g.y) && (!d || (b.x >= f.x ? f.x >= g.x : f.x <= g.x));) { if (c >= e) return e - 1; g = a.i(c++) } return c - 2 } T.prototype.computeJumpLength = function () { return 10 };
    function cq(a, b, c, d, e, f, g) {
        if (!g && Ep(a)) {
            g = a.computeJumpLength(); var h = g / 2, k = [], l = 0; a.isVisible() && (l = dq(a, e, f, k)); if (0 < l) if (K.B(e.y, f.y)) if (e.x < f.x) for (var m = 0; m < l;) { var n = Math.max(e.x, Math.min(k[m++] - h, f.x - g)); b.lineTo(n + c, f.y + d); var p = n + c; for (var r = Math.min(n + g, f.x); m < l;)if (n = k[m], n < r + g) m++, r = Math.min(n + h, f.x); else break; n = f.y - g + d; r += c; var q = f.y + d; a.curve === Yf ? wd(b, r, q, !1) : xd(b, p, n, r, n, r, q) } else for (--l; 0 <= l;) {
                m = Math.min(e.x, Math.max(k[l--] + h, f.x + g)); b.lineTo(m + c, f.y + d); p = m + c; for (n = Math.max(m -
                    g, f.x); 0 <= l;)if (m = k[l], m > n - g) l--, n = Math.max(m - h, f.x); else break; m = f.y - g + d; n += c; r = f.y + d; a.curve === Yf ? wd(b, n, r, !1) : xd(b, p, m, n, m, n, r)
            } else if (K.B(e.x, f.x)) if (e.y < f.y) for (m = 0; m < l;) { n = Math.max(e.y, Math.min(k[m++] - h, f.y - g)); b.lineTo(f.x + c, n + d); p = n + d; for (n = Math.min(n + g, f.y); m < l;)if (r = k[m], r < n + g) m++, n = Math.min(r + h, f.y); else break; r = f.x - g + c; q = f.x + c; n += d; a.curve === Yf ? wd(b, q, n, !1) : xd(b, r, p, r, n, q, n) } else for (--l; 0 <= l;) {
                m = Math.min(e.y, Math.max(k[l--] + h, f.y + g)); b.lineTo(f.x + c, m + d); p = m + d; for (m = Math.max(m - g, f.y); 0 <=
                    l;)if (n = k[l], n > m - g) l--, m = Math.max(n - h, f.y); else break; n = f.x - g + c; r = f.x + c; m += d; a.curve === Yf ? wd(b, r, m, !1) : xd(b, n, p, n, m, r, m)
            }
        } b.lineTo(f.x + c, f.y + d)
    }
    function dq(a, b, c, d) { var e = a.diagram; if (null === e || b.C(c)) return 0; for (e = e.layers; e.next();) { var f = e.value; if (null !== f && f.visible) { f = f.Oa.s; for (var g = f.length, h = 0; h < g; h++) { var k = f[h]; if (k instanceof T) { if (k === a) return 0 < d.length && d.sort(function (a, b) { return a - b }), d.length; if (k.isVisible() && Ep(k)) { var l = k.routeBounds; l.w() && a.routeBounds.Tc(l) && !a.usesSamePort(k) && (l = k.path, null !== l && l.Nd() && eq(b, c, d, k)) } } } } } 0 < d.length && d.sort(function (a, b) { return a - b }); return d.length }
    function eq(a, b, c, d) {
        for (var e = K.B(a.y, b.y), f = d.pointsCount, g = d.i(0), h = I.alloc(), k = 1; k < f; k++) {
            var l = d.i(k); if (k < f - 1) { var m = d.i(k + 1); if (g.y === l.y && l.y === m.y) { if (l.x > g.x && m.x >= l.x || l.x < g.x && m.x <= l.x) continue } else if (g.x === l.x && l.x === m.x && (l.y > g.y && m.y >= l.y || l.y < g.y && m.y <= l.y)) continue } a: {
                m = a.x; var n = a.y, p = b.x, r = b.y, q = g.x; g = g.y; var u = l.x, w = l.y; if (!K.B(m, p)) { if (K.B(n, r) && K.B(q, u) && Math.min(m, p) < q && Math.max(m, p) > q && Math.min(g, w) < n && Math.max(g, w) > n && !K.B(g, w)) { h.x = q; h.y = n; m = !0; break a } } else if (!K.B(n, r) &&
                    K.B(g, w) && Math.min(n, r) < g && Math.max(n, r) > g && Math.min(q, u) < m && Math.max(q, u) > m && !K.B(q, u)) { h.x = m; h.y = g; m = !0; break a } h.x = 0; h.y = 0; m = !1
            } m && (e ? c.push(h.x) : c.push(h.y)); g = l
        } I.free(h)
    } function Ep(a) { a = a.curve; return a === Xf || a === Yf } function Ip(a, b) { if (b || Ep(a)) b = a.diagram, null === b || b.animationManager.isTicking || b.zh.contains(a) || 0 === a.hl.width && 0 === a.hl.height || b.zh.add(a, a.hl.copy()) }
    T.prototype.Ro = function (a) { var b = this.layer; if (null !== b && b.visible && !b.isTemporary) { var c = b.diagram; if (null !== c && !c.animationManager.isTicking) { var d = !1; for (c = c.layers; c.next();) { var e = c.value; if (e.visible) if (e === b) { d = !0; var f = !1; e = e.Oa.s; for (var g = e.length, h = 0; h < g; h++) { var k = e[h]; k instanceof T && (k === this ? f = !0 : f && fq(this, k, a)) } } else if (d) for (f = e.Oa.s, e = f.length, g = 0; g < e; g++)h = f[g], h instanceof T && fq(this, h, a) } } } };
    function fq(a, b, c) { if (null !== b && b.gk && b.xd && Ep(b)) { var d = b.routeBounds; d.w() && (a.routeBounds.Tc(d) || c.Tc(d)) && (a.usesSamePort(b) || b.nc()) } } T.prototype.usesSamePort = function (a) { var b = this.pointsCount, c = a.pointsCount; if (0 < b && 0 < c) { var d = this.i(0), e = a.i(0); if (d.ab(e)) return !0; b = this.i(b - 1); a = a.i(c - 1); if (b.ab(a) || d.ab(a) || b.ab(e)) return !0 } else if (this.fromNode === a.fromNode || this.toNode === a.toNode || this.fromNode === a.toNode || this.toNode === a.fromNode) return !0; return !1 };
    T.prototype.isVisible = function () {
        if (!U.prototype.isVisible.call(this)) return !1; var a = this.containingGroup, b = !0, c = this.diagram; null !== c && (b = c.isTreePathToChildren); c = this.fromNode; if (null !== c) { if (this.isTreeLink && b && !c.isTreeExpanded) return !1; if (c === a) return !0; for (var d = c; null !== d;) { if (d.labeledLink === this) return !0; d = d.containingGroup } c = c.findVisibleNode(); if (null === c || c === a) return !1 } c = this.toNode; if (null !== c) {
            if (this.isTreeLink && !b && !c.isTreeExpanded) return !1; if (c === a) return !0; for (b = c; null !== b;) {
                if (b.labeledLink ===
                    this) return !0; b = b.containingGroup
            } b = c.findVisibleNode(); if (null === b || b === a) return !1
        } return !0
    }; T.prototype.Vb = function (a) { U.prototype.Vb.call(this, a); null !== this.Pf && this.Pf.Nl(); if (null !== this.qd) for (var b = this.qd.iterator; b.next();)b.value.Vb(a) }; T.prototype.computeAdjusting = function () { return this.isAvoiding && null !== this.diagram && this.diagram.animationManager.defaultAnimation.isAnimating ? Ap : this.kk };
    function Fp(a) { var b = a.af; if (null !== b) { var c = a.rf; if (null !== c && isNaN(a.curviness)) { for (var d = a.bf, e = a.sf, f = a = null, g = b.kb.s, h = g.length, k = 0; k < h; k++) { var l = g[k]; if (l.af === b && l.bf === d && l.rf === c && l.sf === e || l.af === c && l.bf === e && l.rf === b && l.sf === d) null === f ? f = l : (null === a && (a = [], a.push(f)), a.push(l)) } if (null !== a) { f = kp(b, c, d, e); null === f && (f = new gq(b, d, c, e), jp(b, f), jp(c, f)); f.links = a; for (b = 0; b < a.length; b++)a[b].Pf = f; f.Nl() } } } }
    function Gp(a) { var b = a.Pf; null !== b && isNaN(a.curviness) && (a.Pf = null, a = b.links.indexOf(a), 0 <= a && (Da(b.links, a), b.Nl())) } T.prototype.kh = function () { return !0 };
    ma.Object.defineProperties(T.prototype, {
        fromNode: {
            get: function () { return this.af }, set: function (a) {
                var b = this.af; if (b !== a) {
                    var c = this.fromPort; null !== b && (this.rf !== b && op(b, this, c), Gp(this), this.D(2)); this.af = a; null !== a && this.Vb(a.isVisible()); this.Tf = null; this.bb(); var d = this.diagram; null !== d && d.aa && d.partManager.setFromNodeForLink(this, a, b); var e = this.fromPort, f = this.fromPortChanged; if (null !== f) { var g = !0; null !== d && (g = d.aa, d.aa = !0); f(this, c, e); null !== d && (d.aa = g) } null !==
                        a && (this.rf !== a && np(a, this, e), Fp(this), this.D(1)); this.g("fromNode", b, a); fp(this)
                }
            }
        }, fromPortId: {
            get: function () { return this.bf }, set: function (a) {
                var b = this.bf; if (b !== a) {
                    var c = this.fromPort; null !== c && lp(c); Gp(this); this.bf = a; var d = this.fromPort; null !== d && lp(d); var e = this.diagram; if (null !== e) { var f = this.data, g = e.model; null !== f && g.ni() && g.qv(f, a) } c !== d && (this.Tf = null, this.bb(), f = this.fromPortChanged, null !== f && (g = !0, null !== e && (g = e.aa, e.aa = !0), f(this, c, d), null !== e && (e.aa = g)));
                    Fp(this); this.g("fromPortId", b, a)
                }
            }
        }, fromPort: { get: function () { var a = this.af; return null === a ? null : a.fs(this.bf) } }, fromPortChanged: { get: function () { return this.cn }, set: function (a) { var b = this.cn; b !== a && (this.cn = a, this.g("fromPortChanged", b, a)) } }, toNode: {
            get: function () { return this.rf }, set: function (a) {
                var b = this.rf; if (b !== a) {
                    var c = this.toPort; null !== b && (this.af !== b && op(b, this, c), Gp(this), this.D(2)); this.rf = a; null !==
                        a && this.Vb(a.isVisible()); this.Tf = null; this.bb(); var d = this.diagram; null !== d && d.aa && d.partManager.setToNodeForLink(this, a, b); var e = this.toPort, f = this.toPortChanged; if (null !== f) { var g = !0; null !== d && (g = d.aa, d.aa = !0); f(this, c, e); null !== d && (d.aa = g) } null !== a && (this.af !== a && np(a, this, e), Fp(this), this.D(1)); this.g("toNode", b, a); fp(this)
                }
            }
        }, toPortId: {
            get: function () { return this.sf }, set: function (a) {
                var b = this.sf; if (b !== a) {
                    var c = this.toPort; null !== c && lp(c); Gp(this); this.sf = a; var d =
                        this.toPort; null !== d && lp(d); var e = this.diagram; if (null !== e) { var f = this.data, g = e.model; null !== f && g.ni() && g.vv(f, a) } c !== d && (this.Tf = null, this.bb(), f = this.toPortChanged, null !== f && (g = !0, null !== e && (g = e.aa, e.aa = !0), f(this, c, d), null !== e && (e.aa = g))); Fp(this); this.g("toPortId", b, a)
                }
            }
        }, toPort: { get: function () { var a = this.rf; return null === a ? null : a.fs(this.sf) } }, toPortChanged: {
            get: function () { return this.qo }, set: function (a) {
                var b = this.qo; b !== a && (this.qo =
                    a, this.g("toPortChanged", b, a))
            }
        }, fromSpot: { get: function () { return null !== this.ba ? this.ba.Ui : Sc }, set: function (a) { var b = this.fromSpot; b.C(a) || (a = a.J(), this.Sc(), this.ba.Ui = a, this.g("fromSpot", b, a), (b.Vc() || a.Vc()) && null !== this.fromNode && lp(this.fromPort), this.bb()) } }, fromEndSegmentLength: {
            get: function () { return null !== this.ba ? this.ba.Si : NaN }, set: function (a) {
                var b = this.fromEndSegmentLength; b !== a && (0 > a && xa(a, ">= 0", T, "fromEndSegmentLength"), this.Sc(),
                    this.ba.Si = a, this.g("fromEndSegmentLength", b, a), this.bb())
            }
        }, fromShortLength: { get: function () { return null !== this.ba ? this.ba.Ti : NaN }, set: function (a) { var b = this.fromShortLength; b !== a && (this.Sc(), this.ba.Ti = a, this.nc(), this.g("fromShortLength", b, a)) } }, toSpot: {
            get: function () { return null !== this.ba ? this.ba.wj : Sc }, set: function (a) {
                var b = this.toSpot; b.C(a) || (a = a.J(), this.Sc(), this.ba.wj = a, this.g("toSpot", b, a), (b.Vc() || a.Vc()) && null !== this.toNode &&
                    lp(this.toPort), this.bb())
            }
        }, toEndSegmentLength: { get: function () { return null !== this.ba ? this.ba.uj : NaN }, set: function (a) { var b = this.toEndSegmentLength; b !== a && (0 > a && xa(a, ">= 0", T, "toEndSegmentLength"), this.Sc(), this.ba.uj = a, this.g("toEndSegmentLength", b, a), this.bb()) } }, toShortLength: {
            get: function () { return null !== this.ba ? this.ba.vj : NaN }, set: function (a) {
                var b = this.toShortLength; b !== a && (this.Sc(), this.ba.vj = a, this.nc(), this.g("toShortLength", b,
                    a))
            }
        }, isLabeledLink: { get: function () { return null === this.qd ? !1 : 0 < this.qd.count } }, labelNodes: { get: function () { return null === this.qd ? Ya : this.qd.iterator } }, relinkableFrom: { get: function () { return 0 !== (this.Pa & 1) }, set: function (a) { var b = 0 !== (this.Pa & 1); b !== a && (this.Pa ^= 1, this.g("relinkableFrom", b, a), this.Sb()) } }, relinkableTo: {
            get: function () { return 0 !== (this.Pa & 2) }, set: function (a) {
                var b = 0 !==
                    (this.Pa & 2); b !== a && (this.Pa ^= 2, this.g("relinkableTo", b, a), this.Sb())
            }
        }, resegmentable: { get: function () { return 0 !== (this.Pa & 4) }, set: function (a) { var b = 0 !== (this.Pa & 4); b !== a && (this.Pa ^= 4, this.g("resegmentable", b, a), this.Sb()) } }, isTreeLink: { get: function () { return 0 !== (this.Pa & 8) }, set: function (a) { var b = 0 !== (this.Pa & 8); b !== a && (this.Pa ^= 8, this.g("isTreeLink", b, a), null !== this.fromNode && Gk(this.fromNode), null !== this.toNode && Gk(this.toNode)) } }, path: {
            get: function () { var a = this.sb(); return a instanceof Bf ? a : null }
        }, routeBounds: {
            get: function () {
                this.rg(); var a = this.hl, b = Infinity, c = Infinity, d = this.pointsCount; if (0 === d) a.h(NaN, NaN, 0, 0); else {
                    if (1 === d) d = this.i(0), b = Math.min(d.x, b), c = Math.min(d.y, c), a.h(d.x, d.y, 0, 0); else if (2 === d) { d = this.i(0); var e = this.i(1); b = Math.min(d.x, e.x); c = Math.min(d.y, e.y); a.h(d.x, d.y, 0, 0); a.Ue(e) } else if (this.computeCurve() === bg && 3 <= d && !this.isOrthogonal) if (e = this.i(0), b = e.x, c = e.y, a.h(b,
                        c, 0, 0), 3 === d) { d = this.i(1); b = Math.min(d.x, b); c = Math.min(d.y, c); var f = this.i(2); b = Math.min(f.x, b); c = Math.min(f.y, c); K.zl(e.x, e.y, d.x, d.y, d.x, d.y, f.x, f.y, .5, a) } else for (f = 3; f < d; f += 3) { var g = this.i(f - 2); f + 3 >= d && (f = d - 1); var h = this.i(f - 1), k = this.i(f); K.zl(e.x, e.y, g.x, g.y, h.x, h.y, k.x, k.y, .5, a); b = Math.min(k.x, b); c = Math.min(k.y, c); e = k } else for (e = this.i(0), f = this.i(1), b = Math.min(e.x, f.x), c = Math.min(e.y, f.y), a.h(e.x, e.y, 0, 0), a.Ue(f), e = 2; e < d; e++)f = this.i(e), b = Math.min(f.x, b), c = Math.min(f.y, c), a.Ue(f); this.nu.h(b -
                            a.x, c - a.y)
                } return a
            }
        }, midPoint: { get: function () { this.rg(); return this.computeMidPoint(new I) } }, midAngle: { get: function () { this.rg(); return this.computeMidAngle() } }, flattenedLengths: {
            get: function () {
                if (null === this.Wp) {
                    this.xd || Hp(this); for (var a = this.Wp = [], b = this.pointsCount, c = 0; c < b - 1; c++) {
                        var d = this.i(c); var e = this.i(c + 1); K.$(d.x, e.x) ? (d = e.y - d.y, 0 > d && (d = -d)) : K.$(d.y, e.y) ? (d = e.x - d.x, 0 > d && (d = -d)) : d = Math.sqrt(d.yf(e));
                        a.push(d)
                    }
                } return this.Wp
            }
        }, flattenedTotalLength: { get: function () { var a = this.ot; if (isNaN(a)) { for (var b = this.flattenedLengths, c = b.length, d = a = 0; d < c; d++)a += b[d]; this.ot = a } return a } }, points: {
            get: function () { return this.Kb }, set: function (a) {
                var b = this.Kb; if (b !== a) {
                    var c = null; if (Array.isArray(a)) {
                        var d = 0 === a.length % 2; if (d) for (var e = 0; e < a.length; e++)if ("number" !== typeof a[e] || isNaN(a[e])) { d = !1; break } if (d) for (c = new F, d = 0; d < a.length / 2; d++)e = (new I(a[2 *
                            d], a[2 * d + 1])).freeze(), c.add(e); else { d = !0; for (e = 0; e < a.length; e++) { var f = a[e]; if (!za(f) || "number" !== typeof f.x || isNaN(f.x) || "number" !== typeof f.y || isNaN(f.y)) { d = !1; break } } if (d) for (c = new F, d = 0; d < a.length; d++)e = a[d], c.add((new I(e.x, e.y)).freeze()) }
                    } else if (a instanceof F) for (c = a.copy(), a = c.iterator; a.next();)a.value.freeze(); else C("Link.points value is not an instance of List or Array: " + a); c.freeze(); this.Kb = c; this.nc(); Hp(this); a = this.diagram; null !== a && (a.mi || a.undoManager.isUndoingRedoing || a.ts.add(this),
                        a.animationManager.zb && (a = a.animationManager, this.nf = c, a.kl.add(this))); this.g("points", b, c)
                }
            }
        }, pointsCount: { get: function () { return this.Kb.count } }, xd: { get: function () { return 0 !== (this.Pa & 16) }, set: function (a) { 0 !== (this.Pa & 16) !== a && (this.Pa ^= 16) } }, suspendsRouting: { get: function () { return 0 !== (this.Pa & 32) }, set: function (a) { 0 !== (this.Pa & 32) !== a && (this.Pa ^= 32) } }, computingPoints: {
            get: function () {
                return 0 !==
                    (this.Pa & 64)
            }, set: function (a) { 0 !== (this.Pa & 64) !== a && (this.Pa ^= 64) }
        }, defaultFromPoint: { get: function () { return this.m }, set: function (a) { this.m = a.copy() } }, defaultToPoint: { get: function () { return this.u }, set: function (a) { this.u = a.copy() } }, isOrthogonal: { get: function () { return 2 === (this.oj.value & 2) } }, isAvoiding: { get: function () { return 4 === (this.oj.value & 4) } }, gk: {
            get: function () { return 0 !== (this.Pa & 128) }, set: function (a) { 0 !== (this.Pa & 128) !== a && (this.Pa ^= 128) }
        }, geometry: { get: function () { if (!this.gk) { this.rg(); if (2 > this.pointsCount) return this.gk = !0, this.ta; this.ta = this.makeGeometry() } return this.ta } }, firstPickIndex: { get: function () { return 2 >= this.pointsCount ? 0 : this.isOrthogonal || !Lp(this.computeSpot(!0)) ? 1 : 0 } }, lastPickIndex: {
            get: function () {
                var a = this.pointsCount; return 0 ===
                    a ? 0 : 2 >= a ? a - 1 : this.isOrthogonal || !Lp(this.computeSpot(!1)) ? a - 2 : a - 1
            }
        }, adjusting: { get: function () { return this.kk }, set: function (a) { var b = this.kk; b !== a && (this.kk = a, this.g("adjusting", b, a)) } }, corner: { get: function () { return this.Lm }, set: function (a) { var b = this.Lm; b !== a && (this.Lm = a, this.nc(), this.g("corner", b, a)) } }, curve: {
            get: function () { return this.Nm }, set: function (a) {
                var b = this.Nm; b !== a && (this.Nm = a, this.bb(), this.nc(),
                    Ip(this, b === Yf || b === Xf || a === Yf || a === Xf), this.g("curve", b, a))
            }
        }, curviness: { get: function () { return this.Om }, set: function (a) { var b = this.Om; b !== a && (this.Om = a, this.bb(), this.nc(), this.g("curviness", b, a)) } }, routing: { get: function () { return this.oj }, set: function (a) { var b = this.oj; b !== a && (this.oj = a, this.Tf = null, this.bb(), Ip(this, 2 === (b.value & 2) || 2 === (a.value & 2)), this.g("routing", b, a)) } }, smoothness: {
            get: function () { return this.lo },
            set: function (a) { var b = this.lo; b !== a && (this.lo = a, this.nc(), this.g("smoothness", b, a)) }
        }, key: { get: function () { var a = this.diagram; if (null !== a && a.model.ni()) return a.model.xc(this.data) } }
    }); T.prototype.invalidateOtherJumpOvers = T.prototype.Ro; T.prototype.findClosestSegment = T.prototype.Xy; T.prototype.updateRoute = T.prototype.rg; T.prototype.invalidateRoute = T.prototype.bb; T.prototype.rollbackRoute = T.prototype.mv; T.prototype.commitRoute = T.prototype.wf; T.prototype.startRoute = T.prototype.ph;
    T.prototype.clearPoints = T.prototype.Dj; T.prototype.removePoint = T.prototype.kv; T.prototype.addPointAt = T.prototype.vf; T.prototype.addPoint = T.prototype.je; T.prototype.insertPointAt = T.prototype.l; T.prototype.insertPoint = T.prototype.Cz; T.prototype.setPointAt = T.prototype.M; T.prototype.setPoint = T.prototype.Yc; T.prototype.getPoint = T.prototype.i; T.prototype.getOtherPort = T.prototype.uz; T.prototype.getOtherNode = T.prototype.No;
    var Jp = null, Kp = null, zp = new D(T, "Normal", 1), hq = new D(T, "Orthogonal", 2), iq = new D(T, "AvoidsNodes", 6), Rp = new D(T, "AvoidsNodesStraight", 7), Zf = new D(T, "None", 0), bg = new D(T, "Bezier", 9), Yf = new D(T, "JumpGap", 10), Xf = new D(T, "JumpOver", 11), Ap = new D(T, "End", 17), Bp = new D(T, "Scale", 18), Cp = new D(T, "Stretch", 19), en = new D(T, "OrientAlong", 21), Xm = new D(T, "OrientPlus90", 22), Zm = new D(T, "OrientMinus90", 23), Dp = new D(T, "OrientOpposite", 24), fn = new D(T, "OrientUpright", 25), Ym = new D(T, "OrientPlus90Upright", 26), $m = new D(T, "OrientMinus90Upright",
        27), an = new D(T, "OrientUpright45", 28), Tp = 16, Sp = 14, Up = 8; T.className = "Link"; T.Normal = zp; T.Orthogonal = hq; T.AvoidsNodes = iq; T.AvoidsNodesStraight = Rp; T.None = Zf; T.Bezier = bg; T.JumpGap = Yf; T.JumpOver = Xf; T.End = Ap; T.Scale = Bp; T.Stretch = Cp; T.OrientAlong = en; T.OrientPlus90 = Xm; T.OrientMinus90 = Zm; T.OrientOpposite = Dp; T.OrientUpright = fn; T.OrientPlus90Upright = Ym; T.OrientMinus90Upright = $m; T.OrientUpright45 = an; T.EndSegmentStep = 8; T.CurvinessStart = Tp; T.SpacingMin = Sp; T.OrthoShortCut = Up;
    function gq(a, b, c, d) { Qa(this); this.Be = this.jq = !1; this.ws = a; this.Kx = b; this.Zu = c; this.Lx = d; this.links = [] } gq.prototype.Nl = function () { if (!this.jq) { var a = this.links; 0 < a.length && (a = a[0].diagram, null !== a && (a.hw.add(this), this.Be = a.undoManager.isUndoingRedoing)) } this.jq = !0 };
    gq.prototype.Gv = function () { if (this.jq) { this.jq = !1; var a = this.links; if (0 < a.length) { var b = a[0], c = b.diagram; c = null === c || c.mi && !this.Be; this.Be = !1; b.arrangeBundledLinks(a, c); 1 === a.length && (b.Pf = null, a.length = 0) } 0 === a.length && (a = this.ws, null !== this && null !== a.Xe && a.Xe.remove(this), a = this.Zu, null !== this && null !== a.Xe && a.Xe.remove(this)) } }; gq.className = "LinkBundle";
    function kk(a) { Qa(this); this.Ux = this.group = null; this.os = !0; this.abort = !1; this.Fd = this.Ed = 1; this.Bn = this.An = -1; this.oc = a.width; this.pc = a.height; this.Yb = null; this.bi = this.ai = 0; this.Hs = 11; this.Cx = this.Bx = 22 }
    kk.prototype.initialize = function (a) {
        if (!(0 >= a.width || 0 >= a.height)) {
            var b = a.y, c = a.x + a.width, d = a.y + a.height; this.Ed = Math.floor((a.x - this.oc) / this.oc) * this.oc; this.Fd = Math.floor((b - this.pc) / this.pc) * this.pc; this.An = Math.ceil((c + 2 * this.oc) / this.oc) * this.oc; this.Bn = Math.ceil((d + 2 * this.pc) / this.pc) * this.pc; a = 1 + (Math.ceil((this.An - this.Ed) / this.oc) | 0); b = 1 + (Math.ceil((this.Bn - this.Fd) / this.pc) | 0); if (null === this.Yb || this.ai < a - 1 || this.bi < b - 1) {
                c = []; for (d = 0; d <= a; d++)c[d] = new Uint32Array(b); this.Yb = c; this.ai =
                    a - 1; this.bi = b - 1
            } a = jq; if (null !== this.Yb) for (b = 0; b <= this.ai; b++)if (c = this.Yb[b], c.fill) c.fill(a); else for (d = 0; d <= this.bi; d++)c[d] = a
        }
    }; function zk(a, b, c) { return a.Ed <= b && b <= a.An && a.Fd <= c && c <= a.Bn } function Wp(a, b, c) { if (!zk(a, b, c)) return jq; b -= a.Ed; b /= a.oc; c -= a.Fd; c /= a.pc; return a.Yb[b | 0][c | 0] } function yk(a) { if (null !== a.Yb) for (var b = 0; b <= a.ai; b++)for (var c = a.Yb[b], d = 0; d <= a.bi; d++)c[d] >= Zp && (c[d] = jq) }
    kk.prototype.Vj = function (a, b, c, d) { if (a > this.An || a + c < this.Ed || b > this.Bn || b + d < this.Fd) return !0; a = (a - this.Ed) / this.oc | 0; b = (b - this.Fd) / this.pc | 0; c = Math.max(0, c) / this.oc + 1 | 0; var e = Math.max(0, d) / this.pc + 1 | 0; 0 > a && (c += a, a = 0); 0 > b && (e += b, b = 0); if (0 > c || 0 > e) return !0; d = Math.min(a + c - 1, this.ai) | 0; for (c = Math.min(b + e - 1, this.bi) | 0; a <= d; a++) { e = this.Yb[a]; for (var f = b; f <= c; f++)if (e[f] === Ak) return !1 } return !0 };
    function kq(a, b, c, d, e, f, g, h, k) {
        if (!(b < f || b > g || c < h || c > k)) {
            var l = b | 0; var m = c | 0; var n = a.Yb[l][m]; if (n >= Zp && n < Xp) for (e ? m += d : l += d, n += 1; f <= l && l <= g && h <= m && m <= k && !(n >= a.Yb[l][m]);)a.Yb[l][m] = n, n += 1, e ? m += d : l += d; l = e ? m : l; if (e) if (0 < d) for (c += d; c < l; c += d)kq(a, b, c, 1, !e, f, g, h, k), kq(a, b, c, -1, !e, f, g, h, k); else for (c += d; c > l; c += d)kq(a, b, c, 1, !e, f, g, h, k), kq(a, b, c, -1, !e, f, g, h, k); else if (0 < d) for (b += d; b < l; b += d)kq(a, b, c, 1, !e, f, g, h, k), kq(a, b, c, -1, !e, f, g, h, k); else for (b += d; b > l; b += d)kq(a, b, c, 1, !e, f, g, h, k), kq(a, b, c, -1, !e, f, g, h,
                k)
        }
    } function lq(a, b, c, d, e, f, g, h, k) { b |= 0; c |= 0; var l = Ak, m = Zp; for (a.Yb[b][c] = m; l === Ak && b > f && b < g && c > h && c < k;)m += 1, a.Yb[b][c] = m, e ? c += d : b += d, l = a.Yb[b][c] } function mq(a, b, c, d, e, f, g, h, k) { b |= 0; c |= 0; var l = Ak, m = Xp; for (a.Yb[b][c] = m; l === Ak && b > f && b < g && c > h && c < k;)a.Yb[b][c] = m, e ? c += d : b += d, l = a.Yb[b][c] }
    function Vp(a, b, c, d, e, f, g) {
        if (null !== a.Yb) {
            a.abort = !1; var h = b.x, k = b.y; if (zk(a, h, k)) {
                h -= a.Ed; h /= a.oc; k -= a.Fd; k /= a.pc; var l = d.x, m = d.y; if (zk(a, l, m)) if (l -= a.Ed, l /= a.oc, m -= a.Fd, m /= a.pc, 1 >= Math.abs(h - l) && 1 >= Math.abs(k - m)) a.abort = !0; else {
                    var n = f.x; d = f.y; b = f.x + f.width; var p = f.y + f.height; n -= a.Ed; n /= a.oc; d -= a.Fd; d /= a.pc; b -= a.Ed; b /= a.oc; p -= a.Fd; p /= a.pc; f = Math.max(0, Math.min(a.ai, n | 0)); b = Math.min(a.ai, Math.max(0, b | 0)); n = Math.max(0, Math.min(a.bi, d | 0)); p = Math.min(a.bi, Math.max(0, p | 0)); d = a.Yb; h |= 0; k |= 0; l |= 0; m |=
                        0; var r = 0 === c || 90 === c ? 1 : -1; c = 90 === c || 270 === c; d[h][k] === Ak ? (lq(a, h, k, r, c, f, b, n, p), lq(a, h, k, 1, !c, f, b, n, p), lq(a, h, k, -1, !c, f, b, n, p)) : lq(a, h, k, r, c, h, k, h, k); d[l][m] === Ak ? (mq(a, l, m, 0 === e || 90 === e ? 1 : -1, 90 === e || 270 === e, f, b, n, p), mq(a, l, m, 1, !(90 === e || 270 === e), f, b, n, p), mq(a, l, m, -1, !(90 === e || 270 === e), f, b, n, p)) : mq(a, l, m, r, c, l, m, l, m); c = Ea(); if (g && nq(a, l, m, e, h, k, c)) {
                            if (c[0] !== l || c[1] !== m) c.unshift(m), c.unshift(l); if (c[c.length - 2] !== h || c[c.length - 1] !== k) c.push(h), c.push(k); a = Zp; e = c[c.length - 2]; g = c[c.length - 1]; d[e][g] =
                                a++; for (f = c.length - 4; 0 <= f; f -= 2)for (e = c[f + 2], g = c[f + 3], b = c[f], l = c[f + 1]; b > e ? e++ : b < e ? e-- : l > g ? g++ : l < g && g--, d[e][g] = a++, e !== b || g !== l;);
                        } else kq(a, h, k, 1, !1, f, b, n, p), kq(a, h, k, -1, !1, f, b, n, p), kq(a, h, k, 1, !0, f, b, n, p), kq(a, h, k, -1, !0, f, b, n, p); Ga(c)
                }
            }
        }
    }
    function nq(a, b, c, d, e, f, g) {
        for (var h = a.Yb, k = b, l = c; ;) { if (k === e && l === f) return !0; var m = k, n = l; if (0 === d) if (m < e) m++; else break; else if (90 === d) if (n < f) n++; else break; else if (180 === d) if (m > e) m--; else break; else if (n > f) n--; else break; var p = h[m][n]; if (0 === p || p === oq) break; k = m; l = n } for (; ;) {
            m = d; n = k; p = l; 0 === d ? p < f ? (m = 90, p++) : p > f && (m = 270, p--) : 90 === d ? n < e ? (m = 0, n++) : n > e && (m = 180, n--) : 180 === d ? p < f ? (m = 90, p++) : p > f && (m = 270, p--) : n < e ? (m = 0, n++) : n > e && (m = 180, n--); n = h[n][p]; if (m !== d && 0 !== n && n !== oq) {
                g.push(k); g.push(l); if (nq(a, k, l,
                    m, e, f, g)) return !0; g.pop(); g.pop()
            } h[k][l] = oq; if (0 === d) { if (k === b) return !1; k-- } else if (90 === d) { if (l === c) return !1; l-- } else if (180 === d) { if (k === b) return !1; k++ } else { if (l === c) return !1; l++ }
        }
    }
    ma.Object.defineProperties(kk.prototype, { bounds: { get: function () { return new N(this.Ed, this.Fd, this.An - this.Ed, this.Bn - this.Fd) } }, Do: { get: function () { return this.oc }, set: function (a) { 0 < a && a !== this.oc && (this.oc = a, this.initialize(this.bounds)) } }, Co: { get: function () { return this.pc }, set: function (a) { 0 < a && a !== this.pc && (this.pc = a, this.initialize(this.bounds)) } } }); var Ak = 0, Zp = 1, Xp = 999999, jq = Xp + 1, oq = Xp + 2; kk.className = "PositionArray";
    function Pp() { Qa(this); this.port = this.node = null; this.le = []; this.Vo = !1 } Pp.prototype.toString = function () { for (var a = this.le, b = this.node.toString() + " " + a.length.toString() + ":", c = 0; c < a.length; c++) { var d = a[c]; null !== d && (b += "\n  " + d.toString()) } return b };
    function pq(a, b, c, d) {
        b = b.offsetY; switch (b) { case 8: return 90; case 2: return 180; case 1: return 270; case 4: return 0 }switch (b) { case 9: return 180 < c ? 270 : 90; case 6: return 90 < c && 270 >= c ? 180 : 0 }a = 180 * Math.atan2(a.height, a.width) / Math.PI; switch (b) {
            case 3: return c > a && c <= 180 + a ? 180 : 270; case 5: return c > 180 - a && c <= 360 - a ? 270 : 0; case 12: return c > a && c <= 180 + a ? 90 : 0; case 10: return c > 180 - a && c <= 360 - a ? 180 : 90; case 7: return 90 < c && c <= 180 + a ? 180 : c > 180 + a && c <= 360 - a ? 270 : 0; case 13: return 180 < c && c <= 360 - a ? 270 : c > a && 180 >= c ? 90 : 0; case 14: return c >
                a && c <= 180 - a ? 90 : c > 180 - a && 270 >= c ? 180 : 0; case 11: return c > 180 - a && c <= 180 + a ? 180 : c > 180 + a ? 270 : 90
        }d && 15 !== b && (c -= 15, 0 > c && (c += 360)); return c > a && c < 180 - a ? 90 : c >= 180 - a && c <= 180 + a ? 180 : c > 180 + a && c < 360 - a ? 270 : 0
    } Pp.prototype.Nl = function () { this.le.length = 0 };
    function Qp(a, b) {
        var c = a.le; if (0 === c.length) {
            a: if (!a.Vo) {
                c = a.Vo; a.Vo = !0; var d = null, e = a.node, f = e instanceof pf ? e : null; if (null === f || f.isSubGraphExpanded) var g = e.isTreeExpanded ? e.findLinksConnected(a.port.portId) : e.kx(); else { if (!f.actualBounds.w()) { a.Vo = c; break a } d = f; g = d.Fu() } f = a.le.length = 0; var h = a.port.ja(sc, I.alloc()), k = a.port.ja(Bc, I.alloc()); e = N.allocAt(h.x, h.y, 0, 0); e.Ue(k); I.free(h); I.free(k); h = I.allocAt(e.x + e.width / 2, e.y + e.height / 2); k = a.port.ii(); for (g = g.iterator; g.next();) {
                    var l = g.value; if (l.isVisible() &&
                        l.fromPort !== l.toPort) {
                            var m = l.fromPort === a.port || null !== l.fromNode && l.fromNode.Md(d), n = l.computeSpot(m, a.port); if (n.Vc() && (m = m ? l.toPort : l.fromPort, null !== m)) {
                                var p = m.part; if (null !== p) {
                                    var r = p.findVisibleNode(); null !== r && r !== p && (p = r, m = p.port); m = l.computeOtherPoint(p, m); p = h.$a(m); p -= k; 0 > p ? p += 360 : 360 <= p && (p -= 360); n = pq(e, n, p, l.isOrthogonal); 0 === n ? (n = 4, 180 < p && (p -= 360)) : 90 === n ? (n = 8, 270 < p && (p -= 360)) : 180 === n ? n = 2 : (n = 1, 90 > p && (p += 360)); r = a.le[f]; void 0 === r ? (r = new qq(l, p, n), a.le[f] = r) : (r.link = l, r.angle = p, r.Kc =
                                        n); r.dv.set(m); f++
                                }
                            }
                    }
                } I.free(h); a.le.sort(Pp.prototype.j); k = a.le.length; d = -1; for (f = h = 0; f < k; f++)g = a.le[f], void 0 !== g && (g.Kc !== d && (d = g.Kc, h = 0), g.Oo = h, h++); d = -1; h = 0; for (f = k - 1; 0 <= f; f--)k = a.le[f], void 0 !== k && (k.Kc !== d && (d = k.Kc, h = k.Oo + 1), k.Cl = h); f = a.le; n = a.port; d = a.node.portSpreading; h = I.alloc(); k = I.alloc(); g = I.alloc(); l = I.alloc(); n.ja(sc, h); n.ja(uc, k); n.ja(Bc, g); n.ja(zc, l); r = p = m = n = 0; if (d === wp) for (var q = 0; q < f.length; q++) {
                    var u = f[q]; if (null !== u) {
                        var w = u.link.computeThickness(); switch (u.Kc) {
                            case 8: p += w; break;
                            case 2: r += w; break; case 1: n += w; break; default: case 4: m += w
                        }
                    }
                } var v = q = 0, z = 1, y = u = 0; for (w = 0; w < f.length; w++) {
                    var A = f[w]; if (null !== A) {
                        if (q !== A.Kc) {
                            q = A.Kc; switch (q) { case 8: var B = g; v = l; break; case 2: B = l; v = h; break; case 1: B = h; v = k; break; default: case 4: B = k, v = g }u = v.x - B.x; y = v.y - B.y; switch (q) {
                                case 8: p > Math.abs(u) ? (z = Math.abs(u) / p, p = Math.abs(u)) : z = 1; break; case 2: r > Math.abs(y) ? (z = Math.abs(y) / r, r = Math.abs(y)) : z = 1; break; case 1: n > Math.abs(u) ? (z = Math.abs(u) / n, n = Math.abs(u)) : z = 1; break; default: case 4: m > Math.abs(y) ? (z = Math.abs(y) /
                                    m, m = Math.abs(y)) : z = 1
                            }v = 0
                        } var E = A.To; if (d === wp) { A = A.link.computeThickness(); A *= z; E.set(B); switch (q) { case 8: E.x = B.x + u / 2 + p / 2 - v - A / 2; break; case 2: E.y = B.y + y / 2 + r / 2 - v - A / 2; break; case 1: E.x = B.x + u / 2 - n / 2 + v + A / 2; break; default: case 4: E.y = B.y + y / 2 - m / 2 + v + A / 2 }v += A } else { var M = .5; d === hp && (M = (A.Oo + 1) / (A.Cl + 1)); E.x = B.x + u * M; E.y = B.y + y * M }
                    }
                } I.free(h); I.free(k); I.free(g); I.free(l); B = a.le; for (f = 0; f < B.length; f++)d = B[f], null !== d && (d.Du = a.computeEndSegmentLength(d)); a.Vo = c; N.free(e)
            } c = a.le
        } for (a = 0; a < c.length; a++)if (e = c[a], null !==
            e && e.link === b) return e; return null
    } Pp.prototype.j = function (a, b) { return a === b ? 0 : null === a ? -1 : null === b ? 1 : a.Kc < b.Kc ? -1 : a.Kc > b.Kc ? 1 : a.angle < b.angle ? -1 : a.angle > b.angle ? 1 : 0 };
    Pp.prototype.computeEndSegmentLength = function (a) { var b = a.link, c = b.computeEndSegmentLength(this.node, this.port, rc, b.fromPort === this.port), d = a.Oo; if (0 > d) return c; var e = a.Cl; if (1 >= e || !b.isOrthogonal) return c; b = a.dv; var f = a.To; if (2 === a.Kc || 8 === a.Kc) d = e - 1 - d; return ((a = 2 === a.Kc || 4 === a.Kc) ? b.y < f.y : b.x < f.x) ? c + 8 * d : (a ? b.y === f.y : b.x === f.x) ? c : c + 8 * (e - 1 - d) }; Pp.className = "Knot"; function qq(a, b, c) { this.link = a; this.angle = b; this.Kc = c; this.dv = new I; this.Cl = this.Oo = 0; this.To = new I; this.Du = 0 }
    qq.prototype.toString = function () { return this.link.toString() + " " + this.angle.toString() + " " + this.Kc.toString() + ":" + this.Oo.toString() + "/" + this.Cl.toString() + " " + this.To.toString() + " " + this.Du.toString() + " " + this.dv.toString() }; qq.className = "LinkInfo"; function ri(a) { Qa(this); this.m = this.H = null; this.Ch = this.on = !0; this.un = !1; this.xm = (new I(0, 0)).freeze(); this.rn = !0; this.Bm = this.qn = null; this.tn = !1; this.j = null; a && Object.assign(this, a) }
    ri.prototype.cloneProtected = function (a) { a.on = this.on; a.Ch = this.Ch; a.un = this.un; a.xm.assign(this.xm); a.rn = this.rn; a.qn = this.qn; a.Bm = this.Bm; a.tn = !0 }; ri.prototype.copy = function () { var a = new this.constructor; this.cloneProtected(a); return a }; ri.prototype.rb = function () { }; ri.prototype.toString = function () { var a = Ha(this.constructor); a += "("; null !== this.group && (a += " in " + this.group); null !== this.diagram && (a += " for " + this.diagram); return a + ")" };
    ri.prototype.D = function () { if (this.isValidLayout) { var a = this.diagram; if (null !== a && !a.undoManager.isUndoingRedoing) { var b = a.animationManager; !b.isTicking && (b.defaultAnimation.isAnimating && b.zc(), this.isOngoing && a.mi || this.isInitial && !a.mi) && (this.isValidLayout = !1, a.Wb()) } } }; ri.prototype.createNetwork = function () { return new rq(this) }; ri.prototype.makeNetwork = function (a) { var b = this.createNetwork(); a instanceof Q ? (b.hg(a.nodes, !0), b.hg(a.links, !0)) : a instanceof pf ? b.hg(a.memberParts) : b.hg(a.iterator); return b };
    ri.prototype.updateParts = function () { this.isValidLayout = !0; var a = this.diagram; if (null === a && null !== this.network) for (var b = this.network.vertexes.iterator; b.next();) { var c = b.value.node; if (null !== c && (a = c.diagram, null !== a)) break } try { null !== a && a.Ea("Layout"), this.commitLayout() } finally { null !== a && a.eb("Layout") } }; ri.prototype.commitLayout = function () { if (null !== this.network) { for (var a = this.network.vertexes.iterator; a.next();)a.value.commit(); if (this.isRouting) for (a = this.network.edges.iterator; a.next();)a.value.commit() } };
    ri.prototype.doLayout = function (a) { var b = new H; a instanceof Q ? (sq(this, b, a.nodes, !0, this.mh, !0, !1, !0), sq(this, b, a.parts, !0, this.mh, !0, !1, !0)) : a instanceof pf ? sq(this, b, a.memberParts, !1, this.mh, !0, !1, !0) : b.addAll(a.iterator); 0 < b.count && (a = this.diagram, null !== a && a.Ea("Layout"), this.doMinimalNoNetworkLayout(b), null !== a && a.eb("Layout")); this.isValidLayout = !0 };
    ri.prototype.doMinimalNoNetworkLayout = function (a) { var b = Math.ceil(Math.sqrt(a.count)); this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin); var c = this.arrangementOrigin.x, d = c, e = this.arrangementOrigin.y, f = 0, g = 0; for (a = a.iterator; a.next();) { var h = a.value; tq(h); var k = h.measuredBounds, l = k.width; k = k.height; h.moveTo(d, e); h instanceof pf && (h.mh = !1); d += Math.max(l, 50) + 20; g = Math.max(g, Math.max(k, 50)); f >= b - 1 ? (f = 0, d = c, e += g + 20, g = 0) : f++ } };
    ri.prototype.doMinimalNetworkLayout = function () {
        var a = Math.ceil(Math.sqrt(this.network.vertexes.count)); this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin); for (var b = this.arrangementOrigin.x, c = b, d = this.arrangementOrigin.y, e = 0, f = 0, g = this.network.vertexes.iterator; g.next();) {
            var h = g.value.node; if (!(null === h || h instanceof T) && (tq(h), this.mh(h))) {
                var k = h.measuredBounds, l = k.width; k = k.height; h.moveTo(c, d); h instanceof pf && (h.mh = !1); c += Math.max(l, 50) + 20; f = Math.max(f, Math.max(k, 50)); e >= a - 1 ? (e =
                    0, c = b, d += f + 20, f = 0) : e++
            }
        }
    }; ri.prototype.mh = function (a) { return !a.location.w() || a instanceof pf && a.mh ? !0 : !1 }; function sq(a, b, c, d, e, f, g, h) { for (c = c.iterator; c.next();) { var k = c.value; d && !k.isTopLevel || null !== e && !e(k) || !k.canLayout() || (f && k instanceof V ? k.isLinkLabel || (k instanceof pf ? null === k.layout ? sq(a, b, k.memberParts, !1, e, f, g, h) : (tq(k), b.add(k)) : (tq(k), b.add(k))) : g && k instanceof T ? b.add(k) : !h || !k.Uc() || k instanceof V || (tq(k), b.add(k))) } }
    function tq(a) { var b = a.actualBounds; (0 === b.width || 0 === b.height || isNaN(b.width) || isNaN(b.height)) && a.Va() } ri.prototype.ki = function (a, b) { var c = this.boundsComputation; if (null !== c) return b || (b = new N), c(a, this, b); if (!b) return a.actualBounds; b.set(a.actualBounds); return b };
    ri.prototype.Yw = function (a) { var b = new H; a instanceof Q ? (sq(this, b, a.nodes, !0, null, !0, !0, !0), sq(this, b, a.links, !0, null, !0, !0, !0), sq(this, b, a.parts, !0, null, !0, !0, !0)) : a instanceof pf ? sq(this, b, a.memberParts, !1, null, !0, !0, !0) : sq(this, b, a.iterator, !1, null, !0, !0, !0); return b };
    ri.prototype.initialOrigin = function (a) { var b = this.group; if (null !== b) { var c = b.position.copy(); (isNaN(c.x) || isNaN(c.y)) && c.set(a); b = b.placeholder; null !== b && (c = b.ja(sc), (isNaN(c.x) || isNaN(c.y)) && c.set(a), a = b.padding, c.x += a.left, c.y += a.top); return c } return a };
    ma.Object.defineProperties(ri.prototype, {
        diagram: { get: function () { return this.H }, set: function (a) { this.H = a } }, group: { get: function () { return this.m }, set: function (a) { this.m !== a && (this.m = a, null !== a && (this.H = a.diagram)) } }, isOngoing: { get: function () { return this.on }, set: function (a) { this.on !== a && (this.on = a) } }, isInitial: { get: function () { return this.Ch }, set: function (a) { this.Ch = a; a || (this.tn = !0) } },
        isViewportSized: { get: function () { return this.un }, set: function (a) { this.un !== a && (this.un = a) && this.D() } }, isRouting: { get: function () { return this.rn }, set: function (a) { this.rn !== a && (this.rn = a) } }, isRealtime: { get: function () { return this.qn }, set: function (a) { this.qn !== a && (this.qn = a) } }, isValidLayout: {
            get: function () { return this.tn }, set: function (a) {
                this.tn !== a && (this.tn = a, a || (a = this.diagram, null !==
                    a && (a.sh = !0)))
            }
        }, network: { get: function () { return this.j }, set: function (a) { this.j !== a && (this.j = a, null !== a && (a.layout = this)) } }, boundsComputation: { get: function () { return this.Bm }, set: function (a) { this.Bm !== a && (this.Bm = a, this.D()) } }, arrangementOrigin: { get: function () { return this.xm }, set: function (a) { this.xm.C(a) || (this.xm.assign(a), this.D()) } }
    }); ri.prototype.collectParts = ri.prototype.Yw; ri.prototype.getLayoutBounds = ri.prototype.ki;
    ri.prototype.invalidateLayout = ri.prototype.D; ri.className = "Layout"; function rq(a) { Qa(this); this.Oc = a; this.tf = new H; this.ue = new H; this.xs = new ob; this.ss = new ob } rq.prototype.clear = function () { if (this.tf) for (var a = this.tf.iterator; a.next();)a.value.clear(); if (this.ue) for (a = this.ue.iterator; a.next();)a.value.clear(); this.tf = new H; this.ue = new H; this.xs = new ob; this.ss = new ob };
    rq.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutNetwork" + (null !== this.layout ? "(" + this.layout.toString() + ")" : ""); if (0 >= a) return b; b += " vertexes: " + this.tf.count + " edges: " + this.ue.count; if (1 < a) { for (var c = this.tf.iterator; c.next();)b += "\n    " + c.value.toString(a - 1); for (c = this.ue.iterator; c.next();)b += "\n    " + c.value.toString(a - 1) } return b }; rq.prototype.createVertex = function () { return new uq(this) }; rq.prototype.createEdge = function () { return new vq(this) };
    rq.prototype.hg = function (a, b, c) {
        if (null !== a) {
            void 0 === b && (b = !1); void 0 === c && (c = null); null === c && (c = function (a) { if (a instanceof V) return !a.isLinkLabel; if (a instanceof T) { var b = a.fromNode; if (null === b || b.isLinkLabel) return !1; a = a.toNode; return null === a || a.isLinkLabel ? !1 : !0 } return !1 }); for (a = a.iterator; a.next();) {
                var d = a.value; if (d instanceof V && (!b || d.isTopLevel) && d.canLayout() && c(d)) if (d instanceof pf && null === d.layout) this.hg(d.memberParts, !1); else if (null === this.hi(d)) {
                    var e = this.createVertex(); e.node =
                        d; this.Yg(e)
                }
            } for (a.reset(); a.next();)if (d = a.value, d instanceof T && (!b || d.isTopLevel) && d.canLayout() && c(d) && null === this.Ho(d)) { var f = d.fromNode; e = d.toNode; null !== f && null !== e && f !== e && (f = this.findGroupVertex(f), e = this.findGroupVertex(e), null !== f && null !== e && this.Wj(f, e, d)) }
        }
    }; rq.prototype.findGroupVertex = function (a) { if (null === a) return null; var b = a.findVisibleNode(); if (null === b) return null; a = this.hi(b); if (null !== a) return a; for (b = b.containingGroup; null !== b;) { a = this.hi(b); if (null !== a) return a; b = b.containingGroup } return null };
    t = rq.prototype; t.Yg = function (a) { if (null !== a) { this.tf.add(a); var b = a.node; null !== b && this.xs.add(b, a); a.network = this } }; t.xl = function (a) { if (null === a) return null; var b = this.hi(a); null === b && (b = this.createVertex(), b.node = a, this.Yg(b)); return b }; t.Cu = function (a) { if (null !== a && wq(this, a)) { for (var b = a.pg, c = b.count - 1; 0 <= c; c--) { var d = b.L(c); this.Kj(d) } b = a.ig; for (a = b.count - 1; 0 <= a; a--)c = b.L(a), this.Kj(c) } };
    function wq(a, b) { if (null === b) return !1; var c = a.tf.remove(b); c && (b = b.node, null !== b && a.xs.remove(b)); return c } t.Ry = function (a) { null !== a && (a = this.hi(a), null !== a && this.Cu(a)) }; t.hi = function (a) { return null === a ? null : this.xs.I(a) }; t.xo = function (a) { if (null !== a) { xq(this, a); var b = a.toVertex; null !== b && b.yl(a); b = a.fromVertex; null !== b && b.vl(a) } }; function xq(a, b) { if (null !== b) { a.ue.add(b); var c = b.link; null !== c && null === a.Ho(c) && a.ss.add(c, b); b.network = a } }
    t.yy = function (a) { if (null === a) return null; var b = a.fromNode, c = a.toNode, d = this.Ho(a); null === d ? (d = this.createEdge(), d.link = a, null !== b && (d.fromVertex = this.xl(b)), null !== c && (d.toVertex = this.xl(c)), this.xo(d)) : (null !== b ? d.fromVertex = this.xl(b) : d.fromVertex = null, null !== c ? d.toVertex = this.xl(c) : d.toVertex = null); return d }; t.Kj = function (a) { if (null !== a) { var b = a.toVertex; null !== b && b.Fl(a); b = a.fromVertex; null !== b && b.El(a); yq(this, a) } };
    function yq(a, b) { null !== b && a.ue.remove(b) && (b = b.link, null !== b && a.ss.remove(b)) } t.Qy = function (a) { null !== a && (a = this.Ho(a), null !== a && this.Kj(a)) }; t.Ho = function (a) { return null === a ? null : this.ss.I(a) }; t.Wj = function (a, b, c) { if (null === a || null === b) return null; if (a.network === this && b.network === this) { var d = this.createEdge(); d.link = c; d.fromVertex = a; d.toVertex = b; this.xo(d); return d } return null }; t.dm = function (a) { if (null !== a) { var b = a.fromVertex, c = a.toVertex; null !== b && null !== c && (b.El(a), c.Fl(a), a.dm(), b.yl(a), c.vl(a)) } };
    t.Go = function () { for (var a = Ea(), b = this.ue.iterator; b.next();) { var c = b.value; c.fromVertex === c.toVertex && a.push(c) } b = a.length; for (c = 0; c < b; c++)this.Kj(a[c]); Ga(a) }; rq.prototype.deleteArtificialVertexes = function () { for (var a = Ea(), b = this.tf.iterator; b.next();) { var c = b.value; null === c.node && null === c.data && a.push(c) } c = a.length; for (b = 0; b < c; b++)this.Cu(a[b]); b = Ea(); for (c = this.ue.iterator; c.next();) { var d = c.value; null === d.link && null === d.data && b.push(d) } c = b.length; for (d = 0; d < c; d++)this.Kj(b[d]); Ga(a); Ga(b) };
    function zq(a) { for (var b = Ea(), c = a.ue.iterator; c.next();) { var d = c.value; null !== d.fromVertex && null !== d.toVertex || b.push(d) } c = b.length; for (d = 0; d < c; d++)a.Kj(b[d]); Ga(b) }
    rq.prototype.Vx = function (a) { void 0 === a && (a = !0); a && (this.deleteArtificialVertexes(), zq(this), this.Go()); a = new F; for (var b = !0; b;) { b = !1; for (var c = this.tf.iterator; c.next();) { var d = c.value; if (0 < d.pg.count || 0 < d.ig.count) { b = this.layout.createNetwork(); a.add(b); Aq(this, b, d); b = !0; break } } } a.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.vertexes.count - a.vertexes.count }); return a };
    function Aq(a, b, c) { if (null !== c && c.network !== b) { wq(a, c); b.Yg(c); for (var d = c.sourceEdges; d.next();) { var e = d.value; e.network !== b && (yq(a, e), xq(b, e), Aq(a, b, e.fromVertex)) } for (d = c.destinationEdges; d.next();)c = d.value, c.network !== b && (yq(a, c), xq(b, c), Aq(a, b, c.toVertex)) } } rq.prototype.Wy = function () { for (var a = new H, b = this.tf.iterator; b.next();)a.add(b.value.node); for (b = this.ue.iterator; b.next();)a.add(b.value.link); return a };
    ma.Object.defineProperties(rq.prototype, { layout: { get: function () { return this.Oc }, set: function (a) { null !== a && (this.Oc = a) } }, vertexes: { get: function () { return this.tf } }, edges: { get: function () { return this.ue } } }); rq.prototype.findAllParts = rq.prototype.Wy; rq.prototype.splitIntoSubNetworks = rq.prototype.Vx; rq.prototype.deleteSelfEdges = rq.prototype.Go; rq.prototype.reverseEdge = rq.prototype.dm; rq.prototype.linkVertexes = rq.prototype.Wj;
    rq.prototype.findEdge = rq.prototype.Ho; rq.prototype.deleteLink = rq.prototype.Qy; rq.prototype.deleteEdge = rq.prototype.Kj; rq.prototype.addLink = rq.prototype.yy; rq.prototype.addEdge = rq.prototype.xo; rq.prototype.findVertex = rq.prototype.hi; rq.prototype.deleteNode = rq.prototype.Ry; rq.prototype.deleteVertex = rq.prototype.Cu; rq.prototype.addNode = rq.prototype.xl; rq.prototype.addVertex = rq.prototype.Yg; rq.prototype.addParts = rq.prototype.hg; rq.className = "LayoutNetwork";
    function uq(a) { Qa(this); this.pb = a; this.j = (new N(0, 0, 10, 10)).freeze(); this.m = (new I(5, 5)).freeze(); this.Oh = this.ub = null; this.pg = new F; this.ig = new F } uq.prototype.clear = function () { this.Oh = this.ub = null; this.pg = new F; this.ig = new F };
    uq.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutVertex#" + bb(this); if (0 < a && (b += null !== this.node ? "(" + this.node.toString() + ")" : "", 1 < a)) { a = ""; for (var c = !0, d = this.pg.iterator; d.next();) { var e = d.value; c ? c = !1 : a += ","; a += e.toString(0) } e = ""; c = !0; for (d = this.ig.iterator; d.next();) { var f = d.value; c ? c = !1 : e += ","; e += f.toString(0) } b += " sources: " + a + " destinations: " + e } return b };
    uq.prototype.commit = function () { var a = this.ub; if (null !== a) { var b = this.bounds, c = a.bounds; za(c) ? (c.x = b.x, c.y = b.y, c.width = b.width, c.height = b.height) : a.bounds = b.copy() } else if (a = this.node, null !== a) { b = this.bounds; if (!(a instanceof pf)) { c = N.alloc(); a.Va(); var d = this.network.layout.ki(a, c), e = a.locationObject.ja(xc); if (d.w() && e.w()) { a.moveTo(b.x + this.focusX - (e.x - d.x), b.y + this.focusY - (e.y - d.y)); N.free(c); return } N.free(c) } a.moveTo(b.x, b.y) } }; uq.prototype.yl = function (a) { null !== a && (this.pg.contains(a) || this.pg.add(a)) };
    uq.prototype.Fl = function (a) { null !== a && this.pg.remove(a) }; uq.prototype.vl = function (a) { null !== a && (this.ig.contains(a) || this.ig.add(a)) }; uq.prototype.El = function (a) { null !== a && this.ig.remove(a) }; function Bq(a, b) { a = a.Oh; b = b.Oh; return a ? b ? (a = a.text, b = b.text, a < b ? -1 : a > b ? 1 : 0) : 1 : null !== b ? -1 : 0 }
    ma.Object.defineProperties(uq.prototype, {
        sourceEdgesArrayAccess: { get: function () { return this.pg._dataArray } }, destinationEdgesArrayAccess: { get: function () { return this.ig._dataArray } }, data: { get: function () { return this.ub }, set: function (a) { this.ub = a; if (null !== a && a.bounds) { var b = a.bounds; a = b.x; var c = b.y, d = b.width; b = b.height; this.m.h(d / 2, b / 2); this.j.h(a, c, d, b) } } }, node: {
            get: function () { return this.Oh },
            set: function (a) { if (this.Oh !== a) { this.Oh = a; a.Va(); var b = this.network.layout, c = N.alloc(), d = b.ki(a, c); b = d.x; var e = d.y, f = d.width; d = d.height; isNaN(b) && (b = 0); isNaN(e) && (e = 0); this.j.h(b, e, f, d); N.free(c); if (!(a instanceof pf) && (a = a.locationObject.ja(xc), a.w())) { this.m.h(a.x - b, a.y - e); return } this.m.h(f / 2, d / 2) } }
        }, bounds: { get: function () { return this.j }, set: function (a) { this.j.C(a) || this.j.assign(a) } }, focus: {
            get: function () { return this.m }, set: function (a) {
                this.m.C(a) ||
                this.m.assign(a)
            }
        }, centerX: { get: function () { return this.j.x + this.m.x }, set: function (a) { var b = this.j; b.x + this.m.x !== a && (b.ka(), b.x = a - this.m.x, b.freeze()) } }, centerY: { get: function () { return this.j.y + this.m.y }, set: function (a) { var b = this.j; b.y + this.m.y !== a && (b.ka(), b.y = a - this.m.y, b.freeze()) } }, focusX: { get: function () { return this.m.x }, set: function (a) { var b = this.m; b.x !== a && (b.ka(), b.x = a, b.freeze()) } }, focusY: {
            get: function () { return this.m.y }, set: function (a) { var b = this.m; b.y !== a && (b.ka(), b.y = a, b.freeze()) }
        }, x: { get: function () { return this.j.x }, set: function (a) { var b = this.j; b.x !== a && (b.ka(), b.x = a, b.freeze()) } }, y: { get: function () { return this.j.y }, set: function (a) { var b = this.j; b.y !== a && (b.ka(), b.y = a, b.freeze()) } }, width: {
            get: function () { return this.j.width }, set: function (a) {
                var b = this.j; b.width !== a && (b.ka(), b.width =
                    a, b.freeze())
            }
        }, height: { get: function () { return this.j.height }, set: function (a) { var b = this.j; b.height !== a && (b.ka(), b.height = a, b.freeze()) } }, network: { get: function () { return this.pb }, set: function (a) { this.pb = a } }, sourceVertexes: { get: function () { for (var a = new H, b = this.sourceEdges; b.next();)a.add(b.value.fromVertex); return a.iterator } }, destinationVertexes: {
            get: function () {
                for (var a = new H, b =
                    this.destinationEdges; b.next();)a.add(b.value.toVertex); return a.iterator
            }
        }, vertexes: { get: function () { for (var a = new H, b = this.sourceEdges; b.next();)a.add(b.value.fromVertex); for (b = this.destinationEdges; b.next();)a.add(b.value.toVertex); return a.iterator } }, sourceEdges: { get: function () { return this.pg.iterator } }, destinationEdges: { get: function () { return this.ig.iterator } }, edges: {
            get: function () {
                for (var a =
                    new F, b = this.sourceEdges; b.next();)a.add(b.value); for (b = this.destinationEdges; b.next();)a.add(b.value); return a.iterator
            }
        }, edgesCount: { get: function () { return this.pg.count + this.ig.count } }
    }); uq.prototype.deleteDestinationEdge = uq.prototype.El; uq.prototype.addDestinationEdge = uq.prototype.vl; uq.prototype.deleteSourceEdge = uq.prototype.Fl; uq.prototype.addSourceEdge = uq.prototype.yl; uq.className = "LayoutVertex"; uq.standardComparer = Bq;
    uq.smartComparer = function (a, b) {
        if (null !== a) {
            if (null !== b) {
                a = a.Oh; var c = b.Oh; if (null !== a) {
                    if (null !== c) {
                        b = a.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/); a = c.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/); for (c = 0; c < b.length; c++)if ("" !== a[c] && void 0 !== a[c]) { var d = parseFloat(b[c]), e = parseFloat(a[c]); if (isNaN(d)) if (isNaN(e)) { if (0 !== b[c].localeCompare(a[c])) return b[c].localeCompare(a[c]) } else return 1; else { if (isNaN(e)) return -1; if (0 !== d - e) return d - e } } else if ("" !==
                            b[c]) return 1; return "" !== a[c] && void 0 !== a[c] ? -1 : 0
                    } return 1
                } return null !== c ? -1 : 0
            } return 1
        } return null !== b ? -1 : 0
    }; function vq(a) { Qa(this); this.ob = a; this.hd = this.bd = this.Vk = this.ub = null } vq.prototype.clear = function () { this.hd = this.bd = this.Vk = this.ub = null }; vq.prototype.toString = function (a) { void 0 === a && (a = 0); var b = "LayoutEdge#" + bb(this); 0 < a && (b += null !== this.Vk ? "(" + this.Vk.toString() + ")" : "", 1 < a && (b += " " + (this.bd ? this.bd.toString() : "null") + " --\x3e " + (this.hd ? this.hd.toString() : "null"))); return b };
    vq.prototype.dm = function () { var a = this.bd; this.bd = this.hd; this.hd = a }; vq.prototype.commit = function () { }; vq.prototype.ux = function (a) { return this.hd === a ? this.bd : this.bd === a ? this.hd : null };
    ma.Object.defineProperties(vq.prototype, {
        network: { get: function () { return this.ob }, set: function (a) { this.ob = a } }, data: { get: function () { return this.ub }, set: function (a) { this.ub !== a && (this.ub = a) } }, link: { get: function () { return this.Vk }, set: function (a) { this.Vk !== a && (this.Vk = a) } }, fromVertex: { get: function () { return this.bd }, set: function (a) { this.bd !== a && (this.bd = a) } }, toVertex: {
            get: function () { return this.hd }, set: function (a) { this.hd !== a && (this.hd = a) }
        }
    }); vq.prototype.getOtherVertex = vq.prototype.ux; vq.className = "LayoutEdge"; function Nk(a) { ri.call(this); this.isViewportSized = !0; this.uo = this.vo = NaN; this.ug = (new L(NaN, NaN)).freeze(); this.qf = (new L(10, 10)).freeze(); this.Hb = Cq; this.Mb = Dq; this.ed = Eq; this.$c = Fq; a && Object.assign(this, a) } la(Nk, ri);
    Nk.prototype.cloneProtected = function (a) { ri.prototype.cloneProtected.call(this, a); a.vo = this.vo; a.uo = this.uo; a.ug.assign(this.ug); a.qf.assign(this.qf); a.Hb = this.Hb; a.Mb = this.Mb; a.ed = this.ed; a.$c = this.$c }; Nk.prototype.rb = function (a) { a.classType === Nk ? a === Eq || a === Gq || a === Hq || a === Iq ? this.sorting = a : a === Dq || a === Jq ? this.arrangement = a : a === Cq || a === Kq ? this.alignment = a : C("Unknown enum value: " + a) : ri.prototype.rb.call(this, a) };
    Nk.prototype.doLayout = function (a) {
        this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin); var b = this.Yw(a); a = this.diagram; for (var c = b.copy().iterator; c.next();) { var d = c.value; if (!d.kh() || null === d.fromNode && null === d.toNode) { if (d.Va(), d instanceof pf) for (d = d.memberParts; d.next();)b.remove(d.value) } else b.remove(d) } var e = b.Fa(); if (0 !== e.length) {
            switch (this.sorting) { case Iq: e.reverse(); break; case Eq: e.sort(this.comparer); break; case Gq: e.sort(this.comparer), e.reverse() }var f = this.wrappingColumn;
            isNaN(f) && (f = 0); var g = this.wrappingWidth; isNaN(g) && null !== a ? (b = a.padding, g = Math.max(a.viewportBounds.width - b.left - b.right, 0)) : g = Math.max(this.wrappingWidth, 0); 0 >= f && 0 >= g && (f = 1); b = this.spacing.width; isFinite(b) || (b = 0); c = this.spacing.height; isFinite(c) || (c = 0); null !== a && a.Ea("Layout"); d = []; switch (this.alignment) {
                case Kq: var h = b, k = c, l = N.alloc(), m = Math.max(this.cellSize.width, 1); if (!isFinite(m)) for (var n = m = 0; n < e.length; n++) { var p = this.ki(e[n], l); m = Math.max(m, p.width) } m = Math.max(m + h, 1); n = Math.max(this.cellSize.height,
                    1); if (!isFinite(n)) for (p = n = 0; p < e.length; p++) { var r = this.ki(e[p], l); n = Math.max(n, r.height) } n = Math.max(n + k, 1); p = this.arrangement; for (var q = r = this.arrangementOrigin.x, u = this.arrangementOrigin.y, w = 0, v = 0, z = 0; z < e.length; z++) {
                        var y = e[z], A = this.ki(y, l), B = Math.ceil((A.width + h) / m) * m, E = Math.ceil((A.height + k) / n) * n; switch (p) { case Jq: var M = Math.abs(q - A.width); break; default: M = q + A.width }if (0 < f && w > f - 1 || 0 < g && 0 < w && M - r > g) d.push(new N(0, u, g + h, v)), w = 0, q = r, u += v, v = 0; v = Math.max(v, E); switch (p) {
                            case Jq: A = -A.width; break; default: A =
                                0
                        }y.moveTo(q + A, u); switch (p) { case Jq: q -= B; break; default: q += B }w++
                    } d.push(new N(0, u, g + h, v)); N.free(l); break; case Cq: k = g; m = f; n = b; p = c; g = N.alloc(); r = Math.max(this.cellSize.width, 1); f = u = l = 0; h = I.alloc(); for (q = 0; q < e.length; q++)v = e[q], w = this.ki(v, g), v = To(v, v.locationObject, v.locationSpot, h), l = Math.max(l, v.x), u = Math.max(u, w.width - v.x), f = Math.max(f, v.y); q = this.arrangement; switch (q) { case Jq: l += n; break; default: u += n }r = isFinite(r) ? Math.max(r + n, 1) : Math.max(l + u, 1); var R = v = this.arrangementOrigin.x; z = this.arrangementOrigin.y;
                    u = 0; k >= l && (k -= l); l = y = 0; B = Math.max(this.cellSize.height, 1); A = f = 0; E = !0; w = I.alloc(); for (M = 0; M < e.length; M++) {
                        var S = e[M], J = this.ki(S, g), G = To(S, S.locationObject, S.locationSpot, h); if (0 < u) switch (q) { case Jq: R = (R - v - (J.width - G.x)) / r; R = K.$(Math.round(R), R) ? Math.round(R) : Math.floor(R); R = R * r + v; break; default: R = (R - v + G.x) / r, R = K.$(Math.round(R), R) ? Math.round(R) : Math.ceil(R), R = R * r + v } else switch (q) { case Jq: y = R + G.x + J.width; break; default: y = R - G.x }switch (q) { case Jq: var ca = -(R + G.x) + y; break; default: ca = R + J.width - G.x - y }if (0 <
                            m && u > m - 1 || 0 < k && 0 < u && ca > k) { d.push(new N(0, E ? z - f : z, k + n, A + f + p)); for (R = 0; R < u && M !== u; R++) { ca = e[M - u + R]; var X = To(ca, ca.locationObject, ca.locationSpot, w); ca.moveTo(ca.position.x, ca.position.y + f - X.y) } A += p; z = E ? z + A : z + (A + f); u = A = f = 0; R = v; E = !1 } R === v && (l = q === Jq ? Math.max(l, J.width - G.x) : Math.min(l, -G.x)); f = Math.max(f, G.y); A = Math.max(A, J.height - G.y); isFinite(B) && (A = Math.max(A, Math.max(J.height, B) - G.y)); E ? S.moveTo(R - G.x, z - G.y) : S.moveTo(R - G.x, z); switch (q) { case Jq: R -= G.x + n; break; default: R += J.width - G.x + n }u++
                    } d.push(new N(0,
                        z, k + n, (E ? A : A + f) + p)); if (e.length !== u) for (k = 0; k < u; k++)m = e[e.length - u + k], n = To(m, m.locationObject, m.locationSpot, h), m.moveTo(m.position.x, m.position.y + f - n.y); I.free(h); I.free(w); if (q === Jq) for (e = 0; e < d.length; e++)f = d[e], f.width += l, f.x -= l; else for (e = 0; e < d.length; e++)f = d[e], f.x > l && (f.width += f.x - l, f.x = l); N.free(g)
            }for (h = f = g = e = 0; h < d.length; h++)k = d[h], e = Math.min(e, k.x), g = Math.min(g, k.y), f = Math.max(f, k.x + k.width); this.arrangement === Jq ? this.commitLayers(d, new I(e + b / 2 - (f + e), g - c / 2)) : this.commitLayers(d, new I(e -
                b / 2, g - c / 2)); null !== a && a.eb("Layout"); this.isValidLayout = !0
        }
    }; Nk.prototype.commitLayers = function () { }; function Fq(a, b) { a = a.text; b = b.text; return a < b ? -1 : a > b ? 1 : 0 }
    ma.Object.defineProperties(Nk.prototype, {
        wrappingWidth: { get: function () { return this.vo }, set: function (a) { this.vo !== a && (0 < a || isNaN(a)) && (this.vo = a, this.isViewportSized = isNaN(a), this.D()) } }, wrappingColumn: { get: function () { return this.uo }, set: function (a) { this.uo !== a && (0 < a || isNaN(a)) && (this.uo = a, this.D()) } }, cellSize: { get: function () { return this.ug }, set: function (a) { this.ug.C(a) || (this.ug.assign(a), this.D()) } }, spacing: {
            get: function () { return this.qf }, set: function (a) { this.qf.C(a) || (this.qf.assign(a), this.D()) }
        }, alignment: { get: function () { return this.Hb }, set: function (a) { this.Hb === a || a !== Cq && a !== Kq || (this.Hb = a, this.D()) } }, arrangement: { get: function () { return this.Mb }, set: function (a) { this.Mb === a || a !== Dq && a !== Jq || (this.Mb = a, this.D()) } }, sorting: {
            get: function () { return this.ed }, set: function (a) {
                this.ed === a || a !== Hq && a !== Iq &&
                    a !== Eq && a !== Gq || (this.ed = a, this.D())
            }
        }, comparer: { get: function () { return this.$c }, set: function (a) { this.$c !== a && (this.$c = a, this.D()) } }
    }); var Kq = new D(Nk, "Position", 0), Cq = new D(Nk, "Location", 1), Dq = new D(Nk, "LeftToRight", 2), Jq = new D(Nk, "RightToLeft", 3), Hq = new D(Nk, "Forward", 4), Iq = new D(Nk, "Reverse", 5), Eq = new D(Nk, "Ascending", 6), Gq = new D(Nk, "Descending", 7); Nk.className = "GridLayout"; Nk.standardComparer = Fq;
    Nk.smartComparer = function (a, b) {
        if (null !== a) {
            if (null !== b) {
                a = a.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/); b = b.text.toLocaleLowerCase().split(/([+-]?[.]?\d+(?:\.\d*)?(?:e[+-]?\d+)?)/); for (var c = 0; c < a.length; c++)if ("" !== b[c] && void 0 !== b[c]) { var d = parseFloat(a[c]), e = parseFloat(b[c]); if (isNaN(d)) if (isNaN(e)) { if (0 !== a[c].localeCompare(b[c])) return a[c].localeCompare(b[c]) } else return 1; else { if (isNaN(e)) return -1; if (0 !== d - e) return d - e } } else if ("" !== a[c]) return 1; return "" !== b[c] &&
                    void 0 !== b[c] ? -1 : 0
            } return 1
        } return null !== b ? -1 : 0
    }; Nk.Position = Kq; Nk.Location = Cq; Nk.LeftToRight = Dq; Nk.RightToLeft = Jq; Nk.Forward = Hq; Nk.Reverse = Iq; Nk.Ascending = Eq; Nk.Descending = Gq; function ni() { this.Kn = new H; this.zn = new H; this.Oa = new H; this.Ze = new ob; this.Ye = new ob; this.Yi = new ob; this.H = null; this.np = !1 } t = ni.prototype; t.clear = function () { this.Kn.clear(); this.zn.clear(); this.Oa.clear(); this.Ze.clear(); this.Ye.clear(); this.Yi.clear() }; t.Te = function (a) { this.H = a };
    t.li = function (a) { if (a instanceof V) { if (this.Kn.add(a), a instanceof pf) { var b = a.containingGroup; null === b ? this.H.Yh.add(a) : b.bl.add(a); b = a.layout; null !== b && (b.diagram = this.H) } } else a instanceof T ? this.zn.add(a) : a instanceof te || this.Oa.add(a); b = a.data; null === b || a instanceof te || (a instanceof T ? this.Ye.add(b, a) : this.Ze.add(b, a)) };
    t.mc = function (a) { a.Cj(); if (a instanceof V) { if (this.Kn.remove(a), a instanceof pf) { var b = a.containingGroup; null === b ? this.H.Yh.remove(a) : b.bl.remove(a); b = a.layout; null !== b && (b.diagram = null) } } else a instanceof T ? this.zn.remove(a) : a instanceof te || this.Oa.remove(a); b = a.data; null === b || a instanceof te || (a instanceof T ? this.Ye.remove(b) : this.Ze.remove(b)) };
    t.Rd = function () {
        var a = this.H; if (null !== a) {
            for (var b = a.nodeTemplateMap.iterator; b.next();) { var c = b.value, d = b.key; (!c.Uc() || c instanceof pf) && C('Invalid node template in Diagram.nodeTemplateMap: template for "' + d + '" must be a Node or a simple Part, not a Group or Link: ' + c) } for (b = a.groupTemplateMap.iterator; b.next();)c = b.value, d = b.key, c instanceof pf || C('Invalid group template in Diagram.groupTemplateMap: template for "' + d + '" must be a Group, not a normal Node or Link: ' + c); for (b = a.linkTemplateMap.iterator; b.next();)c =
                b.value, d = b.key, c instanceof T || C('Invalid link template in Diagram.linkTemplateMap: template for "' + d + '" must be a Link, not a normal Node or simple Part: ' + c); b = Ea(); for (c = a.selection.iterator; c.next();)(d = c.value.data) && b.push(d); c = Ea(); for (a = a.highlighteds.iterator; a.next();)(d = a.value.data) && c.push(d); a = Ea(); for (d = this.nodes.iterator; d.next();) { var e = d.value; null !== e.data && (a.push(e.data), a.push(e.location)) } for (d = this.links.iterator; d.next();)e = d.value, null !== e.data && (a.push(e.data), a.push(e.location));
            for (d = this.parts.iterator; d.next();)e = d.value, null !== e.data && (a.push(e.data), a.push(e.location)); this.removeAllModeledParts(); this.addAllModeledParts(); for (d = 0; d < b.length; d++)e = this.Ic(b[d]), null !== e && (e.isSelected = !0); for (d = 0; d < c.length; d++)e = this.Ic(c[d]), null !== e && (e.isHighlighted = !0); for (d = 0; d < a.length; d += 2)e = this.Ic(a[d]), null !== e && (e.location = a[d + 1]); Ga(b); Ga(c); Ga(a)
        }
    }; ni.prototype.addAllModeledParts = function () { this.addModeledParts(this.diagram.model.nodeDataArray) };
    ni.prototype.addModeledParts = function (a, b) { var c = this, d = this.diagram.model; a.forEach(function (a) { d.mb(a) && Lq(c, a, !1) }); a.forEach(function (a) { d.mb(a) && c.resolveReferencesForData(a) }); !1 !== b && ek(this.diagram, !1) };
    function Lq(a, b, c) { if (void 0 !== b && null !== b && !a.diagram.undoManager.isUndoingRedoing && !a.Ze.contains(b)) { void 0 === c && (c = !0); a: { if (void 0 !== b && null !== b && !a.H.undoManager.isUndoingRedoing && !a.Ze.contains(b)) { var d = a.Lo(b); var e = ep(a, b, d); if (null !== e && (jg(e), e = e.copy(), null !== e)) { var f = a.diagram.skipsModelSourceBindings; a.diagram.skipsModelSourceBindings = !0; e.Qf = d; e.ub = b; a.np && (e.Eg = "Tool"); a.diagram.add(e); e.ub = null; e.data = b; a.diagram.skipsModelSourceBindings = f; d = e; break a } } d = null } null !== d && c && a.resolveReferencesForData(b) } }
    ni.prototype.insertLink = function () { return null }; ni.prototype.resolveReferencesForData = function () { }; ni.prototype.Lo = function (a) { return this.H.model.Lo(a) };
    function ep(a, b, c) { a = a.H; var d = a.model; d.Uj() && d.Uu(b) ? (b = a.groupTemplateMap.I(c), null === b && (b = a.groupTemplateMap.I(""), null === b && (Mq || (Mq = !0, ya('No Group template found for category "' + c + '"'), ya("  Using default group template")), b = a.hy))) : (b = a.nodeTemplateMap.I(c), null === b && (b = a.nodeTemplateMap.I(""), null === b && (Nq || (Nq = !0, ya('No Node template found for category "' + c + '"'), ya("  Using default node template")), b = a.jy))); return b } ni.prototype.getLinkCategoryForData = function () { return "" };
    ni.prototype.setLinkCategoryForData = function () { }; ni.prototype.setFromNodeForLink = function () { }; ni.prototype.setToNodeForLink = function () { }; ni.prototype.findLinkTemplateForCategory = function (a) { var b = this.H, c = b.linkTemplateMap.I(a); null === c && (c = b.linkTemplateMap.I(""), null === c && (Oq || (Oq = !0, ya('No Link template found for category "' + a + '"'), ya("  Using default link template")), c = b.iy)); return c }; ni.prototype.removeAllModeledParts = function () { this.zs(this.diagram.model.nodeDataArray) };
    ni.prototype.zs = function (a) { var b = this; a.forEach(function (a) { b.bp(a) }) }; ni.prototype.bp = function (a) { a = this.Ic(a); null !== a && (Mj(this.diagram, a, !1), this.unresolveReferencesForPart(a)) }; ni.prototype.unresolveReferencesForPart = function () { }; ni.prototype.removeDataForLink = function () { }; ni.prototype.findPartForKey = function (a) { if (null === a || void 0 === a) return null; a = this.H.model.dc(a); return null !== a ? this.Ze.I(a) : null };
    ni.prototype.Rb = function (a) { if (null === a || void 0 === a) return null; a = this.H.model.dc(a); if (null === a) return null; a = this.Ze.I(a); return a instanceof V ? a : null }; ni.prototype.findLinkForKey = function () { return null }; t = ni.prototype; t.Ic = function (a) { if (null === a) return null; var b = this.Ze.I(a); return null !== b ? b : b = this.Ye.I(a) }; t.fi = function (a) { if (null === a) return null; a = this.Ze.I(a); return a instanceof V ? a : null }; t.Hc = function (a) { return null === a ? null : this.Ye.I(a) };
    t.es = function (a) { for (var b = 0; b < arguments.length; ++b); b = new H; for (var c = this.Kn.iterator; c.next();) { var d = c.value, e = d.data; if (null !== e) for (var f = 0; f < arguments.length; f++) { var g = arguments[f]; if (za(g) && Pq(this, e, g)) { b.add(d); break } } } return b.iterator }; t.ds = function (a) { for (var b = 0; b < arguments.length; ++b); b = new H; for (var c = this.zn.iterator; c.next();) { var d = c.value, e = d.data; if (null !== e) for (var f = 0; f < arguments.length; f++) { var g = arguments[f]; if (za(g) && Pq(this, e, g)) { b.add(d); break } } } return b.iterator };
    function Pq(a, b, c) { for (var d in c) { var e = b[d], f = c[d]; if (Aa(f)) { if (!Aa(e) || e.length < f.length) return !1; for (var g = 0; g < e.length; g++) { var h = f[g]; if (void 0 !== h && !Qq(a, e[g], h)) return !1 } } else if (!Qq(a, e, f)) return !1 } return !0 } function Qq(a, b, c) { if ("function" === typeof c) { if (!c(b)) return !1 } else if (c instanceof RegExp) { if (!b || !c.test(b.toString())) return !1 } else if (za(b) && za(c)) { if (!Pq(a, b, c)) return !1 } else if (b !== c) return !1; return !0 }
    ni.prototype.doModelChanged = function (a) {
        if (this.H) {
            var b = this.H; if (a.model === b.model) {
                var c = a.change; b.doModelChanged(a); if (b.aa) {
                    b.aa = !1; try {
                        var d = a.modelChange; if ("" !== d) if (c === ce) { if ("nodeCategory" === d) { var e = this.Ic(a.object), f = a.newValue; null !== e && "string" === typeof f && (e.category = f) } else "nodeDataArray" === d && (this.zs(a.oldValue), this.addModeledParts(a.newValue)); b.isModified = !0 } else if (c === ee) { var g = a.newValue; "nodeDataArray" === d && za(g) && Lq(this, g); b.isModified = !0 } else if (c === fe) {
                            var h = a.oldValue;
                            "nodeDataArray" === d && za(h) && this.bp(h); b.isModified = !0
                        } else c === de && ("SourceChanged" === d ? null !== a.object ? this.updateDataBindings(a.object, a.propertyName) : (this.lp(), this.updateAllTargetBindings()) : "ModelDisplaced" === d && this.Rd()); else if (c === ce) { var k = a.propertyName, l = a.object; if (l === b.model) { if ("nodeKeyProperty" === k || "nodeCategoryProperty" === k) b.undoManager.isUndoingRedoing || this.Rd() } else this.updateDataBindings(l, k); b.isModified = !0 } else if (c === ee || c === fe) {
                            var m = a.change === ee, n = m ? a.newParam : a.oldParam,
                            p = m ? a.newValue : a.oldValue, r = this.Yi.I(a.object); if (Array.isArray(r)) for (a = 0; a < r.length; a++) { var q = r[a]; if (m) xn(q, p, n, !0); else if (!(0 > n)) { var u = n + pn(q); q.mc(u, !0); An(q, u, n) } } b.isModified = !0
                        }
                    } finally { b.aa = !0 }
                }
            }
        }
    }; ni.prototype.updateAllTargetBindings = function (a) { void 0 === a && (a = ""); for (var b = this.parts.iterator; b.next();)b.value.Ma(a); for (b = this.nodes.iterator; b.next();)b.value.Ma(a); for (b = this.links.iterator; b.next();)b.value.Ma(a) };
    ni.prototype.lp = function () {
        for (var a = this.H.model, b = new H, c = a.nodeDataArray, d = 0; d < c.length; d++)b.add(c[d]); var e = []; this.nodes.each(function (a) { null === a.data || b.contains(a.data) || e.push(a.data) }); this.parts.each(function (a) { null === a.data || b.contains(a.data) || e.push(a.data) }); e.forEach(function (b) { Rq(a, b, !1) }); for (d = 0; d < c.length; d++) { var f = c[d]; null === this.Ic(f) && Sq(a, f, !1) } this.refreshDataBoundLinks(); for (c = this.parts.iterator; c.next();)c.value.updateRelationshipsFromData(); for (c = this.nodes.iterator; c.next();)c.value.updateRelationshipsFromData();
        for (c = this.links.iterator; c.next();)c.value.updateRelationshipsFromData()
    }; ni.prototype.refreshDataBoundLinks = function () { }; ni.prototype.updateRelationshipsFromData = function () { };
    ni.prototype.updateDataBindings = function (a, b) { if ("string" === typeof b) { var c = this.Ic(a); if (null !== c) c.Ma(b); else { c = null; for (var d = this.Yi.iterator; d.next();) { for (var e = d.value, f = 0; f < e.length; f++) { var g = e[f].lx(a); null !== g && (null === c && (c = Ea()), c.push(g)) } if (null !== c) break } if (null !== c) { for (d = 0; d < c.length; d++)c[d].Ma(b); Ga(c) } } a === this.diagram.model.modelData && this.updateAllTargetBindings(b) } };
    function Ij(a, b) { var c = b.Dh; if (Aa(c)) { var d = a.Yi.I(c); if (null === d) d = [], d.push(b), a.Yi.add(c, d); else { for (a = 0; a < d.length; a++)if (d[a] === b) return; d.push(b) } } } function Lj(a, b, c) { Gj(b, function (a) { a = a.P.s; for (var b = a.length, d = 0; d < b; d++)dk(c, a[d]) }); var d = b.Dh; if (Aa(d)) { var e = a.Yi.I(d); if (null !== e) for (var f = 0; f < e.length; f++)if (e[f] === b) { e.splice(f, 1); 0 === e.length && a.Yi.remove(d); break } } }
    ni.prototype.Ij = function (a, b, c) {
        void 0 === c && (c = !1); var d = new ob; if (Aa(a)) for (var e = 0; e < a.length; e++)Tq(this, a[e], b, d, c); else for (a = a.iterator; a.next();)Tq(this, a.value, b, d, c); if (null !== b) {
            c = b.model; a = b.toolManager.findTool("Dragging"); a = null !== a ? a.dragOptions.dragsLink : b.Tm.dragsLink; e = new H; for (var f = new ob, g = d.iterator; g.next();) {
                var h = g.value; if (h instanceof T) a || null !== h.fromNode && null !== h.toNode || e.add(h); else if (h instanceof V && null !== h.data && c.Pl()) {
                    var k = h; h = g.key; var l = h.lg(); null !== l && (l =
                        d.I(l), null !== l ? (c.me(k.data, c.va(l.data)), k = b.Hc(k.data), h = h.fh(), null !== h && null !== k && f.add(h, k)) : c.me(k.data, void 0))
                }
            } 0 < e.count && b.Bs(e, !1); if (0 < f.count) for (c = f.iterator; c.next();)d.add(c.key, c.value)
        } if (null !== b && null !== this.H && (b = b.model, c = b.afterCopyFunction, null !== c)) { var m = new ob; d.each(function (a) { null !== a.key.data && m.add(a.key.data, a.value.data) }); c(m, b, this.H.model) } for (b = d.iterator; b.next();)b.value.Ma(); return d
    };
    function Tq(a, b, c, d, e) {
        if (null === b || e && !b.canCopy()) return null; if (d.contains(b)) return d.I(b); var f = a.copyPartData(b, c); if (!(f instanceof U)) return null; f.isSelected = !1; f.isHighlighted = !1; d.add(b, f); if (b instanceof V) {
            for (var g = b.linksConnected; g.next();) { var h = g.value; if (h.fromNode === b) { var k = d.I(h); null !== k && (k.fromNode = f) } h.toNode === b && (h = d.I(h), null !== h && (h.toNode = f)) } if (b instanceof pf && f instanceof pf) for (b = b.memberParts; b.next();)g = Tq(a, b.value, c, d, e), g instanceof T || null === g || (g.containingGroup =
                f)
        } else if (b instanceof T && f instanceof T) for (g = b.fromNode, null !== g && (g = d.I(g), null !== g && (f.fromNode = g)), g = b.toNode, null !== g && (g = d.I(g), null !== g && (f.toNode = g)), b = b.labelNodes; b.next();)g = Tq(a, b.value, c, d, e), null !== g && g instanceof V && (g.labeledLink = f); return f
    }
    ni.prototype.copyPartData = function (a, b) { var c = null, d = a.data; if (null !== d && null !== b) { var e = b.model; a instanceof T || (d = e.copyNodeData(d), za(d) && (e.uf(d), c = b.Ic(d))) } else jg(a), c = a.copy(), null !== c && (e = this.H, null !== b ? b.add(c) : null !== d && null !== e && null !== e.commandHandler && e.commandHandler.copiesClipboardData && (b = e.model, e = null, c instanceof T || (e = b.copyNodeData(d)), za(e) && (c.data = e))); return c };
    ma.Object.defineProperties(ni.prototype, { nodes: { get: function () { return this.Kn } }, links: { get: function () { return this.zn } }, parts: { get: function () { return this.Oa } }, diagram: { get: function () { return this.H } }, addsToTemporaryLayer: { get: function () { return this.np }, set: function (a) { this.np = a } } }); ni.prototype.updateAllRelationshipsFromData = ni.prototype.lp;
    ni.prototype.findLinksByExample = ni.prototype.ds; ni.prototype.findNodesByExample = ni.prototype.es; ni.prototype.findLinkForData = ni.prototype.Hc; ni.prototype.findNodeForData = ni.prototype.fi; ni.prototype.findPartForData = ni.prototype.Ic; ni.prototype.findNodeForKey = ni.prototype.Rb; ni.prototype.removeModeledPart = ni.prototype.bp; ni.prototype.removeModeledParts = ni.prototype.zs; ni.prototype.rebuildParts = ni.prototype.Rd; var Nq = !1, Mq = !1, Oq = !1; ni.className = "PartManager";
    function Uq(a) { ni.apply(this, arguments) } la(Uq, ni); Uq.prototype.addAllModeledParts = function () { var a = this.diagram.model; this.addModeledParts(a.nodeDataArray); Vq(this, a.linkDataArray) }; Uq.prototype.addModeledParts = function (a) { ni.prototype.addModeledParts.call(this, a, !1); for (a = this.links.iterator; a.next();)fp(a.value); ek(this.diagram, !1) }; function Vq(a, b) { b.forEach(function (b) { Wq(a, b) }); ek(a.diagram, !1) }
    function Wq(a, b) {
        if (void 0 !== b && null !== b && !a.diagram.undoManager.isUndoingRedoing && !a.Ye.contains(b)) {
            var c = a.getLinkCategoryForData(b), d = a.findLinkTemplateForCategory(c); if (null !== d) {
                jg(d); var e = d.copy(); if (null !== e) {
                    d = a.diagram.skipsModelSourceBindings; a.diagram.skipsModelSourceBindings = !0; e.Qf = c; e.ub = b; c = a.diagram.model; var f = Xq(c, b, !0); "" !== f && (e.fromPortId = f); f = Yq(c, b, !0); void 0 !== f && (f = a.Rb(f), f instanceof V && (e.fromNode = f)); f = Xq(c, b, !1); "" !== f && (e.toPortId = f); f = Yq(c, b, !1); void 0 !== f && (f = a.Rb(f),
                        f instanceof V && (e.toNode = f)); c = c.Bf(b); Array.isArray(c) && c.forEach(function (b) { b = a.Rb(b); null !== b && (b.labeledLink = e) }); a.np && (e.Eg = "Tool"); a.diagram.add(e); e.ub = null; e.data = b; a.diagram.skipsModelSourceBindings = d
                }
            }
        }
    } Uq.prototype.removeAllModeledParts = function () { var a = this.diagram.model; Zq(this, a.linkDataArray); this.zs(a.nodeDataArray) }; function Zq(a, b) { b.forEach(function (b) { a.bp(b) }) } Uq.prototype.getLinkCategoryForData = function (a) { return this.diagram.model.hs(a) };
    Uq.prototype.setLinkCategoryForData = function (a, b) { return this.diagram.model.fp(a, b) }; Uq.prototype.setFromNodeForLink = function (a, b) { var c = this.diagram.model; c.pv(a.data, c.va(null !== b ? b.data : null)) }; Uq.prototype.setToNodeForLink = function (a, b) { var c = this.diagram.model; c.uv(a.data, c.va(null !== b ? b.data : null)) }; Uq.prototype.removeDataForLink = function (a) { this.diagram.model.Zl(a.data) };
    Uq.prototype.findPartForKey = function (a) { var b = ni.prototype.findPartForKey.call(this, a); return null === b && (a = this.diagram.model.eh(a), null !== a) ? this.Ye.I(a) : b }; Uq.prototype.findLinkForKey = function (a) { if (null === a || void 0 === a) return null; a = this.diagram.model.eh(a); return null !== a ? this.Ye.I(a) : null };
    Uq.prototype.doModelChanged = function (a) {
        var b = this; ni.prototype.doModelChanged.call(this, a); if (this.diagram) {
            var c = this.diagram; if (a.model === c.model) {
                var d = a.change; if (c.aa) {
                    c.aa = !1; try {
                        var e = a.modelChange; if ("" !== e) if (d === ce) {
                            if ("linkFromKey" === e) { var f = this.Hc(a.object); if (null !== f) { var g = this.Rb(a.newValue); f.fromNode = g } } else if ("linkToKey" === e) { var h = this.Hc(a.object); if (null !== h) { var k = this.Rb(a.newValue); h.toNode = k } } else if ("linkFromPortId" === e) {
                                var l = this.Hc(a.object); if (null !== l) {
                                    var m = a.newValue;
                                    "string" === typeof m && (l.fromPortId = m)
                                }
                            } else if ("linkToPortId" === e) { var n = this.Hc(a.object); if (null !== n) { var p = a.newValue; "string" === typeof p && (n.toPortId = p) } } else if ("nodeGroupKey" === e) { var r = this.Ic(a.object); if (null !== r) { var q = a.newValue; if (void 0 !== q) { var u = this.Rb(q); u instanceof pf ? r.containingGroup = u : r.containingGroup = null } else r.containingGroup = null } } else if ("linkLabelKeys" === e) {
                                var w = this.Hc(a.object); if (null !== w) {
                                    var v = a.oldValue, z = a.newValue; Array.isArray(v) && v.forEach(function (a) {
                                        0 <= z.indexOf(a) ||
                                        (a = b.Rb(a), null !== a && (a.labeledLink = null))
                                    }); Array.isArray(z) && z.forEach(function (a) { a = b.Rb(a); null !== a && (a.labeledLink = w) })
                                }
                            } else if ("linkCategory" === e) { var y = this.Hc(a.object), A = a.newValue; null !== y && "string" === typeof A && (y.category = A) } else "linkDataArray" === e && (Zq(this, a.oldValue), Vq(this, a.newValue)); c.isModified = !0
                        } else if (d === ee) {
                            var B = a.newValue; if ("linkDataArray" === e && "object" === typeof B && null !== B) Wq(this, B); else if ("linkLabelKeys" === e && $q(B)) {
                                var E = this.Hc(a.object), M = this.Rb(B); null !== E &&
                                    null !== M && (M.labeledLink = E)
                            } c.isModified = !0
                        } else { if (d === fe) { var R = a.oldValue; if ("linkDataArray" === e && "object" === typeof R && null !== R) this.bp(R); else if ("linkLabelKeys" === e && $q(R)) { var S = this.Rb(R); null !== S && (S.labeledLink = null) } c.isModified = !0 } } else if (d === ce) {
                            var J = a.propertyName; a.object !== c.model || "linkFromKeyProperty" !== J && "linkToKeyProperty" !== J && "linkFromPortIdProperty" !== J && "linkToPortIdProperty" !== J && "linkLabelKeysProperty" !== J && "nodeIsGroupProperty" !== J && "nodeGroupKeyProperty" !== J && "linkCategoryProperty" !==
                                J || c.undoManager.isUndoingRedoing || this.Rd(); c.isModified = !0
                        }
                    } finally { c.aa = !0 }
                }
            }
        }
    }; Uq.prototype.refreshDataBoundLinks = function () { var a = this, b = this.diagram.model, c = new H, d = b.linkDataArray; d.forEach(function (a) { c.add(a) }); var e = []; this.links.each(function (a) { null === a.data || c.contains(a.data) || e.push(a.data) }); e.forEach(function (a) { ar(b, a, !1) }); d.forEach(function (c) { null === a.Hc(c) && br(b, c, !1) }) };
    Uq.prototype.updateRelationshipsFromData = function (a) {
        var b = a.data; if (null !== b) {
            var c = a.diagram; if (null !== c) {
                var d = c.model; if (a instanceof T) {
                    var e = Yq(d, b, !0); e = c.Rb(e); a.fromNode = e; e = Yq(d, b, !1); e = c.Rb(e); a.toNode = e; b = d.Bf(b); if (0 < b.length || 0 < a.labelNodes.count) {
                        if (1 === b.length && 1 === a.labelNodes.count) { e = b[0]; var f = a.labelNodes.first(); if (d.va(f.data) === e) return } e = (new H).addAll(b); var g = new H; a.labelNodes.each(function (a) { null !== a.data && (a = d.va(a.data), void 0 !== a && g.add(a)) }); b = g.copy(); b.ap(e); e =
                            e.copy(); e.ap(g); if (0 < b.count || 0 < e.count) b.each(function (b) { b = c.Rb(b); null !== b && b.labeledLink === a && (b.labeledLink = null) }), e.each(function (b) { b = c.Rb(b); null !== b && b.labeledLink !== a && (b.labeledLink = a) })
                    }
                } else !(a instanceof te) && (b = d.gh(b), b = c.findPartForKey(b), null === b || b instanceof pf) && (a.containingGroup = b)
            }
        }
    };
    Uq.prototype.resolveReferencesForData = function (a) {
        var b = this.diagram.model, c = b.va(a); if (void 0 !== c) {
            var d = cr(b, c), e = this.Ic(a); if (null !== d && null !== e) {
                d = d.iterator; for (var f = {}; d.next();) {
                    var g = d.value; b.mb(g) ? e instanceof pf && b.gh(g) === c && (g = this.Ic(g), null !== g && (g.containingGroup = e)) : (f.link = this.Hc(g), null !== f.link && e instanceof V && (Yq(b, g, !0) === c && (f.link.fromNode = e), Yq(b, g, !1) === c && (f.link.toNode = e), g = b.Bf(g), Array.isArray(g) && g.some(function (a) {
                        return function (b) {
                            return b === c ? (e.labeledLink =
                                a.link, !0) : !1
                        }
                    }(f)))); f = { link: f.link }
                } dr(b, c)
            } a = b.gh(a); void 0 !== a && (a = this.Rb(a), a instanceof pf && (e.containingGroup = a))
        }
    }; Uq.prototype.unresolveReferencesForPart = function (a) { var b = this.diagram.model; if (a instanceof V) { var c = b.va(a.data); if (void 0 !== c) { for (var d = a.linksConnected; d.next();)er(b, c, d.value.data); a.isLinkLabel && (d = a.labeledLink, null !== d && er(b, c, d.data)); if (a instanceof pf) for (a = a.memberParts; a.next();)d = a.value.data, b.mb(d) && er(b, c, d) } } };
    Uq.prototype.copyPartData = function (a, b) { var c = ni.prototype.copyPartData.call(this, a, b); if (a instanceof T) if (a = a.data, null !== a && null !== b) { var d = b.model; a = d.Fo(a); "object" === typeof a && null !== a && (d.ci(a), c = b.Hc(a)) } else null !== c && (b = this.diagram, null !== a && null !== b && null !== b.commandHandler && b.commandHandler.copiesClipboardData && (b = b.model.Fo(a), "object" === typeof b && null !== b && (c.data = b))); return c };
    Uq.prototype.insertLink = function (a, b, c, d) {
        var e = this.diagram, f = e.model, g = e.toolManager.findTool("Linking"), h = ""; null !== a && (null === b && (b = a), h = b.portId, null === h && (h = "")); b = ""; null !== c && (null === d && (d = c), b = d.portId, null === b && (b = "")); d = g.archetypeLinkData; if (d instanceof T) { if (jg(d), f = d.copy(), null !== f) return f.fromNode = a, f.fromPortId = h, f.toNode = c, f.toPortId = b, e.add(f), a = g.archetypeLabelNodeData, a instanceof V && (jg(a), a = a.copy(), null !== a && (a.labeledLink = f, e.add(a))), f } else if (null !== d && (d = f.Fo(d), "object" ===
            typeof d && null !== d)) return null !== a && fr(f, d, f.va(a.data), !0), gr(f, d, h, !0), null !== c && fr(f, d, f.va(c.data), !1), gr(f, d, b, !1), f.ci(d), a = g.archetypeLabelNodeData, null === a || a instanceof V || (a = f.copyNodeData(a), "object" === typeof a && null !== a && (f.uf(a), a = f.va(a), void 0 !== a && f.wu(d, a))), e.Hc(d); return null
    }; Uq.prototype.findLinkForKey = Uq.prototype.findLinkForKey; Uq.prototype.findPartForKey = Uq.prototype.findPartForKey; Uq.prototype.removeAllModeledParts = Uq.prototype.removeAllModeledParts;
    Uq.prototype.addModeledParts = Uq.prototype.addModeledParts; Uq.prototype.addAllModeledParts = Uq.prototype.addAllModeledParts; Uq.className = "GraphLinksPartManager"; function hr() { ni.apply(this, arguments); this.Ng = null } la(hr, ni);
    function ir(a, b, c) { if (null !== b && null !== c && null === c.fh()) { var d = a.diagram.toolManager.findTool("Linking"), e = b, f = c; a.diagram.isTreePathToChildren || (e = c, f = b); if (null === d || !Ff(d, e, f, null, !0)) if (b = a.getLinkCategoryForData(c.data), d = a.findLinkTemplateForCategory(b), null !== d && (jg(d), d = d.copy(), null !== d)) { var g = a.diagram.skipsModelSourceBindings; a.diagram.skipsModelSourceBindings = !0; d.Qf = b; d.ub = c.data; d.fromNode = e; d.toNode = f; a.diagram.add(d); d.ub = null; d.data = c.data; a.diagram.skipsModelSourceBindings = g } } }
    hr.prototype.getLinkCategoryForData = function (a) { return this.diagram.model.ls(a) }; hr.prototype.setLinkCategoryForData = function (a, b) { this.diagram.model.Fs(a, b) }; hr.prototype.setFromNodeForLink = function (a, b, c) { var d = this.diagram.model; void 0 === c && (c = null); b = null !== b ? b.data : null; if (this.diagram.isTreePathToChildren) d.me(a.data, d.va(b)); else { var e = this.Ng; this.Ng = a; null !== c && d.me(c.data, void 0); d.me(b, d.va(null !== a.toNode ? a.toNode.data : null)); this.Ng = e } };
    hr.prototype.setToNodeForLink = function (a, b, c) { var d = this.diagram.model; void 0 === c && (c = null); b = null !== b ? b.data : null; if (this.diagram.isTreePathToChildren) { var e = this.Ng; this.Ng = a; null !== c && d.me(c.data, void 0); d.me(b, d.va(null !== a.fromNode ? a.fromNode.data : null)); this.Ng = e } else d.me(a.data, d.va(b)) }; hr.prototype.removeDataForLink = function (a) { this.diagram.model.me(a.data, void 0) };
    hr.prototype.findLinkForKey = function (a) { if (null === a || void 0 === a) return null; a = this.diagram.model.dc(a); return null !== a ? this.Ye.I(a) : null };
    hr.prototype.doModelChanged = function (a) {
        ni.prototype.doModelChanged.call(this, a); if (this.diagram) {
            var b = this.diagram; if (a.model === b.model) {
                var c = a.change; if (b.aa) {
                    b.aa = !1; try {
                        var d = a.modelChange; if ("" !== d) {
                            if (c === ce) {
                                if ("nodeParentKey" === d) {
                                    var e = a.object, f = this.Rb(a.newValue), g = this.fi(e); if (null !== this.Ng) null !== f && (this.Ng.data = e, this.Ng.category = this.getLinkCategoryForData(e)); else if (null !== g) {
                                        var h = g.fh(); null !== h ? null === f ? b.remove(h) : b.isTreePathToChildren ? h.fromNode = f : h.toNode = f : ir(this, f,
                                            g)
                                    }
                                } else if ("parentLinkCategory" === d) { var k = this.fi(a.object), l = a.newValue; if (null !== k && "string" === typeof l) { var m = k.fh(); null !== m && (m.category = l) } } b.isModified = !0
                            }
                        } else if (c === ce) { var n = a.propertyName; a.object === b.model && "nodeParentKeyProperty" === n && (b.undoManager.isUndoingRedoing || this.Rd()); b.isModified = !0 }
                    } finally { b.aa = !0 }
                }
            }
        }
    };
    hr.prototype.updateRelationshipsFromData = function (a) { var b = a.data; if (null !== b) { var c = a.diagram; if (null !== c) { var d = c.model; a instanceof V && (b = d.hh(b), b = c.Rb(b), d = a.lg(), b !== d && (d = a.fh(), null !== b ? null !== d ? c.isTreePathToChildren ? d.fromNode = b : d.toNode = b : ir(this, b, a) : null !== d && Mj(c, d, !1))) } } }; hr.prototype.updateDataBindings = function (a, b) { ni.prototype.updateDataBindings.call(this, a, b); "string" === typeof b && null !== this.Ic(a) && (a = this.Hc(a), null !== a && a.Ma(b)) };
    hr.prototype.resolveReferencesForData = function (a) { var b = this.diagram.model, c = b.va(a); if (void 0 !== c) { var d = cr(b, c), e = this.Ic(a); if (null !== d && null !== e) { for (d = d.iterator; d.next();) { var f = d.value; b.mb(f) && e instanceof V && b.hh(f) === c && ir(this, e, this.fi(f)) } dr(b, c) } a = b.hh(a); void 0 !== a && e instanceof V && (a = this.Rb(a), ir(this, a, e)) } };
    hr.prototype.unresolveReferencesForPart = function (a) { var b = this.diagram.model; if (a instanceof V) { var c = b.va(a.data), d = this.Hc(a.data); if (null !== d) { d.isSelected = !1; d.isHighlighted = !1; var e = d.layer; if (null !== e) { var f = e.mc(-1, d, !1); 0 <= f && this.diagram.cb(fe, "parts", e, d, null, f, null); f = d.layerChanged; null !== f && f(d, e, null) } } d = this.diagram.isTreePathToChildren; for (a = a.linksConnected; a.next();)e = a.value, e = (d ? e.toNode : e.fromNode).data, b.mb(e) && er(b, c, e) } };
    hr.prototype.insertLink = function (a, b, c) { b = this.diagram.model; var d = a, e = c; this.diagram.isTreePathToChildren || (d = c, e = a); return null !== d && null !== e ? (b.me(e.data, b.va(d.data)), e.fh()) : null }; hr.prototype.findLinkForKey = hr.prototype.findLinkForKey; hr.className = "TreePartManager";
    function Z(a, b) { this.fl = new H; this.dl = new H; this.Pv = ',\n  "insertedNodeKeys": '; this.ey = ',\n  "modifiedNodeData": '; this.Rv = ',\n  "removedNodeKeys": '; this.qh = null; Qa(this); this.Pm = this.wa = ""; this.Cg = !1; this.j = {}; this.Pc = []; this.lb = new ob; this.Ph = "key"; this.wk = this.Xk = null; this.Gm = this.Hm = !1; this.Jm = !0; this.um = null; this.jj = "category"; this.Sf = new ob; this.Et = new F; this.Qg = !1; this.m = null; this.undoManager = new he; void 0 !== a && (Aa(a) ? this.nodeDataArray = a : Object.assign(this, a)); b && Object.assign(this, b) }
    Z.prototype.cloneProtected = function (a) { a.wa = this.wa; a.Pm = this.Pm; a.Cg = this.Cg; a.Ph = this.Ph; a.Xk = this.Xk; a.wk = this.wk; a.Hm = this.Hm; a.Gm = this.Gm; a.Jm = this.Jm; a.um = this.um; a.jj = this.jj }; Z.prototype.copy = function () { var a = new this.constructor; this.cloneProtected(a); return a }; Z.prototype.clear = function () { this.Pc = []; this.lb.clear(); this.Sf.clear(); this.undoManager.clear() };
    Z.prototype.toString = function (a) { void 0 === a && (a = 0); if (1 < a) return this.hp(); var b = ("" !== this.name ? this.name : "") + " Model"; if (0 < a) { b += "\n node data:"; a = this.nodeDataArray; for (var c = a.length, d = 0; d < c; d++) { var e = a[d]; b += " " + this.va(e) + ":" + Ia(e) } } return b };
    Z.prototype.nA = function (a) { a.change !== de && C("Model.toIncrementalData argument is not a Transaction ChangedEvent:" + a.toString()); var b = a.object; if (!(a.isTransactionFinished && b instanceof ge)) return null; jr(this, b); a = this.Jv(b, "FinishedUndo" === a.propertyName); this.qh = null; return a };
    Z.prototype.Jv = function (a, b) {
        var c = this, d = !1, e = new H, f = new H, g = new H, h = this.qh; a.changes.each(function (a) {
            a.model === c && ("nodeDataArray" === a.modelChange ? a.change === ee ? e.add(a.newValue) : a.change === fe && g.add(a.oldValue) : c.mb(a.object) ? f.add(a.object) : a.change !== ce || c.modelData !== a.object && "modelData" !== a.propertyName ? null !== a.object && (h && h.contains(a.object) ? h.get(a.object).each(function (a) { c.mb(a) && f.add(a) }) : a.object instanceof Z || c.ni() && c.Gc(a.object) || kr(c, a.object).each(function (a) { f.add(a) })) :
                d = !0)
        }); var k = this.dl; k.clear(); e.each(function (a) { k.add(c.va(a)); b || f.add(a) }); var l = this.fl; l.clear(); g.each(function (a) { l.add(c.va(a)); b && f.add(a) }); a = c.cloneDeep(f.Fa()); var m = null; d && (null === m && (m = {}), m.modelData = this.cloneDeep(this.modelData)); 0 < k.count && (null === m && (m = {}), b ? m.removedNodeKeys = k.Fa() : m.insertedNodeKeys = k.Fa()); 0 < a.length && (null === m && (m = {}), m.modifiedNodeData = a); 0 < l.count && (null === m && (m = {}), b ? m.insertedNodeKeys = l.Fa() : m.removedNodeKeys = l.Fa()); l.clear(); k.clear(); return m
    };
    Z.prototype.cloneDeep = function (a) { return lr(this, a, !0) };
    function lr(a, b, c, d, e, f) {
        function g(a, c) { h ? (void 0 === a.__gohashid && f.push(a), d.set(a, c)) : (a = jb++, d.set(a, c), e.set(a, b)) } if (!za(b)) return b; f || (f = []); var h = Object.isExtensible(b); d || (d = new ob); if (h) { var k = d.get(b); if (k) return k } else for (e || (e = new ob), k = e.iterator; k.next();) { var l = k.key; if (k.value === b && (l = d.get(l))) return l } if (Array.isArray(b)) { k = []; g(b, k); for (var m = 0; m < b.length; m++)k.push(lr(a, b[m], !1, d, e, f)) } else if (b instanceof I || b instanceof L || b instanceof N || b instanceof hc || b instanceof O) k =
            b.copy(), g(b, k); else {
                if (b instanceof pl || b instanceof P || b instanceof D) return b; if (b instanceof F) k = (new F).addAll(lr(a, b.Fa(), !1, d, e, f)), g(b, k); else if (b instanceof H) k = (new H).addAll(lr(a, b.Fa(), !1, d, e, f)), g(b, k); else if (b instanceof ob) k = (new ob).addAll(lr(a, b.Fa(), !1, d, e, f)), g(b, k); else if (b instanceof Date) k = new Date(b.getTime()), g(b, k); else if (b instanceof RegExp) k = new RegExp(b), g(b, k), k.lastIndex = b.lastIndex; else if ("function" === typeof b.copy) k = b.copy(), g(b, k); else for (m in k = {}, g(b, k), b) "__gohashid" !==
                    m && (k[m] = lr(a, b[m], !1, d, e, f))
        } c && f.forEach(function (a) { delete a.__gohashid }); return k
    } t = Z.prototype; t.quote = function (a) { return JSON.stringify(a).replace(/[\u007F-\uFFFF]/g, function (a) { return "\\u" + ("0000" + a.charCodeAt(0).toString(16)).substr(-4) }) };
    t.om = function () {
        var a = ""; "" !== this.name && (a += ',\n  "name": ' + this.quote(this.name)); "" !== this.dataFormat && (a += ',\n  "dataFormat": ' + this.quote(this.dataFormat)); this.isReadOnly && (a += ',\n  "isReadOnly": ' + this.isReadOnly); "key" !== this.nodeKeyProperty && "string" === typeof this.nodeKeyProperty && (a += ',\n  "nodeKeyProperty": ' + this.quote(this.nodeKeyProperty)); this.copiesArrays && (a += ',\n  "copiesArrays": true'); this.copiesArrayObjects && (a += ',\n  "copiesArrayObjects": true'); this.copiesKey || (a += ',\n  "copiesKey": false');
        "category" !== this.nodeCategoryProperty && "string" === typeof this.nodeCategoryProperty && (a += ',\n  "nodeCategoryProperty": ' + this.quote(this.nodeCategoryProperty)); return a
    };
    t.$o = function (a) { a.name && (this.name = a.name); a.dataFormat && (this.dataFormat = a.dataFormat); a.isReadOnly && (this.isReadOnly = !0); a.nodeKeyProperty && (this.nodeKeyProperty = a.nodeKeyProperty); a.copiesArrays && (this.copiesArrays = !0); a.copiesArrayObjects && (this.copiesArrayObjects = !0); !1 === a.copiesKey && (this.copiesKey = !1); a.nodeCategoryProperty && (this.nodeCategoryProperty = a.nodeCategoryProperty) }; function mr(a, b) { b = b.modelData; za(b) && (a.am(b), a.modelData = b) }
    t.Kv = function () { var a = this.modelData, b = !1, c; for (c in a) if (!nr(c, a[c])) { b = !0; break } a = ""; b && (a = ',\n  "modelData": ' + or(this, this.modelData)); return a + ',\n  "nodeDataArray": ' + pr(this, this.nodeDataArray, !0) }; t.gv = function (a) { mr(this, a); a = a.nodeDataArray; Aa(a) && (this.am(a), this.nodeDataArray = a) };
    function jr(a, b) { function c(a, b) { if (b = b.part) if (b = b.data, a !== b) { var c = d.get(a); null === c ? (c = new H, c.add(b), d.add(a, c)) : c.add(b) } } var d = a.qh; null === d && (d = new ob, b.changes.each(function (a) { if (null !== a.diagram) { var b = a.change; if (b === ce) (a = a.object.panel) && (b = a.data) && c(b, a); else if (b === ee || b === fe) a = a.object, (b = a.itemArray) && c(b, a) } }), a.qh = d) } function kr(a, b) { for (var c = new H, d = 0; d < a.nodeDataArray.length; d++) { var e = a.nodeDataArray[d]; qr(a, b, e, e, c) } return c }
    function qr(a, b, c, d, e) { if (Array.isArray(c)) for (var f = 0; f < c.length; f++) { var g = c[f]; if (g === b) return e.add(d), !0; if (qr(a, b, g, d, e)) return !0 } else if (za(c) && Object.getPrototypeOf(c) === Object.prototype) for (f in c) { g = c[f]; if (g === b) return e.add(d), !0; if (qr(a, b, g, d, e)) return !0 } return !1 }
    t.Iv = function (a, b) {
        var c = this, d = !1, e = new H, f = new H, g = new H, h = this.qh; a.changes.each(function (a) { a.model === c && ("nodeDataArray" === a.modelChange ? a.change === ee ? e.add(a.newValue) : a.change === fe && g.add(a.oldValue) : c.mb(a.object) ? f.add(a.object) : a.change !== ce || c.modelData !== a.object && "modelData" !== a.propertyName ? null !== a.object && (h && h.contains(a.object) ? h.get(a.object).each(function (a) { c.mb(a) && f.add(a) }) : a.object instanceof Z || c.ni() && c.Gc(a.object) || kr(c, a.object).each(function (a) { f.add(a) })) : d = !0) }); var k =
            this.dl; k.clear(); e.each(function (a) { k.add(c.va(a)); b || f.add(a) }); var l = this.fl; l.clear(); g.each(function (a) { l.add(c.va(a)); b && f.add(a) }); a = ""; d && (a += ',\n  "modelData": ' + or(this, this.modelData)); 0 < k.count && (a += (b ? this.Rv : this.Pv) + pr(this, k.Fa(), !0)); 0 < f.count && (a += this.ey + pr(this, f.Fa(), !0)); 0 < l.count && (a += (b ? this.Pv : this.Rv) + pr(this, l.Fa(), !0)); l.clear(); k.clear(); return a
    };
    t.Zo = function (a) {
        (void 0 !== a.name && a.name !== this.name || void 0 !== a.dataFormat && a.dataFormat !== this.dataFormat || void 0 !== a.isReadOnly && a.isReadOnly !== this.isReadOnly || void 0 !== a.nodeKeyProperty && a.nodeKeyProperty !== this.nodeKeyProperty || void 0 !== a.copiesArrays && a.copiesArrays !== this.copiesArrays || void 0 !== a.copiesArrayObjects && a.copiesArrayObjects !== this.copiesArrayObjects || void 0 !== a.copiesKey && a.copiesKey !== this.copiesKey || void 0 !== a.nodeCategoryProperty && a.nodeCategoryProperty !== this.nodeCategoryProperty) &&
        C("applyIncrementalJson cannot change Model properties"); mr(this, a); var b = a.insertedNodeKeys, c = a.modifiedNodeData, d = new ob; if (Array.isArray(c)) for (var e = 0; e < c.length; e++) { var f = c[e], g = this.va(f); void 0 !== g && null !== g && d.set(g, f) } if (Array.isArray(b)) for (e = b.length, f = 0; f < e; f++) { g = b[f]; var h = this.dc(g); null === h && (h = (h = d.get(g)) ? h : this.copyNodeData({}), this.hm(h, g), this.uf(h)) } if (Array.isArray(c)) for (b = c.length, d = 0; d < b; d++)if (e = c[d], f = this.va(e), f = this.dc(f), null !== f) {
            for (var k in e) "__gohashid" === k ||
                k === this.nodeKeyProperty || this.Tj() && k === this.nodeIsGroupProperty || this.setDataProperty(f, k, e[k]); this.im(e, f)
        } a = a.removedNodeKeys; if (Array.isArray(a)) for (c = a.length, k = 0; k < c; k++)b = this.dc(a[k]), null !== b && this.$l(b)
    };
    t.Wx = function (a, b) { a.change !== de && C("Model.toIncrementalJson argument is not a Transaction ChangedEvent:" + a.toString()); var c = a.object; if (!(a.isTransactionFinished && c instanceof ge)) return '{ "incremental": 0 }'; void 0 === b && (b = rr(this, this)); jr(this, c); a = this.Iv(c, "FinishedUndo" === a.propertyName); this.qh = null; return "2" === b ? '{ "incremental": 2' + a + "}" : '{ "class": ' + this.quote(b) + ', "incremental": 1' + this.om() + a + "}" }; t.oA = function (a, b) { return this.Wx(a, b) };
    t.hp = function (a) { void 0 === a && (a = rr(this, this)); return '{ "class": ' + this.quote(a) + this.om() + this.Kv() + "}" }; t.toJSON = function (a) { return this.hp(a) }; t.Sw = function (a) { var b = null; if ("string" === typeof a) try { b = x.JSON.parse(a) } catch (d) { } else "object" === typeof a ? b = a : C("Unable to modify a Model from: " + a); var c = b.incremental; "number" !== typeof c && C("Unable to apply non-incremental changes to Model: " + a); 0 !== c && (this.Ea("applyIncrementalJson"), this.Zo(b), this.eb("applyIncrementalJson")) }; t.Dy = function (a) { return this.Sw(a) };
    Z.constructGraphLinksModel = function () { return new Z }; t = Z.prototype;
    t.am = function (a) { if (Aa(a)) for (var b = a.length, c = 0; c < b; c++) { var d = a[c]; if (za(d)) { var e = c; d = this.am(d); Array.isArray(a) ? a[e] = d : C("Cannot replace an object in an HTMLCollection or NodeList at " + e) } } else if (za(a)) { for (b in a) if (c = a[b], za(c) && (c = this.am(c), a[b] = c, "points" === b && Array.isArray(c))) { e = 0 === c.length % 2; for (d = 0; d < c.length; d++)if ("number" !== typeof c[d]) { e = !1; break } if (e) { e = new F; for (d = 0; d < c.length / 2; d++)e.add(new I(c[2 * d], c[2 * d + 1])); e.freeze(); a[b] = e } } return sr(a) } return a };
    t.pm = function (a) { return void 0 === a ? "undefined" : null === a ? "null" : !0 === a ? "true" : !1 === a ? "false" : "string" === typeof a ? this.quote(a) : "number" === typeof a ? Infinity === a ? "9e9999" : -Infinity === a ? "-9e9999" : isNaN(a) ? '{"class":"NaN"}' : a.toString() : a instanceof Date ? '{"class":"Date", "value":"' + a.toJSON() + '"}' : a instanceof Number ? this.pm(a.valueOf()) : Aa(a) ? pr(this, a) : za(a) ? or(this, a) : "function" === typeof a ? "null" : a.toString() };
    function pr(a, b, c) { void 0 === c && (c = !1); var d = b.length; if (0 >= d) return "[]"; var e = new Sa; e.add("["); c && 1 < d && e.add("\n"); for (var f = 0; f < d; f++) { var g = b[f]; void 0 !== g && (0 < f && (e.add(","), c && e.add("\n")), e.add(a.pm(g))) } c && 1 < d && e.add("\n"); e.add("]"); return e.toString() } function nr(a, b) { return void 0 === b || "__gohashid" === a || "_" === a[0] || "function" === typeof b ? !0 : !1 } function tr(a) { return isNaN(a) ? "NaN" : Infinity === a ? "9e9999" : -Infinity === a ? "-9e9999" : a }
    function or(a, b) {
        var c = b; if (c instanceof I) b = { "class": "go.Point", x: tr(c.x), y: tr(c.y) }; else if (c instanceof L) b = { "class": "go.Size", width: tr(c.width), height: tr(c.height) }; else if (c instanceof N) b = { "class": "go.Rect", x: tr(c.x), y: tr(c.y), width: tr(c.width), height: tr(c.height) }; else if (c instanceof hc) b = { "class": "go.Margin", top: tr(c.top), right: tr(c.right), bottom: tr(c.bottom), left: tr(c.left) }; else if (c instanceof O) c.tb() ? b = { "class": "go.Spot", x: tr(c.x), y: tr(c.y), offsetX: tr(c.offsetX), offsetY: tr(c.offsetY) } :
            b = { "class": "go.Spot", "enum": c.toString() }; else if (c instanceof pl) { b = { "class": "go.Brush", type: c.type.name }; if (c.type === Dl) b.color = c.color; else if (c.type === Gl || c.type === ql) b.start = c.start, b.end = c.end, c.type === ql && (0 !== c.startRadius && (b.startRadius = tr(c.startRadius)), isNaN(c.endRadius) || (b.endRadius = tr(c.endRadius))); if (null !== c.colorStops) { var d = {}; for (c = c.colorStops.iterator; c.next();)d[c.key] = c.value; b.colorStops = d } } else c instanceof P ? (b = { "class": "go.Geometry", type: c.type.name }, 0 !== c.startX && (b.startX =
                tr(c.startX)), 0 !== c.startY && (b.startY = tr(c.startY)), 0 !== c.endX && (b.endX = tr(c.endX)), 0 !== c.endY && (b.endY = tr(c.endY)), c.spot1.C(sc) || (b.spot1 = c.spot1), c.spot2.C(Bc) || (b.spot2 = c.spot2), c.type === P.m && (b.path = P.ga(c))) : c instanceof D && (b = { "class": "go.EnumValue", classType: rr(a, c.classType), name: c.name }); d = "{"; c = !0; for (var e in b) {
                    var f = Bn(b, e); if (!nr(e, f)) if (c ? c = !1 : d += ",", d += a.quote(e) + ":", "points" === e && f instanceof F) {
                        var g = "["; for (f = f.iterator; f.next();) {
                            var h = f.value; 1 < g.length && (g += ","); g += a.pm(h.x);
                            g += ","; g += a.pm(h.y)
                        } g += "]"; d += g
                    } else d += a.pm(f)
                } return d + "}"
    } function ur(a) { return "number" === typeof a ? a : "NaN" === a ? NaN : "9e9999" === a ? Infinity : "-9e9999" === a ? -Infinity : parseFloat(a) }
    function sr(a) {
        if ("object" !== typeof a) return a; var b = a["class"] || ""; if ("NaN" === b) return NaN; if ("Date" === b) return new Date(a.value); if (0 !== b.indexOf("go.")) return a; b = b.substr(3); var c = a; if ("Point" === b) c = new I(ur(a.x), ur(a.y)); else if ("Size" === b) c = new L(ur(a.width), ur(a.height)); else if ("Rect" === b) c = new N(ur(a.x), ur(a.y), ur(a.width), ur(a.height)); else if ("Margin" === b) c = new hc(ur(a.top), ur(a.right), ur(a.bottom), ur(a.left)); else if ("Spot" === b) "string" === typeof a["enum"] ? c = id(a["enum"]) : c = new O(ur(a.x),
            ur(a.y), ur(a.offsetX), ur(a.offsetY)); else if ("Brush" === b) { if (c = new pl, c.type = Ra(pl, a.type), "string" === typeof a.color && (c.color = a.color), a.start instanceof O && (c.start = a.start), a.end instanceof O && (c.end = a.end), "number" === typeof a.startRadius && (c.startRadius = ur(a.startRadius)), "number" === typeof a.endRadius && (c.endRadius = ur(a.endRadius)), a = a.colorStops, za(a)) for (var d in a) c.addColorStop(parseFloat(d), a[d]) } else "Geometry" === b ? (c = null, "string" === typeof a.path ? c = P.parse(a.path) : c = new P, c.type = Ra(P, a.type),
                "number" === typeof a.startX && (c.startX = ur(a.startX)), "number" === typeof a.startY && (c.startY = ur(a.startY)), "number" === typeof a.endX && (c.endX = ur(a.endX)), "number" === typeof a.endY && (c.endY = ur(a.endY)), a.spot1 instanceof O && (c.spot1 = a.spot1), a.spot2 instanceof O && (c.spot2 = a.spot2)) : "EnumValue" === b && (d = a.classType, 0 === d.indexOf("go.") && (d = d.substr(3)), d = vr(d), "function" === typeof d && (c = Ra(d, a.name))); return c
    } t.Xg = function (a) { this.Et.add(a); return this }; t.dk = function (a) { this.Et.remove(a) };
    t.Vr = function (a) { this.skipsUndoManager || this.undoManager.Pu(a); for (var b = this.Et, c = b.length, d = 0; d < c; d++)b.L(d)(a) }; t.cb = function (a, b, c, d, e, f, g) { wr(this, "", a, b, c, d, e, f, g) }; t.g = function (a, b, c, d, e) { wr(this, "", ce, a, this, b, c, d, e) }; t.ys = function (a, b, c, d, e, f) { wr(this, "", ce, b, a, c, d, e, f) }; function wr(a, b, c, d, e, f, g, h, k) { void 0 === h && (h = null); void 0 === k && (k = null); var l = new be; l.model = a; l.change = c; l.modelChange = b; l.propertyName = d; l.object = e; l.oldValue = f; l.oldParam = h; l.newValue = g; l.newParam = k; a.Vr(l) }
    Z.prototype.changeState = function (a, b) {
        if (null !== a && a.model === this) if (a.change === ce) { var c = a.object, d = a.propertyName, e = a.I(b); d === this.nodeKeyProperty && this.mb(c) && (b = a.I(!b), void 0 !== b && this.lb.remove(b), void 0 !== e && this.lb.add(e, c)); Tj(c, d, e) } else a.change === ee ? (c = a.newParam, "nodeDataArray" === a.modelChange ? (a = a.newValue, za(a) && "number" === typeof c && (d = this.va(a), b ? (this.Pc[c] === a && Da(this.Pc, c), void 0 !== d && this.lb.remove(d)) : (this.Pc[c] !== a && Ca(this.Pc, c, a), void 0 !== d && this.lb.add(d, a)))) : "" === a.modelChange ?
            ((d = a.object) && !Aa(d) && a.propertyName && (d = Bn(a.object, a.propertyName)), Aa(d) && "number" === typeof c && (a = a.newValue, b ? Da(d, c) : Ca(d, c, a))) : C("unknown ChangedEvent.Insert modelChange: " + a.toString())) : a.change === fe ? (c = a.oldParam, "nodeDataArray" === a.modelChange ? (a = a.oldValue, za(a) && "number" === typeof c && (d = this.va(a), b ? (this.Pc[c] !== a && Ca(this.Pc, c, a), void 0 !== d && this.lb.add(d, a)) : (this.Pc[c] === a && Da(this.Pc, c), void 0 !== d && this.lb.remove(d)))) : "" === a.modelChange ? ((d = a.object) && !Aa(d) && a.propertyName && (d =
                Bn(a.object, a.propertyName)), Aa(d) && "number" === typeof c && (a = a.oldValue, b ? Ca(d, c, a) : Da(d, c))) : C("unknown ChangedEvent.Remove modelChange: " + a.toString())) : a.change !== de && C("unknown ChangedEvent: " + a.toString())
    }; Z.prototype.Ea = function (a) { return this.undoManager.Ea(a) }; Z.prototype.eb = function (a) { return this.undoManager.eb(a) }; Z.prototype.Hf = function () { return this.undoManager.Hf() };
    Z.prototype.commit = function (a, b) { void 0 === b && (b = ""); var c = this.skipsUndoManager; null === b && (this.skipsUndoManager = !0, b = ""); this.undoManager.Ea(b); var d = !1; try { a(this), d = !0 } finally { d ? this.undoManager.eb(b) : this.undoManager.Hf(), this.skipsUndoManager = c } }; t = Z.prototype; t.Ma = function (a, b) { void 0 === b && (b = ""); wr(this, "SourceChanged", de, b, a, null, null) };
    t.va = function (a) { if (null !== a) { var b = this.Ph; if ("" !== b && (b = Bn(a, b), void 0 !== b)) { if ($q(b)) return b; C("Key value for node data " + a + " is not a number or a string: " + b) } } }; t.hm = function (a, b) { if (null !== a) { var c = this.Ph; if ("" !== c) if (this.mb(a)) { var d = Bn(a, c); d !== b && null === this.dc(b) && (Tj(a, c, b), void 0 !== d && this.lb.remove(d), this.lb.add(b, a), wr(this, "nodeKey", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c), this.cp(d, b)) } else Tj(a, c, b) } }; function $q(a) { return "number" === typeof a || "string" === typeof a }
    t.mb = function (a) { var b = this.va(a); return void 0 === b ? !1 : this.lb.I(b) === a }; t.dc = function (a) { null === a && C("Model.findNodeDataForKey:key must not be null"); return void 0 !== a && $q(a) ? this.lb.I(a) : null };
    t.Wu = function (a) { if (null !== a) { var b = this.Ph; if ("" !== b) { var c = this.va(a); if (void 0 === c || this.lb.contains(c)) { var d = this.Xk; if (null !== d && (c = d(this, a), void 0 !== c && null !== c && !this.lb.contains(c))) { Tj(a, b, c); return } if ("string" === typeof c) { for (d = 2; this.lb.contains(c + d);)d++; Tj(a, b, c + d) } else if (void 0 === c || "number" === typeof c) { for (c = -this.lb.count - 1; this.lb.contains(c);)c--; Tj(a, b, c) } } } } }; t.uf = function (a) { null !== a && (fb(a), this.mb(a) || Sq(this, a, !0)) };
    function Sq(a, b, c) { var d = a.va(b); if (void 0 === d || a.lb.I(d) !== b) a.Wu(b), d = a.va(b), void 0 === d && C("Model.makeNodeDataKeyUnique failed on " + b + ".  Data not added to Model."), a.lb.add(d, b), d = null, c && (d = a.Pc.length, Ca(a.Pc, d, b)), wr(a, "nodeDataArray", ee, "nodeDataArray", a, null, b, null, d), a.cm(b), a.bm(b) } t.Ay = function (a) { if (Aa(a)) for (var b = a.length, c = 0; c < b; c++)this.uf(a[c]); else for (a = a.iterator; a.next();)this.uf(a.value) }; t.$l = function (a) { null !== a && Rq(this, a, !0) };
    function Rq(a, b, c) { var d = a.va(b); void 0 !== d && a.lb.remove(d); d = null; if (c) { a: if (c = a.Pc, Array.isArray(c)) d = c.indexOf(b); else { d = c.length; for (var e = 0; e < d; e++)if (c[e] === b) { d = e; break a } d = -1 } if (0 > d) return; Da(a.Pc, d) } wr(a, "nodeDataArray", fe, "nodeDataArray", a, b, null, d, null); a.kp(b) } t.dA = function (a) { if (Aa(a)) for (var b = a.length, c = 0; c < b; c++)this.$l(a[c]); else for (a = a.iterator; a.next();)this.$l(a.value) };
    t.Rz = function (a) { if (a !== this.nodeDataArray && Aa(a)) { var b = this.fl; b.clear(); b.addAll(this.lb.iteratorKeys); var c = this.dl; c.clear(); for (var d = a.length, e = 0; e < d; e++) { var f = a[e], g = this.va(f); if (void 0 !== g) { c.add(g); var h = this.dc(g); h !== f && (null !== h ? (this.zo(h, f), this.im(f, h)) : (f = this.cloneDeep(f), this.hm(f, g), this.uf(f))) } else g = this.cloneDeep(f), this.uf(g), c.add(this.va(g)) } for (a = b.iterator; a.next();)d = a.value, c.contains(d) || (d = this.dc(d)) && this.$l(d); b.clear(); c.clear() } };
    t.cp = function (a, b) { void 0 !== b && (a = cr(this, a), a instanceof H && this.Sf.add(b, a)) }; t.Ev = function () { }; t.cm = function () { }; t.bm = function () { }; t.kp = function () { }; function er(a, b, c) { if (void 0 !== b) { var d = a.Sf.I(b); null === d && (d = new H, a.Sf.add(b, d)); d.add(c) } } function dr(a, b, c) { if (void 0 !== b) { var d = a.Sf.I(b); d instanceof H && (void 0 === c || null === c ? a.Sf.remove(b) : (d.remove(c), 0 === d.count && a.Sf.remove(b))) } } function cr(a, b) { if (void 0 === b) return null; a = a.Sf.I(b); return a instanceof H ? a : null }
    t.Iy = function (a) { void 0 === a ? this.Sf.clear() : this.Sf.remove(a) }; Z.prototype.copyNodeData = function (a) { if (null === a) return null; var b = this.wk; a = null !== b ? b(a, this) : xr(this, a, !0); za(a) && Qa(a); return a };
    function xr(a, b, c) {
        if (a.copiesArrays && Array.isArray(b)) { var d = []; for (c = 0; c < b.length; c++) { var e = xr(a, b[c], a.copiesArrayObjects); d.push(e) } Qa(d); return d } if (c && za(b)) {
            c = (c = b.constructor) ? new c : {}; e = a.copiesKey || "string" !== typeof a.nodeKeyProperty ? null : a.nodeKeyProperty; for (d in b) if ("__gohashid" === d) c.__gohashid = void 0; else if (d === e) c[e] = void 0; else {
                var f = Bn(b, d), g = rr(a, f); "GraphObject" === g || "Diagram" === g || "Layer" === g || "RowColumnDefinition" === g || "AnimationManager" === g || "Tool" === g || "CommandHandler" ===
                    g || "Layout" === g || "InputEvent" === g || "DiagramEvent" === g || f instanceof Z || f instanceof he || f instanceof ge || f instanceof be ? Tj(c, d, f) : (f = xr(a, f, !1), Tj(c, d, f))
            } Qa(c); return c
        } return b instanceof I ? b.copy() : b instanceof L ? b.copy() : b instanceof N ? b.copy() : b instanceof O ? b.copy() : b instanceof hc ? b.copy() : b
    }
    Z.prototype.setDataProperty = function (a, b, c) { if (this.mb(a)) { if (b === this.nodeKeyProperty) { this.hm(a, c); return } if (b === this.nodeCategoryProperty) { this.gm(a, c); return } } else !yr && a instanceof Y && (yr = !0, ya('Model.setDataProperty is modifying a GraphObject, "' + a.toString() + '"'), ya("  Is that really your intent?")); var d = Bn(a, b); d !== c && (Tj(a, b, c), this.ys(a, b, d, c)) }; t = Z.prototype; t.set = function (a, b, c) { this.setDataProperty(a, b, c) };
    t.zo = function (a, b) { if (b) { var c = this.mb(a), d; for (d in b) "__gohashid" === d || c && d === this.nodeKeyProperty || this.setDataProperty(a, d, b[d]) } }; t.wy = function (a, b) { this.ms(a, -1, b) }; t.ms = function (a, b, c) { 0 > b && (b = a.length); Ca(a, b, c); wr(this, "", ee, "", a, null, c, null, b) }; t.jv = function (a, b) { void 0 === b && (b = -1); a === this.Pc && C("Model.removeArrayItem should not be called on the Model.nodeDataArray"); -1 === b && (b = a.length - 1); var c = a[b]; Da(a, b); wr(this, "", fe, "", a, c, null, b, null) };
    t.Lo = function (a) { if (null === a) return ""; var b = this.jj; if ("" === b) return ""; b = Bn(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; C("getCategoryForNodeData found a non-string category for " + a + ": " + b) }; t.gm = function (a, b) { if (null !== a) { var c = this.jj; if ("" !== c) if (this.mb(a)) { var d = Bn(a, c); void 0 === d && (d = ""); d !== b && (Tj(a, c, b), wr(this, "nodeCategory", ce, c, a, d, b)) } else Tj(a, c, b) } }; t.im = function (a, b) { this.gm(b, this.Lo(a)) }; t.Pl = function () { return !1 }; t.Tj = function () { return !1 }; t.ni = function () { return !1 };
    t.qs = function () { return !1 }; t.Uj = function () { return !1 }; function pi() { return new Z } function rr(a, b) { if ("function" === typeof b) { if (b.className) return b.className; if (b.name) return b.name } else if ("object" === typeof b && null !== b && b.constructor) return rr(a, b.constructor); return typeof b } function vr(a) { return zr[a] ? zr[a] : void 0 !== x.go && x.go[a] ? x.go[a] : null }
    function Bn(a, b) { if (!a || !b) return null; try { if ("function" === typeof b) var c = b(a); else "function" === typeof a.getAttribute ? (c = a.getAttribute(b), null === c && (c = void 0)) : c = a[b] } catch (d) { } return c } function Tj(a, b, c) { if (a && b) try { "function" === typeof b ? b(a, c) : "function" === typeof a.setAttribute ? a.setAttribute(b, c) : a[b] = c } catch (d) { } }
    ma.Object.defineProperties(Z.prototype, {
        name: { get: function () { return this.wa }, set: function (a) { var b = this.wa; b !== a && (this.wa = a, this.g("name", b, a)) } }, dataFormat: { get: function () { return this.Pm }, set: function (a) { var b = this.Pm; b !== a && (this.Pm = a, this.g("dataFormat", b, a)) } }, isReadOnly: { get: function () { return this.Cg }, set: function (a) { var b = this.Cg; b !== a && (this.Cg = a, this.g("isReadOnly", b, a)) } }, modelData: {
            get: function () { return this.j }, set: function (a) { var b = this.j; b !== a && (this.j = a, this.g("modelData", b, a), this.Ma(a)) }
        }, undoManager: { get: function () { return this.m }, set: function (a) { var b = this.m; b !== a && (null !== b && b.Nx(this), this.m = a, null !== a && a.Qw(this)) } }, skipsUndoManager: { get: function () { return this.Qg }, set: function (a) { this.Qg = a } }, nodeKeyProperty: {
            get: function () { return this.Ph }, set: function (a) {
                var b = this.Ph;
                b !== a && ("" === a && C("Model.nodeKeyProperty may not be the empty string"), 0 < this.lb.count && C("Cannot set Model.nodeKeyProperty when there is existing node data"), this.Ph = a, this.g("nodeKeyProperty", b, a))
            }
        }, makeUniqueKeyFunction: { get: function () { return this.Xk }, set: function (a) { var b = this.Xk; b !== a && (this.Xk = a, this.g("makeUniqueKeyFunction", b, a)) } }, nodeDataArray: {
            get: function () { return this.Pc }, set: function (a) {
                var b = this.Pc; if (b !== a) {
                    this.lb.clear();
                    this.Ev(); for (var c = a.length, d = 0; d < c; d++) { var e = a[d]; za(e) || C("Model.nodeDataArray must only contain Objects, not: " + e); fb(e) } this.Pc = a; d = new F; for (e = 0; e < c; e++) { var f = a[e], g = this.va(f); void 0 === g ? d.add(f) : null !== this.lb.I(g) ? d.add(f) : this.lb.add(g, f) } for (d = d.iterator; d.next();)e = d.value, this.Wu(e), f = this.va(e), void 0 !== f && this.lb.add(f, e); wr(this, "nodeDataArray", ce, "nodeDataArray", this, b, a); for (b = 0; b < c; b++)d = a[b], this.cm(d), this.bm(d); Array.isArray(a) || (this.isReadOnly = !0)
                }
            }
        }, copyNodeDataFunction: {
            get: function () { return this.wk }, set: function (a) { var b = this.wk; b !== a && (this.wk = a, this.g("copyNodeDataFunction", b, a)) }
        }, copiesArrays: { get: function () { return this.Hm }, set: function (a) { var b = this.Hm; b !== a && (this.Hm = a, this.g("copiesArrays", b, a)) } }, copiesArrayObjects: { get: function () { return this.Gm }, set: function (a) { var b = this.Gm; b !== a && (this.Gm = a, this.g("copiesArrayObjects", b, a)) } }, copiesKey: {
            get: function () { return this.Jm },
            set: function (a) { var b = this.Jm; b !== a && (this.Jm = a, this.g("copiesKey", b, a)) }
        }, afterCopyFunction: { get: function () { return this.um }, set: function (a) { var b = this.um; b !== a && (this.um = a, this.g("afterCopyFunction", b, a)) } }, nodeCategoryProperty: { get: function () { return this.jj }, set: function (a) { var b = this.jj; b !== a && (this.jj = a, this.g("nodeCategoryProperty", b, a)) } }, type: { get: function () { return "Model" } }
    });
    Z.prototype.setCategoryForNodeData = Z.prototype.gm; Z.prototype.getCategoryForNodeData = Z.prototype.Lo; Z.prototype.removeArrayItem = Z.prototype.jv; Z.prototype.insertArrayItem = Z.prototype.ms; Z.prototype.addArrayItem = Z.prototype.wy; Z.prototype.assignAllDataProperties = Z.prototype.zo; Z.prototype.set = Z.prototype.set; Z.prototype.clearUnresolvedReferences = Z.prototype.Iy; Z.prototype.mergeNodeDataArray = Z.prototype.Rz; Z.prototype.removeNodeDataCollection = Z.prototype.dA; Z.prototype.removeNodeData = Z.prototype.$l;
    Z.prototype.addNodeDataCollection = Z.prototype.Ay; Z.prototype.addNodeData = Z.prototype.uf; Z.prototype.makeNodeDataKeyUnique = Z.prototype.Wu; Z.prototype.findNodeDataForKey = Z.prototype.dc; Z.prototype.containsNodeData = Z.prototype.mb; Z.prototype.setKeyForNodeData = Z.prototype.hm; Z.prototype.getKeyForNodeData = Z.prototype.va; Z.prototype.updateTargetBindings = Z.prototype.Ma; Z.prototype.commit = Z.prototype.commit; Z.prototype.rollbackTransaction = Z.prototype.Hf; Z.prototype.commitTransaction = Z.prototype.eb;
    Z.prototype.startTransaction = Z.prototype.Ea; Z.prototype.raiseDataChanged = Z.prototype.ys; Z.prototype.raiseChanged = Z.prototype.g; Z.prototype.raiseChangedEvent = Z.prototype.cb; Z.prototype.removeChangedListener = Z.prototype.dk; Z.prototype.addChangedListener = Z.prototype.Xg; Z.prototype.writeJsonValue = Z.prototype.pm; Z.prototype.replaceJsonObjects = Z.prototype.am; Z.prototype.applyIncrementalJSON = Z.prototype.Dy; Z.prototype.applyIncrementalJson = Z.prototype.Sw; Z.prototype.toJSON = Z.prototype.toJSON;
    Z.prototype.toJson = Z.prototype.hp; Z.prototype.toIncrementalJSON = Z.prototype.oA; Z.prototype.toIncrementalJson = Z.prototype.Wx; Z.prototype.toIncrementalData = Z.prototype.nA; Z.prototype.clear = Z.prototype.clear; var yr = !1, zr = {}; Z.className = "Model";
    Z.fromJSON = Z.fromJson = function (a, b) {
        void 0 === b && (b = null); var c = null; if ("string" === typeof a) try { c = x.JSON.parse(a) } catch (f) { } else "object" === typeof a ? c = a : C("Unable to construct a Model from: " + a); if (null === b) { a = null; var d = c["class"]; if ("string" === typeof d) try { var e = null; 0 === d.indexOf("go.") ? (d = d.substr(3), e = vr(d)) : (e = vr(d), null === e && (e = x[d])); "function" === typeof e && (a = new e) } catch (f) { } null === a || a instanceof Z ? b = a : C("Unable to construct a Model of declared class: " + c["class"]) } null === b && (b = Z.constructGraphLinksModel());
        b.$o(c); b.gv(c); return b
    }; Z.safePropertyValue = Bn; Z.safePropertySet = Tj; zr.Brush = pl; zr.ChangedEvent = be; zr.Geometry = P; zr.Margin = hc; zr.Panel = W; zr.Point = I; zr.Rect = N; zr.Size = L; zr.Spot = O; zr.Transaction = ge; zr.UndoManager = he; function xi(a, b, c, d) { Qa(this); this._isFrozen = !1; void 0 === a && (a = ""); void 0 === b && (b = a); void 0 === c && (c = null); this.j = -1; this.rd = null; this.tl = a; this.sl = this.po = 0; this.ur = null; this.sn = !1; this.ol = b; this.Fm = c; void 0 === d ? (this.$k = Ar, this.rk = null) : (this.$k = un, this.rk = d); this.lt = new H }
    xi.prototype.copy = function () { var a = new xi; a.tl = this.tl; a.po = this.po; a.sl = this.sl; a.ur = this.ur; a.sn = this.sn; a.ol = this.ol; a.Fm = this.Fm; a.$k = this.$k; a.rk = this.rk; return a }; t = xi.prototype; t.rb = function (a) { a.classType === xi && (this.mode = a) }; t.toString = function () { return "Binding(" + this.targetProperty + ":" + this.sourceProperty + (-1 !== this.xi ? " " + this.xi : "") + " " + this.mode.name + ")" }; t.freeze = function () { this._isFrozen = !0; return this }; t.ka = function () { this._isFrozen = !1; return this };
    t.Fx = function (a) { void 0 === a && (a = null); this.mode = un; this.backConverter = a; return this }; t.Xo = function (a) { void 0 === a && (a = ""); this.sourceName = a; this.isToModel = !1; return this }; t.Uz = function () { this.sourceName = null; this.isToModel = !0; return this }; function gl(a, b, c) { a = a.sourceName; return null === a || "" === a ? b : "/" === a ? c.part : "." === a ? c : ".." === a ? c.panel : b.fb(a) }
    t.Fv = function (a, b, c) { var d = this.ol; if (void 0 === c || "" === d || d === c) { c = this.tl; var e = this.Fm; if (null === e && "" === c) ya("Binding error: target property is the empty string: " + this.toString()); else { var f = b; "" !== d && (f = Bn(b, d)); if (void 0 !== f) if (null === e) "" !== c && Tj(a, c, f); else try { if ("" !== c) { var g = e(f, a); Tj(a, c, g) } else e(f, a) } catch (h) { } } } };
    t.mp = function (a, b, c, d) {
        if (this.$k === un) {
            var e = this.tl; if (void 0 === c || e === c) {
                c = this.ol; var f = this.rk, g = a; "" !== e && (g = Bn(a, e)); if (void 0 !== g && !this.lt.contains(a)) try {
                    this.lt.add(a); var h = null !== d ? d.diagram : null, k = null !== h ? h.model : null; if (null === f) if ("" !== c) null !== k ? k.setDataProperty(b, c, g) : Tj(b, c, g); else { if (null !== k && null !== d && 0 <= d.itemIndex && null !== d.panel && Array.isArray(d.panel.itemArray)) { var l = d.itemIndex, m = d.panel.itemArray; k.jv(m, l); k.ms(m, l, g) } } else try {
                        if ("" !== c) {
                            var n = f(g, b, k); null !== k ? k.setDataProperty(b,
                                c, n) : Tj(b, c, n)
                        } else { var p = f(g, b, k); if (void 0 !== p && null !== k && null !== d && 0 <= d.itemIndex && null !== d.panel && Array.isArray(d.panel.itemArray)) { var r = d.itemIndex, q = d.panel.itemArray; k.jv(q, r); k.ms(q, r, p) } }
                    } catch (u) { }
                } finally { this.lt.remove(a) }
            }
        }
    };
    ma.Object.defineProperties(xi.prototype, {
        xi: { get: function () { return this.j }, set: function (a) { this._isFrozen && va(this); this.j = a } }, targetProperty: { get: function () { return this.tl }, set: function (a) { this._isFrozen && va(this); this.tl = a } }, sourceName: { get: function () { return this.ur }, set: function (a) { this._isFrozen && va(this); this.ur = a; null !== a && (this.sn = !1) } }, isToModel: {
            get: function () { return this.sn },
            set: function (a) { this._isFrozen && va(this); this.sn = a }
        }, sourceProperty: { get: function () { return this.ol }, set: function (a) { this._isFrozen && va(this); this.ol = a } }, converter: { get: function () { return this.Fm }, set: function (a) { this._isFrozen && va(this); this.Fm = a } }, backConverter: { get: function () { return this.rk }, set: function (a) { this._isFrozen && va(this); this.rk = a } }, mode: {
            get: function () { return this.$k },
            set: function (a) { this._isFrozen && va(this); this.$k = a }
        }
    }); xi.prototype.updateSource = xi.prototype.mp; xi.prototype.updateTarget = xi.prototype.Fv; xi.prototype.ofModel = xi.prototype.Uz; xi.prototype.ofObject = xi.prototype.Xo; xi.prototype.makeTwoWay = xi.prototype.Fx; var Yi = "Binding", Ar = new D(xi, "OneWay", 1), un = new D(xi, "TwoWay", 2); xi.className = "Binding"; xi.parseEnum = function (a, b) { return function (c) { c = Ra(a, c); return null === c ? b : c } }; xi.toString = Ia; xi.OneWay = Ar; xi.TwoWay = un;
    function Cr(a, b, c) { Z.call(this); this.Ov = ',\n  "insertedLinkKeys": '; this.by = ',\n  "modifiedLinkData": '; this.Qv = ',\n  "removedLinkKeys": '; this.dd = []; this.Wf = new H; this.wb = new ob; this.Fh = ""; this.Oi = this.vk = this.Yk = null; this.gf = "from"; this.hf = "to"; this.dj = this.cj = ""; this.bj = "category"; this.fe = ""; this.el = "isGroup"; this.Fe = "group"; this.Im = !1; void 0 !== b && (this.linkDataArray = b); void 0 !== a && (Aa(a) ? this.nodeDataArray = a : Object.assign(this, a)); c && Object.assign(this, c) } la(Cr, Z);
    Cr.constructGraphLinksModel = Z.constructGraphLinksModel; Cr.prototype.cloneProtected = function (a) { Z.prototype.cloneProtected.call(this, a); a.Fh = this.Fh; a.Yk = this.Yk; a.vk = this.vk; a.gf = this.gf; a.hf = this.hf; a.cj = this.cj; a.dj = this.dj; a.bj = this.bj; a.fe = this.fe; a.el = this.el; a.Fe = this.Fe; a.Im = this.Im }; t = Cr.prototype; t.clear = function () { Z.prototype.clear.call(this); this.dd = []; this.wb.clear(); this.Wf.clear() };
    t.toString = function (a) { void 0 === a && (a = 0); if (2 <= a) return this.hp(); var b = ("" !== this.name ? this.name : "") + " GraphLinksModel"; if (0 < a) { b += "\n node data:"; a = this.nodeDataArray; var c = a.length, d; for (d = 0; d < c; d++) { var e = a[d]; b += " " + this.va(e) + ":" + Ia(e) } b += "\n link data:"; a = this.linkDataArray; c = a.length; for (d = 0; d < c; d++)e = a[d], b += " " + Yq(this, e, !0) + "--\x3e" + Yq(this, e, !1) } return b };
    t.Jv = function (a, b) {
        "" === this.linkKeyProperty && C("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalData() to succeed."); var c = Z.prototype.Jv.call(this, a, b), d = this, e = new H, f = new H, g = new H, h = this.qh; a.changes.each(function (a) {
            a.model === d && ("linkDataArray" === a.modelChange ? a.change === ee ? e.add(a.newValue) : a.change === fe && g.add(a.oldValue) : d.Gc(a.object) ? f.add(a.object) : null !== a.object && (null !== a.object && h && h.contains(a.object) ? h.get(a.object).each(function (a) { d.Gc(a) && f.add(a) }) :
                a.object instanceof Z || d.mb(a.object) || Dr(d, a.object).each(function (a) { f.add(a) })))
        }); var k = this.dl; k.clear(); e.each(function (a) { k.add(d.xc(a)); b || f.add(a) }); var l = this.fl; l.clear(); g.each(function (a) { l.add(d.xc(a)); b && f.add(a) }); a = d.cloneDeep(f.Fa()); 0 < k.count && (null === c && (c = {}), b ? c.removedLinkKeys = k.Fa() : c.insertedLinkKeys = k.Fa()); 0 < a.length && (null === c && (c = {}), c.modifiedLinkData = a); 0 < l.count && (null === c && (c = {}), b ? c.insertedLinkKeys = l.Fa() : c.removedLinkKeys = l.Fa()); l.clear(); k.clear(); return c
    };
    t.om = function () {
        var a = Z.prototype.om.call(this), b = ""; "category" !== this.linkCategoryProperty && "string" === typeof this.linkCategoryProperty && (b += ',\n  "linkCategoryProperty": ' + this.quote(this.linkCategoryProperty)); "" !== this.linkKeyProperty && "string" === typeof this.linkKeyProperty && (b += ',\n  "linkKeyProperty": ' + this.quote(this.linkKeyProperty)); "from" !== this.linkFromKeyProperty && "string" === typeof this.linkFromKeyProperty && (b += ',\n  "linkFromKeyProperty": ' + this.quote(this.linkFromKeyProperty)); "to" !==
            this.linkToKeyProperty && "string" === typeof this.linkToKeyProperty && (b += ',\n  "linkToKeyProperty": ' + this.quote(this.linkToKeyProperty)); "" !== this.linkFromPortIdProperty && "string" === typeof this.linkFromPortIdProperty && (b += ',\n  "linkFromPortIdProperty": ' + this.quote(this.linkFromPortIdProperty)); "" !== this.linkToPortIdProperty && "string" === typeof this.linkToPortIdProperty && (b += ',\n  "linkToPortIdProperty": ' + this.quote(this.linkToPortIdProperty)); "" !== this.linkLabelKeysProperty && "string" === typeof this.linkLabelKeysProperty &&
                (b += ',\n  "linkLabelKeysProperty": ' + this.quote(this.linkLabelKeysProperty)); "isGroup" !== this.nodeIsGroupProperty && "string" === typeof this.nodeIsGroupProperty && (b += ',\n  "nodeIsGroupProperty": ' + this.quote(this.nodeIsGroupProperty)); "group" !== this.nodeGroupKeyProperty && "string" === typeof this.nodeGroupKeyProperty && (b += ',\n  "nodeGroupKeyProperty": ' + this.quote(this.nodeGroupKeyProperty)); return a + b
    };
    t.$o = function (a) {
        Z.prototype.$o.call(this, a); a.linkKeyProperty && (this.linkKeyProperty = a.linkKeyProperty); a.linkFromKeyProperty && (this.linkFromKeyProperty = a.linkFromKeyProperty); a.linkToKeyProperty && (this.linkToKeyProperty = a.linkToKeyProperty); a.linkFromPortIdProperty && (this.linkFromPortIdProperty = a.linkFromPortIdProperty); a.linkToPortIdProperty && (this.linkToPortIdProperty = a.linkToPortIdProperty); a.linkCategoryProperty && (this.linkCategoryProperty = a.linkCategoryProperty); a.linkLabelKeysProperty && (this.linkLabelKeysProperty =
            a.linkLabelKeysProperty); a.nodeIsGroupProperty && (this.nodeIsGroupProperty = a.nodeIsGroupProperty); a.nodeGroupKeyProperty && (this.nodeGroupKeyProperty = a.nodeGroupKeyProperty)
    }; t.Kv = function () { var a = Z.prototype.Kv.call(this), b = ',\n  "linkDataArray": ' + pr(this, this.linkDataArray, !0); return a + b }; t.gv = function (a) { Z.prototype.gv.call(this, a); a = a.linkDataArray; Array.isArray(a) && (this.am(a), this.linkDataArray = a) };
    function Dr(a, b) { for (var c = new H, d = 0; d < a.linkDataArray.length; d++) { var e = a.linkDataArray[d]; qr(a, b, e, e, c) } return c }
    t.Iv = function (a, b) {
        "" === this.linkKeyProperty && C("GraphLinksModel.linkKeyProperty must not be an empty string for .toIncrementalJson() to succeed."); var c = Z.prototype.Iv.call(this, a, b), d = this, e = new H, f = new H, g = new H, h = this.qh; a.changes.each(function (a) {
            a.model === d && ("linkDataArray" === a.modelChange ? a.change === ee ? e.add(a.newValue) : a.change === fe && g.add(a.oldValue) : d.Gc(a.object) ? f.add(a.object) : null !== a.object && (null !== a.object && h && h.contains(a.object) ? h.get(a.object).each(function (a) { d.Gc(a) && f.add(a) }) :
                a.object instanceof Z || d.mb(a.object) || Dr(d, a.object).each(function (a) { f.add(a) })))
        }); var k = this.dl; k.clear(); e.each(function (a) { k.add(d.xc(a)); b || f.add(a) }); var l = this.fl; l.clear(); g.each(function (a) { l.add(d.xc(a)); b && f.add(a) }); a = c; 0 < k.count && (a += (b ? this.Qv : this.Ov) + pr(this, k.Fa(), !0)); 0 < f.count && (a += this.by + pr(this, f.Fa(), !0)); 0 < l.count && (a += (b ? this.Ov : this.Qv) + pr(this, l.Fa(), !0)); l.clear(); k.clear(); return a
    };
    t.Zo = function (a) {
        (void 0 !== a.linkCategoryProperty && a.linkCategoryProperty !== this.linkCategoryProperty || void 0 !== a.linkKeyProperty && a.linkKeyProperty !== this.linkKeyProperty || void 0 !== a.linkFromKeyProperty && a.linkFromKeyProperty !== this.linkFromKeyProperty || void 0 !== a.linkToKeyProperty && a.linkToKeyProperty !== this.linkToKeyProperty || void 0 !== a.linkFromPortIdProperty && a.linkFromPortIdProperty !== this.linkFromPortIdProperty || void 0 !== a.linkToPortIdProperty && a.linkToPortIdProperty !== this.linkToPortIdProperty ||
            void 0 !== a.linkLabelKeysProperty && a.linkLabelKeysProperty !== this.linkLabelKeysProperty || void 0 !== a.nodeIsGroupProperty && a.nodeIsGroupProperty !== this.nodeIsGroupProperty || void 0 !== a.nodeGroupKeyProperty && a.nodeGroupKeyProperty !== this.nodeGroupKeyProperty) && C("applyIncrementalJson cannot change Model properties"); Z.prototype.Zo.call(this, a); var b = a.insertedLinkKeys; if (Array.isArray(b)) for (var c = b.length, d = 0; d < c; d++) { var e = b[d], f = this.eh(e); null === f && (f = this.Fo({}), this.Ds(f, e), this.ci(f)) } b = a.modifiedLinkData;
        if (Array.isArray(b)) for (c = b.length, d = 0; d < c; d++)if (e = b[d], f = this.xc(e), f = this.eh(f), null !== f) { for (var g in e) "__gohashid" !== g && g !== this.linkKeyProperty && this.setDataProperty(f, g, e[g]); Er(this, e, f) } a = a.removedLinkKeys; if (Array.isArray(a)) for (g = a.length, b = 0; b < g; b++)c = this.eh(a[b]), null !== c && this.Zl(c)
    };
    Cr.prototype.changeState = function (a, b) {
        if (null !== a && a.model === this) {
            if (a.change === ce) { var c = a.object, d = a.propertyName; if (d === this.linkKeyProperty && this.Gc(c)) { var e = a.I(b); b = a.I(!b); void 0 !== b && this.wb.remove(b); void 0 !== e && this.wb.add(e, c); Tj(c, d, e); return } } else if (a.change === ee) {
                c = a.newParam; if ("linkDataArray" === a.modelChange) {
                    a = a.newValue; za(a) && "number" === typeof c && (d = this.xc(a), b ? (this.Wf.remove(a), this.dd[c] === a && this.dd.splice(c, 1), void 0 !== d && this.wb.remove(d)) : (this.Wf.add(a), this.dd[c] !==
                        a && this.dd.splice(c, 0, a), void 0 !== d && this.wb.add(d, a))); return
                } if ("linkLabelKeys" === a.modelChange) { d = this.Bf(a.object); Array.isArray(d) && "number" === typeof c && (b ? (c = d.indexOf(a.newValue), 0 <= c && d.splice(c, 1)) : 0 > d.indexOf(a.newValue) && d.splice(c, 0, a.newValue)); return }
            } else if (a.change === fe) {
                c = a.oldParam; if ("linkDataArray" === a.modelChange) {
                    a = a.oldValue; za(a) && "number" === typeof c && (d = this.xc(a), b ? (this.Wf.add(a), this.dd[c] !== a && this.dd.splice(c, 0, a), void 0 !== d && this.wb.add(d, a)) : (this.Wf.remove(a), this.dd[c] ===
                        a && this.dd.splice(c, 1), void 0 !== d && this.wb.remove(d))); return
                } if ("linkLabelKeys" === a.modelChange) { d = this.Bf(a.object); Array.isArray(d) && "number" === typeof c && (b ? 0 > d.indexOf(a.newValue) && d.splice(c, 0, a.newValue) : (c = d.indexOf(a.newValue), 0 <= c && d.splice(c, 1))); return }
            } Z.prototype.changeState.call(this, a, b)
        }
    }; t = Cr.prototype; t.Rl = function (a) { if (void 0 !== a) { var b = this.Oi; if (null !== b) { var c = this.dc(a); null === c && (c = this.copyNodeData(b), Tj(c, this.nodeKeyProperty, a), this.uf(c)) } return a } };
    t.sx = function (a) { return Yq(this, a, !0) }; t.pv = function (a, b) { fr(this, a, b, !0) }; t.vx = function (a) { return Yq(this, a, !1) }; t.uv = function (a, b) { fr(this, a, b, !1) }; function Yq(a, b, c) { if (null !== b && (a = c ? a.gf : a.hf, "" !== a && (a = Bn(b, a), void 0 !== a))) { if ($q(a)) return a; C((c ? "FromKey" : "ToKey") + " value for link data " + b + " is not a number or a string: " + a) } }
    function fr(a, b, c, d) { null === c && (c = void 0); if (null !== b) { var e = d ? a.gf : a.hf; if ("" !== e) if (c = a.Rl(c), a.Gc(b)) { var f = Bn(b, e); f !== c && (dr(a, f, b), Tj(b, e, c), null === a.dc(c) && er(a, c, b), wr(a, d ? "linkFromKey" : "linkToKey", ce, e, b, f, c), "string" === typeof e && a.Ma(b, e)) } else Tj(b, e, c) } } t.tx = function (a) { return Xq(this, a, !0) }; t.qv = function (a, b) { gr(this, a, b, !0) }; t.wx = function (a) { return Xq(this, a, !1) }; t.vv = function (a, b) { gr(this, a, b, !1) };
    function Xq(a, b, c) { if (null === b) return ""; a = c ? a.cj : a.dj; if ("" === a) return ""; b = Bn(b, a); return void 0 === b ? "" : b } function gr(a, b, c, d) { if (null !== b) { var e = d ? a.cj : a.dj; if ("" !== e) if (a.Gc(b)) { var f = Bn(b, e); void 0 === f && (f = ""); f !== c && (Tj(b, e, c), wr(a, d ? "linkFromPortId" : "linkToPortId", ce, e, b, f, c), "string" === typeof e && a.Ma(b, e)) } else Tj(b, e, c) } } t.Bf = function (a) { if (null === a) return Fr; var b = this.fe; if ("" === b) return Fr; a = Bn(a, b); return void 0 === a ? Fr : a };
    t.Es = function (a, b) { if (null !== a) { var c = this.fe; if ("" !== c) if (this.Gc(a)) { var d = Bn(a, c); void 0 === d && (d = Fr); if (d !== b) { if (Array.isArray(d)) for (var e = d.length, f = 0; f < e; f++)dr(this, d[f], a); Tj(a, c, b); e = b.length; for (f = 0; f < e; f++) { var g = b[f]; null === this.dc(g) && er(this, g, a) } wr(this, "linkLabelKeys", ce, c, a, d, b); "string" === typeof c && this.Ma(a, c) } } else Tj(a, c, b) } };
    t.wu = function (a, b) { if (null !== b && void 0 !== b && null !== a) { var c = this.fe; if ("" !== c) { var d = Bn(a, c); if (void 0 === d) c = [], c.push(b), this.Es(a, c); else if (Array.isArray(d)) { var e = d.indexOf(b); 0 <= e || (e = d.length, d.push(b), this.Gc(a) && (null === this.dc(b) && er(this, b, a), wr(this, "linkLabelKeys", ee, c, a, null, b, null, e))) } else C(c + " property is not an Array; cannot addLabelKeyForLinkData: " + a) } } };
    t.Mx = function (a, b) { if (null !== b && void 0 !== b && null !== a) { var c = this.fe; if ("" !== c) { var d = Bn(a, c); if (Array.isArray(d)) { var e = d.indexOf(b); 0 > e || (d.splice(e, 1), this.Gc(a) && (dr(this, b, a), wr(this, "linkLabelKeys", fe, c, a, b, null, e, null))) } else void 0 !== d && C(c + " property is not an Array; cannot removeLabelKeyforLinkData: " + a) } } }; t.xc = function (a) { if (null !== a) { var b = this.Fh; if ("" !== b && (b = Bn(a, b), void 0 !== b)) { if ($q(b)) return b; C("Key value for link data " + a + " is not a number or a string: " + b) } } };
    t.Ds = function (a, b) { if (null !== a) { var c = this.Fh; if ("" !== c) if (this.Gc(a)) { var d = Bn(a, c); d !== b && null === this.eh(b) && (Tj(a, c, b), void 0 !== d && this.wb.remove(d), this.wb.add(b, a), wr(this, "linkKey", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c)) } else Tj(a, c, b) } }; t.eh = function (a) { null === a && C("GraphLinksModel.findLinkDataForKey:key must not be null"); return void 0 !== a && $q(a) ? this.wb.I(a) : null };
    t.us = function (a) { if (null !== a) { var b = this.Fh; if ("" !== b) { var c = this.xc(a); if (void 0 === c || this.wb.contains(c)) { var d = this.Yk; if (null !== d && (c = d(this, a), void 0 !== c && null !== c && !this.wb.contains(c))) { Tj(a, b, c); return } if ("string" === typeof c) { for (d = 2; this.wb.contains(c + d);)d++; Tj(a, b, c + d) } else if (void 0 === c || "number" === typeof c) { for (c = -this.wb.count - 1; this.wb.contains(c);)c--; Tj(a, b, c) } } } } }; t.Gc = function (a) { return null === a ? !1 : this.Wf.contains(a) }; t.ci = function (a) { null !== a && (fb(a), this.Gc(a) || br(this, a, !0)) };
    function br(a, b, c) { if ("" !== a.linkKeyProperty) { var d = a.xc(b); if (void 0 !== d && a.wb.I(d) === b) return; a.us(b); d = a.xc(b); void 0 === d && C("GraphLinksModel.makeLinkDataKeyUnique failed on " + b + ". Data not added to model."); a.wb.add(d, b) } a.Wf.add(b); d = null; c && (d = a.dd.length, a.dd.splice(d, 0, b)); wr(a, "linkDataArray", ee, "linkDataArray", a, null, b, null, d); Gr(a, b) } t.zy = function (a) { if (Array.isArray(a)) for (var b = a.length, c = 0; c < b; c++)this.ci(a[c]); else for (a = a.iterator; a.next();)this.ci(a.value) };
    t.Zl = function (a) { null !== a && ar(this, a, !0) }; function ar(a, b, c) { a.Wf.remove(b); var d = a.xc(b); void 0 !== d && a.wb.remove(d); d = null; if (c) { d = a.dd.indexOf(b); if (0 > d) return; a.dd.splice(d, 1) } wr(a, "linkDataArray", fe, "linkDataArray", a, b, null, d, null); c = Yq(a, b, !0); dr(a, c, b); c = Yq(a, b, !1); dr(a, c, b); d = a.Bf(b); if (Array.isArray(d)) for (var e = d.length, f = 0; f < e; f++)c = d[f], dr(a, c, b) } t.bA = function (a) { if (Array.isArray(a)) for (var b = a.length, c = 0; c < b; c++)this.Zl(a[c]); else for (a = a.iterator; a.next();)this.Zl(a.value) };
    t.Qz = function (a) {
        "" === this.linkKeyProperty && C("GraphLinksModel.linkKeyProperty must not be an empty string for .mergeLinkDataArray() to succeed."); if (a !== this.linkDataArray && Aa(a)) {
            var b = this.fl; b.clear(); b.addAll(this.wb.iteratorKeys); var c = this.dl; c.clear(); for (var d = a.length, e = 0; e < d; e++) { var f = a[e], g = this.xc(f); if (void 0 !== g) { c.add(g); var h = this.eh(g); h !== f && (null !== h ? (this.zo(h, f), Er(this, f, h)) : (f = this.cloneDeep(f), this.Ds(f, g), this.ci(f))) } else g = this.cloneDeep(f), this.ci(g), c.add(this.xc(g)) } for (a =
                b.iterator; a.next();)d = a.value, c.contains(d) || (d = this.eh(d)) && this.Zl(d); b.clear(); c.clear()
        }
    }; function Gr(a, b) { var c = Yq(a, b, !0); c = a.Rl(c); null === a.dc(c) && er(a, c, b); c = Yq(a, b, !1); c = a.Rl(c); null === a.dc(c) && er(a, c, b); var d = a.Bf(b); if (Array.isArray(d)) for (var e = d.length, f = 0; f < e; f++)c = d[f], null === a.dc(c) && er(a, c, b) }
    t.Fo = function (a) { if (null === a) return null; var b = this.vk; a = null !== b ? b(a, this) : xr(this, a, !0); za(a) && (Qa(a), "" !== this.gf && Tj(a, this.gf, void 0), "" !== this.hf && Tj(a, this.hf, void 0), "" !== this.fe && Tj(a, this.fe, [])); return a }; t.Uu = function (a) { if (null === a) return !1; var b = this.el; return "" === b ? !1 : Bn(a, b) ? !0 : !1 }; t.gh = function (a) { if (null !== a) { var b = this.Fe; if ("" !== b && (b = Bn(a, b), void 0 !== b)) { if ($q(b)) return b; C("GroupKey value for node data " + a + " is not a number or a string: " + b) } } };
    t.gp = function (a, b) { null === b && (b = void 0); if (null !== a) { var c = this.Fe; if ("" !== c) if (this.mb(a)) { var d = Bn(a, c); d !== b && (dr(this, d, a), Tj(a, c, b), null === this.dc(b) && er(this, b, a), wr(this, "nodeGroupKey", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c)) } else Tj(a, c, b) } }; Cr.prototype.copyNodeData = function (a) { if (null === a) return null; a = Z.prototype.copyNodeData.call(this, a); this.Gj || "" === this.Fe || void 0 === Bn(a, this.Fe) || Tj(a, this.Fe, void 0); return a };
    Cr.prototype.setDataProperty = function (a, b, c) {
        if (this.mb(a)) { if (b === this.nodeKeyProperty) { this.hm(a, c); return } if (b === this.nodeCategoryProperty) { this.gm(a, c); return } if (b === this.nodeGroupKeyProperty) { this.gp(a, c); return } } else if (this.Gc(a)) {
            if (b === this.linkFromKeyProperty) { fr(this, a, c, !0); return } if (b === this.linkToKeyProperty) { fr(this, a, c, !1); return } if (b === this.linkFromPortIdProperty) { gr(this, a, c, !0); return } if (b === this.linkToPortIdProperty) { gr(this, a, c, !1); return } if (b === this.linkKeyProperty) {
                this.Ds(a,
                    c); return
            } if (b === this.linkCategoryProperty) { this.fp(a, c); return } if (b === this.linkLabelKeysProperty) { this.Es(a, c); return }
        } var d = Bn(a, b); d !== c && (Tj(a, b, c), this.ys(a, b, d, c))
    }; t = Cr.prototype; t.zo = function (a, b) { if (b) { var c = this.mb(a), d = this.Gc(a), e; for (e in b) "__gohashid" === e || c && e === this.nodeKeyProperty || c && e === this.nodeIsGroupProperty && Bn(a, e) === b[e] || d && e === this.linkKeyProperty || this.setDataProperty(a, e, b[e]) } };
    t.cp = function (a, b) {
        Z.prototype.cp.call(this, a, b); for (var c = this.lb.iterator; c.next();)this.lv(c.value, a, b); for (c = this.Wf.iterator; c.next();) {
            var d = c.value, e = a, f = b; if (Yq(this, d, !0) === e) { var g = this.gf; Tj(d, g, f); wr(this, "linkFromKey", ce, g, d, e, f); "string" === typeof g && this.Ma(d, g) } Yq(this, d, !1) === e && (g = this.hf, Tj(d, g, f), wr(this, "linkToKey", ce, g, d, e, f), "string" === typeof g && this.Ma(d, g)); g = this.Bf(d); if (Array.isArray(g)) for (var h = g.length, k = this.fe, l = 0; l < h; l++)g[l] === e && (g[l] = f, wr(this, "linkLabelKeys", ee,
                k, d, e, f, l, l))
        }
    }; t.lv = function (a, b, c) { if (this.gh(a) === b) { var d = this.Fe; Tj(a, d, c); wr(this, "nodeGroupKey", ce, d, a, b, c); "string" === typeof d && this.Ma(a, d) } }; t.Ev = function () { Z.prototype.Ev.call(this); for (var a = this.linkDataArray, b = a.length, c = 0; c < b; c++)Gr(this, a[c]) };
    t.cm = function (a) {
        Z.prototype.cm.call(this, a); a = this.va(a); var b = cr(this, a); if (null !== b) {
            var c = Ea(); for (b = b.iterator; b.next();) {
                var d = b.value; if (this.mb(d)) { if (this.gh(d) === a) { var e = this.Fe; wr(this, "nodeGroupKey", ce, e, d, a, a); "string" === typeof e && this.Ma(d, e); c.push(d) } } else if (Yq(this, d, !0) === a && (e = this.gf, wr(this, "linkFromKey", ce, e, d, a, a), "string" === typeof e && this.Ma(d, e), c.push(d)), Yq(this, d, !1) === a && (e = this.hf, wr(this, "linkToKey", ce, e, d, a, a), "string" === typeof e && this.Ma(d, e), c.push(d)), e = this.Bf(d),
                    Array.isArray(e)) for (var f = e.length, g = this.fe, h = 0; h < f; h++)e[h] === a && (wr(this, "linkLabelKeys", ee, g, d, a, a, h, h), c.push(d))
            } for (b = 0; b < c.length; b++)dr(this, a, c[b]); Ga(c)
        }
    }; t.bm = function (a) { Z.prototype.bm.call(this, a); var b = this.gh(a); null === this.dc(b) && er(this, b, a) }; t.kp = function (a) { Z.prototype.kp.call(this, a); var b = this.gh(a); dr(this, b, a) };
    t.hs = function (a) { if (null === a) return ""; var b = this.bj; if ("" === b) return ""; b = Bn(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; C("getCategoryForLinkData found a non-string category for " + a + ": " + b) }; Cr.prototype.getLinkCategoryForData = function (a) { return this.hs(a) }; Cr.prototype.fp = function (a, b) { if (null !== a) { var c = this.bj; if ("" !== c) if (this.Gc(a)) { var d = Bn(a, c); void 0 === d && (d = ""); d !== b && (Tj(a, c, b), wr(this, "linkCategory", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c)) } else Tj(a, c, b) } };
    Cr.prototype.setLinkCategoryForData = function (a, b) { this.fp(a, b) }; t = Cr.prototype; t.im = function (a, b) { Z.prototype.im.call(this, a, b); this.gp(b, this.gh(a)) }; function Er(a, b, c) { a.fp(c, a.hs(b)); a.pv(c, a.sx(b)); a.uv(c, a.vx(b)); a.Es(c, a.Bf(b)); a.qv(c, a.tx(b)); a.vv(c, a.wx(b)) } t.Tj = function () { return !0 }; t.ni = function () { return !0 }; t.qs = function () { return !0 }; t.Uj = function () { return !0 };
    ma.Object.defineProperties(Cr.prototype, {
        archetypeNodeData: { get: function () { return this.Oi }, set: function (a) { var b = this.Oi; b !== a && (this.Oi = a, this.g("archetypeNodeData", b, a)) } }, linkFromKeyProperty: { get: function () { return this.gf }, set: function (a) { var b = this.gf; b !== a && (this.gf = a, this.g("linkFromKeyProperty", b, a)) } }, linkToKeyProperty: {
            get: function () { return this.hf }, set: function (a) {
                var b = this.hf; b !== a && (this.hf = a, this.g("linkToKeyProperty",
                    b, a))
            }
        }, linkFromPortIdProperty: { get: function () { return this.cj }, set: function (a) { var b = this.cj; b !== a && (this.cj = a, this.g("linkFromPortIdProperty", b, a)) } }, linkToPortIdProperty: { get: function () { return this.dj }, set: function (a) { var b = this.dj; b !== a && (this.dj = a, this.g("linkToPortIdProperty", b, a)) } }, linkLabelKeysProperty: {
            get: function () { return this.fe }, set: function (a) {
                var b = this.fe; b !== a && (this.fe = a, this.g("linkLabelKeysProperty",
                    b, a))
            }
        }, linkDataArray: {
            get: function () { return this.dd }, set: function (a) {
                var b = this.dd; if (b !== a) {
                    this.wb.clear(); for (var c = a.length, d = 0; d < c; d++) { var e = a[d]; za(e) || C("GraphLinksModel.linkDataArray must only contain Objects, not: " + e); fb(e) } this.dd = a; if ("" !== this.linkKeyProperty) {
                        d = new F; for (e = 0; e < c; e++) { var f = a[e], g = this.xc(f); void 0 === g ? d.add(f) : null !== this.wb.I(g) ? d.add(f) : this.wb.add(g, f) } for (d = d.iterator; d.next();)e = d.value, this.us(e), f = this.xc(e), void 0 !== f && this.wb.add(f,
                            e)
                    } d = new H; for (e = 0; e < c; e++)d.add(a[e]); this.Wf = d; wr(this, "linkDataArray", ce, "linkDataArray", this, b, a); for (b = 0; b < c; b++)Gr(this, a[b])
                }
            }
        }, linkKeyProperty: { get: function () { return this.Fh }, set: function (a) { var b = this.Fh; if (b !== a) { this.Fh = a; this.wb.clear(); for (var c = this.linkDataArray.length, d = 0; d < c; d++) { var e = this.linkDataArray[d], f = this.xc(e); void 0 === f && (this.us(e), f = this.xc(e)); void 0 !== f && this.wb.add(f, e) } this.g("linkKeyProperty", b, a) } } }, makeUniqueLinkKeyFunction: {
            get: function () { return this.Yk }, set: function (a) { var b = this.Yk; b !== a && (this.Yk = a, this.g("makeUniqueLinkKeyFunction", b, a)) }
        }, copyLinkDataFunction: { get: function () { return this.vk }, set: function (a) { var b = this.vk; b !== a && (this.vk = a, this.g("copyLinkDataFunction", b, a)) } }, nodeIsGroupProperty: { get: function () { return this.el }, set: function (a) { var b = this.el; b !== a && (this.el = a, this.g("nodeIsGroupProperty", b, a)) } }, nodeGroupKeyProperty: {
            get: function () { return this.Fe }, set: function (a) { var b = this.Fe; b !== a && (this.Fe = a, this.g("nodeGroupKeyProperty", b, a)) }
        }, Gj: { get: function () { return this.Im }, set: function (a) { this.Im !== a && (this.Im = a) } }, linkCategoryProperty: { get: function () { return this.bj }, set: function (a) { var b = this.bj; b !== a && (this.bj = a, this.g("linkCategoryProperty", b, a)) } }, type: { get: function () { return "GraphLinksModel" } }
    });
    Cr.prototype.setCategoryForLinkData = Cr.prototype.fp; Cr.prototype.getCategoryForLinkData = Cr.prototype.hs; Cr.prototype.assignAllDataProperties = Cr.prototype.zo; Cr.prototype.setGroupKeyForNodeData = Cr.prototype.gp; Cr.prototype.getGroupKeyForNodeData = Cr.prototype.gh; Cr.prototype.isGroupForNodeData = Cr.prototype.Uu; Cr.prototype.copyLinkData = Cr.prototype.Fo; Cr.prototype.mergeLinkDataArray = Cr.prototype.Qz; Cr.prototype.removeLinkDataCollection = Cr.prototype.bA; Cr.prototype.removeLinkData = Cr.prototype.Zl;
    Cr.prototype.addLinkDataCollection = Cr.prototype.zy; Cr.prototype.addLinkData = Cr.prototype.ci; Cr.prototype.containsLinkData = Cr.prototype.Gc; Cr.prototype.makeLinkDataKeyUnique = Cr.prototype.us; Cr.prototype.findLinkDataForKey = Cr.prototype.eh; Cr.prototype.setKeyForLinkData = Cr.prototype.Ds; Cr.prototype.getKeyForLinkData = Cr.prototype.xc; Cr.prototype.removeLabelKeyForLinkData = Cr.prototype.Mx; Cr.prototype.addLabelKeyForLinkData = Cr.prototype.wu; Cr.prototype.setLabelKeysForLinkData = Cr.prototype.Es;
    Cr.prototype.getLabelKeysForLinkData = Cr.prototype.Bf; Cr.prototype.setToPortIdForLinkData = Cr.prototype.vv; Cr.prototype.getToPortIdForLinkData = Cr.prototype.wx; Cr.prototype.setFromPortIdForLinkData = Cr.prototype.qv; Cr.prototype.getFromPortIdForLinkData = Cr.prototype.tx; Cr.prototype.setToKeyForLinkData = Cr.prototype.uv; Cr.prototype.getToKeyForLinkData = Cr.prototype.vx; Cr.prototype.setFromKeyForLinkData = Cr.prototype.pv; Cr.prototype.getFromKeyForLinkData = Cr.prototype.sx; Cr.prototype.clear = Cr.prototype.clear;
    var Fr = Object.freeze([]); Cr.className = "GraphLinksModel"; zr.GraphLinksModel = Cr; Z.constructGraphLinksModel = Z.constructGraphLinksModel = function () { return new Cr }; Z.initDiagramModel = pi = function () { return new Cr }; function Hr(a, b) { Z.call(this); this.Ge = "parent"; this.Km = !1; this.lj = "parentLinkCategory"; void 0 !== a && (Aa(a) ? this.nodeDataArray = a : Object.assign(this, a)); b && Object.assign(this, b) } la(Hr, Z); Hr.constructGraphLinksModel = Z.constructGraphLinksModel;
    Hr.prototype.cloneProtected = function (a) { Z.prototype.cloneProtected.call(this, a); a.Ge = this.Ge; a.Km = this.Km; a.lj = this.lj }; t = Hr.prototype; t.toString = function (a) { void 0 === a && (a = 0); if (2 <= a) return this.hp(); var b = ("" !== this.name ? this.name : "") + " TreeModel"; if (0 < a) { b += "\n node data:"; a = this.nodeDataArray; for (var c = a.length, d = 0; d < c; d++) { var e = a[d]; b += " " + this.va(e) + ":" + Ia(e) } } return b };
    t.om = function () { var a = Z.prototype.om.call(this), b = ""; "parent" !== this.nodeParentKeyProperty && "string" === typeof this.nodeParentKeyProperty && (b += ',\n  "nodeParentKeyProperty": ' + this.quote(this.nodeParentKeyProperty)); return a + b }; t.$o = function (a) { Z.prototype.$o.call(this, a); a.nodeParentKeyProperty && (this.nodeParentKeyProperty = a.nodeParentKeyProperty) };
    t.Zo = function (a) { void 0 !== a.nodeParentKeyProperty && a.nodeParentKeyProperty !== this.nodeParentKeyProperty && C("applyIncrementalJson cannot change Model properties"); Z.prototype.Zo.call(this, a) }; t.Rl = function (a) { return a }; t.hh = function (a) { if (null !== a) { var b = this.Ge; if ("" !== b && (b = Bn(a, b), void 0 !== b)) { if ($q(b)) return b; C("ParentKey value for node data " + a + " is not a number or a string: " + b) } } };
    t.me = function (a, b) { null === b && (b = void 0); if (null !== a) { var c = this.Ge; if ("" !== c) if (b = this.Rl(b), this.mb(a)) { var d = Bn(a, c); d !== b && (dr(this, d, a), Tj(a, c, b), null === this.dc(b) && er(this, b, a), wr(this, "nodeParentKey", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c)) } else Tj(a, c, b) } }; t.ls = function (a) { if (null === a) return ""; var b = this.lj; if ("" === b) return ""; b = Bn(a, b); if (void 0 === b) return ""; if ("string" === typeof b) return b; C("getParentLinkCategoryForNodeData found a non-string category for " + a + ": " + b); return "" };
    Hr.prototype.getLinkCategoryForData = function (a) { return this.ls(a) }; Hr.prototype.Fs = function (a, b) { if (null !== a) { var c = this.lj; if ("" !== c) if (this.mb(a)) { var d = Bn(a, c); void 0 === d && (d = ""); d !== b && (Tj(a, c, b), wr(this, "parentLinkCategory", ce, c, a, d, b), "string" === typeof c && this.Ma(a, c)) } else Tj(a, c, b) } }; Hr.prototype.setLinkCategoryForData = function (a, b) { this.Fs(a, b) };
    Hr.prototype.copyNodeData = function (a) { if (null === a) return null; a = Z.prototype.copyNodeData.call(this, a); this.Hj || "" === this.Ge || void 0 === Bn(a, this.Ge) || Tj(a, this.Ge, void 0); return a }; Hr.prototype.setDataProperty = function (a, b, c) { if (this.mb(a)) { if (b === this.nodeKeyProperty) { this.hm(a, c); return } if (b === this.nodeCategoryProperty) { this.gm(a, c); return } if (b === this.nodeParentKeyProperty) { this.me(a, c); return } } var d = Bn(a, b); d !== c && (Tj(a, b, c), this.ys(a, b, d, c)) }; t = Hr.prototype;
    t.cp = function (a, b) { Z.prototype.cp.call(this, a, b); for (var c = this.lb.iterator; c.next();)this.lv(c.value, a, b) }; t.lv = function (a, b, c) { if (this.hh(a) === b) { var d = this.Ge; Tj(a, d, c); wr(this, "nodeParentKey", ce, d, a, b, c); "string" === typeof d && this.Ma(a, d) } };
    t.cm = function (a) { Z.prototype.cm.call(this, a); a = this.va(a); var b = cr(this, a); if (null !== b) { var c = Ea(); for (b = b.iterator; b.next();) { var d = b.value; if (this.mb(d) && this.hh(d) === a) { var e = this.Ge; wr(this, "nodeParentKey", ce, e, d, a, a); "string" === typeof e && this.Ma(d, e); c.push(d) } } for (b = 0; b < c.length; b++)dr(this, a, c[b]); Ga(c) } }; t.bm = function (a) { Z.prototype.bm.call(this, a); var b = this.hh(a); b = this.Rl(b); null === this.dc(b) && er(this, b, a) }; t.kp = function (a) { Z.prototype.kp.call(this, a); var b = this.hh(a); dr(this, b, a) };
    t.im = function (a, b) { Z.prototype.im.call(this, a, b); this.Fs(b, this.ls(a)); this.me(b, this.hh(a)) }; t.Pl = function () { return !0 }; t.qs = function () { return !0 };
    ma.Object.defineProperties(Hr.prototype, {
        nodeParentKeyProperty: { get: function () { return this.Ge }, set: function (a) { var b = this.Ge; b !== a && (this.Ge = a, this.g("nodeParentKeyProperty", b, a)) } }, Hj: { get: function () { return this.Km }, set: function (a) { this.Km !== a && (this.Km = a) } }, parentLinkCategoryProperty: { get: function () { return this.lj }, set: function (a) { var b = this.lj; b !== a && (this.lj = a, this.g("parentLinkCategoryProperty", b, a)) } },
        linkCategoryProperty: { get: function () { return this.parentLinkCategoryProperty }, set: function (a) { this.parentLinkCategoryProperty = a } }, type: { get: function () { return "TreeModel" } }
    }); Hr.prototype.setParentLinkCategoryForNodeData = Hr.prototype.Fs; Hr.prototype.getParentLinkCategoryForNodeData = Hr.prototype.ls; Hr.prototype.setParentKeyForNodeData = Hr.prototype.me; Hr.prototype.getParentKeyForNodeData = Hr.prototype.hh; Hr.className = "TreeModel"; zr.TreeModel = Hr;
    function Ir(a) { ri.call(this); this.ew = this.Wm = this.hc = 0; this.Pp = 360; this.dw = Jr; this.Qi = 0; this.Sv = new I; this.Dp = this.ie = 0; this.Pr = new Kr; this.Zs = this.kj = 0; this.oy = 600; this.$n = NaN; this.zm = 1; this.no = 0; this.ql = 360; this.Mb = Jr; this.K = Lr; this.ed = Mr; this.$c = Bq; this.qf = 6; this.Jn = Nr; a && Object.assign(this, a) } la(Ir, ri);
    Ir.prototype.cloneProtected = function (a) { ri.prototype.cloneProtected.call(this, a); a.$n = this.$n; a.zm = this.zm; a.no = this.no; a.ql = this.ql; a.Mb = this.Mb; a.K = this.K; a.ed = this.ed; a.$c = this.$c; a.qf = this.qf; a.Jn = this.Jn };
    Ir.prototype.rb = function (a) { if (a.classType === Ir) if (a === Or || a === Pr || a === Qr || a === Rr || a === Mr) this.sorting = a; else if (a === Sr || a === Tr || a === Lr || a === Ur) this.direction = a; else if (a === Vr || a === Wr || a === Jr || a === Xr) this.arrangement = a; else { if (a === Yr || a === Nr) this.nodeDiameterFormula = a } else ri.prototype.rb.call(this, a) }; Ir.prototype.createNetwork = function () { return new Zr(this) };
    Ir.prototype.doLayout = function (a) {
        null === this.network && (this.network = this.makeNetwork(a)); this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin); a = this.network.vertexes; if (1 >= a.count) 1 === a.count && (a = a.first(), a.centerX = 0, a.centerY = 0); else {
            var b = new F; b.addAll(a.iterator); a = new F; var c = new F; var d = this.sort(b); var e, f, g = this.Dp; var h = this.arrangement; var k = this.nodeDiameterFormula; var l = this.radius; if (!isFinite(l) || 0 >= l) l = NaN; var m = this.aspectRatio; if (!isFinite(m) || 0 >= m) m = 1; var n = this.startAngle;
            isFinite(n) || (n = 0); var p = this.sweepAngle; if (!isFinite(p) || 360 < p || 1 > p) p = 360; b = this.spacing; isFinite(b) || (b = NaN); h === Xr && k === Yr ? h = Jr : h === Xr && k !== Yr && (h = this.arrangement); if ((this.direction === Sr || this.direction === Tr) && this.sorting !== Mr) { for (k = 0; !(k >= d.length); k += 2) { a.add(d.L(k)); if (k + 1 >= d.length) break; c.add(d.L(k + 1)) } this.direction === Sr ? (this.arrangement === Xr && a.reverse(), d = new F, d.addAll(a), d.addAll(c)) : (this.arrangement === Xr && c.reverse(), d = new F, d.addAll(c), d.addAll(a)) } k = d.length; for (var r = f = e = 0; r <
                d.length; r++) { var q = n + p * f * (this.direction === Lr ? 1 : -1) / k, u = d.L(r).diameter; isNaN(u) && (u = $r(d.L(r), q)); 360 > p && (0 === r || r === d.length - 1) && (u /= 2); e += u; f++ } if (isNaN(l) || h === Xr) {
                    isNaN(b) && (b = 6); if (h !== Jr && h !== Xr) { f = -Infinity; for (g = 0; g < k; g++)r = d.L(g), e = d.L(g === k - 1 ? 0 : g + 1), isNaN(r.diameter) && $r(r, 0), isNaN(e.diameter) && $r(e, 0), f = Math.max(f, (r.diameter + e.diameter) / 2); g = f + b; h === Vr ? l = (f + b) / (2 * Math.PI / k) : l = as(this, g * (360 <= p ? k : k - 1), m, n * Math.PI / 180, p * Math.PI / 180) } else l = as(this, e + (360 <= p ? k : k - 1) * (h !== Xr ? b : 1.6 * b), m, n *
                        Math.PI / 180, p * Math.PI / 180); f = l * m
                } else if (f = l * m, r = bs(this, l, f, n * Math.PI / 180, p * Math.PI / 180), isNaN(b)) { if (h === Jr || h === Xr) b = (r - e) / (360 <= p ? k : k - 1) } else if (h === Jr || h === Xr) r = (r - e) / (360 <= p ? k : k - 1), r < b ? (l = as(this, e + b * (360 <= p ? k : k - 1), m, n * Math.PI / 180, p * Math.PI / 180), f = l * m) : b = r; else {
                    g = -Infinity; for (e = 0; e < k; e++)q = d.L(e), u = d.L(e === k - 1 ? 0 : e + 1), isNaN(q.diameter) && $r(q, 0), isNaN(u.diameter) && $r(u, 0), g = Math.max(g, (q.diameter + u.diameter) / 2); g += b; e = as(this, g * (360 <= p ? k : k - 1), m, n * Math.PI / 180, p * Math.PI / 180); e > l ? (l = e, f = l * m) : g =
                        r / (360 <= p ? k : k - 1)
                } this.dw = h; this.hc = l; this.Wm = m; this.ew = n; this.Pp = p; this.Qi = b; this.ie = f; this.Dp = g; b = d; d = this.dw; h = this.hc; l = this.ew; m = this.Pp; n = this.Qi; p = this.ie; k = this.Dp; if (this.direction !== Sr && this.direction !== Tr || d !== Xr) if (this.direction === Sr || this.direction === Tr) {
                    g = 0; switch (d) { case Wr: g = 180 * cs(this, h, p, l, k) / Math.PI; break; case Jr: k = b = 0; g = a.first(); null !== g && (b = $r(g, Math.PI / 2)); g = c.first(); null !== g && (k = $r(g, Math.PI / 2)); g = 180 * cs(this, h, p, l, n + (b + k) / 2) / Math.PI; break; case Vr: g = m / b.length }if (this.direction ===
                        Sr) { switch (d) { case Wr: ds(this, a, l, Ur); break; case Jr: es(this, a, l, Ur); break; case Vr: fs(this, a, m / 2, l, Ur) }switch (d) { case Wr: ds(this, c, l + g, Lr); break; case Jr: es(this, c, l + g, Lr); break; case Vr: fs(this, c, m / 2, l + g, Lr) } } else { switch (d) { case Wr: ds(this, c, l, Ur); break; case Jr: es(this, c, l, Ur); break; case Vr: fs(this, c, m / 2, l, Ur) }switch (d) { case Wr: ds(this, a, l + g, Lr); break; case Jr: es(this, a, l + g, Lr); break; case Vr: fs(this, a, m / 2, l + g, Lr) } }
                } else switch (d) {
                    case Wr: ds(this, b, l, this.direction); break; case Jr: es(this, b, l, this.direction);
                        break; case Vr: fs(this, b, m, l, this.direction); break; case Xr: gs(this, b, m, l, this.direction)
                } else gs(this, b, m, l - m / 2, Lr)
        } this.updateParts(); this.network = null; this.isValidLayout = !0
    }; function fs(a, b, c, d, e) { var f = a.Pp, g = a.hc; a = a.ie; d = d * Math.PI / 180; c = c * Math.PI / 180; for (var h = b.length, k = 0; k < h; k++) { var l = d + (e === Lr ? k * c / (360 <= f ? h : h - 1) : -(k * c) / h), m = b.L(k), n = g * Math.tan(l) / a; n = Math.sqrt((g * g + a * a * n * n) / (1 + n * n)); m.centerX = n * Math.cos(l); m.centerY = n * Math.sin(l); m.actualAngle = 180 * l / Math.PI } }
    function es(a, b, c, d) { var e = a.hc, f = a.ie, g = a.Qi; c = c * Math.PI / 180; for (var h = b.length, k = 0; k < h; k++) { var l = b.L(k), m = b.L(k === h - 1 ? 0 : k + 1), n = f * Math.sin(c); l.centerX = e * Math.cos(c); l.centerY = n; l.actualAngle = 180 * c / Math.PI; isNaN(l.diameter) && $r(l, 0); isNaN(m.diameter) && $r(m, 0); l = cs(a, e, f, d === Lr ? c : -c, (l.diameter + m.diameter) / 2 + g); c += d === Lr ? l : -l } }
    function ds(a, b, c, d) { var e = a.hc, f = a.ie, g = a.Dp; c = c * Math.PI / 180; for (var h = b.length, k = 0; k < h; k++) { var l = b.L(k); l.centerX = e * Math.cos(c); l.centerY = f * Math.sin(c); l.actualAngle = 180 * c / Math.PI; l = cs(a, e, f, d === Lr ? c : -c, g); c += d === Lr ? l : -l } } function gs(a, b, c, d, e) { var f = a.Pp; a.kj = 0; a.Pr = new Kr; if (360 > c) { for (f = d + (e === Lr ? f : -f); 0 > f;)f += 360; f %= 360; 180 < f && (f -= 360); f *= Math.PI / 180; a.Zs = f; hs(a, b, c, d, e) } else is(a, b, c, d, e); a.Pr.commit(b) }
    function is(a, b, c, d, e) {
        var f = a.hc, g = a.Qi, h = a.Wm, k = f * Math.cos(d * Math.PI / 180), l = a.ie * Math.sin(d * Math.PI / 180), m = b.Fa(); if (3 === m.length) m[0].centerX = f, m[0].centerY = 0, m[1].centerX = m[0].centerX - m[0].width / 2 - m[1].width / 2 - g, m[1].y = m[0].y, m[2].centerX = (m[0].centerX + m[1].centerX) / 2, m[2].y = m[0].y - m[2].height - g; else if (4 === m.length) m[0].centerX = f, m[0].centerY = 0, m[2].centerX = -m[0].centerX, m[2].centerY = m[0].centerY, m[1].centerX = 0, m[1].y = Math.min(m[0].y, m[2].y) - m[1].height - g, m[3].centerX = 0, m[3].y = Math.max(m[0].y +
            m[0].height + g, m[2].y + m[2].height + g); else {
                f = I.alloc(); for (var n = 0; n < m.length; n++) { m[n].centerX = k; m[n].centerY = l; if (n >= m.length - 1) break; js(a, k, l, m, n, e, f) || ks(a, k, l, m, n, e, f); k = f.x; l = f.y } I.free(f); a.kj++; if (!(23 < a.kj)) {
                    k = m[0].centerX; l = m[0].centerY; f = m[m.length - 1].centerX; n = m[m.length - 1].centerY; var p = Math.abs(k - f) - ((m[0].width + m[m.length - 1].width) / 2 + g), r = Math.abs(l - n) - ((m[0].height + m[m.length - 1].height) / 2 + g); g = 0; 1 > Math.abs(r) ? Math.abs(k - f) < (m[0].width + m[m.length - 1].width) / 2 && (g = 0) : g = 0 < r ? r : 1 > Math.abs(p) ?
                        0 : p; k = Math.abs(f) > Math.abs(n) ? 0 < f !== l > n : 0 < n !== k < f; if (k = e === Lr ? k : !k) g = -Math.abs(g), g = Math.min(g, -m[m.length - 1].width), g = Math.min(g, -m[m.length - 1].height); a.Pr.compare(g, m); 1 < Math.abs(g) && (a.hc = 8 > a.kj ? a.hc - g / (2 * Math.PI) : 5 > m.length && 10 < g ? a.hc / 2 : a.hc - (0 < g ? 1.7 : -2.3), a.ie = a.hc * h, is(a, b, c, d, e))
                }
        }
    }
    function hs(a, b, c, d, e) {
        for (var f = a.hc, g = a.ie, h = a.Wm, k = f * Math.cos(d * Math.PI / 180), l = g * Math.sin(d * Math.PI / 180), m = I.alloc(), n = b.Fa(), p = 0; p < n.length; p++) { n[p].centerX = k; n[p].centerY = l; if (p >= n.length - 1) break; js(a, k, l, n, p, e, m) || ks(a, k, l, n, p, e, m); k = m.x; l = m.y } I.free(m); a.kj++; if (!(23 < a.kj)) {
            k = Math.atan2(l, k); k = e === Lr ? a.Zs - k : k - a.Zs; k = Math.abs(k) < Math.abs(k - 2 * Math.PI) ? k : k - 2 * Math.PI; f = k * (f + g) / 2; g = a.Pr; if (Math.abs(f) < Math.abs(g.Kl)) for (g.Kl = f, g.hk = [], g.qm = [], k = 0; k < n.length; k++)g.hk[k] = n[k].bounds.x, g.qm[k] = n[k].bounds.y;
            1 < Math.abs(f) && (a.hc = 8 > a.kj ? a.hc - f / (2 * Math.PI) : a.hc - (0 < f ? 1.7 : -2.3), a.ie = a.hc * h, hs(a, b, c, d, e))
        }
    } function js(a, b, c, d, e, f, g) { var h = a.hc, k = a.ie, l = 0; a = (d[e].width + d[e + 1].width) / 2 + a.Qi; var m = !1; if (0 <= c !== (f === Lr)) { if (f = b + a, f > h) { f = b - a; if (f < -h) return g.x = f, g.y = l, !1; m = !0 } } else if (f = b - a, f < -h) { f = b + a; if (f > h) return g.x = f, g.y = l, !1; m = !0 } l = Math.sqrt(1 - Math.min(1, f * f / (h * h))) * k; 0 > c !== m && (l = -l); if (Math.abs(c - l) > (d[e].height + d[e + 1].height) / 2) return g.x = f, g.y = l, !1; g.x = f; g.y = l; return !0 }
    function ks(a, b, c, d, e, f, g) { var h = a.hc, k = a.ie, l = 0; a = (d[e].height + d[e + 1].height) / 2 + a.Qi; d = !1; if (0 <= b !== (f === Lr)) { if (f = c - a, f < -k) { f = c + a; if (f > k) { g.x = l; g.y = f; return } d = !0 } } else if (f = c + a, f > k) { f = c - a; if (f < -k) { g.x = l; g.y = f; return } d = !0 } l = Math.sqrt(1 - Math.min(1, f * f / (k * k))) * h; 0 > b !== d && (l = -l); g.x = l; g.y = f } Ir.prototype.commitLayout = function () { this.commitNodes(); this.isRouting && this.commitLinks() };
    Ir.prototype.commitNodes = function () { var a = null !== this.group && null !== this.group.placeholder && this.group.isSubGraphExpanded, b = a ? this.group.location.copy() : null, c = this.actualCenter; a ? c = new I(0, 0) : (c.x = this.arrangementOrigin.x + this.hc, c.y = this.arrangementOrigin.y + this.ie); for (var d = this.network.vertexes.iterator; d.next();) { var e = d.value; e.x += c.x; e.y += c.y; e.commit() } a && (this.group.Va(), a = this.group.position.copy(), c = this.group.location.copy(), b = b.ne(c.ne(a)), this.group.move(b), this.Sv = b.ne(a)) };
    Ir.prototype.commitLinks = function () { for (var a = this.network.edges.iterator; a.next();)a.value.commit() }; function bs(a, b, c, d, e) { var f = a.oy; if (.001 > Math.abs(a.Wm - 1)) return void 0 !== d && void 0 !== e ? e * b : 2 * Math.PI * b; a = b > c ? Math.sqrt(b * b - c * c) / b : Math.sqrt(c * c - b * b) / c; var g = 0; var h = void 0 !== d && void 0 !== e ? e / (f + 1) : Math.PI / (2 * (f + 1)); for (var k = 0, l = 0; l <= f; l++) { void 0 !== d && void 0 !== e ? k = d + l * e / f : k = l * Math.PI / (2 * f); var m = Math.sin(k); g += Math.sqrt(1 - a * a * m * m) * h } return void 0 !== d && void 0 !== e ? (b > c ? b : c) * g : 4 * (b > c ? b : c) * g }
    function as(a, b, c, d, e) { return b / (void 0 !== d && void 0 !== e ? bs(a, 1, c, d, e) : bs(a, 1, c)) } function cs(a, b, c, d, e) { if (.001 > Math.abs(a.Wm - 1)) return e / b; var f = b > c ? Math.sqrt(b * b - c * c) / b : Math.sqrt(c * c - b * b) / c, g = 0; a = 2 * Math.PI / (700 * a.network.vertexes.count); b > c && (d += Math.PI / 2); for (var h = 0; ; h++) { var k = Math.sin(d + h * a); g += (b > c ? b : c) * Math.sqrt(1 - f * f * k * k) * a; if (g >= e) return h * a } }
    Ir.prototype.sort = function (a) { switch (this.sorting) { case Qr: break; case Rr: a.reverse(); break; case Or: a.sort(this.comparer); break; case Pr: a.sort(this.comparer); a.reverse(); break; case Mr: return this.Yj(ls(a)); default: C("Invalid sorting type.") }return a };
    function ls(a) { for (var b = [], c = 0; c < a.length; c++)b.push(0); c = new F; for (var d = 0; d < a.length; d++) { var e = -1, f = -1; if (0 === d) for (var g = 0; g < a.length; g++) { var h = a.L(g).edgesCount; h > e && (e = h, f = g) } else for (g = 0; g < a.length; g++)h = b[g], h > e && (e = h, f = g); c.add(a.L(f)); b[f] = -1; f = a.L(f); for (g = f.sourceEdges; g.next();)e = a.indexOf(g.value.fromVertex), 0 > e || 0 <= b[e] && b[e]++; for (f = f.destinationEdges; f.next();)e = a.indexOf(f.value.toVertex), 0 > e || 0 <= b[e] && b[e]++ } return c }
    Ir.prototype.Yj = function (a) {
        for (var b = [], c = 0; c < a.length; c++) { var d = a.L(c); b[c] = []; for (var e, f = d.destinationEdges; f.next();)e = a.indexOf(f.value.toVertex), e !== c && 0 > b[c].indexOf(e) && b[c].push(e); for (d = d.sourceEdges; d.next();)e = a.indexOf(d.value.fromVertex), e !== c && 0 > b[c].indexOf(e) && b[c].push(e) } f = []; for (c = 0; c < b.length; c++)f[c] = 0; c = []; var g = [], h = []; d = []; e = new F; for (var k = 0, l = 0; l < b.length; l++) {
            var m = b[l].length; if (1 === m) d.push(l); else if (0 === m) e.add(a.L(l)); else {
                if (0 === k) c.push(l); else {
                    for (var n = m = Infinity,
                        p = -1, r = [], q = 0; q < c.length; q++)0 > b[c[q]].indexOf(c[q === c.length - 1 ? 0 : q + 1]) && r.push(q === c.length - 1 ? 0 : q + 1); if (0 === r.length) for (q = 0; q < c.length; q++)r.push(q); for (q = 0; q < r.length; q++) {
                            for (var u = r[q], w = b[l], v = 0, z = 0; z < g.length; z++) { var y = f[g[z]], A = f[h[z]]; if (y < A) { var B = y; y = A } else B = A; if (B < u && u <= y) for (A = 0; A < w.length; A++) { var E = w[A]; 0 > c.indexOf(E) || B < f[E] && f[E] < y || B === f[E] || y === f[E] || v++ } else for (A = 0; A < w.length; A++)E = w[A], 0 > c.indexOf(E) || B < f[E] && f[E] < y && B !== f[E] && y !== f[E] && v++ } w = v; for (z = v = 0; z < b[l].length; z++)B =
                                c.indexOf(b[l][z]), 0 <= B && (B = Math.abs(u - (B >= u ? B + 1 : B)), v += B < c.length + 1 - B ? B : c.length + 1 - B); for (z = 0; z < g.length; z++)B = f[g[z]], y = f[h[z]], B >= u && B++, y >= u && y++, B > y && (A = y, y = B, B = A), y - B < (c.length + 2) / 2 === (B < u && u <= y) && v++; if (w < m || w === m && v < n) m = w, n = v, p = u
                        } c.splice(p, 0, l); for (m = 0; m < c.length; m++)f[c[m]] = m; for (m = 0; m < b[l].length; m++)n = b[l][m], 0 <= c.indexOf(n) && (g.push(l), h.push(n))
                } k++
            }
        } for (g = c.length; ;) {
            f = !0; for (h = 0; h < d.length; h++)if (k = d[h], l = b[k][0], m = c.indexOf(l), 0 <= m) {
                for (p = n = 0; p < b[l].length; p++)r = c.indexOf(b[l][p]),
                    0 > r || r === m || (q = r > m ? r - m : m - r, n += r < m !== q > g - q ? 1 : -1); c.splice(0 > n ? m : m + 1, 0, k); d.splice(h, 1); h--
            } else f = !1; if (f) break; else c.push(d[0]), d.splice(0, 1)
        } for (b = 0; b < c.length; b++)e.add(a.L(c[b])); return e
    };
    ma.Object.defineProperties(Ir.prototype, {
        radius: { get: function () { return this.$n }, set: function (a) { this.$n !== a && (0 < a || isNaN(a)) && (this.$n = a, this.D()) } }, aspectRatio: { get: function () { return this.zm }, set: function (a) { this.zm !== a && 0 < a && (this.zm = a, this.D()) } }, startAngle: { get: function () { return this.no }, set: function (a) { this.no !== a && (this.no = a, this.D()) } }, sweepAngle: {
            get: function () { return this.ql },
            set: function (a) { this.ql !== a && (0 < a && 360 >= a ? this.ql = a : this.ql = 360, this.D()) }
        }, arrangement: { get: function () { return this.Mb }, set: function (a) { this.Mb === a || a !== Xr && a !== Jr && a !== Wr && a !== Vr || (this.Mb = a, this.D()) } }, direction: { get: function () { return this.K }, set: function (a) { this.K === a || a !== Lr && a !== Ur && a !== Sr && a !== Tr || (this.K = a, this.D()) } }, sorting: {
            get: function () { return this.ed }, set: function (a) {
                this.ed === a || a !== Qr && a !== Rr &&
                    a !== Or && !Pr && a !== Mr || (this.ed = a, this.D())
            }
        }, comparer: { get: function () { return this.$c }, set: function (a) { this.$c !== a && (this.$c = a, this.D()) } }, spacing: { get: function () { return this.qf }, set: function (a) { this.qf !== a && (this.qf = a, this.D()) } }, nodeDiameterFormula: { get: function () { return this.Jn }, set: function (a) { this.Jn === a || a !== Nr && a !== Yr || (this.Jn = a, this.D()) } }, actualXRadius: { get: function () { return this.hc } },
        actualYRadius: { get: function () { return this.ie } }, actualSpacing: { get: function () { return this.Qi } }, actualCenter: { get: function () { return this.Sv } }
    });
    var Jr = new D(Ir, "ConstantSpacing", 0), Wr = new D(Ir, "ConstantDistance", 1), Vr = new D(Ir, "ConstantAngle", 2), Xr = new D(Ir, "Packed", 3), Lr = new D(Ir, "Clockwise", 4), Ur = new D(Ir, "Counterclockwise", 5), Sr = new D(Ir, "BidirectionalLeft", 6), Tr = new D(Ir, "BidirectionalRight", 7), Qr = new D(Ir, "Forwards", 8), Rr = new D(Ir, "Reverse", 9), Or = new D(Ir, "Ascending", 10), Pr = new D(Ir, "Descending", 11), Mr = new D(Ir, "Optimized", 12), Nr = new D(Ir, "Pythagorean", 13), Yr = new D(Ir, "Circular", 14); Ir.className = "CircularLayout"; Ir.ConstantSpacing = Jr;
    Ir.ConstantDistance = Wr; Ir.ConstantAngle = Vr; Ir.Packed = Xr; Ir.Clockwise = Lr; Ir.Counterclockwise = Ur; Ir.BidirectionalLeft = Sr; Ir.BidirectionalRight = Tr; Ir.Forwards = Qr; Ir.Reverse = Rr; Ir.Ascending = Or; Ir.Descending = Pr; Ir.Optimized = Mr; Ir.Pythagorean = Nr; Ir.Circular = Yr; function Kr() { this.Kl = -Infinity; this.qm = this.hk = null }
    Kr.prototype.compare = function (a, b) { if (0 < a && 0 > this.Kl || Math.abs(a) < Math.abs(this.Kl) && !(0 > a && 0 < this.Kl)) for (this.Kl = a, this.hk = [], this.qm = [], a = 0; a < b.length; a++)this.hk[a] = b[a].bounds.x, this.qm[a] = b[a].bounds.y }; Kr.prototype.commit = function (a) { if (null !== this.hk && null !== this.qm) for (var b = 0; b < this.hk.length; b++) { var c = a.L(b); c.x = this.hk[b]; c.y = this.qm[b] } }; Kr.className = "VertexArrangement"; function Zr(a) { rq.call(this, a) } la(Zr, rq); Zr.prototype.createVertex = function () { return new ms(this) };
    Zr.prototype.createEdge = function () { return new ns(this) }; Zr.className = "CircularNetwork"; function ms(a) { uq.call(this, a); this.u = this.Pi = NaN } la(ms, uq);
    function $r(a, b) { var c = a.network; if (null === c) return NaN; c = c.layout; if (null === c) return NaN; if (c.arrangement === Xr) if (c.nodeDiameterFormula === Yr) a.Pi = Math.max(a.width, a.height); else { c = Math.abs(Math.sin(b)); b = Math.abs(Math.cos(b)); if (0 === c) return a.width; if (0 === b) return a.height; a.Pi = Math.min(a.height / c, a.width / b) } else a.Pi = c.nodeDiameterFormula === Yr ? Math.max(a.width, a.height) : Math.sqrt(a.width * a.width + a.height * a.height); return a.Pi }
    ma.Object.defineProperties(ms.prototype, { diameter: { get: function () { return this.Pi }, set: function (a) { this.Pi !== a && (this.Pi = a) } }, actualAngle: { get: function () { return this.u }, set: function (a) { this.u !== a && (this.u = a) } } }); ms.className = "CircularVertex"; function ns(a) { vq.call(this, a) } la(ns, vq); ns.className = "CircularEdge";
    function os(a) { ri.call(this); this.Vg = null; this.wn = 0; this.Wd = (new L(100, 100)).freeze(); this.ym = !1; this.pf = !0; this.md = !1; this.Zk = 100; this.Xm = 1; this.Vf = 1E3; this.En = 10; this.ao = Math; this.Ck = .05; this.Bk = 50; this.zk = 150; this.Ak = 0; this.Sm = 10; this.Rm = 5; a && Object.assign(this, a) } la(os, ri);
    os.prototype.cloneProtected = function (a) { ri.prototype.cloneProtected.call(this, a); a.Wd.assign(this.Wd); a.ym = this.ym; a.pf = this.pf; a.md = this.md; a.Zk = this.Zk; a.Xm = this.Xm; a.Vf = this.Vf; a.En = this.En; a.ao = this.ao; a.Ck = this.Ck; a.Bk = this.Bk; a.zk = this.zk; a.Ak = this.Ak; a.Sm = this.Sm; a.Rm = this.Rm }; os.prototype.createNetwork = function () { return new ps(this) };
    os.prototype.doLayout = function (a) {
        null === this.network && (this.network = this.makeNetwork(a)); a = this.maxIterations; if (0 < this.network.vertexes.count) {
            this.network.Go(); for (var b = this.network.vertexes.iterator; b.next();) { var c = b.value; c.charge = this.electricalCharge(c); c.mass = this.gravitationalMass(c) } for (b = this.network.edges.iterator; b.next();)c = b.value, c.stiffness = this.springStiffness(c), c.length = this.springLength(c); this.xu(); this.wn = 0; if (this.needsClusterLayout()) {
                b = this.network; c = b.Vx(); for (var d = c.iterator; d.next();) {
                    this.network =
                    d.value; for (var e = this.network.vertexes.iterator; e.next();) { var f = e.value; f.Qd = f.vertexes.count; f.nh = 1; f.Fj = null; f.Se = null } qs(this, 0, a)
                } this.network = b; var g = d = c.iterator; d = this.arrangementSpacing; var h = g.count, k = !0; f = e = 0; for (var l = Ea(), m = 0; m < h + b.vertexes.count + 2; m++)l[m] = null; h = 0; g.reset(); for (var n = N.alloc(); g.next();)if (m = g.value, this.computeBounds(m, n), k) k = !1, e = n.x + n.width / 2, f = n.y + n.height / 2, l[0] = new I(n.x + n.width + d.width, n.y), l[1] = new I(n.x, n.y + n.height + d.height), h = 2; else {
                    var p = rs(l, h, e, f, n.width,
                        n.height, d), r = l[p], q = new I(r.x + n.width + d.width, r.y), u = new I(r.x, r.y + n.height + d.height); p + 1 < h && l.splice(p + 1, 0, null); l[p] = q; l[p + 1] = u; h++; p = r.x - n.x; r = r.y - n.y; for (m = m.vertexes.iterator; m.next();)q = m.value, q.centerX += p, q.centerY += r
                } N.free(n); for (m = b.vertexes.iterator; m.next();)g = m.value, p = g.bounds, 2 > h ? (e = p.x + p.width / 2, f = p.y + p.height / 2, l[0] = new I(p.x + p.width + d.width, p.y), l[1] = new I(p.x, p.y + p.height + d.height), h = 2) : (k = rs(l, h, e, f, p.width, p.height, d), n = l[k], r = new I(n.x + p.width + d.width, n.y), p = new I(n.x, n.y +
                    p.height + d.height), k + 1 < h && l.splice(k + 1, 0, null), l[k] = r, l[k + 1] = p, h++, g.centerX = n.x + g.width / 2, g.centerY = n.y + g.height / 2); Ga(l); for (d = c.iterator; d.next();) { c = d.value; for (e = c.vertexes.iterator; e.next();)b.Yg(e.value); for (c = c.edges.iterator; c.next();)b.xo(c.value) }
            } ss(this, a); this.updateParts()
        } this.Zk = a; this.network = null; this.isValidLayout = !0
    };
    os.prototype.needsClusterLayout = function () { if (3 > this.network.vertexes.count) return !1; for (var a = 0, b = 0, c = this.network.vertexes.first().bounds, d = this.network.vertexes.iterator; d.next();) { if (d.value.bounds.Tc(c) && (a++, 2 < a)) return !0; if (10 < b) break; b++ } return !1 }; os.prototype.computeBounds = function (a, b) { var c = !0; for (a = a.vertexes.iterator; a.next();) { var d = a.value; c ? (c = !1, b.set(d.bounds)) : b.Zc(d.bounds) } return b };
    function qs(a, b, c) { if (ts(a, b)) { var d = a.Vf; a.Vf *= 1 + 1 / (b + 1); var e = us(a, b), f = Math.max(0, Math.max(Math.min(a.network.vertexes.count, c * (b + 1) / 11), 10)); a.maxIterations += f; qs(a, b + 1, c); ss(a, f); vs(a, e); b = e.vertexes.Fa(); b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.Qd - a.Qd }); for (c = 0; c < b.length; c++)ws(a, b[c]); a.Vf = d } }
    function ts(a, b) { if (10 < b || 3 > a.network.vertexes.count) return !1; a.Vg = a.network.vertexes.Fa(); a = a.Vg; a.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.Qd - a.Qd }); for (b = a.length - 1; 0 <= b && 1 >= a[b].Qd;)b--; return 1 < a.length - b }
    function us(a, b) {
        for (var c = a.network, d = new ps(a), e = 0; e < a.Vg.length; e++) { var f = a.Vg[e]; if (1 < f.Qd) { d.Yg(f); var g = new xs; g.Ks = f.Qd; g.Ls = f.width; g.Js = f.height; g.Lv = f.focus.x; g.Mv = f.focus.y; null === f.Se && (f.Se = new F); f.Se.add(g); f.nv = f.Se.count - 1 } else break } for (f = c.edges.iterator; f.next();) {
            var h = f.value; e = h.fromVertex; g = h.toVertex; e.network === d && g.network === d ? d.xo(h) : e.network === d ? (h = e.Fj, null === h && (h = new F, e.Fj = h), h.add(g), e.Qd--, e.nh += g.nh) : g.network === d && (h = g.Fj, null === h && (h = new F, g.Fj = h), h.add(e),
                g.Qd--, g.nh += e.nh)
        } for (e = d.edges.iterator; e.next();)f = e.value, f.length *= Math.max(1, K.sqrt((f.fromVertex.nh + f.toVertex.nh) / (4 * b + 1))); for (b = d.vertexes.iterator; b.next();) {
            e = b.value; var k = e.Fj; if (null !== k && 0 < k.count && (g = e.Se.L(e.Se.count - 1).Ks - e.Qd, !(0 >= g))) {
                for (var l = h = 0, m = k.count - g; m < k.count; m++) { var n = k.L(m), p = null; for (f = n.edges.iterator; f.next();) { var r = f.value; if (r.ux(n) === e) { p = r; break } } null !== p && (l += p.length, h += n.width * n.height) } f = e.centerX; k = e.centerY; m = e.width; n = e.height; p = e.focus; r = m * n; 1 > r &&
                    (r = 1); h = K.sqrt((h + r + l * l * 4 / (g * g)) / r); g = (h - 1) * m / 2; h = (h - 1) * n / 2; e.bounds = new N(f - p.x - g, k - p.y - h, m + 2 * g, n + 2 * h); e.focus = new I(p.x + g, p.y + h)
            }
        } a.network = d; return c
    } function vs(a, b) { for (var c = a.network.vertexes.iterator; c.next();) { var d = c.value; d.network = b; if (null !== d.Se) { var e = d.Se.L(d.nv); d.Qd = e.Ks; var f = e.Lv, g = e.Mv; d.bounds = new N(d.centerX - f, d.centerY - g, e.Ls, e.Js); d.focus = new I(f, g); d.nv-- } } for (c = a.network.edges.iterator; c.next();)c.value.network = b; a.network = b }
    function ws(a, b) {
        var c = b.Fj; if (null !== c && 0 !== c.count) {
            var d = b.centerX, e = b.centerY, f = b.width, g = b.height; null !== b.Se && 0 < b.Se.count && (g = b.Se.L(0), f = g.Ls, g = g.Js); f = K.sqrt(f * f + g * g) / 2; for (var h = !1, k = g = 0, l = 0, m = b.vertexes.iterator; m.next();) { var n = m.value; 1 >= n.Qd ? k++ : (h = !0, l++, g += Math.atan2(b.centerY - n.centerY, b.centerX - n.centerX)) } if (0 !== k) for (0 < l && (g /= l), l = b = 0, b = h ? 2 * Math.PI / (k + 1) : 2 * Math.PI / k, 0 === k % 2 && (l = b / 2), 1 < c.count && c.sort(function (a, b) { return null === a || null === b || a === b ? 0 : b.width * b.height - a.width * a.height }),
                h = 0 === k % 2 ? 0 : 1, c = c.iterator; c.next();)if (k = c.value, !(1 < k.Qd || a.isFixed(k))) { m = null; for (n = k.edges.iterator; n.next();) { m = n.value; break } n = k.width; var p = k.height; n = K.sqrt(n * n + p * p) / 2; m = f + m.length + n; n = g + (b * (h / 2 >> 1) + l) * (0 === h % 2 ? 1 : -1); k.centerX = d + m * Math.cos(n); k.centerY = e + m * Math.sin(n); h++ }
        }
    }
    function rs(a, b, c, d, e, f, g) { var h = 9E19, k = -1, l = 0; a: for (; l < b; l++) { var m = a[l], n = m.x - c, p = m.y - d; n = n * n + p * p; if (n < h) { for (p = l - 1; 0 <= p; p--)if (a[p].y > m.y && a[p].x - m.x < e + g.width) continue a; for (p = l + 1; p < b; p++)if (a[p].x > m.x && a[p].y - m.y < f + g.height) continue a; k = l; h = n } } return k } os.prototype.xu = function () { if (this.comments) for (var a = this.network.vertexes.iterator; a.next();)this.addComments(a.value) };
    os.prototype.addComments = function (a) { var b = a.node; if (null !== b) for (b = b.Gu(); b.next();) { var c = b.value; if ("Comment" === c.category && c.isVisible()) { var d = this.network.hi(c); null === d && (d = this.network.xl(c)); d.charge = this.defaultCommentElectricalCharge; c = null; for (var e = d.destinationEdges; e.next();) { var f = e.value; if (f.toVertex === a) { c = f; break } } if (null === c) for (e = d.sourceEdges; e.next();)if (f = e.value, f.fromVertex === a) { c = f; break } null === c && (c = this.network.Wj(a, d, null)); c.length = this.defaultCommentSpringLength } } };
    function ys(a, b) { var c = a.bounds, d = c.x; a = c.y; var e = c.width; c = c.height; var f = b.bounds, g = f.x; b = f.y; var h = f.width; f = f.height; return d + e < g ? a > b + f ? (c = d + e - g, a = a - b - f, K.sqrt(c * c + a * a)) : a + c < b ? (d = d + e - g, a = a + c - b, K.sqrt(d * d + a * a)) : g - (d + e) : d > g + h ? a > b + f ? (c = d - g - h, a = a - b - f, K.sqrt(c * c + a * a)) : a + c < b ? (d = d - g - h, a = a + c - b, K.sqrt(d * d + a * a)) : d - (g + h) : a > b + f ? a - (b + f) : a + c < b ? b - (a + c) : .1 } function ss(a, b) { a.Vg = null; for (b = a.wn + b; a.wn < b && (a.wn++, zs(a));); a.Vg = null }
    function zs(a) {
        null === a.Vg && (a.Vg = a.network.vertexes.Fa()); var b = a.Vg; if (0 >= b.length) return !1; var c = b[0]; c.forceX = 0; c.forceY = 0; for (var d = c.centerX, e = d, f = c = c.centerY, g = 1; g < b.length; g++) { var h = b[g]; h.forceX = 0; h.forceY = 0; var k = h.centerX; h = h.centerY; d = Math.min(d, k); e = Math.max(e, k); c = Math.min(c, h); f = Math.max(f, h) } (e = e - d > f - c) ? b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : a.centerX - b.centerX }) : b.sort(function (a, b) { return null === a || null === b || a === b ? 0 : a.centerY - b.centerY }); c = a.Vf; var l = d = h = 0; for (f =
            0; f < b.length; f++) {
                g = b[f]; d = g.bounds; h = g.focus; k = d.x + h.x; var m = d.y + h.y; d = g.charge * a.electricalFieldX(k, m); l = g.charge * a.electricalFieldY(k, m); d += g.mass * a.gravitationalFieldX(k, m); l += g.mass * a.gravitationalFieldY(k, m); g.forceX += d; g.forceY += l; for (var n = f + 1; n < b.length; n++) {
                    var p = b[n]; if (p !== g) {
                        d = p.bounds; h = p.focus; l = d.x + h.x; var r = d.y + h.y; if (k - l > c || l - k > c) { if (e) break } else if (m - r > c || r - m > c) { if (!e) break } else {
                            var q = ys(g, p); 1 > q ? (d = a.randomNumberGenerator, null === d && (a.randomNumberGenerator = d = new As), q = d.random(),
                                h = d.random(), k > l ? (d = Math.abs(p.bounds.right - g.bounds.x), d = (1 + d) * q) : k < l ? (d = Math.abs(p.bounds.x - g.bounds.right), d = -(1 + d) * q) : (d = Math.max(p.width, g.width), d = (1 + d) * q - d / 2), m > r ? (l = Math.abs(p.bounds.bottom - g.bounds.y), l = (1 + l) * h) : k < l ? (l = Math.abs(p.bounds.y - g.bounds.bottom), l = -(1 + l) * h) : (l = Math.max(p.height, g.height), l = (1 + l) * h - l / 2)) : (h = -(g.charge * p.charge) / (q * q), d = (l - k) / q * h, l = (r - m) / q * h); g.forceX += d; g.forceY += l; p.forceX -= d; p.forceY -= l
                        }
                    }
                }
        } for (e = a.network.edges.iterator; e.next();)h = e.value, c = h.fromVertex, f = h.toVertex,
            g = c.bounds, k = c.focus, d = g.x + k.x, g = g.y + k.y, m = f.bounds, n = f.focus, k = m.x + n.x, m = m.y + n.y, n = ys(c, f), 1 > n ? (n = a.randomNumberGenerator, null === n && (a.randomNumberGenerator = n = new As), h = n.random(), n = n.random(), d = (d > k ? 1 : -1) * (1 + (f.width > c.width ? f.width : c.width)) * h, l = (g > m ? 1 : -1) * (1 + (f.height > c.height ? f.height : c.height)) * n) : (h = h.stiffness * (n - h.length), d = (k - d) / n * h, l = (m - g) / n * h), c.forceX += d, c.forceY += l, f.forceX -= d, f.forceY -= l; for (e = d = 0; e < b.length; e++)c = b[e], a.isFixed(c) ? a.moveFixedVertex(c) : d = Math.max(d, a.moveVertex(c) ||
                0); return d > a.epsilonDistance * a.epsilonDistance
    } os.prototype.moveVertex = function (a) { var b = a.forceX, c = a.forceY, d = this.moveLimit; b < -d ? b = -d : b > d && (b = d); c < -d ? c = -d : c > d && (c = d); a.centerX += b; a.centerY += c; return b * b + c * c }; os.prototype.moveFixedVertex = function () { }; os.prototype.commitLayout = function () { this.rv(); this.commitNodes(); this.isRouting && this.commitLinks() };
    os.prototype.rv = function () { if (this.setsPortSpots) for (var a = this.network.edges.iterator; a.next();) { var b = a.value.link; null !== b && (b.fromSpot = Sc, b.toSpot = Sc) } }; os.prototype.commitNodes = function () { var a = 0, b = 0; if (this.arrangesToOrigin) { var c = N.alloc(); this.computeBounds(this.network, c); b = this.arrangementOrigin; a = b.x - c.x; b = b.y - c.y; N.free(c) } c = N.alloc(); for (var d = this.network.vertexes.iterator; d.next();) { var e = d.value; if (0 !== a || 0 !== b) c.assign(e.bounds), c.x += a, c.y += b, e.bounds = c; e.commit() } N.free(c) };
    os.prototype.commitLinks = function () { for (var a = this.network.edges.iterator; a.next();)a.value.commit() }; os.prototype.springStiffness = function (a) { a = a.stiffness; return isNaN(a) ? this.Ck : a }; os.prototype.springLength = function (a) { a = a.length; return isNaN(a) ? this.Bk : a }; os.prototype.electricalCharge = function (a) { a = a.charge; return isNaN(a) ? this.zk : a }; os.prototype.electricalFieldX = function () { return 0 }; os.prototype.electricalFieldY = function () { return 0 };
    os.prototype.gravitationalMass = function (a) { a = a.mass; return isNaN(a) ? this.Ak : a }; os.prototype.gravitationalFieldX = function () { return 0 }; os.prototype.gravitationalFieldY = function () { return 0 }; os.prototype.isFixed = function (a) { return a.isFixed };
    ma.Object.defineProperties(os.prototype, {
        currentIteration: { get: function () { return this.wn } }, arrangementSpacing: { get: function () { return this.Wd }, set: function (a) { this.Wd.C(a) || (this.Wd.assign(a), this.D()) } }, arrangesToOrigin: { get: function () { return this.ym }, set: function (a) { this.ym !== a && (this.ym = a, this.D()) } }, setsPortSpots: {
            get: function () { return this.pf }, set: function (a) {
                this.pf !== a && (this.pf =
                    a, this.D())
            }
        }, comments: { get: function () { return this.md }, set: function (a) { this.md !== a && (this.md = a, this.D()) } }, maxIterations: { get: function () { return this.Zk }, set: function (a) { this.Zk !== a && 0 <= a && (this.Zk = a, this.D()) } }, epsilonDistance: { get: function () { return this.Xm }, set: function (a) { this.Xm !== a && 0 < a && (this.Xm = a, this.D()) } }, infinityDistance: {
            get: function () { return this.Vf }, set: function (a) {
                this.Vf !==
                a && 1 < a && (this.Vf = a, this.D())
            }
        }, moveLimit: { get: function () { return this.En }, set: function (a) { this.En !== a && 1 < a && (this.En = a, this.D()) } }, randomNumberGenerator: { get: function () { return this.ao }, set: function (a) { this.ao !== a && (null !== a && "function" !== typeof a.random && C('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: ' + a), this.ao = a) } }, defaultSpringStiffness: {
            get: function () { return this.Ck },
            set: function (a) { this.Ck !== a && (this.Ck = a, this.D()) }
        }, defaultSpringLength: { get: function () { return this.Bk }, set: function (a) { this.Bk !== a && (this.Bk = a, this.D()) } }, defaultElectricalCharge: { get: function () { return this.zk }, set: function (a) { this.zk !== a && (this.zk = a, this.D()) } }, defaultGravitationalMass: { get: function () { return this.Ak }, set: function (a) { this.Ak !== a && (this.Ak = a, this.D()) } }, defaultCommentSpringLength: {
            get: function () { return this.Sm }, set: function (a) { this.Sm !== a && (this.Sm = a, this.D()) }
        }, defaultCommentElectricalCharge: { get: function () { return this.Rm }, set: function (a) { this.Rm !== a && (this.Rm = a, this.D()) } }
    }); os.className = "ForceDirectedLayout"; function xs() { this.Mv = this.Lv = this.Js = this.Ls = this.Ks = 0 } xs.className = "ForceDirectedSubnet"; function ps(a) { rq.call(this, a) } la(ps, rq); ps.prototype.createVertex = function () { return new Bs(this) }; ps.prototype.createEdge = function () { return new Cs(this) };
    ps.className = "ForceDirectedNetwork"; function Bs(a) { uq.call(this, a); this.ga = !1; this.Ha = this.u = NaN; this.nh = this.Qd = this.V = this.F = 0; this.Se = this.Fj = null; this.nv = 0 } la(Bs, uq);
    ma.Object.defineProperties(Bs.prototype, {
        isFixed: { get: function () { return this.ga }, set: function (a) { this.ga !== a && (this.ga = a) } }, charge: { get: function () { return this.u }, set: function (a) { this.u !== a && (this.u = a) } }, mass: { get: function () { return this.Ha }, set: function (a) { this.Ha !== a && (this.Ha = a) } }, forceX: { get: function () { return this.F }, set: function (a) { this.F !== a && (this.F = a) } }, forceY: {
            get: function () { return this.V }, set: function (a) { this.V !== a && (this.V = a) }
        }
    }); Bs.className = "ForceDirectedVertex"; function Cs(a) { vq.call(this, a); this.j = this.m = NaN } la(Cs, vq); ma.Object.defineProperties(Cs.prototype, { stiffness: { get: function () { return this.m }, set: function (a) { this.m !== a && (this.m = a) } }, length: { get: function () { return this.j }, set: function (a) { this.j !== a && (this.j = a) } } }); Cs.className = "ForceDirectedEdge";
    function As() { var a = 0; void 0 === a && (a = 42); this.seed = a; this.Xx = 48271; this.Zx = 2147483647; this.Q = 44488.07041494893; this.$x = 3399; this.Yx = 1 / 2147483647; this.random() } As.prototype.random = function () { var a = this.seed % this.Q * this.Xx - this.seed / this.Q * this.$x; 0 < a ? this.seed = a : this.seed = a + this.Zx; return this.seed * this.Yx }; As.className = "RandomNumberGenerator";
    function Ds(a) { ri.call(this); this.gc = this.ee = 25; this.K = 0; this.yk = Es; this.Uk = Fs; this.Lk = Gs; this.Zi = 4; this.lk = Hs; this.Yf = 15; this.pf = !0; this.yn = 4; this.Qa = this.Bq = this.Ia = -1; this.Dd = this.Dn = 0; this.Ta = this.Bd = this.Cd = this.de = this.qc = null; this.Gn = 0; this.Fn = this.ij = null; this.he = 0; this.al = null; this.Of = new I; this.De = []; this.De.length = 100; this.qw = this.re = 0; a && Object.assign(this, a) } la(Ds, ri);
    Ds.prototype.cloneProtected = function (a) { ri.prototype.cloneProtected.call(this, a); a.ee = this.ee; a.gc = this.gc; a.K = this.K; a.yk = this.yk; a.Uk = this.Uk; a.Lk = this.Lk; a.Zi = this.Zi; a.lk = this.lk; a.Yf = this.Yf; a.pf = this.pf; a.yn = this.yn; a.re = this.re };
    Ds.prototype.rb = function (a) { a.classType === Ds ? 0 === a.name.indexOf("Aggressive") ? this.aggressiveOption = a : 0 === a.name.indexOf("Cycle") ? this.cycleRemoveOption = a : 0 === a.name.indexOf("Init") ? this.initializeOption = a : 0 === a.name.indexOf("Layer") ? this.layeringOption = a : C("Unknown enum value: " + a) : ri.prototype.rb.call(this, a) }; Ds.prototype.createNetwork = function () { return new Is(this) };
    Ds.prototype.doLayout = function (a) {
        null === this.network && (this.network = this.makeNetwork(a)); this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin); this.Bq = -1; this.Dd = this.Dn = 0; this.al = this.Fn = this.ij = null; for (a = 0; a < this.De.length; a++)this.De[a] = null; if (0 < this.network.vertexes.count) {
            this.network.Go(); this.cycleRemoveOption !== Js && this.removeCycles(); for (a = this.network.vertexes.iterator; a.next();)a.value.layer = -1; this.Ia = -1; this.assignLayers(); for (a.reset(); a.next();)this.Ia = Math.max(this.Ia,
                a.value.layer); this.cycleRemoveOption === Js && this.removeCycles(); a = this.network; for (var b = [], c = a.edges.iterator; c.next();) { var d = c.value; d.valid = !1; b.push(d) } for (c = 0; c < b.length; c++) {
                    d = b[c]; var e = d.fromVertex, f = d.toVertex; if (!(d.valid || (null === e.node && null === e.data || null === f.node && null === f.data) && e.layer === f.layer)) {
                        var g = 0, h = 0, k = 0, l = 0; if (null !== d.link) {
                            h = d.link; if (null === h) continue; var m = e.node; g = f.node; if (null === m || null === g) continue; var n = h.fromNode; k = h.toNode; for (var p = h.fromPort; null !== p && !p.Nd();)p =
                                p.panel; for (h = h.toPort; null !== h && !h.Nd();)h = h.panel; if (d.rev) { l = n; var r = p; n = k; p = h; k = l; h = r } var q = e.focus; l = f.focus; var u = d.rev ? f.bounds : e.bounds; r = I.alloc(); m !== n ? u.w() && n.isVisible() ? n.actualBounds.w() ? (To(n, p, xc, r), r.x += n.actualBounds.x - u.x, r.y += n.actualBounds.y - u.y) : (To(n, p, xc, r), r.w() || r.assign(q)) : r.assign(q) : u.w() ? (To(n, p, xc, r), r.w() || r.assign(q)) : r.assign(q); n = d.rev ? e.bounds : f.bounds; m = I.alloc(); g !== k ? n.w() && k.isVisible() ? k.actualBounds.w() ? (To(k, h, xc, m), m.x += k.actualBounds.x - n.x, m.y += k.actualBounds.y -
                                    n.y) : (To(k, h, xc, m), m.w() || m.assign(l)) : m.assign(l) : n.w() ? (To(k, h, xc, m), m.w() || m.assign(l)) : m.assign(l); 90 === this.K || 270 === this.K ? (g = Math.round((r.x - q.x) / this.gc), k = r.x, h = Math.round((m.x - l.x) / this.gc), l = m.x) : (g = Math.round((r.y - q.y) / this.gc), k = r.y, h = Math.round((m.y - l.y) / this.gc), l = m.y); I.free(r); I.free(m); d.portFromColOffset = g; d.portFromPos = k; d.portToColOffset = h; d.portToPos = l
                        } else d.portFromColOffset = 0, d.portFromPos = 0, d.portToColOffset = 0, d.portToPos = 0; r = e.layer; m = f.layer; n = 0; u = d.link; if (null !== u) {
                            var w =
                                u.fromPort, v = u.toPort; if (null !== w && null !== v) {
                                    var z = u.fromNode; p = u.toNode; if (null !== z && null !== p) {
                                        var y = Ks(this, !0), A = Ks(this, !1), B = this.setsPortSpots ? y : u.computeSpot(!0, w); q = this.setsPortSpots ? A : u.computeSpot(!1, v); var E = u.isOrthogonal; B.Vc() && B.Ef(A) && q.Vc() && q.Ef(y) ? n = 0 : (y = u.getLinkPoint(z, w, B, !0, E, p, v, I.alloc()), A = u.getLinkDirection(z, w, y, B, !0, E, p, v), I.free(y), B.rs() || A !== Ls(this, d, !0) ? this.setsPortSpots && null !== z && 1 === z.ports.count && d.rev && (n += 1) : n += 1, B = u.getLinkPoint(p, v, q, !1, E, z, w, I.alloc()),
                                            u = u.getLinkDirection(p, v, B, q, !1, E, z, w), I.free(B), q.rs() || u !== Ls(this, d, !1) ? this.setsPortSpots && null !== p && 1 === p.ports.count && d.rev && (n += 2) : n += 2)
                                    }
                                }
                        } p = n; n = 1 === p || 3 === p ? !0 : !1; if (p = 2 === p || 3 === p ? !0 : !1) q = a.createVertex(), q.node = null, q.Bj = 1, q.layer = r, q.near = e, a.Yg(q), e = a.Wj(e, q, d.link), e.valid = !1, e.rev = d.rev, e.portFromColOffset = g, e.portToColOffset = 0, e.portFromPos = k, e.portToPos = 0, e = q; u = 1; n && u--; if (r - m > u && 0 < r) {
                            d.valid = !1; q = a.createVertex(); q.node = null; q.Bj = 2; q.layer = r - 1; a.Yg(q); e = a.Wj(e, q, d.link); e.valid = !0;
                            e.rev = d.rev; e.portFromColOffset = p ? 0 : g; e.portToColOffset = 0; e.portFromPos = p ? 0 : k; e.portToPos = 0; e = q; for (r--; r - m > u && 0 < r;)q = a.createVertex(), q.node = null, q.Bj = 3, q.layer = r - 1, a.Yg(q), e = a.Wj(e, q, d.link), e.valid = !0, e.rev = d.rev, e.portFromColOffset = 0, e.portToColOffset = 0, e.portFromPos = 0, e.portToPos = 0, e = q, r--; e = a.Wj(q, f, d.link); e.valid = !n; n && (q.near = f); e.rev = d.rev; e.portFromColOffset = 0; e.portToColOffset = h; e.portFromPos = 0; e.portToPos = l
                        } else d.valid = !0
                    }
                } a = this.qc = []; for (b = 0; b <= this.Ia; b++)a[b] = 0; for (b = this.network.vertexes.iterator; b.next();)b.value.index =
                    -1; this.initializeIndices(); this.Bq = -1; for (c = this.Dd = this.Dn = 0; c <= this.Ia; c++)a[c] > a[this.Dd] && (this.Bq = a[c] - 1, this.Dd = c), a[c] < a[this.Dn] && (this.Dn = c); this.al = []; for (c = 0; c < a.length; c++)this.al[c] = []; for (b.reset(); b.next();)a = b.value, this.al[a.layer][a.index] = a; this.Qa = -1; for (a = 0; a <= this.Ia; a++) { b = Ms(this, a); c = 0; d = this.qc[a]; for (f = 0; f < d; f++)e = b[f], c += this.nodeMinColumnSpace(e, !0), e.column = c, c += 1, c += this.nodeMinColumnSpace(e, !1); this.Qa = Math.max(this.Qa, c - 1); Ns(this, a, b) } this.reduceCrossings(); this.straightenAndPack();
            this.updateParts()
        } this.network = null; this.isValidLayout = !0
    }; Ds.prototype.linkMinLength = function () { return 1 }; function Os(a) { var b = a.fromVertex.node || a.fromVertex.data; a = a.toVertex.node || a.toVertex.data; return null === b && null === a ? 8 : null === b || null === a ? 4 : 1 } Ds.prototype.nodeMinLayerSpace = function (a, b) { return null === a.node && null === a.data ? 0 : 90 === this.K || 270 === this.K ? b ? a.focus.y + 10 : a.bounds.height - a.focus.y + 10 : b ? a.focus.x + 10 : a.bounds.width - a.focus.x + 10 };
    Ds.prototype.nodeMinColumnSpace = function (a, b) { if (null === a.node && null === a.data) return 0; var c = b ? a.av : a.$u; if (null !== c) return c; c = this.K; return 90 === c || 270 === c ? b ? a.av = a.focus.x / this.gc + 1 | 0 : a.$u = (a.bounds.width - a.focus.x) / this.gc + 1 | 0 : b ? a.av = a.focus.y / this.gc + 1 | 0 : a.$u = (a.bounds.height - a.focus.y) / this.gc + 1 | 0 }; function Ps(a) { null === a.ij && (a.ij = []); for (var b = 0, c = a.network.vertexes.iterator; c.next();) { var d = c.value; a.ij[b] = d.layer; b++; a.ij[b] = d.column; b++; a.ij[b] = d.index; b++ } return a.ij }
    function Qs(a, b) { var c = 0; for (a = a.network.vertexes.iterator; a.next();) { var d = a.value; d.layer = b[c]; c++; d.column = b[c]; c++; d.index = b[c]; c++ } }
    function Rs(a, b, c) {
        var d = Ms(a, b), e = a.qc[b]; if (null === a.Fn || a.Fn.length < e * e) a.Fn = []; for (var f = a.Fn, g = 0; g < e; g++) {
            var h = 0, k = d[g], l = k.near; if (null !== l && l.layer === k.layer) if (k = l.index, k > g) for (var m = g + 1; m < k; m++) { var n = d[m]; n.near === l && n.Bj === l.Bj || h++ } else for (m = g - 1; m > k; m--)n = d[m], n.near === l && n.Bj === l.Bj || h++; var p; if (0 <= c) for (k = d[g].sourceEdgesArrayAccess, l = 0; l < k.length; l++) {
                var r = k[l]; if (r.valid && r.fromVertex.layer !== b) for (n = r.fromVertex.index, m = r.portToPos, r = r.portFromPos, p = l + 1; p < k.length; p++) {
                    var q =
                        k[p]; if (q.valid && q.fromVertex.layer !== b) { var u = q.fromVertex.index; var w = q.portToPos; q = q.portFromPos; m < w && (n > u || n === u && r > q) && h++; w < m && (u > n || u === n && q > r) && h++ }
                }
            } if (0 >= c) for (k = d[g].destinationEdgesArrayAccess, l = 0; l < k.length; l++)if (r = k[l], r.valid && r.toVertex.layer !== b) for (n = r.toVertex.index, m = r.portToPos, r = r.portFromPos, p = l + 1; p < k.length; p++)q = k[p], q.valid && q.toVertex.layer !== b && (u = q.toVertex.index, w = q.portToPos, q = q.portFromPos, r < q && (n > u || n === u && m > w) && h++, q < r && (u > n || u === n && w > m) && h++); f[g * e + g] = h; for (k = g +
                1; k < e; k++) {
                    var v = 0, z = 0; if (0 <= c) { h = d[g].sourceEdgesArrayAccess; var y = d[k].sourceEdgesArrayAccess; for (l = 0; l < h.length; l++)if (r = h[l], r.valid && r.fromVertex.layer !== b) for (n = r.fromVertex.index, r = r.portFromPos, p = 0; p < y.length; p++)q = y[p], q.valid && q.fromVertex.layer !== b && (u = q.fromVertex.index, q = q.portFromPos, (n < u || n === u && r < q) && z++, (u < n || u === n && q < r) && v++) } if (0 >= c) for (h = d[g].destinationEdgesArrayAccess, y = d[k].destinationEdgesArrayAccess, l = 0; l < h.length; l++)if (r = h[l], r.valid && r.toVertex.layer !== b) for (n = r.toVertex.index,
                        m = r.portToPos, p = 0; p < y.length; p++)q = y[p], q.valid && q.toVertex.layer !== b && (u = q.toVertex.index, w = q.portToPos, (n < u || n === u && m < w) && z++, (u < n || u === n && w < m) && v++); f[g * e + k] = v; f[k * e + g] = z
            }
        } Ns(a, b, d); return f
    } Ds.prototype.countCrossings = function () { for (var a = 0, b = 0; b <= this.Ia; b++)for (var c = Rs(this, b, 1), d = this.qc[b], e = 0; e < d; e++)for (var f = e; f < d; f++)a += c[e * d + f]; return a };
    function Ss(a) { for (var b = 0, c = 0; c <= a.Ia; c++) { for (var d = a, e = c, f = Ms(d, e), g = d.qc[e], h = 0, k = 0; k < g; k++) { var l = f[k].destinationEdgesArrayAccess; if (null !== l) for (var m = 0; m < l.length; m++) { var n = l[m]; if (n.valid && n.toVertex.layer !== e) { var p = n.fromVertex.column + n.portFromColOffset; var r = n.toVertex.column + n.portToColOffset; h += (Math.abs(p - r) + 1) * Os(n) } } } Ns(d, e, f); b += h } return b }
    Ds.prototype.normalize = function () { var a = Infinity; this.Qa = -1; for (var b = this.network.vertexes.iterator; b.next();) { var c = b.value; a = Math.min(a, c.column - this.nodeMinColumnSpace(c, !0)); this.Qa = Math.max(this.Qa, c.column + this.nodeMinColumnSpace(c, !1)) } for (b.reset(); b.next();)b.value.column -= a; this.Qa -= a };
    function Ts(a, b, c) { for (var d = Ms(a, b), e = a.qc[b], f = [], g = 0; g < e; g++) { var h = d[g], k = null; 0 >= c && (k = h.sourceEdgesArrayAccess); var l = null; 0 <= c && (l = h.destinationEdgesArrayAccess); var m = 0, n = 0, p = h.near; null !== p && p.layer === h.layer && (m += p.column - 1, n++); if (null !== k) for (p = 0; p < k.length; p++) { h = k[p]; var r = h.fromVertex; h.valid && !h.rev && r.layer !== b && (m += r.column, n++) } if (null !== l) for (k = 0; k < l.length; k++)h = l[k], p = h.toVertex, h.valid && !h.rev && p.layer !== b && (m += p.column, n++); f[g] = 0 === n ? -1 : m / n } Ns(a, b, d); return f }
    function Us(a, b, c) {
        for (var d = Ms(a, b), e = a.qc[b], f = [], g = 0; g < e; g++) {
            var h = d[g], k = null; 0 >= c && (k = h.sourceEdgesArrayAccess); var l = null; 0 <= c && (l = h.destinationEdgesArrayAccess); var m = 0, n = [], p = h.near; null !== p && p.layer === h.layer && (n[m] = p.column - 1, m++); h = void 0; if (null !== k) for (p = 0; p < k.length; p++) { h = k[p]; var r = h.fromVertex; h.valid && !h.rev && r.layer !== b && (n[m] = r.column + h.portFromColOffset, m++) } if (null !== l) for (k = 0; k < l.length; k++)h = l[k], p = h.toVertex, h.valid && !h.rev && p.layer !== b && (n[m] = p.column + h.portToColOffset,
                m++); 0 === m ? f[g] = -1 : (n.sort(function (a, b) { return a - b }), l = m >> 1, f[g] = 0 !== (m & 1) ? n[l] : n[l - 1] + n[l] >> 1)
        } Ns(a, b, d); return f
    } function Vs(a, b, c, d, e, f) { if (b.component === d) { b.component = c; if (e) for (var g = b.destinationEdges; g.next();) { var h = g.value; var k = h.toVertex; var l = b.layer - k.layer; h = a.linkMinLength(h); l === h && Vs(a, k, c, d, e, f) } if (f) for (g = b.sourceEdges; g.next();)h = g.value, k = h.fromVertex, l = k.layer - b.layer, h = a.linkMinLength(h), l === h && Vs(a, k, c, d, e, f) } }
    function Ws(a, b, c, d, e, f) { if (b.component === d) { b.component = c; if (e) for (var g = b.destinationEdges; g.next();)Ws(a, g.value.toVertex, c, d, e, f); if (f) for (b = b.sourceEdges; b.next();)Ws(a, b.value.fromVertex, c, d, e, f) } }
    Ds.prototype.removeCycles = function () {
        for (var a = this.network.edges.iterator; a.next();)a.value.rev = !1; switch (this.yk) {
            default: case Xs: a = this.network; var b = 0, c = a.vertexes.count - 1, d = []; d.length = c + 1; for (var e = a.vertexes.iterator; e.next();)e.value.valid = !0; for (; null !== Ys(a);) {
                for (e = Zs(a); null !== e;)d[c] = e, c--, e.valid = !1, e = Zs(a); for (e = $s(a); null !== e;)d[b] = e, b++, e.valid = !1, e = $s(a); e = null; for (var f = 0, g = this.network.vertexes.iterator; g.next();) {
                    var h = g.value; if (h.valid) {
                        for (var k = 0, l = h.destinationEdges; l.next();)l.value.toVertex.valid &&
                            k++; l = 0; for (var m = h.sourceEdges; m.next();)m.value.fromVertex.valid && l++; if (null === e || f < k - l) e = h, f = k - l
                    }
                } null !== e && (d[b] = e, b++, e.valid = !1)
            } for (b = 0; b < a.vertexes.count; b++)d[b].index = b; for (d = a.edges.iterator; d.next();)b = d.value, b.fromVertex.index > b.toVertex.index && (a.dm(b), b.rev = !0); break; case Es: for (d = this.network.vertexes.iterator; d.next();)a = d.value, a.Gl = -1, a.finish = -1; for (a = this.network.edges.iterator; a.next();)a.value.forest = !1; this.Gn = 0; for (d.reset(); d.next();)b = d.value, 0 === b.sourceEdges.count &&
                at(this, b); for (d.reset(); d.next();)b = d.value, -1 === b.Gl && at(this, b); for (a.reset(); a.next();)d = a.value, d.forest || (b = d.fromVertex, c = b.finish, e = d.toVertex, f = e.finish, e.Gl < b.Gl && c < f && (this.network.dm(d), d.rev = !0)); break; case Js: a = this.network; b = a.vertexes.iterator; for (d = Infinity; b.next();)d = Math.min(d, b.value.layer); if (Infinity > d) {
                    if (0 > d) for (b.reset(); b.next();)b.value.layer -= d; d = []; for (b.reset(); b.next();)c = b.value, e = d[c.layer], void 0 === e ? d[c.layer] = [c] : e.push(c); for (c = b = 0; c < d.length; c++)if (e = d[c], !e ||
                        0 === e.length) b++; else if (0 < c) for (f = 0; f < e.length; f++)e[f].layer -= b; for (d = a.edges.iterator; d.next();)b = d.value, b.fromVertex.layer < b.toVertex.layer && (a.dm(b), b.rev = !0)
                }
        }
    }; function Ys(a) { for (a = a.vertexes.iterator; a.next();) { var b = a.value; if (b.valid) return b } return null } function Zs(a) { for (a = a.vertexes.iterator; a.next();) { var b = a.value; if (b.valid) { for (var c = !0, d = b.destinationEdges; d.next();)if (d.value.toVertex.valid) { c = !1; break } if (c) return b } } return null }
    function $s(a) { for (a = a.vertexes.iterator; a.next();) { var b = a.value; if (b.valid) { for (var c = !0, d = b.sourceEdges; d.next();)if (d.value.fromVertex.valid) { c = !1; break } if (c) return b } } return null } function at(a, b) { b.Gl = a.Gn; a.Gn++; for (var c = b.destinationEdges; c.next();) { var d = c.value, e = d.toVertex; -1 === e.Gl && (d.forest = !0, at(a, e)) } b.finish = a.Gn; a.Gn++ }
    Ds.prototype.assignLayers = function () {
        switch (this.Uk) {
            case bt: ct(this); break; case dt: for (var a, b = this.network.vertexes.iterator; b.next();)a = et(this, b.value), this.Ia = Math.max(a, this.Ia); for (b.reset(); b.next();)a = b.value, a.layer = this.Ia - a.layer; break; default: case Fs: ct(this); for (b = this.network.vertexes.iterator; b.next();)b.value.valid = !1; for (b.reset(); b.next();)a = b.value, 0 === a.sourceEdges.count && ft(this, a); a = Infinity; for (b.reset(); b.next();)a = Math.min(a, b.value.layer); this.Ia = -1; for (b.reset(); b.next();) {
                var c =
                    b.value; c.layer -= a; this.Ia = Math.max(this.Ia, c.layer)
            }
        }
    }; function ct(a) { for (var b = a.network.vertexes.iterator; b.next();) { var c = gt(a, b.value); a.Ia = Math.max(c, a.Ia) } } function gt(a, b) { var c = 0; if (-1 === b.layer) { for (var d = b.destinationEdges; d.next();) { var e = d.value, f = e.toVertex; e = a.linkMinLength(e); c = Math.max(c, gt(a, f) + e) } b.layer = c } else c = b.layer; return c }
    function et(a, b) { var c = 0; if (-1 === b.layer) { for (var d = b.sourceEdges; d.next();) { var e = d.value, f = e.fromVertex; e = a.linkMinLength(e); c = Math.max(c, et(a, f) + e) } b.layer = c } else c = b.layer; return c }
    function ft(a, b) {
        if (!b.valid) {
            b.valid = !0; for (var c = b.destinationEdges; c.next();)ft(a, c.value.toVertex); for (c = a.network.vertexes.iterator; c.next();)c.value.component = -1; for (var d = b.sourceEdgesArrayAccess, e = d.length, f = 0; f < e; f++) { var g = d[f], h = g.fromVertex, k = g.toVertex; g = a.linkMinLength(g); h.layer - k.layer > g && Vs(a, h, 0, -1, !0, !1) } for (Vs(a, b, 1, -1, !0, !0); 0 !== b.component;) {
                f = 0; d = Infinity; h = 0; k = null; for (g = a.network.vertexes.iterator; g.next();) {
                    var l = g.value; if (1 === l.component) {
                        var m = 0, n = !1, p = l.sourceEdgesArrayAccess;
                        e = p.length; for (var r = 0; r < e; r++) { var q = p[r], u = q.fromVertex; m += 1; 1 !== u.component && (f += 1, u = u.layer - l.layer, q = a.linkMinLength(q), d = Math.min(d, u - q)) } p = l.destinationEdgesArrayAccess; e = p.length; for (r = 0; r < e; r++)q = p[r].toVertex, --m, 1 !== q.component ? --f : n = !0; (null === k || m < h) && !n && (k = l, h = m)
                    }
                } if (0 < f) { for (c.reset(); c.next();)e = c.value, 1 === e.component && (e.layer += d); b.component = 0 } else k.component = 0
            } for (c = a.network.vertexes.iterator; c.next();)c.value.component = -1; for (Vs(a, b, 1, -1, !0, !1); 0 !== b.component;) {
                d = 0; e = Infinity;
                f = 0; h = null; for (k = a.network.vertexes.iterator; k.next();)if (g = k.value, 1 === g.component) { l = 0; m = !1; p = g.sourceEdgesArrayAccess; n = p.length; for (r = 0; r < n; r++)q = p[r].fromVertex, l += 1, 1 !== q.component ? d += 1 : m = !0; p = g.destinationEdgesArrayAccess; n = p.length; for (r = 0; r < n; r++)q = p[r], u = q.toVertex, --l, 1 !== u.component && (--d, u = g.layer - u.layer, q = a.linkMinLength(q), e = Math.min(e, u - q)); (null === h || l > f) && !m && (h = g, f = l) } if (0 > d) { for (c.reset(); c.next();)d = c.value, 1 === d.component && (d.layer -= e); b.component = 0 } else h.component = 0
            }
        }
    }
    function Ls(a, b, c) { return 90 === a.K ? c && !b.rev || !c && b.rev ? 270 : 90 : 180 === a.K ? c && !b.rev || !c && b.rev ? 0 : 180 : 270 === a.K ? c && !b.rev || !c && b.rev ? 90 : 270 : c && !b.rev || !c && b.rev ? 180 : 0 }
    Ds.prototype.initializeIndices = function () { switch (this.Lk) { default: case ht: for (var a = this.network.vertexes.iterator; a.next();) { var b = a.value, c = b.layer; b.index = this.qc[c]; this.qc[c]++ } break; case Gs: a = this.network.vertexes.iterator; for (b = this.Ia; 0 <= b; b--)for (a.reset(); a.next();)c = a.value, c.layer === b && -1 === c.index && it(this, c); break; case jt: for (a = this.network.vertexes.iterator, b = 0; b <= this.Ia; b++)for (a.reset(); a.next();)c = a.value, c.layer === b && -1 === c.index && kt(this, c) } };
    function it(a, b) { var c = b.layer; b.index = a.qc[c]; a.qc[c]++; b = b.destinationEdgesArrayAccess; for (c = !0; c;) { c = !1; for (var d = 0; d < b.length - 1; d++) { var e = b[d], f = b[d + 1]; e.portFromColOffset > f.portFromColOffset && (c = !0, b[d] = f, b[d + 1] = e) } } for (c = 0; c < b.length; c++)d = b[c], d.valid && (d = d.toVertex, -1 === d.index && it(a, d)) }
    function kt(a, b) { var c = b.layer; b.index = a.qc[c]; a.qc[c]++; b = b.sourceEdgesArrayAccess; for (var d = !0; d;)for (d = !1, c = 0; c < b.length - 1; c++) { var e = b[c], f = b[c + 1]; e.portToColOffset > f.portToColOffset && (d = !0, b[c] = f, b[c + 1] = e) } for (c = 0; c < b.length; c++)d = b[c], d.valid && (d = d.fromVertex, -1 === d.index && kt(a, d)) }
    Ds.prototype.reduceCrossings = function () {
        var a = this.countCrossings(), b = Ps(this), c, d; for (c = 0; c < this.Zi; c++) { for (d = 0; d <= this.Ia; d++)lt(this, d, 1), mt(this, d, 1); var e = this.countCrossings(); e < a && (a = e, b = Ps(this)); for (d = this.Ia; 0 <= d; d--)lt(this, d, -1), mt(this, d, -1); e = this.countCrossings(); e < a && (a = e, b = Ps(this)) } Qs(this, b); for (c = 0; c < this.Zi; c++) {
            for (d = 0; d <= this.Ia; d++)lt(this, d, 0), mt(this, d, 0); e = this.countCrossings(); e < a && (a = e, b = Ps(this)); for (d = this.Ia; 0 <= d; d--)lt(this, d, 0), mt(this, d, 0); e = this.countCrossings();
            e < a && (a = e, b = Ps(this))
        } Qs(this, b); var f, g, h; switch (this.lk) {
            case nt: break; case ot: for (h = a + 1; (d = this.countCrossings()) < h;)for (h = d, c = this.Ia; 0 <= c; c--)for (g = 0; g <= c; g++) {
                for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, -1) || f; e = this.countCrossings(); e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, 1) || f; e = this.countCrossings(); e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f = mt(this, d, 1) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f =
                    mt(this, d, -1) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, 0) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f = mt(this, d, 0) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this))
            } break; default: case Hs: for (c = this.Ia, g = 0, h = a + 1; (d = this.countCrossings()) < h;) {
                h = d; for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, -1) || f; e = this.countCrossings(); e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, 1) || f; e = this.countCrossings(); e >= a ? Qs(this, b) :
                    (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f = mt(this, d, 1) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f = mt(this, d, -1) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = c; d >= g; d--)f = mt(this, d, 0) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this)); for (f = !0; f;)for (f = !1, d = g; d <= c; d++)f = mt(this, d, 0) || f; e >= a ? Qs(this, b) : (a = e, b = Ps(this))
            }
        }Qs(this, b)
    };
    function lt(a, b, c) { var d = Ms(a, b), e = a.qc[b], f = Us(a, b, c), g = Ts(a, b, c); for (c = 0; c < e; c++)-1 === g[c] && (g[c] = d[c].column), -1 === f[c] && (f[c] = d[c].column); for (var h = !0, k; h;)for (h = !1, c = 0; c < e - 1; c++)if (f[c + 1] < f[c] || f[c + 1] === f[c] && g[c + 1] < g[c]) h = !0, k = f[c], f[c] = f[c + 1], f[c + 1] = k, k = g[c], g[c] = g[c + 1], g[c + 1] = k, k = d[c], d[c] = d[c + 1], d[c + 1] = k; for (c = f = 0; c < e; c++)k = d[c], k.index = c, f += a.nodeMinColumnSpace(k, !0), k.column = f, f += 1, f += a.nodeMinColumnSpace(k, !1); Ns(a, b, d) }
    function mt(a, b, c) {
        var d = Ms(a, b), e = a.qc[b]; c = Rs(a, b, c); var f; var g = []; for (f = 0; f < e; f++)g[f] = -1; var h = []; for (f = 0; f < e; f++)h[f] = -1; for (var k = !1, l = !0; l;)for (l = !1, f = 0; f < e - 1; f++) {
            var m = c[d[f].index * e + d[f + 1].index], n = c[d[f + 1].index * e + d[f].index], p = 0, r = 0, q = d[f].column, u = d[f + 1].column, w = a.nodeMinColumnSpace(d[f], !0), v = a.nodeMinColumnSpace(d[f], !1), z = a.nodeMinColumnSpace(d[f + 1], !0), y = a.nodeMinColumnSpace(d[f + 1], !1); w = q - w + z; v = u - v + y; var A = d[f].sourceEdges.iterator; for (A.reset(); A.next();)if (z = A.value, y = z.fromVertex,
                z.valid && y.layer === b) { for (z = 0; d[z] !== y;)z++; z < f && (p += 2 * (f - z), r += 2 * (f + 1 - z)); z === f + 1 && (p += 1); z > f + 1 && (p += 4 * (z - f), r += 4 * (z - (f + 1))) } A = d[f].destinationEdges.iterator; for (A.reset(); A.next();)if (z = A.value, y = z.toVertex, z.valid && y.layer === b) { for (z = 0; d[z] !== y;)z++; z === f + 1 && (r += 1) } A = d[f + 1].sourceEdges.iterator; for (A.reset(); A.next();)if (z = A.value, y = z.fromVertex, z.valid && y.layer === b) { for (z = 0; d[z] !== y;)z++; z < f && (p += 2 * (f + 1 - z), r += 2 * (f - z)); z === f && (r += 1); z > f + 1 && (p += 4 * (z - (f + 1)), r += 4 * (z - f)) } A = d[f + 1].destinationEdges.iterator;
            for (A.reset(); A.next();)if (z = A.value, y = z.toVertex, z.valid && y.layer === b) { for (z = 0; d[z] !== y;)z++; z === f && (p += 1) } z = y = 0; A = g[d[f].index]; var B = h[d[f].index], E = g[d[f + 1].index], M = h[d[f + 1].index]; -1 !== A && (y += Math.abs(A - q), z += Math.abs(A - v)); -1 !== B && (y += Math.abs(B - q), z += Math.abs(B - v)); -1 !== E && (y += Math.abs(E - u), z += Math.abs(E - w)); -1 !== M && (y += Math.abs(M - u), z += Math.abs(M - w)); if (r < p - .5 || r === p && n < m - .5 || r === p && n === m && z < y - .5) l = k = !0, d[f].column = v, d[f + 1].column = w, m = d[f], d[f] = d[f + 1], d[f + 1] = m
        } for (f = 0; f < e; f++)d[f].index =
            f; Ns(a, b, d); return k
    }
    Ds.prototype.straightenAndPack = function () {
        if (0 === this.re) {
            var a = 0 !== (this.Yf & 1); var b = 0 !== (this.Yf & pt); 1E3 < this.network.edges.count && b && (a = !1); if (a) { var c = []; for (b = 0; b <= this.Ia; b++)c[b] = 0; for (var d, e = this.network.vertexes.iterator; e.next();) { var f = e.value; b = f.layer; d = f.column; f = this.nodeMinColumnSpace(f, !1); c[b] = Math.max(c[b], d + f) } for (e.reset(); e.next();)f = e.value, b = f.layer, d = f.column, f.column = (8 * (this.Qa - c[b]) >> 1) + 8 * d; this.Qa *= 8 } if (0 !== (this.Yf & 2)) for (c = !0; c;) {
                c = !1; for (b = this.Dd + 1; b <= this.Ia; b++)c =
                    qt(this, b, 1) || c; for (b = this.Dd - 1; 0 <= b; b--)c = qt(this, b, -1) || c; c = qt(this, this.Dd, 0) || c
            } if (0 !== (this.Yf & 4)) { for (b = this.Dd + 1; b <= this.Ia; b++)rt(this, b, 1); for (b = this.Dd - 1; 0 <= b; b--)rt(this, b, -1); rt(this, this.Dd, 0) } a && (st(this, -1), st(this, 1)); if (0 !== (this.Yf & 2)) for (c = !0; c;) { c = !1; c = qt(this, this.Dd, 0) || c; for (b = this.Dd + 1; b <= this.Ia; b++)c = qt(this, b, 0) || c; for (b = this.Dd - 1; 0 <= b; b--)c = qt(this, b, 0) || c }
        } else tt(this)
    }; function qt(a, b, c) { for (var d = !1; ut(a, b, c);)d = !0; return d }
    function ut(a, b, c) {
        var d, e = Ms(a, b), f = a.qc[b], g = Ts(a, b, -1); if (0 < c) for (d = 0; d < f; d++)g[d] = -1; var h = Ts(a, b, 1); if (0 > c) for (d = 0; d < f; d++)h[d] = -1; for (var k = !1, l = !0; l;)for (l = !1, d = 0; d < f; d++) {
            var m = e[d].column, n = a.nodeMinColumnSpace(e[d], !0), p = a.nodeMinColumnSpace(e[d], !1), r = 0; 0 > d - 1 || m - e[d - 1].column - 1 > n + a.nodeMinColumnSpace(e[d - 1], !1) ? r = m - 1 : r = m; n = d + 1 >= f || e[d + 1].column - m - 1 > p + a.nodeMinColumnSpace(e[d + 1], !0) ? m + 1 : m; var q = p = 0, u = 0; if (0 >= c) for (var w = e[d].sourceEdges.iterator; w.next();) {
                var v = w.value; var z = v.fromVertex;
                if (v.valid && z.layer !== b) { var y = Os(v); var A = v.portFromColOffset; v = v.portToColOffset; z = z.column; p += (Math.abs(m + v - (z + A)) + 1) * y; q += (Math.abs(r + v - (z + A)) + 1) * y; u += (Math.abs(n + v - (z + A)) + 1) * y }
            } if (0 <= c) for (w = e[d].destinationEdges.iterator; w.next();)v = w.value, z = v.toVertex, v.valid && z.layer !== b && (y = Os(v), A = v.portFromColOffset, v = v.portToColOffset, z = z.column, p += (Math.abs(m + A - (z + v)) + 1) * y, q += (Math.abs(r + A - (z + v)) + 1) * y, u += (Math.abs(n + A - (z + v)) + 1) * y); v = A = y = 0; w = g[e[d].index]; z = h[e[d].index]; -1 !== w && (y += Math.abs(w - m), A +=
                Math.abs(w - r), v += Math.abs(w - n)); -1 !== z && (y += Math.abs(z - m), A += Math.abs(z - r), v += Math.abs(z - n)); if (q < p || q === p && A < y) l = k = !0, e[d].column = r; else if (u < p || u === p && v < y) l = k = !0, e[d].column = n
        } Ns(a, b, e); a.normalize(); return k
    }
    function rt(a, b, c) {
        var d = Ms(a, b), e = a.qc[b], f = Us(a, b, c), g = []; for (c = 0; c < e; c++)g[c] = f[c]; for (f = !0; f;)for (f = !1, c = 0; c < e; c++) {
            var h = d[c].column, k = a.nodeMinColumnSpace(d[c], !0), l = a.nodeMinColumnSpace(d[c], !1), m = 0; if (-1 === g[c]) if (0 === c && c === e - 1) m = h; else if (0 === c) { var n = d[c + 1].column; n - h === l + a.nodeMinColumnSpace(d[c + 1], !0) ? m = h - 1 : m = h } else c === e - 1 ? (n = d[c - 1].column, m = h - n === k + a.nodeMinColumnSpace(d[c - 1], !1) ? h + 1 : h) : (n = d[c - 1].column, k = n + a.nodeMinColumnSpace(d[c - 1], !1) + k + 1, n = d[c + 1].column, l = n - a.nodeMinColumnSpace(d[c +
                1], !0) - l - 1, m = (k + l) / 2 | 0); else 0 === c && c === e - 1 ? m = g[c] : 0 === c ? (n = d[c + 1].column, l = n - a.nodeMinColumnSpace(d[c + 1], !0) - l - 1, m = Math.min(g[c], l)) : c === e - 1 ? (n = d[c - 1].column, k = n + a.nodeMinColumnSpace(d[c - 1], !1) + k + 1, m = Math.max(g[c], k)) : (n = d[c - 1].column, k = n + a.nodeMinColumnSpace(d[c - 1], !1) + k + 1, n = d[c + 1].column, l = n - a.nodeMinColumnSpace(d[c + 1], !0) - l - 1, k < g[c] && g[c] < l ? m = g[c] : k >= g[c] ? m = k : l <= g[c] && (m = l)); m !== h && (f = !0, d[c].column = m)
        } Ns(a, b, d); a.normalize()
    }
    function vt(a, b) { for (var c = !0, d = a.network.vertexes.iterator; d.next();) { var e = d.value, f = a.nodeMinColumnSpace(e, !0), g = a.nodeMinColumnSpace(e, !1); if (e.column - f <= b && e.column + g >= b) { c = !1; break } } a = !1; if (c) for (d.reset(); d.next();)c = d.value, c.column > b && (--c.column, a = !0); return a }
    function wt(a, b) { var c = b + 1; var d, e = [], f = []; for (d = 0; d <= a.Ia; d++)e[d] = !1, f[d] = !1; for (var g = a.network.vertexes.iterator; g.next();) { d = g.value; var h = d.column - a.nodeMinColumnSpace(d, !0), k = d.column + a.nodeMinColumnSpace(d, !1); h <= b && k >= b && (e[d.layer] = !0); h <= c && k >= c && (f[d.layer] = !0) } h = !0; c = !1; for (d = 0; d <= a.Ia; d++)h = h && !(e[d] && f[d]); if (h) for (g.reset(); g.next();)a = g.value, a.column > b && (--a.column, c = !0); return c }
    function st(a, b) { for (var c = 0; c <= a.Qa; c++)for (; vt(a, c);); a.normalize(); for (c = 0; c < a.Qa; c++)for (; wt(a, c);); a.normalize(); var d; if (0 < b) for (c = 0; c <= a.Qa; c++) { var e = Ps(a); var f = Ss(a); for (d = f + 1; f < d;) { d = f; xt(a, c, 1); var g = Ss(a); g > f ? Qs(a, e) : g < f && (f = g, e = Ps(a)) } } if (0 > b) for (c = a.Qa; 0 <= c; c--)for (e = Ps(a), f = Ss(a), d = f + 1; f < d;)d = f, xt(a, c, -1), g = Ss(a), g > f ? Qs(a, e) : g < f && (f = g, e = Ps(a)); a.normalize() }
    function xt(a, b, c) {
        a.he = 0; for (var d = a.network.vertexes.iterator; d.next();)d.value.component = -1; if (0 < c) for (d.reset(); d.next();) { var e = d.value; e.column - a.nodeMinColumnSpace(e, !0) <= b && (e.component = a.he) } if (0 > c) for (d.reset(); d.next();)e = d.value, e.column + a.nodeMinColumnSpace(e, !1) >= b && (e.component = a.he); a.he++; for (d.reset(); d.next();)b = d.value, -1 === b.component && (Ws(a, b, a.he, -1, !0, !0), a.he++); var f; b = []; for (f = 0; f < a.he * a.he; f++)b[f] = !1; e = []; for (f = 0; f < (a.Ia + 1) * (a.Qa + 1); f++)e[f] = -1; for (d.reset(); d.next();) {
            f =
            d.value; for (var g = f.layer, h = Math.max(0, f.column - a.nodeMinColumnSpace(f, !0)), k = Math.min(a.Qa, f.column + a.nodeMinColumnSpace(f, !1)); h <= k; h++)e[g * (a.Qa + 1) + h] = f.component
        } for (f = 0; f <= a.Ia; f++) { if (0 < c) for (g = 0; g < a.Qa; g++)-1 !== e[f * (a.Qa + 1) + g] && -1 !== e[f * (a.Qa + 1) + g + 1] && e[f * (a.Qa + 1) + g] !== e[f * (a.Qa + 1) + g + 1] && (b[e[f * (a.Qa + 1) + g] * a.he + e[f * (a.Qa + 1) + g + 1]] = !0); if (0 > c) for (g = a.Qa; 0 < g; g--)-1 !== e[f * (a.Qa + 1) + g] && -1 !== e[f * (a.Qa + 1) + g - 1] && e[f * (a.Qa + 1) + g] !== e[f * (a.Qa + 1) + g - 1] && (b[e[f * (a.Qa + 1) + g] * a.he + e[f * (a.Qa + 1) + g - 1]] = !0) } e =
            []; for (f = 0; f < a.he; f++)e[f] = !0; g = []; for (g.push(0); 0 !== g.length;)if (k = g[g.length - 1], g.pop(), e[k]) for (e[k] = !1, f = 0; f < a.he; f++)b[k * a.he + f] && g.splice(0, 0, f); if (0 < c) for (d.reset(); d.next();)a = d.value, e[a.component] && --a.column; if (0 > c) for (d.reset(); d.next();)c = d.value, e[c.component] && (c.column += 1)
    }
    function tt(a) {
        for (var b = Ea(), c = a.al.length, d = 0; d <= c; d++)b[d] = []; for (d = a.network.vertexes.iterator; d.next();) { var e = d.value; b[c - e.layer][e.index] = e } c = new H; yt(b, c); var f = null, g = null, h = null, k = null, l = 0 !== (a.re & 1), m = 0 !== (a.re & 2), n = 0 !== (a.re & 4), p = 0 !== (a.re & 8); l && (a.verticalAlignment(b, c, !0), f = zt(a, b, !0, !1)); b.reverse(); n && (a.verticalAlignment(b, c, !1), h = zt(a, b, !1, !1)); d = ba(b); for (e = d.next(); !e.done; e = d.next())e.value.reverse(); p && (a.verticalAlignment(b, c, !1), k = zt(a, b, !1, !0)); b.reverse(); m && (a.verticalAlignment(b,
            c, !0), g = zt(a, b, !0, !0)); Ga(b); a.u(f, g, h, k); a.network.vertexes.each(function (a) { var b = Ea(); l && b.push(f.get(a)); m && b.push(g.get(a)); n && b.push(h.get(a)); p && b.push(k.get(a)); b.sort(function (a, b) { return a - b }); var c = b.length; c = (b[Math.floor((c - 1) / 2)] + b[Math.ceil((c - 1) / 2)]) / 2; Ga(b); a.wc = c })
    }
    function yt(a, b) {
        for (var c = a.length, d = 1; d < c - 1; d++)for (var e = 0, f = 0, g = a[d], h = a[d + 1], k = 0; f < h.length; k++) {
            var l = h[k]; a: { if (null === l.node) { var m = At(l); if (0 < m.length) { m = null === m[0].node; break a } } m = !1 } var n = m; if (k === h.length - 1 || n) {
                m = g.length - 1; n && (m = At(l)[0].index); for (; f <= k;) { l = h[f]; if (!l.Yn) { n = []; for (var p = ba(l.sourceEdgesArrayAccess), r = p.next(); !r.done; r = p.next())r = r.value, r.valid && n.push(r); l.Yn = n } l = ba(l.Yn); for (n = l.next(); !n.done; n = l.next())n = n.value, p = n.fromVertex.index, (p < e || p > m) && b.add(n); f++ } e =
                    m
            }
        }
    } Ds.prototype.verticalAlignment = function (a, b, c) { Bt(a); a = ba(a); for (var d = a.next(); !d.done; d = a.next()) { var e = -1; d = ba(d.value); for (var f = d.next(); !f.done; f = d.next()) { f = f.value; var g = c ? At(f) : Ct(f), h = g.length; if (0 < h) { g.sort(function (a, b) { return a.si - b.si }); var k = (h - 1) / 2; h = Math.floor(k); for (k = Math.ceil(k); h <= k; h++)if (f.align === f) { var l = g[h], m = void 0; m = c ? Dt(l, f) : Dt(f, l); !b.contains(m) && e < l.si && (l.align = f, f.root = l.root, f.align = l.root, e = l.si) } } } } };
    function Bt(a) { for (var b = 0; b < a.length; b++)for (var c = a[b], d = 0; d < c.length; d++) { var e = c[d]; e.root = e; e.align = e; e.Kz = b; e.si = d } }
    function zt(a, b, c, d) {
        for (var e = 90 === a.K || 270 === a.K, f = ba(b), g = f.next(); !g.done; g = f.next()) { var h = ba(g.value); for (g = h.next(); !g.done; g = h.next())g = g.value, g.ld = g, g.shift = Infinity, g.wc = NaN, g.mg = 0 } g = 90 === a.K || 270 === a.K; f = ba(b); for (h = f.next(); !h.done; h = f.next()) {
            h = ba(h.value); for (var k = h.next(); !k.done; k = h.next())if (k = k.value, k.root === k) {
                for (var l = 0, m = k; m.align !== k;) {
                    var n = m.align, p = g ? m.width : m.height, r = g ? n.width : n.height; if (c) {
                        var q = Dt(m, n); var u = q.portFromPos; var w = q.portToPos; null !== q.link && (m.node &&
                            m.node !== q.link.fromNode && (u = g ? m.focusX : m.focusY), n.node && n.node !== q.link.toNode && (w = g ? n.focusX : n.focusY))
                    } else q = Dt(n, m), u = q.portToPos, w = q.portFromPos, null !== q.link && (m.node && m.node !== q.link.toNode && (u = g ? m.focusX : m.focusY), n.node && n.node !== q.link.fromNode && (w = g ? n.focusX : n.focusY)); p = d ? m.mg + (p - u) - (r - w) : m.mg + u - w; n.mg = p; l = Math.min(l, p); m = m.align
                } m = k; do m.mg = m.mg - l, m = m.align; while (m !== k)
            }
        } c = ba(b); for (g = c.next(); !g.done; g = c.next())for (f = ba(g.value), g = f.next(); !g.done; g = f.next())g = g.value, g.root === g &&
            Et(a, g, b); for (c = 0; c < b.length; c++)if (!(0 >= b[c].length) && (g = b[c][0], g.ld === g)) { Infinity === g.ld.shift && (g.ld.shift = 0); g = c; h = 0; do { for (f = b[g][h]; f.align !== f.root;)f = f.align, g++, 0 < f.si && (h = Ft(f, b), h.ld.shift = Math.min(h.ld.shift, f.ld.shift + f.wc + f.mg - (h.wc + h.mg + (e ? h.width : h.height) + a.columnSpacing))); h = f.si + 1 } while (g < b.length && h < b[g].length && f.ld === b[g][h].ld) } a = new ob; b = ba(b); for (g = b.next(); !g.done; g = b.next())for (c = ba(g.value), g = c.next(); !g.done; g = c.next())g = g.value, g.wc = g.wc + g.ld.shift + g.mg, f = e ? g.width :
                g.height, a.add(g, d ? -g.wc - f : g.wc); return a
    } function Et(a, b, c) { if (isNaN(b.wc)) { b.wc = 0; var d = b; do { if (0 < d.si) { var e = Ft(d, c), f = e.root; Et(a, f, c); b.ld === b && (b.ld = f.ld); var g = 90 === a.K || 270 === a.K ? e.width : e.height; b.ld === f.ld && (b.wc = Math.max(b.wc, f.wc + e.mg + g - d.mg + a.columnSpacing)) } d = d.align } while (d !== b); for (; d.align !== b;)d = d.align, d.wc = b.wc, d.ld = b.ld } } function Ft(a, b) { var c = a.Kz; a = a.si; 1 > a && C("Could not determine previous vertex in layer"); return b[c][a - 1] }
    Ds.prototype.u = function (a) { for (var b = [], c = 0; c < arguments.length; ++c)b[c] = arguments[c]; c = -1; for (var d = Ea(), e = Ea(), f = Infinity, g = 0; 4 > g; g++)if (b[g]) { var h = Gt(this, b[g], d, e, g); h < f && (c = g, f = h) } f = {}; for (g = 0; 4 > g; f = { delta: f.delta }, g++)b[g] && (f.delta = 0 === g || 2 === g ? d[c] - d[g] : e[c] - e[g], 0 !== f.delta && b[g].each(function (a) { return function (b) { b.value += a.delta } }(f))); Ga(d); Ga(e) };
    function Gt(a, b, c, d, e) { if (!b || 0 === b.count) return c[e] = 0, d[e] = 0, Infinity; var f = Infinity, g = -Infinity; b.each(function (b) { var c = b.key; b = b.value; c = 90 === a.K || 270 === a.K ? c.width : c.height; b < f && (f = b); b + c > g && (g = b + c) }); c[e] = f; d[e] = g; return g - f } Ds.prototype.commitLayout = function () { if (this.setsPortSpots) for (var a = Ks(this, !0), b = Ks(this, !1), c = this.network.edges.iterator; c.next();) { var d = c.value.link; null !== d && (d.fromSpot = a, d.toSpot = b) } this.commitNodes(); this.Au(); this.isRouting && this.commitLinks() };
    function Ks(a, b) { return 270 === a.K ? b ? $c : cd : 90 === a.K ? b ? cd : $c : 180 === a.K ? b ? ad : bd : b ? bd : ad }
    Ds.prototype.commitNodes = function () {
        this.de = []; this.Cd = []; this.Bd = []; this.Ta = []; for (var a = 0; a <= this.Ia; a++)this.de[a] = 0, this.Cd[a] = 0, this.Bd[a] = 0, this.Ta[a] = 0; for (a = this.network.vertexes.iterator; a.next();) { var b = a.value, c = b.layer; this.de[c] = Math.max(this.de[c], this.nodeMinLayerSpace(b, !0)); this.Cd[c] = Math.max(this.Cd[c], this.nodeMinLayerSpace(b, !1)) } b = 0; c = this.ee; for (var d = 0; d <= this.Ia; d++) {
            var e = c; 0 >= this.de[d] + this.Cd[d] && (e = 0); 0 < d && (b += e / 2); 90 === this.K || 0 === this.K ? (b += this.Cd[d], this.Bd[d] = b,
                b += this.de[d]) : (b += this.de[d], this.Bd[d] = b, b += this.Cd[d]); d < this.Ia && (b += e / 2); this.Ta[d] = b
        } c = b; b = this.arrangementOrigin; for (d = 0; d <= this.Ia; d++)270 === this.K ? this.Bd[d] = b.y + this.Bd[d] : 90 === this.K ? (this.Bd[d] = b.y + c - this.Bd[d], this.Ta[d] = c - this.Ta[d]) : 180 === this.K ? this.Bd[d] = b.x + this.Bd[d] : (this.Bd[d] = b.x + c - this.Bd[d], this.Ta[d] = c - this.Ta[d]); c = 0 !== this.re; a.reset(); d = 270 === this.K || 90 === this.K; for (var f = Infinity, g = e = Infinity, h = -Infinity; a.next();) {
            var k = a.value, l = k.layer, m = k.column | 0; d ? (g = b.x + this.gc *
                m, l = this.Bd[l]) : (g = this.Bd[l], l = b.y + this.gc * m); k.centerX = g; k.centerY = l; c && isFinite(k.wc) && (d ? k.node ? k.x = b.x + k.wc : k.centerX = b.x + k.wc : k.node ? k.y = b.y + k.wc : k.centerY = b.y + k.wc); f = Math.min(k.x, f); e = Math.min(k.y, e); d ? (g = f, h = Math.max(h, k.bounds.right)) : (g = e, h = Math.max(h, k.bounds.bottom))
        } this.qw = h - g; f = b.x - f; b = b.y - e; this.Of = new I(c && d ? 0 : f, c && !d ? 0 : b); for (a.reset(); a.next();)c = a.value, c.x += f, c.y += b, c.commit()
    };
    Ds.prototype.Au = function () {
        for (var a = 0, b = this.ee, c = 0; c <= this.Ia; c++)a += this.de[c], a += this.Cd[c]; a += this.Ia * b; b = []; c = 0 !== this.re ? this.qw : this.gc * this.Qa; for (var d = this.maxLayer; 0 <= d; d--)270 === this.K ? 0 === d ? b.push(new N(0, 0, c, Math.abs(this.Ta[0]))) : b.push(new N(0, this.Ta[d - 1], c, Math.abs(this.Ta[d - 1] - this.Ta[d]))) : 90 === this.K ? 0 === d ? b.push(new N(0, this.Ta[0], c, Math.abs(this.Ta[0] - a))) : b.push(new N(0, this.Ta[d], c, Math.abs(this.Ta[d - 1] - this.Ta[d]))) : 180 === this.K ? 0 === d ? b.push(new N(0, 0, Math.abs(this.Ta[0]),
            c)) : b.push(new N(this.Ta[d - 1], 0, Math.abs(this.Ta[d - 1] - this.Ta[d]), c)) : 0 === d ? b.push(new N(this.Ta[0], 0, Math.abs(this.Ta[0] - a), c)) : b.push(new N(this.Ta[d], 0, Math.abs(this.Ta[d - 1] - this.Ta[d]), c)); this.commitLayers(b, this.Of)
    }; Ds.prototype.commitLayers = function () { };
    Ds.prototype.commitLinks = function () {
        for (var a = this.network.edges.iterator, b; a.next();)b = a.value.link, null !== b && (b.ph(), b.Dj(), b.wf()); for (a.reset(); a.next();)b = a.value.link, null !== b && b.rg(); for (a.reset(); a.next();) {
            var c = a.value; b = c.link; if (null !== b) {
                b.ph(); var d = b, e = d.fromNode, f = d.toNode, g = d.fromPort, h = d.toPort; if (null !== e) { var k = e.findVisibleNode(); null !== k && k !== e && (e = k, g = k.port) } null !== f && (k = f.findVisibleNode(), null !== k && k !== f && (f = k, h = k.port)); var l = b.computeSpot(!0, g); k = b.computeSpot(!1, h); var m =
                    c.fromVertex, n = c.toVertex; if (c.valid) {
                        if (b.curve === bg && 4 === b.pointsCount) if (m.column === n.column && 0 === this.alignOption) c = b.getLinkPoint(e, g, l, !0, !1, f, h), g = b.getLinkPoint(f, h, k, !1, !1, e, g), c.w() || c.set(e.actualBounds.center), g.w() || g.set(f.actualBounds.center), b.Dj(), b.vf(c.x, c.y), b.vf((2 * c.x + g.x) / 3, (2 * c.y + g.y) / 3), b.vf((c.x + 2 * g.x) / 3, (c.y + 2 * g.y) / 3), b.vf(g.x, g.y); else {
                            var p = !1, r = !1; null !== g && l === rc && (p = !0); null !== h && k === rc && (r = !0); if (p || r) {
                                var q = b.i(0).x; c = b.i(0).y; m = b.i(3).x; d = b.i(3).y; if (p) {
                                    if (90 ===
                                        this.K || 270 === this.K) { p = q; var u = (c + d) / 2 } else p = (q + m) / 2, u = c; b.M(1, p, u); l = b.getLinkPoint(e, g, l, !0, !1, f, h); l.w() || l.set(e.actualBounds.center); b.M(0, l.x, l.y)
                                } r && (90 === this.K || 270 === this.K ? (l = m, c = (c + d) / 2) : (l = (q + m) / 2, c = d), b.M(2, l, c), e = b.getLinkPoint(f, h, k, !1, !1, e, g), e.w() || e.set(f.actualBounds.center), b.M(3, e.x, e.y))
                            }
                        } b.wf()
                    } else if (m.layer === n.layer) b.wf(); else {
                        p = r = !1; u = this.ee; q = b.firstPickIndex + 1; if (b.isOrthogonal) { p = !0; var w = b.pointsCount; 4 < w && b.points.removeRange(2, w - 3) } else if (b.curve === bg) r = !0,
                            w = b.pointsCount, 4 < w && b.points.removeRange(2, w - 3), 4 === w && (q = 2); else { w = b.pointsCount; var v = l === rc, z = k === rc; 2 < w && v && z ? b.points.removeRange(1, w - 2) : 3 < w && v && !z ? b.points.removeRange(1, w - 3) : 3 < w && !v && z ? b.points.removeRange(2, w - 2) : 4 < w && !v && !z && b.points.removeRange(2, w - 3) } if (c.rev) {
                                for (; null !== n && m !== n;) {
                                    var y = w = null; for (v = n.sourceEdges.iterator; v.next() && (z = v.value, z.link !== c.link || (w = z.fromVertex, y = z.toVertex, null !== w.node));); if (null === w) break; if (w !== m) {
                                        v = b.i(q - 1).x; z = b.i(q - 1).y; var A = w.centerX; var B = w.centerY;
                                        if (p) 180 === this.K || 0 === this.K ? q === b.firstPickIndex + 1 ? (b.l(q++, v, z), b.l(q++, v, B)) : (null !== y ? y.centerY : z) !== B && (y = this.Ta[w.layer - 1] + this.Of.x, b.l(q++, y, z), b.l(q++, y, B)) : q === b.firstPickIndex + 1 ? (b.l(q++, v, z), b.l(q++, A, z)) : (null !== y ? y.centerX : v) !== A && (y = this.Ta[w.layer - 1] + this.Of.y, b.l(q++, v, y), b.l(q++, A, y)); else if (q === b.firstPickIndex + 1) {
                                            y = Math.max(10, this.de[n.layer]); var E = Math.max(10, this.Cd[n.layer]); if (r) 180 === this.K ? A <= n.bounds.x ? (n = n.bounds.x, b.l(q++, n - y, B), b.l(q++, n, B), b.l(q++, n + E, B)) : (b.l(q++,
                                                A - y, B), b.l(q++, A, B), b.l(q++, A + E, B)) : 90 === this.K ? B >= n.bounds.bottom ? (n = n.bounds.y + n.bounds.height, b.l(q++, A, n + E), b.l(q++, A, n), b.l(q++, A, n - y)) : (b.l(q++, A, B + E), b.l(q++, A, B), b.l(q++, A, B - y)) : 270 === this.K ? B <= n.bounds.y ? (n = n.bounds.y, b.l(q++, A, n - y), b.l(q++, A, n), b.l(q++, A, n + E)) : (b.l(q++, A, B - y), b.l(q++, A, B), b.l(q++, A, B + E)) : 0 === this.K && (A >= n.bounds.right ? (n = n.bounds.x + n.bounds.width, b.l(q++, n + E, B), b.l(q++, n, B), b.l(q++, n - y, B)) : (b.l(q++, A + E, B), b.l(q++, A, B), b.l(q++, A - y, B))); else {
                                                    b.l(q++, v, z); var M = 0; if (180 ===
                                                        this.K || 0 === this.K) { if (180 === this.K ? A >= n.bounds.right : A <= n.bounds.x) M = (0 === this.K ? -y : E) / 2; b.l(q++, v + M, B) } else { if (270 === this.K ? B >= n.bounds.bottom : B <= n.bounds.y) M = (90 === this.K ? -y : E) / 2; b.l(q++, A, z + M) } b.l(q++, A, B)
                                            }
                                        } else y = Math.max(10, this.de[w.layer]), E = Math.max(10, this.Cd[w.layer]), r ? 180 === this.K ? (b.l(q++, A - y - u, B), b.l(q++, Math.min(A - y + u / 2, A), B), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, Math.max(A + E - u / 2, A), B), b.l(q++, A + E + u, B)) : 90 === this.K ? (b.l(q++, A, B + E + u), b.l(q++, A, Math.max(B + E - u / 2, B)), b.l(q++, A, B), b.l(q++,
                                            A, B), b.l(q++, A, Math.min(B - y + u / 2, B)), b.l(q++, A, B - y - u)) : 270 === this.K ? (b.l(q++, A, B - y - u), b.l(q++, A, Math.min(B - y + u / 2, B)), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, A, Math.max(B + E - u / 2, B)), b.l(q++, A, B + E + u)) : (b.l(q++, A + E + u, B), b.l(q++, Math.max(A + E - u / 2, A), B), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, Math.min(A - y + u / 2, A), B), b.l(q++, A - y - u, B)) : b.l(q++, A, B)
                                    } n = w
                                } if (null === h || l !== rc || p) if (v = b.i(q - 1).x, z = b.i(q - 1).y, A = b.i(q).x, B = b.i(q).y, p) u = this.Cd[m.layer], 180 === this.K || 0 === this.K ? (p = z, p >= m.bounds.y && p <= m.bounds.bottom && (180 === this.K ?
                                    A >= m.bounds.x : A <= m.bounds.right) && (n = m.centerX + (180 === this.K ? -u : u), p < m.bounds.y + m.bounds.height / 2 ? p = m.bounds.y - this.gc / 2 : p = m.bounds.bottom + this.gc / 2, b.l(q++, n, z), b.l(q++, n, p)), b.l(q++, A, p)) : (p = v, p >= m.bounds.x && p <= m.bounds.right && (270 === this.K ? B >= m.bounds.y : B <= m.bounds.bottom) && (n = m.centerY + (270 === this.K ? -u : u), p < m.bounds.x + m.bounds.width / 2 ? p = m.bounds.x - this.gc / 2 : p = m.bounds.right + this.gc / 2, b.l(q++, v, n), b.l(q++, p, n)), b.l(q++, p, B)), b.l(q++, A, B); else if (r) y = Math.max(10, this.de[m.layer]), E = Math.max(10,
                                        this.Cd[m.layer]), 180 === this.K && A >= m.bounds.x ? (n = m.bounds.x + m.bounds.width, b.M(q - 2, n, z), b.M(q - 1, n + E, z)) : 90 === this.K && B <= m.bounds.bottom ? (n = m.bounds.y, b.M(q - 2, v, n), b.M(q - 1, v, n - y)) : 270 === this.K && B >= m.bounds.y ? (n = m.bounds.y + m.bounds.height, b.M(q - 2, v, n), b.M(q - 1, v, n + E)) : 0 === this.K && A <= m.bounds.right && (n = m.bounds.x, b.M(q - 2, n, z), b.M(q - 1, n - y, z)); else {
                                            y = Math.max(10, this.de[m.layer]); E = Math.max(10, this.Cd[m.layer]); p = 0; if (180 === this.K || 0 === this.K) {
                                                if (180 === this.K ? A <= m.bounds.x : A >= m.bounds.right) p = (0 === this.K ?
                                                    E : -y) / 2; b.l(q++, A + p, z)
                                            } else { if (270 === this.K ? B <= m.bounds.y : B >= m.bounds.bottom) p = (90 === this.K ? E : -y) / 2; b.l(q++, v, B + p) } b.l(q++, A, B)
                                }
                            } else {
                                for (; null !== m && m !== n;) {
                                    y = w = null; for (m = m.destinationEdges.iterator; m.next() && (v = m.value, v.link !== c.link || (w = v.toVertex, y = v.fromVertex, null !== y.node && (y = null), null !== w.node));); if (null === w) break; w !== n && (v = b.i(q - 1).x, z = b.i(q - 1).y, A = w.centerX, B = w.centerY, p ? 180 === this.K || 0 === this.K ? (null !== y ? y.centerY : z) !== B && (y = this.Ta[w.layer] + this.Of.x, q === b.firstPickIndex + 1 && (y = 0 ===
                                        this.K ? Math.max(y, v) : Math.min(y, v)), b.l(q++, y, z), b.l(q++, y, B)) : (null !== y ? y.centerX : v) !== A && (y = this.Ta[w.layer] + this.Of.y, q === b.firstPickIndex + 1 && (y = 90 === this.K ? Math.max(y, z) : Math.min(y, z)), b.l(q++, v, y), b.l(q++, A, y)) : (y = Math.max(10, this.de[w.layer]), E = Math.max(10, this.Cd[w.layer]), r ? 180 === this.K ? (b.l(q++, A + E + u, B), b.l(q++, Math.max(A + E - u / 2, A), B), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, Math.min(A - y + u / 2, A), B), b.l(q++, A - y - u, B)) : 90 === this.K ? (b.l(q++, A, B - y - u), b.l(q++, A, Math.min(B - y + u / 2, B)), b.l(q++, A, B), b.l(q++,
                                            A, B), b.l(q++, A, Math.max(B + E - u / 2, B)), b.l(q++, A, B + E + u)) : 270 === this.K ? (b.l(q++, A, B + E + u), b.l(q++, A, Math.max(B + E - u / 2, B)), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, A, Math.min(B - y + u / 2, B)), b.l(q++, A, B - y - u)) : (b.l(q++, A - y - u, B), b.l(q++, Math.min(A - y + u / 2, A), B), b.l(q++, A, B), b.l(q++, A, B), b.l(q++, Math.max(A + E - u / 2, A), B), b.l(q++, A + E + u, B)) : 180 === this.K ? (b.l(q++, A + E, B), b.l(q++, A - y, B)) : 90 === this.K ? (b.l(q++, A, B - y), b.l(q++, A, B + E)) : 270 === this.K ? (b.l(q++, A, B + E), b.l(q++, A, B - y)) : (b.l(q++, A - y, B), b.l(q++, A + E, B)))); m = w
                                } p && (v = b.i(q - 1).x,
                                    z = b.i(q - 1).y, A = b.i(q).x, B = b.i(q).y, 180 === this.K || 0 === this.K ? z !== B && (y = 0 === this.K ? Math.min(Math.max((A + v) / 2, this.Ta[n.layer] + this.Of.x), A) : Math.max(Math.min((A + v) / 2, this.Ta[n.layer] + this.Of.x), A), b.l(q++, y, z), b.l(q++, y, B)) : v !== A && (y = 90 === this.K ? Math.min(Math.max((B + z) / 2, this.Ta[n.layer] + this.Of.y), B) : Math.max(Math.min((B + z) / 2, this.Ta[n.layer] + this.Of.y), B), b.l(q++, v, y), b.l(q++, A, y)))
                        } null !== d && r && (null !== g && (l === rc && (l = b.i(0), d = b.i(2), l.C(d) || b.M(1, (l.x + d.x) / 2, (l.y + d.y) / 2)), l = b.getLinkPoint(e, g, rc,
                            !0, !1, f, h), l.w() || l.set(e.actualBounds.center), b.M(0, l.x, l.y)), null !== h && (k === rc && (k = b.i(b.pointsCount - 1), l = b.i(b.pointsCount - 3), k.C(l) || b.M(b.pointsCount - 2, (k.x + l.x) / 2, (k.y + l.y) / 2)), e = b.getLinkPoint(f, h, rc, !1, !1, e, g), e.w() || e.set(f.actualBounds.center), b.M(b.pointsCount - 1, e.x, e.y))); b.wf(); c.commit()
                    }
            }
        } this.avoidOrthogonalOverlaps()
    };
    Ds.prototype.avoidOrthogonalOverlaps = function () {
        if (!(0 >= this.linkSpacing)) {
            for (var a = new F, b = this.network.edges.iterator; b.next();) { var c = b.value.link; null !== c && c.isOrthogonal && !a.contains(c) && a.add(c) } if (0 < a.count) if (90 === this.K || 270 === this.K) {
                b = 0; c = []; for (a = a.iterator; a.next();) {
                    var d = a.value; if (null !== d && d.isOrthogonal) for (var e = 2; e < d.pointsCount - 3; e++) {
                        var f = d.i(e); var g = d.i(e + 1); if (this.B(f.y, g.y) && !this.B(f.x, g.x)) {
                            var h = new Ht; h.layer = Math.floor(f.y / 2); var k = d.i(0), l = d.i(d.pointsCount - 1); h.first =
                                k.x * k.x + k.y; h.fc = l.x * l.x + l.y; h.kd = Math.min(f.x, g.x); h.Fc = Math.max(f.x, g.x); h.index = e; h.link = d; if (e + 2 < d.pointsCount) { k = d.i(e - 1); l = d.i(e + 2); var m = 0; k.y < f.y ? m = l.y < f.y ? 3 : f.x < g.x ? 2 : 1 : k.y > f.y && (m = l.y > f.y ? 0 : g.x < f.x ? 2 : 1); h.j = m } c.push(h)
                        }
                    }
                } if (1 < c.length) for (c.sort(this.Rx), a = 0; a < c.length;) {
                    f = c[a].layer; for (d = a + 1; d < c.length && c[d].layer === f;)d++; if (1 < d - a) for (f = a; f < d;) {
                        g = c[f].Fc; for (e = a + 1; e < d && c[e].kd < g;)g = Math.max(g, c[e].Fc), e++; g = e - f; if (1 < g) {
                            this.wi(c, this.Cs, f, f + g); m = 1; h = c[f].fc; for (k = f; k < e; k++)l = c[k], l.fc !==
                                h && (m++, h = l.fc); this.wi(c, this.Qx, f, f + g); var n = 1; h = c[f].first; for (k = f; k < e; k++)l = c[k], l.first !== h && (n++, h = l.first); k = !0; l = n; m < n ? (k = !1, l = m, h = c[f].fc, this.wi(c, this.Cs, f, f + g)) : h = c[f].first; m = 0; for (n = f; n < e; n++) { var p = c[n]; (k ? p.first : p.fc) !== h && (m++, h = k ? p.first : p.fc); var r = p.link; f = r.i(p.index); g = r.i(p.index + 1); var q = this.linkSpacing * (m - (l - 1) / 2); b++; r.ph(); r.M(p.index, f.x, f.y + q); r.M(p.index + 1, g.x, g.y + q); r.wf() }
                        } f = e
                    } a = d
                }
            } else {
                b = 0; c = []; for (a = a.iterator; a.next();)if (d = a.value, null !== d && d.isOrthogonal) for (e =
                    2; e < d.pointsCount - 3; e++)f = d.i(e), g = d.i(e + 1), this.B(f.x, g.x) && !this.B(f.y, g.y) && (h = new Ht, h.layer = Math.floor(f.x / 2), k = d.i(0), l = d.i(d.pointsCount - 1), h.first = k.x + k.y * k.y, h.fc = l.x + l.y * l.y, h.kd = Math.min(f.y, g.y), h.Fc = Math.max(f.y, g.y), h.index = e, h.link = d, e + 2 < d.pointsCount && (k = d.i(e - 1), l = d.i(e + 2), m = 0, k.x < f.x ? m = l.x < f.x ? 3 : f.y < g.y ? 2 : 1 : k.x > f.x && (m = l.x > f.x ? 0 : g.y < f.y ? 2 : 1), h.j = m), c.push(h)); if (1 < c.length) for (c.sort(this.Rx), a = 0; a < c.length;) {
                        f = c[a].layer; for (d = a + 1; d < c.length && c[d].layer === f;)d++; if (1 < d - a) for (f =
                            a; f < d;) {
                                g = c[f].Fc; for (e = a + 1; e < d && c[e].kd < g;)g = Math.max(g, c[e].Fc), e++; g = e - f; if (1 < g) {
                                    this.wi(c, this.Cs, f, f + g); m = 1; h = c[f].fc; for (k = f; k < e; k++)l = c[k], l.fc !== h && (m++, h = l.fc); this.wi(c, this.Qx, f, f + g); n = 1; h = c[f].first; for (k = f; k < e; k++)l = c[k], l.first !== h && (n++, h = l.first); k = !0; l = n; m < n ? (k = !1, l = m, h = c[f].fc, this.wi(c, this.Cs, f, f + g)) : h = c[f].first; m = 0; for (n = f; n < e; n++)p = c[n], (k ? p.first : p.fc) !== h && (m++, h = k ? p.first : p.fc), r = p.link, f = r.i(p.index), g = r.i(p.index + 1), q = this.linkSpacing * (m - (l - 1) / 2), b++, r.ph(), r.M(p.index,
                                        f.x + q, f.y), r.M(p.index + 1, g.x + q, g.y), r.wf()
                                } f = e
                        } a = d
                    }
            }
        }
    }; t = Ds.prototype; t.Rx = function (a, b) { return a instanceof Ht && b instanceof Ht && a !== b ? a.layer < b.layer ? -1 : a.layer > b.layer ? 1 : a.kd < b.kd ? -1 : a.kd > b.kd ? 1 : a.Fc < b.Fc ? -1 : a.Fc > b.Fc ? 1 : 0 : 0 }; t.Qx = function (a, b) { return a instanceof Ht && b instanceof Ht && a !== b ? a.first < b.first ? -1 : a.first > b.first || a.j < b.j ? 1 : a.j > b.j || a.kd < b.kd ? -1 : a.kd > b.kd ? 1 : a.Fc < b.Fc ? -1 : a.Fc > b.Fc ? 1 : 0 : 0 };
    t.Cs = function (a, b) { return a instanceof Ht && b instanceof Ht && a !== b ? a.fc < b.fc ? -1 : a.fc > b.fc || a.j < b.j ? 1 : a.j > b.j || a.kd < b.kd ? -1 : a.kd > b.kd ? 1 : a.Fc < b.Fc ? -1 : a.Fc > b.Fc ? 1 : 0 : 0 }; t.B = function (a, b) { a -= b; return -1 < a && 1 > a };
    t.wi = function (a, b, c, d) { var e = a.length, f = d - c; if (!(1 >= f)) if ((0 > c || c >= e - 1) && C("not in range 0 <= from < length: " + c), 2 === f) d = a[c], e = a[c + 1], 0 < b(d, e) && (a[c] = e, a[c + 1] = d); else if (0 === c) if (d >= e) a.sort(b); else for (c = a.slice(0, d), c.sort(b), b = 0; b < d; b++)a[b] = c[b]; else if (d >= e) for (d = a.slice(c), d.sort(b), b = c; b < e; b++)a[b] = d[b - c]; else for (e = a.slice(c, d), e.sort(b), b = c; b < d; b++)a[b] = e[b - c] };
    function Ms(a, b) { var c = a.qc[b]; if (c >= a.De.length) { var d = []; for (var e = 0; e < a.De.length; e++)d[e] = a.De[e]; a.De = d } void 0 === a.De[c] || null === a.De[c] ? d = [] : (d = a.De[c], a.De[c] = null); a = a.al[b]; for (b = 0; b < a.length; b++)c = a[b], d[c.index] = c; return d } function Ns(a, b, c) { a.De[a.qc[b]] = c }
    ma.Object.defineProperties(Ds.prototype, {
        layerSpacing: { get: function () { return this.ee }, set: function (a) { this.ee !== a && 0 <= a && (this.ee = a, this.D()) } }, columnSpacing: { get: function () { return this.gc }, set: function (a) { this.gc !== a && 0 < a && (this.gc = a, this.D()) } }, direction: { get: function () { return this.K }, set: function (a) { this.K !== a && (0 === a || 90 === a || 180 === a || 270 === a ? (this.K = a, this.D()) : C("LayeredDigraphLayout.direction must be 0, 90, 180, or 270")) } },
        cycleRemoveOption: { get: function () { return this.yk }, set: function (a) { this.yk === a || a !== Xs && a !== Es && a !== Js || (this.yk = a, this.D()) } }, layeringOption: { get: function () { return this.Uk }, set: function (a) { this.Uk === a || a !== Fs && a !== bt && a !== dt || (this.Uk = a, this.D()) } }, initializeOption: { get: function () { return this.Lk }, set: function (a) { this.Lk === a || a !== Gs && a !== jt && a !== ht || (this.Lk = a, this.D()) } }, iterations: {
            get: function () { return this.Zi }, set: function (a) { this.Zi !== a && 0 <= a && (this.Zi = a, this.D()) }
        }, aggressiveOption: { get: function () { return this.lk }, set: function (a) { this.lk === a || a !== nt && a !== Hs && a !== ot || (this.lk = a, this.D()) } }, packOption: { get: function () { return this.Yf }, set: function (a) { this.Yf !== a && 0 <= a && 8 > a && (this.Yf = a, this.D()) } }, alignOption: {
            get: function () { return this.re }, set: function (a) {
                this.re !== a && 0 <= a && 16 > a && (this.re =
                    a, this.D())
            }
        }, setsPortSpots: { get: function () { return this.pf }, set: function (a) { this.pf !== a && (this.pf = a, this.D()) } }, linkSpacing: { get: function () { return this.yn }, set: function (a) { this.yn !== a && 0 <= a && (this.yn = a, this.D()) } }, maxLayer: { get: function () { return this.Ia } }, maxIndex: { get: function () { return this.Bq } }, maxColumn: { get: function () { return this.Qa } }, minIndexLayer: {
            get: function () { return this.Dn }
        }, maxIndexLayer: { get: function () { return this.Dd } }
    });
    var Es = new D(Ds, "CycleDepthFirst", 0), Xs = new D(Ds, "CycleGreedy", 1), Js = new D(Ds, "CycleFromLayers", 2), Fs = new D(Ds, "LayerOptimalLinkLength", 0), bt = new D(Ds, "LayerLongestPathSink", 1), dt = new D(Ds, "LayerLongestPathSource", 2), Gs = new D(Ds, "InitDepthFirstOut", 0), jt = new D(Ds, "InitDepthFirstIn", 1), ht = new D(Ds, "InitNaive", 2), nt = new D(Ds, "AggressiveNone", 0), Hs = new D(Ds, "AggressiveLess", 1), ot = new D(Ds, "AggressiveMore", 2), pt = 8; Ds.className = "LayeredDigraphLayout"; Ds.CycleDepthFirst = Es; Ds.CycleGreedy = Xs;
    Ds.CycleFromLayers = Js; Ds.LayerOptimalLinkLength = Fs; Ds.LayerLongestPathSink = bt; Ds.LayerLongestPathSource = dt; Ds.InitDepthFirstOut = Gs; Ds.InitDepthFirstIn = jt; Ds.InitNaive = ht; Ds.AggressiveNone = nt; Ds.AggressiveLess = Hs; Ds.AggressiveMore = ot; Ds.PackNone = 0; Ds.PackExpand = 1; Ds.PackStraighten = 2; Ds.PackMedian = 4; Ds.PackAll = 15; Ds.AlignNone = 0; Ds.AlignUpperLeft = 1; Ds.AlignUpperRight = 2; Ds.AlignLowerLeft = 4; Ds.AlignLowerRight = 8; Ds.AlignAll = 15;
    function Ht() { this.index = this.Fc = this.kd = this.fc = this.first = this.layer = 0; this.link = null; this.j = 0 } Ht.className = "SegInfo"; function Is(a) { rq.call(this, a) } la(Is, rq); Is.prototype.createVertex = function () { return new It(this) }; Is.prototype.createEdge = function () { return new Jt(this) }; Is.className = "LayeredDigraphNetwork"; function It(a) { uq.call(this, a); this.Wn = this.Yn = this.Xn = this.Zn = null; this.Ya = this.wg = this.aj = -1; this.u = NaN; this.F = null; this.valid = !1; this.finish = this.Gl = NaN; this.Bj = 0; this.$u = this.av = null }
    la(It, uq); function Kt(a, b) { return a.index - b.index } function Dt(a, b) { if (!a.Wn) { for (var c = [], d = ba(a.destinationEdgesArrayAccess), e = d.next(); !e.done; e = d.next())e = e.value, e.valid && c.push(e); a.Wn = c } a = ba(a.Wn); for (c = a.next(); !c.done; c = a.next())if (c = c.value, c.toVertex === b) return c; C("Unable to find destination edge to given vertex") } function At(a) { if (!a.Zn) { for (var b = [], c = ba(a.sourceEdgesArrayAccess), d = c.next(); !d.done; d = c.next())d = d.value, d.valid && b.push(d.fromVertex); b.sort(Kt); a.Zn = b } return a.Zn }
    function Ct(a) { if (!a.Xn) { for (var b = [], c = ba(a.destinationEdgesArrayAccess), d = c.next(); !d.done; d = c.next())d = d.value, d.valid && b.push(d.toVertex); b.sort(Kt); a.Xn = b } return a.Xn } It.prototype.yl = function (a) { uq.prototype.yl.call(this, a); this.Yn = this.Zn = null }; It.prototype.Fl = function (a) { uq.prototype.Fl.call(this, a); this.Yn = this.Zn = null }; It.prototype.vl = function (a) { uq.prototype.vl.call(this, a); this.Wn = this.Xn = null }; It.prototype.El = function (a) { uq.prototype.El.call(this, a); this.Wn = this.Xn = null };
    ma.Object.defineProperties(It.prototype, {
        layer: { get: function () { return this.aj }, set: function (a) { this.aj !== a && (this.aj = a) } }, column: { get: function () { return this.wg }, set: function (a) { this.wg !== a && (this.wg = a) } }, index: { get: function () { return this.Ya }, set: function (a) { this.Ya !== a && (this.Ya = a) } }, component: { get: function () { return this.u }, set: function (a) { this.u !== a && (this.u = a) } }, near: {
            get: function () { return this.F }, set: function (a) { this.F !== a && (this.F = a) }
        }
    }); It.prototype.deleteDestinationEdge = It.prototype.El; It.prototype.addDestinationEdge = It.prototype.vl; It.prototype.deleteSourceEdge = It.prototype.Fl; It.prototype.addSourceEdge = It.prototype.yl; It.className = "LayeredDigraphVertex"; function Jt(a) { vq.call(this, a); this.j = this.ga = this.Ha = !1; this.V = this.u = NaN; this.F = this.m = 0 } la(Jt, vq);
    ma.Object.defineProperties(Jt.prototype, {
        fromVertex: { get: function () { return this.bd }, set: function (a) { this.bd !== a && (this.bd = a) } }, toVertex: { get: function () { return this.hd }, set: function (a) { this.hd !== a && (this.hd = a) } }, valid: { get: function () { return this.Ha }, set: function (a) { this.Ha !== a && (this.Ha = a) } }, rev: { get: function () { return this.ga }, set: function (a) { this.ga !== a && (this.ga = a) } }, forest: {
            get: function () { return this.j }, set: function (a) { this.j !== a && (this.j = a) }
        }, portFromPos: { get: function () { return this.u }, set: function (a) { this.u !== a && (this.u = a) } }, portToPos: { get: function () { return this.V }, set: function (a) { this.V !== a && (this.V = a) } }, portFromColOffset: { get: function () { return this.m }, set: function (a) { this.m !== a && (this.m = a) } }, portToColOffset: {
            get: function () { return this.F },
            set: function (a) { this.F !== a && (this.F = a) }
        }
    }); Jt.className = "LayeredDigraphEdge"; function Lt(a) { ri.call(this); this.Qb = new H; this.Sn = Mt; this.od = Nt; this.ro = Ot; this.uq = Pt; this.Tv = []; this.md = !0; this.Mb = Qt; this.Wd = (new L(10, 10)).freeze(); var b = new Rt(this); this.W = new St(b); this.Y = new St(b); this.mu = []; a && Object.assign(this, a) } la(Lt, ri);
    Lt.prototype.cloneProtected = function (a) { ri.prototype.cloneProtected.call(this, a); a.Sn = this.Sn; a.ro = this.ro; a.uq = this.uq; a.md = this.md; a.Mb = this.Mb; a.Wd.assign(this.Wd); a.W.copyInheritedPropertiesFrom(this.W); a.Y.copyInheritedPropertiesFrom(this.Y) };
    Lt.prototype.rb = function (a) { a.classType === Lt ? 0 === a.name.indexOf("Alignment") ? this.alignment = a : 0 === a.name.indexOf("Arrangement") ? this.arrangement = a : 0 === a.name.indexOf("Compaction") ? this.compaction = a : 0 === a.name.indexOf("Path") ? this.path = a : 0 === a.name.indexOf("Sorting") ? this.sorting = a : 0 === a.name.indexOf("Style") ? this.treeStyle = a : C("Unknown enum value: " + a) : ri.prototype.rb.call(this, a) }; Lt.prototype.createNetwork = function () { return new Rt(this) };
    Lt.prototype.makeNetwork = function (a) { function b(a) { if (a instanceof V) return !a.isLinkLabel && "Comment" !== a.category; if (a instanceof T) { var b = a.fromNode; if (null === b || b.isLinkLabel || "Comment" === b.category) return !1; a = a.toNode; return null === a || a.isLinkLabel || "Comment" === a.category ? !1 : !0 } return !1 } var c = this.createNetwork(); a instanceof Q ? (c.hg(a.nodes, !0, b), c.hg(a.links, !0, b)) : a instanceof pf ? c.hg(a.memberParts, !1, b) : c.hg(a.iterator, !1, b); return c };
    Lt.prototype.doLayout = function (a) {
        null === this.network && (this.network = this.makeNetwork(a)); this.arrangement !== Tt && (this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin)); var b = this.diagram; null === b && a instanceof Q && (b = a); this.path === Mt && null !== b ? this.od = b.isTreePathToChildren ? Nt : Ut : this.od = this.path === Mt ? Nt : this.path; if (0 < this.network.vertexes.count) {
            this.network.Go(); for (a = this.network.vertexes.iterator; a.next();)b = a.value, b.initialized = !1, b.level = 0, b.parent = null, b.children = []; if (0 < this.Qb.count) {
                a =
                new H; for (b = this.Qb.iterator; b.next();) { var c = b.value; c instanceof V ? (c = this.network.hi(c), null !== c && a.add(c)) : c instanceof St && a.add(c) } this.Qb = a
            } 0 === this.Qb.count && this.findRoots(); for (a = this.Qb.copy().iterator; a.next();)b = a.value, b.initialized || (b.initialized = !0, Vt(this, b)); b = this.network.vertexes; for (a = null; a = Wt(b), 0 < a.count;)b = Xt(this, a), null !== b && this.Qb.add(b), b.initialized = !0, Vt(this, b), b = a; for (a = this.Qb.iterator; a.next();)b = a.value, b instanceof St && Yt(this, b); for (a = this.Qb.iterator; a.next();)b =
                a.value, b instanceof St && Zt(this, b); for (a = this.Qb.iterator; a.next();)b = a.value, b instanceof St && $t(this, b); this.xu(); if (this.layerStyle === au) {
                    a = []; for (b = this.network.vertexes.iterator; b.next();) { c = b.value; var d = c.parent; null === d && (d = c); d = 0 === d.angle || 180 === d.angle; var e = a[c.level]; void 0 === e && (e = 0); a[c.level] = Math.max(e, d ? c.width : c.height) } for (b = 0; b < a.length; b++)void 0 === a[b] && (a[b] = 0); this.Tv = a; for (b = this.network.vertexes.iterator; b.next();)c = b.value, d = c.parent, null === d && (d = c), 0 === d.angle || 180 ===
                        d.angle ? (180 === d.angle && (c.focusX += a[c.level] - c.width), c.width = a[c.level]) : (270 === d.angle && (c.focusY += a[c.level] - c.height), c.height = a[c.level])
                } else if (this.layerStyle === bu) for (a = this.network.vertexes.iterator; a.next();) {
                    b = a.value; c = 0 === b.angle || 180 === b.angle; d = -1; for (e = 0; e < b.children.length; e++) { var f = b.children[e]; d = Math.max(d, c ? f.width : f.height) } if (0 <= d) for (e = 0; e < b.children.length; e++)f = b.children[e], c ? (180 === b.angle && (f.focusX += d - f.width), f.width = d) : (270 === b.angle && (f.focusY += d - f.height), f.height =
                        d)
                } for (a = this.Qb.iterator; a.next();)b = a.value, b instanceof St && this.layoutTree(b); this.arrangeTrees(); this.updateParts()
        } this.network = null; this.Qb = new H; this.isValidLayout = !0
    }; function Wt(a) { var b = new H; for (a = a.iterator; a.next();) { var c = a.value; c.initialized || b.add(c) } return b }
    Lt.prototype.findRoots = function () { for (var a = this.network.vertexes, b = a.iterator; b.next();) { var c = b.value; switch (this.od) { case Nt: 0 === c.sourceEdges.count && this.Qb.add(c); break; case Ut: 0 === c.destinationEdges.count && this.Qb.add(c); break; default: C("Unhandled path value " + this.od.toString()) } } 0 === this.Qb.count && (a = Xt(this, a), null !== a && this.Qb.add(a)) };
    function Xt(a, b) { var c = 999999, d = null; for (b = b.iterator; b.next();) { var e = b.value; switch (a.od) { case Nt: e.sourceEdges.count < c && (c = e.sourceEdges.count, d = e); break; case Ut: e.destinationEdges.count < c && (c = e.destinationEdges.count, d = e); break; default: C("Unhandled path value " + a.od.toString()) } } return d }
    function Vt(a, b) {
        if (null !== b) {
            switch (a.od) { case Nt: if (0 < b.destinationEdges.count) { for (var c = new F, d = b.destinationVertexes; d.next();) { var e = d.value; cu(a, b, e) && c.add(e) } 0 < c.count && (b.children = c.Fa()) } break; case Ut: if (0 < b.sourceEdges.count) { c = new F; for (d = b.sourceVertexes; d.next();)e = d.value, cu(a, b, e) && c.add(e); 0 < c.count && (b.children = c.Fa()) } break; default: C("Unhandled path value" + a.od.toString()) }c = b.children; d = c.length; for (e = 0; e < d; e++) { var f = c[e]; f.initialized = !0; f.level = b.level + 1; f.parent = b; a.Qb.remove(f) } for (b =
                0; b < d; b++)Vt(a, c[b])
        }
    } function cu(a, b, c) { if (c.initialized) { if (null === b) var d = !1; else { for (d = b.parent; null !== d && d !== c;)d = d.parent; d = d === c } if (d || c.level > b.level) return !1; a.removeChild(c.parent, c) } return !0 } Lt.prototype.removeChild = function (a, b) { if (null !== a && null !== b) { for (var c = a.children, d = 0, e = 0; e < c.length; e++)c[e] === b && d++; if (0 < d) { d = Array(c.length - d); for (var f = e = 0; f < c.length; f++)c[f] !== b && (d[e++] = c[f]); a.children = d } } };
    function Yt(a, b) { if (null !== b) { a.initializeTreeVertexValues(b); b.alignment === du && a.sortTreeVertexChildren(b); for (var c = 0, d = b.childrenCount, e = 0, f = b.children, g = f.length, h = 0; h < g; h++) { var k = f[h]; Yt(a, k); c += k.descendantCount + 1; d = Math.max(d, k.maxChildrenCount); e = Math.max(e, k.maxGenerationCount) } b.descendantCount = c; b.maxChildrenCount = d; b.maxGenerationCount = 0 < d ? e + 1 : 0 } }
    function eu(a, b) { switch (a.ro) { default: case Ot: return null !== b.parent ? b.parent : a.W; case fu: return null === b.parent ? a.W : null === b.parent.parent ? a.Y : b.parent; case gu: return null !== b.parent ? null !== b.parent.parent ? b.parent.parent : a.Y : a.W; case hu: var c = !0; if (0 === b.childrenCount) c = !1; else for (var d = b.children, e = d.length, f = 0; f < e; f++)if (0 < d[f].childrenCount) { c = !1; break } return c && null !== b.parent ? a.Y : null !== b.parent ? b.parent : a.W } }
    Lt.prototype.initializeTreeVertexValues = function (a) { a.copyInheritedPropertiesFrom(eu(this, a)); if (null !== a.parent && a.parent.alignment === du) { for (var b = a.angle, c = a.parent.children, d = 0; d < c.length && a !== c[d];)d++; 0 === d % 2 ? d !== c.length - 1 && (b = 90 === b ? 180 : 180 === b ? 270 : 270 === b ? 180 : 270) : b = 90 === b ? 0 : 180 === b ? 90 : 270 === b ? 0 : 90; a.angle = b } a.initialized = !0 }; function Zt(a, b) { if (null !== b) { a.assignTreeVertexValues(b); b = b.children; for (var c = b.length, d = 0; d < c; d++)Zt(a, b[d]) } } Lt.prototype.assignTreeVertexValues = function () { };
    function $t(a, b) { if (null !== b) { b.alignment !== du && a.sortTreeVertexChildren(b); b = b.children; for (var c = b.length, d = 0; d < c; d++)$t(a, b[d]) } } Lt.prototype.sortTreeVertexChildren = function (a) { switch (a.sorting) { case iu: break; case ju: a.children.reverse(); break; case ku: a.children.sort(a.comparer); break; case lu: a.children.sort(a.comparer); a.children.reverse(); break; default: C("Unhandled sorting value " + a.sorting.toString()) } }; Lt.prototype.xu = function () { if (this.comments) for (var a = this.network.vertexes.iterator; a.next();)this.addComments(a.value) };
    Lt.prototype.addComments = function (a) {
        var b = a.angle, c = a.parent, d = 0; var e = !1; null !== c && (d = c.angle, e = c.alignment, e = mu(e)); b = 90 === b || 270 === b; d = 90 === d || 270 === d; c = 0 === a.childrenCount; var f = 0, g = 0, h = 0, k = a.commentSpacing; if (null !== a.node) for (var l = a.node.Gu(); l.next();) {
            var m = l.value; "Comment" === m.category && m.canLayout() && (null === a.comments && (a.comments = []), a.comments.push(m), m.Va(), m = m.measuredBounds, b && !c || !e && !d && c || e && d && c ? (f = Math.max(f, m.width), g += m.height + Math.abs(h)) : (f += m.width + Math.abs(h), g = Math.max(g,
                m.height)), h = k)
        } null !== a.comments && (b && !c || !e && !d && c || e && d && c ? (f += Math.abs(a.commentMargin), g = Math.max(0, g - a.height)) : (g += Math.abs(a.commentMargin), f = Math.max(0, f - a.width)), e = N.allocAt(0, 0, a.bounds.width + f, a.bounds.height + g), a.bounds = e, N.free(e))
    }; function mu(a) { return a === nu || a === du || a === qu || a === ru } function su(a) { return a === nu || a === du }
    function tu(a) { var b = a.parent; if (null !== b) { var c = b.alignment; if (mu(c)) { if (su(c)) { b = b.children; for (c = 0; c < b.length && a !== b[c];)c++; return 0 === c % 2 } if (c === qu) return !0 } } return !1 }
    Lt.prototype.layoutComments = function (a) {
        if (null !== a.comments) {
            var b = a.node.measuredBounds, c = a.parent, d = a.angle, e = 0; var f = !1; null !== c && (e = c.angle, f = c.alignment, f = mu(f)); d = 90 === d || 270 === d; c = 90 === e || 270 === e; for (var g = 0 === a.childrenCount, h = tu(a), k = 0, l = a.comments, m = l.length, n = I.alloc(), p = 0; p < m; p++) {
                var r = l[p], q = r.measuredBounds; if (d && !g || !f && !c && g || f && c && g) {
                    if (135 < e && !f || c && h) if (0 <= a.commentMargin) for (n.h(a.bounds.x - a.commentMargin - q.width, a.bounds.y + k), r.move(n), r = r.Kd(); r.next();) {
                        var u = r.value; u.fromSpot =
                            ad; u.toSpot = bd
                    } else for (n.h(a.bounds.x + 2 * a.focus.x - a.commentMargin, a.bounds.y + k), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = bd, u.toSpot = ad; else if (0 <= a.commentMargin) for (n.h(a.bounds.x + 2 * a.focus.x + a.commentMargin, a.bounds.y + k), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = bd, u.toSpot = ad; else for (n.h(a.bounds.x + a.commentMargin - q.width, a.bounds.y + k), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = ad, u.toSpot = bd; k = 0 <= a.commentSpacing ? k + (q.height + a.commentSpacing) : k + (a.commentSpacing - q.height)
                } else {
                    if (135 <
                        e && !f || !c && h) if (0 <= a.commentMargin) for (n.h(a.bounds.x + k, a.bounds.y - a.commentMargin - q.height), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = $c, u.toSpot = cd; else for (n.h(a.bounds.x + k, a.bounds.y + 2 * a.focus.y - a.commentMargin), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = cd, u.toSpot = $c; else if (0 <= a.commentMargin) for (n.h(a.bounds.x + k, a.bounds.y + 2 * a.focus.y + a.commentMargin), r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = cd, u.toSpot = $c; else for (n.h(a.bounds.x + k, a.bounds.y + a.commentMargin - q.height),
                            r.move(n), r = r.Kd(); r.next();)u = r.value, u.fromSpot = $c, u.toSpot = cd; k = 0 <= a.commentSpacing ? k + (q.width + a.commentSpacing) : k + (a.commentSpacing - q.width)
                }
            } I.free(n); b = k - a.commentSpacing - (d ? b.height : b.width); if (this.od === Nt) for (a = a.destinationEdges; a.next();)e = a.value.link, null === e || e.isAvoiding || (e.fromEndSegmentLength = 0 < b ? b : NaN); else for (a = a.sourceEdges; a.next();)e = a.value.link, null === e || e.isAvoiding || (e.toEndSegmentLength = 0 < b ? b : NaN)
        }
    };
    Lt.prototype.layoutTree = function (a) {
        if (null !== a) {
            for (var b = a.children, c = b.length, d = 0; d < c; d++)this.layoutTree(b[d]); switch (a.compaction) {
                case uu: vu(this, a); break; case wu: if (a.alignment === du) vu(this, a); else if (0 === a.childrenCount) d = a.parent, c = !1, b = 0, null !== d && (b = d.angle, c = d.alignment, c = mu(c)), d = tu(a), a.S.h(0, 0), a.Da.h(a.width, a.height), null === a.parent || null === a.comments || (180 !== b && 270 !== b || c) && !d ? a.la.h(0, 0) : 180 === b && !c || (90 === b || 270 === b) && d ? a.la.h(a.width - 2 * a.focus.x, 0) : a.la.h(0, a.height - 2 * a.focus.y),
                    a.So = null, a.ep = null; else {
                        var e = xu(a); b = 90 === e || 270 === e; var f = 0, g = a.children, h = g.length; for (c = 0; c < h; c++)d = g[c], f = Math.max(f, b ? d.Da.width : d.Da.height); var k = a.alignment; d = k === yu; var l = k === zu, m = mu(k), n = Math.max(0, a.breadthLimit); c = this.computeLayerSpacing(a); var p = a.nodeSpacing, r = this.computeNodeIndent(a), q = a.rowSpacing, u = 0; if (d || l || a.em || a.fm && 1 === a.maxGenerationCount) u = Math.max(0, a.rowIndent); d = a.width; var w = a.height, v = 0, z = 0, y = 0, A = null, B = null, E = 0, M = 0, R = 0, S = 0, J = 0, G = 0, ca = 0, X = 0; m && !su(k) && 135 < e && g.reverse();
                    if (su(k)) if (1 < h) for (var na = 0; na < h; na++)0 === na % 2 && na !== h - 1 && (X = Math.max(X, b ? g[na].Da.width : g[na].Da.height)); else 1 === h && (X = b ? g[0].Da.width : g[0].Da.height); if (m) {
                        switch (k) {
                            case nu: z = 135 > e ? Au(this, a, g, X, v, z) : Bu(this, a, g, X, v, z); X = z.x; v = z.width; z = z.height; break; case qu: for (A = 0; A < h; A++)B = g[A], n = B.Da, y = 0 === G ? 0 : q, b ? (B.S.h(f - n.width, S + y), v = Math.max(v, n.width), z = Math.max(z, S + y + n.height), S += y + n.height) : (B.S.h(R + y, f - n.height), v = Math.max(v, R + y + n.width), z = Math.max(z, n.height), R += y + n.width), G++; break; case ru: for (A =
                                0; A < h; A++)B = g[A], f = B.Da, n = 0 === G ? 0 : q, b ? (B.S.h(p / 2 + a.focus.x, S + n), v = Math.max(v, f.width), z = Math.max(z, S + n + f.height), S += n + f.height) : (B.S.h(R + n, p / 2 + a.focus.y), v = Math.max(v, R + n + f.width), z = Math.max(z, f.height), R += n + f.width), G++
                        }A = Cu(this, 2); B = Cu(this, 2); b ? (A[0].h(0, 0), A[1].h(0, z), B[0].h(v, 0)) : (A[0].h(0, 0), A[1].h(v, 0), B[0].h(0, z)); B[1].h(v, z)
                    } else for (na = 0; na < h; na++) {
                        var wa = g[na], db = wa.Da; if (b) {
                            0 < n && 0 < G && R + p + db.width > n && (R < f && Du(a, k, f - R, 0, ca, na - 1), J++, G = 0, ca = na, y = z, R = 0, S = 135 < e ? -z - q : z + q); Eu(this, wa, 0, S); var gb =
                                0; if (0 === G) { if (A = wa.So, B = wa.ep, E = db.width, M = db.height, null === A || null === B || e !== xu(wa)) A = Cu(this, 2), B = Cu(this, 2), A[0].h(0, 0), A[1].h(0, M), B[0].h(E, 0), B[1].h(E, M) } else { var Db = Ea(); M = Fu(this, a, wa, A, B, E, M, Db); gb = M.x; A = Db[0]; B = Db[1]; E = M.width; M = M.height; Ga(Db); R < db.width && 0 > gb && (Gu(a, -gb, 0, ca, na - 1), Hu(A, -gb, 0), Hu(B, -gb, 0), gb = 0) } wa.S.h(gb, S); v = Math.max(v, E); z = Math.max(z, y + (0 === J ? 0 : q) + db.height); R = E
                        } else {
                            0 < n && 0 < G && S + p + db.height > n && (S < f && Du(a, k, 0, f - S, ca, na - 1), J++, G = 0, ca = na, y = v, S = 0, R = 135 < e ? -v - q : v + q); Eu(this,
                                wa, R, 0); gb = 0; if (0 === G) { if (A = wa.So, B = wa.ep, E = db.width, M = db.height, null === A || null === B || e !== xu(wa)) A = Cu(this, 2), B = Cu(this, 2), A[0].h(0, 0), A[1].h(E, 0), B[0].h(0, M), B[1].h(E, M) } else Db = Ea(), M = Fu(this, a, wa, A, B, E, M, Db), gb = M.x, A = Db[0], B = Db[1], E = M.width, M = M.height, Ga(Db), S < db.height && 0 > gb && (Gu(a, 0, -gb, ca, na - 1), Hu(A, 0, -gb), Hu(B, 0, -gb), gb = 0); wa.S.h(R, gb); z = Math.max(z, M); v = Math.max(v, y + (0 === J ? 0 : q) + db.width); S = M
                        } G++
                    } 0 < J && (b ? (z += Math.max(0, c), R < v && Du(a, k, v - R, 0, ca, h - 1), 0 < u && (l || Gu(a, u, 0, 0, h - 1), v += u)) : (v += Math.max(0,
                        c), S < z && Du(a, k, 0, z - S, ca, h - 1), 0 < u && (l || Gu(a, 0, u, 0, h - 1), z += u))); u = l = 0; switch (k) {
                            case Iu: b ? l += v / 2 - a.focus.x - r / 2 : u += z / 2 - a.focus.y - r / 2; break; case Ju: 0 < J ? b ? l += v / 2 - a.focus.x - r / 2 : u += z / 2 - a.focus.y - r / 2 : b ? (X = g[0].S.x + g[0].la.x, l += X + (g[h - 1].S.x + g[h - 1].la.x + 2 * g[h - 1].focus.x - X) / 2 - a.focus.x - r / 2) : (X = g[0].S.y + g[0].la.y, u += X + (g[h - 1].S.y + g[h - 1].la.y + 2 * g[h - 1].focus.y - X) / 2 - a.focus.y - r / 2); break; case yu: b ? (l -= r, v += r) : (u -= r, z += r); break; case zu: b ? (l += v - a.width + r, v += r) : (u += z - a.height + r, z += r); break; case nu: b ? 1 < h ? l += X + p / 2 - a.focus.x :
                                l += g[0].focus.x - a.focus.x + g[0].la.x : 1 < h ? u += X + p / 2 - a.focus.y : u += g[0].focus.y - a.focus.y + g[0].la.y; break; case qu: b ? l += v + p / 2 - a.focus.x : u += z + p / 2 - a.focus.y; break; case ru: break; default: z = this.customAlignment(a, l, u, v, z), l = z[0], u = z[1], v = z[2], z = z[3]
                        }for (r = 0; r < h; r++)X = g[r], b ? X.S.h(X.S.x + X.la.x - l, X.S.y + (135 < e ? (m ? -z : -X.Da.height) + X.la.y - c : w + c + X.la.y)) : X.S.h(X.S.x + (135 < e ? (m ? -v : -X.Da.width) + X.la.x - c : d + c + X.la.x), X.S.y + X.la.y - u); h = g = 0; m ? b ? (v = Ku(a, v, l), 0 > l && (l = 0), 135 < e && (u += z + c), z += w + c, k === ru && (g += p / 2 + a.focus.x), h +=
                            w + c) : (135 < e && (l += v + c), v += d + c, z = Lu(a, z, u), 0 > u && (u = 0), k === ru && (h += p / 2 + a.focus.y), g += d + c) : b ? (null === a.comments ? d > v && (v = Mu(k, d - v, 0), g = v.x, h = v.y, v = d, l = 0) : v = Ku(a, v, l), 0 > l && (g -= l, l = 0), 135 < e && (u += z + c), z = Math.max(Math.max(z, w), z + w + c), h += w + c) : (135 < e && (l += v + c), v = Math.max(Math.max(v, d), v + d + c), null === a.comments ? w > z && (z = Mu(k, 0, w - z), g = z.x, h = z.y, z = w, u = 0) : z = Lu(a, z, u), 0 > u && (h -= u, u = 0), g += d + c); if (0 < J) e = Cu(this, 4), J = Cu(this, 4), b ? (e[2].h(0, w + c), e[3].h(e[2].x, z), J[2].h(v, e[2].y), J[3].h(J[2].x, e[3].y)) : (e[2].h(d + c, 0), e[3].h(v,
                                e[2].y), J[2].h(e[2].x, z), J[3].h(e[3].x, J[2].y)); else { e = Cu(this, A.length + 2); J = Cu(this, B.length + 2); for (k = 0; k < A.length; k++)m = A[k], e[k + 2].h(m.x + g, m.y + h); for (k = 0; k < B.length; k++)m = B[k], J[k + 2].h(m.x + g, m.y + h) } b ? (e[0].h(l, 0), e[1].h(e[0].x, w), e[2].y < e[1].y && (e[2].x > e[0].x ? e[2].assign(e[1]) : e[1].assign(e[2])), e[3].y < e[2].y && (e[3].x > e[0].x ? e[3].assign(e[2]) : e[2].assign(e[3])), J[0].h(l + d, 0), J[1].h(J[0].x, w), J[2].y < J[1].y && (J[2].x < J[0].x ? J[2].assign(J[1]) : J[1].assign(J[2])), J[3].y < J[2].y && (J[3].x < J[0].x ? J[3].assign(J[2]) :
                                    J[2].assign(J[3])), e[2].y -= c / 2, J[2].y -= c / 2) : (e[0].h(0, u), e[1].h(d, e[0].y), e[2].x < e[1].x && (e[2].y > e[0].y ? e[2].assign(e[1]) : e[1].assign(e[2])), e[3].x < e[2].x && (e[3].y > e[0].y ? e[3].assign(e[2]) : e[2].assign(e[3])), J[0].h(0, u + w), J[1].h(d, J[0].y), J[2].x < J[1].x && (J[2].y < J[0].y ? J[2].assign(J[1]) : J[1].assign(J[2])), J[3].x < J[2].x && (J[3].y < J[0].y ? J[3].assign(J[2]) : J[2].assign(J[3])), e[2].x -= c / 2, J[2].x -= c / 2); Nu(this, A); Nu(this, B); a.So = e; a.ep = J; a.la.h(l, u); a.Da.h(v, z)
                } break; default: C("Unhandled compaction value " +
                    a.compaction.toString())
            }
        }
    };
    function vu(a, b) {
        if (0 === b.childrenCount) { var c = !1, d = 0; null !== b.parent && (d = b.parent.angle, c = b.parent.alignment, c = mu(c)); var e = tu(b); b.S.h(0, 0); b.Da.h(b.width, b.height); null === b.parent || null === b.comments || (180 !== d && 270 !== d || c) && !e ? b.la.h(0, 0) : 180 === d && !c || (90 === d || 270 === d) && e ? b.la.h(b.width - 2 * b.focus.x, 0) : b.la.h(0, b.height - 2 * b.focus.y) } else {
            d = xu(b); c = 90 === d || 270 === d; var f = 0; e = b.children; for (var g = e.length, h = 0; h < g; h++) { var k = e[h]; f = Math.max(f, c ? k.Da.width : k.Da.height) } var l = b.alignment, m = l === yu, n = l ===
                zu; h = mu(l); var p = Math.max(0, b.breadthLimit); k = a.computeLayerSpacing(b); var r = b.nodeSpacing, q = a.computeNodeIndent(b), u = m || n ? 0 : q / 2, w = b.rowSpacing, v = 0; if (m || n || b.em || b.fm && 1 === b.maxGenerationCount) v = Math.max(0, b.rowIndent); m = b.width; var z = b.height, y = 0, A = 0, B = 0, E = 0, M = 0, R = 0, S = 0, J = 0, G = 0, ca = 0; h && !su(l) && 135 < d && e.reverse(); if (su(l)) if (1 < g) for (var X = 0; X < g; X++) {
                    var na = e[X], wa = na.Da; 0 === X % 2 && X !== g - 1 ? G = Math.max(G, (c ? wa.width : wa.height) + a.computeBusNodeSpacing(na) - r) : 0 !== X % 2 && (ca = Math.max(ca, (c ? wa.width : wa.height) +
                        a.computeBusNodeSpacing(na) - r))
                } else 1 === g && (G = c ? e[0].Da.width : e[0].Da.height); if (h) switch (l) {
                    case nu: case du: A = 135 > d ? Au(a, b, e, G, y, A) : Bu(a, b, e, G, y, A); G = A.x; y = A.width; A = A.height; break; case qu: for (p = 0; p < g; p++)u = e[p], B = u.Da, ca = 0 === S ? 0 : w, c ? (u.S.h(f - B.width, M + ca), y = Math.max(y, B.width), A = Math.max(A, M + ca + B.height), M += ca + B.height) : (u.S.h(E + ca, f - B.height), y = Math.max(y, E + ca + B.width), A = Math.max(A, B.height), E += ca + B.width), S++; break; case ru: for (f = 0; f < g; f++)p = e[f], u = p.Da, B = 0 === S ? 0 : w, c ? (p.S.h(r / 2 + b.focus.x, M + B),
                        y = Math.max(y, u.width), A = Math.max(A, M + B + u.height), M += B + u.height) : (p.S.h(E + B, r / 2 + b.focus.y), y = Math.max(y, E + B + u.width), A = Math.max(A, u.height), E += B + u.width), S++
                } else for (ca = 0; ca < g; ca++)X = e[ca], na = X.Da, c ? (0 < p && 0 < S && E + r + na.width > p && (E < f && Du(b, l, f - E, 0, J, ca - 1), R++, S = 0, J = ca, B = A, E = 0, M = 135 < d ? -A - w : A + w), wa = 0 === S ? u : r, Eu(a, X, 0, M), X.S.h(E + wa, M), y = Math.max(y, E + wa + na.width), A = Math.max(A, B + (0 === R ? 0 : w) + na.height), E += wa + na.width) : (0 < p && 0 < S && M + r + na.height > p && (M < f && Du(b, l, 0, f - M, J, ca - 1), R++, S = 0, J = ca, B = y, M = 0, E = 135 < d ? -y - w :
                    y + w), wa = 0 === S ? u : r, Eu(a, X, E, 0), X.S.h(E, M + wa), A = Math.max(A, M + wa + na.height), y = Math.max(y, B + (0 === R ? 0 : w) + na.width), M += wa + na.height), S++; 0 < R && (c ? (A += Math.max(0, k), E < y && Du(b, l, y - E, 0, J, g - 1), 0 < v && (n || Gu(b, v, 0, 0, g - 1), y += v)) : (y += Math.max(0, k), M < A && Du(b, l, 0, A - M, J, g - 1), 0 < v && (n || Gu(b, 0, v, 0, g - 1), A += v))); v = n = 0; switch (l) {
                        case Iu: c ? n += y / 2 - b.focus.x - q / 2 : v += A / 2 - b.focus.y - q / 2; break; case Ju: 0 < R ? c ? n += y / 2 - b.focus.x - q / 2 : v += A / 2 - b.focus.y - q / 2 : c ? (a = e[0].S.x + e[0].la.x, n += a + (e[g - 1].S.x + e[g - 1].la.x + 2 * e[g - 1].focus.x - a) / 2 - b.focus.x -
                            q / 2) : (a = e[0].S.y + e[0].la.y, v += a + (e[g - 1].S.y + e[g - 1].la.y + 2 * e[g - 1].focus.y - a) / 2 - b.focus.y - q / 2); break; case yu: c ? (n -= q, y += q) : (v -= q, A += q); break; case zu: c ? (n += y - b.width + q, y += q) : (v += A - b.height + q, A += q); break; case nu: case du: c ? 1 < g ? n += G + r / 2 - b.focus.x : n += e[0].focus.x - b.focus.x + e[0].la.x : 1 < g ? v += G + r / 2 - b.focus.y : v += e[0].focus.y - b.focus.y + e[0].la.y; break; case qu: c ? n += y + r / 2 - b.focus.x : v += A + r / 2 - b.focus.y; break; case ru: break; default: a = a.customAlignment(b, n, v, y, A), n = a[0], v = a[1], y = a[2], A = a[3]
                    }for (a = 0; a < g; a++)q = e[a], c ?
                        q.S.h(q.S.x + q.la.x - n, q.S.y + (135 < d ? (h ? -A : -q.Da.height) + q.la.y - k : z + k + q.la.y)) : q.S.h(q.S.x + (135 < d ? (h ? -y : -q.Da.width) + q.la.x - k : m + k + q.la.x), q.S.y + q.la.y - v); c ? (y = Ku(b, y, n), 0 > n && (n = 0), 135 < d && (v += A + k), A = Math.max(Math.max(A, z), A + z + k)) : (135 < d && (n += y + k), y = Math.max(Math.max(y, m), y + m + k), A = Lu(b, A, v), 0 > v && (v = 0)); b.la.h(n, v); b.Da.h(y, A)
        }
    } Lt.prototype.customAlignment = function (a, b, c, d, e) { return [b, c, d, e] };
    function Au(a, b, c, d, e, f) {
        var g = c.length; if (0 === g) return new N(d, 0, e, f); if (1 === g) return b = c[0], e = b.Da.width, f = b.Da.height, new N(d, 0, e, f); for (var h = b.nodeSpacing, k = b.rowSpacing, l = 90 === xu(b), m = 0, n = 0, p = 0, r = 0; r < g; r++)if (!(0 !== r % 2 || 1 < g && r === g - 1)) {
            var q = c[r], u = q.Da, w = 0 === m ? 0 : k; if (l) { var v = a.computeBusNodeSpacing(q) - h; q.S.h(d - (u.width + v), p + w); e = Math.max(e, u.width + v); f = Math.max(f, p + w + u.height); p += w + u.height } else v = a.computeBusNodeSpacing(q) - h, q.S.h(n + w, d - (u.height + v)), f = Math.max(f, u.height + v), e = Math.max(e,
                n + w + u.width), n += w + u.width; m++
        } m = 0; q = n; r = p; l ? (n = d + h, p = 0) : (n = 0, p = d + h); for (u = 0; u < g; u++)if (0 !== u % 2) { w = c[u]; v = w.Da; var z = 0 === m ? 0 : k; if (l) { var y = a.computeBusNodeSpacing(w) - h; w.S.h(n + y, p + z); e = Math.max(e, n + v.width + y); f = Math.max(f, p + z + v.height); p += z + v.height } else y = a.computeBusNodeSpacing(w) - h, w.S.h(n + z, p + y), e = Math.max(e, n + z + v.width), f = Math.max(f, p + v.height + y), n += z + v.width; m++ } 1 < g && 1 === g % 2 && (c = c[g - 1], g = c.Da, a = a.computeBusLastRowSpacing(c, l ? Math.max(Math.abs(r), Math.abs(p)) : Math.max(Math.abs(q), Math.abs(n))),
            l ? (c.S.h(d + h / 2 - c.focus.x - c.la.x, f + a), l = d + h / 2 - c.focus.x - c.la.x, e = Math.max(e, l + g.width), 0 > l && (e -= l), f = Math.max(f, Math.max(r, p) + a + g.height), 0 > c.S.x && (d = Ou(b, c.S.x, !1, d, h))) : (c.S.h(e + a, d + h / 2 - c.focus.y - c.la.y), e = Math.max(e, Math.max(q, n) + a + g.width), p = d + h / 2 - c.focus.y - c.la.y, f = Math.max(f, p + g.height), 0 > p && (f -= p), 0 > c.S.y && (d = Ou(b, c.S.y, !0, d, h)))); return new N(d, 0, e, f)
    }
    function Bu(a, b, c, d, e, f) {
        var g = c.length; if (0 === g) return new N(d, 0, e, f); if (1 === g) return c = c[0], e = c.Da.width, f = c.Da.height, new N(d, 0, e, f); for (var h = b.nodeSpacing, k = b.rowSpacing, l = 270 === xu(b), m = 0, n = 0, p = 0, r = 0; r < g; r++)if (!(0 !== r % 2 || 1 < g && r === g - 1)) {
            var q = c[r], u = q.Da, w = 0 === m ? 0 : k; if (l) { var v = a.computeBusNodeSpacing(q) - h; p -= w + u.height; q.S.h(d - (u.width + v), p); e = Math.max(e, u.width + v); f = Math.max(f, Math.abs(p)) } else v = a.computeBusNodeSpacing(q) - h, n -= w + u.width, q.S.h(n, d - (u.height + v)), f = Math.max(f, u.height + v), e = Math.max(e,
                Math.abs(n)); m++
        } m = 0; q = n; r = p; l ? (n = d + h, p = 0) : (n = 0, p = d + h); for (u = 0; u < g; u++)if (0 !== u % 2) { w = c[u]; v = w.Da; var z = 0 === m ? 0 : k; if (l) { var y = a.computeBusNodeSpacing(w) - h; p -= z + v.height; w.S.h(n + y, p); e = Math.max(e, n + v.width + y); f = Math.max(f, Math.abs(p)) } else y = a.computeBusNodeSpacing(w) - h, n -= z + v.width, w.S.h(n, p + y), f = Math.max(f, p + v.height + y), e = Math.max(e, Math.abs(n)); m++ } 1 < g && 1 === g % 2 && (k = c[g - 1], m = k.Da, a = a.computeBusLastRowSpacing(k, l ? Math.max(Math.abs(r), Math.abs(p)) : Math.max(Math.abs(q), Math.abs(n))), l ? (k.S.h(d + h / 2 - k.focus.x -
            k.la.x, -f - m.height - a), n = d + h / 2 - k.focus.x - k.la.x, e = Math.max(e, n + m.width), 0 > n && (e -= n), f = Math.max(f, Math.abs(Math.min(r, p)) + a + m.height), 0 > k.S.x && (d = Ou(b, k.S.x, !1, d, h))) : (k.S.h(-e - m.width - a, d + h / 2 - k.focus.y - k.la.y), e = Math.max(e, Math.abs(Math.min(q, n)) + a + m.width), p = d + h / 2 - k.focus.y - k.la.y, f = Math.max(f, p + m.height), 0 > p && (f -= p), 0 > k.S.y && (d = Ou(b, k.S.y, !0, d, h)))); for (b = 0; b < g; b++)h = c[b], l ? h.S.h(h.S.x, h.S.y + f) : h.S.h(h.S.x + e, h.S.y); return new N(d, 0, e, f)
    }
    Lt.prototype.computeBusNodeSpacing = function (a) { return null === a.parent ? 0 : a.parent.nodeSpacing }; Lt.prototype.computeBusLastRowSpacing = function (a) { return null === a.parent ? 0 : a.parent.rowSpacing }; function Ou(a, b, c, d, e) { a = a.children; for (var f = a.length, g = 0; g < f; g++)c ? a[g].S.h(a[g].S.x, a[g].S.y - b) : a[g].S.h(a[g].S.x - b, a[g].S.y); b = a[f - 1]; return Math.max(d, c ? b.la.y + b.focus.y - e / 2 : b.la.x + b.focus.x - e / 2) }
    function Ku(a, b, c) { switch (a.alignment) { case Ju: case Iu: return c + a.width > b && (b = c + a.width), 0 > c && (b -= c), b; case yu: return a.width > b ? a.width : b; case zu: return 2 * a.focus.x > b ? a.width : b + a.width - 2 * a.focus.x; case nu: case du: return Math.max(a.width, Math.max(b, c + a.width) - Math.min(0, c)); case qu: return a.width - a.focus.x + a.nodeSpacing / 2 + b; case ru: return Math.max(a.width, a.focus.x + a.nodeSpacing / 2 + b); default: return b } }
    function Lu(a, b, c) { switch (a.alignment) { case Ju: case Iu: return c + a.height > b && (b = c + a.height), 0 > c && (b -= c), b; case yu: return a.height > b ? a.height : b; case zu: return 2 * a.focus.y > b ? a.height : b + a.height - 2 * a.focus.y; case nu: case du: return Math.max(a.height, Math.max(b, c + a.height) - Math.min(0, c)); case qu: return a.height - a.focus.y + a.nodeSpacing / 2 + b; case ru: return Math.max(a.height, a.focus.y + a.nodeSpacing / 2 + b); default: return b } }
    function Mu(a, b, c) { switch (a) { case Iu: b /= 2; c /= 2; break; case Ju: b /= 2; c /= 2; break; case yu: c = b = 0; break; case zu: break; default: C("Unhandled alignment value " + a.toString()) }return new I(b, c) } function Du(a, b, c, d, e, f) { b = Mu(b, c, d); Gu(a, b.x, b.y, e, f) } function Gu(a, b, c, d, e) { if (0 !== b || 0 !== c) for (a = a.children; d <= e; d++) { var f = a[d].S; f.x += b; f.y += c } }
    function Eu(a, b, c, d) { var e = b.parent; switch (a.od) { case Nt: for (a = b.sourceEdges; a.next();)b = a.value, b.fromVertex === e && b.relativePoint.h(c, d); break; case Ut: for (a = b.destinationEdges; a.next();)b = a.value, b.toVertex === e && b.relativePoint.h(c, d); break; default: C("Unhandled path value " + a.od.toString()) } } function Hu(a, b, c) { for (var d = 0; d < a.length; d++) { var e = a[d]; e.x += b; e.y += c } }
    function Fu(a, b, c, d, e, f, g, h) {
        var k = xu(b), l = 90 === k || 270 === k, m = b.nodeSpacing; b = d; var n = e; d = f; var p = g, r = c.So, q = c.ep; g = c.Da; var u = l ? Math.max(p, g.height) : Math.max(d, g.width); if (null === r || k !== xu(c)) r = Cu(a, 2), q = Cu(a, 2), l ? (r[0].h(0, 0), r[1].h(0, g.height), q[0].h(g.width, 0), q[1].h(q[0].x, r[1].y)) : (r[0].h(0, 0), r[1].h(g.width, 0), q[0].h(0, g.height), q[1].h(r[1].x, q[0].y)); if (l) {
            p = 9999999; if (!(null === n || 2 > n.length || null === r || 2 > r.length)) for (e = c = 0; c < n.length && e < r.length;) {
                f = n[c]; var w = r[e]; k = w.x; l = w.y; k += d; var v =
                    f; c + 1 < n.length && (v = n[c + 1]); var z = w; w = z.x; z = z.y; e + 1 < r.length && (z = r[e + 1], w = z.x, z = z.y, w += d); var y = p; f.y === l ? y = k - f.x : f.y > l && f.y < z ? y = k + (f.y - l) / (z - l) * (w - k) - f.x : l > f.y && l < v.y && (y = k - (f.x + (l - f.y) / (v.y - f.y) * (v.x - f.x))); y < p && (p = y); v.y <= f.y ? c++ : z <= l ? e++ : (v.y <= z && c++, z <= v.y && e++)
            } p = d - p; p += m; c = r; e = p; if (null === b || 2 > b.length || null === c || 2 > c.length) d = null; else {
                m = Cu(a, b.length + c.length); for (d = f = k = 0; f < c.length && c[f].y < b[0].y;)l = c[f++], m[d++].h(l.x + e, l.y); for (; k < b.length;)l = b[k++], m[d++].h(l.x, l.y); for (k = b[b.length - 1].y; f <
                    c.length && c[f].y <= k;)f++; for (; f < c.length && c[f].y > k;)l = c[f++], m[d++].h(l.x + e, l.y); c = Cu(a, d); for (k = 0; k < d; k++)c[k].assign(m[k]); Nu(a, m); d = c
            } f = q; k = p; if (null === n || 2 > n.length || null === f || 2 > f.length) e = null; else { m = Cu(a, n.length + f.length); for (e = l = c = 0; c < n.length && n[c].y < f[0].y;)v = n[c++], m[e++].h(v.x, v.y); for (; l < f.length;)v = f[l++], m[e++].h(v.x + k, v.y); for (f = f[f.length - 1].y; c < n.length && n[c].y <= f;)c++; for (; c < n.length && n[c].y > f;)k = n[c++], m[e++].h(k.x, k.y); f = Cu(a, e); for (c = 0; c < e; c++)f[c].assign(m[c]); Nu(a, m); e = f } f =
                Math.max(0, p) + g.width; g = u; Nu(a, b); Nu(a, r); Nu(a, n); Nu(a, q); h[0] = d; h[1] = e; return new N(p, 0, f, g)
        } d = 9999999; if (!(null === n || 2 > n.length || null === r || 2 > r.length)) for (e = c = 0; c < n.length && e < r.length;)f = n[c], w = r[e], k = w.x, l = w.y, l += p, v = f, c + 1 < n.length && (v = n[c + 1]), z = w, w = z.x, z = z.y, e + 1 < r.length && (z = r[e + 1], w = z.x, z = z.y, z += p), y = d, f.x === k ? y = l - f.y : f.x > k && f.x < w ? y = l + (f.x - k) / (w - k) * (z - l) - f.y : k > f.x && k < v.x && (y = l - (f.y + (k - f.x) / (v.x - f.x) * (v.y - f.y))), y < d && (d = y), v.x <= f.x ? c++ : w <= k ? e++ : (v.x <= w && c++, w <= v.x && e++); p -= d; p += m; c = r; e = p; if (null ===
            b || 2 > b.length || null === c || 2 > c.length) d = null; else { m = Cu(a, b.length + c.length); for (d = f = k = 0; f < c.length && c[f].x < b[0].x;)l = c[f++], m[d++].h(l.x, l.y + e); for (; k < b.length;)l = b[k++], m[d++].h(l.x, l.y); for (k = b[b.length - 1].x; f < c.length && c[f].x <= k;)f++; for (; f < c.length && c[f].x > k;)l = c[f++], m[d++].h(l.x, l.y + e); c = Cu(a, d); for (k = 0; k < d; k++)c[k].assign(m[k]); Nu(a, m); d = c } f = q; k = p; if (null === n || 2 > n.length || null === f || 2 > f.length) e = null; else {
                m = Cu(a, n.length + f.length); for (e = l = c = 0; c < n.length && n[c].x < f[0].x;)v = n[c++], m[e++].h(v.x, v.y);
                for (; l < f.length;)v = f[l++], m[e++].h(v.x, v.y + k); for (f = f[f.length - 1].x; c < n.length && n[c].x <= f;)c++; for (; c < n.length && n[c].x > f;)k = n[c++], m[e++].h(k.x, k.y); f = Cu(a, e); for (c = 0; c < e; c++)f[c].assign(m[c]); Nu(a, m); e = f
            } f = u; g = Math.max(0, p) + g.height; Nu(a, b); Nu(a, r); Nu(a, n); Nu(a, q); h[0] = d; h[1] = e; return new N(p, 0, f, g)
    } function Cu(a, b) { a = a.mu[b]; if (void 0 !== a && (a = a.pop(), void 0 !== a)) return a; a = []; for (var c = 0; c < b; c++)a[c] = new I; return a }
    function Nu(a, b) { var c = b.length, d = a.mu[c]; void 0 === d && (d = [], a.mu[c] = d); d.push(b) }
    Lt.prototype.arrangeTrees = function () {
        if (this.Mb === Tt) for (var a = this.Qb.iterator; a.next();) { var b = a.value; if (b instanceof St) { var c = b.node; if (null !== c) { var d = c.position; c = d.x; d = d.y; isFinite(c) || (c = 0); isFinite(d) || (d = 0); Pu(this, b, c, d) } } } else {
            a = []; for (b = this.Qb.iterator; b.next();)c = b.value, c instanceof St && a.push(c); switch (this.sorting) { case iu: break; case ju: a.reverse(); break; case ku: a.sort(this.comparer); break; case lu: a.sort(this.comparer); a.reverse(); break; default: C("Unhandled sorting value " + this.sorting.toString()) }c =
                this.arrangementOrigin; b = c.x; c = c.y; for (d = 0; d < a.length; d++) { var e = a[d]; Pu(this, e, b + e.la.x, c + e.la.y); switch (this.Mb) { case Qt: c += e.Da.height + this.Wd.height; break; case Qu: b += e.Da.width + this.Wd.width; break; default: C("Unhandled arrangement value " + this.Mb.toString()) } }
        }
    }; function Pu(a, b, c, d) { if (null !== b) { b.x = c; b.y = d; b = b.children; for (var e = b.length, f = 0; f < e; f++) { var g = b[f]; Pu(a, g, c + g.S.x, d + g.S.y) } } } Lt.prototype.commitLayout = function () { this.rv(); this.commitNodes(); this.Au(); this.isRouting && this.commitLinks() };
    Lt.prototype.commitNodes = function () { for (var a = this.network.vertexes.iterator; a.next();)a.value.commit(); for (a.reset(); a.next();)this.layoutComments(a.value) };
    Lt.prototype.Au = function () {
        if (this.layerStyle === au) {
            for (var a = this.Tv, b = [], c = null, d = this.network.vertexes.iterator; d.next();) { var e = d.value; null === c ? c = e.bounds.copy() : c.Zc(e.bounds); var f = b[e.level]; void 0 === f ? f = this.computeLayerSpacing(e) : f = Math.max(f, this.computeLayerSpacing(e)); b[e.level] = f } for (d = 0; d < b.length; d++)void 0 === b[d] && (b[d] = 0); 90 === this.angle || 270 === this.angle ? (c.yc(this.nodeSpacing / 2, this.layerSpacing), d = new I(-this.nodeSpacing / 2, -this.layerSpacing / 2)) : (c.yc(this.layerSpacing, this.nodeSpacing /
                2), d = new I(-this.layerSpacing / 2, -this.nodeSpacing / 2)); e = []; c = 90 === this.angle || 270 === this.angle ? c.width : c.height; f = 0; if (180 === this.angle || 270 === this.angle) for (var g = 0; g < a.length; g++)f += a[g] + b[g]; for (g = 0; g < a.length; g++) { var h = a[g] + b[g]; 270 === this.angle ? (f -= h, e.push(new N(0, f, c, h))) : 90 === this.angle ? (e.push(new N(0, f, c, h)), f += h) : 180 === this.angle ? (f -= h, e.push(new N(f, 0, h, c))) : (e.push(new N(f, 0, h, c)), f += h) } this.commitLayers(e, d)
        }
    }; Lt.prototype.commitLayers = function () { };
    Lt.prototype.commitLinks = function () { for (var a = this.network.edges.iterator; a.next();)a.value.commit() }; Lt.prototype.rv = function () { for (var a = this.Qb.iterator; a.next();) { var b = a.value; b instanceof St && Ru(this, b) } }; function Ru(a, b) { if (null !== b) { a.setPortSpots(b); b = b.children; for (var c = b.length, d = 0; d < c; d++)Ru(a, b[d]) } }
    Lt.prototype.setPortSpots = function (a) {
        var b = a.alignment; if (mu(b)) {
            var c = this.od === Nt, d = xu(a); switch (d) { case 0: var e = bd; break; case 90: e = cd; break; case 180: e = ad; break; default: e = $c }var f = a.children, g = f.length; switch (b) {
                case nu: case du: for (b = 0; b < g; b++) {
                    var h = f[b]; h = (c ? h.sourceEdges : h.destinationEdges).first(); if (null !== h && (h = h.link, null !== h)) {
                        var k = 90 === d || 270 === d ? ad : $c; if (1 === g || b === g - 1 && 1 === g % 2) switch (d) { case 0: k = ad; break; case 90: k = $c; break; case 180: k = bd; break; default: k = cd } else 0 === b % 2 && (k = 90 === d || 270 ===
                            d ? bd : cd); c ? (a.setsPortSpot && (h.fromSpot = e), a.setsChildPortSpot && (h.toSpot = k)) : (a.setsPortSpot && (h.fromSpot = k), a.setsChildPortSpot && (h.toSpot = e))
                    }
                } break; case qu: d = 90 === d || 270 === d ? bd : cd; for (f = c ? a.destinationEdges : a.sourceEdges; f.next();)g = f.value.link, null !== g && (c ? (a.setsPortSpot && (g.fromSpot = e), a.setsChildPortSpot && (g.toSpot = d)) : (a.setsPortSpot && (g.fromSpot = d), a.setsChildPortSpot && (g.toSpot = e))); break; case ru: for (d = 90 === d || 270 === d ? ad : $c, f = c ? a.destinationEdges : a.sourceEdges; f.next();)g = f.value.link,
                    null !== g && (c ? (a.setsPortSpot && (g.fromSpot = e), a.setsChildPortSpot && (g.toSpot = d)) : (a.setsPortSpot && (g.fromSpot = d), a.setsChildPortSpot && (g.toSpot = e)))
            }
        } else if (c = xu(a), this.od === Nt) for (e = a.destinationEdges; e.next();) {
            if (d = e.value.link, null !== d) {
                if (a.setsPortSpot) if (a.portSpot.Tb()) switch (c) { case 0: d.fromSpot = bd; break; case 90: d.fromSpot = cd; break; case 180: d.fromSpot = ad; break; default: d.fromSpot = $c } else d.fromSpot = a.portSpot; if (a.setsChildPortSpot) if (a.childPortSpot.Tb()) switch (c) {
                    case 0: d.toSpot = ad; break;
                    case 90: d.toSpot = $c; break; case 180: d.toSpot = bd; break; default: d.toSpot = cd
                } else d.toSpot = a.childPortSpot
            }
        } else for (e = a.sourceEdges; e.next();)if (d = e.value.link, null !== d) {
            if (a.setsPortSpot) if (a.portSpot.Tb()) switch (c) { case 0: d.toSpot = bd; break; case 90: d.toSpot = cd; break; case 180: d.toSpot = ad; break; default: d.toSpot = $c } else d.toSpot = a.portSpot; if (a.setsChildPortSpot) if (a.childPortSpot.Tb()) switch (c) { case 0: d.fromSpot = ad; break; case 90: d.fromSpot = $c; break; case 180: d.fromSpot = bd; break; default: d.fromSpot = cd } else d.fromSpot =
                a.childPortSpot
        }
    }; function xu(a) { a = a.angle; return 45 >= a ? 0 : 135 >= a ? 90 : 225 >= a ? 180 : 315 >= a ? 270 : 0 } Lt.prototype.computeLayerSpacing = function (a) { var b = xu(a); b = 90 === b || 270 === b; var c = a.layerSpacing; if (0 < a.layerSpacingParentOverlap) { var d = Math.min(1, a.layerSpacingParentOverlap); c -= b ? a.height * d : a.width * d } c < (b ? -a.height : -a.width) && (c = b ? -a.height : -a.width); return c };
    Lt.prototype.computeNodeIndent = function (a) { var b = xu(a), c = a.nodeIndent; if (0 < a.nodeIndentPastParent) { var d = Math.min(1, a.nodeIndentPastParent); c += 90 === b || 270 === b ? a.width * d : a.height * d } return c = Math.max(0, c) };
    ma.Object.defineProperties(Lt.prototype, {
        roots: { get: function () { return this.Qb }, set: function (a) { this.Qb !== a && (this.Qb = a, this.D()) } }, path: { get: function () { return this.Sn }, set: function (a) { this.Sn !== a && (this.Sn = a, this.D()) } }, treeStyle: { get: function () { return this.ro }, set: function (a) { this.Mb === a || a !== Ot && a !== gu && a !== hu && a !== fu || (this.ro = a, this.D()) } }, layerStyle: {
            get: function () { return this.uq },
            set: function (a) { this.Mb === a || a !== Pt && a !== bu && a !== au || (this.uq = a, this.D()) }
        }, comments: { get: function () { return this.md }, set: function (a) { this.md !== a && (this.md = a, this.D()) } }, arrangement: { get: function () { return this.Mb }, set: function (a) { this.Mb === a || a !== Qt && a !== Qu && a !== Tt || (this.Mb = a, this.D()) } }, arrangementSpacing: { get: function () { return this.Wd }, set: function (a) { this.Wd.C(a) || (this.Wd.assign(a), this.D()) } }, rootDefaults: {
            get: function () { return this.W }, set: function (a) { this.W !== a && (this.W = a, this.D()) }
        }, alternateDefaults: { get: function () { return this.Y }, set: function (a) { this.Y !== a && (this.Y = a, this.D()) } }, sorting: { get: function () { return this.W.sorting }, set: function (a) { this.W.sorting === a || a !== iu && a !== ju && a !== ku && !lu || (this.W.sorting = a, this.D()) } }, comparer: {
            get: function () { return this.W.comparer }, set: function (a) {
                this.W.comparer !==
                a && (this.W.comparer = a, this.D())
            }
        }, angle: { get: function () { return this.W.angle }, set: function (a) { this.W.angle !== a && (0 === a || 90 === a || 180 === a || 270 === a ? (this.W.angle = a, this.D()) : C("TreeLayout.angle must be 0, 90, 180, or 270")) } }, alignment: { get: function () { return this.W.alignment }, set: function (a) { this.W.alignment !== a && (this.W.alignment = a, this.D()) } }, nodeIndent: {
            get: function () { return this.W.nodeIndent }, set: function (a) {
                this.W.nodeIndent !==
                a && 0 <= a && (this.W.nodeIndent = a, this.D())
            }
        }, nodeIndentPastParent: { get: function () { return this.W.nodeIndentPastParent }, set: function (a) { this.W.nodeIndentPastParent !== a && 0 <= a && 1 >= a && (this.W.nodeIndentPastParent = a, this.D()) } }, nodeSpacing: { get: function () { return this.W.nodeSpacing }, set: function (a) { this.W.nodeSpacing !== a && (this.W.nodeSpacing = a, this.D()) } }, layerSpacing: {
            get: function () { return this.W.layerSpacing }, set: function (a) {
                this.W.layerSpacing !==
                a && (this.W.layerSpacing = a, this.D())
            }
        }, layerSpacingParentOverlap: { get: function () { return this.W.layerSpacingParentOverlap }, set: function (a) { this.W.layerSpacingParentOverlap !== a && 0 <= a && 1 >= a && (this.W.layerSpacingParentOverlap = a, this.D()) } }, compaction: { get: function () { return this.W.compaction }, set: function (a) { this.W.compaction === a || a !== uu && a !== wu || (this.W.compaction = a, this.D()) } }, breadthLimit: {
            get: function () { return this.W.breadthLimit },
            set: function (a) { this.W.breadthLimit !== a && 0 <= a && (this.W.breadthLimit = a, this.D()) }
        }, rowSpacing: { get: function () { return this.W.rowSpacing }, set: function (a) { this.W.rowSpacing !== a && (this.W.rowSpacing = a, this.D()) } }, rowIndent: { get: function () { return this.W.rowIndent }, set: function (a) { this.W.rowIndent !== a && 0 <= a && (this.W.rowIndent = a, this.D()) } }, commentSpacing: {
            get: function () { return this.W.commentSpacing }, set: function (a) {
                this.W.commentSpacing !==
                a && (this.W.commentSpacing = a, this.D())
            }
        }, commentMargin: { get: function () { return this.W.commentMargin }, set: function (a) { this.W.commentMargin !== a && (this.W.commentMargin = a, this.D()) } }, setsPortSpot: { get: function () { return this.W.setsPortSpot }, set: function (a) { this.W.setsPortSpot !== a && (this.W.setsPortSpot = a, this.D()) } }, portSpot: {
            get: function () { return this.W.portSpot }, set: function (a) {
                this.W.portSpot.C(a) || (this.W.portSpot =
                    a, this.D())
            }
        }, setsChildPortSpot: { get: function () { return this.W.setsChildPortSpot }, set: function (a) { this.W.setsChildPortSpot !== a && (this.W.setsChildPortSpot = a, this.D()) } }, childPortSpot: { get: function () { return this.W.childPortSpot }, set: function (a) { this.W.childPortSpot.C(a) || (this.W.childPortSpot = a, this.D()) } }, alternateSorting: {
            get: function () { return this.Y.sorting }, set: function (a) {
                this.Y.sorting === a || a !== iu && a !== ju &&
                    a !== ku && !lu || (this.Y.sorting = a, this.D())
            }
        }, alternateComparer: { get: function () { return this.Y.comparer }, set: function (a) { this.Y.comparer !== a && (this.Y.comparer = a, this.D()) } }, alternateAngle: { get: function () { return this.Y.angle }, set: function (a) { this.Y.angle === a || 0 !== a && 90 !== a && 180 !== a && 270 !== a || (this.Y.angle = a, this.D()) } }, alternateAlignment: {
            get: function () { return this.Y.alignment }, set: function (a) {
                this.Y.alignment !==
                a && (this.Y.alignment = a, this.D())
            }
        }, alternateNodeIndent: { get: function () { return this.Y.nodeIndent }, set: function (a) { this.Y.nodeIndent !== a && 0 <= a && (this.Y.nodeIndent = a, this.D()) } }, alternateNodeIndentPastParent: { get: function () { return this.Y.nodeIndentPastParent }, set: function (a) { this.Y.nodeIndentPastParent !== a && 0 <= a && 1 >= a && (this.Y.nodeIndentPastParent = a, this.D()) } }, alternateNodeSpacing: {
            get: function () { return this.Y.nodeSpacing },
            set: function (a) { this.Y.nodeSpacing !== a && (this.Y.nodeSpacing = a, this.D()) }
        }, alternateLayerSpacing: { get: function () { return this.Y.layerSpacing }, set: function (a) { this.Y.layerSpacing !== a && (this.Y.layerSpacing = a, this.D()) } }, alternateLayerSpacingParentOverlap: { get: function () { return this.Y.layerSpacingParentOverlap }, set: function (a) { this.Y.layerSpacingParentOverlap !== a && 0 <= a && 1 >= a && (this.Y.layerSpacingParentOverlap = a, this.D()) } }, alternateCompaction: {
            get: function () { return this.Y.compaction }, set: function (a) { this.Y.compaction === a || a !== uu && a !== wu || (this.Y.compaction = a, this.D()) }
        }, alternateBreadthLimit: { get: function () { return this.Y.breadthLimit }, set: function (a) { this.Y.breadthLimit !== a && 0 <= a && (this.Y.breadthLimit = a, this.D()) } }, alternateRowSpacing: { get: function () { return this.Y.rowSpacing }, set: function (a) { this.Y.rowSpacing !== a && (this.Y.rowSpacing = a, this.D()) } }, alternateRowIndent: {
            get: function () { return this.Y.rowIndent }, set: function (a) { this.Y.rowIndent !== a && 0 <= a && (this.Y.rowIndent = a, this.D()) }
        }, alternateCommentSpacing: { get: function () { return this.Y.commentSpacing }, set: function (a) { this.Y.commentSpacing !== a && (this.Y.commentSpacing = a, this.D()) } }, alternateCommentMargin: { get: function () { return this.Y.commentMargin }, set: function (a) { this.Y.commentMargin !== a && (this.Y.commentMargin = a, this.D()) } }, alternateSetsPortSpot: {
            get: function () { return this.Y.setsPortSpot }, set: function (a) { this.Y.setsPortSpot !== a && (this.Y.setsPortSpot = a, this.D()) }
        }, alternatePortSpot: { get: function () { return this.Y.portSpot }, set: function (a) { this.Y.portSpot.C(a) || (this.Y.portSpot = a, this.D()) } }, alternateSetsChildPortSpot: { get: function () { return this.Y.setsChildPortSpot }, set: function (a) { this.Y.setsChildPortSpot !== a && (this.Y.setsChildPortSpot = a, this.D()) } }, alternateChildPortSpot: {
            get: function () { return this.Y.childPortSpot }, set: function (a) { this.Y.childPortSpot.C(a) || (this.Y.childPortSpot = a, this.D()) }
        }
    });
    var Mt = new D(Lt, "PathDefault", -1), Nt = new D(Lt, "PathDestination", 0), Ut = new D(Lt, "PathSource", 1), iu = new D(Lt, "SortingForwards", 10), ju = new D(Lt, "SortingReverse", 11), ku = new D(Lt, "SortingAscending", 12), lu = new D(Lt, "SortingDescending", 13), Iu = new D(Lt, "AlignmentCenterSubtrees", 20), Ju = new D(Lt, "AlignmentCenterChildren", 21), yu = new D(Lt, "AlignmentStart", 22), zu = new D(Lt, "AlignmentEnd", 23), nu = new D(Lt, "AlignmentBus", 24), du = new D(Lt, "AlignmentBusBranching", 25), qu = new D(Lt, "AlignmentTopLeftBus", 26), ru = new D(Lt,
        "AlignmentBottomRightBus", 27), uu = new D(Lt, "CompactionNone", 30), wu = new D(Lt, "CompactionBlock", 31), Ot = new D(Lt, "StyleLayered", 40), hu = new D(Lt, "StyleLastParents", 41), gu = new D(Lt, "StyleAlternating", 42), fu = new D(Lt, "StyleRootOnly", 43), Qt = new D(Lt, "ArrangementVertical", 50), Qu = new D(Lt, "ArrangementHorizontal", 51), Tt = new D(Lt, "ArrangementFixedRoots", 52), Pt = new D(Lt, "LayerIndividual", 60), bu = new D(Lt, "LayerSiblings", 61), au = new D(Lt, "LayerUniform", 62); Lt.className = "TreeLayout"; Lt.PathDefault = Mt;
    Lt.PathDestination = Nt; Lt.PathSource = Ut; Lt.SortingForwards = iu; Lt.SortingReverse = ju; Lt.SortingAscending = ku; Lt.SortingDescending = lu; Lt.AlignmentCenterSubtrees = Iu; Lt.AlignmentCenterChildren = Ju; Lt.AlignmentStart = yu; Lt.AlignmentEnd = zu; Lt.AlignmentBus = nu; Lt.AlignmentBusBranching = du; Lt.AlignmentTopLeftBus = qu; Lt.AlignmentBottomRightBus = ru; Lt.CompactionNone = uu; Lt.CompactionBlock = wu; Lt.StyleLayered = Ot; Lt.StyleLastParents = hu; Lt.StyleAlternating = gu; Lt.StyleRootOnly = fu; Lt.ArrangementVertical = Qt;
    Lt.ArrangementHorizontal = Qu; Lt.ArrangementFixedRoots = Tt; Lt.LayerIndividual = Pt; Lt.LayerSiblings = bu; Lt.LayerUniform = au; function Rt(a) { rq.call(this, a) } la(Rt, rq); Rt.prototype.createVertex = function () { return new St(this) }; Rt.prototype.createEdge = function () { return new Su(this) }; Rt.className = "TreeNetwork";
    function St(a) { uq.call(this, a); this.V = !1; this.hb = null; this.u = []; this.ob = this.Ha = this.F = this.ga = 0; this.md = null; this.S = new I(0, 0); this.Da = new L(0, 0); this.la = new I(0, 0); this.fm = this.em = this.gA = !1; this.ep = this.So = null; this.ed = iu; this.$c = Bq; this.Ib = 0; this.Hb = Ju; this.Oq = this.Nq = 0; this.Pq = 20; this.ee = 50; this.tq = 0; this.Bp = wu; this.tp = 0; this.gr = 25; this.Ap = this.fr = 10; this.zp = 20; this.pr = !0; this.Xq = Sc; this.nr = !0; this.xp = Sc } la(St, uq);
    St.prototype.copyInheritedPropertiesFrom = function (a) { null !== a && (this.ed = a.sorting, this.$c = a.comparer, this.Ib = a.angle, this.Hb = a.alignment, this.Nq = a.nodeIndent, this.Oq = a.nodeIndentPastParent, this.Pq = a.nodeSpacing, this.ee = a.layerSpacing, this.tq = a.layerSpacingParentOverlap, this.Bp = a.compaction, this.tp = a.breadthLimit, this.gr = a.rowSpacing, this.fr = a.rowIndent, this.Ap = a.commentSpacing, this.zp = a.commentMargin, this.pr = a.setsPortSpot, this.Xq = a.portSpot, this.nr = a.setsChildPortSpot, this.xp = a.childPortSpot) };
    ma.Object.defineProperties(St.prototype, {
        initialized: { get: function () { return this.V }, set: function (a) { this.V !== a && (this.V = a) } }, parent: { get: function () { return this.hb }, set: function (a) { this.hb !== a && (this.hb = a) } }, children: { get: function () { return this.u }, set: function (a) { if (this.u !== a) { if (null !== a) for (var b = a.length, c = 0; c < b; c++); this.u = a } } }, level: {
            get: function () { return this.ga }, set: function (a) {
                this.ga !==
                a && (this.ga = a)
            }
        }, descendantCount: { get: function () { return this.F }, set: function (a) { this.F !== a && (this.F = a) } }, maxChildrenCount: { get: function () { return this.Ha }, set: function (a) { this.Ha !== a && (this.Ha = a) } }, maxGenerationCount: { get: function () { return this.ob }, set: function (a) { this.ob !== a && (this.ob = a) } }, comments: {
            get: function () { return this.md }, set: function (a) {
                if (this.md !== a) {
                    if (null !== a) for (var b =
                        a.length, c = 0; c < b; c++); this.md = a
                }
            }
        }, sorting: { get: function () { return this.ed }, set: function (a) { this.ed !== a && (this.ed = a) } }, comparer: { get: function () { return this.$c }, set: function (a) { this.$c !== a && (this.$c = a) } }, angle: { get: function () { return this.Ib }, set: function (a) { this.Ib !== a && (this.Ib = a) } }, alignment: { get: function () { return this.Hb }, set: function (a) { this.Hb !== a && (this.Hb = a) } }, nodeIndent: {
            get: function () { return this.Nq }, set: function (a) { this.Nq !== a && (this.Nq = a) }
        }, nodeIndentPastParent: { get: function () { return this.Oq }, set: function (a) { this.Oq !== a && (this.Oq = a) } }, nodeSpacing: { get: function () { return this.Pq }, set: function (a) { this.Pq !== a && (this.Pq = a) } }, layerSpacing: { get: function () { return this.ee }, set: function (a) { this.ee !== a && (this.ee = a) } }, layerSpacingParentOverlap: {
            get: function () { return this.tq }, set: function (a) { this.tq !== a && (this.tq = a) }
        }, compaction: { get: function () { return this.Bp }, set: function (a) { this.Bp !== a && (this.Bp = a) } }, breadthLimit: { get: function () { return this.tp }, set: function (a) { this.tp !== a && (this.tp = a) } }, rowSpacing: { get: function () { return this.gr }, set: function (a) { this.gr !== a && (this.gr = a) } }, rowIndent: {
            get: function () { return this.fr }, set: function (a) {
                this.fr !==
                a && (this.fr = a)
            }
        }, commentSpacing: { get: function () { return this.Ap }, set: function (a) { this.Ap !== a && (this.Ap = a) } }, commentMargin: { get: function () { return this.zp }, set: function (a) { this.zp !== a && (this.zp = a) } }, setsPortSpot: { get: function () { return this.pr }, set: function (a) { this.pr !== a && (this.pr = a) } }, portSpot: { get: function () { return this.Xq }, set: function (a) { this.Xq.C(a) || (this.Xq = a) } }, setsChildPortSpot: {
            get: function () { return this.nr }, set: function (a) { this.nr !== a && (this.nr = a) }
        }, childPortSpot: { get: function () { return this.xp }, set: function (a) { this.xp.C(a) || (this.xp = a) } }, childrenCount: { get: function () { return this.children.length } }, relativePosition: { get: function () { return this.S }, set: function (a) { this.S.set(a) } }, subtreeSize: { get: function () { return this.Da }, set: function (a) { this.Da.set(a) } },
        subtreeOffset: { get: function () { return this.la }, set: function (a) { this.la.set(a) } }
    }); St.className = "TreeVertex"; function Su(a) { vq.call(this, a); this.Vt = new I(0, 0) } la(Su, vq);
    Su.prototype.commit = function () {
        var a = this.link; if (null !== a && !a.isAvoiding) {
            var b = this.network.layout, c = null, d = null; switch (b.od) { case Nt: c = this.fromVertex; d = this.toVertex; break; case Ut: c = this.toVertex; d = this.fromVertex; break; default: C("Unhandled path value " + b.od.toString()) }if (null !== c && null !== d) {
                var e = this.Vt; if (0 !== e.x || 0 !== e.y || c.gA) {
                    d = c.bounds; var f = xu(c), g = b.computeLayerSpacing(c), h = c.rowSpacing; a.rg(); var k = a.curve === bg, l = a.isOrthogonal, m; a.ph(); if (l || k) {
                        for (m = 2; 4 < a.pointsCount;)a.kv(2); var n =
                            a.i(1); var p = a.i(2)
                    } else { for (m = 1; 3 < a.pointsCount;)a.kv(1); n = a.i(0); p = a.i(a.pointsCount - 1) } var r = a.i(a.pointsCount - 1); 0 === f ? (c.alignment === zu ? (f = d.bottom + e.y, 0 === e.y && n.y > r.y + c.rowIndent && (f = Math.min(f, Math.max(n.y, f - b.computeNodeIndent(c))))) : c.alignment === yu ? (f = d.top + e.y, 0 === e.y && n.y < r.y - c.rowIndent && (f = Math.max(f, Math.min(n.y, f + b.computeNodeIndent(c))))) : f = c.em || c.fm && 1 === c.maxGenerationCount ? d.top - c.la.y + e.y : d.y + d.height / 2 + e.y, k ? (a.l(m, n.x, f), m++, a.l(m, d.right + g, f), m++, a.l(m, d.right + g + (e.x - h) /
                        3, f), m++, a.l(m, d.right + g + 2 * (e.x - h) / 3, f), m++, a.l(m, d.right + g + (e.x - h), f), m++, a.l(m, p.x, f)) : (l && (a.l(m, d.right + g / 2, n.y), m++), a.l(m, d.right + g / 2, f), m++, a.l(m, d.right + g + e.x - (l ? h / 2 : h), f), m++, l && a.l(m, a.i(m - 1).x, p.y))) : 90 === f ? (c.alignment === zu ? (f = d.right + e.x, 0 === e.x && n.x > r.x + c.rowIndent && (f = Math.min(f, Math.max(n.x, f - b.computeNodeIndent(c))))) : c.alignment === yu ? (f = d.left + e.x, 0 === e.x && n.x < r.x - c.rowIndent && (f = Math.max(f, Math.min(n.x, f + b.computeNodeIndent(c))))) : f = c.em || c.fm && 1 === c.maxGenerationCount ? d.left - c.la.x +
                            e.x : d.x + d.width / 2 + e.x, k ? (a.l(m, f, n.y), m++, a.l(m, f, d.bottom + g), m++, a.l(m, f, d.bottom + g + (e.y - h) / 3), m++, a.l(m, f, d.bottom + g + 2 * (e.y - h) / 3), m++, a.l(m, f, d.bottom + g + (e.y - h)), m++, a.l(m, f, p.y)) : (l && (a.l(m, n.x, d.bottom + g / 2), m++), a.l(m, f, d.bottom + g / 2), m++, a.l(m, f, d.bottom + g + e.y - (l ? h / 2 : h)), m++, l && a.l(m, p.x, a.i(m - 1).y))) : 180 === f ? (c.alignment === zu ? (f = d.bottom + e.y, 0 === e.y && n.y > r.y + c.rowIndent && (f = Math.min(f, Math.max(n.y, f - b.computeNodeIndent(c))))) : c.alignment === yu ? (f = d.top + e.y, 0 === e.y && n.y < r.y - c.rowIndent && (f = Math.max(f,
                                Math.min(n.y, f + b.computeNodeIndent(c))))) : f = c.em || c.fm && 1 === c.maxGenerationCount ? d.top - c.la.y + e.y : d.y + d.height / 2 + e.y, k ? (a.l(m, n.x, f), m++, a.l(m, d.left - g, f), m++, a.l(m, d.left - g + (e.x + h) / 3, f), m++, a.l(m, d.left - g + 2 * (e.x + h) / 3, f), m++, a.l(m, d.left - g + (e.x + h), f), m++, a.l(m, p.x, f)) : (l && (a.l(m, d.left - g / 2, n.y), m++), a.l(m, d.left - g / 2, f), m++, a.l(m, d.left - g + e.x + (l ? h / 2 : h), f), m++, l && a.l(m, a.i(m - 1).x, p.y))) : 270 === f ? (c.alignment === zu ? (f = d.right + e.x, 0 === e.x && n.x > r.x + c.rowIndent && (f = Math.min(f, Math.max(n.x, f - b.computeNodeIndent(c))))) :
                                    c.alignment === yu ? (f = d.left + e.x, 0 === e.x && n.x < r.x - c.rowIndent && (f = Math.max(f, Math.min(n.x, f + b.computeNodeIndent(c))))) : f = c.em || c.fm && 1 === c.maxGenerationCount ? d.left - c.la.x + e.x : d.x + d.width / 2 + e.x, k ? (a.l(m, f, n.y), m++, a.l(m, f, d.top - g), m++, a.l(m, f, d.top - g + (e.y + h) / 3), m++, a.l(m, f, d.top - g + 2 * (e.y + h) / 3), m++, a.l(m, f, d.top - g + (e.y + h)), m++, a.l(m, f, p.y)) : (l && (a.l(m, n.x, d.top - g / 2), m++), a.l(m, f, d.top - g / 2), m++, a.l(m, f, d.top - g + e.y + (l ? h / 2 : h)), m++, l && a.l(m, p.x, a.i(m - 1).y))) : C("Invalid angle " + f); a.wf()
                } else e = d, a = this.link,
                    d = this.network.layout, b = xu(c), b !== xu(e) && (g = d.computeLayerSpacing(c), h = c.bounds, c = e.bounds, 0 === b && c.left - h.right < g + 1 || 90 === b && c.top - h.bottom < g + 1 || 180 === b && h.left - c.right < g + 1 || 270 === b && h.top - c.bottom < g + 1 || (a.rg(), c = a.curve === bg, e = a.isOrthogonal, d = mu(this.fromVertex.alignment), a.ph(), 0 === b ? (b = h.right + g / 2, c ? 4 === a.pointsCount && (c = a.i(3).y, a.M(1, b - 20, a.i(1).y), a.l(2, b - 20, c), a.l(3, b, c), a.l(4, b + 20, c), a.M(5, a.i(5).x, c)) : e ? d ? a.M(3, a.i(2).x, a.i(4).y) : 6 === a.pointsCount && (a.M(2, b, a.i(2).y), a.M(3, b, a.i(3).y)) :
                        4 === a.pointsCount ? a.l(2, b, a.i(2).y) : 3 === a.pointsCount ? a.M(1, b, a.i(2).y) : 2 === a.pointsCount && a.l(1, b, a.i(1).y)) : 90 === b ? (b = h.bottom + g / 2, c ? 4 === a.pointsCount && (c = a.i(3).x, a.M(1, a.i(1).x, b - 20), a.l(2, c, b - 20), a.l(3, c, b), a.l(4, c, b + 20), a.M(5, c, a.i(5).y)) : e ? d ? a.M(3, a.i(2).x, a.i(4).y) : 6 === a.pointsCount && (a.M(2, a.i(2).x, b), a.M(3, a.i(3).x, b)) : 4 === a.pointsCount ? a.l(2, a.i(2).x, b) : 3 === a.pointsCount ? a.M(1, a.i(2).x, b) : 2 === a.pointsCount && a.l(1, a.i(1).x, b)) : 180 === b ? (b = h.left - g / 2, c ? 4 === a.pointsCount && (c = a.i(3).y, a.M(1,
                            b + 20, a.i(1).y), a.l(2, b + 20, c), a.l(3, b, c), a.l(4, b - 20, c), a.M(5, a.i(5).x, c)) : e ? d ? a.M(3, a.i(2).x, a.i(4).y) : 6 === a.pointsCount && (a.M(2, b, a.i(2).y), a.M(3, b, a.i(3).y)) : 4 === a.pointsCount ? a.l(2, b, a.i(2).y) : 3 === a.pointsCount ? a.M(1, b, a.i(2).y) : 2 === a.pointsCount && a.l(1, b, a.i(1).y)) : 270 === b && (b = h.top - g / 2, c ? 4 === a.pointsCount && (c = a.i(3).x, a.M(1, a.i(1).x, b + 20), a.l(2, c, b + 20), a.l(3, c, b), a.l(4, c, b - 20), a.M(5, c, a.i(5).y)) : e ? d ? a.M(3, a.i(2).x, a.i(4).y) : 6 === a.pointsCount && (a.M(2, a.i(2).x, b), a.M(3, a.i(3).x, b)) : 4 === a.pointsCount ?
                                a.l(2, a.i(2).x, b) : 3 === a.pointsCount ? a.M(1, a.i(2).x, b) : 2 === a.pointsCount && a.l(1, a.i(1).x, b)), a.wf()))
            }
        }
    }; ma.Object.defineProperties(Su.prototype, { fromVertex: { get: function () { return this.bd }, set: function (a) { this.bd !== a && (this.bd = a) } }, toVertex: { get: function () { return this.hd }, set: function (a) { this.hd !== a && (this.hd = a) } }, relativePoint: { get: function () { return this.Vt }, set: function (a) { this.Vt.set(a) } } }); Su.className = "TreeEdge";
    La.prototype.initializeStandardTools = function () {
        this.gb("Action", new pg, this.mouseDownTools); this.gb("Relinking", new Ie, this.mouseDownTools); this.gb("LinkReshaping", new $f, this.mouseDownTools); this.gb("Rotating", new ng, this.mouseDownTools); this.gb("Resizing", new gg, this.mouseDownTools); this.gb("Linking", new Tf, this.mouseMoveTools); this.gb("Dragging", new Ae, this.mouseMoveTools); this.gb("DragSelecting", new Ag, this.mouseMoveTools); this.gb("Panning", new Bg, this.mouseMoveTools); this.gb("ContextMenu", new Dg,
            this.mouseUpTools); this.gb("TextEditing", new Og, this.mouseUpTools); this.gb("ClickCreating", new qg, this.mouseUpTools); this.gb("ClickSelecting", new og, this.mouseUpTools)
    }; En("Horizontal", new Gm); En("Spot", new Im); En("Table", new Nm); En("Viewbox", new Sm); En("TableRow", new Qm); En("TableColumn", new Rm); En("Graduated", new cn); En("Grid", new Tm); mi.add("GraphLinksModel", Uq); mi.add("TreeModel", hr);
    var Tu = x.go, Uu = {
        get licenseKey() { return Q.licenseKey }, set licenseKey(a) { Q.licenseKey = a }, get version() { return Q.version }, Group: pf, EnumValue: D, List: F, Set: H, Map: ob, Point: I, Size: L, Rect: N, Margin: hc, Spot: O, Geometry: P, PathFigure: Wd, PathSegment: Xd, InputEvent: Zd, DiagramEvent: ae, ChangedEvent: be, Model: Z, GraphLinksModel: Cr, TreeModel: Hr, Binding: xi, Transaction: ge, UndoManager: he, CommandHandler: Rk, Tool: je, DraggingTool: Ae, DraggingInfo: Je, DraggingOptions: Be, LinkingBaseTool: Af, LinkingTool: Tf, RelinkingTool: Ie, LinkReshapingTool: $f,
        ResizingTool: gg, RotatingTool: ng, ClickSelectingTool: og, ActionTool: pg, ClickCreatingTool: qg, HTMLInfo: ye, ContextMenuTool: Dg, DragSelectingTool: Ag, PanningTool: Bg, TextEditingTool: Og, ToolManager: La, Animation: jh, AnimationManager: fh, AnimationTrigger: Yh, Layer: ci, Diagram: Q, Palette: Lk, Overview: Fk, Brush: pl, GraphObject: Y, Panel: W, RowColumnDefinition: Uj, Shape: Bf, TextBlock: Pg, Picture: ck, Part: U, Adornment: te, Node: V, Link: T, Placeholder: ig, Layout: ri, LayoutNetwork: rq, LayoutVertex: uq, LayoutEdge: vq, GridLayout: Nk, PanelLayout: Vl,
        CircularLayout: Ir, CircularNetwork: Zr, CircularVertex: ms, CircularEdge: ns, ForceDirectedLayout: os, ForceDirectedNetwork: ps, ForceDirectedVertex: Bs, ForceDirectedEdge: Cs, LayeredDigraphLayout: Ds, LayeredDigraphNetwork: Is, LayeredDigraphVertex: It, LayeredDigraphEdge: Jt, TreeLayout: Lt, TreeNetwork: Rt, TreeVertex: St, TreeEdge: Su
    }; "object" === typeof Tu && Tu.version && ya("WARNING: a `go` object on the root object is already defined.  " + ("Debug" in Tu ? "debug " : "") + "version: " + Tu.version + ", replaced with version: " + Uu.version);
    Q.prototype.go = Uu; x.go = Uu; 'undefined' !== typeof module && 'object' === typeof module.exports && (module.exports = 'undefined' !== typeof global ? global.go : self.go);
})();

"function" === typeof define && define.amd && define("undefined" !== typeof global ? global.go : self.go);
