import { Project } from './Project';

export class ContactCheckKeyword {
    id: number = 0;
    constructor(
        public contactId: number,
        public keyword: string = null
    ) {

    }
}

export class ProjectLinkCheck {
    contactLinkId: number;
    lastCheck: Date;
    actualLinkStatus: number[];
    httpStatus: number;
    anonymized: boolean;
    deIndexed: boolean;
}

export class ProjectLink {
    _checked: boolean = false;
    _id: number;
    // _redirectUrl: string = '';
    contentId: number = 0;
    customerId: number = 0;
    contactId: number = 0;
    customer: Project;
    insertDate: Date = new Date();
    autority: boolean = false;
    isWebFormRequest: boolean = false;
    successFee: number = 0;

    // Customer
    url: string = null;
    contract: boolean = false;
    injuryLevel: number = 0; // workability
    instanceReceiver: string = null;

    // Contact
    notes: string = null;
    domain: string = null;
    serpPosition: number = 0;
    publishingDate: string = null;

    // Instance
    status: number = 0;
    lastCheck: Date = null;
    ttdName: string = null; // TTD

    eventsNames: string[] = null;

    // Auto Check
    lastAutoCheck: ProjectLinkCheck = null;
    statusNotMatch: boolean = null;
    lastAutoCheck2: boolean = true;

    //Wft
    wftStatus: number = 0;
    wftDate: Date = null;
    wftResponse: string = null;

    constructor() { }
}
