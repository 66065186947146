// Core
import { DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Config } from '../../app.constants';
import { BRCustomerContentId, GetCustomerLinkParams, InstanceStatus, LinkEntitiesParams, MatrixMacroCategory, MatrixSubValuesMassive, RichEnum, SoftLegalTab, UserGroup, UsersCustomer } from '../../instruments/models';
// Instruments
import { DatePipe, NgIf, NgFor, NgClass, NgStyle } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { throttleTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../components/alert/alert.service';
import {
    ContactReportType, ContentModel, CustomerEvent, CustomerLinkWftStatus, Domain, Enums, Instance, JsonReply,
    JsonReplyWithParams, LinkManyEntitiesParams, LinksToAssets, Recall,
    SearchOrder, TableActionGenericType, TableActionType, TableOptions, UpdateType
} from '../../instruments/models';
import { ProjectLink } from '../../instruments/models/project/ProjectLink';
import {
    AuthService, ContentService, CustomerEventsService,
    InstanceService, NotificationService, ProjectLinkService, SharedService, ValueMatrixService
} from '../../instruments/services';
import { SelectValuesOnlyMacroComponent } from '../select-values-only-macro/select-values-only-macro.component';
import { ValuesPipe } from '../../instruments/helpers/KeysPipe';
import { SafeLinkPipe } from '../../instruments/helpers/SafeLinkPipe';
import { ValueOfEnumPipe } from '../../instruments/helpers/ValueOfEnumPipe';
import { JoinArrayWithSeparatorPipe } from '../../instruments/helpers/JoinArrayWithSeparatorPipe';
import { NothingFoundComponent } from '../nothing-found/nothing-found.component';
import { WidgetTableComponent } from '../widget-table/widget-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { RmPaginatorComponent } from '../rm-paginator/rm-paginator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TriStateCheckboxComponent } from '../tri-state-checkbox/tri-state-checkbox.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'rm-table',
    styleUrls: ['./rm-table.component.css'],
    templateUrl: './rm-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, MatFormFieldModule, MatInputModule, MatButtonModule, MatDatepickerModule, MatSelectModule, MatOptionModule, MatCheckboxModule, TriStateCheckboxComponent, SelectValuesOnlyMacroComponent, MatTooltipModule, NgClass, MatProgressSpinnerModule, RmPaginatorComponent, MatTableModule, CdkTableModule, WidgetTableComponent, NgStyle, NothingFoundComponent, JoinArrayWithSeparatorPipe, ValueOfEnumPipe, SafeLinkPipe, ValuesPipe, TranslateModule]
})
export class RmTableComponent implements OnInit, AfterViewInit, OnDestroy {
    loading: boolean = false;
    storageCategoryIds: [number];
    dataSource: any;
    dataSourceContent: MatTableDataSource<any>;
        displayedColumns: string[] = [
            'position',
            'variation',
            'keyword',
            'lastExecutionDate'
    ];
    dataSubject = new BehaviorSubject<any[]>([]);
    checkedCount: number = 0;
    checkedItems: any[] = [];
    allChecked: boolean = false;
    responseData: any;
    formattedData: any;
    tableMessage: string;
    tableIcon: string;
    updatedRow: any;
    firstLoading: boolean = true;
    updateType = UpdateType;
    updateCheckData = { id: -1, date: null };
    updateCheckResponse = {
        contactLinkId: 0,
        lastCheck: "",
        actualLinkStatus: [],
        httpStatus: 0,
        anonymized: false,
        deIndexed: true,
        actualLinkDeindexStatus: [],
        metaTagNoIndexError: null,
        keywordsCheck: []
    }
    subscription = fromEvent(document, 'click')
        .pipe(throttleTime(100))
        .subscribe();

    selectedSerpTableId: number = 0;
    selectedSerpTableUrl: string = null;
    manualCheckSpinner: boolean = true;
    rotatingEl: number = 0;

    macroCategories: MatrixMacroCategory[];
    microCategories: any = [];
    public macroIndexer = {};
    public colorsDataset: { [macroCatId: number]: { color: string, style: string } } = {};

    enums: Enums = new Enums();
    instances: Instance[] = [];
    debounceClick = new EventEmitter();

    updatePagination: EventEmitter<any> = new EventEmitter<any>();

    usersCustomers: UsersCustomer = undefined;

    @Input() tableRendered: EventEmitter<boolean>;
    // CHECKED ACTIONS

    @Output() customActionEmited: EventEmitter<any> = new EventEmitter<any>();
    @Output() dataLoaded: EventEmitter<any> = new EventEmitter<any>();

    @Output() reloadTabs: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() options: TableOptions;
    @Input() contactId: number;

    private _entityId: number;
    @Input()
    get entityId() {
        return this._entityId;
    }
    set entityId(value: number) {
        this._entityId = value;
        this.options.params.entityId = value;
        this.options.params.contentId = value;
    }

    channelsUnium: any[] = [];
    categoryUnium: any[] = [];
    macroUnium: any[] = [];

    @Input() dataSourceExternal: any;

    @Output() itemUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('checkAllCheckbox') checkAllCheckbox: MatCheckbox;
    @ViewChild('instancesPlaceholder') instancesPlaceholder;

    constructor(private httpClient: HttpClient,
                public changeDetector: ChangeDetectorRef,
                private alert: AlertService,
                private authService: AuthService,
                private customerEventsService: CustomerEventsService,
                private projectLinkService: ProjectLinkService,
                private snackbar: NotificationService,
                private _router: Router,
                private shared: SharedService,
                private contentService: ContentService,
                private translate: TranslateService,
                private instanceService: InstanceService,
                private valueMatrixService: ValueMatrixService,
                private datePipe: DatePipe) {
    }

    @ViewChild('selectValuesMacro') selectValuesMacro: SelectValuesOnlyMacroComponent;

    ngOnInit() {
        if (!this.dataSourceExternal) {
            this.dataSource = new MyDataSource(this.dataSubject);
            this.options.params.contactId = this.contactId ? this.contactId : 0;
            this.options.params.customerId = this.contactId ? this.contactId : 0;
            this.options.params.entityId = this.entityId ? this.entityId : 0;
            this.setTableMessage('spinner', 'TITLES.FETCHING_DATA');

            //qua cerchiamo i params nel localStorage, se non ci sono fa finta di niente
            var retrievedParams = localStorage.getItem(this.options.params.contactId + "" + this.options.params.isSoftLegal + "" + this.options.params.entityId + this.options.paths.fetchPath + this.options.params.softLegalTab);

            if (retrievedParams && this.options.paths.fetchPath == 'api/Content/Get') {
                //se ci sono prendo i params dal LocalStorage per la matrice valoriale nella tabella Contacts
                this.storageCategoryIds = Array.prototype.concat.apply([], JSON.parse(retrievedParams).subCategoryIds);
            }

            if (retrievedParams) {
                this.options.params = JSON.parse(retrievedParams);
            }

            if (this.options.params.contactId || this.options.noContactId) {
                this.fetchData();
            }
        } else {
            this.dataSource = new MyDataSource(this.dataSubject);
            this.formatDatasource(this.dataSourceExternal);
        }

        if (this.shared.loggedUser && this.shared.loggedUserID) {
            this.shared.allowActionsForUser(this.options.buttons);
            this.changeDetector.detectChanges();
        }

        this.shared.userLoaded.subscribe(() => {
            this.shared.allowActionsForUser(this.options.buttons);
            this.changeDetector.detectChanges();
        });

        //error
        if (this.options.filters) {
            if (this.options.filters.length > 0) {
                this.options.filters.map(op => {
                    if (op.type == "valueChoosing") {

                        this.valueMatrixService.getGoalMatrix(this.contactId, null).subscribe(resp => {
                            if (resp.ok) {
                                op.macroCategories = [];

                                resp.data.map(res => {
                                    res.value = 0;
                                    if (res.macroCategoryName != null) {
                                        res.subCategories = [];
                                        op.macroCategories[res.id] = res;
                                    }
                                })

                                resp.data.map(res => {
                                    if (res.subCategoryName != null) {
                                        res.categoryId = res.id;
                                        op.macroCategories[res.macroCategoryId].subCategories.push(res);
                                    }
                                })

                                op.macroCategories.map(macro => {
                                    macro.value = 0;
                                    macro.subCategories.map(sub => {
                                        if (sub.value) {
                                            macro.value = 1;
                                        }
                                    })
                                })
                            } else {
                                console.error(resp.errorMessage)
                            }
                        });
                    }
                })
            }
        }

        //import from UNIUM API
        if (this.options.showFilters) {
            if (this.options.filters.some(t => t.type === 'select_channel_unium' || t.type === 'select_macro_unium' || t.type === 'select_category_unium' )) {
                this.dataForSelectUnium();
            }
        }
    }
    
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        if (this.tableRendered) {
            this.tableRendered.emit(true);
        }
    }

    public shomyUniumxModal() {
        this.alert.myUniumxLink(this.usersCustomers, 'MYUniumx', this.shared.viewedCustomerName).subscribe(result => {
            if (result === 'ok') {
                this.updateMyUniumx(this.usersCustomers);
            }
        });
    }

    private updateMyUniumx(usersCustomers) {
        this.httpClient.post<JsonReply<any>>('/api/UsersCustomer/UpdateAssociatedUsers', usersCustomers).subscribe({
            next: (resp) => {
                if (resp.ok) {
                    this.snackbar.done();
                }
            }
        });
    }

    public myUniumxModal(evt, row) {

        var isAdmin = this.shared.isCurrentUserInSomeGroups([UserGroup.Admin])

        if (isAdmin) {
            evt.stopPropagation();

            this.httpClient.post<JsonReply<any>>('/api/UsersCustomer/GetAssociatedUsers', row.id).subscribe({
                next: (resp) => {
                    if (resp.ok) {
                        this.usersCustomers = resp.data;
                        this.shomyUniumxModal();
                    }
                }
            });
        }

    }

    private dataForSelectUnium() {
        var customerId = this.contactId;
        this.httpClient.post<JsonReply<any>>('/api/Content/GetImportUniumFilters', customerId).subscribe({
            next: (resp) => {
                if (resp.ok) {
                    this.channelsUnium = resp.data.channels;
                    this.categoryUnium = resp.data.categories;
                    this.macroUnium = resp.data.typologies;
                }
            }
        });
    }

    public formatDate(date: Date) {
        return moment(date).fromNow();
    }

    public loadInMemoryData(data: any[]) {
        this.dataSubject.next(data);
        this.changeDetector.detectChanges();
    }

    public massTimingsHtml(url) {
        return url;
    }

    public rowCol(row, col) {
        let arrProp = col.split('.');

        let ret = row;

        if (ret && ret != '-') {
            for (var i = 0; i < arrProp.length; i++) {
                ret = ret[arrProp[i]];

                if (!ret || ret == '-') {
                    ret = null;
                    break;
                }
            }
        } else {
            ret = null;
        }

        if (col && (this.options.formats[col].format || this.options.formats[col].type)) {
            switch (this.options.formats[col].type) {
                case 'dateTime':
                    /*ret = moment(ret).format(Config.MomentDateTimeFormat);
                    return ret;*/
                    let lastChar2 = false;

                    if (ret) {
                        lastChar2 = ret.slice(-1) == 'Z' ? true : false;
                    }

                    if (lastChar2) {
                        return moment(ret).format(Config.MomentDateTimeFormat);
                    } else {
                        return ret;
                    }
                case 'domainUser':
                    return ret;
                case 'date':
                    let lastChar = false;
                    
                    if (ret) {
                        lastChar = ret.slice(-1) == 'Z' ? true : false;
                    }

                    if (lastChar) {
                        return moment(ret).format(Config.MomentDateFormat);
                    } else {
                        return ret;
                    }
                case 'boolean':
                    if (ret == true || ret == 'ENUMS.BOOLEAN_WITH_UNKNOWN.YES') {
                        ret = this.enums.BooleanEnumWithUnknown.fromExact(ret as boolean);
                        ret = this.translate.instant(ret);
                    } else {
                        return false;
                    }
                    return ret;
                default:
                    return ret;
            }
        }

        if (this.options.formats[col].translate && ret != null) {
            ret = this.translate.instant(ret);
        }

        return ret;
    }

    public rowColEnum(row, col) {
        let arrProp = col.split('.');

        let ret = row;

        if (ret && ret != '-') {
            for (var i = 0; i < arrProp.length; i++) {
                ret = ret[arrProp[i]];

                if (!ret || ret == '-') {
                    break;
                }
            }
        } else {
            ret = null;
        }

        if (this.options.formats[col].type === 'enum' && this.options.formats[col].format) {
            if (typeof (ret) == "boolean") {
                ret = ret ? 1 : 0;
            }
            if (typeof (ret) == "number") {
                
                //row is a soft legal row, enter in it
                let item = (this.options.formats[col].format(ret) as RichEnum).getForValue(ret);
                if (item instanceof Array) {
                    return item.map(i => i.toString());
                }
                
                item = item.toString();
                if (this.options.formats[col].translate) {
                    ret = this.translate.instant(item);
                }
            } else {
                //ret is already translated to string enum, translate it
                if (this.options.formats[col].translate && ret) {
                    ret = this.translate.instant(ret);
                }
            }
        }

        return ret;
    }

    public rowScore(row) {
        if (row.rScore && row.rScore != '-') {
            let ret = (Math.round(row.rScore * 100) / 100).toFixed(2);
            return ret;
        }
        else {
            return '-';
        }   
    }

    public roleNumberToString(row) {
        if (!row.sales)
            return '';

        return this.shared.roleNumberToString(row.sales.userGroup)
    }

    public rowUrl(row) {
        let ret = true;

        if (row.url == '-') {
            ret = false;
        } else {
            ret = true;
        }

        return ret;
    }

    public clickOnReload(rowId, row) {
        this.rotatingEl = row.contentId;
        this.manualCheckSpinner = false;
        var path = "/api/SoftLegal/CheckRealTime"
        var brContent = new BRCustomerContentId(row.contactId, row.contentId);
        this.httpClient.post<JsonReply<any>>(path, brContent).subscribe({
            next: (resp) => {
                this.manualCheckSpinner = true;
                this.rotatingEl = 0;
                if (resp.ok) {
                    this.updateCheckData = { id: row.contentId, date: resp.data.lastCheck };      
                }

                this.changeDetector.detectChanges();
            }
        });
    }

    public getInheritedAssetsPreview(row: any, col: string): string {
        if (col === 'preview') {
            if (row.preview) {
                return row.preview;
            }
        }
        return null;
    }
    public getInheritedAssetsNotes(row: any, col: string): string {
        if (col === 'notes') {
            if (row.notes) {
                if (row.notes !== "-") {
                    return row.notes;
                }
            }
        }
        return null;
    }

    public getStatusTooltip(row: any, col: string): string {
        if (col === 'softLegal.lastAutoCheck') {
            if (row.softLegal.lastAutoCheck) {
                let newData = null
                if (row.id === this.updateCheckData.id) {
                    newData = this.updateCheckData.date
                    this.manualCheckSpinner = true;
                }
                const link = row as ContentModel;
                const check = this.updateCheckResponse.contactLinkId == 0 ? link.softLegal.lastAutoCheck : this.updateCheckResponse;
                const checkDate = newData != null ? moment(newData) : moment(check.lastCheck);
                const unanomyzatedKeywordsNames = [];
                if (this.updateCheckResponse.contactLinkId == 0) {
                    row.softLegal.lastAutoCheck.keywordsCheck.map(key => {
                        if (key.isAnonymized != true) {
                            unanomyzatedKeywordsNames.push(key.keyword)
                        }
                    });
                } else {
                    this.updateCheckResponse.keywordsCheck.map(key => {
                        if (key.isAnonymized != true) {
                            unanomyzatedKeywordsNames.push(key.keyword)
                        }
                    });
                }
                const keywords = check.httpStatus == 200 ? unanomyzatedKeywordsNames.length != 0 ? 'NO (Detected Keywords: ' + unanomyzatedKeywordsNames.toString() + ")" : "NO (Keyword: not inserted)" : "NO CHECK (Not possible)"              
                let linkDeindexStatus = [];
                let linkDeIndexString = "";
                if (check.deIndexed === true) {
                    const linkDeindexStatus = [];   
                    if (this.updateCheckResponse.contactLinkId == 0) {
                        row.softLegal.lastAutoCheck.actualLinkDeindexStatus.map(el => {
                            if (el == 0) {
                                linkDeindexStatus.push("Unknown")
                            } else if (el == 1) {
                                linkDeindexStatus.push("MetaNoIndex")
                            } else if (el == 2) {
                                linkDeindexStatus.push("X-Robots-Tag")
                            } else if (el == 4) {
                                linkDeindexStatus.push("Robots.txt")
                            } else {
                                linkDeindexStatus.push("Any")
                            }
                        });
                    } else {
                        this.updateCheckResponse.actualLinkDeindexStatus.map(el => {
                            if (el == 0) {
                                linkDeindexStatus.push("Unknown")
                            } else if (el == 1) {
                                linkDeindexStatus.push("MetaNoIndex")
                            } else if (el == 2) {
                                linkDeindexStatus.push("X-Robots-Tag")
                            } else if (el == 4) {
                                linkDeindexStatus.push("Robots.txt")
                            } else {
                                linkDeindexStatus.push("Any")
                            }
                        });
                    }
                    linkDeIndexString = linkDeindexStatus.toString();

                }     
                const metaTag = this.updateCheckResponse.contactLinkId == 0 ? row.softLegal.lastAutoCheck.metaTagNoIndexError : this.updateCheckResponse.metaTagNoIndexError;
                const metatagNoindex = metaTag != null ? ". Metatag Noindex Error: " + metaTag  : ""
                const anonymized = (check.anonymized === null) ? 'NO CHECK (Keyword not inserted)' : check.anonymized ? 'YES' :  keywords ;
                const deindexed = check.httpStatus != 200 ? "NO CHECK (Not possible)" : check.deIndexed ? "YES (" + linkDeIndexString + metatagNoindex + ")" : 'NO';
                return `Last check: ${checkDate.format(this.options.config.MomentDateFormat)}\n RESPONSE: ${check.httpStatus}\n ANONYMIZATION: ${anonymized}\n DE-INDEXING: ${deindexed}`;                 
            }
        }
        return null;
    }

    public getCustomActionClass(row: any, config: any): string {
        const defaultRowClass = '';
        return defaultRowClass;
    }

    public getCustomActionTooltip(row: any, config: any): string {
        //case custom action = wft
        if (config !== null && config.label !== null && config.label === 'WFT') {
            const link = row as ProjectLink;
            const wftStatus: CustomerLinkWftStatus = link.wftStatus;
            switch (wftStatus) {
                case CustomerLinkWftStatus.NotProcessed:
                    return this.getTranslation('BUTTONS.' + config.actionConfig.tooltip);
                case CustomerLinkWftStatus.Ok:
                    return this.getTranslation('BUTTONS.' + 'OK_WFT_ACQUISITION') + this.datePipe.transform(link.wftDate, 'dd-MM-yyyy');
                case CustomerLinkWftStatus.Error:
                    return this.getTranslation('BUTTONS.' + 'FAIL_WFT_ACQUISITION') + this.datePipe.transform(link.wftDate, 'dd-MM-yyyy') + " " + this.getTranslation('MESSAGES.' + 'FAILED');
                case CustomerLinkWftStatus.UrlNotAvailableForWft:
                    return this.getTranslation('BUTTONS.' + 'FAIL_WFT_URL_ACQUISITION') + this.datePipe.transform(link.wftDate, 'dd-MM-yyyy') + " " + this.getTranslation('MESSAGES.' + 'FAILED');
                default:
                    return this.getTranslation('BUTTONS.' + config.actionConfig.tooltip);
            }
        }
        return this.getTranslation('BUTTONS.' + config.actionConfig.tooltip);
    }

    public getTranslation(key:string): string{
        let translation = "";
        let clientTranslation = this.translate.get(key).subscribe(cliente => {
            translation = cliente;
        })
        return translation;
    };

    public getCustomActionIcon(row: any, config: any): string {
        //case custom action = wft
        if (config !== null && config.label !== null && config.label === 'WFT') {
            const link = row as ProjectLink;
            const wftStatus: CustomerLinkWftStatus = link.wftStatus;
            switch (wftStatus) {
                case CustomerLinkWftStatus.NotProcessed:
                    return config.actionConfig.icon;
                case CustomerLinkWftStatus.Ok:
                    return 'check_circle_green';
                case CustomerLinkWftStatus.Error:
                    return 'priority_high_circle';
                case CustomerLinkWftStatus.Loading:
                    // condition for div ngIf in table component
                    return 'loadingWftCheck';
                case CustomerLinkWftStatus.UrlNotAvailableForWft:
                    return 'priority_high_circle'
                default:
                    return config.actionConfig.icon;
            }
        }
        return config.actionConfig.icon;
    }

    public getRowColor(row: any): string {
        const defaultRowClass = 'rm-table-row ';
        if (row.contentCustomerExtData && row.serpContentInfo !== undefined && row.serpContentInfo !== null && row.serpContentInfo.position !== undefined && row.serpContentInfo.position !== null) {
            const evaluation: number = row.contentCustomerExtData.evaluation;
            switch (evaluation) {
                case -1:
                    return defaultRowClass + '--bg-grey-light opacity-50';
                case 0:
                    return defaultRowClass + '--bg-red-light';
                case 1:
                    return defaultRowClass + '--bg-yellow';
                case 2:
                    return defaultRowClass + '--bg-green-light';
                default:
                    return defaultRowClass;
            }
        }

        return defaultRowClass;
    }

    private disableButtons(): void {
        const showExportAll: boolean = (this.checkedCount === 0 || this.allChecked)  && !this.loading;
        const showExportMany: boolean = (this.checkedCount > 0) && !this.allChecked && !this.loading;
        const showDelete: boolean = this.checkedCount > 0;
        const showGenericEdit: boolean = this.checkedCount > 0;
        const showEditMany: boolean = this.checkedCount > 0;
        const showAddSuccessFee: boolean = this.checkedCount > 0;
        const showRemoveSuccessFee: boolean = this.checkedCount > 0;
        const showConvertMany: boolean = this.checkedCount > 0;

        this.options.buttons.forEach(button => {
            if (!button.allowed)
                return;

            const isExportMany = button.genericType === TableActionGenericType.ExportMany;
            const isExportAll = button.genericType === TableActionGenericType.ExportAll;
            const isDelete = button.genericType === TableActionGenericType.DeleteMany;
            const isGenericEdit = button.genericType === TableActionGenericType.GenericEdit;
            const isEditMany = button.genericType === TableActionGenericType.EditMany;
            const isShownForEveryone = button.genericType === TableActionGenericType.JsonExport;
            const isConvertMany = button.genericType === TableActionGenericType.ConvertMany;
            const isMassiveContents = button.genericType === TableActionGenericType.MassiveContents;

            if (isExportMany) {
                showExportMany ? button.enabled = true : button.enabled = false;
            } else if (isExportAll) {
                showExportAll ? button.enabled = true : button.enabled = false;
            } else if (isDelete) {
                showDelete ? button.enabled = true : button.enabled = false;
            } else if (isGenericEdit) {
                showGenericEdit ? button.enabled = true : button.enabled = false;
            } else if (isEditMany) {
                showEditMany ? button.enabled = true : button.enabled = false;
            } else if (isShownForEveryone) {
                button.enabled = true;
            } else if (isConvertMany) {
                showConvertMany ? button.enabled = true : button.enabled = false;
            } else if (isMassiveContents) {
                isMassiveContents ? button.enabled = true : button.enabled = false;
            }
        });
    }

    private setTableMessage(icon: string, message: string): void {
        this.tableMessage = message;
        this.tableIcon = icon;
    }

    public fetchData(): Promise<boolean> {
        this.loading = true;
        this.setTableMessage('spinner', 'TITLES.FETCHING_DATA');
        this.checkedCount = 0;
        this.checkedItems = [];
        if (this.selectValuesMacro) {
            if (this.selectValuesMacro.macroCategories) {
                var selectedSubcategories = [];
                this.selectValuesMacro.macroCategories.forEach(mac => {
                    var selectedSubcategoriesInMacro: number[] = [];
                    mac.subCategories.forEach(sub => {
                        if (sub.value > 0) {
                            selectedSubcategoriesInMacro.push(sub.id);
                        }
                    });
                    if (selectedSubcategoriesInMacro.length > 0) {
                        selectedSubcategories.push(selectedSubcategoriesInMacro);
                    }
                });
                //qua setta i params
                this.options.params.subCategoryIds = selectedSubcategories;
            }
            //qua setto i params (contactId + fetchPath + tab)
            localStorage.setItem(this.options.params.contactId + "" + this.options.params.isSoftLegal + "" + this.options.params.entityId + this.options.paths.fetchPath + this.options.params.softLegalTab, JSON.stringify(this.options.params));
        }

        //qua se non c'e l'author rimuovo anche gli userIds, altrimenti continuerebbe a filtrare
        if (this.options.params.author == "")
            this.options.params.userIds = null;

        return new Promise<boolean>((resolve, reject) => {

            this.httpClient.post<JsonReplyWithParams<any, any>>(this.options.paths.fetchPath, this.options.params).subscribe({
                next: value => {
                    //solo in home, salva i params per l'ordering
                    if (this.options.paths.fetchPath == '/api/Customer/Find') {
                        localStorage.setItem('homeOrdering', JSON.stringify(this.options.params.ordering));
                    }
                    //salvo i params della tabella events
                    if (this.options.paths.fetchPath == 'api/ContactEvent/ForContact') {
                        localStorage.setItem('/api/ContactEvent/ForContact', JSON.stringify(this.options.params));
                    }
                    //salvo i params della tabella importazione contenuti
                    if (this.options.paths.fetchPath == '/api/Content/InheritedForCustomer') {
                        localStorage.setItem('/api/Content/InheritedForCustomer', JSON.stringify(this.options.params));
                    }
                    //salvo i params della tabella softLegal
                    if (this.options.paths.fetchPath == 'api/SoftLegal/ForCustomer' || this.options.paths.fetchPath == '/api/SoftLegal/ForCustomer') {
                        localStorage.setItem('SoftLegal' + this.options.params.softLegalTab, JSON.stringify(this.options.params));
                    }
                    //salvo i params della tabella Recall
                    if (this.options.paths.fetchPath == 'api/ContactRecall/ForContact') {
                        localStorage.setItem('Recall', JSON.stringify(this.options.params));
                    }
                    //salvo i params della tabella  LinkLesivi
                    if (this.options.paths.fetchPath == 'api/SoftLegal/ForCustomer') {
                        localStorage.setItem('LinkLesivi', JSON.stringify(this.options.params));
                    }                  

                    if (value.ok) {
                        this.loading = false;

                        if (this.options.paths.basePath == 'SoftLegal' && !this.firstLoading) {
                            if (this.options.params.totalItems != value.params.totalItems) {
                                this.reloadTabs.emit(true);
                            }
                        }
                        this.firstLoading = false;

                        value.data.map(row => {
                            if (row.contentWidget) {
                                row.random = Math.floor(Math.random() * 6) + 1;
                            }
                        });
                        
                        this.responseData = value.data;
                        if (value.params) {
                            Object.assign(this.options.params, value.params);  //this.options.params = value.params;
                        }

                        this.dataLoaded.emit();
                        this.updatePagination.emit();

                        value.data.length === 0 ? this.setTableMessage('sad', 'TITLES.NOTHING_FOUND') : null;

                        this.formattedData = (this.responseData as any[]).map(item => {
                            const formatted = {};
                            for (const prop in item) {
                                formatted[prop] = this.options.formatter(prop, item[prop], item);
                            }

                            formatted['_item'] = item;

                            return formatted;
                        });
                        this.disableButtons();

                        this.dataSubject.next(this.formattedData);
                        // this.dataSource = new MyDataSource(this.dataSubject);
                        this.changeDetector.detectChanges();
                        resolve(true);
                    } else {
                        this.setTableMessage('error', value.errorMessage);
                        this.snackbar.error(value.errorMessage);
                        this.loading = false;
                        reject();
                    }
                },
            });
        });
    }

    public formatDatasource(data) {
        this.firstLoading = false;

        data.map(row => {
            if (row.contentWidget) {
                row.random = Math.floor(Math.random() * 6) + 1;
            }
        });

        this.responseData = data;

        this.dataLoaded.emit();
        this.updatePagination.emit();

        data.length === 0 ? this.setTableMessage('sad', 'TITLES.NOTHING_FOUND') : null;

        this.formattedData = (this.responseData as any[]).map(item => {
            const formatted = {};
            for (const prop in item) {
                formatted[prop] = this.options.formatter(prop, item[prop], item);
            }

            formatted['_item'] = item;

            return formatted;
        });
        this.disableButtons();

        this.dataSubject.next(this.formattedData);
        // this.dataSource = new MyDataSource(this.dataSubject);
        this.changeDetector.detectChanges();
    }

    getRecallTooltip(row, col) {
        //check if last recall is successful or not
        let success = 'Failed'

        if (row.lastRecallSucceeded) {
            success = 'Successfull'
        }

        //returns string to rmTable.html
        if (row.lastRecallNote != undefined && row.lastRecallNote != '-') {
            return row.lastRecallNote + " - " + success
        } else if (row.lastRecallNote != undefined && row.lastRecallNote == '-') {
            return success
        }
    }

    public deleteCheckedItems(): void {
        const deleteCount = this.getCheckedIds().length;
        const deleteMessage = deleteCount === 1 ? 'MESSAGES.DELETE_ONE_ITEM' : 'MESSAGES.DELETE_ITEMS';
        const snackbarMessage = deleteCount === 1 ? 'MESSAGES.AN_ITEM_HAS_BEEN_DELETED' : 'MESSAGES.ITEMS_HAVE_BEEN_DELETED';
        this.alert.delete(deleteMessage, {deleteCount: deleteCount}).subscribe(response => {
            if (response === 'ok') {
                this.loading = true;
                this.disableButtons();

                this.httpClient.post<JsonReply<any>>(this.options.paths.deleteManyPath, this.getCheckedIds()).subscribe({
                    next: (resp) => {
                        this.loading = false;
                        if (resp.ok) {
                            this.fetchData();
                            this.snackbar.done(snackbarMessage, { deleteCount: deleteCount });
                            this.allChecked = false;
                            this.checkedCount = 0;
                        }
                    }
                });
            }
        });
    }

    public deleteCheckedItemsBR(): void {
        let linkIds = this.getCheckedIds();
        var links = linkIds.map(link => new BRCustomerContentId(this.contactId, link));
        const deleteCount = this.getCheckedIds().length;
        const deleteMessage = deleteCount === 1 ? 'MESSAGES.DELETE_ONE_ITEM' : 'MESSAGES.DELETE_ITEMS';
        const snackbarMessage = deleteCount === 1 ? 'MESSAGES.AN_ITEM_HAS_BEEN_DELETED' : 'MESSAGES.ITEMS_HAVE_BEEN_DELETED';
        this.alert.delete(deleteMessage, {deleteCount: deleteCount}).subscribe(response => {
            if (response === 'ok') {
                this.loading = true;
                this.disableButtons();
                    this.httpClient.post<JsonReply<any>>(this.options.paths.deleteManyPath, links).subscribe({
                        next: (resp) => {
                            this.loading = false;
                            if (resp.ok) {
                                this.fetchData();
                                this.snackbar.done(snackbarMessage, { deleteCount: deleteCount });
                                this.allChecked = false;
                                this.checkedCount = 0;
                            }
                        }
                    });
            }
        });
    }

    public deleteCheckedItemsCG(): void {
        let linkIds = this.getCheckedIds();
        var links = linkIds.map(link => new BRCustomerContentId(this.contactId, link));
        const deleteCount = this.getCheckedIds().length;
        const deleteMessage = deleteCount === 1 ? 'MESSAGES.DELETE_ONE_ITEM' : 'MESSAGES.DELETE_ITEMS';
        const snackbarMessage = deleteCount === 1 ? 'MESSAGES.AN_ITEM_HAS_BEEN_DELETED' : 'MESSAGES.ITEMS_HAVE_BEEN_DELETED';
        this.alert.delete(deleteMessage, { deleteCount: deleteCount }).subscribe(response => {
            if (response === 'ok') {
                this.loading = true;
                this.disableButtons();

                //aggiungo un counter per gli elementi modificati
                var okToCheck = 0;

                var linkIdsReports = this.getCheckedIdsReports();

                if (linkIdsReports.length > 0) {
                    //qua se ha almeno un link con report
                    linkIdsReports.map(id => {
                        okToCheck++;
                        this.httpClient.post<JsonReply<any>>('api/ContactDocument/DeleteById', id).subscribe({
                            next: (resp) => {
                                if (resp.ok) {
                                    okToCheck--;

                                    if (okToCheck == 0) {
                                        this.fetchData();
                                        this.snackbar.done(snackbarMessage, { deleteCount: deleteCount });
                                        this.allChecked = false;
                                        this.checkedCount = 0;
                                        this.loading = false;
                                    } else {
                                        this.snackbar.error("An error occurred while deleting");
                                        this.loading = false;
                                    }
                                }
                            }
                        });
                    })
                }
            }
        });
    }

    //generic edit
    public massiveSoftLegal(tab: SoftLegalTab = SoftLegalTab.TODO): void {
        const links = this.formattedData.filter(item => item._checked).map(item => item);
        //const linksPolished = this.responseData;
        var ids = [];
        //var finalLinks = [];

        links.map(link => {
            let id = new BRCustomerContentId(this.contactId, link.contentId);
            ids.push(id);
        })

        //linksPolished.map(link => {
        //    if (ids.includes(link.id)) {
        //        finalLinks.push(link)
        //    }
        //})
        this.alert.massiveSoftLegal(links, 'BUTTONS.MASSIVE_OPERATIONS', tab).subscribe(result => {
            if (result === 'ok') {
                this.snackbar.done();
                this.fetchData();
            } else if (result === 'linkEvent') {
                
                this.linkToEvent(ids);
            } else if (result === 'createInstance') {
                this.createInstance(ids);
                var currentUrl = this._router.url;
                this._router.navigateByUrl('/', { skipLocationChange: true })
                    .then(() =>
                        this._router.navigateByUrl(currentUrl));
            } else if (result === 'addInstance') {
                this.getInstances(ids);
                var currentUrl = this._router.url;
                this._router.navigateByUrl('/', { skipLocationChange: true })
                    .then(() =>
                        this._router.navigateByUrl(currentUrl));
            }
        });
    }

    public editMany(): void {
        const links = this.formattedData.filter(item => item._checked).map(item => item);
        const linksPolished = this.responseData;
        var ids = [];
        var finalLinks = [];

        if (links != null && links.length > 0) {
            if (links[0].contentId !== undefined) {
                links.map(link => {
                    let id = new BRCustomerContentId(link.contactId, link.contentId);
                    ids.push(id);
                })
            }
            else {
                links.map(link => {
                    ids.push(link.id);
                })
            }
        }

        linksPolished.map((link, i) => {
            if (ids.includes(link.id)) {
                finalLinks.push(link)
            }
        })

        this.alert.editMany(finalLinks, 'BUTTONS.MASSIVE_OPERATIONS').subscribe(result => {
            if (result === 'ok') {
                this.snackbar.done();
                this.fetchData();
            } else if (result === 'linkEvent') {
                this.linkToEvent(ids);
            } else if (result === 'createInstance') {
                this.createInstance(ids);
                var currentUrl = this._router.url;
                this._router.navigateByUrl('/', { skipLocationChange: true })
                    .then(() =>
                        this._router.navigateByUrl(currentUrl));
            } else if (result === 'addInstance') {
                this.getInstances(ids);
                var currentUrl = this._router.url;
                this._router.navigateByUrl('/', { skipLocationChange: true })
                    .then(() =>
                        this._router.navigateByUrl(currentUrl));
            }
        });
    }
    
    public MassiveContents() {
        const links = this.formattedData.filter(item => item._checked).map(item => item);
        let ids = [];
        let brIds = [];
        links.map(li => {
            ids.push(li.contentId);
            brIds.push(new BRCustomerContentId(this.contactId, li.contentId));
        });

        this.alert.massiveContents(links).subscribe(result => {
            if (result === 'ok') {
                //colleghiamo gli eventi

                let eventParams = new LinkManyEntitiesParams();
                eventParams.linkedEntitiesIds = brIds;
                eventParams.entityId = links[0].entityId;
                if (eventParams.entityId) {
                    this.projectLinkService.linkManyToEvent(eventParams).then(resp => {
                    });
                }           

                let catIds = [];

                links[0].macroCat.map(mac => {
                    mac.subCategories.map(sub => {
                        if (sub.value) {
                            catIds.push(sub.categoryId);
                        }
                    })
                })

                //values params
                let params: MatrixSubValuesMassive = {
                    contentIds: brIds,
                    customerId: this.contactId,
                    categoryIds: catIds
                }

                //save values
                this.valueMatrixService.updateContentMassiveValues(params).then(resp => {
                    if (resp.ok) {
                        //content params
                        let param = {
                            Ids: brIds,
                            AddToSoftLegal: false || links[0].importToSoft,
                            Tags: links[0].tags,
                            Pertinence: links[0].pertinence,
                            Evaluation: links[0].evaluation,
                            Sender: links[0].sender
                        };

                        //update contents
                        this.contentService.updateMassiveContent(param).then(resp => {
                            this.snackbar.done();
                            this.fetchData();
                        })
                    } else {
                        this.snackbar.error();
                    }
                });
            } else if (result === 'linkEvent') {

                this.linkToEvent(brIds);
            }
        });
    }

    public ConvertMany() {
        const links = this.formattedData.filter(item => item._checked).map(item => item);
        let ids = [];
        links.map(li => {
            ids.push(li.id);
        });
        this.alert.massiveImport().subscribe(result => {
            if (result != 'cancel') {
                var userResult: any = result;
                this.contentService.MassiveImport(new LinksToAssets(this.contactId, userResult.ImportToSoftLegal, userResult.TakeFromUnium, ids)).then(resp => {
                    if (resp.ok) {
                        this.snackbar.done();
                        this.fetchData();
                    } else {
                        this.snackbar.error();
                    }
                });
            }
        });
    }
    
    //add to event
    public linkToEvent(linkIds: BRCustomerContentId[]): void {
        const params = new LinkManyEntitiesParams();
        params.linkedEntitiesIds = linkIds;
        if (linkIds.length > 0) {
            this.customerEventsService.getAllEventNames(this.options.params.customerId).then(resp => {
                const events = resp.data;
                this.alert.selectEvent(params, events)
                    .subscribe(result => {
                        if (result === 'ok') {
                            this.projectLinkService.linkManyToEvent(params).then(resp => {
                                if (resp.ok) {
                                    this.fetchData();
                                    this.snackbar.done();
                                }
                            });
                        }
                    });
            });
        }
    }

    //create instance
    public createInstance(linkIds: BRCustomerContentId[]): void {
        if (linkIds.length > 0) {
            this.projectLinkService.groupToInstance(linkIds).then(resp => {
                if (resp.ok) {
                    this.fetchData();
                    this.snackbar.done();
                }
            });
        } else {
            this.snackbar.error('Select instances'); // TODO: Translate
        }
    }

    //get instances
    private getInstances(ids): void {
        if (this.instancesPlaceholder) {
            this.instancesPlaceholder.icon = 'spinner';
            this.instancesPlaceholder.innerText = 'Loading instances'; // TODO: Translate
        }
        console.log(this.options.params);
        var param = new GetCustomerLinkParams();
        param.contactId = this.options.params.customerId;
        param.customerId = this.options.params.customerId;
        param.softLegalTab = SoftLegalTab.Instance;
        this.instanceService.forCustomer(param).then(resp => {
            if (this.instancesPlaceholder) {
                this.instancesPlaceholder.icon = 'sad'; // but true
                this.instancesPlaceholder.innerText = 'Error';
            }
            if (resp.ok) {
                this.instances = resp.data;
                if (resp.data.length === 0) {
                    if (this.instancesPlaceholder) {
                        this.instancesPlaceholder.icon = 'sad'; // but true
                        this.instancesPlaceholder.innerText = null;
                    }
                }

                this.addToInstance(ids);
            } else {
                if (this.instancesPlaceholder) {
                    this.instancesPlaceholder.icon = 'sad'; // but true
                    this.instancesPlaceholder.innerText = resp.errorMessage;
                }
            }
        });
    }

    //add to instance
    public addToInstance(instanceIds: BRCustomerContentId[]): void {
        const params = new LinkManyEntitiesParams();
        params.linkedEntitiesIds = instanceIds;
        this.alert.selectInstance(params, this.instances
            .filter(instance => instance.status === InstanceStatus.Open))
            .subscribe(result => {
                if (result === 'ok') {
                    this.projectLinkService.linkManyToInstance(params).then(resp => {
                        if (resp.ok) {
                            this.fetchData();
                            this.snackbar.done();
                        }
                    });
                }
            });
    }

    public updateItem(row: any): void {
        row.contactId = this.contactId;
        const type = this.options.update.type;
        if (type === UpdateType.Popup) {
            this.updateInPopUp(row);
        } else if (type === UpdateType.RouterLink) {
            this._router.navigate(['/' + this.options.paths.updatePath(row), this.options.update.action(row)]);
        }
    }

    public actionClicked(actionType: TableActionType) {
        switch (actionType) {
            case TableActionType.ContactsReportAll:
                this.exportAllContacts(ContactReportType.Contacts);
                break;
            case TableActionType.SalesReportAll:
                this.exportAllContacts(ContactReportType.SalesDashboard);
                break;
            case TableActionType.LegalReportAll:
                this.exportAllContacts(ContactReportType.Legal);
                break;
            case TableActionType.ExportDefaultAll:
                this.exportAll();
                break;
            case TableActionType.ExportFiltered:
                this.exportFiltered();
                break;
            case TableActionType.ContactsReportMany:
                this.exportManyContacts(ContactReportType.Contacts);
                break;
            case TableActionType.SalesReportMany:
                this.exportManyContacts(ContactReportType.Sales);
                break;
            case TableActionType.LegalReportMany:
                this.exportManyContacts(ContactReportType.Legal);
                break;
            case TableActionType.ExportDefaultMany:
                this.exportMany();
                break;
            case TableActionType.DeleteMany:
                this.deleteCheckedItems();
                break;
            case TableActionType.DeleteManyBR:
                this.deleteCheckedItemsBR();
                break;
            case TableActionType.DeleteManyContactDocument:
                this.deleteCheckedItemsCG();
                break;
            case TableActionType.TTDReportAll:
                this.exportAll();
                break;
            case TableActionType.ExportSoftLegalLinksAll:
                this.exportAllSoftLegalLinks();
                break;
            case TableActionType.JsonExport:
                this.openApiKeyPopup();
                break;
            case TableActionType.ExportSERPLinksAll: {
                this.exportAll({ 'ResultId': this.entityId.toString() });
                break;
            }
            case TableActionType.ExportRelatedDomains: {
                this.exportRelatedDomains();
                break;
            }
            case TableActionType.MassiveSoftLegal: {
                this.massiveSoftLegal(this.options.params.softLegalTab);
                break;
            }
            case TableActionType.MassiveContents: {
                this.MassiveContents();
                break;
            }
            case TableActionType.EditMany: {
                this.editMany();
                break;
            }
            case TableActionType.ConvertMany: {
                this.ConvertMany();
                break;
            }
            default:
                break;
        }
    }

    public getOpacity(row, options) {
        if (options.paths.fetchPath == 'api/SERP/Link/ForResult') {
            if (row.contentCustomerExtData) {
                return row.contentCustomerExtData.pertinence == 1 || row.contentCustomerExtData.pertinence == 2 ? '1' : '.65';
            } else {
                return '1';
            }
        } else {
            return '1';
        }
    }

    private openApiKeyPopup(): void {
        this.authService.getAccessToken()
            .then(token => {
                const link = document.location.origin + '/api/Customer/List?token=' + token;
                this.alert.apiLink(token, link).subscribe(res => {
                    if (res == 'ok') {
                        this.shared.copyLink(link);
                    }
                });
            }).catch(err => console.error(err));
    }

    private updateInPopUp(row: any) {
        const updateQuery = () => {
            this.httpClient.post<JsonReply<any>>(this.options.paths.updatePath(), this.updatedRow).subscribe({
                next: () => {
                    this.fetchData();
                    this.itemUpdated.emit();
                },
            });
        };

        // this.responseData.forEach(respRow => {
        //    if (respRow.id === +row.id) {
        //        this.updatedRow = respRow;
        //    }
        // });

        /* Qualcosa qua non va, non trova le ipdatedRow corrette, controllare filtro */
        if (row.contentId) {
            this.updatedRow = this.responseData.find(item => item.contentId == row.contentId);
        } else {
            this.updatedRow = this.responseData.find(item => item.id == row.id);
        }

        switch (this.options.update.action()) {
            case('contactRecall'):
                this.alert.contactRecall(this.updatedRow as Recall, 'BUTTONS.UPDATE_RECALL').subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    }
                });
                break;
            case ('customerRecall'):
                this.alert.customerRecall(this.updatedRow as Recall, 'BUTTONS.ADD_CUSTOMER_RECALL').subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    }
                });
                break;
            case('customerReminder'):
                this.alert.customerReminer(this.updatedRow as Recall, 'BUTTONS.UPDATE_REMINDER').subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    }
                });
                break;
            case('event'):
                this.alert.event(this.updatedRow as CustomerEvent, 'BUTTONS.UPDATE_EVENT').subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    }
                });
                break;
            case ('link'):
                this.alert.link(this.updatedRow as ContentModel, 'BUTTONS.UPDATE_LINK').subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    } else if (result === 'newUrlIsOtherCustomerContent') {
                        this.snackbar.error('Error, URL already exists, create new link');
                    } else if (result === 'newUrlIsCustomerContent') {
                        this.snackbar.error('Error, URL already exists');
                    } else if (result === 'savedSameUrl') {
                        this.fetchData();
                    }
                });
                break;
            case('domain'):
                this.alert.domain(this.updatedRow as Domain, 'BUTTONS.UPDATE_DOMAIN').subscribe(result => {
                    if (result === 'ok') {
                        this.updatedRow.type = this.updatedRow.typeIsVideo ? 2 : 0;
                        updateQuery();
                    }
                });
                break;
            case('instanceLink'):
                this.alert.instanceLink(this.updatedRow).subscribe(result => {
                    if (result === 'ok') {
                        updateQuery();
                    }
                });
                break;
            default:
                break;
        }
    }

    // CHECKBOXES

    // Select items from table to delete/export/success fee
    public checkItems(e: any, row: any): void {
        var rowId = row.id ? row.id : row.contentId;
        const itemIndex = this.checkedItems.findIndex((item) => item === rowId);

        const itemExists = itemIndex !== -1;

        if (e.checked) {
            this.checkedCount++;
            if (!itemExists) {
                this.checkedItems.push(rowId);
            }
        } else {
            this.checkedCount--;
            if (itemExists) {
                this.checkedItems.splice(itemIndex, 1);
            }
        }
       
        this.checkedCount === this.responseData.length ? this.allChecked = true : this.allChecked = false;
        this.disableButtons();
    }

    // Custom actoin on checkbox
    public checkAction (event: any, row: any): void {
        const { linkingParams, paths: { linkToEntityPath } } = this.options;
        if(row.contactId !== undefined)
            linkingParams.contactId = row.contactId;
        linkingParams.entityId = this.entityId;
        linkingParams.linkedEntityId = row.id;
        this.loading = true;
        this.httpClient.post<JsonReply<any>>(linkToEntityPath, linkingParams).subscribe({
            next: (resp) => {
                this.loading = true;
                if (resp.ok) {
                    this.fetchData();
                    this.itemUpdated.emit();
                }
            },
        });
    }

    public checkAll(e): void {
        this.formattedData.forEach(item => item._checked = e.checked);
        this.checkedCount = e.checked ? this.formattedData.length : 0;
        if (this.checkedCount === this.formattedData.length) {
            this.allChecked = true;
        }

        this.checkedItems = e.checked ? this.formattedData.map(function (item) {
            if (item.Id !== undefined) {
                return item.Id;
            }
            else if (item.contentId !== undefined) {
                return item.contentId;
            }
        }) : [];
        this.disableButtons();
    }

    private getCheckedIds(): any[] {
        return this.formattedData.filter(item => item._checked).map(function (item) {
            if (item.id !== undefined) {
                return item.id;
            }
            else if (item.contentId !== undefined) {
                return item.contentId;
            }
        });
    }

    private getCheckedIdsReports(): any[] {
        return this.formattedData.filter(item => item._checked).map(function (item) {
            if (item.id !== undefined) {
                return item.id;
            }
            else if (item.contentId !== undefined) {
                return item.contentId;
            }
        });
    }

    private getCheckedIdsNotReports(): any[] {
        return this.formattedData.filter(item => item._checked && !item.isReport).map(function (item) {
            if (item.id !== undefined) {
                return item.id;
            }
            else if (item.contentId !== undefined) {
                return item.contentId;
            }
        });
    }

    //TEST  

    public exportRelatedDomains() {
        this.shared.downloadFile('api/Domain/ExportForTTD?ttdId=' + this._entityId, 'should_have_filename')
    }

    //DeleteTTD
    public deleteTTD(e, row) {
        var linkEntitiesParams = new LinkEntitiesParams();
        linkEntitiesParams.entityId = row.ttdId;
        linkEntitiesParams.linkedEntityId = row.id;

        const deleteMessage =  'MESSAGES.DELETE_TTD';
        const snackbarMessage = 'MESSAGES.AN_ITEM_HAS_BEEN_DELETED';
        this.alert.delete(deleteMessage, { ttdName: row.name }).subscribe(response => {
            if (response === 'ok') {
                this.loading = true;

                this.httpClient.post<JsonReply<any>>("/api/Domain/LinkToTTD", linkEntitiesParams ).subscribe({
                    next: (resp) => {
                        this.loading = false;
                        if (resp.ok) {
                            this.fetchData();
                            this.snackbar.done(snackbarMessage);
                        }
                    }
                });
            }
        });
    }

    // EXPORT
    public exportAll(headers: { [key: string]: string } = {}) {
        if (this.contactId) {
            headers['ContactId'] = this.contactId.toString();
        }

        const exportName = `${this.options.exportName + (this.contactId ? '_' + this.contactId : '')}.xlsx`;

        this.loading = true;

        this.shared.downloadFile(this.options.paths.exportAllPath, exportName, headers)
            .then(() => {
                this.loading = false;
                this.changeDetector.detectChanges();
            })
            .catch(() => {
                console.log('catch loading: ', this.loading);
                this.loading = false;
                console.log('catch loading: ', this.loading);
                this.changeDetector.detectChanges();
            });
    }

    public exportFiltered(headers: { [key: string]: string } = {}) {
        if (this.contactId) {
            headers['ContactId'] = this.contactId.toString();
        }

        headers['GetContentParams'] = JSON.stringify(this.options.params);

        const exportName = `${this.options.exportName + (this.contactId ? '_' + this.contactId : '')}.xlsx`;

        this.loading = true;

        this.shared.downloadFile(this.options.paths.exportAllPath, exportName, headers)
            .then(() => {
                this.loading = false;
                this.changeDetector.detectChanges();
            })
            .catch(() => {
                console.log('catch loading: ', this.loading);
                this.loading = false;
                console.log('catch loading: ', this.loading);
                this.changeDetector.detectChanges();
            });
    }

    public exportAllContacts(reportType: ContactReportType) {
        this.exportAll({ 'ReportType': reportType.toString() });
    }

    public exportAllSoftLegalLinks() {
        this.shared.downloadFile('api/SoftLegal/ExportLinks?customerId=' + this.contactId, 'should_have_filename')
    }

    public exportMany(headers?: { [key: string]: string }) {
        //let header = new HttpHeaders();
        //header = header.append('Accept', 'application/octet-stream');
        //header = header.append('IdList', this.getCheckedIds().join(','));

        if (!headers) {
            headers = {};
        }

        if (this.contactId) {
            headers['ContactId'] = this.contactId.toString();
        }

        headers['IdList'] = this.getCheckedIds().join(',');

        //const exportName = `${this.options.exportName + (this.contactId ? '_' + this.contactId : '')}.xlsx`;
        this.loading = true;

        this.shared.downloadFile(this.options.paths.exportManyPath, null, headers)
            .then(() => {
                console.log('then loading: ', this.loading);
                this.loading = false;
                console.log('then loading: ', this.loading);
                this.changeDetector.detectChanges();
            })
            .catch(() => {
                console.log('catch loading: ', this.loading);
                this.loading = false;
                console.log('catch loading: ', this.loading);
                this.changeDetector.detectChanges();
            });

    }

    public exportManyContacts(reportType: ContactReportType) {
        this.exportMany({ 'ReportType': reportType.toString() });
    }

    // SORTING

    public changeOrder(columnName: string) {
        if (columnName) {

            SearchOrder.changeOrder(this.options.params.ordering, columnName);

            this.fetchData();
        }
    }

    public getFlipIconClass(propertyName): string {
        return SearchOrder.getFlipIconClass(this.options.params.ordering, propertyName);
    }

    public pageSizeLoop(n: number): any[] {
        return Array(n);
    }

    // FILTERS
    public resetFilters(): void {
        Object.assign(this.options.params, this.shared.deepClone(this.options.resetParams()));
        this.options.params.contactId = this.contactId ? this.contactId : 0;
        this.options.params.customerId = this.contactId ? this.contactId : 0;
        this.options.params.entityId = this.entityId ? this.entityId : 0;
        if (this.selectValuesMacro) {
            if (this.selectValuesMacro.macroCategories) {
                this.selectValuesMacro.macroCategories.forEach(mac => {
                    mac.value = 0;
                    mac.subCategories.forEach(sub => {
                        sub.value = 0;
                    });
                });
            }
        }
        this.fetchData();
    }

    public _stopPropagation(e) {
        e.stopPropagation();
    }

    public emitCustomAction(item: any, event: Event) {
        this.customActionEmited.emit(item);
        event.stopPropagation();
    }

    public handleUrlNavigation(rowId: number, event): void {
        event.stopPropagation();
        const updatedRow: ContentModel = this.formattedData.find(row => row.id === rowId);
        updatedRow.softLegal.lastCheck = moment(new Date()).fromNow() as any;
        this.dataSubject.next(this.formattedData);
    }

    public recallTool(string) {
        var text = string.substr(12);
        var textCapital = text.charAt(0).toUpperCase() + text.slice(1);

        return textCapital;
    }

    public log(keyword, choices) {
        choices.map(choice => {
            if (choice.value) {
                this.options.params[choice.param] = keyword;
            } else {
                this.options.params[choice.param] = null;
            }
        })
    }

    //tabella Domain Link alla pagina del dettaglio del TTD
    public ttdNameLinked(row, ev){
        ev.stopPropagation()
        window.open('/ttd_details;id=' + row.ttdId, '_blank')
    }

    public shareReport(contentId: number, name: string) {
        var file = name.split("").reverse().join("").split(".")[0]
        var isPdf = file.split("").reverse().join("") == "pdf"
        var link: string = environment.myUniumxUrl + 'report;id=' + contentId + ';pdf=' + isPdf;
        this.shared.copyLink(link);
        this.snackbar.done("Link copiato")
    }


    public downloadOffline(row) {
        console.log(row)
        this.contentService.download(new BRCustomerContentId(row.contactId, row.contentId)).then(res => {
            if (row.content_Subtype == "pdf") {
                var file = new Blob([res], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL, "_blank");
            } else if (row.content_Type == "image") {
                var file = new Blob([res], { type: 'image/' + row.content_Subtype });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL, "_blank");
            } else {
                this.shared.downloadBlobFile(res, row.fileName);
            }
        })
    }

}

export class MyDataSource extends DataSource<any[]> {
    constructor(private subject: BehaviorSubject<any[]>) {
        super();
    }

    connect(): Observable<any[]> {
        return this.subject.asObservable();
    }

    disconnect(): void { }
}
