<form class="row" *ngIf="data" #linkForm="ngForm">
    <!-- Link -->
    <mat-form-field class="col-12 mb-3">
        <mat-label>{{ 'TABLE_HEADINGS.LINK' | translate }}</mat-label>
        <input name="link_url"
               [(ngModel)]="data.link.url"
               required matInput
               pattern="https?://.+"
               #linkUrlModel="ngModel"
               >
        <mat-error *ngIf="linkUrlModel.invalid && (linkUrlModel.dirty || linkUrlModel.touched)">
            <div *ngIf="linkUrlModel.errors.required">
                Link is <strong>required</strong>
            </div>
            <div *ngIf="linkUrlModel.errors.pattern">
                Please, enter a valid {{'TABLE_HEADINGS.LINK' | translate}}
            </div>
        </mat-error>
    </mat-form-field>
    <!-- Title -->
    <mat-form-field class="col-12 mb-3">
        <input name="link_title" [(ngModel)]="data.link.title" matInput [placeholder]="'TABLE_HEADINGS.TITLE' | translate">
    </mat-form-field>
    <!-- Publishing date -->
    <mat-form-field class="col-4">
        <mat-label>{{'TABLE_HEADINGS.PUBLISHING_DATE' | translate }}</mat-label>
        <input name="link_pubDate"
               [matDatepicker]="pickerPubDate"
               [(ngModel)]="data.link.publicationDate"
               matInput>
        <mat-datepicker-toggle matSuffix [for]="pickerPubDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerPubDate></mat-datepicker>
    </mat-form-field>
    <!-- Workability -->
    <mat-form-field class="col-4">
        <mat-label>{{ 'TABLE_HEADINGS.WORKABILITY' | translate }}</mat-label>
        <mat-select 
                    name="link_injLevel"
                    [(ngModel)]="data.link.softLegal.workability">
            <mat-option *ngFor="let option of enums.InjuryLevel" [value]="option.value">{{option.name | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Success Fee -->
    <mat-form-field class="col-4">
        <mat-label>{{ 'TABLE_HEADINGS.SUCCESS_FEE' | translate }}</mat-label>
        <mat-select 
                    name="link_successFee"
                    [(ngModel)]="data.link.softLegal.successFee">
            <mat-option *ngFor="let option of enums.SuccessFeeStatus" [value]="option.value">{{option.name | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- TTD -->
    <mat-form-field class="col-4" *ngIf="data.link.softLegal.contentId != null && data.link.softLegal.contentId != 0 ">
        <mat-label>{{'TABLE_HEADINGS.TTD' | translate }}</mat-label>
        <input name="link_header"
               [(ngModel)]="data.link.softLegal.ttdName"
               readonly
               matInput>
    </mat-form-field>
    <!-- Instance receiver -->
    <mat-form-field class="col-4" *ngIf="data.link.softLegal.contentId != null && data.link.softLegal.contentId != 0">
        <mat-label>{{'TABLE_HEADINGS.INSTANCE_RECEIVER' | translate }}</mat-label>
        <input name="link_instance_receiver"
               [(ngModel)]="data.link.softLegal.instanceReceiver"
               readonly
               matInput>
    </mat-form-field>
    <!-- STATUS -->
    <mat-form-field class="col-4" *ngIf="data.link.softLegal.contentId != null && data.link.softLegal.contentId != 0">
        <mat-label>{{ 'TABLE_HEADINGS.STATUS' | translate }}</mat-label>
        <mat-select 
                    [ngClass]="{'toBeSavedUnderline': toBeSaved}"
                    name="instance_link_status"
                    (selectionChange)="isStatusChanged = true; toBeSaved = false"
                    [(ngModel)]="data.link.softLegal.status">
            <mat-option *ngFor="let option of enums.SoftLegalLinkStatus" [value]="option.value">
                {{option.name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Notes -->
    <mat-form-field class="col-10">
        <mat-label>{{'TABLE_HEADINGS.NOTES' | translate}}</mat-label>
        <textarea name="link_notes"
                  [(ngModel)]="data.link.contentCustomerExtData.commonNotes"
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5">
        </textarea>
    </mat-form-field>
    <!--    Wikipedia Source-->
    <mat-checkbox class="mat-form-field-infix col-2 mt-4"
                  [(ngModel)]="data.link.contentCustomerExtData.wikipediaSource"
                  name="wikipediaSource">
        {{'TABLE_HEADINGS.WIKIPEDIA_SOURCE' | translate}}
    </mat-checkbox>
    <mat-dialog-actions class="w-100 mr-3" *ngIf="data.link.softLegal.contentId === 0">
        <button mat-button color="primary"
                class="ml-auto level2-button"
                [disabled]="linkForm.invalid"
                style="min-width:125px"
                (click)="checkUrl()">
            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
            {{'BUTTONS.SAVE' | translate}}
        </button>
    </mat-dialog-actions>
    <section class="col-12" *ngIf="data.link.softLegal.contentId != 0">
        <strong class="subheading-underline-blue-light mt-4 mb-3 col-12" style="padding: 0; display: block">
            {{'TITLES.ACTIONS' | translate}} ({{'ENUMS.CONTRACT_STATUS.SENT_TO' | translate}})
        </strong>
        <div class="row">
            <!-- google -->
            <div class="col-6">
                <div class="row align-items-center">
                    <mat-checkbox class="mat-form-field-infix col-3"
                                  [(ngModel)]="data.link.softLegal.isGoogleBlocked"
                                  (change)="onActionChange($event, 'googleActivationDate', 'googleResponse')"
                                  name="link_google">
                        Google
                    </mat-checkbox>

                    <mat-form-field class="col-4">
                        <mat-label>{{'REPORTS.SENT_ON' | translate }}</mat-label>
                        <input [disabled]="!data.link.softLegal.isGoogleBlocked"
                               name="link_pubDate4"
                               [matDatepicker]="googleActivationDate"
                               [(ngModel)]="data.link.softLegal.googleActivationDate"
                               matInput>
                        <mat-datepicker-toggle matSuffix [for]="googleActivationDate"></mat-datepicker-toggle>
                        <mat-datepicker #googleActivationDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>{{ 'TABLE_HEADINGS.RESULT' | translate }}</mat-label>
                        <mat-select name="instance_link_google"
                                    (selectionChange)="onActionResponseChange($event, 'googleActivationDate', 'googleResponse', 'isGoogleBlocked')"
                                    [(ngModel)]="data.link.softLegal.googleResponse">
                            <mat-option [value]="null">Seleziona</mat-option>
                            <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                                {{option.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <!-- web form -->
            <div class="col-6">
                <div class="row align-items-center">
                    <mat-checkbox class="mat-form-field-infix col-3"
                                  [(ngModel)]="data.link.softLegal.webForm"
                                  (change)="onActionChange($event, 'webFormActivationDate', 'webFormResponse')"
                                  name="link_web_form">
                        {{'CUSTOMER_LINK.IS_WEB_FORM_REQUEST' | translate}}
                    </mat-checkbox>


                    <mat-form-field class="col-4">
                        <mat-label>{{'REPORTS.SENT_ON' | translate }}</mat-label>
                        <input [disabled]="!data.link.softLegal.webForm"
                               name="link_pubDate3"
                               [matDatepicker]="WebFormActivationDate"
                               [(ngModel)]="data.link.softLegal.webFormActivationDate"
                               matInput>
                        <mat-datepicker-toggle matSuffix [for]="WebFormActivationDate"></mat-datepicker-toggle>
                        <mat-datepicker #WebFormActivationDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>{{ 'TABLE_HEADINGS.RESULT' | translate }}</mat-label>
                        <mat-select  name="instance_link_webform"
                                    (selectionChange)="onActionResponseChange($event, 'webFormActivationDate', 'webFormResponse', 'webForm')"
                                    [(ngModel)]="data.link.softLegal.webFormResponse">
                            <mat-option [value]="null">Seleziona</mat-option>
                            <mat-option *ngFor="let option of enums.WebFormStatus" [value]="option.value">
                                {{option.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <!-- yahoo -->
            <div class="col-6">
                <div class="row align-items-center">
                    <mat-checkbox class="mat-form-field-infix col-3"
                                  [(ngModel)]="data.link.softLegal.isYahooBlocked"
                                  (change)="onActionChange($event, 'yahooActivationDate', 'yahooResponse')"
                                  name="link_yahoo">
                        Yahoo
                    </mat-checkbox>


                    <mat-form-field class="col-4">
                        <mat-label>{{'REPORTS.SENT_ON' | translate }}</mat-label>
                        <input [disabled]="!data.link.softLegal.isYahooBlocked"
                               name="link_pubDate5"
                               [matDatepicker]="YahooActivationDate"
                               [(ngModel)]="data.link.softLegal.yahooActivationDate"
                               matInput>
                        <mat-datepicker-toggle matSuffix [for]="YahooActivationDate"></mat-datepicker-toggle>
                        <mat-datepicker #YahooActivationDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>{{ 'TABLE_HEADINGS.RESULT' | translate }}</mat-label>
                        <mat-select  name="yahooResponse"
                                    (selectionChange)="onActionResponseChange($event, 'yahooActivationDate', 'yahooResponse', 'isYahooBlocked')"
                                    [(ngModel)]="data.link.softLegal.yahooResponse">
                            <mat-option [value]="null">Seleziona</mat-option>
                            <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                                {{option.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <!-- guarantor -->
            <div class="col-6">
                <div class="row align-items-center">
                    <mat-checkbox class="mat-form-field-infix col-3"
                                  [(ngModel)]="data.link.softLegal.autority"
                                  (change)="onActionChange($event, 'autorityActivationDate', 'autorityResponse')"
                                  name="link_guarantor">
                        {{'CUSTOMER_LINK.GUARANTOR' | translate}}
                    </mat-checkbox>


                    <mat-form-field class="col-4">
                        <mat-label>{{'REPORTS.SENT_ON' | translate }}</mat-label>
                        <input [disabled]="!data.link.softLegal.autority"
                               name="link_pubDate2"
                               [matDatepicker]="autorityActivationDate"
                               [(ngModel)]="data.link.softLegal.autorityActivationDate"
                               matInput>
                        <mat-datepicker-toggle matSuffix [for]="autorityActivationDate"></mat-datepicker-toggle>
                        <mat-datepicker #autorityActivationDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>{{ 'TABLE_HEADINGS.RESULT' | translate }}</mat-label>
                        <mat-select  name="autorityResponse"
                                    (selectionChange)="onActionResponseChange($event, 'autorityActivationDate', 'autorityResponse', 'autority')"
                                    [(ngModel)]="data.link.softLegal.autorityResponse">
                            <mat-option [value]="null">Seleziona</mat-option>
                            <mat-option *ngFor="let option of enums.AuthorityStatus" [value]="option.value == 1">
                                {{option.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- bing -->
            <div class="col-6">
                <div class="row align-items-center">
                    <mat-checkbox class="mat-form-field-infix col-3"
                                  [(ngModel)]="data.link.softLegal.isBingBlocked"
                                  (change)="onActionChange($event, 'bingActivationDate', 'bingResponse')"
                                  name="link_bing">
                        Bing
                    </mat-checkbox>


                    <mat-form-field class="col-4">
                        <mat-label>{{'REPORTS.SENT_ON' | translate }}</mat-label>
                        <input [disabled]="!data.link.softLegal.isBingBlocked"
                               name="link_pubDate6"
                               [matDatepicker]="BingActivationDate"
                               [(ngModel)]="data.link.softLegal.bingActivationDate"
                               matInput>
                        <mat-datepicker-toggle matSuffix [for]="BingActivationDate"></mat-datepicker-toggle>
                        <mat-datepicker #BingActivationDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>{{ 'TABLE_HEADINGS.RESULT' | translate }}</mat-label>
                        <mat-select  name="bingResponse"
                                    (selectionChange)="onActionResponseChange($event, 'bingActivationDate', 'bingResponse', 'isBingBlocked')"
                                    [(ngModel)]="data.link.softLegal.bingResponse">
                            <mat-option [value]="null">Seleziona</mat-option>
                            <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                                {{option.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </section>

    <!-- Istanze -->
    <section class="col-12" 
             *ngIf="data.link.softLegal.contentId != null && data.link.softLegal.contentId !== 0 && data.link.softLegal.instanceId != null && data.link.softLegal.instanceId != 0">
        <strong class="subheading-underline-blue-light mt-4 mb-3 col-12" style="padding: 0; display: block">
            {{'TITLES.INSTANCE' | translate}}
        </strong>
        <div class="row">
            <mat-form-field class="col-3">
                <mat-label>{{ ('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('TITLES.INSTANCE' | translate) }}</mat-label>
                <mat-select name="link_instanceResponse"
                            (selectionChange)="isResponseChanged = true; toBeSaved = true"
                            [(ngModel)]="data.link.softLegal.instanceResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.InstanceLinkStatus" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-3" *ngIf="data.link.softLegal.lastCheck">
                <mat-label>{{'TABLE_HEADINGS.MANUAL_CHECK' | translate}}</mat-label>
                <input name="link_manualCheckDate"
                       [matDatepicker]="pickerManualCheck"
                       [(ngModel)]="data.link.softLegal.lastCheck"
                       [disabled]="true"
                       matInput>
                <mat-datepicker-toggle matSuffix [for]="pickerManualCheck"></mat-datepicker-toggle>
                <mat-datepicker #pickerManualCheck></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-3" *ngIf="data.link.softLegal.lastAutoCheck">
                <mat-label>{{'TABLE_HEADINGS.LAST_CHECK' | translate}}</mat-label>
                <input name="link_lastCheckDate"
                       [matDatepicker]="pickerlastCheck"
                       [(ngModel)]="data.link.softLegal.lastAutoCheck.lastCheck"
                       [disabled]="true"
                       matInput>
                <mat-datepicker-toggle matSuffix [for]="pickerlastCheck"></mat-datepicker-toggle>
                <mat-datepicker #pickerlastCheck></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-2" *ngIf="data.link.softLegal.lastAutoCheck">
                <mat-label>{{'TABLE_HEADINGS.STATUS' | translate}}</mat-label>
                <input name="link_httpStatus"
                       [(ngModel)]="data.link.softLegal.lastAutoCheck.httpStatus"
                       disabled
                       matInput>
            </mat-form-field>

            <div class="col-1 mt-3">
                <img *ngIf="data.link.softLegal.statusNotMatch === true" src="/app_assets/svg/material-icons/priority_high_circle.svg">
                <img *ngIf="data.link.softLegal.statusNotMatch === false" src="/app_assets/svg/material-icons/check_circle_green.svg">
            </div>
        </div>
    </section>

    <!-- SAVE -->
    <section class="col-12 mt-4" *ngIf="data.link.softLegal.contentId != 0">
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    style="min-width:125px"
                    [disabled]="linkForm.invalid"
                    (click)="checkUrl()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </section>

    <section class="col-12" *ngIf="data.link.softLegal.contentId && data.link.softLegal.contentId !== 0">
        <div class="mat-typography pt-4">
            <strong class="subheading-underline-blue-light pb-2 d-block">{{'TITLES.EVENTS' | translate}}</strong>
            <div class="row justify-content-end">
                <!-- ADD EVENT BUTTON -->
                <button mat-raised-button
                        type="button"
                        class="add_btn uniumx-button subheadButton level2-button mt-3"
                        style="margin-right:15px;"
                        (click)="selectEvent()">
                    <img src="/app_assets/svg/material-icons/list_blue.svg">
                    <span class="uniumx-button-text"> {{'BUTTONS.SELECT_EVENTS' | translate}}</span>
                </button>
            </div>
        </div>
        <mat-card  class="p-0 mt-3 inner-container w-100" style="max-height: 45vh">
            <rm-table *ngIf="data.link.softLegal.contentId !== 0"
                      #linkTable
                      [contactId]="data.link.contactId"
                      [options]="tableOptions"
                      [entityId]="data.link.contentId">
            </rm-table>
        </mat-card>
    </section>
    <!-- Actions -->
    <!-- Linking documents -->
    <section class="col-12 alertUpload" *ngIf="data.link.softLegal.contentId && data.link.softLegal.contentId !== 0">
        <div class="mat-typography pt-4">
            <strong class="subheading-underline-blue-light pb-2 d-block">{{'TITLES.DOCUMENTS' | translate}}</strong>

            <div class="row justify-content-end">

                <div class="mat-typography" #assetPanel>
                    <strong class="subheading mb-2">
                        <a mat-raised-button
                           color="primary"
                           [matMenuTriggerFor]="assetsMenu"
                           class="add_btn uniumx-button ml-auto level2-button mt-3 mr-3">
                            <img src="/app_assets/svg/material-icons/cloud_upload_blue.svg">
                            <span class="uniumx-button-text">{{'BUTTONS.UPLOAD_FILE' | translate}}</span>
                        </a>

                        <!-- Menu assets Generic/CustomerImage -->
                        <mat-menu #assetsMenu="matMenu" class="content_document_menu">
                            <!-- Genric -->
                            <button mat-menu-item class="menu-btn">
                                <rm-file-uploader #docUploader
                                                  [content]="data.link"
                                                  [headers]="uploadHeaders_type0"
                                                  [defaultStyle]="false"
                                                  (input)="onUpload()"
                                                  [acceptMimeTypes]="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                                  (uploadFinished)="fileUploadFinished($event)"
                                                  [uploadText]="'BUTTONS.UPLOAD_FILE' | translate"
                                                  [uploadUrl]="config.ApiUrls.UPLOAD_CONTACTS_SOFT_LEGAL">
                                    <img class="mr-1" src="/app_assets/svg/material-icons/add_blue.svg">
                                    {{'ENUMS.CONTENT_DOCUMENT_TYPE.GENERAL' | translate}}
                                </rm-file-uploader>
                            </button>
                            <!-- CustomerImage -->
                            <button mat-menu-item class="menu-btn" [disabled]="totalDocumentsForCustomer > 0">
                                <rm-file-uploader #docUploader
                                                  [content]="data.link"
                                                  [headers]="uploadHeaders_type1"
                                                  [defaultStyle]="false"
                                                  (input)="onUpload()"
                                                  [acceptMimeTypes]="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                                  (uploadFinished)="fileUploadFinished($event)"
                                                  [uploadText]="'BUTTONS.UPLOAD_FILE' | translate"
                                                  [uploadUrl]="config.ApiUrls.UPLOAD_CONTACTS_SOFT_LEGAL">
                                    <img class="mr-1" src="/app_assets/svg/material-icons/add_blue.svg">
                                    {{'ENUMS.CONTENT_DOCUMENT_TYPE.CUSTOMER_LINK_IMAGE' | translate}}
                                </rm-file-uploader>
                            </button>
                        </mat-menu>
                    </strong>
                </div>
            </div>

            <mat-card  *ngIf="!uploader" class="p-0 mt-3 inner-container w-100">
                <app-customer-documents-table #linkDocument
                                              [entityId]="data.link.contactId"
                                              [contentId]="data.link.contentId"
                                              [downloadUrl]="config.ApiUrls.DOWNLOAD_LINK_DOCUMENT"
                                              (totalDocumentsForCustomer)="totalDocumentsForCustomer = $event">
                </app-customer-documents-table>
            </mat-card>
        </div>
    </section>
    <!-- Linking Events -->
</form>
