export class CustomerEvent {
    static count: number = 0;
    public _id: number;
    public _checked: boolean;
    public id: number;
    public description: string;
    public notes: string;
    public sentiment: number;
    public dayUnknown: boolean;
    public isLinked: boolean;
    public assetId: number;
    public assetCount: number;
    public startDate: Date;
    public endDate: Date;
    public weight: number;
    public isHideInChart: boolean;

    constructor(public contactId: number, public isAppointment: boolean = false) {

        this._id = CustomerEvent.count++;
        this.description = null;
        this.notes = null;
        this.sentiment = 0;
        this.dayUnknown = false;
        this.isLinked = false;
        this.assetId = 0;
        this.assetCount = 0;
        this.startDate = new Date();
        this.endDate = null;
        this.weight = 0;
        this.isHideInChart = false;
    }
}
