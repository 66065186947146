import { SERPSentiment } from "./Enums";
import { ContentModel } from "./content/ContentModel";

export interface SERPLinkPositionData {
    linkId: number;
    position: number;
    date: string;
}

export interface SERPLinkPositionTrend {
    positions: SERPLinkPositionData[];
    dates: Date[];
}

export interface DiagramResult {
    macroCategoryName: string
    percGoal: number
    percASIS: number
    isUnrelated?: boolean
    macroCategoryId?: number
    weight?: number
    count?: number
    subCategories?: SubCategory[]
    color?: string
    prevPercASIS?: number
}

interface SubCategory {
    subCategoryId: number
    subCategoryName: string
    count: number
    contentIds: number[]
}

export class SERPQuery {

    id: number = 0;
    insertDate: Date = new Date();
    language: number = 0;
    keyword: string = null;
    frequency: number = 0;
    scheduledDate: Date = null;
    canSchedule: boolean = false;
    processed: boolean = false;
    queryUrl: string = null;
    parameters: any = null;
    queryUrlFromParameters: string = null;

    results: SERPResult[] = [];

    //UI
    uuleDisplay: string = null;
    hlDisplay: string = null;
    glDisplay: string = null;
    description: string = null;
    querySearchKeywords: string = null;
    querySearchEngineHost: string = null;

    constructor(public customerId: number) {

    }
}

export class SERPResult {
    _index: number;
    id: number;
    queryId: number;
    queryDate: Date;
    success: boolean;

    links: ContentModel[];
}
