import { Routes } from '@angular/router';
import { Config } from './app.constants';

export const routes: Routes = [
    { path: Config.RoutingUrls.FIND_CONTACTS, loadComponent: () => import('./pages/find-contacts/find-contacts.component') },
    { path: Config.RoutingUrls.CREATE_CONTACT, loadComponent: () => import('./pages/create-customer/create-customer.component') },
    { path: Config.RoutingUrls.CUSTOMER_DETAILS, loadComponent: () => import('./pages/project-details/project-details.component') },
    { path: Config.RoutingUrls.CONTENT, loadComponent: () => import('./pages/content/content.component') },
    { path: Config.RoutingUrls.LOGIN, loadComponent: () => import('./pages/login/login.component') },
    { path: Config.RoutingUrls.DOMAINS, loadComponent: () => import('./pages/domains/domains.component') },
    { path: Config.RoutingUrls.TTD, loadComponent: () => import('./pages/ttd/ttd.component') },
    { path: Config.RoutingUrls.TTD_DETAILS, loadComponent: () => import('./pages/ttd/ttd-details/ttd-details.component') },
    { path: Config.RoutingUrls.TOOLS, loadComponent: () => import('./pages/tools/tools.component') },
    { path: Config.RoutingUrls.USERMANAGEMENT, loadComponent: () => import('./pages/user-management/user-management.component') },
    { path: '', redirectTo: Config.RoutingUrls.LOGIN, pathMatch: 'full' },
    { path: '**', loadComponent: () => import('./pages/page-not-found/page-not-found.component') }
];