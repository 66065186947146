import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { JsonReply, WorkingTeam, BRCustomerContentId  } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/WritingTeam')
export class ContentWritingTeamService {
    constructor(private httpClient: HttpClient) {
    }

    @Route('ForContent')
    public getForContent(BRCustomerContentId: BRCustomerContentId, url: string = ''): Promise<JsonReply<WorkingTeam>> {
        return this.httpClient.post<JsonReply<WorkingTeam>>(url, BRCustomerContentId)
            .toPromise();
    }

    @Route('Update')
    public update(team: WorkingTeam, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, team)
            .toPromise();
    }

    @Route('Delete')
    public delete(contentId: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, contentId)
            .toPromise();
    }
}