<!-- CLOSE BTN: Start -->
<div class="position-relative">
    <button mat-icon-button class="close-dialog-btn" type="button" #closeBtn (click)="_cancel()">
        <img src="/app_assets/svg/material-icons/clear_grey.svg">
    </button>
</div>
<!-- CLOSE BTN: End -->
<!-- HEADING: Start -->
<h2 mat-dialog-title class="justify-content-start p-0 mb-3 heading-underline-blue">
    <img class="mr-3 dialog-icon"
         *ngIf="data.icon"
         src="/app_assets/svg/material-icons/{{data.icon}}.svg">
    <strong>{{data.title | translate:data.translateParams}}<span *ngIf="data.afterTitle"> - {{data.afterTitle}}</span></strong>
</h2>
<!-- HEADING: End -->
<!-- ACTIONS: Start -->
<mat-dialog-actions *ngIf="data.type === 'default'">
    <button mat-button color="primary" (click)="_ok()">Ok</button>
    <button *ngIf="data.showCancelBtn" mat-button color="primary" (click)="_cancel()">{{'BUTTONS.CANCEL' | translate}}</button>
</mat-dialog-actions>
<!-- ACTIONS: End -->
<!-- MASSIVE BROWSER: Start -->
<mat-dialog-actions *ngIf="data.type === 'massiveImport'">
    <!--Import in SoftLegal-->
    <mat-checkbox [(ngModel)]="importFromUniumOptions.ImportToSoftLegal" class="col-12" style="margin-top: 1.3em" name="importInSoftLegal">
        {{'MESSAGES.IMPORT_IMPORTINSOFTLEGAL' | translate}}
    </mat-checkbox>
    <!-- solo per i campi selezionati
            Preview (valore)
    -Autore
    -Data di pubblicazione-->
    <!--Take from Unium-->
    <mat-checkbox [(ngModel)]="importFromUniumOptions.TakeFromUnium" class="col-12" style="margin-top: 1.3em" name="takeFromUnium">
        {{'MESSAGES.IMPORT_ALERT' | translate}} {{'TABLE_HEADINGS.PREVIEW' | translate}}, {{'TABLE_HEADINGS.AUTHOR' | translate}}  {{'MESSAGES.AND' | translate}} {{'TABLE_HEADINGS.PUBLISHING_DATE' | translate}}?
    </mat-checkbox>
    <button mat-button color="primary" (click)="massiveBrowser()">Ok</button>
    <button *ngIf="data.showCancelBtn" mat-button color="primary" (click)="_cancel()">{{'BUTTONS.CANCEL' | translate}}</button>
</mat-dialog-actions>
<!-- MASSIVE BROWSER: End -->

<div *ngIf="data.type === 'channelChart'">
    <app-channels-chart [customer]="data.data" (closeModal)="_ok()"></app-channels-chart>
</div>

<div *ngIf="data.type === 'channelsSetStatus'">
   <widget-set-channel [data]="data.data" (closeModal)="_ok()" />
</div>

<div *ngIf="data.type === 'softLegalShareStatus'">
   <widget-share-status [data]="data.data"></widget-share-status>
</div>

<!-- LINK CONTACT TEMPLATE: Start -->
<div *ngIf="data.type === 'contact'">
    <mat-card >
        <!--Name-->
        <mat-form-field class="col-6 col-md-4">
            <mat-label>{{'CONTACT.NAME' | translate}}</mat-label>
            <input [(ngModel)]="tableOptions.params.name"
                   #name="ngModel"
                   name="name_filter"
                   matInput
                   >
            <button *ngIf="tableOptions.params.name" matSuffix mat-icon-button aria-label="Clear" (click)="tableOptions.params.name=''">
                <i class="material-icons clear-input-btn">close</i>
            </button>
        </mat-form-field>
        <!--Surname-->
        <mat-form-field class="col-6 col-md-4">
            <mat-label>{{'CONTACT.SURNAME' | translate}}</mat-label>
            <input name="surname_filter"
                   [(ngModel)]="tableOptions.params.surname"
                   matInput
                   >
            <button *ngIf="tableOptions.params.surname" matSuffix mat-icon-button aria-label="Clear" (click)="tableOptions.params.surname=''">
                <i class="material-icons clear-input-btn">close</i>
            </button>
        </mat-form-field>
        <!-- First contact from-->
        <mat-form-field class="col-6 col-md-4">
            <mat-label>{{'CONTACT.FIRST_CONTACT' | translate}} - {{'TITLES.FROM' | translate}}</mat-label>
            <input name="firstContactFrom_filter"
                   [(ngModel)]="tableOptions.params.firstContactDateFrom"
                   matInput
                   [matDatepicker]="firstContactFromPickerFilter"
                   >
            <mat-datepicker-toggle matSuffix [for]="firstContactFromPickerFilter"></mat-datepicker-toggle>
            <mat-datepicker #firstContactFromPickerFilter></mat-datepicker>
        </mat-form-field>
        <!-- First contact to -->
        <mat-form-field class="col-6 col-md-4">
            <mat-label>{{'CONTACT.FIRST_CONTACT' | translate}} - {{'TITLES.TO' | translate}}</mat-label>
            <input name="firstContactTo_filter"
                   [(ngModel)]="tableOptions.params.firstContactDateTo"
                   matInput
                   [matDatepicker]="firstContactToPickerFilter"
                   >
            <mat-datepicker-toggle matSuffix [for]="firstContactToPickerFilter"></mat-datepicker-toggle>
            <mat-datepicker #firstContactToPickerFilter></mat-datepicker>
        </mat-form-field>
        <div class="d-flex justify-content-end">
            <button mat-button
                    class="pr-4 mr-1 level2-button"
                    color="primary"
                    (click)="updateList()">
                <img class="mb-1 mr-2" src="/app_assets/svg/material-icons/search_blue.svg">
                {{'BUTTONS.FIND' | translate}}
            </button>
        </div>
    </mat-card>
    <!-- PAGINATOR: Start -->
    <div class="pr-2 w-100" *ngIf="tableOptions.pagination.enabled">
        <rm-paginator class="w-100"
                      [showButtons]="tableOptions.pagination.showButtons"
                      [params]="tableOptions.params"
                      [updatePaginationEvent]="updatePagination"
                      (paginationParamsChanged)="updateList()"></rm-paginator>
    </div>
    <!-- PAGINATOR: End -->
    <div class="row">
        <mat-form-field class="col-4">
            <mat-label>{{ 'TITLES.CONTACT' | translate }}</mat-label>
            <mat-select 
                        name="contact_alert"
                        [(ngModel)]="data.params.entityId">
                <mat-option *ngFor="let contact of data.contacts" [value]="contact.id">
                    <span class="text-uppercase">
                        {{contact.id}} - {{contact.name}} {{contact.surname}}
                    </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>{{'TABLE_HEADINGS.DESCRIPTION' | translate}}</mat-label>
            <input name="description"
                   [(ngModel)]="data.params.description"
                   maxlength="10"
                   matInput
                   >
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>{{ 'TITLES.CONTACT_RELATIONSHIP' | translate }}</mat-label>
            <mat-select 
                        name="contact_relation"
                        [(ngModel)]="data.params.relation">
                <mat-option *ngFor="let option of enums.ContactRelationship" [value]="option.value">
                    {{option.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SAVE BUTTON -->
        <mat-dialog-actions class="mr-3 w-100">
            <button mat-raised-button color="primary"
                    class="uniumx-button level2-button"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</div>
<!-- LINK CONTACT TEMPLATE: End -->
<!-- WFT ACQUISITION TEMPLATE: Start -->
<div *ngIf="data.type === 'wftAcquisition'">
    <div *ngIf="data.request.linkUrl !== null" class="row mb-2">
        <a href="{{data.request.linkUrl}}" target="_blank" class="col-10">
            {{data.request.linkUrl}}
        </a>
    </div>
    <form #acquisitionForm="ngForm" class="row">
        <!-- SERP position -->
        <mat-form-field class="col-6">
            <mat-label>{{'WFT.CASE_ID' | translate}}</mat-label>
            <input type="number"
                   name="acquisition_caseId"
                   [(ngModel)]="data.request.caseId"
                   min="1"
                   (keydown)="shared.disallowNegative($event)"
                   matInput>
        </mat-form-field>
        <!-- Case Name -->
        <mat-form-field class="col-6 mb-2">
            <mat-label>{{'WFT.CASE_NAME' | translate}}</mat-label>
            <input name="acquisition_caseName"
                   [(ngModel)]="data.request.caseName"
                   matInput
                   >
        </mat-form-field>
        <!-- Case Notes -->
        <mat-form-field class="col-12 mb-2">
            <mat-label>{{'WFT.CASE_NOTES' | translate}}</mat-label>
            <textarea name="acquisition_caseNotes"
                      [(ngModel)]="data.request.caseNotes"
                      matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"></textarea>
        </mat-form-field>
        <!-- Use account email -->
        <p class="col-4" style="font-size: 14px;padding-top: 10px;">{{'WFT.SEND_TO_EMAIL' | translate}}</p>
        <!-- Email -->
        <mat-form-field class="col-8 mb-2" *ngIf="data.request.sendToEmail">
            <mat-label>{{'CONTACT.EMAIL' | translate}}</mat-label>
            <input name="acquisition_email"
                   [(ngModel)]="data.request.email"
                   #accountemail="ngModel"
                   email
                   matInput
                   >
            <mat-error *ngIf="accountemail.invalid && (accountemail.dirty || accountemail.touched)">
                <div *ngIf="accountemail.errors.email" class="error-hint">
                    Please, enter a valid {{'CONTACT.EMAIL' | translate}}
                </div>
            </mat-error>
        </mat-form-field>
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>
<!-- WFT ACQUISITION TEMPLATE: End -->
<!-- EVENT TEMPLATE -->
<div *ngIf="data.type === 'event'">
    <form #eventForm="ngForm" class="row">
        <!-- Description -->
        <mat-form-field class="col-12 mb-3">
            <mat-label>{{'TABLE_HEADINGS.DESCRIPTION' | translate}}</mat-label>
            <textarea name="event_description"
                      [(ngModel)]="data.event.description"
                      required matInput
                      #eventDescriptionModel="ngModel"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"></textarea>
            <mat-error *ngIf="eventDescriptionModel.invalid && (eventDescriptionModel.dirty || eventDescriptionModel.touched)">
                <div *ngIf="eventDescriptionModel.errors.required">
                    {{'TABLE_HEADINGS.DESCRIPTION' | translate}} is <strong>required</strong>
                </div>
            </mat-error>
        </mat-form-field>

        <div class="col-6" style="margin-bottom:35px">
            <mat-checkbox name="isHideInChart"
                          [(ngModel)]="data.event.isHideInChart">
                {{'TITLES.HIDE' | translate}}
            </mat-checkbox>
        </div>
        <div class="col-6" style="margin-bottom:35px">
            <mat-checkbox name="isAppointment"
                          [(ngModel)]="data.event.isAppointment">
                {{'TITLES.APPOINTMENT' | translate}}
            </mat-checkbox>
        </div>
        <div class="col-3 pr-0">
            <mat-slide-toggle name="unknown_date" [(ngModel)]="data.event.dayUnknown" class="toggleFather d-block mb-2"></mat-slide-toggle>
            {{'MESSAGES.DISABLE_DAY' | translate}}
        </div>
        <!-- DD/MM/YYYY: Start -->
        <section *ngIf="!data.event.dayUnknown" class="col-9 mb-3">
            <div class="row">
                <!-- Start date -->
                <mat-form-field class="col-6">
                    <mat-label>{{'TABLE_HEADINGS.START_DATE' | translate}}</mat-label>
                    <input name="event_startDate_day"
                           [matDatepicker]="pickerStartDateDay"
                           [(ngModel)]="data.event.startDate"
                           #eventSDPicker="ngModel"
                           matInput
                           required>
                    <mat-datepicker-toggle matSuffix [for]="pickerStartDateDay"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDateDay></mat-datepicker>
                    <mat-error *ngIf="eventSDPicker.invalid && (eventSDPicker.dirty || eventSDPicker.touched)">
                        <div *ngIf="eventSDPicker.errors.required">
                            {{'TABLE_HEADINGS.START_DATE' | translate}} is <strong>required</strong>
                        </div>
                    </mat-error>
                </mat-form-field>
                <!-- End date -->
                <mat-form-field class="col-6">
                    <mat-label>{{'TABLE_HEADINGS.END_DATE' | translate}}</mat-label>
                    <input name="event_endDate_day"
                           [(ngModel)]="data.event.endDate"
                           [matDatepicker]="pickerEndDateDay"
                           #eventEDPicker="ngModel"
                           matInput>
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDateDay"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDateDay></mat-datepicker>
                    <mat-error *ngIf="eventEDPicker.invalid && (eventEDPicker.dirty || eventEDPicker.touched)">
                        <div *ngIf="eventEDPicker.errors.required">
                            {{'TABLE_HEADINGS.END_DATE' | translate}} is <strong>required</strong>
                        </div>
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
        <!-- DD/MM/YYYY: End -->
        <!-- MM/YYYY: Start -->
        <section class="col-9 mb-3" *ngIf="data.event.dayUnknown">
            <div class="row">
                <!-- Start date -->
                <div class="col-6">
                    <app-rm-month-date-picker [propertyName]="'startDate'"
                                              [placeholder]="'TABLE_HEADINGS.START_DATE' | translate"
                                              [model]="data.event"
                                              [selector]="'pickerStartDate'"
                                              [required]="true">
                    </app-rm-month-date-picker>
                </div>
                <!-- End date -->
                <div class="col-6">
                    <app-rm-month-date-picker [propertyName]="'endDate'"
                                              [placeholder]="'TABLE_HEADINGS.END_DATE' | translate"
                                              [model]="data.event"
                                              [selector]="'pickerEndDate'">
                    </app-rm-month-date-picker>
                </div>
            </div>
        </section>

        <!-- MM/YYYY: End -->
        <!-- Notes -->
        <mat-form-field class="col-12">
            <mat-label>{{'TABLE_HEADINGS.NOTES' | translate}}</mat-label>
            <textarea name="event_notes"
                      [(ngModel)]="data.event.notes"
                      matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"></textarea>
        </mat-form-field>
        <div class="d-inline-flex flex-row w-100">
            <div class="col-6 d-flex flex-column">
                <!-- Sentiment -->
                <div class="input-label mt-2">{{'EVENT.SENTIMENT' | translate}}</div>
                <div class="d-flex align-items-center">
                    <div class="input-value ml-2 mr-2">{{data.event.sentiment}}</div>
                    <mat-slider class="w-100" min="-5" max="5" step="1" showTickMarks>
                        <input matSliderThumb [(ngModel)]="data.event.sentiment" name="event_sentiment" />
                    </mat-slider>
                </div>
            </div>
            <div class="col-6 d-flex flex-column">
                <!-- Weight -->
                <div class="input-label mt-2">{{'TABLE_HEADINGS.WEIGHT' | translate}}</div>
                <div class="d-flex align-items-center">
                    <div class="input-value ml-2 mr-2">{{data.event.weight}}</div>
                    <mat-slider class="w-100" min="-5" max="5" step="1" showTickMarks>
                        <input matSliderThumb [(ngModel)]="data.event.weight" name="event_weight" />
                    </mat-slider>
                </div>
            </div>
        </div>
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="eventForm.invalid || !data.event.startDate"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>

<!-- DOMAIN TEMPLATE -->
<div *ngIf="data.type === 'domain'">
    <form #eventForm="ngForm" class="row align-items-center">
        <!-- Url -->
        <mat-form-field class="col-12">
            <mat-label>{{'TABLE_HEADINGS.URL' | translate }}</mat-label>
            <input [(ngModel)]="data.domain.domain"
                   name="domain_url"
                   matInput
                   required
                   >
        </mat-form-field>
        <!-- Name -->
        <mat-form-field class="col-4">
            <mat-label>{{'TABLE_HEADINGS.NAME' | translate}}</mat-label>
            <input [(ngModel)]="data.domain.name"
                   name="domain_name"
                   matInput
                   >
        </mat-form-field>
        <!-- Languages -->
        <mat-form-field class="col-4">
            <mat-label>{{ 'TABLE_HEADINGS.LANGUAGES' | translate }}</mat-label>
            <mat-select [(ngModel)]="data.domain.supportedLanguages"
                        name="domain_languages"
                        multiple>
                <mat-form-field class="col-12 fieldInSelect">
                    <mat-label>{{ 'TITLES.WRITE_HERE_FILTER' | translate }}</mat-label>
                    <input  matInput class="inputInSelect" (keyup)="onKeyLanguage($event.target.value)">
                </mat-form-field>
                <mat-option *ngFor="let lang of selectedStates" [value]="lang.displayName">{{lang.name | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Selector -->
        <mat-form-field class="col-4">
            <mat-label>{{ 'TABLE_HEADINGS.DOMAIN_CATEGORY' | translate }}</mat-label>
            <mat-select 
                        name="domain_category"
                        [(ngModel)]="data.domain.domainCategoryId">
                <mat-option *ngFor="let category of shared.availableDomainCategorys" [value]="category.id"> {{category.name}} </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="linea"></div>
        <!-- default -->
        <mat-checkbox [(ngModel)]="data.domain.isDefaultChannel" class="col-6" name="domain_is_default">
            {{'CUSTOMER_CHANNEL.IS_DEFAULT' | translate}}
        </mat-checkbox>
        <!-- indexing -->
        <mat-form-field class="col-6">
            <mat-label>{{ 'CUSTOMER_CHANNEL.INDEXING' | translate }}</mat-label>
            <mat-select [(ngModel)]="data.domain.indexingEval"
                        name="domain_indexing">
                <mat-option *ngFor="let option of enums.ChannelIndexing" [value]="option.value"> {{option.name | translate}} </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Managed by reputation -->
        <mat-checkbox [(ngModel)]="data.domain.managedByReputationManager" class="col-6" name="domain_is_managedByReputationManager">
            {{'TABLE_HEADINGS.MANAGED_BY_REPUTATION-MANAGER' | translate}}
        </mat-checkbox>
        <!-- target -->
        <mat-form-field class="col-6">
            <mat-label>{{ 'CUSTOMER_CHANNEL.ACTIVITY_TARGET' | translate }}</mat-label>
            <mat-select [(ngModel)]="data.domain.activityTarget"
                        name="domain_target">
                <mat-option *ngFor="let option of enums.ActivityTarget" [value]="option.value"> {{option.name | translate}} </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Video -->
        <mat-checkbox [(ngModel)]="data.domain.typeIsVideo" class="col-6" name="domain_type" style="margin-top: 0.5rem;">
            {{'TABLE_HEADINGS.DOMAIN_TYPE' | translate}}
        </mat-checkbox>
        <!-- SAVE BTN -->
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="eventForm.invalid"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="data.type === 'apiLink'">
    <div>
        <form class="row" #apiLinkForm="ngForm">
            <mat-form-field class="col-12 mb-3">
                <mat-label>Token</mat-label>
                <textarea name="apiToken"
                          [ngModel]="data.token"
                          matInput
                          disabled
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="2"
                          cdkAutosizeMaxRows="7">
                </textarea>
            </mat-form-field>
            <mat-form-field class="col-12 mb-3">
                <mat-label>URL</mat-label>
                <textarea name="apiLinkUrl"
                          [ngModel]="data.link"
                          matInput
                          disabled
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="2"
                          cdkAutosizeMaxRows="7">
                </textarea>
            </mat-form-field>
        </form>
    </div>
    <mat-dialog-actions class="w-100 mr-3">
        <button mat-button color="primary"
                class="ml-auto"
                (click)="_ok()">
            {{'BUTTONS.COPY_TO_CLIPBOARD' | translate}}
        </button>
    </mat-dialog-actions>
</div>

<!-- LINK ENTITY TEMPLATE -->
<div *ngIf="data.type === 'linkEntity'">
    <mat-card  class="p-0 inner-container">
        <rm-table #eventsTable
                  *ngIf="tableOptions"
                  [contactId]="data.customerId"
                  [options]="tableOptions"
                  [entityId]="data.entityId">
        </rm-table>
    </mat-card>
    <mat-dialog-actions class="w-100 mr-3">
        <button mat-button color="primary"
                class="ml-auto level2-button"
                (click)="_ok()">
            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
            {{'BUTTONS.SAVE' | translate}}
        </button>
    </mat-dialog-actions>
</div>

<!-- LINK TEMPLATE -->
<div *ngIf="data.type === 'link'">
    <app-soft-legal-detail [data]="data" [tableOptions]="tableOptions" (ok)="_ok()" (alert)="alert($event)"></app-soft-legal-detail>
</div>

<!-- TAG TEMPLATE -->
<div *ngIf="data.type === 'tag'">
    <div class="row">
        <!-- ADD TAG INPUT -->
        <mat-form-field class="tag-input col-12">
            <mat-label>{{'New tag' | translate}}</mat-label>
            <input 
                   [(ngModel)]="data.tag.tag"
                   name="input"
                   matInput>
            <mat-hint align="start">You can add multiple tags by separating them with a comma.</mat-hint>
        </mat-form-field>
        <!-- ADD TAG BUTTON -->
        <mat-dialog-actions>
            <button mat-button color="primary"
                    class="ml-auto"
                    [disabled]="recallForm.invalid"
                    (click)="_ok()">
                Add
            </button>
        </mat-dialog-actions>
    </div>
</div>

<!-- CONTACT RECALL TEMPLATE -->
<div *ngIf="data.type === 'contactRecall'">
    <form class="row" #recallForm="ngForm">
        <!-- USER -->
        <mat-form-field class="col-12" *ngIf="data.availableUsers !== null">
            <mat-label>{{ 'TABLE_HEADINGS.USER' | translate }}</mat-label>
            <mat-select 
                        name="recallPerson"
                        [(ngModel)]="data.recall.sales"
                        [compareWith]="data.compareFn">
                <mat-option *ngFor="let sales of shared.availableUsers" [value]="sales"> {{sales.fullName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- DATE OF RECALL -->
        <div class="col-8 mb-3">
            <rm-datetime-picker [model]="data.recall.recallDate" [name]="recallDate" [placeHolder]="'TABLE_HEADINGS.DATE' | translate" (emitter)="data.recall.recallDate = $event" />
        </div>
        <!-- RECALL TYPE -->
        <mat-form-field class="col-4">
            <mat-label>{{ 'TABLE_HEADINGS.TYPE' | translate }}</mat-label>
            <mat-select 
                        name="recallType"
                        [(ngModel)]="data.recall.recallType">
                <mat-option *ngFor="let option of enums.RecallType" [value]="option.value"> {{option.name | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- RECALL NOTES -->
        <mat-form-field class="col-12">
            <mat-label>{{'CONTACT.NOTES' | translate}}</mat-label>
            <textarea name="recallNote"
                      [(ngModel)]="data.recall.recallResult"
                      matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"></textarea>
        </mat-form-field>
        <!-- RECALL SUCCESS -->
        <div class="col-12 mat-typography recall-checkbox">
            {{'MESSAGES.RECALL_SUCCEEDED' | translate}}:
            <mat-checkbox class="ml-3"
                          name="recallSucceeded"
                          [(ngModel)]="data.recall.succeeded">
            </mat-checkbox>
        </div>
        <!-- ADD RECALL BUTTON -->
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="recallForm.invalid"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>

<!-- CUSTOMER RECALL TEMPLATE -->
<div *ngIf="data.type === 'customerRecall'">
    <form class="row" #recallForm="ngForm">
        <!-- USER -->
        <mat-form-field class="col-12" *ngIf="data.availableUsers !== null">
            <mat-label>{{ 'TABLE_HEADINGS.USER' | translate }}</mat-label>
            <mat-select 
                        name="recallPerson"
                        [(ngModel)]="data.recall.sales"
                        [compareWith]="data.compareFn"
                        disabled>
                <mat-option *ngFor="let sales of shared.availableUsers" [value]="sales">
                    {{roleNumberToString(sales.userGroup)}}{{sales.fullName}} ({{userRole}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- DATE OF RECALL -->
        <div class="col-12 mb-3">
            <rm-datetime-picker [model]="data.recall.recallDate" [name]="recallDate" [placeHolder]="'TABLE_HEADINGS.DATE' | translate" (emitter)="data.recall.recallDate = $event" />
        </div>
        <!-- RECALL NOTES -->
        <mat-form-field class="col-12">
            <mat-label>{{'CONTACT.ACTIVITY' | translate}}</mat-label>
            <textarea name="recallNote"
                      [(ngModel)]="data.recall.recallResult"
                      matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"></textarea>
        </mat-form-field>
        <div class="col-12 mat-typography recall-checkbox mb-4">
            {{'TABLE_HEADINGS.COMPLETED'| translate}}:
            <mat-checkbox class="ml-3" name="recallCompleted" [(ngModel)]="data.recall.completed"></mat-checkbox>
        </div>
        <div class="row col-12 m-0 fontFamily" *ngIf="data.recall.id">
            <rm-file-uploader #fileUploader
                                class="uniumx-button d-inline-block"
                                [uploadText]="'MESSAGES.UPLOAD_STARTED' | translate"
                                [uploadUrl]="config.ApiUrls.UPLOAD_RECALL_FILE"
                                [headers]="createHeaders(data.recall.id)"
                                (uploadFinished)="fileUploadFinished($event, true)">
                <img src="/app_assets/svg/material-icons/folder_open.svg">
                <span class="uniumx-button-text">{{'BUTTONS.UPLOAD_FILE' | translate}}</span>
            </rm-file-uploader>
        </div>
        <div class="row col-12 pt-3 m-0">
            <button mat-raised-button *ngIf="data.recall.fileName" type="button" class="add_btn uniumx-button level2-button" (click)="handleDownloadRecall(data.recall)">
                <img src="/app_assets/svg/material-icons/save_alt_blue.svg">
                <span class="uniumx-button-text"> Download</span>
            </button>
            <button mat-raised-button *ngIf="data.recall.fileName" type="button" class="add_btn uniumx-button level2-button ml-4" (click)="handleRemoveRecall(data.recall, true)">
                <img src="/app_assets/svg/material-icons/delete_blue.svg">
                <span class="uniumx-button-text"> Rimuovi file</span>
            </button>
        </div>
        <!-- ADD RECALL BUTTON -->
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="recallForm.invalid"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>

<!-- CUSTOMER REMINDER TEMPLATE -->
<div *ngIf="data.type === 'customerReminder'">
    <form class="row" #recallForm="ngForm">
        <!-- USER -->
        <mat-form-field class="col-12" *ngIf="data.availableUsers !== null">
            <mat-label>{{ 'TABLE_HEADINGS.USER' | translate}}</mat-label>
            <mat-select 
                        name="recallPerson"
                        [(ngModel)]="data.recall.sales"
                        [compareWith]="data.compareFn">
                <mat-option *ngFor="let sales of shared.availableUsers" [value]="sales"> {{sales.fullName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- DATE OF RECALL -->
        <div class="col-12 mb-3">
            <rm-datetime-picker [model]="data.recall.recallDate" [name]="recallDate" [placeHolder]="'TABLE_HEADINGS.DATE' | translate" (emitter)="data.recall.recallDate = $event" />
        </div>
        <!-- RECALL NOTES -->
        <mat-form-field class="col-8">
            <mat-label>{{'TABLE_HEADINGS.NOTES' | translate}}</mat-label>
            <input name="recallNote"
                   [(ngModel)]="data.recall.recallResult"
                   matInput>
        </mat-form-field>
        <!-- RECALL TYPE -->
        <mat-form-field class="col-4">
            <mat-label>{{ 'TABLE_HEADINGS.TYPE' | translate }}</mat-label>
            <mat-select 
                        name="recallType"
                        [(ngModel)]="data.recall.recallType">
                <mat-option *ngFor="let option of enums.RecallType" [value]="option.value"> {{option.name | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- RECALL SUCCESS -->
        <div class="col-12 mat-typography recall-checkbox">
            {{'TABLE_HEADINGS.COMPLETED'| translate}}:
            <mat-checkbox class="ml-3"
                          name="recallSucceeded"
                          [(ngModel)]="data.recall.succeeded">
            </mat-checkbox>
        </div>
        <!-- ADD RECALL BUTTON -->
        <mat-dialog-actions class="w-100 mr-3">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="recallForm.invalid"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>

<!-- VALUES TEMPLATE -->
<div *ngIf="data.type === 'value'">
    <app-select-values-only-macro #selectValuesMacro [initMacroCategories]="data.entity.macroCategories"></app-select-values-only-macro>
    <!-- ADD VALUES BUTTON -->
    <mat-dialog-actions class="d-flex justify-content-end align-items-center mr-3">
        <!--<mat-error class="mat-typography ml-2 mr-3" *ngIf="valueForm.invalid">Please provide valid values</mat-error>-->
        <!--[disabled]="valueForm.invalid"-->
        <button mat-raised-button color="primary"
                class="uniumx-button level2-button"
                (click)="saveValues()">
            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
            {{'BUTTONS.SAVE' | translate}}
        </button>
    </mat-dialog-actions>
</div>

<!-- SELECT INSTANCE TEMPLATE -->
<div *ngIf="data.type === 'selectInstance'">
    <div class="row">
        <mat-form-field class="col-12">
            <mat-label>{{ 'TITLES.INSTANCE' | translate }}</mat-label>
            <mat-select 
                        name="instance"
                        [(ngModel)]="data.params.entityId">
                <mat-option *ngFor="let instance of data.instances" [value]="instance.id">
                    <span class="text-uppercase text-bold">
                        {{(instance.status | valueOfEnum: enums.InstanceStatus:true).name | translate}}
                    </span>
                    {{instance.ttdName}} &ndash;
                    {{instance.eventName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SAVE BUTTON -->
        <mat-dialog-actions class="mr-3 w-100">
            <button mat-raised-button color="primary"
                    class="uniumx-button level2-button"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</div>

<!-- SELECT EVENT TEMPLATE -->
<div *ngIf="data.type === 'selectEvent'">
    <div class="row">
        <mat-form-field class="col-12">
            <mat-label>{{ 'TITLES.EVENTS' | translate }}</mat-label>
            <mat-select 
                        name="event"
                        [(ngModel)]="data.params.entityId">
                <mat-option *ngFor="let event of data.events" [value]="event.id">
                    {{event.description}} - {{event.startDate | date:config.NativeDateFormat}} &ndash;
                    {{event.endDate| date:config.NativeDateFormat}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SAVE BUTTON -->
        <mat-dialog-actions class="mr-3 w-100">
            <button mat-raised-button color="primary"
                    class="uniumx-button level2-button"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</div>

<!-- INSTANCE LINK TEMPLATE -->
<div *ngIf="data.type === 'instanceLink'">
    <div class="row">
        <!-- URL -->
        <mat-form-field class="col-12 mb-3">
            <mat-label>{{'TABLE_HEADINGS.URL' | translate}}</mat-label>
            <textarea name="instance_link_url"
                      [(ngModel)]="data.link.url"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="7"
                      matInput>
            </textarea>
        </mat-form-field>
        <!-- STATUS -->
        <mat-form-field class="col-12">
            <mat-label>{{ 'TABLE_HEADINGS.STATUS' | translate }}</mat-label>
            <mat-select 
                        name="instance_link_status"
                        [(ngModel)]="data.link.status">
                <mat-option *ngFor="let option of enums.SoftLegalLinkStatus" [value]="option.value">
                    {{option.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SAVE BUTTON -->
        <mat-dialog-actions class="mr-3 w-100">
            <button mat-raised-button color="primary"
                    class="uniumx-button level2-button"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</div>

<!-- LINK TREND TEMPLATE -->
<div *ngIf="data.type === 'serpLinkTrend'">
    <app-link-position-trend [trend]="data.trend"></app-link-position-trend>
</div>

<!--SOFT LEGAL-->
<div *ngIf="data.type === 'massiveSoftLegal'">
    <soft-legal-massive [data]="data" [tab]="data.tab" (ok)="_ok()" (alert)="alert($event)"></soft-legal-massive>
</div>

<!-- Edit many TEMPLATE
    Nuova modifica-->
<div *ngIf="data.type === 'editMany'">
    <div class="row buttonLevel">
        <div class="col-12 justify-content-center">
            <!-- Case Type -->
            <mat-form-field class="col-6" style="padding-right:20px">
                <mat-label>{{ 'CONTACT.CASE_TYPE' | translate }}</mat-label>
                <mat-select 
                            [ngClass]="{'beforeScary2': enableMassiveCaseType}"
                            name="caseType"
                            (selectionChange)="changeCaseType($event)"
                            [(ngModel)]="selectedCaseType">
                    <mat-option *ngFor="let caseType of enums.CaseType" [value]="caseType.value">
                        {{caseType.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Sales -->
            <mat-form-field class="col-6" style="padding-left:20px">
                <mat-label>{{ 'CONTACT.SALES' | translate }}</mat-label>
                <mat-select id="salesAssignedBox"
                            name="salesAssigned"
                            [ngClass]="{'beforeScary2': enableMassiveSales}"
                            (selectionChange)="changeSales($event)"
                            [compareWith]="domainUserCompareFn"
                            [(ngModel)]="AssignedSales">
                    <mat-option *ngFor="let sales of availableSales" [value]="sales"> {{sales.fullName}} </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Relationship type -->
            <mat-form-field class="col-6" style="padding-right:20px">
                <mat-label>{{ 'CONTACT.RELATIONSHIP' | translate }}</mat-label>
                <mat-select 
                            id="statusBox"
                            [ngClass]="{'beforeScary2': enableMassiveRelationship}"
                            (selectionChange)="changeRelationship($event)"
                            name="relationship_type"
                            [(ngModel)]="ContactStatus">
                    <mat-option *ngFor="let status of enums.ContactStatus" [value]="status.value">
                        {{status.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Status -->
            <mat-form-field class="col-6" style="padding-left:20px">
                <mat-label>{{ 'CONTACT.NEGOTIATION_STATUS' | translate }}</mat-label>
                <mat-select 
                            name="commercial_status"
                            [ngClass]="{'beforeScary2': enableMassiveStatus}"
                            (selectionChange)="changeStatus($event)"
                            [(ngModel)]="NegotiationStatus">
                    <mat-option *ngFor="let workflowResult of enums.WorkFlowResult" [value]="workflowResult.value">
                        {{workflowResult.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SAVE BUTTON -->
            <mat-dialog-actions class="mr-3 w-100 justify-content-center" style="display:block">
                <div style="display:inline-flex;float:right">
                    <button mat-button
                            class="uniumx-button level2-button"
                            style="margin:15px 0px 0px 15px;width: 125px;"
                            (click)="reseteditManyFindContacts()">
                        <img class="mr-2" src="/app_assets/svg/material-icons/cached_blue.svg">
                        Reset
                    </button>
                    <button mat-button
                            class="uniumx-button level2-button"
                            style="margin:15px 0px 0px 15px;width: 125px;"
                            (click)="editMassive()">
                        <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                        {{'BUTTONS.SAVE' | translate}}
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
    </div>
</div>

<!-- Edit many TEMPLATE
    Nuova modifica-->
<div *ngIf="data.type === 'massiveContents'">
    <div class="row buttonLevel">
        <div class="col-12 justify-content-center">
            <div class="row">
                <!-- PERTINENCE -->
                <mat-form-field class="col-3">
                    <mat-label>{{ 'TABLE_HEADINGS.PERTINENCE' | translate }}</mat-label>
                    <mat-select 
                                [(ngModel)]="data.contents[0].pertinence"
                                name="content_pertinence">
                        <mat-option *ngFor="let option of enums.Pertinence"
                                    [value]="option.value">
                            {{option.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Evaluation -->
                <mat-form-field class="col-3">
                    <mat-label>{{ 'TABLE_HEADINGS.EVALUATION' | translate }}</mat-label>
                    <mat-select 
                                [(ngModel)]="data.contents[0].evaluation"
                                name="content_evaluation">
                        <mat-option *ngFor="let option of enums.ContentEvaluation"
                                    [value]="option.value">
                            {{option.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Sender -->
                <mat-form-field class="col-3">
                    <mat-label>{{ 'CONTENT.SENDER' | translate }}</mat-label>
                    <mat-select 
                                [(ngModel)]="data.contents[0].sender"
                                name="content_sender">
                        <mat-option *ngFor="let option of enums.Sender"
                                    [value]="option.value">
                            {{option.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- TAGS -->
                <mat-form-field class="tag-list col-3">
                    <mat-chip-grid #keywordList>
                        <mat-chip-row *ngFor="let keyword of keywords" selectable="true"
                                  (removed)="deleteKeyword(keyword, keywords)">
                            {{keyword.tag}}
                            <img src="/app_assets/svg/material-icons/cancel_blue.svg" matChipRemove>
                        </mat-chip-row>
                        <mat-label>{{'ASSET.TAGS' | translate}}</mat-label>
                        <input 
                               [matChipInputFor]="keywordList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addKeyword($event, keywords)">
                    </mat-chip-grid>
                </mat-form-field>
                <!-- link to event -->

                <mat-form-field class="col-2">
                    <mat-label>{{ 'TITLES.EVENTS' | translate }}</mat-label>
                    <mat-select 
                                name="event"
                                [(ngModel)]="data.contents[0].entityId">
                        <mat-option *ngFor="let event of data.events" [value]="event.id">
                            {{event.description}} - {{event.startDate | date:config.NativeDateFormat}} &ndash;
                            {{event.endDate| date:config.NativeDateFormat}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-dialog-actions class="col-2" style="padding: 12px 6px">
                    <mat-checkbox [(ngModel)]="data.contents[0].importToSoft" style="top: -10px" class="col-12" name="importInSoftLegal">
                        <span>{{'MESSAGES.IMPORT_IMPORTINSOFTLEGAL' | translate}}</span>
                    </mat-checkbox>
                </mat-dialog-actions>
                <!-- VALUES -->
                <section class="col-12 mt-4">
                    <div class="mat-typography">
                        <strong class="subheading-underline-blue-light rm-divider pb-2">
                            {{'TITLES.VALUE_MATRIX' | translate}}
                        </strong>
                    </div>
                    <div style="margin-top: 40px;" *ngIf="showValues">
                        <app-select-values-only-macro style="width: 100%" #selectValuesMacro [initMacroCategories]="massiveMacroCategories"></app-select-values-only-macro>
                    </div>
                </section>
                <!-- SAVE BUTTON -->
                <mat-dialog-actions class="mr-3 w-100 justify-content-center" style="display:block">
                    <div style="display:inline-flex;float:right">
                        <button mat-button
                                class="uniumx-button level2-button"
                                style="margin:15px 0px 0px 15px;width: 125px;"
                                (click)="resetMassive()">
                            <img class="mr-2" src="/app_assets/svg/material-icons/cached_blue.svg">
                            Reset
                        </button>
                        <button mat-button
                                class="uniumx-button level2-button"
                                style="margin:15px 0px 0px 15px;width: 125px;"
                                (click)="massiveContents()">
                            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                            {{'BUTTONS.SAVE' | translate}}
                        </button>
                    </div>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>

<!-- default channel add TEMPLATE
    Nuova modifica-->
<div *ngIf="data.type === 'defaultChannelAdd'">
    <div class="row buttonLevel">
        <div class="col-12 justify-content-center">
            <!-- Case Type -->
            <mat-form-field class="col-6" style="padding-right:20px">
                <mat-label>{{ 'CONTACT.CASE_TYPE' | translate }}</mat-label>
                <mat-select 
                            multiple
                            [ngClass]="{'beforeScary2': enableMassiveCaseType}"
                            [withDefault]="0"
                            name="caseType"
                            (selectionChange)="changeCaseType($event)"
                            [(ngModel)]="newContact.caseType">
                    <mat-option *ngFor="let caseType of enums.CaseType" [value]="caseType.value">
                        {{caseType.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Sales -->
            <mat-form-field class="col-6" style="padding-left:20px">
                <mat-label>{{ 'CONTACT.SALES' | translate}}</mat-label>
                <mat-select 
                            id="salesAssignedBox"
                            name="salesAssigned"
                            [ngClass]="{'beforeScary2': enableMassiveSales}"
                            (selectionChange)="changeSales($event)"
                            [compareWith]="domainUserCompareFn"
                            [(ngModel)]="newContact.assignedSales">
                    <mat-option *ngFor="let sales of availableSales" [value]="sales"> {{sales.fullName}} </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Relationship type -->
            <mat-form-field class="col-6" style="padding-right:20px">
                <mat-label>{{ 'CONTACT.RELATIONSHIP' | translate }}</mat-label>
                <mat-select 
                            id="statusBox"
                            [ngClass]="{'beforeScary2': enableMassiveRelationship}"
                            (selectionChange)="changeRelationship($event)"
                            name="relationship_type"
                            [(ngModel)]="newContact.contactStatus">
                    <mat-option *ngFor="let status of enums.ContactStatus" [value]="status.value">
                        {{status.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Status -->
            <mat-form-field class="col-6" style="padding-left:20px">
                <mat-label>{{ 'CONTACT.NEGOTIATION_STATUS' | translate }}</mat-label>
                <mat-select 
                            name="commercial_status"
                            [ngClass]="{'beforeScary2': enableMassiveStatus}"
                            (selectionChange)="changeStatus($event)"
                            [(ngModel)]="newContact.workflowResult">
                    <mat-option *ngFor="let workflowResult of enums.WorkFlowResult" [value]="workflowResult.value">
                        {{workflowResult.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SAVE BUTTON -->
            <mat-dialog-actions class="mr-3 w-100 justify-content-center" style="display:block">
                <div style="display:inline-flex;float:right">
                    <button mat-button
                            class="uniumx-button level2-button"
                            style="margin:15px 0px 0px 15px;width: 125px;"
                            (click)="resetEnablers()">
                        <img class="mr-2" src="/app_assets/svg/material-icons/cached_blue.svg">
                        Reset
                    </button>
                    <button mat-button
                            class="uniumx-button level2-button"
                            style="margin:15px 0px 0px 15px;width: 125px;"
                            (click)="editMassive()">
                        <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                        {{'BUTTONS.SAVE' | translate}}
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
    </div>
</div>

<!-- url check channel update -->
<div *ngIf="data.type === 'urlCheck'">
    <div class="urlCheckAlert">
        <h4>Warning!</h4>
        <p *ngIf="data.channelCheck.isSoftLegal">This {{channelOrLink}} is currently been used in Soft Legal</p>
        <p *ngIf="data.channelCheck.isContentBuild">This {{channelOrLink}} is currently been used in Content Build</p>
        <p *ngIf="data.channelCheck.isCustomerChannel">This {{channelOrLink}} is currently been used in Customer Channel</p>
        <p *ngIf="data.channelCheck.isSERPContent">This {{channelOrLink}} is currently been used in SERP Content</p>
        <p *ngIf="data.channelCheck.usedByOtherCustomer">This {{channelOrLink}} is currently been used by other customers</p>
        <p *ngIf="data.channelCheck.newUrlIsCustomerContent">This {{channelOrLink}} already exists for this customer</p>
        <p *ngIf="data.channelCheck.newUrlIsOtherCustomerContent">The new {{channelOrLink}} is currently been used by other customers</p>

        <div style="margin-top: 15px">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    style="min-width:125px; margin-right: 30px"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>

            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    style="min-width:125px"
                    (click)="_cancel()">
                <img src="/app_assets/svg/material-icons/clear_grey.svg">
                {{'BUTTONS.CANCEL' | translate}}
            </button>
        </div>
    </div>
</div>

<!-- url check channel update -->
<div *ngIf="data.type === 'serpTable'">

    <h5>{{data.randomString}}</h5>
    <mat-table [dataSource]="data.serpSource">
        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef>{{'SERP.LINK_POSITION_TREND' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry" [ngClass]="{'query-in-progress': loading}">
                {{entry.position}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="variation">
            <mat-header-cell *matHeaderCellDef>{{'TABLE_HEADINGS.VARIATION' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry" [ngClass]="{'query-in-progress': loading}">
                {{entry.variation}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="keyword">
            <mat-header-cell *matHeaderCellDef>{{'SERP.KEYWORD' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry" [ngClass]="{'query-in-progress': loading}">
                {{entry.querySearchKeywords}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="engine">
            <mat-header-cell *matHeaderCellDef>{{'SERP.ENGINE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry" [ngClass]="{'query-in-progress': loading}">
                {{entry.querySearchEngineHost}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="frequency">
            <mat-header-cell *matHeaderCellDef>{{'SERP.FREQUENCY' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry" [ngClass]="{'query-in-progress': loading}">
                {{enums.SERPFrequency.getForValue(entry.frequency).name | translate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastExecutionDate">
            <mat-header-cell *matHeaderCellDef>{{'SERP.LAST_EXECUTION_DATE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let entry"
                      [ngClass]="{'query-in-progress': loading}">
                <span [matTooltip]="entry.lastExecutionDate | date: 'dd/MM/yyyy, HH:mm'"
                      matTooltipPosition="right">
                    {{formatDate(entry.lastExecutionDate)}}
                </span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsContentSerp"></mat-header-row>
        <mat-row *matRowDef="let riga; columns: displayedColumnsContentSerp;" class="table-row"></mat-row>
    </mat-table>
</div>

<!-- QUICK SERP TEMPLATE -->
<div *ngIf="data.type === 'quickSerpDictionary'">
    <mat-label>{{ 'TITLES.CONTACT_KEYWORDS' | translate}}</mat-label>
    <mat-card class="inner-container p-3 mt-3">
        <mat-chip-grid class="aired" #keywordList>
            <mat-chip-row *ngFor="let keyword of data.dictionary" selectable="true"
                          (removed)="deleteDictionary(keyword)">
                {{keyword.keyword}}
                <img src="/app_assets/svg/material-icons/cancel_blue.svg" matChipRemove>
            </mat-chip-row>
            <input [matChipInputFor]="keywordList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addDictionary($event)">
        </mat-chip-grid>
    </mat-card>
    <mat-dialog-actions class="w-100 mr-3">
        <button mat-button color="primary"
                class="ml-auto level2-button"
                (click)="_ok()">
            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
            {{'BUTTONS.SAVE' | translate}}
        </button>
    </mat-dialog-actions>
</div>

<!-- MYUNIUMX TEMPLATE -->
<div *ngIf="data.type === 'myuniumxtable'" class="fontFamily">
    <app-myuniumx-users-settings [usersCustomers]="data.users" (ok)="_ok()"></app-myuniumx-users-settings>
</div>

<!-- Step Date TEMPLATE -->
<div *ngIf="data.type === 'stepdatechange'" class="fontFamily">
    <mat-form-field class="col-12 my-2">
        <mat-label>{{'Nuovo Contatto' | translate }}</mat-label>
        <input name="newContactDate"
               [matDatepicker]="picker"
               [(ngModel)]="data.params.statusDate"
               matInput
               required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="col-12">
        <input type="time" class="newContactTime" name="newContactTime" [(ngModel)]="data.params.time" />
    </div>

    <div class="col-12">
        <p *ngIf="isFutureDate" style="font-size: 12px; margin-top: 20px; color: #f44336;">Non inserire date future</p>
    </div>

    <mat-dialog-actions class="w-100 mr-3">
        <button mat-button color="primary"
                class="level2-button"
                (click)="_okStepDateChange()">
            <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
            {{'BUTTONS.SAVE' | translate}}
        </button>
    </mat-dialog-actions>
</div>

<!-- add new Serp -->
<div *ngIf="data.type === 'addNewSerp'" class="fontFamily addNewSerp">
    <mat-tab-group class="center">
        <mat-tab label="Automatica">
            <app-serp-generator (query)="getQuery($event, data)" [type]="config.TYPESERP.SERPAUTOMATIC" [insertDate]="data.query.insertDate"></app-serp-generator>
        </mat-tab>
        <mat-tab label="Manuale">
            <app-serp-generator (query)="getQuery($event, data)" [type]="config.TYPESERP.SERPMANUAL" [insertDate]="data.query.insertDate"></app-serp-generator>
        </mat-tab>
    </mat-tab-group>
</div>


<!-- moreInfoSerp -->
<div *ngIf="data.type === 'moreInfoSerp'" class="fontFamily">
    <div class="row">
        <!-- data inserimento -->
        <div class="col-md-12 mt-2">
            <p><strong>Data inserimento:</strong> {{data.query.insertDate | date:config.NativeDateFormat}}</p>
        </div>
        <!-- keyword -->
        <div class="col-md-12 mt-2">
            <p><strong>Keyword:</strong> {{data.query.querySearchKeywords}}</p>
        </div>
        <!-- domain -->
        <div class="col-md-12 mt-2">
            <p><strong>Dominio:</strong> {{data.query.querySearchEngineHost}}</p>
        </div>
        <!-- citta -->
        <div *ngIf="data.query.uuleDisplay" class="col-md-12 mt-2">
            <p><strong>Citta':</strong> {{data.query.uuleDisplay}}</p>
        </div>
        <!-- hl -->
        <div *ngIf="data.query.hlDisplay" class="col-md-12 mt-2">
            <p><strong>Browser:</strong> {{data.query.hlDisplay}}</p>
        </div>
        <!-- gl -->
        <div *ngIf="data.query.glDisplay" class="col-md-12 mt-2">
            <p><strong>Nazione:</strong> {{data.query.glDisplay}}</p>
        </div>

        <!-- numero acquisizioni -->
        <div class="col-md-12 mt-2">
            <p><strong>{{'SERP.ACQUISITIONS' | translate}}: </strong> {{data.query.results.length}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-2 d-flex align-items-center">
            <strong>URL:</strong>
            <!-- url from Paramaters -->
            <button *ngIf="data.query.queryUrlFromParameters" class="level2-button mat-raised-button ml-3" (click)="copyToBoard(data.query.queryUrlFromParameters)" style="min-width:unset;">
                <img src="/app_assets/svg/material-icons/copy.svg" class="svg-20" style="margin-bottom:-4px;" />
            </button>
            <!-- normal url -->
            <button *ngIf="!data.query.queryUrlFromParameters" class="level2-button mat-raised-button ml-3" (click)="copyToBoard(data.query.queryUrl)" style="min-width:unset;">
                <img src="/app_assets/svg/material-icons/copy.svg" class="svg-20" style="margin-bottom:-4px;" />
            </button>
            <img *ngIf="copytext" src="app_assets/svg/material-icons/check_circle_green.svg" class="m-0 ml-3" />
        </div>
    </div>
</div>


<!-- edit Url Serp when is not parametric -->
<div *ngIf="data.type === 'editUrlSerp'" class="fontFamily addNewSerp">
    <app-serp-generator (query)="getQuery($event, data)" [type]="config.TYPESERP.SERPAUTOMATIC" [insertDate]="data.query.insertDate" [isEdit]="true" /> 
</div>

<!-- customerStartActivity -->
<div *ngIf="data.type === 'customerStartActivity'" class="fontFamily">
    <div class="row mt-5">
        <!-- Contract Date -->
        <mat-form-field class="col-12">
            <mat-label>{{'CONTACT.CONTRACT_DATE' | translate}}</mat-label>
            <input name="contactDate"
                   [matDatepicker]="pickerContractDate"
                   [(ngModel)]="data.contract.salesContractDate"
                   #firstContactModel="ngModel"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="pickerContractDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerContractDate></mat-datepicker>
        </mat-form-field>
        <!-- Contract End Date -->
        <mat-form-field class="col-12">
            <mat-label>{{'CONTACT.CONTRACT_END_DATE' | translate}}</mat-label>
            <input name="contactEndDate"
                   [matDatepicker]="pickerContractEndDate"
                   [(ngModel)]="data.contract.salesContractEndDate"
                   #firstContactModel="ngModel"
                   matInput>
            <mat-datepicker-toggle matSuffix [for]="pickerContractEndDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerContractEndDate></mat-datepicker>
        </mat-form-field>

        <!-- Relationship type -->
        <mat-form-field class="col-12">
            <mat-label>{{ 'CONTACT.RELATIONSHIP' | translate }}</mat-label>
            <mat-select 
                        id="statusBox"
                        disabled="true"
                        name="relationship_type"
                        [(value)]="data.contract.customerType">
                <mat-option *ngFor="let status of enums.ContactStatus" [value]="status.value">
                    {{status.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-dialog-actions class="col-12">
            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    [disabled]="!data.contract.salesContractDate || !data.contract.salesContractEndDate"
                    style="min-width:125px; margin-right: 30px"
                    (click)="_ok()">
                <img class="mr-2" src="/app_assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>

            <button mat-button color="primary"
                    class="ml-auto level2-button"
                    style="min-width:125px;margin-left: 0 !important"
                    (click)="_cancel()">
                <img src="/app_assets/svg/material-icons/clear_grey.svg">
                {{'BUTTONS.CANCEL' | translate}}
            </button>
        </mat-dialog-actions>
    </div>




</div>
