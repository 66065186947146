import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ContactCheckKeyword, JsonReply } from '../models';
import { Route, RoutePrefix } from '../helpers/routeDecorators';

@Injectable()
@RoutePrefix('/api/ContactCheckKeyword')
export class CustomerCheckKeywordService {

    constructor(private httpClient: HttpClient) {

    }

    @Route('ForContact')
    public getForContact(contactId: number, url: string = ''): Promise<JsonReply<ContactCheckKeyword[]>> {
        return this.httpClient.post<JsonReply<ContactCheckKeyword[]>>(url, contactId)
            .toPromise();
    }

    @Route('Add')
    public add(keyword: ContactCheckKeyword, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, keyword)
            .toPromise();
    }

    @Route('Delete')
    public delete(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }
}