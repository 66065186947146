export class CustomerStartActivity {
    salesContractDate: any;
    salesContractEndDate: any;
    customerType: number;

    constructor() {
        this.salesContractDate = null;
        this.salesContractEndDate = null;
        this.customerType = 8;
    }
}