import { BRCustomerContentId } from './BRCustomerContentId';

export class LinkEntitiesParams {
    entityId: number = 0;
    contactId: number = 0;
    linkedEntityId: number = 0;
}

export class LinkManyEntitiesParams {
    entityId: number = 0;
    linkedEntitiesIds: BRCustomerContentId[] = [];
}

export class BRLinkManyEntitiesParams {
    entityId: number = 0;
    linkedEntitiesIds: BRCustomerContentId[] = [];
}
