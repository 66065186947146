/**
 * Decorator to add routePrefix property to any API service
 * @param constructor
 */
export function Route(route: string) {
    var apiRoute = route;

    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        // save a reference to the original method this way we keep the values currently in the
        // descriptor and don't overwrite what another decorator might have done to the descriptor.
        if (descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }

        var classProto = target;
        var originalMethod = descriptor.value;

        //editing the descriptor/value parameter
        descriptor.value = function () {
            var args = [];
            for (var i = 0; i < arguments.length; i++) {
                args[i] = arguments[i];
            }
            args[arguments.length] = classProto['routePrefix'] + '/' + apiRoute;

            // note usage of originalMethod here
            var result = originalMethod.apply(this, args);
            
            return result;
        };

        // return edited descriptor as opposed to overwriting the descriptor
        return descriptor;
    };
}
