import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';

import {combineLatestWith, Observable, switchMap} from 'rxjs';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import {
    JsonReply,
    MatrixValues,
    MatrixSubValues,
    MatrixAddCustomCat,
    MatrixDeleteCustomCat,
    MatrixSubStatus,
    MatrixMacroValues,
    BRCustomerContentId,
    MatrixSubValuesMassive,
    MatrixMacroCategory
} from '../models';
import {map, tap} from 'rxjs/operators';
@Injectable()
@RoutePrefix('/api/ValueMatrix')
export class ValueMatrixService {

    constructor(private httpClient: HttpClient) {

    }

    @Route('TemplateForContact')
    public getCategoriesList(customerId: number, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, customerId).toPromise();
    }

    @Route('Asset/List')
    public getValuesForAsset(BRCustomerContentId: BRCustomerContentId, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, BRCustomerContentId).toPromise();
    }

    @Route('Asset/Matrix')
    public getAssetMatrix(customerId: number, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, customerId).toPromise();
    }

    @Route('Asset/Update')
    public updateAssetValues(values: MatrixSubValues, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, values).toPromise();
    }

    @Route('Content/List')
    public getValuesForContent(BRCustomerContentId: BRCustomerContentId, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, BRCustomerContentId).toPromise();
    }

    @Route('Content/Matrix')
    public getContentMatrix(customerId: number, url: string = ''): Promise<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, customerId).toPromise();
    }

    @Route('Content/Update')
    public updateContentValues(values: MatrixSubValues, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, values).toPromise();
    }

    @Route('Content/MassiveUpdate')
    public updateContentMassiveValues(values: MatrixSubValuesMassive, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, values).toPromise();
    }

    @Route('Goal/Matrix')
    public getGoalMatrix(customerId: number, categoryActive: boolean | null, url: string = ''): Observable<JsonReply<MatrixValues>> {
        return this.httpClient.post<JsonReply<MatrixValues>>(url, {customerId, categoryActive});
    }

    @Route('Goal/Update')
    public updateGoalMatrix(values: MatrixSubValues, url: string = ''): Observable<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, values);
    }

    @Route('Customer/Update')
    public updateCustomerCategories(categories: MatrixSubStatus, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, categories).toPromise();
    }

    @Route('CustomerMacro/Update')
    public updateCustomerMacroCategories(categories: MatrixMacroValues, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, categories).toPromise();
    }

    @Route('Customer/AddCategory')
    public addCustomCategory(category: MatrixAddCustomCat, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, category).toPromise();
    }

    @Route('Customer/DeleteCategory')
    public deleteCustomCategory(category: MatrixDeleteCustomCat, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, category).toPromise();
    }


    public getValuesForContentWithAllTemplate(BRCustomerContentId: BRCustomerContentId, url: string = '/api/ValueMatrix/'){

        const template$ = this.httpClient.post<JsonReply<MatrixMacroCategory[]>>(url + 'TemplateForContact', BRCustomerContentId.customerId)
            .pipe(
                map(values => {
                    if(values.ok){
                        const data = values.data;
                        return data.filter(d => d.status === false);
                    }
                })
            );

        const values$ = this.httpClient.post<JsonReply<MatrixMacroCategory[]>>(url + 'Content/List' , BRCustomerContentId)
            .pipe(
                map(values => {
                    if(values.ok){
                        return values.data;
                    }
                })
            );

        return template$.pipe(
            combineLatestWith(values$),
            map(resp => {
                const template = resp[0];
                const values = resp[1];

                template.map(t => {
                    const val = values.find(v => v.id === t.id);
                    if(!val){
                        t.categoryActive = false;
                        values.push(t)
                    }
                })

                return values;
            })
        )

    }
}
