import { TableOptions, TableActions, UpdateType, GetFeasibilityParams, FilterSelectEnumOptions, SoftLegalTab } from '../models';

export class ContactLinksTableOptions extends TableOptions {
    params: GetFeasibilityParams;
    newParams: GetFeasibilityParams;

    constructor() {
        super('SoftLegal', true);
        this.buttons = [
            TableActions.ExportContactLinksAll,
            TableActions.ExportContactLinksMany,
            TableActions.DeleteManyBR,
        ];
        this.params = new GetFeasibilityParams();
        this.newParams = new GetFeasibilityParams();
        //se ci sono paramentri salvati nel localstorage li recupero
        var paramStorage = localStorage.getItem('LinkLesivi')
        this.params = paramStorage ? JSON.parse(paramStorage) : this.params;

        this.paths.basePath = 'SoftLegal';
        this.displayedColumns = [
            'selectItems',
            'url',
            'publicationDate',
            'softLegal.workability',
            'softLegal.successFee',
            /*'serpContentInfo.position',*/
            'softLegal.eventsNames',
            'contentCustomerExtData.commonNotes',
            //'customAction'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'url': {
                label: 'LINK',
                sort: true,
                type: 'link',
                plainText: false
            },
            'publicationDate': {
                format: () => 'DD/MM/YYYY',
                label: 'PUBLISHING_DATE',
                sort: true,
                type: 'date',
                plainText: true
            },
            'softLegal.workability': {
                format: () => this.enums.InjuryLevel,
                label: 'WORKABILITY',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'softLegal.successFee': {
                format: () => this.enums.SuccessFeeStatus,
                label: 'SUCCESS_FEE',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },/*
            'serpContentInfo.position': {
                label: 'SERP_POSITION',
                sort: true,
                plainText: true
            },*/
            'softLegal.eventsNames': {
                label: 'EVENTS_NAMES',
                sort: false,
                plainText: true
            },
            'contentCustomerExtData.commonNotes': {
                label: 'NOTES',
                sort: true,
                type: 'longText',
                plainText: false,
                //longTextWithFormat: true
            },
            'customAction': {
                label: 'WFT',
                sort: false,
                plainText: false,
                actionConfig: {
                    tooltip: 'ADD_WFT_ACQUISITION',
                    icon: 'play_circle_blue'
                }
            }
        };
        this.update = {
            action: () => {
                return 'link';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'URL',
                formName: 'url_filter',
                type: 'text-input',
                class: 'col-8',
                param: 'linkPart'
            },
            {
                name: 'PUBLISHING_DATE',
                formName: 'publishing_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4',
                param: 'publishingDate'
            },
            {
                name: 'SUCCESS_FEE',
                formName: 'success_fee_filter',
                type: 'select',
                class: 'col-12 col-md-4',
                param: 'successFee',
                enum: 'SuccessFeeStatus',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            },
            {
                name: 'INJURY_LEVEL',
                formName: 'injury_level_filter',
                type: 'select',
                class: 'col-12 col-md-4',
                param: 'injuryLevel',
                enum: 'InjuryLevel',
                additionalOptions: [FilterSelectEnumOptions.Any_value_null]
            },
            {
                name: 'EVENT',
                formName: 'event_name_filter',
                type: 'text-input',
                class: 'col-12 col-md-4',
                param: 'eventName'
            }
        ];
        this.exportName = 'injurious_links';

        this.params.softLegalTab = SoftLegalTab.All;
        this.newParams.softLegalTab = SoftLegalTab.All;
    }

}



