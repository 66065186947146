import {BaseParamsWithContactId} from "./BaseParamsWithContactId";
import {RichEnum} from "../RichEnum";

export class GetContactLinkParams extends BaseParamsWithContactId {
    constructor(
        public reverseOrder: boolean = false,
        public injuryLevel: number = RichEnum.Any,
        public publishingDate: number = null,
        public linkPart: string = null,
        public notesPart: string = null,
        public eventName: string = null,
    ) {
        super();
    }
}
