import { ContentModel } from "./ContentModel";

export class UsedbyOtherCustomer {
    isSoftLegal: boolean;
    isContentBuild: boolean;
    isCustomerChannel: boolean;
    isSERPContent: boolean;
    newUrlIsOtherCustomerContent: boolean;
    newUrlIsCustomerContent: boolean;
    newUrlContent: ContentModel;
}