import { TableOptions, TableActions, GetCustomerLinkParams, UpdateType, SoftLegalTab } from '../models';

export class SoftLegalTableOptions extends TableOptions {
    constructor(tab: SoftLegalTab) {
        super('SoftLegal', true);
        this.buttons = [
            //TableActions.ExportSoftLegalLinksAll,
            TableActions.DeleteManyBR,
            TableActions.MassiveSoftLegal
        ];
        this.tab = tab;
        if (tab == SoftLegalTab.Instance) {
            this.params = new GetCustomerLinkParams();
            this.newParams = new GetCustomerLinkParams();
        }

        this.paths.basePath = 'SoftLegal';
        this.paths.updatePath = () => '/api/Content/Update';

        if (tab == SoftLegalTab.Authority) {
            this.displayedColumns = [
                'selectItems',
                'softLegal.isLinkWithDocuments',
                'url',
                'softLegal.successFee',
                'softLegal.status',
                'softLegal.instanceReceiverOrTTD',
                'softLegal.eventsNames',
                'contentCustomerExtData.commonNotes',
            ];
        } else {
            this.displayedColumns = [
                'selectItems',
                'softLegal.isLinkWithDocuments',
                'url',
                'softLegal.successFee',
                'softLegal.instanceReceiverOrTTD',
                'softLegal.eventsNames',
                'contentCustomerExtData.commonNotes',
                'contentComponent'
            ];
        }
        
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'softLegal.isLinkWithDocuments': {
                label: 'EMPTY',
                sort: false,
            },
            'url': {
                label: 'URL',
                sort: true,
                type: 'link',
                plainText: false
            },
            'softLegal.successFee': {
                format: () => this.enums.SuccessFeeStatus,
                label: 'SUCCESS_FEE',
                sort: false,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'softLegal.status': {
                format: () => this.enums.SoftLegalLinkStatus,
                label: 'STATUS',
                sort: true,
                type: 'enum',
                translate: true,
                plainText: false
            },
            'softLegal.instanceReceiverOrTTD': {
                label: 'TTD_INSTANCE_RECEIVER',
                sort: false,
                plainText: true
            },
            'softLegal.eventsNames': {
                label: 'EVENTS_NAMES',
                sort: false,
                plainText: false,
                type: 'longText'
            },
            'contentCustomerExtData.commonNotes': {
                label: 'NOTES',
                sort: false,
                plainText: false,
                type: 'longText'
                //longTextWithFormat: true
            },
            'contentComponent': {
                label: 'EMPTY',
                sort: true,
                type: 'contentComponent'
            },
            'customAction': {
                label: 'WFT',
                sort: false,
                plainText: false,
                actionConfig: {
                    tooltip: 'ADD_WFT_ACQUISITION',
                    icon: 'play_circle_blue'
                }
            }
        };
        this.update = {
            action: () => {
                return 'link';
            },
            enabled: true,
            type: UpdateType.Popup,
        };

        this.showFilters = false;
        this.pagination.enabled = true;
        this.pagination.showButtons = true;
    }

}