// Core
import {Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit} from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpUserEvent, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";

//Instruments
import { JsonReply, ContentModel } from "../../instruments/models";
import { NotificationService } from "../notification/notification.service";
import { ContentService } from '../../instruments/services';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'rm-file-uploader',
    templateUrl: './rm-file-uploader.component.html',
    styleUrls: ['./rm-file-uploader.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, MatButtonModule, TranslateModule]
})
export class RmFileUploaderComponent implements OnInit {
    private _disabled: boolean;
    private _headers: HttpHeaders;
    public loading: boolean = false;
    private requestSubscription: Subscription;
    public resp: any;

    @ViewChild('ngMatFileUploaderInput', { read: ElementRef, static: true }) nativeInput: ElementRef;
    @Input() defaultStyle: boolean = true;
    @Input() uploadText: string;
    @Input() OnlyUpload: boolean;
    @Input() content: ContentModel;
    @Input() uploadUrl: string = 'http://localhost/upload';
    maxFileSize: number = 0;
    @Input()
    set headers(value: HttpHeaders) {
        this._headers = value;
    }
    get headers(): HttpHeaders {
        return this._headers;
    }

    @Input() acceptMimeTypes: string = 'image/*,video/*,audio/*,application/pdf,application/zip,application/gzip,text/plain';
    @Input()
    set disabled(disabled: boolean) {
        this._disabled = disabled;
    }
    get disabled(): boolean {
        return this._disabled;
    }

    @Output() uploadFinished: EventEmitter<any> = new EventEmitter<any>();
    @Output() uploadFailed: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitFile: EventEmitter<any> = new EventEmitter<any>();

    file: any;
    percentDone: number = 0;
    fail: boolean = false;

    constructor(private http: HttpClient,
        public contentService: ContentService,
        private snackbar: NotificationService) {

    }

    ngOnInit() {
        if (!this.headers) {
            this.headers = new HttpHeaders();
        }      
    }

    public openFileDialog(): void {
        this.removeFile();
        this.nativeInput.nativeElement.click();
    }

    public fileSelectionChanged(elem: any): void {
        let fi = elem.srcElement;
        if (fi.files && fi.files[0]) {
            this.file = fi.files[0];
            this.uploadCheck();
            this.snackbar.progress(this.uploadText);
        } else {
            this.file = null;
        }
    }

    public removeFile(): void {
        if (this.requestSubscription)
            this.requestSubscription.unsubscribe();

        this.file = null;
        this.nativeInput.nativeElement.value = null;
        this.percentDone = 0;
    }

    public uploadCheck() {
        let sizeMB = this.file.size / Math.pow(1024, 2)

        this.contentService.maxUploadFileSize().then(resp => {
            if (resp.ok) {
                this.maxFileSize = resp.data;

                if (sizeMB < resp.data && !this.OnlyUpload) {
                    this.upload()
                }
                else if (this.OnlyUpload) {
                    this.percentDone = 0;
                    this.emitFile.emit(this.file);
                } else {
                    this.snackbar.error('Error, max size is ' + this.maxFileSize + ' MB');
                    this.file = null;
                    this.nativeInput.nativeElement.value = null;
                    this.percentDone = 0;
                }
            }

        })
       
    }

    public upload(): void {
        this.loading = true;
        this.percentDone = 0;
        let formData: FormData = new FormData();

        formData.append(this.file.name, this.file);

        this.requestSubscription = this.http.post<JsonReply<any>>(this.uploadUrl, formData, { headers: this._headers, 'observe': 'events', 'reportProgress': true, responseType: 'json' }).subscribe(r => {
            let ev: HttpEvent<JsonReply<any>> = r;
            this.resp = r;
            let type = ev.type;
            if (type === HttpEventType.UploadProgress) {
                let progressEv: HttpProgressEvent = ev as HttpProgressEvent;
                this.percentDone = (progressEv.loaded / progressEv.total) * 100;
                return;
            }

            if (type === HttpEventType.Response) {
                let resp = r as HttpResponse<JsonReply<any>>;
                if (resp.ok && resp.body.ok) {
                    this.percentDone = 100;
                    this.uploadFinished.emit({ sender: this, args: resp.body.data });
                } else {
                    this.fail = true;
                    this.uploadFailed.emit({ sender: this, args: resp });
                }

                this.removeFile();
            }

            this.loading = false;
        }, err => {
            console.error(err);
            this.snackbar.error('Upload failed');
            this.loading = false;
        });
    }

    public uploadReport(content) {

        this.loading = true;
        this.percentDone = 0;
        let formData: FormData = new FormData();

        formData.append(content.name, content.file);

        this.requestSubscription = this.http.post<JsonReply<any>>("/api/ContactDocument/UploadMyUniumXDocument", formData, { headers: this._headers, 'observe': 'events', 'reportProgress': true, responseType: 'json' }).subscribe(r => {
            let ev: HttpEvent<JsonReply<any>> = r;
            this.resp = r;
            let type = ev.type;
            if (type === HttpEventType.UploadProgress) {
                let progressEv: HttpProgressEvent = ev as HttpProgressEvent;
                this.percentDone = (progressEv.loaded / progressEv.total) * 100;
                return;
            }

            if (type === HttpEventType.Response) {
                let resp = r as HttpResponse<JsonReply<any>>;
                if (resp.ok && resp.body.ok) {
                    this.percentDone = 100;
                    this.uploadFinished.emit({ sender: this, args: resp.body.data });
                } else {
                    this.fail = true;
                    this.uploadFailed.emit({ sender: this, args: resp });
                }

                this.removeFile();
            }

            this.loading = false;
        }, err => {
            console.error(err);
            this.snackbar.error('Upload failed');
            this.loading = false;
        });
    }
}
