// Core
import { Component, OnInit, Input, NgZone, AfterViewInit } from '@angular/core';

import * as Highcharts from 'highcharts/highstock';

import { SERPLinkPositionTrend } from '../../instruments/models';
// import { isNullOrUndefined } from 'util';

import { SharedService, stringToDate } from '../../instruments/services';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-link-position-trend',
    styleUrls: ['./link-position-trend.component.css'],
    templateUrl: './link-position-trend.component.html',
    standalone: true,
    imports: [NgClass],
})
export class LinkPositionTrendComponent implements OnInit, AfterViewInit {  

    public trendValid: boolean = false;
    private chart: any;
    private options: any;
    dates: any = [];
    positions: any = [];

    @Input() trend: SERPLinkPositionTrend;

    constructor(
        private zone: NgZone,
        public shared: SharedService
    ) {
    }

    ngOnInit(): void {
        if (this.trend) {
            this.trend.dates = this.trend.dates.map(d => stringToDate(d));
            this.trendValid = this.trendIsValid();
        }
    }

    ngAfterViewInit(): void {
        if (this.trend && this.trendIsValid()) {
            this.zone.runOutsideAngular(() => {
                this.initChart();
            });
        }
    }

    private initChart(): void {
        Highcharts.stockChart('highcharts_link_trend', {

            rangeSelector: {
                selected: 1
            },

            title: {
                text: null
            },
            yAxis: {
                reversed: true,
                tickInterval: 1
            },
            navigator: {
                yAxis: {
                    reversed: true
                }
            },

            series: [{
                name: 'SERP position',
                data: this.trendToDatasetMapper(this.trend),
                type: 'spline',
                tooltip: {
                    valueDecimals: 0
                }
            }]
        });
    }

    private trendToDatasetMapper(trend: SERPLinkPositionTrend) {
        let data = [];
        trend.positions.map(p => {
            let date: any = new Date(p.date).getTime();
            data.push([date, p.position])
        });

        return data;
    }

    private trendIsValid(): boolean {
        return this.trend && this.trend.dates && this.trend.positions && this.trend.dates.length > 0 && this.trend.positions.length > 0;
    }
}