export class ProjectGoal {
    customerId: number;
    goalKeyConcepts: number;
    projectBrief: number;
    criticalAspects: number;

    constructor(customerId, goalKeyConcepts, projectBrief, criticalAspects) {
        this.customerId = customerId;
        this.goalKeyConcepts = goalKeyConcepts;
        this.projectBrief = projectBrief;
        this.criticalAspects = criticalAspects;
    }
}