import { TableOptions, TableActions, GetContactEventParams, UpdateType } from '../models';

export class EventsTableOptions extends TableOptions {
    constructor() {
        super('ContactEvent', false);
        this.buttons = [
            TableActions.ExportEventsAll,
            TableActions.ExportEventsMany,
            TableActions.DeleteMany
        ];     
        this.params = new GetContactEventParams();
        this.newParams = new GetContactEventParams();
        //se ci sono paramentri salvati nel localstorage li recupero
        var paramStorage = localStorage.getItem('/api/ContactEvent/ForContact')
        this.params = paramStorage ? JSON.parse(paramStorage) : this.params;

        this.paths.basePath = 'ContactEvent';
        this.displayedColumns = [
            'selectItems',
            'description',
            'startDate',
            'endDate',
            'duration',
            'weight',
            'isAppointment'
        ];
        this.formats = {
            'selectItems': {
                label: 'EMPTY',
                sort: false,
                plainText: true
            },
            'description': {
                label: 'DESCRIPTION',
                sort: true,
                type: 'longText',
                plainText: false
            },
            'startDate': {
                label: 'START_DATE',
                sort: true,
                type: 'date',
                plainText: true
            },
            'endDate': {
                label: 'END_DATE',
                sort: true,
                type: 'date',
                plainText: true
            },
            'duration': {
                label: 'TIME_SPAN',
                sort: false,
                plainText: true
            },
            'weight': {
                label: 'WEIGHT',
                sort: true,
                plainText: true
            },
            'isAppointment': {
                label: 'APPOINTMENT',
                sort: false,
                type: 'boolean',
                plainText: false
            }
        };
        this.update = {
            action: () => {
                return 'event';
            },
            enabled: true,
            type: UpdateType.Popup,
        };
        this.showFilters = true;
        this.filters = [
            {
                name: 'DESCRIPTION',
                formName: 'description_filter',
                type: 'text-input',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'description'
            },
            {
                name: 'START_DATE',
                formName: 'start_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'startDate'
            },
            {
                name: 'END_DATE',
                formName: 'end_date_filter',
                type: 'datepicker',
                class: 'col-12 col-md-4 col-lg-3',
                param: 'endDate'
            },
            {
                name: 'APPOINTMENT',
                formName: 'is_appointment_filter',
                type: 'checkbox',
                class: 'col-3',
                param: 'isAppointment'
            }
        ];
        this.exportName = 'events';
    }

}



