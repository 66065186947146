import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, JsonReplyWithParams, GetTTDParams, TTD, Instance, GetCustomerLinkParams } from "../models";

@Injectable()
@RoutePrefix('/api/Instance')
export class InstanceService {
    constructor(private httpClient: HttpClient) { }

    @Route('ForCustomer')
    public forCustomer(param: GetCustomerLinkParams, url: string = ''): Promise<JsonReply<Instance[]>> {
        return this.httpClient.post<JsonReply<Instance[]>>(url, param).toPromise();
    }

    @Route('OpenForCustomer')
    public openForCustomer(customerId: number, url: string = ''): Promise<JsonReply<Instance[]>> {
        return this.httpClient.post<JsonReply<Instance[]>>(url, customerId).toPromise();
    }

    @Route('ById')
    public getById(id: number, url: string = ''): Promise<JsonReply<Instance>> {
        return this.httpClient.post<JsonReply<Instance>>(url, id).toPromise();
    }

    @Route('Update')
    public update(instance: Instance, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, instance).toPromise();
    }

    @Route('Delete')
    public delete(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id).toPromise();
    }

    @Route('DeleteMany')
    public deleteMany(ids: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ids).toPromise();
    }
}